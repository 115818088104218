import React from 'react';
import {
  BillingInvoiceHeaderInfoForPdfOutputResponse,
  BillingInvoiceStoreDetailItemAmountForPdfOutputResponse,
  BillingInvoiceStoreDetailItemHeaderForPdfOutputResponse,
  BillingInvoiceStoreDetailItemRowDataOutputResponse,
} from '../../api-client';
import { SurvayShopTotalType } from '../../type/PdfType';
import { PdfLayout } from '../templates/PdfLayout';
import { ShopDetailTable } from './Table/ShopDetailTable';
import { BILLING_PDF_SETTINGS } from '../../BillingConstants';

export const ShopDetailInvoice = ({
  lists,
  headers,
  totals,
  tableHeaders,
  nontaxAmountPerTaxRate,
}: {
  lists: Array<BillingInvoiceStoreDetailItemRowDataOutputResponse>;
  tableHeaders: BillingInvoiceStoreDetailItemHeaderForPdfOutputResponse;
  headers: BillingInvoiceHeaderInfoForPdfOutputResponse;
  totals: SurvayShopTotalType;
  nontaxAmountPerTaxRate: BillingInvoiceStoreDetailItemAmountForPdfOutputResponse[];
}): JSX.Element => {
  return (
    <PdfLayout
      headers={headers}
      billingType="店舗別明細"
      paddingHorizontal={BILLING_PDF_SETTINGS.SHOP_DETAIL_PADDINGHORIZONTAL}
    >
      <ShopDetailTable
        lists={lists}
        totals={totals}
        headers={tableHeaders}
        nontaxAmoutPerTaxRate={nontaxAmountPerTaxRate}
      />
    </PdfLayout>
  );
};
