import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSms } from '@fortawesome/free-solid-svg-icons';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { CustomerInfoApi } from '../../../api-client';
import type {
  SmsAuthManualSendFormResponse,
  AuthHistoryListDisplayOutputResponse,
  AuthHistorySaveFormResponse,
  SmsAuthManualSendOutputResponse,
  IncResultOutputResponse,
  ResultOutputResponse,
} from '../../../api-client';
import { CustomerAuthHistoryTable } from '../../organisms/Table/CustomerAuthHistoryTable';
import { createTestId } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { ManualAuthenticationModal } from '../../organisms/Modal/Customer/ManualAuthenticationModal';
import { CustomerSmsAuthModal } from '../../organisms/Modal/Customer/CustomerSmsAuthModal';
import { CustomerInfoHeader } from './CustomerInfoHeader';

export interface CustomerAuthHistoryState {
  api: CustomerInfoApi;
  isLoading: boolean;
  list: AuthHistoryListDisplayOutputResponse[];
  errorMessage: string[];
}
export interface ModalState {
  isManualModal: boolean;
  isEditModal: boolean;
  forms: AuthHistorySaveFormResponse;
  isEdit: boolean;
}

export type TestIds = 'plus-button' | 'sms-button';

export const CustomerAuthHistoryPage: React.FC = () => {
  const testid = createTestId<TestIds>(CustomerAuthHistoryPage);

  const { state: $, mergeState } = useLargeState<CustomerAuthHistoryState>({
    api: new CustomerInfoApi(),
    isLoading: false,
    list: [],
    errorMessage: [],
  });

  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();

  const initialData: AuthHistorySaveFormResponse = useMemo(
    () => ({
      id: undefined,
      customerId: Number(id),
      disableFlg: false,
      occurrenceAt: '',
      phoneNumber: '',
      successFlg: false,
    }),
    [id]
  );

  const {
    state: $modal,
    mergeState: mergeModal,
    useBindSet,
  } = useLargeState<ModalState>({
    isManualModal: false,
    isEditModal: false,
    forms: initialData,
    isEdit: true,
  });

  const setForms = useBindSet('forms');

  useEffect(() => {
    if (id && Number(id) > 0) {
      $.api
        .authHistoryListDisplay(Number(id))
        .then((res: AxiosResponse<AuthHistoryListDisplayOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            mergeState({ list: res.data, errorMessage: [], isLoading: false });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
    }
  }, [$.api, id, mergeState]);

  const createSendSmsBody = useCallback((body: AuthHistorySaveFormResponse) => {
    return {
      customerId: Number(body.customerId),
      phoneNumber: String(body.phoneNumber),
    };
  }, []);

  const onClickSubmitOk = useCallback(() => {
    history.go(0);
  }, [history]);

  // SMS手動送信
  const onSendSmsBody = useCallback(
    async (body: AuthHistorySaveFormResponse) => {
      mergeState({ isLoading: true });
      const retrunObj = {
        data: { codeValue: '', smsDeadlineAt: '' },
        errorMessage: '',
      };
      const sendBody: SmsAuthManualSendFormResponse = createSendSmsBody(body);
      await $.api
        .smsAuthManualSend(sendBody)
        .then((res: AxiosResponse<SmsAuthManualSendOutputResponse>) => {
          if (res.data) {
            mergeState({ errorMessage: [] });
            retrunObj.data = res.data;
          }
        })
        .catch((res: AxiosError<ResultOutputResponse>) => {
          const errors = res.response?.data.errors;
          if (errors) {
            errors.forEach((error) => window.alert(error.message));
          } else {
            window.alert('不明のエラー発生');
          }
        })
        .finally(() => {
          mergeState({ isLoading: false });
        });
      return retrunObj;
    },
    [$.api, createSendSmsBody, mergeState]
  );

  // 会員情報の認証履歴を保存、更新
  const onSubmitAddAuthBody = useCallback(
    async (body: AuthHistorySaveFormResponse) => {
      mergeState({ isLoading: true });
      const retrunObj = {
        returnFlg: false,
        errorMessage: [''],
      };
      await $.api
        .authHistorySave(body)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (res.data.result) {
            mergeState({ isLoading: false, errorMessage: [] });
            retrunObj.returnFlg = res.data.result;
          } else {
            mergeState({ isLoading: false, errorMessage: [`${res.data.errorMessage}`] });
            retrunObj.errorMessage.push(res.data.errorMessage || '');
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
              retrunObj.errorMessage.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
      return retrunObj;
    },
    [$.api, mergeState]
  );

  return (
    <>
      <CustomerInfoHeader activeKey="authHistory" customerId={Number(id)} />
      {$.errorMessage.length
        ? $.errorMessage.map((errorMessage) => (
            <Alert variant="danger" key={errorMessage}>
              {errorMessage}
            </Alert>
          ))
        : undefined}
      <ManualAuthenticationModal
        isModal={$modal.isManualModal}
        forms={$modal.forms}
        setForms={setForms}
        onHide={() =>
          mergeModal({
            isManualModal: false,
            forms: initialData,
          })
        }
        submit={onSendSmsBody}
      />
      <CustomerSmsAuthModal
        isModal={$modal.isEditModal}
        forms={$modal.forms}
        setForms={setForms}
        onHide={() =>
          mergeModal({
            isEditModal: false,
            forms: initialData,
          })
        }
        submit={onSubmitAddAuthBody}
        reload={onClickSubmitOk}
        isEdit={$modal.isEdit}
      />
      <LoadingSpinner isLoading={$.isLoading}>
        <div className="d-flex flex-row-reverse">
          <Button data-testid={testid('sms-button')} variant="link" onClick={() => mergeModal({ isManualModal: true })}>
            <FontAwesomeIcon icon={faSms} />
            SMS認証手動送信
          </Button>
        </div>
        <hr />
        <div className="d-flex flex-row-reverse">
          <Button
            data-testid={testid('plus-button')}
            variant="link"
            onClick={() => mergeModal({ isEditModal: true, isEdit: false })}
          >
            <FontAwesomeIcon icon={faPlus} />
            履歴追加
          </Button>
        </div>
        {$.list.length > 0 ? (
          <>
            <CustomerAuthHistoryTable
              dataList={$.list}
              onClisckPen={(item: AuthHistoryListDisplayOutputResponse) =>
                mergeModal({
                  forms: {
                    id: item.id,
                    customerId: Number(id),
                    disableFlg: item.deleteFlg,
                    occurrenceAt: item.authAt,
                    phoneNumber: item.phoneNumber,
                    successFlg: item.successFlg,
                  },
                  isEditModal: true,
                  isEdit: true,
                })
              }
            />
          </>
        ) : undefined}
      </LoadingSpinner>
    </>
  );
};
