import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: number;
  min?: number;
  max?: number;
  className?: string;
  required?: boolean | undefined;
  readOnly?: boolean | undefined;
  disabled?: boolean | undefined;
  name?: string | undefined;
  id?: string;
}

export const InputNumber: React.VFC<Props> = ({ value, ...rest }) => {
  return (
    <Form.Control
      type="number"
      value={value !== undefined ? value : ''}
      onWheel={(event) => event.currentTarget.blur()}
      onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
      {...rest}
    />
  );
};
