import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { DefaultApi, ClientOfRequiredNumberListOutputResponse, IncResultOutputResponse } from '../../../api-client';
import { TITLE } from '../../../constants/Title';
import { Url } from '../../../constants/Url';
import { Title } from '../../atoms/Title';
import { Alert } from '../../atoms/Alert';
import { Button } from '../../atoms/Button';
import { SearchFrom } from '../../molecules/Form/SearchFrom';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { ClientOfRequiredNumberTable } from '../../organisms/Table/ClientOfRequiredNumberTable';

const sampleGetList = [...Array(201)].map((_, i) => {
  return { clientId: i + 1, clientName: '会社A', requiredNumber: 3, salesStaffName: 'サトウ' };
});

const MAX_RECORD_PER_PAGE = 20;

export const ClientOfRequiredNumberPage: React.VFC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [list, setList] = useState<ClientOfRequiredNumberListOutputResponse[]>([]);
  const [listPerPage, setListPerPage] = useState<ClientOfRequiredNumberListOutputResponse[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [error, setError] = useState<IncResultOutputResponse | null>(null);

  const history = useHistory();
  const defaultApi = new DefaultApi();

  useEffect(() => {
    defaultApi
      .clientOfRequiredNumberList(true)
      .then((res: AxiosResponse<ClientOfRequiredNumberListOutputResponse[]>) => {
        setList(res.data);
        setListPerPage(res.data.slice(0, MAX_RECORD_PER_PAGE));
        setTotalPage(Math.ceil(Number(res.data?.length) / MAX_RECORD_PER_PAGE));
      });
  }, []);

  useEffect(() => {
    setListPerPage(list.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(list?.length) / MAX_RECORD_PER_PAGE));
  }, [list, currentPage]);

  const handleClickSearchBtn = (): void => {
    defaultApi
      .clientOfRequiredNumberList(true, searchText)
      .then((res: AxiosResponse<ClientOfRequiredNumberListOutputResponse[]>) => {
        setList(res.data);
        setCurrentPage(1);
      });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.CLIENT_OF_REQUIRED_NUMBER}</Title>
      {error?.errorCode && error?.errorMessage && (
        <Alert variant="danger">{`${error.errorMessage} (エラーコード：${error.errorCode})`}</Alert>
      )}

      <SearchFrom
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        onClick={handleClickSearchBtn}
      />

      <div className="d-flex justify-content-end mb-4">
        <Button
          variant="link"
          className="text-secondary"
          onClick={() => {
            history.push({
              pathname: Url.KEISAI.CLIENT_OF_REQUIRED_NUMBER_TARGET,
            });
          }}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          派遣マスト数を設定
        </Button>
      </div>
      <ClientOfRequiredNumberTable list={list} setList={setList} listPerPage={listPerPage} setError={setError} />
      <div className="mt-4">
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />
      </div>
    </>
  );
};
