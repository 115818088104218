import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Table, Tabs, Tab } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Alert } from '../../atoms/Alert';
import { BillingAgentFeeModal } from '../../organisms/Modal/BillingAgentFeeModal';
import {
  BillingApi,
  BillingAgentFeeDetailOutputResponse,
  BillingAgentFeeDetailOneRowDtoResponse,
  BillingAgentFeeDetailForEditModalOutputResponse,
  RoiCompanyInfoDtoResponse,
} from '../../../api-client';

const EDIT_TYPE = {
  ADD: 'add',
  EDIT: 'edit',
};

export const BillingAgentFeeDetailPage: React.VFC = () => {
  const location = useLocation<{ id: number }>();
  const [info, setInfo] = useState<RoiCompanyInfoDtoResponse>({
    address: '',
    city: '',
    id: 0,
    name: '',
    phoneNumber: '',
    postalcode: '',
    prefecture: '',
  });
  const [detail, setDetail] = useState<BillingAgentFeeDetailOutputResponse>({
    agentFeeListHeaderId: 0,
    billingAgentName: '',
    incAccountName: '',
    paymentConditionId: 0,
    paymentDate: '',
    publishedDate: '',
    roiCompanyInfo: info,
  });
  const [editItem, setEditItem] = useState<BillingAgentFeeDetailOneRowDtoResponse>();
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [editType, setEditType] = useState<string>(EDIT_TYPE.ADD);

  const billingId = location.state?.id;

  const billingApi = new BillingApi();

  useEffect(() => {
    billingApi.billingAgentFeeDetail(billingId).then((res) => {
      setDetail(res.data);
    });
  }, []);

  const openModal = (editFlg: boolean, targetItem?: BillingAgentFeeDetailOneRowDtoResponse) => {
    setEditItem(targetItem);
    setEditType(editFlg ? EDIT_TYPE.EDIT : EDIT_TYPE.ADD);
    setIsEditModal(true);
  };

  const calcTotalAmount = () => {
    detail.billingAgentFeeDetailList?.reduce((sum, element) => {
      // 税込合計金額の算出
      return sum + element.paymentFeeAmount + Math.floor(element.paymentFeeAmount * element.taxRate);
    }, 0);
  };

  const getTotalTaxExcluded = () => {
    let totalByTax = [
      {
        rate: 10,
        total: 0,
      },
    ];

    detail.billingAgentFeeDetailList?.map((item) => {
      const targetRate = totalByTax.filter((target) => {
        return target.rate === item.taxRate;
      });
      if (targetRate.length < 1) {
        totalByTax.push({ rate: item.taxRate, total: item.paymentFeeAmount });
      } else {
        const index = totalByTax.findIndex((x) => x.rate === item.taxRate);
        totalByTax[index].total += item.paymentFeeAmount;
      }
    });
    // 消費税率降順にソート
    totalByTax = totalByTax.sort((a, b) => b.rate - a.rate);

    return totalByTax.map((element, index) => {
      <tr>
        <td>&nbsp;</td>
        <td>{index === 0 ? '小計（税抜）' : ' '}</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>\{element.total.toLocaleString()}</td>
        <td>{element.rate}%</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>;
    });
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'subject':
        setDetail({ ...detail, subject: event.target.value });
        break;
      case 'paymentDate':
        setDetail({ ...detail, paymentDate: event.target.value });
        break;
      case 'paymentConditonId':
        setDetail({ ...detail, paymentConditionId: Number(event.target.value) });
        break;
      case 'remarks':
        setDetail({ ...detail, remarks: event.target.value });
        break;
      case 'applyComment':
        setDetail({ ...detail, applyComment: event.target.value });
        break;
      case 'rejectComment':
        setDetail({ ...detail, rejectComment: event.target.value });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <BillingAgentFeeModal
        isEditModal={isEditModal}
        setIsEditModal={setIsEditModal}
        editItem={editItem}
        modalOutput={detail.billingAgentFeeDetailForEditModalOutput}
        setDetail={setDetail}
        editType={editType}
      />
      <Tabs>
        <Tab title="ダウンロード">&nbsp;</Tab>
        <Tab title="各種設定">&nbsp;</Tab>
      </Tabs>
      <h3 className="text-center center">代理店フィー一覧</h3>
      <Row className="d-flex">
        <Col className="col-7">
          <div className="mb-4">{detail.billingAgentName}&nbsp;御中</div>
          <div className="mb-4">件名&nbsp;{detail.subject}</div>
          <div className="mb-4">ご請求金額&nbsp;\{calcTotalAmount()}(税込)</div>
          <div className="mb-4">お支払日&nbsp;{detail.paymentDate}</div>
          <div>お支払条件&nbsp;{detail.paymentConditionId}</div>
        </Col>
        <Col className="col-3 justify-content-end">
          <div className="mb-4">{detail.publishedDate}</div>
          <div>{detail.roiCompanyInfo.name}</div>
          <div>〒{detail.roiCompanyInfo.postalcode}</div>
          <div>
            {detail.roiCompanyInfo.prefecture}
            {detail.roiCompanyInfo.city}
            {detail.roiCompanyInfo.address}
            {detail.roiCompanyInfo.building}
          </div>
          <div>TEL : {detail.roiCompanyInfo.phoneNumber}</div>
          <div>FAX : {detail.roiCompanyInfo.faxNumber}</div>
          <div>担当 : {detail.incAccountName}</div>
        </Col>
      </Row>
      <Table className="mt-4 mb-4">
        <thead>
          <tr>
            <th>No</th>
            <th>企業名</th>
            <th>項目</th>
            <th>合計</th>
            <th>御社分</th>
            <th>金額(税抜)</th>
            <th>税区分</th>
            <th>備考</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {detail.billingAgentFeeDetailList?.map((item, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{item.clientName}</td>
                <td>{item.billingItemName}</td>
                <td>{item.billingFeeAmount}</td>
                <td>{item.companyAmount}</td>
                <td>{item.paymentFeeAmount}</td>
                <td>{item.taxRate}</td>
                <td>{item.remarks}</td>
                <td>
                  <a href="#" onClick={() => openModal(true, item)}>
                    編集
                  </a>
                </td>
              </tr>
            );
          })}
          {getTotalTaxExcluded()}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end mt-4 mb-4">
        <Button variant="secondary" className="me-4" onClick={() => openModal(false, undefined)}>
          明細追加
        </Button>
      </div>
      <Row className="d-flex align-items-center">
        <Form.Group controlId="remarks">
          <Form.Label>備考</Form.Label>
          <Form.Control as="textarea" rows={3} name="remarks" defaultValue={detail.remarks} onChange={onChangeText} />
        </Form.Group>
      </Row>
      <Row className="d-flex align-items-center">
        <Form.Group controlId="applyComment">
          <Form.Label>申請コメント</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="applyComment"
            defaultValue={detail.applyComment}
            onChange={onChangeText}
          />
        </Form.Group>
      </Row>
      <Row className="d-flex align-items-center">
        <Form.Group controlId="rejectComment">
          <Form.Label>差戻コメント</Form.Label>
          <Form.Control
            readOnly
            as="textarea"
            rows={3}
            name="rejectComment"
            defaultValue={detail.rejectComment}
            onChange={onChangeText}
          />
        </Form.Group>
      </Row>
      <div className="d-flex justify-content-end mt-4 mb-4">
        <Button variant="secondary" className="me-4">
          更新を反映
        </Button>
        <Button variant="secondary" className="me-4">
          担当者確認
        </Button>
      </div>
    </>
  );
};
