import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { createTestId, uuid } from '../../../utils/functions';
import type { CustomerAuthHistoryState } from '../../pages/Customer/CustomerAuthHistoryPage';
import { PaginationTable } from './PaginationTable';

interface Props {
  dataList: CustomerAuthHistoryState['list'];
  onClisckPen: (item: CustomerAuthHistoryState['list'][0]) => void;
}
export type TestIds = 'table' | 'link-button' | 'icon-button';

export const CustomerAuthHistoryTable: React.FC<Props> = React.memo(({ dataList, onClisckPen }) => {
  const testId = createTestId<TestIds>(CustomerAuthHistoryTable);
  const MAX_RECORD_PER_PAGE = useMemo(() => 20, []);

  const tableData: {
    label: string;
    name: keyof CustomerAuthHistoryState['list'][0];
  }[] = useMemo(() => {
    return [
      { label: '送信日時', name: 'sendAt' },
      { label: '認証日時', name: 'authAt' },
      { label: '電話番号', name: 'phoneNumber' },
      { label: 'ステータス', name: 'status' },
      { label: '詳細', name: 'authDetail' },
      { label: 'IPアドレス', name: 'ipAddress' },
      { label: '手動認証', name: 'manualAuthenticationFlg' },
      { label: '有効', name: 'deleteFlg' },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (item: CustomerAuthHistoryState['list'][0], labelName: keyof CustomerAuthHistoryState['list'][0]) => {
      if (labelName === 'sendAt') return item.sendAt ? item.sendAt : '';
      if (labelName === 'authAt') return item.authAt ? item.authAt : '';
      if (labelName === 'deleteFlg') return item.deleteFlg ? '無効' : '有効';
      if (labelName === 'manualAuthenticationFlg') return item.manualAuthenticationFlg ? '手動' : '-';
      return item[labelName];
    },
    []
  );

  const labelList = useMemo(
    () => ['送信日時', '認証日時', '電話番号', 'ステータス', '詳細', 'IPアドレス', '手動認証', '有効'],
    []
  );

  return (
    <PaginationTable
      data-testid={testId('table')}
      resultNum={MAX_RECORD_PER_PAGE}
      list={dataList}
      headerNames={labelList}
    >
      {(list) => {
        return (list as CustomerAuthHistoryState['list']).map((item, i) => {
          return (
            <tr className="align-middle" key={uuid()}>
              {tableData.map(({ name }) => (
                <td key={name}>{createDisplayElement(item, name)}</td>
              ))}
              <td>
                <Button data-testid={testId('icon-button', i)} variant="link" onClick={() => onClisckPen(item)}>
                  <FontAwesomeIcon icon={faPen} />
                </Button>
              </td>
            </tr>
          );
        });
      }}
    </PaginationTable>
  );
});
