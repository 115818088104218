/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { AxiosResponse } from 'axios';
import { Url } from '../../../constants/Url';
import { when } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { ChainStoreMasterListDisplayParentOutputResponse, ShoppingApi } from '../../../api-client';
import type {
  ChainStoreMasterListDisplayOutputResponse,
  ChainStoreMasterRegisterEditFormResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { CorrectPhoneMasterForms } from '../../organisms/Forms/CorrectPhoneMasterForms';
import { CorrectPhoneMasterTable } from '../../organisms/Table/CorrectPhoneMasterTable';
import { CorrectPhoneMasterEditModal } from '../../organisms/Modal/Correct/CorrectPhoneMasterEditModal';

export interface ChainStoreMasterForm {
  chainName?: string;
  phoneNumber?: string;
  storeName?: string;
}
export interface CorrectPhoneMasterState {
  api: ShoppingApi;
  isLoading: boolean;
  isModal: boolean;
  list: ChainStoreMasterListDisplayOutputResponse[];
  modalInfo: ChainStoreMasterRegisterEditFormResponse;
  forms: ChainStoreMasterForm;
  errorMessage: string;
}
export type TestIds = 'search-button' | 'link-button';

export const CorrectPhoneMasterPage: React.FC = () => {
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<CorrectPhoneMasterState>({
    api: new ShoppingApi(),
    isLoading: false,
    isModal: false,
    list: [],
    modalInfo: { chainName: '', storeName: '', storePhoneNumber: '', prefectureId: -1 },
    forms: {},
    errorMessage: '',
  });

  const history = useHistory();
  const setForms = useBindSet('forms');

  // 1ページの最大数（指定しなくてもバックエンド側でデフォルト値[50]が適用される)
  const MAX_RECORD_PER_PAGE = 50;

  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState(0);

  const chainName = $.forms.chainName ?? undefined;
  const storeName = $.forms.storeName ?? undefined;
  const phoneNumber = $.forms.phoneNumber ?? undefined;

  useEffect(() => {
    onSearch();
  }, [page]);

  const handlePageChange = (selectedPage: number) => {
    setPage(selectedPage);
  };

  const convertEmptyStringToUndefinded = (v: string | undefined) => {
    if (v === undefined) return v;
    if (v.trim().length === 0) return undefined;
    return v;
  };
  // 検索
  const onSearch = () => {
    $.api
      .chainStoreMasterListDisplay(
        convertEmptyStringToUndefinded(chainName),
        convertEmptyStringToUndefinded(storeName),
        convertEmptyStringToUndefinded(phoneNumber),
        page,
        MAX_RECORD_PER_PAGE
      )
      .then((res: AxiosResponse<ChainStoreMasterListDisplayParentOutputResponse>) => {
        setPageCount(res.data.total / MAX_RECORD_PER_PAGE);
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          mergeState({ list: [], errorMessage: 'エラーが発生しました。' });
        } else {
          mergeState({ list: res.data.list, errorMessage: '' });
        }
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ errorMessage: error.errorMessage, list: [] });
      });
  };

  const onClickAdd = () => {
    mergeState({
      isModal: true,
      modalInfo: { chainName: '', storeName: '', storePhoneNumber: '', chainStoreMasterId: -1, prefectureId: -1 },
    });
  };

  const onClickEdit = (info: ChainStoreMasterListDisplayOutputResponse) => {
    mergeState({
      modalInfo: {
        chainName: info.chainName,
        storeName: info.storeName,
        storePhoneNumber: info.storePhoneNumber,
        chainStoreMasterId: info.chainStoreMasterId,
        prefectureId: info.prefectureId || -1,
      },
      isModal: true,
    });
  };

  const onClickDelete = useCallback(
    (id: number, i: number) => {
      $.api
        .chainStoreMasterDelete(id)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (res.data.result) {
            const newArray = $.list;
            newArray.splice(i, 1);
            mergeState({ list: newArray });
          } else {
            history.push(Url.COMMON_ERROR);
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    },
    [$.api, $.list, history, mergeState]
  );

  return (
    <>
      <LoadingSpinner isLoading={$.isLoading}>
        {when(
          $.isModal && !!$.modalInfo.chainStoreMasterId,
          <CorrectPhoneMasterEditModal
            isModal={$.isModal}
            onHide={() => mergeState({ isModal: false })}
            info={$.modalInfo}
            handleSearchCorrectPhoneMaster={onSearch}
          />
        )}
        <div className="border border-black p-2 m-2 d-flex justify-content-start bg-light">
          <Button variant="link" onClick={() => history.goBack()}>
            &lt; 戻る
          </Button>
        </div>
        <Form className="mt-4">
          <Row className="g-2 mb-4">
            <CorrectPhoneMasterForms setForms={setForms} />
          </Row>
          <Button
            className="mt-2"
            onClick={() => {
              onSearch();
            }}
          >
            検索
          </Button>
          <div className="m-2 d-flex justify-content-end">
            <Button variant="link" onClick={() => onClickAdd()}>
              <FontAwesomeIcon icon={faPlus} />
              追加
            </Button>
          </div>
        </Form>
        {$.list.length ? (
          <CorrectPhoneMasterTable list={$.list} onClickEdit={onClickEdit} onClickDelete={onClickDelete} />
        ) : undefined}
        {when(
          $.list.length > 0,
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={(selected) => handlePageChange(selected.selected)}
            activeClassName="active"
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        )}
      </LoadingSpinner>
    </>
  );
};
