import React from 'react';
import { Card } from 'react-bootstrap';
import type { MonitorRuleDtoResponse } from '../../../../../api-client';
import { monitorApi } from '../../../../../test/mocks/api/MonitorApi';
import { uuid } from '../../../../../utils/functions';
import { DayOfWeek, MONITOR_RULE_TYPE } from '../../../../../Constants';
import sass from '../../../../../scss/organisms/calendarTable.module.scss';
import { CalenderTable } from '../../../Table/CalenderTable';

// TODO: [添削] テスト未実装
export const MonitorRule: React.FC<{ monitorRule: MonitorRuleDtoResponse; isReadOnly: boolean }> = ({
  monitorRule,
  isReadOnly,
}) => {
  const backgroundColor = isReadOnly ? {} : { backgroundColor: 'rgba(0, 0, 0, 0.05)' };
  const image = monitorRule.imageUrl ? (
    <p>
      <img src={monitorRule.imageUrl} alt="" style={{ width: '176px' }} />{' '}
    </p>
  ) : (
    <></>
  );
  const content = monitorRule.content
    ? monitorRule.content.split(/\n/).map((line, i, { length }) => (
        <span key={uuid()}>
          {line}
          {i < length ? <br /> : <></>}
        </span>
      ))
    : '';
  const imageTargets = monitorRule.imageTargets.map((value: string, index: number) => (
    <ImageRuleBaseTag contentName={`調査対象${index + 1}`} content={value} key={uuid()} />
  ));

  return (
    <Card body style={backgroundColor}>
      {image}
      {monitorRule.type === MONITOR_RULE_TYPE.INVESTIGATION_DATE ? (
        <CalenderTable monitorRuleSettingAttribute={monitorRule.monitorRuleSettingAttribute} />
      ) : (
        <div style={{ fontWeight: 'bold' }}>{content}</div>
      )}
      {monitorRule.supplements?.map((v) => {
        return (
          <div
            key={uuid()}
            style={{ whiteSpace: 'pre-wrap' }}
            className="small"
            dangerouslySetInnerHTML={{ __html: v }}
          />
        );
      })}
      {imageTargets}
      <ImageRuleBaseTag contentName="撮影目的" content={monitorRule.objective} />
      <ImageRuleBaseTag contentName="タイミング" content={monitorRule.timing} />
      <ImageRuleBaseTag contentName="範囲" content={monitorRule.range} />
      <ImageRuleBaseTag contentName="範囲フリー記述" content={monitorRule.rangeFreeText} />
      <ImageRuleBaseTag contentName="撮影角度" content={monitorRule.angle} />
      <ImageRuleBaseTag contentName="撮影角度フリー記述" content={monitorRule.angleFreeText} />
      <ImageRuleBaseTag contentName="その他注意" content={monitorRule.other} />
    </Card>
  );
};
const ImageRuleBaseTag: React.FC<{ contentName: string; content?: string }> = ({ contentName, content }) => {
  return content ? (
    <p>
      {contentName}：{content}
    </p>
  ) : (
    <></>
  );
};
