export const DATETIME_DISPLAY_FORMAT = 'yyyy/MM/dd HH:mm:ss';
export const LOCALDATETIME_HYPHEN_PADDING_START_DAY_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const DATE_DISPLAY_FORMAT = 'yyyy/MM/dd';
export const LOCALDATE_HYPHEN_FORMAT = 'yyyy-MM-dd';
export const LOCALDATE_HYPHEN_SHORT_FORMAT = 'yyyy-MM';
export const HOUR_MINUTE = 'HH:mm';

export const DayOfWeek = ['月', '火', '水', '木', '金', '土', '日', '祝', '祝前'];
export const SITE_TITLE = '検証';
export const SITE_DESCRIPTION = '検証アプリ';

export const SUCCESS = '成功';
export const ERROR = '失敗';
export const CLEAR = 'クリア';

// Service Mode
export const SERVICE_MODE_NORMAL = 0;
export const SERVICE_MODE_MAINTENANCE = 99;

// Token
export const ACCESS_TOKEN_BEARER = 'Bearer ';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
// 同一視用Cookie
export const FANCREW_CUSTOMER_ID_COOKIE_TOKEN = 'fid';

export const OEM_API_VERSION = '1';

export const PARAM_TYPE = {
  STRING: 1,
  DATE: 2,
  DATE_AND_TIME: 3,
  LONG: 4,
  INT: 5,
  DOUBLE: 6,
  BOOLEAN: 7,
  IMAGE: 8,
} as const;

export const IMAGE_TYPE_ID = {
  MAIN_SHOP: 1,
  SUB_SHOP: 2,
  FREE_SHOP: 3,
  BRAND: 16,
  BRAND_RICH: 17,
  CAMPAIGN: 18,
  CAMPAIGN_RICH: 19,
  QUESTION: 22,
  MONITOR_RULE_PHOTO: 24,
  BANNER: 25,
} as const;

export const OBJECT_TYPE = {
  BANNER: 'BANNER',
  BRAND: 'BRAND',
  CAMPAIGN: 'CAMPAIGN',
  ENQUETE: 'ENQUETE',
  INVOICE: 'INVOICE',
  RECEPT: 'RECEPT',
  SHOP: 'SHOP',
  PDF: 'PDF',
  BILLING_PDF: 'BILLING_PDF',
  QUESTION: 'QUESTION',
  MONITOR_RULE: 'MONITOR_RULE',
} as const;

export const MESSAGE_SUCCESS_APPLICATION = '申請しました。';
export const GENDER_NAMES: string[] = ['男性', '女性'];

export const GENDER = {
  MAN: 0,
  WOMAN: 1,
};

export const POINT_EXCHENGE_STATUS_NAME = ['通常', 'ポイント凍結'];

export const POINT_EXCHENGE_STATUS = {
  NORMAL: 0,
  POINT_FREEZE: 1,
};

export const INC_CATEGORY = {
  KEISAI: 1,
  UCOMI: 2,
  SALES_PLANNING: 3,
  MARKETING: 4,
  BILLING: 5,
  QCQREW: 6,
  DATA_INPUT_WORKER: 7,
};

export const ENCODE_SJIS = 'Shift_JIS';
export const ENCODE_UTF8 = 'utf8';

export const EXTENSION_EXCEL = 'xlsx';
export const EXTENSION_PDF = 'pdf';
export const MAIL_SEAERCH_TYPE_ALL = 99;

// 直前のURLを取得するkey
export const LAST_PATH = 'last_path';

// エスカレーションIDを取得するkey
export const ESCALATION_ID = 'escalation_id';

// クエリキャッシュのキー 一意である必要がある
export const CACHE_KEY_BILLING_CLIENT = 'cache_key_billing_client';
export const CACHE_KEY_BILLING_CONTRACT = 'cache_key_billing_contract';
export const CACHE_KEY_BILLING_CONTRACT_SHOP = 'cache_key_billing_contract_shop';
export const CACHE_KEY_BILLING_MONITOR_BASE = 'cache_key_billing_monitor_base';
export const CACHE_KEY_BILLING_INC_ACCOUNT = 'cache_key_billing_inc_account';
export const CACHE_KEY_BILLING_BILLING_APPROVE_INC_ACCOUNT = 'cache_key_billing_billing_approve_inc_account';
export const CACHE_KEY_BILLING_AGENT = 'cache_key_billing_agent';
export const CACHE_KEY_API_CLIENT = 'cache_key_api_client';

export const VISIT_AT_CHECK_RESULT_TYPE = {
  ALLOWED: 0,
  NG: 1,
  ALERT: 2,
};

export const MONITOR_RULE_TYPE = {
  MONITOR_RULE: 1, // "モニタールール"
  INVESTIGATION_DATE: 2, // "調査日"
  TIME: 3, // "時間"
  NUMBER_OF_PEOPLE_OR_COMPANION: 4, // "人数・同伴者"
  RESERVE: 5, // "予約"
  TARGET_ITEM: 6, // "指定商品"
  PHOTO_SHOOT: 7, // "写真撮影"
  CLERK_INVESTIGATION: 8, // "店員調査"
  TOILET_INVESTIGATION: 9, // "トイレ調査"
  CLEANING_INVESTIGATION: 10, // "清掃状況調査"
  OTHER_INVESTIGATION: 11, // "その他調査"
  PAYMENT: 12, // "支払方法"
  COUPON_OR_DISCOUNT: 13, // "クーポン・割引"
  INVESTIGATION_EVIDENCE: 14, // "調査証明"
  REMARK: 15, // "特記事項"
  NOTE: 16, // "注意事項"
  QUALIFICATION: 17, // "応募条件"
  ORDER_METHOD: 18, // "注文方法"
  TARGET_BRAND: 19, // "対象ブランド"
  APPLICATION_METHOD: 20, // "申込方法"
  APPEARANCE_INVESTIGATION: 21, // "外観調査"
  PURCHASE_STORE: 22, // "購入店舗"
  OPERATION_INVESTIGATION: 23, // "施術調査"
  EXPERIENCE_INVESTIGATION: 24, // "体験調査"
  OTHER: 99, // "その他"
};

export const CORRECT_BEFORE_ACTION_STATUS = {
  ESCALATION_ANSWERED: 0, // エスカレ回答
  ESCALATION_RELEASED: 1, // エスカレ回答からのリリース
  CUSTOMER_RESUBMIT: 2, // カスタマー再提出
} as const;

export const EVENT_MESSAGE_SEND_STATUS = {
  未送信: 0, // 未送信
  送信済: 1, // 送信済み
  停止: 2, // 停止
  エラー: 3, // エラー
} as const;

export const EVENT_MESSAGE_SUMMARY_TYPE = {
  当選: 1,
  アンケート提出完了: 2,
  アンケート審査NG: 3,
  調査証明提出完了: 4,
  調査証明NG: 5,
  キャンセル: 6,
  モニター完了: 7,
  提出期限通知: 8,
  再提出期限通知: 9,
  モニター却下: 10,
  添削に関する補足事項: 11,
} as const;

export const APPLY_STATUS = [
  '手動抽選待ち',
  '自動抽選待ち',
  '落選',
  '当選返答待ち',
  '当選',
  '当選辞退',
  '提出期限切れ',
  '完了',
  'モニター無効',
  'キャンセル待ち受付完了',
  'キャンセル待ち受付辞退',
  'キャンセル待ち期限切れ',
  'キャンセル待ち当選返答待ち',
  'キャンセル待ち当選返答期限切れ',
  'キャンセル待ち当選辞退',
] as const;
