import { Url } from '../../../constants/Url';

// 調査証明添削画面のURLを生成
export const getSurveyProofCorrectPageUrl = (surveyProofCorrectType: number, applyId: number | undefined): string => {
  switch (surveyProofCorrectType) {
    case 0: // レシート
      return `${Url.TENSAKU.CORRECT_RECEIPT}/${applyId}`;
    case 1: // データ入力
      return `${Url.TENSAKU.CORRECT_DATA_INPUT}/${applyId}`;
    case 2: // データ入力添削
      return `${Url.TENSAKU.CORRECT_DATA_WCHECK}/${applyId}`;
    case 3: // 納品書
      return `${Url.TENSAKU.CORRECT_DELIVERY_SLIP}/${applyId}`;
    default:
      return `${Url.TENSAKU.CORRECT_RECEIPT}/${applyId}`;
  }
};
