import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button, Card, Col, Form, Row, Table, FloatingLabel } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
/* eslint-disable import/no-cycle */
import { MonitorBaseInfoOutputResponse, ShopApi, ShopListOutputResponse } from '../../../api-client';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { ShopUpdateModal } from './ShopUpdateModal';
/* eslint-enable import/no-cycle */

const getSampleData = (num: number): ShopListOutputResponse[] => {
  return [...Array(num)].map((_, i) => ({
    id: i + 1,
    name: `店舗名${i + 1}`,
    clientName: `クライアント名${i + 1}`,
  }));
};

export interface Props {
  isModal: boolean;
  onSave: (id: number, shopName: string, url: string) => void;
  onHide: () => void;
  contractShopId: number;
  mergeParam: (shopId: number, shopName: string, url: string) => void;
}

export interface State {
  api: ShopApi;
  currentId: number;
  currentShopName: string;
  currentShopUrl: string;
  params: { id?: number; name?: string; clientName?: string };
  list: ShopListOutputResponse[];
  listPerPage: ShopListOutputResponse[];
  currentPage: number;
  totalPage: number;
}

const MAX_RECORD_PER_PAGE = 100;

export const ShopListModal: React.VFC<Props> = ({ isModal, onSave, onHide, contractShopId, mergeParam }) => {
  const testid = createTestId(ShopListModal);

  const { state: $, mergeState } = useLargeState<State>({
    api: new ShopApi(),
    currentId: 0,
    currentShopName: '',
    currentShopUrl: '',
    params: {},
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  });

  useEffect(() => {
    if (!isModal) return;
    $.api.shopList().then((res: AxiosResponse<ShopListOutputResponse[]>) => {
      setPagingStates(res.data);
      // setPagingStates(getSampleData(13));
    });
  }, [$.api, isModal]);

  const onSearch = () => {
    const { id, name, clientName } = $.params;
    $.api.shopList(id, name, clientName).then((res: AxiosResponse<ShopListOutputResponse[]>) => {
      setPagingStates(res.data);
      // setPagingStates(getSampleData(1));
    });
  };

  const setPagingStates = (list: ShopListOutputResponse[]) => {
    const sortList = list.sort((a, b) => b.id - a.id);

    mergeState({
      list: sortList,
      listPerPage: sortList.slice(0, MAX_RECORD_PER_PAGE),
      totalPage: Math.ceil(Number(sortList?.length) / MAX_RECORD_PER_PAGE),
      currentPage: 1,
    });
  };

  const [isUpdataModal, setIsUpdataModal] = useState<boolean>(false);

  return (
    <Modal
      onHide={onHide}
      isModal={isModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button
              variant="link"
              className="ms-2"
              onClick={() => setIsUpdataModal(true)}
              data-testid={testid('create')}
            >
              作成
            </Button>
            <ShopUpdateModal
              isModal={isUpdataModal}
              onHide={() => {
                setIsUpdataModal(false);
              }}
              contractShopId={contractShopId}
              mergeParam={mergeParam}
              onHideShopListModal={onHide}
            />
            <Button variant="link" className="ms-2" onClick={onHide} data-testid={testid('cancel')}>
              キャンセル
            </Button>
            <Button
              className="ms-2"
              onClick={() => onSave(Number($.currentId), $.currentShopName, $.currentShopUrl)}
              data-testid={testid('save-button')}
              disabled={$.currentId === 0}
            >
              保存
            </Button>
          </div>

          <Card className="d-flex">
            <Row className="mx-2 my-3">
              <Col className="px-1">
                <FloatingLabel label="実店舗ID" data-testid={testid('id')}>
                  <Form.Control
                    type="number"
                    placeholder="実店舗ID"
                    value={$.params.id || ''}
                    onChange={(e) =>
                      mergeState({
                        params: { ...$.params, id: e.target.value !== '' ? Number(e.target.value) : undefined },
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col className="px-1">
                <FloatingLabel label="実店舗名" data-testid={testid('name')}>
                  <Form.Control
                    type="text"
                    placeholder="実店舗名"
                    value={$.params.name || ''}
                    onChange={(e) => mergeState({ params: { ...$.params, name: e.target.value } })}
                  />
                </FloatingLabel>
              </Col>
              <Col className="px-1">
                <FloatingLabel label="クライアント名" data-testid={testid('clientName')}>
                  <Form.Control
                    type="text"
                    placeholder="クライアント名"
                    value={$.params.clientName || ''}
                    onChange={(e) => mergeState({ params: { ...$.params, clientName: e.target.value } })}
                  />
                </FloatingLabel>
              </Col>
              <Col className="col-2">
                <Button onClick={onSearch} type="button" className="w-100 h-100" data-testid={testid('search-button')}>
                  検索
                </Button>
              </Col>
            </Row>
          </Card>
          <Table className="mt-3">
            <thead>
              <tr className="align-middle">
                <th>&nbsp;</th>
                <th>実店舗ID</th>
                <th>実店舗名</th>
                <th>クライアント名</th>
              </tr>
            </thead>
            <tbody>
              {$.listPerPage?.map(({ id, name, clientName, url }: ShopListOutputResponse, i) => (
                <tr key={id} className="align-middle">
                  <td>
                    <Form.Check
                      id={String(id)}
                      type="radio"
                      name="radioGroup01"
                      data-testid={testid('radio', i)}
                      checked={$.currentId === id}
                      onChange={() => {
                        mergeState({ currentId: id, currentShopName: name, currentShopUrl: url });
                      }}
                    />
                  </td>

                  <td>{id}</td>
                  <td>{name}</td>
                  <td>{clientName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-4" data-testid={testid('pagination')}>
            <PaginationWithEllipsis
              currentPage={$.currentPage}
              totalPage={$.totalPage}
              handleClick={(page) => {
                if (!page || page > $.totalPage) return;
                mergeState({
                  listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
                  currentPage: page,
                });
              }}
            />
          </div>
        </>
      }
    />
  );
};
