import React from 'react';
import { Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
import { Badge } from '../../atoms/Badge';
import type { State } from '../../pages/ManagementAnnounce/ManagementAnnouncePage';
import { formatISODate } from '../../../utils/functions';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';

export interface Props {
  listPerPage: State['listPerPage'];
}

const STATUS_DISPLAY = 0;

export const ManagementAnnounceTable: React.VFC<Props> = ({ listPerPage }) => {
  const history = useHistory();

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th className="text-center">状態</th>
            <th>タイトル</th>
            <th>表示期間</th>
          </tr>
        </thead>
        <tbody>
          {listPerPage?.map((item) => (
            <tr key={item.id} className="align-middle">
              <td>{item.id}</td>
              <td className="col-1">
                <Badge
                  pill
                  style={{ fontSize: '0.875rem', width: '100%' }}
                  bg={item.status === STATUS_DISPLAY ? 'success' : 'secondary'}
                >
                  {item.status === STATUS_DISPLAY ? '表示' : '非表示'}
                </Badge>
              </td>
              <td>{item.title}</td>
              <td>
                <div className="d-flex justify-content-between">
                  <div>
                    {item.startAt ? formatISODate(item.startAt, DATETIME_DISPLAY_FORMAT) : ''} ～{' '}
                    {item.endAt ? formatISODate(item.endAt, DATETIME_DISPLAY_FORMAT) : ''}
                  </div>
                  <div>
                    <OverlayTrigger
                      placement="top-end"
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body className="p-1">編集</Popover.Body>
                        </Popover>
                      }
                    >
                      <button
                        type="button"
                        className="ms-2"
                        onClick={() => history.push({ pathname: Url.KEISAI.MANAGEMENT_ANNOUNCE_MODIFY, state: item })}
                      >
                        <FontAwesomeIcon icon={faPen} className="text-secondary flex-shrink-0 me-1" fixedWidth />
                      </button>
                    </OverlayTrigger>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};
