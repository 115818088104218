import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { constants } from 'buffer';
import { createTestId, formatISODate, nameof, when } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import type { ModalState as ParentState } from '../../pages/Customer/CustomerAuthHistoryPage';
import type { SmsAuthManualSendOutputResponse } from '../../../api-client';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';

interface Props {
  forms: ParentState['forms'];
  setFormData: DispatchSetState<string>;
  data: SmsAuthManualSendOutputResponse;
  errorMessage: string;
}

export type TestIds =
  | keyof ParentState['forms']
  | keyof SmsAuthManualSendOutputResponse
  | 'auto-fill-button'
  | 'clipboard';

export const ManualAuthenticationModalForms: React.FC<Props> = ({ forms, setFormData, data, errorMessage }) => {
  const testid = createTestId<TestIds>(ManualAuthenticationModalForms);
  const { state: $, onChangeSet } = useLargeState<ParentState['forms']>(forms);
  const [resData, setResData] = useState<SmsAuthManualSendOutputResponse>({ codeValue: '', smsDeadlineAt: '' });
  const [errorMesssage, setErrorMesssage] = useState<string>('');

  const name = (x: keyof ParentState['forms'] | keyof SmsAuthManualSendOutputResponse) => nameof(x);

  useEffect(() => setFormData(String($.phoneNumber)), [setFormData, $]);

  useEffect(() => {
    setErrorMesssage(errorMessage);
    setResData(data);
  }, [errorMessage, data]);

  return (
    <>
      <Form.Group controlId={name('customerId')} data-testid={testid('customerId')}>
        <Form.Label>会員ID</Form.Label>
        <Form.Control type="text" autoComplete="off" value={$.customerId ?? ''} readOnly />
      </Form.Group>
      <Form.Group controlId={name('phoneNumber')} data-testid={testid('phoneNumber')}>
        <Form.Label>電話番号</Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$.phoneNumber ?? ''}
          onChange={onChangeSet('phoneNumber')}
          isInvalid={errorMesssage.length > 0}
        />
        <Form.Control.Feedback type="invalid">{errorMesssage}</Form.Control.Feedback>
      </Form.Group>
      {when(
        resData.codeValue.length > 0,
        <div className="bg-light pt-2 ps-2">
          <h6>会員向け情報</h6>
          <Form.Label>SMS認証送信画面URL</Form.Label>
          <Form.Group
            controlId={name('codeValue')}
            data-testid={testid('codeValue')}
            className="col-md-11 d-flex justify-content-between align-items-center"
          >
            {/* FIXME tocのURLの追加 */}
            <Form.Control as="textarea" autoComplete="off" value={resData.codeValue} readOnly />
            <div className="ms-2" style={{ cursor: 'pointer' }} data-testid={testid('clipboard')}>
              <FontAwesomeIcon
                icon={faClipboard}
                onClick={() => {
                  navigator.clipboard.writeText(resData.codeValue);
                  window.alert('クリップボードにコピーしました');
                }}
              />
            </div>
          </Form.Group>
          <Form.Group controlId={name('smsDeadlineAt')} data-testid={testid('smsDeadlineAt')} className="col-md-11">
            <Form.Label>認証手続き期限</Form.Label>
            <Form.Control
              type="input"
              className="col-md-11"
              autoComplete="off"
              value={resData.smsDeadlineAt ? formatISODate(resData.smsDeadlineAt, DATETIME_DISPLAY_FORMAT) : ''}
              readOnly
            />
          </Form.Group>
        </div>
      )}
    </>
  );
};
