import * as React from 'react';
import { Form, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faEye } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
/* eslint-disable import/no-cycle */
import type { DispatchSetState } from '../../../interfaces/utils';
import { Button } from '../../atoms/Button';
import { Url } from '../../../constants/Url';
import type { Index, ContractShop } from '../../pages/PostingRequest/PostingReservationPage';
import { APPLICATION_FORM_TYPE } from '../../pages/PostingRequest/PostingReservationPage';
import { when } from '../../../utils/functions';
/* eslint-enable import/no-cycle */

export interface Props {
  postingRequestId: number;
  clientId: number | undefined;
  clientName: string | undefined;
  contractShops: ContractShop[];
  applicationFormType: string;
  setContractShops: DispatchSetState<ContractShop[]>;
  setIsStatusModal: DispatchSetState<boolean>;
  setIsBasicInfoModal: DispatchSetState<boolean>;
  setIsShopListModal: DispatchSetState<boolean>;
  setMonitorBaseId: DispatchSetState<number>;
  setMonitorBaseIds: DispatchSetState<number[]>;
  setIndex: DispatchSetState<Index>;
  postingRequestStatus: number;
}

export const PostingReservationShopTable: React.VFC<Props> = ({
  postingRequestId,
  clientId,
  clientName,
  contractShops,
  applicationFormType,
  setContractShops,
  setIsStatusModal,
  setIsBasicInfoModal,
  setIsShopListModal,
  setMonitorBaseId,
  setMonitorBaseIds,
  setIndex,
  postingRequestStatus,
}) => {
  const history = useHistory();
  return (
    <>
      {contractShops?.map((shop, si) => (
        <Table key={shop.id} className="w-100 mb-4">
          <thead>
            <tr>
              <th>契約店舗名</th>
              <th>表示用店舗名</th>
              <th>実店舗</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-0">{shop.contractShopName}</td>
              <td className="border-0">{shop.shopName}</td>
              <td className="border-0">
                <span>
                  <OverlayTrigger
                    placement="top-start"
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Body className="p-1">実店舗設定</Popover.Body>
                      </Popover>
                    }
                  >
                    <button
                      type="button"
                      onClick={() => {
                        setIndex({ shop: si });
                        setIsShopListModal(true);
                      }}
                      disabled={postingRequestStatus === 5}
                    >
                      <FontAwesomeIcon
                        icon={faClipboardList}
                        className="text-secondary flex-shrink-0 me-1"
                        fixedWidth
                      />
                    </button>
                  </OverlayTrigger>
                </span>
                <span>{shop.shopId ? '設定済み' : '未設定'}</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan={3}>
                <div className="px-2 mb-4">
                  <Table className="mb-0">
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>モニター名</th>
                        <th>モニタールールセット</th>
                        <th>事前アンケート</th>
                        <th>事後アンケート</th>
                        <th>表示開始日</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody className="py-4">
                      {shop.monitorRsvs.map((mr, mi) => (
                        <React.Fragment key={mr.monitorBaseId}>
                          <tr className="border-0">
                            <td className="border-0">
                              <Form.Check.Input
                                type="checkbox"
                                name={`checkbox${mr.monitorBaseId}`}
                                checked={mr.checked}
                                onChange={() => {
                                  setContractShops(
                                    contractShops?.map((v, i) =>
                                      si === i
                                        ? {
                                            ...v,
                                            monitorRsvs: v.monitorRsvs.map((m, idx) =>
                                              mi === idx ? { ...m, checked: !mr.checked } : m
                                            ),
                                          }
                                        : v
                                    )
                                  );
                                }}
                              />
                            </td>
                            <td className="border-0">{mr.monitorName}</td>
                            <td className="border-0">{mr.monitorRuleSetName}</td>
                            <td className="border-0">{mr.preEnqueteName}</td>
                            <td className="border-0">{mr.postEnqueteName}</td>
                            <td className="border-0">{mr.applicationStartDate}</td>
                            <td className="border-0 d-flex justify-content-end">
                              {when(
                                postingRequestStatus !== 0 && postingRequestStatus !== 1,
                                <Button
                                  variant="outline-secondary"
                                  className="text-secondary"
                                  style={{ marginRight: 10 }}
                                  target="_blank"
                                  href={mr.previewUrl}
                                >
                                  <span style={{ textDecoration: 'none', color: 'black' }}>
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      fixedWidth
                                      className="me-1"
                                      style={{ fontSize: '1rem' }}
                                    />
                                    プレビュー
                                  </span>
                                </Button>
                              )}
                              <Button
                                type="button"
                                size="sm"
                                disabled={
                                  clientId !== 1 &&
                                  mr.applicationFormStatus !== 1 &&
                                  applicationFormType === APPLICATION_FORM_TYPE.ADD
                                }
                                onClick={() => {
                                  setMonitorBaseIds([mr.monitorBaseId]);
                                  setIsStatusModal(true);
                                }}
                              >
                                ステータス設定
                              </Button>
                              <Button
                                type="button"
                                size="sm"
                                className="ms-2"
                                onClick={() => {
                                  setMonitorBaseId(mr.monitorBaseId);
                                  setIsBasicInfoModal(true);
                                }}
                                disabled={postingRequestStatus === 5}
                              >
                                基本情報設定
                              </Button>
                              <Button
                                type="button"
                                size="sm"
                                className="ms-2"
                                onClick={() => {
                                  localStorage.clear();
                                  history.push({
                                    pathname: Url.KEISAI.MONITOR_BASE_MODIFY,
                                    state: {
                                      clientName,
                                      postingRequestId,
                                      monitorBaseId: mr.monitorBaseId,
                                      contractShopName: shop.contractShopName,
                                    },
                                  });
                                }}
                                disabled={postingRequestStatus !== 1}
                              >
                                モニター予約
                              </Button>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      ))}
    </>
  );
};
