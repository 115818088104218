import { Url } from '../constants/Url';

export type PageType =
  // sqlTemplatePage
  | { type: 'SQL_TMP_INFO' }
  | { type: 'SQL_TMP_UPDATE' }
  | { type: 'SQL_TMP_COPY' }
  | { type: 'SQL_TMP_DELETE' }
  // sqlQueryPage
  | { type: 'SQL_QUERY_CREATE' }
  | { type: 'SQL_QUERY_INFO' }
  | { type: 'SQL_QUERY_COPY' }
  | { type: 'SQL_QUERY_UPDATE' }
  | { type: 'SQL_QUERY_DELETE' };

export const pageTransition = (type: PageType, listData: any, history: any) => {
  switch (type.type) {
    case 'SQL_TMP_INFO':
      return history.push({
        pathname: `${Url.KEISAI.SQL_TMP_INFO}/${listData.templateId}`,
        state: { listData },
      });
    case 'SQL_TMP_COPY':
      return history.push({
        pathname: Url.KEISAI.SQL_TMP_CREATE,
        state: { listData },
      });
    case 'SQL_TMP_UPDATE':
      return history.push({
        pathname: `${Url.KEISAI.SQL_TMP_UPDATE}/${listData.templateId}`,
        state: { listData },
      });
    case 'SQL_TMP_DELETE':
      return history.push({
        pathname: Url.KEISAI.SQL_TMP_DELETE_CONFIRM,
        state: { listData },
      });
    case 'SQL_QUERY_CREATE':
      return history.push({
        pathname: `${Url.KEISAI.SQL_QUERY_CREATE}`,
        state: { listData, templateFlg: true },
      });
    case 'SQL_QUERY_INFO':
      return history.push({
        pathname: `${Url.KEISAI.SQL_QUERY_INFO}/${listData.queryId}`,
        state: { listData },
      });
    case 'SQL_QUERY_DELETE':
      return history.push({
        pathname: Url.KEISAI.SQL_QUERY_DELETE_CONFIRM,
        state: { listData },
      });
    case 'SQL_QUERY_UPDATE':
      return history.push({
        pathname: `${Url.KEISAI.SQL_QUERY_UPDATE}/${listData.queryId}`,
        state: { listData },
      });
    case 'SQL_QUERY_COPY':
      return history.push({
        pathname: Url.KEISAI.SQL_QUERY_CREATE,
        state: { listData },
      });
    // errorの場合の処理をどうするか
    default:
      return history.push(Url.KEISAI.SQL_TMP);
  }
};
