import * as React from 'react';
import { Row } from 'react-bootstrap';
import { ReactNode } from 'react';

export const BillingCommonRow = ({ className, children }: { className?: string; children: ReactNode }) => {
  return (
    <Row style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} className={className}>
      {children}
    </Row>
  );
};
