import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
import { Completion } from '../../molecules/Completion';

export const SqlQueryCompletionPage = () => {
  const history = useHistory();

  const handleOnClick = () => {
    history.push(Url.KEISAI.SQL_QUERY);
  };

  return <Completion text="一覧画面へ戻る" onClick={handleOnClick} />;
};
