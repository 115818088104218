import React, { useEffect, useState } from 'react';
import { Button, Form, Modal, Table } from 'react-bootstrap';
import { EnqueteInfoOutputResponse, EnquetePointSettingOutputResponse } from '../../../api-client';

export interface Props {
  data?: EnqueteInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<EnqueteInfoOutputResponse>>;
  showFlg: boolean;
  setShowFlg: React.Dispatch<React.SetStateAction<boolean>>;
  selectedQuestionId: number | undefined;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const AllotmentPointConfirmModal: React.VFC<Props> = ({
  showFlg,
  setShowFlg,
  data,
  setData,
  selectedQuestionId,
  setSelectedQuestionId,
}) => {
  const [selectedChoiceList, setSelectedChoiceList] = useState<EnquetePointSettingOutputResponse[]>([]);

  useEffect(() => {
    const pointSettings = data?.pointSettings.filter((_) => {
      return _.questionId === selectedQuestionId;
    });
    if (pointSettings && pointSettings.length > 0) {
      setSelectedChoiceList(pointSettings);
    } else {
      setSelectedChoiceList([]);
    }

    const question = data?.enqueteQuestionsList?.find((_) => {
      return _.questionId === selectedQuestionId;
    });
    if (pointSettings?.length === 0 && question) {
      // 通常の配点設定から積上げ用配点設定を生成するパターン。たぶん。
      setSelectedChoiceList(
        question.selections.map((v) => {
          return {
            point: v.point,
            pointSettingId: -1, // ここに選択肢IDを指定していたのでエラーが発生していた。
            questionId: question.questionId,
            selectionContent: v.content,
            selectionId: v.selectionId,
          } as EnquetePointSettingOutputResponse;
        })
      );
    }
  }, [showFlg]);

  // キャンセルまたはモーダルを閉じた場合のイベント
  const onHide = () => {
    setShowFlg(false);
    setSelectedQuestionId(undefined);
  };

  // テキスト入力イベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>, selectionId: number) => {
    const newSelectedChoiceList = selectedChoiceList?.map((normalList) => {
      if (normalList.selectionId === selectionId) {
        return {
          ...normalList,
          point: Number.isNaN(parseInt(event.target.value, 10)) ? undefined : parseInt(event.target.value, 10),
        };
      }
      return normalList;
    });

    setSelectedChoiceList(newSelectedChoiceList);
  };

  // 保存ボタンイベント
  const onSubmit = () => {
    const newList = data?.pointSettings.filter((_) => {
      return _.questionId !== selectedQuestionId;
    });

    if (data?.enqueteQuestionsList && newList) {
      setData({
        ...data,
        pointSettings: data?.pointSettings.filter((_) => {
          return _.questionId !== selectedQuestionId;
        }),
      });

      selectedChoiceList.map((_) => {
        return newList?.push(_);
      });
      setData({ ...data, pointSettings: newList });
      setShowFlg(false);
      setSelectedQuestionId(undefined);
    }
  };

  return (
    <Modal size="lg" show={showFlg} onHide={onHide} scrollable centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="d-flex justify-content-end mb-4">
          <Button variant="link" className="ms-2" onClick={onHide}>
            キャンセル
          </Button>
          <Button className="ms-2" onClick={onSubmit}>
            保存
          </Button>
        </div>
        <div className="bg-light mb-4 p-3" style={{ width: '100%' }}>
          <div className="mb-2" style={{ fontWeight: 'bold' }}>
            設問
          </div>
          <div className="mb-4">
            {
              data?.enqueteQuestionsList.find((_) => {
                return _.questionId === selectedQuestionId;
              })?.content
            }
          </div>
          <Table width="80%">
            <thead>
              <tr>
                <th style={{ width: '80%' }}>選択肢</th>
                <th className="text-center">
                  積上配点
                  <br />
                  (最大120点)
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedChoiceList?.map((choice, index: number) => {
                return (
                  <tr key={index.toString()}>
                    <td className="align-middle" align="left">
                      {choice.selectionContent}
                    </td>
                    <td className="align-middle" align="center">
                      <Form.Control
                        type="number"
                        defaultValue={choice.point}
                        style={{ width: '60%' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          onChangeText(e, choice.selectionId);
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal.Body>
    </Modal>
  );
};
