import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { when } from '../../utils/functions';

export interface Props {
  onChangeTemplateCode: (value?: number) => void;
  onChangeTemplateText: (value?: string) => void;
  templateList: { id: number; name: string; text: string }[];
  templateCode?: number;
  templateText?: string;
  invisibleInitialSelect?: boolean;
}

export const TemplateSelectContent: React.VFC<Props> = ({
  onChangeTemplateCode,
  onChangeTemplateText,
  templateList,
  templateCode,
  templateText,
  invisibleInitialSelect = false,
}) => {
  const [templateCodeValue, setTemplateCodeValue] = useState<number | undefined>(
    invisibleInitialSelect ? templateCode : -1
  );
  const [templateTextValue, setTemplateTextValue] = useState<string | undefined>(templateText);
  return (
    <>
      <div className="d-flex mt-2">
        <Form.Select
          value={templateCodeValue}
          defaultValue={invisibleInitialSelect ? templateCode : -1}
          onChange={(e) => {
            const id = Number(e.target.value) >= 0 ? Number(e.target.value) : undefined;
            setTemplateCodeValue(id);
            onChangeTemplateCode(id);
          }}
        >
          {when(
            !invisibleInitialSelect,
            <option value={-1} key="default">
              テンプレートを選択
            </option>
          )}
          {templateList.map(({ id, name }) => {
            return (
              <option value={id} key={id}>
                {name}
              </option>
            );
          })}
        </Form.Select>
        <Button
          variant="primary"
          className="ms-2"
          style={{ minWidth: '70px' }}
          onClick={() => {
            const value =
              templateList.filter(({ id }) => id === templateCodeValue).length > 0
                ? templateList.filter(({ id }) => id === templateCodeValue)[0].text
                : '';
            setTemplateTextValue(value);
            onChangeTemplateText(value);
          }}
        >
          挿入
        </Button>
      </div>
      <Form.Control
        as="textarea"
        rows={3}
        className="mt-2"
        value={templateTextValue || ''}
        onChange={(e) => {
          setTemplateTextValue(e.target.value);
          onChangeTemplateText(e.target.value);
        }}
      />
    </>
  );
};
