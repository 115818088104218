import React, { useMemo, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import type { MailBulkDeliveryListState } from '../../pages/MailBulkDelivery/MailBulkDeliveryListPage';
import { InputNumber } from '../../atoms/InputNumber';
import { OemSelectBox } from '../../atoms/OemSelectBox';

export type State = MailBulkDeliveryListState['forms'];

interface Props {
  setForms: DispatchSetState<State>;
}

export type TestIds = keyof State;

export const MailBulkDeliveryListForms: React.FC<Props> = ({ setForms }) => {
  const testId = createTestId<TestIds>(MailBulkDeliveryListForms);
  const { state: $form, onChangeSet } = useLargeState<State>({ oemType: -1 });

  useEffect(() => {
    setForms({ ...$form });
  }, [setForms, $form]);

  const options: [string, number][] = useMemo(
    () =>
      Object.entries({
        選択してください: -1,
        ファンくる: 0,
        Tモニター: 1,
      }),
    []
  );

  return (
    <>
      <Row>
        <Col>
          <Form.Group data-testid={testId('customerId')} controlId="customerId" key="customerId">
            <Form.Label>会員ID</Form.Label>
            <InputNumber value={$form.customerId} onChange={onChangeSet('customerId', Number)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group data-testid={testId('title')} controlId="title" key="title">
            <Form.Label>タイトル</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              value={$form.title || ''}
              onChange={onChangeSet('title', String)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group data-testid={testId('mailAddress')} controlId="mailAddress" key="mailAddress">
            <Form.Label>メールアドレス</Form.Label>
            <Form.Control
              type="email"
              autoComplete="off"
              value={$form.mailAddress || ''}
              onChange={onChangeSet('mailAddress', String)}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group data-testid={testId('content')} controlId="content" key="content">
            <Form.Label>本文</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              value={$form.content || ''}
              onChange={onChangeSet('content', String)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group data-testid={testId('applyId')} controlId="applyId" key="applyId">
            <Form.Label>応募ID</Form.Label>
            <InputNumber value={$form.applyId} onChange={onChangeSet('applyId', Number)} />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group data-testid={testId('oemType')} controlId="oemType" key="oemType">
            <Form.Label>OEM</Form.Label>
            <OemSelectBox onChange={onChangeSet('oemType', String)} />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
