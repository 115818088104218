import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SqlTemplateApi, SqlTemplateListOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { LinkButton } from '../../atoms/Button/LinkButton';
import { SearchFrom } from '../../molecules/Form/SearchFrom';
import { PaginationComponent } from '../../organisms/Pagination';
import { SqlTemplateTable } from '../../organisms/Table/SqlTemplateTable';

export const cellTitle: string[] = ['ID', 'テンプレート名', '最終更新者', '作成日時', '更新日時', '操作'];

export const SqlTemplatePage = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [listData, setListData] = useState<SqlTemplateListOutputResponse[]>([]);
  const [sliceData, setSliceData] = useState<SqlTemplateListOutputResponse[]>();
  // 1ページのデータ表示数
  const resultNum = 5;
  const history = useHistory();

  const sqlTemplateApi = new SqlTemplateApi();

  useEffect(() => {
    sqlTemplateApi.sqlTemplateList().then((response: AxiosResponse<any>) => {
      setListData(response.data);
      setSliceData(response.data.slice(0, resultNum));
    });
  }, []);

  const handleOnClick = (type: string) => {
    switch (type) {
      case 'create':
        return history.push(Url.KEISAI.SQL_TMP_CREATE);
      case 'sqlQueryList':
        return history.push(Url.KEISAI.SQL_QUERY);
      default:
        return history.push(Url.COMMON_ERROR);
    }
  };

  const handleClickSearchBtn = () => {
    // api-docsの更新がうまく反映されないため、再コミットのためだけに意味の無いコードを追加する。
    console.log('TODO');
    sqlTemplateApi.sqlTemplateList(searchText).then((response: AxiosResponse<any>) => {
      setListData(response.data);
      setSliceData(response.data.slice(0, resultNum));
    });
  };

  return (
    <>
      <LinkButton text="SQLクエリ一覧へ" onClick={() => handleOnClick('sqlQueryList')} />
      <LinkButton text="新規作成" onClick={() => handleOnClick('create')} />
      <h4>SQLテンプレート一覧</h4>
      <SearchFrom
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        onClick={handleClickSearchBtn}
      />
      <SqlTemplateTable cellTitle={cellTitle} tableData={sliceData} />
      <PaginationComponent resultNum={resultNum} initialData={listData} setSliceData={setSliceData} />
    </>
  );
};
