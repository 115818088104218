import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { HeaderLayout } from '../organisms/HeaderLayout';
import { BreadCrumbLayout } from '../organisms/BreadCrumbLayout';
import { FooterLayout } from '../organisms/FooterLayout';
import { SideBarLayout } from '../organisms/SideBarLayout';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../scss/global.scss';
import scss from '../../scss/templates/layout.module.scss';

export const OemLayout: React.FC<{ isSidebarHide?: boolean }> = ({ isSidebarHide, children }) => {
  return (
    <>
      <div className={scss.wrap_layout}>
        <input
          type="checkbox"
          id="sidebar_toggleInput"
          className="sidebar_toggleInput d-none"
          defaultChecked={isSidebarHide}
        />
        <div className={scss.wrapper}>
          <Container fluid>
            <HeaderLayout />
            <Row className={scss.main}>
              <Col className={scss.main_inner}>
                <Container fluid>{children}</Container>
              </Col>
            </Row>
            <FooterLayout />
          </Container>
        </div>
      </div>
    </>
  );
};
