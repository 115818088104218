import React from 'react';
import { ResultModalMode, TemplateFormMode } from '../components/pages/Correction/constants';
import { UseLargeState } from '../hooks/useLargeState';
import { LargeStateProviderProps } from '../interfaces/utils';
import { createSafeContext } from '../utils/functions';

import type {
  NgAndOtherHistoryDetailOutputResponse,
  ApplyInfoDisplayOutputResponse,
  CorrectApi,
  CorrectEnqueteApi,
  CorrectionCheckDisplayOutputResponse,
  CorrectionNGListOutputResponse,
  CorrectionTemplateApi,
  CorrectionTemplateListOutputResponse,
  CorrectReceiptApi,
  DisplayPointOutputResponse,
  EnqueteCorrectDisplayControlOutputResponse,
  EnqueteDisplayOutputResponse,
  EscalationInfoDisplayOutputResponse,
  NoteApi,
  NoteDisplayOutputResponse,
  NoteListOutputResponse,
  NoteVisibleRangeTypeListOutputResponse,
  NoteVisibleTargetTypeListOutputResponse,
  ReceiptCorrectDisplayControlOutputResponse,
  ReceiptInfoDisplayOutputResponse,
  ReceiptNoDuplicateCheckOutputResponse,
  RejectInfoDisplayResultOutputResponse,
  CorrectionStatusDisplayOutputResponse,
  CorrectionHistoryDisplayOutputResponse,
  EnqueteAnswerImageListFormResponse,
} from '../api-client';
import { LoadingSpinner } from '../components/molecules/Loading/LoadingSpinner';
import { EnqueteAnswerListFormResponse } from '../api-client';

export interface CommentFormState {
  dp: Partial<DisplayPointOutputResponse>;
  tempId?: number;
  content: string;
  shopReviewDisplayFlg?: boolean;
}

export interface SelectEditFormState {
  dp: Partial<DisplayPointOutputResponse>;
  selectionIds?: number[];
}

export interface ImageEditFormState {
  editImageIds?: EnqueteAnswerListFormResponse[];
}

export interface ReceiptOkForm {
  purchaseDate?: string;
  noPurchaseTimeFlag?: boolean;
  purchaseTime?: string;
  purchaseAmount?: number;
  noReceiptNoFlag?: boolean;
  receiptNo?: string;
  attentionContent?: string;
}

export interface CorrectionPageState {
  // 共通 ---------------------------------

  isLoading: boolean;

  isEnquete: boolean;

  applyId: number;

  api_correct: CorrectApi;
  res_correctionNgList?: CorrectionNGListOutputResponse[];
  res_answerAndNgHistoryDetail?: NgAndOtherHistoryDetailOutputResponse[];
  res_escalationInfoDisplay?: EscalationInfoDisplayOutputResponse[];
  res_applyInfoDisplay?: ApplyInfoDisplayOutputResponse;
  res_rejectInfoDisplay?: RejectInfoDisplayResultOutputResponse;
  res_receiptInfoDisplay?: ReceiptInfoDisplayOutputResponse;
  res_correctionCheckDisplay?: CorrectionCheckDisplayOutputResponse;
  res_enqueteDisplay?: EnqueteDisplayOutputResponse[];
  res_correctionHistoryDisplay?: CorrectionHistoryDisplayOutputResponse[];

  api_correctionTemplate: CorrectionTemplateApi;
  res_noteTemplateList?: CorrectionTemplateListOutputResponse[];
  res_ngTemplateList?: CorrectionTemplateListOutputResponse[];
  res_rejectTemplateList?: CorrectionTemplateListOutputResponse[];
  res_escalationTemplateList?: CorrectionTemplateListOutputResponse[];
  res_answerTemplateList?: CorrectionTemplateListOutputResponse[];
  res_correctionStatus?: CorrectionStatusDisplayOutputResponse;

  api_note: NoteApi;
  res_noteDisplay?: NoteDisplayOutputResponse;
  enum_noteVisibleRangeType?: NoteVisibleRangeTypeListOutputResponse[];
  enum_noteVisibleTargetType?: NoteVisibleTargetTypeListOutputResponse[];

  isNoteModal: boolean;
  templateFormMode?: TemplateFormMode;
  form_editNote?: Partial<NoteListOutputResponse>;

  isNGHistoryModal: boolean;
  titleForNGHistoryModal?: string;
  dpForNGHistoryModal?: Partial<DisplayPointOutputResponse>;

  commentForms_ng?: CommentFormState[];
  commentForms_escale?: CommentFormState[];

  debug_forceEscalateAnswer?: boolean;

  isResultModal: boolean;
  resultFormMode?: ResultModalMode;

  // レシート添削 ---------------------------------

  api_correctReceipt: CorrectReceiptApi;
  res_correctReceiptDisplayControl?: ReceiptCorrectDisplayControlOutputResponse;

  isEnqueteModal?: boolean;
  isReceiptInfoModal?: boolean;
  isOkReleaseModal?: boolean;

  receiptForm_tellCheck?: {
    result?: boolean;
    checkbox?: boolean;
    phoneNo?: string;
  };
  receiptForm_dateCheck?: {
    // ConstantのVISIT_AT_CHECK_RESULT_TYPE
    result?: number;
    date?: string;
    checkbox?: boolean;
    time?: string;
    errorMessage?: string;
    forceOkFlg?: boolean;
  };
  receiptForm_receiptCheck?: {
    result?: boolean;
    checkbox?: boolean;
    receiptNo?: string;
    forceOkFlg?: boolean;
    errorPhotoList?: ReceiptNoDuplicateCheckOutputResponse[];
  };

  // アンケート添削 ---------------------------------

  api_correctEnquete: CorrectEnqueteApi;
  res_correctEnqueteDisplayControl?: EnqueteCorrectDisplayControlOutputResponse;

  commentForms_enqueteText?: CommentFormState[];
  selectedForms?: SelectEditFormState[];
  imageForms?: ImageEditFormState;

  saveAnswer?: () => Promise<boolean | undefined>;
}

export const CorrectionContext = createSafeContext<{
  largeState: UseLargeState<CorrectionPageState>;
}>();

export const CorrectionProvider: React.FC<LargeStateProviderProps<CorrectionPageState>> = ({
  largeState,
  children,
}) => {
  return (
    <CorrectionContext.Provider value={{ largeState }}>
      <LoadingSpinner isLoading={largeState.state.isLoading}>{children}</LoadingSpinner>
    </CorrectionContext.Provider>
  );
};
