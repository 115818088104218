/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AdministratedCookieSaveFormResponse } from '../domains';
// @ts-ignore
import { AgentLoginDisplayOutputResponse } from '../domains';
// @ts-ignore
import { ApplyHistoryListDisplayFormResponse } from '../domains';
// @ts-ignore
import { ApplyHistoryListDisplayOutputResponse } from '../domains';
// @ts-ignore
import { ApplyInfoOutputResponse } from '../domains';
// @ts-ignore
import { ApplyStatusListOutputResponse } from '../domains';
// @ts-ignore
import { AuthHistoryListDisplayOutputResponse } from '../domains';
// @ts-ignore
import { AuthHistorySaveFormResponse } from '../domains';
// @ts-ignore
import { CustomerInCompanyMemoEditConfirmedFormResponse } from '../domains';
// @ts-ignore
import { CustomerInformationChangeHistoryDisplayOutputResponse } from '../domains';
// @ts-ignore
import { CustomerInformationDetailDisplayOutputResponse } from '../domains';
// @ts-ignore
import { CustomerInformationEditConfirmFormResponse } from '../domains';
// @ts-ignore
import { CustomerInformationListDisplayFormResponse } from '../domains';
// @ts-ignore
import { CustomerInformationListDisplayOutputResponse } from '../domains';
// @ts-ignore
import { CustomerMoralEditConfirmedFormResponse } from '../domains';
// @ts-ignore
import { CustomerStatusEditConfirmFormResponse } from '../domains';
// @ts-ignore
import { GetCustomerIdByApplyIdOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { LoginHistoryDisplayOutputResponse } from '../domains';
// @ts-ignore
import { MailAddressEditConfirmedFormResponse } from '../domains';
// @ts-ignore
import { MailHistoryOemDisplayOutputResponse } from '../domains';
// @ts-ignore
import { MailHistoryRoiDisplayOutputResponse } from '../domains';
// @ts-ignore
import { MailTypeListOutputResponse } from '../domains';
// @ts-ignore
import { MoralInfectionExecuteFormResponse } from '../domains';
// @ts-ignore
import { MoralListOutputResponse } from '../domains';
// @ts-ignore
import { OemFeeListOutputResponse } from '../domains';
// @ts-ignore
import { OemListOutputResponse } from '../domains';
// @ts-ignore
import { PageableResponse } from '../domains';
// @ts-ignore
import { PhoneNumberConfirmFormResponse } from '../domains';
// @ts-ignore
import { RelatedCustomerListSearchFormResponse } from '../domains';
// @ts-ignore
import { RelatedCustomerListSearchOutputResponse } from '../domains';
// @ts-ignore
import { ResultOutputResponse } from '../domains';
// @ts-ignore
import { SmsAuthManualSendFormResponse } from '../domains';
// @ts-ignore
import { SmsAuthManualSendOutputResponse } from '../domains';
// @ts-ignore
import { SubmissionStatusListOutputResponse } from '../domains';
// @ts-ignore
import { WinLostHistoryOutputResponse } from '../domains';
/**
 * CustomerInfoApi - axios parameter creator
 * @export
 */
export const CustomerInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 管理画面ログインを行った端末のCookieを保存するAPI
         * @summary 管理画面ログインを行った端末のCookieを保存
         * @param {AdministratedCookieSaveFormResponse} administratedCookieSaveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratedCookieSave: async (administratedCookieSaveFormResponse: AdministratedCookieSaveFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'administratedCookieSaveFormResponse' is not null or undefined
            assertParamExists('administratedCookieSave', 'administratedCookieSaveFormResponse', administratedCookieSaveFormResponse)
            const localVarPath = `/api/administrated-cookie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(administratedCookieSaveFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 成り代わりログイン画面を表示するAPI
         * @summary 成り代わりログイン画面表示
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentLoginDisplay: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('agentLoginDisplay', 'customerId', customerId)
            const localVarPath = `/api/agent-login/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報詳細の応募履歴一覧表示を行うAPI
         * @summary 会員情報詳細　応募履歴一覧表示
         * @param {number} customerId 
         * @param {ApplyHistoryListDisplayFormResponse} applyHistoryListDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyHistoryListDisplay: async (customerId: number, applyHistoryListDisplayFormResponse: ApplyHistoryListDisplayFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('applyHistoryListDisplay', 'customerId', customerId)
            // verify required parameter 'applyHistoryListDisplayFormResponse' is not null or undefined
            assertParamExists('applyHistoryListDisplay', 'applyHistoryListDisplayFormResponse', applyHistoryListDisplayFormResponse)
            const localVarPath = `/api/apply-history-list/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applyHistoryListDisplayFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報画面に使用する応募ステータス一覧API
         * @summary 応募ステータス一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyStatusList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/apply-status/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報の認証履歴一覧を表示するAPI
         * @summary 会員情報詳細　認証履歴一覧表示
         * @param {number} customerId 
         * @param {PageableResponse} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHistoryListDisplay: async (customerId: number, pageable?: PageableResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('authHistoryListDisplay', 'customerId', customerId)
            const localVarPath = `/api/auth-history/list/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報の認証履歴を保存、更新するAPI
         * @summary 会員情報詳細　認証履歴保存
         * @param {AuthHistorySaveFormResponse} authHistorySaveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHistorySave: async (authHistorySaveFormResponse: AuthHistorySaveFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authHistorySaveFormResponse' is not null or undefined
            assertParamExists('authHistorySave', 'authHistorySaveFormResponse', authHistorySaveFormResponse)
            const localVarPath = `/api/auth-history-save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authHistorySaveFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員社内メモを保存するAPI
         * @summary 会員社内メモ保存
         * @param {CustomerInCompanyMemoEditConfirmedFormResponse} customerInCompanyMemoEditConfirmedFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInCompanyMemoEditConfirmed: async (customerInCompanyMemoEditConfirmedFormResponse: CustomerInCompanyMemoEditConfirmedFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerInCompanyMemoEditConfirmedFormResponse' is not null or undefined
            assertParamExists('customerInCompanyMemoEditConfirmed', 'customerInCompanyMemoEditConfirmedFormResponse', customerInCompanyMemoEditConfirmedFormResponse)
            const localVarPath = `/api/customer-memo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerInCompanyMemoEditConfirmedFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報の応募詳細を表示するAPI
         * @summary 会員情報詳細　応募詳細表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationApplyInfo: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('customerInformationApplyInfo', 'applyId', applyId)
            const localVarPath = `/api/apply/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報の変更履歴を表示するAPI
         * @summary 会員情報詳細　会員変更履歴表示
         * @param {number} customerId 
         * @param {PageableResponse} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationChangeHistoryDisplay: async (customerId: number, pageable?: PageableResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerInformationChangeHistoryDisplay', 'customerId', customerId)
            const localVarPath = `/api/customer-edit-history/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報詳細の会員情報表示を行う
         * @summary 会員情報詳細会員情報表示
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationDetailDisplay: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('customerInformationDetailDisplay', 'customerId', customerId)
            const localVarPath = `/api/customer-info/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報を編集するAPI
         * @summary 会員情報詳細　会員情報編集確定
         * @param {CustomerInformationEditConfirmFormResponse} customerInformationEditConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationEditConfirm: async (customerInformationEditConfirmFormResponse: CustomerInformationEditConfirmFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerInformationEditConfirmFormResponse' is not null or undefined
            assertParamExists('customerInformationEditConfirm', 'customerInformationEditConfirmFormResponse', customerInformationEditConfirmFormResponse)
            const localVarPath = `/api/customer-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerInformationEditConfirmFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報一覧を画面表示する
         * @summary 会員情報一覧　画面表示
         * @param {CustomerInformationListDisplayFormResponse} customerInformationListDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationListDisplay: async (customerInformationListDisplayFormResponse: CustomerInformationListDisplayFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerInformationListDisplayFormResponse' is not null or undefined
            assertParamExists('customerInformationListDisplay', 'customerInformationListDisplayFormResponse', customerInformationListDisplayFormResponse)
            const localVarPath = `/api/customer-info/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerInformationListDisplayFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員のモラル更新をするAPI
         * @summary モラル更新
         * @param {CustomerMoralEditConfirmedFormResponse} customerMoralEditConfirmedFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoralEditConfirmed: async (customerMoralEditConfirmedFormResponse: CustomerMoralEditConfirmedFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerMoralEditConfirmedFormResponse' is not null or undefined
            assertParamExists('customerMoralEditConfirmed', 'customerMoralEditConfirmedFormResponse', customerMoralEditConfirmedFormResponse)
            const localVarPath = `/api/customer-moral`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerMoralEditConfirmedFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員ステータスの更新を行う
         * @summary 会員ステータス更新
         * @param {CustomerStatusEditConfirmFormResponse} customerStatusEditConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerStatusEditConfirm: async (customerStatusEditConfirmFormResponse: CustomerStatusEditConfirmFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerStatusEditConfirmFormResponse' is not null or undefined
            assertParamExists('customerStatusEditConfirm', 'customerStatusEditConfirmFormResponse', customerStatusEditConfirmFormResponse)
            const localVarPath = `/api/customer-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerStatusEditConfirmFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 応募IDから会員IDを取得するAPI
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerIdByApplyId: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('getCustomerIdByApplyId', 'applyId', applyId)
            const localVarPath = `/api/customer-id/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員のログイン履歴を表示するAPI
         * @summary 会員情報詳細　ログイン履歴表示
         * @param {number} customerId 
         * @param {PageableResponse} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginHistoryDisplay: async (customerId: number, pageable?: PageableResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('loginHistoryDisplay', 'customerId', customerId)
            const localVarPath = `/api/login-history/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (pageable !== undefined) {
                localVarQueryParameter['pageable'] = pageable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員のメールアドレスを更新するAPI
         * @summary メールアドレス更新
         * @param {MailAddressEditConfirmedFormResponse} mailAddressEditConfirmedFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailAddressEditConfirmed: async (mailAddressEditConfirmedFormResponse: MailAddressEditConfirmedFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailAddressEditConfirmedFormResponse' is not null or undefined
            assertParamExists('mailAddressEditConfirmed', 'mailAddressEditConfirmedFormResponse', mailAddressEditConfirmedFormResponse)
            const localVarPath = `/api/customer-mail-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailAddressEditConfirmedFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報でOEMのメール履歴を表示するAPI
         * @summary 会員情報詳細　メール履歴（OEM）表示
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [customerId] 
         * @param {number} [messageType] 
         * @param {number} [apiclientId] 
         * @param {number} [applyId] 
         * @param {number} [id] 
         * @param {number} [sendStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailHistoryOemDisplay: async (pageNum: number, pagePerItems: number, customerId?: number, messageType?: number, apiclientId?: number, applyId?: number, id?: number, sendStatus?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNum' is not null or undefined
            assertParamExists('mailHistoryOemDisplay', 'pageNum', pageNum)
            // verify required parameter 'pagePerItems' is not null or undefined
            assertParamExists('mailHistoryOemDisplay', 'pagePerItems', pagePerItems)
            const localVarPath = `/api/oem-mail-history/list/{customer-id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customer-id'] = customerId;
            }

            if (messageType !== undefined) {
                localVarQueryParameter['message-type'] = messageType;
            }

            if (apiclientId !== undefined) {
                localVarQueryParameter['apiclient-id'] = apiclientId;
            }

            if (applyId !== undefined) {
                localVarQueryParameter['apply-id'] = applyId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (sendStatus !== undefined) {
                localVarQueryParameter['send-status'] = sendStatus;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pagePerItems !== undefined) {
                localVarQueryParameter['pagePerItems'] = pagePerItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報詳細のメール履歴（ROI）の表示API
         * @summary 会員情報詳細　メール履歴（ROI）表示
         * @param {number} customerId 
         * @param {number} [mailTypeCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailHistoryRoiDisplay: async (customerId: number, mailTypeCode?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('mailHistoryRoiDisplay', 'customerId', customerId)
            const localVarPath = `/api/roi-mail-history/list/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (mailTypeCode !== undefined) {
                localVarQueryParameter['mail-type-code'] = mailTypeCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報のメール種類一覧を表示するAPI
         * @summary メール種類一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mail-type/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モラル感染及び波及処理の実行を行う
         * @summary モラル感染波及処理実行
         * @param {MoralInfectionExecuteFormResponse} moralInfectionExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moralInfectionExecute: async (moralInfectionExecuteFormResponse: MoralInfectionExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'moralInfectionExecuteFormResponse' is not null or undefined
            assertParamExists('moralInfectionExecute', 'moralInfectionExecuteFormResponse', moralInfectionExecuteFormResponse)
            const localVarPath = `/api/moral-infection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(moralInfectionExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報のモラル一覧を表示するAPI
         * @summary モラル一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moralList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/moral/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary OEMユーザの報酬のリストを取得する
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemFeeList: async (customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('oemFeeList', 'customerId', customerId)
            const localVarPath = `/api/oem-fee/list/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報詳細でOEM一覧を表示するAPI
         * @summary OEM一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oem/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 電話番号を確認するAPI
         * @summary 電話番号確認
         * @param {PhoneNumberConfirmFormResponse} phoneNumberConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneNumberConfirm: async (phoneNumberConfirmFormResponse: PhoneNumberConfirmFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumberConfirmFormResponse' is not null or undefined
            assertParamExists('phoneNumberConfirm', 'phoneNumberConfirmFormResponse', phoneNumberConfirmFormResponse)
            const localVarPath = `/api/customer-info/phone-number-confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(phoneNumberConfirmFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報の関連会員一覧検索を行うAPI
         * @summary 会員情報詳細　関連会員一覧検索
         * @param {RelatedCustomerListSearchFormResponse} relatedCustomerListSearchFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relatedCustomerListSearch: async (relatedCustomerListSearchFormResponse: RelatedCustomerListSearchFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'relatedCustomerListSearchFormResponse' is not null or undefined
            assertParamExists('relatedCustomerListSearch', 'relatedCustomerListSearchFormResponse', relatedCustomerListSearchFormResponse)
            const localVarPath = `/api/related-customer/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(relatedCustomerListSearchFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報のSMS認証を手動送信するAPI
         * @summary 会員情報詳細　SMS認証手動送信
         * @param {SmsAuthManualSendFormResponse} smsAuthManualSendFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsAuthManualSend: async (smsAuthManualSendFormResponse: SmsAuthManualSendFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsAuthManualSendFormResponse' is not null or undefined
            assertParamExists('smsAuthManualSend', 'smsAuthManualSendFormResponse', smsAuthManualSendFormResponse)
            const localVarPath = `/api/sms-auth-manual-send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(smsAuthManualSendFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報の提出ステータス一覧を表示するAPI
         * @summary 提出ステータス一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionStatusList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/submission-status/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員情報詳細の当選落選履歴を表示するAPI
         * @summary 会員情報詳細　当選落選履歴
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        winLostHistory: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('winLostHistory', 'applyId', applyId)
            const localVarPath = `/api/win-lost-history/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerInfoApi - functional programming interface
 * @export
 */
export const CustomerInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * 管理画面ログインを行った端末のCookieを保存するAPI
         * @summary 管理画面ログインを行った端末のCookieを保存
         * @param {AdministratedCookieSaveFormResponse} administratedCookieSaveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratedCookieSave(administratedCookieSaveFormResponse: AdministratedCookieSaveFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratedCookieSave(administratedCookieSaveFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 成り代わりログイン画面を表示するAPI
         * @summary 成り代わりログイン画面表示
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async agentLoginDisplay(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AgentLoginDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.agentLoginDisplay(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報詳細の応募履歴一覧表示を行うAPI
         * @summary 会員情報詳細　応募履歴一覧表示
         * @param {number} customerId 
         * @param {ApplyHistoryListDisplayFormResponse} applyHistoryListDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyHistoryListDisplay(customerId: number, applyHistoryListDisplayFormResponse: ApplyHistoryListDisplayFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplyHistoryListDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyHistoryListDisplay(customerId, applyHistoryListDisplayFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報画面に使用する応募ステータス一覧API
         * @summary 応募ステータス一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyStatusList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApplyStatusListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyStatusList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報の認証履歴一覧を表示するAPI
         * @summary 会員情報詳細　認証履歴一覧表示
         * @param {number} customerId 
         * @param {PageableResponse} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authHistoryListDisplay(customerId: number, pageable?: PageableResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuthHistoryListDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authHistoryListDisplay(customerId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報の認証履歴を保存、更新するAPI
         * @summary 会員情報詳細　認証履歴保存
         * @param {AuthHistorySaveFormResponse} authHistorySaveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authHistorySave(authHistorySaveFormResponse: AuthHistorySaveFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authHistorySave(authHistorySaveFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員社内メモを保存するAPI
         * @summary 会員社内メモ保存
         * @param {CustomerInCompanyMemoEditConfirmedFormResponse} customerInCompanyMemoEditConfirmedFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerInCompanyMemoEditConfirmed(customerInCompanyMemoEditConfirmedFormResponse: CustomerInCompanyMemoEditConfirmedFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerInCompanyMemoEditConfirmed(customerInCompanyMemoEditConfirmedFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報の応募詳細を表示するAPI
         * @summary 会員情報詳細　応募詳細表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerInformationApplyInfo(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplyInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerInformationApplyInfo(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報の変更履歴を表示するAPI
         * @summary 会員情報詳細　会員変更履歴表示
         * @param {number} customerId 
         * @param {PageableResponse} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerInformationChangeHistoryDisplay(customerId: number, pageable?: PageableResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerInformationChangeHistoryDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerInformationChangeHistoryDisplay(customerId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報詳細の会員情報表示を行う
         * @summary 会員情報詳細会員情報表示
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerInformationDetailDisplay(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerInformationDetailDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerInformationDetailDisplay(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報を編集するAPI
         * @summary 会員情報詳細　会員情報編集確定
         * @param {CustomerInformationEditConfirmFormResponse} customerInformationEditConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerInformationEditConfirm(customerInformationEditConfirmFormResponse: CustomerInformationEditConfirmFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerInformationEditConfirm(customerInformationEditConfirmFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報一覧を画面表示する
         * @summary 会員情報一覧　画面表示
         * @param {CustomerInformationListDisplayFormResponse} customerInformationListDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerInformationListDisplay(customerInformationListDisplayFormResponse: CustomerInformationListDisplayFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerInformationListDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerInformationListDisplay(customerInformationListDisplayFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員のモラル更新をするAPI
         * @summary モラル更新
         * @param {CustomerMoralEditConfirmedFormResponse} customerMoralEditConfirmedFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerMoralEditConfirmed(customerMoralEditConfirmedFormResponse: CustomerMoralEditConfirmedFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerMoralEditConfirmed(customerMoralEditConfirmedFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員ステータスの更新を行う
         * @summary 会員ステータス更新
         * @param {CustomerStatusEditConfirmFormResponse} customerStatusEditConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async customerStatusEditConfirm(customerStatusEditConfirmFormResponse: CustomerStatusEditConfirmFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.customerStatusEditConfirm(customerStatusEditConfirmFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 応募IDから会員IDを取得するAPI
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerIdByApplyId(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCustomerIdByApplyIdOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerIdByApplyId(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員のログイン履歴を表示するAPI
         * @summary 会員情報詳細　ログイン履歴表示
         * @param {number} customerId 
         * @param {PageableResponse} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginHistoryDisplay(customerId: number, pageable?: PageableResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LoginHistoryDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginHistoryDisplay(customerId, pageable, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員のメールアドレスを更新するAPI
         * @summary メールアドレス更新
         * @param {MailAddressEditConfirmedFormResponse} mailAddressEditConfirmedFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailAddressEditConfirmed(mailAddressEditConfirmedFormResponse: MailAddressEditConfirmedFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailAddressEditConfirmed(mailAddressEditConfirmedFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報でOEMのメール履歴を表示するAPI
         * @summary 会員情報詳細　メール履歴（OEM）表示
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [customerId] 
         * @param {number} [messageType] 
         * @param {number} [apiclientId] 
         * @param {number} [applyId] 
         * @param {number} [id] 
         * @param {number} [sendStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailHistoryOemDisplay(pageNum: number, pagePerItems: number, customerId?: number, messageType?: number, apiclientId?: number, applyId?: number, id?: number, sendStatus?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailHistoryOemDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailHistoryOemDisplay(pageNum, pagePerItems, customerId, messageType, apiclientId, applyId, id, sendStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報詳細のメール履歴（ROI）の表示API
         * @summary 会員情報詳細　メール履歴（ROI）表示
         * @param {number} customerId 
         * @param {number} [mailTypeCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailHistoryRoiDisplay(customerId: number, mailTypeCode?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MailHistoryRoiDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailHistoryRoiDisplay(customerId, mailTypeCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報のメール種類一覧を表示するAPI
         * @summary メール種類一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MailTypeListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モラル感染及び波及処理の実行を行う
         * @summary モラル感染波及処理実行
         * @param {MoralInfectionExecuteFormResponse} moralInfectionExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moralInfectionExecute(moralInfectionExecuteFormResponse: MoralInfectionExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moralInfectionExecute(moralInfectionExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報のモラル一覧を表示するAPI
         * @summary モラル一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moralList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MoralListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moralList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary OEMユーザの報酬のリストを取得する
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemFeeList(customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OemFeeListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemFeeList(customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報詳細でOEM一覧を表示するAPI
         * @summary OEM一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OemListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 電話番号を確認するAPI
         * @summary 電話番号確認
         * @param {PhoneNumberConfirmFormResponse} phoneNumberConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async phoneNumberConfirm(phoneNumberConfirmFormResponse: PhoneNumberConfirmFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.phoneNumberConfirm(phoneNumberConfirmFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報の関連会員一覧検索を行うAPI
         * @summary 会員情報詳細　関連会員一覧検索
         * @param {RelatedCustomerListSearchFormResponse} relatedCustomerListSearchFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async relatedCustomerListSearch(relatedCustomerListSearchFormResponse: RelatedCustomerListSearchFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RelatedCustomerListSearchOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.relatedCustomerListSearch(relatedCustomerListSearchFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報のSMS認証を手動送信するAPI
         * @summary 会員情報詳細　SMS認証手動送信
         * @param {SmsAuthManualSendFormResponse} smsAuthManualSendFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smsAuthManualSend(smsAuthManualSendFormResponse: SmsAuthManualSendFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SmsAuthManualSendOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smsAuthManualSend(smsAuthManualSendFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報の提出ステータス一覧を表示するAPI
         * @summary 提出ステータス一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionStatusList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SubmissionStatusListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionStatusList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員情報詳細の当選落選履歴を表示するAPI
         * @summary 会員情報詳細　当選落選履歴
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async winLostHistory(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WinLostHistoryOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.winLostHistory(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerInfoApi - factory interface
 * @export
 */
export const CustomerInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerInfoApiFp(configuration)
    return {
        /**
         * 管理画面ログインを行った端末のCookieを保存するAPI
         * @summary 管理画面ログインを行った端末のCookieを保存
         * @param {AdministratedCookieSaveFormResponse} administratedCookieSaveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratedCookieSave(administratedCookieSaveFormResponse: AdministratedCookieSaveFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.administratedCookieSave(administratedCookieSaveFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 成り代わりログイン画面を表示するAPI
         * @summary 成り代わりログイン画面表示
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        agentLoginDisplay(customerId: number, options?: any): AxiosPromise<AgentLoginDisplayOutputResponse> {
            return localVarFp.agentLoginDisplay(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報詳細の応募履歴一覧表示を行うAPI
         * @summary 会員情報詳細　応募履歴一覧表示
         * @param {number} customerId 
         * @param {ApplyHistoryListDisplayFormResponse} applyHistoryListDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyHistoryListDisplay(customerId: number, applyHistoryListDisplayFormResponse: ApplyHistoryListDisplayFormResponse, options?: any): AxiosPromise<Array<ApplyHistoryListDisplayOutputResponse>> {
            return localVarFp.applyHistoryListDisplay(customerId, applyHistoryListDisplayFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報画面に使用する応募ステータス一覧API
         * @summary 応募ステータス一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyStatusList(options?: any): AxiosPromise<Array<ApplyStatusListOutputResponse>> {
            return localVarFp.applyStatusList(options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報の認証履歴一覧を表示するAPI
         * @summary 会員情報詳細　認証履歴一覧表示
         * @param {number} customerId 
         * @param {PageableResponse} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHistoryListDisplay(customerId: number, pageable?: PageableResponse, options?: any): AxiosPromise<Array<AuthHistoryListDisplayOutputResponse>> {
            return localVarFp.authHistoryListDisplay(customerId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報の認証履歴を保存、更新するAPI
         * @summary 会員情報詳細　認証履歴保存
         * @param {AuthHistorySaveFormResponse} authHistorySaveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authHistorySave(authHistorySaveFormResponse: AuthHistorySaveFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.authHistorySave(authHistorySaveFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員社内メモを保存するAPI
         * @summary 会員社内メモ保存
         * @param {CustomerInCompanyMemoEditConfirmedFormResponse} customerInCompanyMemoEditConfirmedFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInCompanyMemoEditConfirmed(customerInCompanyMemoEditConfirmedFormResponse: CustomerInCompanyMemoEditConfirmedFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.customerInCompanyMemoEditConfirmed(customerInCompanyMemoEditConfirmedFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報の応募詳細を表示するAPI
         * @summary 会員情報詳細　応募詳細表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationApplyInfo(applyId: number, options?: any): AxiosPromise<ApplyInfoOutputResponse> {
            return localVarFp.customerInformationApplyInfo(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報の変更履歴を表示するAPI
         * @summary 会員情報詳細　会員変更履歴表示
         * @param {number} customerId 
         * @param {PageableResponse} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationChangeHistoryDisplay(customerId: number, pageable?: PageableResponse, options?: any): AxiosPromise<Array<CustomerInformationChangeHistoryDisplayOutputResponse>> {
            return localVarFp.customerInformationChangeHistoryDisplay(customerId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報詳細の会員情報表示を行う
         * @summary 会員情報詳細会員情報表示
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationDetailDisplay(customerId: number, options?: any): AxiosPromise<CustomerInformationDetailDisplayOutputResponse> {
            return localVarFp.customerInformationDetailDisplay(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報を編集するAPI
         * @summary 会員情報詳細　会員情報編集確定
         * @param {CustomerInformationEditConfirmFormResponse} customerInformationEditConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationEditConfirm(customerInformationEditConfirmFormResponse: CustomerInformationEditConfirmFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.customerInformationEditConfirm(customerInformationEditConfirmFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報一覧を画面表示する
         * @summary 会員情報一覧　画面表示
         * @param {CustomerInformationListDisplayFormResponse} customerInformationListDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerInformationListDisplay(customerInformationListDisplayFormResponse: CustomerInformationListDisplayFormResponse, options?: any): AxiosPromise<Array<CustomerInformationListDisplayOutputResponse>> {
            return localVarFp.customerInformationListDisplay(customerInformationListDisplayFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員のモラル更新をするAPI
         * @summary モラル更新
         * @param {CustomerMoralEditConfirmedFormResponse} customerMoralEditConfirmedFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerMoralEditConfirmed(customerMoralEditConfirmedFormResponse: CustomerMoralEditConfirmedFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.customerMoralEditConfirmed(customerMoralEditConfirmedFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員ステータスの更新を行う
         * @summary 会員ステータス更新
         * @param {CustomerStatusEditConfirmFormResponse} customerStatusEditConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        customerStatusEditConfirm(customerStatusEditConfirmFormResponse: CustomerStatusEditConfirmFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.customerStatusEditConfirm(customerStatusEditConfirmFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 応募IDから会員IDを取得するAPI
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerIdByApplyId(applyId: number, options?: any): AxiosPromise<GetCustomerIdByApplyIdOutputResponse> {
            return localVarFp.getCustomerIdByApplyId(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員のログイン履歴を表示するAPI
         * @summary 会員情報詳細　ログイン履歴表示
         * @param {number} customerId 
         * @param {PageableResponse} [pageable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginHistoryDisplay(customerId: number, pageable?: PageableResponse, options?: any): AxiosPromise<Array<LoginHistoryDisplayOutputResponse>> {
            return localVarFp.loginHistoryDisplay(customerId, pageable, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員のメールアドレスを更新するAPI
         * @summary メールアドレス更新
         * @param {MailAddressEditConfirmedFormResponse} mailAddressEditConfirmedFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailAddressEditConfirmed(mailAddressEditConfirmedFormResponse: MailAddressEditConfirmedFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.mailAddressEditConfirmed(mailAddressEditConfirmedFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報でOEMのメール履歴を表示するAPI
         * @summary 会員情報詳細　メール履歴（OEM）表示
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [customerId] 
         * @param {number} [messageType] 
         * @param {number} [apiclientId] 
         * @param {number} [applyId] 
         * @param {number} [id] 
         * @param {number} [sendStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailHistoryOemDisplay(pageNum: number, pagePerItems: number, customerId?: number, messageType?: number, apiclientId?: number, applyId?: number, id?: number, sendStatus?: number, options?: any): AxiosPromise<MailHistoryOemDisplayOutputResponse> {
            return localVarFp.mailHistoryOemDisplay(pageNum, pagePerItems, customerId, messageType, apiclientId, applyId, id, sendStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報詳細のメール履歴（ROI）の表示API
         * @summary 会員情報詳細　メール履歴（ROI）表示
         * @param {number} customerId 
         * @param {number} [mailTypeCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailHistoryRoiDisplay(customerId: number, mailTypeCode?: number, options?: any): AxiosPromise<Array<MailHistoryRoiDisplayOutputResponse>> {
            return localVarFp.mailHistoryRoiDisplay(customerId, mailTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報のメール種類一覧を表示するAPI
         * @summary メール種類一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailTypeList(options?: any): AxiosPromise<Array<MailTypeListOutputResponse>> {
            return localVarFp.mailTypeList(options).then((request) => request(axios, basePath));
        },
        /**
         * モラル感染及び波及処理の実行を行う
         * @summary モラル感染波及処理実行
         * @param {MoralInfectionExecuteFormResponse} moralInfectionExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moralInfectionExecute(moralInfectionExecuteFormResponse: MoralInfectionExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.moralInfectionExecute(moralInfectionExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報のモラル一覧を表示するAPI
         * @summary モラル一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moralList(options?: any): AxiosPromise<Array<MoralListOutputResponse>> {
            return localVarFp.moralList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary OEMユーザの報酬のリストを取得する
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemFeeList(customerId: number, options?: any): AxiosPromise<Array<OemFeeListOutputResponse>> {
            return localVarFp.oemFeeList(customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報詳細でOEM一覧を表示するAPI
         * @summary OEM一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemList(options?: any): AxiosPromise<Array<OemListOutputResponse>> {
            return localVarFp.oemList(options).then((request) => request(axios, basePath));
        },
        /**
         * 電話番号を確認するAPI
         * @summary 電話番号確認
         * @param {PhoneNumberConfirmFormResponse} phoneNumberConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        phoneNumberConfirm(phoneNumberConfirmFormResponse: PhoneNumberConfirmFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.phoneNumberConfirm(phoneNumberConfirmFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報の関連会員一覧検索を行うAPI
         * @summary 会員情報詳細　関連会員一覧検索
         * @param {RelatedCustomerListSearchFormResponse} relatedCustomerListSearchFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relatedCustomerListSearch(relatedCustomerListSearchFormResponse: RelatedCustomerListSearchFormResponse, options?: any): AxiosPromise<Array<RelatedCustomerListSearchOutputResponse>> {
            return localVarFp.relatedCustomerListSearch(relatedCustomerListSearchFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報のSMS認証を手動送信するAPI
         * @summary 会員情報詳細　SMS認証手動送信
         * @param {SmsAuthManualSendFormResponse} smsAuthManualSendFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsAuthManualSend(smsAuthManualSendFormResponse: SmsAuthManualSendFormResponse, options?: any): AxiosPromise<SmsAuthManualSendOutputResponse> {
            return localVarFp.smsAuthManualSend(smsAuthManualSendFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報の提出ステータス一覧を表示するAPI
         * @summary 提出ステータス一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionStatusList(options?: any): AxiosPromise<Array<SubmissionStatusListOutputResponse>> {
            return localVarFp.submissionStatusList(options).then((request) => request(axios, basePath));
        },
        /**
         * 会員情報詳細の当選落選履歴を表示するAPI
         * @summary 会員情報詳細　当選落選履歴
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        winLostHistory(applyId: number, options?: any): AxiosPromise<Array<WinLostHistoryOutputResponse>> {
            return localVarFp.winLostHistory(applyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerInfoApi - object-oriented interface
 * @export
 * @class CustomerInfoApi
 * @extends {BaseAPI}
 */
export class CustomerInfoApi extends BaseAPI {
    /**
     * 管理画面ログインを行った端末のCookieを保存するAPI
     * @summary 管理画面ログインを行った端末のCookieを保存
     * @param {AdministratedCookieSaveFormResponse} administratedCookieSaveFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public administratedCookieSave(administratedCookieSaveFormResponse: AdministratedCookieSaveFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).administratedCookieSave(administratedCookieSaveFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 成り代わりログイン画面を表示するAPI
     * @summary 成り代わりログイン画面表示
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public agentLoginDisplay(customerId: number, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).agentLoginDisplay(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報詳細の応募履歴一覧表示を行うAPI
     * @summary 会員情報詳細　応募履歴一覧表示
     * @param {number} customerId 
     * @param {ApplyHistoryListDisplayFormResponse} applyHistoryListDisplayFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public applyHistoryListDisplay(customerId: number, applyHistoryListDisplayFormResponse: ApplyHistoryListDisplayFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).applyHistoryListDisplay(customerId, applyHistoryListDisplayFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報画面に使用する応募ステータス一覧API
     * @summary 応募ステータス一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public applyStatusList(options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).applyStatusList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報の認証履歴一覧を表示するAPI
     * @summary 会員情報詳細　認証履歴一覧表示
     * @param {number} customerId 
     * @param {PageableResponse} [pageable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public authHistoryListDisplay(customerId: number, pageable?: PageableResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).authHistoryListDisplay(customerId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報の認証履歴を保存、更新するAPI
     * @summary 会員情報詳細　認証履歴保存
     * @param {AuthHistorySaveFormResponse} authHistorySaveFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public authHistorySave(authHistorySaveFormResponse: AuthHistorySaveFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).authHistorySave(authHistorySaveFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員社内メモを保存するAPI
     * @summary 会員社内メモ保存
     * @param {CustomerInCompanyMemoEditConfirmedFormResponse} customerInCompanyMemoEditConfirmedFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public customerInCompanyMemoEditConfirmed(customerInCompanyMemoEditConfirmedFormResponse: CustomerInCompanyMemoEditConfirmedFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).customerInCompanyMemoEditConfirmed(customerInCompanyMemoEditConfirmedFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報の応募詳細を表示するAPI
     * @summary 会員情報詳細　応募詳細表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public customerInformationApplyInfo(applyId: number, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).customerInformationApplyInfo(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報の変更履歴を表示するAPI
     * @summary 会員情報詳細　会員変更履歴表示
     * @param {number} customerId 
     * @param {PageableResponse} [pageable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public customerInformationChangeHistoryDisplay(customerId: number, pageable?: PageableResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).customerInformationChangeHistoryDisplay(customerId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報詳細の会員情報表示を行う
     * @summary 会員情報詳細会員情報表示
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public customerInformationDetailDisplay(customerId: number, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).customerInformationDetailDisplay(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報を編集するAPI
     * @summary 会員情報詳細　会員情報編集確定
     * @param {CustomerInformationEditConfirmFormResponse} customerInformationEditConfirmFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public customerInformationEditConfirm(customerInformationEditConfirmFormResponse: CustomerInformationEditConfirmFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).customerInformationEditConfirm(customerInformationEditConfirmFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報一覧を画面表示する
     * @summary 会員情報一覧　画面表示
     * @param {CustomerInformationListDisplayFormResponse} customerInformationListDisplayFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public customerInformationListDisplay(customerInformationListDisplayFormResponse: CustomerInformationListDisplayFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).customerInformationListDisplay(customerInformationListDisplayFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員のモラル更新をするAPI
     * @summary モラル更新
     * @param {CustomerMoralEditConfirmedFormResponse} customerMoralEditConfirmedFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public customerMoralEditConfirmed(customerMoralEditConfirmedFormResponse: CustomerMoralEditConfirmedFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).customerMoralEditConfirmed(customerMoralEditConfirmedFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員ステータスの更新を行う
     * @summary 会員ステータス更新
     * @param {CustomerStatusEditConfirmFormResponse} customerStatusEditConfirmFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public customerStatusEditConfirm(customerStatusEditConfirmFormResponse: CustomerStatusEditConfirmFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).customerStatusEditConfirm(customerStatusEditConfirmFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 応募IDから会員IDを取得するAPI
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public getCustomerIdByApplyId(applyId: number, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).getCustomerIdByApplyId(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員のログイン履歴を表示するAPI
     * @summary 会員情報詳細　ログイン履歴表示
     * @param {number} customerId 
     * @param {PageableResponse} [pageable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public loginHistoryDisplay(customerId: number, pageable?: PageableResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).loginHistoryDisplay(customerId, pageable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員のメールアドレスを更新するAPI
     * @summary メールアドレス更新
     * @param {MailAddressEditConfirmedFormResponse} mailAddressEditConfirmedFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public mailAddressEditConfirmed(mailAddressEditConfirmedFormResponse: MailAddressEditConfirmedFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).mailAddressEditConfirmed(mailAddressEditConfirmedFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報でOEMのメール履歴を表示するAPI
     * @summary 会員情報詳細　メール履歴（OEM）表示
     * @param {number} pageNum 
     * @param {number} pagePerItems 
     * @param {number} [customerId] 
     * @param {number} [messageType] 
     * @param {number} [apiclientId] 
     * @param {number} [applyId] 
     * @param {number} [id] 
     * @param {number} [sendStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public mailHistoryOemDisplay(pageNum: number, pagePerItems: number, customerId?: number, messageType?: number, apiclientId?: number, applyId?: number, id?: number, sendStatus?: number, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).mailHistoryOemDisplay(pageNum, pagePerItems, customerId, messageType, apiclientId, applyId, id, sendStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報詳細のメール履歴（ROI）の表示API
     * @summary 会員情報詳細　メール履歴（ROI）表示
     * @param {number} customerId 
     * @param {number} [mailTypeCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public mailHistoryRoiDisplay(customerId: number, mailTypeCode?: number, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).mailHistoryRoiDisplay(customerId, mailTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報のメール種類一覧を表示するAPI
     * @summary メール種類一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public mailTypeList(options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).mailTypeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モラル感染及び波及処理の実行を行う
     * @summary モラル感染波及処理実行
     * @param {MoralInfectionExecuteFormResponse} moralInfectionExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public moralInfectionExecute(moralInfectionExecuteFormResponse: MoralInfectionExecuteFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).moralInfectionExecute(moralInfectionExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報のモラル一覧を表示するAPI
     * @summary モラル一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public moralList(options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).moralList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary OEMユーザの報酬のリストを取得する
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public oemFeeList(customerId: number, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).oemFeeList(customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報詳細でOEM一覧を表示するAPI
     * @summary OEM一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public oemList(options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).oemList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 電話番号を確認するAPI
     * @summary 電話番号確認
     * @param {PhoneNumberConfirmFormResponse} phoneNumberConfirmFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public phoneNumberConfirm(phoneNumberConfirmFormResponse: PhoneNumberConfirmFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).phoneNumberConfirm(phoneNumberConfirmFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報の関連会員一覧検索を行うAPI
     * @summary 会員情報詳細　関連会員一覧検索
     * @param {RelatedCustomerListSearchFormResponse} relatedCustomerListSearchFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public relatedCustomerListSearch(relatedCustomerListSearchFormResponse: RelatedCustomerListSearchFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).relatedCustomerListSearch(relatedCustomerListSearchFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報のSMS認証を手動送信するAPI
     * @summary 会員情報詳細　SMS認証手動送信
     * @param {SmsAuthManualSendFormResponse} smsAuthManualSendFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public smsAuthManualSend(smsAuthManualSendFormResponse: SmsAuthManualSendFormResponse, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).smsAuthManualSend(smsAuthManualSendFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報の提出ステータス一覧を表示するAPI
     * @summary 提出ステータス一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public submissionStatusList(options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).submissionStatusList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員情報詳細の当選落選履歴を表示するAPI
     * @summary 会員情報詳細　当選落選履歴
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerInfoApi
     */
    public winLostHistory(applyId: number, options?: AxiosRequestConfig) {
        return CustomerInfoApiFp(this.configuration).winLostHistory(applyId, options).then((request) => request(this.axios, this.basePath));
    }
}
