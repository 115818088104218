import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplyInfoDisplayOutputResponse, CorrectApi, SurveyProofImageInfoDtoResponse } from '../../../api-client';

export const SurveyProofImageListPage: React.FC = () => {
  const applyId = Number(useParams<{ id: string | undefined }>().id);
  const api = new CorrectApi();
  const [urlList, setUrlList] = useState<Array<SurveyProofImageInfoDtoResponse>>([]);

  useEffect(() => {
    api.applyInfoDisplay(applyId).then((res: AxiosResponse<ApplyInfoDisplayOutputResponse>) => {
      if (res?.data?.surveyProofList) setUrlList(res.data.surveyProofList[0].surveyProofImageInfoDtoList);
    });
  }, []);

  return (
    <div>
      {urlList.map((data) => (
        <a href={data.imageUrl} target="_blank" rel="noreferrer">
          <img src={data.imageUrl} alt="" width="500px" height="500px" />
        </a>
      ))}
    </div>
  );
};
