import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「予約」Contents
export const MonitorRuleSetReserveContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  // 予約ラジオボタン有効無効化フラグ
  const [radioDisableFlg, setRadioDisableFlg] = useState<boolean>(
    data.monitorRuleList.find((_) => {
      return _.monitorRuleType === 5;
    })?.reserveType === 0
  );
  // 「電話予約」かどうか
  const [reserveTypePhoneFlg, setReserveTypePhoneFlg] = useState<boolean>(false);
  // 「WEB予約」かどうか
  const [reserveTypeWEBFlg, setReserveTypeWEBFlg] = useState<boolean>(false);

  useEffect(() => {
    const reserveRules = data.monitorRuleList.find((_) => _.monitorRuleType === 5);
    setRadioDisableFlg(reserveRules?.reserveType === 0);
    setReserveTypePhoneFlg(reserveRules?.reservePhoneFlg || false);
    setReserveTypeWEBFlg(reserveRules?.reserveWebFlg || false);
  }, [data.monitorRuleList]);

  useEffect(() => {
    if (radioDisableFlg) return;
    setData({
      ...data,
      monitorRuleList: data?.monitorRuleList.map((_, i) => {
        return i === index
          ? {
              ..._,
              reservePhoneFlg: false,
              reserveWebFlg: false,
              reservePhoneNumber: undefined,
              reserveURL: undefined,
              samedayReservationsFlg: false,
              dedicatedReservationSiteFlg: false,
            }
          : { ..._ };
      }),
    });
  }, [radioDisableFlg]);

  const chengeReservePhoneCheck = (isReservePhone: boolean) => {
    setReserveTypePhoneFlg(isReservePhone);
    setData({
      ...data,
      monitorRuleList: data?.monitorRuleList.map((_, i) => {
        return i === index
          ? {
              ..._,
              reservePhoneFlg: isReservePhone,
              reservePhoneNumber: !isReservePhone ? undefined : _.reservePhoneNumber,
              samedayReservationsFlg: !isReservePhone ? false : _.samedayReservationsFlg,
            }
          : { ..._ };
      }),
    });
  };

  const changeReserveWebCheck = (isReserveWeb: boolean) => {
    setReserveTypeWEBFlg(isReserveWeb);
    setData({
      ...data,
      monitorRuleList: data?.monitorRuleList.map((_, i) => {
        return i === index
          ? {
              ..._,
              reserveWebFlg: isReserveWeb,
              reserveURL: !isReserveWeb ? undefined : _.reserveURL,
              dedicatedReservationSiteFlg: !isReserveWeb ? false : _.dedicatedReservationSiteFlg,
            }
          : { ..._ };
      }),
    });
  };

  return (
    <>
      <div className="d-flex ">
        <Form.Check id="radio1" className="d-flex">
          <Form.Check.Input
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup03"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                  return i === index ? { ..._, reserveType: Number(e.target.value) } : { ..._ };
                }),
              });
              setRadioDisableFlg(true);
            }}
            value={0}
            checked={
              data.monitorRuleList.find((_) => {
                return _.monitorRuleType === 5;
              })?.reserveType === 0 || false
            }
          />
          <Form.Check.Label>要予約</Form.Check.Label>
        </Form.Check>
      </div>
      <Row>
        <Form.Group className="d-flex align-items-center ms-5 mt-3">
          <Form.Label className="mt-2 me-2">電話予約</Form.Label>
          <Form.Check.Input
            data-testid="reservePhoneCheckBox"
            className="flex-shrink-0 ms-2 me-3"
            type="checkbox"
            name="checkBoxGroup03"
            checked={reserveTypePhoneFlg}
            onChange={() => {
              chengeReservePhoneCheck(!reserveTypePhoneFlg);
            }}
            disabled={!radioDisableFlg}
          />
          <Col className="col-2">
            {/* TODO stringじゃない？ */}
            <Form.Control
              data-testid="reservePhoneText"
              disabled={!(radioDisableFlg && reserveTypePhoneFlg)}
              type="text"
              maxLength={13}
              value={
                data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 5;
                })?.reservePhoneNumber === undefined
                  ? ''
                  : data?.monitorRuleList?.find((r) => {
                      return r.monitorRuleType === 5;
                    })?.reservePhoneNumber || ''
              }
              onChange={(e) => {
                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                    return i === index
                      ? { ..._, reservePhoneNumber: e.target.value === '' ? undefined : e.target.value }
                      : { ..._ };
                  }),
                });
              }}
            />
          </Col>
          （ハイフンありで入力）
          <Form.Label className="mt-2 ms-3" style={{ marginRight: '3.25rem' }}>
            当日不可
          </Form.Label>
          <Form.Check.Input
            data-testid="samedayReservationsCheckBox"
            className="flex-shrink-0"
            type="checkbox"
            name="checkBoxGroup01"
            checked={
              data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 5;
              })?.samedayReservationsFlg === undefined
                ? false
                : data?.monitorRuleList?.find((r) => {
                    return r.monitorRuleType === 5;
                  })?.samedayReservationsFlg || false
            }
            onChange={() => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                  return i === index ? { ..._, samedayReservationsFlg: !_.samedayReservationsFlg } : { ..._ };
                }),
              });
            }}
            disabled={!(radioDisableFlg && reserveTypePhoneFlg)}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="d-flex align-items-center ms-5 mt-3">
          <Form.Label className="mt-2 me-2">WEB予約</Form.Label>
          <Form.Check.Input
            data-testid="reserveWebCheckBox"
            className="flex-shrink-0 ms-2 me-3"
            type="checkbox"
            name="checkBoxGroup01"
            checked={reserveTypeWEBFlg}
            onChange={() => {
              changeReserveWebCheck(!reserveTypeWEBFlg);
            }}
            disabled={!radioDisableFlg}
          />
          <Col className="col-2">
            <Form.Control
              data-testid="reserveWebText"
              disabled={!(radioDisableFlg && reserveTypeWEBFlg)}
              type="text"
              value={
                data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 5;
                })?.reserveURL === undefined
                  ? ''
                  : data?.monitorRuleList?.find((r) => {
                      return r.monitorRuleType === 5;
                    })?.reserveURL || ''
              }
              onChange={(e) => {
                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                    return i === index
                      ? { ..._, reserveURL: e.target.value === '' ? undefined : e.target.value }
                      : { ..._ };
                  }),
                });
              }}
            />
          </Col>
          <Form.Label className="mt-2 me-2" style={{ marginLeft: '10.2rem' }}>
            予約専用サイト
          </Form.Label>
          <Form.Check.Input
            data-testid="dedicatedReservationSiteCheckBox"
            className="flex-shrink-0 ms-2 me-3"
            type="checkbox"
            name="checkBoxGroup01"
            checked={
              data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 5;
              })?.dedicatedReservationSiteFlg === undefined
                ? false
                : data?.monitorRuleList?.find((r) => {
                    return r.monitorRuleType === 5;
                  })?.dedicatedReservationSiteFlg || false
            }
            onChange={() => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                  return i === index ? { ..._, dedicatedReservationSiteFlg: !_.dedicatedReservationSiteFlg } : { ..._ };
                }),
              });
            }}
            disabled={!(radioDisableFlg && reserveTypeWEBFlg)}
          />
        </Form.Group>
        <Form.Group className="d-flex align-items-center ms-5 mt-3">
          <Form.Label style={{ fontWeight: 'bold' }} className="mt-2 me-2">
            ※「電話予約」「WEB予約」の場合、「電話番号」または「URL」の入力が無い場合、店舗の情報が表示されます。
          </Form.Label>
        </Form.Group>
      </Row>
      <Form.Group className="mt-3">
        <Form.Check id="radio2" className="d-flex">
          <Form.Check.Input
            data-testid="reserveBanRadio"
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup03"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                  return i === index ? { ..._, reserveType: Number(e.target.value) } : { ..._ };
                }),
              });
              setRadioDisableFlg(false);
            }}
            value={1}
            checked={
              data.monitorRuleList.find((_) => {
                return _.monitorRuleType === 5;
              })?.reserveType === 1 || false
            }
          />
          <Form.Check.Label>予約禁止</Form.Check.Label>
        </Form.Check>
      </Form.Group>
      <Form.Group className="mt-3 mb-4">
        <Form.Check id="radio2" className="d-flex">
          <Form.Check.Input
            data-testid="reserveAnyRadio"
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup03"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                  return i === index ? { ..._, reserveType: Number(e.target.value) } : { ..._ };
                }),
              });
              setRadioDisableFlg(false);
            }}
            value={2}
            checked={
              data.monitorRuleList.find((_) => {
                return _.monitorRuleType === 5;
              })?.reserveType === 2 || false
            }
          />
          <Form.Check.Label>予約任意</Form.Check.Label>
          <Button
            className="ms-5"
            variant="secondary"
            onClick={() => {
              let reflectionText = '';
              let supplementText = '';
              const beautyFixedText =
                '※予約の際、ホットペッパービューティーの会員登録が必要です。既に会員登録済の方は不要です。';
              const reserveType: number | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 5;
              })?.reserveType;
              const reservePhoneNumber: string | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 5;
              })?.reservePhoneNumber;
              const reserveUrl: string | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 5;
              })?.reserveURL;
              const samedayReservationsFlg: boolean | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 5;
              })?.samedayReservationsFlg;
              const dedicatedReservationSiteFlg: boolean | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 5;
              })?.dedicatedReservationSiteFlg;
              if (reserveType !== undefined) {
                // 要予約で「電話予約」にチェックがある場合
                if (reserveTypePhoneFlg && reserveType === 0) {
                  reflectionText = `必須（電話）のみ\n ＜予約方法＞\n ・下記に表示されている番号より、営業時間内に電話予約を行ってください。\n 📞${
                    reservePhoneNumber || '[店舗情報より取得]'
                  }`;
                  // 「当日不可」にチェックがある場合
                  supplementText = '※指定以外の方法で予約された場合、謝礼のお支払いができません。\n';
                  if (samedayReservationsFlg) {
                    supplementText +=
                      '※前日までに予約をしてください。当日の予約は受け付けておりません。\n※電話予約時に「直接来店のみ受け付けています」と対応される場合があります。その際は予約はできなくても直接ご来店ください。\n※ただし、アンケートの電話対応の設問には電話した際の対応についてご回答ください。\n';
                  }
                }
                // 要予約で「WEB予約」にチェックがある場合
                if (reserveTypeWEBFlg && reserveType === 0) {
                  reflectionText = `必須（WEB）のみ\n ＜予約方法＞\n ・下記に表示されているURLより、WEB予約を行ってください。\n ${
                    reserveUrl || '[店舗情報より取得]'
                  }`;
                  supplementText +=
                    '※予約専用サイトからの予約のみが対象となります。 仮予約も含め、その他サイトからの予約および電話予約は禁止です。確認された場合、謝礼のお支払いはできません。\n';

                  // 「予約専用サイト」にチェックがある場合
                  if (dedicatedReservationSiteFlg) {
                    supplementText =
                      '※予約専用サイトからの予約のみが対象となります。 仮予約も含め、その他サイトからの予約および電話予約は禁止です。確認された場合、謝礼のお支払いはできません。\n';
                  }
                }
                // モニタールールセット種別が美容の場合
                if ((reserveTypePhoneFlg || reserveTypeWEBFlg) && reserveType === 0 && data.monitorRuleSetType === 6) {
                  supplementText += beautyFixedText;
                }
                // 要予約で「電話予約・WEB予約」両方にチェックがある場合場合
                if (reserveTypePhoneFlg && reserveTypeWEBFlg && reserveType === 0) {
                  reflectionText = `必須（電話・WEB可）\n ＜予約方法＞\n  [電話の場合]\n   ・下記に表示されている番号より、営業時間内に電話予約を行ってください。\n     📞${
                    reservePhoneNumber || '[店舗情報より取得]'
                  }\n  [WEBの場合]\n   ・下記に表示されているURLより、WEB予約を行ってください。\n     ${
                    reserveUrl || '[店舗情報より取得]'
                  } `;
                }
                // 予約禁止の場合
                if (reserveType === 1) {
                  reflectionText = '禁止\n仮予約も含め、ご予約は禁止です。確認された場合、謝礼のお支払いはできません。';
                  supplementText = '※閉店や改装工事・休業日の変更などの理由により、営業していない場合がございます。';
                }
                // 予約任意の場合
                if (reserveType === 2) {
                  reflectionText = '予約任意（不要）';
                  supplementText =
                    '※混雑が予想される場合はご予約をおすすめいたします。\n※閉店や改装工事・休業日の変更などの理由により、営業していない場合がございますので、対象店舗が来店予定日に営業していることを、事前にお電話で確認をお願いします。\n※他社予約サイト経由で予約した場合、意図しない割引が適用される可能性がございます。割引利用の可否については、【クーポン・割引】項目の確認をお願いします。\n';
                  // モニタールールセット種別が美容の場合
                  if (data.monitorRuleSetType === 6) {
                    supplementText += beautyFixedText;
                  }
                }
              }

              let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                [],
                data.monitorRuleList.find((supplement) => {
                  return supplement.monitorRuleType === rowData.monitorRuleType;
                })?.supplementList
              );
              copySupplementList = copySupplementList.map((r, i) => {
                return i === 0 ? { ...r, supplement: supplementText } : { ...r };
              });

              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 5
                    ? {
                        ...row,
                        beforeApplyContent: reflectionText,
                        afterApplyContent: reflectionText,
                        supplementList: copySupplementList,
                      }
                    : { ...row };
                }),
              });
            }}
          >
            反映
          </Button>
        </Form.Check>
      </Form.Group>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
