import React from 'react';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { dial } from '../../../../../utils/functions';
import { ResultModalMode } from '../../../../pages/Correction/constants';
import { CorrectionModalBase } from '../CorrectionModalBase';
import { OKForm } from './forms/OKForm';
import { QuestionForm } from './forms/QuestionForm';
import { RejectForm } from './forms/RejectForm';
import { ResendForm } from './forms/ResendForm';

// TODO: [添削] テスト未実装
export const ResultModal: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  return (
    <CorrectionModalBase isModal={!!$.isResultModal} title="添削結果入力">
      {dial(
        $.resultFormMode ?? '',
        {
          [ResultModalMode.OK]: <OKForm isViewOnly={false} />,
          [ResultModalMode.RESEND]: <ResendForm />,
          [ResultModalMode.REJECT]: <RejectForm />,
          [ResultModalMode.ESCALE]: <QuestionForm />,
        },
        <pre>ERROR: 無効な ResultModalMode</pre>
      )}
    </CorrectionModalBase>
  );
};
