import React, { useState, Dispatch, SetStateAction, useContext } from 'react';
import { Table, Form, Card, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
  BillingApi,
  BillingDestinationShopBulkDeleteFormResponse,
  BillingDestinationShopBulkUpdateFormResponse,
  BillingDestinationShopListOutputResponse,
  BillingDestinationShopUpdateFormResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { useMultipleChecked } from '../../../hooks/useMultipleChecked';
import { convertNanToUndefined } from '../../../utils/functionsForBilling';

export interface Props {
  billingDestinationId: number;
  list: BillingDestinationShopListOutputResponse[];
  setList: Dispatch<SetStateAction<BillingDestinationShopListOutputResponse[]>>;
  page: number;
  setUpdResult: Dispatch<SetStateAction<IncResultOutputResponse | undefined>>;
  destShopBulkUpdateForm: BillingDestinationShopBulkUpdateFormResponse;
  handleClickSearchBtn: () => void;
}

export const BillingDestinationShopTable: React.VFC<Props> = ({
  billingDestinationId,
  list,
  setList,
  page,
  setUpdResult,
  destShopBulkUpdateForm,
  handleClickSearchBtn,
}) => {
  const [isModal, setIsModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const billingApi = new BillingApi();
  const MAX_RECORD_PER_PAGE = 20;

  /*
   * 一括紐付け解除
   */
  const billingDestShopBulkDeleteForm = {
    billingDestId: billingDestinationId,
  } as BillingDestinationShopBulkDeleteFormResponse;

  const handleClickDelete = (id: number): void => {
    setIsModal(true);
    setDeleteId(id);
  };

  const handleClickModalYes = (): void => {
    if (!deleteId) return;

    billingApi
      .billingDestShopDelete({ billingDestId: billingDestinationId, shopId: deleteId })
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        if (res.data.result) {
          setList(list.filter((item) => item.shopId !== deleteId));
        }
        setUpdResult(res.data);
        handleClickSearchBtn();
        setIsModal(false);
      });
  };

  const handleClickCheckBox = (shopId: number) => {
    const updateList = list.map((v) => {
      if (v.shopId === shopId) {
        return { ...v, relationFlg: !v.relationFlg };
      }
      return v;
    });
    setList(updateList);
  };

  const handleClickModalNo = (): void => {
    setIsModal(false);
    setDeleteId(null);
  };

  const handleClickShopUpdateBtn = () => {
    const shopIds: Array<number> = [];
    list.map((v) => {
      if (v.relationFlg) {
        shopIds.push(v.shopId);
      }
    });

    const shopUpdate = {
      billingDestId: billingDestinationId,
      shopId: shopIds,
    } as BillingDestinationShopUpdateFormResponse;
    billingApi
      .billingDestShopUpdate(shopUpdate)
      .then((response: AxiosResponse<IncResultOutputResponse>) => {
        setUpdResult(response.data);
        handleClickSearchBtn();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickShopBulkUpdateBtn = () => {
    const converted = convertNanToUndefined(destShopBulkUpdateForm) as BillingDestinationShopBulkUpdateFormResponse;

    billingApi
      .billingDestShopBulkUpdate(converted)
      .then((response: AxiosResponse<IncResultOutputResponse>) => {
        setUpdResult(response.data);
        handleClickSearchBtn();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickShopBulkDeleteBtn = () => {
    billingApi
      .billingDestShopBulkDelete(billingDestShopBulkDeleteForm)
      .then((response: AxiosResponse<IncResultOutputResponse>) => {
        setUpdResult(response.data);
        handleClickSearchBtn();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Modal
        title="紐付けを解除しますか？"
        body={
          <div className="d-flex justify-content-center">
            <Button variant="primary" onClick={handleClickModalYes} className="mx-3">
              はい
            </Button>
            <Button variant="secondary" onClick={handleClickModalNo} className="mx-3">
              いいえ
            </Button>
          </div>
        }
        centered
        isModal={isModal}
        onHide={handleClickModalNo}
      />

      <Card>
        <Card.Body>
          <div className="d-flex justify-content-center">
            <Button className="mx-3" onClick={handleClickShopUpdateBtn}>
              チェックの入った店舗を紐づける
            </Button>
            <Button className="mx-3" onClick={handleClickShopBulkUpdateBtn}>
              検索結果をまとめて紐付け
            </Button>
            <Button className="mx-3" onClick={handleClickShopBulkDeleteBtn}>
              送付先まとめて紐付け解除
            </Button>
          </div>
        </Card.Body>
      </Card>

      <Table>
        <thead>
          <tr>
            <th>契約店舗ID</th>
            <th>店舗名</th>
            <th>クライアントID</th>
            <th>クライアント名</th>
            <th>請求送付先案枝番</th>
            <th>請求送付先案件名</th>
            <th>紐付き</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {list
            .slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE)
            ?.map((data: BillingDestinationShopListOutputResponse) => {
              return (
                <tr key={data.shopId} className="align-middle">
                  <td>{data.shopId}</td>
                  <td>{data.shopName}</td>
                  <td>{data.clientId}</td>
                  <td>{data.clientName}</td>
                  <td>{data.branchNumber}</td>
                  <td>{data.billingDestName}</td>
                  <td>
                    <Form.Check.Input
                      className="flex-shrink-0 me-2"
                      type="checkbox"
                      name=""
                      checked={data.relationFlg}
                      onChange={() => handleClickCheckBox(data.shopId)}
                    />
                  </td>
                  <td>
                    <Button
                      variant="light"
                      className="text-secondary ms-2"
                      testId="delete"
                      onClick={() => {
                        handleClickDelete(data.shopId);
                      }}
                    >
                      紐付け解除
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};
