import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Button, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CreatableSelect from 'react-select/creatable';
import { InputNumber } from '../../atoms/InputNumber';
import { useLargeState } from '../../../hooks/useLargeState';
import { uuid, when } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
// eslint-disable-next-line import/no-cycle
import { ShoppingApi } from '../../../api-client';
import type {
  DataInputShopPhoneNumberSearchFormResponse,
  DataInputShopPhoneNumberSearchOutputResponse,
  DataInputSubmitFormResponse,
  ReceiptReadResultOutputResponse,
  CommonMasterListOutputResponse,
  ShoppingDisplayControlOutputResponse,
} from '../../../api-client';
import { DoubleButton } from '../../molecules/DoubleButton';
import { ErrorMessagesForInput } from '../../pages/Correction/CorrectDataInputPage';
import { Alert } from '../../atoms/Alert';

export type State = DataInputSubmitFormResponse;
export type PrefectureType = CommonMasterListOutputResponse[];
export type ChaneNameType = string[];
export type TaxRateListType = number[];

interface Props {
  setForms: DispatchSetState<DataInputSubmitFormResponse>;
  formData: State;
  output: ReceiptReadResultOutputResponse[];
  prefectureList: PrefectureType;
  chainNameList: ChaneNameType;
  taxRateList: TaxRateListType;
  controlInfo: ShoppingDisplayControlOutputResponse;
  errorMessagesForInput: ErrorMessagesForInput[];
}
interface PageState {
  shoppingApi: ShoppingApi;
  output: Array<ReceiptReadResultOutputResponse & { newFlg: boolean }>;
  errorMessages: string[];
}

export const CorrectDataInputForm: React.FC<Props> = React.memo(
  ({ setForms, formData, output, prefectureList, chainNameList, taxRateList, controlInfo, errorMessagesForInput }) => {
    const { state: $form, mergeState: mergeForm } = useLargeState<State>(formData);

    useEffect(() => {
      setForms({ ...$form });
    }, [setForms, $form]);

    const { state: $, mergeState } = useLargeState<PageState>({
      shoppingApi: new ShoppingApi(),
      output: output.map((obj) => {
        return { ...obj, newFlg: false };
      }),
      errorMessages: [],
    });

    const purchaseDoubleButtonOption = {
      yes: '購入している',
      no: '購入していない',
    };

    const initialReceiptForm: State['receiptReadResultFormList'][0] = {
      amountReadableFlg: true,
      taxRateCode: undefined,
      chainNameReadableFlg: true,
      inFocusFlg: undefined,
      prefectureReadableFlg: true,
      purchaseCheckReadableFlg: true,
      purchaseCheckResultFlg: undefined,
      receiptDateTimeReadableFlg: true,
      receiptNumberReadableFlg: undefined,
      shopPhoneNumberReadableFlg: true,
      storeReadableFlg: true,
      visibleAllCornerFlg: undefined,
    };

    const initialOutput: ReceiptReadResultOutputResponse & { newFlg: boolean } = {
      autoCorrectReceiptId: 0,
      chain: { chainNameReadableFlg: true },
      inFocusCheck: { inFocusCheckFlg: true },
      purchaseCheckResult: { purchaseCheckReadableFlg: true, purchaseCheckResultFlg: true },
      receiptAmount: { amountReadableFlg: true },
      receiptDateTime: { receiptDateTimeReadableFlg: true },
      receiptNumber: { receiptNumberReadableFlg: true },
      receiptReadResultPrefecture: { prefectureReadableFlg: true },
      shopPhoneNumber: { shopPhoneNumberReadableFlg: true },
      store: { storeReadableFlg: true },
      visibleAllCorner: { visibleAllCornerFlg: true },
      newFlg: true,
      commonNgList: [],
    };

    const calculateAmount = (amount: number | undefined, tax: number, i: number) => {
      let taxIncludeAmount = amount;
      let newTotalAmount: number | undefined;

      if (taxIncludeAmount) {
        const taxAmount = Math.floor(tax === 0 ? 0 : (taxIncludeAmount * tax) / 100);
        taxIncludeAmount += taxAmount;
      }

      const newReceiptAmountList = $form.receiptReadResultFormList.map(({ receiptAmountIncludeTax }, idx) =>
        i === idx ? taxIncludeAmount : receiptAmountIncludeTax
      );
      // 全てのレシートフォームの利用金額がundefinedの時、税込み合計金額もundefinedにする
      if (newReceiptAmountList.some((receiptAmount) => receiptAmount !== undefined)) {
        newReceiptAmountList.forEach((receiptAmount) => {
          newTotalAmount = (newTotalAmount || 0) + (receiptAmount || 0);
        });
      }

      return { taxIncludeAmount, newTotalAmount };
    };

    const onChangeReceiptAmount = (receiptAmount: number | undefined, tax: number, i: number) => {
      let amount = {
        taxIncludeAmount: receiptAmount,
        newTotalAmount: $form.totalAmountIncludeTax,
      };
      amount = calculateAmount(receiptAmount, tax, i);
      mergeForm({
        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
          if (i !== idx) return obj;
          return {
            ...obj,
            receiptAmount,
            receiptAmountIncludeTax: amount.taxIncludeAmount,
          };
        }),
        totalAmountIncludeTax: amount.newTotalAmount,
      });
    };

    const onChangeReceiptReadableButton = (
      amountReadableFlg: boolean,
      amount: number | undefined,
      amountIncludeTax: number | undefined,
      i: number
    ) => {
      const receiptAmountIncludeTax = amountReadableFlg ? undefined : amountIncludeTax;
      const receiptAmount = amountReadableFlg ? undefined : amount;
      const totalAmountIncludeTax = calculateAmount(0, 0, i).newTotalAmount;
      mergeForm({
        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
          if (i !== idx) return obj;
          return {
            ...obj,
            amountReadableFlg: !amountReadableFlg,
            receiptAmountIncludeTax,
            receiptAmount,
          };
        }),
        totalAmountIncludeTax,
      });
    };

    const onChangeTaxRate = (receiptAmount: number | undefined, tax: number, i: number) => {
      let amount = {
        taxIncludeAmount: receiptAmount,
        newTotalAmount: $form.totalAmountIncludeTax,
      };
      if (receiptAmount) {
        amount = calculateAmount(receiptAmount, tax, i);
      }
      mergeForm({
        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
          if (i !== idx) return obj;
          return {
            ...obj,
            taxRateCode: tax,
            receiptAmountIncludeTax: amount.taxIncludeAmount,
          };
        }),
        totalAmountIncludeTax: amount.newTotalAmount,
      });
    };

    const shopPhoneNumberSearch = (phoneNumber: string, i: number) => {
      const body: DataInputShopPhoneNumberSearchFormResponse = { phoneNumber };

      $.shoppingApi
        .dataInputShopPhoneNumberSearch(body)
        .then((res: AxiosResponse<DataInputShopPhoneNumberSearchOutputResponse>) => {
          const { chainName, prefectureId, storeName } = res.data;
          mergeForm({
            receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
              if (i !== idx) return obj;
              return {
                ...obj,
                chainName: chainName || undefined,
                storeName: storeName || undefined,
                selectPrefectureId: prefectureId || undefined,
              };
            }),
          });
        });
    };

    const onCloseForm = (i: number) => {
      const newArray = $form.receiptReadResultFormList;
      newArray.splice(i, 1);
      let newTotalAmount: number | undefined;
      if (newArray.length > 0 && newArray.some(({ receiptAmountIncludeTax }) => receiptAmountIncludeTax)) {
        newArray.forEach(({ receiptAmountIncludeTax }) => {
          newTotalAmount = (newTotalAmount || 0) + (receiptAmountIncludeTax || 0);
        });
      }
      mergeForm({
        receiptReadResultFormList: newArray,
        totalAmountIncludeTax: newTotalAmount,
      });

      const newOutputList = $.output;
      newOutputList.splice(i, 1);
      mergeState({ output: newOutputList });
    };

    const onClickButtonForDisabled = (flg: boolean, i: number, inFocusFlg: boolean) => {
      if (flg) {
        mergeForm({
          receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
            if (i !== idx) return obj;
            return {
              ...obj,
              inFocusFlg: inFocusFlg ? flg : obj.inFocusFlg,
              visibleAllCornerFlg: inFocusFlg ? obj.visibleAllCornerFlg : flg,
            };
          }),
        });
      } else {
        mergeForm({
          receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
            if (i !== idx) return obj;
            return {
              ...obj,
              inFocusFlg: inFocusFlg ? flg : obj.inFocusFlg,
              visibleAllCornerFlg: inFocusFlg ? obj.visibleAllCornerFlg : flg,
              shopPhoneNumberReadableFlg: false,
              shopPhoneNumber: undefined,
              chainNameReadableFlg: false,
              chainName: undefined,
              storeReadableFlg: false,
              storeName: undefined,
              prefectureReadableFlg: false,
              selectPrefectureId: undefined,
              receiptDateTimeReadableFlg: false,
              receiptDateTime: undefined,
              purchaseCheckReadableFlg: false,
              purchaseCheckResultFlg: undefined,
              amountReadableFlg: false,
              receiptAmount: undefined,
            };
          }),
        });
      }
    };

    return (
      <>
        {$form.receiptReadResultFormList.map((form, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={i}>
              {when(
                i > 0,
                <div className="d-flex justify-content-end">
                  <Button variant="link" onClick={() => onCloseForm(i)}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                    {i + 1}枚目のレシート
                  </Button>
                </div>
              )}
              <Card className="p-2">
                <h6>1.ピントが合っていて文字が全て確認できますか？</h6>
                {controlInfo.isDataInputPossible &&
                  errorMessagesForInput.filter((v) => v.target === `inFocusFlg_${i}`).length > 0 && (
                    <Alert variant="danger">
                      {errorMessagesForInput.filter((v) => v.target === `inFocusFlg_${i}`)[0].message}
                    </Alert>
                  )}
                <div className="me-2 d-flex justify-content-end">
                  <DoubleButton
                    yesFlg={form.inFocusFlg}
                    onClickButton={(flg: boolean) => onClickButtonForDisabled(flg, i, true)}
                    checkFlg
                  />
                </div>
                <h6 className="mt-2">2.折り曲げや切断された跡が無く、四隅が全て確認できますか？</h6>
                {errorMessagesForInput.filter((v) => v.target === `visibleAllCornerFlg_${i}`).length > 0 && (
                  <Alert variant="danger">
                    {errorMessagesForInput.filter((v) => v.target === `visibleAllCornerFlg_${i}`)[0].message}
                  </Alert>
                )}
                <div className="me-2 d-flex justify-content-end">
                  <DoubleButton
                    yesFlg={form.visibleAllCornerFlg}
                    onClickButton={(flg: boolean) => onClickButtonForDisabled(flg, i, false)}
                    checkFlg
                  />
                </div>

                <h6 className="mt-2">3.レシート番号は確認できますか？</h6>
                {errorMessagesForInput.filter((v) => v.target === `receiptNumberReadableFlg_${i}`).length > 0 && (
                  <Alert variant="danger">
                    {errorMessagesForInput.filter((v) => v.target === `receiptNumberReadableFlg_${i}`)[0].message}{' '}
                  </Alert>
                )}
                <div className="me-2 d-flex justify-content-end">
                  <DoubleButton
                    yesFlg={form.receiptNumberReadableFlg}
                    onClickButton={(flg: boolean) =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, receiptNumberReadableFlg: flg };
                        }),
                      })
                    }
                    checkFlg
                  />
                </div>

                <h6 className="mt-2">4.電話番号（ハイフンなし）を入力して下さい</h6>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={form.shopPhoneNumber || ''}
                    onChange={(e) => {
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, shopPhoneNumber: e.target.value };
                        }),
                      });
                    }}
                    disabled={!form.shopPhoneNumberReadableFlg}
                  />
                  <Button
                    className="ms-2"
                    style={{ minWidth: '150px' }}
                    variant="outline-primary"
                    onClick={() => shopPhoneNumberSearch(String(form.shopPhoneNumber), i)}
                  >
                    電話番号から
                    <br />
                    店舗検索
                  </Button>
                  <Button
                    className="ms-2"
                    style={{ minWidth: '150px' }}
                    onClick={() =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, shopPhoneNumberReadableFlg: !form.shopPhoneNumberReadableFlg };
                        }),
                      })
                    }
                    variant={form.shopPhoneNumberReadableFlg ? 'outline-primary' : 'primary'}
                  >
                    入力できない
                  </Button>
                </div>
                <h6 className="mt-2">5.チェーン名を入力してください</h6>
                <div className="d-flex">
                  <div style={{ width: '80%' }}>
                    <CreatableSelect
                      isClearable
                      placeholder=""
                      formatCreateLabel={(value) => `${value} を作成`}
                      createOptionPosition="last"
                      value={{ value: form.chainName, label: form.chainName }}
                      options={chainNameList.map((name) => {
                        return { label: name, value: name };
                      })}
                      onChange={(e) =>
                        mergeForm({
                          receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                            if (i !== idx) return obj;
                            return { ...obj, chainName: e?.value };
                          }),
                        })
                      }
                      isDisabled={!form.chainNameReadableFlg}
                    />
                  </div>
                  <Button
                    className="ms-2"
                    style={{ minWidth: '150px' }}
                    onClick={() =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, chainNameReadableFlg: !form.chainNameReadableFlg };
                        }),
                      })
                    }
                    variant={form.chainNameReadableFlg ? 'outline-primary' : 'primary'}
                  >
                    入力できない
                  </Button>
                </div>
                <h6 className="mt-2">6.ストア名を入力してください</h6>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={form.storeName || ''}
                    onChange={(e) => {
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, storeName: e.target.value };
                        }),
                      });
                    }}
                    disabled={!form.storeReadableFlg}
                  />
                  <div className="mt-1 ms-1">店</div>
                  <Button
                    className="ms-2"
                    style={{ minWidth: '150px' }}
                    onClick={() =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, storeReadableFlg: !form.storeReadableFlg };
                        }),
                      })
                    }
                    variant={form.storeReadableFlg ? 'outline-primary' : 'primary'}
                  >
                    入力できない
                  </Button>
                </div>
                <h6 className="mt-2">7.都道府県を入力してください</h6>
                <div className="d-flex">
                  <Form.Select
                    value={form.selectPrefectureId ?? ''}
                    onChange={(e) =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return {
                            ...obj,
                            selectPrefectureId: e.target.value === '' ? undefined : Number(e.target.value),
                          };
                        }),
                      })
                    }
                    disabled={!form.prefectureReadableFlg}
                  >
                    <option value="" key={uuid()}>
                      都道府県を選択してください
                    </option>
                    {prefectureList.map(({ id, name }) => {
                      return (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Button
                    className="ms-2"
                    style={{ minWidth: '150px' }}
                    onClick={() =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, prefectureReadableFlg: !form.prefectureReadableFlg };
                        }),
                      })
                    }
                    variant={form.prefectureReadableFlg ? 'outline-primary' : 'primary'}
                  >
                    選択できない
                  </Button>
                </div>
                <h6 className="mt-2">8.レシート日時を入力してください</h6>
                <div className="d-flex">
                  <Form.Control
                    type="datetime-local"
                    min="1900-01-01T00:00:00"
                    max="2100-12-31T23:59:59"
                    autoComplete="off"
                    value={form.receiptDateTime || ''}
                    onChange={(e) =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, receiptDateTime: e.target.value };
                        }),
                      })
                    }
                    disabled={!form.receiptDateTimeReadableFlg}
                  />
                  <Button
                    className="ms-2"
                    style={{ minWidth: '150px' }}
                    onClick={() =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, receiptDateTimeReadableFlg: !form.receiptDateTimeReadableFlg };
                        }),
                      })
                    }
                    variant={form.receiptDateTimeReadableFlg ? 'outline-primary' : 'primary'}
                  >
                    入力できない
                  </Button>
                </div>
                <h6 className="mt-2">9.対象商品を購入していますか</h6>
                <div className="me-2 d-flex justify-content-between">
                  <div>
                    <DoubleButton
                      yesFlg={form.purchaseCheckResultFlg}
                      onClickButton={(flg: boolean) =>
                        mergeForm({
                          receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                            if (i !== idx) return obj;
                            return { ...obj, purchaseCheckResultFlg: flg };
                          }),
                        })
                      }
                      label={purchaseDoubleButtonOption}
                      checkFlg
                      disabled={!form.purchaseCheckReadableFlg}
                    />
                  </div>
                  <Button
                    className="ms-2"
                    style={{ minWidth: '150px' }}
                    onClick={() =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, purchaseCheckReadableFlg: !form.purchaseCheckReadableFlg };
                        }),
                      })
                    }
                    variant={form.purchaseCheckReadableFlg ? 'outline-primary' : 'primary'}
                  >
                    判断できない
                  </Button>
                </div>

                <h6 className="mt-2">10.利用金額を入力してください</h6>
                <div className="d-flex">
                  <InputNumber
                    value={form.receiptAmount !== undefined ? form.receiptAmount : undefined}
                    onChange={(e) =>
                      onChangeReceiptAmount(
                        e.target.value === '' ? undefined : Number(e.target.value),
                        form.taxRateCode || 0,
                        i
                      )
                    }
                    disabled={!form.amountReadableFlg}
                  />
                  <div className="mt-1 ms-1">円</div>
                  <Button
                    className="ms-2"
                    style={{ minWidth: '150px' }}
                    onClick={() =>
                      onChangeReceiptReadableButton(
                        form.amountReadableFlg,
                        form.receiptAmount,
                        form.receiptAmountIncludeTax,
                        i
                      )
                    }
                    variant={form.amountReadableFlg ? 'outline-primary' : 'primary'}
                  >
                    入力できない
                  </Button>
                </div>
                {when(
                  taxRateList && taxRateList.length > 1,
                  <>
                    <h6 className="mt-2">11.税率を選択してください</h6>
                    {taxRateList && taxRateList.length > 1 && taxRateList ? (
                      taxRateList.map((tax) => {
                        return (
                          <Form.Check
                            type="radio"
                            key={tax}
                            id={`radio-${i}-${tax}`}
                            label={tax === 0 ? '税込' : `税抜き${tax}％`}
                            onChange={() => onChangeTaxRate(form.receiptAmount, tax, i)}
                            checked={tax === form.taxRateCode}
                            disabled={!form.amountReadableFlg}
                          />
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <hr className="mx-2" />
                <div className="d-flex justify-content-end">
                  <h6>対象商品の税込み合計金額 {form.receiptAmountIncludeTax} 円</h6>
                </div>
                <div className="p-2 bg-light">
                  <h6>事務局への質問</h6>
                  <Form.Control
                    type="text"
                    value={form.inquiryContents || ''}
                    onChange={(e) =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, inquiryContents: e.target.value };
                        }),
                      })
                    }
                  />
                </div>
              </Card>
            </React.Fragment>
          );
        })}
        <div className="d-flex justify-content-end">
          <Button
            variant="link"
            onClick={() => {
              const newArray = $form.receiptReadResultFormList;
              newArray.push(initialReceiptForm);
              mergeForm({
                receiptReadResultFormList: newArray,
              });

              const newOutputList = $.output;
              newOutputList.push(initialOutput);
              mergeState({
                output: newOutputList,
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            フォームを追加
          </Button>
        </div>
        {when(
          controlInfo.isDataInputEscalationAnswerPossible,
          <>
            <h6>添削結果入力</h6>
            <div className="d-flex justify-content-end">
              <h6 className="mt-1 me-1">対象商品の税込み合計金額</h6> {formData.totalAmountIncludeTax} 円
            </div>
          </>
        )}
      </>
    );
  }
);
