/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplyManagementApi, ExecutiveStaffApplyStatusSummaryDisplayOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { ExecutiveStaffApplyManagementTable } from '../../organisms/Table/ExecutiveStaffApplyManagementTable';
import { Title } from '../../atoms/Title';

export const ExcecutiveStaffApplyManagementPage: React.FC = () => {
  const history = useHistory();

  const [summary, setSummary] = useState<ExecutiveStaffApplyStatusSummaryDisplayOutputResponse>();

  useEffect(() => {
    const api = new ApplyManagementApi();
    api
      .executiveStaffApplyStatusSummaryDisplay()
      .then((res) => {
        setSummary(res.data);
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [history]);

  return (
    <>
      <Title>事務局案件管理添削状況サマリー</Title>
      <hr />
      <ExecutiveStaffApplyManagementTable summary={summary} />
    </>
  );
};
