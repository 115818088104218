import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Form, ListGroup, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faEye } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
/* eslint-disable import/no-cycle */
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CampaignApi,
  CampaignInfoOutputResponse,
  CampaignUpdateFormResponse,
  RecommendApiClientListOutputResponse,
  ImageUpdateFormResponse,
  IncResultOutputResponse,
  MonitorBaseSimpleInfoOutputResponse,
  BrandAllMonitorBaseOutputResponse,
  BrandApi,
  BrandMonitorBaseImageInfoOutputResponse,
} from '../../../api-client';
import { getImageSize, getPresignedUrl, s3Upload, s3Delete, s3UploadAlignment } from '../../../utils/s3';
import { IMAGE_TYPE_ID, OBJECT_TYPE } from '../../../Constants';
import { TITLE } from '../../../constants/Title';
import { Url } from '../../../constants/Url';
import { Alert } from '../../atoms/Alert';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { FormControl } from '../../molecules/Form/FormControl';
import { Dropzone } from '../../molecules/Dropzone';
import { ImageCard } from '../../molecules/ImageCard';
import { LinkedMonitorBaseModal } from '../../organisms/Modal/LinkedMonitorBaseModal';
import { OemModal } from '../../organisms/Modal/OemModal';
import { ValidationFormControl } from '../../molecules/Form/ValidationFormControl';
import { campaignSchema } from '../../../constants/YupSchema';
import { SubmitPrimaryButton } from '../../atoms/Button/SubmitPrimaryButton';
import { MonitorImageModal } from '../../organisms/Modal/ShopImageModal';
/* eslint-enable import/no-cycle */

const sampleCampaignInfo = {
  catchCopy: 'とても美味しいよ',
  id: -9223372036854776000,
  imageId: 100,
  imageUrl: 'http://fancrew.jp/api/3.0/image/brand/0000/4320/2117960-1641275727657.jpg',
  name: 'テストキャンペーン',
  nameKana: 'てすときゃんぺーん',
  oemShowFlg: true,
  overview: '美味しいよ',
  shopList: [
    { shopId: 1, shopName: '店舗1' },
    { shopId: 2, shopName: '店舗2' },
    { shopId: 3, shopName: '店舗3' },
  ],
  oemList: [
    // { oemId: 1, oemName: 'OEM1' },
    // { oemId: 2, oemName: 'OEM2' },
    // { oemId: 3, oemName: 'OEM3' },
  ],
  shopShowType: 'クローズド',
  showType: '非表示',
  publicStartDate: '2021-02-12',
  publicEndDate: '2022-12-28',
  floatingRewardAutoFlg: true,
  noIndexFlg: true,
  titleH1Tag: '<h1>見出しです</h1>',
};
const sampleRecommendOemList = {
  oemList: [],
  searchOemList: [
    { apiClientId: 1, apiClientName: 'OEM1' },
    { apiClientId: 2, apiClientName: 'OEM2' },
    { apiClientId: 3, apiClientName: 'OEM3' },
  ],
};

export const SEARCH_TYPE = {
  CLIENT: 'クライアント',
  SHOP: '店舗',
} as const;

const enum ShowTypeEnum {
  DISPLAY = 1,
  HIDE = 2,
  CLOSED = 3,
}

const SHOW_TYPE = new Map([
  [ShowTypeEnum.DISPLAY, '表示'],
  [ShowTypeEnum.HIDE, '非表示'],
  [ShowTypeEnum.CLOSED, 'クローズド'],
]);

const CAMPAIGN_IMAGE_TYPE = {
  SELF: 1,
  MONITOR: 2,
} as const;

const PAGE_TYPE = {
  DETAIL: 'detail',
  MODIFY: 'modify',
  CONFIRM: 'confirm',
  INIT: null,
} as const;

interface Image {
  isDefault: boolean;
  id?: number;
  name?: string;
  url?: string;
  file?: File;
  contentType?: string;
  width?: number;
  height?: number;
  format?: string;
}

type PageType = 'detail' | 'modify' | 'confirm' | null;

// FIXME 川﨑 キャンペーンの紐づけ変更をする際に対応すること
export const CampaignDetailModifyPage: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<{ id: number; type: 'detail' | 'modify' }>();
  const [campaignId] = useState<number>(location.state?.id);

  const brandApi = new BrandApi();
  const campaignApi = new CampaignApi();
  const [pageType, setPageType] = useState<PageType>(null);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
    setFocus,
    formState: { errors, submitCount },
  } = useForm<CampaignUpdateFormResponse>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    shouldFocusError: false,
    defaultValues: {
      floatingRewardAutoFlg: false,
      oemShowFlg: false,
      showType: SHOW_TYPE.get(ShowTypeEnum.DISPLAY),
      shopShowType: SHOW_TYPE.get(ShowTypeEnum.DISPLAY),
      noIndexFlg: false,
    },
    resolver: yupResolver(campaignSchema),
  });

  const [targetMonitorBaseName, setTargetMonitorBaseName] = useState<string>('');
  const watchTargetMonitorBaseId = watch('targetMonitorBaseId');

  const [campaignImageType, setCampaignImageType] = useState<number>(CAMPAIGN_IMAGE_TYPE.SELF);
  const [image, setImage] = useState<Image>({ isDefault: false });
  const [imageUrlByTargetMonitorBase, setImageUrlByTargetMonitorBase] = useState<string>('');
  const [allMonitorBaseList, setAllMonitorBaseList] = useState<MonitorBaseSimpleInfoOutputResponse[]>([]);

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>({
    result: false,
    errorCode: '',
    errorMessage: '',
  });

  const [isMonitorBaseImageModal, setIsMonitorBaseImageModal] = useState<boolean>(false);
  const [isLinkedShopModal, setIsLinkedShopModal] = useState<boolean>(false);
  const [monitorBaseList, setMonitorBaseList] = useState<
    {
      monitorBaseId: number;
      monitorBaseName: string;
      shopId?: number;
      shopName?: string;
      clientId?: number;
      clientName?: string;
    }[]
  >([]);

  const [isOemModal, setIsOemModal] = useState<boolean>(false);
  const [oemList, setOemList] = useState<{ oemId: number; oemName: string }[]>([]);

  const [isRecommendOemAlert, setIsRecommendOemAlert] = useState<boolean>(false);

  useEffect(() => {
    brandApi.allMonitorBase().then((res: AxiosResponse<BrandAllMonitorBaseOutputResponse>) => {
      // const list = sampleAllShopList.allShopList;
      const list = res.data.allMonitorBaseList;
      setAllMonitorBaseList(list);
    });

    if (campaignId) {
      campaignApi.campaignInfo(campaignId).then((res: AxiosResponse<CampaignInfoOutputResponse>) => {
        // res.data = sampleCampaignInfo;
        const {
          id,
          monitorBaseList: resMonitorBaseList,
          oemList: resOemList,
          imageId,
          imageUrl,
          campaignImageType: resCampaignImageType,
          imageUrlByTargetMonitorBase: resImageForMonitorBaseUrl,
          monitorBaseForCampaignImage: resMonitorBaseForCampaignImage,
          ...rest
        } = res.data;
        setCampaignImageType(resCampaignImageType);
        setMonitorBaseList(resMonitorBaseList);
        setOemList(resOemList);
        setImageUrlByTargetMonitorBase(resImageForMonitorBaseUrl || '');
        setImage({ isDefault: !!imageId, id: imageId || undefined, url: imageUrl || undefined });
        reset(rest);
        setValue('targetMonitorBaseId', resMonitorBaseForCampaignImage?.monitorBaseId);
      });
    }
    if (location.state?.type) setPageType(location.state?.type);
  }, []);

  useEffect(() => {
    const result = allMonitorBaseList.find((item) => item.monitorBaseId === Number(watchTargetMonitorBaseId));
    setTargetMonitorBaseName(result?.monitorBaseName || '');
  }, [watchTargetMonitorBaseId, allMonitorBaseList]);

  useEffect(() => {
    if (errors) {
      if (errors.name) {
        setFocus('name');
      } else if (errors.nameKana) {
        setFocus('nameKana');
      } else if (errors.overview) {
        setFocus('overview');
      } else if (errors.catchCopy) {
        setFocus('catchCopy');
      } else if (errors.publicStartDate) {
        setFocus('publicStartDate');
      }
    }
  }, [submitCount]);

  useEffect(() => {
    if (!Number(watchTargetMonitorBaseId)) return;
    brandApi
      .brandMonitorBaseImageInfo(Number(watchTargetMonitorBaseId))
      .then((res: AxiosResponse<BrandMonitorBaseImageInfoOutputResponse>) => {
        setImageUrlByTargetMonitorBase(res.data.imageUrl || '');
      });
  }, [watchTargetMonitorBaseId]);

  const handleClickConfirm = () => {
    window.history.pushState(null, '', Url.KEISAI.CAMPAIGN_MODIFY_CONFIRM);
    setTimeout(() => setPageType(PAGE_TYPE.CONFIRM), 100);
  };

  const searchRecommendOemList = () => {
    if (monitorBaseList.length === 0) {
      setIsRecommendOemAlert(true);
      return;
    }

    const param = {
      list: monitorBaseList.map(({ monitorBaseId }) => ({ monitorBaseId })),
    };

    campaignApi
      .campaignRecommendApiclientList(param)
      .then((res: AxiosResponse<RecommendApiClientListOutputResponse[]>) => {
        // const data = sampleRecommendOemList;
        setIsRecommendOemAlert(false);
        const data = res.data[0];

        if (!data) return;
        const notDuplicateOemList: { oemId: number; oemName: string }[] = [];
        data.searchOemList.forEach((searchItem) => {
          if (oemList.every((item) => item.oemId !== searchItem.apiClientId)) {
            notDuplicateOemList.push({ oemId: searchItem.apiClientId, oemName: searchItem.apiClientName });
          }
        });
        setOemList([...oemList, ...notDuplicateOemList]);
      });
  };

  const shopImmediateUpdate = () => {
    campaignApi.campaignShopImmediateUpdate(campaignId).then((res: AxiosResponse<IncResultOutputResponse>) => {
      if (!res.data.result) setUpdResult(res.data);
    });
  };

  const onDrop = async (files: File[]) => {
    const [file] = files;
    if (!file) return;

    const { name, type: contentType } = file;
    const url = window.URL.createObjectURL(file);
    const format = name.split('.').pop();
    const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));

    setImage((prev) => ({ ...prev, ...size, file, name, contentType, format, url }));
  };

  const onSubmit: SubmitHandler<CampaignUpdateFormResponse> = async (data) => {
    const { isDefault, id, url, name, contentType, file, width, height, format } = image;

    try {
      let uploadImageId = id;
      if (campaignImageType === CAMPAIGN_IMAGE_TYPE.SELF) {
        if (file && name && contentType && width && height && format) {
          const presignedUrl = await getPresignedUrl({ contentType, fileKey: name, objectType: OBJECT_TYPE.CAMPAIGN });
          const uploadTime = await s3Upload(presignedUrl, contentType, file);

          const alignmentParam: ImageUpdateFormResponse = { width, height, format };
          if (isDefault) {
            alignmentParam.imageId = id;
          } else {
            alignmentParam.imageTypeId = IMAGE_TYPE_ID.CAMPAIGN;
            const presignedPath = presignedUrl.replace(/\?.*$/, '');
            alignmentParam.path = presignedPath.substring(
              presignedPath.indexOf('amazonaws.com/') + 'amazonaws.com/'.length
            );
            alignmentParam.uploadTime = uploadTime;
          }

          await s3UploadAlignment(alignmentParam).then((res) => {
            if (!res?.result) {
              setUpdResult(res);
            }
            uploadImageId = res.id;
          });
        } else if (isDefault && !url && id) {
          await s3Delete(id).then((res) => {
            if (!res?.result) {
              setUpdResult(res);
              return;
            }
            uploadImageId = undefined;
            setImage((prev) => ({ ...prev, isDefault: false }));
          });
        }
      } else {
        uploadImageId = undefined;
      }

      const { targetMonitorBaseId, ...rest } = data;
      const updParam: CampaignUpdateFormResponse = { ...data, campaignImageType, imageId: uploadImageId };
      if (campaignImageType === CAMPAIGN_IMAGE_TYPE.MONITOR) updParam.targetMonitorBaseId = Number(targetMonitorBaseId);
      if (monitorBaseList.length !== 0)
        updParam.monitorBaseList = monitorBaseList.map(({ monitorBaseId, monitorBaseName }) => ({
          monitorBaseId,
          monitorBaseName,
        }));
      if (oemList.length !== 0) updParam.oemList = oemList.map(({ oemId }) => ({ oemId }));

      await campaignApi.campaignUpdate(updParam, campaignId).then((res: AxiosResponse<IncResultOutputResponse>) => {
        setUpdResult(res.data);
      });
      history.push(Url.KEISAI.CAMPAIGN);
    } catch {
      history.push(Url.COMMON_ERROR);
    }
  };

  const getTitle = () => {
    switch (pageType) {
      case PAGE_TYPE.DETAIL:
        return TITLE.KEISAI.CAMPAIGN_DETAIL;
      case PAGE_TYPE.MODIFY:
        return TITLE.KEISAI.CAMPAIGN_MODIFY;
      case PAGE_TYPE.CONFIRM:
        return TITLE.KEISAI.CAMPAIGN_MODIFY_CONFIRM;
      default:
    }
    return '';
  };

  return (
    <>
      <MonitorImageModal
        isMonitorBaseImageModal={isMonitorBaseImageModal}
        setIsMonitorImageModal={setIsMonitorBaseImageModal}
        setValue={setValue}
        setImageUrlByTargetMonitorBase={setImageUrlByTargetMonitorBase}
      />
      <LinkedMonitorBaseModal
        mode="campaign"
        id={campaignId}
        isLinkedMonitorBaseModal={isLinkedShopModal}
        setIsLinkedMonitorBaseModal={setIsLinkedShopModal}
        monitorBaseList={monitorBaseList}
        setMonitorBaseList={setMonitorBaseList}
      />
      <OemModal
        mode="campaign"
        id={campaignId}
        isOemModal={isOemModal}
        setIsOemModal={setIsOemModal}
        oemList={oemList}
        setOemList={setOemList}
      />

      <Title className="mb-4">{getTitle()}</Title>
      {updResult?.result && <Alert variant="success">確定しました。</Alert>}
      {updResult?.errorCode && updResult?.errorMessage && (
        <Alert variant="danger">{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}
      {pageType === PAGE_TYPE.DETAIL && (
        <div className="d-flex justify-content-end mb-4">
          <Button variant="link" onClick={() => history.goBack()}>
            キャンセル
          </Button>

          <a
            className="text-dark btn"
            // TODO: 後で変更
            href="https://www.fancrew.jp/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faEye} fixedWidth className="me-1" />
            プレビュー
          </a>

          <Button
            variant="link"
            className="text-dark"
            onClick={() => {
              window.history.pushState(null, '', Url.KEISAI.CAMPAIGN_MODIFY);
              setPageType(PAGE_TYPE.MODIFY);
            }}
          >
            <FontAwesomeIcon icon={faPen} fixedWidth className="me-1" />
            編集
          </Button>
        </div>
      )}

      <Form onSubmit={handleSubmit(handleClickConfirm)}>
        {pageType === PAGE_TYPE.MODIFY && (
          <div className="d-flex justify-content-end mb-4">
            <Button
              variant="link"
              onClick={() => {
                setPageType(PAGE_TYPE.DETAIL);
                history.goBack();
              }}
            >
              キャンセル
            </Button>
            <SubmitPrimaryButton className="ms-2">確認画面へ</SubmitPrimaryButton>
          </div>
        )}
        {pageType === PAGE_TYPE.CONFIRM && (
          <div className="d-flex justify-content-end mb-4">
            <Button
              variant="link"
              className="ms-2"
              onClick={() => {
                setPageType(PAGE_TYPE.MODIFY);
                setUpdResult({ result: false, errorCode: '', errorMessage: '' });
                history.goBack();
              }}
            >
              キャンセル
            </Button>
            <Button type="submit" className="ms-2" onClick={() => onSubmit(getValues())}>
              確定
            </Button>
          </div>
        )}

        <fieldset disabled={pageType !== PAGE_TYPE.MODIFY}>
          <Form.Group className="mb-4">
            <Form.Label>
              キャンペーン名<span className="text-danger">*</span>
            </Form.Label>
            <ValidationFormControl register={register} registerName="name" type="text" errorsTargetName={errors.name} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              キャンペーン名（かな）<span className="text-danger">*</span>
            </Form.Label>
            <ValidationFormControl
              register={register}
              registerName="nameKana"
              type="text"
              errorsTargetName={errors.nameKana}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              概要<span className="text-danger">*</span>
            </Form.Label>
            <ValidationFormControl
              register={register}
              registerName="overview"
              type="text"
              as="textarea"
              rows={4}
              errorsTargetName={errors.overview}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              キャッチコピー<span className="text-danger">*</span>
            </Form.Label>
            <ValidationFormControl
              register={register}
              registerName="catchCopy"
              type="text"
              errorsTargetName={errors.catchCopy}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>設定画像</Form.Label>
            {pageType === PAGE_TYPE.DETAIL ? (
              <ImageCard url={image.url || imageUrlByTargetMonitorBase} name={image.name || ''} isActive={false} />
            ) : (
              <>
                <Form.Check id="radio1" className="d-flex mb-2">
                  <Form.Check.Input
                    className="flex-shrink-0 me-2"
                    type="radio"
                    name="radioGroup01"
                    checked={campaignImageType === CAMPAIGN_IMAGE_TYPE.SELF}
                    onChange={() => setCampaignImageType(CAMPAIGN_IMAGE_TYPE.SELF)}
                  />
                  <div className="mt-4 w-100">
                    {image.url ? (
                      <ImageCard
                        url={image.url || ''}
                        name={image.name || ''}
                        onDelete={() => setImage((prev) => ({ isDefault: prev.isDefault, id: prev.id }))}
                        isActive={pageType === PAGE_TYPE.MODIFY}
                      />
                    ) : (
                      <Dropzone onDrop={onDrop} />
                    )}
                  </div>
                </Form.Check>

                <Form.Check id="radio2" className="d-flex">
                  <Form.Check.Input
                    className="flex-shrink-0 me-2"
                    type="radio"
                    name="radioGroup01"
                    checked={campaignImageType === CAMPAIGN_IMAGE_TYPE.MONITOR}
                    onChange={() => setCampaignImageType(CAMPAIGN_IMAGE_TYPE.MONITOR)}
                  />
                  <div className="w-100 mt-2">
                    <Table borderless className="mb-0">
                      <thead>
                        <tr className="align-middle">
                          <th className="col-4">
                            <div className="d-flex align-items-center gap-2">
                              <span>モニターベースID</span>
                              {pageType === PAGE_TYPE.MODIFY && (
                                <Button variant="link" onClick={() => setIsMonitorBaseImageModal(true)}>
                                  (検索はこちら)
                                </Button>
                              )}
                            </div>
                          </th>
                          <th className="col-4">モニターベース名</th>
                          <th className="col-4">画像</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <FormControl register={register} registerName="targetMonitorBaseId" type="number" />
                          </td>
                          <td>{targetMonitorBaseName}</td>
                          <td>
                            {imageUrlByTargetMonitorBase && (
                              <img
                                src={imageUrlByTargetMonitorBase}
                                style={{ width: '90px', height: '63px', objectFit: 'cover' }}
                                alt="imageForShop"
                              />
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Form.Check>
              </>
            )}
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              表示設定<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select className="mb-4" defaultValue={SHOW_TYPE.get(ShowTypeEnum.DISPLAY)} {...register('showType')}>
              <option value={SHOW_TYPE.get(ShowTypeEnum.DISPLAY)}>{SHOW_TYPE.get(ShowTypeEnum.DISPLAY)}</option>
              <option value={SHOW_TYPE.get(ShowTypeEnum.HIDE)}>{SHOW_TYPE.get(ShowTypeEnum.HIDE)}</option>
              <option value={SHOW_TYPE.get(ShowTypeEnum.CLOSED)}>{SHOW_TYPE.get(ShowTypeEnum.CLOSED)}</option>
            </Form.Select>

            <Form.Group className="ms-4">
              <Form.Label>公開開始日</Form.Label>
              <span className="text-danger">*</span>
              <ValidationFormControl
                register={register}
                registerName="publicStartDate"
                type="datetime-local"
                errorsTargetName={errors.publicStartDate}
              />
            </Form.Group>

            <Form.Group className="ms-4">
              <Form.Label>公開終了日</Form.Label>
              <FormControl register={register} registerName="publicEndDate" type="datetime-local" />
            </Form.Group>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              掲載する店舗の表示状態<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select defaultValue={SHOW_TYPE.get(ShowTypeEnum.DISPLAY)} {...register('shopShowType')}>
              <option value={SHOW_TYPE.get(ShowTypeEnum.DISPLAY)}>{SHOW_TYPE.get(ShowTypeEnum.DISPLAY)}</option>
              <option value={SHOW_TYPE.get(ShowTypeEnum.CLOSED)}>{SHOW_TYPE.get(ShowTypeEnum.CLOSED)}</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>紐付いているモニターベース一覧</Form.Label>
            {monitorBaseList.length > 0 && (
              <div className="d-flex" style={{ width: '100%', height: '100%' }}>
                <div className="form-floating ms-2 me-3 mb-1" style={{ width: '10%' }}>
                  モニターベースID
                </div>
                <div className="me-3" style={{ width: '13%' }}>
                  モニターベース名
                </div>
                <div className="me-3" style={{ width: '7%' }}>
                  実店舗ID
                </div>
                <div className="me-2" style={{ width: '31%' }}>
                  実店舗名
                </div>
                <div className="me-3" style={{ width: '8%' }}>
                  クライアントID
                </div>
                <div className="me-4" style={{ width: '31%' }}>
                  クライアント名
                </div>
              </div>
            )}
            <ListGroup
              className={monitorBaseList.length > 0 ? 'mb-3' : ''}
              style={{ maxHeight: '206px', overflowY: 'scroll' }}
            >
              {monitorBaseList?.map((item) => {
                return (
                  <ListGroup.Item key={item.monitorBaseId}>
                    <div className="d-flex" style={{ width: '100%', height: '100%' }}>
                      <div className="form-floating me-3" style={{ width: '10%' }}>
                        {item.monitorBaseId}
                      </div>
                      <div className="me-3" style={{ width: '13%' }}>
                        {item.monitorBaseName}
                      </div>
                      <div className="me-3" style={{ width: '7%' }}>
                        {item.shopId}
                      </div>
                      <div className="me-3" style={{ width: '31%' }}>
                        {item.shopName}
                      </div>
                      <div className="me-3" style={{ width: '8%' }}>
                        {item.clientId}
                      </div>
                      <div style={{ width: '31%' }}>{item.clientName}</div>
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
            {pageType === PAGE_TYPE.MODIFY && <Button onClick={() => setIsLinkedShopModal(true)}>編集</Button>}
          </Form.Group>

          <Form.Check id="floatingRewardAutoFlg" className="d-flex mb-4">
            <Form.Check.Input className="flex-shrink-0 me-2" type="checkbox" {...register('floatingRewardAutoFlg')} />
            <Form.Check.Label>変動謝礼をしているモニターベースを自動紐付けする</Form.Check.Label>
          </Form.Check>

          <Form.Group className="mb-4">
            <Form.Label>公開するＯＥＭ一覧</Form.Label>
            {pageType === PAGE_TYPE.MODIFY && isRecommendOemAlert && (
              <Alert variant="danger">モニターベースを紐付けてから再度ボタンを押下して下さい。</Alert>
            )}
            {pageType === PAGE_TYPE.MODIFY && (
              <Button className="d-block mb-3" onClick={() => searchRecommendOemList()}>
                推奨選択済みにする
              </Button>
            )}
            <ListGroup
              className={oemList?.length > 0 ? 'mb-3' : ''}
              style={{ maxHeight: '206px', overflowY: 'scroll' }}
            >
              {oemList?.map((item) => {
                return <ListGroup.Item key={item.oemName}>{item.oemName}</ListGroup.Item>;
              })}
            </ListGroup>
            {pageType === PAGE_TYPE.MODIFY && <Button onClick={() => setIsOemModal(true)}>編集</Button>}
          </Form.Group>

          <Form.Check id="oemShowFlg" className="d-flex mb-4">
            <Form.Check.Input className="flex-shrink-0 me-2" type="checkbox" {...register('oemShowFlg')} />
            <Form.Check.Label>OEMへ公開を許可する</Form.Check.Label>
          </Form.Check>

          <Form.Check id="noIndexFlg" className="d-flex mb-4">
            <Form.Check.Input className="flex-shrink-0 me-2" type="checkbox" {...register('noIndexFlg')} />
            <Form.Check.Label>noIndex設定</Form.Check.Label>
          </Form.Check>

          <Form.Group className="mb-4">
            <Form.Label>タイトルタグ／H1タグ</Form.Label>
            <FormControl register={register} registerName="titleH1Tag" type="text" />
          </Form.Group>
        </fieldset>
      </Form>
    </>
  );
};
