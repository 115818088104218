import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
// eslint-disable-next-line import/no-cycle
import { CustomerDetailPage } from './CustomerDetailPage';
import { Url } from '../../../constants/Url';
// eslint-disable-next-line import/no-cycle
import { CustomerInfoApi } from '../../../api-client';
import type { CustomerInformationDetailDisplayOutputResponse } from '../../../api-client';
import { CustomerInfoHeader } from './CustomerInfoHeader';

export type TestIds =
  | 'applicationHistory'
  | 'pointHistory'
  | 'giftTicketList'
  | 'mailHistory'
  | 'loginHistory'
  | 'relatedCustomer'
  | 'authHistory'
  | 'clientNg'
  | 'agentLogin';

export const CustomerInfoPage: React.FC = () => {
  const { id } = useParams<{ id: string | undefined }>();

  return (
    <>
      <CustomerInfoHeader activeKey="customerInfo" customerId={Number(id)} />
      {id ? <CustomerDetailPage id={id} /> : <></>}
    </>
  );
};
