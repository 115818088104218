import React, { useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Row, Col, Tabs, Tab, Table, FloatingLabel } from 'react-bootstrap';
import { createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { EnqueteListApi, EnqueteListOutputResponse, CommonMasterListOutputResponse } from '../../../api-client';
import { RecommendFloatingLabel } from '../../molecules/RecommendFloatingLabel';

export interface Props {
  isModal: boolean;
  onSave: (id: number, name: string) => void;
  onHide: () => void;
  client?: string;
}

export interface State {
  api: EnqueteListApi;
  tabType: TabType;
  currentEnqueteId: number | null;
  currentEnqueteName: string;
  lists: {
    selection: EnqueteListOutputResponse[];
    stacking: EnqueteListOutputResponse[];
    other: EnqueteListOutputResponse[];
    membership: EnqueteListOutputResponse[];
    enqueteMonitor: EnqueteListOutputResponse[];
  };
  listsPerPage: {
    selection: EnqueteListOutputResponse[];
    stacking: EnqueteListOutputResponse[];
    other: EnqueteListOutputResponse[];
    membership: EnqueteListOutputResponse[];
    enqueteMonitor: EnqueteListOutputResponse[];
  };
  currentPages: { selection: number; stacking: number; other: number; membership: number; enqueteMonitor: number };
  totalPages: { selection: number; stacking: number; other: number; membership: number; enqueteMonitor: number };
  searchParams: {
    selection: SearchParam;
    stacking: SearchParam;
    other: SearchParam;
    membership: SearchParam;
    enqueteMonitor: SearchParam;
  };
}

type TabType = typeof ENQUETE_TYPE[keyof typeof ENQUETE_TYPE]['key'];

type SearchParam = {
  enqueteId: number | undefined;
  enqueteName: string | undefined;
  businessCategoryId: number | undefined;
  questionId: number | undefined;
  questionContent: string | undefined;
  clientName: string | undefined;
  enqueteType: typeof ENQUETE_TYPE[keyof typeof ENQUETE_TYPE]['id'];
};

const ENQUETE_TYPE = {
  selection: { id: 2, key: 'selection', name: '事後 選択式' },
  stacking: { id: 3, key: 'stacking', name: '事後 積上式' },
  other: { id: 6, key: 'other', name: '事後 その他' },
  membership: { id: 4, key: 'membership', name: '会員属性' },
  enqueteMonitor: { id: 5, key: 'enqueteMonitor', name: 'アンケートモニター' },
} as const;

const MAX_RECORD_PER_PAGE = 100;

const initialSearchParam = {
  enqueteId: undefined,
  enqueteName: undefined,
  businessCategoryId: undefined,
  questionId: undefined,
  questionContent: undefined,
  clientName: undefined,
};

export const MonitorPostEnqueteModal: React.VFC<Props> = ({ isModal, onSave, onHide, client }) => {
  const testid = createTestId(MonitorPostEnqueteModal);

  const { state: $, mergeState } = useLargeState<State>({
    api: new EnqueteListApi(),
    tabType: ENQUETE_TYPE.selection.key,
    currentEnqueteId: null,
    currentEnqueteName: '',
    lists: { selection: [], stacking: [], other: [], membership: [], enqueteMonitor: [] },
    listsPerPage: { selection: [], stacking: [], other: [], membership: [], enqueteMonitor: [] },
    currentPages: { selection: 1, stacking: 1, other: 1, membership: 1, enqueteMonitor: 1 },
    totalPages: { selection: 1, stacking: 1, other: 1, membership: 1, enqueteMonitor: 1 },
    searchParams: {
      selection: { ...initialSearchParam, clientName: client, enqueteType: ENQUETE_TYPE.selection.id },
      stacking: { ...initialSearchParam, clientName: client, enqueteType: ENQUETE_TYPE.stacking.id },
      other: { ...initialSearchParam, clientName: client, enqueteType: ENQUETE_TYPE.other.id },
      membership: { ...initialSearchParam, clientName: client, enqueteType: ENQUETE_TYPE.membership.id },
      enqueteMonitor: { ...initialSearchParam, clientName: client, enqueteType: ENQUETE_TYPE.enqueteMonitor.id },
    },
  });

  const setPagingStates = useCallback(
    (list: EnqueteListOutputResponse[]) => {
      const sortList = list.sort((a, b) => b.enqueteId - a.enqueteId);
      mergeState({
        lists: { ...$.lists, [$.tabType]: sortList },
        listsPerPage: { ...$.listsPerPage, [$.tabType]: sortList.slice(0, MAX_RECORD_PER_PAGE) },
        totalPages: { ...$.totalPages, [$.tabType]: Math.ceil(Number(sortList?.length) / MAX_RECORD_PER_PAGE) },
        currentPages: { ...$.currentPages, [$.tabType]: 1 },
      });
    },
    [mergeState, $.tabType]
  );

  useEffect(() => {
    if (!isModal || $.lists[$.tabType].length !== 0) return;
    const { enqueteId, enqueteName, questionId, questionContent, clientName, enqueteType } = $.searchParams[$.tabType];
    $.api
      .enqueteList(enqueteType, enqueteId, enqueteName, questionId, questionContent, clientName)
      .then((res: AxiosResponse<EnqueteListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
  }, [isModal, $.tabType, $.api]);

  // useEffect(() => {
  //   console.log($);
  // }, [$]);

  const onSearch = () => {
    const { enqueteId, enqueteName, questionId, questionContent, clientName, enqueteType } = $.searchParams[$.tabType];
    $.api
      .enqueteList(enqueteType, enqueteId, enqueteName, questionId, questionContent, clientName)
      .then((res: AxiosResponse<EnqueteListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
  };

  return (
    <Modal
      onHide={onHide}
      isModal={isModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button variant="link" className="ms-2" onClick={onHide} data-testid={testid('cancel')}>
              キャンセル
            </Button>
            <Button
              className="ms-2"
              disabled={!$.currentEnqueteId}
              data-testid={testid('save-button')}
              onClick={() => onSave($.currentEnqueteId as number, $.currentEnqueteName)}
            >
              保存
            </Button>
          </div>

          <Tabs
            activeKey={$.tabType}
            onSelect={(key) => mergeState({ tabType: key as TabType })}
            className="mb-4"
            data-testid={testid('tabs')}
          >
            {Object.values(ENQUETE_TYPE).map(({ key, name }) => (
              <Tab key={key} eventKey={key} title={name} />
            ))}
          </Tabs>

          <>
            <Form.Group className="bg-light p-4 mb-4">
              <Row className="mb-3">
                <Col className="px-2">
                  <FloatingLabel label="アンケートID" data-testid={testid('enqueteId')}>
                    <Form.Control
                      type="number"
                      placeholder="アンケートID"
                      value={$.searchParams[$.tabType].enqueteId || ''}
                      onChange={(e) =>
                        mergeState({
                          searchParams: {
                            ...$.searchParams,
                            [$.tabType]: { ...$.searchParams[$.tabType], enqueteId: Number(e.target.value) },
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col className="px-2">
                  <FloatingLabel label="アンケート名" data-testid={testid('enqueteName')}>
                    <Form.Control
                      type="text"
                      placeholder="アンケート名"
                      value={$.searchParams[$.tabType].enqueteName || ''}
                      onChange={(e) =>
                        mergeState({
                          searchParams: {
                            ...$.searchParams,
                            [$.tabType]: { ...$.searchParams[$.tabType], enqueteName: e.target.value },
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="px-2">
                  <FloatingLabel label="設問ID" data-testid={testid('questionId')}>
                    <Form.Control
                      type="number"
                      placeholder="設問ID"
                      value={$.searchParams[$.tabType].questionId || ''}
                      onChange={(e) =>
                        mergeState({
                          searchParams: {
                            ...$.searchParams,
                            [$.tabType]: { ...$.searchParams[$.tabType], questionId: Number(e.target.value) },
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col className="px-2">
                  <FloatingLabel label="設問" data-testid={testid('questionContent')}>
                    <Form.Control
                      type="text"
                      placeholder="設問"
                      value={$.searchParams[$.tabType].questionContent || ''}
                      onChange={(e) =>
                        mergeState({
                          searchParams: {
                            ...$.searchParams,
                            [$.tabType]: { ...$.searchParams[$.tabType], questionContent: e.target.value },
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col className="px-2">
                  <FloatingLabel label="管理クライアント" data-testid={testid('clientName')}>
                    <Form.Control
                      type="text"
                      placeholder="管理クライアント"
                      value={$.searchParams[$.tabType].clientName || ''}
                      disabled
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col className="col-4 px-2">
                  <Button className="w-100 py-2" onClick={onSearch} data-testid={testid('search-button')}>
                    検索
                  </Button>
                </Col>
              </Row>
            </Form.Group>
            <div className="mb-4" data-testid={testid('pagination')}>
              <PaginationWithEllipsis
                currentPage={$.currentPages[$.tabType]}
                totalPage={$.totalPages[$.tabType]}
                handleClick={(page) => {
                  if (!page || page > $.totalPages[$.tabType]) return;
                  mergeState({
                    listsPerPage: {
                      ...$.listsPerPage,
                      [$.tabType]: $.lists[$.tabType].slice(
                        (page - 1) * MAX_RECORD_PER_PAGE,
                        page * MAX_RECORD_PER_PAGE
                      ),
                    },
                    currentPages: { ...$.currentPages, [$.tabType]: page },
                  });
                }}
              />
            </div>
            <Table className="text-nowrap" data-testid={testid('table')}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>アンケートID</th>
                  <th>アンケート名 / 説明</th>
                  <th>管理クライアント</th>
                </tr>
              </thead>
              <tbody>
                {$.listsPerPage[$.tabType].map((item, i) => (
                  <tr key={item.enqueteId} className="align-middle">
                    <td>
                      <Form.Check
                        id={`${$.tabType}${item.enqueteId}`}
                        type="radio"
                        name="radioGroup01"
                        data-testid={testid('radio', i)}
                        checked={$.currentEnqueteId === item.enqueteId}
                        onChange={() => {
                          mergeState({ currentEnqueteId: item.enqueteId, currentEnqueteName: item.enqueteName });
                        }}
                      />
                    </td>
                    <td>{item.enqueteId}</td>
                    <td>
                      {item.enqueteName}
                      <br />
                      <span className="text-secondary">{item.description}</span>
                    </td>
                    <td>{item.clientName}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        </>
      }
    />
  );
};
