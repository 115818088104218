import { useRecoilState } from 'recoil';
import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import {
  BillingApi,
  UnBillingListOutputResponse,
  UnBillingApplyFormResponse,
  UnBillingApplyFormRowDataResponse,
  IncResultOutputResponse,
  BillingInvoiceApproverListOutputResponse,
} from '../../api-client';
import { ResultAlert } from '../organisms/Alert/ResultAlert';
import { BILLING_APPROVAL_TYPE, BILLING_DEFAULT_FADE_TIME } from '../../BillingConstants';
import { convertMoneyText } from '../../utils/functionsForBilling';
import useSearchQuery from '../../hooks/useSearchQuery';
import { InputNumber } from '../atoms/InputNumber';
import { currentUserState } from '../../states/currentUser';

type SearchParams = {
  type: number;
  contractShopId?: number;
  monitorBaseId?: number;
  startYm?: string;
  endYm?: string;
  applicationIds?: string;
  billingHeaderId?: number;
};

interface RouterParams {
  type: string;
  billingHeaderId?: string;
}

export const YuucomiManagerPage: React.VFC = () => {
  const [list, setList] = useState<UnBillingListOutputResponse[]>([]);
  const { type, billingHeaderId } = useParams<RouterParams>();
  const { getSearchParams } = useSearchQuery();
  const currentUser = useRecoilState(currentUserState);

  const initSearchCondition: () => SearchParams = () => {
    const searchConditions = {
      type: Number(type),
      contractShopId:
        getSearchParams('contractShopId') !== null ? Number(getSearchParams('contractShopId')) : undefined,
      monitorBaseId: getSearchParams('monitorBaseId') !== null ? Number(getSearchParams('monitorBaseId')) : undefined,
      startYm: getSearchParams('startYm') !== null ? getSearchParams('startYm') : undefined,
      endYm: getSearchParams('endYm') !== null ? getSearchParams('endYm') : undefined,
      applicationIds: getSearchParams('applicationIds') !== null ? getSearchParams('applicationIds') : undefined,
      billingHeaderId: billingHeaderId === undefined ? undefined : Number(billingHeaderId),
    } as SearchParams;

    return searchConditions;
  };

  const [searchParam, setSearchParam] = useState<SearchParams>(() => {
    return initSearchCondition();
  });

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const [billingApproverList, setBillingApproverList] = useState<BillingInvoiceApproverListOutputResponse>();
  const [selectedApproverId, setSelectedApproverId] = useState<number>();

  const billingApi = new BillingApi();

  const fetchData = (): void => {
    // 検索処理
    billingApi
      .unbillingList(
        searchParam.type,
        searchParam.contractShopId,
        searchParam.monitorBaseId,
        searchParam.startYm,
        searchParam.endYm,
        searchParam.applicationIds,
        searchParam.billingHeaderId
      )
      .then((res: AxiosResponse<UnBillingListOutputResponse[]>) => {
        setList(res.data);
      });
  };

  const fetchBillingApproverList = () => {
    billingApi.billingApproverList(currentUser[0]!.id).then((res) => {
      setBillingApproverList(res.data);
      // 上長がいない場合は上長リストの最初の人を上長として設定する
      setSelectedApproverId(
        res.data.selected === undefined || res.data.selected === null
          ? res.data.billingApproverList[0].id
          : res.data.selected
      );
    });
  };

  const handleClickApplyBtn = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const newApplyList: UnBillingApplyFormRowDataResponse[] = [];
    list.map((item) => {
      newApplyList.push({
        applyId: item.applyId,
        clientName: item.clientName,
        completeAt: item.completeAt,
        hideAnswerFlg: item.hideAnswerFlg,
        reason: item.reason,
        shopName: item.contractShopName,
        status: item.status || '',
        unBillingFlg: item.unBillingFlg,
        increaseSlotFlg: item.increaseSlotFlg,
        preventionComment: item.preventionComment,
      });
    });

    const appList = {
      type: Number(searchParam.type),
      approverId: selectedApproverId,
      unBillingList: newApplyList,
    } as UnBillingApplyFormResponse;
    billingApi.unbillingApply(appList).then((response: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(response.data);
      fetchData();
    });
  };

  const handleOnChangeUnBilling = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    setList((current) => {
      const newList = current.slice();
      newList[index].unBillingFlg = event.target.checked;
      return newList;
    });
  };

  const handleOnChangeHideAnswer = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    setList((current) => {
      const newList = current.slice();
      newList[index].hideAnswerFlg = event.target.checked;
      return newList;
    });
  };

  const handleOnChangeIncreaseSlot = (event: React.ChangeEvent<HTMLInputElement>, index: number): void => {
    setList((current) => {
      const newList = current.slice();
      newList[index].increaseSlotFlg = event.target.checked;
      return newList;
    });
  };

  const handleCheckAllUnBillingFlg = () => {
    const newList = list.map((v) => {
      return { ...v, unBillingFlg: !v.unBillingFlg };
    });
    setList(newList);
  };

  const handleCheckAllhideAnswerFlg = () => {
    const newList = list.map((v) => {
      return { ...v, hideAnswerFlg: !v.hideAnswerFlg };
    });
    setList(newList);
  };

  const handleOnChangeReason = (e: any, id: number) => {
    setList((current) => {
      const newList = current.slice();
      newList.forEach((newitem, index) => {
        if (newList[index].applyId === id) {
          newList[index].reason = e.target.value;
        }
      });

      return newList;
    });
  };

  const handleOnChangePreventionComment = (e: any, id: number) => {
    setList((current) => {
      const newList = current.slice();
      newList.forEach((newitem, index) => {
        if (newList[index].applyId === id) {
          newList[index].preventionComment = e.target.value;
        }
      });

      return newList;
    });
  };

  useEffect(() => {
    // 非請求申請から飛んできた場合は初回表示で検索する。
    if (searchParam.billingHeaderId !== undefined) {
      fetchData();
    }
    fetchBillingApproverList();
  }, []);

  return (
    <>
      <Form>
        <Container>
          <Row className="g-2 mb-4">
            <Col className="col-6">
              <Form.Group controlId="shopId">
                <Row>
                  <Col className="col-4">
                    <Form.Label className="mt-2 me-2">契約店舗ID</Form.Label>
                  </Col>
                  <Col className="col-8">
                    <InputNumber
                      onChange={(e) =>
                        setSearchParam((prevState) => ({
                          ...prevState,
                          contractShopId: e.target.value ? Number(e.target.value) : undefined,
                        }))
                      }
                      value={searchParam.contractShopId}
                      min={0}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col className="col-6">
              <Form.Group controlId="applicationIds">
                <Row>
                  <Col className="col-4">
                    <Form.Label className="mt-2 me-2">モニターベースID</Form.Label>
                  </Col>
                  <Col className="col-8">
                    <Form.Control
                      type="number"
                      value={searchParam.monitorBaseId}
                      name="monitorBaseId"
                      onChange={(e) =>
                        setSearchParam((prevState) => ({ ...prevState, monitorBaseId: Number(e.target.value) }))
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row className="g-2 mb-4">
            <Col className="col-6">
              <Form.Group controlId="startYm">
                <Row>
                  <Col className="col-4">
                    <Form.Label className="mt-2 me-2">モニター期間</Form.Label>
                  </Col>
                  <Col className="col-3">
                    <Form.Control
                      type="date"
                      name="startYm"
                      value={searchParam.startYm}
                      onChange={(e) => setSearchParam((prevState) => ({ ...prevState, startYm: e.target.value }))}
                    />
                  </Col>
                  ～
                  <Col className="col-3">
                    <Form.Control
                      type="date"
                      name="endYm"
                      value={searchParam.endYm}
                      onChange={(e) => setSearchParam((prevState) => ({ ...prevState, endYm: e.target.value }))}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col className="col-6">
              <Form.Group controlId="applicationIds">
                <Row>
                  <Col className="col-4">
                    <Form.Label className="mt-2 me-2">応募ID(カンマ区切りで複数可能)</Form.Label>
                  </Col>
                  <Col className="col-8">
                    <Form.Control
                      type="text"
                      value={searchParam.applicationIds}
                      name="applicationIds"
                      onChange={(e) =>
                        setSearchParam((prevState) => ({ ...prevState, applicationIds: e.target.value }))
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
          <Row className="g-2 mb-4">
            <Col className="col-6">
              <Form.Group controlId="billingHeaderId">
                <Row>
                  <Col className="col-4">
                    <Form.Label className="mt-2 me-2">請求書ヘッダID</Form.Label>
                  </Col>
                  <Col className="col-8">
                    <InputNumber
                      min={0}
                      value={searchParam.billingHeaderId}
                      onChange={(e) =>
                        setSearchParam((prevState) => ({
                          ...prevState,
                          billingHeaderId: e.target.value ? Number(e.target.value) : undefined,
                        }))
                      }
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col className="col-6">
              <Form.Group>
                <Row>{}</Row>
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <Button onClick={fetchData}>検索</Button>
          </div>
        </Container>
      </Form>
      <div className="d-flex justify-content-end mb-4">
        <Button variant="secondary" className="me-4" onClick={handleCheckAllUnBillingFlg}>
          一括非請求
        </Button>
        <Button variant="secondary" className="me-4" onClick={handleCheckAllhideAnswerFlg}>
          一括管理画面非表示
        </Button>
      </div>
      <ResultAlert updResult={updResult} fadeoutTime={BILLING_DEFAULT_FADE_TIME} setUpdResult={setUpdResult} />
      <Table style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>クライアント名</th>
            <th>店舗名</th>
            <th>モニターベース名</th>
            <th>
              店舗の
              <br />
              表示状態
            </th>
            <th>応募ID</th>
            <th>来店日時</th>
            <th>性別</th>
            <th>利用金額</th>
            <th>完了日</th>
            <th>申請ステータス</th>
            <th>非請求</th>
            <th>
              管理画面
              <br />
              非表示
            </th>
            <th>増枠</th>
            <th style={{ width: '300px' }}>理由</th>
            {searchParam.type === BILLING_APPROVAL_TYPE.SALES && (
              <th style={{ width: '300px' }}>
                再発防止
                <br />
                コメント
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {list.map((item, idx) => {
            return (
              <tr key={idx} className="align-middle">
                <td>{item.clientName}</td>
                <td>{item.contractShopName}</td>
                <td>{item.monitorBaseName}</td>
                <td>{item.monitorStatus}</td>
                <td>{item.applyId}</td>
                <td>{item.visitAt}</td>
                <td>{item.gender === 0 ? '男性' : '女性'}</td>
                <td>{convertMoneyText(item.customerAmount)}</td>
                <td>{item.completeAt}</td>
                <td>{item.status}</td>
                <td>
                  <Form.Check.Input
                    className="flex-shrink-0 me-2"
                    checked={item.unBillingFlg}
                    type="checkbox"
                    name="unBilling"
                    value={idx}
                    onChange={(e) => handleOnChangeUnBilling(e, idx)}
                    disabled={!item.canUnBillingFlg}
                  />
                </td>
                <td>
                  <Form.Check.Input
                    className="flex-shrink-0 me-2"
                    checked={item.hideAnswerFlg}
                    type="checkbox"
                    name="unBilling"
                    value={idx}
                    onChange={(e) => handleOnChangeHideAnswer(e, idx)}
                    disabled={!item.canUnDisplayTobFlg}
                  />
                </td>
                <td>
                  <Form.Check.Input
                    className="flex-shrink-0 me-2"
                    checked={item.increaseSlotFlg}
                    type="checkbox"
                    name="increaseSlotFlg"
                    value={idx}
                    onChange={(e) => handleOnChangeIncreaseSlot(e, idx)}
                    disabled={!item.canIncreaseSlotFlg}
                  />
                </td>
                <td>
                  <Form.Control
                    as="textarea"
                    rows={1}
                    name="reason"
                    defaultValue={item.reason}
                    readOnly={!!item.status}
                    onChange={(e) => handleOnChangeReason(e, item.applyId)}
                  />
                </td>
                <td>
                  {searchParam.type === BILLING_APPROVAL_TYPE.SALES && (
                    <Form.Control
                      as="textarea"
                      rows={1}
                      name="reason"
                      defaultValue={item.reason}
                      readOnly={!!item.status}
                      onChange={(e) => handleOnChangePreventionComment(e, item.applyId)}
                    />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {Number(type) === BILLING_APPROVAL_TYPE.SALES && (
        <div>
          <Form.Label>承認者</Form.Label>
          <Form.Select onChange={(e) => setSelectedApproverId(Number(e.target.value))} value={selectedApproverId}>
            {billingApproverList?.billingApproverList.map((v) => {
              if (v.id !== currentUser[0]!.id) {
                // 自分自身以外を承認者として選択することができる
                return (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                );
              }
            })}
          </Form.Select>
        </div>
      )}
      <div className="d-flex justify-content-end mt-4 mb-2">
        <Button variant="secondary" className="me-4" onClick={handleClickApplyBtn} disabled={list.length === 0}>
          申請
        </Button>
      </div>
    </>
  );
};
