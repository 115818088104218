import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { CustomerInfoApi, LoginHistoryDisplayOutputResponse } from '../../../api-client';
import { LoginHistoryTable } from '../../organisms/Table/LoginHistoryTable';
import { when } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import { CustomerInfoHeader } from './CustomerInfoHeader';

export interface LoginHistoryState {
  api: CustomerInfoApi;
  isLoading: boolean;
  list: LoginHistoryDisplayOutputResponse[];
  errorMessage: string[];
}

export type TestIds = 'plus-button' | 'sms-button';

export const LoginHistoryPage: React.FC = () => {
  const { state: $, mergeState } = useLargeState<LoginHistoryState>({
    api: new CustomerInfoApi(),
    isLoading: false,
    list: [],
    errorMessage: [],
  });

  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();

  useEffect(() => {
    if (id && Number(id) > 0) {
      mergeState({ isLoading: true });
      $.api
        .loginHistoryDisplay(Number(id))
        .then((res: AxiosResponse<LoginHistoryDisplayOutputResponse[]>) => {
          // 降順にソート
          const sortData = res.data.sort((a, b) => (a.loginAt > b.loginAt ? -1 : 1));
          mergeState({ list: sortData, errorMessage: [] });
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        })
        .finally(() => {
          mergeState({ isLoading: false });
        });
    }
  }, [$.api, history, id, mergeState]);

  return (
    <>
      <CustomerInfoHeader activeKey="loginHistory" customerId={Number(id)} />
      <LoadingSpinner isLoading={$.isLoading}>
        <LoginHistoryTable dataList={$.list} />
      </LoadingSpinner>
    </>
  );
};
