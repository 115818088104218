import React, { useCallback, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { Modal } from '../../molecules/Modal';
// eslint-disable-next-line import/no-cycle
import { PaidServicePointReturnApi } from '../../../api-client';
import type {
  PaidServicePointReturnRegisterDisplayFormResponse,
  PaidServicePointReturnRegisterDisplayOutputResponse,
  PaidServicePointReturnRegisterExecutionFormResponse,
  PaidServiceTypeListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { PaidServicePointReturnRegisterForms } from '../../organisms/Forms/PaidServicePointReturnRegisterForms';
import { PaidServicePointReturnRegisterTable } from '../../organisms/Table/PaidServicePointReturnRegisterTable';
import { createTestId } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Url } from '../../../constants/Url';

export interface PaidServicePointReturnRegisterState {
  api: PaidServicePointReturnApi;
  isLoading: boolean;
  list: PaidServicePointReturnRegisterDisplayOutputResponse[];
  forms: FormState;
  paidServiceTypeList: OptionType[];
  isConfirm: boolean;
  isModal: boolean;
  modalMessage: string;
  errorMessage: string;
}
export type TestIds = 'confirm-button' | 'submit-button' | 'ok-button';
export type OptionType = { value: number; label: string };
export type FormState = { applyIdList: string[]; pointReturnReason: string; targetServiceCode: number[] };

export const PaidServicePointReturnRegisterPage: React.FC = () => {
  const testid = createTestId<TestIds>(PaidServicePointReturnRegisterPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<PaidServicePointReturnRegisterState>({
    api: new PaidServicePointReturnApi(),
    isLoading: false,
    list: [],
    forms: { applyIdList: [], pointReturnReason: '', targetServiceCode: [0, 1] },
    paidServiceTypeList: [],
    isConfirm: false,
    isModal: false,
    modalMessage: '',
    errorMessage: '',
  });

  const history = useHistory();
  const setForms = useBindSet('forms');

  // enum取得
  useEffect(() => {
    mergeState({ isLoading: true });
    $.api.paidServiceTypeList().then((res: AxiosResponse<PaidServiceTypeListOutputResponse[]>) => {
      let targetServiceCodeList: OptionType[] = [];
      if (res.data.length > 0) {
        targetServiceCodeList = res.data.map((targetServiceTypeObj: PaidServiceTypeListOutputResponse) => {
          return {
            label: targetServiceTypeObj.name,
            value: targetServiceTypeObj.code,
          };
        });
        mergeState({
          isLoading: false,
          paidServiceTypeList: targetServiceCodeList,
        });
      }
    });
  }, [$.api, mergeState]);

  // 確認
  const createConfirmBody = useCallback((body: FormState) => {
    return {
      applyIdList: body.applyIdList.map((applyId: string) => Number(applyId)),
      pointReturnReason: body.pointReturnReason,
      targetServiceCode: body.targetServiceCode,
    };
  }, []);

  const onConfirm = useCallback(
    (body: FormState) => {
      if (body.applyIdList.length === 0 || body.pointReturnReason.length === 0 || body.targetServiceCode.length === 0) {
        mergeState({ errorMessage: '入力されていない条件があります', list: [] });
        return;
      }

      if (body.applyIdList.length !== Array.from(new Set(body.applyIdList)).length) {
        mergeState({ errorMessage: '応募IDに重複があります。', list: [] });
        return;
      }
      const confirmBody: PaidServicePointReturnRegisterDisplayFormResponse = createConfirmBody(body);
      $.api
        .paidServicePointReturnRegisterDisplay(confirmBody)
        .then((res: AxiosResponse<PaidServicePointReturnRegisterDisplayOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessage: 'エラーが発生しました' });
          } else {
            mergeState({ list: res.data, errorMessage: '' });
          }
        })
        .catch((error) => {
          mergeState({ list: [], errorMessage: error.errorMessage });
        });
    },
    [$.api, createConfirmBody, mergeState]
  );

  // 実行
  const createRegisterBody = useCallback((body: PaidServicePointReturnRegisterDisplayOutputResponse[]) => {
    const returnList: PaidServicePointReturnRegisterExecutionFormResponse[] = [];
    body.forEach((rowDate: PaidServicePointReturnRegisterDisplayOutputResponse) => {
      returnList.push({
        applyId: rowDate.applyId,
        pointReturnReason: rowDate.pointReturnReason,
        targetServiceCode: rowDate.targetService.code,
      });
    });
    return returnList;
  }, []);

  const onSubmit = useCallback(
    (body: PaidServicePointReturnRegisterDisplayOutputResponse[]) => {
      const registerBody: PaidServicePointReturnRegisterExecutionFormResponse[] = createRegisterBody(body);
      mergeState({ isLoading: true });
      $.api
        .paidServicePointReturnRegisterExecution(registerBody)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (res.data.result) {
            mergeState({ isLoading: false, isModal: true, modalMessage: '登録に成功しました。', errorMessage: '' });
          } else {
            mergeState({ isLoading: false, errorMessage: '登録に失敗しました。' });
          }
        })
        .catch((error: IncResultOutputResponse) => {
          mergeState({ list: [], errorMessage: error.errorMessage, isLoading: false });
        });
    },
    [$.api, createRegisterBody, mergeState]
  );

  return (
    <>
      <Modal
        centered
        isModal={$.isModal}
        body={
          <>
            <p className="text-center">{$.modalMessage}</p>
            <div className="text-center">
              <Button
                data-testid={testid('ok-button')}
                onClick={() => history.push(Url.TENSAKU.PAID_SERVICE_POINT_RETURN_HISTORY)}
              >
                OK
              </Button>
            </div>
          </>
        }
      />
      <LoadingSpinner isLoading={$.isLoading}>
        {$.errorMessage.length ? <Alert variant="danger">{$.errorMessage}</Alert> : undefined}
        <Form className="mt-4">
          <Row className="g-2 mb-4">
            <Col>
              <PaidServicePointReturnRegisterForms setForms={setForms} paidServiceTypeList={$.paidServiceTypeList} />
              <Button
                data-testid={testid('confirm-button')}
                className="mt-4"
                onClick={() => {
                  onConfirm($.forms);
                }}
              >
                確認
              </Button>
            </Col>
          </Row>
        </Form>
        {$.list.length ? (
          <>
            <PaidServicePointReturnRegisterTable list={$.list} />
            <Button
              data-testid={testid('submit-button')}
              className="mt-4"
              onClick={() => {
                onSubmit($.list);
              }}
            >
              実行
            </Button>
          </>
        ) : undefined}
      </LoadingSpinner>
    </>
  );
};
