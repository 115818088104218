import React from 'react';
import { useRecoilState } from 'recoil';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { HeaderLayout } from '../organisms/HeaderLayout';
import { BreadCrumbLayout } from '../organisms/BreadCrumbLayout';
import { FooterLayout } from '../organisms/FooterLayout';
import { SideBarLayout } from '../organisms/SideBarLayout';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../scss/global.scss';
import scss from '../../scss/templates/layout.module.scss';
import { useCurrentUser } from '../../hooks/useCurrentUser';

export const Layout: React.FC<{ isSidebarHide?: boolean }> = ({ isSidebarHide, children }) => {
  const { currentUser } = useCurrentUser();
  return (
    <>
      <div className={scss.wrap_layout}>
        {currentUser !== null && (
          <>
            <input
              type="checkbox"
              id="sidebar_toggleInput"
              className="sidebar_toggleInput d-none"
              defaultChecked={isSidebarHide}
            />
            <SideBarLayout />
            <div className="sidebar_toggle d-flex align-items-center justify-content-center">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="sidebar_toggleInput" className="px-4 py-3">
                <FontAwesomeIcon icon={faBars} className="text-secondary" fixedWidth />
              </label>
            </div>
          </>
        )}
        <div className={scss.wrapper}>
          <Container fluid>
            <HeaderLayout />
            <BreadCrumbLayout />
            <Row className={scss.main}>
              <Col className={scss.main_inner}>
                <Container fluid>{children}</Container>
              </Col>
            </Row>
            <FooterLayout />
          </Container>
        </div>
      </div>
    </>
  );
};
