import React, { Dispatch, SetStateAction } from 'react';
import { Table, Form } from 'react-bootstrap';
import type {
  ClientOfRequiredNumberListOutputResponse,
  ClientOfRequiredNumberTargetUpdateFormResponse,
} from '../../../api-client';

export interface Props {
  listPerPage: ClientOfRequiredNumberListOutputResponse[];
  setListPerPage: Dispatch<SetStateAction<ClientOfRequiredNumberListOutputResponse[]>>;
  updData: ClientOfRequiredNumberTargetUpdateFormResponse;
  setUpdData: Dispatch<SetStateAction<ClientOfRequiredNumberTargetUpdateFormResponse>>;
}

export const ClientOfRequiredNumberTargetTable: React.VFC<Props> = ({
  listPerPage,
  setListPerPage,
  updData,
  setUpdData,
}) => {
  const handleChangeInput = (targetIndex: number, e: React.ChangeEvent<any>) => {
    const targetValue = Number(e.target.value);
    if (Number.isNaN(targetValue)) return;

    setListPerPage(
      listPerPage.map((item, i) => {
        return targetIndex === i ? { ...item, requiredNumber: targetValue } : item;
      })
    );

    const isAlreadyUpdated = updData.clientOfRequiredNumberTargets.find((item) => {
      return item.clientId === listPerPage[targetIndex].clientId;
    });

    if (isAlreadyUpdated) {
      setUpdData({
        clientOfRequiredNumberTargets: updData.clientOfRequiredNumberTargets.map((item) => {
          return item.clientId === listPerPage[targetIndex].clientId ? { ...item, requiredNumber: targetValue } : item;
        }),
      });
    } else {
      setUpdData({
        clientOfRequiredNumberTargets: [
          ...updData.clientOfRequiredNumberTargets,
          { clientId: listPerPage[targetIndex].clientId, requiredNumber: targetValue },
        ],
      });
    }
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>ID</th>
          <th>クライアント名</th>
          <th>担当営業</th>
          <th>派遣マスト数</th>
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((data: ClientOfRequiredNumberListOutputResponse, i: number) => {
          return (
            <tr key={data.clientId}>
              <th>{data.clientId}</th>
              <th>{data.clientName}</th>
              <th>{data.salesStaffName}</th>
              <th>
                <Form.Control
                  type="number"
                  style={{ width: '100px' }}
                  value={data.requiredNumber}
                  onChange={(e) => handleChangeInput(i, e)}
                />
              </th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
