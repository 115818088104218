import React from 'react';
import { Alert as BootstrapAlert } from 'react-bootstrap';

interface Props {
  children: React.ReactNode;
  variant?: 'primary' | 'success' | 'danger';
  testId?: string;
  show?: boolean | undefined;
}

export const Alert: React.FC<Props> = ({ children, testId, variant = 'primary', show }) => {
  return (
    <BootstrapAlert data-testid={testId} variant={variant} show={show}>
      {children}
    </BootstrapAlert>
  );
};
