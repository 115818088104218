import React from 'react';
import type { DisplayPointOutputResponse } from '../../../../../api-client';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { equalDp, useMergeStateOfCommentEditors } from '../../../../pages/Correction/utils';
import { CommentEditor } from '../parts/CommentEditor';

export const TextAnswerEditor: React.FC<{
  /** 送信に使用するDisplayPoint */
  sendingDisplayPoint?: Partial<DisplayPointOutputResponse>;
  answerContent?: string;
  contentCharLengthMin?: number;
  contentCharLengthMax?: number;
  isInputOnlyNumber?: boolean | undefined;
  isEnquete?: boolean;
  answerMethodCode: number;
}> = ({
  sendingDisplayPoint: sdp,
  answerContent,
  contentCharLengthMin,
  contentCharLengthMax,
  isInputOnlyNumber,
  isEnquete,
  answerMethodCode,
}) => {
  const {
    largeState: { state: $, setState },
  } = useSafeContext(CorrectionContext);
  const mergeArray = useMergeStateOfCommentEditors(sdp, setState);
  return (
    <CommentEditor
      title="回答編集"
      formState={($.commentForms_enqueteText ?? []).find(equalDp(sdp))}
      newForm={{ dp: sdp as DisplayPointOutputResponse, content: answerContent ?? '' }}
      onChange={(state) => mergeArray(state, 'commentForms_enqueteText')}
      placeholder="ユーザ回答を添削者が修正できる"
      contentCharLengthMin={contentCharLengthMin}
      contentCharLengthMax={contentCharLengthMax}
      isInputOnlyNumber={isInputOnlyNumber}
      isEnquete={isEnquete}
      answerMethodCode={answerMethodCode}
    />
  );
};
