import React, { useMemo, useCallback } from 'react';
import { createTestId } from '../../../utils/functions';
import type { RelatedCustomerListState } from '../../pages/Customer/RelatedCustomerListPage';
import { PaginationTable } from './PaginationTable';

interface Props {
  dataList: RelatedCustomerListState['list'];
}
export type TestIds = 'table' | 'link-button' | 'icon-button';

export const RelatedCustomerListTable: React.FC<Props> = React.memo(({ dataList }) => {
  const testId = createTestId<TestIds>(RelatedCustomerListTable);
  const MAX_RECORD_PER_PAGE = useMemo(() => 20, []);

  const tableData: {
    label: string;
    name: keyof RelatedCustomerListState['list'][0];
  }[] = useMemo(() => {
    return [
      { label: '発生日時', name: 'occurrenceAt' },
      { label: '会員ID', name: 'customerId' },
      { label: '会員名', name: 'customerName' },
      { label: 'メールアドレス', name: 'mailAddress' },
      { label: '一致項目値', name: 'matchedValue' },
      { label: '有効', name: 'disableFlg' },
      { label: 'モラル', name: 'moralName' },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (item: RelatedCustomerListState['list'][0], labelName: keyof RelatedCustomerListState['list'][0], i: number) => {
      if (labelName === 'occurrenceAt') {
        return item.occurrenceAt ? item.occurrenceAt : '';
      }
      if (labelName === 'matchedValue') {
        return (
          <>
            {item.matchedValue}
            <br />
            <span className="text-secondary">{item.reason}</span>
          </>
        );
      }
      if (labelName === 'disableFlg') {
        return item.disableFlg ? '無効' : '有効';
      }
      return item[labelName];
    },
    []
  );

  const labelList = useMemo(
    () => ['発生日時', '会員ID', '会員名', 'メールアドレス', '一致項目値', '有効', 'モラル'],
    []
  );

  return (
    <PaginationTable
      data-testid={testId('table')}
      resultNum={MAX_RECORD_PER_PAGE}
      list={dataList}
      headerNames={labelList}
    >
      {(list) => {
        return (list as RelatedCustomerListState['list']).map((item, i) => {
          return (
            <tr className="align-middle" key={item.customerId}>
              {tableData.map(({ name }) => (
                <td key={name}>{createDisplayElement(item, name, i)}</td>
              ))}
            </tr>
          );
        });
      }}
    </PaginationTable>
  );
});
