import React, { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { Url } from '../../../constants/Url';
/* eslint-disable import/no-cycle */
import {
  PostingRequestDetailInfoApi,
  PostingRequestUpsertApi,
  PostingRequestDetailInfoOutputResponse,
  MonitorContractRsvInfoOutputResponse,
  PostingRequestDetailInfoMonitorBaseInfoOutputResponse,
  PostingRequestContractShopInfoOutputResponse,
  MonitorTargetMonthInfoOutputResponse,
  IncResultOutputResponse,
  PostingRequestInfoOutputResponse,
  MatterHandOverSalesInfoOutputResponse,
  PostingRequestMonitorInfoOutputResponse,
  PostingRequestUpsertFormResponse,
  PostingRequestInfoDownloadApi,
  PostingRequestInfoUploadApi,
  IdNameOutputResponse,
} from '../../../api-client';
import { createTestId, execDownload, uuid } from '../../../utils/functions';
import { Overwrite } from '../../../interfaces/utils';
import { useLargeState } from '../../../hooks/useLargeState';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { Alert } from '../../atoms/Alert';
import { PostingSummaryTable } from '../../organisms/Table/PostingSummaryTable';
import { MonitorModifyModal } from '../../organisms/Modal/MonitorModifyModal';
import { ShopBulkChangeModal } from '../../organisms/Modal/ShopBulkChangeModal';
import { MessageInputModal } from '../../organisms/Modal/MessageInputModal';
import { PostEnqueteModal } from '../../organisms/Modal/PostEnqueteModal';
import { PostingRequestShopTable } from '../../organisms/Table/PostingRequestShopTable';
import { Modal } from '../../molecules/Modal';
import { Dropzone } from '../../molecules/Dropzone';

/* eslint-enable import/no-cycle */

export type Summary = Partial<
  Omit<
    PostingRequestDetailInfoOutputResponse,
    'applicationFormId' | 'contractShops' | 'postingRequest' | 'matterHandoverSalesList'
  >
>;

export type MonitorBase = Overwrite<
  PostingRequestDetailInfoMonitorBaseInfoOutputResponse & { uuid?: string; checked: boolean },
  {
    id?: number;
    monitorContractRsv?: Partial<
      Overwrite<
        MonitorContractRsvInfoOutputResponse,
        { monitorTargetMonth: Overwrite<MonitorTargetMonthInfoOutputResponse, { id?: number }> }
      >
    >;
    monitor?: Partial<PostingRequestMonitorInfoOutputResponse>;
  }
>;

export type ContractShop = Overwrite<
  PostingRequestContractShopInfoOutputResponse,
  { id?: number; url?: string; monitorBases: MonitorBase[] }
> & { uuid: string };

export interface ModalConfig {
  mode?: 'add' | 'edit' | 'bulkChange';
  shopIndex?: number;
  monitorIndex?: number;
}

export interface State {
  getApi: PostingRequestDetailInfoApi;
  upsertApi: PostingRequestUpsertApi;
  dlApi: PostingRequestInfoDownloadApi;
  ulApi: PostingRequestInfoUploadApi;
  id?: number;
  applicationFormFlg?: boolean;
  sfApplicationId?: string;
  summary: Summary;
  contractShops: ContractShop[];
  postingRequest: Partial<PostingRequestInfoOutputResponse>;
  matterHandoverSalesList: Partial<MatterHandOverSalesInfoOutputResponse>[];
  isMonitorModifyModal: boolean;
  isShopBulkChangeModal: boolean;
  isMessageInputModal: boolean;
  isPostEnqueteModal: boolean;
  modalConfig: ModalConfig;
  updResult: IncResultOutputResponse;
  defaultFlg: boolean;
  initialFlg: { first: boolean; second: boolean };
  applicationStatus: string;
  contractRsvAlertMonitorBases: IdNameOutputResponse[];
  statusRsvAlertMonitorBases: IdNameOutputResponse[];
  postingRsvAlertMonitorBases: IdNameOutputResponse[];
}

export const PRODUCT_TYPE = ['通常', 'スマート', 'リターン'];

export const APPLICATION_FORM_TYPE = {
  NEW: '新規',
  ADD: '追加',
  CHANGE: '変更',
  CANCEL: '解約',
  PAUSE: '休止',
};

export const APPLICATION_STATUS = {
  NEW: 'NEW',
  DRAFT: 'DRAFT',
  SECOND: 'SECOND',
  CHANGE: 'CHANGE',
};

const TRACKER_TYPES = [
  { code: 18, displayName: '掲載（新規）' },
  { code: 59, displayName: '掲載（増店）' },
  { code: 10, displayName: '修正' },
  { code: 19, displayName: '表示' },
  { code: 20, displayName: '非表示' },
  { code: 21, displayName: 'その他' },
  { code: 26, displayName: '取消' },
  { code: 27, displayName: 'POP' },
  { code: 28, displayName: '修正→表示' },
  { code: 29, displayName: '修正＆非表示' },
  { code: 42, displayName: 'システム化のみ' },
  { code: 43, displayName: '【既存】 QSCメール設定のみ' },
  { code: 44, displayName: '【既存・クラウド】システム化＆速報メール、アカウント設定' },
  { code: 45, displayName: '表示＆非表示' },
  { code: 49, displayName: 'ES登録' },
  { code: 51, displayName: 'ES修正' },
  { code: 54, displayName: '【クラウドのみ】CSユーザー追加・変更' },
  { code: 61, displayName: '【A窓】閉店・休業' },
  { code: 62, displayName: '【B窓】営業確認' },
  { code: 63, displayName: '【B窓】掲載チーム作業のみ' },
  { code: 67, displayName: '【リターン】ポイント変換・請求' },
];

export const PostingRequestPage: React.VFC = () => {
  const testid = createTestId(PostingRequestPage);

  const history = useHistory();
  const location = useLocation<{ id: number; applicationFormFlg: boolean }>();
  const { id, applicationFormFlg } = location.state;
  const initialState: State = {
    id,
    applicationFormFlg,
    getApi: new PostingRequestDetailInfoApi(),
    upsertApi: new PostingRequestUpsertApi(),
    dlApi: new PostingRequestInfoDownloadApi(),
    ulApi: new PostingRequestInfoUploadApi(),
    summary: {},
    contractShops: [],
    postingRequest: {},
    matterHandoverSalesList: [],
    isMonitorModifyModal: false,
    isShopBulkChangeModal: false,
    isMessageInputModal: false,
    isPostEnqueteModal: false,
    modalConfig: {},
    updResult: { result: false },
    // 初期表示状態からcontractShops以下が変更されるとfalseになる
    defaultFlg: true,
    initialFlg: { first: false, second: false },
    applicationStatus: APPLICATION_STATUS.NEW,
    contractRsvAlertMonitorBases: [],
    statusRsvAlertMonitorBases: [],
    postingRsvAlertMonitorBases: [],
  };

  const { state: $, mergeState, useBindSet } = useLargeState<State>(initialState);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [isOkModal, setIsOkModal] = useState<boolean>(false);
  const [isDropModal, setIsDropModal] = useState<boolean>(false);

  const [msg, setMeg] = useState<string | undefined>();

  useEffect(() => {
    if (!$.id || $.applicationFormFlg === undefined) return;
    $.getApi
      .postingRequestDetailInfo($.id, $.applicationFormFlg)
      .then((res: AxiosResponse<PostingRequestDetailInfoOutputResponse>) => {
        const {
          applicationFormName,
          applicationFormType,
          contractStartDate,
          contractEndDate,
          clientId,
          clientName,
          contractShops,
          contractRsvAlertMonitorBases,
          statusRsvAlertMonitorBases,
          postingRsvAlertMonitorBases,
          ...rest
        } = res.data;

        let applicationStatus: string = APPLICATION_STATUS.NEW;
        if (!$.applicationFormFlg) {
          applicationStatus = APPLICATION_STATUS.CHANGE;
        } else if (contractShops.length && contractShops[0].monitorBases.length) {
          if (contractShops[0].monitorBases[0].monitorContractRsv?.id) {
            applicationStatus = APPLICATION_STATUS.DRAFT;
          } else {
            applicationStatus = APPLICATION_STATUS.SECOND;
          }
        }

        mergeState({
          ...rest,
          contractShops: contractShops
            .map((s) => ({
              ...s,
              uuid: uuid(),
              monitorBases: s.monitorBases.map((m) => ({ ...m, uuid: uuid(), checked: true })),
            }))
            .sort((a, b) => a.id - b.id),
          summary: {
            applicationFormType,
            applicationFormName,
            contractStartDate,
            contractEndDate,
            clientId,
            clientName,
          },
          applicationStatus,
          contractRsvAlertMonitorBases,
          statusRsvAlertMonitorBases,
          postingRsvAlertMonitorBases,
        });
      });
  }, [$.getApi, mergeState, location]);

  useEffect(() => {
    if (!$.defaultFlg) return;

    if (!$.initialFlg.first) {
      mergeState({ initialFlg: { first: true, second: false } });
      return;
    }
    if (!$.initialFlg.second) {
      mergeState({ initialFlg: { first: true, second: true } });
      return;
    }

    mergeState({ defaultFlg: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$.contractShops]);

  const download = () => {
    $.dlApi.postingRequestInfoDownload($.id, $.applicationFormFlg).then((res) => {
      execDownload(
        res.data,
        'text/csv',
        `${$.summary.clientName}_${$.summary.applicationFormName}_${format(new Date(), 'yyyy_MM_dd_hh_mm_ss')}.xlsx`
      ).catch(() => {
        history.push(Url.COMMON_ERROR);
      });
    });
  };

  const onDrop = (files: File[]) => {
    if (files === null || files.length === 0) return;
    const file = files[0];

    const reader = new FileReader();
    reader.onload = () => {
      $.ulApi
        .postingRequestInfoUpload({
          data: reader.result?.toString().replace(/data:.*\/.*;base64,/, '') || '',
        })
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          const { result, ...rest } = res.data;

          if (res.data.result) {
            setIsOkModal(true);
            setIsDropModal(false);
          } else {
            setMeg(res.data.errorMessage);
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = (temporaryFlg: boolean) => {
    /* eslint-disable-next-line @typescript-eslint/no-shadow */
    const { id, applicationFormFlg, contractShops, postingRequest, matterHandoverSalesList } = $;
    const paramShops = contractShops
      .filter((shop) => {
        return shop.monitorBases.filter((m) => m.checked).length > 0;
      })
      .map(({ uuid: z, ...shop }) => ({
        ...shop,
        monitorBases: shop.monitorBases
          .filter((m) => m.checked)
          .map(({ monitor, uuid: y, checked, ...mb }) => {
            let base: any = mb;

            if (!applicationFormFlg) {
              base = { ...base, monitorContractRsv: undefined };
            }

            return base;
          }),
      }));

    let contractshopsForm = paramShops;
    if (
      ($.applicationStatus === APPLICATION_STATUS.SECOND || $.applicationStatus === APPLICATION_STATUS.CHANGE) &&
      $.defaultFlg
    ) {
      contractshopsForm = paramShops.map((shop) => {
        const monitorBases = shop.monitorBases.map((mb) => {
          return { id: mb.id, name: mb.name, productType: mb.productType };
        });
        return {
          id: shop.id,
          name: shop.name,
          storeSpotAmount: shop.storeSpotAmount,
          storeStockAmount: shop.storeStockAmount,
          monitorBases,
        };
      });
    }

    $.upsertApi
      .postingRequestUpsert({
        id,
        applicationFormFlg,
        temporaryFlg,
        contractShops: contractshopsForm,
        postingRequest,
        matterHandoverSalesList: matterHandoverSalesList.map(({ files, ...rest }) => ({
          ...rest,
          fileIds: files ? files.map((f) => f.id) : [],
        })),
      } as PostingRequestUpsertFormResponse)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
        setIsSubmited(true);
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  return (
    <>
      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.POSTING_REQUEST}
      </Title>

      {$.updResult.result && (
        <Alert testId={testid('success-alert')} variant="success">
          実施しました。
        </Alert>
      )}
      {$.updResult.errorMessage && (
        <Alert
          testId={testid('failure-alert')}
          variant="danger"
        >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}

      <MonitorModifyModal
        isModal={$.isMonitorModifyModal}
        setIsModal={useBindSet('isMonitorModifyModal')}
        contractShops={$.contractShops}
        setContractShops={useBindSet('contractShops')}
        config={$.modalConfig}
        setConfig={useBindSet('modalConfig')}
        applicationFormFlg={applicationFormFlg}
        applicationStatus={$.applicationStatus}
      />
      <ShopBulkChangeModal
        isModal={$.isShopBulkChangeModal}
        setIsModal={useBindSet('isShopBulkChangeModal')}
        contractShops={$.contractShops}
        setContractShops={useBindSet('contractShops')}
      />
      <MessageInputModal
        isModal={$.isMessageInputModal}
        setIsModal={useBindSet('isMessageInputModal')}
        matterHandoverSalesList={$.matterHandoverSalesList}
        setMatterHandoverSalesList={useBindSet('matterHandoverSalesList')}
        editFlg
      />
      <PostEnqueteModal
        clientName={$.summary.clientName || ''}
        isModal={$.isPostEnqueteModal}
        setIsModal={useBindSet('isPostEnqueteModal')}
        contractShops={$.contractShops}
        setContractShops={useBindSet('contractShops')}
        config={$.modalConfig}
        setConfig={useBindSet('modalConfig')}
      />

      <Modal
        centered
        isModal={isOkModal}
        body={
          <div className="text-center">
            <p>アップロードしました</p>
            <Button onClick={() => history.go(0)}>OK</Button>
          </div>
        }
      />
      {msg && (
        <Modal
          centered
          isModal
          body={
            <div className="text-center">
              <p>エラー：{msg}</p>
              <Button onClick={() => setMeg(undefined)}>OK</Button>
            </div>
          }
        />
      )}
      <Modal
        centered
        isModal={isDropModal}
        closeButton
        onHide={() => setIsDropModal(false)}
        body={
          <>
            <Dropzone onDrop={onDrop} type="excel" />
          </>
        }
      />
      {$.contractRsvAlertMonitorBases && $.contractRsvAlertMonitorBases.length > 0 && (
        <Alert testId={testid('contract-rsv-alert')} variant="danger">
          下記のモニターは別の掲載依頼で契約予約が作成されています。
          {$.contractRsvAlertMonitorBases.map((d, index) => (
            <li>{d.name}</li>
          ))}
        </Alert>
      )}
      {$.statusRsvAlertMonitorBases && $.statusRsvAlertMonitorBases.length > 0 && (
        <Alert testId={testid('status-rsv-alert')} variant="danger">
          下記のモニターは別の掲載依頼でステータス予約が作成されています。
          {$.statusRsvAlertMonitorBases.map((d, index) => (
            <li>{d.name}</li>
          ))}
        </Alert>
      )}
      {$.postingRsvAlertMonitorBases && $.postingRsvAlertMonitorBases.length > 0 && (
        <Alert testId={testid('posting-rsv-alert')} variant="danger">
          下記のモニターは別の掲載依頼で掲載予約が作成されています。
          {$.postingRsvAlertMonitorBases.map((d, index) => (
            <li key={index}>{d.name}</li>
          ))}
        </Alert>
      )}

      <PostingSummaryTable info={$.summary} />

      {/*
      <div className="d-flex justify-content-end mb-4">
        <Form.Check
          type="switch"
          label="OEM表示可否"
          data-testid={testid('oemDisplayFlg')}
          checked={$.postingRequest.oemDisplayFlg ?? false}
          onChange={(e) =>
            mergeState({
              postingRequest: { ...$.postingRequest, oemDisplayFlg: Boolean(e.target.value) },
            })
          }
        />
      </div>
      */}

      <div className="d-flex justify-content-end mb-4 gap-2">
        <Button variant="link" onClick={() => history.goBack()} data-testid={testid('cancel')}>
          キャンセル
        </Button>

        {$.applicationFormFlg && (
          <Button
            variant="link"
            type="button"
            onClick={() =>
              history.push({
                pathname: Url.KEISAI.APPLICATION_MODIFY,
                state: { applicationId: $.id, clientId: $.summary.clientId, clientName: $.summary.clientName },
              })
            }
          >
            申込書確認
          </Button>
        )}

        <Button
          type="button"
          data-testid={testid('postingRequestInfoDownload-button')}
          onClick={download}
          disabled={isSubmited}
        >
          掲載依頼DL
        </Button>

        <Button
          type="button"
          onClick={() => {
            setIsDropModal(true);
          }}
        >
          掲載依頼UL
        </Button>

        <Button
          type="button"
          data-testid={testid('monitorBulkChange-button')}
          disabled={!$.contractShops.some((shop) => shop.monitorBases.some((monitor) => monitor.checked))}
          onClick={() =>
            mergeState({ isMonitorModifyModal: true, modalConfig: { ...$.modalConfig, mode: 'bulkChange' } })
          }
        >
          一括変更（モニター）
        </Button>

        <Button
          type="button"
          data-testid={testid('shopBulkChange-button')}
          onClick={() => mergeState({ isShopBulkChangeModal: true })}
          disabled={!(!$.sfApplicationId || $.summary.applicationFormType === APPLICATION_FORM_TYPE.CHANGE)}
        >
          一括変更（店舗）
        </Button>

        <Button
          type="button"
          onClick={() => mergeState({ isMessageInputModal: true })}
          disabled={
            $.summary.applicationFormType === APPLICATION_FORM_TYPE.CANCEL ||
            $.summary.applicationFormType === APPLICATION_FORM_TYPE.PAUSE
          }
        >
          申し送り事項等入力
        </Button>

        <Button type="button" onClick={() => onSubmit(true)} data-testid={testid('save-button')}>
          一時保存
        </Button>

        <Button
          type="button"
          onClick={() => onSubmit(false)}
          data-testid={testid('postingRequest-button')}
          disabled={isSubmited}
        >
          掲載依頼
        </Button>
      </div>

      <div className="d-flex justify-content-between align-items-end mb-2">
        <div>
          <Button
            type="button"
            size="sm"
            data-testid={testid('select-button')}
            onClick={() => {
              mergeState({
                contractShops: $.contractShops.map((s) => ({
                  ...s,
                  monitorBases: s.monitorBases.map((m) =>
                    $.summary.applicationFormType === APPLICATION_FORM_TYPE.CHANGE ? { ...m, checked: true } : m
                  ),
                })),
              });
            }}
          >
            全選択
          </Button>

          <Button
            type="button"
            variant="outline-secondary"
            size="sm"
            className="ms-2"
            data-testid={testid('unSelect-button')}
            onClick={() => {
              mergeState({
                contractShops: $.contractShops.map((s) => ({
                  ...s,
                  monitorBases: s.monitorBases.map((m) => ({ ...m, checked: false })),
                })),
              });
            }}
          >
            全解除
          </Button>
        </div>

        <FloatingLabel label="トラッカー" data-testid={testid('select-trackerType')}>
          <Form.Select
            value={$.postingRequest.trackerType ?? ''}
            onChange={(e) =>
              mergeState({
                postingRequest: { ...$.postingRequest, trackerType: Number(e.target.value) },
              })
            }
          >
            <option value="">&nbsp;</option>
            {TRACKER_TYPES.map((trackerType) => (
              <option key={trackerType.displayName} value={trackerType.code}>
                {trackerType.displayName}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
      </div>

      <PostingRequestShopTable
        contractShops={$.contractShops}
        setContractShops={useBindSet('contractShops')}
        setIsMonitorModal={useBindSet('isMonitorModifyModal')}
        setModalConfig={useBindSet('modalConfig')}
        applicationFormFlg={applicationFormFlg}
        disabled={!(!$.sfApplicationId || $.summary.applicationFormType === APPLICATION_FORM_TYPE.CHANGE)}
      />
    </>
  );
};
