/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BillingAgentFeeListOutputResponse
 */
export interface BillingAgentFeeListOutputResponse {
    /**
     * 代理店フィー一覧ヘッダID
     * @type {number}
     * @memberof BillingAgentFeeListOutputResponse
     */
    'agentFeeListHeaderId': number;
    /**
     * 代理店フィー一覧ステータス
     * @type {string}
     * @memberof BillingAgentFeeListOutputResponse
     */
    'agentFeeStatus'?: BillingAgentFeeListOutputResponseAgentFeeStatusEnum;
    /**
     * 代理店ID
     * @type {number}
     * @memberof BillingAgentFeeListOutputResponse
     */
    'billingAgentId'?: number;
    /**
     * 代理店名
     * @type {string}
     * @memberof BillingAgentFeeListOutputResponse
     */
    'billingAgentName'?: string;
    /**
     * 請求フローステータス
     * @type {string}
     * @memberof BillingAgentFeeListOutputResponse
     */
    'billingFlowStatus'?: BillingAgentFeeListOutputResponseBillingFlowStatusEnum;
    /**
     * 合計金額
     * @type {number}
     * @memberof BillingAgentFeeListOutputResponse
     */
    'companyAmount'?: number;
    /**
     * 担当者
     * @type {string}
     * @memberof BillingAgentFeeListOutputResponse
     */
    'incAccountName': string;
    /**
     * 月度
     * @type {string}
     * @memberof BillingAgentFeeListOutputResponse
     */
    'publishedDate': string;
}

export const BillingAgentFeeListOutputResponseAgentFeeStatusEnum = {
    SalesDptUncommitted: 'SALES_DPT_UNCOMMITTED',
    SalesDptManagerUncommitted: 'SALES_DPT_MANAGER_UNCOMMITTED',
    ManageDptUncommitted: 'MANAGE_DPT_UNCOMMITTED'
} as const;

export type BillingAgentFeeListOutputResponseAgentFeeStatusEnum = typeof BillingAgentFeeListOutputResponseAgentFeeStatusEnum[keyof typeof BillingAgentFeeListOutputResponseAgentFeeStatusEnum];
export const BillingAgentFeeListOutputResponseBillingFlowStatusEnum = {
    WaitingCompleteApply: 'WAITING_COMPLETE_APPLY',
    WaitingCreateStock: 'WAITING_CREATE_STOCK',
    SalesDptUncommitted: 'SALES_DPT_UNCOMMITTED',
    SalesDptManagerUncommitted: 'SALES_DPT_MANAGER_UNCOMMITTED',
    ManageDptUncommitted: 'MANAGE_DPT_UNCOMMITTED',
    Unpublished: 'UNPUBLISHED',
    Published: 'PUBLISHED'
} as const;

export type BillingAgentFeeListOutputResponseBillingFlowStatusEnum = typeof BillingAgentFeeListOutputResponseBillingFlowStatusEnum[keyof typeof BillingAgentFeeListOutputResponseBillingFlowStatusEnum];


