import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Modal } from '../../molecules/Modal';
import {
  BillingAgentFeeDetailOutputResponse,
  BillingAgentFeeDetailOneRowDtoResponse,
  BillingAgentFeeDetailForEditModalOutputResponse,
  BillingAgentFeeDetailOneRowDtoResponseCompanyAmountTypeEnum,
} from '../../../api-client';
import { Alert } from '../../atoms/Alert';

export interface Props {
  isEditModal: boolean;
  setIsEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  editItem?: BillingAgentFeeDetailOneRowDtoResponse;
  modalOutput?: BillingAgentFeeDetailForEditModalOutputResponse;
  setDetail: React.Dispatch<React.SetStateAction<BillingAgentFeeDetailOutputResponse>>;
  editType: string;
}

const EDIT_TYPE = {
  ADD: 'add',
  EDIT: 'edit',
};
const RATIO_MIN = 0;
const RATIO_MAX = 100;

type UpdateData = {
  agentFeeDetailId: number | undefined;
  clientName: string | undefined;
  billingItemName: string | undefined;
  billingFeeAmount: number | undefined;
  companyAmountType: BillingAgentFeeDetailOneRowDtoResponseCompanyAmountTypeEnum | undefined;
  companyAmount: number | undefined;
  paymentFeeAmount: number | undefined;
  taxRate: number | undefined;
  remarks: string | undefined;
};

export const BillingAgentFeeModal: React.VFC<Props> = ({
  isEditModal,
  setIsEditModal,
  editItem,
  modalOutput,
  setDetail,
  editType,
}) => {
  const [updData, setUpdData] = useState<UpdateData>({
    agentFeeDetailId: editItem?.agentFeeDetailId,
    clientName: editItem?.clientName,
    billingItemName: editItem?.billingItemName,
    billingFeeAmount: editItem?.billingFeeAmount,
    companyAmountType: editItem?.companyAmountType,
    companyAmount: editItem?.companyAmount,
    paymentFeeAmount: editItem?.paymentFeeAmount,
    taxRate: editItem?.taxRate,
    remarks: editItem?.remarks,
  });
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const onHide = () => {
    setUpdData((current) => {
      // const newUpd = undefined;
      const newUpd = current;
      newUpd.agentFeeDetailId = undefined;
      newUpd.clientName = undefined;
      newUpd.billingItemName = undefined;
      newUpd.billingFeeAmount = undefined;
      newUpd.companyAmountType = undefined;
      newUpd.companyAmount = undefined;
      newUpd.paymentFeeAmount = undefined;
      newUpd.taxRate = undefined;
      newUpd.remarks = undefined;
      return newUpd;
    });
    setIsEditModal(false);
    window.location.reload();
  };

  const getPaymentFeeAmount = () => {
    let paymentFeeAmount = 0;
    if (updData.billingFeeAmount !== undefined && updData.companyAmount !== undefined) {
      paymentFeeAmount = Math.floor((updData.billingFeeAmount * updData.companyAmount) / 100);
    }
    setUpdData({ ...updData, paymentFeeAmount });
    return paymentFeeAmount;
  };

  const clientOption = () => {
    const arr = [];
    if (modalOutput !== undefined) {
      modalOutput.clientSelectionIds.map((clients) => {
        arr.push(
          <option key={clients.id} value={clients.id}>
            {clients.name}
          </option>
        );
      });
    } else {
      arr.push(<option value={0}>&nbsp;</option>);
    }
    return arr;
  };

  const billintItemOption = () => {
    const arr = [];
    if (modalOutput !== undefined) {
      modalOutput.billingUseItemIds.map((billing) => {
        arr.push(
          <option key={billing.id} value={billing.id}>
            {billing.name}
          </option>
        );
      });
    } else {
      arr.push(<option value={0}>&nbsp;</option>);
    }
    return arr;
  };

  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.id) {
      case 'clientName':
        if (modalOutput !== undefined) {
          const selectedClient = modalOutput.clientSelectionIds.find(
            (clients) => clients.id === Number(event.target.value)
          );
          setUpdData({ ...updData, clientName: selectedClient !== undefined ? selectedClient.name : '' });
        }
        break;

      case 'billingItemName':
        if (modalOutput !== undefined) {
          const selectedBilling = modalOutput.billingUseItemIds.find(
            (billing) => billing.id === Number(event.target.value)
          );
          setUpdData({ ...updData, billingItemName: selectedBilling !== undefined ? selectedBilling.name : '' });
        }
        break;

      case 'rateType':
        setUpdData({ ...updData, companyAmountType: Number(event.target.value) === 1 ? 'FIXED' : 'RATIO' });
        break;

      default:
        break;
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = 0;
    switch (event.target.id) {
      case 'billingFeeAmount':
        setUpdData({ ...updData, billingFeeAmount: Number(event.target.value) });
        break;

      case 'companyAmount':
        value = Math.max(RATIO_MIN, Math.min(RATIO_MAX, Number(event.target.value)));
        setUpdData({ ...updData, companyAmount: value });
        break;

      case 'taxRate':
        value = Math.max(RATIO_MIN, Math.min(RATIO_MAX, Number(event.target.value)));
        setUpdData({ ...updData, taxRate: value });
        break;

      case 'remarks':
        setUpdData({ ...updData, remarks: event.target.value });
        break;

      default:
        break;
    }
  };

  const handleSubmit = (e: any) => {
    const data = {
      agentFeeDetailId: updData.agentFeeDetailId !== undefined ? updData.agentFeeDetailId : 0,
      clientName: updData.clientName !== undefined ? updData.clientName : '',
      billingItemName: updData.billingItemName !== undefined ? updData.clientName : '',
      billingFeeAmount: updData.billingFeeAmount !== undefined ? updData.billingFeeAmount : 0,
      // rateType = updData.billingFeeAmount !== undefined ? updData.billingFeeAmount : '',
      companyAmount: updData.companyAmount !== undefined ? updData.companyAmount : 0,
      paymentFeeAmount: updData.paymentFeeAmount !== undefined ? updData.paymentFeeAmount : 0,
      taxRate: updData.taxRate !== undefined ? updData.taxRate : 0,
      remarks: updData.remarks,
    } as BillingAgentFeeDetailOneRowDtoResponse;

    setDetail((current) => {
      const newDetail = current;
      // 編集時は既存のレコードを削除してから追加
      if (editType === EDIT_TYPE.EDIT) {
        if (current.billingAgentFeeDetailList !== undefined) {
          const targetItem = current.billingAgentFeeDetailList?.filter((target) => {
            return target.agentFeeDetailId !== updData.agentFeeDetailId;
          });
          newDetail.billingAgentFeeDetailList = targetItem;
        }
      }
      newDetail.billingAgentFeeDetailList?.push(data);

      return newDetail;
    });
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isEditModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-4" controlId="clientName">
                <Form.Label className="pt-2">企業</Form.Label>
                <Col className="col-8">
                  {editType === EDIT_TYPE.ADD ? (
                    <Form.Select required onChange={onChangeSelect}>
                      {clientOption()}
                    </Form.Select>
                  ) : (
                    <Form.Control readOnly type="text" defaultValue={editItem?.clientName} />
                  )}
                </Col>
              </Form.Group>
              <Form.Group className="mb-4" controlId="billingItemName">
                <Form.Label className="pt-2">項目</Form.Label>
                <Col className="col-8">
                  {editType === EDIT_TYPE.ADD ? (
                    <Form.Select required onChange={onChangeSelect}>
                      {billintItemOption()}
                    </Form.Select>
                  ) : (
                    <Form.Control readOnly type="text" defaultValue={editItem?.billingItemName} />
                  )}
                </Col>
              </Form.Group>
              <Form.Group className="mb-4" controlId="billingFeeAmount">
                <Form.Label className="pt-2">合計</Form.Label>
                <Col className="col-8">
                  {editType === EDIT_TYPE.ADD ? (
                    <Form.Control required type="number" />
                  ) : (
                    <Form.Control readOnly type="number" defaultValue={editItem?.billingFeeAmount} />
                  )}
                </Col>
              </Form.Group>
              <Form.Group className="mb-4" controlId="rateType">
                <Form.Label className="pt-2">フィータイプ</Form.Label>
                <Col className="col-8">
                  {editType === EDIT_TYPE.ADD ? (
                    <Form.Select required onChange={onChangeSelect}>
                      <option value={0}>固定</option>
                      <option value={1}>割合</option>
                    </Form.Select>
                  ) : (
                    <Form.Control
                      readOnly
                      type="text"
                      defaultValue={
                        editItem?.companyAmountType ===
                        BillingAgentFeeDetailOneRowDtoResponseCompanyAmountTypeEnum.Fixed
                          ? '固定'
                          : '割合'
                      }
                    />
                  )}
                </Col>
              </Form.Group>
              <Form.Group className="mb-4" controlId="companyAmount">
                <Form.Label className="pt-2">御社分</Form.Label>
                <Col className="col-8">
                  <Form.Control type="number" defaultValue={editItem?.companyAmount} onChange={onChange} />
                </Col>
              </Form.Group>
              <Form.Group className="mb-4" controlId="paymentFeeAmount">
                <Form.Label className="pt-2">金額</Form.Label>
                <Col className="col-8">
                  <Form.Control type="text" readOnly value={getPaymentFeeAmount()} />
                </Col>
              </Form.Group>
              <Form.Group className="mb-4" controlId="taxRate">
                <Form.Label className="pt-2">税区分</Form.Label>
                <Col className="col-8">
                  <Form.Control type="number" defaultValue={editItem?.taxRate} onChange={onChange} />
                </Col>
              </Form.Group>
              <Form.Group className="mb-4" controlId="remarks">
                <Form.Label className="pt-2">備考</Form.Label>
                <Col className="col-8">
                  <Form.Control type="text" defaultValue={editItem?.remarks} onChange={onChange} />
                </Col>
              </Form.Group>
              <Row className="d-flex justify-content-end mt-4">
                <Button type="submit" variant="secondary">
                  {editType === EDIT_TYPE.ADD ? '追加' : '更新'}
                </Button>
                <Button variant="secondary" onClick={onHide}>
                  キャンセル
                </Button>
              </Row>
            </Form>
          </>
        }
      />
    </>
  );
};
