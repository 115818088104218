/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForceUnseatedProcessExecutionFormResponse } from '../domains';
// @ts-ignore
import { ForceWinningProcessExecutionFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * ForceApi - axios parameter creator
 * @export
 */
export const ForceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 強制落選処理を実行する
         * @summary 強制落選処理実行API
         * @param {ForceUnseatedProcessExecutionFormResponse} forceUnseatedProcessExecutionFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceUnseatedProcessExecution: async (forceUnseatedProcessExecutionFormResponse: ForceUnseatedProcessExecutionFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forceUnseatedProcessExecutionFormResponse' is not null or undefined
            assertParamExists('forceUnseatedProcessExecution', 'forceUnseatedProcessExecutionFormResponse', forceUnseatedProcessExecutionFormResponse)
            const localVarPath = `/api/force-unseated-process-execution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forceUnseatedProcessExecutionFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 強制当選処理を実行する
         * @summary 強制当選処理実行API
         * @param {ForceWinningProcessExecutionFormResponse} forceWinningProcessExecutionFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceWinningProcessExecution: async (forceWinningProcessExecutionFormResponse: ForceWinningProcessExecutionFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forceWinningProcessExecutionFormResponse' is not null or undefined
            assertParamExists('forceWinningProcessExecution', 'forceWinningProcessExecutionFormResponse', forceWinningProcessExecutionFormResponse)
            const localVarPath = `/api/force-winning-process-execution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forceWinningProcessExecutionFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForceApi - functional programming interface
 * @export
 */
export const ForceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForceApiAxiosParamCreator(configuration)
    return {
        /**
         * 強制落選処理を実行する
         * @summary 強制落選処理実行API
         * @param {ForceUnseatedProcessExecutionFormResponse} forceUnseatedProcessExecutionFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceUnseatedProcessExecution(forceUnseatedProcessExecutionFormResponse: ForceUnseatedProcessExecutionFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceUnseatedProcessExecution(forceUnseatedProcessExecutionFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 強制当選処理を実行する
         * @summary 強制当選処理実行API
         * @param {ForceWinningProcessExecutionFormResponse} forceWinningProcessExecutionFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forceWinningProcessExecution(forceWinningProcessExecutionFormResponse: ForceWinningProcessExecutionFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forceWinningProcessExecution(forceWinningProcessExecutionFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForceApi - factory interface
 * @export
 */
export const ForceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForceApiFp(configuration)
    return {
        /**
         * 強制落選処理を実行する
         * @summary 強制落選処理実行API
         * @param {ForceUnseatedProcessExecutionFormResponse} forceUnseatedProcessExecutionFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceUnseatedProcessExecution(forceUnseatedProcessExecutionFormResponse: ForceUnseatedProcessExecutionFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.forceUnseatedProcessExecution(forceUnseatedProcessExecutionFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 強制当選処理を実行する
         * @summary 強制当選処理実行API
         * @param {ForceWinningProcessExecutionFormResponse} forceWinningProcessExecutionFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forceWinningProcessExecution(forceWinningProcessExecutionFormResponse: ForceWinningProcessExecutionFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.forceWinningProcessExecution(forceWinningProcessExecutionFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ForceApi - object-oriented interface
 * @export
 * @class ForceApi
 * @extends {BaseAPI}
 */
export class ForceApi extends BaseAPI {
    /**
     * 強制落選処理を実行する
     * @summary 強制落選処理実行API
     * @param {ForceUnseatedProcessExecutionFormResponse} forceUnseatedProcessExecutionFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForceApi
     */
    public forceUnseatedProcessExecution(forceUnseatedProcessExecutionFormResponse: ForceUnseatedProcessExecutionFormResponse, options?: AxiosRequestConfig) {
        return ForceApiFp(this.configuration).forceUnseatedProcessExecution(forceUnseatedProcessExecutionFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 強制当選処理を実行する
     * @summary 強制当選処理実行API
     * @param {ForceWinningProcessExecutionFormResponse} forceWinningProcessExecutionFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForceApi
     */
    public forceWinningProcessExecution(forceWinningProcessExecutionFormResponse: ForceWinningProcessExecutionFormResponse, options?: AxiosRequestConfig) {
        return ForceApiFp(this.configuration).forceWinningProcessExecution(forceWinningProcessExecutionFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
