import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { AxiosError, AxiosResponse } from 'axios';
/* eslint-disable import/no-cycle */
import {
  IncResultOutputResponse,
  MonitorStatusRsvCreateApi,
  MonitorStatusRsvCreateFormResponse,
  MonitorStatusRsvFormResponse,
  MonitorStatusRsvListApi,
  MonitorStatusRsvListItemOutputResponse,
  MonitorStatusRsvListOutputResponse,
} from '../../../api-client';
import { DispatchSetState } from '../../../interfaces/utils';
// import { monitorStatusRsvListApi } from '../../../test/mocks/api/MonitorStatusRsvListApi';
import { createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Alert } from '../../atoms/Alert';
import { Badge } from '../../atoms/Badge';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { HTTPStatusCode } from '../../../constants/HTTPStatusCode';

/* eslint-enable import/no-cycle */

export interface Props {
  isModal: boolean;
  setIsModal: DispatchSetState<boolean>;
  postingRequestId: number;
  monitorBaseIds: number[];
  setMonitorBaseIds: DispatchSetState<number[]>;
}

export interface State {
  getApi: MonitorStatusRsvListApi;
  // getApi: ReturnType<typeof monitorStatusRsvListApi>;
  createApi: MonitorStatusRsvCreateApi;
  isInValid: boolean;
  status: MonitorStatusRsvListOutputResponse['status'] | null;
  hidingReasonType: MonitorStatusRsvListOutputResponse['reasonForHiding'] | null;
  statusRsvs: MonitorStatusRsvListItemOutputResponse[] | null;
  updResult: IncResultOutputResponse;
  monitorBaseName: MonitorStatusRsvListOutputResponse['monitorBaseName'];
  contractShopName: MonitorStatusRsvListOutputResponse['contractShopName'];
}

const STATUS = ['掲載準備', '公開', '限定公開', '非公開'] as const;
const HIDING_REASON_TYPE = [
  { code: 0, displayName: 'ユーザ連絡' },
  { code: 1, displayName: '休止' },
  { code: 2, displayName: '修正に伴う非表示' },
  { code: 3, displayName: '隔月等実施' },
  { code: 4, displayName: 'スポット契約満了（自動更新なし契約）' },
  { code: 5, displayName: '解約（自動更新あり契約）' },
  { code: 6, displayName: '閉店' },
  { code: 8, displayName: '公開OK' },
] as const;

export const StatusModal: React.VFC<Props> = ({
  isModal,
  setIsModal,
  postingRequestId,
  monitorBaseIds,
  setMonitorBaseIds,
}) => {
  const testid = createTestId(StatusModal);

  const [error504Flg, setError504Flg] = useState<boolean>(false);

  const [validErrorMsg, setValidErrorMsg] = useState<string>();

  const { state: $, mergeState } = useLargeState<State>({
    getApi: new MonitorStatusRsvListApi(),
    // getApi: monitorStatusRsvListApi(),
    createApi: new MonitorStatusRsvCreateApi(),
    isInValid: false,
    status: null,
    hidingReasonType: null,
    statusRsvs: null,
    updResult: { result: false },
    monitorBaseName: '',
    contractShopName: '',
  });

  const {
    state: f,
    setState: fSetState,
    onChangeSet: fOnChangeSet,
  } = useLargeState<Partial<Omit<MonitorStatusRsvFormResponse, 'monitorBaseIds' | 'postingRequestId'>>>({});

  useEffect(() => {
    if (!isModal || monitorBaseIds.length !== 1) return;

    $.getApi.monitorStatusRsvList(monitorBaseIds[0]).then((res: AxiosResponse<MonitorStatusRsvListOutputResponse>) => {
      const { ...rest } = res.data;
      mergeState(rest);
    });
  }, [isModal, monitorBaseIds, $.getApi, mergeState]);

  const onHide = () => {
    mergeState({ status: null, hidingReasonType: null, isInValid: false, updResult: { result: false } });
    fSetState({});
    setMonitorBaseIds([]);
    setIsModal(false);
    setError504Flg(false);
    setValidErrorMsg(undefined);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!f.applyingAt || !f.status) return;

    if ($.status && $.status === '非公開中') {
      // 非公開中から公開へのステータス予約の場合、非公開理由として「公開OK」を選択してもらう。
      if (f.hidingReasonType !== HIDING_REASON_TYPE[7].code) {
        setValidErrorMsg('非公開中から公開へステータス変更を予約する場合、非表示理由「公開OK」を指定してください。');
        return;
      }
    }

    setValidErrorMsg(undefined);

    const createParam: MonitorStatusRsvCreateFormResponse = {
      monitorStatusRsvs: monitorBaseIds.map((id) => {
        return {
          monitorBaseId: id,
          postingRequestId,
          applyingAt: f.applyingAt,
          status: f.status,
          hidingReasonType: f.hidingReasonType,
        } as MonitorStatusRsvFormResponse;
      }),
    };

    $.createApi
      .monitorStatusRsvCreate(createParam)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
        if (!res.data.result) return;
        onHide();
      })
      .catch((error: AxiosError<IncResultOutputResponse>) => {
        if (error.response?.status === HTTPStatusCode.GatewayTimeout) {
          setError504Flg(true);
        } else {
          mergeState({ updResult: error.response?.data });
        }
      });
  };

  return (
    <Modal
      onHide={onHide}
      isModal={isModal}
      closeButton
      centered
      size="lg"
      title={`契約店舗名：${$.contractShopName}, モニター名：${$.monitorBaseName}`}
      body={
        <Form onSubmit={onSubmit}>
          <div className="d-flex justify-content-end mb-4">
            <Button type="submit" className="ms-2" data-testid={testid('reserve-button')}>
              予約
            </Button>
          </div>

          {error504Flg && (
            <Alert testId={testid('failure-504')} variant="danger">
              処理に時間がかかっています。お手数ですが暫くしてから「ステータス設定」で登録結果を確認してください。
            </Alert>
          )}

          {validErrorMsg && (
            <Alert testId={testid('failure-valid')} variant="danger">
              {validErrorMsg}
            </Alert>
          )}

          {!error504Flg && $.updResult.errorMessage && $.updResult.errorCode && (
            <Alert
              testId={testid('failure-alert')}
              variant="danger"
            >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
          )}

          {!error504Flg && !$.updResult.result && $.updResult.errorMessage && (
            <Alert variant="danger">
              <pre style={{ whiteSpace: 'pre-wrap' }}>{$.updResult.errorMessage}</pre>
            </Alert>
          )}

          {$.status && (
            <Badge bg="secondary" className="px-4 mb-4" style={{ fontSize: '0.875rem' }} pill>
              {$.status}
            </Badge>
          )}

          {$.hidingReasonType && (
            <Form.Group as={Row} className="mb-4" data-testid={testid('currentReasonForHiding')}>
              <Form.Label column sm="2">
                非表示理由
              </Form.Label>
              <Col sm="10">
                <Form.Control readOnly value={$.hidingReasonType} />
              </Col>
            </Form.Group>
          )}

          <div className="bg-light p-4">
            <Form.Group className="mb-4" data-testid={testid('applyingAt')}>
              <Form.Label>適用日時</Form.Label>
              <div className="d-flex gap-2 mb-4">
                <Form.Control
                  required
                  type="datetime-local"
                  value={f.applyingAt || ''}
                  onChange={fOnChangeSet('applyingAt')}
                />
              </div>
            </Form.Group>

            <Form.Group data-testid={testid('status')}>
              <Form.Label>ステータス</Form.Label>
              <Form.Select required value={f.status} onChange={fOnChangeSet('status', Number)}>
                <option value="">&nbsp;</option>
                {STATUS.map((label, i) => (
                  <option key={i} value={i + 1}>
                    {label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mt-4" data-testid={testid('reasonForHiding')}>
              <Form.Label>非表示理由</Form.Label>
              <Form.Select value={f.hidingReasonType} onChange={fOnChangeSet('hidingReasonType', Number)}>
                <option value="">&nbsp;</option>
                {HIDING_REASON_TYPE.map((label) => (
                  <option key={label.displayName} value={label.code}>
                    {label.displayName}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            {$.statusRsvs && $.statusRsvs.length > 0 && (
              <div className="mt-4" style={{ backgroundColor: 'white' }}>
                {$.statusRsvs.map((rsv) => (
                  <ul
                    style={{
                      listStyle: 'none',
                      padding: '0.5rem',
                    }}
                  >
                    <li>変更日時 {rsv.applyingAt}</li>
                    <li>変更ステータス {rsv.status}</li>
                    <li>非表示理由 {rsv.reasonForHiding}</li>
                  </ul>
                ))}
              </div>
            )}
          </div>
        </Form>
      }
    />
  );
};
