import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Modal } from '../../molecules/Modal';

export interface DeliveryNoteUpdate {
  billingHeaderId: number;
  deliveryNote: string;
  isTmp: boolean;
}

export interface Props {
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  clientId?: string;
  clientName?: string;
  branchId?: string;
  branchName?: string;
  memo: DeliveryNoteUpdate;
  setUpdateNoteData: React.Dispatch<React.SetStateAction<DeliveryNoteUpdate>>;
}

export const BillingListMemoModal: React.VFC<Props> = ({
  isModal,
  setIsModal,
  clientId,
  clientName,
  branchId,
  branchName,
  memo,
  setUpdateNoteData,
}) => {
  const [note, setEditedNote] = useState<string>('');

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedNote(event.target.value);
  };
  useEffect(() => {
    setEditedNote(memo.deliveryNote);
  }, [isModal]);

  const onClickSave = (tmp: boolean) => {
    if (branchId !== undefined) {
      setUpdateNoteData({
        ...memo,
        deliveryNote: note !== memo.deliveryNote ? note : memo.deliveryNote,
        isTmp: tmp,
      });
      onHide();
    }
  };

  const onHide = () => {
    setEditedNote('');
    setIsModal(false);
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            <Row className="g-2 mb-4">
              <Col className="col-5">
                <Form.Group className="d-flex" controlId="clientId">
                  <Form.Label className="mt-2 me-2" style={{ width: '120px' }}>
                    クライアントID
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Control readOnly type="text" name="clientId" defaultValue={clientId} />
                  </Col>
                </Form.Group>
              </Col>
              <Col className="col-4">
                <Form.Group className="d-flex" controlId="clientName">
                  <Form.Label className="mt-2 me-2" style={{ width: '95px' }}>
                    クライアント名
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Control readOnly type="text" name="clientName" defaultValue={clientName} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2 mb-4">
              <Col className="col-5">
                <Form.Group className="d-flex" controlId="branchId">
                  <Form.Label className="mt-2 me-2" style={{ width: '120px' }}>
                    請求送付先枝番
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Control readOnly type="text" name="branchId" defaultValue={branchId} />
                  </Col>
                </Form.Group>
              </Col>
              <Col className="col-4">
                <Form.Group className="d-flex" controlId="branchName">
                  <Form.Label className="mt-2 me-2" style={{ width: '95px' }}>
                    請求送付先案件名
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Control readOnly type="text" name="branchName" defaultValue={branchName} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group controlId="memo">
                <Form.Label>配送memo</Form.Label>
                <Form.Control as="textarea" rows={3} name="memo" value={note} onChange={onChangeText} />
              </Form.Group>
            </Row>
            <div className="d-flex justify-content-center mt-4 mb-2">
              <Button variant="secondary" className="me-4" onClick={() => onClickSave(false)}>
                テンプレとして保存
              </Button>
              <Button variant="secondary" className="me-4" onClick={() => onClickSave(true)}>
                今回のみ
              </Button>
              <Button variant="secondary" className="me-4" onClick={onHide}>
                キャンセル
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
