import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosError } from 'axios';
import React, { ChangeEvent, useCallback } from 'react';
import { Alert, Card, Form } from 'react-bootstrap';
import type { ReceiptNoDuplicateCheckOutputResponse } from '../../../../../../../api-client';
import { useLargeState } from '../../../../../../../hooks/useLargeState';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext, CorrectionPageState } from '../../../../../../../store/correctionStore';
import { debugLog, uuid, when } from '../../../../../../../utils/functions';
import { Button } from '../../../../../../atoms/Button';
import { ImageThumbnail } from '../../../../../../atoms/ImageThumbnail';
import { SideBy } from '../../../../../../atoms/SideBy';

export const ReceiptCheck: React.FC = () => {
  const {
    largeState: { state: $, setState },
  } = useSafeContext(CorrectionContext);
  const {
    state: f,
    onChangeCheckbox: check,
    onChangeSet: set,
  } = useLargeState<NonNullable<CorrectionPageState['receiptForm_receiptCheck']>>($.receiptForm_receiptCheck ?? {});
  const onOK = () => {
    setState((prev) => ({
      ...prev,
      receiptForm_receiptCheck: {
        ...(prev.receiptForm_receiptCheck ?? {}),
        ...f,
        result: true,
        errorPhotoList: undefined,
      },
    }));
  };
  const onClickForceOk = (e: ChangeEvent<HTMLInputElement>) => {
    setState((prev) => ({
      ...prev,
      receiptForm_receiptCheck: {
        ...(prev.receiptForm_receiptCheck ?? {}),
        ...f,
        forceOkFlg: e.target.checked,
      },
    }));
    if (e.target.checked) {
      onOK();
    }
  };

  const onCheck = useCallback(() => {
    debugLog({ applyId: $.applyId, receiptNo: f.receiptNo });
    try {
      if (!f.checkbox && f.receiptNo) {
        $.api_correctReceipt
          .receiptNoDuplicateCheck({ applyId: $.applyId, receiptNo: f.receiptNo })
          .then((res) => {
            if (res.data.length === 0) {
              onOK();
            } else {
              setState((prev) => ({
                ...prev,
                receiptForm_receiptCheck: {
                  ...(prev.receiptForm_receiptCheck ?? {}),
                  ...f,
                  result: false,
                  errorPhotoList: res.data,
                },
              }));
            }
          })
          .catch((e: AxiosError<ReceiptNoDuplicateCheckOutputResponse[]>) => {
            setState((prev) => ({
              ...prev,
              receiptForm_receiptCheck: {
                ...(prev.receiptForm_receiptCheck ?? {}),
                ...f,
                result: false,
                errorPhotoList: e.response?.data,
              },
            }));
          });
      } else if (f.checkbox) {
        onOK();
      } else {
        setState((prev) => ({
          ...prev,
          receiptForm_receiptCheck: {
            ...(prev.receiptForm_receiptCheck ?? {}),
            ...f,
            result: false,
            errorPhotoList: undefined,
          },
        }));
      }
    } catch (error) {
      console.warn(error);
    }
  }, [$.api_correctReceipt, $.applyId, f, setState]);
  return (
    <>
      <Form>
        <Form.Group>
          <Form.Check>
            <Form.Check.Input type="checkbox" checked={f.checkbox ?? false} onChange={check('checkbox')} />
            <Form.Check.Label>レシートNoなし</Form.Check.Label>
          </Form.Check>
        </Form.Group>
        <SideBy>
          <Form.Group>
            <Form.Control size="lg" disabled={f.checkbox} value={f.receiptNo ?? ''} onChange={set('receiptNo')} />
          </Form.Group>
          <Button onClick={onCheck}>チェックして添削結果に反映</Button>
        </SideBy>
        {when(
          f.result === true,
          <Alert variant="success">
            <FontAwesomeIcon icon={faCheckCircle} /> チェックOK
          </Alert>
        )}
        {!f.checkbox && !f.receiptNo ? (
          <Alert variant="danger">
            <FontAwesomeIcon icon={faBan} /> 「レシートNoなし」にチェックを入れるか、レシート番号を入力してください。
          </Alert>
        ) : (
          f.result === false && (
            <>
              <Alert variant="danger">
                <FontAwesomeIcon icon={faBan} /> 同一店舗でレシートNoが重複しています。
                {f.errorPhotoList?.map((applies) => (
                  <Card body key={uuid()}>
                    <h5>応募ID: {applies.applyId}</h5>
                    <SideBy>
                      {applies.imageUrlList.map(({ imageUrl }) => (
                        <ImageThumbnail key={uuid()} url={imageUrl} />
                      ))}
                    </SideBy>
                  </Card>
                ))}
              </Alert>
              <div>
                <Form.Check>
                  <Form.Check.Label htmlFor="receipt_no_force_flg">確認の上問題なし</Form.Check.Label>
                  <Form.Check.Input
                    id="receipt_no_force_flg"
                    checked={f.forceOkFlg ?? false}
                    onChange={(e) => onClickForceOk(e)}
                  />
                </Form.Check>
              </div>
            </>
          )
        )}
      </Form>
    </>
  );
};
