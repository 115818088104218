import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Alert } from '../../atoms/Alert';
import { BillingAgentApi, DepositFileDownloadOutputResponse } from '../../../api-client';
import { execDownload } from '../../../utils/functions';
import { Url } from '../../../constants/Url';

export const BillingAgentDepositDownloadPage: React.VFC = () => {
  const [month, setMonth] = useState<string>();
  const [error, setError] = useState<string>();
  const history = useHistory();

  const billingAgentApi = new BillingAgentApi();

  const handleChangeMonth = (e: any) => {
    setMonth(e.target.value.replace('-', ''));
  };

  const handleClickDownload = (e: any) => {
    e.preventDefault();

    if (month) {
      billingAgentApi.downloadDepositFile(month).then((response: AxiosResponse<DepositFileDownloadOutputResponse>) => {
        execDownload(response.data.data, 'text/csv', `diposit-${month}.csv`).catch(() => {
          history.push(Url.COMMON_ERROR);
        });
      });
    } else {
      setError('年月は必須入力項目です。');
    }
  };

  return (
    <>
      <h3>入金データダウンロード</h3>
      {error && <Alert variant="danger">{error}</Alert>}
      <Row className="mb-4">
        <Col className="col-4">
          <Form.Group controlId="month">
            <Form.Label>対象月</Form.Label>
            <Form.Control type="month" onChange={handleChangeMonth} />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-4">
          <Button variant="secondary" className="me-4" onClick={handleClickDownload}>
            入金データダウンロード
          </Button>
        </Col>
      </Row>
    </>
  );
};
