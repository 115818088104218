import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { EnqueteInfoOutputResponse } from '../../../api-client';

export interface Props {
  showFlg: boolean;
  setShowFlg: React.Dispatch<React.SetStateAction<boolean>>;
  data?: EnqueteInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<EnqueteInfoOutputResponse>>;
  selectedQuestionId: number | undefined;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const PromptReportMailModal: React.VFC<Props> = ({
  showFlg,
  setShowFlg,
  data,
  setData,
  selectedQuestionId,
  setSelectedQuestionId,
}) => {
  const [mailContent, setMailContent] = useState<string>();

  useEffect(() => {
    setMailContent(
      data?.enqueteQuestionsList.find((question) => {
        return question.questionId === selectedQuestionId;
      })?.mailContent
    );
  }, [selectedQuestionId]);

  // キャンセルまたはモーダルを閉じた場合のイベント
  const onHide = () => {
    setShowFlg(false);
    setSelectedQuestionId(undefined);
  };

  // 保存イベント
  const onSubmit = () => {
    if (data?.enqueteQuestionsList && mailContent)
      setData({
        ...data,
        enqueteQuestionsList: data?.enqueteQuestionsList.map((question) => {
          return question.questionId === selectedQuestionId ? { ...question, mailContent } : { ...question };
        }),
      });
    setShowFlg(false);
    setSelectedQuestionId(undefined);
  };

  return (
    <Modal size="lg" show={showFlg} onHide={onHide} scrollable centered>
      <Modal.Header closeButton>
        <h3 className="mb-0">速報メールに表示する内容を記入</h3>{' '}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-end mb-4">
          <Button variant="link" className="ms-2" onClick={onHide}>
            キャンセル
          </Button>
          <Button onClick={onSubmit} className="ms-2">
            保存
          </Button>
        </div>
        <div>
          <Form.Group className="mb-4">
            <Form.Label className="text-secondary">
              内容を記載すると速報メール機能をON、空欄にするとOFFになります。
            </Form.Label>
            <Form.Control
              id="mailContent"
              defaultValue={mailContent || ''}
              onChange={(event) => setMailContent(event.target.value)}
              type="text"
            />
          </Form.Group>
        </div>
      </Modal.Body>
    </Modal>
  );
};
