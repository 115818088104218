import React, { SyntheticEvent, useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../hooks/useLargeState';
import { Button } from '../atoms/Button';
import { LoadingSpinner } from '../molecules/Loading/LoadingSpinner';
import { Modal } from '../molecules/Modal';
// eslint-disable-next-line import/no-cycle
import { BulkRejectApi, ErrorObjectResponse, ResultOutputResponse } from '../../api-client';
import type {
  BulkRejectTargetApplyListFormResponse,
  BulkRejectTargetApplyListOutputResponse,
  BulkRejectTargetApplyFormResponse,
  IncResultOutputResponse,
} from '../../api-client';
import { BulkRejectForms } from '../organisms/Forms/BulkRejectForms';
import { BulkRejectTable } from '../organisms/Table/BulkRejectTable';
import { createTestId } from '../../utils/functions';
import { Alert } from '../atoms/Alert';
import { Title } from '../atoms/Title';

export interface BulkRejectState {
  api: BulkRejectApi;
  isLoading: boolean;
  list: BulkRejectTargetApplyListOutputResponse[];
  reason: string;
  forms: FormState;
  isConfirm: boolean;
  isModal: boolean;
  modalMessage: string;
  errorMessages: string[];
}
export type TestIds = 'confirm-button' | 'submit-button' | 'ok-button' | 'reason';
export type FormState = { applyIdList: string[]; customerId?: string };

export const BulkRejectPage: React.FC = () => {
  const testid = createTestId<TestIds>(BulkRejectPage);
  const {
    state: $,
    mergeState,
    onChangeSet,
    useBindSet,
  } = useLargeState<BulkRejectState>({
    api: new BulkRejectApi(),
    isLoading: false,
    list: [],
    forms: {
      applyIdList: [],
      customerId: '',
    },
    reason: '',
    isConfirm: false,
    isModal: false,
    modalMessage: '',
    errorMessages: [],
  });

  const setForms = useBindSet('forms');
  const history = useHistory();

  const createConfirmBody = useCallback((body: FormState) => {
    return {
      applyIdList: body.applyIdList ? body.applyIdList.map((applyId: string) => Number(applyId)) : body.applyIdList,
      customerId: body.customerId ? Number(body.customerId) : undefined,
    };
  }, []);

  // 確認
  const onConfirm = useCallback(
    (body: FormState) => {
      if (body.applyIdList.length === 0 && !body.customerId) {
        mergeState({ errorMessages: ['検索条件を入力してください'], list: [] });
        return;
      }

      if (body.applyIdList.length !== 0 && body.customerId) {
        mergeState({ errorMessages: ['指定するのは会員IDか応募IDのいずれかのみにしてください'], list: [] });
        return;
      }

      if (body.applyIdList.length !== Array.from(new Set(body.applyIdList)).length) {
        mergeState({ errorMessages: ['応募IDに重複があります。'], list: [] });
        return;
      }

      const confirmBody: BulkRejectTargetApplyListFormResponse = createConfirmBody(body);
      $.api
        .bulkRejectTargetApplyList(confirmBody)
        .then((res: AxiosResponse<BulkRejectTargetApplyListOutputResponse[]>) => {
          console.log(res.data);
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessages: ['エラーが発生しました'] });
          } else {
            mergeState({ list: res.data, errorMessages: [] });
          }
        })
        .catch((error: { response: { data: { errors: any } }; errors: any }) => {
          const { errors } = error.response.data;
          const errorMessages = errors.map((value: ErrorObjectResponse) => value.message);
          mergeState({ list: [], errorMessages });
        });
    },
    [$.api, createConfirmBody, mergeState]
  );

  const createregisterBody = useCallback((body: BulkRejectTargetApplyListOutputResponse[], reason: string) => {
    const returnObj: BulkRejectTargetApplyFormResponse = { applyIdList: [], rejectReason: reason };

    body.forEach((targetApply: BulkRejectTargetApplyListOutputResponse) => {
      returnObj.applyIdList.push(targetApply.applyId);
    });
    return returnObj;
  }, []);

  // 実行
  const onSubmit = useCallback(
    (body: BulkRejectTargetApplyListOutputResponse[], reason: string) => {
      if (!reason) {
        mergeState({ errorMessages: ['無効理由を入力してください'] });
        return;
      }
      const registerBody: BulkRejectTargetApplyFormResponse = createregisterBody(body, reason);
      mergeState({ isLoading: true });
      $.api
        .bulkRejectTargetApply(registerBody)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (res.data.result) {
            mergeState({ isLoading: false, isModal: true, modalMessage: '登録に成功しました。', errorMessages: [] });
          } else {
            mergeState({ isLoading: false, errorMessages: [] });
          }
        })
        .catch((error) => {
          const { errors } = error.response.data;
          const errorMessages = errors.map((value: ErrorObjectResponse) => value.message);
          mergeState({ list: [], errorMessages });
        });
    },
    [$.api, createregisterBody, mergeState, $.reason]
  );

  return (
    <>
      <Modal
        centered
        isModal={$.isModal}
        body={
          <>
            <p className="text-center">{$.modalMessage}</p>
            <div className="text-center">
              <Button data-testid={testid('ok-button')} onClick={() => history.go(0)}>
                OK
              </Button>
            </div>
          </>
        }
      />
      <LoadingSpinner isLoading={$.isLoading}>
        {$.errorMessages.map((data) => (
          <Alert variant="danger">{data}</Alert>
        ))}
        <Title>一括モニター無効</Title>
        <Form className="mt-4">
          <Row className="g-2 mb-4">
            <Col>
              <BulkRejectForms setForms={setForms} />
              <Button
                data-testid={testid('confirm-button')}
                className="mt-4"
                onClick={() => {
                  onConfirm($.forms);
                }}
              >
                確認
              </Button>
            </Col>
          </Row>
        </Form>
        {$.list.length ? (
          <>
            <Form.Group data-testid={testid('reason')}>
              <Form.Label>無効理由</Form.Label>
              <Form.Control
                type="string"
                autoComplete="on"
                required
                value={$.reason}
                onChange={onChangeSet('reason', String)}
              />
            </Form.Group>
            <BulkRejectTable list={$.list} />
            <Button
              data-testid={testid('submit-button')}
              className="mt-4"
              onClick={() => {
                onSubmit($.list, $.reason);
              }}
            >
              実行
            </Button>
          </>
        ) : undefined}
      </LoadingSpinner>
    </>
  );
};
