import React, { Dispatch, SetStateAction } from 'react';
import { Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FieldArrayWithId, UseFormRegister, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { getImageSize } from '../../utils/s3';
import { Button } from '../atoms/Button';
import { FormControl } from '../molecules/Form/FormControl';
import { Dropzone } from '../molecules/Dropzone';
import { ImageCard } from '../molecules/ImageCard';
import { PARAM_NAME, RequiredRichUpdateFormResponse } from '../pages/Brand/BrandRichPage';
import type { Image } from '../pages/Brand/BrandRichPage';

export interface Props {
  fields: FieldArrayWithId<RequiredRichUpdateFormResponse, 'designPatternList', 'id'>[];
  register: UseFormRegister<RequiredRichUpdateFormResponse>;
  getValues: UseFormGetValues<RequiredRichUpdateFormResponse>;
  setValue: UseFormSetValue<RequiredRichUpdateFormResponse>;
  findParamIndex: (designPatternListIndex: number, str: string) => number;
  images: Image[];
  setImages: Dispatch<SetStateAction<Image[]>>;
}

export const RichDragDropContext: React.VFC<Props> = ({
  fields,
  register,
  getValues,
  setValue,
  findParamIndex,
  images,
  setImages,
}) => {
  const handleOnDragEnd = (result: DropResult) => {
    if (!result) return;
    const designPatternList = getValues('designPatternList');
    const [draggedItem] = designPatternList.splice(result.source.index, 1);
    designPatternList.splice(result.destination!.index, 0, draggedItem);
    setValue('designPatternList', designPatternList);

    const updImages = images;
    const [draggedImage] = updImages.splice(result.source.index, 1);
    updImages.splice(result.destination!.index, 0, draggedImage);
    setImages(updImages);
  };

  const handleOnDeleteImage = (index: number) => {
    const deletedImage = images.map((v, i) => {
      return index === i ? { isDefault: v.isDefault, id: v.id, value: '' } : v;
    });
    setImages(deletedImage);
  };

  const handleOnDrop = async (files: File[], index: number) => {
    const [file] = files;
    if (!file) return;

    const { name, type: contentType } = file;
    const value = window.URL.createObjectURL(file);
    const format = name.split('.').pop();
    const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));

    setImages(
      images.map((image, i) => (index === i ? { ...image, ...size, file, name, contentType, value, format } : image))
    );
  };

  const renderImageArea = (index: number) => {
    if (images.length === 0) return;
    let elm;

    if (images[index].value) {
      const { value, name } = images[index];

      elm = <ImageCard url={value || ''} name={name} onDelete={() => handleOnDeleteImage(index)} />;
    } else {
      elm = <Dropzone onDrop={(files) => handleOnDrop(files, index)} />;
    }
    return elm;
  };

  const handleOnDeleteSet = (index: number) => {
    const designPatternList = getValues('designPatternList');
    designPatternList.splice(index, 1);
    setValue('designPatternList', designPatternList);

    const updImages = images;
    updImages.splice(index, 1);
    setImages(updImages);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="patterns">
        {(droppableProvided) => (
          <ul
            style={{ listStyle: 'none' }}
            className="patterns ps-0"
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
          >
            {fields.map((_, idx) => {
              return (
                <Draggable
                  key={getValues(`designPatternList.${idx}.setNo`)}
                  draggableId={getValues(`designPatternList.${idx}.setNo`)}
                  index={idx}
                >
                  {(draggableProvided) => (
                    <li ref={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
                      <Card className="mb-4">
                        <Card.Body className="d-flex gap-4">
                          <button
                            type="button"
                            style={{ cursor: 'default' }}
                            data-testid="grip"
                            {...draggableProvided.dragHandleProps}
                          >
                            <FontAwesomeIcon style={{ cursor: 'grab' }} icon={faGripLines} fixedWidth />
                          </button>
                          <div className="flex-grow-1">
                            <Form.Group className="flex-1 mb-4">
                              <Form.Label>見出し</Form.Label>
                              <FormControl
                                register={register}
                                registerName={`designPatternList.${idx}.paramList.${findParamIndex(
                                  idx,
                                  PARAM_NAME.HEADING
                                )}.value`}
                                type="text"
                              />
                            </Form.Group>

                            <Form.Group className="mb-4">
                              <Form.Label>文言</Form.Label>
                              <FormControl
                                register={register}
                                registerName={`designPatternList.${idx}.paramList.${findParamIndex(
                                  idx,
                                  PARAM_NAME.WORDING
                                )}.value`}
                                type="text"
                                as="textarea"
                                rows={4}
                              />
                            </Form.Group>

                            <Form.Group>
                              <Form.Label>設定画像</Form.Label>
                              {renderImageArea(idx)}
                            </Form.Group>
                          </div>
                          <Button
                            variant="link"
                            testId="delete"
                            className="text-secondary"
                            onClick={() => handleOnDeleteSet(idx)}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                          </Button>
                        </Card.Body>
                      </Card>
                    </li>
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};
