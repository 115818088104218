import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import type {
  ShopReplaceConfirmDisplayFormResponse as ConfirmForms,
  ShopReplaceMonitorSearchFormResponse as Forms,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import { createTestId } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import type { ShopData, State as PageState } from '../../pages/ShopReplacePage';
import { ShopReplaceMonitorSearchTable } from '../Table/ShopReplaceMonitorSearchTable';

export const ShopReplaceMonitorSearchModal: React.FC<{
  isModal: PageState['isMonitorSearchModal'];
  setShow: DispatchSetState<PageState['isMonitorSearchModal']>;
  setAfterReplaceMonitorBaseId: DispatchSetState<ConfirmForms['afterReplaceMonitorBaseId']>;
  applyId?: ConfirmForms['applyId'];
  beforeMonitorId: ShopData['monitorBaseId'];
  monitorSearchList: PageState['monitorSearchList'];
  onSearchMonitorList: (form: Forms) => void;
}> = ({
  isModal,
  setShow,
  setAfterReplaceMonitorBaseId,
  applyId,
  beforeMonitorId,
  monitorSearchList,
  onSearchMonitorList,
}) => {
  const testid = createTestId(ShopReplaceMonitorSearchModal);
  const formid = (x: keyof Forms) => testid(x);

  const SPLIT_NUM = 3;

  const { state: f, onChangeSet } = useLargeState<Partial<Forms>>({});

  return (
    <Modal
      closeButton
      centered
      scrollable
      isModal={isModal}
      onHide={() => setShow(false)}
      title="モニター検索"
      size="lg"
      body={
        <>
          <Form>
            <Row className="g-2 mb-4">
              <Col>
                <Form.Group data-testid={formid('monitorBaseId')}>
                  <Form.Label>モニターベースID</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="00000"
                    autoComplete="off"
                    value={f.monitorBaseId ?? ''}
                    onChange={onChangeSet('monitorBaseId', Number)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group data-testid={formid('shopName')}>
                  <Form.Label>店舗名</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="○○駅前店"
                    autoComplete="off"
                    value={f.shopName ?? ''}
                    onChange={onChangeSet('shopName', String)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Button
            data-testid={testid('search-button')}
            disabled={!applyId}
            onClick={() => {
              if (!applyId) return;
              onSearchMonitorList({
                applyId,
                monitorBaseId: f.monitorBaseId,
                shopName: f.shopName,
              });
            }}
          >
            検索
          </Button>
          <hr />
          {monitorSearchList !== undefined && monitorSearchList.length ? (
            <ShopReplaceMonitorSearchTable
              resultNum={SPLIT_NUM}
              beforeMonitorBaseId={beforeMonitorId}
              monitorSearchList={monitorSearchList}
              onSelect={(id) => {
                setAfterReplaceMonitorBaseId(id);
                setShow(false);
              }}
            />
          ) : (
            <div data-testid={testid('not-found')}>
              {monitorSearchList !== undefined ? '一致する情報は見つかりません' : ''}
            </div>
          )}
        </>
      }
    />
  );
};
