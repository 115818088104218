import React, { useMemo, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, isoDateToInput, nameof } from '../../../utils/functions';
import { DispatchSetState, FormElement } from '../../../interfaces/utils';
import type { GiftTicketPageState } from '../../pages/GiftTicketPage';

export type State = Required<GiftTicketPageState>['editForms'];
type EditableFormsState = Pick<
  State,
  | 'giftTicketId'
  | 'giftUseState'
  | 'usedShopId'
  | 'expirationDate'
  | 'usedDate'
  | 'messageForCustomer'
  | 'internalComment'
>;

export interface Props {
  forms: State;
  setForms: DispatchSetState<State>;
  statusList: GiftTicketPageState['statusList'];
}
export type TestIds = keyof EditableFormsState;

export const GiftTicketEditForms: React.FC<Props> = ({ forms, setForms, statusList }) => {
  const testId = createTestId<TestIds>(GiftTicketEditForms);
  const { state: $, onChangeSet } = useLargeState<State>({
    ...forms,
    expirationDate: forms.expirationDate ? isoDateToInput('date', forms.expirationDate) : '',
    usedDate: forms.usedDate ? isoDateToInput('datetime-local', forms?.usedDate || '') : undefined,
  });
  const editForms: FormElement<EditableFormsState, { label: string; type: React.HTMLInputTypeAttribute }>[] = useMemo(
    () => [
      {
        label: '利用店舗ID',
        type: 'number',
        name: 'usedShopId',
        onChange: onChangeSet('usedShopId'),
      },
      {
        label: '有効期限',
        type: 'date',
        name: 'expirationDate',
        onChange: onChangeSet('expirationDate'),
      },
      {
        label: '利用日時',
        type: 'datetime-local',
        name: 'usedDate',
        onChange: onChangeSet('usedDate'),
      },
      {
        label: 'メッセージ',
        type: 'text',
        name: 'messageForCustomer',
        onChange: onChangeSet('messageForCustomer'),
      },
      {
        label: '内部コメント',
        type: 'text',
        name: 'internalComment',
        onChange: onChangeSet('internalComment'),
      },
    ],
    [onChangeSet]
  );

  useEffect(() => setForms($), [setForms, $]);

  return (
    <>
      <Form.Group
        data-testid={testId('giftTicketId')}
        controlId={nameof<State>('giftTicketId')}
        key={nameof<State>('giftTicketId')}
      >
        <Form.Label>ギフト券ID</Form.Label>
        <Form.Control type="number" autoComplete="off" value={$.giftTicketId || ''} disabled />
      </Form.Group>
      <Form.Group
        data-testid={testId('giftUseState')}
        controlId={nameof<State>('giftUseState')}
        key={nameof<State>('giftUseState')}
      >
        <Form.Label>利用状態</Form.Label>
        <Form.Select aria-label="Default select example" value={$.giftUseState} onChange={onChangeSet('giftUseState')}>
          {(statusList || []).map(({ code, name }) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      {editForms.map(({ label, name, type, onChange }) => (
        <Form.Group data-testid={testId(name)} controlId={name} key={name}>
          <Form.Label>{label}</Form.Label>
          <Form.Control type={type} autoComplete="off" value={$[name] || ''} onChange={onChange} />
        </Form.Group>
      ))}
    </>
  );
};
