import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export interface HeadProps {
  title: string;
  description: string;
  keywords: string;
  url: string;
  thumbnail: string;
  noindex: string;
}

const Head: React.FC<HeadProps> = ({ title, description, keywords, url, thumbnail, noindex }) => {
  return (
    <HelmetProvider>
      <Helmet
        htmlAttributes={{ lang: 'ja' }}
        title={title}
        link={[{ rel: 'canonical', href: url }]}
        meta={[
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1, shrink-to-fit=no, viewport-fit=cover',
          },
          { name: 'robots', content: noindex },
          { name: 'description', content: description },
          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:description', content: description },
          { name: 'twitter:image', content: thumbnail },
          { name: 'twitter:site', content: '@aaaaaa' },
          { name: 'twitter:text:title', content: title },
          { property: 'og:description', content: description },
          { property: 'og:image', content: thumbnail },
          { property: 'og:site_name', content: 'ファンくる' },
          { property: 'og:title', content: title },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: url },
          { property: 'keywords', content: keywords },
        ]}
      />
    </HelmetProvider>
  );
};

export default Head;
