import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { AxiosResponse } from 'axios';

import { DefaultApi, AboutOpeningHourSetInfoOutputResponse, IncResultOutputResponse } from '../../api-client';
import { TITLE } from '../../constants/Title';

import { Title } from '../atoms/Title';
import { Alert } from '../atoms/Alert';
import { Button } from '../atoms/Button';
import { AboutOpeningTable } from '../organisms/Table/AboutOpeningTable';

type DateType = 'startTimestamp' | 'endTimestamp';

const DATE_TYPE = {
  START: 'startTimestamp',
  END: 'endTimestamp',
} as const;

export const AboutOpeningPage: React.VFC = () => {
  const initialData: AboutOpeningHourSetInfoOutputResponse = {
    contentHtml: '',
    startTimestamp: '',
    endTimestamp: '',
  };

  const initialResult: IncResultOutputResponse = {
    result: false,
    errorCode: '',
    errorMessage: '',
  };

  const [data, setData] = useState<AboutOpeningHourSetInfoOutputResponse>(initialData);
  const [updResults, setUpdResults] = useState<IncResultOutputResponse>(initialResult);

  const defaultApi = new DefaultApi();

  useEffect(() => {
    defaultApi.aboutOpeningHourSetInfo().then((res: AxiosResponse<AboutOpeningHourSetInfoOutputResponse>) => {
      setData(res.data);
    });
  }, []);

  const handleChangeTextarea = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData({ ...data, contentHtml: e.target.value });
  };

  const handleChangeDatepicker = (key: DateType, e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.value) return;
    const replacedValue = e.target.value.replace(/-/g, '/');
    setData({
      ...data,
      [key]: data[key] ? data[key].replace(/([0-9/]*) /, `${replacedValue} `) : `${replacedValue} `,
    });
  };

  const handleChangeTimepicker = (key: DateType, e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.value) return;
    setData({
      ...data,
      [key]: data[key] ? data[key].replace(/ ([0-9:]*)/, ` ${e.target.value}`) : ` ${e.target.value}`,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    // console.log({ data });
    defaultApi.aboutOpeningHourSetUpdate(data).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResults(res.data);
    });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.ABOUT_OPENING}</Title>
      {updResults.result && <Alert variant="success">更新しました。</Alert>}
      {updResults.errorMessage && (
        <Alert variant="danger">{`${updResults.errorMessage} (エラーコード：${updResults.errorCode})`}</Alert>
      )}
      <Form className="mb-4" onSubmit={handleSubmit}>
        <AboutOpeningTable
          data={data}
          handleChangeTextarea={handleChangeTextarea}
          handleChangeDatepickerStart={(e) => handleChangeDatepicker(DATE_TYPE.START, e)}
          handleChangeTimepickerStart={(e) => handleChangeTimepicker(DATE_TYPE.START, e)}
          handleChangeDatepickerEnd={(e) => handleChangeDatepicker(DATE_TYPE.END, e)}
          handleChangeTimepickerEnd={(e) => handleChangeTimepicker(DATE_TYPE.END, e)}
        />
        <Button type="submit">更新</Button>
      </Form>
    </>
  );
};
