import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  className?: string;
}

export const Datepicker: React.VFC<Props> = (props) => {
  return <Form.Control type="date" {...props} />;
};
