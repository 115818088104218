import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { Card, Col, Container, Form, Popover, Row, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ENQUETE_TYPE } from '../../pages/Enquete/EnqueteListPage';
import { QuestionInfoApi, QuestionInfoOutputResponse, SelectionInfoOutputResponse } from '../../../api-client';
import { ANSWER_METHOD, ANSWER_METHOD_SUMMARY } from '../Modal/Question/QuestionConfirmModal';
import { uuid } from '../../../utils/functions';

export interface Props {
  questionId: number;
  enqueteType: number;
}

export const QuestionInfoPopover: React.VFC<Props> = memo(({ questionId, enqueteType }) => {
  const [data, setData] = useState<QuestionInfoOutputResponse>();

  useEffect(() => {
    const questionInfoApi = new QuestionInfoApi();
    questionInfoApi.questionInfo(questionId).then((res: AxiosResponse<QuestionInfoOutputResponse>) => {
      setData(res.data);
    });
  }, []);

  const MultiSelectTable = () => {
    return (
      <>
        {data && (
          <>
            <div className="d-flex justify-content-start">
              <Form.Label style={{ fontWeight: 'bolder' }}>選択肢（横軸）</Form.Label>
            </div>
            <Card>
              <SelectTable />
            </Card>
            <div className="d-flex justify-content-start">
              <Form.Label style={{ fontWeight: 'bolder' }}>項目（縦軸）</Form.Label>
            </div>
            <Card>
              <Table>
                <thead>
                  <tr style={{ whiteSpace: 'nowrap' }}>
                    <th style={{ width: '10rem' }}>回答選択肢</th>
                  </tr>
                </thead>
                <tbody>
                  {data.verticalSelections.map((selection) => (
                    <tr key={uuid()} style={{ whiteSpace: 'nowrap', textAlign: 'left' }}>
                      <td style={{ width: '10rem' }}>{selection.content}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </>
        )}
      </>
    );
  };

  const SelectTable = () => {
    return (
      <>
        {data && (
          <Table>
            <thead>
              <tr style={{ whiteSpace: 'nowrap' }}>
                <th style={{ width: '10rem' }}>回答選択肢</th>
                {enqueteType === ENQUETE_TYPE.BEFOREHAND && (
                  <>
                    <th>当選</th>
                    <th>落選</th>
                  </>
                )}
                {enqueteType === ENQUETE_TYPE.MEMBERSHIP && (
                  <>
                    <th>&nbsp;</th>
                  </>
                )}
                {enqueteType !== ENQUETE_TYPE.BEFOREHAND && enqueteType !== ENQUETE_TYPE.MEMBERSHIP && (
                  <>
                    <th>
                      <div className="d-flex justify-content-end">
                        <p style={{ marginBottom: '0px' }}>得点</p>
                        <Form.Check size={100} type="switch" defaultChecked={!data.selectionPointDisableFlg} />
                      </div>
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {data.selections.map((selection: SelectionInfoOutputResponse) => (
                <tr key={uuid()} style={{ whiteSpace: 'nowrap' }}>
                  {enqueteType === ENQUETE_TYPE.MEMBERSHIP && (
                    <>
                      <td style={{ textAlign: 'left' }}>{selection.customerTagAnswerName}</td>
                    </>
                  )}
                  <td style={{ width: '10rem', textAlign: 'left' }}>{selection.content}</td>
                  {enqueteType === ENQUETE_TYPE.BEFOREHAND && (
                    <>
                      <td>
                        <Form.Check defaultChecked={selection.winFlg} />
                      </td>
                      <td>
                        <Form.Check defaultChecked={selection.loseFlg} />
                      </td>
                    </>
                  )}
                  {enqueteType !== ENQUETE_TYPE.BEFOREHAND && enqueteType !== ENQUETE_TYPE.MEMBERSHIP && (
                    <>
                      <td style={{ textAlign: 'right' }}>{selection.point}</td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </>
    );
  };

  const TextTable = () => {
    return (
      <>
        {data && (
          <Table>
            <thead>
              <tr style={{ whiteSpace: 'nowrap' }}>
                <th>回答最小文字数</th>
                <th>回答最大文字数</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                <td>{data.minimumInput}</td>
                <td>{data.maximumInput}</td>
              </tr>
            </tbody>
          </Table>
        )}
      </>
    );
  };

  return (
    <>
      {data && (
        <>
          <Popover.Header>{data.questionAnswerMethodName}</Popover.Header>
          <Popover.Body className="p-2 text-center">
            {data.questionAnswerMethodSummary === ANSWER_METHOD_SUMMARY.SELECT &&
              (data.questionAnswerMethod === ANSWER_METHOD.SINGLE_MATRIX ||
                data.questionAnswerMethod === ANSWER_METHOD.MULTI_MATRIX) && <MultiSelectTable />}
            {data.questionAnswerMethodSummary === ANSWER_METHOD_SUMMARY.SELECT &&
              !(
                data.questionAnswerMethod === ANSWER_METHOD.SINGLE_MATRIX ||
                data.questionAnswerMethod === ANSWER_METHOD.MULTI_MATRIX
              ) && <SelectTable />}
            {data.questionAnswerMethodSummary === ANSWER_METHOD_SUMMARY.TEXT && <TextTable />}
          </Popover.Body>
        </>
      )}
    </>
  );
});
