/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UnBillingApprovalListOutputResponse
 */
export interface UnBillingApprovalListOutputResponse {
    /**
     * 申請者
     * @type {string}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'applicant': string;
    /**
     * 応募ID
     * @type {number}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'applyId': number;
    /**
     * 承認者
     * @type {string}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'approver'?: string;
    /**
     * 申請ID。更新処理で利用する
     * @type {number}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'billingApprovalId': number;
    /**
     * クライアント名
     * @type {string}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'clientName': string;
    /**
     * 申請日
     * @type {string}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'createDate': string;
    /**
     * 管理画面非表示フラグ
     * @type {boolean}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'hideAnswerFlg': boolean;
    /**
     * 増枠フラグ
     * @type {boolean}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'increaseSlotFlg': boolean;
    /**
     * 再発防止コメント
     * @type {string}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'preventionComment': string;
    /**
     * 申請理由
     * @type {string}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'reason': string;
    /**
     * 却下理由
     * @type {string}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'rejectComment': string;
    /**
     * 店舗名
     * @type {string}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'shopName': string;
    /**
     * 承認:0,却下:1
     * @type {string}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'unBillingApprovalStatus'?: UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum;
    /**
     * 非請求フラグ
     * @type {boolean}
     * @memberof UnBillingApprovalListOutputResponse
     */
    'unBillingFlg': boolean;
}

export const UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum = {
    Approve: 'APPROVE',
    Reject: 'REJECT'
} as const;

export type UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum = typeof UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum[keyof typeof UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum];


