import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { OemCustomerApplyListApi, OemCustomerApplyListOutputResponse } from '../../../api-client';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
// eslint-disable-next-line import/no-cycle
import { execDownload, nothingWithHyphenStr } from '../../../utils/functions';
import { PaginationWithEllipsisBilling } from '../../molecules/PaginationWithEllipsisBilling';
import { Url } from '../../../constants/Url';
import { OemSelectBox } from '../../atoms/OemSelectBox';
import sass from '../../../scss/organisms/scrollTable.module.scss';
import { OemManagementHeader } from '../../molecules/OemManagementHeader';
import { APPLY_STATUS } from '../../../Constants';

interface SearchParams {
  customerId?: number;
  applyId?: number;
  apiClientId?: number;
  applyStatusCode?: number;
  createAtStart?: string;
  createAtEnd?: string;
  completeAtStart?: string;
  completeAtEnd?: string;
}

export const OemApplyInfoListPage: React.FC = () => {
  const MAX_RECORD_PER_PAGE = 20;
  const currentUser = useCurrentUser();
  const oemCustomerApplyListApi = new OemCustomerApplyListApi();
  const [list, setList] = useState<OemCustomerApplyListOutputResponse[]>([]);
  const [dataCount, setDataCount] = useState<number>();
  const history = useHistory();

  useEffect(() => {
    const apiClientId =
      typeof currentUser.currentUser?.apiclientId === 'number' ? currentUser.currentUser?.apiclientId : undefined;
    oemCustomerApplyListApi
      .oemCustomerApplyList(
        1,
        MAX_RECORD_PER_PAGE,
        undefined,
        undefined,
        apiClientId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then((res) => {
        setList(res.data.list);
        setDataCount(res.data.total);
      });
  }, []);

  const handleSearch = (data: SearchParams) => {
    const apiClientId =
      typeof currentUser.currentUser?.apiclientId === 'number'
        ? currentUser.currentUser?.apiclientId
        : data.apiClientId;

    oemCustomerApplyListApi
      .oemCustomerApplyList(
        1,
        MAX_RECORD_PER_PAGE,
        data.customerId,
        data.applyId,
        apiClientId,
        data.applyStatusCode,
        data.completeAtStart === '' ? undefined : data.completeAtStart,
        data.completeAtEnd === '' ? undefined : data.completeAtEnd,
        data.createAtStart === '' ? undefined : data.createAtStart,
        data.createAtEnd === '' ? undefined : data.createAtEnd
      )
      .then((res) => {
        setList(res.data.list);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<SearchParams>({
    defaultValues: {},
    reValidateMode: 'onSubmit',
    mode: 'onSubmit',
  });
  const onSubmit: SubmitHandler<SearchParams> = (data) => {
    handleSearch(data);
  };

  const onClickPageNation = (clickPage: number) => {
    const apiClientId =
      typeof currentUser.currentUser?.apiclientId === 'number'
        ? currentUser.currentUser?.apiclientId
        : getValues('apiClientId');
    oemCustomerApplyListApi
      .oemCustomerApplyList(
        clickPage,
        MAX_RECORD_PER_PAGE,
        getValues('customerId'),
        getValues('applyId'),
        apiClientId,
        getValues('applyStatusCode'),
        getValues('completeAtStart') === '' ? undefined : getValues('completeAtStart'),
        getValues('completeAtEnd') === '' ? undefined : getValues('completeAtEnd'),
        getValues('createAtStart') === '' ? undefined : getValues('createAtStart'),
        getValues('createAtEnd') === '' ? undefined : getValues('createAtEnd')
      )
      .then((res) => {
        setList(res.data.list);
        setDataCount(res.data.total);
      });
  };

  /**
   * ダウンロードボタン押下
   * @param e
   */
  const handleClickDownload = () => {
    const apiClientId =
      typeof currentUser.currentUser?.apiclientId === 'number'
        ? currentUser.currentUser?.apiclientId
        : getValues('apiClientId');
    oemCustomerApplyListApi
      .oemCustomerApplyListCsvDownload(
        getValues('customerId'),
        getValues('applyId'),
        apiClientId,
        getValues('applyStatusCode'),
        getValues('completeAtStart') === '' ? undefined : getValues('completeAtStart'),
        getValues('completeAtEnd') === '' ? undefined : getValues('completeAtEnd'),
        getValues('createAtStart') === '' ? undefined : getValues('createAtStart'),
        getValues('createAtEnd') === '' ? undefined : getValues('createAtEnd')
      )
      .then((res) => {
        return execDownload(res.data.data, 'text/csv', 'apply_information.csv');
      })
      .catch((e) => {
        history.push(Url.COMMON_ERROR);
      });
  };

  const emptyOne = (v1?: string, v2?: string) => {
    if (v1 !== undefined && v1.length > 0 && (v2 === undefined || v2 === '')) {
      return true;
    }

    return v2 !== undefined && v2.length > 0 && (v1 === undefined || v1 === '');
  };

  return (
    <>
      <OemManagementHeader title="会員・応募一覧" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group controlId="customerId">
              <Form.Label>会員ID</Form.Label>
              <Form.Control type="number" {...register('customerId')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="applyId">
              <Form.Label>応募ID</Form.Label>
              <Form.Control type="text" {...register('applyId')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="applyStatusCode">
              <Form.Label>応募ステータス</Form.Label>
              <Form.Select {...register('applyStatusCode')}>
                <option>{}</option>
                {APPLY_STATUS.map((v, index) => {
                  return <option value={index}>{v}</option>;
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          {typeof currentUser.currentUser?.apiclientId !== 'number' ? (
            <Col>
              <Form.Group controlId="apiClientId">
                <Form.Label>OEM</Form.Label>
                <OemSelectBox<SearchParams> register={register} registerName="apiClientId" />
              </Form.Group>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="winningAt">
              {errors.createAtStart !== undefined && <p style={{ color: 'red' }}>{errors.createAtStart.message}</p>}
              <Form.Label>応募日</Form.Label>
              <div className="d-flex justify-content-between">
                <Form.Control
                  type="date"
                  {...register('createAtStart', {
                    validate: (value) =>
                      emptyOne(value, getValues('createAtEnd')) ? '日付は両方入力してください。' : undefined,
                  })}
                />
                ～
                <Form.Control
                  type="date"
                  {...register('createAtEnd', {
                    validate: (value) =>
                      emptyOne(value, getValues('createAtStart')) ? '日付は両方入力してください。' : undefined,
                  })}
                />
              </div>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="completeAt">
              {errors.completeAtStart !== undefined && <p style={{ color: 'red' }}>{errors.completeAtStart.message}</p>}
              <Form.Label>完了日</Form.Label>
              <div className="d-flex justify-content-between">
                <Form.Control
                  type="date"
                  {...register('completeAtStart', {
                    validate: (value) =>
                      emptyOne(value, getValues('completeAtEnd')) ? '日付は両方入力してください。' : undefined,
                  })}
                />
                ～
                <Form.Control
                  type="date"
                  {...register('completeAtEnd', {
                    validate: (value) =>
                      emptyOne(value, getValues('completeAtStart')) ? '日付は両方入力してください。' : undefined,
                  })}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-center m-2">
          <Button type="submit">検索</Button>
        </div>
        <div className="d-flex justify-content-center m-2">
          <Button type="button" onClick={() => handleClickDownload()}>
            検索結果をダウンロード
          </Button>
        </div>
      </Form>
      <div className={sass.oemApplyListTable}>
        <Table>
          <thead>
            <tr>
              <th>会員ID</th>
              <th>応募ID</th>
              <th>実店舗ID</th>
              <th>実店舗名</th>
              <th>モニターID</th>
              <th>モニター名</th>
              <th>種別</th>
              <th>応募日</th>
              <th>当選ステータス</th>
              <th>提出期限</th>
              <th>モニター完了日</th>
              <th>来店日</th>
              <th>来店人数</th>
              <th>レシート金額</th>
              <th>レシート提出状況</th>
              <th>レシート提出日</th>
              <th>レシート再提出日</th>
              <th>レシートNG回数</th>
              <th>レシート画像</th>
              <th>アンケート提出状況</th>
              <th>アンケート提出日</th>
              <th>アンケート再提出日</th>
              <th>アンケートNG回数</th>
              <th>アンケート画像</th>
              <th>付与謝礼</th>
              <th>手数料</th>
              <th>謝礼タイプ</th>
              <th>謝礼</th>
              <th>謝礼上限</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 &&
              list.map((v) => {
                return (
                  <tr key={v.applyId}>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.customerId)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.applyId)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.shopId)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.shopName)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.monitorId)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.monitorName)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.category)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.applyAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.applyStatus)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.deadlineAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.completeAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.visitAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.visitCount)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.receiptPrice)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.receiptSubmitStatus)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.receiptSubmitAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.receiptResubmitAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.receiptNgNumber)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>
                      {v.receiptImageUrls.map((vv: string, index: number) => (
                        <span key={`receipt_${v.applyId}_${index}`} className="d-block">
                          <a href={nothingWithHyphenStr(vv)} target="_blank" rel="noreferrer">
                            画像{index}
                          </a>
                        </span>
                      ))}
                    </td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.enqueteSubmitStatus)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.enqeuteSubmitAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.enqueteResubmitAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.enqueteNgNumber)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>
                      {v.enqueteImageUrls.map((vv: string, index: number) => (
                        <span key={`enquete_${v.applyId}_${index}`} className="d-block">
                          <a href={nothingWithHyphenStr(vv)} target="_blank" rel="noreferrer">
                            画像{index}
                          </a>
                        </span>
                      ))}
                    </td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.grantReward)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.fee)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.rewardType)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.reward)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.rewardLimit)}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      {dataCount !== undefined && (
        <div className="d-flex justify-content-center">
          <PaginationWithEllipsisBilling
            maxRecordPerPage={MAX_RECORD_PER_PAGE}
            totalDataNum={dataCount}
            handleClick={(e) => onClickPageNation(e.page)}
          />
        </div>
      )}
    </>
  );
};
