import React, { useMemo, useCallback } from 'react';
import { Button, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Url } from '../../../constants/Url';
import { createTestId, formatISODate, when } from '../../../utils/functions';
import type { CustomerListState } from '../../pages/Customer/CustomerListPage';
import { PaginationTable } from './PaginationTable';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { AgentLogin } from '../AgentLogin';

interface Props {
  dataList: CustomerListState['list'];
}
export type TestIds = 'table' | 'link-button' | 'icon-button';

export const CustomerListTable: React.FC<Props> = React.memo(({ dataList }) => {
  const testId = createTestId<TestIds>(CustomerListTable);
  const history = useHistory();
  const MAX_RECORD_PER_PAGE = useMemo(() => 20, []);

  const tableData: {
    label: string;
    name: keyof CustomerListState['list'][0];
  }[] = useMemo(() => {
    return [
      { label: '会員ID', name: 'customerId' },
      { label: 'OEM/会員登録日時', name: 'oemId' },
      { label: '名前/生年月日/性別', name: 'customerName' },
      { label: 'メールアドレス/電話番号', name: 'mailAddress' },
      { label: '会員ステータス/モラル', name: 'status' },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (item: CustomerListState['list'][0], labelName: keyof CustomerListState['list'][0], i: number) => {
      if (labelName === 'customerId') {
        return (
          <Button
            variant="link"
            onClick={() => history.push(`${Url.TENSAKU.CUSTOMER_INFO}/${item.customerId}`)}
            data-testid={testId('link-button', i)}
          >
            {item.customerId}
          </Button>
        );
      }

      if (labelName === 'oemId') {
        return (
          <>
            {item.oemName}
            <br />
            {item.registeredAt ? formatISODate(item.registeredAt, DATETIME_DISPLAY_FORMAT) : ''}
          </>
        );
      }
      if (labelName === 'customerName') {
        return (
          <>
            {item.customerName}
            <br />
            {item.birthday ? formatISODate(item.birthday, 'yyyy/MM/dd') : ''}
            <br />
            {item.gender}
          </>
        );
      }
      if (labelName === 'mailAddress') {
        return (
          <>
            {item.mailAddress}
            <br />
            {when(item.phoneNumber === '認証未実施', <FontAwesomeIcon icon={faExclamationTriangle} />)}
            {item.phoneNumber}
            {when(item.pastAuthPhoneNumberFlg, <p style={{ color: 'red' }}>過去の電話番号認証履歴でヒット</p>)}
          </>
        );
      }
      if (labelName === 'status') {
        return (
          <>
            {item.status}
            <br />
            {item.moral}
          </>
        );
      }

      return '';
    },
    [history, testId]
  );

  const tooltipText = useMemo(
    () => (
      <Popover id="button-tooltip" style={{ maxWidth: '7.5rem' }}>
        成り替わりログイン
      </Popover>
    ),
    []
  );

  return (
    <PaginationTable
      data-testid={testId('table')}
      resultNum={MAX_RECORD_PER_PAGE}
      list={dataList}
      headerNames={[
        '会員ID',
        'OEM/会員登録日時',
        '名前/生年月日/性別',
        'メールアドレス/電話番号',
        '会員ステータス/モラル',
      ]}
    >
      {(list) => {
        return (list as CustomerListState['list']).map((item, i) => {
          return (
            <tr className="align-middle" key={item.customerId}>
              {tableData.map(({ name }) => (
                <td key={name}>{createDisplayElement(item, name, i)}</td>
              ))}
              <td>
                <AgentLogin customerId={item.customerId} apiClientId={item.oemId} />
              </td>
            </tr>
          );
        });
      }}
    </PaginationTable>
  );
});
