import * as React from 'react';
import { useMemo } from 'react';
import { createTestId, formatISODate, uuid } from '../../../utils/functions';
import { Tag } from '../../atoms/Tag';
import type { LoginHistoryState } from '../../pages/Customer/LoginHistoryPage';
import { PaginationTable } from './PaginationTable';

export interface ListItemProps {
  item: LoginHistoryState['list'][0];
}

export const ListItem: React.VFC<ListItemProps> = ({ item }) => {
  const { cookie, ipAddress, loginAt, moral, moralInfectionRemarks, terminalType, userAgent } = item;
  const tagsMinWidth = '80px';

  return (
    <>
      <tr className="align-middle" key={`${ipAddress}-1`}>
        <td rowSpan={3}>{loginAt ? formatISODate(loginAt, 'yyyy/MM/dd HH:mm:ss') : ''}</td>
        <td rowSpan={3}>{terminalType}</td>
        <td rowSpan={1} style={{ border: '0px none' }}>
          <div className="d-flex justify-content-start">
            <div style={{ width: '110px' }}>
              <Tag label="IPアドレス" minWidth={tagsMinWidth} />
            </div>
            {ipAddress}
          </div>
        </td>
        <td rowSpan={3}>
          <div className="mb-2">{moral}</div>
          {moralInfectionRemarks}
        </td>
      </tr>
      <tr key={`${ipAddress}-2`}>
        <td rowSpan={1} style={{ border: '0px none' }}>
          <div className="d-flex justify-content-start">
            <div style={{ width: '110px' }}>
              <Tag label="Cookie" minWidth={tagsMinWidth} />
            </div>
            {cookie}
          </div>
        </td>
      </tr>
      <tr key={`${ipAddress}-3`}>
        <td rowSpan={1}>
          <div className="d-flex justify-content-start">
            <div style={{ width: '110px' }}>
              <Tag label="User-Agent" minWidth={tagsMinWidth} />
            </div>
            {userAgent}
          </div>
        </td>
      </tr>
    </>
  );
};

export interface Props {
  dataList: LoginHistoryState['list'];
}

export type TestIds = 'table';

export const LoginHistoryTable: React.VFC<Props> = ({ dataList }) => {
  const testid = createTestId<TestIds>(LoginHistoryTable);
  const MAX_RECORD_PER_PAGE = useMemo(() => 20, []);

  const labelList = useMemo(() => ['ログイン時刻', 'PC/SP', '', 'モラル/モラル感染備考'], []);

  return (
    <PaginationTable
      data-testid={testid('table')}
      list={dataList}
      resultNum={MAX_RECORD_PER_PAGE}
      headerNames={labelList}
    >
      {(list) => {
        return (list as LoginHistoryState['list']).map((item) => {
          return <ListItem item={item} key={uuid()} />;
        });
      }}
    </PaginationTable>
  );
};
