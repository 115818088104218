import React from 'react';
import {
  BillingInvoiceHeaderInfoForPdfOutputResponse,
  BillingInvoiceTotalingDetailItemForPdfRowDataOutputResponse,
  BillingInvoiceTotalingDetailItemHeaderForPdfRowDataOutputResponse,
} from '../../api-client';
import { SurvayShopTotalType } from '../../type/PdfType';
import { PdfLayout } from '../templates/PdfLayout';
import { SurveyDetailTable } from './Table/SurvayDetailTable';
import { BILLING_PDF_SETTINGS } from '../../BillingConstants';

interface Props {
  lists: Array<BillingInvoiceTotalingDetailItemForPdfRowDataOutputResponse>;
  tableHeaders: BillingInvoiceTotalingDetailItemHeaderForPdfRowDataOutputResponse;
  headers: BillingInvoiceHeaderInfoForPdfOutputResponse;
  totals: SurvayShopTotalType;
}

export const SurveyDetailInvoice = ({
  lists,
  headers,
  totals,
  tableHeaders,
}: {
  lists: Array<BillingInvoiceTotalingDetailItemForPdfRowDataOutputResponse>;
  tableHeaders: BillingInvoiceTotalingDetailItemHeaderForPdfRowDataOutputResponse;
  headers: BillingInvoiceHeaderInfoForPdfOutputResponse;
  totals: SurvayShopTotalType;
}): JSX.Element => (
  <PdfLayout
    headers={headers}
    billingType="調査別明細"
    paddingHorizontal={BILLING_PDF_SETTINGS.MONITOR_DETAIL_PADDINGHORIZONTAL}
  >
    <SurveyDetailTable lists={lists} totals={totals} headers={tableHeaders} />
  </PdfLayout>
);
