import { View } from '@react-pdf/renderer';
import React, { useEffect } from 'react';
import { BillingInvoiceDetailContractOutputResponse } from '../../../api-client';
import { Col } from '../../atoms/PdfParts/Col';
import { Row } from '../../atoms/PdfParts/Row';
import { TextStyle } from '../../atoms/PdfParts/TextStyle';
import { BILLING_ITEM_RELATION_TYPE, BILLING_PDF_SETTINGS } from '../../../BillingConstants';
import {
  calcAmountPerTaxRate,
  calcIncludeTaxAmount,
  calcNonTaxAmount,
  calcNonTaxSubTotal,
  calcTaxAmountPerTaxRate,
  convertMoneyText,
  getRateText,
} from '../../../utils/functionsForBilling';

type Props = {
  billingInvoiceItem: Array<BillingInvoiceDetailContractOutputResponse> | undefined;
};

const items = [
  {
    name: '契約名・品目・品名',
    width_: BILLING_PDF_SETTINGS.ITEM_DETAIL_ITEM_NAME,
  },
  {
    name: '単価',
    width_: BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_PRICE,
  },
  {
    name: '数量',
    width_: BILLING_PDF_SETTINGS.ITEM_DETAIL_QUANTITY,
  },
  {
    name: '単位',
    width_: BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_NAME,
  },
  {
    name: '金額（円）',
    width_: BILLING_PDF_SETTINGS.ITEM_DETAIL_AMOUNT,
  },
  {
    name: '税区分',
    width_: BILLING_PDF_SETTINGS.ITEM_DETAIL_TAX,
  },
];

export const ItemDetailTable: React.FC<Props> = ({ billingInvoiceItem }) => {
  // 税額ごとの合計
  const amountPerTaxRate = calcAmountPerTaxRate(billingInvoiceItem);
  // 税抜合計
  const nontaxAmount = calcNonTaxAmount(amountPerTaxRate);

  let rowCount = 0;
  const ROW_MAX = 13;
  const amountPerTaxRateRow: JSX.Element[] = [];

  amountPerTaxRate?.forEach((v) => {
    amountPerTaxRateRow.push(
      <Row borderBottom borderLeft borderRight fontSize={8}>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_ITEM_NAME} borderRight left>
          <TextStyle marginLeft={10}>{getRateText(v[0])}対象</TextStyle>
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_PRICE}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_QUANTITY}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_NAME}>
          {}
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_AMOUNT} borderRight right>
          <TextStyle>{convertMoneyText(v[1])}</TextStyle>
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_TAX}>{}</Col>
      </Row>
    );
  });

  const taxValuePerTaxRate = calcTaxAmountPerTaxRate(amountPerTaxRate);
  const taxValuePerTaxRateRow: JSX.Element[] = [];
  taxValuePerTaxRate.forEach((value, key) => {
    taxValuePerTaxRateRow.push(
      <Row borderBottom borderLeft borderRight fontSize={8}>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_ITEM_NAME} borderRight left>
          <TextStyle>消費税等（{key}%）</TextStyle>
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_PRICE}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_QUANTITY}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_NAME}>
          {}
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_AMOUNT} borderRight right>
          <TextStyle>{convertMoneyText(value)}</TextStyle>
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_TAX}>{}</Col>
      </Row>
    );
  });

  return (
    <div>
      <Row borderBottom borderTop borderLeft borderRight th fixed fontSize={9}>
        {items.map((item, i) => (
          <Col th key={i} width_={item.width_ || 1} borderRight={i < items.length - 1} left={i === 0}>
            <TextStyle th maxLines={1}>
              {item.name}
            </TextStyle>
          </Col>
        ))}
      </Row>
      {billingInvoiceItem ? (
        billingInvoiceItem.map((list) =>
          list.billingInvoiceDetailItemList.map((item, idx) => {
            if (rowCount > ROW_MAX) {
              rowCount = 0;
            }
            rowCount += 1;
            return (
              <Row key={idx} fontSize={8} borderBottom borderLeft borderRight break_={rowCount > ROW_MAX}>
                {idx === 0 && (
                  <Row fontSize={8} borderBottom>
                    <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_ITEM_NAME} left>
                      <TextStyle>{list.contractName} </TextStyle>
                    </Col>
                    <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_PRICE}>
                      <TextStyle>{}</TextStyle>
                    </Col>
                    <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_QUANTITY}>
                      <TextStyle>{}</TextStyle>
                    </Col>
                    <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_NAME}>
                      <TextStyle>{}</TextStyle>
                    </Col>
                    <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_AMOUNT}>
                      <TextStyle>{}</TextStyle>
                    </Col>
                    <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_TAX}>
                      <TextStyle>{}</TextStyle>
                    </Col>
                  </Row>
                )}
                <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_ITEM_NAME} borderRight left>
                  <TextStyle marginLeft={10}>
                    {item.itemNameForDisplay ? item.itemNameForDisplay : item.billingItemName}
                  </TextStyle>
                </Col>
                {item.unitPrice === 0 ? (
                  <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_PRICE}>
                    <TextStyle>-</TextStyle>
                  </Col>
                ) : (
                  <Col borderRight right width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_PRICE}>
                    <TextStyle>{convertMoneyText(item.unitPrice)}</TextStyle>
                  </Col>
                )}
                {item.relationType === BILLING_ITEM_RELATION_TYPE.CONTRACT &&
                !item.editableQuantityBillingInvoiceDetailItemFlg ? (
                  <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_QUANTITY}>
                    <TextStyle>-</TextStyle>
                  </Col>
                ) : (
                  <Col borderRight right width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_QUANTITY}>
                    <TextStyle>{item.quantity}</TextStyle>
                  </Col>
                )}
                <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_NAME}>
                  <TextStyle>{item.unitName}</TextStyle>
                </Col>
                <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_AMOUNT} borderRight right>
                  <TextStyle>{convertMoneyText(item.amount)}</TextStyle>
                </Col>
                <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_TAX}>
                  <TextStyle>{getRateText(item.taxRate)}</TextStyle>
                </Col>
                {idx === list.billingInvoiceDetailItemList.length - 1 && (
                  <Row fontSize={8} borderTop>
                    <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_ITEM_NAME} borderRight left>
                      <TextStyle marginLeft={20}>小計</TextStyle>
                    </Col>
                    <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_PRICE}>
                      <TextStyle>{}</TextStyle>
                    </Col>
                    <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_QUANTITY}>
                      <TextStyle>{}</TextStyle>
                    </Col>
                    <Col borderRight right width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_NAME}>
                      <TextStyle>{}</TextStyle>
                    </Col>
                    <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_AMOUNT} borderRight right>
                      <TextStyle>{convertMoneyText(calcNonTaxSubTotal(list))}</TextStyle>
                    </Col>
                    <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_TAX}>
                      <TextStyle>{}</TextStyle>
                    </Col>
                  </Row>
                )}
              </Row>
            );
          })
        )
      ) : (
        <></>
      )}

      <View style={{ width: '100%', borderStyle: 'solid', paddingBottom: '1', borderTop: 1 }} />
      <Row th fontSize={8} borderBottom borderLeft borderRight>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_ITEM_NAME} borderRight left>
          <TextStyle>合計</TextStyle>
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_PRICE}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_QUANTITY}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_NAME}>
          {}
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_AMOUNT} borderRight right>
          <TextStyle>{convertMoneyText(nontaxAmount)}</TextStyle>
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_TAX}>{}</Col>
      </Row>
      {amountPerTaxRateRow}
      {taxValuePerTaxRateRow}
      <Row fontSize={8} th borderLeft borderRight borderBottom>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_ITEM_NAME} borderRight left>
          <TextStyle th>ご請求金額</TextStyle>
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_PRICE}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_QUANTITY}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_UNIT_NAME}>
          {}
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_AMOUNT} borderRight right>
          <TextStyle>{convertMoneyText(calcIncludeTaxAmount(calcAmountPerTaxRate(billingInvoiceItem)))}</TextStyle>
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.ITEM_DETAIL_TAX}>{}</Col>
      </Row>
    </div>
  );
};
