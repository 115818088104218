import React, { useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { GENDER, GENDER_NAMES } from '../../../Constants';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import { createTestId, nameof } from '../../../utils/functions';
import type { CustomerInfoState as ParentState } from '../../pages/Customer/Card/CustomerInfoCard';
import type { ContextValueType } from '../../pages/Customer/CustomerDetailPage';

interface Props {
  forms: ParentState['forms'];
  setForms: DispatchSetState<ParentState['forms']>;
  getPrefecture: ContextValueType['prefecture'];
}

interface PrefectureState {
  city: string;
  prefecturesName: string;
  prefecturesId: number;
}

export type TestIds = keyof ParentState['forms'] | 'auto-fill-button' | 'prefectureName';

export const CustomerInfoEditModalForms: React.FC<Props> = ({ forms, setForms, getPrefecture }) => {
  const testid = createTestId<TestIds>(CustomerInfoEditModalForms);
  const { state: $, onChangeSet } = useLargeState<ParentState['forms']>(forms ?? {});
  const {
    state: $prefecture,
    // mergeState: mergePrefecture
  } = useLargeState<PrefectureState>({
    city: '',
    prefecturesName: '',
    prefecturesId: -1,
  });

  const name = (x: keyof ParentState['forms']) => nameof(x);
  useEffect(() => setForms($), [setForms, $]);

  // const onClickGetPrefecture = useCallback(
  //   (prefecture: string) => {
  //     getPrefecture(prefecture).then((res) => {
  //       mergePrefecture({ city: res.city, prefecturesName: res.prefecturesName });
  //     });
  //   },
  //   [getPrefecture, mergePrefecture]
  // );

  return (
    <>
      <Row>
        <Form.Group className="col-md-6" controlId={name('lastName')} data-testid={testid('lastName')}>
          <Form.Label>姓名</Form.Label>
          <Form.Control type="text" autoComplete="off" value={$.lastName ?? ''} onChange={onChangeSet('lastName')} />
        </Form.Group>
        <Form.Group className="col-md-6 mt-2" controlId={name('firstName')} data-testid={testid('firstName')}>
          <Form.Label />
          <Form.Control type="text" autoComplete="off" value={$.firstName ?? ''} onChange={onChangeSet('firstName')} />
        </Form.Group>
      </Row>
      <Form.Group className="mt-3" controlId={`${name('gender')}-man`} data-testid={testid('gender', 0)}>
        <Form.Label>性別</Form.Label>
        <Form.Check
          type="radio"
          key="gender-man"
          name="gender"
          label={GENDER_NAMES[GENDER.MAN]}
          value={GENDER.MAN}
          onChange={onChangeSet('gender', Number)}
          checked={$.gender === GENDER.MAN}
        />
      </Form.Group>
      <Form.Group controlId={`${name('gender')}-woman`} data-testid={testid('gender', 1)}>
        <Form.Check
          type="radio"
          key="gender-woman"
          name="gender"
          label={GENDER_NAMES[GENDER.WOMAN]}
          value={GENDER.WOMAN}
          onChange={onChangeSet('gender', Number)}
          checked={$.gender === GENDER.WOMAN}
        />
      </Form.Group>
      <Form.Group controlId={name('birthday')} data-testid={testid('birthday')}>
        <Form.Label>生年月日</Form.Label>
        <Form.Control type="date" autoComplete="off" value={$.birthday || ''} onChange={onChangeSet('birthday')} />
      </Form.Group>
      {/* FIXME 一旦郵便番号の更新は諦める。パイロット後に対応 */}
      {/* <Row>
        <Form.Group className="col-md-8 mt-3" controlId={name('zipCode')} data-testid={testid('zipCode')}>
          <Form.Label>郵便番号</Form.Label>
          <Form.Control type="text" autoComplete="off" value={$.zipCode ?? ''} onChange={onChangeSet('zipCode')} />
        </Form.Group>
        <Button
          data-testid={testid('auto-fill-button')}
          className="col-md-4 mt-5"
          style={{ height: '38px' }}
          onClick={() => {
            onClickGetPrefecture($.zipCode);
          }}
        >
          住所自動入力
        </Button>
      </Row> */}
      {$prefecture.prefecturesName.length > 0 ? (
        <p className="text-secondary" data-testid={testid('prefectureName')}>
          {$prefecture.prefecturesName} {$prefecture.city}
        </p>
      ) : undefined}
    </>
  );
};
