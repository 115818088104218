import React from 'react';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkWithTooltip } from './LinkWithTooltip';

export const QuestionCircleTooltip = React.memo(({ id, tooltip }: { id?: string; tooltip: string }) => {
  return (
    <LinkWithTooltip id={id} href="#" tooltip={tooltip}>
      <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
    </LinkWithTooltip>
  );
});
