import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { Alert } from '../../atoms/Alert';
import {
  BillingApi,
  BillingAgentFeeListOutputResponse,
  BillingAgentFeeListOutputResponseAgentFeeStatusEnum,
  BillingAgentFeeListOutputResponseBillingFlowStatusEnum,
} from '../../../api-client';

const MAX_RECORD_PER_PAGE = 20;

const AGENT_FEE_STATUS = {
  SalesDptUncommitted: 0,
  SalesDptManagerUncommitted: 1,
  ManageDptUncommitted: 2,
};

type SearchParam = {
  billingAgentId: number | undefined;
  salesIncAccountId: number | undefined;
  clientId: number | undefined;
  targetMonth: string | undefined;
  agentFeeStatus: number | undefined;
  hasUnapproved: boolean | undefined;
  approverIncAccountId: number | undefined;
};

export const BillingAgentFeeListPage: React.VFC = () => {
  const billingApi = new BillingApi();
  const [list, setList] = useState<BillingAgentFeeListOutputResponse[]>([]);
  const [listPerPage, setListPerPage] = useState<BillingAgentFeeListOutputResponse[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [searchParam, setSearchParam] = useState<SearchParam>({
    billingAgentId: undefined,
    salesIncAccountId: undefined,
    clientId: undefined,
    targetMonth: undefined,
    agentFeeStatus: undefined,
    hasUnapproved: undefined,
    approverIncAccountId: undefined,
  });

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'billingAgentId':
        setSearchParam({ ...searchParam, billingAgentId: Number(event.target.value) });
        break;

      case 'salesIncAccountId':
        setSearchParam({ ...searchParam, salesIncAccountId: Number(event.target.value) });
        break;

      case 'clientId':
        setSearchParam({ ...searchParam, clientId: Number(event.target.value) });
        break;

      case 'targetMonth':
        setSearchParam({ ...searchParam, targetMonth: event.target.value });
        break;

      case 'approverIncAccountId':
        setSearchParam({ ...searchParam, approverIncAccountId: Number(event.target.value) });
        break;

      default:
        break;
    }
  };

  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.id) {
      case 'agentFeeStatus':
        setSearchParam({ ...searchParam, agentFeeStatus: Number(event.target.value) });
        break;

      case 'hasUnapproved':
        setSearchParam({
          ...searchParam,
          hasUnapproved: event.target.value === undefined ? event.target.value : event.target.value === 'true',
        });
        break;

      default:
        break;
    }
  };

  const renderAgentFeeStatus = (target?: BillingAgentFeeListOutputResponseAgentFeeStatusEnum) => {
    let feeStatus;
    switch (target) {
      case BillingAgentFeeListOutputResponseAgentFeeStatusEnum.SalesDptUncommitted:
        feeStatus = '担当者確認待ち';
        break;

      case BillingAgentFeeListOutputResponseAgentFeeStatusEnum.SalesDptManagerUncommitted:
        feeStatus = '上長確認待ち';
        break;

      case BillingAgentFeeListOutputResponseAgentFeeStatusEnum.ManageDptUncommitted:
        feeStatus = '管理部確認待ち';
        break;

      default:
        break;
    }
    return feeStatus;
  };

  const renderBillingFlowStatus = (target?: BillingAgentFeeListOutputResponseBillingFlowStatusEnum) => {
    let flowStatus;
    switch (target) {
      case BillingAgentFeeListOutputResponseBillingFlowStatusEnum.WaitingCompleteApply:
        flowStatus = '派遣完了待ち';
        break;

      case BillingAgentFeeListOutputResponseBillingFlowStatusEnum.SalesDptUncommitted:
        flowStatus = '営業確認待ち';
        break;

      case BillingAgentFeeListOutputResponseBillingFlowStatusEnum.SalesDptManagerUncommitted:
        flowStatus = '営業上長確認待ち';
        break;

      case BillingAgentFeeListOutputResponseBillingFlowStatusEnum.ManageDptUncommitted:
        flowStatus = '管理部確認待ち';
        break;

      case BillingAgentFeeListOutputResponseBillingFlowStatusEnum.Unpublished:
        flowStatus = '発行待ち';
        break;

      case BillingAgentFeeListOutputResponseBillingFlowStatusEnum.Published:
        flowStatus = '発行済み';
        break;

      default:
        break;
    }

    return flowStatus;
  };

  const fetchData = () => {
    billingApi
      .billingAgentFeeList(
        searchParam.billingAgentId,
        searchParam.salesIncAccountId,
        searchParam.clientId,
        searchParam.targetMonth,
        searchParam.agentFeeStatus,
        searchParam.hasUnapproved,
        searchParam.approverIncAccountId
      )
      .then((res) => {
        setList(res.data);
        setListPerPage(res.data.slice(0, MAX_RECORD_PER_PAGE));
        setTotalPage(Math.ceil(Number(res.data?.length) / MAX_RECORD_PER_PAGE));
        setCurrentPage(1);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = () => {
    fetchData();
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="g-2 mb-4">
              <Col className="col-5">
                <Form.Group className="d-flex" controlId="billingAgentId">
                  <Form.Label className="mt-2 me-2" style={{ width: '120px' }}>
                    代理店
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Control type="text" name="billingAgentId" onChange={onChangeText} />
                  </Col>
                </Form.Group>
              </Col>
              <Col className="col-4">
                <Form.Group className="d-flex" controlId="salesIncAccountId">
                  <Form.Label className="mt-2 me-2" style={{ width: '95px' }}>
                    担当者
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Control type="text" name="salesIncAccountId" onChange={onChangeText} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2 mb-4">
              <Col className="col-5">
                <Form.Group className="d-flex" controlId="clientId">
                  <Form.Label className="mt-2 me-2" style={{ width: '120px' }}>
                    クライアント
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Control type="text" name="clientId" onChange={onChangeText} />
                  </Col>
                </Form.Group>
              </Col>
              <Col className="col-4">
                <Form.Group className="d-flex" controlId="targetMonth">
                  <Form.Label className="mt-2 me-2" style={{ width: '95px' }}>
                    月度
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Control type="month" name="targetMonth" onChange={onChangeText} />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2 mb-4">
              <Col className="col-5">
                <Form.Group className="d-flex" controlId="agentFeeStatus">
                  <Form.Label className="mt-2 me-2" style={{ width: '120px' }}>
                    代理店フィーステータス
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Select id="agentFeeStatus" onChange={onChangeSelect}>
                      <option value={AGENT_FEE_STATUS.SalesDptUncommitted}>担当者確認待ち</option>
                      <option value={AGENT_FEE_STATUS.SalesDptManagerUncommitted}>上長確認待ち</option>
                      <option value={AGENT_FEE_STATUS.ManageDptUncommitted}>管理部確認待ち</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col className="col-4">
                <Form.Group className="d-flex" controlId="hasUnapproved">
                  <Form.Label className="mt-2 me-2" style={{ width: '95px' }}>
                    請求未承認有無
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Select id="hasUnapproved" onChange={onChangeSelect}>
                      <option value={undefined}>有</option>
                      <option value="true">有</option>
                      <option value="false">無</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2 mb-4">
              <Col className="col-5">
                <Form.Group className="d-flex" controlId="approverIncAccountId">
                  <Form.Label className="mt-2 me-2" style={{ width: '120px' }}>
                    承認者担当
                  </Form.Label>
                  <Col className="col-8">
                    <Form.Control type="text" name="approverIncAccountId" onChange={onChangeText} />
                  </Col>
                </Form.Group>
              </Col>
              <Col className="col-4">
                <Button type="submit" style={{ marginTop: '2rem' }}>
                  検索
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Row>
        <Col className="col-4">
          <Button variant="secondary" className="me-4">
            チェック入りをまとめて確認
          </Button>
        </Col>
      </Row>
      <Table>
        <thead className="text-nowrap">
          <tr>
            <th>
              <Form.Check.Input
                className="flex-shrink-0 me-2"
                checked={false}
                type="checkbox"
                name="unBilling"
                value={0}
                /* onChange={(e) => handleOnChangeUnBilling(e, idx)} */
              />
            </th>
            <th>代理店</th>
            <th>月度</th>
            <th>請求ワークフローステータス</th>
            <th>代理店フィー一覧ステータス</th>
            <th>合計金額</th>
            <th>担当者</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {listPerPage.map((item, index) => {
            return (
              <tr key={item.agentFeeListHeaderId}>
                <td>
                  <Form.Check.Input
                    className="flex-shrink-0 me-2"
                    checked={false}
                    type="checkbox"
                    name="unBilling"
                    value={index}
                    /* onChange={(e) => handleOnChangeUnBilling(e, idx)} */
                  />
                </td>
                <td>
                  {item.billingAgentId}:{item.billingAgentName}
                </td>
                <td>{item.publishedDate}</td>
                <td>{renderBillingFlowStatus(item.billingFlowStatus)}</td>
                <td>{renderAgentFeeStatus(item.agentFeeStatus)}</td>
                <td>\{item.companyAmount?.toLocaleString()}</td>
                <td>{item.publishedDate}</td>
                <td>
                  <a href="#">確認</a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="mt-4">
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />
      </div>
    </>
  );
};
