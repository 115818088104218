import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { TemplateSelectContent } from '../../../molecules/TemplateSelectContent';
import type { CorrectionTemplateListOutputResponse } from '../../../../api-client';
import { uuid, when } from '../../../../utils/functions';
// eslint-disable-next-line import/no-cycle
import { ACTIVE_KEY } from '../../Forms/CorrectDataInputWcheckForm';

export const CorrectResult: React.FC<{
  totalAmount: number | undefined;
  activeKey: string;
  onSelect: (key: string | null) => void;
  onApproveConfirm: () => void;
  onCorrectNG: (alsoEnqueteNgFlg: boolean) => void;
  onCorrectReject: () => void;
  onEscalation: () => void;
  ngTemplateList: CorrectionTemplateListOutputResponse[];
  rejectTemplateList: CorrectionTemplateListOutputResponse[];
  escalationTemplateList: CorrectionTemplateListOutputResponse[];
  ngTemplateMessageList: { ngFlg: boolean; id?: number; ngMessage?: string }[];
  onChangeTemplateCode: (id?: number) => void;
  onChangeTemplateText: (text?: string) => void;
}> = React.memo(
  ({
    totalAmount,
    activeKey,
    onSelect,
    onApproveConfirm,
    onCorrectNG,
    onCorrectReject,
    onEscalation,
    ngTemplateList,
    rejectTemplateList,
    escalationTemplateList,
    ngTemplateMessageList,
    onChangeTemplateCode,
    onChangeTemplateText,
  }) => {
    return (
      <Tabs
        defaultActiveKey={ACTIVE_KEY.CORRECT_OK}
        activeKey={activeKey}
        onSelect={(key) => onSelect(key)}
        className="mx-2"
      >
        <Tab eventKey={ACTIVE_KEY.CORRECT_OK} title="添削OK">
          <div className="d-flex justify-content-end mt-2">
            <h6 className="mt-1 me-1">対象商品の税込み合計金額</h6> {totalAmount} 円
          </div>
          <div className="d-flex justify-content-end mt-2">
            <Button variant="outline-primary" onClick={() => onApproveConfirm()} style={{ minWidth: '150px' }}>
              <FontAwesomeIcon icon={faCheckCircle} />
              承認
            </Button>
          </div>
        </Tab>
        <Tab eventKey={ACTIVE_KEY.CORRECT_RE_SUBMIT} title="添削NG(再提出)">
          {when(
            ngTemplateMessageList.filter(({ ngFlg }) => ngFlg).length > 0,
            <div className="bg-white p-2 m-2">
              <h6>以下のレシートにNGがあります。</h6>
              {ngTemplateMessageList
                .filter(({ ngFlg }) => ngFlg)
                .map(({ ngMessage }, i) => {
                  return (
                    <div className="mt-2" key={uuid()}>
                      {i + 1}枚目
                      <br />
                      {ngMessage}
                    </div>
                  );
                })}
            </div>
          )}
          <h6 className="mt-2">ユーザーに通知するメッセージ</h6>
          {/* TODO <Button
          variant="link"
        >
          <FontAwesomeIcon icon={faWindowMaximize} />
          テンプレート一覧
        </Button> */}
          <TemplateSelectContent
            onChangeTemplateCode={onChangeTemplateCode}
            onChangeTemplateText={onChangeTemplateText}
            templateList={ngTemplateList.map(({ templateId: id, templateName: name, templateText: text }) => {
              return { id, name, text };
            })}
          />
          <div className="d-flex justify-content-center mt-2">
            <Button variant="primary" className="me-2" onClick={() => onCorrectNG(false)}>
              NG（再提出）
            </Button>
            {/* アンケートもNG機能は一旦画面に表示しないことになったためコメントアウト */}
            {/* <Button variant="outline-primary" onClick={() => onCorrectNG(true)}> */}
            {/*  アンケートも全てNG（再提出） */}
            {/* </Button> */}
          </div>
        </Tab>
        <Tab eventKey={ACTIVE_KEY.CORRECT_REJECT} title="却下">
          <h6 className="mt-2">ユーザーに通知するメッセージ</h6>
          {/* TODO <Button
          variant="link"
        >
          <FontAwesomeIcon icon={faWindowMaximize} />
          テンプレート一覧
        </Button> */}
          <TemplateSelectContent
            onChangeTemplateCode={onChangeTemplateCode}
            onChangeTemplateText={onChangeTemplateText}
            templateList={rejectTemplateList.map(({ templateId: id, templateName: name, templateText: text }) => {
              return { id, name, text };
            })}
          />
          <div className="d-flex justify-content-end mt-2">
            <Button variant="primary" className="me-2" onClick={() => onCorrectReject()}>
              却下
            </Button>
          </div>
        </Tab>
        <Tab eventKey={ACTIVE_KEY.ESCALATION} title="事務局へ質問">
          <h6 className="mt-2">エスカレテンプレート選択</h6>
          <TemplateSelectContent
            onChangeTemplateCode={onChangeTemplateCode}
            onChangeTemplateText={onChangeTemplateText}
            templateList={escalationTemplateList.map(({ templateId: id, templateName: name, templateText: text }) => {
              return { id, name, text };
            })}
          />
          {/* TODO <Button
          variant="link"
        >
          <FontAwesomeIcon icon={faWindowMaximize} />
          テンプレートの定義を確認
        </Button> */}
          <div className="d-flex justify-content-end mt-2">
            <Button variant="primary" className="me-2" onClick={() => onEscalation()}>
              エスカレ実行
            </Button>
          </div>
        </Tab>
      </Tabs>
    );
  }
);
