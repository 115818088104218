import React, { useState, Dispatch, SetStateAction } from 'react';
import { Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPen, faEye, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { CampaignApi, CampaignListOutputResponse, IncResultOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { Badge } from '../../atoms/Badge';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { when } from '../../../utils/functions';

export interface Props {
  list: CampaignListOutputResponse[];
  listPerPage: CampaignListOutputResponse[];
  setList: Dispatch<SetStateAction<CampaignListOutputResponse[]>>;
  setUpdResult: Dispatch<SetStateAction<IncResultOutputResponse | null>>;
}

export const CampaignTable: React.VFC<Props> = ({ list, setList, listPerPage, setUpdResult }) => {
  const history = useHistory();
  const [isModal, setIsModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const campaignApi = new CampaignApi();

  const handleClickDelete = (id: number): void => {
    setIsModal(true);
    setDeleteId(id);
  };

  const handleClickModalYes = (): void => {
    if (!deleteId) return;

    campaignApi.campaignDelete(deleteId).then((res: AxiosResponse<IncResultOutputResponse>) => {
      // console.log(res.data);
      // const res = {
      //   data: {
      //     result: false,
      //     errorCode: 'AAA-0000',
      //     errorMessage: '既に店舗が紐付いているため削除できませんでした。',
      //   },
      // };
      if (res.data.result) {
        setList(list.filter((item) => item.id !== deleteId));
      }
      setUpdResult(res.data);
      setIsModal(false);
    });
  };

  const handleClickModalNo = (): void => {
    setIsModal(false);
    setDeleteId(null);
  };

  const getBadgeType = (type: string): 'success' | 'secondary' | 'warning' => {
    switch (type) {
      case '表示':
        return 'success';
      case '非表示':
        return 'secondary';
      case 'クローズド':
        return 'warning';
      default:
        return 'success';
    }
  };

  const boolToCircleOrCross = (flg: boolean) => (flg ? '〇' : '✕');

  return (
    <>
      <Modal
        title="消して良いですか？"
        footer={
          <>
            <Button variant="secondary" onClick={handleClickModalNo}>
              いいえ
            </Button>
            <Button variant="primary" onClick={handleClickModalYes}>
              はい
            </Button>
          </>
        }
        isModal={isModal}
        onHide={handleClickModalNo}
      />

      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>表示設定</th>
            <th>キャンペーン名</th>
            <th>設定画像</th>
            <th>公開開始日</th>
            <th>公開終了日</th>
            <th>店舗数</th>
            <th>表示する店舗の表示状態</th>
            <th>OEM公開状態</th>
            <th>リッチ化設定状態</th>
          </tr>
        </thead>
        <tbody>
          {listPerPage?.map((data: CampaignListOutputResponse) => {
            return (
              <tr key={data.id} className="align-middle">
                <td>{data.id}</td>
                <td className="col-1">
                  <Badge pill style={{ fontSize: '0.875rem', width: '100%' }} bg={getBadgeType(data.showType || '')}>
                    {data.showType}
                  </Badge>
                </td>
                <td>
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={() => {
                      history.push({
                        pathname: Url.KEISAI.CAMPAIGN_DETAIL,
                        state: { id: data.id, type: 'detail' },
                      });
                    }}
                  >
                    {data.name}
                  </Button>
                </td>
                <td>
                  {when(
                    data.imageUrl != null,
                    <img
                      // src="http://fancrew.jp/api/3.0/image/brand/0000/4320/2117960-1641275727657.jpg"
                      src={data.imageUrl!}
                      alt={data.name}
                      style={{ width: '90px', height: '63px', objectFit: 'cover' }}
                    />
                  )}
                </td>
                <td>{data.publicStartDate}</td>
                <td>{data.publicEndDate}</td>
                <td>{data.shopCount}</td>
                <td>{data.shopShowType}</td>
                <td>{boolToCircleOrCross((data as any).oemShowFlg)}</td>
                <td>{boolToCircleOrCross((data as any).richShowFlg)}</td>
                <td>
                  <div className="d-flex justify-content-end">
                    <div>
                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Body className="p-1">編集</Popover.Body>
                          </Popover>
                        }
                      >
                        <button
                          type="button"
                          className="ms-2"
                          data-testid="pen"
                          onClick={() => {
                            history.push({
                              pathname: Url.KEISAI.CAMPAIGN_MODIFY,
                              state: { id: data.id, type: 'modify' },
                            });
                          }}
                        >
                          <FontAwesomeIcon icon={faPen} className="text-secondary flex-shrink-0 me-1" fixedWidth />
                        </button>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Body className="p-1">プレビュー</Popover.Body>
                          </Popover>
                        }
                      >
                        <a
                          className="ms-2"
                          // TODO: 後で変更
                          href="https://www.fancrew.jp/"
                          target="_blank"
                          rel="noreferrer"
                          data-testid="eye"
                        >
                          <FontAwesomeIcon icon={faEye} className="text-secondary flex-shrink-0 me-1" fixedWidth />
                        </a>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top-end"
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Body className="p-1">リッチ化設定</Popover.Body>
                          </Popover>
                        }
                      >
                        <button
                          type="button"
                          className="ms-2"
                          data-testid="clipboardList"
                          onClick={() => {
                            history.push({
                              pathname: Url.KEISAI.CAMPAIGN_RICH,
                              state: { id: data.id },
                            });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faClipboardList}
                            className="text-secondary flex-shrink-0 me-1"
                            fixedWidth
                          />
                        </button>
                      </OverlayTrigger>

                      <Button
                        variant="light"
                        className="text-secondary ms-2"
                        testId="delete"
                        onClick={() => {
                          handleClickDelete(data.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                      </Button>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
