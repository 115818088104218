/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplyManagementApi, QcCrewApplyStatusSummaryDisplayOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { QcCrewApplyManagementTable } from '../../organisms/Table/QcCrewApplyManagementTable';
import { Title } from '../../atoms/Title';

export const QcCrewApplyManagementPage: React.FC = () => {
  const history = useHistory();

  const [summary, setSummary] = useState<QcCrewApplyStatusSummaryDisplayOutputResponse>();

  useEffect(() => {
    const api = new ApplyManagementApi();
    api
      .qcCrewApplyStatusSummaryDisplay()
      .then((res) => {
        setSummary(res.data);
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [history]);

  return (
    <>
      <Title>QCクルー案件管理添削状況サマリー</Title>
      <hr />
      <QcCrewApplyManagementTable summary={summary} />
    </>
  );
};
