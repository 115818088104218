import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SqlTemplateApi, SqlTemplateCreateFormResponse, IncResultOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { SqlTemplateInfoTable } from '../../organisms/Table/SqlTemplateInfoTable';
import { PrimaryButton } from '../../atoms/Button/PrimaryButton';

export const SqlTemplateCreateConfirmPage = () => {
  const location = useLocation();
  const history = useHistory();
  const createContents = location.state as SqlTemplateCreateFormResponse;

  const ClickConfirm = () => {
    const sqlCreate = new SqlTemplateApi();
    sqlCreate.sqlTemplateCreate(createContents).then((response: AxiosResponse<IncResultOutputResponse>) => {
      history.push(Url.KEISAI.SQL_TMP_COMPLETION);
    });
  };

  return (
    <>
      <p>作成します。よろしいですか？</p>
      <SqlTemplateInfoTable tableTitle="SQLテンプレート作成確認" cellData={createContents} />
      <PrimaryButton onClick={ClickConfirm}>作成</PrimaryButton>
    </>
  );
};
