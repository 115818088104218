import { useParams } from 'react-router-dom';
import { OemFeeListTable } from '../../organisms/Table/OemFeeListTable';
import { CustomerInfoHeader } from '../Customer/CustomerInfoHeader';

export const OemFeeListPage = () => {
  const { customerId } = useParams<{ customerId: string | undefined }>();

  return (
    <>
      <CustomerInfoHeader activeKey="oemFee" customerId={Number(customerId)} />
      <OemFeeListTable customerId={Number(customerId)} />
    </>
  );
};
