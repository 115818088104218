import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Url } from '../../constants/Url';
import { OemScodeCreateApi } from '../../api-client/api/oem-scode-create-api';
import { ApplyHistoryListTable } from '../organisms/Table/ApplyHistoryListTable';
import { ApplyHistoryListDisplayOutputResponse, CustomerInfoApi } from '../../api-client';
import { OEM_API_VERSION } from '../../Constants';

export const IncOemTopPage: React.VFC = () => {
  const { customerId } = useParams<{ customerId: string | '0' }>();
  const { apiClientId } = useParams<{ apiClientId: string | '0' }>();
  const customerInfoApi = new CustomerInfoApi();
  const oemSCodeCreateApi = new OemScodeCreateApi();
  const [sCode, setSCode] = useState<string>();
  const [list, setList] = useState<ApplyHistoryListDisplayOutputResponse[]>([]);
  useEffect(() => {
    Promise.all([
      oemSCodeCreateApi.incOemScodeCreate(OEM_API_VERSION, Number(customerId), Number(apiClientId)),
      customerInfoApi.applyHistoryListDisplay(Number(customerId), {}),
    ]).then((res) => {
      setSCode(res[0].data.scode);
      setList(res[1].data);
    });
  }, []);

  return (
    <>
      {typeof sCode === 'string' && (
        <>
          <h3>このページのURLの有効期限は画面を表示してから約24時間です。</h3>
          <a
            href={`${Url.TOC_OEM.DETAIL}/:monitorId?sCode=${sCode}`}
            target="_blank"
            rel="noreferrer"
            className="d-block"
          >
            応募ページ
          </a>
          <a href={`${Url.TOC_OEM.PROGRESS}?sCode=${sCode}`} target="_blank" rel="noreferrer" className="d-block">
            進行中のモニター
          </a>
          <a
            href={`${Url.TOC_OEM.PRECHECK}/:applyId?sCode=${sCode}`}
            target="_blank"
            rel="noreferrer"
            className="d-block"
          >
            事前確認
          </a>
          <a
            href={`${Url.TOC_OEM.STORE_GUIDE}/:applyId?sCode=${sCode}`}
            target="_blank"
            rel="noreferrer"
            className="d-block"
          >
            店内ガイド
          </a>
          <a href={`${Url.TOC_OEM.ENQ}/:applyId?sCode=${sCode}`} target="_blank" rel="noreferrer" className="d-block">
            アンケート提出
          </a>
          <a
            href={`${Url.TOC_OEM.RECEIPT}/:applyId?sCode=${sCode}`}
            target="_blank"
            rel="noreferrer"
            className="d-block"
          >
            レシート提出
          </a>
          <ApplyHistoryListTable listPerPage={list} resultNum={100} customerId={Number(customerId)} />
        </>
      )}
    </>
  );
};
