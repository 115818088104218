import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useLargeState } from '../../../../hooks/useLargeState';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { PointGrantContext } from '../../../../store/pointPageStore';
import { createTestId, nameof, uuid } from '../../../../utils/functions';
import { Button } from '../../../atoms/Button';
import type { State as ParentState } from '../PointGrantPage';
import { Title } from '../../../atoms/Title';

type FormsState = Partial<Exclude<ParentState['forms'], undefined>>;

interface Props {
  onConfirm: (formsState: ParentState['forms']) => void;
}

export const PointGrantRegisterPage: React.FC<Props> = ({ onConfirm }) => {
  const testid = createTestId(PointGrantRegisterPage);
  const context = useSafeContext(PointGrantContext);

  const params = useParams<{ id: string | undefined }>();

  // 数字がurlに入っていない場合はformに書き込まない
  const id = Number.isNaN(Number(params.id)) ? undefined : Number(params.id);

  const { state: $, mergeState } = context;
  const { state: f, onChangeSet } = useLargeState<FormsState>($.forms ?? { customerId: id });

  const readyForms: Required<ParentState['forms']> | undefined = useMemo(() => {
    const { customerId, evidence, grantPoints, grantType, internalComment, messageForCustomer } = f;
    if (
      ![customerId, evidence, grantPoints, grantType, internalComment, messageForCustomer].every(
        (x) => x !== undefined && x !== ''
      )
    )
      return undefined;
    return f as Required<ParentState['forms']>;
  }, [f]);

  return (
    <>
      <Title>ポイント付与</Title>
      <Button data-testid={testid('bulk-button')} variant="link" onClick={() => mergeState({ phase: 'bulk' })}>
        xlsxファイルでの一括付与はこちら
      </Button>
      <Form>
        <Form.Group data-testid={testid(nameof<FormsState>('customerId'))}>
          <Form.Label>対象会員ID</Form.Label>
          <Form.Control
            type="number"
            autoComplete="off"
            value={f.customerId ?? ''}
            onChange={onChangeSet('customerId')}
          />
        </Form.Group>
        <Form.Group data-testid={testid(nameof<FormsState>('applyId'))}>
          <Form.Label>応募ID</Form.Label>
          <Form.Control type="number" autoComplete="off" value={f.applyId ?? ''} onChange={onChangeSet('applyId')} />
        </Form.Group>
        <Form.Group data-testid={testid(nameof<FormsState>('grantType'))}>
          <Form.Label>ポイントタイプ</Form.Label>
          <Form.Select autoComplete="off" value={f.grantType ?? ''} onChange={onChangeSet('grantType')}>
            <option hidden defaultChecked>
              選択してください
            </option>
            {$.pointTypes.map(({ code, name }) => (
              <option key={uuid()} value={code}>
                {name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group data-testid={testid(nameof<FormsState>('grantPoints'))}>
          <Form.Label>付与ポイント数</Form.Label>
          <Form.Control
            type="number"
            autoComplete="off"
            value={f.grantPoints ?? ''}
            onChange={onChangeSet('grantPoints')}
          />
        </Form.Group>
        <Form.Group data-testid={testid(nameof<FormsState>('evidence'))}>
          <Form.Label>付与根拠（SlackURL、問い合わせ番号等）</Form.Label>
          <Form.Control as="textarea" autoComplete="off" value={f.evidence ?? ''} onChange={onChangeSet('evidence')} />
        </Form.Group>
        <Form.Group data-testid={testid(nameof<FormsState>('internalComment'))}>
          <Form.Label>内部用コメント</Form.Label>
          <Form.Control
            as="textarea"
            autoComplete="off"
            value={f.internalComment ?? ''}
            onChange={onChangeSet('internalComment')}
          />
        </Form.Group>
        <Form.Group data-testid={testid(nameof<FormsState>('messageForCustomer'))}>
          <Form.Label>ユーザ向けメッセージ</Form.Label>
          <Form.Control
            as="textarea"
            autoComplete="off"
            value={f.messageForCustomer ?? ''}
            onChange={onChangeSet('messageForCustomer')}
          />
        </Form.Group>
      </Form>
      <Button
        data-testid={testid('confirm-button')}
        disabled={!readyForms}
        onClick={() => {
          if (!readyForms) return;
          onConfirm({
            applyId: Number(f.applyId),
            customerId: Number(f.customerId),
            evidence: f.evidence,
            grantPoints: Number(f.grantPoints),
            grantType: Number(f.grantType),
            internalComment: f.internalComment,
            messageForCustomer: f.messageForCustomer,
          } as ParentState['forms']);
        }}
      >
        確認
      </Button>
    </>
  );
};
