import React from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';

export interface Props {
  currentPage: number;
  totalPage: number;
  handleClick: (page: number) => void;
}

export const PaginationWithEllipsis: React.FC<Props> = ({ currentPage, totalPage, handleClick }: Props) => {
  const displayPages = [];
  const items: JSX.Element[] = [];

  for (let i = 1; i <= totalPage; i += 1) {
    if (i === 1 || i === totalPage || (i >= currentPage - 2 && i <= currentPage + 2)) {
      displayPages.push(i);
    }
  }

  let previousPage = 0;

  displayPages.forEach((page) => {
    if (previousPage > 0) {
      if (page - previousPage === 2 || page - previousPage > 2) {
        items.push(<BootstrapPagination.Ellipsis key={previousPage + 1} />);
      }
    }
    items.push(
      <BootstrapPagination.Item
        key={page}
        active={page === currentPage}
        onClick={() => {
          handleClick(page);
        }}
      >
        {page}
      </BootstrapPagination.Item>
    );

    previousPage = page;
  });

  return (
    <BootstrapPagination data-testid="pagination" className="mb-0">
      <BootstrapPagination.First onClick={() => handleClick(1)} />
      <BootstrapPagination.Prev onClick={() => handleClick(currentPage - 1)} />
      {items}
      <BootstrapPagination.Next onClick={() => handleClick(currentPage + 1)} />
      <BootstrapPagination.Last onClick={() => handleClick(totalPage)} />
    </BootstrapPagination>
  );
};
