/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * PostingRsvDownloadApi - axios parameter creator
 * @export
 */
export const PostingRsvDownloadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 掲載予約情報をダウンロードする
         * @summary 掲載予約情報ダウンロード
         * @param {number} postingRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postingRsvDownload: async (postingRequestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postingRequestId' is not null or undefined
            assertParamExists('postingRsvDownload', 'postingRequestId', postingRequestId)
            const localVarPath = `/api/posting-rsv-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (postingRequestId !== undefined) {
                localVarQueryParameter['postingRequestId'] = postingRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostingRsvDownloadApi - functional programming interface
 * @export
 */
export const PostingRsvDownloadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostingRsvDownloadApiAxiosParamCreator(configuration)
    return {
        /**
         * 掲載予約情報をダウンロードする
         * @summary 掲載予約情報ダウンロード
         * @param {number} postingRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postingRsvDownload(postingRequestId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postingRsvDownload(postingRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostingRsvDownloadApi - factory interface
 * @export
 */
export const PostingRsvDownloadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostingRsvDownloadApiFp(configuration)
    return {
        /**
         * 掲載予約情報をダウンロードする
         * @summary 掲載予約情報ダウンロード
         * @param {number} postingRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postingRsvDownload(postingRequestId: number, options?: any): AxiosPromise<string> {
            return localVarFp.postingRsvDownload(postingRequestId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostingRsvDownloadApi - object-oriented interface
 * @export
 * @class PostingRsvDownloadApi
 * @extends {BaseAPI}
 */
export class PostingRsvDownloadApi extends BaseAPI {
    /**
     * 掲載予約情報をダウンロードする
     * @summary 掲載予約情報ダウンロード
     * @param {number} postingRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostingRsvDownloadApi
     */
    public postingRsvDownload(postingRequestId: number, options?: AxiosRequestConfig) {
        return PostingRsvDownloadApiFp(this.configuration).postingRsvDownload(postingRequestId, options).then((request) => request(this.axios, this.basePath));
    }
}
