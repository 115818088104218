/* eslint-disable import/no-cycle */
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ApplyInfoOutputResponse } from '../../../../api-client';
import { Url } from '../../../../constants/Url';
import { createTestId, formatISODate } from '../../../../utils/functions';
import { Button } from '../../../atoms/Button';

export interface Props {
  applyId: string;
  applyInfo: ApplyInfoOutputResponse;
}

const ReceiptInfoCard: React.FC<Props> = ({ applyId, applyInfo }) => {
  const testid = createTestId(ReceiptInfoCard);
  const history = useHistory();

  return (
    <Card className="mb-4" data-testid={testid('receiptInfoCard')}>
      <Card.Body>
        <Form.Group className="mb-4">
          <div className="fs-5 p-1 fw-bold">来店/レシート</div>
          <div className="bg-light p-3 mb-2">
            <Form.Label>来店（購入）日時</Form.Label>
            <div className="fw-bold">
              {applyInfo?.receiptInfo.visitAt && formatISODate(applyInfo?.receiptInfo.visitAt, 'yyyy/MM/dd HH:mm:ss')}
            </div>
          </div>
          <div className="bg-light p-3">
            <Form.Label>レシート金額/利用金額</Form.Label>
            <div className="fw-bold">{applyInfo?.receiptInfo.surveyProofAmount}</div>
            <div className="text-right">
              <Button
                className="text-right"
                onClick={() => {
                  history.push(`${Url.TENSAKU.RECEIPT_PRICE_MODIFY}/${applyId}`);
                }}
              >
                金額修正
                <p style={{ fontSize: '0.5rem' }}>※機能完成までしばらくお待ちください</p>
              </Button>
            </div>
          </div>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ReceiptInfoCard;
