import React, { useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { createTestId, formatISODate, nameof } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import type { ModalState as ParentState } from '../../pages/Customer/CustomerAuthHistoryPage';

interface Props {
  forms: ParentState['forms'];
  setFormData: DispatchSetState<ParentState['forms']>;
  isEdit: boolean;
}
export const CustomerSmsAuthModalForms: React.FC<Props> = ({ forms, setFormData, isEdit }) => {
  const testid = createTestId<keyof ParentState['forms'] | 'auto-fill-button'>(CustomerSmsAuthModalForms);
  const { state: $, onChangeSet, mergeState } = useLargeState<ParentState['forms']>(forms);

  const name = (x: keyof ParentState['forms']) => nameof(x);
  useEffect(() => setFormData($), [setFormData, $]);

  const onchangeDisableFlg = useCallback(
    (disableFlg: boolean) => {
      mergeState({ disableFlg: !disableFlg });
    },
    [mergeState]
  );

  return (
    <>
      <Form.Group controlId={name('phoneNumber')} data-testid={testid('phoneNumber')}>
        <Form.Label>電話番号</Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$.phoneNumber ?? ''}
          onChange={onChangeSet('phoneNumber')}
          disabled={isEdit}
        />
      </Form.Group>
      {isEdit ? (
        <>
          <Form.Group controlId={name('occurrenceAt')} data-testid={testid('occurrenceAt')}>
            <Form.Label>送信日時</Form.Label>
            <Form.Control type="text" autoComplete="off" value={$.occurrenceAt || ''} disabled />
          </Form.Group>
          <Form.Group controlId={name('successFlg')} data-testid={testid('successFlg')}>
            <Form.Label>成否</Form.Label>
            <Form.Control type="text" autoComplete="off" value={$.successFlg ? '成功' : '失敗'} disabled />
          </Form.Group>
        </>
      ) : undefined}
      <Form.Group controlId={name('disableFlg')} data-testid={testid('disableFlg')}>
        <Form.Label>有効</Form.Label>
        <Form.Check type="switch" onChange={() => onchangeDisableFlg($.disableFlg)} checked={!$.disableFlg} />
      </Form.Group>
    </>
  );
};
