import React, { useEffect } from 'react';
import { Tabs, Tab, Form } from 'react-bootstrap';
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import { BannerPriorityListApi, BannerPriorityUpdateApi } from '../../../api-client';
import type {
  BannerPriorityUpdateFormResponse,
  BannerPriorityListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { Alert } from '../../atoms/Alert';
import { BannerPriorityModifyTable } from '../../organisms/Table/BannerPriorityModifyTable';
import type { BannerType } from './BannerListPage';

const GENDER = {
  MALE: '男性',
  FEMALE: '女性',
} as const;

export type Gender = keyof typeof GENDER;

export const BannerPriorityModifyPage: React.VFC = () => {
  const history = useHistory();
  const { state: bannerType } = useLocation<BannerType>();

  const { register, handleSubmit, reset, control } = useForm<BannerPriorityListOutputResponse>({});
  const { fields } = useFieldArray({ control, name: 'list' });

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<{
    api: BannerPriorityListApi;
    updateApi: BannerPriorityUpdateApi;
    tabType: Gender;
    currentPages: { MALE: number; FEMALE: number };
    updResult: IncResultOutputResponse;
  }>({
    api: new BannerPriorityListApi(),
    updateApi: new BannerPriorityUpdateApi(),
    tabType: 'MALE',
    currentPages: { MALE: 1, FEMALE: 1 },
    updResult: { result: false },
  });

  useEffect(() => {
    if (!bannerType?.id) return;
    $.api.bannerPriorityList(bannerType?.id).then((res: AxiosResponse<BannerPriorityListOutputResponse>) => {
      // const { list } = sampleGetData;
      reset({
        list: res.data.list.sort((a, b) => b.id - a.id).map((v, i) => ({ ...v, index: i })),
      });
    });
  }, [bannerType?.id, $.api, reset]);

  const onSubmit: SubmitHandler<BannerPriorityListOutputResponse> = (data) => {
    $.updateApi
      .bannerPriorityUpdate(
        data.list.map(({ id, malePriority, femalePriority }: BannerPriorityUpdateFormResponse) => ({
          id,
          malePriority: Number(malePriority) || undefined,
          femalePriority: Number(femalePriority) || undefined,
        }))
      )
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      });
  };

  return (
    <>
      <Title className="mb-4">{`${bannerType.name}${TITLE.KEISAI.BANNER_PRIORITY_MODIFY}`}</Title>

      {$.updResult?.result && <Alert variant="success">保存しました。</Alert>}
      {$.updResult?.errorCode && $.updResult?.errorMessage && (
        <Alert variant="danger">{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-end mb-4">
          <Button variant="link" className="ms-2" onClick={() => history.goBack()}>
            キャンセル
          </Button>
          <Button type="submit" className="ms-2">
            保存
          </Button>
        </div>
        <Tabs onSelect={(key) => mergeState({ tabType: key as Gender })} className="mb-4">
          {Object.entries(GENDER).map(([key, value]) => {
            return <Tab key={key} eventKey={key} title={value} />;
          })}
        </Tabs>
        <BannerPriorityModifyTable
          tab={$.tabType}
          fields={fields}
          register={register}
          currentPages={$.currentPages}
          setCurrentPages={useBindSet('currentPages')}
        />
      </Form>
    </>
  );
};
