interface Props {
  rateType: string;
  rateValue: number;
  upperLimit?: number;
}
export const RateView: React.FC<{ props: Props }> = ({ props }) => {
  const { rateType } = props;

  return (
    <>
      <h5>割合／固定</h5>
      <p>{rateType}</p>
      <RateValueContent props={props} />
    </>
  );
};

const RateValueContent: React.FC<{ props: Props }> = ({ props }) => {
  const { rateType, rateValue, upperLimit } = props;
  if (rateType === '割合') {
    return (
      <>
        <p>{rateValue}%</p>
        <p>{upperLimit}円</p>
      </>
    );
  }
  return <p>{rateValue}円</p>;
};
