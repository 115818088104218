import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useSafeContext } from '../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../store/correctionStore';
import { uuid, when } from '../../../../../../utils/functions';
import { TinyHead } from '../../../../../atoms/TinyHead';
import { TemplateFormMode } from '../../../../../pages/Correction/constants';
import { dpToData, getCommonCommentFormList } from '../../../../../pages/Correction/utils';

export const AccordionList: React.FC<{ mode: TemplateFormMode.NG | TemplateFormMode.ESCALE }> = ({ mode }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  const list = useMemo(
    () =>
      getCommonCommentFormList(mode, $).map((ng) => ({
        ...ng,
        answer: dpToData(ng.dp).toCommentForm($.commentForms_enqueteText).at(0)?.content,
      })),
    [$, mode]
  );
  return (
    <>
      {
        // NGの場合
        mode === TemplateFormMode.NG ? (
          <>
            <TinyHead>以下の項目にNGが存在します</TinyHead>
            {list.map((ng) => {
              const { content: answer } = dpToData(ng.dp).toCommentForm($.commentForms_enqueteText).at(0) ?? {};
              return (
                <Card body key={uuid()}>
                  <TinyHead>{ng.name}</TinyHead>
                  <div className="bg-white mt-2 p-2 border border-danger text-danger">{ng?.content}</div>
                  {when(!!answer, <div className="bg-white mt-2 p-2 border">{answer}</div>)}
                </Card>
              );
            })}
          </>
        ) : (
          <>
            <TinyHead>以下の項目にエスカレが存在します</TinyHead>
            <ul>
              {list.map(({ name }) => (
                <li key={name}>{name}</li>
              ))}
            </ul>
          </>
        )
      }
    </>
  );
};
