/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientNgCustomerDetailOutputResponse } from '../domains';
// @ts-ignore
import { ClientNgCustomerInputCompleteFormResponse } from '../domains';
// @ts-ignore
import { ClientNgListFormResponse } from '../domains';
// @ts-ignore
import { ClientNgListOutputResponse } from '../domains';
// @ts-ignore
import { ClientNgReasonTypeListOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * ClientNgApi - axios parameter creator
 * @export
 */
export const ClientNgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * クライアントNGを削除(論理)する。
         * @summary クライアントNG削除
         * @param {number} clientId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgCustomerDelete: async (clientId: number, customerId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientNgCustomerDelete', 'clientId', clientId)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('clientNgCustomerDelete', 'customerId', customerId)
            const localVarPath = `/api/client-ng/{client-id}/{customer-id}`
                .replace(`{${"client-id"}}`, encodeURIComponent(String(clientId)))
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントNG会員詳細を取得する。
         * @summary クライアントNG会員詳細取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgCustomerDetail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientNgCustomerDetail', 'id', id)
            const localVarPath = `/api/client-ng/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントNG会員の登録更新を行う。
         * @summary クライアントNG会員の登録更新
         * @param {ClientNgCustomerInputCompleteFormResponse} clientNgCustomerInputCompleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgCustomerInputComplete: async (clientNgCustomerInputCompleteFormResponse: ClientNgCustomerInputCompleteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientNgCustomerInputCompleteFormResponse' is not null or undefined
            assertParamExists('clientNgCustomerInputComplete', 'clientNgCustomerInputCompleteFormResponse', clientNgCustomerInputCompleteFormResponse)
            const localVarPath = `/api/client-ng`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientNgCustomerInputCompleteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントNG CSVダウンロードする。
         * @summary クライアントNG CSVダウンロード
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgCustomerListDownload: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clientNgCustomerListDownload', 'id', id)
            const localVarPath = `/api/client-ng-file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントNG一覧を取得する。
         * @summary クライアントNG一覧取得表示
         * @param {ClientNgListFormResponse} clientNgListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgList: async (clientNgListFormResponse: ClientNgListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientNgListFormResponse' is not null or undefined
            assertParamExists('clientNgList', 'clientNgListFormResponse', clientNgListFormResponse)
            const localVarPath = `/api/client-ng/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientNgListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントNG理由タイプリスト取得する。
         * @summary クライアントNG理由タイプリスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgReasonTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/client-ng-reason-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientNgApi - functional programming interface
 * @export
 */
export const ClientNgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientNgApiAxiosParamCreator(configuration)
    return {
        /**
         * クライアントNGを削除(論理)する。
         * @summary クライアントNG削除
         * @param {number} clientId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientNgCustomerDelete(clientId: number, customerId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientNgCustomerDelete(clientId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントNG会員詳細を取得する。
         * @summary クライアントNG会員詳細取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientNgCustomerDetail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientNgCustomerDetailOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientNgCustomerDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントNG会員の登録更新を行う。
         * @summary クライアントNG会員の登録更新
         * @param {ClientNgCustomerInputCompleteFormResponse} clientNgCustomerInputCompleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientNgCustomerInputComplete(clientNgCustomerInputCompleteFormResponse: ClientNgCustomerInputCompleteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientNgCustomerInputComplete(clientNgCustomerInputCompleteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントNG CSVダウンロードする。
         * @summary クライアントNG CSVダウンロード
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientNgCustomerListDownload(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientNgCustomerListDownload(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントNG一覧を取得する。
         * @summary クライアントNG一覧取得表示
         * @param {ClientNgListFormResponse} clientNgListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientNgList(clientNgListFormResponse: ClientNgListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientNgListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientNgList(clientNgListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントNG理由タイプリスト取得する。
         * @summary クライアントNG理由タイプリスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientNgReasonTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientNgReasonTypeListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientNgReasonTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientNgApi - factory interface
 * @export
 */
export const ClientNgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientNgApiFp(configuration)
    return {
        /**
         * クライアントNGを削除(論理)する。
         * @summary クライアントNG削除
         * @param {number} clientId 
         * @param {number} customerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgCustomerDelete(clientId: number, customerId: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.clientNgCustomerDelete(clientId, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントNG会員詳細を取得する。
         * @summary クライアントNG会員詳細取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgCustomerDetail(id: number, options?: any): AxiosPromise<ClientNgCustomerDetailOutputResponse> {
            return localVarFp.clientNgCustomerDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントNG会員の登録更新を行う。
         * @summary クライアントNG会員の登録更新
         * @param {ClientNgCustomerInputCompleteFormResponse} clientNgCustomerInputCompleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgCustomerInputComplete(clientNgCustomerInputCompleteFormResponse: ClientNgCustomerInputCompleteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.clientNgCustomerInputComplete(clientNgCustomerInputCompleteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントNG CSVダウンロードする。
         * @summary クライアントNG CSVダウンロード
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgCustomerListDownload(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.clientNgCustomerListDownload(id, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントNG一覧を取得する。
         * @summary クライアントNG一覧取得表示
         * @param {ClientNgListFormResponse} clientNgListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgList(clientNgListFormResponse: ClientNgListFormResponse, options?: any): AxiosPromise<Array<ClientNgListOutputResponse>> {
            return localVarFp.clientNgList(clientNgListFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントNG理由タイプリスト取得する。
         * @summary クライアントNG理由タイプリスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientNgReasonTypeList(options?: any): AxiosPromise<Array<ClientNgReasonTypeListOutputResponse>> {
            return localVarFp.clientNgReasonTypeList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientNgApi - object-oriented interface
 * @export
 * @class ClientNgApi
 * @extends {BaseAPI}
 */
export class ClientNgApi extends BaseAPI {
    /**
     * クライアントNGを削除(論理)する。
     * @summary クライアントNG削除
     * @param {number} clientId 
     * @param {number} customerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientNgApi
     */
    public clientNgCustomerDelete(clientId: number, customerId: number, options?: AxiosRequestConfig) {
        return ClientNgApiFp(this.configuration).clientNgCustomerDelete(clientId, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントNG会員詳細を取得する。
     * @summary クライアントNG会員詳細取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientNgApi
     */
    public clientNgCustomerDetail(id: number, options?: AxiosRequestConfig) {
        return ClientNgApiFp(this.configuration).clientNgCustomerDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントNG会員の登録更新を行う。
     * @summary クライアントNG会員の登録更新
     * @param {ClientNgCustomerInputCompleteFormResponse} clientNgCustomerInputCompleteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientNgApi
     */
    public clientNgCustomerInputComplete(clientNgCustomerInputCompleteFormResponse: ClientNgCustomerInputCompleteFormResponse, options?: AxiosRequestConfig) {
        return ClientNgApiFp(this.configuration).clientNgCustomerInputComplete(clientNgCustomerInputCompleteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントNG CSVダウンロードする。
     * @summary クライアントNG CSVダウンロード
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientNgApi
     */
    public clientNgCustomerListDownload(id: number, options?: AxiosRequestConfig) {
        return ClientNgApiFp(this.configuration).clientNgCustomerListDownload(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントNG一覧を取得する。
     * @summary クライアントNG一覧取得表示
     * @param {ClientNgListFormResponse} clientNgListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientNgApi
     */
    public clientNgList(clientNgListFormResponse: ClientNgListFormResponse, options?: AxiosRequestConfig) {
        return ClientNgApiFp(this.configuration).clientNgList(clientNgListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントNG理由タイプリスト取得する。
     * @summary クライアントNG理由タイプリスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientNgApi
     */
    public clientNgReasonTypeList(options?: AxiosRequestConfig) {
        return ClientNgApiFp(this.configuration).clientNgReasonTypeList(options).then((request) => request(this.axios, this.basePath));
    }
}
