import * as React from 'react';
import { useWatch } from 'react-hook-form';
import { ValidationFormControl } from '../../molecules/Form/ValidationFormControl';

// formテキストエリア用コンポーネント
export const AutoChangeHeightTextArea = (props: any) => {
  const { control, getValues, register, errorTargetName } = props;
  useWatch({
    control,
    name: 'sqlString',
  });
  const rowsNum = getValues('sqlString')?.split('\n').length;
  return (
    <ValidationFormControl
      as="textarea"
      rows={rowsNum}
      register={register}
      id="sqlString"
      registerName="sqlString"
      errorsTargetName={errorTargetName}
    />
  );
};
