/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * JobControllerApi - axios parameter creator
 * @export
 */
export const JobControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} jobName 
         * @param {string} [p1] 
         * @param {string} [p2] 
         * @param {string} [p3] 
         * @param {boolean} [disableDuplicateExec] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runJob: async (jobName: string, p1?: string, p2?: string, p3?: string, disableDuplicateExec?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobName' is not null or undefined
            assertParamExists('runJob', 'jobName', jobName)
            const localVarPath = `/api/job/{jobName}`
                .replace(`{${"jobName"}}`, encodeURIComponent(String(jobName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (p1 !== undefined) {
                localVarQueryParameter['p1'] = p1;
            }

            if (p2 !== undefined) {
                localVarQueryParameter['p2'] = p2;
            }

            if (p3 !== undefined) {
                localVarQueryParameter['p3'] = p3;
            }

            if (disableDuplicateExec !== undefined) {
                localVarQueryParameter['disableDuplicateExec'] = disableDuplicateExec;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobControllerApi - functional programming interface
 * @export
 */
export const JobControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} jobName 
         * @param {string} [p1] 
         * @param {string} [p2] 
         * @param {string} [p3] 
         * @param {boolean} [disableDuplicateExec] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async runJob(jobName: string, p1?: string, p2?: string, p3?: string, disableDuplicateExec?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.runJob(jobName, p1, p2, p3, disableDuplicateExec, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * JobControllerApi - factory interface
 * @export
 */
export const JobControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} jobName 
         * @param {string} [p1] 
         * @param {string} [p2] 
         * @param {string} [p3] 
         * @param {boolean} [disableDuplicateExec] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runJob(jobName: string, p1?: string, p2?: string, p3?: string, disableDuplicateExec?: boolean, options?: any): AxiosPromise<object> {
            return localVarFp.runJob(jobName, p1, p2, p3, disableDuplicateExec, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobControllerApi - object-oriented interface
 * @export
 * @class JobControllerApi
 * @extends {BaseAPI}
 */
export class JobControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} jobName 
     * @param {string} [p1] 
     * @param {string} [p2] 
     * @param {string} [p3] 
     * @param {boolean} [disableDuplicateExec] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobControllerApi
     */
    public runJob(jobName: string, p1?: string, p2?: string, p3?: string, disableDuplicateExec?: boolean, options?: AxiosRequestConfig) {
        return JobControllerApiFp(this.configuration).runJob(jobName, p1, p2, p3, disableDuplicateExec, options).then((request) => request(this.axios, this.basePath));
    }
}
