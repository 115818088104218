import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import {
  PointExchangeDestinationListApi,
  PointExchangeDestinationUpdateApi,
  PointExchangeDestinationListOutputResponse,
  IncResultOutputResponse,
} from '../../api-client';
import { PointExchangeTable } from '../organisms/Table/PointExchangeTable';

// サンプルデータ
const sampleData = {
  data: [
    {
      id: 1,
      name: 'PeX',
      disableMessage: 'aaa',
      immediateStopFlg: true,
      disableBeginTimestamp: '',
      disableEndTimestamp: '2021/12/14 13:00',
    },
    {
      id: 2,
      name: 'Amazon',
      disableMessage: 'bbb',
      immediateStopFlg: true,
      disableBeginTimestamp: '2021/10/21 10:00',
      disableEndTimestamp: '2021/12/14 13:00',
    },
    {
      id: 3,
      name: 'GPoint',
      disableMessage: 'ccc',
      immediateStopFlg: false,
      disableBeginTimestamp: '2021/10/21 10:00',
      disableEndTimestamp: '2021/12/14 13:00',
    },
  ] as PointExchangeDestinationListOutputResponse[],
};

type DateType = 'disableBeginTimestamp' | 'disableEndTimestamp';

const DATE_TYPE = {
  BEGIN: 'disableBeginTimestamp',
  END: 'disableEndTimestamp',
} as const;

export const PointExchangePage: React.VFC = () => {
  const [data, setData] = useState<PointExchangeDestinationListOutputResponse[]>([]);
  const [updResults, setUpdResults] = useState<IncResultOutputResponse[]>([]);

  const getApi = new PointExchangeDestinationListApi();

  useEffect(() => {
    getApi.pointExcDestList().then((res: AxiosResponse<PointExchangeDestinationListOutputResponse[]>) => {
      setData(res.data);
      setUpdResults(
        [...Array(sampleData.data.length)].map((v, i) => {
          return { result: false, errorCode: '', errorMessage: '' };
        })
      );
    });
  }, []);

  const handleChangeTextarea = (targetIndex: number, e: React.ChangeEvent<HTMLInputElement>): void => {
    setData(
      data.map((item, i) => {
        return targetIndex === i ? { ...item, disableMessage: e.target.value } : item;
      })
    );
  };

  const handleChangeCheckbox = (targetIndex: number, e: React.ChangeEvent<HTMLInputElement>): void => {
    setData(
      data.map((item, i) => {
        return targetIndex === i ? { ...item, immediateStopFlg: e.target.checked } : item;
      })
    );
  };

  const handleChangeDatepicker = (key: DateType, targetIndex: number, e: React.ChangeEvent<HTMLInputElement>): void => {
    const replacedValue = e.target.value ? e.target.value.replace(/-/g, '/') : '';

    setData(
      data.map((item, i) => {
        return targetIndex === i
          ? {
              ...item,
              [key]: item[key] ? item[key]!.replace(/([0-9/]*) /, `${replacedValue} `) : `${replacedValue} `,
            }
          : item;
      })
    );
  };

  const handleChangeTimepicker = (key: DateType, targetIndex: number, e: React.ChangeEvent<HTMLInputElement>): void => {
    const replacedValue = e.target.value ? e.target.value : '';
    setData(
      data.map((item, i) => {
        return targetIndex === i
          ? {
              ...item,
              [key]: item[key] ? item[key]!.replace(/ ([0-9:]*)/, ` ${replacedValue}`) : ` ${replacedValue}`,
            }
          : item;
      })
    );
  };

  const handleSubmit = (targetIndex: number, e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const targetData = data[targetIndex];

    targetData.disableBeginTimestamp = targetData.disableBeginTimestamp === ' ' ? '' : targetData.disableBeginTimestamp;
    targetData.disableEndTimestamp = targetData.disableEndTimestamp === ' ' ? '' : targetData.disableEndTimestamp;

    if (
      targetData.disableBeginTimestamp &&
      !/(\d){4}\/(\d){2}\/(\d){2} (\d){2}:(\d){2}/.test(targetData.disableBeginTimestamp)
    ) {
      setUpdResults(
        updResults.map((item, idx) => {
          return targetIndex === idx
            ? {
                result: false,
                errorCode: '',
                errorMessage: '開始日時：日付と時間の両方を指定してください。',
              }
            : item;
        })
      );
    } else if (
      targetData.disableEndTimestamp &&
      !/(\d){4}\/(\d){2}\/(\d){2} (\d){2}:(\d){2}/.test(targetData.disableEndTimestamp)
    ) {
      setUpdResults(
        updResults.map((item, idx) => {
          return targetIndex === idx
            ? {
                result: false,
                errorCode: '',
                errorMessage: '終了日時：日付と時間の両方を指定してください。',
              }
            : item;
        })
      );
    } else {
      const { name, ...rest } = targetData;
      const updateParam: any = rest;
      const updateApi = new PointExchangeDestinationUpdateApi();

      updateApi.pointExcDestUpdate(updateParam).then((res: AxiosResponse<IncResultOutputResponse>) => {
        setUpdResults(
          updResults.map((item, idx) => {
            return targetIndex === idx ? res.data : item;
          })
        );
      });
    }
  };

  return (
    <>
      {data?.map((item, idx) => {
        return (
          <div key={item.id.toString()}>
            <PointExchangeTable
              item={item}
              handleChangeTextarea={(e) => handleChangeTextarea(idx, e)}
              handleChangeCheckbox={(e) => handleChangeCheckbox(idx, e)}
              handleChangeDatepickerBegin={(e) => handleChangeDatepicker(DATE_TYPE.BEGIN, idx, e)}
              handleChangeTimepickerBegin={(e) => handleChangeTimepicker(DATE_TYPE.BEGIN, idx, e)}
              handleChangeDatepickerEnd={(e) => handleChangeDatepicker(DATE_TYPE.END, idx, e)}
              handleChangeTimepickerEnd={(e) => handleChangeTimepicker(DATE_TYPE.END, idx, e)}
              handleSubmit={(e) => handleSubmit(idx, e)}
              updResult={updResults[idx]}
            />
            {data.length === idx + 1 || <hr className="my-5" />}
          </div>
        );
      })}
    </>
  );
};
