import React, { useEffect, useCallback, useState } from 'react';
import { Tabs, Tab, Alert } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line import/no-cycle
import { BannerListApi, BannerListDeleteApi } from '../../../api-client';
import type {
  BannerListOutputResponse,
  IncResultOutputResponse,
  BannerListOutputListItemResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { TITLE } from '../../../constants/Title';
import { Url } from '../../../constants/Url';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { BannerListTable } from '../../organisms/Table/BannerListTable';
import { when } from '../../../utils/functions';

export const BANNER_TYPE = {
  KURUKURU: 1,
  RECOMMEND: 2,
  MODAL: 3,
  CAMPAIGN: 4,
  CATETOP: 6,
} as const;

const BANNER_OPTIONS = [
  { id: BANNER_TYPE.KURUKURU, name: 'くるくるバナー' },
  { id: BANNER_TYPE.RECOMMEND, name: 'あなたにおすすめバナー' },
  { id: BANNER_TYPE.MODAL, name: 'モーダルバナー' },
  { id: BANNER_TYPE.CAMPAIGN, name: 'キャンペーン・特集' },
  { id: BANNER_TYPE.CATETOP, name: 'カテゴリTOPバナー' },
] as const;

export const CATEGORY_OPTIONS = [
  { id: 1, name: 'グルメ' },
  { id: 2, name: '美容' },
  { id: 3, name: '店頭購入' },
  { id: 4, name: '通販' },
  { id: 5, name: 'サービス' },
  { id: 6, name: 'アンケート' },
] as const;

const MAX_RECORD_PER_PAGE = 100;

export type BannerType = typeof BANNER_OPTIONS[number];
export type Category = typeof CATEGORY_OPTIONS[number];
export type DisplayListType = BannerListOutputListItemResponse & {
  checked: boolean;
};

export const BannerListPage: React.VFC = () => {
  const history = useHistory();

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<{
    api: BannerListApi;
    deleteApi: BannerListDeleteApi;
    bannerTypeId: number;
    categoryId: number;
    list: DisplayListType[];
    listPerPage: DisplayListType[];
    currentPage: number;
    totalPage: number;
    updResult: IncResultOutputResponse;
  }>({
    api: new BannerListApi(),
    deleteApi: new BannerListDeleteApi(),
    bannerTypeId: 1,
    categoryId: 1,
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
    updResult: { result: false },
  });

  const [deleteFlg, setDeleteFlg] = useState<boolean>(false);

  const setPagingStates = useCallback(
    (list: BannerListOutputListItemResponse[]) => {
      const modifyList = list.map((item) => ({ ...item, checked: false })).sort((a, b) => b.id - a.id);

      mergeState({
        list: modifyList,
        listPerPage: modifyList.slice(0, MAX_RECORD_PER_PAGE),
        totalPage: Math.ceil(Number(modifyList?.length) / MAX_RECORD_PER_PAGE),
        currentPage: 1,
      });
    },
    [mergeState]
  );

  useEffect(() => {
    $.api
      .bannerList($.bannerTypeId, $.bannerTypeId === BANNER_TYPE.CATETOP ? $.categoryId : undefined)
      .then((res: AxiosResponse<BannerListOutputResponse>) => {
        setPagingStates(res.data.list);
      });
  }, [$.api, $.bannerTypeId, $.categoryId, setPagingStates]);

  const onDelete = () => {
    const deleteIds = $.listPerPage.filter(({ checked }) => checked).map(({ id }) => id);
    $.deleteApi.bannerListDelete(deleteIds).then((res: AxiosResponse<IncResultOutputResponse>) => {
      if (res.data.errorMessage) {
        mergeState({ updResult: res.data });
      } else {
        setDeleteFlg(true);
        setPagingStates($.list.filter((item) => !deleteIds.includes(item.id)));
      }
    });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.BANNER_LIST}</Title>

      {when(
        !!$.updResult.errorMessage && $.updResult.errorMessage?.length > 0,
        <Alert variant="danger">{$.updResult.errorMessage}</Alert>
      )}

      {deleteFlg && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          削除しました。
        </Alert>
      )}

      <div className="d-flex justify-content-end mb-4">
        <Button variant="link" onClick={() => history.push(Url.KEISAI.BANNER_COMMON)}>
          共通設定
        </Button>
      </div>

      <Tabs onSelect={(key) => mergeState({ bannerTypeId: Number(key) })} className="mb-4">
        {BANNER_OPTIONS.map((b) => (
          <Tab key={b.id} eventKey={b.id} title={b.name}>
            {b.id === BANNER_TYPE.CATETOP && (
              <Tabs onSelect={(key) => mergeState({ categoryId: Number(key) })} className="mb-4">
                {CATEGORY_OPTIONS.map((c) => (
                  <Tab key={c.id} eventKey={c.id} title={c.name} />
                ))}
              </Tabs>
            )}
          </Tab>
        ))}
      </Tabs>

      <div className="d-flex justify-content-between mb-4">
        <Button variant="outline-secondary" onClick={onDelete}>
          チェックしたバナーをまとめて削除
        </Button>

        <div>
          <Button
            variant="link"
            onClick={() => {
              history.push({
                pathname: Url.KEISAI.BANNER_INSITE,
                state: BANNER_OPTIONS.find(({ id }) => id === $.bannerTypeId),
              });
            }}
          >
            インサイトを見る
          </Button>

          <Button
            variant="link"
            className="ms-2"
            onClick={() => {
              history.push({
                pathname: Url.KEISAI.BANNER_PRIORITY_MODIFY,
                state: BANNER_OPTIONS.find(({ id }) => id === $.bannerTypeId),
              });
            }}
          >
            優先順位設定
          </Button>

          <Button
            variant="link"
            className="text-secondary ms-2"
            onClick={() => {
              history.push({
                pathname: Url.KEISAI.BANNER_ITEM_MODIFY,
                state: {
                  bannerType: BANNER_OPTIONS.find(({ id }) => id === $.bannerTypeId),
                  categoryId: $.bannerTypeId === BANNER_TYPE.CATETOP ? $.categoryId : undefined,
                },
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
            追加
          </Button>
        </div>
      </div>

      <BannerListTable
        listPerPage={$.listPerPage}
        setListPerPage={useBindSet('listPerPage')}
        transitionParam={{
          bannerType: BANNER_OPTIONS.find(({ id }) => id === $.bannerTypeId),
          categoryId: $.bannerTypeId === BANNER_TYPE.CATETOP ? $.categoryId : undefined,
        }}
      />

      <div className="mb-4">
        <PaginationWithEllipsis
          currentPage={$.currentPage}
          totalPage={$.totalPage}
          handleClick={(page) => {
            if (!page || page > $.totalPage) return;
            mergeState({
              listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
              currentPage: page,
            });
          }}
        />
      </div>
    </>
  );
};
