/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PostingRequestRsvInfoListOutputResponse } from '../domains';
/**
 * PostingRequestRsvInfoListApi - axios parameter creator
 * @export
 */
export const PostingRequestRsvInfoListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 掲載依頼予約情報一覧を取得する
         * @summary 掲載依頼予約情報一覧取得
         * @param {string} [clientIdOrName] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [shopName] 
         * @param {string} [monitorName] 
         * @param {string} [enqueteName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postingRequestRsvInfoList: async (clientIdOrName?: string, fromDate?: string, toDate?: string, shopName?: string, monitorName?: string, enqueteName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/posting-request-rsv-info-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientIdOrName !== undefined) {
                localVarQueryParameter['clientIdOrName'] = clientIdOrName;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = toDate;
            }

            if (shopName !== undefined) {
                localVarQueryParameter['shopName'] = shopName;
            }

            if (monitorName !== undefined) {
                localVarQueryParameter['monitorName'] = monitorName;
            }

            if (enqueteName !== undefined) {
                localVarQueryParameter['enqueteName'] = enqueteName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostingRequestRsvInfoListApi - functional programming interface
 * @export
 */
export const PostingRequestRsvInfoListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostingRequestRsvInfoListApiAxiosParamCreator(configuration)
    return {
        /**
         * 掲載依頼予約情報一覧を取得する
         * @summary 掲載依頼予約情報一覧取得
         * @param {string} [clientIdOrName] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [shopName] 
         * @param {string} [monitorName] 
         * @param {string} [enqueteName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postingRequestRsvInfoList(clientIdOrName?: string, fromDate?: string, toDate?: string, shopName?: string, monitorName?: string, enqueteName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostingRequestRsvInfoListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postingRequestRsvInfoList(clientIdOrName, fromDate, toDate, shopName, monitorName, enqueteName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostingRequestRsvInfoListApi - factory interface
 * @export
 */
export const PostingRequestRsvInfoListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostingRequestRsvInfoListApiFp(configuration)
    return {
        /**
         * 掲載依頼予約情報一覧を取得する
         * @summary 掲載依頼予約情報一覧取得
         * @param {string} [clientIdOrName] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {string} [shopName] 
         * @param {string} [monitorName] 
         * @param {string} [enqueteName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postingRequestRsvInfoList(clientIdOrName?: string, fromDate?: string, toDate?: string, shopName?: string, monitorName?: string, enqueteName?: string, options?: any): AxiosPromise<Array<PostingRequestRsvInfoListOutputResponse>> {
            return localVarFp.postingRequestRsvInfoList(clientIdOrName, fromDate, toDate, shopName, monitorName, enqueteName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostingRequestRsvInfoListApi - object-oriented interface
 * @export
 * @class PostingRequestRsvInfoListApi
 * @extends {BaseAPI}
 */
export class PostingRequestRsvInfoListApi extends BaseAPI {
    /**
     * 掲載依頼予約情報一覧を取得する
     * @summary 掲載依頼予約情報一覧取得
     * @param {string} [clientIdOrName] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {string} [shopName] 
     * @param {string} [monitorName] 
     * @param {string} [enqueteName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostingRequestRsvInfoListApi
     */
    public postingRequestRsvInfoList(clientIdOrName?: string, fromDate?: string, toDate?: string, shopName?: string, monitorName?: string, enqueteName?: string, options?: AxiosRequestConfig) {
        return PostingRequestRsvInfoListApiFp(this.configuration).postingRequestRsvInfoList(clientIdOrName, fromDate, toDate, shopName, monitorName, enqueteName, options).then((request) => request(this.axios, this.basePath));
    }
}
