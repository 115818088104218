import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useLargeState } from '../../../hooks/useLargeState';
// eslint-disable-next-line import/no-cycle
import { BannerCommonListApi, BannerCommonUpdateApi } from '../../../api-client';
import type {
  BannerCommonUpdateFormResponse,
  BannerCommonUpdateFormListResponse,
  BannerCommonListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { Alert } from '../../atoms/Alert';
import { BannerCommonTable } from '../../organisms/Table/BannerCommonTable';

export const BannerCommonPage: React.VFC = () => {
  const history = useHistory();

  const { register, handleSubmit, reset, control } = useForm<BannerCommonListOutputResponse>({});
  const { fields } = useFieldArray({ control, name: 'list' });

  const { state: $, mergeState } = useLargeState<{
    api: BannerCommonListApi;
    updateApi: BannerCommonUpdateApi;
    updResult: IncResultOutputResponse;
    form: Partial<BannerCommonUpdateFormListResponse>;
  }>({
    api: new BannerCommonListApi(),
    updateApi: new BannerCommonUpdateApi(),
    updResult: { result: false },
    form: {},
  });

  useEffect(() => {
    $.api.bannerCommonList().then((res: AxiosResponse<BannerCommonListOutputResponse>) => {
      reset({ list: res.data.list });
    });
  }, [$.api, reset]);

  const onSubmit: SubmitHandler<BannerCommonListOutputResponse> = (list) => {
    const forms = list.list as BannerCommonUpdateFormResponse[];
    const body: BannerCommonUpdateFormListResponse = { banners: forms };
    $.updateApi.bannerCommonUpdate(body).then((res: AxiosResponse<IncResultOutputResponse>) => {
      mergeState({ updResult: res.data });
    });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.BANNER_COMMON}</Title>

      {$.updResult?.result && <Alert variant="success">保存しました。</Alert>}
      {$.updResult?.errorCode && $.updResult?.errorMessage && (
        <Alert variant="danger">{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-end mb-4">
          <Button variant="link" className="ms-2" onClick={() => history.goBack()}>
            キャンセル
          </Button>
          <Button type="submit" className="ms-2">
            保存
          </Button>
        </div>
        <BannerCommonTable fields={fields} register={register} />
      </Form>
    </>
  );
};
