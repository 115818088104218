import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Row, Col, FloatingLabel } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { IdNameOutputResponse, MonitorApi, RoleListApi } from '../../../api-client';
import type { MonitorListOutputResponse } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, execDownload } from '../../../utils/functions';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { MonitorListTable } from '../../organisms/Table/MonitorListTable';
// eslint-disable-next-line import/no-cycle
import { MonitorStatusModal } from '../../organisms/Modal/MonitorStatusModal';
import { Url } from '../../../constants/Url';
import { BasicInfoModal } from '../../organisms/Modal/BasicInfoModal';

export interface State {
  api: MonitorApi;
  monitorIdName: string | undefined;
  shopIdName: string | undefined;
  clientIdName: string | undefined;
  fromDate: string | undefined;
  toDate: string | undefined;
  list: MonitorListOutputResponse[];
  listPerPage: MonitorListOutputResponse[];
  currentPage: number;
  totalPage: number;
  isStatusModal: boolean;
  monitorBaseId: number | undefined;
  isBasicInfoModal: boolean;
}

const MAX_RECORD_PER_PAGE = 100;

export const MonitorListPage: React.VFC = () => {
  const history = useHistory();
  const testid = createTestId(MonitorListPage);
  const initialState: State = {
    api: new MonitorApi(),
    monitorIdName: undefined,
    shopIdName: undefined,
    clientIdName: undefined,
    fromDate: format(new Date().setDate(1), 'yyyy-MM-dd'),
    toDate: undefined,
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
    isStatusModal: false,
    monitorBaseId: undefined,
    isBasicInfoModal: false,
  };

  const { state: $, mergeState, useBindSet } = useLargeState<State>(initialState);

  // 営業ロール保有者フラグ
  const [salesFlg, setSalesFlg] = useState<boolean>(false);

  const roleListApi = new RoleListApi();

  useEffect(() => {
    $.api
      .monitorList($.monitorIdName, $.shopIdName, $.clientIdName, undefined, $.fromDate, $.toDate)
      .then((res: AxiosResponse<MonitorListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 画面初期表示時に一回だけロール一覧を取得し、営業ロールを持っているか確認する。
  useEffect(() => {
    roleListApi.roleList().then((res: AxiosResponse<IdNameOutputResponse[]>) => {
      setSalesFlg(res.data.some((role) => role.name === '営業'));
    });
  }, []);

  const onSearch = () => {
    $.api
      .monitorList($.monitorIdName, $.shopIdName, $.clientIdName, undefined, $.fromDate, $.toDate)
      .then((res: AxiosResponse<MonitorListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
  };

  const onDownload = () => {
    $.api
      .monitorDetailListDownload($.monitorIdName, $.shopIdName, $.clientIdName, undefined, $.fromDate, $.toDate)
      .then((res) => {
        execDownload(res.data, 'text/csv', `${format(new Date(), 'yyyy_MM_dd_hh_mm_ss')}.xlsx`).catch(() => {
          history.push(Url.COMMON_ERROR);
        });
      });
  };

  const setPagingStates = (list: MonitorListOutputResponse[]) => {
    const modifyList = list.sort((a, b) => b.monitorId - a.monitorId);

    mergeState({
      list: modifyList,
      listPerPage: modifyList.slice(0, MAX_RECORD_PER_PAGE),
      totalPage: Math.ceil(Number(modifyList?.length) / MAX_RECORD_PER_PAGE),
      currentPage: 1,
    });
  };

  const onClickStatusModal = (id: number) => {
    mergeState({ monitorBaseId: id, isStatusModal: true });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.MONITOR_LIST}</Title>

      <MonitorStatusModal
        isModal={$.isStatusModal}
        setIsModal={useBindSet('isStatusModal')}
        monitorBaseId={$.monitorBaseId}
        setMonitorBaseId={useBindSet('monitorBaseId')}
      />

      <BasicInfoModal
        isModal={$.isBasicInfoModal}
        setIsModal={useBindSet('isBasicInfoModal')}
        monitorBaseId={$.monitorBaseId ? $.monitorBaseId : 0}
        setMonitorBaseId={useBindSet('monitorBaseId')}
        modifyFlg={false}
      />

      <Form.Group className="mb-4">
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="モニター名/ID" data-testid={testid('clientIdOrName')}>
              <Form.Control
                type="text"
                placeholder="モニター名/ID"
                value={$.monitorIdName || ''}
                onChange={(e) => mergeState({ monitorIdName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="実店舗名/ID" data-testid={testid('shopName')}>
              <Form.Control
                type="text"
                placeholder="実店舗名/ID"
                value={$.shopIdName || ''}
                onChange={(e) => mergeState({ shopIdName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="クライアント名/ID" data-testid={testid('monitorName')}>
              <Form.Control
                type="text"
                placeholder="クライアント名/ID"
                value={$.clientIdName || ''}
                onChange={(e) => mergeState({ clientIdName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col />
        </Row>
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="モニター開始日" data-testid={testid('fromDate')}>
              <Form.Control
                type="date"
                placeholder="モニター開始日"
                value={$.fromDate ? $.fromDate.replace(/\//g, '-') : ''}
                onChange={(e) => mergeState({ fromDate: e.target.value ? e.target.value : undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="モニター終了日" data-testid={testid('toDate')}>
              <Form.Control
                type="date"
                placeholder="モニター終了日"
                value={$.toDate ? $.toDate.replace(/\//g, '-') : ''}
                onChange={(e) => mergeState({ toDate: e.target.value ? e.target.value : undefined })}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col className="col-4">
            <Button className="w-100 py-3" onClick={onSearch} data-testid={testid('search-button')}>
              検索
            </Button>
          </Col>
          <Col className="col-4">
            <Button className="w-100 py-3" onClick={onDownload}>
              ダウンロード
            </Button>
          </Col>
        </Row>
      </Form.Group>

      <MonitorListTable
        listPerPage={$.listPerPage}
        setModal={onClickStatusModal}
        setIsBasicInfoModal={useBindSet('isBasicInfoModal')}
        setMonitorBaseId={useBindSet('monitorBaseId')}
        salesFlg={salesFlg}
      />

      <div className="mb-4" data-testid={testid('pagination')}>
        <PaginationWithEllipsis
          currentPage={$.currentPage}
          totalPage={$.totalPage}
          handleClick={(page) => {
            if (!page || page > $.totalPage) return;
            mergeState({
              listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
              currentPage: page,
            });
          }}
        />
      </div>
    </>
  );
};
