import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useSafeContext } from '../../hooks/useSafeContext';
import sass from '../../scss/templates/CorrectionTemplate.module.scss';
import { CorrectionContext } from '../../store/correctionStore';
import { when } from '../../utils/functions';
import { Button } from './Button';

// TODO: [添削] Storybook未実装
/** 編集・削除ボタンを右上に設定。
 * - 設定したい親を `style={{ position: 'relative' }}` する */
export const EditDelete: React.FC<{ onEdit?: () => void; onDelete?: () => void }> = ({ onEdit, onDelete }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  return when(
    !!($.res_correctReceiptDisplayControl?.noteUpdate ?? $.res_correctEnqueteDisplayControl?.noteUpdate),
    <div style={{ position: 'absolute', top: 8, right: 8 }}>
      {onEdit && (
        <Button className={sass.btn_edit} variant="link" onClick={onEdit}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      )}
      {onDelete && (
        <Button className={sass.btn_delete} variant="link" onClick={onDelete}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      )}
    </div>
  );
};
