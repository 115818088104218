import * as React from 'react';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
import type { PostingRequestRsvInfoListOutputResponse } from '../../../api-client';
import { createTestId, uuid } from '../../../utils/functions';
import { Button } from '../../atoms/Button';

export interface Props {
  listPerPage: PostingRequestRsvInfoListOutputResponse[];
}

export const PostingReservationListTable: React.VFC<Props> = ({ listPerPage }) => {
  const testid = createTestId(PostingReservationListTable);
  const history = useHistory();

  const listItem = ({
    applicationFormId,
    clientId,
    clientName,
    applicationFormType,
    applicationStartDate,
    contractShops,
    postingRequestId,
  }: PostingRequestRsvInfoListOutputResponse) => {
    let shopNames: string[] = [];
    let monitorNames: string[] = [];
    let preEnqueteNames: string[] = [];
    let postEnqueteNames: string[] = [];
    contractShops.forEach((item) => {
      item.monitorPostingRsvs.forEach((v, i) => {
        shopNames = i === 0 ? [...shopNames, item.name] : [...shopNames, '-'];
        monitorNames = [...monitorNames, v.monitorName];
        preEnqueteNames = [...preEnqueteNames, v.preEnqueteName ? v.preEnqueteName : '-'];
        postEnqueteNames = [...postEnqueteNames, v.postEnqueteName ? v.postEnqueteName : '-'];
      });
    });

    return (
      <tr key={uuid()}>
        <td>{clientId}</td>
        <td>
          <Button
            variant="link"
            className="p-0"
            onClick={() =>
              history.push({
                pathname: `${Url.KEISAI.POSTING_RESERVATION}/${postingRequestId}`,
                state: { id: applicationFormId, applicationFormFlg: true },
              })
            }
          >
            {clientName}
          </Button>
        </td>
        <td>{applicationFormType}</td>
        <td>{applicationStartDate}</td>
        <td>{shopNames.map((v, i) => (v !== '-' ? <div key={uuid()}>{v}</div> : <div key={uuid()}>&nbsp;</div>))}</td>
        <td>
          {monitorNames.map((v, i) => (
            <div key={uuid()}>{v}</div>
          ))}
        </td>
        <td>
          {preEnqueteNames.map((v, i) => (v !== '-' ? <div key={uuid()}>{v}</div> : <div key={uuid()}>&nbsp;</div>))}
        </td>
        <td>
          {postEnqueteNames.map((v, i) => (v !== '-' ? <div key={uuid()}>{v}</div> : <div key={uuid()}>&nbsp;</div>))}
        </td>
      </tr>
    );
  };

  return (
    <Table className="mb-4" data-testid={testid('table')}>
      <thead>
        <tr className="align-middle">
          <th>クライアントID</th>
          <th>クライアント名</th>
          <th>申込種別</th>
          <th>表示開始日</th>
          <th>契約店舗名</th>
          <th>モニター名</th>
          <th>事前アンケート</th>
          <th>事後アンケート</th>
        </tr>
      </thead>
      <tbody>{listPerPage?.map((v) => listItem(v))}</tbody>
    </Table>
  );
};
