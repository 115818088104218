import React from 'react';
import { Card } from 'react-bootstrap';
import { useSignal } from '../../../../../../../hooks/useSignal';

interface Props {
  answerContent?: string;
  ngWordList: string[];
}

export const TextHighlighter: React.FC<Props> = ({ answerContent, ngWordList }) => {
  const [ref] = useSignal<HTMLDivElement>(null, (current) => {
    if (!current?.innerHTML) return;
    const c = current;
    c.innerHTML = ngWordList.reduce(
      // TODO: [添削] rgba(0, 0, 0, 0.2) にするかは要相談
      (source, ngword) => source?.replaceAll(ngword, `<span class="bg-warning">${ngword}</span>`),
      answerContent ?? ''
    );
  });

  return (
    <>
      <Card body>
        <div ref={ref}>{answerContent}</div>
      </Card>
    </>
  );
};
