import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Url } from '../constants/Url';

/** リダイレクトページへ飛ぶ関数を呼び出す */
export function useRedirect(url: string): () => void {
  const history = useHistory();
  return useCallback(() => {
    history.push(`${Url.REDIRECT}?url=${encodeURIComponent(url)}`);
  }, [history, url]);
}
