import * as React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { ValidationFormControl } from '../../molecules/Form/ValidationFormControl';
import { AutoChangeHeightTextArea } from '../TextArea/AutoChangeHeightTextArea';
import { parameterCellTitle } from './SqlTemplateParameterTable';
import { FormSelect } from '../../molecules/Form/FormSelect';
import { FormControl } from '../../molecules/Form/FormControl';
import { PrimaryButton } from '../../atoms/Button/PrimaryButton';
import { parameterTypeOption, personalInformationProtectionFlagOption } from './SqlTemplateInputTable';
import { SelectOption } from '../../../interfaces/SqlTemplate';

// WARNING: フロントエンドとバックエンドで二重管理になっている
const executionTypeOption: SelectOption[] = [
  { value: '1', text: '1回のみ実行' },
  { value: '2', text: '繰り返し実行' },
];

// WARNING: フロントエンドとバックエンドで二重管理になっている
const repeatType: SelectOption[] = [
  { value: '', text: '選択してください' },
  { value: '1', text: '日' },
  { value: '2', text: '月' },
  { value: '3', text: '火' },
  { value: '4', text: '水' },
  { value: '5', text: '木' },
  { value: '6', text: '金' },
  { value: '7', text: '土' },
  { value: '100', text: '1日' },
  { value: '150', text: '15日' },
  { value: '300', text: '月末日' },
  { value: '999', text: '毎日' },
];

export const SqlQueryInputTable = (props: any) => {
  const {
    tableTitle,
    sqlUpdateDatas,
    register,
    errors,
    control,
    getValues,
    listFields,
    listAppend,
    listRemove,
    addressFields,
    addressAppend,
    addressRemove,
  } = props;

  const FlagSelectBox = (arg: Omit<ControllerProps<any>, 'render'>) => (
    <Controller
      {...arg}
      render={({ field }) => (
        <Form.Group className="mb-4" controlId="formId3">
          <Form.Select
            onChange={(e) => field.onChange(e.target.value === 'ON')}
            defaultValue={getValues(field.name) === true ? 'ON' : 'OFF'}
          >
            {personalInformationProtectionFlagOption.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
    />
  );

  const ExecutionTypeSelectBox = (arg: Omit<ControllerProps<any>, 'render'>) => (
    <Controller
      {...arg}
      render={({ field }) => (
        <>
          <Form.Group className="mb-4" controlId="formId1">
            <Form.Select onChange={(e) => field.onChange(e.target.value)} defaultValue={getValues('execType')}>
              {executionTypeOption.map((option: SelectOption) => (
                <option key={option.value} value={option.value}>
                  {option.text}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          {field.value === '1' ? (
            <DateTimePicker control={control} name="executeTimestamp" />
          ) : (
            <Row className="g-2 mb-4">
              <Col>
                <ExecutionExpectedDateSelectBox control={control} name="repeatType" />
              </Col>
              <Col>
                <Form.Group className="mb-4" controlId="formId4">
                  <Form.Label>時間</Form.Label>
                  <Form.Control
                    type="time"
                    {...register('repeatTime')}
                    className={`form-control ${errors.repeatTime && 'is-invalid'}`}
                  />
                  {errors.repeatTime && (
                    <Form.Control.Feedback type="invalid">{errors.repeatTime.message}</Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
          )}
        </>
      )}
    />
  );

  const ExecutionExpectedDateSelectBox = (arg: Omit<ControllerProps<any>, 'render'>) => (
    <Controller
      {...arg}
      render={({ field }) => (
        <Form.Group className="mb-4" controlId="formId3">
          <Form.Label>日</Form.Label>
          <Form.Select
            onChange={(e) => field.onChange(e.target.value)}
            defaultValue={getValues('repeatType')}
            className={`form-control ${errors.repeatType && 'is-invalid'}`}
          >
            {repeatType.map((option: SelectOption) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </Form.Select>
          {errors.repeatType && (
            <Form.Control.Feedback type="invalid">{errors.repeatType.message}</Form.Control.Feedback>
          )}
        </Form.Group>
      )}
    />
  );

  const DateTimePicker = (arg: Omit<ControllerProps<any>, 'render'>) => (
    <Controller
      {...arg}
      render={({ field }) => (
        <>
          <Form.Group className="mb-4" controlId="formId2">
            <Form.Label>日付/時間</Form.Label>
            <Form.Control
              type="datetime-local"
              className={`form-control ${errors.executionPlanTime && 'is-invalid'}`}
              defaultValue={getValues('executionPlanTime')?.replace(/\s/, 'T')}
              onChange={(e) => field.onChange(e.target.value.replace('T', ' '))}
            />
            {errors.executionPlanTime && (
              <Form.Control.Feedback type="invalid">{errors.executionPlanTime.message}</Form.Control.Feedback>
            )}
          </Form.Group>
        </>
      )}
    />
  );
  return (
    <Table>
      <thead>
        <tr>
          <th>{tableTitle}</th>
        </tr>
      </thead>
      <tbody>
        {sqlUpdateDatas?.queryId ? (
          <tr>
            <th>ID</th>
            <td>{sqlUpdateDatas?.queryId}</td>
          </tr>
        ) : null}
        <tr>
          <th>クエリ名(*)</th>
          <td>
            <ValidationFormControl
              type="text"
              register={register}
              id="queryName"
              registerName="queryName"
              errorsTargetName={errors.queryName}
            />
          </td>
        </tr>
        <tr>
          <th>SQL(*)</th>
          <td>
            <AutoChangeHeightTextArea
              control={control}
              getValues={getValues}
              register={register}
              errorTargetName={errors.sqlString}
            />
          </td>
        </tr>
        <tr>
          <th>パラメータ一覧</th>
          <td>
            <Table>
              <thead>
                <tr>
                  {parameterCellTitle.map((title: any) => (
                    <th key={title}>{title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listFields.map((e: any, index: any) => (
                  <tr key={index}>
                    <td>
                      <ValidationFormControl
                        type="text"
                        register={register}
                        id="parameterDisplayName"
                        registerName={
                          `parameterList.${index}.parameterDisplayName` as 'parameterList.0.parameterDisplayName'
                        }
                        errorsTargetName={errors?.parameterList?.[index]?.parameterDisplayName}
                      />
                    </td>
                    <td>
                      <ValidationFormControl
                        type="text"
                        register={register}
                        id="parameterName"
                        registerName={`parameterList.${index}.parameterName` as 'parameterList.0.parameterName'}
                        errorsTargetName={errors?.parameterList?.[index]?.parameterName}
                      />
                    </td>
                    <td>
                      <FormSelect
                        getValues={getValues}
                        register={register}
                        registerName={`parameterList.${index}.parameterType` as 'parameterList.0.parameterType'}
                        errorsTargetName={errors?.parameterList?.[index]?.parameterType}
                        selectOption={parameterTypeOption}
                      />
                    </td>
                    <td>
                      <FormControl
                        type="text"
                        register={register}
                        id="parameterValue"
                        registerName={`parameterList.${index}.parameterValue` as 'parameterList.0.parameterValue'}
                      />
                    </td>
                    <td className="mb-4">
                      <PrimaryButton onClick={() => listRemove(index)}>削除</PrimaryButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <PrimaryButton onClick={() => listAppend({})}>追加</PrimaryButton>
          </td>
        </tr>
        <tr>
          <th>スキップフラグ</th>
          <td>
            <FlagSelectBox control={control} name="skipFlag" />
          </td>
        </tr>
        <tr>
          <th>有効フラグ</th>
          <td>
            <FlagSelectBox control={control} name="validFlag" />
          </td>
        </tr>
        <tr>
          <th>実行予定時間</th>
          <td>
            <ExecutionTypeSelectBox control={control} name="execType" />
          </td>
        </tr>
        <tr>
          <th>個人情報保護フラグ</th>
          <td>
            <FlagSelectBox control={control} name="personalInformationProtectionFlag" />
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>
            {addressFields.map((e: any, index: any) => (
              <Row key={index} className="g-2 mb-1">
                <Col>
                  <ValidationFormControl
                    type="text"
                    register={register}
                    id="mailaddress"
                    registerName={`mailaddressList.${index}.mailaddress` as 'mailaddressList.0.mailaddress'}
                    errorsTargetName={errors?.mailaddressList?.[index]?.mailaddress}
                  />
                </Col>
                <Col>
                  <PrimaryButton onClick={() => addressRemove(index)}>削除</PrimaryButton>
                </Col>
              </Row>
            ))}
            <PrimaryButton onClick={() => addressAppend({})}>追加</PrimaryButton>
          </td>
        </tr>
        <tr>
          <th>上長承認の証跡URL</th>
          <td>
            <ValidationFormControl
              type="text"
              register={register}
              id="superiorApprovalUrl"
              registerName="superiorApprovalUrl"
              errorsTargetName={errors.superiorApprovalUrl}
            />
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
