import React from 'react';
import { Button, Card, Col, Form, InputGroup, Pagination, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

export const ListSample = () => {
  return (
    <>
      <Row>
        <Col>
          <h3>一覧ページサンプル</h3>
          <Card>
            <Card.Body>
              <Form>
                <InputGroup className="bg-light p-2 mb-4">
                  <Form.Control type="text" placeholder="検索" defaultValue="" />
                  <Button variant="primary">
                    <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
                  </Button>
                </InputGroup>
                <Pagination>
                  <Pagination.First disabled />
                  <Pagination.Prev disabled />
                  <Pagination.Item active>{1}</Pagination.Item>
                  <Pagination.Item>{2}</Pagination.Item>
                  <Pagination.Item>{3}</Pagination.Item>
                  <Pagination.Item>{4}</Pagination.Item>
                  <Pagination.Item>{5}</Pagination.Item>
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="text-secondary">
                    <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                    追加
                  </Button>
                </div>
                <Table>
                  <thead>
                    <tr className="align-middle">
                      <th>企業ID</th>
                      <th>企業名</th>
                      <th>作成日</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                    <tr className="align-middle">
                      <td>890123</td>
                      <td className="col-12">
                        <Button variant="link" href="#" className="d-block text-start">
                          株式会社クライアント名テキスト
                        </Button>
                      </td>
                      <td>2021/06/01</td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
