import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useLargeState } from '../../../../../../hooks/useLargeState';
import { useSafeContext } from '../../../../../../hooks/useSafeContext';
import sass from '../../../../../../scss/templates/CorrectionTemplate.module.scss';
import { CorrectionContext } from '../../../../../../store/correctionStore';
import { debugLog } from '../../../../../../utils/functions';
import { Button } from '../../../../../atoms/Button';
import {
  CorrectionTargetType,
  CorrectionWorkType,
  DisplayPointType,
  TemplateFormBodyState,
  TemplateFormMode,
} from '../../../../../pages/Correction/constants';
import {
  createReceiptOkInitialForm,
  getCommonCommentFormList,
  jumpTo,
  nativeAlert,
  noCorrectionTarget,
  questionPreSend,
  toAt,
} from '../../../../../pages/Correction/utils';
import { CommonBody } from '../../../TemplateForm/bodies/CommonBody';
import { AccordionList } from './AccordionList';
import { EnqueteAnswerListFormResponse } from '../../../../../../api-client';

export const QuestionForm: React.FC = () => {
  const {
    largeState: { state: $, useBindSet },
  } = useSafeContext(CorrectionContext);
  const { state: f, mergeState } = useLargeState<{
    templateFormState?: TemplateFormBodyState;
  }>({});
  const setLoading = useBindSet('isLoading');
  const history = useHistory();
  const onChangeTemplateForm = useCallback(
    (state) => {
      debugLog(state);
      mergeState({ templateFormState: state });
    },
    [mergeState]
  );
  const receiptOk = useMemo(
    () =>
      createReceiptOkInitialForm($.res_receiptInfoDisplay, {
        tell: $.receiptForm_tellCheck,
        date: $.receiptForm_dateCheck,
        receipt: $.receiptForm_receiptCheck,
      }),
    [$.res_receiptInfoDisplay, $.receiptForm_tellCheck, $.receiptForm_dateCheck, $.receiptForm_receiptCheck]
  );

  const getAnswerList = useMemo(() => {
    const freeAnswerList: EnqueteAnswerListFormResponse[] =
      $.commentForms_enqueteText?.map((value) => ({
        answerId: value.dp.targetId ?? NaN,
        commentAnswer: value.content,
        shopReviewDisplayFlg: value.shopReviewDisplayFlg,
        answerChoiseList: [],
      })) ?? [];
    const selectionAnswerList: EnqueteAnswerListFormResponse[] =
      $.selectedForms?.map((value) => ({
        answerId: value.dp.targetId ?? NaN,
        commentAnswer: null,
        answerChoiceList: value.selectionIds,
      })) ?? [];

    const imageAnswerList = $.imageForms?.editImageIds ?? [];
    return [...freeAnswerList, ...selectionAnswerList, ...imageAnswerList];
  }, [$.commentForms_enqueteText, $.selectedForms, $.imageForms]);
  const onConfirm = useCallback(async () => {
    try {
      if (
        !(await questionPreSend(
          () => ({
            common: {
              applyId: $.applyId,
              correctionUrl: document.location.href,
              escalationList: [
                {
                  displayPoint: {
                    typeCode: DisplayPointType.FOR_ALL,
                    targetId: null,
                  },
                  escalationTemplateId: f.templateFormState?.templateId ?? NaN,
                  inquiry: f.templateFormState?.content ?? '',
                },
                ...getCommonCommentFormList(TemplateFormMode.ESCALE, $).map(
                  ({ dp, tempId: escalationTemplateId, content: inquiry }) => ({
                    displayPoint: { typeCode: dp.type ?? NaN, targetId: dp.targetId },
                    escalationTemplateId,
                    inquiry,
                  })
                ),
              ],
              correctionTargetTypeCode: $.isEnquete ? CorrectionTargetType.ENQUETE : CorrectionTargetType.RECEIPT,
              correctionNgComments: getCommonCommentFormList(TemplateFormMode.NG, $).map(
                ({ dp, tempId: templateId, content: ngMessage }) => ({
                  displayPoint: { typeCode: dp.type ?? NaN, targetId: dp.targetId },
                  templateId,
                  ngMessage,
                })
              ),
              modifiedAnswers: getAnswerList,
            },
            receiptInfo: {
              noPurchaseTimeFlag: receiptOk.noPurchaseTimeFlag,
              purchaseAt: toAt(receiptOk.purchaseDate, receiptOk.noPurchaseTimeFlag, receiptOk.purchaseTime),
              noReceiptNoFlag: receiptOk.noReceiptNoFlag,
              receiptNo: receiptOk.receiptNo,
            },
          }),
          async ({ common, receiptInfo }) => {
            if (common.escalationList.find((x) => x.escalationTemplateId === undefined)) {
              nativeAlert('テンプレートIDが設定されていない項目があります');
            }
            if (!$.isEnquete) {
              await $.api_correctReceipt.receiptCorrectionEscalationExecute({
                ...common,
                receiptInfo,
              });
            } else {
              await $.api_correctEnquete.enqueteCorrectionEscalationExecute({
                ...common,
              });
            }
          },
          undefined,
          setLoading
        ))
      )
        return;
      $.api_correct
        .normalCorrectTargetDistribute({
          beforeApplyId: $.applyId,
          workTypeCode: CorrectionWorkType.ESCALATION,
        })
        .then(({ data }) => jumpTo(history, data))
        .catch(() => noCorrectionTarget(history));
    } catch (error) {
      console.error(error);
      nativeAlert('エラーが発生しました');
    }
  }, [
    $,
    f.templateFormState?.content,
    f.templateFormState?.templateId,
    history,
    setLoading,
    receiptOk.noPurchaseTimeFlag,
    receiptOk.noReceiptNoFlag,
    receiptOk.purchaseDate,
    receiptOk.purchaseTime,
    receiptOk.receiptNo,
  ]);
  return (
    <>
      <AccordionList mode={TemplateFormMode.ESCALE} />
      <CommonBody
        // mode={TemplateFormMode.ESCALE}
        name="エスカレ"
        templateList={$.res_escalationTemplateList ?? []}
        onChange={onChangeTemplateForm}
      />
      <Button className={sass.btn_gray} onClick={onConfirm}>
        <FontAwesomeIcon icon={faPlus} /> エスカレ実行
      </Button>
    </>
  );
};
