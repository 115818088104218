import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Alert, Button, Card, Form, Modal } from 'react-bootstrap';
import {
  MonitorApi,
  MonitorListOutputResponse,
  MonitorInfoApi,
  MonitorInfoOutputResponse,
  PostingRsvUpsertFormResponse,
} from '../../../api-client';
import { DispatchSetState, NestedPartial } from '../../../interfaces/utils';
import { Title } from '../../atoms/Title';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { useLargeState } from '../../../hooks/useLargeState';
import { MonitorBaseModifyMonitorListTable } from '../Table/MonitorBaseModifyMonitorListTable';
import { State } from '../../pages/Monitor/MonitorBaseModifyPage';

export interface Props {
  isModal: boolean;
  setIsModal: DispatchSetState<boolean>;
  state: State;
  mergeState: (value: Partial<State>) => void;
  fState: NestedPartial<PostingRsvUpsertFormResponse>;
  fMergeState: (value: Partial<NestedPartial<PostingRsvUpsertFormResponse>>) => void;
}

export interface MonitorModalState {
  monitorIdName: string | undefined;
  shopIdName: string | undefined;
  clientIdName: string | undefined;
  monitorBaseId: number | undefined;
  fromDate: string | undefined;
  toDate: string | undefined;
  list: MonitorListOutputResponse[];
  listPerPage: MonitorListOutputResponse[];
  currentPage: number;
  totalPage: number;
}

export const MonitorListModal: React.VFC<Props> = ({ isModal, setIsModal, state, mergeState, fState, fMergeState }) => {
  //  ページ関連変数
  const MAX_RECORD_PER_PAGE = 20;

  const monitorApi = new MonitorApi();
  const monitorInfoApi = new MonitorInfoApi();

  const [selectedMonitorId, setSelectedMonitorId] = useState<number>();
  const [selectedFlg, setSelectedFlg] = useState<boolean>(false);

  const initialState: MonitorModalState = {
    monitorIdName: undefined,
    shopIdName: undefined,
    clientIdName: undefined,
    monitorBaseId: undefined,
    fromDate: undefined,
    toDate: undefined,
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  };

  const { outputs } = state;
  const { state: $, mergeState: mMergeState } = useLargeState<MonitorModalState>(initialState);

  useEffect(() => {
    monitorApi
      .monitorList($.monitorIdName, $.shopIdName, $.clientIdName, $.monitorBaseId, $.fromDate, $.toDate)
      .then((res: AxiosResponse<MonitorListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
  }, [isModal]);

  useEffect(() => {
    mMergeState({
      listPerPage: $.list?.slice(($.currentPage - 1) * MAX_RECORD_PER_PAGE, $.currentPage * MAX_RECORD_PER_PAGE),
      totalPage: Math.ceil(Number($.list.length) / MAX_RECORD_PER_PAGE),
    });
  }, [$.list, $.currentPage]);

  const setPagingStates = (list: MonitorListOutputResponse[]) => {
    // 期間の開始日の降順でソート
    const monitors = list
      .sort((a, b) => a.monitorId - b.monitorId)
      .sort(
        (a, b) =>
          Number(new Date(b.period.slice(0, b.period.indexOf('～')))) -
          Number(new Date(a.period.slice(0, a.period.indexOf('～'))))
      );

    mMergeState({
      list: monitors,
      listPerPage: monitors.slice(0, MAX_RECORD_PER_PAGE),
      totalPage: Math.ceil(Number(monitors?.length) / MAX_RECORD_PER_PAGE),
      currentPage: 1,
    });
  };

  // 検索ボタンイベント
  const onSearch = async (e: any) => {
    setSelectedMonitorId(undefined);
    setSelectedFlg(false);
    e.preventDefault();
    monitorApi
      .monitorList($.monitorIdName, $.shopIdName, $.clientIdName, $.monitorBaseId, $.fromDate, $.toDate)
      .then((res: AxiosResponse<MonitorListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
  };

  // コピーボタンイベント
  const handleOnKeep = async (e: any) => {
    if (!selectedMonitorId) {
      setSelectedFlg(true);
      return;
    }

    monitorInfoApi.monitorInfo(selectedMonitorId).then((res: AxiosResponse<MonitorInfoOutputResponse>) => {
      const {
        id: monitorId,
        monitorBaseId,
        preEnqueteName,
        postEnqueteName,
        ruleSetName,
        startAt,
        monitorTargetMonth,
        contractBorderCount,
        apiClients,
        mainImageId,
        mainImageUrl,
        subImages,
        freeSpaceImageId,
        freeSpaceImageUrl,
        categoryId,
        businessCategoryName,
        incidental,
        winningSeparatingSetting,
        ...rest
      } = res.data;

      mergeState({
        categoryId,
        outputs: {
          ...outputs,
          preEnqueteName,
          postEnqueteName,
          ruleSetName,
          monitorTargetMonth: { ...outputs.monitorTargetMonth },
          contractBorderCount: outputs.contractBorderCount,
          businessCategoryName,
        },
        apiClients,
        imageData: {
          main: {
            isDefault: !!mainImageId,
            id: mainImageId,
            path: mainImageUrl,
          },
          sub: subImages
            ? subImages?.map(({ id, url }) => {
                const list = {
                  isDefault: !!id,
                  id,
                  path: url,
                };
                return list;
              })
            : [],
          free: {
            isDefault: !!freeSpaceImageId,
            id: freeSpaceImageId,
            path: freeSpaceImageUrl,
          },
        },
      });

      fMergeState({
        ...rest,
        userRewardId: undefined,
        userRewardType: fState.userRewardType,
        userRewardValue: fState.userRewardValue,
        userRewardUpperLimit: fState.userRewardUpperLimit,
        incidental: { ...incidental, id: fState.incidental?.id },
        winningSeparatingSetting: { ...winningSeparatingSetting, id: fState.winningSeparatingSetting?.id },
        applicationStartDate: startAt,
        winningBorderCount: fState.winningBorderCount,
        borderCountAutoReleaseFlg: fState.borderCountAutoReleaseFlg,
      });
      onHide();
    });
  };

  // キャンセルまたはモーダルを閉じた場合のイベント
  const onHide = () => {
    setIsModal(false);
    setSelectedMonitorId(undefined);
    setSelectedFlg(false);
    mMergeState({
      monitorIdName: undefined,
      shopIdName: undefined,
      clientIdName: undefined,
      fromDate: undefined,
      toDate: undefined,
    });
  };

  return (
    <>
      <Modal size="xl" show={isModal} onHide={onHide} scrollable centered>
        <Modal.Header closeButton>
          <Title className="mb-0">モニター一覧</Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end mb-2">
            <div>
              <Button className="me-2" variant="link" onClick={onHide}>
                <p>キャンセル</p>
              </Button>
            </div>
            <div>
              <Button variant="primary" onClick={handleOnKeep}>
                コピー
              </Button>
            </div>
          </div>
          {selectedFlg && (
            <Alert variant="danger" style={{ marginTop: 10 }}>
              モニターが選択されていません。
            </Alert>
          )}
          <Form onSubmit={onSearch}>
            <Card className="mb-4 mt-4">
              <div className="m-4 me-3 ms-3">
                <div className="d-flex" style={{ width: '100%', height: '100%' }}>
                  <div className="form-floating me-2" style={{ width: '33%' }}>
                    <input
                      type="text"
                      className="form-control"
                      id="monitorNameOrId"
                      placeholder="入力してください。"
                      onChange={(e) => mMergeState({ monitorIdName: e.target.value || undefined })}
                      value={$.monitorIdName || ''}
                      data-testid="monitorNameOrId"
                    />
                    <label htmlFor="monitorNameOrId">モニター名/ID</label>
                  </div>
                  <div className="form-floating me-2" style={{ width: '33%' }}>
                    <input
                      type="text"
                      className="form-control"
                      id="shopNameOrId"
                      placeholder="入力してください。"
                      onChange={(e) => mMergeState({ shopIdName: e.target.value || undefined })}
                      value={$.shopIdName || ''}
                      data-testid="shopNameOrId"
                    />
                    <label htmlFor="shopNameOrId">実店舗名/ID</label>
                  </div>
                  <div className="form-floating" style={{ width: '34%' }}>
                    <input
                      type="text"
                      className="form-control"
                      id="clientNameOrId"
                      placeholder="入力してください。"
                      onChange={(e) => mMergeState({ clientIdName: e.target.value || undefined })}
                      value={$.clientIdName || ''}
                      data-testid="clientNameOrId"
                    />
                    <label htmlFor="clientNameOrId">クライアント名/ID</label>
                  </div>
                </div>
                <div className="d-flex mt-2" style={{ width: '100%', height: '100%' }}>
                  <div className="form-floating me-2" style={{ width: '25%' }}>
                    <input
                      type="text"
                      className="form-control"
                      id="monitorBaseId"
                      placeholder="入力してください。"
                      onChange={(e) => mMergeState({ monitorBaseId: Number(e.target.value) || undefined })}
                      value={$.monitorBaseId || ''}
                      data-testid="monitorBaseId"
                    />
                    <label htmlFor="monitorBaseId">モニターベースID</label>
                  </div>
                  <div className="form-floating me-2" style={{ width: '30%' }}>
                    <input
                      type="date"
                      className="form-control"
                      id="fromDate"
                      placeholder="入力してください。"
                      onChange={(e) => mMergeState({ fromDate: e.target.value ? e.target.value : undefined })}
                      value={$.fromDate ? $.fromDate.replace(/\//g, '-') : ''}
                      data-testid="fromDate"
                    />
                    <label htmlFor="fromDate">モニター開始日</label>
                  </div>
                  <div className="form-floating me-2" style={{ width: '30%' }}>
                    <input
                      type="date"
                      className="form-control"
                      id="toDate"
                      placeholder="入力してください。"
                      onChange={(e) => mMergeState({ toDate: e.target.value ? e.target.value : undefined })}
                      value={$.toDate ? $.toDate.replace(/\//g, '-') : ''}
                      data-testid="toDate"
                    />
                    <label htmlFor="toDate">モニター終了日</label>
                  </div>
                  <div style={{ textAlign: 'center', width: '15%' }}>
                    <Button type="submit" style={{ width: '100%', height: '100%' }}>
                      検索
                    </Button>
                  </div>
                </div>
              </div>
            </Card>

            <PaginationWithEllipsis
              data-testid="page"
              currentPage={$.currentPage}
              totalPage={$.totalPage}
              handleClick={(page) => {
                if (!page || page > $.totalPage) return;
                mMergeState({
                  listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
                  currentPage: page,
                });
              }}
            />
            <MonitorBaseModifyMonitorListTable
              listPerPage={$.listPerPage}
              selectedMonitorId={selectedMonitorId}
              setSelectedMonitorId={setSelectedMonitorId}
            />
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
