import React from 'react';
import { Button, Card, Col, Form, InputGroup, Pagination, Row, Tabs, Tab, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';

export const SelectList = () => {
  return (
    <>
      <Row>
        <Col>
          <Card className="mb-3">
            <Card.Body>
              <h4>複数選択Listパターン</h4>
              <Form>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="ms-2">
                    キャンセル
                  </Button>
                  <Button variant="primary" className="ms-2">
                    保存
                  </Button>
                </div>
                <div className="mb-3">
                  <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-2">
                    <Tab eventKey="1" title="一覧">
                      <InputGroup className="bg-light p-2 mb-4">
                        <Form.Control type="text" placeholder="検索" defaultValue="" />
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
                        </Button>
                      </InputGroup>
                      <Pagination>
                        <Pagination.First disabled />
                        <Pagination.Prev disabled />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Item>{4}</Pagination.Item>
                        <Pagination.Item>{5}</Pagination.Item>
                        <Pagination.Next />
                        <Pagination.Last />
                      </Pagination>
                      <div className="d-flex justify-content-end mb-4">
                        <Button variant="link" className="text-secondary">
                          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                          追加
                        </Button>
                      </div>
                      <ListGroup>
                        <ListGroup.Item>
                          <Form.Check id="checkBox1" className="d-flex">
                            <Form.Check.Input
                              className="flex-shrink-0 me-2"
                              type="checkbox"
                              name="checkBoxGroup01"
                              defaultChecked
                            />
                            <Form.Check.Label className="w-100">クライアント1</Form.Check.Label>
                          </Form.Check>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Form.Check id="checkBox2" className="d-flex">
                            <Form.Check.Input
                              className="flex-shrink-0 me-2"
                              type="checkbox"
                              name="checkBoxGroup01"
                              defaultChecked
                            />
                            <Form.Check.Label>クライアント2</Form.Check.Label>
                          </Form.Check>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Form.Check id="checkBox3" className="d-flex">
                            <Form.Check.Input className="flex-shrink-0 me-2" type="checkbox" name="checkBoxGroup01" />
                            <Form.Check.Label>クライアント3</Form.Check.Label>
                          </Form.Check>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Form.Check id="checkBox4" className="d-flex">
                            <Form.Check.Input className="flex-shrink-0 me-2" type="checkbox" name="checkBoxGroup01" />
                            <Form.Check.Label>クライアント4</Form.Check.Label>
                          </Form.Check>
                        </ListGroup.Item>
                      </ListGroup>
                    </Tab>
                    <Tab eventKey="2" title="選択中">
                      <InputGroup className="bg-light p-2 mb-4">
                        <Form.Control type="text" placeholder="検索" defaultValue="" />
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
                        </Button>
                      </InputGroup>
                      <Pagination>
                        <Pagination.First disabled />
                        <Pagination.Prev disabled />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Item>{4}</Pagination.Item>
                        <Pagination.Item>{5}</Pagination.Item>
                        <Pagination.Next />
                        <Pagination.Last />
                      </Pagination>
                      <ListGroup>
                        <ListGroup.Item>
                          <Form.Check id="selectedCheckBox1" className="d-flex">
                            <Form.Check.Input
                              className="flex-shrink-0 me-2"
                              type="checkbox"
                              name="checkBoxGroup01"
                              defaultChecked
                            />
                            <Form.Check.Label className="w-100">クライアント1</Form.Check.Label>
                          </Form.Check>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <Form.Check id="selectedCheckBox2" className="d-flex">
                            <Form.Check.Input
                              className="flex-shrink-0 me-2"
                              type="checkbox"
                              name="checkBoxGroup01"
                              defaultChecked
                            />
                            <Form.Check.Label>クライアント2</Form.Check.Label>
                          </Form.Check>
                        </ListGroup.Item>
                      </ListGroup>
                    </Tab>
                  </Tabs>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
