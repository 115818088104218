/* eslint-disable import/no-cycle */
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { ApplyInfoOutputResponse, ApplyStatusListOutputResponse } from '../../../../api-client';
import { Url } from '../../../../constants/Url';
import { createTestId, formatISODate } from '../../../../utils/functions';
import { Button } from '../../../atoms/Button';

export interface Props {
  applyInfo: ApplyInfoOutputResponse;
  applyStatusList: ApplyStatusListOutputResponse[];
}

const ApplyStatusCard: React.FC<Props> = ({ applyInfo, applyStatusList }) => {
  const testid = createTestId(ApplyStatusCard);
  const history = useHistory();

  const { applyId } = useParams<{ applyId: string | undefined }>();

  return (
    <Card className="mb-4" data-testid={testid('applyStatusCard')}>
      <Card.Body>
        <Form.Group className="mb-4">
          <div className="fs-5 p-1 fw-bold">応募ステータス</div>
          <div className="bg-light p-3">
            <Form.Label>応募ステータス</Form.Label>
            <div>{applyStatusList?.find((status) => status.code === applyInfo?.applyStatus)?.name}</div>
            <div className="text-right">
              <Button
                className="text-right"
                onClick={() => {
                  history.push(`${Url.TENSAKU.EXTEND_DEADLINE_REGISTER}/${applyId}`);
                }}
              >
                期限延長・当選復帰
              </Button>
            </div>
          </div>
          <Form.Label className="pt-2">応募日時</Form.Label>
          <p className="fw-bold">{applyInfo?.applyAt && formatISODate(applyInfo?.applyAt, 'yyyy/MM/dd HH:mm:ss')}</p>
          <Form.Label>当選日時</Form.Label>
          <p className="fw-bold">
            {applyInfo?.winningAt && formatISODate(applyInfo?.winningAt, 'yyyy/MM/dd HH:mm:ss')}
          </p>
          <Form.Label>提出期限</Form.Label>
          <p className="fw-bold">
            {applyInfo?.deadlineAt && formatISODate(applyInfo?.deadlineAt, 'yyyy/MM/dd HH:mm:ss')}
          </p>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default ApplyStatusCard;
