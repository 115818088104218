/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BulkRejectTargetApplyFormResponse } from '../domains';
// @ts-ignore
import { BulkRejectTargetApplyListFormResponse } from '../domains';
// @ts-ignore
import { BulkRejectTargetApplyListOutputResponse } from '../domains';
// @ts-ignore
import { ResultOutputResponse } from '../domains';
/**
 * BulkRejectApi - axios parameter creator
 * @export
 */
export const BulkRejectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 一括モニター無効の実行
         * @summary 一括モニター無効　実行
         * @param {BulkRejectTargetApplyFormResponse} bulkRejectTargetApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRejectTargetApply: async (bulkRejectTargetApplyFormResponse: BulkRejectTargetApplyFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkRejectTargetApplyFormResponse' is not null or undefined
            assertParamExists('bulkRejectTargetApply', 'bulkRejectTargetApplyFormResponse', bulkRejectTargetApplyFormResponse)
            const localVarPath = `/api/bulk-rejected`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkRejectTargetApplyFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 一括モニター無効の対象応募取得
         * @summary 一括モニター無効　対象応募取得
         * @param {BulkRejectTargetApplyListFormResponse} bulkRejectTargetApplyListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRejectTargetApplyList: async (bulkRejectTargetApplyListFormResponse: BulkRejectTargetApplyListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkRejectTargetApplyListFormResponse' is not null or undefined
            assertParamExists('bulkRejectTargetApplyList', 'bulkRejectTargetApplyListFormResponse', bulkRejectTargetApplyListFormResponse)
            const localVarPath = `/api/bulk-rejected/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkRejectTargetApplyListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BulkRejectApi - functional programming interface
 * @export
 */
export const BulkRejectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BulkRejectApiAxiosParamCreator(configuration)
    return {
        /**
         * 一括モニター無効の実行
         * @summary 一括モニター無効　実行
         * @param {BulkRejectTargetApplyFormResponse} bulkRejectTargetApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkRejectTargetApply(bulkRejectTargetApplyFormResponse: BulkRejectTargetApplyFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkRejectTargetApply(bulkRejectTargetApplyFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 一括モニター無効の対象応募取得
         * @summary 一括モニター無効　対象応募取得
         * @param {BulkRejectTargetApplyListFormResponse} bulkRejectTargetApplyListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkRejectTargetApplyList(bulkRejectTargetApplyListFormResponse: BulkRejectTargetApplyListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BulkRejectTargetApplyListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkRejectTargetApplyList(bulkRejectTargetApplyListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BulkRejectApi - factory interface
 * @export
 */
export const BulkRejectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BulkRejectApiFp(configuration)
    return {
        /**
         * 一括モニター無効の実行
         * @summary 一括モニター無効　実行
         * @param {BulkRejectTargetApplyFormResponse} bulkRejectTargetApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRejectTargetApply(bulkRejectTargetApplyFormResponse: BulkRejectTargetApplyFormResponse, options?: any): AxiosPromise<ResultOutputResponse> {
            return localVarFp.bulkRejectTargetApply(bulkRejectTargetApplyFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 一括モニター無効の対象応募取得
         * @summary 一括モニター無効　対象応募取得
         * @param {BulkRejectTargetApplyListFormResponse} bulkRejectTargetApplyListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkRejectTargetApplyList(bulkRejectTargetApplyListFormResponse: BulkRejectTargetApplyListFormResponse, options?: any): AxiosPromise<Array<BulkRejectTargetApplyListOutputResponse>> {
            return localVarFp.bulkRejectTargetApplyList(bulkRejectTargetApplyListFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BulkRejectApi - object-oriented interface
 * @export
 * @class BulkRejectApi
 * @extends {BaseAPI}
 */
export class BulkRejectApi extends BaseAPI {
    /**
     * 一括モニター無効の実行
     * @summary 一括モニター無効　実行
     * @param {BulkRejectTargetApplyFormResponse} bulkRejectTargetApplyFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkRejectApi
     */
    public bulkRejectTargetApply(bulkRejectTargetApplyFormResponse: BulkRejectTargetApplyFormResponse, options?: AxiosRequestConfig) {
        return BulkRejectApiFp(this.configuration).bulkRejectTargetApply(bulkRejectTargetApplyFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 一括モニター無効の対象応募取得
     * @summary 一括モニター無効　対象応募取得
     * @param {BulkRejectTargetApplyListFormResponse} bulkRejectTargetApplyListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BulkRejectApi
     */
    public bulkRejectTargetApplyList(bulkRejectTargetApplyListFormResponse: BulkRejectTargetApplyListFormResponse, options?: AxiosRequestConfig) {
        return BulkRejectApiFp(this.configuration).bulkRejectTargetApplyList(bulkRejectTargetApplyListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
