import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { ReceiptPriceModifyHistoryTable } from '../../Table/ReceiptPriceModifyHistoryTable';

interface Props {
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ReceiptPriceModifyHistoryModal: React.FC<Props> = ({ isModal, setIsModal }) => {
  const handleClose = () => {
    setIsModal(false);
  };
  return (
    <>
      <Modal show={isModal} size="xl" responsive>
        <Modal.Header>金額修正履歴</Modal.Header>
        <Modal.Body>
          <ReceiptPriceModifyHistoryTable />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
