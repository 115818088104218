import React, { useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Table } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
// eslint-disable-next-line import/no-cycle
import { ClientListApi, ClientListOutputResponse } from '../../../api-client';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { RecommendSearchBar } from '../../molecules/RecommendSearchBar';
import { createTestId } from '../../../utils/functions';

const getSampleData = (num: number) => {
  return {
    clientList: [...Array(num)].map((_, i) => ({
      clientId: i + 1,
      clientName: `クライアント名${i + 1}`,
      roiMemberName: `担当者${i + 1}`,
    })),
    allClientList: [...Array(10)].map((_, i) => ({
      clientId: i + 1,
      clientName: `クライアント名${i + 1}`,
    })),
  };
};

export interface Props {
  isModal: boolean;
  onSelect: (id: number, name: string) => void;
  onHide: () => void;
}

export type State = {
  api: ClientListApi;
  searchText: string;
  clientId?: number;
  clientName: string;
  allClientList: ClientListOutputResponse['allClientList'];
  list: ClientListOutputResponse['clientList'];
  listPerPage: ClientListOutputResponse['clientList'];
  currentPage: number;
  totalPage: number;
};

const MAX_RECORD_PER_PAGE = 100;

export const ClientListModal: React.VFC<Props> = ({ isModal, onSelect, onHide }) => {
  const testid = createTestId(ClientListModal);

  const {
    state: $,
    mergeState,
    onChangeSet,
  } = useLargeState<State>({
    api: new ClientListApi(),
    searchText: '',
    clientName: '',
    allClientList: [],
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  });

  const setPagingStates = useCallback(
    (list: ClientListOutputResponse['clientList']) => {
      const sortList = list.sort((a, b) => b.clientId - a.clientId);
      mergeState({
        list: sortList,
        listPerPage: sortList.slice(0, MAX_RECORD_PER_PAGE),
        totalPage: Math.ceil(Number(sortList?.length) / MAX_RECORD_PER_PAGE),
        currentPage: 1,
      });
    },
    [mergeState]
  );

  useEffect(() => {
    if (!isModal) return;
    $.api.clientList().then((res: AxiosResponse<ClientListOutputResponse>) => {
      const { clientList, allClientList } = res.data;
      mergeState({ allClientList });
      setPagingStates(clientList);
    });
  }, [isModal, $.api, mergeState, setPagingStates]);

  const onSearch = () => {
    $.api.clientList($.searchText).then((res: AxiosResponse<ClientListOutputResponse>) => {
      const { clientList } = res.data;
      setPagingStates(clientList);
    });
  };

  return (
    <Modal
      closeButton
      centered
      scrollable
      isModal={isModal}
      onHide={onHide}
      size="lg"
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button variant="link" className="ms-2" onClick={onHide} data-testid={testid('cancel-button')}>
              キャンセル
            </Button>
            <Button
              className="ms-2"
              data-testid={testid('select-button')}
              onClick={() => onSelect($.clientId as number, $.clientName)}
              disabled={!$.clientId}
            >
              決定
            </Button>
          </div>

          <RecommendSearchBar
            value={$.searchText}
            options={$.allClientList.map(({ clientName }) => clientName)}
            onSearch={onSearch}
            onChange={onChangeSet('searchText')}
            onClickItem={(e) => mergeState({ searchText: e.currentTarget.innerHTML })}
          />

          <Table data-testid={testid('table')}>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>ID</th>
                <th>クライアント名</th>
                <th>営業担当者</th>
                <th>説明</th>
              </tr>
            </thead>
            <tbody>
              {$.listPerPage?.map(({ clientId, clientName, salesList, description }, i) => (
                <tr key={clientId} className="align-middle">
                  <td>
                    <Form.Check.Input
                      type="radio"
                      name="radioGroup01"
                      data-testid={testid('radio', i)}
                      checked={$.clientId === clientId}
                      onChange={() =>
                        mergeState({
                          clientId,
                          clientName: $.allClientList.find((c) => c.clientId === clientId)?.clientName || '',
                        })
                      }
                    />
                  </td>
                  <td>{clientId}</td>
                  <td>{clientName}</td>
                  <td>{salesList.join(',')}</td>
                  <td>{description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      }
    />
  );
};
