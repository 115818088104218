import React, { useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from '../../../atoms/Button';
import { DispatchSetState } from '../../../../interfaces/utils';
import { Modal } from '../../../molecules/Modal';
import type { ModalState as ParentState } from '../../../pages/Customer/CustomerAuthHistoryPage';
import { createTestId } from '../../../../utils/functions';
import { ManualAuthenticationModalForms } from '../../Forms/ManualAuthenticationModalForms';
import { useLargeState } from '../../../../hooks/useLargeState';
import type { SmsAuthManualSendOutputResponse } from '../../../../api-client';
import { CustomerInfoApi } from '../../../../api-client';
import { CustomerAuthHistoryState } from '../../../pages/Customer/CustomerAuthHistoryPage';

interface Props {
  isModal: boolean;
  forms: ParentState['forms'];
  setForms: DispatchSetState<ParentState['forms']>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide: () => any;
  submit: (body: ParentState['forms']) => Promise<{ data: SmsAuthManualSendOutputResponse; errorMessage: string }>;
}

interface ModalState {
  resData: SmsAuthManualSendOutputResponse;
  isResultModal: boolean;
  modalMessage: string;
  clickFunc: () => void;
}

export const ManualAuthenticationModal: React.FC<Props> = ({ isModal, onHide, forms, setForms, submit }) => {
  const testid = createTestId(ManualAuthenticationModal);

  const { state: $formData, useBindSet } = useLargeState<ParentState['forms']>(forms);

  const { state: $ } = useLargeState<CustomerAuthHistoryState>({
    api: new CustomerInfoApi(),
    isLoading: false,
    list: [],
    errorMessage: [],
  });

  const setFormData = useBindSet('phoneNumber');
  useEffect(() => setForms($formData), [setForms, $formData]);

  const { state: $modal, mergeState } = useLargeState<ModalState>({
    resData: { codeValue: '', smsDeadlineAt: '' },
    isResultModal: false,
    modalMessage: '',
    clickFunc: onHide,
  });

  const onClickSubmit = useCallback(() => {
    $.api
      .phoneNumberConfirm({ phoneNumber: forms.phoneNumber ?? '' })
      .then((res) => {
        return res.data;
      })
      .then(({ result, errorMessage }) => {
        if (result) {
          submit(forms).then((res) => {
            mergeState({ isResultModal: true, resData: res.data, modalMessage: '' });
          });
        } else {
          mergeState({ isResultModal: true, modalMessage: errorMessage });
        }
      });
  }, [$, forms, mergeState, submit]);

  return (
    <>
      <Modal
        closeButton
        centered
        scrollable
        size="lg"
        isModal={isModal}
        onHide={onHide}
        body={
          <>
            <Form>
              <ManualAuthenticationModalForms
                forms={forms}
                setFormData={setFormData}
                data={$modal.resData}
                errorMessage={$modal.modalMessage}
              />
            </Form>
            <Button
              className="mt-2"
              data-testid={testid('submit-button')}
              disabled={!$formData.phoneNumber}
              onClick={() => onClickSubmit()}
            >
              SMS認証URL生成
            </Button>
          </>
        }
      />
    </>
  );
};
