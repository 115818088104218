import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Card, Form, FloatingLabel, FormControl, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faGripLines, faTimesCircle, faAngleUp, faLink, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Button } from '../atoms/Button';
import { MonitorRuleSetSurveyDateContents } from './MonitorRuleSetSurveyDateContents';
import { MonitorRuleSetTimeContents } from './MonitorRuleSetTimeContents';
import { MonitorRuleSetNumberPeopleContents } from './MonitorRuleSetNumberPeopleContents';
import { MonitorRuleSetReserveContents } from './MonitorRuleSetReserveContents';
import { MonitorRuleSetPaymentMethodContents } from './MonitorRuleSetPaymentMethodContents';
import { MonitorRuleSetSpecifyItemContents } from './MonitorRuleSetSpecifyItemContents';
import { MonitorRuleSetCouponContents } from './MonitorRuleSetCouponContents';
import { MonitorRuleSetSurveyProofContents } from './MonitorRuleSetSurveyProofContents';
import { MonitorRuleSetPhotoShotGourmetContents } from './MonitorRuleSetPhotoShotGourmetContents';
import { MonitorRuleSetApplicationConditionContents } from './MonitorRuleSetApplicationConditionContents';
import { MonitorRuleSetPurchaseShopContents } from './MonitorRuleSetPurchaseShopContents';
import { MonitorRuleSetSpecifyMenuCourseContents } from './MonitorRuleSetSpecifyMenuCourseContents';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';
import { MonitorRuleSetPhotoShotOtherContents } from './MonitorRuleSetPhotoShotOtherContents';
import { MonitorRuleSetOrderConditionContents } from './MonitorRuleSetOrderConditionContents';
import { MonitorRuleSetInfoMonitorRuleOutputResponse, MonitorRuleSetInfoOutputResponse } from '../../api-client';
import { ImageProp } from '../pages/MonitorRule/MonitorRuleSetModifyPage';
import { MonitorRuleSetNotesContents } from './MonitorRuleSetNotesContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse & ImageProp>>;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

export const monitorRuleTypeName = (monitorRuleType: number) => {
  return (
    <>
      {monitorRuleType === 2 && '調査日'}
      {monitorRuleType === 3 && '時間'}
      {monitorRuleType === 4 && '人数・同伴者'}
      {monitorRuleType === 5 && '予約'}
      {monitorRuleType === 6 && '指定商品'}
      {monitorRuleType === 7 && '写真撮影'}
      {monitorRuleType === 8 && '店員調査'}
      {monitorRuleType === 9 && 'トイレ調査'}
      {monitorRuleType === 10 && '清掃状況調査'}
      {monitorRuleType === 11 && 'その他調査'}
      {monitorRuleType === 12 && '支払方法'}
      {monitorRuleType === 13 && 'クーポン・割引'}
      {monitorRuleType === 14 && '調査証明'}
      {monitorRuleType === 15 && '特記事項'}
      {monitorRuleType === 16 && '注意事項'}
      {monitorRuleType === 17 && '応募条件'}
      {monitorRuleType === 18 && '注文方法'}
      {monitorRuleType === 19 && '対象ブランド'}
      {monitorRuleType === 20 && '申込方法'}
      {monitorRuleType === 21 && '外観調査'}
      {monitorRuleType === 22 && '購入店舗'}
      {monitorRuleType === 23 && '指定メニュー・コース'}
      {monitorRuleType === 99 && 'その他'}
    </>
  );
};

export const MonitorRuleCard: React.VFC<Props> = ({
  data,
  setData,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  useEffect(() => {}, []);

  const handleOnDragEnd = (result: DropResult) => {
    if (result.destination === undefined || data === undefined) return;

    const items: MonitorRuleSetInfoMonitorRuleOutputResponse[] = Array.from(data?.monitorRuleList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    // 動かそうとしたContentsの順序を上書きする。
    reorderedItem.index = result.destination!.index;
    items.splice(result.destination!.index, 0, reorderedItem);
    setData({ ...data, monitorRuleList: items });
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Card className="mb-4">
        <Card.Body>
          <h4 className="mb-4">モニタールール</h4>

          <div className="d-flex justify-content-end mb-2">
            <Button
              data-testid="monitorRuleAddButton"
              variant="link"
              className="text-secondary"
              onClick={() => {
                const copyMonitorRuleSetList: MonitorRuleSetInfoMonitorRuleOutputResponse[] = Object.assign(
                  [],
                  data?.monitorRuleList
                );
                copyMonitorRuleSetList.push({
                  monitorRuleType: 99,
                  monitorRuleId: undefined,
                  imageURL: undefined,
                  monitorRuleName: '',
                  iconType: 1,
                  disableFlg: false,
                  imageRuleList: [],
                  index: copyMonitorRuleSetList[copyMonitorRuleSetList.length - 1].index + 1,
                  ngItemList: [],
                  targetItemList: [],
                  visitTimeSetList: [],
                  afterApplyContent: undefined,
                  beforeApplyContent: undefined,
                  supplementList: [
                    {
                      id: undefined,
                      supplement: '',
                    },
                  ],
                  orderMethodPhoneNumberFlg: false,
                  orderMethodUrlFlg: false,
                });
                setData({
                  ...data,
                  monitorRuleList: copyMonitorRuleSetList,
                });
              }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              追加
            </Button>
          </div>

          <Droppable droppableId="MonitorRuleCard" type="MonitorRuleCard">
            {(droppableProvided) => (
              <ul
                style={{ listStyle: 'none' }}
                className="ps-0"
                {...droppableProvided.droppableProps}
                ref={droppableProvided.innerRef}
              >
                {data?.monitorRuleList.map((item: MonitorRuleSetInfoMonitorRuleOutputResponse, idx) => {
                  return (
                    // <Accordion defaultActiveKey={idx.toString()}>
                    <Accordion key={idx.toString()} defaultActiveKey={idx.toString()}>
                      <Accordion.Item eventKey={idx.toString()}>
                        <div key={idx.toString()}>
                          <Draggable key={idx.toString()} draggableId={idx.toString()} index={idx}>
                            {(draggableProvided) => (
                              <li
                                key={idx.toString()}
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                <Card key={idx.toString()}>
                                  <Card.Body className="pb-4">
                                    <div className="d-flex justify-content-between align-items-center gap-4">
                                      <button
                                        type="button"
                                        style={{ cursor: 'default' }}
                                        data-testid="gripButton"
                                        {...draggableProvided.dragHandleProps}
                                      >
                                        <FontAwesomeIcon style={{ cursor: 'grab' }} icon={faGripLines} fixedWidth />
                                      </button>
                                      <Accordion.Header />
                                      <label>
                                        <h5 className="mb-0">{monitorRuleTypeName(item.monitorRuleType)}</h5>
                                      </label>
                                      <FloatingLabel
                                        controlId="monitorRuleTypeName"
                                        label="モニタールール種別名"
                                        className="flex-grow-1"
                                      >
                                        <FormControl
                                          type="text"
                                          value={item.monitorRuleName}
                                          onChange={(e) => {
                                            setData({
                                              ...data,
                                              monitorRuleList: data.monitorRuleList.map((_, inde) => {
                                                return inde === idx
                                                  ? { ..._, monitorRuleName: e.target.value }
                                                  : { ..._ };
                                              }),
                                            });
                                          }}
                                          placeholder="モニタールール種別名"
                                          className="mb-0"
                                        />
                                      </FloatingLabel>
                                      {item.monitorRuleType === 99 ? (
                                        <Button
                                          data-testid="monitorRuleDeleteButton"
                                          className="text-secondary bg-transparent border-0"
                                          onClick={() => {
                                            setData({
                                              ...data,
                                              monitorRuleList: data.monitorRuleList.filter((_, inde) => {
                                                return inde !== idx;
                                              }),
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                                        </Button>
                                      ) : (
                                        <Form.Check
                                          data-testid="disableSwitchButton"
                                          size={100}
                                          type="switch"
                                          checked={!item.disableFlg}
                                          onChange={(e) => {
                                            setData({
                                              ...data,
                                              monitorRuleList: data.monitorRuleList.map((_, inde) => {
                                                return inde === idx ? { ..._, disableFlg: !item.disableFlg } : { ..._ };
                                              }),
                                            });
                                          }}
                                        />
                                      )}
                                    </div>
                                    {/* <Accordion.Collapse eventKey={idx.toString()} > */}
                                    <Accordion.Body className="p-0">
                                      <div className="px-4 my-4" style={{ width: '100%' }}>
                                        {/* 「調査日」contents */}
                                        {item.monitorRuleType === 2 && (
                                          <MonitorRuleSetSurveyDateContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「時間」contents */}
                                        {item.monitorRuleType === 3 && (
                                          <MonitorRuleSetTimeContents
                                            data={data}
                                            setData={setData}
                                            rowData={item}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「人数・同伴者」contents */}
                                        {item.monitorRuleType === 4 && (
                                          <MonitorRuleSetNumberPeopleContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「予約」contents */}
                                        {item.monitorRuleType === 5 && (
                                          <MonitorRuleSetReserveContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「支払方法」contents */}
                                        {item.monitorRuleType === 12 && (
                                          <MonitorRuleSetPaymentMethodContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「指定商品」contents */}
                                        {item.monitorRuleType === 6 && (
                                          <MonitorRuleSetSpecifyItemContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「指定メニュー・コース」contents */}
                                        {item.monitorRuleType === 23 && (
                                          <MonitorRuleSetSpecifyMenuCourseContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「クーポン・割引」contents */}
                                        {item.monitorRuleType === 13 && (
                                          <MonitorRuleSetCouponContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「調査証明」contents */}
                                        {item.monitorRuleType === 14 && (
                                          <MonitorRuleSetSurveyProofContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「写真撮影（グルメ）」contents */}
                                        {data.monitorRuleList.find((_) => {
                                          return _.monitorRuleType === 6;
                                        }) !== undefined &&
                                          item.monitorRuleType === 7 && (
                                            <MonitorRuleSetPhotoShotGourmetContents
                                              data={data}
                                              rowData={item}
                                              setData={setData}
                                              index={idx}
                                              setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                              setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                            />
                                          )}
                                        {/* 「写真撮影（その他）」contents */}
                                        {data.monitorRuleList.find((_) => {
                                          return _.monitorRuleType === 23;
                                        }) !== undefined &&
                                          item.monitorRuleType === 7 && (
                                            <MonitorRuleSetPhotoShotOtherContents
                                              data={data}
                                              rowData={item}
                                              setData={setData}
                                              index={idx}
                                              setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                              setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                            />
                                          )}
                                        {/* 「応募条件」contents */}
                                        {item.monitorRuleType === 17 && (
                                          <MonitorRuleSetApplicationConditionContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「購入店舗」contents */}
                                        {item.monitorRuleType === 22 && (
                                          <MonitorRuleSetPurchaseShopContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「注文方法」contents */}
                                        {item.monitorRuleType === 18 && (
                                          <MonitorRuleSetOrderConditionContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                        {/* 「注意事項」contents */}
                                        {item.monitorRuleType === 16 && (
                                          <MonitorRuleSetNotesContents
                                            data={data}
                                            rowData={item}
                                            setData={setData}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}

                                        {(item.monitorRuleType === 9 || // {/* 「トイレ調査」contents */}
                                          item.monitorRuleType === 11 || // {/* 「その他調査」contents */}
                                          item.monitorRuleType === 15 || // {/* 「特記事項」contents */}
                                          item.monitorRuleType === 19 || // {/* 「対象ブランド」contents */}
                                          item.monitorRuleType === 20 || // {/* 「申込方法」contents */}
                                          item.monitorRuleType === 8 || // {/* 「店員調査」contents */}
                                          item.monitorRuleType === 10 || // {/* 「清掃状況調査」contents */}
                                          item.monitorRuleType === 21 || // {/* 「外観」contents */}
                                          item.monitorRuleType === 99) && ( // {/* 「その他」contents */}
                                          <MonitorRuleSetCommonContents
                                            data={data}
                                            setData={setData}
                                            rowData={item}
                                            index={idx}
                                            setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
                                            setSelectedMonitorRuleId={setSelectedMonitorRuleId}
                                          />
                                        )}
                                      </div>
                                      {/* </Accordion.Collapse> */}
                                    </Accordion.Body>
                                  </Card.Body>
                                </Card>
                              </li>
                            )}
                          </Draggable>
                        </div>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}
                {droppableProvided.placeholder}
              </ul>
            )}
          </Droppable>
        </Card.Body>
      </Card>
    </DragDropContext>
  );
};
