import React from 'react';
import type { ContextValueType as CustomerDetailContextValueType } from '../components/pages/Customer/CustomerDetailPage';
// eslint-disable-next-line import/no-cycle
import { createSafeContext } from '../utils/functions';

// export const CustomerDetailContext = createSafeContext<UseLargeState<CustomerDetailContextValueType>>();
export const CustomerDetailContext = createSafeContext<CustomerDetailContextValueType>();

export const CustomerDetailProvider: React.FC<CustomerDetailContextValueType> = ({
  children,
  customer,
  mail,
  memo,
  moral,
  prefecture,
  reload,
  status,
}) => (
  <CustomerDetailContext.Provider value={{ customer, mail, memo, moral, prefecture, reload, status }}>
    {children}
  </CustomerDetailContext.Provider>
);
