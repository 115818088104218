/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MailMagazineListOutputResponse } from '../domains';
/**
 * F4ListApi - axios parameter creator
 * @export
 */
export const F4ListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * F4一覧情報を取得する
         * @summary F4一覧情報取得
         * @param {string} [sendScheduleTimeStampFrom] 
         * @param {string} [sendScheduleTimeStampTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f4List: async (sendScheduleTimeStampFrom?: string, sendScheduleTimeStampTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/f4-mail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sendScheduleTimeStampFrom !== undefined) {
                localVarQueryParameter['sendScheduleTimeStampFrom'] = (sendScheduleTimeStampFrom as any instanceof Date) ?
                    (sendScheduleTimeStampFrom as any).toISOString() :
                    sendScheduleTimeStampFrom;
            }

            if (sendScheduleTimeStampTo !== undefined) {
                localVarQueryParameter['sendScheduleTimeStampTo'] = (sendScheduleTimeStampTo as any instanceof Date) ?
                    (sendScheduleTimeStampTo as any).toISOString() :
                    sendScheduleTimeStampTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * F4ListApi - functional programming interface
 * @export
 */
export const F4ListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = F4ListApiAxiosParamCreator(configuration)
    return {
        /**
         * F4一覧情報を取得する
         * @summary F4一覧情報取得
         * @param {string} [sendScheduleTimeStampFrom] 
         * @param {string} [sendScheduleTimeStampTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f4List(sendScheduleTimeStampFrom?: string, sendScheduleTimeStampTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailMagazineListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f4List(sendScheduleTimeStampFrom, sendScheduleTimeStampTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * F4ListApi - factory interface
 * @export
 */
export const F4ListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = F4ListApiFp(configuration)
    return {
        /**
         * F4一覧情報を取得する
         * @summary F4一覧情報取得
         * @param {string} [sendScheduleTimeStampFrom] 
         * @param {string} [sendScheduleTimeStampTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f4List(sendScheduleTimeStampFrom?: string, sendScheduleTimeStampTo?: string, options?: any): AxiosPromise<MailMagazineListOutputResponse> {
            return localVarFp.f4List(sendScheduleTimeStampFrom, sendScheduleTimeStampTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * F4ListApi - object-oriented interface
 * @export
 * @class F4ListApi
 * @extends {BaseAPI}
 */
export class F4ListApi extends BaseAPI {
    /**
     * F4一覧情報を取得する
     * @summary F4一覧情報取得
     * @param {string} [sendScheduleTimeStampFrom] 
     * @param {string} [sendScheduleTimeStampTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof F4ListApi
     */
    public f4List(sendScheduleTimeStampFrom?: string, sendScheduleTimeStampTo?: string, options?: AxiosRequestConfig) {
        return F4ListApiFp(this.configuration).f4List(sendScheduleTimeStampFrom, sendScheduleTimeStampTo, options).then((request) => request(this.axios, this.basePath));
    }
}
