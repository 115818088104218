import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { PointGrantContext } from '../../../../store/pointPageStore';
import { createTestId, uuid, when } from '../../../../utils/functions';
import { Button } from '../../../atoms/Button';
import { Title } from '../../../atoms/Title';

interface Props {
  onSubmit: () => void;
}

export const PointGrantConfirmPage: React.FC<Props> = ({ onSubmit }) => {
  const testid = createTestId(PointGrantConfirmPage);
  const context = useContext(PointGrantContext);
  const { state: $, mergeState } = context;
  return when(
    !!context,
    <>
      <Title>ポイント付与</Title>
      <Table data-testid={testid('table')}>
        <thead>
          <tr className="align-middle">
            <th>#</th>
            <th>対象会員ID</th>
            <th>ポイントタイプ</th>
            <th>付与ポイント数</th>
            <th>付与根拠</th>
            <th>内部用コメント</th>
            <th>ユーザ向けメッセージ</th>
          </tr>
        </thead>
        <tbody>
          {$.list.map(({ customerId, grantType, grantPoints, evidence, internalComment, messageForCustomer }, i) => (
            <tr key={uuid()} className="align-middle">
              <td>{i}</td>
              <td>{customerId}</td>
              <td>{$.pointTypes.find((x) => grantType === x.code)?.name}</td>
              <td>{grantPoints}</td>
              <td>{evidence}</td>
              <td>{internalComment}</td>
              <td>{messageForCustomer}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button data-testid={testid('add-button')} onClick={() => mergeState({ phase: 'add' })}>
        フォームで再入力
      </Button>{' '}
      <Button data-testid={testid('bulk-button')} onClick={() => mergeState({ phase: 'bulk' })}>
        xlxsファイルで再入力
      </Button>
      {'\u3000'}
      <Button
        data-testid={testid('submit-button')}
        onClick={() => {
          onSubmit();
        }}
      >
        実行
      </Button>
    </>
  );
};
