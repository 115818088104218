import { RegisterOptions } from 'react-hook-form';

export type OptionsType = {
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
};
export default function useGetValidationRule() {
  const getRules = (options: OptionsType = {}, type?: 'number' | 'email' | 'month') => {
    let rules: RegisterOptions = {};
    if (type === 'number') {
      // rules = { ...rules, valueAsNumber: true };
      rules = {
        // ...rules,
        pattern: {
          value: /^\d+$/,
          message: '数値のみ入力してください。',
        },
      };
    }
    if (type === 'email') {
      rules = {
        // ...rules,
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: 'emailの形式が正しくありません。',
        },
      };
    }
    if (options.required) {
      rules = {
        ...rules,
        required: '入力必須項目です。',
      };
    }
    if (options.min !== undefined && type === 'number') {
      rules = {
        ...rules,
        min: {
          value: options.min,
          message: `${options.min}以上の値を入力してください。`,
        },
      };
    }
    if (options?.max !== undefined && type === 'number') {
      rules = {
        ...rules,
        max: {
          value: options.max,
          message: `${options.max}以下の値を入力してください。`,
        },
      };
    }
    if (options.minLength !== undefined) {
      rules = {
        ...rules,
        minLength: {
          value: options.minLength,
          message: `${options.minLength}以上の文字を入力してください。`,
        },
      };
    }
    if (options.maxLength !== undefined) {
      rules = {
        ...rules,
        maxLength: {
          value: options.maxLength,
          message: `${options.maxLength}以下の文字を入力してください。`,
        },
      };
    }
    return rules;
  };

  return { getRules };
}
