import React, { useMemo, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import { InputNumber } from '../../atoms/InputNumber';
import type { CorrectSettingSearchState } from '../../pages/CorrectSetting/CorrectSettingSearchPage';

export type State = CorrectSettingSearchState['forms'];
export type FormState = { formType: number; enqueteMethod: [string, number][]; receiptMethod: [string, number][] };

interface Props {
  setForms: DispatchSetState<State>;
  correctMethod: CorrectSettingSearchState['correctMethod'];
}

export type TestIds = keyof State;

export const CorrectSettingSearchForms: React.FC<Props> = React.memo(({ setForms, correctMethod }) => {
  const testId = createTestId<TestIds>(CorrectSettingSearchForms);
  const { state: $, onChangeSet, mergeState } = useLargeState<State>({});
  const {
    state: $form,
    onChangeSet: onChangeSetForm,
    mergeState: mergeStateForm,
  } = useLargeState<FormState>({ formType: 0, enqueteMethod: [], receiptMethod: [] });

  useEffect(() => {
    setForms({ ...$ });
  }, [setForms, $]);

  useEffect(() => {
    setForms({
      ...$,
      clientId: undefined,
      clientName: undefined,
      monitorId: undefined,
      monitorName: undefined,
    });
    mergeState({
      ...$,
      clientId: undefined,
      clientName: undefined,
      monitorId: undefined,
      monitorName: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$form.formType]);

  useEffect(() => {
    mergeStateForm({
      enqueteMethod: correctMethod.enqueteCorrectMethodList.map(({ content, id }) => [content, id]),
      receiptMethod: correctMethod.surveyProofCorrectMethodList.map(({ content, id }) => [content, id]),
    });
  }, [correctMethod.enqueteCorrectMethodList, correctMethod.surveyProofCorrectMethodList, mergeStateForm]);

  const options: [string, number][] = useMemo(
    () =>
      Object.entries({
        クライアントID: 0,
        クライアント名: 1,
        モニターID: 2,
        モニター名: 3,
      }),
    []
  );

  const renderForm = (formType: number) => {
    switch (formType) {
      case 0:
        return <InputNumber value={$.clientId} min={0} onChange={onChangeSet('clientId', Number)} />;
      case 1:
        return (
          <Form.Control
            type="text"
            autoComplete="off"
            value={$.clientName || ''}
            onChange={onChangeSet('clientName', String)}
          />
        );
      case 2:
        return <InputNumber value={$.monitorId} min={0} onChange={onChangeSet('monitorId', Number)} />;
      case 3:
        return (
          <Form.Control
            type="text"
            autoComplete="off"
            value={$.monitorName || ''}
            onChange={onChangeSet('monitorName', String)}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <>
      <Row>
        <Form.Group data-testid={testId('clientId')} controlId="applyId" key="applyId" className="d-flex col-md-6">
          <Form.Select value={$form.formType} onChange={onChangeSetForm('formType', Number)} className="w-25">
            {options.map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
          {renderForm($form.formType)}
        </Form.Group>
      </Row>
      <Row>
        <Col md={3}>
          <Form.Label>購入証明添削方法</Form.Label>
          <Form.Group
            data-testid={testId('surveyProofCorrectMethod')}
            controlId="surveyProofCorrectMethod"
            key="surveyProofCorrectMethod"
          >
            <Form.Select value={$.surveyProofCorrectMethod} onChange={onChangeSet('surveyProofCorrectMethod', Number)}>
              <option value={-1}>--</option>
              {$form.receiptMethod.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={3}>
          <Form.Label>アンケート添削方法</Form.Label>
          <Form.Group
            data-testid={testId('enqueteCorrectMethod')}
            controlId="enqueteCorrectMethod"
            key="enqueteCorrectMethod"
          >
            <Form.Select value={$.enqueteCorrectMethod} onChange={onChangeSet('enqueteCorrectMethod', Number)}>
              <option value={-1}>--</option>
              {$form.enqueteMethod.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
});
