import React from 'react';
import { Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMaximize } from '@fortawesome/free-regular-svg-icons';
import { uuid, when } from '../../../../utils/functions';
import type { CorrectDataInputState } from '../../../pages/Correction/CorrectDataInputPage';
import type { CorrectDataInputWcheckState } from '../../../pages/Correction/CorrectDataInputWcheckPage';

type OutputType = CorrectDataInputState['output'] | CorrectDataInputWcheckState['output'];
export const DataInputNoteContent: React.FC<{
  output: OutputType;
}> = React.memo(({ output }) => {
  return (
    <>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>入力時の注意点</Accordion.Header>
          <Accordion.Body>
            レシートが複数ある場合、分割されている可能性があります。
            <br />
            つながりが確認できる場合はひとつのフォームに入力してください。
            <div className="d-flex justify-content-end">
              <a
                href="https://sites.google.com/view/roi-correction-portal/receiptManual/commonCheckPoint?authuser=0#h.hpnwsi4xi2j4"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faWindowMaximize} />
                繋がりが確認できるとは
              </a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {when(
        output.applyId > 0,
        <div className="bg-white p-2 mt-2">
          <div className="bg-light" style={{ whiteSpace: 'pre-wrap' }}>
            {output.note?.noteText}
            <div className="mt-2">
              {output.note?.noteImageList.map((obj) => {
                return <img src={obj.imagePath} alt={obj.imagePath} key={uuid()} className="me-2" />;
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
});
