import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { format } from 'date-fns';
import { MonitorApi, MonitorApplyHistoryListOutputResponse, MonitorListOutputResponse } from '../../../api-client';
import { createTestId } from '../../../utils/functions';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { useLargeState } from '../../../hooks/useLargeState';
import { MonitorApplyHistoryListTable } from '../../organisms/Table/MonitorApplyHistoryListTable';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';

// eslint-disable-next-line import/no-cycle

export interface State {
  api: MonitorApi;
  monitorIdName: string | undefined;
  shopIdName: string | undefined;
  clientIdName: string | undefined;
  fromDate: string | undefined;
  toDate: string | undefined;
  list: MonitorApplyHistoryListOutputResponse[];
  listPerPage: MonitorApplyHistoryListOutputResponse[];
  currentPage: number;
  totalPage: number;
}

const MAX_RECORD_PER_PAGE = 100;

export const MonitorApplyHistoryListPage: React.VFC = () => {
  const testid = createTestId(MonitorApplyHistoryListPage);
  const initialState: State = {
    api: new MonitorApi(),
    monitorIdName: undefined,
    shopIdName: undefined,
    clientIdName: undefined,
    fromDate: format(new Date().setDate(1), 'yyyy-MM-dd'),
    toDate: undefined,
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  };

  const { state: $, mergeState, useBindSet } = useLargeState<State>(initialState);

  useEffect(() => {
    $.api
      .monitorApplyHistoryList($.monitorIdName, $.shopIdName, $.clientIdName, undefined, $.fromDate, $.toDate)
      .then((res: AxiosResponse<MonitorApplyHistoryListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    $.api
      .monitorApplyHistoryList($.monitorIdName, $.shopIdName, $.clientIdName, undefined, $.fromDate, $.toDate)
      .then((res: AxiosResponse<MonitorApplyHistoryListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
  };

  const setPagingStates = (list: MonitorApplyHistoryListOutputResponse[]) => {
    const modifyList = list.sort((a, b) => b.monitorId - a.monitorId);

    mergeState({
      list: modifyList,
      listPerPage: modifyList.slice(0, MAX_RECORD_PER_PAGE),
      totalPage: Math.ceil(Number(modifyList?.length) / MAX_RECORD_PER_PAGE),
      currentPage: 1,
    });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.MONITOR_APPLY_HISTORY_LIST}</Title>

      <Form.Group className="mb-4 w-100">
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="モニター名/ID" data-testid={testid('clientIdOrName')}>
              <Form.Control
                type="text"
                placeholder="モニター名/ID"
                value={$.monitorIdName || ''}
                onChange={(e) => mergeState({ monitorIdName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="実店舗名/ID" data-testid={testid('shopName')}>
              <Form.Control
                type="text"
                placeholder="実店舗名/ID"
                value={$.shopIdName || ''}
                onChange={(e) => mergeState({ shopIdName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="クライアント名/ID" data-testid={testid('monitorName')}>
              <Form.Control
                type="text"
                placeholder="クライアント名/ID"
                value={$.clientIdName || ''}
                onChange={(e) => mergeState({ clientIdName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col />
        </Row>
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="開始月(From)" data-testid={testid('fromDate')}>
              <Form.Control
                type="date"
                placeholder="開始月(From)"
                value={$.fromDate ? $.fromDate.replace(/\//g, '-') : ''}
                onChange={(e) => mergeState({ fromDate: e.target.value ? e.target.value : undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="開始月(To)" data-testid={testid('toDate')}>
              <Form.Control
                type="date"
                placeholder="開始月(To)"
                value={$.toDate ? $.toDate.replace(/\//g, '-') : ''}
                onChange={(e) => mergeState({ toDate: e.target.value ? e.target.value : undefined })}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col className="col-4">
            <Button className="w-100 py-3" onClick={onSearch} data-testid={testid('search-button')}>
              検索
            </Button>
          </Col>
        </Row>
      </Form.Group>

      <MonitorApplyHistoryListTable listPerPage={$.listPerPage} />

      <div className="mb-4" data-testid={testid('pagination')}>
        <PaginationWithEllipsis
          currentPage={$.currentPage}
          totalPage={$.totalPage}
          handleClick={(page) => {
            if (!page || page > $.totalPage) return;
            mergeState({
              listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
              currentPage: page,
            });
          }}
        />
      </div>
    </>
  );
};
