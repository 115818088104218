import React, { useState, useEffect } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { TITLE } from '../../../constants/Title';
import { Url } from '../../../constants/Url';
/* eslint-disable import/no-cycle */
import {
  ClientAccountSettingFileUploadApi,
  ClientAccountSettingFileDownloadApi,
  ClientAccountSettingTemplateFileDownloadApi,
  ClientAccountSettingFileCheckApi,
  ClientAccountSettingFileCheckOutputResponse,
  ClientAccountSettingFileCheckOutputListItemResponse,
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  IncResultOutputResponse,
  ClientAccountSettingFileUploadFormResponse,
  ClientAccountSettingFileUploadFormListItemResponse,
  RoleListApi,
  IdNameOutputResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, execDownload, when } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Title } from '../../atoms/Title';
import { Dropzone } from '../../molecules/Dropzone';
import { TagsInput } from '../../molecules/TagsInput';

/* eslint-enable import/no-cycle */

export interface State {
  uploadApi: ClientAccountSettingFileUploadApi;
  downloadApi: ClientAccountSettingFileDownloadApi;
  templateDownloadApi: ClientAccountSettingTemplateFileDownloadApi;
  fileCheckApi: ClientAccountSettingFileCheckApi;
  commonApi: CommonMasterListApi;
  clientOpts: CommonMasterListOutputResponse[];
  clientIds: number[];
  checkResult: boolean;
  resultMessageList: string[];
  list: ClientAccountSettingFileCheckOutputListItemResponse[];
  updResult: IncResultOutputResponse;
}

export const ClientAccountSettingPage: React.VFC = () => {
  const testid = createTestId(ClientAccountSettingPage);
  const history = useHistory();

  const { state: $, mergeState } = useLargeState<State>({
    uploadApi: new ClientAccountSettingFileUploadApi(),
    downloadApi: new ClientAccountSettingFileDownloadApi(),
    templateDownloadApi: new ClientAccountSettingTemplateFileDownloadApi(),
    fileCheckApi: new ClientAccountSettingFileCheckApi(),
    commonApi: new CommonMasterListApi(),
    clientIds: [],
    clientOpts: [],
    checkResult: false,
    resultMessageList: [],
    list: [],
    updResult: { result: false },
  });

  const [tags, setTags] = useState<string[]>([]);

  // 営業ロール保有者フラグ
  const [salesFlg, setSalesFlg] = useState<boolean>(false);

  const roleListApi = new RoleListApi();

  // 画面初期表示時に一回だけロール一覧を取得し、営業ロールを持っているか確認する。
  useEffect(() => {
    roleListApi.roleList().then((res: AxiosResponse<IdNameOutputResponse[]>) => {
      setSalesFlg(res.data.some((role) => role.name === '営業'));
    });
  }, []);

  useEffect(() => {
    $.commonApi.commonMasterList('client').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
      mergeState({ clientOpts: res.data });
    });
  }, [$.commonApi, mergeState]);

  useEffect(
    () =>
      mergeState({
        clientIds: tags.map((name) => Number(name.substring(0, name.indexOf('：')))).filter(Boolean),
      }),
    [tags, $.clientOpts, mergeState]
  );

  const onDownload = () => {
    if ($.clientIds.length === 0) return;
    $.downloadApi.clientAccountSettingFileDownload($.clientIds).then((res: AxiosResponse<string>) => {
      execDownload(
        res.data,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '【クライアントアカウント】ダウンロード.xlsx'
      ).catch(() => {
        history.push(Url.COMMON_ERROR);
      });
    });
  };

  const onTemplateDownload = () => {
    $.templateDownloadApi.clientAccountSettingTemplateFileDownload().then((res: AxiosResponse<string>) => {
      execDownload(
        res.data,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '【クライアントアカウント】テンプレートダウンロード.xlsx'
      ).catch(() => {
        history.push(Url.COMMON_ERROR);
      });
    });
  };

  const onDrop = (files: File[]) => {
    if (files === null || files.length === 0) return;
    const file = files[0];

    const reader = new FileReader();
    reader.onload = () => {
      $.fileCheckApi
        .clientAccountSettingFileCheck({ file: reader.result?.toString().replace(/data:.*\/.*;base64,/, '') || '' })
        .then((res: AxiosResponse<ClientAccountSettingFileCheckOutputResponse>) => {
          const { result, ...rest } = res.data;
          mergeState({ checkResult: result, ...rest });
        });
    };
    reader.readAsDataURL(file);
  };

  const onUpload = () => {
    const list: any /* ClientAccountSettingFileUploadFormListItemResponse[] TODO 修正 */ = $.list.map(
      ({ errorList, clientId, accountName, mailReceiveLevel, mailaddress, ...rest }) => {
        return {
          ...rest,
          clientId: `${clientId}`,
          accountName: `${accountName}`,
          mailReceiveLevel: `${mailReceiveLevel}`,
          mailaddress: `${mailaddress}`,
        };
      }
    );
    $.uploadApi
      .clientAccountSettingFileUpload({ list })
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  return (
    <>
      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.CLIENT_ACCOUNT_SETTING}
      </Title>

      {$.resultMessageList.length > 0 && (
        <Alert testId={testid('check-alert')} variant="danger">
          {$.resultMessageList?.map((m, i) => (
            <div key={i}>{m}</div>
          ))}
        </Alert>
      )}
      {$.updResult.result && (
        <Alert testId={testid('success-alert')} variant="success">
          保存しました。
        </Alert>
      )}
      {$.updResult.errorMessage && (
        <Alert
          testId={testid('failure-alert')}
          variant="danger"
        >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}

      <div className="d-flex justify-content-end mb-4">
        {$.list.length === 0 ? (
          <>
            <Button
              variant="outline-secondary"
              data-testid={testid('template-download-button')}
              className="ms-2"
              onClick={() => onTemplateDownload()}
            >
              テンプレートダウンロード
            </Button>
            <Button
              variant="outline-secondary"
              data-testid={testid('download-button')}
              className="ms-2"
              onClick={() => onDownload()}
              disabled={$.clientIds.length === 0}
            >
              ダウンロード
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="link"
              className="ms-2"
              data-testid={testid('cancel-button')}
              onClick={() => {
                mergeState({
                  list: [],
                  checkResult: false,
                  resultMessageList: [],
                  updResult: { result: false },
                });
              }}
            >
              キャンセル
            </Button>
            <Button data-testid={testid('save-button')} className="ms-2" onClick={onUpload} disabled={!$.checkResult}>
              保存
            </Button>
          </>
        )}
      </div>

      {$.list.length === 0 ? (
        <>
          <Form.Group className="mb-4" data-testid={testid('client')}>
            <Form.Label>クライアント</Form.Label>
            <TagsInput
              tags={tags}
              setTags={setTags}
              options={$.clientOpts.map(({ id, name }) => `${id}：${name}`)}
              pill
            />
          </Form.Group>

          {when(
            !salesFlg,
            <>
              <Dropzone onDrop={onDrop} type="excel" />
            </>
          )}
        </>
      ) : (
        <div style={{ width: 'calc(100vw - 20rem)', overflowX: 'scroll' }} className="mb-4">
          <Table bordered className="mt-4 text-nowrap" data-testid={testid('table')}>
            <thead>
              <tr>
                <th>アカウントID</th>
                <th>アカウント名</th>
                <th>クライアントID</th>
                <th>組織ID</th>
                <th>契約店舗ID</th>
                <th>メールアドレス</th>
                <th>クライアント管理者権限</th>
                <th>目標設定権限</th>
                <th>ユーザー編集権限</th>
                <th>ギフト表示権限</th>
                <th>メール受信可否</th>
                <th>全店参照権限</th>
                <th>メール受信レベル</th>
                <th>削除する</th>
                <th>エラー内容</th>
              </tr>
            </thead>
            <tbody>
              {$.list.map(
                ({
                  accountId,
                  accountName,
                  clientId,
                  organizationId,
                  shopId,
                  mailaddress,
                  clientManagementFlg,
                  targetSettingFlg,
                  userEditFlg,
                  giftShowFlg,
                  mailReceiveFlg,
                  viewAllFlg,
                  mailReceiveLevel,
                  deleteFlg,
                  errorList,
                }) => (
                  <tr key={shopId} className="align-middle">
                    <td>{accountId}</td>
                    <td>{accountName}</td>
                    <td>{clientId}</td>
                    <td>{organizationId}</td>
                    <td>{shopId}</td>
                    <td>{mailaddress}</td>
                    <td className="text-center">{clientManagementFlg === 'true' && '○'}</td>
                    <td className="text-center">{targetSettingFlg === 'true' && '○'}</td>
                    <td className="text-center">{userEditFlg === 'true' && '○'}</td>
                    <td className="text-center">{giftShowFlg === 'true' && '○'}</td>
                    <td className="text-center">{mailReceiveFlg === 'true' && '○'}</td>
                    <td className="text-center">{viewAllFlg === 'true' && '○'}</td>
                    <td>{mailReceiveLevel}</td>
                    <td className="text-center">{deleteFlg === 'true' && '○'}</td>
                    <td>
                      {errorList?.map((e: string, i: number) => (
                        <div key={i} className="text-danger">
                          {e}
                        </div>
                      ))}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};
