import { View } from '@react-pdf/renderer';
import React from 'react';
import {
  BillingInvoiceTotalingDetailItemForPdfRowDataOutputResponse,
  BillingInvoiceTotalingDetailItemHeaderForPdfRowDataOutputResponse,
} from '../../../api-client';
import { SurvayShopTotalType } from '../../../type/PdfType';
import { Col } from '../../atoms/PdfParts/Col';
import { Row } from '../../atoms/PdfParts/Row';
import { TextStyle } from '../../atoms/PdfParts/TextStyle';
import { convertMoneyText, getRowHeightByStrLen } from '../../../utils/functionsForBilling';
import { BILLING_PDF_SETTINGS } from '../../../BillingConstants';

type Props = {
  lists: Array<BillingInvoiceTotalingDetailItemForPdfRowDataOutputResponse>;
  headers: BillingInvoiceTotalingDetailItemHeaderForPdfRowDataOutputResponse;
  totals: SurvayShopTotalType;
};

export const SurveyDetailTable: React.FC<Props> = ({ lists, totals, headers }) => {
  let rowCount = 0;
  return (
    <>
      <Row borderBottom borderTop borderRight borderLeft fixed th>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_NO}>
          <TextStyle>{headers.no}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_SHOP_NAME}>
          <TextStyle>{headers.contractShopName}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_MONITOR_NAME}>
          <TextStyle>{headers.monitorName}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_REPORT_COUNT}>
          <TextStyle>{headers.reportCount}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{headers.userAmount}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{headers.spot}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{headers.stock}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{headers.imageOption}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{headers.billingRewardFee}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{headers.billingFee}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{headers.others}</TextStyle>
        </Col>
        <Col borderRight th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{headers.amount}</TextStyle>
        </Col>
        <Col th width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_REMARKS}>
          <TextStyle>{headers.remarks}</TextStyle>
        </Col>
      </Row>
      {lists.length !== 0 &&
        lists.map((list, i) => {
          if (rowCount >= BILLING_PDF_SETTINGS.MAX_LINE_NUM_DEFAULT) {
            rowCount = 0;
          }
          rowCount += Math.max(
            getRowHeightByStrLen(
              list.contractShopName,
              BILLING_PDF_SETTINGS.MONITOR_DETAIL_SHOP_NAME,
              BILLING_PDF_SETTINGS.MAX_LINE_DEFAULT
            ),
            getRowHeightByStrLen(
              list.monitorName,
              BILLING_PDF_SETTINGS.MONITOR_DETAIL_MONITOR_NAME,
              BILLING_PDF_SETTINGS.MAX_LINE_DEFAULT
            )
          );
          return (
            <Row
              key={i}
              borderBottom={i !== lists.length - 1}
              borderRight
              borderLeft
              break_={rowCount >= BILLING_PDF_SETTINGS.MAX_LINE_NUM_DEFAULT}
            >
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_NO}>
                <TextStyle>{i + 1}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_SHOP_NAME}>
                <TextStyle>{list.contractShopName}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_MONITOR_NAME}>
                <TextStyle>{list.monitorName}</TextStyle>
              </Col>
              <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_REPORT_COUNT}>
                <TextStyle>{list.reportCount}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS} right>
                <TextStyle>{convertMoneyText(list.userAmount)}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS} right>
                <TextStyle>{convertMoneyText(list.spot)}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS} right>
                <TextStyle>{convertMoneyText(list.stock)}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS} right>
                <TextStyle>{convertMoneyText(list.imageOption)}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS} right>
                <TextStyle>{convertMoneyText(list.billingRewardFee)}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS} right>
                <TextStyle>{convertMoneyText(list.billingFee)}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS} right>
                <TextStyle>{convertMoneyText(list.others)}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS} right>
                <TextStyle>{convertMoneyText(list.amount)}</TextStyle>
              </Col>
              <Col width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_REMARKS}>
                <TextStyle>{list.remarks}</TextStyle>
              </Col>
            </Row>
          );
        })}
      <View style={{ width: '100%', borderStyle: 'solid', paddingBottom: '1', borderTop: 1 }} />
      <Row borderTop borderBottom borderLeft borderRight th>
        <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_NO}>
          {/* ここを何もないカッコにするとなぜかDLできなくなるので一旦これで */}
          <TextStyle> </TextStyle>
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_SHOP_NAME} right>
          <TextStyle>合計</TextStyle>
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_MONITOR_NAME}>
          <TextStyle> </TextStyle>
        </Col>
        <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_REPORT_COUNT}>
          <TextStyle>{totals.reportCount.toLocaleString()}</TextStyle>
        </Col>
        <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{convertMoneyText(totals.userAmount)}</TextStyle>
        </Col>
        <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{convertMoneyText(totals.spot)}</TextStyle>
        </Col>
        <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{convertMoneyText(totals.stock)}</TextStyle>
        </Col>
        <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{convertMoneyText(totals.imageOption)}</TextStyle>
        </Col>
        <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{convertMoneyText(totals.billingRewardFee)}</TextStyle>
        </Col>
        <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{convertMoneyText(totals.billingFee)}</TextStyle>
        </Col>
        <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{convertMoneyText(totals.others)}</TextStyle>
        </Col>
        <Col borderRight right width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_OTHERS}>
          <TextStyle>{convertMoneyText(totals.amount)}</TextStyle>
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.MONITOR_DETAIL_REMARKS}>{}</Col>
      </Row>
    </>
  );
};
