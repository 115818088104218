/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useState } from 'react';

export interface UseLargeState<T extends Record<string, any>> {
  state: T;
  setState: React.Dispatch<React.SetStateAction<T>>;
  mergeState: (value: Partial<T>) => void;
  onChangeSet: <N extends keyof T, R = T[N]>(
    name: N,
    formatFn?: ((value: any) => R) | undefined
  ) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  onChangeCheckbox: <N extends keyof T>(name: N) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  useBindSet: <E = any>(name: keyof T) => (e: E) => void;
}

/**
 * useStateをオブジェクトで運用しやすくする。
 * ```tsx
 * const { state, mergeState, bindSet, setState } = useLargeState<{
 *   isBool: boolean,
 *   count?: number
 * }>({
 *   isBool: false,
 *   count: undefined
 * });
 * // state:は読み取り専用
 * console.log({ state });
 * // 基本的に状態変更はこれで行う
 * mergeState({ isBool: true, count: 42 });
 * // コンポーネントでの使い方
 * <Component
 *   // set関数をpropsに渡す場合
 *   setIsBool={useBindSet('isBool')}
 *   // stateをもとに変更を加える場合
 *   onClick={() => setState(
 *     (prev: typeof state) => ({ count: prev.count + 1 })
 *   )}
 * />
 * ```
 */
export function useLargeState<T extends Record<string, any>>(initialState: T): UseLargeState<T> {
  const [state, setState] = useState<T>((initialState || {}) as T);
  const mergeState = useCallback((value: Partial<T>) => setState((prev) => ({ ...prev, ...value })), []);
  const bindSet = useCallback(
    <S extends T[keyof T]>(name: keyof T) =>
      ((value: S) => setState((prev) => ({ ...prev, [name]: value }))) as React.Dispatch<React.SetStateAction<S>>,
    []
  );
  const useBindSet = (name: keyof T) => useCallback((e) => bindSet(name)(e), [name]);
  const onChangeSet = useCallback(
    <N extends keyof T, R = T[N]>(name: N, formatFn?: (value: any) => R) =>
      (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        // jest対策: https://github.com/testing-library/user-event/issues/533#issuecomment-750818261
        const { value } = e.target;
        const format = !value ? undefined : formatFn;
        mergeState({ [name]: (format || ((x) => x))(value) } as Partial<T>);
      },
    [mergeState]
  );
  const onChangeCheckbox = useCallback(
    <N extends keyof T>(name: N) =>
      (e: React.ChangeEvent<HTMLInputElement>) => {
        // jest対策: https://github.com/testing-library/user-event/issues/533#issuecomment-750818261
        const { checked } = e.target;
        mergeState({ [name]: checked } as Partial<T>);
      },
    [mergeState]
  );
  return { state, setState, mergeState, onChangeSet, onChangeCheckbox, useBindSet };
}
