import React, { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import {
  PointApi,
  ApprovedAmountModifyHistoryDetailOutputResponse,
  PointHistoryInfoOutputResponse,
  PointHistoryListFormResponse,
  PointHistoryListOutputResponse,
  PointTypeListOutputResponse,
} from '../../../api-client';
// import { pointApi } from '../../../test/mocks/api/PointApi';
import { Url } from '../../../constants/Url';
import { useLargeState } from '../../../hooks/useLargeState';
import { PointHistoryProvider } from '../../../store/pointPageStore';
import { dial } from '../../../utils/functions';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { PointHistoryInfoPage } from './History/PointHistoryInfoPage';
import { PointHistoryListPage } from './History/PointHistoryListPage';

export interface State {
  api: PointApi;
  // api: ReturnType<typeof pointApi>;
  isLoading: boolean;
  phase: 'list' | 'info';
  historyData?: PointHistoryListOutputResponse;
  pointTypes: PointTypeListOutputResponse[];
  customerId: number;
  forms: PointHistoryListFormResponse & { searchType?: 'createAt' | 'expirationDate' };
  selectedIndex?: number;
  info?: PointHistoryInfoOutputResponse;
  receiptList: ApprovedAmountModifyHistoryDetailOutputResponse[];
}

export const initialState: Omit<State, 'api' | 'customerId'> = {
  isLoading: false,
  phase: 'list',
  pointTypes: [],
  forms: {},
  receiptList: [],
};

export const PointHistoryPage: React.FC = () => {
  const history = useHistory();
  const params = useParams<{ id: string | undefined }>();
  const largeState = useLargeState<State>({
    api: new PointApi(),
    // api: pointApi({}, { enableLogs: true }),
    customerId: Number(params.id),
    ...initialState,
  });
  const { state: $, mergeState } = largeState;

  const readyForms: State['forms'] = useMemo(() => {
    const { searchType, createAtStart, createAtEnd, expirationDateStart, expirationDateEnd, ...rest } = $.forms;
    return {
      ...rest,
      // eslint-disable-next-line no-nested-ternary
      ...(searchType === 'createAt'
        ? { createAtStart, createAtEnd }
        : searchType === 'expirationDate'
        ? { expirationDateStart, expirationDateEnd }
        : {}),
      applyId: $.forms.applyId !== undefined ? Number($.forms.applyId) : undefined,
      pointType: $.forms.pointType !== undefined ? Number($.forms.pointType) : undefined,
    };
  }, [$.forms]);

  useEffect(() => {
    mergeState({ isLoading: true });
    (async () => {
      mergeState({
        isLoading: false,
        pointTypes: (await $.api.pointTypeList()).data,
        historyData: (
          await $.api.pointHistoryList(
            $.customerId,
            // 空なのは初期描画時のため
            {}
          )
        ).data,
      });
    })();
  }, [$.api, $.customerId, mergeState]);

  const onSearch = useCallback(() => {
    mergeState({ isLoading: true });
    (async () => {
      mergeState({
        isLoading: false,
        historyData: (await $.api.pointHistoryList($.customerId, readyForms)).data,
      });
    })();
  }, [$.api, $.customerId, mergeState, readyForms]);

  useEffect(() => {
    if ($.phase !== 'info' || $.historyData === undefined || $.selectedIndex === undefined) return;
    const { pointType, pointId, applyId } = $.historyData.pointHistoryList[$.selectedIndex];
    mergeState({ isLoading: true });
    (async () => {
      mergeState({
        isLoading: false,
        info: (await $.api.pointHistoryInfo(pointType, pointId)).data,
        receiptList:
          applyId === undefined || applyId === null
            ? []
            : (await $.api.approvedAmountModifyHistoryInfo(applyId)).data.approvedAmountModifyHistoryDetailList,
      });
    })();
  }, [$.api, $.historyData, $.phase, $.selectedIndex, mergeState]);

  return (
    <LoadingSpinner isLoading={$.isLoading}>
      <PointHistoryProvider largeState={largeState}>
        {dial($.phase, {
          list: (
            <PointHistoryListPage
              onNew={() => history.push(`${Url.TENSAKU.POINT_GRANT}/${params.id}`)}
              onSearch={onSearch}
              onInfo={(selectedIndex: number) => {
                mergeState({ phase: 'info', selectedIndex });
              }}
            />
          ),
          info: (
            <PointHistoryInfoPage
              onBack={() => {
                mergeState({ phase: 'list', selectedIndex: undefined });
              }}
            />
          ),
        })}
      </PointHistoryProvider>
    </LoadingSpinner>
  );
};
