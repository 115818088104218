import React from 'react';
import { Table, Form } from 'react-bootstrap';
import { FieldArrayWithId, UseFormRegister } from 'react-hook-form';
import { AllList } from '../../pages/Brand/BrandOemPage';

export interface Props {
  fields: FieldArrayWithId<AllList, 'allList', 'id'>[];
  register: UseFormRegister<AllList>;
  disabled: boolean;
}

export const OemTable: React.VFC<Props> = ({ fields, register, disabled }) => {
  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>OEM名</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, idx) => {
            return (
              <tr key={item.apiClientId} className="align-middle">
                <td>
                  <Form.Check id={`oemShowFlg${idx}`}>
                    <Form.Check.Input
                      className="flex-shrink-0 me-2"
                      type="checkbox"
                      {...register(`allList.${idx}.checked`)}
                      disabled={disabled}
                    />
                    <Form.Check.Label>{item.apiClientName}</Form.Check.Label>
                  </Form.Check>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
