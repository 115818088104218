import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
}

export const Checkbox: React.VFC<Props> = ({ label, onChange, checked = false }) => {
  return (
    <Form.Check className="d-flex">
      <Form.Check.Input className="flex-shrink-0 me-2" type="checkbox" checked={checked} onChange={onChange} />
      <Form.Check.Label>{label}</Form.Check.Label>
    </Form.Check>
  );
};
