import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';
import { Layout } from '../templates/Layout';
import { getHistoryState } from '../../utils/functions';

interface ErrorMessage {
  code: string;
  field: string;
  message: string;
}
interface ErrorResult {
  result: boolean;
  errors: Array<ErrorMessage>;
}
const ErrorPage: React.FC = () => {
  const location = useLocation();
  const error = getHistoryState(location.state, 'error') as AxiosError;
  const oldPath = getHistoryState(location.state, 'oldPath') as string;
  const [path, setPath] = useState('');

  const errorResult: ErrorResult | undefined =
    error.response?.data !== undefined ? (error.response?.data as ErrorResult) : undefined;

  useEffect(() => {
    // この処理を入れないと/errorに上書きされてしまう
    if (oldPath !== '/error') {
      setPath(oldPath);
    }
  }, []);

  return (
    <>
      <Layout>
        <h4>エラーが発生しました。</h4>
        {errorResult?.errors && (
          <>
            <h5>エラー詳細</h5>
            {errorResult.errors.map((v) => {
              return (
                <>
                  <p>エラーコード:{v.code}</p>
                  <p>エラーメッセージ:{v.message}</p>
                </>
              );
            })}
          </>
        )}
        <a href={path}>前のページに戻る</a>
      </Layout>
    </>
  );
};

export default ErrorPage;
