import React from 'react';
import { Form } from 'react-bootstrap';
import { Path, UseFormRegister } from 'react-hook-form';
import useSuggestApiClient from '../../hooks/useSuggestApiClient';

interface Props<T> {
  onChange?: (...data: any) => void;
  className?: string;
  registerName?: Path<T>;
  register?: UseFormRegister<T>;
}

export const OemSelectBox = <T,>(props: Props<T>): JSX.Element => {
  const { register, registerName, className, onChange } = props;
  const suggestApiClient = useSuggestApiClient();

  return (
    <>
      {suggestApiClient.isSuccess ? (
        <>
          {register !== undefined && registerName !== undefined ? (
            <Form.Select {...register(registerName)} className={className}>
              <option>{}</option>
              {suggestApiClient!.data!.map((v) => {
                return (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                );
              })}
            </Form.Select>
          ) : (
            <Form.Select onChange={onChange}>
              <option>{}</option>
              {suggestApiClient!.data!.map((v) => {
                return (
                  <option key={v.id} value={v.id}>
                    {v.name}
                  </option>
                );
              })}
            </Form.Select>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
