import React, { Fragment } from 'react';
import { formatISODate, uuid, when } from '../../../../../utils/functions';
import { BadgeHead } from '../../../../atoms/BadgeHead';
import { SideBy } from '../../../../atoms/SideBy';
import { TinyHead } from '../../../../atoms/TinyHead';
import { Indent } from './Indent';

export const Post: React.FC<{
  header: string;
  indent: number;
  posts: {
    text?: string;
    messageForCustomer?: string;
    messageForExecutiveStaff?: string;
    roleName: string;
    id: number;
    name: string;
    createAt?: string;
    ticketUrl?: string;
  }[];
}> = React.memo(({ header, indent, posts }) => (
  <Indent depth={indent}>
    <TinyHead>{header}</TinyHead>
    {posts.map(
      ({ text, messageForCustomer, messageForExecutiveStaff, roleName: role, id, name, createAt: at, ticketUrl }) => (
        <Fragment key={uuid()}>
          <p>
            {text
              ? text.split(/\n/).map((line, i, { length }) => (
                  <span key={uuid()}>
                    {line}
                    {i !== length - 1 ? <br /> : <></>}
                  </span>
                ))
              : ''}
          </p>
          {when(!!messageForCustomer, <TinyHead>ユーザに関するメッセージ</TinyHead>)}
          <p>
            {messageForCustomer
              ? messageForCustomer.split(/\n/).map((line, i, { length }) => (
                  <span key={uuid()}>
                    {line}
                    {i !== length - 1 ? <br /> : <></>}
                  </span>
                ))
              : ''}
          </p>
          {when(!!messageForExecutiveStaff, <TinyHead>事務所へのメッセージ</TinyHead>)}
          <p>
            {messageForExecutiveStaff
              ? messageForExecutiveStaff.split(/\n/).map((line, i, { length }) => (
                  <span key={uuid()}>
                    {line}
                    {i !== length - 1 ? <br /> : <></>}
                  </span>
                ))
              : ''}
          </p>
          <SideBy style={{ color: 'gray' }}>
            <BadgeHead>{role}</BadgeHead>
            <p>ID: {id}</p>
            <p>{name}</p>
          </SideBy>
          {at ? <p style={{ color: 'gray' }}>{formatISODate(at, 'yyyy-MM-dd HH:mm:ss')}</p> : <></>}
          {ticketUrl && (
            <>
              <TinyHead>エスカレチケットURL</TinyHead>
              <a href={ticketUrl}>{ticketUrl}</a>
            </>
          )}
        </Fragment>
      )
    )}
  </Indent>
));
