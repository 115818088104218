import React, { useMemo, useCallback } from 'react';
import { Button, Form } from 'react-bootstrap';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, formatISODate, when } from '../../../utils/functions';
import { Tag } from '../../atoms/Tag';
import type { MailHistoryListOemState } from '../../pages/MailHistory/MailHistoryListRoiPage';
import { MailHistoryModal } from '../Modal/MailHistoryModal';
import { PaginationTable } from './PaginationTable';

interface Props {
  dataList: MailHistoryListOemState['list'];
}

export interface MailHistoryListTableState {
  itemNum: number;
  textFlg: boolean;
  isModal: boolean;
}

export type TestIds = 'table' | 'textFlg' | 'subject-button';

export const MailHistoryListTable: React.FC<Props> = React.memo(({ dataList }) => {
  const testId = createTestId<TestIds>(MailHistoryListTable);

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<MailHistoryListTableState>({
    itemNum: 0,
    textFlg: false,
    isModal: false,
  });
  const setModal = useBindSet('isModal');

  const MAX_RECORD_PER_PAGE = useMemo(() => 20, []);

  const tableData: {
    label: string;
    name: keyof MailHistoryListOemState['list'][0];
  }[] = useMemo(() => {
    return [
      { label: '送信日時', name: 'sendAt' },
      { label: 'ID', name: 'id' },
      { label: 'メール種類', name: 'mailTypeName' },
      { label: '種別', name: 'deliverTypeName' },
      { label: 'メールアドレス', name: 'mailAddress' },
      { label: '件名', name: 'subject' },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (item: MailHistoryListOemState['list'][0], labelName: keyof MailHistoryListOemState['list'][0], i: number) => {
      if (labelName === 'sendAt') {
        return item.sendAt ? formatISODate(item.sendAt, DATETIME_DISPLAY_FORMAT) : item.sendStatus;
      }
      if (labelName === 'mailTypeName' || labelName === 'deliverTypeName') {
        return <Tag label={item[labelName]} />;
      }
      if (labelName === 'subject') {
        return (
          <Button
            variant="link"
            className="text-dark text-decoration-underline"
            data-testid={testId('subject-button', i)}
            onClick={() => {
              mergeState({ isModal: true, itemNum: i });
            }}
          >
            {item.subject}
          </Button>
        );
      }
      return item[labelName];
    },
    [mergeState, testId]
  );

  const labelList = useMemo(() => ['送信日時', 'ID', 'メール種類', '種別', 'メールアドレス', '件名'], []);

  return (
    <>
      <MailHistoryModal isModal={$.isModal} item={dataList[$.itemNum]} setModal={setModal} />
      <div className="d-flex justify-content-end me-4">
        <span className="me-2">メール本文表示</span>
        <Form.Group controlId="textFlg" data-testid={testId('textFlg')}>
          <Form.Check type="switch" onChange={() => mergeState({ textFlg: !$.textFlg })} checked={$.textFlg} />
        </Form.Group>
      </div>
      <PaginationTable
        data-testid={testId('table')}
        resultNum={MAX_RECORD_PER_PAGE}
        list={dataList}
        headerNames={labelList}
      >
        {(list) => {
          return (list as MailHistoryListOemState['list']).map((item, i) => {
            return (
              <React.Fragment key={item.id}>
                <tr className="align-middle" key={`${item.id}-1`}>
                  {tableData.map(({ name }) => (
                    <td key={name} rowSpan={name === 'sendAt' && $.textFlg ? 2 : 1}>
                      {createDisplayElement(item, name, i)}
                    </td>
                  ))}
                </tr>
                {when(
                  $.textFlg,
                  <tr className="align-middle mt-2" key={`${item.id}-2`}>
                    <td colSpan={5}>{item.text.replace(/\r?\n/g, '')}</td>
                  </tr>
                )}
              </React.Fragment>
            );
          });
        }}
      </PaginationTable>
    </>
  );
});
