/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OemEventMessageListOutputResponse } from '../domains';
/**
 * OemEventMessageListApi - axios parameter creator
 * @export
 */
export const OemEventMessageListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * イベントメッセージを照会する
         * @summary イベントメッセージ照会
         * @param {number} [eventMessageId] 
         * @param {number} [eventType] 
         * @param {number} [sendStatus] 
         * @param {number} [applyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemEventMessageList: async (eventMessageId?: number, eventType?: number, sendStatus?: number, applyId?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ome-event-message-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventMessageId !== undefined) {
                localVarQueryParameter['eventMessageId'] = eventMessageId;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['eventType'] = eventType;
            }

            if (sendStatus !== undefined) {
                localVarQueryParameter['sendStatus'] = sendStatus;
            }

            if (applyId !== undefined) {
                localVarQueryParameter['applyId'] = applyId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OemEventMessageListApi - functional programming interface
 * @export
 */
export const OemEventMessageListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OemEventMessageListApiAxiosParamCreator(configuration)
    return {
        /**
         * イベントメッセージを照会する
         * @summary イベントメッセージ照会
         * @param {number} [eventMessageId] 
         * @param {number} [eventType] 
         * @param {number} [sendStatus] 
         * @param {number} [applyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemEventMessageList(eventMessageId?: number, eventType?: number, sendStatus?: number, applyId?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OemEventMessageListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemEventMessageList(eventMessageId, eventType, sendStatus, applyId, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OemEventMessageListApi - factory interface
 * @export
 */
export const OemEventMessageListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OemEventMessageListApiFp(configuration)
    return {
        /**
         * イベントメッセージを照会する
         * @summary イベントメッセージ照会
         * @param {number} [eventMessageId] 
         * @param {number} [eventType] 
         * @param {number} [sendStatus] 
         * @param {number} [applyId] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemEventMessageList(eventMessageId?: number, eventType?: number, sendStatus?: number, applyId?: number, customerId?: number, options?: any): AxiosPromise<Array<OemEventMessageListOutputResponse>> {
            return localVarFp.oemEventMessageList(eventMessageId, eventType, sendStatus, applyId, customerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OemEventMessageListApi - object-oriented interface
 * @export
 * @class OemEventMessageListApi
 * @extends {BaseAPI}
 */
export class OemEventMessageListApi extends BaseAPI {
    /**
     * イベントメッセージを照会する
     * @summary イベントメッセージ照会
     * @param {number} [eventMessageId] 
     * @param {number} [eventType] 
     * @param {number} [sendStatus] 
     * @param {number} [applyId] 
     * @param {number} [customerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OemEventMessageListApi
     */
    public oemEventMessageList(eventMessageId?: number, eventType?: number, sendStatus?: number, applyId?: number, customerId?: number, options?: AxiosRequestConfig) {
        return OemEventMessageListApiFp(this.configuration).oemEventMessageList(eventMessageId, eventType, sendStatus, applyId, customerId, options).then((request) => request(this.axios, this.basePath));
    }
}
