/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { MailQueueListOutputResponse } from '../domains';
/**
 * MailQueueListApi - axios parameter creator
 * @export
 */
export const MailQueueListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * mailQueue一覧を取得する
         * @summary mailQueue一覧取得
         * @param {number} [id] 
         * @param {number} [mailType] 
         * @param {number} [mailDeliverType] 
         * @param {number} [sendState] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailQueueList: async (id?: number, mailType?: number, mailDeliverType?: number, sendState?: number, limit?: number, offset?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/mail-queue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (mailType !== undefined) {
                localVarQueryParameter['mailType'] = mailType;
            }

            if (mailDeliverType !== undefined) {
                localVarQueryParameter['mailDeliverType'] = mailDeliverType;
            }

            if (sendState !== undefined) {
                localVarQueryParameter['sendState'] = sendState;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailQueueListApi - functional programming interface
 * @export
 */
export const MailQueueListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailQueueListApiAxiosParamCreator(configuration)
    return {
        /**
         * mailQueue一覧を取得する
         * @summary mailQueue一覧取得
         * @param {number} [id] 
         * @param {number} [mailType] 
         * @param {number} [mailDeliverType] 
         * @param {number} [sendState] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailQueueList(id?: number, mailType?: number, mailDeliverType?: number, sendState?: number, limit?: number, offset?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MailQueueListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailQueueList(id, mailType, mailDeliverType, sendState, limit, offset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailQueueListApi - factory interface
 * @export
 */
export const MailQueueListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailQueueListApiFp(configuration)
    return {
        /**
         * mailQueue一覧を取得する
         * @summary mailQueue一覧取得
         * @param {number} [id] 
         * @param {number} [mailType] 
         * @param {number} [mailDeliverType] 
         * @param {number} [sendState] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailQueueList(id?: number, mailType?: number, mailDeliverType?: number, sendState?: number, limit?: number, offset?: number, options?: any): AxiosPromise<Array<MailQueueListOutputResponse>> {
            return localVarFp.mailQueueList(id, mailType, mailDeliverType, sendState, limit, offset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailQueueListApi - object-oriented interface
 * @export
 * @class MailQueueListApi
 * @extends {BaseAPI}
 */
export class MailQueueListApi extends BaseAPI {
    /**
     * mailQueue一覧を取得する
     * @summary mailQueue一覧取得
     * @param {number} [id] 
     * @param {number} [mailType] 
     * @param {number} [mailDeliverType] 
     * @param {number} [sendState] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailQueueListApi
     */
    public mailQueueList(id?: number, mailType?: number, mailDeliverType?: number, sendState?: number, limit?: number, offset?: number, options?: AxiosRequestConfig) {
        return MailQueueListApiFp(this.configuration).mailQueueList(id, mailType, mailDeliverType, sendState, limit, offset, options).then((request) => request(this.axios, this.basePath));
    }
}
