import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, ListGroup, Modal } from 'react-bootstrap';
import {
  BranchSelectionInfoOutputResponse,
  EnqueteInfoOutputResponse,
  EnqueteQuestionBranchOutputResponse,
  EnqueteQuestionSettingOutputResponse,
} from '../../../api-client';

export interface Props {
  data?: EnqueteInfoOutputResponse;
  selectedBranch: EnqueteQuestionBranchOutputResponse | undefined;
  showFlg: boolean;
  setShowFlg: React.Dispatch<React.SetStateAction<boolean>>;
  selectedQuestionId: number | undefined;
  selectedQuestionIdx: number;
  branchList: EnqueteQuestionBranchOutputResponse[] | undefined;
  setBranchList: React.Dispatch<React.SetStateAction<EnqueteQuestionBranchOutputResponse[] | undefined>>;
}

export const JumpQuestionSelectModal: React.VFC<Props> = ({
  data,
  showFlg,
  setShowFlg,
  selectedBranch,
  selectedQuestionId,
  selectedQuestionIdx,
  branchList,
  setBranchList,
}) => {
  // 検索パラメータ
  const [searchQuestionParam, setSearchQuestionParam] = useState<string>();
  // 絞り込み検索結果用＆設問リスト標準表示
  const [searchResultList, setSearchResultList] = useState<EnqueteQuestionSettingOutputResponse[] | undefined>([]);
  const [searchFlg, setSearchFlg] = useState<boolean>(true);

  // 選択アイテムのアクティブキー
  const [activeKey, setActiveKey] = useState<string>('0');

  useEffect(() => {
    if (!data || selectedQuestionIdx === 0) return;
    const questionIdList: number[] = [];
    // 選択中の設問IDをセット
    questionIdList.push(data.enqueteQuestionsList[selectedQuestionIdx].questionId);
    // 紐づけている設問IDをセット
    branchList?.forEach((_) => {
      questionIdList.push(_.conditionQuestionId);
    });

    // 直前の設問が既に分岐設問として紐づけられているかを判定、紐づけられていれば表示しない。
    if (
      questionIdList.filter((_) => {
        return _ === data.enqueteQuestionsList[selectedQuestionIdx - 1].questionId;
      }).length > 0
    ) {
      return;
    }

    const searched: EnqueteQuestionSettingOutputResponse[] = [];
    searched.push(data.enqueteQuestionsList[selectedQuestionIdx - 1]);
    setSearchResultList(searched);
  }, [selectedQuestionIdx]);

  // テキストChangeイベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'searchQuestion':
        setSearchQuestionParam(event.target.value === '' ? undefined : event.target.value);
        break;
      case 'searchFlg':
        if (event.target.checked) {
          if (data !== undefined && selectedQuestionIdx !== 0) {
            const questionIdList: number[] = [];
            // 選択中の設問IDをセット
            questionIdList.push(data.enqueteQuestionsList[selectedQuestionIdx].questionId);
            // 紐づけている設問IDをセット
            branchList?.forEach((_) => {
              questionIdList.push(_.conditionQuestionId);
            });

            // 元の設問一覧の設問List
            const formerQuestionList: number[] = [];
            data.enqueteQuestionsList.forEach((_) => {
              formerQuestionList.push(_.questionId);
            });

            // 直前の設問が既に分岐設問として紐づけられているかを判定、紐づけられていれば表示しない。
            if (
              questionIdList.filter((_) => {
                return _ === data.enqueteQuestionsList[selectedQuestionIdx - 1].questionId;
              }).length > 0
            ) {
              setSearchResultList(undefined);
              break;
            }

            const searched: EnqueteQuestionSettingOutputResponse[] = [];
            searched.push(data.enqueteQuestionsList[selectedQuestionIdx - 1]);
            setSearchResultList(searched);
            break;
          } else {
            setSearchResultList(undefined);
            break;
          }
        }
        if (data !== undefined) {
          const questionIdList2: number[] = [];
          // 選択中の設問IDをセット
          questionIdList2.push(data.enqueteQuestionsList[selectedQuestionIdx].questionId);
          // 紐づけている設問IDをセット
          branchList?.forEach((_) => {
            questionIdList2.push(_.conditionQuestionId);
          });

          // 元の設問一覧の設問List
          const formerQuestionList2: number[] = [];
          data.enqueteQuestionsList.forEach((_) => {
            formerQuestionList2.push(_.questionId);
          });

          // 元の設問Listと「紐づけている設問（選択中の設問を除く）」の差分となる設問Listを取得
          const differenceQustionIdList2 = formerQuestionList2.filter((row) => {
            return questionIdList2?.indexOf(row) === -1;
          });

          const newList: EnqueteQuestionSettingOutputResponse[] = [];
          // 差分のListを一つ一つ詰める
          differenceQustionIdList2.forEach((_) => {
            newList.push(
              data.enqueteQuestionsList
                .filter((expectData) => {
                  return expectData.questionId !== 0;
                })
                .filter((row) => {
                  return row.questionId === _;
                })[0]
            );
          });
          setSearchResultList(newList);
        }

        break;
      default:
        break;
    }
  };

  // 検索ボタンイベント
  const handleSerachQuestion = () => {
    if (searchQuestionParam !== undefined) {
      const serachResult: EnqueteQuestionSettingOutputResponse[] | undefined = data?.enqueteQuestionsList.filter(
        (value) => new RegExp(searchQuestionParam, 'ig').test(value.content)
      );

      if (serachResult === undefined) {
        if (data !== undefined) {
          setSearchResultList(data.enqueteQuestionsList);
        }
      } else {
        setActiveKey('0');
        setSearchResultList(serachResult);
      }
    } else {
      setSearchResultList(data?.enqueteQuestionsList);
    }
  };

  // キャンセル Or 閉じるイベント
  const onHide = () => {
    setShowFlg(false);
    setSearchFlg(true);
    setActiveKey('0');
    if (data !== undefined && selectedQuestionIdx !== 0) {
      const searched: EnqueteQuestionSettingOutputResponse[] = [];
      searched.push(data?.enqueteQuestionsList[selectedQuestionIdx - 1]);
      setSearchResultList(searched);
    }

    if (data !== undefined && selectedQuestionIdx === 0) {
      setSearchResultList(undefined);
    }
  };

  // 保存イベント
  const onSubmit = () => {
    if (searchResultList) {
      let selections: BranchSelectionInfoOutputResponse[] = [];
      selections = searchResultList[Number(activeKey)].selections.map((it) => {
        return {
          selectionId: it.selectionId,
          content: it.content,
          selectFlg: false,
        } as BranchSelectionInfoOutputResponse;
      });

      const newBranch = {
        condition: true,
        conditionQuestionId: searchResultList[Number(activeKey)].questionId,
        selections,
        targetQuestionId: selectedQuestionId,
      } as EnqueteQuestionBranchOutputResponse;
      setBranchList([newBranch]);
    }

    setShowFlg(false);
    setSearchFlg(true);
    setActiveKey('0');
  };

  return (
    <Modal size="lg" show={showFlg} onHide={onHide} scrollable centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="d-flex justify-content-end mb-4">
          <Button variant="link" className="ms-2" onClick={onHide}>
            キャンセル
          </Button>
          <Button className="ms-2" onClick={onSubmit}>
            保存
          </Button>
        </div>
        <InputGroup className="bg-light p-2 mb-4">
          <Form.Control
            data-testid="searchText"
            type="text"
            placeholder="設問を検索"
            id="searchQuestion"
            onChange={onChangeText}
          />
          {/* TODO 検索処理実装 */}
          <Button variant="primary" onClick={handleSerachQuestion} data-testid="searchButton">
            <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
          </Button>
        </InputGroup>
        <div className="me-3">
          <input
            data-testid="filterButton"
            type="checkbox"
            className="btn-check "
            id="searchFlg"
            autoComplete="off"
            defaultChecked={searchFlg}
            onClick={() => {
              setSearchFlg(!searchFlg);
            }}
            onChange={onChangeText}
          />
          <label className="btn btn-outline-primary rounded-pill" htmlFor="searchFlg">
            直前の設問
          </label>
        </div>
        <ListGroup className="mt-4 mb-4" activeKey={activeKey}>
          {searchResultList?.map((row, index) => {
            return (
              <ListGroup.Item
                key={index}
                action
                href={index.toString()}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveKey(index.toString());
                }}
              >
                {row.content}
                <br />
                {row.answerMethod === 1 && 'テキスト'}
                {row.answerMethod === 2 && 'ラジオボタン単一選択'}
                {row.answerMethod === 3 && 'プルダウン単一選択'}
                {row.answerMethod === 4 && '複数選択'}
                {row.answerMethod === 5 && 'マトリクス単一選択'}
                {row.answerMethod === 6 && 'マトリクス複数選択'}
                {row.answerMethod === 7 && '画像撮影'}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </Modal.Body>
    </Modal>
  );
};
