/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { SqlQueryCreateFormResponse } from '../domains';
// @ts-ignore
import { SqlQueryInfoOutputResponse } from '../domains';
// @ts-ignore
import { SqlQueryListParentOutputResponse } from '../domains';
// @ts-ignore
import { SqlQueryUpdateFormResponse } from '../domains';
/**
 * SqlQueryApi - axios parameter creator
 * @export
 */
export const SqlQueryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * SQLクエリコピーを取得する
         * @summary SQLクエリコピー取得
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryCopy: async (sqlQueryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlQueryId' is not null or undefined
            assertParamExists('sqlQueryCopy', 'sqlQueryId', sqlQueryId)
            const localVarPath = `/api/sql-query-copy/{sql-query-id}`
                .replace(`{${"sql-query-id"}}`, encodeURIComponent(String(sqlQueryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLクエリを登録する
         * @summary SQLクエリ登録
         * @param {SqlQueryCreateFormResponse} sqlQueryCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryCreate: async (sqlQueryCreateFormResponse: SqlQueryCreateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlQueryCreateFormResponse' is not null or undefined
            assertParamExists('sqlQueryCreate', 'sqlQueryCreateFormResponse', sqlQueryCreateFormResponse)
            const localVarPath = `/api/sql-query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sqlQueryCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLクエリ情報を削除する
         * @summary SQLクエリ情報削除
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryDelete: async (sqlQueryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlQueryId' is not null or undefined
            assertParamExists('sqlQueryDelete', 'sqlQueryId', sqlQueryId)
            const localVarPath = `/api/sql-query/{sql-query-id}`
                .replace(`{${"sql-query-id"}}`, encodeURIComponent(String(sqlQueryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLクエリ情報を取得する
         * @summary SQLクエリ情報取得
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryInfo: async (sqlQueryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlQueryId' is not null or undefined
            assertParamExists('sqlQueryInfo', 'sqlQueryId', sqlQueryId)
            const localVarPath = `/api/sql-query/{sql-query-id}`
                .replace(`{${"sql-query-id"}}`, encodeURIComponent(String(sqlQueryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLクエリ一覧を取得する
         * @summary SQLクエリ一覧取得
         * @param {number} accountId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryList: async (accountId: number, page?: number, size?: number, sort?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('sqlQueryList', 'accountId', accountId)
            const localVarPath = `/api/sql-query-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLクエリ実行結果ファイルダウンロードする
         * @summary SQLクエリ実行結果ファイルダウンロード
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryResultFile: async (sqlQueryId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlQueryId' is not null or undefined
            assertParamExists('sqlQueryResultFile', 'sqlQueryId', sqlQueryId)
            const localVarPath = `/api/sql-query-result-file/{sql-query-id}`
                .replace(`{${"sql-query-id"}}`, encodeURIComponent(String(sqlQueryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLクエリを更新する
         * @summary SQLクエリ更新
         * @param {number} sqlQueryId 
         * @param {SqlQueryUpdateFormResponse} sqlQueryUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryUpdate: async (sqlQueryId: number, sqlQueryUpdateFormResponse: SqlQueryUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlQueryId' is not null or undefined
            assertParamExists('sqlQueryUpdate', 'sqlQueryId', sqlQueryId)
            // verify required parameter 'sqlQueryUpdateFormResponse' is not null or undefined
            assertParamExists('sqlQueryUpdate', 'sqlQueryUpdateFormResponse', sqlQueryUpdateFormResponse)
            const localVarPath = `/api/sql-query/{sql-query-id}`
                .replace(`{${"sql-query-id"}}`, encodeURIComponent(String(sqlQueryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sqlQueryUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SqlQueryApi - functional programming interface
 * @export
 */
export const SqlQueryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SqlQueryApiAxiosParamCreator(configuration)
    return {
        /**
         * SQLクエリコピーを取得する
         * @summary SQLクエリコピー取得
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlQueryCopy(sqlQueryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IncResultOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlQueryCopy(sqlQueryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLクエリを登録する
         * @summary SQLクエリ登録
         * @param {SqlQueryCreateFormResponse} sqlQueryCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlQueryCreate(sqlQueryCreateFormResponse: SqlQueryCreateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlQueryCreate(sqlQueryCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLクエリ情報を削除する
         * @summary SQLクエリ情報削除
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlQueryDelete(sqlQueryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlQueryDelete(sqlQueryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLクエリ情報を取得する
         * @summary SQLクエリ情報取得
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlQueryInfo(sqlQueryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SqlQueryInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlQueryInfo(sqlQueryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLクエリ一覧を取得する
         * @summary SQLクエリ一覧取得
         * @param {number} accountId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlQueryList(accountId: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SqlQueryListParentOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlQueryList(accountId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLクエリ実行結果ファイルダウンロードする
         * @summary SQLクエリ実行結果ファイルダウンロード
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlQueryResultFile(sqlQueryId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlQueryResultFile(sqlQueryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLクエリを更新する
         * @summary SQLクエリ更新
         * @param {number} sqlQueryId 
         * @param {SqlQueryUpdateFormResponse} sqlQueryUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlQueryUpdate(sqlQueryId: number, sqlQueryUpdateFormResponse: SqlQueryUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlQueryUpdate(sqlQueryId, sqlQueryUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SqlQueryApi - factory interface
 * @export
 */
export const SqlQueryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SqlQueryApiFp(configuration)
    return {
        /**
         * SQLクエリコピーを取得する
         * @summary SQLクエリコピー取得
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryCopy(sqlQueryId: number, options?: any): AxiosPromise<Array<IncResultOutputResponse>> {
            return localVarFp.sqlQueryCopy(sqlQueryId, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLクエリを登録する
         * @summary SQLクエリ登録
         * @param {SqlQueryCreateFormResponse} sqlQueryCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryCreate(sqlQueryCreateFormResponse: SqlQueryCreateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.sqlQueryCreate(sqlQueryCreateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLクエリ情報を削除する
         * @summary SQLクエリ情報削除
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryDelete(sqlQueryId: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.sqlQueryDelete(sqlQueryId, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLクエリ情報を取得する
         * @summary SQLクエリ情報取得
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryInfo(sqlQueryId: number, options?: any): AxiosPromise<SqlQueryInfoOutputResponse> {
            return localVarFp.sqlQueryInfo(sqlQueryId, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLクエリ一覧を取得する
         * @summary SQLクエリ一覧取得
         * @param {number} accountId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryList(accountId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<SqlQueryListParentOutputResponse> {
            return localVarFp.sqlQueryList(accountId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLクエリ実行結果ファイルダウンロードする
         * @summary SQLクエリ実行結果ファイルダウンロード
         * @param {number} sqlQueryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryResultFile(sqlQueryId: number, options?: any): AxiosPromise<string> {
            return localVarFp.sqlQueryResultFile(sqlQueryId, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLクエリを更新する
         * @summary SQLクエリ更新
         * @param {number} sqlQueryId 
         * @param {SqlQueryUpdateFormResponse} sqlQueryUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlQueryUpdate(sqlQueryId: number, sqlQueryUpdateFormResponse: SqlQueryUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.sqlQueryUpdate(sqlQueryId, sqlQueryUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SqlQueryApi - object-oriented interface
 * @export
 * @class SqlQueryApi
 * @extends {BaseAPI}
 */
export class SqlQueryApi extends BaseAPI {
    /**
     * SQLクエリコピーを取得する
     * @summary SQLクエリコピー取得
     * @param {number} sqlQueryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlQueryApi
     */
    public sqlQueryCopy(sqlQueryId: number, options?: AxiosRequestConfig) {
        return SqlQueryApiFp(this.configuration).sqlQueryCopy(sqlQueryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLクエリを登録する
     * @summary SQLクエリ登録
     * @param {SqlQueryCreateFormResponse} sqlQueryCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlQueryApi
     */
    public sqlQueryCreate(sqlQueryCreateFormResponse: SqlQueryCreateFormResponse, options?: AxiosRequestConfig) {
        return SqlQueryApiFp(this.configuration).sqlQueryCreate(sqlQueryCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLクエリ情報を削除する
     * @summary SQLクエリ情報削除
     * @param {number} sqlQueryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlQueryApi
     */
    public sqlQueryDelete(sqlQueryId: number, options?: AxiosRequestConfig) {
        return SqlQueryApiFp(this.configuration).sqlQueryDelete(sqlQueryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLクエリ情報を取得する
     * @summary SQLクエリ情報取得
     * @param {number} sqlQueryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlQueryApi
     */
    public sqlQueryInfo(sqlQueryId: number, options?: AxiosRequestConfig) {
        return SqlQueryApiFp(this.configuration).sqlQueryInfo(sqlQueryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLクエリ一覧を取得する
     * @summary SQLクエリ一覧取得
     * @param {number} accountId 
     * @param {number} [page] Zero-based page index (0..N)
     * @param {number} [size] The size of the page to be returned
     * @param {Array<string>} [sort] Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlQueryApi
     */
    public sqlQueryList(accountId: number, page?: number, size?: number, sort?: Array<string>, options?: AxiosRequestConfig) {
        return SqlQueryApiFp(this.configuration).sqlQueryList(accountId, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLクエリ実行結果ファイルダウンロードする
     * @summary SQLクエリ実行結果ファイルダウンロード
     * @param {number} sqlQueryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlQueryApi
     */
    public sqlQueryResultFile(sqlQueryId: number, options?: AxiosRequestConfig) {
        return SqlQueryApiFp(this.configuration).sqlQueryResultFile(sqlQueryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLクエリを更新する
     * @summary SQLクエリ更新
     * @param {number} sqlQueryId 
     * @param {SqlQueryUpdateFormResponse} sqlQueryUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlQueryApi
     */
    public sqlQueryUpdate(sqlQueryId: number, sqlQueryUpdateFormResponse: SqlQueryUpdateFormResponse, options?: AxiosRequestConfig) {
        return SqlQueryApiFp(this.configuration).sqlQueryUpdate(sqlQueryId, sqlQueryUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
