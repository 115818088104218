import React, { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import type { CorrectionTemplateListOutputResponse } from '../../../../../api-client';
import { useLargeState } from '../../../../../hooks/useLargeState';
import { Button } from '../../../../atoms/Button';
import { TemplateFormBodyState } from '../../../../pages/Correction/constants';
import { CommonBody } from '../../TemplateForm/bodies/CommonBody';

export const AnswerForm: React.FC<{
  templateList?: CorrectionTemplateListOutputResponse[];
  onSubmit: (state?: TemplateFormBodyState) => void;
}> = ({ templateList, onSubmit }) => {
  const { state: f, mergeState } = useLargeState<{
    templateFormState?: TemplateFormBodyState;
  }>({});
  const onChangeTemplateForm = useCallback(
    (state) => {
      mergeState({ templateFormState: state });
    },
    [mergeState]
  );
  return (
    <Card body>
      <CommonBody name="回答" templateList={templateList ?? []} onChange={onChangeTemplateForm} />
      <Button
        onClick={() => {
          const { templateFormState } = f;
          if (!templateFormState?.content) return;
          onSubmit(templateFormState);
        }}
      >
        送信
      </Button>
    </Card>
  );
};
