import React, { useMemo, useCallback } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { Url } from '../../../constants/Url';
import { createTestId, formatISODate, uuid } from '../../../utils/functions';
import type { CustomerEditHistoryState } from '../../pages/Customer/CustomerEditHistoryPage';
import { PaginationTable } from './PaginationTable';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';

interface Props {
  dataList: CustomerEditHistoryState['list'];
}
export type TestIds = 'table' | 'link-button' | 'icon-button';

export const CustomerEditHistoryTable: React.FC<Props> = React.memo(({ dataList }) => {
  const testId = createTestId<TestIds>(CustomerEditHistoryTable);
  const history = useHistory();
  const MAX_RECORD_PER_PAGE = useMemo(() => 20, []);

  const tableData: {
    label: string;
    name: keyof CustomerEditHistoryState['list'][0];
  }[] = useMemo(() => {
    return [
      { label: '変更日時', name: 'changeAt' },
      { label: '変更箇所', name: 'changeContents' },
      { label: '変更理由', name: 'changeReason' },
      { label: '変更者', name: 'changeBy' },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (item: CustomerEditHistoryState['list'][0], labelName: keyof CustomerEditHistoryState['list'][0]) => {
      if (labelName === 'changeAt') {
        return item.changeAt ? formatISODate(item.changeAt, DATETIME_DISPLAY_FORMAT) : '';
      }

      return item[labelName];
    },
    []
  );

  return (
    <PaginationTable
      data-testid={testId('table')}
      resultNum={MAX_RECORD_PER_PAGE}
      list={dataList}
      headerNames={['変更日時', '変更箇所', '変更理由', '変更者']}
    >
      {(list) => {
        return (list as CustomerEditHistoryState['list']).map((item, i) => {
          return (
            <tr className="align-middle" key={uuid()}>
              {tableData.map(({ name }) => (
                <td key={name}>{createDisplayElement(item, name)}</td>
              ))}
            </tr>
          );
        });
      }}
    </PaginationTable>
  );
});
