import { faClipboardList, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { MonitorRuleSetListOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { Button } from '../../atoms/Button';

export interface Props {
  listPerPage: MonitorRuleSetListOutputResponse[];
  onCopy: (id: number) => void;
}

export const MonitorRuleSetTable: React.VFC<Props> = ({ listPerPage, onCopy }) => {
  const history = useHistory();

  const detailTooltip = (
    <Tooltip id="button-tooltip" style={{ maxWidth: '25rem' }}>
      詳細確認
    </Tooltip>
  );

  const copyTooltip = (
    <Tooltip id="button-tooltip" style={{ maxWidth: '25rem' }}>
      コピー作成
    </Tooltip>
  );

  return (
    <Table className="mb-4">
      <thead>
        <tr className="align-middle">
          <th>ID</th>
          <th>モニタールールセット名</th>
          <th>クライアント</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((item: MonitorRuleSetListOutputResponse) => {
          return (
            <tr key={item.monitorRuleSetId.toString()} className="align-middle">
              <td>
                <span>{item.monitorRuleSetId}</span>
              </td>
              <td>
                {/* eslint-disable-next-line react/jsx-no-comment-textnodes,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
                <a
                  href={`${Url.KEISAI.MONITOR_RULE_SET_MODIFY}/${item.monitorRuleSetId}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.monitorRuleSetName}
                </a>
                <br />
              </td>
              <td>
                {item.clientList?.map((clientItem: any, i) => {
                  return (
                    <span key={i.toString()}>
                      {clientItem}
                      <br />
                    </span>
                  );
                })}
              </td>
              <td width="15%">
                <div style={{ textAlign: 'center' }}>
                  <OverlayTrigger placement="top-end" overlay={detailTooltip} rootClose>
                    <span>
                      <a
                        href={`${Url.KEISAI.MONITOR_RULE_SET_DETAIL}/${item.monitorRuleSetId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faClipboardList}
                          className="text-secondary flex-shrink-0 me-1"
                          fixedWidth
                        />
                      </a>
                    </span>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top-end" overlay={copyTooltip} rootClose>
                    <span>
                      <Button
                        data-testid="copy"
                        variant="link"
                        className="text-secondary"
                        onClick={() => {
                          onCopy(item.monitorRuleSetId);
                        }}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </Button>
                    </span>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
