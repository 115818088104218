import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import type { DispatchSetState, NestedPartial } from '../../../interfaces/utils';
import { MatterHandOverSalesInfoOutputResponse } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { when } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';

export interface Props {
  isModal: boolean;
  setIsModal: DispatchSetState<boolean>;
  matterHandoverSalesList: Partial<MatterHandOverSalesInfoOutputResponse>[];
  setMatterHandoverSalesList: DispatchSetState<Partial<MatterHandOverSalesInfoOutputResponse>[]>;
  editFlg: boolean;
}

export interface State {
  list: NestedPartial<MatterHandOverSalesInfoOutputResponse>[];
}

const TYPE = {
  CATEGORY: 0,
  START_DATE: 1,
  SHOP_URL: 2,
  PRE_ENQUETE: 3,
  POST_ENQUETE: 4,
  OEM_SETTING: 5,
  IMAGE_POST: 6,
  IMAGE_POST_OBJECT: 7,
  VIST_WEEKDAY: 8,
  VIST_TIME: 9,
  VIST_VERIFICATION: 10,
  PAYMENT_METHOD: 11,
  MANAGEMENT_GROUPING: 12,
  MANAGEMENT_ACCOUNT: 13,
  OTHER_MATTER: 14,
  INCREASE_BORDER: 15,
  SEARCH_FLG: 16,
  COUNTDOWN: 17,
  OPEN_DATE: 18,
  ORIGIN_SHOP: 19,
  CHANGE_FROM_ORIGIN: 20,
  ADD_ACCOUNT_INFO: 21,
  COMPLETE_DATE: 22,
  FIX_DETAIL: 23,
  DRINK_COMMENT: 24,
  BEAUTY_COMMENT: 25,
  MAIL_COMMENT: 26,
  SHOP_COMMENT: 27,
  STATUS_CHANGE: 100,
  STATUS_CHANGE_DATE: 101,
};

const STATUS_CHANGE = {
  displayStatus: '表示ステータス',
  startAt: '開始日時',
  reasonForHiding: '非表示理由',
} as const;

export const MessageInputModal: React.VFC<Props> = ({
  isModal,
  setIsModal,
  matterHandoverSalesList,
  setMatterHandoverSalesList,
  editFlg,
}) => {
  const { state: $, mergeState } = useLargeState<State>({ list: [] });
  const {
    state: s,
    mergeState: sMergeState,
    onChangeSet: sOnChangeSet,
  } = useLargeState<{ displayStatus?: string; startAt?: string; reasonForHiding?: string }>({});

  const [increaseBorderFlg, setIncreaseBorderFlg] = useState<boolean>(
    matterHandoverSalesList.find((v) => v.type === TYPE.INCREASE_BORDER)
      ? matterHandoverSalesList.find((v) => v.type === TYPE.INCREASE_BORDER)?.content === 'true'
      : false
  );
  const [searchFlg, setSearchFlg] = useState<boolean>(
    matterHandoverSalesList.find((v) => v.type === TYPE.SEARCH_FLG)
      ? matterHandoverSalesList.find((v) => v.type === TYPE.SEARCH_FLG)?.content === 'true'
      : false
  );
  const [hidingReasonErrorFlg, setHidingReasonErrorFlg] = useState<boolean>(false);

  const getContent = (type: number) => {
    return $.list.find((v) => v.type === type)?.content || '';
  };

  const setContent = (type: number, value: string) => {
    mergeState({
      list: $.list.find((v) => v.type === type)
        ? $.list.map((v) => (v.type === type ? { ...v, content: value } : v))
        : [...$.list, { type, content: value }],
    });
  };

  useEffect(() => {
    if (!isModal) return;
    const statusChange = matterHandoverSalesList.find((m) => m.type === TYPE.STATUS_CHANGE)?.content;
    if (statusChange) {
      const sliceContent = (key: typeof STATUS_CHANGE[keyof typeof STATUS_CHANGE]): string | undefined => {
        return statusChange.match(new RegExp(`${key}：(.*?)(,|$)`))
          ? (statusChange.match(new RegExp(`${key}：(.*?)(,|$)`)) as string[])[1]
          : undefined;
      };

      sMergeState({
        displayStatus: sliceContent(STATUS_CHANGE.displayStatus),
        startAt: sliceContent(STATUS_CHANGE.startAt),
        reasonForHiding: sliceContent(STATUS_CHANGE.reasonForHiding),
      });
    }
    const initialData = [];
    if (!matterHandoverSalesList.find((data) => data.type === TYPE.VIST_WEEKDAY)) {
      initialData.push({ type: TYPE.VIST_WEEKDAY, content: '', files: [] });
    }
    if (!matterHandoverSalesList.find((data) => data.type === TYPE.VIST_TIME)) {
      initialData.push({ type: TYPE.VIST_TIME, content: '', files: [] });
    }
    if (!matterHandoverSalesList.find((data) => data.type === TYPE.MANAGEMENT_GROUPING)) {
      initialData.push({ type: TYPE.MANAGEMENT_GROUPING, content: '', files: [] });
    }
    if (!matterHandoverSalesList.find((data) => data.type === TYPE.STATUS_CHANGE)) {
      initialData.push({ type: TYPE.STATUS_CHANGE, content: '', files: [] });
    }
    if (!matterHandoverSalesList.find((data) => data.type === TYPE.OEM_SETTING)) {
      initialData.push({ type: TYPE.OEM_SETTING, content: '', files: [] });
    }
    mergeState({
      list: [...matterHandoverSalesList, ...initialData],
    });
    setIncreaseBorderFlg(
      matterHandoverSalesList.find((v) => v.type === TYPE.INCREASE_BORDER)
        ? matterHandoverSalesList.find((v) => v.type === TYPE.INCREASE_BORDER)?.content === 'true'
        : false
    );
    setSearchFlg(
      matterHandoverSalesList.find((v) => v.type === TYPE.SEARCH_FLG)
        ? matterHandoverSalesList.find((v) => v.type === TYPE.SEARCH_FLG)?.content === 'true'
        : false
    );
  }, [isModal, matterHandoverSalesList, mergeState, sMergeState]);

  useEffect(() => {
    let content = '';
    const entries = Object.entries(s);
    entries.forEach(([key, val]) => {
      content += val ? `${STATUS_CHANGE[key as keyof typeof STATUS_CHANGE]}：${val},` : '';
    });
    if (content) content = content.slice(0, -1);
    mergeState({
      list: $.list.map((m) => (m.type === TYPE.STATUS_CHANGE ? { ...m, content } : m)),
    });
  }, [s, mergeState]);

  const onSave = () => {
    if (s.displayStatus === '非公開' && !s.reasonForHiding) {
      setHidingReasonErrorFlg(true);
      return;
    }
    setMatterHandoverSalesList($.list as any);
    setIsModal(false);
    setHidingReasonErrorFlg(false);
  };

  const categoryOptions: [string, string][] = Object.entries({
    本導入: '本導入',
    完全無料トライアル: '完全無料トライアル',
    有料トライアル: '有料トライアル',
    キャンペーン: 'キャンペーン',
  });
  const imagePostOptions: [string, string][] = Object.entries({
    画像投稿〇: '画像投稿〇',
    '画像投稿×': '画像投稿×',
    コピー元と同様: 'コピー元と同様',
  });
  const managementOptions: [string, string][] = Object.entries({
    あり: 'あり',
    なし: 'なし',
  });
  const outputStatusOptions: [string, string][] = Object.entries({
    掲載準備: '掲載準備',
    公開: '公開',
    限定公開: '限定公開',
    非公開: '非公開',
  });

  const hidingReasonOptions: [string, string][] = Object.entries({
    ユーザ連絡: 'ユーザ連絡',
    休止: '休止',
    修正に伴う非表示: '修正に伴う非表示',
    隔月等実施: '隔月等実施',
    'スポット契約満了（自動更新なし契約）': 'スポット契約満了（自動更新なし契約）',
    '解約（自動更新あり契約）': '解約（自動更新あり契約）',
    閉店: '閉店',
    公開OK: '公開OK',
  });

  const outputOemOptions: [string, string][] = Object.entries({
    '有（マーケに任せる）': '有（マーケに任せる）',
    '無（絶対開放不可）': '無（絶対開放不可）',
    コピー元と同様: 'コピー元と同様',
  });

  const heightChange = (name: string) => {
    // textarea要素
    const $textarea = document.getElementById(name);
    if ($textarea) {
      // textarea要素のpaddingのY軸(高さ)
      const PADDING_Y = 40;
      // textareaそ要素のlineheight
      let { lineHeight } = window.getComputedStyle($textarea!);
      // "19.6px" のようなピクセル値が返ってくるので、数字だけにする
      lineHeight = lineHeight.replace(/[^-\d.]/g, '');

      // textarea要素に入力された値の行数
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const lines = `${$textarea!.innerHTML}\n`.match(/\r\n|\n/g).length;
      // 高さを再計算
      $textarea!.style!.height = `${Number(lineHeight) * lines + PADDING_Y}px`;
    }
  };

  // テキストエリアの高さを設定する
  useEffect(() => {
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const n in TYPE) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      heightChange(`textarea${TYPE[n]}`);
    }
  }, [$.list]);

  return (
    <Modal
      onHide={() => setIsModal(false)}
      isModal={isModal}
      size="lg"
      closeButton
      centered
      body={
        <>
          {when(
            editFlg,
            <div className="d-flex justify-content-end mb-4">
              <Button type="button" className="ms-2" onClick={onSave}>
                保存
              </Button>
            </div>
          )}

          {hidingReasonErrorFlg && <Alert variant="danger">非表示理由を選択してください。</Alert>}
          <Form.Group className="mb-4">
            <Form.Label>分類</Form.Label>
            <Form.Select
              value={getContent(TYPE.CATEGORY)}
              onChange={(e) => setContent(TYPE.CATEGORY, e.target.value)}
              disabled={!editFlg}
            >
              <option value="">&nbsp;</option>
              {categoryOptions.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>掲載開始希望日</Form.Label>
            <Form.Control
              type="date"
              value={getContent(TYPE.START_DATE)}
              onChange={(e) => setContent(TYPE.START_DATE, e.target.value)}
              disabled={!editFlg}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>申込店舗URL</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.SHOP_URL}`}
              value={getContent(TYPE.SHOP_URL)}
              onChange={(e) => setContent(TYPE.SHOP_URL, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>事前アンケート</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.PRE_ENQUETE}`}
              value={getContent(TYPE.PRE_ENQUETE)}
              onChange={(e) => setContent(TYPE.PRE_ENQUETE, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>事後アンケート</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.POST_ENQUETE}`}
              value={getContent(TYPE.POST_ENQUETE)}
              onChange={(e) => setContent(TYPE.POST_ENQUETE, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>OEM設定</Form.Label>
            <Form.Select
              value={getContent(TYPE.OEM_SETTING)}
              onChange={(e) => setContent(TYPE.OEM_SETTING, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            >
              <option value="">&nbsp;</option>
              {outputOemOptions.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>画像投稿</Form.Label>
            <Form.Select
              value={getContent(TYPE.IMAGE_POST)}
              onChange={(e) => setContent(TYPE.IMAGE_POST, e.target.value)}
              disabled={!editFlg}
            >
              <option value="">&nbsp;</option>
              {imagePostOptions.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>画像投稿の目的</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.IMAGE_POST_OBJECT}`}
              value={getContent(TYPE.IMAGE_POST_OBJECT)}
              onChange={(e) => setContent(TYPE.IMAGE_POST_OBJECT, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>来店曜日</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.VIST_WEEKDAY}`}
              value={getContent(TYPE.VIST_WEEKDAY)}
              onChange={(e) => setContent(TYPE.VIST_WEEKDAY, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>来店時間</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.VIST_TIME}`}
              value={getContent(TYPE.VIST_TIME)}
              onChange={(e) => setContent(TYPE.VIST_TIME, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>来店証明</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.VIST_VERIFICATION}`}
              value={getContent(TYPE.VIST_VERIFICATION)}
              onChange={(e) => setContent(TYPE.VIST_VERIFICATION, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>支払方法</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.PAYMENT_METHOD}`}
              value={getContent(TYPE.PAYMENT_METHOD)}
              onChange={(e) => setContent(TYPE.PAYMENT_METHOD, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>管理画面グルーピング</Form.Label>
            <Form.Select
              value={getContent(TYPE.MANAGEMENT_GROUPING)}
              onChange={(e) => setContent(TYPE.MANAGEMENT_GROUPING, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            >
              <option value="">&nbsp;</option>
              {managementOptions.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>管理画面アカウント(クラウド)</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.MANAGEMENT_ACCOUNT}`}
              value={getContent(TYPE.MANAGEMENT_ACCOUNT)}
              onChange={(e) => setContent(TYPE.MANAGEMENT_ACCOUNT, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>飲食申し送り事項</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.DRINK_COMMENT}`}
              value={getContent(TYPE.DRINK_COMMENT)}
              onChange={(e) => setContent(TYPE.DRINK_COMMENT, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>美容申し送り事項</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.BEAUTY_COMMENT}`}
              value={getContent(TYPE.BEAUTY_COMMENT)}
              onChange={(e) => setContent(TYPE.BEAUTY_COMMENT, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>その他伝達事項</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.OTHER_MATTER}`}
              value={getContent(TYPE.OTHER_MATTER)}
              onChange={(e) => setContent(TYPE.OTHER_MATTER, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="d-flex justify-content-start">
              <Form.Label>月中増枠</Form.Label>
              <Form.Check
                type="radio"
                className="ms-5"
                name="INCREASE_BORDER"
                onChange={() => {}}
                onClick={() => {
                  setIncreaseBorderFlg(!increaseBorderFlg);
                  setContent(TYPE.INCREASE_BORDER, `${!increaseBorderFlg}`);
                }}
                checked={increaseBorderFlg}
                disabled={!editFlg}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4">
            <div className="d-flex justify-content-start">
              <Form.Label>Google/Yahoo検索表示フラグ</Form.Label>
              <Form.Check
                type="radio"
                className="ms-5"
                name="SEARCH_FLG"
                onChange={() => {}}
                onClick={() => {
                  setSearchFlg(!searchFlg);
                  setContent(TYPE.SEARCH_FLG, `${!searchFlg}`);
                }}
                checked={searchFlg}
                disabled={!editFlg}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>非表示タイマー・カウントダウン</Form.Label>
            <Form.Control
              type="date"
              value={getContent(TYPE.COUNTDOWN)}
              onChange={(e) => setContent(TYPE.COUNTDOWN, e.target.value)}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>通販申し送り事項</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.MAIL_COMMENT}`}
              value={getContent(TYPE.MAIL_COMMENT)}
              onChange={(e) => setContent(TYPE.MAIL_COMMENT, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>店頭申し送り事項</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.SHOP_COMMENT}`}
              value={getContent(TYPE.SHOP_COMMENT)}
              onChange={(e) => setContent(TYPE.SHOP_COMMENT, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>店舗オープン日</Form.Label>
            <Form.Control
              type="date"
              value={getContent(TYPE.OPEN_DATE)}
              onChange={(e) => setContent(TYPE.OPEN_DATE, e.target.value)}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>コピーする既存店舗情報</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.ORIGIN_SHOP}`}
              value={getContent(TYPE.ORIGIN_SHOP)}
              onChange={(e) => setContent(TYPE.ORIGIN_SHOP, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>既存店舗からの変更点</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.CHANGE_FROM_ORIGIN}`}
              value={getContent(TYPE.CHANGE_FROM_ORIGIN)}
              onChange={(e) => setContent(TYPE.CHANGE_FROM_ORIGIN, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>追加アカウント情報①</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.ADD_ACCOUNT_INFO}`}
              value={getContent(TYPE.ADD_ACCOUNT_INFO)}
              onChange={(e) => setContent(TYPE.ADD_ACCOUNT_INFO, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>完了希望納期</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.COMPLETE_DATE}`}
              value={getContent(TYPE.COMPLETE_DATE)}
              onChange={(e) => setContent(TYPE.COMPLETE_DATE, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>
          <Form.Group className="mb-4">
            <Form.Label>修正/作業内容</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
              id={`textarea${TYPE.FIX_DETAIL}`}
              value={getContent(TYPE.FIX_DETAIL)}
              onChange={(e) => setContent(TYPE.FIX_DETAIL, e.target.value)}
              style={{ resize: 'none' }}
              disabled={!editFlg}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>表示ステータス</Form.Label>
            <Form.Select value={s.displayStatus || ''} onChange={sOnChangeSet('displayStatus')} disabled={!editFlg}>
              <option value="">&nbsp;</option>

              {outputStatusOptions.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>開始日時</Form.Label>
            <Form.Control
              type="datetime-local"
              value={s.startAt || ''}
              onChange={sOnChangeSet('startAt')}
              disabled={!editFlg}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>非表示理由</Form.Label>
            <Form.Select value={s.reasonForHiding || ''} onChange={sOnChangeSet('reasonForHiding')} disabled={!editFlg}>
              <option value="">&nbsp;</option>
              {hidingReasonOptions.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </>
      }
    />
  );
};
