import React, { useCallback, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { CorrectSettingApi } from '../../../api-client';
import type {
  CorrectSettingTargetListFormResponse,
  CorrectSettingTargetListOutputResponse,
  CorrectMethodDisplayOutputResponse,
  ErrorObjectResponse,
} from '../../../api-client';
import { CorrectSettingSearchForms } from '../../organisms/Forms/CorrectSettingSearchForms';
import { CorrectSettingSearchTable } from '../../organisms/Table/CorrectSettingSearchTable';
import { createTestId } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';

export interface CorrectSettingSearchState {
  api: CorrectSettingApi;
  isLoading: boolean;
  list: CorrectSettingTargetListOutputResponse[];
  forms: CorrectSettingTargetListFormResponse;
  correctMethod: CorrectMethodDisplayOutputResponse;
  errorMessages: string[];
}
export type TestIds = 'search-button' | 'link-button';

export type OnDownloadCsvFunc = (id: number) => void;

export const CorrectSettingSearchPage: React.FC = () => {
  const testid = createTestId<TestIds>(CorrectSettingSearchPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<CorrectSettingSearchState>({
    api: new CorrectSettingApi(),
    isLoading: false,
    list: [],
    forms: {},
    correctMethod: { enqueteCorrectMethodList: [], surveyProofCorrectMethodList: [] },
    errorMessages: [],
  });

  const setForms = useBindSet('forms');

  useEffect(() => {
    $.api.correctMethodDisplay().then((res: AxiosResponse<CorrectMethodDisplayOutputResponse>) => {
      if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
        mergeState({ list: [], errorMessages: ['エラーが発生しました。'] });
      } else {
        mergeState({
          correctMethod: res.data,
          errorMessages: [],
        });
      }
    });
  }, [$.api, mergeState]);

  const createSearchBody = useCallback((body: CorrectSettingTargetListFormResponse) => {
    return {
      clientId: body.clientId,
      clientName: body.clientName,
      monitorId: body.monitorId,
      monitorName: body.monitorName,
      enqueteCorrectMethod:
        body.enqueteCorrectMethod === 0 || (body.enqueteCorrectMethod && body.enqueteCorrectMethod >= 0)
          ? body.enqueteCorrectMethod
          : undefined,
      surveyProofCorrectMethod:
        body.surveyProofCorrectMethod === 0 || (body.surveyProofCorrectMethod && body.surveyProofCorrectMethod >= 0)
          ? body.surveyProofCorrectMethod
          : undefined,
    };
  }, []);

  // 検索
  const onSearch = useCallback(
    (body: CorrectSettingTargetListFormResponse) => {
      const searchBody = createSearchBody(body);
      $.api
        .correctSettingTargetList(searchBody)
        .then((res: AxiosResponse<CorrectSettingTargetListOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessages: ['エラーが発生しました。'] });
          } else {
            mergeState({ list: res.data, errorMessages: [] });
          }
        })
        .catch((error) => {
          const errors = [...error.response?.data.errors];
          const errorMessages = errors.map((value: ErrorObjectResponse) => value.message);
          mergeState({ errorMessages, list: [] });
        });
    },
    [$.api, createSearchBody, mergeState]
  );

  return (
    <>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.errorMessages.map((message) => (
          <Alert variant="danger">{message}</Alert>
        ))}
        <Form className="mt-4">
          <Row className="g-2 mb-4">
            <Col>
              <CorrectSettingSearchForms setForms={setForms} correctMethod={$.correctMethod} />
              <Button
                data-testid={testid('search-button')}
                className="mt-4"
                onClick={() => {
                  onSearch($.forms);
                }}
              >
                検索
              </Button>
            </Col>
          </Row>
        </Form>
        {$.list.length ? <CorrectSettingSearchTable list={$.list} /> : undefined}
      </LoadingSpinner>
    </>
  );
};
