import React, { useMemo, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { createTestId, formatISODate } from '../../../utils/functions';
import type { PaidServicePointReturnHistoryState } from '../../pages/PaidServicePointReturn/PaidServicePointReturnHistoryPage';

interface Props {
  list: PaidServicePointReturnHistoryState['list'];
}
export type TestIds = 'table' | 'edit-button';

export const PaidServicePointReturnHistoryTable: React.FC<Props> = ({ list }) => {
  const testId = createTestId<TestIds>(PaidServicePointReturnHistoryTable);
  const tableData: {
    label: string;
    name: keyof PaidServicePointReturnHistoryState['list'][0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parser?: (x?: any) => any;
  }[] = useMemo(() => {
    return [
      { label: '登録日時/応募者', name: 'createAt', parser: String },
      { label: '応募ID', name: 'applyId', parser: Number },
      { label: '応募ステータス', name: 'applyStatus', parser: Number },
      { label: '店舗・モニター情報', name: 'shopId', parser: String },
      { label: 'ポイント返還理由', name: 'pointReturnReason', parser: String },
      { label: '対象サービス', name: 'targetServiceName', parser: String },
      { label: '処理結果/変換不要理由', name: 'result', parser: String },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (
      item: PaidServicePointReturnHistoryState['list'][0],
      labelName: keyof PaidServicePointReturnHistoryState['list'][0]
    ) => {
      // 複数の情報を入れるセル
      const combineName = ['createAt', 'shopId', 'result'];
      if (!combineName.includes(labelName)) {
        return item[labelName];
      }

      if (labelName === 'createAt') {
        return `
          ${item.createAt ? formatISODate(item.createAt, DATETIME_DISPLAY_FORMAT) : ''}\r\n
          ${item.createIncAccount.name}
        `;
      }
      if (labelName === 'shopId') {
        return (
          <>
            {item.shopId}&emsp;{item.shopName}
            <br />
            {item.monitorBaseId}&emsp;{item.monitorBaseName}
          </>
        );
      }
      if (labelName === 'result') {
        return item.returnUnnecessaryReason.length === 0 ? (
          <>{item.result}</>
        ) : (
          <>
            {item.result}
            <br />
            {item.returnUnnecessaryReason}
          </>
        );
      }

      return '';
    },
    []
  );

  return (
    <Table data-testid={testId('table')}>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label)].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <tr className="align-middle" key={item.applyId}>
            {tableData.map(({ name }) => (
              <td key={name}>{createDisplayElement(item, name)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
