import { Configuration } from '../api-client/configuration';
// eslint-disable-next-line import/order
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { SERVICE_MODE_MAINTENANCE, SERVICE_MODE_NORMAL } from '../Constants';
// eslint-disable-next-line import/no-cycle
import { RefreshTokenControllerApi } from '../api-client';
// eslint-disable-next-line import/no-cycle
import { history } from '../App';
import { HTTPStatusCode } from '../constants/HTTPStatusCode';
import { Url } from '../constants/Url';
import { TokenCookies } from './CookiesService';
import type { BaseAPI } from '../api-client/base';

const cookie = new TokenCookies();

const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT || '';
const retryCounterMap = new Map();
const LIMIT_RETRY_COUNT = 3;

export const onInstantiated = (
  self: BaseAPI,
  configuration: Configuration | undefined,
  basePath: string,
  axios: AxiosInstance
): void => {
  const isLocalhosted = ENVIRONMENT === 'local';

  // interceptors.request.use で送信時に引数に入れた関数が動作する
  // 引数で渡ってくるのは axios の設定(送信先や通信方式も持つ今まさに通信を実行しようとしている設定)で、返り値が通信時に実際に使われる axios の設定になる
  axios.interceptors.request.use(
    (config) => {
      cookie.cleanupTokens();
      const [accesstoken, refreshtoken] = cookie.getTokens();
      return {
        ...(config ?? {}), // 本来のリクエストを継承
        headers: {
          ...(config.headers ?? {}), // 本来のヘッダを継承
          ...(accesstoken && refreshtoken
            ? {
                accesstoken,
                refreshtoken,
                ...(isLocalhosted ? { authorization: accesstoken } : {}),
              }
            : {}),
          'x-jroi-secret-key': 'orange',
        },
      };
    },
    (error) => {
      Promise.reject(error);
    }
  );
  let isRetry = false;
  // interceptors.response.use で返信時に引数に入れた関数が動作する
  axios.interceptors.response.use(
    // 第一引数は通信成功時処理。受けた内容をそのまま通過
    (response: AxiosResponse) => {
      // modeが存在している場合
      if ('mode' in response.headers) {
        // サービスモードが通常の場合
        // eslint-disable-next-line eqeqeq
        if (parseInt(response.headers.mode, 10) === SERVICE_MODE_NORMAL) {
          return response;
        }
        if (parseInt(response.headers.mode, 10) === SERVICE_MODE_MAINTENANCE) {
          history.push(Url.MAINTENANCE);
        }
      }
      return response;
    },
    // エラー時, 通信失敗時はリトライさせたい
    (error: AxiosError) => {
      // 通信失敗時はリトライさせたい
      const originalRequest = error.config;
      // 認証エラー 401
      if (error.response?.status === HTTPStatusCode.Unauthorized && !isRetry) {
        const retryCounter = (retryCounterMap.get(originalRequest.url) || 0) + 1;
        isRetry = true;
        const [, refreshToken] = cookie.getTokens();
        // リトライが3回以上
        if (retryCounter > LIMIT_RETRY_COUNT) {
          retryCounterMap.delete(originalRequest.url);
          // エラーの場合ログイン画面に戻る
          cookie.clearTokens();
          history.push(Url.LOGIN);
        }
        // リフレッシュトークンがある場合
        if (refreshToken != null) {
          const api = new RefreshTokenControllerApi();
          return (
            api
              .refreshToken(refreshToken)
              // eslint-disable-next-line consistent-return
              .then((res) => {
                if (res.status === HTTPStatusCode.OK) {
                  const modifiedAxios = axios;
                  const { accessToken: accesstoken, refreshToken: refreshtoken } = res.data;
                  cookie.setTokens({
                    access_token: accesstoken,
                    refresh_token: refreshtoken,
                  });
                  // modifiedAxios.defaults.headers = {
                  //   ...(modifiedAxios.defaults.headers ?? {}), // 本来のヘッダを継承
                  //   // localhostのみaccesstokenの代わりにauthorizationヘッダー付ける
                  //   ...(isLocalhosted
                  //     ? {
                  //         authorization: accesstoken,
                  //       }
                  //     : {
                  //         accesstoken,
                  //       }),
                  // };
                  // 本来行いたかったAPIリクエストを再実行
                  return modifiedAxios.request({
                    ...(error.config ?? {}), // 本来のリクエストを継承
                    headers: {
                      ...(error.config.headers ?? {}), // 本来のヘッダを継承
                      // localhostのみaccesstokenの代わりにauthorizationヘッダー付ける
                      ...(isLocalhosted
                        ? {
                            authorization: accesstoken,
                          }
                        : {
                            accesstoken,
                          }),
                    },
                  });
                }
              })
              .catch(() => {
                retryCounterMap.delete(originalRequest.url);
                console.log('failure token refresh');
                // エラーの場合ログイン画面に戻る
                cookie.clearTokens();
                return Promise.reject(error);
              })
          );
        }
        retryCounterMap.delete(originalRequest.url);
        // エラーの場合ログイン画面に戻る
        cookie.clearTokens();
        let historyState = {};
        if (typeof originalRequest.data === 'string') {
          const jsonData = JSON.parse(originalRequest.data);
          if (jsonData.username !== undefined && jsonData.password !== undefined) {
            historyState = { ...historyState, failedLogin: true };
          }
        }
        history.push(Url.LOGIN, historyState);
        return Promise.reject(error);

        // 422以外はエラーページに遷移
      }

      // 特定の URL の場合、一旦画面遷移無し。
      if (
        originalRequest?.url?.indexOf('/monitor-status-rsv') !== -1 &&
        error.response?.status === HTTPStatusCode.GatewayTimeout
      ) {
        // 何もしない。
      } else if (
        error.response?.status !== HTTPStatusCode.UnprocessableEntity &&
        error.response?.status !== HTTPStatusCode.Unauthorized
      ) {
        history.push(Url.COMMON_ERROR, { error, oldPath: history.location.pathname + history.location.search });
      }
      // エラー終了時にPromiseを返す
      return Promise.reject(error);
    }
  );
};
