import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { createTestId, nameof } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import type { State as EditPageState, FormsState as State } from '../../pages/ClientNg/ClientNgEditPage';

interface Props {
  forms: State;
  setForms: DispatchSetState<State>;
  modalType: EditPageState['editModalType'];
  reasonTypes: EditPageState['reasonTypes'];
}
export const ClientNgEditModalForms: React.FC<Props> = ({ forms, setForms, modalType, reasonTypes }) => {
  const testid = createTestId<keyof State>(ClientNgEditModalForms);
  const { state: $, onChangeSet } = useLargeState<State>(forms ?? {});
  const name = (x: keyof State) => nameof(x);
  useEffect(() => setForms($), [setForms, $]);
  return (
    <>
      <Form.Group controlId={name('ngCustomerId')} data-testid={testid('ngCustomerId')}>
        <Form.Label>会員ID</Form.Label>
        <Form.Control
          type="number"
          autoComplete="off"
          disabled={modalType === 'edit'}
          value={$.ngCustomerId ?? ''}
          onChange={onChangeSet('ngCustomerId')}
        />
      </Form.Group>
      <Form.Group controlId={name('ngReasonType')} data-testid={testid('ngReasonType')}>
        <Form.Label>NG理由</Form.Label>
        <Form.Select value={$.ngReasonType} onChange={onChangeSet('ngReasonType')}>
          <option hidden>--選択--</option>
          {reasonTypes.map(({ ngReasonTypeId, ngReasonTypeName }) => (
            <option value={ngReasonTypeId} key={ngReasonTypeName}>
              {ngReasonTypeName}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group controlId={name('ngReasonNote')} data-testid={testid('ngReasonNote')}>
        <Form.Label>NG理由備考</Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$.ngReasonNote ?? ''}
          onChange={onChangeSet('ngReasonNote')}
        />
      </Form.Group>
    </>
  );
};
