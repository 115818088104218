import React from 'react';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../store/correctionStore';
import { CorrectionModalBase } from './CorrectionModalBase';
import { Button } from '../../../atoms/Button';
import { nativeAlert } from '../../../pages/Correction/utils';

// TODO: [添削] テスト未実装
export const OkReleaseModal: React.FC = () => {
  const {
    largeState: { state: $, mergeState },
  } = useSafeContext(CorrectionContext);

  const receiptCorrectionOkRelease = async (rewardCancelFlg: boolean) => {
    if (!$.res_correctReceiptDisplayControl?.okRelease) return;
    try {
      await $.api_correctReceipt.receiptCorrectionOkRelease({
        applyId: $.applyId,
        rewardCancelFlg,
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      nativeAlert(error);
    }
    await mergeState({ isOkReleaseModal: false });
  };

  return (
    <CorrectionModalBase isModal={!!$.isOkReleaseModal} title="報酬も同時に解除しますか？">
      <Button className="mt-2 me-2" variant="primary" onClick={() => receiptCorrectionOkRelease(true)}>
        解除する
      </Button>
      <Button className="mt-2" variant="secondary" onClick={() => receiptCorrectionOkRelease(false)}>
        解除しない
      </Button>
    </CorrectionModalBase>
  );
};
