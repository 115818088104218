import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import type {
  EnqueteCorrectDisplayControlOutputResponse,
  EscalationInfoDisplayOutputResponse,
  ReceiptCorrectDisplayControlOutputResponse,
  RejectInfoDisplayOutputResponse,
} from '../../../../../api-client';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { debugLog } from '../../../../../utils/functions';
import { DisplayControlEscalate } from '../../../../pages/Correction/constants';
import { nativeAlert, nativeConfirm } from '../../../../pages/Correction/utils';
import { AnswerForm } from './AnswerForm';
import { Post } from './Post';

export type EscalationItem = Partial<EscalationInfoDisplayOutputResponse & RejectInfoDisplayOutputResponse>;

export const Answer: React.FC<{
  escalationItem: EscalationItem;
  displayControl?: ReceiptCorrectDisplayControlOutputResponse | EnqueteCorrectDisplayControlOutputResponse;
}> = ({ escalationItem, displayControl }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  const history = useHistory();
  const escalationAnswer = useMemo(() => {
    const { answer: text, answerPerson: person = {}, answerTypeName: typeName, answeredAt: createAt } = escalationItem;
    return text ? { text, person, typeName, createAt } : undefined;
  }, [escalationItem]);
  const questionReset = useCallback(
    () => nativeConfirm('送信すると他の入力中の項目はリセットされます。よろしいですか？'),
    []
  );
  const onError = useCallback((error: unknown, body: unknown) => {
    console.error(error, body);
    nativeAlert(error);
  }, []);
  if (escalationAnswer && !$.debug_forceEscalateAnswer) {
    const {
      text = '',
      person: { roleName = '', id = NaN, name = '' },
      createAt,
    } = escalationAnswer;
    return <Post header="回答" indent={1} posts={[{ text, roleName, id, name, createAt }]} />;
  }
  if (displayControl?.escalation === DisplayControlEscalate.ANSWER) {
    return (
      <AnswerForm
        templateList={$.res_answerTemplateList ?? []}
        onSubmit={async (state) => {
          const { applyId } = $;
          const { escalationId, ticketId = NaN } = escalationItem;
          const escalate = $.res_escalationInfoDisplay?.find((x) => x.escalationId === escalationId);
          if (!state || !escalate || !escalationId) return;
          const { templateId: correctionTemplateId = NaN, content: answer = '' } = state;
          const body = {
            applyId,
            escalationInfo: { correctionTemplateId, answer, escalationId, ticketId },
            // FIXME: [添削] フラグ暫定設定中
            forcedAssignReleaseFlg: nativeConfirm('[任意] この案件へのアサインを解除してもよろしいですか？'),
          };
          debugLog(body);
          if (questionReset()) {
            try {
              if ($.isEnquete)
                $.api_correctEnquete
                  .enqueteCorrectionEscalationAnswerExecute(body)
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((e) => {
                    throw new Error(e);
                  });
              else
                await $.api_correctReceipt
                  .receiptCorrectionEscalationAnswerExecute(body)
                  .then(() => {
                    window.location.reload();
                  })
                  .catch((e) => {
                    throw new Error(e);
                  });
            } catch (error) {
              onError(error, body);
            }
          }
        }}
      />
    );
  }
  return <></>;
};
