import React, { useEffect } from 'react';
import { logout } from '../../services/AuthService';

const LogoutPage: React.FC = () => {
  // React-Router-Domの機能
  useEffect(() => {
    logout();
  }, []);
  return <></>;
};

export default LogoutPage;
