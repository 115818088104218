/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiClientInfoOutputResponse } from '../domains';
// @ts-ignore
import { ApiClientListOutputResponse } from '../domains';
// @ts-ignore
import { ApiClientUpdateFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * ApiClientApi - axios parameter creator
 * @export
 */
export const ApiClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * APIクライアント情報を取得
         * @summary APIクライアント情報 取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiClientInfo', 'id', id)
            const localVarPath = `/api/apiClient/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * APIクライアント一覧を取得する
         * @summary APIクライアント一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/apiClient/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * APIクライアントを更新する。
         * @summary APIクライアント
         * @param {ApiClientUpdateFormResponse} apiClientUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientUpdate: async (apiClientUpdateFormResponse: ApiClientUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiClientUpdateFormResponse' is not null or undefined
            assertParamExists('apiClientUpdate', 'apiClientUpdateFormResponse', apiClientUpdateFormResponse)
            const localVarPath = `/api/apiClient`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiClientUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiClientApi - functional programming interface
 * @export
 */
export const ApiClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiClientApiAxiosParamCreator(configuration)
    return {
        /**
         * APIクライアント情報を取得
         * @summary APIクライアント情報 取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiClientInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * APIクライアント一覧を取得する
         * @summary APIクライアント一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApiClientListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * APIクライアントを更新する。
         * @summary APIクライアント
         * @param {ApiClientUpdateFormResponse} apiClientUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiClientUpdate(apiClientUpdateFormResponse: ApiClientUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiClientUpdate(apiClientUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiClientApi - factory interface
 * @export
 */
export const ApiClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiClientApiFp(configuration)
    return {
        /**
         * APIクライアント情報を取得
         * @summary APIクライアント情報 取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientInfo(id: number, options?: any): AxiosPromise<ApiClientInfoOutputResponse> {
            return localVarFp.apiClientInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * APIクライアント一覧を取得する
         * @summary APIクライアント一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientList(options?: any): AxiosPromise<Array<ApiClientListOutputResponse>> {
            return localVarFp.apiClientList(options).then((request) => request(axios, basePath));
        },
        /**
         * APIクライアントを更新する。
         * @summary APIクライアント
         * @param {ApiClientUpdateFormResponse} apiClientUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiClientUpdate(apiClientUpdateFormResponse: ApiClientUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.apiClientUpdate(apiClientUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiClientApi - object-oriented interface
 * @export
 * @class ApiClientApi
 * @extends {BaseAPI}
 */
export class ApiClientApi extends BaseAPI {
    /**
     * APIクライアント情報を取得
     * @summary APIクライアント情報 取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiClientApi
     */
    public apiClientInfo(id: number, options?: AxiosRequestConfig) {
        return ApiClientApiFp(this.configuration).apiClientInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * APIクライアント一覧を取得する
     * @summary APIクライアント一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiClientApi
     */
    public apiClientList(options?: AxiosRequestConfig) {
        return ApiClientApiFp(this.configuration).apiClientList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * APIクライアントを更新する。
     * @summary APIクライアント
     * @param {ApiClientUpdateFormResponse} apiClientUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiClientApi
     */
    public apiClientUpdate(apiClientUpdateFormResponse: ApiClientUpdateFormResponse, options?: AxiosRequestConfig) {
        return ApiClientApiFp(this.configuration).apiClientUpdate(apiClientUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
