import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { CustomerInfoApi, MailHistoryOemDisplayListOutputResponse } from '../../../api-client';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { EVENT_MESSAGE_SUMMARY_TYPE, EVENT_MESSAGE_SEND_STATUS } from '../../../Constants';
import { PaginationWithEllipsisBilling } from '../../molecules/PaginationWithEllipsisBilling';
import { OemSelectBox } from '../../atoms/OemSelectBox';
import { OemManagementHeader } from '../../molecules/OemManagementHeader';
import sass from '../../../scss/organisms/scrollTable.module.scss';

interface SearchParams {
  id?: number;
  eventType?: number;
  sendStatus?: number;
  applyId?: number;
  customerId?: number;
  apiClientId?: number;
}

interface SelectProps {
  key: string;
  value: number | undefined;
}

export const OemEventMessageListPage: React.FC = () => {
  const currentUser = useCurrentUser();
  const customerInfoApi = new CustomerInfoApi();
  const [list, setList] = useState<MailHistoryOemDisplayListOutputResponse[]>([]);
  const [dataCount, setDataCount] = useState<number>();
  const MAX_RECORD_PER_PAGE = 20;

  /**
   * FIXME
   * Object.entiriesで作った値と組み合わせると初期値が正しく表示できない
   */
  const selectableEventMessageType = () => {
    const result: SelectProps[] = [{ key: '', value: undefined }];
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(EVENT_MESSAGE_SUMMARY_TYPE)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result.push({ key: key, value: EVENT_MESSAGE_SUMMARY_TYPE[key] });
    }
    return result;
  };

  const selectableSendStata = () => {
    const result: SelectProps[] = [{ key: '', value: undefined }];
    // eslint-disable-next-line no-restricted-syntax
    for (const key of Object.keys(EVENT_MESSAGE_SEND_STATUS)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result.push({ key: key, value: EVENT_MESSAGE_SEND_STATUS[key] });
    }
    return result;
  };

  useEffect(() => {
    const apiclientId =
      typeof currentUser.currentUser?.apiclientId === 'number' ? currentUser.currentUser?.apiclientId : undefined;
    customerInfoApi
      .mailHistoryOemDisplay(1, MAX_RECORD_PER_PAGE, undefined, undefined, apiclientId, undefined, undefined, undefined)
      .then((res) => {
        setList(res.data.list);
        setDataCount(res.data.total);
      });
  }, []);

  const handleSearch = (data: SearchParams) => {
    const apiclientId =
      typeof currentUser.currentUser?.apiclientId === 'number'
        ? currentUser.currentUser?.apiclientId
        : data.apiClientId;
    customerInfoApi
      .mailHistoryOemDisplay(
        1,
        MAX_RECORD_PER_PAGE,
        data.customerId,
        data.eventType,
        apiclientId,
        data.applyId,
        data.id,
        data.sendStatus
      )
      .then((res) => {
        setList(res.data.list);
      });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<SearchParams>({
    defaultValues: {},
  });
  const onSubmit: SubmitHandler<SearchParams> = (data) => {
    handleSearch(data);
  };

  const getProperty = (d: { [key: string]: string } | undefined) => {
    const result: JSX.Element[] = [];

    if (d === undefined) return result;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(d)) {
      result.push(<span className="d-block">{`${key}/${value}`}</span>);
    }
    return result;
  };

  const onClickPageNation = (clickPage: number) => {
    const apiClientId =
      typeof currentUser.currentUser?.apiclientId === 'number'
        ? currentUser.currentUser?.apiclientId
        : getValues('apiClientId');
    customerInfoApi
      .mailHistoryOemDisplay(
        clickPage,
        MAX_RECORD_PER_PAGE,
        getValues('customerId'),
        getValues('eventType'),
        apiClientId,
        getValues('applyId'),
        getValues('id'),
        getValues('sendStatus')
      )
      .then((res) => {
        setList(res.data.list);
        setDataCount(res.data.total);
      });
  };

  return (
    <>
      <OemManagementHeader title="イベントメッセージ一覧" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group controlId="id">
              <Form.Label>イベント・メッセージID</Form.Label>
              <Form.Control type="number" {...register('id')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="eventType">
              <Form.Label>イベント種別</Form.Label>
              <Form.Select {...register('eventType')}>
                {selectableEventMessageType().map((v) => {
                  return (
                    <option key={v.value} value={v.value}>
                      {v.key}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="sendStatus">
              <Form.Label>送信状態</Form.Label>
              <Form.Select {...register('sendStatus')}>
                {selectableSendStata().map((v) => {
                  return (
                    <option key={v.value} value={v.value}>
                      {v.key}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="applyId">
              <Form.Label>応募ID</Form.Label>
              <Form.Control type="number" {...register('applyId')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="customerId">
              <Form.Label>会員ID</Form.Label>
              <Form.Control type="number" {...register('customerId')} />
            </Form.Group>
          </Col>
          {typeof currentUser.currentUser?.apiclientId !== 'number' ? (
            <Col>
              <Form.Group controlId="apiClientId">
                <Form.Label>OEM</Form.Label>
                <OemSelectBox<SearchParams> register={register} registerName="apiClientId" />
              </Form.Group>
            </Col>
          ) : (
            <Col>{}</Col>
          )}
        </Row>
        <div className="d-flex justify-content-center m-2">
          <Button type="submit">検索</Button>
        </div>
      </Form>
      <div className={sass.oemEventMessageListTable}>
        <Table>
          <thead>
            <tr>
              <th className={sass.tenText}>イベント・メッセージID</th>
              <th className={sass.oemTableDateTime}>作成日時</th>
              <th className={sass.oemTableDateTime}>更新日時</th>
              <th className={sass.tenText}>イベント種別</th>
              <th className={sass.tenText}>送信タイミング</th>
              <th className={sass.tenText}>送信状態</th>
              <th className={sass.oemTableDateTime}>送信日時</th>
              <th className={sass.thirtyText}>エラー理由</th>
              <th className={sass.fiveText}>応募ID</th>
              <th className={sass.fiveText}>会員ID</th>
              <th className={sass.thirtyText}>プロパティ(key/value)</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 &&
              list.map((v) => {
                return (
                  <tr key={v.id}>
                    <td>{v.id}</td>
                    <td>{v.createAt}</td>
                    <td>{v.updateAt}</td>
                    <td>{v.messageTypeName}</td>
                    <td>{v.sendTiming}</td>
                    <td>{v.sendStatus.name}</td>
                    <td>{v.sendAt}</td>
                    <td>{v.sendErrorReason}</td>
                    <td>{v.applyId}</td>
                    <td>{v.customerId}</td>
                    <td>{getProperty(v.property).map((vv) => vv)}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      {dataCount !== undefined && (
        <div className="d-flex justify-content-center">
          <PaginationWithEllipsisBilling
            maxRecordPerPage={MAX_RECORD_PER_PAGE}
            totalDataNum={dataCount}
            handleClick={(e) => onClickPageNation(e.page)}
          />
        </div>
      )}
    </>
  );
};
