import { faFemale, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Col, Form, FormControl, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「応募条件」Contents
export const MonitorRuleSetApplicationConditionContents: React.VFC<Props> = ({
  data,
  setData,
  rowData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  const sampleRange: any[] = [
    { id: '1', value: '全体が写るように撮影' },
    { id: '2', value: 'メニュー全体が写るように撮影' },
    { id: '99', value: 'その他（フリーテキスト）' },
  ];
  const [requireMessage, setRequireMessage] = useState<string>();

  useEffect(() => {
    if (data.id === 0)
      setData({
        ...data,
        monitorRuleList: data?.monitorRuleList.map((row, inde) => {
          return row.monitorRuleType === 17 ? { ...row, maleFlg: true, femaleFlg: true } : { ...row };
        }),
      });
  }, []);

  /* eslint-disable */
  return (
    <>
      <div className="d-flex ">
        <Form.Label className="d-block" style={{ fontWeight: 'bold' }}>
          性別
        </Form.Label>
      </div>
      {requireMessage && (
        <div style={{ color: 'red' }} className="mb-2">
          {requireMessage}
        </div>
      )}
      <div className="d-flex justify-content-start btn-group mb-4" role="group">
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="men"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, maleFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.maleFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.maleFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="men">
            男性
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            className="btn-check"
            id="women"
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.femaleFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.femaleFlg || false
            }
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, femaleFlg: e.target.checked } : { ...row };
                }),
              });
            }}
          />
          <label className="btn btn-outline-primary " htmlFor="women">
            女性
          </label>
        </div>
      </div>
      <Form.Label className="d-block" style={{ fontWeight: 'bold' }}>
        年齢条件
      </Form.Label>
      <div className="d-flex justify-content-start ">
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="10s"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, age10sFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.age10sFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.age10sFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="10s">
            10代
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="20s"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, age20sFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.age20sFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.age20sFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="20s">
            20代
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="30s"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, age30sFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.age30sFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.age30sFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="30s">
            30代
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="40s"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, age40sFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.age40sFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.age40sFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="40s">
            40代
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="50s"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, age50sFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.age50sFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.age50sFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="50s">
            50代
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="60s"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, age60sFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.age60sFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.age60sFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="60s">
            60代
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="70s"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, age70sFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.age70sFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.age70sFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="70s">
            70代
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="80s"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, age80sFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.age80sFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.age80sFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="80s">
            80代
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="90s"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, age90sFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 17;
              })?.age90sFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 17;
                  })?.age90sFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="90s">
            90代
          </label>
        </div>
      </div>
      <div className="mt-2">または</div>
      <Form.Group className="d-flex align-items-center mb-4">
        <Form.Label className="mb-0">年齢：</Form.Label>
        <Form.Select
          id="ageFrom"
          style={{ width: '80px' }}
          value={
            data?.monitorRuleList?.find((r) => {
              return r.monitorRuleType === 17;
            })?.ageFrom || ''
          }
          onChange={(e) => {
            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                return row.monitorRuleType === 17
                  ? { ...row, ageFrom: e.target.value !== '' ? Number(e.target.value) : undefined }
                  : { ...row };
              }),
            });
          }}
        >
          <option value="">&nbsp;</option>
          {[...Array(85)].map((_, i) => (
            <option key={i + 16} value={i + 16}>
              {i + 16}
            </option>
          ))}
        </Form.Select>
        <Form.Label className="mb-0">歳～</Form.Label>
        <Form.Select
          id="ageTo"
          style={{ width: '80px' }}
          value={
            data?.monitorRuleList?.find((r) => {
              return r.monitorRuleType === 17;
            })?.ageTo || ''
          }
          onChange={(e) => {
            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                return row.monitorRuleType === 17
                  ? { ...row, ageTo: e.target.value !== '' ? Number(e.target.value) : undefined }
                  : { ...row };
              }),
            });
          }}
        >
          <option value="">&nbsp;</option>
          {[...Array(85)].map((_, i) => (
            <option key={i + 16} value={i + 16}>
              {i + 16}
            </option>
          ))}
        </Form.Select>
        <Form.Label className="mb-0">歳</Form.Label>
      </Form.Group>
      <Col className="col-5 mb-4">
        <Form.Group className="d-flex align-items-center mt-4" controlId="readOnly1">
          <Form.Label className="mt-2 me-4" style={{ fontWeight: 'bold' }}>
            職業
          </Form.Label>
          <Col>
            <Form.Control
              data-testid="workText"
              type="text"
              onChange={(e) => {
                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                    return row.monitorRuleType === 17 ? { ...row, work: e.target.value } : { ...row };
                  }),
                });
              }}
              value={
                data?.monitorRuleList.find((_) => {
                  return _.monitorRuleType === 17;
                })?.work === undefined
                  ? ''
                  : data?.monitorRuleList.find((_) => {
                      return _.monitorRuleType === 17;
                    })?.work || ''
              }
            />
          </Col>
        </Form.Group>
        <Form.Label className="mt-4 me-4" style={{ fontWeight: 'bold' }}>
          その他
        </Form.Label>
        <div className="d-flex align-items-end">
          <FormControl
            data-testid="otherText"
            as="textarea"
            className="mt-2"
            style={{ height: '100px', width: '485px' }}
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 17 ? { ...row, otherCondition: e.target.value } : { ...row };
                }),
              });
            }}
            value={
              data.monitorRuleList.find((_) => {
                return _.monitorRuleType === rowData.monitorRuleType;
              })?.otherCondition === undefined
                ? ''
                : data.monitorRuleList.find((_) => {
                    return _.monitorRuleType === rowData.monitorRuleType;
                  })?.otherCondition || ''
            }
          />
          <div>
            <Button
              className="ms-4"
              variant="secondary"
              onClick={() => {
                let reflectionText = '';
                let supplementText = '';
                const maleFlg: boolean | undefined = data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 17;
                })?.maleFlg;
                const femaleFlg: boolean | undefined = data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 17;
                })?.femaleFlg;

                const applicationCondition = data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 17;
                });
                const ageFlgs = [
                  { age: '10代', flg: applicationCondition?.age10sFlg || false },
                  { age: '20代', flg: applicationCondition?.age20sFlg || false },
                  { age: '30代', flg: applicationCondition?.age30sFlg || false },
                  { age: '40代', flg: applicationCondition?.age40sFlg || false },
                  { age: '50代', flg: applicationCondition?.age50sFlg || false },
                  { age: '60代', flg: applicationCondition?.age60sFlg || false },
                  { age: '70代', flg: applicationCondition?.age70sFlg || false },
                  { age: '80代', flg: applicationCondition?.age80sFlg || false },
                  { age: '90代', flg: applicationCondition?.age90sFlg || false },
                  { age: '', flg: false },
                ];

                const ageFrom: number | undefined = applicationCondition?.ageFrom;
                const ageTo: number | undefined = applicationCondition?.ageTo;

                const work: string | undefined = data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 17;
                })?.work;
                const otherCondition: string | undefined = data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 17;
                })?.otherCondition;

                // 「性別」の取得
                if (maleFlg || femaleFlg) {
                  setRequireMessage('');
                  if (maleFlg && femaleFlg) {
                  } else if (maleFlg) {
                    reflectionText = '性別：男性\n';
                  } else {
                    reflectionText = '性別：女性\n';
                  }
                } else {
                  setRequireMessage('性別を選択してください。');
                }
                // 「年齢」の取得
                // 年代が連続していたら〇代～〇代と表示する
                if (ageFlgs.some(({ flg }) => flg)) {
                  reflectionText = `${reflectionText}年齢：`;
                  let trueCount = 0;
                  let prevAge = '';
                  let endAge = '';
                  let displayFlg = false;
                  ageFlgs.forEach(({ age, flg }) => {
                    if (flg) {
                      trueCount = trueCount + 1;
                      if (trueCount === 1) {
                        reflectionText = displayFlg ? `${reflectionText}・${age}` : `${reflectionText}${age}`;
                        displayFlg = true;
                      }
                      if (trueCount === 2) {
                        prevAge = age;
                      }
                      if (trueCount > 2) {
                        prevAge = '';
                        endAge = age;
                      }
                    } else {
                      if (prevAge !== '') reflectionText = `${reflectionText}・${prevAge}`;
                      if (endAge !== '') reflectionText = `${reflectionText}～${endAge}`;
                      trueCount = 0;
                      prevAge = '';
                      endAge = '';
                    }
                  });
                } else {
                  // 年代が入力されていない場合
                  if (ageFrom && ageTo) {
                    // 年齢範囲がどちらも入力されている場合
                    reflectionText = `${reflectionText}年齢：${ageFrom}歳～${ageTo}歳`;
                  }
                }

                // 「職業」の取得
                if (work) {
                  reflectionText = `${reflectionText}\n職業：${work}\n`;
                }
                // 「その他」の取得
                if (otherCondition) {
                  reflectionText = `${reflectionText}\nその他：${otherCondition}\n`;
                }

                let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                  [],
                  data.monitorRuleList.find((supplement) => {
                    return supplement.monitorRuleType === rowData.monitorRuleType;
                  })?.supplementList
                );
                copySupplementList = copySupplementList.map((r, i) => {
                  return i === 0 ? { ...r, supplement: supplementText } : { ...r };
                });

                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                    return row.monitorRuleType === 17
                      ? {
                          ...row,
                          beforeApplyContent: reflectionText,
                          afterApplyContent: reflectionText,
                          supplementList: copySupplementList,
                        }
                      : { ...row };
                  }),
                });
              }}
            >
              反映
            </Button>
          </div>
        </div>
      </Col>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg={true}
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
  /* eslint-disable */
};
