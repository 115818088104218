import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { differenceInYears } from 'date-fns';
import { useLargeState } from '../../../../hooks/useLargeState';
import { createTestId, formatISODate, isoDateToInput } from '../../../../utils/functions';
import type { CustomerInformationEditConfirmFormResponse } from '../../../../api-client';
import { CustomerInfoEditModal } from '../../../organisms/Modal/Customer/CustomerInfoEditModal';
import type { CustomerDetailState } from '../CustomerDetailPage';
import { GENDER_NAMES, DATETIME_DISPLAY_FORMAT, DATE_DISPLAY_FORMAT } from '../../../../Constants';

export type State = CustomerDetailState['customerData'];
interface Props {
  customerData: State;
  oemFlag: boolean;
}

export interface CustomerInfoState {
  isModal: boolean;
  forms: CustomerInformationEditConfirmFormResponse;
}

export type TestIds = keyof State | 'pen';

export const CustomerInfoCard: React.FC<Props> = ({ customerData, oemFlag }) => {
  const testid = createTestId<TestIds>(CustomerInfoCard);

  const { state: $info } = useLargeState<State>(customerData);

  const initialData: CustomerInformationEditConfirmFormResponse = useMemo(
    () => ({
      customerId: customerData.customerId,
      firstName: customerData.firstName || '',
      lastName: customerData.lastName || '',
      gender: Number(customerData.gender),
      birthday: isoDateToInput('date', customerData.birthday || '') as string,
      zipCode: customerData.zipCode || '',
      prefectureId: Number(customerData.prefectureId),
    }),
    [customerData]
  );

  const {
    state: $modal,
    mergeState: mergeModal,
    useBindSet,
  } = useLargeState<CustomerInfoState>({
    isModal: false,
    forms: initialData,
  });

  const setForms = useBindSet('forms');

  return (
    <>
      <CustomerInfoEditModal
        isModal={$modal.isModal}
        forms={$modal.forms}
        setForms={setForms}
        onHide={() =>
          mergeModal({
            isModal: false,
            forms: initialData,
          })
        }
      />
      <Card className="mt-2 me-2" style={{ width: '24.25%' }}>
        <div className="d-flex justify-content-between mt-2">
          <h6>会員情報</h6>
          <div style={{ cursor: 'pointer' }} data-testid={testid('pen')}>
            <FontAwesomeIcon icon={faPen} onClick={() => mergeModal({ isModal: true })} />
          </div>
        </div>
        {oemFlag ? undefined : (
          <>
            <small>姓名</small>
            <p className="mb-0" data-testid={testid('firstName')}>
              {$info.lastName}
              {$info.firstName}
            </p>
            <small className="text-secondary" data-testid={testid('nameUpdateCount')}>
              変更回数 {$info.nameUpdateCount}
            </small>
            <small className="text-secondary mb-2" data-testid={testid('nameUpdateAt')}>
              更新日時
              {$info.nameUpdateAt ? formatISODate($info.nameUpdateAt, DATETIME_DISPLAY_FORMAT) : ''}
            </small>
          </>
        )}
        <small>性別</small>
        <p className="mb-2" data-testid={testid('gender')}>
          {$info.gender != null ? GENDER_NAMES[$info.gender] : ''}
        </p>
        <small>生年月日</small>
        <p className="mb-0" data-testid={testid('birthday')}>
          {$info.birthday ? formatISODate($info.birthday, DATE_DISPLAY_FORMAT) : ''}
          {$info.birthday
            ? `（${differenceInYears(new Date(), new Date(formatISODate($info.birthday, DATE_DISPLAY_FORMAT)))}歳）`
            : ''}
        </p>
        <small className="text-secondary" data-testid={testid('birthdayUpdateCount')}>
          変更回数 {$info.birthdayUpdateCount}
        </small>
        <small className="text-secondary mb-2" data-testid={testid('birthdayUpdateAt')}>
          更新日時
          {$info.birthdayUpdateAt ? formatISODate($info.birthdayUpdateAt, DATETIME_DISPLAY_FORMAT) : ''}
        </small>
        {oemFlag ? undefined : (
          <>
            <small>郵便番号</small>
            <p className="mb-0" data-testid={testid('zipCode')}>
              {$info.zipCode}
            </p>
            <p className="text-secondary" data-testid={testid('prefecture')}>
              {$info.prefecture}
            </p>
          </>
        )}
      </Card>
    </>
  );
};
