import { faBan, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../../store/correctionStore';
import { displayWhen, uuid } from '../../../../../../../utils/functions';
import { ImageThumbnail } from '../../../../../../atoms/ImageThumbnail';
import { SideBy } from '../../../../../../atoms/SideBy';
import { TinyHead } from '../../../../../../atoms/TinyHead';
import { Url } from '../../../../../../../constants/Url';
import { DisplayPointOutputResponse, HashDuplicateAnswerImageOutputResponse } from '../../../../../../../api-client';
import { DisplayPointType } from '../../../../../../pages/Correction/constants';

export const DuplicateImages: React.FC<{ displayPoint: DisplayPointOutputResponse }> = ({ displayPoint }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const duplicateImageApplyIdList = (): Array<HashDuplicateAnswerImageOutputResponse> => {
    if (displayPoint.type !== DisplayPointType.ANSWER) return [];
    return (
      $.res_correctionCheckDisplay?.hashDuplicateAnswerImageList.filter(
        (data) => displayPoint.targetId === data.displayPoint.targetId
      ) ?? []
    );
  };
  return (
    <>
      {displayWhen(
        $.res_correctionCheckDisplay?.googleDuplicateImageList.length === 0 &&
          $.res_correctionCheckDisplay?.hashDuplicateSurveyProofImageList.length === 0,
        <Alert variant="success">
          <FontAwesomeIcon icon={faCheckCircle} /> 重複なし
        </Alert>
      )}
      {displayWhen(
        $.res_correctionCheckDisplay?.googleDuplicateImageList.length !== 0,
        <Alert variant="warning">
          <FontAwesomeIcon icon={faExclamationTriangle} /> 類似する画像が存在します。
          <TinyHead>Google類似画像検索結果</TinyHead>
          <SideBy>
            {$.res_correctionCheckDisplay?.googleDuplicateImageList.map(({ imageUrl }) => (
              <ImageThumbnail key={uuid()} url={imageUrl} />
            ))}
          </SideBy>
        </Alert>
      )}
      {displayWhen(
        !$.isEnquete && $.res_correctionCheckDisplay?.hashDuplicateSurveyProofImageList.length !== 0,
        <Alert variant="danger">
          <FontAwesomeIcon icon={faBan} /> 重複する画像が存在します。
          <TinyHead>ハッシュ値重複画像</TinyHead>
          <SideBy>
            {$.res_correctionCheckDisplay?.hashDuplicateSurveyProofImageList.map(({ applyId }) => (
              <a href={`${Url.TENSAKU.CORRECT_RECEIPT}/${applyId}`} target="_blank" rel="noreferrer">
                {applyId}
              </a>
            ))}
          </SideBy>
        </Alert>
      )}
      {displayWhen(
        $.isEnquete && duplicateImageApplyIdList().length !== 0,
        <Alert variant="danger">
          <FontAwesomeIcon icon={faBan} /> 重複する画像が存在します。
          <TinyHead>ハッシュ値重複画像</TinyHead>
          <SideBy>
            {duplicateImageApplyIdList().map(({ applyId }) => (
              <a href={`${Url.TENSAKU.CORRECT_ENQUETE}/${applyId}`} target="_blank" rel="noreferrer">
                {applyId}
              </a>
            ))}
          </SideBy>
        </Alert>
      )}
    </>
  );
};
