import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../../../../hooks/useLargeState';
import { useSafeContext } from '../../../../../../hooks/useSafeContext';
import { CorrectionContext, ReceiptOkForm } from '../../../../../../store/correctionStore';
import { when } from '../../../../../../utils/functions';
import { Button } from '../../../../../atoms/Button';
import { SideBy } from '../../../../../atoms/SideBy';
import { CorrectionTargetType, CorrectionWorkType } from '../../../../../pages/Correction/constants';
// eslint-disable-next-line import/no-cycle
import {
  createReceiptOkInitialForm,
  isForceCorrect,
  jumpTo,
  noCorrectionTarget,
  toAt,
} from '../../../../../pages/Correction/utils';
import { DifferenceInputAmountModal } from '../../DifferenceInputAmountModal';
import { CorrectionOkResultInfoFormResponse, ResultOutputResponse } from '../../../../../../api-client';

interface Props {
  isViewOnly: boolean;
  _isEnquete?: boolean;
}

export interface ModalProps {
  onClose: (value: boolean) => void;
  content: string;
}

export const OKForm: React.FC<Props> = ({ isViewOnly, _isEnquete }) => {
  const {
    largeState: { state: $, useBindSet },
  } = useSafeContext(CorrectionContext);

  const isEnquete = _isEnquete ?? $.isEnquete;
  const history = useHistory();
  const initialForm = useMemo(
    () =>
      createReceiptOkInitialForm($.res_receiptInfoDisplay, {
        tell: $.receiptForm_tellCheck,
        date: $.receiptForm_dateCheck,
        receipt: $.receiptForm_receiptCheck,
      }),
    [$.res_receiptInfoDisplay, $.receiptForm_tellCheck, $.receiptForm_dateCheck, $.receiptForm_receiptCheck]
  );
  const { state: f, onChangeSet: set, onChangeCheckbox: check } = useLargeState<ReceiptOkForm>(initialForm);
  const setLoading = useBindSet<boolean>('isLoading');
  const [modalConfig, setModalConfig] = useState<ModalProps | undefined>();

  const correctionDistribute = useCallback(() => {
    $.api_correct
      .normalCorrectTargetDistribute({
        beforeApplyId: $.applyId,
        workTypeCode: CorrectionWorkType.CHECK_OK,
        correctTargetTypeCord: $.isEnquete ? CorrectionTargetType.ENQUETE : CorrectionTargetType.RECEIPT,
      })
      .then(({ data }) => jumpTo(history, data))
      .catch(() => noCorrectionTarget(history));
  }, [$.api_correct, $.applyId, $.isEnquete, history]);

  const receiptAlertCheck = useCallback(
    async (res: AxiosResponse<ResultOutputResponse>, receiptInfo: CorrectionOkResultInfoFormResponse) => {
      setLoading?.(false);
      const ret = await new Promise<boolean>((resolve) => {
        let errorContent = '';
        res.data.errors?.forEach((value) => {
          errorContent += `${value.message}\n`;
        });
        // Modal側でresolveする仕様にすることで処理を一時的に止めている
        setModalConfig({
          onClose: resolve,
          content: errorContent,
        });
      });
      // ModalConfig.valueの有無でModalの出し分けを行う
      setModalConfig(undefined);
      setLoading?.(true);
      // APIからの確認ダイアログを表示
      if (ret) {
        $.api_correctReceipt
          .correctionOkExecute({
            applyId: $.applyId,
            forcedCorrectFlg: isForceCorrect($.res_correctReceiptDisplayControl, $.res_correctEnqueteDisplayControl),
            forcedOkFlg: true,
            correctionOkResultInfo: receiptInfo,
          })
          .then(() => {
            correctionDistribute();
          });
      } else {
        setLoading?.(false);
      }

      setLoading?.(false);
    },
    [
      $.api_correctReceipt,
      $.applyId,
      $.res_correctEnqueteDisplayControl,
      $.res_correctReceiptDisplayControl,
      setLoading,
    ]
  );

  const fetchEnquete = useCallback(() => {
    $.saveAnswer!().then((saveAnsRes) => {
      if (saveAnsRes) {
        // 回答保存でキャンセルした場合以外は添削OK
        // キャンセルした場合は添削OKしない
        $.api_correctEnquete
          .enqueteCorrectionOkExecute({
            applyId: $.applyId,
            enqueteCorrectionOkResultInfo: { attentionContent: f.attentionContent || '' },
            forcedCorrectFlg: isForceCorrect($.res_correctReceiptDisplayControl, $.res_correctEnqueteDisplayControl),
          })
          .then(() => {
            correctionDistribute();
          });
      }
    });
  }, [
    $.api_correctEnquete,
    $.applyId,
    $.res_correctEnqueteDisplayControl,
    $.res_correctReceiptDisplayControl,
    $.saveAnswer,
    f.attentionContent,
  ]);

  const fetchReceipt = useCallback(() => {
    const receiptInfo = {
      purchaseAt: toAt(f.purchaseDate, f.noPurchaseTimeFlag, f.purchaseTime) ?? '',
      noPurchaseTimeFlag: !!f.noPurchaseTimeFlag,
      purchaseAmount: Number(f.purchaseAmount),
      noReceiptNoFlag: !!f.noReceiptNoFlag,
      receiptNo: f.receiptNo ?? '',
      attentionContent: f.attentionContent ?? '',
    };

    $.api_correctReceipt
      .correctionOkExecute({
        applyId: $.applyId,
        forcedCorrectFlg: isForceCorrect($.res_correctReceiptDisplayControl, $.res_correctEnqueteDisplayControl),
        forcedOkFlg: false,
        correctionOkResultInfo: receiptInfo,
      })
      .then((res: AxiosResponse<ResultOutputResponse>) => {
        // 金額系のチェックが引っかかった場合
        if (!res.data.result) receiptAlertCheck(res, receiptInfo);
        if (res.data.result) correctionDistribute();
      });
  }, [
    $.api_correctReceipt,
    $.applyId,
    $.res_correctEnqueteDisplayControl,
    $.res_correctReceiptDisplayControl,
    f.attentionContent,
    f.noPurchaseTimeFlag,
    f.noReceiptNoFlag,
    f.purchaseAmount,
    f.purchaseDate,
    f.purchaseTime,
    f.receiptNo,
    receiptAlertCheck,
  ]);

  const onSubmit = useCallback(async () => {
    if ($.isEnquete) fetchEnquete();
    else fetchReceipt();
  }, [$.isEnquete, $.api_correct, $.applyId, fetchEnquete, fetchReceipt, history]);

  return (
    <>
      {modalConfig && <DifferenceInputAmountModal {...modalConfig} />}
      <Form>
        {when(
          !isEnquete,
          <>
            <Form.Group>
              <Form.Label>利用金額 *</Form.Label>
              <Form.Control
                size="lg"
                value={f.purchaseAmount ?? ''}
                onChange={set('purchaseAmount')}
                type="number"
                disabled={isViewOnly}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>来店日時</Form.Label>
              <Form.Control
                size="lg"
                type="date"
                value={f.purchaseDate ?? ''}
                onChange={set('purchaseDate')}
                disabled
              />
            </Form.Group>
            <SideBy>
              <Form.Group>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    checked={f.noPurchaseTimeFlag ?? false}
                    onChange={check('noPurchaseTimeFlag')}
                    disabled
                  />
                  <Form.Check.Label aria-disabled>時刻なし</Form.Check.Label>
                </Form.Check>
              </Form.Group>
              <Form.Group>
                <Form.Control
                  size="lg"
                  type="time"
                  disabled
                  value={f.purchaseTime ?? ''}
                  onChange={set('purchaseTime')}
                />
              </Form.Group>
            </SideBy>
            <Form.Label>レシートNo</Form.Label>
            <SideBy>
              <Form.Group>
                <Form.Check>
                  <Form.Check.Input
                    type="checkbox"
                    checked={!!f.noReceiptNoFlag}
                    onChange={check('noReceiptNoFlag')}
                    disabled
                  />
                  <Form.Check.Label aria-disabled>レシートNoなし</Form.Check.Label>
                </Form.Check>
              </Form.Group>
              <Form.Group>
                <Form.Control size="lg" value={f.receiptNo ?? ''} disabled onChange={set('receiptNo')} />
              </Form.Group>
            </SideBy>
          </>
        )}
        <Form.Group>
          <Form.Label>注意事項喚起文言</Form.Label>
          <p>※ユーコミから指示があった場合に入力してください</p>
          <Form.Control
            size="lg"
            value={f.attentionContent ?? ''}
            onChange={set('attentionContent')}
            disabled={isViewOnly}
          />
        </Form.Group>
        {when(
          !isViewOnly,
          <p>
            <Button variant="success" onClick={onSubmit}>
              <FontAwesomeIcon icon={faCheckCircle} /> {$.isEnquete ? 'アンケート' : 'レシート'}チェックOK
            </Button>
          </p>
        )}
      </Form>
    </>
  );
};
