import { Url } from '../../../../constants/Url';

const applyText = '応募ID：';
const searcher = new RegExp(`${applyText}`, 'g');
export const hasIncludeApplyIdText = (infoText: string): boolean => {
  const startApplyTextPlace = infoText.search(searcher);
  return startApplyTextPlace !== -1;
};

export const convertApplyIdToLink = (infoText: string): JSX.Element => {
  const startApplyTextPlace = infoText.search(searcher);
  const startApplyIdPlace = startApplyTextPlace + applyText.length;

  const applyIdText = infoText.substring(startApplyIdPlace);
  const staticMessage = infoText.substring(0, startApplyIdPlace);
  const applyIdList = applyIdText.split(',');

  return (
    <span>
      {staticMessage}
      {applyIdList.map((applyId) => {
        return applyInfoLink(applyId);
      })}
    </span>
  );
};
/*
NOTE:
- 応募情報の確認を複数回行いたい
- 応募情報を確認した後にもう一度データ入力Wチェック画面に戻ってきてしまい承認ボタンを再度押さないといけない
ため、別タブ表示することにした。

同タブで良い場合は、React-routerで遷移先を分割することも検討
 */
const applyInfoLink = (applyIdText: string) => {
  return (
    <>
      <a href={`${Url.TENSAKU.CORRECT_DATA_WCHECK}/${applyIdText}`} target="_blank" rel="noreferrer noopener">
        {applyIdText}
      </a>{' '}
    </>
  );
};
