import React from 'react';

import { Datepicker } from '../atoms/Datepicker';
import { Timepicker } from '../atoms/Timepicker';

export interface Props {
  timestamp: string;
  handleChangeDatepicker: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeTimepicker: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const DateAndTime: React.VFC<Props> = ({ timestamp, handleChangeDatepicker, handleChangeTimepicker }) => {
  return (
    <>
      <Datepicker
        value={timestamp.slice(0, timestamp.indexOf(' ')).replace(/\//g, '-')}
        onChange={handleChangeDatepicker}
        className="d-inline w-auto mx-2"
        data-testid="datepicker"
      />
      <Timepicker
        value={timestamp.slice(timestamp.indexOf(' ') + 1, timestamp.length)}
        onChange={handleChangeTimepicker}
        className="d-inline w-auto"
        data-testid="timepicker"
      />
    </>
  );
};
