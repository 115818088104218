import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useLargeState } from '../../../../hooks/useLargeState';
import { createTestId, formatISODate } from '../../../../utils/functions';
import type { CustomerDetailState } from '../CustomerDetailPage';
import type { CustomerStatusEditConfirmFormResponse } from '../../../../api-client';
import { CustomerStatusInfoEditModal } from '../../../organisms/Modal/Customer/CustomerStatusInfoEditModal';

export type State = CustomerDetailState['customerData'];
interface Props {
  customerData: State;
  oemFlag: boolean;
}

export interface StatusInfoState {
  isModal: boolean;
  forms: CustomerStatusEditConfirmFormResponse;
}

export type TestIds = keyof State | 'pen';

export const StatusInfoCard: React.FC<Props> = ({ customerData, oemFlag }) => {
  const testid = createTestId<TestIds>(StatusInfoCard);

  const { state: $info } = useLargeState<State>(customerData);

  const initialData: CustomerStatusEditConfirmFormResponse = useMemo(
    () => ({
      customerId: customerData.customerId,
      customerStatus: Number(customerData.customerStatus),
      pointFreezeFlg: customerData.pointFreezeFlg || false,
      pointFreezeReason: customerData.pointFreezeReason || undefined,
      testCustomerFlg: customerData.testCustomerFlg || false,
    }),
    [customerData]
  );

  const {
    state: $modal,
    mergeState: mergeModal,
    useBindSet,
  } = useLargeState<StatusInfoState>({
    isModal: false,
    forms: initialData,
  });

  const setForms = useBindSet('forms');

  return (
    <>
      <CustomerStatusInfoEditModal
        isModal={$modal.isModal}
        forms={$modal.forms}
        setForms={setForms}
        onHide={() =>
          mergeModal({
            isModal: false,
            forms: initialData,
          })
        }
        oemFlag={oemFlag}
      />
      <Card className="mt-2 me-2" style={{ width: '24.25%' }}>
        <div className="d-flex justify-content-between mt-2">
          <h6>会員ステータス</h6>
          <div style={{ cursor: 'pointer' }} data-testid={testid('pen')}>
            <FontAwesomeIcon icon={faPen} onClick={() => mergeModal({ isModal: true })} />
          </div>
        </div>

        <small>会員ステータス</small>
        <p className="mb-0" data-testid={testid('customerStatusName')}>
          {$info.customerStatusName}
        </p>
        <small className="text-secondary" data-testid={testid('createAt')}>
          登録日時 {$info.createAt ? formatISODate($info.createAt, 'yyyy/MM/dd HH:mm:ss') : ''}
        </small>
        {$info.customerStatus === 0 ? (
          <small className="text-secondary mb-2" data-testid={testid('leaveAt')}>
            退会日時 {$info.leaveAt ? formatISODate($info.leaveAt, 'yyyy/MM/dd HH:mm:ss') : ''}
          </small>
        ) : undefined}
        {oemFlag ? undefined : (
          <>
            <small>ポイント交換ステータス</small>
            <p className="mb-0" data-testid={testid('pointExchangeStatusName')}>
              {$info.pointExchangeStatusName}
            </p>
            <small className="text-secondary mb-2" data-testid={testid('pointExchangeStatusUpdateAt')}>
              更新日時
              {$info.pointExchangeStatusUpdateAt
                ? formatISODate($info.pointExchangeStatusUpdateAt, 'yyyy/MM/dd HH:mm:ss')
                : ''}
            </small>
            <small>ポイント凍結理由</small>
            <p className="mb-0" data-testid={testid('pointFreezeReason')}>
              {$info.pointFreezeReason ?? '未設定'}
            </p>
          </>
        )}
        <small>テスト会員</small>
        <p className="mb-0" data-testid={testid('testCustomerFlg')}>
          {$info.testCustomerFlg ? 'テスト会員' : '通常会員'}
        </p>
      </Card>
    </>
  );
};
