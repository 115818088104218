import React, { useEffect, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import type { CorrectionTemplateListOutputResponse, NoteTemplateOutputResponse } from '../../../../../api-client';
import { useLargeState } from '../../../../../hooks/useLargeState';
import { uuid, when } from '../../../../../utils/functions';
import { SideBy } from '../../../../atoms/SideBy';
import { AnswerMethod, TemplateFormBodyState } from '../../../../pages/Correction/constants';
import { TimeList } from '../../Main/parts/TimeList';

export type TemplateList = Partial<NoteTemplateOutputResponse & CorrectionTemplateListOutputResponse>[];

/** なんとかテンプレートのフォーム */
export const CommonBody: React.FC<{
  name?: string;
  templateList: TemplateList;
  onChange?: (state: TemplateFormBodyState) => void;
  // linkColor?: string;
  buttonVariant?: string;
  initContent?: string;
  initTempId?: number;
  isOptionalTempId?: boolean;
  placeholder?: string;
  answerMethodCode?: number;
}> = ({
  name,
  templateList,
  onChange,
  // linkColor,
  buttonVariant,
  initContent,
  initTempId,
  isOptionalTempId,
  placeholder,
  answerMethodCode,
}) => {
  const { state, onChangeSet, mergeState, setState } = useLargeState<TemplateFormBodyState>({
    content: initContent ?? '',
    templateId: initTempId ?? undefined,
    templateContent: initTempId ? templateList.find((t) => t.templateId === initTempId)?.templateText ?? '' : '',
  });
  const enableTempId = useMemo(() => !!name, [name]);
  useEffect(() => {
    if (onChange) {
      onChange(state);
    }
  }, [onChange, state]);

  const textInputComponent = () => {
    switch (answerMethodCode) {
      case AnswerMethod.DATE:
        return (
          <>
            <span className="small">カレンダーから選択するかyyyy-MM-dd形式で入力が必要です</span>
            <Form.Control
              type="date"
              value={state.content ?? ''}
              onChange={onChangeSet('content')}
              disabled={!isOptionalTempId && enableTempId ? state.templateId === undefined : false}
            />
          </>
        );
      case AnswerMethod.TIME:
        return (
          <>
            <span className="small">※30分刻みでhh:mm形式での入力が必要です。</span>
            <Form.Control
              type="text"
              list="time-list"
              value={state.content ?? ''}
              onChange={onChangeSet('content')}
              disabled={!isOptionalTempId && enableTempId ? state.templateId === undefined : false}
            />
            <datalist id="time-list">
              {TimeList.map((v) => {
                return (
                  <option key={v.id} value={v.id}>
                    {}
                  </option>
                );
              })}
            </datalist>
          </>
        );
      default:
        return (
          <Form.Control
            size="lg"
            value={state.content ?? ''}
            onChange={onChangeSet('content')}
            as="textarea"
            disabled={!isOptionalTempId && enableTempId ? state.templateId === undefined : false}
            placeholder={placeholder ?? (enableTempId ? `${name}テンプレート選択したら文言自動でテキスト挿入` : '')}
          />
        );
    }
  };

  return (
    <>
      {when(
        enableTempId,
        <SideBy>
          <Form.Group>
            <Form.Select
              size="lg"
              value={`${state.templateId}` ?? ''}
              onChange={(e) => {
                const templateId = +e.target.value;
                const { templateText, content } = templateList.find((t) => t.templateId === templateId) ?? {};
                mergeState({ templateId, templateContent: content ?? templateText ?? '' });
              }}
            >
              <option hidden value="">
                {name}テンプレートを選択
              </option>
              {templateList.map(({ title, templateName, templateId }) => (
                <option key={uuid()} value={templateId}>
                  {title ?? templateName ?? templateId}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Button
            variant={buttonVariant}
            onClick={() => {
              setState((prev) => {
                const { templateText, content } = templateList.find((t) => t.templateId === prev.templateId) ?? {};
                return {
                  ...prev,
                  content: content ?? templateText ?? '',
                };
              });
            }}
          >
            挿入
          </Button>
          {/* TODO: [添削] 今回はテンプレート一覧を実装しない */}
          {/* <Button variant="link" {...(linkColor ? { style: { color: linkColor } } : {})}>
            <FontAwesomeIcon icon={faWindowMaximize} /> {name}テンプレート一覧
          </Button> */}
        </SideBy>
      )}
      <Form.Group>{textInputComponent()}</Form.Group>
    </>
  );
};
