import React, { useCallback, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { parseISO, format, subMonths } from 'date-fns';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { ErrorObjectResponse, ExtendDeadlineHistoryApi } from '../../../api-client';
import type {
  ExtendDeadlineHisListFormResponse,
  ExtendDeadlineHisListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { ExtendDeadlineHistoryForms } from '../../organisms/Forms/ExtendDeadlineHistoryForms';
import { ExtendDeadlineHistoryTable } from '../../organisms/Table/ExtendDeadlineHistoryTable';
import { createTestId, execDownload } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Url } from '../../../constants/Url';
import { Title } from '../../atoms/Title';

export interface ExtendDeadlineHistoryState {
  api: ExtendDeadlineHistoryApi;
  isLoading: boolean;
  list: ExtendDeadlineHisListOutputResponse[];
  forms: ExtendDeadlineHisListFormResponse;
  errorMessages: string[];
}
export type TestIds = 'search-button' | 'link-button';

export type OnDownloadCsvFunc = (id: number) => void;

export const ExtendDeadlineHistoryPage: React.FC = () => {
  const testid = createTestId<TestIds>(ExtendDeadlineHistoryPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<ExtendDeadlineHistoryState>({
    api: new ExtendDeadlineHistoryApi(),
    isLoading: false,
    list: [],
    forms: { createAtFrom: format(subMonths(new Date(), 1), 'yyyy-MM-dd') },
    errorMessages: [],
  });

  // 初期フェッチ
  useEffect(() => {
    onSearch($.forms);
  }, []);

  const history = useHistory();
  const setForms = useBindSet('forms');

  const onDownloadCsv = useCallback(
    (id: number) => {
      mergeState({ isLoading: true });
      $.api
        .extendDeadlineHistoryFileDownload(id)
        .then((res) => {
          execDownload(res.data, 'text/csv', `extend_deadline_${format(new Date(), 'yyyy_MM_dd_hh_mm_ss')}.xlsx`)
            .catch(() => {
              history.push(Url.COMMON_ERROR);
            })
            .finally(() => {
              mergeState({ isLoading: false });
            });
        })
        .catch((error: IncResultOutputResponse) => {
          mergeState({ errorMessages: [error.errorMessage!], list: [], isLoading: false });
        });
    },
    [$.api, history, mergeState]
  );

  const createSearchBody = useCallback((body: ExtendDeadlineHisListFormResponse) => {
    return {
      applyId: body.applyId,
      createAtFrom: body.createAtFrom ? parseISO(body.createAtFrom as string).toISOString() : body.createAtFrom,
      createAtTo: body.createAtTo ? parseISO(body.createAtTo as string).toISOString() : body.createAtTo,
    };
  }, []);

  // 検索
  const onSearch = useCallback(
    (body: ExtendDeadlineHisListFormResponse) => {
      if (body.createAtFrom.length === 0) {
        mergeState({ errorMessages: ['登録開始日は入力必須です'], list: [] });
        return;
      }
      const searchBody = createSearchBody(body);
      $.api
        .extendDeadlineHistoryList(searchBody)
        .then((res: AxiosResponse<ExtendDeadlineHisListOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessages: ['エラーが発生しました。'] });
          } else {
            mergeState({ list: res.data, errorMessages: [] });
          }
        })
        .catch((error) => {
          const errors = [...error.response?.data.errors];
          const errorMessages = errors.map((value: ErrorObjectResponse) => value.message);
          mergeState({ errorMessages, list: [] });
        });
    },
    [$.api, createSearchBody, mergeState]
  );

  return (
    <>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.errorMessages.map((message) => (
          <Alert variant="danger">{message}</Alert>
        ))}
        <Title>期限延長・当選復帰</Title>
        <Button
          variant="link"
          data-testid={testid('link-button')}
          onClick={() => {
            history.push(Url.TENSAKU.EXTEND_DEADLINE_REGISTER);
          }}
        >
          新規作成
        </Button>
        <Form className="mt-4">
          <Row className="g-2 mb-4">
            <Col>
              <ExtendDeadlineHistoryForms setForms={setForms} />
              <Button
                data-testid={testid('search-button')}
                className="mt-4"
                onClick={() => {
                  onSearch($.forms);
                }}
              >
                検索
              </Button>
            </Col>
          </Row>
        </Form>
        {$.list.length ? <ExtendDeadlineHistoryTable list={$.list} onDownloadCsv={onDownloadCsv} /> : undefined}
      </LoadingSpinner>
    </>
  );
};
