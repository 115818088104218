import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useLargeState } from '../../../../hooks/useLargeState';
import { createTestId, formatISODate } from '../../../../utils/functions';
import type { CustomerDetailState } from '../CustomerDetailPage';
import type { CustomerMoralEditConfirmedFormResponse } from '../../../../api-client';
import { CustomerMoralInfoEditModal } from '../../../organisms/Modal/Customer/CustomerMoralInfoEditModal';
import { WrappableText } from '../../../atoms/WrappableText';

export type State = CustomerDetailState['customerData'];
interface Props {
  customerData: State;
}

export interface MoralInfoState {
  isModal: boolean;
  forms: CustomerMoralEditConfirmedFormResponse;
}

export type TestIds = keyof State | 'infection-button' | 'pen';

export const MoralInfoCard: React.FC<Props> = ({ customerData }) => {
  const testid = createTestId<TestIds>(MoralInfoCard);

  const { state: $info } = useLargeState<State>(customerData);

  const initialData: CustomerMoralEditConfirmedFormResponse = useMemo(
    () => ({
      customerId: customerData.customerId,
      moral: Number(customerData.moral),
      moralUpdateReason: customerData.moralUpdateReason || '',
    }),
    [customerData]
  );

  const {
    state: $modal,
    mergeState: mergeModal,
    useBindSet,
  } = useLargeState<MoralInfoState>({
    isModal: false,
    forms: initialData,
  });

  const setForms = useBindSet('forms');

  return (
    <>
      <CustomerMoralInfoEditModal
        isModal={$modal.isModal}
        forms={$modal.forms}
        setForms={setForms}
        onHide={() =>
          mergeModal({
            isModal: false,
            forms: initialData,
          })
        }
      />
      <Card className="mt-2" style={{ width: '24.25%' }}>
        <div className="d-flex justify-content-between mt-2">
          <h6>モラル</h6>
          <div style={{ cursor: 'pointer' }} data-testid={testid('pen')}>
            <FontAwesomeIcon icon={faPen} onClick={() => mergeModal({ isModal: true })} />
          </div>
        </div>

        <small>モラル種別</small>
        <p className="mb-0" data-testid={testid('moralName')}>
          {$info.moralName}
        </p>
        <small className="text-secondary mb-2" data-testid={testid('moralUpdateAt')}>
          更新日時 {$info.moralUpdateAt ? formatISODate($info.moralUpdateAt, 'yyyy/MM/dd HH:mm:ss') : ''}
        </small>

        <small>モラル変更理由</small>
        <WrappableText memo={$info.moralUpdateReason} height={192} data-testid={testid('moralUpdateReason')} />
      </Card>
    </>
  );
};
