import { faGripLines, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { NestedPartial } from '../../../interfaces/utils';
// eslint-disable-next-line import/no-cycle
import {
  QuestionCategoryListApi,
  QuestionCategoryCreateApi,
  QuestionCategoryHierarchyListApi,
} from '../../../api-client';
import type {
  IncResultOutputResponse,
  QuestionCategoryCreateFormResponse,
  QuestionCategoryHierarchyListOutputResponse,
  QuestionCategoryListOutputResponse,
} from '../../../api-client';

export interface Props {
  clientIds: number[] | undefined;
  showFlg: boolean;
  setShowFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestionCategoryList: React.Dispatch<React.SetStateAction<QuestionCategoryListOutputResponse[]>>;
}

type QuestionCategoriesItem = NestedPartial<QuestionCategoryHierarchyListOutputResponse>;

export const EnqueteCategoryConfirmModal: React.VFC<Props> = ({
  showFlg,
  setShowFlg,
  clientIds,
  setQuestionCategoryList,
}) => {
  // 新規ID用のインクリメント変数
  const [idIncrement, setIdIncrement] = useState<number>(0);
  // 元の紐づけられたカテゴリID一覧
  const [dataIdList, setDataIdList] = useState<number[]>([]);
  const indList: number[] = [];
  const dataIds: number[] = [];
  const afterDataIds: number[] = [];
  const [questionCategories, setQuestionCategories] = useState<QuestionCategoriesItem[]>([]);
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>({ result: false });

  const questionCategoryHierarchyListApi = new QuestionCategoryHierarchyListApi();
  const updateApi = new QuestionCategoryCreateApi();
  const questionCategoryListApi = new QuestionCategoryListApi();

  let deletePrevObj: any;
  let deleteCounter = 1;
  const deleteTargetCateItem = (obj: any, rootIds: string[]) => {
    if (rootIds.length !== deleteCounter) {
      deletePrevObj = obj;
      deleteCounter += 1;
      deleteTargetCateItem(
        obj.children.find((item: any) => item.id.toString() === rootIds[deleteCounter - 1]),
        rootIds
      );
    } else {
      deletePrevObj.children.splice(
        deletePrevObj.children.findIndex((item: any) => item.id.toString() === obj.id.toString()),
        1
      );
    }
  };

  useEffect(() => {
    if (!showFlg || !clientIds || clientIds.length === 0) return;
    questionCategoryHierarchyListApi
      .questionCategoryHierarchyList(clientIds)
      .then((res: AxiosResponse<QuestionCategoryHierarchyListOutputResponse[]>) => {
        idsBackUp(res.data);
        setQuestionCategories(res.data);
      });
  }, [showFlg, clientIds]);

  // 削除のため元のIDリストをバックアップする関数
  const idsBackUp = (targetList: any[] | any) => {
    if (targetList !== undefined) {
      for (let i = 0; i < targetList.length; i += 1) {
        dataIds.push(targetList[i].id);
        targetList.forEach((_: any) => {
          dataIds.push(_.id);
          idsBackUp(_.children);
        });
      }
    }
    setDataIdList(
      dataIds.filter((x, i, self) => {
        return self.indexOf(x) === i;
      })
    );
  };

  // 削除のため元のIDリストをバックアップする関数
  const deleteTargetCheck = (targetList: any[] | any): number[] => {
    if (targetList !== undefined) {
      for (let i = 0; i < targetList.length; i += 1) {
        afterDataIds.push(targetList[i].id);
        targetList.forEach((_: any) => {
          afterDataIds.push(_.id);
          deleteTargetCheck(_.children);
        });
      }
    }
    return afterDataIds.filter((x, i, self) => {
      return self.indexOf(x) === i;
    });
  };

  // 保存イベント
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!questionCategories?.length || !clientIds) return;
    const param: QuestionCategoryCreateFormResponse = {
      clientIds,
      // 元の紐づけられていたカテゴリID一覧から削除されているカテゴリIDを探す
      disableQuestionCategoryIds: dataIdList.filter(
        (itemA) => deleteTargetCheck(questionCategories).indexOf(itemA) === -1
      ),
      questionCategories: questionCategories as any[],
    };
    updateApi.questionCategoryCreate(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      if (res.data.result) {
        // 「設問カテゴリ」一覧再取得
        if (clientIds) {
          questionCategoryListApi
            .questionCategoryList(clientIds, true)
            .then((res2: AxiosResponse<QuestionCategoryListOutputResponse[]>) => {
              setQuestionCategoryList(res2.data);
            });
        }
        setShowFlg(false);
      } else {
        setUpdResult(res.data);
      }
    });
  };

  // キャンセルまたはモーダルを閉じた場合のイベント
  const onHide = () => {
    setShowFlg(false);
  };

  // 「✕」ボタンイベント
  const onDelete = (Ids: string[]) => {
    if (!questionCategories.length) return;
    const copyData = JSON.parse(JSON.stringify(questionCategories));
    // 一番上の親カテゴリの場合
    if (Ids.length === 1) {
      copyData.splice(
        copyData.findIndex((item: any) => item.id.toString() === Ids[0]),
        1
      );
    } else {
      deleteTargetCateItem(copyData[copyData.findIndex((item: any) => item.id.toString() === Ids[0])], Ids);
    }
    setQuestionCategories(copyData);
  };

  // テキスト入力Changeイベント
  const onChangeInput = (Ids: string[], e: any) => {
    if (!questionCategories.length) return;

    const copyData = JSON.parse(JSON.stringify(questionCategories));
    if (Ids.length === 1) {
      setQuestionCategories(
        copyData.map((v: any) => (v.id.toString() === Ids[0] ? { ...v, name: e.target.value } : v))
      );
    } else {
      let code = 'copyData.find((item) => item.id.toString() === rootIds[0])';
      for (let i = 0; i < Ids.length - 1; i += 1) {
        code += `.children.find((item) => item.id.toString() === rootIds[${i + 1}])`;
      }
      code += '.name = value';
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      new Function('copyData', 'rootIds', 'value', `"use strict";return ${code}`)(copyData, Ids, e.target.value);
      setQuestionCategories(copyData);
    }
  };

  // 「子カテゴリ追加」ボタンイベント
  const addChildCategory = (rootIds: string[]) => {
    if (!questionCategories.length) return;
    let copyData: QuestionCategoriesItem[] = Object.assign([], questionCategories);
    if (rootIds.length === 1) {
      copyData = copyData.map((v: QuestionCategoriesItem) =>
        v?.id?.toString() === rootIds[0]
          ? {
              ...v,
              children: [...(v.children || []), { id: -(idIncrement + 1), name: '', children: [] }],
            }
          : v
      );
    } else {
      let code = 'copyData.find((item) => item.id.toString() === rootIds[0])';
      for (let i = 0; i < rootIds.length - 1; i += 1) {
        code += `.children.find((item) => item.id.toString() === rootIds[${i + 1}])`;
      }
      code += '.children.push({ id: -(idIncrement + 1) , name: "", children: []})';
      // eslint-disable-next-line @typescript-eslint/no-implied-eval
      new Function('copyData', 'rootIds', 'idIncrement', `"use strict"; return ${code}`)(
        copyData,
        rootIds,
        idIncrement
      );
    }
    setQuestionCategories(copyData);
    setIdIncrement((prev) => prev + 1);
  };

  const contents = (childs: QuestionCategoryHierarchyListOutputResponse[], rootIds: string[]) => {
    return childs?.map((child) => {
      return (
        <Row
          key={child.id}
          className="ms-2 me-2 bg-light ps-3 pt-3 "
          style={{ borderTop: rootIds.length === 1 ? '1px solid #FFF' : '' }}
        >
          <Col className="flex-grow-0 flex-shrink-0 ps-0">
            <button type="button">
              <FontAwesomeIcon data-testid="linesButton" icon={faGripLines} style={{ verticalAlign: -7 }} fixedWidth />
            </button>
          </Col>
          <Col>
            <Row>
              <Col className="col-12">
                <Form.Group controlId="formId2_1">
                  <Form.Control
                    required
                    type="text"
                    value={child.name || ''}
                    onChange={(e) => onChangeInput([...rootIds, child.id.toString()], e)}
                  />
                </Form.Group>
              </Col>
              <div className="d-flex justify-content-end mb-1">
                <Button
                  data-testid="childAddButton"
                  variant="link"
                  className="text-secondary pb-1"
                  onClick={() => addChildCategory([...rootIds, child.id.toString()])}
                >
                  <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                  子カテゴリ追加
                </Button>
              </div>
              {contents(child.children, [...rootIds, child.id.toString()])}
            </Row>
          </Col>
          <Col className="flex-grow-0">
            <button
              data-testid="deleteButton"
              type="button"
              className="text-secondary"
              onClick={() => {
                onDelete([...rootIds, child.id.toString()]);
              }}
              id={`${indList}`}
            >
              <FontAwesomeIcon icon={faTimesCircle} style={{ verticalAlign: -8 }} fixedWidth />
            </button>
          </Col>
        </Row>
      );
    });
  };

  const confirmContents = questionCategories?.map((row: any, ind: number) => {
    return (
      <Fragment key={row.id}>
        <div className="border rounded p-3 me-4">
          <Row className="gx-3">
            <Col className="flex-grow-0 flex-shrink-0">
              <button type="button" className="text-secondary">
                <FontAwesomeIcon
                  data-testid="linesButton"
                  icon={faGripLines}
                  fixedWidth
                  style={{ verticalAlign: -8 }}
                />
              </button>
            </Col>
            <Col>
              <Form.Group controlId="formAccordion_1" className="w-100 mb-3">
                <Form.Control
                  required
                  type="text"
                  value={row.name || ''}
                  onChange={(e) => {
                    onChangeInput([row.id.toString()], e);
                  }}
                />
              </Form.Group>
              {contents(row.children, [row.id.toString()])}
              <div className="d-flex justify-content-end ">
                <Button
                  data-testid="addButton"
                  variant="link"
                  className="text-secondary pb-2"
                  onClick={() => {
                    addChildCategory([row.id.toString()]);
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                  追加
                </Button>
              </div>
            </Col>
            <Col className="flex-grow-0 flex-shrink-0">
              <button
                data-testid="deleteButton"
                type="button"
                className="text-secondary"
                onClick={() => onDelete([row.id.toString()])}
              >
                <FontAwesomeIcon icon={faTimesCircle} fixedWidth style={{ verticalAlign: -8 }} />
              </button>
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-end">
          <Button
            data-testid="addButton"
            variant="link"
            className="text-secondary p-0"
            onClick={() => {
              const copyData = JSON.parse(JSON.stringify(questionCategories));
              copyData.splice(ind + 1, 0, { id: -(idIncrement + 1), name: '', children: [] });
              setQuestionCategories(copyData);
              setIdIncrement((prev) => prev + 1);
            }}
          >
            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          </Button>
        </div>
      </Fragment>
    );
  });

  return (
    <Modal size="xl" show={showFlg} onHide={onHide} scrollable centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <div className="d-flex justify-content-end mb-4">
            <Button variant="link" className="ms-2" onClick={() => setShowFlg(false)}>
              キャンセル
            </Button>
            <Button type="submit" variant="primary" className="ms-2">
              保存
            </Button>
          </div>
          {updResult?.errorCode && updResult?.errorMessage && (
            <Alert variant="danger">{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
          )}
          <div className="d-flex justify-content-end">
            <Button
              data-testid="addButton"
              variant="link"
              className="text-secondary p-0"
              onClick={() => {
                setQuestionCategories([
                  { id: -(idIncrement + 1), name: '', children: [] },
                  ...(questionCategories || []),
                ]);
                setIdIncrement((prev) => prev + 1);
              }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
            </Button>
          </div>
          {confirmContents}
        </Form>
      </Modal.Body>
    </Modal>
  );
};
