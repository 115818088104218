import React, { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { createTestId, nameof } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import type { MoralInfoState as ParentState } from '../../pages/Customer/Card/MoralInfoCard';

interface Props {
  forms: ParentState['forms'];
  setForms: DispatchSetState<ParentState['forms']>;
}
export const CustomerMoralInfoEditModalForms: React.FC<Props> = ({ forms, setForms }) => {
  const testid = createTestId<keyof ParentState['forms'] | 'auto-fill-button'>(CustomerMoralInfoEditModalForms);
  const { state: $, onChangeSet } = useLargeState<ParentState['forms']>(forms ?? {});

  const name = (x: keyof ParentState['forms']) => nameof(x);
  useEffect(() => setForms($), [setForms, $]);

  const options: [string, number][] = useMemo(
    () =>
      Object.entries({
        '普通(当選率100%)': 0,
        '悪い1回目(当選率40%)': 1,
        '悪い2回目(当選率40→0%)': 2,
        '適正なし(当選率0%)': 3,
        '再登録不可(当選率0%)': 4,
      }),
    []
  );

  return (
    <>
      <Form.Group controlId={name('moral')} data-testid={testid('moral')}>
        <Form.Label>モラル</Form.Label>
        <Form.Select value={$.moral} onChange={onChangeSet('moral', Number)}>
          {options.map(([label, value]) => (
            <option key={`${value}`} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group controlId={name('moralUpdateReason')} data-testid={testid('moralUpdateReason')}>
        <Form.Label>モラル変更理由</Form.Label>
        <Form.Control
          as="textarea"
          autoComplete="off"
          value={$.moralUpdateReason ?? ''}
          onChange={onChangeSet('moralUpdateReason')}
        />
      </Form.Group>
    </>
  );
};
