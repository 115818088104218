import React from 'react';

interface Props {
  memo: string | undefined;
  height: number;
}

export const WrappableText: React.FC<Props> = ({ memo, height }) => {
  return <pre style={{ whiteSpace: 'pre-wrap', height: `${height}px` }}>{memo}</pre>;
};
