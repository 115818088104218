import { Font, StyleSheet, Document, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { BillingInvoiceHeaderInfoForPdfOutputResponse } from '../../api-client';

Font.register({
  family: 'NotoSansCJKjp',
  src: '/fonts/Nasu-Regular-20200227.ttf',
});

type Props = {
  headers: BillingInvoiceHeaderInfoForPdfOutputResponse;
  billingType: string;
  paddingHorizontal: number;
};

export const PdfLayout: React.FC<Props> = ({ headers, billingType, paddingHorizontal, children }) => {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal,
      fontFamily: 'Nasu-Regular',
    },
    leftContainer: {
      textAlign: 'left',
      fontSize: 14,
      marginTop: 10,
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: 'black',
      fontFamily: 'Nasu-Regular',
      margin: 12,
    },
    rightContainer: {
      textAlign: 'right',
      fontSize: 11,
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 9,
      bottom: 15,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
  });

  return (
    <Document>
      <Page style={styles.body} size="A4" orientation="landscape">
        <View style={styles.header} fixed>
          <View style={styles.leftContainer}>
            <Text>
              {headers.billingMonth} {billingType}
            </Text>
            <Text>{headers.clientName} 御中</Text>
          </View>
          <View style={styles.rightContainer}>
            <Text>{headers.billingPublishedDate}</Text>
            <Text>請求書番号: {headers.billingHeaderId}</Text>
            <Text>{headers.companyName}</Text>
          </View>
        </View>
        {children}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};
