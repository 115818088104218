import React, { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEye } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { Button } from '../atoms/Button';
import { DispatchSetState } from '../../interfaces/utils';

export interface Props {
  disabled?: boolean;
  setIsModal?: DispatchSetState<boolean>;
}
export const MonitorHeader: React.VFC<Props> = ({ disabled, setIsModal }) => {
  const history = useHistory();

  return (
    <Card className="position-sticky mb-4" style={{ top: '-1.5rem', zIndex: 2 }}>
      <Card.Body className="d-flex justify-content-between p-3">
        <div className="d-flex justify-content-center align-items-center">
          <Button
            variant="link"
            className="text-secondary me-3"
            disabled={disabled}
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} fixedWidth />
            戻る
          </Button>
        </div>

        <div className="d-flex justify-content-end">
          {setIsModal && (
            <Button className="text-nowrap ms-2" onClick={() => setIsModal(true)} disabled={disabled}>
              モニターコピー
            </Button>
          )}
          <Button type="submit" className="ms-2" disabled={disabled}>
            保存
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
