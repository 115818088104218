import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { when } from '../../utils/functions';

export interface Props {
  yesFlg?: boolean;
  onClickButton: (flg: boolean) => void;
  label?: { yes: string; no: string };
  checkFlg?: boolean;
  disabled?: boolean;
}

export const DoubleButton: React.VFC<Props> = ({
  yesFlg,
  onClickButton,
  label = { yes: 'はい', no: 'いいえ' },
  checkFlg = false,
  disabled = false,
}) => {
  const getVariant = (yes: boolean): string => {
    if (yesFlg === undefined) {
      return 'outline-primary';
    }
    if (yes) {
      return yesFlg ? 'primary' : 'outline-primary';
    }
    return yesFlg ? 'outline-primary' : 'primary';
  };
  return (
    <ButtonGroup>
      <Button
        style={{ minWidth: '100px' }}
        variant={getVariant(true)}
        onClick={() => onClickButton(true)}
        disabled={disabled}
      >
        {when(!!checkFlg, <FontAwesomeIcon icon={faCheckCircle} />)}
        {label.yes}
      </Button>
      <Button
        style={{ minWidth: '100px' }}
        variant={getVariant(false)}
        onClick={() => onClickButton(false)}
        disabled={disabled}
      >
        {label.no}
      </Button>
    </ButtonGroup>
  );
};
