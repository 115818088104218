import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { BillingInvoiceDetailProductTab } from '../../organisms/BillingInvoiceDetailProductTab';
import { BillingInvoiceDownloadModal } from '../../organisms/Modal/BillingInvoiceDownloadModal';
import { BillingApi, BillingDestinationInfoOutputResponse } from '../../../api-client';
import { BillingProvider } from '../../context/BillingContext';
import { Url } from '../../../constants/Url';
import { FLOW_STATUS_STRING } from '../../../BillingConstants';
import Loading from '../../molecules/Loading';

const BillingInvoiceDetailDispatchTab = lazy(() =>
  import('../../organisms/BillingInvoiceDetailDispatchTab').then((module) => ({
    default: module.BillingInvoiceDetailDispatchTab,
  }))
);
const BillingInvoiceDetailStoreTab = lazy(() =>
  import('../../organisms/BillingInvoiceDetailStoreTab').then((module) => ({
    default: module.BillingInvoiceDetailStoreTab,
  }))
);
const TAB_TYPE = {
  DOWNLOAD: 'download',
  SETTING: 'setting',
  PRODUCT: 'product',
  STORE: 'store',
  PROJECT: 'project',
  DISPATCH: 'dispatch',
} as const;

export const BillingInvoiceDetail: React.VFC = () => {
  const { id } = useParams<{ id: string | '0' }>();
  const billingId = Number(id);
  const [isModal, setIsModal] = useState<boolean>(false);
  const billingApi = new BillingApi();
  const [billingDestinationInfo, setBillingDestinationInfo] = useState<BillingDestinationInfoOutputResponse>();
  // TODO 無理やりやってるのできれいにしたい
  const [billingFlowStatus, setBillingFlowStatus] = useState<number>(0);
  const [clickShopDetailTab, setClickShopDetailTab] = useState(false);
  const [clickApplyDetailTab, setClickApplyDetailTab] = useState(false);

  useEffect(() => {
    billingApi.billingDestinationInfo(billingId).then((res) => {
      setBillingDestinationInfo(res.data);
    });
    billingApi.billingInvoiceFlowStatus(billingId).then((res) => {
      setBillingFlowStatus(res.data.billingFlowStatusCode);
    });
  }, []);

  /**
   * 設定ボタン
   */
  const handleClickBillingDestSettings = () => {
    window.open(`${Url.BILLING.BILLING_DESTINATION_DETAIL}/${billingDestinationInfo?.billingDestinationId}`, '_blank');
  };
  const clickDownLoad = () => {
    setIsModal(true);
  };
  const onClickTab = (e: string | null) => {
    if (typeof e === 'string') {
      if (e === TAB_TYPE.STORE) {
        setClickShopDetailTab(true);
      }
      if (e === TAB_TYPE.DISPATCH) {
        setClickApplyDetailTab(true);
      }
    }
  };
  return (
    <BillingProvider billingId={billingId}>
      <>
        <BillingInvoiceDownloadModal
          isModal={isModal}
          setIsModal={setIsModal}
          key={billingId + 9}
          clientName={billingDestinationInfo?.clientName || ''}
          billingMonth={billingDestinationInfo?.billingMonth || ''}
          billingDestinationName={billingDestinationInfo?.billingDestinationName}
        />

        <div className="mb-n5 d-flex justify-content-between">
          <div>{billingFlowStatus !== undefined && <h5>{FLOW_STATUS_STRING.get(billingFlowStatus)}</h5>}</div>
          <div>
            <Button onClick={clickDownLoad}>ダウンロード</Button>
            <Button className="ms-2" onClick={handleClickBillingDestSettings}>
              各種設定
            </Button>
          </div>
        </div>
        <Tabs onSelect={(e) => onClickTab(e)}>
          <Tab eventKey={TAB_TYPE.PRODUCT} title="請求書">
            <BillingInvoiceDetailProductTab key={billingId + 10} />
          </Tab>
          <Tab eventKey={TAB_TYPE.STORE} title="店舗/案件別明細">
            {clickShopDetailTab && (
              <Suspense fallback={<Loading />}>
                <BillingInvoiceDetailStoreTab key={billingId + 11} />
              </Suspense>
            )}
          </Tab>
          <Tab eventKey={TAB_TYPE.DISPATCH} title="派遣別明細">
            {clickApplyDetailTab && (
              <Suspense fallback={<Loading />}>
                <BillingInvoiceDetailDispatchTab key={billingId + 12} />
              </Suspense>
            )}
          </Tab>
        </Tabs>
      </>
    </BillingProvider>
  );
};
