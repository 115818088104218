import { useRecoilState } from 'recoil';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Table, Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { Title } from '../atoms/Title';
import {
  BillingApi,
  BillingInvoiceApproverListOutputResponse,
  IncResultOutputResponse,
  UnBillingApprovalListOutputResponse,
  UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum,
  UnBillingApproveFormResponse,
  UnBillingApproveFormRowDataResponse,
} from '../../api-client';
import { YuucomiSuperiorTable } from '../organisms/Table/YuucomiSuperiorTable';
import { ResultAlert } from '../organisms/Alert/ResultAlert';
import { BILLING_APPROVAL_TYPE, BILLING_DEFAULT_FADE_TIME } from '../../BillingConstants';
import { currentUserState } from '../../states/currentUser';

interface RouterParams {
  type: string;
}
export const YuucomiSuperiorPage: React.VFC = () => {
  const { type } = useParams<RouterParams>();
  const billingApi = new BillingApi();
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const [list, setList] = useState<UnBillingApprovalListOutputResponse[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [billingApproverList, setBillingApproverList] = useState<BillingInvoiceApproverListOutputResponse>();
  const currentUser = useRecoilState(currentUserState);
  const [selectedApproverId, setSelectedApproverId] = useState<number | undefined>(currentUser[0]!.id);
  const fetchData = () => {
    billingApi.unBillingApprovalList(Number(type), selectedApproverId).then((res) => {
      setList(res.data);
    });
  };

  const fetchBillingApproverList = () => {
    billingApi.billingApproverList(currentUser[0]!.id).then((res) => {
      setBillingApproverList(res.data);
    });
  };

  const handleInputValidate = (list_: UnBillingApprovalListOutputResponse[]) => {
    setErrorMsg('');
    let result = false;
    if (!list_) {
      // データがない場合もエラーにする
      result = true;
    }
    list_.some((element) => {
      if (
        element.unBillingApprovalStatus === UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum.Reject &&
        !element.rejectComment
      ) {
        setErrorMsg('却下する場合は却下コメントが必須です。');
        result = true;
      }
    });
    return result;
  };

  const handleApprove = (e: any) => {
    e.preventDefault();
    if (handleInputValidate(list)) {
      return;
    }
    const newList: UnBillingApproveFormRowDataResponse[] = [];
    list.map((row) => {
      if (row.unBillingApprovalStatus) {
        newList.push({
          applyId: row.applyId,
          billingApprovalId: row.billingApprovalId,
          rejectReason: row.rejectComment,
          unBillingApprovalStatus: row.unBillingApprovalStatus,
        });
      }
    });
    const updateForm: UnBillingApproveFormResponse = { unBillingList: newList };
    if (updateForm) {
      billingApi.unbillingApprove(updateForm).then((res) => {
        res.data.clientIds.map((v) => {
          const req = new XMLHttpRequest();
          // 再作成ジョブを実行
          req.open('get', `${process.env.REACT_APP_TOB_BATCH_HOST}/api/job/daily-batch?clientId=${v}`);
          req.setRequestHeader('Access-Control-Allow-Origin', '*');
          req.send();
        });

        setUpdResult({ result: true } as IncResultOutputResponse);
        fetchData();
      });
    }
  };

  const handleAllApprove = () => {
    const newList = list.map((v) => {
      return { ...v, unBillingApprovalStatus: UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum.Approve };
    });
    setList(newList);
  };

  useEffect(() => {
    fetchData();
    fetchBillingApproverList();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedApproverId]);

  const handleChangeYuuComiSuperior = (newValue: UnBillingApprovalListOutputResponse) => {
    setList(
      list.map((value) => {
        if (value.billingApprovalId === newValue.billingApprovalId) {
          return newValue;
        }
        return value;
      })
    );
  };
  return (
    <>
      <Title className="mb-4">非請求承認</Title>

      {Number(type) === BILLING_APPROVAL_TYPE.SALES && (
        <>
          <Row>
            <Col className="col-2">
              <Form.Label>承認者</Form.Label>
              <Form.Select
                onChange={(e) => setSelectedApproverId(e.target.value === '' ? undefined : Number(e.target.value))}
                value={selectedApproverId}
              >
                <option value="">{}</option>
                {billingApproverList?.billingApproverList.map((v) => {
                  return (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
          </Row>
        </>
      )}

      <ResultAlert updResult={updResult} fadeoutTime={BILLING_DEFAULT_FADE_TIME} setUpdResult={setUpdResult} />
      <div className="d-flex justify-content-end mb-4">
        <Button variant="secondary" className="me-4" onClick={handleAllApprove}>
          一括承認
        </Button>
      </div>
      <Table className="px-0" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>クライアント名</th>
            <th>店舗名</th>
            <th style={{ width: '65px' }}>応募ID</th>
            <th style={{ width: '65px' }}>非請求</th>
            <th style={{ width: '65px' }}>管理画面非表示</th>
            <th style={{ width: '65px' }}>増枠</th>
            <th>理由</th>
            <th style={{ width: '90px' }}>申請者</th>
            {Number(type) === BILLING_APPROVAL_TYPE.SALES && <th style={{ width: '90px' }}>承認者</th>}
            <th style={{ width: '100px' }}>却下/承認</th>
            <th style={{ width: '300px' }}>却下理由</th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.map((v) => {
              return (
                <YuucomiSuperiorTable
                  key={v.applyId}
                  row={v}
                  handleChangeYuuComiSuperior={handleChangeYuuComiSuperior}
                  type={type}
                />
              );
            })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end mt-4 mb-2">
        <Button variant="secondary" className="me-4" onClick={(e) => handleApprove(e)}>
          更新
        </Button>
      </div>
    </>
  );
};
