/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplicationInfoListOutputResponse } from '../domains';
/**
 * ApplicationInfoListApi - axios parameter creator
 * @export
 */
export const ApplicationInfoListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 申込書情報一覧を取得する
         * @summary 申込書情報一覧取得
         * @param {string} [clientKeyword] 
         * @param {string} [roiMemberName] 
         * @param {string} [contractStartDateFrom] 
         * @param {string} [contractStartDateTo] 
         * @param {string} [contractEndDateFrom] 
         * @param {string} [contractEndDateTo] 
         * @param {boolean} [activeFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationInfoList: async (clientKeyword?: string, roiMemberName?: string, contractStartDateFrom?: string, contractStartDateTo?: string, contractEndDateFrom?: string, contractEndDateTo?: string, activeFlg?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/application-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientKeyword !== undefined) {
                localVarQueryParameter['clientKeyword'] = clientKeyword;
            }

            if (roiMemberName !== undefined) {
                localVarQueryParameter['roiMemberName'] = roiMemberName;
            }

            if (contractStartDateFrom !== undefined) {
                localVarQueryParameter['contractStartDateFrom'] = (contractStartDateFrom as any instanceof Date) ?
                    (contractStartDateFrom as any).toISOString().substr(0,10) :
                    contractStartDateFrom;
            }

            if (contractStartDateTo !== undefined) {
                localVarQueryParameter['contractStartDateTo'] = (contractStartDateTo as any instanceof Date) ?
                    (contractStartDateTo as any).toISOString().substr(0,10) :
                    contractStartDateTo;
            }

            if (contractEndDateFrom !== undefined) {
                localVarQueryParameter['contractEndDateFrom'] = (contractEndDateFrom as any instanceof Date) ?
                    (contractEndDateFrom as any).toISOString().substr(0,10) :
                    contractEndDateFrom;
            }

            if (contractEndDateTo !== undefined) {
                localVarQueryParameter['contractEndDateTo'] = (contractEndDateTo as any instanceof Date) ?
                    (contractEndDateTo as any).toISOString().substr(0,10) :
                    contractEndDateTo;
            }

            if (activeFlg !== undefined) {
                localVarQueryParameter['activeFlg'] = activeFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationInfoListApi - functional programming interface
 * @export
 */
export const ApplicationInfoListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationInfoListApiAxiosParamCreator(configuration)
    return {
        /**
         * 申込書情報一覧を取得する
         * @summary 申込書情報一覧取得
         * @param {string} [clientKeyword] 
         * @param {string} [roiMemberName] 
         * @param {string} [contractStartDateFrom] 
         * @param {string} [contractStartDateTo] 
         * @param {string} [contractEndDateFrom] 
         * @param {string} [contractEndDateTo] 
         * @param {boolean} [activeFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationInfoList(clientKeyword?: string, roiMemberName?: string, contractStartDateFrom?: string, contractStartDateTo?: string, contractEndDateFrom?: string, contractEndDateTo?: string, activeFlg?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplicationInfoListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationInfoList(clientKeyword, roiMemberName, contractStartDateFrom, contractStartDateTo, contractEndDateFrom, contractEndDateTo, activeFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationInfoListApi - factory interface
 * @export
 */
export const ApplicationInfoListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationInfoListApiFp(configuration)
    return {
        /**
         * 申込書情報一覧を取得する
         * @summary 申込書情報一覧取得
         * @param {string} [clientKeyword] 
         * @param {string} [roiMemberName] 
         * @param {string} [contractStartDateFrom] 
         * @param {string} [contractStartDateTo] 
         * @param {string} [contractEndDateFrom] 
         * @param {string} [contractEndDateTo] 
         * @param {boolean} [activeFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationInfoList(clientKeyword?: string, roiMemberName?: string, contractStartDateFrom?: string, contractStartDateTo?: string, contractEndDateFrom?: string, contractEndDateTo?: string, activeFlg?: boolean, options?: any): AxiosPromise<ApplicationInfoListOutputResponse> {
            return localVarFp.applicationInfoList(clientKeyword, roiMemberName, contractStartDateFrom, contractStartDateTo, contractEndDateFrom, contractEndDateTo, activeFlg, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationInfoListApi - object-oriented interface
 * @export
 * @class ApplicationInfoListApi
 * @extends {BaseAPI}
 */
export class ApplicationInfoListApi extends BaseAPI {
    /**
     * 申込書情報一覧を取得する
     * @summary 申込書情報一覧取得
     * @param {string} [clientKeyword] 
     * @param {string} [roiMemberName] 
     * @param {string} [contractStartDateFrom] 
     * @param {string} [contractStartDateTo] 
     * @param {string} [contractEndDateFrom] 
     * @param {string} [contractEndDateTo] 
     * @param {boolean} [activeFlg] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationInfoListApi
     */
    public applicationInfoList(clientKeyword?: string, roiMemberName?: string, contractStartDateFrom?: string, contractStartDateTo?: string, contractEndDateFrom?: string, contractEndDateTo?: string, activeFlg?: boolean, options?: AxiosRequestConfig) {
        return ApplicationInfoListApiFp(this.configuration).applicationInfoList(clientKeyword, roiMemberName, contractStartDateFrom, contractStartDateTo, contractEndDateFrom, contractEndDateTo, activeFlg, options).then((request) => request(this.axios, this.basePath));
    }
}
