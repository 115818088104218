import React from 'react';
import { View } from '@react-pdf/renderer';
import { BillingInvoiceDetailApplicationSearchForPdfOutputResponse } from '../../../api-client';
import { Col } from '../../atoms/PdfParts/Col';
import { Row } from '../../atoms/PdfParts/Row';
import { TextStyle } from '../../atoms/PdfParts/TextStyle';
import { calcFee, convertMoneyText, getRateText, getRowHeightByStrLen } from '../../../utils/functionsForBilling';
import { BILLING_PDF_SETTINGS } from '../../../BillingConstants';

type Props = {
  lists: BillingInvoiceDetailApplicationSearchForPdfOutputResponse;
  totals: { amount: number; rewardFee: number; fee: number };
};

const items = [
  {
    name: 'No',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_NO,
  },
  {
    name: '店舗名/商品名',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_SHOP_NAME,
  },
  {
    name: '調査名',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_MONITOR_NAME,
  },
  {
    name: '応募ID',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS,
  },
  {
    name: '来店日',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS,
  },
  {
    name: '提出日',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS,
  },
  {
    name: 'ユーザー利用金額(税込)',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS,
  },
  {
    name: '謝礼',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS,
  },
  {
    name: '手数料',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS,
  },
  {
    name: '謝礼',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS,
  },
  {
    name: '手数料',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS,
  },
  {
    name: '備考',
    size: BILLING_PDF_SETTINGS.APPLICATION_DETAIL_REMARKS,
  },
];

export const DispatchDetailTable: React.FC<Props> = ({ lists, totals }) => {
  let rowCount = 0;
  return (
    <>
      <Row borderBottom borderTop borderRight borderLeft fixed th>
        {items.map((item, i) => (
          <Col th key={i} width_={item.size || 1} borderRight={i < items.length - 1}>
            <TextStyle th>{item.name}</TextStyle>
          </Col>
        ))}
      </Row>
      {lists.billingInvoiceDetailApplicationSearchOutputList.length !== 0 &&
        lists.billingInvoiceDetailApplicationSearchOutputList.map((list, i) => {
          if (rowCount >= BILLING_PDF_SETTINGS.MAX_LINE_NUM_DEFAULT) {
            rowCount = 0;
          }
          rowCount += Math.max(
            getRowHeightByStrLen(
              list.shopName,
              BILLING_PDF_SETTINGS.APPLICATION_DETAIL_SHOP_NAME,
              BILLING_PDF_SETTINGS.MAX_LINE_DEFAULT
            ),
            // getRowHeightByStrLen(
            //   list.contractName,
            //   BILLING_PDF_SETTINGS.APPLICATION_DETAIL_CONTRACT_NAME,
            //   BILLING_PDF_SETTINGS.MAX_LINE_DEFAULT
            // ),
            getRowHeightByStrLen(
              list.monitorBaseName,
              BILLING_PDF_SETTINGS.APPLICATION_DETAIL_MONITOR_NAME,
              BILLING_PDF_SETTINGS.MAX_LINE_DEFAULT
            )
          );

          return (
            <Row
              key={i}
              borderBottom={i !== lists.billingInvoiceDetailApplicationSearchOutputList.length - 1}
              borderLeft
              borderRight
              break_={rowCount >= BILLING_PDF_SETTINGS.MAX_LINE_NUM_DEFAULT}
            >
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_NO}>
                <TextStyle>{i + 1}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_SHOP_NAME}>
                <TextStyle>{list.shopName}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_MONITOR_NAME}>
                <TextStyle>{list.monitorBaseName}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS}>
                <TextStyle>{list.applyId}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS}>
                <TextStyle>{list.visitAt}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS}>
                <TextStyle>{list.completeAt}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS} right>
                <TextStyle>{convertMoneyText(list.customerAmount)}</TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS} right>
                <TextStyle>
                  {list.billingRewardFeeType === 'RATIO'
                    ? getRateText(list.billingRewardFeeValue, false)
                    : convertMoneyText(list.billingRewardFeeValue)}
                </TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS} right>
                <TextStyle>
                  {list.billingFeeType === 'RATIO'
                    ? getRateText(list.billingFeeValue, false)
                    : convertMoneyText(list.billingFeeValue)}
                </TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS} right>
                <TextStyle>
                  {convertMoneyText(
                    calcFee(
                      list.billingRewardFeeType,
                      list.customerAmount,
                      list.billingRewardFeeValue,
                      list.billingRewardFeeLower,
                      list.billingRewardFeeUpper
                    )
                  )}
                </TextStyle>
              </Col>
              <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS} right>
                <TextStyle>
                  {convertMoneyText(
                    calcFee(
                      list.billingFeeType,
                      list.customerAmount,
                      list.billingFeeValue,
                      list.billingFeeLower,
                      list.billingFeeUpper
                    )
                  )}
                </TextStyle>
              </Col>
              <Col width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_REMARKS}>
                <TextStyle>{list.remarks}</TextStyle>
              </Col>
            </Row>
          );
        })}
      <View style={{ width: '100%', borderStyle: 'solid', paddingBottom: '1', borderTop: 1 }} />
      <Row borderTop borderBottom borderLeft borderRight th>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_NO}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_SHOP_NAME} right>
          <TextStyle>合計</TextStyle>
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_MONITOR_NAME}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS} right>
          <TextStyle>{convertMoneyText(totals.amount)}</TextStyle>
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS}>
          {}
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS} right>
          <TextStyle>{convertMoneyText(totals.rewardFee)}</TextStyle>
        </Col>
        <Col borderRight width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_OTHERS} right>
          <TextStyle>{convertMoneyText(totals.fee)}</TextStyle>
        </Col>
        <Col width_={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_REMARKS}>{}</Col>
      </Row>
    </>
  );
};
