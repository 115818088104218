import React from 'react';
import type { DisplayPointOutputResponse } from '../../../../../api-client';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import sass from '../../../../../scss/templates/CorrectionTemplate.module.scss';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { equalDp, useMergeStateOfCommentEditors } from '../../../../pages/Correction/utils';
import { CommentEditor } from '../parts/CommentEditor';

export const EscalateEditor: React.FC<{
  /** 送信に使用するDisplayPoint */
  sendingDisplayPoint?: Partial<DisplayPointOutputResponse>;
}> = ({ sendingDisplayPoint: sdp }) => {
  const {
    largeState: { state: $, setState },
  } = useSafeContext(CorrectionContext);
  const mergeArray = useMergeStateOfCommentEditors(sdp, setState);
  return (
    <CommentEditor
      title="事務所へ質問"
      templateName="エスカレ"
      formState={($.commentForms_escale ?? []).find(equalDp(sdp))}
      onChange={(state) => mergeArray(state, 'commentForms_escale')}
      templateList={$.res_escalationTemplateList ?? []}
      // linkColor="black"
      styleClassName={sass.editor_escale}
      buttonVariant="info"
    />
  );
};
