import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { MailaddressOutputResponse, SqlQueryListOutputResponse } from '../../../api-client/domains';
import { LinkButton } from '../../atoms/Button/LinkButton';
import { pageTransition } from '../../../utils/pageTransition';
import { when } from '../../../utils/functions';

interface Props {
  tableData: SqlQueryListOutputResponse[];
}

export const SqlQueryTable: React.FC<Props> = (props: Props) => {
  const { tableData } = props;
  const history = useHistory();

  // 結果欄の出力条件修正

  return (
    <Table className="table">
      <thead>
        <tr>
          <th key="ID" style={{ width: '2%' }}>
            ID
          </th>
          <th key="クエリ名" style={{ width: '20%' }}>
            クエリ名
          </th>
          <th key="最終更新者" style={{ width: '7%' }}>
            最終更新者
          </th>
          <th key="更新日" style={{ width: '7%' }}>
            更新日
          </th>
          <th key="メールアドレス" style={{ width: '20%' }}>
            メールアドレス
          </th>
          <th key="実行予定時間" style={{ width: '7%' }}>
            実行予定時間
          </th>
          <th key="開始時間/終了時間" style={{ width: '20%' }}>
            開始時間/終了時間
          </th>
          <th key="実行結果(クリックでデータダウンロード)" style={{ width: '5%' }}>
            実行結果(クリックでデータダウンロード)
          </th>
          <th key="操作" style={{ width: '5%' }}>
            操作
          </th>
        </tr>
      </thead>
      <tbody>
        {tableData?.map((data: SqlQueryListOutputResponse) => {
          return (
            <tr key={data.queryId}>
              <td>{data.queryId}</td>
              <td>
                <LinkButton
                  text={data.queryName}
                  onClick={() => pageTransition({ type: 'SQL_QUERY_INFO' }, data, history)}
                />
              </td>
              <td>{data.lastUpdater}</td>
              <td>{data.updateTime}</td>
              <td style={{ wordBreak: 'break-all' }}>
                {data.mailaddressList.map((address: MailaddressOutputResponse) => (
                  <p>{address.mailaddress}</p>
                ))}
              </td>
              <td>{data.executionPlanTime}</td>
              <td>{`${data.startTime}/${data.endTime}`}</td>
              <td>
                {when(
                  data.resultFilePath !== '' && !data.errFlg,
                  <a href={data.resultFilePath} download>
                    結果
                  </a>
                )}
                {when(data.startTime === '' && data.endTime === '', <>未実行</>)}
                {when(data.errFlg, <>失敗</>)}
              </td>
              <td>
                <LinkButton text="コピー" onClick={() => pageTransition({ type: 'SQL_QUERY_COPY' }, data, history)} />
                <LinkButton text="編集" onClick={() => pageTransition({ type: 'SQL_QUERY_UPDATE' }, data, history)} />
                <LinkButton text="削除" onClick={() => pageTransition({ type: 'SQL_QUERY_DELETE' }, data, history)} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
