import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SqlTemplateApi, SqlTemplateUpdateFormResponse, SqlTemplateListOutputResponse } from '../../../api-client';
import { SqlTemplateInfoTable } from '../../organisms/Table/SqlTemplateInfoTable';
import { PrimaryButton } from '../../atoms/Button/PrimaryButton';
import { Url } from '../../../constants/Url';

export const SqlTemplateUpdateConfirmPage = () => {
  const location = useLocation();
  const history = useHistory();
  // location.stateの型定義
  const updateContents = location.state as SqlTemplateListOutputResponse & SqlTemplateUpdateFormResponse;
  const ClickConfirm = () => {
    const sqlUpdate = new SqlTemplateApi();

    sqlUpdate.sqlTemplateUpdate(updateContents.templateId, updateContents).then((response: AxiosResponse<any>) => {
      history.push(Url.KEISAI.SQL_TMP_COMPLETION);
    });
  };

  return (
    <>
      <p>変更を確定します。よろしいですか？</p>
      <SqlTemplateInfoTable tableTitle="SQLテンプレート編集確認" cellData={updateContents} />
      <PrimaryButton onClick={ClickConfirm}>変更確定</PrimaryButton>
    </>
  );
};
