/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { InlineObjectResponse } from '../domains';
// @ts-ignore
import { MailBulkDeliveryDetailOutputResponse } from '../domains';
// @ts-ignore
import { MailBulkDeliveryListOutputResponse } from '../domains';
// @ts-ignore
import { MailBulkDeliveryUpdateFormResponse } from '../domains';
/**
 * MailBulkDeliveryApi - axios parameter creator
 * @export
 */
export const MailBulkDeliveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 一括メール配信詳細取得
         * @summary 一括メール配信詳細取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailBulkDeliveryDetail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('mailBulkDeliveryDetail', 'id', id)
            const localVarPath = `/api/mail-bulk-delivery/info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary メール一括配信履歴一覧
         * @param {InlineObjectResponse} inlineObjectResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailBulkDeliveryList: async (inlineObjectResponse: InlineObjectResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObjectResponse' is not null or undefined
            assertParamExists('mailBulkDeliveryList', 'inlineObjectResponse', inlineObjectResponse)
            const localVarPath = `/api/mail-bulk-delivery/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObjectResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 一括メール配信詳細登録
         * @param {MailBulkDeliveryUpdateFormResponse} mailBulkDeliveryUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailBulkDeliveryUpdate: async (mailBulkDeliveryUpdateFormResponse: MailBulkDeliveryUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailBulkDeliveryUpdateFormResponse' is not null or undefined
            assertParamExists('mailBulkDeliveryUpdate', 'mailBulkDeliveryUpdateFormResponse', mailBulkDeliveryUpdateFormResponse)
            const localVarPath = `/api/mail-bulk-delivery`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mailBulkDeliveryUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MailBulkDeliveryApi - functional programming interface
 * @export
 */
export const MailBulkDeliveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MailBulkDeliveryApiAxiosParamCreator(configuration)
    return {
        /**
         * 一括メール配信詳細取得
         * @summary 一括メール配信詳細取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailBulkDeliveryDetail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailBulkDeliveryDetailOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailBulkDeliveryDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary メール一括配信履歴一覧
         * @param {InlineObjectResponse} inlineObjectResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailBulkDeliveryList(inlineObjectResponse: InlineObjectResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MailBulkDeliveryListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailBulkDeliveryList(inlineObjectResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 一括メール配信詳細登録
         * @param {MailBulkDeliveryUpdateFormResponse} mailBulkDeliveryUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mailBulkDeliveryUpdate(mailBulkDeliveryUpdateFormResponse: MailBulkDeliveryUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mailBulkDeliveryUpdate(mailBulkDeliveryUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MailBulkDeliveryApi - factory interface
 * @export
 */
export const MailBulkDeliveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MailBulkDeliveryApiFp(configuration)
    return {
        /**
         * 一括メール配信詳細取得
         * @summary 一括メール配信詳細取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailBulkDeliveryDetail(id: number, options?: any): AxiosPromise<MailBulkDeliveryDetailOutputResponse> {
            return localVarFp.mailBulkDeliveryDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary メール一括配信履歴一覧
         * @param {InlineObjectResponse} inlineObjectResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailBulkDeliveryList(inlineObjectResponse: InlineObjectResponse, options?: any): AxiosPromise<MailBulkDeliveryListOutputResponse> {
            return localVarFp.mailBulkDeliveryList(inlineObjectResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 一括メール配信詳細登録
         * @param {MailBulkDeliveryUpdateFormResponse} mailBulkDeliveryUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mailBulkDeliveryUpdate(mailBulkDeliveryUpdateFormResponse: MailBulkDeliveryUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.mailBulkDeliveryUpdate(mailBulkDeliveryUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MailBulkDeliveryApi - object-oriented interface
 * @export
 * @class MailBulkDeliveryApi
 * @extends {BaseAPI}
 */
export class MailBulkDeliveryApi extends BaseAPI {
    /**
     * 一括メール配信詳細取得
     * @summary 一括メール配信詳細取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailBulkDeliveryApi
     */
    public mailBulkDeliveryDetail(id: number, options?: AxiosRequestConfig) {
        return MailBulkDeliveryApiFp(this.configuration).mailBulkDeliveryDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary メール一括配信履歴一覧
     * @param {InlineObjectResponse} inlineObjectResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailBulkDeliveryApi
     */
    public mailBulkDeliveryList(inlineObjectResponse: InlineObjectResponse, options?: AxiosRequestConfig) {
        return MailBulkDeliveryApiFp(this.configuration).mailBulkDeliveryList(inlineObjectResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 一括メール配信詳細登録
     * @param {MailBulkDeliveryUpdateFormResponse} mailBulkDeliveryUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MailBulkDeliveryApi
     */
    public mailBulkDeliveryUpdate(mailBulkDeliveryUpdateFormResponse: MailBulkDeliveryUpdateFormResponse, options?: AxiosRequestConfig) {
        return MailBulkDeliveryApiFp(this.configuration).mailBulkDeliveryUpdate(mailBulkDeliveryUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
