import * as React from 'react';
import { Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { FieldArrayWithId, UseFormRegister } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { Badge } from '../../atoms/Badge';
import { FormControl } from '../../molecules/Form/FormControl';
import type { Gender } from '../../pages/Banner/BannerPriorityModifyPage';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import type { BannerPriorityListOutputResponse } from '../../../api-client';
import { DispatchSetState } from '../../../interfaces/utils';

export interface Props {
  tab: Gender;
  fields: FieldArrayWithId<BannerPriorityListOutputResponse, 'list', 'id'>[];
  register: UseFormRegister<BannerPriorityListOutputResponse>;
  currentPages: { MALE: number; FEMALE: number };
  setCurrentPages: DispatchSetState<{ MALE: number; FEMALE: number }>;
}

const GENDER_SETTING = {
  MALE: '男',
  FEMALE: '女',
  BOTH: '指定なし',
} as const;

const MAX_RECORD_PER_PAGE = 100;
export const BannerPriorityModifyTable: React.VFC<Props> = ({
  tab,
  fields,
  register,
  currentPages,
  setCurrentPages,
}) => {
  const filteredList = fields.filter(
    ({ gender }) => gender === GENDER_SETTING[tab as Gender] || gender === GENDER_SETTING.BOTH
  );

  // 各アイテムの正しい index
  const indexMap = new Map<number, number>();
  fields.forEach((value, index, array) => {
    indexMap.set(value.id, index);
  });

  const totalPage = Math.ceil(filteredList.length / MAX_RECORD_PER_PAGE);

  return (
    <>
      <Table className="mb-4">
        <thead>
          <tr>
            <th>ID</th>
            <th className="text-center" style={{ width: '130px' }}>
              バナー表示状態
            </th>
            <th>広告名称</th>
            <th className="col-1">
              優先レベル
              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Popover id="popover-basic" className="mw-100">
                    <Popover.Body className="p-1">大きい数値ほど優先度高い</Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <FontAwesomeIcon icon={faQuestionCircle} fixedWidth />
                </span>
              </OverlayTrigger>
            </th>
            <th>エリア設定</th>
            <th>性別設定</th>
            <th>年齢設定</th>
            <th>有効／無効</th>
          </tr>
        </thead>
        <tbody>
          {filteredList
            .filter(
              (_, i) =>
                i + 1 > (currentPages[tab] - 1) * MAX_RECORD_PER_PAGE && i < currentPages[tab] * MAX_RECORD_PER_PAGE
            )
            .map((field, idx) => (
              <tr key={field.id} className="align-middle">
                <td>{field.id}</td>
                <td>
                  {field.bannerViewStatus === '表示中' ? (
                    <Badge bg="success" pill style={{ fontSize: '0.875rem', width: '100%' }}>
                      {field.bannerViewStatus}
                    </Badge>
                  ) : (
                    field.bannerViewStatus
                  )}
                </td>
                <td>{field.title}</td>
                <td>
                  {tab !== 'FEMALE' && (
                    <FormControl
                      id="male"
                      type="number"
                      className="text-align-center"
                      register={register}
                      registerName={`list.${indexMap.get(field.id)}.malePriority`}
                    />
                  )}

                  {tab !== 'MALE' && (
                    <FormControl
                      id="female"
                      type="number"
                      className="text-align-center"
                      register={register}
                      registerName={`list.${indexMap.get(field.id)}.femalePriority`}
                    />
                  )}
                </td>
                <td>{field.area}</td>
                <td>{field.gender}</td>
                <td>{field.age}</td>
                <td>{field.validFlg ? '有効' : '無効'}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div className="mb-4">
        <PaginationWithEllipsis
          currentPage={currentPages[tab]}
          totalPage={totalPage}
          handleClick={(page) => {
            if (page < 1 || totalPage < page) return;
            setCurrentPages({ ...currentPages, [tab]: page });
          }}
        />
      </div>
    </>
  );
};
