import React from 'react';
import { Form } from 'react-bootstrap';

export const NgRejectContent: React.FC<{
  ngMessage: string;
  rejectAlertFlg: boolean;
  onChangeNgMessage: (value: string) => void;
  onChangeRejectAlertFlg: (value: boolean) => void;
  type?: 'card';
}> = React.memo(({ ngMessage, rejectAlertFlg, onChangeNgMessage, onChangeRejectAlertFlg, type }) => {
  return (
    <div className={`p-2 mb-2 ${type === 'card' && 'bg-white'}`}>
      <div className="bg-light p-2 mt-2">
        <Form.Label>NG文言通知</Form.Label>
        <Form.Control type="text" value={ngMessage} onChange={(e) => onChangeNgMessage(e.target.value)} />
      </div>
      <div className="mt-2 d-flex justify-content-start">
        <Form.Check type="switch" checked={rejectAlertFlg} onChange={() => onChangeRejectAlertFlg(rejectAlertFlg)} />
        <div className="ms-2">却下対象アラート</div>
      </div>
    </div>
  );
});
