import * as React from 'react';
import { Table } from 'react-bootstrap';
import { createTestId } from '../../../utils/functions';
import type { Summary } from '../../pages/PostingRequest/PostingRequestPage';

export type Summary2 = {
  clientId?: number | undefined;
  clientName?: string | undefined;
  applicationFormId?: number | undefined;
  applicationFormName?: string | undefined;
  applicationFormType?: string | undefined;
  contractStartDate?: string | undefined;
  contractEndDate?: string | undefined;
  trackerType?: number | undefined;
  trackerTypeName?: string | undefined;
};

export interface Props {
  // info: Summary;
  info: Summary2;
}

export const PostingSummaryTable2: React.VFC<Props> = ({ info }) => {
  const {
    clientId,
    clientName,
    applicationFormName,
    applicationFormType,
    contractStartDate,
    contractEndDate,
    trackerTypeName,
  } = info;
  return (
    <Table className="w-75 mb-4">
      <thead>
        <tr>
          <th>クライアントID</th>
          <th>クライアント名</th>
          <th>申込書名</th>
          <th>申込書種別</th>
          <th>期間</th>
          <th>トラッカー種別</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{clientId}</td>
          <td>{clientName}</td>
          <td>{applicationFormName}</td>
          <td>{applicationFormType}</td>
          <td>{contractStartDate && contractEndDate ? `${contractStartDate} ~ ${contractEndDate}` : ''}</td>
          <td>{trackerTypeName}</td>
        </tr>
      </tbody>
    </Table>
  );
};
