import React, { ReactNode } from 'react';
import { Badge } from 'react-bootstrap';
import { Color } from 'react-bootstrap/esm/types';

export enum BadgeType {
  INFO,
  MUST_ACTION,
}

interface Props {
  type?: number;
  children: ReactNode;
}
// TODO: [添削] Storybook未実装
export const BadgeHead: React.FC<Props> = ({ type, children }) => {
  const colorClass =
    type === BadgeType.MUST_ACTION ? { bg: 'info', text: 'dark' as Color } : { bg: 'dark', text: 'light' as Color };
  return (
    <h5>
      <Badge pill bg={colorClass.bg} text={colorClass.text}>
        {children}
      </Badge>
    </h5>
  );
};
