import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Badge, Nav, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { createTestId, formatISODate, when } from '../../../utils/functions';
/* eslint-disable import/no-cycle */
import {
  ApplyInfoOutputResponse,
  ApplyStatusListOutputResponse,
  CustomerInfoApi,
  GetCustomerIdByApplyIdOutputResponse,
  WinLostHistoryOutputResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import ApplyMonitorInfoCard from '../../organisms/Card/ApplyInfo/ApplyMonitorInfoCard';
import ApplyStatusCard from '../../organisms/Card/ApplyInfo/ApplyStatusCard';
import ReceiptInfoCard from '../../organisms/Card/ApplyInfo/ReceiptInfoCard';
import SurveyProofCard from '../../organisms/Card/ApplyInfo/SurveyProofCard';
import { WinLostHistoryTable } from '../../organisms/Table/WinLostHistoryTable';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { CustomerInfoHeader } from '../Customer/CustomerInfoHeader';

export interface State {
  api: CustomerInfoApi;
}

export const ApplyInfoPage: React.VFC = () => {
  const testid = createTestId(ApplyInfoPage);

  const { applyId, customerIdStr } = useParams<{ applyId: string | undefined; customerIdStr: string | undefined }>();
  const [customerId, setCustomerId] = useState<number>(Number(customerIdStr));

  const history = useHistory();

  const [applyInfo, setApplyInfo] = useState<ApplyInfoOutputResponse>();
  const [applyStatusList, setApplyStatusList] = useState<ApplyStatusListOutputResponse[]>();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [winLostHistoryList, setWinLostHistoryList] = useState<WinLostHistoryOutputResponse[]>([]);

  useEffect(() => {
    if (applyId && Number(applyId) > 0) {
      const api = new CustomerInfoApi();
      // 会員IDがパラメータとして存在しない場合は応募IDから会員IDを取得する
      if (customerIdStr === undefined) {
        api.getCustomerIdByApplyId(Number(applyId)).then((res: AxiosResponse<GetCustomerIdByApplyIdOutputResponse>) => {
          setCustomerId(res.data.customerId);
        });
      }
      api
        .customerInformationApplyInfo(Number(applyId))
        .then((res) => {
          setApplyInfo(res.data);
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });

      api
        .applyStatusList()
        .then((res) => {
          setApplyStatusList(res.data);
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
      api
        .winLostHistory(Number(applyId))
        .then((res: AxiosResponse<Array<WinLostHistoryOutputResponse>>) => {
          setWinLostHistoryList(res.data);
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    }
  }, [applyId, history]);

  const onHideCancelModal = () => {
    setShowCancelModal(false);
  };

  const onCancel = () => {
    // TODO: キャンセル待ちの解除処理
    setShowCancelModal(false);
  };

  return (
    <>
      <CustomerInfoHeader activeKey="" customerId={customerId} />
      <Tab.Container defaultActiveKey="applyStatus">
        <Nav className="mt-2 ms-2" variant="pills">
          <Nav.Item style={{ cursor: 'pointer' }} data-testid={testid('applyStatus')}>
            <Nav.Link eventKey="applyStatus">応募ステータス</Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ cursor: 'pointer' }} data-testid={testid('winLostHinstory')}>
            <Nav.Link eventKey="winLostHinstory">当選/落選履歴</Nav.Link>
          </Nav.Item>
          <Button
            variant="link"
            onClick={() => history.push(`${Url.TENSAKU.EXTEND_DEADLINE_REGISTER}/${applyId}`)}
            data-testid={testid('extendDeadline')}
          >
            期限延長・当選復帰
          </Button>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="applyStatus">
            <Modal
              body={<>この応募のキャンセル待ちを解除しますか？</>}
              footer={
                <>
                  <Button variant="secondary" onClick={onHideCancelModal}>
                    いいえ
                  </Button>
                  <Button variant="primary" onClick={onCancel}>
                    はい
                  </Button>
                </>
              }
              isModal={showCancelModal}
              onHide={onHideCancelModal}
            />
            <div>
              <p
                className="fw-bold p-3 bg-secondary mt-2"
                data-testid={testid('applyId')}
              >{`応募ID ${applyInfo?.applyId}`}</p>
              <p>{`クライアント ${applyInfo?.clientId} ${applyInfo?.clientName} ${applyInfo?.roiMemberName}`}</p>
              <p>
                有料サービス/リターン
                {applyInfo?.paidServiceReturnStatus.extendDeadlineFlg && (
                  <Badge className="mx-1" pill bg="secondary">
                    期限延長
                  </Badge>
                )}
                {applyInfo?.paidServiceReturnStatus.waitingFlg && (
                  <Badge className="mx-1" pill bg="secondary">
                    <>
                      <>キャンセル待ち</>
                      <button
                        className="text-light fw-bold"
                        type="button"
                        onClick={() => {
                          setShowCancelModal(true);
                        }}
                      >
                        ｜解除
                      </button>
                    </>
                  </Badge>
                )}
                {applyInfo?.paidServiceReturnStatus.returnFlg && (
                  <Badge className="mx-1" pill bg="secondary">
                    リターン
                  </Badge>
                )}
              </p>
              <div>
                {applyInfo?.monitorRejectAt && (
                  <div className="w-100">
                    <div className="fw-bold">モニター却下理由</div>
                    <div style={{ fontSize: '0.9rem' }}>{applyInfo?.monitorRejectReason}</div>
                    <p className="text-secondary">
                      却下日時
                      {applyInfo?.monitorRejectAt}
                    </p>
                  </div>
                )}
                {applyInfo?.monitorInvalidAt && (
                  <div className="w-100">
                    <div className="fw-bold">モニター無効理由</div>
                    <div style={{ fontSize: '0.9rem' }}>{applyInfo?.monitorInvalidReason}</div>
                    <p className="text-secondary">
                      無効日時
                      {applyInfo?.monitorInvalidAt}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-between gap-2 w-100">
              <div className="w-50" data-testid={testid('applyMonitorInfoCard')}>
                {applyInfo && <ApplyMonitorInfoCard applyInfo={applyInfo} />}
              </div>
              <div className="w-50" data-testid={testid('applyStatusCard')}>
                {applyInfo && applyStatusList && (
                  <ApplyStatusCard applyInfo={applyInfo} applyStatusList={applyStatusList} />
                )}
                {applyInfo && applyId && <ReceiptInfoCard applyId={applyId} applyInfo={applyInfo} />}
              </div>
              <div className="w-50" data-testid={testid('surveyProofCard')}>
                {applyInfo && applyId && <SurveyProofCard applyId={applyId} applyInfo={applyInfo} />}
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="winLostHinstory">
            {when(winLostHistoryList.length > 0, <WinLostHistoryTable list={winLostHistoryList} />)}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};
