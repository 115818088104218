import React from 'react';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { createTestId } from '../../../utils/functions';

export interface Props {
  isModal: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCancel: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOk: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide: () => any;
  title?: string;
}
export const DeleteModal: React.FC<Props> = ({ isModal, onCancel, onOk, onHide, title, children }) => {
  const testid = createTestId(DeleteModal);
  return (
    <Modal
      closeButton
      centered
      scrollable
      isModal={isModal}
      onHide={onHide}
      title={title ?? 'この会員のクライアントNGを解除してもよろしいですか？'}
      size="lg"
      body={
        <>
          {children}
          <Button data-testid={testid('cancel-button')} variant="link" onClick={onCancel}>
            キャンセル
          </Button>
          {'\u3000'}
          <Button data-testid={testid('ok-button')} variant="danger" onClick={onOk}>
            解除
          </Button>
        </>
      }
    />
  );
};

DeleteModal.defaultProps = {
  title: undefined,
};
