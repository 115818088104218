/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 代理店フィー明細一覧
 * @export
 * @interface BillingAgentFeeDetailOneRowDtoResponse
 */
export interface BillingAgentFeeDetailOneRowDtoResponse {
    /**
     * 代理店フィー一覧明細Id
     * @type {number}
     * @memberof BillingAgentFeeDetailOneRowDtoResponse
     */
    'agentFeeDetailId': number;
    /**
     * 合計
     * @type {number}
     * @memberof BillingAgentFeeDetailOneRowDtoResponse
     */
    'billingFeeAmount': number;
    /**
     * 項目
     * @type {string}
     * @memberof BillingAgentFeeDetailOneRowDtoResponse
     */
    'billingItemName': string;
    /**
     * 企業名
     * @type {string}
     * @memberof BillingAgentFeeDetailOneRowDtoResponse
     */
    'clientName': string;
    /**
     * 御社分
     * @type {number}
     * @memberof BillingAgentFeeDetailOneRowDtoResponse
     */
    'companyAmount': number;
    /**
     * 御社分タイプ(1:固定,2:割合)
     * @type {string}
     * @memberof BillingAgentFeeDetailOneRowDtoResponse
     */
    'companyAmountType': BillingAgentFeeDetailOneRowDtoResponseCompanyAmountTypeEnum;
    /**
     * 金額(税抜)
     * @type {number}
     * @memberof BillingAgentFeeDetailOneRowDtoResponse
     */
    'paymentFeeAmount': number;
    /**
     * 備考
     * @type {string}
     * @memberof BillingAgentFeeDetailOneRowDtoResponse
     */
    'remarks'?: string;
    /**
     * 税区分
     * @type {number}
     * @memberof BillingAgentFeeDetailOneRowDtoResponse
     */
    'taxRate': number;
}

export const BillingAgentFeeDetailOneRowDtoResponseCompanyAmountTypeEnum = {
    Fixed: 'FIXED',
    Ratio: 'RATIO'
} as const;

export type BillingAgentFeeDetailOneRowDtoResponseCompanyAmountTypeEnum = typeof BillingAgentFeeDetailOneRowDtoResponseCompanyAmountTypeEnum[keyof typeof BillingAgentFeeDetailOneRowDtoResponseCompanyAmountTypeEnum];


