import React, { useState, useCallback, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { PaginationComponent } from '../Pagination';
import { createTestId } from '../../../utils/functions';

export const PaginationTable: React.FC<{
  resultNum: number;
  list: unknown[] | undefined;
  headerNames: string[];
  children: (slicedList: unknown[]) => React.ReactNode;
  pageNationClassName?: string;
}> = ({ resultNum, headerNames, list, children, pageNationClassName }) => {
  const testId = createTestId(PaginationTable);
  const [slicedList, setSlicedList] = useState<unknown[]>();
  const resetSlicedList = useCallback(() => {
    setSlicedList((list || []).slice(0, resultNum));
  }, [resultNum, list]);
  useEffect(() => {
    if (!list) return;
    resetSlicedList();
  }, [list, resetSlicedList]);
  return (
    <>
      <PaginationComponent
        resultNum={resultNum}
        initialData={list}
        setSliceData={setSlicedList}
        pageNationClassName={pageNationClassName}
      />
      <Table data-testid={testId('table')}>
        <thead>
          <tr className="align-middle">
            {headerNames.map((label) => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>
        <tbody>{slicedList ? children(slicedList) : undefined}</tbody>
      </Table>
    </>
  );
};
