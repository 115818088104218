import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { BillingApi, BillingDestinationRelationHeaderOutputResponse } from '../../../api-client';
import { BillingDestShopRelationPage } from './BillingDestShopRelationPage';
import { BillingDestContractRelationPage } from './BillingDestContractRelationPage';
import { Title } from '../../atoms/Title';
import { BillingDestinationDetailPanel } from './BillingDestinationDetailPanel';
import useBillingSuggestIncAccount from '../../../hooks/useBillingSuggestIncAccount';
import useBillingSuggestClient from '../../../hooks/useBillingSuggestClient';

const TAB_TYPE = {
  DOWNLOAD: 'download',
  SETTING: 'setting',
  PRODUCT: 'product',
  STORE: 'store',
  PROJECT: 'project',
  DISPATCH: 'dispatch',
} as const;

export const BillingInvoiceDestRelationPage: React.VFC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const billingDestinationId = id ? Number(id) : 0;

  const [headerData, setHeaderData] = useState<BillingDestinationRelationHeaderOutputResponse>();
  const billingApi = new BillingApi();
  const billingSuggestClientResult = useBillingSuggestClient();
  const billingSuggestIncAccountResult = useBillingSuggestIncAccount();
  useEffect(() => {
    billingApi.billingDestinationRelationHeader(billingDestinationId).then((res) => {
      setHeaderData(res.data);
    });
  }, []);

  return (
    <>
      {headerData !== undefined && (
        <>
          <Title>請求送付先紐付け</Title>

          <BillingDestinationDetailPanel headerData={headerData} />

          <Tabs>
            <Tab eventKey={TAB_TYPE.PRODUCT} title="契約">
              <BillingDestContractRelationPage
                id={billingDestinationId}
                clientId={headerData?.clientId}
                billingSuggestClientResult={billingSuggestClientResult}
                billingSuggestIncAccountResult={billingSuggestIncAccountResult}
              />
            </Tab>
            <Tab eventKey={TAB_TYPE.STORE} title="店舗">
              <BillingDestShopRelationPage
                id={billingDestinationId}
                clientId={headerData?.clientId}
                billingSuggestClientResult={billingSuggestClientResult}
                billingSuggestIncAccountResult={billingSuggestIncAccountResult}
              />
            </Tab>
          </Tabs>
        </>
      )}
    </>
  );
};
