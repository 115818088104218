/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * EnqueteReceiptFileApi - axios parameter creator
 * @export
 */
export const EnqueteReceiptFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * アンケート回答画像ダウンロードAPIを実行する
         * @summary アンケート回答画像ダウンロードAPI
         * @param {number} clientId 
         * @param {string} monitorStartMonth 
         * @param {string} monitorEndMonth 
         * @param {boolean} compressionFlg 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {string} [deadlineType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteAnswerImageDL: async (clientId: number, monitorStartMonth: string, monitorEndMonth: string, compressionFlg: boolean, organizationId?: number, shopId?: number, deadlineType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('enqueteAnswerImageDL', 'clientId', clientId)
            // verify required parameter 'monitorStartMonth' is not null or undefined
            assertParamExists('enqueteAnswerImageDL', 'monitorStartMonth', monitorStartMonth)
            // verify required parameter 'monitorEndMonth' is not null or undefined
            assertParamExists('enqueteAnswerImageDL', 'monitorEndMonth', monitorEndMonth)
            // verify required parameter 'compressionFlg' is not null or undefined
            assertParamExists('enqueteAnswerImageDL', 'compressionFlg', compressionFlg)
            const localVarPath = `/api/enquete-answer-image-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['shopId'] = shopId;
            }

            if (deadlineType !== undefined) {
                localVarQueryParameter['deadlineType'] = deadlineType;
            }

            if (monitorStartMonth !== undefined) {
                localVarQueryParameter['monitorStartMonth'] = monitorStartMonth;
            }

            if (monitorEndMonth !== undefined) {
                localVarQueryParameter['monitorEndMonth'] = monitorEndMonth;
            }

            if (compressionFlg !== undefined) {
                localVarQueryParameter['compressionFlg'] = compressionFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 事後アンケートダウンロードAPIを実行する
         * @summary 事後アンケートダウンロードAPI
         * @param {number} clientId 
         * @param {string} ymFrom 
         * @param {string} ymTo 
         * @param {boolean} divideFlg 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {string} [deadlineType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnqueteDL: async (clientId: number, ymFrom: string, ymTo: string, divideFlg: boolean, organizationId?: number, shopId?: number, deadlineType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('postEnqueteDL', 'clientId', clientId)
            // verify required parameter 'ymFrom' is not null or undefined
            assertParamExists('postEnqueteDL', 'ymFrom', ymFrom)
            // verify required parameter 'ymTo' is not null or undefined
            assertParamExists('postEnqueteDL', 'ymTo', ymTo)
            // verify required parameter 'divideFlg' is not null or undefined
            assertParamExists('postEnqueteDL', 'divideFlg', divideFlg)
            const localVarPath = `/api/post-enquete-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['shopId'] = shopId;
            }

            if (deadlineType !== undefined) {
                localVarQueryParameter['deadlineType'] = deadlineType;
            }

            if (ymFrom !== undefined) {
                localVarQueryParameter['ymFrom'] = ymFrom;
            }

            if (ymTo !== undefined) {
                localVarQueryParameter['ymTo'] = ymTo;
            }

            if (divideFlg !== undefined) {
                localVarQueryParameter['divideFlg'] = divideFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 事前アンケートダウンロードAPIを実行する
         * @summary 事前アンケートダウンロードAPI
         * @param {number} clientId 
         * @param {string} monitorStartMonth 
         * @param {string} monitorEndMonth 
         * @param {boolean} divideFlg 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priorEnqueteDL: async (clientId: number, monitorStartMonth: string, monitorEndMonth: string, divideFlg: boolean, organizationId?: number, shopId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('priorEnqueteDL', 'clientId', clientId)
            // verify required parameter 'monitorStartMonth' is not null or undefined
            assertParamExists('priorEnqueteDL', 'monitorStartMonth', monitorStartMonth)
            // verify required parameter 'monitorEndMonth' is not null or undefined
            assertParamExists('priorEnqueteDL', 'monitorEndMonth', monitorEndMonth)
            // verify required parameter 'divideFlg' is not null or undefined
            assertParamExists('priorEnqueteDL', 'divideFlg', divideFlg)
            const localVarPath = `/api/prior-enquete-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['shopId'] = shopId;
            }

            if (monitorStartMonth !== undefined) {
                localVarQueryParameter['monitorStartMonth'] = monitorStartMonth;
            }

            if (monitorEndMonth !== undefined) {
                localVarQueryParameter['monitorEndMonth'] = monitorEndMonth;
            }

            if (divideFlg !== undefined) {
                localVarQueryParameter['divideFlg'] = divideFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レシートダウンロードAPIを実行する
         * @summary レシートダウンロードAPI
         * @param {number} clientId 
         * @param {string} monitorStartMonth 
         * @param {string} monitorEndMonth 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {string} [deadlineType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDL: async (clientId: number, monitorStartMonth: string, monitorEndMonth: string, organizationId?: number, shopId?: number, deadlineType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('receiptDL', 'clientId', clientId)
            // verify required parameter 'monitorStartMonth' is not null or undefined
            assertParamExists('receiptDL', 'monitorStartMonth', monitorStartMonth)
            // verify required parameter 'monitorEndMonth' is not null or undefined
            assertParamExists('receiptDL', 'monitorEndMonth', monitorEndMonth)
            const localVarPath = `/api/receipt-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['shopId'] = shopId;
            }

            if (deadlineType !== undefined) {
                localVarQueryParameter['deadlineType'] = deadlineType;
            }

            if (monitorStartMonth !== undefined) {
                localVarQueryParameter['monitorStartMonth'] = monitorStartMonth;
            }

            if (monitorEndMonth !== undefined) {
                localVarQueryParameter['monitorEndMonth'] = monitorEndMonth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnqueteReceiptFileApi - functional programming interface
 * @export
 */
export const EnqueteReceiptFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnqueteReceiptFileApiAxiosParamCreator(configuration)
    return {
        /**
         * アンケート回答画像ダウンロードAPIを実行する
         * @summary アンケート回答画像ダウンロードAPI
         * @param {number} clientId 
         * @param {string} monitorStartMonth 
         * @param {string} monitorEndMonth 
         * @param {boolean} compressionFlg 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {string} [deadlineType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteAnswerImageDL(clientId: number, monitorStartMonth: string, monitorEndMonth: string, compressionFlg: boolean, organizationId?: number, shopId?: number, deadlineType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteAnswerImageDL(clientId, monitorStartMonth, monitorEndMonth, compressionFlg, organizationId, shopId, deadlineType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 事後アンケートダウンロードAPIを実行する
         * @summary 事後アンケートダウンロードAPI
         * @param {number} clientId 
         * @param {string} ymFrom 
         * @param {string} ymTo 
         * @param {boolean} divideFlg 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {string} [deadlineType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postEnqueteDL(clientId: number, ymFrom: string, ymTo: string, divideFlg: boolean, organizationId?: number, shopId?: number, deadlineType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postEnqueteDL(clientId, ymFrom, ymTo, divideFlg, organizationId, shopId, deadlineType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 事前アンケートダウンロードAPIを実行する
         * @summary 事前アンケートダウンロードAPI
         * @param {number} clientId 
         * @param {string} monitorStartMonth 
         * @param {string} monitorEndMonth 
         * @param {boolean} divideFlg 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priorEnqueteDL(clientId: number, monitorStartMonth: string, monitorEndMonth: string, divideFlg: boolean, organizationId?: number, shopId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priorEnqueteDL(clientId, monitorStartMonth, monitorEndMonth, divideFlg, organizationId, shopId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レシートダウンロードAPIを実行する
         * @summary レシートダウンロードAPI
         * @param {number} clientId 
         * @param {string} monitorStartMonth 
         * @param {string} monitorEndMonth 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {string} [deadlineType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptDL(clientId: number, monitorStartMonth: string, monitorEndMonth: string, organizationId?: number, shopId?: number, deadlineType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptDL(clientId, monitorStartMonth, monitorEndMonth, organizationId, shopId, deadlineType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnqueteReceiptFileApi - factory interface
 * @export
 */
export const EnqueteReceiptFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnqueteReceiptFileApiFp(configuration)
    return {
        /**
         * アンケート回答画像ダウンロードAPIを実行する
         * @summary アンケート回答画像ダウンロードAPI
         * @param {number} clientId 
         * @param {string} monitorStartMonth 
         * @param {string} monitorEndMonth 
         * @param {boolean} compressionFlg 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {string} [deadlineType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteAnswerImageDL(clientId: number, monitorStartMonth: string, monitorEndMonth: string, compressionFlg: boolean, organizationId?: number, shopId?: number, deadlineType?: string, options?: any): AxiosPromise<string> {
            return localVarFp.enqueteAnswerImageDL(clientId, monitorStartMonth, monitorEndMonth, compressionFlg, organizationId, shopId, deadlineType, options).then((request) => request(axios, basePath));
        },
        /**
         * 事後アンケートダウンロードAPIを実行する
         * @summary 事後アンケートダウンロードAPI
         * @param {number} clientId 
         * @param {string} ymFrom 
         * @param {string} ymTo 
         * @param {boolean} divideFlg 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {string} [deadlineType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postEnqueteDL(clientId: number, ymFrom: string, ymTo: string, divideFlg: boolean, organizationId?: number, shopId?: number, deadlineType?: string, options?: any): AxiosPromise<string> {
            return localVarFp.postEnqueteDL(clientId, ymFrom, ymTo, divideFlg, organizationId, shopId, deadlineType, options).then((request) => request(axios, basePath));
        },
        /**
         * 事前アンケートダウンロードAPIを実行する
         * @summary 事前アンケートダウンロードAPI
         * @param {number} clientId 
         * @param {string} monitorStartMonth 
         * @param {string} monitorEndMonth 
         * @param {boolean} divideFlg 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priorEnqueteDL(clientId: number, monitorStartMonth: string, monitorEndMonth: string, divideFlg: boolean, organizationId?: number, shopId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.priorEnqueteDL(clientId, monitorStartMonth, monitorEndMonth, divideFlg, organizationId, shopId, options).then((request) => request(axios, basePath));
        },
        /**
         * レシートダウンロードAPIを実行する
         * @summary レシートダウンロードAPI
         * @param {number} clientId 
         * @param {string} monitorStartMonth 
         * @param {string} monitorEndMonth 
         * @param {number} [organizationId] 
         * @param {number} [shopId] 
         * @param {string} [deadlineType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptDL(clientId: number, monitorStartMonth: string, monitorEndMonth: string, organizationId?: number, shopId?: number, deadlineType?: string, options?: any): AxiosPromise<string> {
            return localVarFp.receiptDL(clientId, monitorStartMonth, monitorEndMonth, organizationId, shopId, deadlineType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnqueteReceiptFileApi - object-oriented interface
 * @export
 * @class EnqueteReceiptFileApi
 * @extends {BaseAPI}
 */
export class EnqueteReceiptFileApi extends BaseAPI {
    /**
     * アンケート回答画像ダウンロードAPIを実行する
     * @summary アンケート回答画像ダウンロードAPI
     * @param {number} clientId 
     * @param {string} monitorStartMonth 
     * @param {string} monitorEndMonth 
     * @param {boolean} compressionFlg 
     * @param {number} [organizationId] 
     * @param {number} [shopId] 
     * @param {string} [deadlineType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnqueteReceiptFileApi
     */
    public enqueteAnswerImageDL(clientId: number, monitorStartMonth: string, monitorEndMonth: string, compressionFlg: boolean, organizationId?: number, shopId?: number, deadlineType?: string, options?: AxiosRequestConfig) {
        return EnqueteReceiptFileApiFp(this.configuration).enqueteAnswerImageDL(clientId, monitorStartMonth, monitorEndMonth, compressionFlg, organizationId, shopId, deadlineType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 事後アンケートダウンロードAPIを実行する
     * @summary 事後アンケートダウンロードAPI
     * @param {number} clientId 
     * @param {string} ymFrom 
     * @param {string} ymTo 
     * @param {boolean} divideFlg 
     * @param {number} [organizationId] 
     * @param {number} [shopId] 
     * @param {string} [deadlineType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnqueteReceiptFileApi
     */
    public postEnqueteDL(clientId: number, ymFrom: string, ymTo: string, divideFlg: boolean, organizationId?: number, shopId?: number, deadlineType?: string, options?: AxiosRequestConfig) {
        return EnqueteReceiptFileApiFp(this.configuration).postEnqueteDL(clientId, ymFrom, ymTo, divideFlg, organizationId, shopId, deadlineType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 事前アンケートダウンロードAPIを実行する
     * @summary 事前アンケートダウンロードAPI
     * @param {number} clientId 
     * @param {string} monitorStartMonth 
     * @param {string} monitorEndMonth 
     * @param {boolean} divideFlg 
     * @param {number} [organizationId] 
     * @param {number} [shopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnqueteReceiptFileApi
     */
    public priorEnqueteDL(clientId: number, monitorStartMonth: string, monitorEndMonth: string, divideFlg: boolean, organizationId?: number, shopId?: number, options?: AxiosRequestConfig) {
        return EnqueteReceiptFileApiFp(this.configuration).priorEnqueteDL(clientId, monitorStartMonth, monitorEndMonth, divideFlg, organizationId, shopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * レシートダウンロードAPIを実行する
     * @summary レシートダウンロードAPI
     * @param {number} clientId 
     * @param {string} monitorStartMonth 
     * @param {string} monitorEndMonth 
     * @param {number} [organizationId] 
     * @param {number} [shopId] 
     * @param {string} [deadlineType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnqueteReceiptFileApi
     */
    public receiptDL(clientId: number, monitorStartMonth: string, monitorEndMonth: string, organizationId?: number, shopId?: number, deadlineType?: string, options?: AxiosRequestConfig) {
        return EnqueteReceiptFileApiFp(this.configuration).receiptDL(clientId, monitorStartMonth, monitorEndMonth, organizationId, shopId, deadlineType, options).then((request) => request(this.axios, this.basePath));
    }
}
