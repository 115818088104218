import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Row } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import type { ExtendDeadlineRegisterState, FormState } from '../../pages/ExtendDeadline/ExtendDeadlineRegisterPage';
import { TagsInputForm } from '../../molecules/TagsInputForm';

export type State = Pick<ExtendDeadlineRegisterState['forms'], 'applyIdList' | 'afterDeadline' | 'reason'>;

interface Props {
  setForms: DispatchSetState<State>;
  onConfirm: (body: FormState) => void;
}

export type TestIds = keyof State;

export const ExtendDeadlineRegisterForms: React.FC<Props> = React.memo(({ setForms, onConfirm }) => {
  // idがURLに含まれている場合は初期値を入れる
  const { id } = useParams<{ id: string | undefined }>();

  const isApplyIdAlreadySet = typeof id === 'string' && !Number.isNaN(Number(id));

  const applyIdList = isApplyIdAlreadySet ? [id] : [];
  // sv-SEロケールはYYYY-MM-DD形式の日付文字列を戻す
  const afterDeadline = isApplyIdAlreadySet ? new Date().toLocaleDateString('sv-SE') : '';
  const reason = isApplyIdAlreadySet ? '今日の日付を自動挿入しているため、確認' : '';

  const testId = createTestId<TestIds>(ExtendDeadlineRegisterForms);
  const {
    state: $formState,
    useBindSet,
    onChangeSet: onChangeInput,
  } = useLargeState<State>({
    applyIdList,
    afterDeadline,
    reason,
  });
  const setApplyIdList = useBindSet('applyIdList');

  useEffect(() => {
    setForms($formState);
    if (isApplyIdAlreadySet) {
      onConfirm($formState);
    }
  }, [setForms, $formState, isApplyIdAlreadySet, onConfirm]);

  return (
    <>
      <Row>
        <Form.Group data-testid={testId('applyIdList')} controlId="applyIdList" key="applyIdList">
          <Form.Label>
            応募ID※複数応募を登録する場合は、Excelやスプレッドシートなどで応募IDを縦にコピーして入力欄に貼り付けてください。
          </Form.Label>
          <TagsInputForm
            type="number"
            tags={$formState.applyIdList.map((applyId) => String(applyId)) || []}
            placeholder="応募IDを入力後enterを押下してください"
            setInput={setApplyIdList}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group
          data-testid={testId('afterDeadline')}
          controlId="afterDeadline"
          key="afterDeadline"
          className="col-md-6"
        >
          <Form.Label>延長後の期限</Form.Label>
          <Form.Control
            type="date"
            autoComplete="off"
            value={$formState.afterDeadline || ''}
            onChange={onChangeInput('afterDeadline', String)}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group data-testid={testId('reason')} controlId="reason" key="reason">
          <Form.Label>理由</Form.Label>
          <Form.Control
            type="text"
            autoComplete="off"
            value={$formState.reason || ''}
            onChange={onChangeInput('reason', String)}
            onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
          />
        </Form.Group>
      </Row>
    </>
  );
});
