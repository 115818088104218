import { BillingInvoiceDetailApplicationSearchForPdfOutputResponse } from '../../api-client';
import { DispatchDetailTable } from './Table/DispatchDetailTable';
import { PdfLayout } from '../templates/PdfLayout';
import { BILLING_PDF_SETTINGS } from '../../BillingConstants';

export const DispatchedDetailInvoice = ({
  lists,
  totals,
}: {
  lists: BillingInvoiceDetailApplicationSearchForPdfOutputResponse;
  totals: { amount: number; rewardFee: number; fee: number };
}): JSX.Element => (
  <PdfLayout
    headers={lists.billingInvoiceHeaderInfoForPdfOutput}
    billingType="派遣別明細書"
    paddingHorizontal={BILLING_PDF_SETTINGS.APPLICATION_DETAIL_PADDINGHORIZONTAL}
  >
    <DispatchDetailTable lists={lists} totals={totals} />
  </PdfLayout>
);
