import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Table } from 'react-bootstrap';
import { uuid } from '../../../utils/functions';
import type { CorrectPhoneMasterState } from '../../pages/Correction/CorrectPhoneMasterPage';
import type { ChainStoreMasterListDisplayOutputResponse } from '../../../api-client';

interface Props {
  list: CorrectPhoneMasterState['list'];
  onClickEdit: (info: ChainStoreMasterListDisplayOutputResponse) => void;
  onClickDelete: (id: number, i: number) => void;
}
export type TestIds = 'table' | 'edit-button';

export const CorrectPhoneMasterTable: React.FC<Props> = ({ list, onClickEdit, onClickDelete }) => {
  const tableData: {
    label: string;
    name: keyof CorrectPhoneMasterState['list'][0];
  }[] = useMemo(() => {
    return [
      { label: 'チェーン', name: 'chainName' },
      { label: 'ストア名', name: 'storeName' },
      { label: '電話番号', name: 'storePhoneNumber' },
      { label: '都道府県', name: 'prefectureName' },
    ];
  }, []);

  return (
    <Table>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label), ''].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item, i) => (
          <tr className="align-middle" key={uuid()}>
            {tableData.map(({ name }) => (
              <td key={uuid()}>{item[name]}</td>
            ))}
            <td key={uuid()}>
              <Button variant="link" onClick={() => onClickEdit(item)}>
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button variant="link" onClick={() => onClickDelete(item.chainStoreMasterId, i)}>
                <FontAwesomeIcon icon={faTimesCircle} />
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
