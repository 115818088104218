import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import {
  BillingApi,
  BillingDestinationDetailOutputResponse,
  BillingDestinationRelationHeaderOutputResponse,
} from '../../../api-client';

interface Props {
  headerData: BillingDestinationRelationHeaderOutputResponse;
}
// TODO ページじゃないので移動
export const BillingDestinationDetailPanel: React.VFC<Props> = ({ headerData }) => {
  const [detail, setDetail] = useState<BillingDestinationDetailOutputResponse>(
    {} as BillingDestinationDetailOutputResponse
  );
  const billingApi = new BillingApi();

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h3>紐付け先</h3>
              <Row className="g-2 mb-4">
                <Col>
                  <Form.Group controlId="clientId">
                    <Row>
                      <Col className="col-4">
                        <Form.Label className="pt-2">クライアントID</Form.Label>
                      </Col>
                      <Col className="col-8">
                        <Form.Control readOnly type="text" name="clientId" defaultValue={headerData?.clientId} />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="clientName">
                    <Row>
                      <Col className="col-4">
                        <Form.Label className="pt-2">クライアント名</Form.Label>
                      </Col>
                      <Col className="col-8">
                        <Form.Control readOnly type="text" name="clientName" defaultValue={headerData?.clientName} />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="g-2 mb-4">
                <Col>
                  <Form.Group controlId="billingDestinationBranchNumber">
                    <Row>
                      <Col className="col-4">
                        <Form.Label className="pt-2">請求送付先枝番</Form.Label>
                      </Col>
                      <Col className="col-8">
                        <Form.Control
                          readOnly
                          type="text"
                          name="billingDestinationBranchNumber"
                          defaultValue={headerData?.billingDestinationBranchNumber}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="billingDestinationName">
                    <Row>
                      <Col className="col-4">
                        <Form.Label className="pt-2">請求送付先案件名</Form.Label>
                      </Col>
                      <Col className="col-8">
                        <Form.Control
                          readOnly
                          type="text"
                          name="billingDestinationName"
                          defaultValue={headerData?.billingDestinationName}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
