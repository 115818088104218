import React from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faTimesCircle, faPlus } from '@fortawesome/free-solid-svg-icons';

export const RegisterTable = () => {
  return (
    <>
      <Row>
        <Col>
          <Card className="mb-4">
            <Card.Body>
              <h4>Table</h4>
              <Form>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="ms-2">
                    キャンセル
                  </Button>
                  <Button variant="outline-secondary" className="ms-2">
                    プレビュー
                  </Button>
                  <Button variant="primary" className="ms-2">
                    保存
                  </Button>
                </div>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="text-secondary">
                    <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                    追加
                  </Button>
                </div>
                <Table>
                  <thead>
                    <tr>
                      <th> </th>
                      <th>項目A</th>
                      <th>項目B</th>
                      <th>項目C</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle">
                        <button type="button" className="text-secondary">
                          <FontAwesomeIcon icon={faGripLines} fixedWidth />
                        </button>
                      </td>
                      <td className="align-middle col-4">
                        <Form.Group controlId="tableFormId1">
                          <Form.Control type="text" placeholder="プレースホルダー" />
                        </Form.Group>
                      </td>
                      <td className="align-middle col-4">
                        <Form.Group controlId="tableFormId2">
                          <Form.Control type="text" placeholder="プレースホルダー" />
                        </Form.Group>
                      </td>
                      <td className="align-middle col-4">
                        <Form.Select>
                          <option>Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </td>
                      <td className="align-middle">
                        <button type="button" className="ms-2 text-secondary">
                          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle">
                        <button type="button" className="text-secondary">
                          <FontAwesomeIcon icon={faGripLines} fixedWidth />
                        </button>
                      </td>
                      <td className="align-middle col-4">
                        <Form.Group controlId="tableFormId1">
                          <Form.Control type="text" placeholder="プレースホルダー" />
                        </Form.Group>
                      </td>
                      <td className="align-middle col-4">
                        <Form.Group controlId="tableFormId2">
                          <Form.Control type="text" placeholder="プレースホルダー" />
                        </Form.Group>
                      </td>
                      <td className="align-middle col-4">
                        <Form.Select disabled>
                          <option>Open this select menu</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </td>
                      <td className="align-middle">
                        <button type="button" className="ms-2 text-secondary">
                          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
