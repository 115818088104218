import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import type { NestedPartial } from '../../../interfaces/utils';
import type { MatterHandOverSalesInfoOutputResponse } from '../../../api-client';
// eslint-disable-next-line import/no-cycle
import { MatterHandoverSalesListApi, PostingRequestRsvDetailInfoApi } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { Title } from '../../atoms/Title';
import { TITLE } from '../../../constants/Title';

export interface State {
  getApi: PostingRequestRsvDetailInfoApi;
  salesListApi: MatterHandoverSalesListApi;
  matterHandoverSalesList: Partial<MatterHandOverSalesInfoOutputResponse>[];
}

const TYPE = {
  CATEGORY: 0,
  START_DATE: 1,
  SHOP_URL: 2,
  PRE_ENQUETE: 3,
  POST_ENQUETE: 4,
  OEM_SETTING: 5,
  IMAGE_POST: 6,
  IMAGE_POST_OBJECT: 7,
  VIST_WEEKDAY: 8,
  VIST_TIME: 9,
  VIST_VERIFICATION: 10,
  PAYMENT_METHOD: 11,
  MANAGEMENT_GROUPING: 12,
  MANAGEMENT_ACCOUNT: 13,
  OTHER_MATTER: 14,
  INCREASE_BORDER: 15,
  SEARCH_FLG: 16,
  COUNTDOWN: 17,
  OPEN_DATE: 18,
  ORIGIN_SHOP: 19,
  CHANGE_FROM_ORIGIN: 20,
  ADD_ACCOUNT_INFO: 21,
  COMPLETE_DATE: 22,
  FIX_DETAIL: 23,
  DRINK_COMMENT: 24,
  BEAUTY_COMMENT: 25,
  MAIL_COMMENT: 26,
  SHOP_COMMENT: 27,
  STATUS_CHANGE: 100,
  STATUS_CHANGE_DATE: 101,
  HIDDEN_REASON: 102,
};

const STATUS_CHANGE = {
  displayStatus: '表示ステータス',
  startAt: '開始日時',
  reasonForHiding: '非表示理由',
} as const;

export const CommentPage: React.VFC = () => {
  const { state: $, mergeState } = useLargeState<State>({
    getApi: new PostingRequestRsvDetailInfoApi(),
    salesListApi: new MatterHandoverSalesListApi(),
    matterHandoverSalesList: [],
  });
  const { state: s, mergeState: sMergeState } = useLargeState<{
    displayStatus?: string;
    startAt?: string;
    reasonForHiding?: string;
  }>({});

  const { postingRequestId } = useParams<{
    postingRequestId: string | undefined;
  }>();

  // この画面で編集は実装しないので false 固定。
  const [editFlg] = useState<boolean>(false);

  // const [oemFlg, setOemFlg] = useState<boolean>(true);
  // const [increaseBorderFlg, setIncreaseBorderFlg] = useState<boolean>();
  // const [searchFlg, setSearchFlg] = useState<boolean>();

  useEffect(() => {
    if (postingRequestId) {
      $.salesListApi
        .matterHandoverSalesList(Number(postingRequestId))
        .then((res: AxiosResponse<MatterHandOverSalesInfoOutputResponse[]>) => {
          mergeState({
            matterHandoverSalesList: res.data,
          });
        });
    }
  }, [$.salesListApi, mergeState, postingRequestId]);

  const existType = (type: number) => {
    return $.matterHandoverSalesList.some(
      (v) => v.type === type && v.content != null && v.content !== 'false' && v.content !== ''
    );
  };

  const getContent = (type: number) => {
    return $.matterHandoverSalesList.find((v) => v.type === type)?.content || '';
  };

  const getBooleanContent = (type: number) => {
    const obj = $.matterHandoverSalesList.find((v) => v.type === type);
    if (obj) {
      if (obj.content === 'true') return true;
    }
    return false;
  };

  useEffect(() => {
    const statusChange = $.matterHandoverSalesList.find((m) => m.type === TYPE.STATUS_CHANGE)?.content;
    if (statusChange) {
      const sliceContent = (key: typeof STATUS_CHANGE[keyof typeof STATUS_CHANGE]): string | undefined => {
        return statusChange.match(new RegExp(`${key}：(.*?)(,|$)`))
          ? (statusChange.match(new RegExp(`${key}：(.*?)(,|$)`)) as string[])[1]
          : undefined;
      };

      sMergeState({
        displayStatus: sliceContent(STATUS_CHANGE.displayStatus),
        startAt: sliceContent(STATUS_CHANGE.startAt),
        reasonForHiding: sliceContent(STATUS_CHANGE.reasonForHiding),
      });
    }
    const initialData = [];
    if (!$.matterHandoverSalesList.find((data) => data.type === TYPE.VIST_WEEKDAY)) {
      initialData.push({ type: TYPE.VIST_WEEKDAY, content: '全曜日OK', files: [] });
    }
    if (!$.matterHandoverSalesList.find((data) => data.type === TYPE.VIST_TIME)) {
      initialData.push({ type: TYPE.VIST_TIME, content: '営業時間内', files: [] });
    }
    if (!$.matterHandoverSalesList.find((data) => data.type === TYPE.MANAGEMENT_GROUPING)) {
      initialData.push({ type: TYPE.MANAGEMENT_GROUPING, content: 'なし', files: [] });
    }
    if (!$.matterHandoverSalesList.find((data) => data.type === TYPE.STATUS_CHANGE)) {
      initialData.push({ type: 100, content: '', files: [] });
    }
  }, [$.matterHandoverSalesList, mergeState, sMergeState]);

  const categoryOptions: [string, string][] = Object.entries({
    本導入: '本導入',
    完全無料トライアル: '完全無料トライアル',
    有料トライアル: '有料トライアル',
    キャンペーン: 'キャンペーン',
  });
  const imagePostOptions: [string, string][] = Object.entries({
    画像投稿〇: '画像投稿〇',
    '画像投稿×': '画像投稿×',
    コピー元と同様: 'コピー元と同様',
  });
  const managementOptions: [string, string][] = Object.entries({
    あり: 'あり',
    なし: 'なし',
  });
  const oemOptions: [string, string][] = Object.entries({
    '有（マーケに任せる）': '有（マーケに任せる）',
    '無（絶対開放不可）': '無（絶対開放不可）',
    コピー元と同様: 'コピー元と同様',
  });

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.COMMENT}</Title>
      <Form.Group className="mb-4" hidden={!existType(TYPE.CATEGORY)}>
        <Form.Label>分類</Form.Label>
        <Form.Select value={getContent(TYPE.CATEGORY)} disabled={!editFlg}>
          <option value="">&nbsp;</option>
          {categoryOptions.map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4" hidden={!existType(TYPE.START_DATE)}>
        <Form.Label>掲載開始希望日</Form.Label>
        <Form.Control type="date" value={getContent(TYPE.START_DATE)} disabled={!editFlg} />
      </Form.Group>

      <Form.Group className="mb-4" hidden={!existType(TYPE.SHOP_URL)}>
        <Form.Label>申込店舗URL</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.SHOP_URL)} disabled={!editFlg} />
      </Form.Group>

      <Form.Group className="mb-4" hidden={!existType(TYPE.PRE_ENQUETE)}>
        <Form.Label>事前アンケート</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.PRE_ENQUETE)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.POST_ENQUETE)}>
        <Form.Label>事後アンケート</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.POST_ENQUETE)} disabled={!editFlg} />
      </Form.Group>

      <Form.Group className="mb-4" hidden={!existType(TYPE.OEM_SETTING)}>
        <Form.Label>OEM設定</Form.Label>
        <Form.Select value={getContent(TYPE.OEM_SETTING)} disabled={!editFlg}>
          <option value="">&nbsp;</option>
          {oemOptions.map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.IMAGE_POST)}>
        <Form.Label>画像投稿</Form.Label>
        <Form.Select value={getContent(TYPE.IMAGE_POST)} disabled={!editFlg}>
          <option value="">&nbsp;</option>
          {imagePostOptions.map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-4" hidden={!existType(TYPE.IMAGE_POST_OBJECT)}>
        <Form.Label>画像投稿の目的</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={4}
          value={getContent(TYPE.IMAGE_POST_OBJECT)}
          disabled={!editFlg}
        />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.VIST_WEEKDAY)}>
        <Form.Label>来店曜日</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.VIST_WEEKDAY)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.VIST_TIME)}>
        <Form.Label>来店時間</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.VIST_TIME)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.VIST_VERIFICATION)}>
        <Form.Label>来店証明</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={4}
          value={getContent(TYPE.VIST_VERIFICATION)}
          disabled={!editFlg}
        />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.PAYMENT_METHOD)}>
        <Form.Label>支払方法</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.PAYMENT_METHOD)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.MANAGEMENT_GROUPING)}>
        <Form.Label>管理画面グルーピング</Form.Label>
        <Form.Select value={getContent(TYPE.MANAGEMENT_GROUPING)} disabled={!editFlg}>
          {managementOptions.map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.MANAGEMENT_ACCOUNT)}>
        <Form.Label>管理画面アカウント(クラウド)</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={4}
          value={getContent(TYPE.MANAGEMENT_ACCOUNT)}
          disabled={!editFlg}
        />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.DRINK_COMMENT)}>
        <Form.Label>飲食申し送り事項</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.DRINK_COMMENT)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.BEAUTY_COMMENT)}>
        <Form.Label>美容申し送り事項</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.BEAUTY_COMMENT)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.OTHER_MATTER)}>
        <Form.Label>その他伝達事項</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.OTHER_MATTER)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.INCREASE_BORDER)}>
        <div className="d-flex justify-content-start">
          <Form.Label>月中増枠</Form.Label>
          <Form.Check
            type="radio"
            className="ms-5"
            name="INCREASE_BORDER"
            checked={getBooleanContent(TYPE.INCREASE_BORDER)}
            disabled={!editFlg}
          />
        </div>
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.SEARCH_FLG)}>
        <div className="d-flex justify-content-start">
          <Form.Label>Google/Yahoo検索表示フラグ</Form.Label>
          <Form.Check
            type="radio"
            className="ms-5"
            name="SEARCH_FLG"
            checked={getBooleanContent(TYPE.SEARCH_FLG)}
            disabled={!editFlg}
          />
        </div>
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.COUNTDOWN)}>
        <Form.Label>非表示タイマー・カウントダウン</Form.Label>
        <Form.Control type="date" value={getContent(TYPE.COUNTDOWN)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.MAIL_COMMENT)}>
        <Form.Label>通販申し送り事項</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.MAIL_COMMENT)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.SHOP_COMMENT)}>
        <Form.Label>店頭申し送り事項</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.SHOP_COMMENT)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.OPEN_DATE)}>
        <Form.Label>店舗オープン日</Form.Label>
        <Form.Control type="date" value={getContent(TYPE.OPEN_DATE)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.ORIGIN_SHOP)}>
        <Form.Label>コピーする既存店舗情報</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.ORIGIN_SHOP)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.CHANGE_FROM_ORIGIN)}>
        <Form.Label>既存店舗からの変更点</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={4}
          value={getContent(TYPE.CHANGE_FROM_ORIGIN)}
          disabled={!editFlg}
        />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.ADD_ACCOUNT_INFO)}>
        <Form.Label>追加アカウント情報①</Form.Label>
        <Form.Control
          type="text"
          as="textarea"
          rows={4}
          value={getContent(TYPE.ADD_ACCOUNT_INFO)}
          disabled={!editFlg}
        />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.COMPLETE_DATE)}>
        <Form.Label>完了希望納期</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.COMPLETE_DATE)} disabled={!editFlg} />
      </Form.Group>
      <Form.Group className="mb-4" hidden={!existType(TYPE.FIX_DETAIL)}>
        <Form.Label>修正/作業内容</Form.Label>
        <Form.Control type="text" as="textarea" rows={4} value={getContent(TYPE.FIX_DETAIL)} disabled={!editFlg} />
      </Form.Group>

      <Form.Group className="mb-4" hidden={!existType(TYPE.STATUS_CHANGE)}>
        <Form.Label>表示ステータス</Form.Label>
        <Form.Control type="text" value={s.displayStatus || ''} disabled={!editFlg} />
      </Form.Group>

      <Form.Group className="mb-4" hidden={!existType(TYPE.STATUS_CHANGE)}>
        <Form.Label>開始日時</Form.Label>
        <Form.Control type="datetime-local" value={s.startAt || ''} disabled={!editFlg} />
      </Form.Group>

      <Form.Group className="mb-4" hidden={!existType(TYPE.STATUS_CHANGE)}>
        <Form.Label>非表示理由</Form.Label>
        <Form.Control type="text" value={s.reasonForHiding || ''} disabled={!editFlg} />
      </Form.Group>
    </>
  );
};
