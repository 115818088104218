import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { CustomerInfoApi } from '../../../api-client';
import type { AgentLoginDisplayOutputResponse, IncResultOutputResponse } from '../../../api-client';
import { createTestId } from '../../../utils/functions';

export interface CustomerListState {
  api: CustomerInfoApi;
  isLoading: boolean;
  data: AgentLoginDisplayOutputResponse;
  errorMessage: string[];
}

export const AgentLoginPage: React.FC = () => {
  const testid = createTestId<keyof AgentLoginDisplayOutputResponse>(AgentLoginPage);
  const { id } = useParams<{ id: string | undefined }>();
  const { state: $, mergeState } = useLargeState<CustomerListState>({
    api: new CustomerInfoApi(),
    isLoading: false,
    data: { url: '', customerId: 0 },
    errorMessage: [],
  });

  // 検索
  useEffect(() => {
    mergeState({ isLoading: true });
    if (id) {
      $.api
        .agentLoginDisplay(Number(id))
        .then((res: AxiosResponse<AgentLoginDisplayOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            mergeState({ data: res.data, errorMessage: [], isLoading: false });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
    }
  }, [$.api, id, mergeState]);

  return (
    <>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.data.customerId !== 0 ? (
          <>
            <p className="d-flex justify-content-start mt-2">
              <span className="me-2" data-testid={testid('customerId')}>
                {$.data.customerId}
              </span>
              <span className="me-2" data-testid={testid('customerName')}>
                {$.data.customerName}
              </span>
              <span data-testid={testid('oemName')}>{$.data.oemName}</span>
            </p>
            <Card className="p-2">
              <strong className="ms-2">成り代わりログイン状態でのリンク先一覧</strong>
              <div>
                {/* FIXME */}
                {/* {$.data.oemId === 1 ? ( */}
                {$.data.oemName !== 'ファンくる' ? (
                  // TODO リンク先設定
                  <Link to="/" className="ms-2">
                    TOP
                  </Link>
                ) : undefined}
                <Link to="/" className="ms-2">
                  検索
                </Link>
                <Link to="/" className="ms-2">
                  マイページ
                </Link>
              </div>
            </Card>
          </>
        ) : undefined}
      </LoadingSpinner>
    </>
  );
};
