import React, { useCallback, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import type { WinLostHistoryOutputResponse } from '../../../api-client';
import { createTestId, formatISODate, uuid } from '../../../utils/functions';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';

interface Props {
  list: WinLostHistoryOutputResponse[];
}

export type TestIds = 'table' | 'occurrenceAt';

export const WinLostHistoryTable: React.FC<Props> = React.memo(({ list }) => {
  const testId = createTestId<TestIds>(WinLostHistoryTable);

  const tableData: {
    label: string;
    name: keyof WinLostHistoryOutputResponse;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }[] = useMemo(() => {
    return [
      { label: '発生日時', name: 'occurrenceAt' },
      { label: '応募進行ステータス', name: 'progressStatus' },
      { label: '理由', name: 'lostReason' },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (item: WinLostHistoryOutputResponse) => {
      const returnElement: JSX.Element[] = [];
      tableData.forEach(({ name }) => {
        if (name === 'occurrenceAt') {
          returnElement.push(
            <td key={uuid()}>{item.occurrenceAt ? formatISODate(item.occurrenceAt, DATETIME_DISPLAY_FORMAT) : ''}</td>
          );
        }
        if (name === 'progressStatus') {
          returnElement.push(<td key={uuid()}>{item.progressStatus}</td>);
        }
        if (name === 'lostReason') {
          if (!item.lostReason) {
            returnElement.push(<td key={uuid()}>-</td>);
          } else {
            returnElement.push(<td key={uuid()}>{item.lostReason}</td>);
          }
        }
      });
      return returnElement;
    },
    [tableData]
  );

  return (
    <Table data-testid={testId('table')}>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label)].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <tr className="align-middle" key={uuid()}>
            {createDisplayElement(item)}
          </tr>
        ))}
      </tbody>
    </Table>
  );
});
