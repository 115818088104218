import { faCopy, faEye, faEyeSlash, faLink, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Button, Form, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { QuestionListOutputResponse } from '../../../api-client';

export interface Props {
  selectionQuestionId: number | undefined;
  setSelectionQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
  listPerPage: QuestionListOutputResponse[];
  pageType: string | undefined;
  topTabKey: string;
  subTabkey: string;
}

export const MonitorRuleQuestionListModalTable: React.VFC<Props> = ({
  selectionQuestionId,
  setSelectionQuestionId,
  listPerPage,
  pageType,
  topTabKey,
  subTabkey,
}) => {
  // 【積上式設問】tableContents
  const tableContents1 = (
    <>
      <thead>
        <tr>
          <th> </th>
          <th style={{ textAlign: 'center' }}>設問ID</th>
          <th>設問/回答方法</th>
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((row: QuestionListOutputResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'right' }}>
                <Form.Check.Input
                  data-testid="questionListCheckBox"
                  className="flex-shrink-0 me-2"
                  type="radio"
                  name="radioGroup1"
                  onChange={() => {
                    setSelectionQuestionId(row.questionId);
                  }}
                  defaultChecked={selectionQuestionId === row.questionId}
                />
              </td>
              <td style={{ textAlign: 'center' }}>{row?.questionId}</td>
              <td className="text-secondary">
                {row?.questionContent}
                <br />
                {row?.questionAnswerMethodName}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  // 【事後 選択式グローバル設問】tableContents
  const tableContents2 = (
    <>
      <thead>
        <tr>
          <th> </th>
          <th style={{ textAlign: 'center' }}>設問ID</th>
          <th style={{ textAlign: 'center' }}>カテゴリ</th>
          <th>設問/回答方法</th>
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((row: QuestionListOutputResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'right' }}>
                <Form.Check.Input
                  data-testid="questionListCheckBox"
                  className="flex-shrink-0 me-2"
                  type="radio"
                  name="radioGroup2"
                  onClick={() => {
                    setSelectionQuestionId(row.questionId);
                  }}
                  onChange={() => {}}
                  defaultChecked={selectionQuestionId === row.questionId}
                />
              </td>
              <td style={{ textAlign: 'center' }}>{row?.questionId}</td>
              <td style={{ textAlign: 'center' }}>{row?.categoryName}</td>
              <td className="text-secondary">
                {row?.questionContent}
                <br />
                {row?.questionAnswerMethodName}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  // 【事後 選択式企業設問】tableContents
  const tableContents3 = (
    <>
      <thead>
        <tr>
          <th> </th>
          <th style={{ textAlign: 'center' }}>設問ID</th>
          <th style={{ textAlign: 'center' }}>カテゴリ</th>
          <th>設問/回答方法</th>
          <th style={{ textAlign: 'center' }}>
            管理
            <br />
            クライアント
          </th>
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((row: QuestionListOutputResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'right' }}>
                <Form.Check.Input
                  data-testid="questionListCheckBox"
                  className="flex-shrink-0 me-2"
                  type="radio"
                  name="radioGroup3"
                  onChange={() => {
                    setSelectionQuestionId(row.questionId);
                  }}
                  defaultChecked={selectionQuestionId === row.questionId}
                />
              </td>
              <td style={{ textAlign: 'center' }}>{row?.questionId}</td>
              <td style={{ textAlign: 'center' }}>{row?.categoryName}</td>
              <td className="text-secondary">
                {row?.questionContent}
                <br />
                {row?.questionAnswerMethodName}
              </td>
              <td width="15%" style={{ textAlign: 'center' }}>
                {row?.clientName}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  return (
    <>
      <Table id="targetTable">
        {pageType === undefined && topTabKey === '2' && subTabkey === '1' && tableContents2}
        {pageType === undefined && topTabKey === '2' && subTabkey === '2' && tableContents3}
        {pageType === undefined && topTabKey === '3' && tableContents1}
      </Table>
    </>
  );
};
