import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { BillingApi } from '../../api-client';
import { BILLING_FLOW_STATUS, BILLING_STATUS } from '../../BillingConstants';

interface BillingContextInterface {
  billingId: number;
  billingFlowStatus: number;
  isSalesDptEditable: boolean;
  isSalesDptMgrEditable: boolean;
  isAccDptMgrEditable: boolean;
  handleCheckCanDrop: (applicantId: number | undefined, currentUseId: number | undefined) => boolean;
  handleCheckCanForceDrop: (currentUseId: number | undefined) => boolean;
  getBillingStatusLabelName: (billingStatus: number) => string;
  handleUpdateBillingFlowStatus: () => void;
  handleSetBillingFlowStatus: (newBillingFlowStatus: number) => void;
}

export interface Props {
  billingId: number;
  children: ReactNode;
}

const BillingContext = createContext<BillingContextInterface | null>(null);

const BillingProvider: React.VFC<Props> = ({ billingId, children }) => {
  const [billingFlowStatus, setBillingFlowStatus] = useState<number>(0);
  const billingApi = new BillingApi();
  useEffect(() => {
    billingApi.billingInvoiceFlowStatus(billingId).then((res) => {
      setBillingFlowStatus(res.data.billingFlowStatusCode);
    });
  }, []);

  // 営業が修正可能かどうかのフラグ
  const isSalesDptEditable: boolean = billingFlowStatus <= BILLING_FLOW_STATUS.SALES_DPT_UNCOMMITTED;
  // 営業上長が編集可能かどうかのフラグ
  const isSalesDptMgrEditable = billingFlowStatus === BILLING_FLOW_STATUS.SALES_DPT_MANAGER_UNCOMMITTED;
  // 管理部が編集可能かどうかのフラグ
  const isAccDptMgrEditable = billingFlowStatus === BILLING_FLOW_STATUS.MANAGE_DPT_UNCOMMITTED;

  const handleCheckCanDrop = (applicantId: number | undefined, currentUseId: number | undefined) => {
    // ログインユーザーと請求書の営業確認した人間が同一かどうか
    // TODO システム承認した場合の処理
    const isSameUser = applicantId !== undefined && applicantId === currentUseId;
    // 上長確認待ちまたは管理部確認待ちで画面開いてるユーザーが申請を行った営業だった場合、自身で差し戻しができる
    return (isSalesDptMgrEditable || isAccDptMgrEditable) && isSameUser;
  };

  /**
   *
   * @param currentUseId
   */
  const handleCheckCanForceDrop = (currentUseId: number | undefined) => {
    // ログインユーザーと請求書の営業確認した人間が同一かどうか
    // TODO アカウントの制御(管理部なら実施できるように修正する)
    // 一旦管理部確認が終わっていれば出るようにする
    return billingFlowStatus > BILLING_FLOW_STATUS.MANAGE_DPT_UNCOMMITTED;
  };

  const getBillingStatusLabelName = (billingStatus: number): string => {
    if (billingStatus === BILLING_STATUS.BILLING) {
      return '請求';
    }
    if (billingStatus === BILLING_STATUS.UN_BILLING) {
      return '非請求';
    }
    if (billingStatus === BILLING_STATUS.CARRY_OVER) {
      return '翌月繰越';
    }
    return '';
  };

  const handleSetBillingFlowStatus = (newBillingFlowStatus: number) => {
    setBillingFlowStatus(newBillingFlowStatus);
  };
  const handleUpdateBillingFlowStatus = () => {
    billingApi.billingInvoiceFlowStatus(billingId).then((res) => {
      setBillingFlowStatus(res.data.billingFlowStatusCode);
    });
  };

  const value: BillingContextInterface = {
    billingId,
    billingFlowStatus,
    isSalesDptEditable,
    isSalesDptMgrEditable,
    isAccDptMgrEditable,
    handleCheckCanDrop,
    handleCheckCanForceDrop,
    getBillingStatusLabelName,
    handleSetBillingFlowStatus,
    handleUpdateBillingFlowStatus,
  };

  return <BillingContext.Provider value={value}>{children}</BillingContext.Provider>;
};

export { BillingContext, BillingProvider };
