import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import scss from '../../scss/atoms/tag.module.scss';

export interface Props {
  label: string;
  onDelete?: () => void;
  pill?: boolean;
  minWidth?: string;
}

export const Tag: React.VFC<Props> = ({ label, onDelete, pill = true, minWidth }) => {
  return (
    <div className={`d-inline-flex m-0 ${scss.tag} ${pill && 'rounded-pill'} text-center`}>
      <span style={{ width: minWidth }}>{label}</span>
      {onDelete && (
        <span onClick={onDelete} className={scss.tag_close} aria-hidden="true">
          <FontAwesomeIcon icon={faTimes} fixedWidth />
        </span>
      )}
    </div>
  );
};
