import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Tabs, Tab, FloatingLabel, Table, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faPen, faEye, faClipboardList } from '@fortawesome/free-solid-svg-icons';
import {
  ClientListApi,
  CommonMasterListApi,
  QuestionListApi,
  QuestionCategoryListApi,
  AllClientResponse,
  ClientListOutputResponse,
  CommonMasterListOutputResponse,
  MonitorRuleSetInfoOutputResponse,
  QuestionListOutputResponse,
  QuestionCategoryListOutputResponse,
} from '../../../api-client';
import { Badge } from '../../atoms/Badge';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { RecommendFloatingLabel } from '../../molecules/RecommendFloatingLabel';
import { MonitorRuleQuestionListModalTable } from '../Table/MonitorRuleQuestionListModalTable';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  selectedBeforeApplyContent: string | undefined;
  selectedAfterApplyContent: string | undefined;
  selectedMonitorRuleId: number;
  selectedQuestionId: number | undefined;
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MonitorRuleQuestionAssociationModal: React.VFC<Props> = ({
  data,
  setData,
  isModal,
  setIsModal,
  selectedAfterApplyContent,
  selectedBeforeApplyContent,
  selectedMonitorRuleId,
  selectedQuestionId,
}) => {
  const questionListApi = new QuestionListApi();
  const clientListApi = new ClientListApi();
  const questionCategoryListApi = new QuestionCategoryListApi();

  //  ページ関連変数
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listPerPage, setListPerPage] = useState<QuestionListOutputResponse[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const MAX_RECORD_PER_PAGE = 20;

  const [questionListData, setQuestionListData] = useState<QuestionListOutputResponse[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionListOutputResponse>();
  const [selectionQuestionId, setSelectionQuestionId] = useState<number | undefined>();

  const [searchParams, setSearchParms] = useState<{
    questionId: number | undefined;
    questionName: string | undefined;
    clientId: number | undefined;
    clientName: string | undefined;
    categoryId: number | undefined;
    categoryName: string | undefined;
  }>({
    questionId: undefined,
    questionName: undefined,
    clientId: undefined,
    clientName: undefined,
    categoryId: undefined,
    categoryName: undefined,
  });

  // アクティブなTabKey
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  //  カテゴリリスト
  const [categoryList, setCategoryList] = useState<CommonMasterListOutputResponse[]>([]);
  const [clientList, setClientList] = useState<AllClientResponse[]>([]);

  // 初期表示
  useEffect(() => {
    if (selectedQuestionId !== undefined) {
      questionListApi.questionList(selectedQuestionId).then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
        setSelectedQuestion(res.data[0]);
      });
      setSelectionQuestionId(selectedQuestionId);
    }

    switch (activeTabKey) {
      // 事後 選択式 グローバル
      case '1':
        questionListApi
          .questionList(undefined, undefined, undefined, undefined, 2, [1, 2, 3, 4])
          .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
            setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
            // setQuestionListData(testData);
          });
        // クライアント一覧取得
        clientListApi.clientList().then((res: AxiosResponse<ClientListOutputResponse>) => {
          setClientList(res.data.allClientList);
        });
        break;
      // 事後 選択式 企業
      case '2':
        questionListApi
          .questionList(undefined, undefined, undefined, undefined, 2, [5])
          .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
            setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
            // setQuestionListData(testData);
          });
        // クライアント一覧取得
        clientListApi.clientList().then((res: AxiosResponse<ClientListOutputResponse>) => {
          setClientList(res.data.allClientList);
        });
        break;
      // 積上式
      default:
        questionListApi
          .questionList(undefined, undefined, undefined, undefined, 3, undefined)
          .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
            setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
          });
        break;
    }
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (!isModal || categoryList.length !== 0) return;
    questionCategoryListApi
      .questionCategoryList(undefined, true)
      .then((res: AxiosResponse<QuestionCategoryListOutputResponse[]>) => {
        setCategoryList(res.data);
      });
  }, [isModal]);

  // タブ変更時
  useEffect(() => {
    switch (activeTabKey) {
      // 事後 選択式 グローバル
      case '1':
        questionListApi
          .questionList(undefined, undefined, undefined, undefined, 2, [1, 2, 3, 4])
          .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
            setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
            // setQuestionListData(testData);
          });

        break;
      // 事後 選択式 企業
      case '1' || '2':
        questionListApi
          .questionList(undefined, undefined, undefined, undefined, 2, [5])
          .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
            setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
            // setQuestionListData(testData);
          });

        break;
      // 積上式
      default:
        questionListApi
          .questionList(undefined, undefined, undefined, undefined, 3, undefined)
          .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
            setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
          });
        break;
    }
    setCurrentPage(1);
  }, [activeTabKey]);

  useEffect(() => {
    setListPerPage(questionListData?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(questionListData.length) / MAX_RECORD_PER_PAGE));
  }, [questionListData, currentPage]);

  // 「検索」ボタンイベント
  const handleSearch = () => {
    const categoryListParam: any[] = [];
    const clientListParam: any[] = [];

    categoryListParam.push(searchParams.categoryId);
    clientListParam.push(searchParams.clientId);

    switch (activeTabKey) {
      // 事後 選択式グローバル設問
      case '1':
        questionListApi
          .questionList(
            searchParams.questionId,
            searchParams.questionName,
            categoryListParam[0] === undefined ? undefined : categoryListParam,
            searchParams.clientId,
            2,
            [1, 2, 3, 4]
          )
          .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
            setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
            // setQuestionListData(testData);
          });
        break;
      // 事後 選択式企業設問
      case '2':
        questionListApi
          .questionList(
            searchParams.questionId,
            searchParams.questionName,
            categoryListParam[0] === undefined ? undefined : categoryListParam,
            searchParams.clientId,
            2,
            [5]
          )
          .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
            setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
            // setQuestionListData(testData);
          });
        break;
      // 積上式
      default:
        questionListApi
          .questionList(
            searchParams.questionId,
            searchParams.questionName,
            categoryListParam[0] === undefined ? undefined : categoryListParam,
            undefined,
            3,
            undefined
          )
          .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
            setQuestionListData(res.data.sort((a, b) => (a.questionId > b.questionId ? -1 : 1)));
          });
        break;
    }
    setCurrentPage(1);
    setListPerPage(questionListData?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(questionListData.length) / MAX_RECORD_PER_PAGE));
  };

  // 各種Text系変更イベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'client':
        setSearchParms({ ...searchParams, clientName: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'questionId':
        setSearchParms({
          ...searchParams,
          questionId: Number.isNaN(parseInt(event.target.value, 10)) ? undefined : parseInt(event.target.value, 10),
        });
        break;
      case 'questionName':
        setSearchParms({ ...searchParams, questionName: event.target.value === '' ? undefined : event.target.value });
        break;
      default:
        break;
    }
  };

  // 保存イベント
  const handleOnKeep = () => {
    if (selectionQuestionId === undefined) {
      setData({
        ...data,
        monitorRuleList: data?.monitorRuleList.map((_, i) => {
          return _.monitorRuleId === selectedMonitorRuleId ? { ..._, questionId: undefined } : { ..._ };
        }),
      });
    } else {
      setData({
        ...data,
        monitorRuleList: data?.monitorRuleList.map((_, i) => {
          return _.monitorRuleId === selectedMonitorRuleId ? { ..._, questionId: selectionQuestionId } : { ..._ };
        }),
      });
    }
    setIsModal(false);
  };

  return (
    <Modal
      size="xl"
      show={isModal}
      title="モニタールールと設問の紐付け設定"
      onHide={() => {
        setIsModal(false);
      }}
      scrollable
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div>
          <div className="d-flex justify-content-end mb-4">
            <Button
              variant="link"
              className="ms-2"
              onClick={() => {
                setIsModal(false);
              }}
            >
              キャンセル
            </Button>
            <Button type="button" className="ms-2" onClick={handleOnKeep}>
              保存
            </Button>
          </div>
          <div className="border p-2 mb-4">
            <span className="d-block fw-bold mb-2">紐付け元のモニタールール</span>
            {selectedBeforeApplyContent !== undefined && (
              <span data-testid="selectedBeforeApplyContent">
                【応募前】
                <br />
                {selectedBeforeApplyContent?.split('\n').map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item}
                      <br />
                    </React.Fragment>
                  );
                })}
                <br />
              </span>
            )}
            {selectedAfterApplyContent !== undefined && (
              <span data-testid="selectedAfterApplyContent">
                【応募後】
                <br />
                {selectedAfterApplyContent?.split('\n').map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {item}
                      <br />
                    </React.Fragment>
                  );
                })}
              </span>
            )}
          </div>
          <span className="d-block fw-bold mb-2">紐付け先の設問</span>

          <div className="d-flex justify-content-between bg-light p-2 mb-4">
            <div className="d-flex align-items-center gap-4">
              <span className="d-block fw-bold">紐付け済み設問</span>
              {selectedQuestion !== undefined ? (
                <>
                  <div>
                    <span>{selectedQuestion?.questionContent}</span>
                    <br />
                    <span className="text-secondary">
                      {selectedQuestion?.selectionsCount === undefined
                        ? `${selectedQuestion?.questionAnswerMethodName}`
                        : `${selectedQuestion?.questionAnswerMethodName}(${selectedQuestion?.selectionsCount})`}
                    </span>
                  </div>
                </>
              ) : (
                <div>紐づけ済み設問なし</div>
              )}
            </div>
            {selectedQuestion !== undefined && (
              <Button
                data-testid="questionDeleteButton"
                variant="light"
                className="text-secondary ms-2"
                onClick={() => {
                  setSelectedQuestion(undefined);
                  setSelectionQuestionId(undefined);
                }}
              >
                <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
              </Button>
            )}
          </div>

          <Tabs
            defaultActiveKey={activeTabKey}
            id="uncontrolled-tab-example"
            onSelect={(key) => {
              setActiveTabKey(key === null ? '' : key);
            }}
            className="mb-2"
          >
            <Tab eventKey="1" title="事後 選択式グローバル設問">
              <div className="bg-light p-2 mb-4 mt-4">
                <div className="d-flex" style={{ width: '100%', height: '100%' }}>
                  <div className="form-floating me-2" style={{ width: '25%' }}>
                    <input
                      type="number"
                      className="form-control"
                      id="questionId"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      value={searchParams.questionId}
                      data-testid="questionIdText"
                    />
                    <label htmlFor="questionId">設問ID</label>
                  </div>
                  <div className="form-floating me-2" style={{ width: '25%' }}>
                    <RecommendFloatingLabel
                      value={searchParams.categoryName !== undefined ? searchParams.categoryName : ''}
                      label="カテゴリ"
                      options={categoryList?.map((_) => _.name)}
                      onChange={(e) => {
                        setSearchParms({
                          ...searchParams,
                          categoryId: categoryList.find((row) => {
                            return row.name === e.target.value;
                          })?.id,
                          categoryName: e.target.value,
                        });
                      }}
                      onDelete={() => {
                        setSearchParms({ ...searchParams, categoryId: undefined, categoryName: undefined });
                      }}
                      onClickItem={(e) => {
                        setSearchParms({
                          ...searchParams,
                          categoryId: categoryList.find((row) => {
                            return row.name === e.currentTarget.innerHTML;
                          })?.id,
                          categoryName: e.currentTarget.innerHTML,
                        });
                      }}
                    />
                  </div>
                  <div className="form-floating me-2" style={{ width: '50%' }}>
                    <input
                      type="text"
                      className="form-control"
                      id="questionName"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      value={searchParams.questionName === undefined ? '' : searchParams.questionName}
                      data-testid="questionNameText"
                    />
                    <label htmlFor="questionName">設問</label>
                  </div>
                  <div style={{ textAlign: 'center', width: '10%' }}>
                    <Button type="submit" style={{ width: '100%', height: '100%' }} onClick={handleSearch}>
                      検索
                    </Button>
                  </div>
                </div>
              </div>
              <PaginationWithEllipsis
                data-testid="page"
                currentPage={currentPage}
                totalPage={totalPage}
                handleClick={(page) => {
                  if (!page || page > totalPage) return;
                  setListPerPage(questionListData.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
                  setCurrentPage(page);
                }}
              />
              <MonitorRuleQuestionListModalTable
                selectionQuestionId={selectedQuestionId}
                setSelectionQuestionId={setSelectionQuestionId}
                listPerPage={listPerPage}
                pageType={undefined}
                subTabkey="1"
                topTabKey="2"
              />
            </Tab>
            <Tab eventKey="2" title="事後 選択式企業設問">
              <div className="mb-4 mt-4 bg-light">
                <div className="d-flex mb-2" style={{ width: '100%', height: '85%', margin: 'auto' }}>
                  <div className="form-floating ms-2 mt-2 " style={{ width: '25%' }}>
                    <input
                      type="number"
                      className="form-control"
                      id="questionId"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      value={searchParams.questionId === undefined ? '' : searchParams.questionId}
                      data-testid="questionIdText"
                    />
                    <label htmlFor="questionId">設問ID</label>
                  </div>
                  <div className="form-floating ms-2 mt-2 " style={{ width: '25%' }}>
                    <RecommendFloatingLabel
                      value={searchParams.categoryName !== undefined ? searchParams.categoryName : ''}
                      label="カテゴリ"
                      options={categoryList?.map((_) => _.name)}
                      onChange={(e) => {
                        setSearchParms({
                          ...searchParams,
                          categoryId: categoryList.find((row) => {
                            return row.name === e.target.value;
                          })?.id,
                          categoryName: e.target.value,
                        });
                      }}
                      onDelete={() => {
                        setSearchParms({ ...searchParams, categoryId: undefined, categoryName: undefined });
                      }}
                      onClickItem={(e) => {
                        setSearchParms({
                          ...searchParams,
                          categoryId: categoryList.find((row) => {
                            return row.name === e.currentTarget.innerHTML;
                          })?.id,
                          categoryName: e.currentTarget.innerHTML,
                        });
                      }}
                    />
                  </div>
                  <div className="form-floating me-2 ms-2 mt-2" style={{ width: '25%' }}>
                    <input
                      type="text"
                      className="form-control"
                      id="questionName"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      value={searchParams.questionName === undefined ? '' : searchParams.questionName}
                      data-testid="questionNameText"
                    />
                    <label htmlFor="questionName">設問</label>
                  </div>
                  <div className="form-floating me-2 mt-2" style={{ width: '25%' }}>
                    <RecommendFloatingLabel
                      value={searchParams.clientName !== undefined ? searchParams.clientName : ''}
                      label="管理クライアント"
                      options={clientList.map((_) => _.clientName)}
                      onChange={(e) => {
                        setSearchParms({
                          ...searchParams,
                          clientId: clientList.find((row) => {
                            return row.clientName === e.target.value;
                          })?.clientId,
                          clientName: e.target.value,
                        });
                      }}
                      onDelete={() => {
                        setSearchParms({ ...searchParams, clientId: undefined, clientName: undefined });
                      }}
                      onClickItem={(e) => {
                        setSearchParms({
                          ...searchParams,
                          clientId: clientList.find((row) => {
                            return row.clientName === e.currentTarget.innerHTML;
                          })?.clientId,
                          clientName: e.currentTarget.innerHTML,
                        });
                      }}
                    />
                  </div>
                </div>
                <p style={{ width: '25%', height: '100%', margin: 'auto' }}>
                  <Button
                    type="button"
                    className="mb-2"
                    style={{ width: '100%', height: '100%' }}
                    onClick={handleSearch}
                  >
                    検索
                  </Button>
                </p>
              </div>
              <PaginationWithEllipsis
                data-testid="page"
                currentPage={currentPage}
                totalPage={totalPage}
                handleClick={(page) => {
                  if (!page || page > totalPage) return;
                  setListPerPage(questionListData.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
                  setCurrentPage(page);
                }}
              />
              <MonitorRuleQuestionListModalTable
                selectionQuestionId={selectionQuestionId}
                setSelectionQuestionId={setSelectionQuestionId}
                listPerPage={listPerPage}
                pageType={undefined}
                subTabkey="2"
                topTabKey="2"
              />
            </Tab>
            <Tab eventKey="3" title="積上式設問">
              <div className="bg-light p-2 mb-4 mt-4">
                <div className="d-flex" style={{ width: '100%', height: '100%' }}>
                  <div className="form-floating me-2" style={{ width: '25%' }}>
                    <input
                      type="number"
                      className="form-control"
                      id="questionId"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      value={searchParams.questionId === undefined ? '' : searchParams.questionId}
                      data-testid="questionIdText"
                    />
                    <label htmlFor="questionId">設問ID</label>
                  </div>
                  <div className="form-floating me-2" style={{ width: '75%' }}>
                    <input
                      type="text"
                      className="form-control"
                      id="questionName"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      value={searchParams.questionName === undefined ? '' : searchParams.questionName}
                      data-testid="questionNameText"
                    />
                    <label htmlFor="questionName">設問</label>
                  </div>
                  <div style={{ textAlign: 'center', width: '10%' }}>
                    <Button type="button" style={{ width: '100%', height: '100%' }} onClick={handleSearch}>
                      検索
                    </Button>
                  </div>
                </div>
              </div>
              <PaginationWithEllipsis
                data-testid="page"
                currentPage={currentPage}
                totalPage={totalPage}
                handleClick={(page) => {
                  if (!page || page > totalPage) return;
                  setListPerPage(questionListData.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
                  setCurrentPage(page);
                }}
              />
              <MonitorRuleQuestionListModalTable
                selectionQuestionId={selectedQuestionId}
                setSelectionQuestionId={setSelectionQuestionId}
                listPerPage={listPerPage}
                pageType={undefined}
                subTabkey="1"
                topTabKey="3"
              />
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
    </Modal>
  );
};
