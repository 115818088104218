import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../store/correctionStore';
import { CorrectionModalBase } from './CorrectionModalBase';
import { Button } from '../../../atoms/Button';
import { IncResultOutputResponse } from '../../../../api-client';
import { ModalProps } from './ResultModal/forms/OKForm';

// TODO: [添削] テスト未実装
export const DifferenceInputAmountModal: React.FC<ModalProps> = ({ onClose, content }) => {
  const DifferenceInputAmountContent = () => {
    return (
      <div>
        <p style={{ whiteSpace: 'pre-wrap' }}>{content}</p>
      </div>
    );
  };
  return (
    <CorrectionModalBase title="注意" isModal>
      <DifferenceInputAmountContent />
      <Button className="mt-2 me-2" variant="primary" onClick={() => onClose(true)}>
        そのまま添削OKする
      </Button>
      <Button className="mt-2" variant="secondary" onClick={() => onClose(false)}>
        戻って修正する
      </Button>
    </CorrectionModalBase>
  );
};
