import { Url } from '../../../../../../../constants/Url';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../../store/correctionStore';
import { OriginalLink } from '../../../../../../atoms/OriginalLink';
import { useCurrentUser } from '../../../../../../../hooks/useCurrentUser';
import { ROLE_NAME } from '../../../../../../../RoleConstants';

export const LinkIllegalMoralCheck: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const { currentUser } = useCurrentUser();
  const list = $.res_correctionCheckDisplay?.illegalCustomerWarningList ?? [];

  // 対象が存在しない場合は空を返却する
  if (list.length === 0) return <p>対象なし</p>;

  return (
    <>
      <p>◆紐づく会員IDの中に、モラルが悪いユーザーが含まれます◆</p>
      {currentUser?.role !== ROLE_NAME.QC_CREW ? (
        <span>
          関連会員一覧の確認は
          <OriginalLink
            url={`${Url.TENSAKU.RELATED_CUSTOMER}/${$.res_applyInfoDisplay?.applyInfo?.customerId}`}
            anotherTag
            title="コチラから"
          />
        </span>
      ) : (
        <span>関連会員一覧の確認はコチラから</span>
      )}
    </>
  );
};
