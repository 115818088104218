import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { parse, addMonths, format } from 'date-fns';
import { Modal } from '../../molecules/Modal';
import { Alert } from '../../atoms/Alert';
import { BillingApi, BillingDivideInvoiceFormResponse, BillingInvoiceDivideDtoResponse } from '../../../api-client';
import { LOCALDATE_HYPHEN_FORMAT } from '../../../Constants';

export interface Props {
  isEditModal: boolean;
  setIsEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
  unitPrice: number;
  quantity: number;
  amount: number;
  taxRate: number;
  billingHeaderId: number;
  billingUseItemId: number;
  billingMonth: string;
}

export const BillingDivideInvoiceModal: React.VFC<Props> = ({
  isEditModal,
  setIsEditModal,
  name,
  unitPrice,
  quantity,
  amount,
  taxRate,
  billingHeaderId,
  billingUseItemId,
  billingMonth,
}) => {
  const [division, setDivision] = useState<number>();
  const [error, setError] = useState<string>('');
  const [form, setForm] = useState<BillingDivideInvoiceFormResponse>({
    unitPrice, // 合計金額
    billingHeaderId,
    billingUseItemId,
    taxRate,
    divided: [],
  });
  const billingApi = new BillingApi();

  const calcDivideValue = (index: number, maxIndex: number): number => {
    return index + 1 === maxIndex
      ? unitPrice - Math.floor(unitPrice / maxIndex) * (maxIndex - 1)
      : Math.floor(unitPrice / maxIndex);
  };
  const initDivisionState = () => {
    if (division === undefined) {
      setError('按分月数を入力してください。');
      return;
    }

    if (division < 1) {
      setError('按分は1以上で設定してください。');
      return;
    }
    const startYmd = parse(billingMonth, LOCALDATE_HYPHEN_FORMAT, new Date());

    // const arr: JSX.Element[] = [];
    const newDivided: BillingInvoiceDivideDtoResponse[] = [];
    for (let i = 0; i < division; i += 1) {
      const ymd = format(addMonths(startYmd, i), LOCALDATE_HYPHEN_FORMAT);
      const amountValue = calcDivideValue(i, division);
      newDivided.push({
        ymd,
        amount: amountValue,
      } as BillingInvoiceDivideDtoResponse);
    }
    // stateを初期化
    setForm({
      ...form,
      divided: newDivided,
    });
  };

  const getDivistionElement = (): JSX.Element[] => {
    const arr: JSX.Element[] = [];
    form.divided.forEach((v: BillingInvoiceDivideDtoResponse, i: number) => {
      arr.push(
        <Row className="mb-4">
          <Col className="col-6">
            <Form.Group controlId="divisionMonth">
              <Row>
                <Col>
                  <Form.Label className="mt-2 me-2">年月：</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    required
                    type="month"
                    name="divisionMonth"
                    onChange={(e) => changeDivisionMonth(e, i)}
                    value={v.ymd.replace(/-01$/, '')}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="price">
              <Row>
                <Col>
                  <Form.Label className="mt-2 me-2">金額：</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    required
                    type="text"
                    name="price"
                    value={v.amount}
                    onChange={(e) => changeAmount(e, i)}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
      );
    });
    return arr;
  };

  const changeDivisionMonth = (e: any, index: number) => {
    setForm((prevState) => ({
      ...prevState,
      divided: prevState.divided.map((v: BillingInvoiceDivideDtoResponse, i: number) => {
        if (i === index) {
          return {
            ...v,
            ymd: e.target.value.concat('-01'),
          } as BillingInvoiceDivideDtoResponse;
        }
        return v;
      }),
    }));
  };

  const changeAmount = (e: any, index: number) => {
    setForm((prevState) => ({
      ...prevState,
      divided: prevState.divided.map((v: BillingInvoiceDivideDtoResponse, i: number) => {
        if (i === index) {
          return {
            ...v,
            amount: Number(e.target.value),
          } as BillingInvoiceDivideDtoResponse;
        }
        return v;
      }),
    }));
  };

  const checkSameYmd = () => {
    const org = form.divided.length;
    const ymd = new Set<string>();
    form.divided.forEach((v) => {
      ymd.add(v.ymd);
    });
    return org === ymd.size;
  };

  const checkSameAmount = () => {
    let divideAmount = 0;
    form.divided.forEach((v) => {
      divideAmount += v.amount;
    });
    return form.unitPrice === divideAmount;
  };

  const saveDivide = () => {
    if (!checkSameYmd()) {
      setError('同一の日付が設定されています。一意になるように入力してください。');
      return;
    }
    if (!checkSameAmount()) {
      setError('合計金額に誤りがあります。');
      return;
    }

    billingApi
      .billingDivideInvoice(form)
      .then((res) => {
        // TODO 必要に応じてメッセージ出す
        onHide();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onHide = () => {
    setIsEditModal(false);
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isEditModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            <h3>按分設定モーダル</h3>
            <div className="mt-4 mb-4">
              <Row>
                <Col className="col-3">商品名</Col>
                <Col>{name}</Col>
              </Row>
              <Row>
                <Col className="col-3">単価</Col>
                <Col>\{unitPrice}</Col>
              </Row>
              <Row>
                <Col className="col-3">件数</Col>
                <Col>{quantity}</Col>
              </Row>
              <Row>
                <Col className="col-3">合計金額</Col>
                <Col>\{amount}</Col>
              </Row>
            </div>
            <div>
              <Row className="g-2 mb-4">
                <Col className="col-3">
                  <Form.Label className="mt-2 me-2">按分月数</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    name="anbuntsuki"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDivision(Number(e.target.value))}
                  />
                </Col>
                <Col>
                  <Button className="me-2" onClick={initDivisionState}>
                    決定
                  </Button>
                </Col>
              </Row>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}
            <div>{getDivistionElement()}</div>
            <div className="d-flex justify-content-end mb-4">
              <Button variant="secondary" className="me-4" onClick={saveDivide}>
                設定
              </Button>
              <Button variant="secondary" className="me-4" onClick={onHide}>
                キャンセル
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
