import * as React from 'react';
import { Table } from 'react-bootstrap';
import { createTestId } from '../../../utils/functions';
import type { Summary } from '../../pages/PostingRequest/PostingRequestPage';

export interface Props {
  info: Summary;
}

export const PostingSummaryTable: React.VFC<Props> = ({ info }) => {
  const testid = createTestId(PostingSummaryTable);
  const { clientId, clientName, applicationFormName, applicationFormType, contractStartDate, contractEndDate } = info;
  return (
    <Table className="w-75 mb-4" data-testid={testid('table')}>
      <thead>
        <tr>
          <th>クライアントID</th>
          <th>クライアント名</th>
          <th>申込書名</th>
          <th>申込書種別</th>
          <th>期間</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{clientId}</td>
          <td>{clientName}</td>
          <td>{applicationFormName}</td>
          <td>{applicationFormType}</td>
          <td>{contractStartDate && contractEndDate ? `${contractStartDate} ~ ${contractEndDate}` : ''}</td>
        </tr>
      </tbody>
    </Table>
  );
};
