import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { ReceiptPriceModifyContext } from '../../pages/ReceiptPrice/ReceiptPriceModifyPage';

export const ReceiptPriceModifyHistoryTable: React.FC = () => {
  const $ = useContext(ReceiptPriceModifyContext);
  return (
    <>
      <Table bordered size="xl" responsive hover={false}>
        <thead>
          <tr>
            <th rowSpan={2} style={{ verticalAlign: 'top' }}>
              変更日時
            </th>
            <th rowSpan={2} style={{ verticalAlign: 'top' }}>
              変更者
            </th>
            <th rowSpan={2} style={{ verticalAlign: 'top' }}>
              作業メモ
            </th>
            <th colSpan={5}>各種金額の修正</th>
          </tr>
          <tr>
            <th>種類</th>
            <th>変更前</th>
            <th>変更後</th>
            <th>差分</th>
            <th>備考</th>
          </tr>
        </thead>
        <tbody>
          {$.reciptPriceModifyHistory?.approvedAmountModifyHistoryDetailList &&
            $.reciptPriceModifyHistory?.approvedAmountModifyHistoryDetailList.map((item) =>
              item.variousModifyList.map((value, i: number) => (
                <tr>
                  {i === 0 && (
                    <>
                      <td rowSpan={item.variousModifyList.length}>{item.updateAt}</td>
                      <td rowSpan={item.variousModifyList.length}>{item.updateIncName}</td>
                      <td rowSpan={item.variousModifyList.length}>{item.workingMemo}</td>
                    </>
                  )}
                  <td>{value.category}</td>
                  <td>{value.priceBeforeChange}</td>
                  <td>{value.priceAfterChange}</td>
                  <DiffenceAmountComponent amount={value.differentialsAmount} />
                  <td>{value.note}</td>
                </tr>
              ))
            )}
        </tbody>
      </Table>
    </>
  );
};

export const DiffenceAmountComponent: React.FC<{ amount: number }> = ({ amount }) => {
  if (amount > 0) return <td>{`+${amount}`}</td>;
  return <td>{amount}</td>;
};
