import { Form } from 'react-bootstrap';
import * as React from 'react';
import { ErrorMessage } from '../../organisms/Table/SqlTemplateInputTable';

export const FormSelect = (props: any) => {
  const { getValues, register, registerName, errorsTargetName, selectOption } = props;
  return (
    <Form.Group className="mb-4">
      <Form.Select
        defaultValue={getValues(registerName)}
        {...register(registerName, {
          required: {
            value: true,
            message: '入力必須項目です。',
          },
        })}
        className={`form-control ${errorsTargetName ? 'is-invalid' : ''}`}
      >
        {selectOption.map((option: any) => (
          <option key={option.value} value={option.value}>
            {option.text}
          </option>
        ))}
      </Form.Select>
      {errorsTargetName ? ErrorMessage(errorsTargetName?.message) : ''}
    </Form.Group>
  );
};
