import React, { useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Row, Col, FloatingLabel, Card, Table } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { formatDuration, intervalToDuration, parseISO, isBefore } from 'date-fns';
import { ja } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
/* eslint-disable import/no-cycle */
import {
  ApplicationInfoApi,
  ApplicationInfoUpdateApi,
  ApplicationInfoUpdateFormResponse,
  ApplicationInfoOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { TITLE } from '../../../constants/Title';
import { createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { ClientListModal } from '../../organisms/Modal/ClientListModal';
import { Button } from '../../atoms/Button';
import { Title } from '../../atoms/Title';
import { Alert } from '../../atoms/Alert';
/* eslint-enable import/no-cycle */

const sampleData = {
  applicationId: 1,
  applicationName: '申込書名',
  applicationType: 2,
  representiveName: '担当者氏名',
  representiveNameKana: '担当者氏名（カナ）',
  representiveDivision: '担当者部署',
  representiveJobTitle: '担当者役職',
  representivePhoneNumber: '222-2222-2222',
  representiveMailaddress: 'aaa@gmail.com',
  contractResponsibleName: '契約責任者氏名',
  contractResponsibleNameKana: '契約責任者氏名（カナ）',
  contractResponsibleDivision: '契約責任者部署',
  contractResponsibleJobTitle: '契約責任者役職',
  contractResponsiblePhoneNumber: '333-33333333',
  contractResponsibleMailaddress: 'bbb@gmail.com',
  contractStartDate: '2022-03-22',
  contractEndDate: '2022-04-22',
  yearPublishFee: 1,
  monthAspUseFee: 1,
  contractShopCount: 1,
  contractMonitorCount: 1,
  contractNoteList: ['備考1'],
  contractOptionList: [
    { content: 'オプション内容1', amount: 100, unit: 0, frequency: 1 },
    { content: 'オプション内容2', amount: 100, unit: 1, frequency: 1 },
    { content: 'オプション内容3', amount: 100, unit: 2, frequency: 1 },
  ],
};

export type State = {
  api: ApplicationInfoApi;
  updateApi: ApplicationInfoUpdateApi;
  isNew: boolean;
  clientId: number | null;
  clientName: string;
  applicationId: number | null;
  duration?: string;
  isClientListModal: boolean;
  isInvalidDuration: boolean;
  contractShopCount?: number;
  contractMonitorCount?: number;
  updResult: IncResultOutputResponse;
};

const APPLICATION_TYPE = ['新規', '追加', '変更', '解約', '休止'] as const;
const UNIT = ['回数', '回数・種類'] as const;
const GENERATION_FREQUENCY = ['初回', '毎月', '毎年', '都度'] as const;

export const ApplicationModifyPage: React.VFC = () => {
  const testid = createTestId(ApplicationModifyPage);
  const history = useHistory();
  const location = useLocation<{ applicationId?: number; clientId?: number; clientName?: string }>();

  const { state: $, mergeState } = useLargeState<State>({
    api: new ApplicationInfoApi(),
    updateApi: new ApplicationInfoUpdateApi(),
    isNew: !location?.state?.applicationId,
    applicationId: location?.state?.applicationId || null,
    clientId: location?.state?.clientId || null,
    clientName: location?.state?.clientName || '',
    isClientListModal: false,
    isInvalidDuration: false,
    updResult: { result: false },
  });

  const {
    state: f,
    mergeState: fMergeState,
    onChangeSet: fOnChangeSet,
    setState: fSetState,
  } = useLargeState<Partial<ApplicationInfoUpdateFormResponse>>({});

  const setDuration = useCallback(
    (startDate: string | undefined, endDate: string | undefined) => {
      if (!startDate || !endDate) return;

      const start = parseISO(startDate);
      const endTime = 'T23:59:59.000Z'; // 契約終了日の終わりの時間で計算
      const end = parseISO(`${endDate}${endTime}`);

      if (isBefore(end, start)) {
        mergeState({ isInvalidDuration: true, duration: '' });
        return;
      }

      const diff = diffMonth(new Date(startDate), new Date(endDate));
      const year = Math.trunc(diff / 12);
      const month = diff % 12;

      let duration = '';
      if (diff === 0) {
        duration = '';
      } else if (diff < 12) {
        duration = `${month}ヶ月`;
      } else if (month === 0) {
        duration = `${year}年`;
      } else {
        duration = `${year}年${month}ヶ月`;
      }

      // const duration = formatDuration(intervalToDuration({ start, end }), { format: ['years', 'months'], locale: ja });
      mergeState({ duration: duration.replace(/か/, 'ヶ'), isInvalidDuration: false });
    },
    [mergeState]
  );

  const diffMonth = (d1: Date, d2: Date) => {
    const d1Month = d1.getFullYear() * 12 + d1.getMonth();
    const d2Month = d2.getFullYear() * 12 + d2.getMonth();
    return d2Month - d1Month + 1;
  };

  useEffect(() => {
    if (!$.applicationId) return;
    $.api.applicationInfo($.applicationId).then((res: AxiosResponse<ApplicationInfoOutputResponse>) => {
      const { applicationId, contractShopCount, contractMonitorCount, contractStartDate, contractEndDate, ...rest } =
        res.data;
      mergeState({ contractShopCount, contractMonitorCount });
      fSetState({ contractStartDate, contractEndDate, applicationId: applicationId as number, ...rest });
      setDuration(contractStartDate, contractEndDate);
    });
  }, [$.applicationId, $.api, fSetState, mergeState, setDuration]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { clientId } = $;
    if (!clientId) return;

    $.updateApi
      .applicationInfoUpdate({ clientId, ...(f as any) })
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ applicationId: res.data.id, updResult: res.data });
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  return (
    <>
      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.APPLICATION_MODIFY}
      </Title>

      <ClientListModal
        isModal={$.isClientListModal}
        onSelect={(clientId: number, clientName: string) =>
          mergeState({ clientId, clientName, isClientListModal: false })
        }
        onHide={() => mergeState({ isClientListModal: false })}
      />

      {$.updResult.result && (
        <Alert testId={testid('success-alert')} variant="success">
          保存しました。
        </Alert>
      )}
      {$.updResult.errorMessage && (
        <Alert
          testId={testid('failure-alert')}
          variant="danger"
        >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}

      <Form onSubmit={onSubmit}>
        <div className="d-flex justify-content-end mb-4">
          <Button variant="link" testId={testid('cancel-button')} className="ms-2" onClick={() => history.goBack()}>
            キャンセル
          </Button>
          {$.isNew && (
            <Button
              className="ms-2"
              testId={testid('select-button')}
              onClick={() => mergeState({ isClientListModal: true })}
            >
              クライアント選択
            </Button>
          )}
          <Button type="submit" testId={testid('submit-button')} className="ms-2" disabled={!$.clientId}>
            保存
          </Button>
        </div>

        <FloatingLabel label="クライアント名" className="mb-4" data-testid={testid('clientName')}>
          <Form.Control readOnly type="text" placeholder="クライアント名" value={$.clientName || ''} />
        </FloatingLabel>

        <FloatingLabel label="申込書名(必須)" className="mb-4" data-testid={testid('applicationName')}>
          <Form.Control
            required
            type="text"
            placeholder="申込書名"
            value={f.applicationName || ''}
            onChange={fOnChangeSet('applicationName')}
          />
        </FloatingLabel>

        <FloatingLabel label="申込書種別(必須)" className="mb-4" data-testid={testid('applicationType')}>
          <Form.Select
            required
            value={f.applicationType}
            onChange={fOnChangeSet('applicationType', Number)}
            disabled={!$.isNew}
          >
            <option value="">&nbsp;</option>
            {APPLICATION_TYPE.map((label, value) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>

        <Card className="mb-4">
          <Card.Body>
            <h4 className="mb-4">担当者</h4>

            <FloatingLabel label="氏名" className="mb-4" data-testid={testid('representiveName')}>
              <Form.Control
                type="text"
                placeholder="氏名"
                value={f.representiveName || ''}
                onChange={fOnChangeSet('representiveName')}
              />
            </FloatingLabel>

            <FloatingLabel label="フリガナ" className="mb-4" data-testid={testid('representiveNameKana')}>
              <Form.Control
                type="text"
                placeholder="フリガナ"
                value={f.representiveNameKana || ''}
                onChange={fOnChangeSet('representiveNameKana')}
              />
            </FloatingLabel>

            <FloatingLabel label="部署" className="mb-4" data-testid={testid('representiveDivision')}>
              <Form.Control
                type="text"
                placeholder="部署"
                value={f.representiveDivision || ''}
                onChange={fOnChangeSet('representiveDivision')}
              />
            </FloatingLabel>

            <FloatingLabel label="役職" className="mb-5" data-testid={testid('representiveJobTitle')}>
              <Form.Control
                type="text"
                placeholder="役職"
                value={f.representiveJobTitle || ''}
                onChange={fOnChangeSet('representiveJobTitle')}
              />
            </FloatingLabel>

            <h5 className="mb-4">連絡先</h5>

            <FloatingLabel label="メールアドレス" className="mb-4" data-testid={testid('representiveMailaddress')}>
              <Form.Control
                type="email"
                placeholder="メールアドレス"
                value={f.representiveMailaddress || ''}
                onChange={fOnChangeSet('representiveMailaddress')}
              />
            </FloatingLabel>

            <FloatingLabel label="電話番号" data-testid={testid('representivePhoneNumber')}>
              <Form.Control
                type="tel"
                placeholder="電話番号"
                className="mb-0"
                value={f.representivePhoneNumber || ''}
                onChange={fOnChangeSet('representivePhoneNumber')}
              />
            </FloatingLabel>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <h4 className="mb-4">契約責任者</h4>

            <FloatingLabel label="氏名" className="mb-4" data-testid={testid('contractResponsibleName')}>
              <Form.Control
                type="text"
                placeholder="氏名"
                value={f.contractResponsibleName || ''}
                onChange={fOnChangeSet('contractResponsibleName')}
              />
            </FloatingLabel>

            <FloatingLabel label="フリガナ" className="mb-4" data-testid={testid('contractResponsibleNameKana')}>
              <Form.Control
                type="text"
                placeholder="フリガナ"
                value={f.contractResponsibleNameKana || ''}
                onChange={fOnChangeSet('contractResponsibleNameKana')}
              />
            </FloatingLabel>

            <FloatingLabel label="部署" className="mb-4" data-testid={testid('contractResponsibleDivision')}>
              <Form.Control
                type="text"
                placeholder="部署"
                value={f.contractResponsibleDivision || ''}
                onChange={fOnChangeSet('contractResponsibleDivision')}
              />
            </FloatingLabel>

            <FloatingLabel label="役職" className="mb-5" data-testid={testid('contractResponsibleJobTitle')}>
              <Form.Control
                type="text"
                placeholder="役職"
                value={f.contractResponsibleJobTitle || ''}
                onChange={fOnChangeSet('contractResponsibleJobTitle')}
              />
            </FloatingLabel>

            <h5 className="mb-4">連絡先</h5>

            <FloatingLabel
              label="メールアドレス"
              className="mb-4"
              data-testid={testid('contractResponsibleMailaddress')}
            >
              <Form.Control
                type="email"
                placeholder="メールアドレス"
                value={f.contractResponsibleMailaddress || ''}
                onChange={fOnChangeSet('contractResponsibleMailaddress')}
              />
            </FloatingLabel>

            <FloatingLabel label="電話番号" data-testid={testid('contractResponsiblePhoneNumber')}>
              <Form.Control
                type="tel"
                placeholder="電話番号"
                className="mb-0"
                value={f.contractResponsiblePhoneNumber || ''}
                onChange={fOnChangeSet('contractResponsiblePhoneNumber')}
              />
            </FloatingLabel>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <h4 className="mb-4">契約内容</h4>

            {$.duration && (
              <div className="d-flex justify-content-end mb-2" data-testid={testid('duration')}>
                <span className="bg-light p-2">契約期間：{$.duration}</span>
              </div>
            )}

            {$.isInvalidDuration && (
              <Alert variant="danger" testId={testid('duration-alert')}>
                終了日は開始日以降の日付を指定してください。
              </Alert>
            )}

            <Row className="d-flex align-items-center mb-4">
              <Col>
                <FloatingLabel label="契約開始日(必須)" data-testid={testid('contractStartDate')}>
                  <Form.Control
                    required
                    type="date"
                    placeholder="契約開始日"
                    value={f.contractStartDate || ''}
                    onChange={(e) => {
                      fMergeState({ contractStartDate: e.target.value });
                      setDuration(e.target.value, f.contractEndDate);
                    }}
                  />
                </FloatingLabel>
              </Col>
              {' ~ '}
              <Col>
                <FloatingLabel label="契約終了日(必須)" data-testid={testid('contractEndDate')}>
                  <Form.Control
                    required
                    type="date"
                    placeholder="契約終了日"
                    value={f.contractEndDate || ''}
                    onChange={(e) => {
                      fMergeState({ contractEndDate: e.target.value });
                      setDuration(f.contractStartDate, e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <div className="d-flex align-items-center mb-4">
              <FloatingLabel label="年間掲載料金(必須)" className="flex-grow-1" data-testid={testid('yearPublishFee')}>
                <Form.Control
                  type="number"
                  placeholder="年間掲載料金"
                  className="mb-0"
                  value={f.yearPublishFee || ''}
                  onChange={fOnChangeSet('yearPublishFee', Number)}
                  required
                />
              </FloatingLabel>
              <span className="col-1 ms-2">円(合計)</span>
            </div>

            <div className="d-flex align-items-center mb-4">
              <FloatingLabel
                label="月額ASP利用料金(必須)"
                className="flex-grow-1"
                data-testid={testid('monthAspUseFee')}
              >
                <Form.Control
                  type="number"
                  placeholder="月額ASP利用料金"
                  className="mb-0"
                  value={f.monthAspUseFee || ''}
                  onChange={fOnChangeSet('monthAspUseFee', Number)}
                  required
                />
              </FloatingLabel>
              <span className="col-1 ms-2">円(合計)</span>
            </div>
            {$.contractShopCount !== undefined && (
              <div className="d-flex align-items-center mb-4">
                <FloatingLabel label="契約店舗数" className="flex-grow-1" data-testid={testid('contractShopCount')}>
                  <Form.Control
                    type="number"
                    placeholder="契約店舗数"
                    className="mb-0"
                    value={$.contractShopCount || ''}
                    readOnly
                  />
                </FloatingLabel>
                <span className="col-1 ms-2">店舗</span>
              </div>
            )}

            {$.contractMonitorCount !== undefined && (
              <div className="d-flex align-items-center mb-4">
                <FloatingLabel
                  label="契約モニター数"
                  className="flex-grow-1"
                  data-testid={testid('contractMonitorCount')}
                >
                  <Form.Control
                    type="number"
                    placeholder="契約モニター数"
                    className="mb-0"
                    value={$.contractMonitorCount || ''}
                    readOnly
                  />
                </FloatingLabel>
                <span className="col-1 ms-2">モニター</span>
              </div>
            )}

            <div className="bg-light p-4">
              <span className="d-block fw-bold mb-4">契約備考</span>
              {f.contractNoteList?.map((v, i) => (
                <FloatingLabel
                  key={i}
                  label={`契約備考${i + 1}`}
                  className="flex-grow-1"
                  data-testid={testid('note', i)}
                >
                  <Form.Control
                    type="text"
                    className="mb-4"
                    placeholder={`契約備考${i + 1}`}
                    value={v || ''}
                    onChange={(e) =>
                      fMergeState({
                        contractNoteList: f.contractNoteList?.map((n, ni) => (i === ni ? e.target.value : n)),
                      })
                    }
                  />
                </FloatingLabel>
              ))}
              <div className="d-flex justify-content-end">
                <Button
                  variant="link"
                  data-testid={testid('noteAdd-button')}
                  className="text-secondary"
                  onClick={() => {
                    fMergeState({ contractNoteList: [...(f.contractNoteList || []), ''] });
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                  追加
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <h4 className="mb-4">契約オプション</h4>

            <div className="d-flex justify-content-end">
              <Button
                variant="link"
                data-testid={testid('optionAdd-button')}
                className="text-secondary"
                onClick={() =>
                  fMergeState({
                    contractOptionList: [
                      ...(f.contractOptionList || []),
                      { content: '', amount: 0, unit: 0, frequency: 0 },
                    ],
                  })
                }
              >
                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                追加
              </Button>
            </div>

            <Table data-testid={testid('table')}>
              <thead>
                <tr>
                  <th>
                    オプション内容
                    {f.contractOptionList && f.contractOptionList?.length > 0 && <span className="text-danger">*</span>}
                  </th>
                  <th>
                    金額
                    {f.contractOptionList && f.contractOptionList?.length > 0 && <span className="text-danger">*</span>}
                  </th>
                  <th>
                    単位
                    {f.contractOptionList && f.contractOptionList?.length > 0 && <span className="text-danger">*</span>}
                  </th>
                  <th>
                    発生頻度
                    {f.contractOptionList && f.contractOptionList?.length > 0 && <span className="text-danger">*</span>}
                  </th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {f.contractOptionList?.map(({ content, amount, unit, frequency }, i) => (
                  <tr key={i}>
                    <td data-testid={testid('content', i)}>
                      <Form.Control
                        required
                        type="text"
                        className="mb-0"
                        value={content}
                        onChange={(e) =>
                          fMergeState({
                            contractOptionList: f.contractOptionList?.map((o, oi) =>
                              i === oi ? { ...o, content: e.target.value } : o
                            ),
                          })
                        }
                      />
                    </td>
                    <td className="d-flex align-items-center" data-testid={testid('amount', i)}>
                      <Form.Control
                        required
                        type="number"
                        className="w-50 mb-0"
                        value={amount || ''}
                        onChange={(e) =>
                          fMergeState({
                            contractOptionList: f.contractOptionList?.map((o, oi) =>
                              i === oi ? { ...o, amount: Number(e.target.value) } : o
                            ),
                          })
                        }
                      />
                      <span className="ms-1">円</span>
                    </td>
                    <td>
                      <Form.Select
                        required
                        className="w-auto d-inline-block"
                        value={unit || ''}
                        onChange={(e) =>
                          fMergeState({
                            contractOptionList: f.contractOptionList?.map((o, oi) =>
                              i === oi ? { ...o, unit: Number(e.target.value) } : o
                            ),
                          })
                        }
                      >
                        {UNIT.map((label, value) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    <td data-testid={testid('frequency', i)}>
                      <Form.Select
                        required
                        className="w-auto d-inline-block"
                        value={frequency}
                        onChange={(e) =>
                          fMergeState({
                            contractOptionList: f.contractOptionList?.map((o, oi) =>
                              i === oi ? { ...o, frequency: Number(e.target.value) } : o
                            ),
                          })
                        }
                      >
                        {GENERATION_FREQUENCY.map((label, value) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </Form.Select>
                    </td>
                    <td className="text-end">
                      <Button
                        variant="light"
                        className="bg-transparent"
                        onClick={() =>
                          fMergeState({ contractOptionList: f.contractOptionList?.filter((_, oi) => i !== oi) })
                        }
                      >
                        <FontAwesomeIcon className="text-secondary" icon={faTimesCircle} fixedWidth />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};
