import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

import { Card } from 'react-bootstrap';
import { Button } from '../atoms/Button';

export interface Props {
  url: string;
  name?: string;
  onDelete?: () => void;
  isActive?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

export const ImageCard: React.VFC<Props> = ({ url, name, onDelete, dragHandleProps, isActive = true }) => {
  return (
    <Card data-testid="imageCard">
      <Card.Body className="d-flex justify-content-between align-middle gap-4">
        <div className="d-flex align-items-center gap-4">
          <button
            type="button"
            className={`text-secondary ${!dragHandleProps ? 'invisible' : ''}`}
            {...dragHandleProps}
          >
            <FontAwesomeIcon style={{ cursor: 'grab' }} icon={faGripLines} fixedWidth />
          </button>
          <img src={url} alt={name} style={{ width: '90px', height: '63px', objectFit: 'cover' }} />
          {name && <span>{name}</span>}
        </div>
        {onDelete && (
          <Button
            variant="link"
            className={`text-secondary ${!isActive ? 'invisible' : ''}`}
            testId="delete"
            onClick={onDelete}
          >
            <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};
