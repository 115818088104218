import React from 'react';
import { Layout } from '../templates/Layout';

const MaintenancePage: React.FC = () => {
  return (
    <Layout>
      <h4>現在メンテナンス中です</h4>
    </Layout>
  );
};

export default MaintenancePage;
