import React, { useEffect, useState } from 'react';
import { Form, Row, Col, FloatingLabel, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { MailTemplateApi, MailTemplateListOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';

export const MailTemplateListPage: React.VFC = () => {
  const history = useHistory();
  const mailTemplateApi = new MailTemplateApi();
  const [data, setData] = useState<MailTemplateListOutputResponse[]>([]);

  useEffect(() => {
    mailTemplateApi.mailTemplateList().then((res: AxiosResponse<MailTemplateListOutputResponse[]>) => {
      setData(res.data);
      // setData(res.data.map((_,i)=>{return {..._,type : `タイプ${i+1}`}}));
    });
  }, []);

  return (
    <>
      <Title className="mb-4">メールテンプレート一覧画面</Title>

      <div className="d-flex justify-content-end">
        <Button variant="link" className="text-secondary">
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          <Link to={`${Url.KEISAI.MAIL_TEMPLATE_MODIFY}`} target="_blank">
            追加
          </Link>
        </Button>
      </div>

      <Table className="mb-4">
        <thead>
          <tr className="align-middle">
            <th> </th>
            <th>タイプ</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item: MailTemplateListOutputResponse) => {
            return (
              <tr key={item.mailTemplateId.toString()} className="align-middle">
                <td> </td>
                <td>
                  <Button variant="link" className="p-0">
                    <a
                      href={`${Url.KEISAI.MAIL_TEMPLATE_MODIFY}/${item.mailTemplateId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.type}
                    </a>
                  </Button>
                  <br />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
