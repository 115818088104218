import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { yupResolver } from '@hookform/resolvers/yup';
import { sqlTemplateSchema } from '../../../constants/YupSchema';
import {
  SqlQueryCreateParameterListFormResponse,
  SqlQueryUpdateFormResponse,
  SqlTemplateApi,
  SqlTemplateCreateFormResponse,
  SqlTemplateInfoOutputResponse,
  SqlQueryInfoOutputResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { SqlTemplateInputTable } from '../../organisms/Table/SqlTemplateInputTable';
import { SubmitPrimaryButton } from '../../atoms/Button/SubmitPrimaryButton';

// parameterList内のparameterIdを削除
export const pickParameter = (
  obj: SqlTemplateInfoOutputResponse | SqlQueryUpdateFormResponse | SqlQueryInfoOutputResponse
) => {
  const parameterList: SqlQueryCreateParameterListFormResponse[] = [];
  // objectから配列に変換
  const arrayData = Object.entries(obj).map(([key, value]) => ({ key, value }));
  // 配列からparameterListのみ取得
  const filterData = arrayData.filter((e) => e.key === 'parameterList');
  // parameterIdを除いた配列を作成
  for (let i = 0; i < filterData[0].value.length; i += 1) {
    const { parameterId, ...parameter } = filterData[0].value[i];
    parameterList.push(parameter);
  }
  return parameterList;
};

export const SqlTemplateCreatePage = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
    control,
    getValues,
  } = useForm<SqlTemplateCreateFormResponse>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    shouldFocusError: false,
    defaultValues: {
      personalInformationProtectionFlag: false,
    },
    resolver: yupResolver(sqlTemplateSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parameterList',
  });

  const sqlTemplateApi = new SqlTemplateApi();
  useEffect(() => {
    if (location.state) {
      const listData = location.state.listData as SqlTemplateInfoOutputResponse;

      sqlTemplateApi.sqlTemplateInfo(listData.templateId).then((response: AxiosResponse<any>) => {
        const obj: SqlTemplateInfoOutputResponse = response.data;
        reset({
          personalInformationProtectionFlag: obj.personalInformationProtectionFlag,
          sqlString: obj.sqlString,
          templateName: obj.templateName,
          parameterList: pickParameter(obj),
        });
      });
    }
  }, []);
  const onSubmit: SubmitHandler<SqlTemplateCreateFormResponse> = (data: SqlTemplateCreateFormResponse) => {
    history.push({
      pathname: Url.KEISAI.SQL_TMP_CREATE_CONFIRM,
      state: data,
    });
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SqlTemplateInputTable
          tableTitle="SQLテンプレート作成"
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
          fields={fields}
          append={append}
          remove={remove}
        />
        <SubmitPrimaryButton>確認</SubmitPrimaryButton>
      </Form>
    </>
  );
};
