import React, { useCallback, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../atoms/Button';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, execDownload, isoDateToInput, parseSearchQuery } from '../../../utils/functions';
// eslint-disable-next-line import/no-cycle
import { ClientNgApi } from '../../../api-client';
import type {
  ClientNgCustomerDetailOutputResponse,
  ClientNgCustomerDetailChildOutputResponse,
  ClientNgReasonTypeListOutputResponse,
} from '../../../api-client';
import { DeleteModal } from '../../organisms/Modal/DeleteModal';
import { ClientNgEditModal } from '../../organisms/Modal/ClientNgEditModal';
import { ClientNgEditTable } from '../../organisms/Table/ClientNgEditTable';
import { Url } from '../../../constants/Url';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { useRedirect } from '../../../hooks/useRedirect';

export interface FormsState {
  ngCustomerId?: ClientNgCustomerDetailChildOutputResponse['ngCustomerId'];
  ngReasonType?: ClientNgCustomerDetailChildOutputResponse['ngReasonType'];
  ngReasonNote?: ClientNgCustomerDetailChildOutputResponse['ngReasonNote'];
}

export interface State {
  api: ClientNgApi;
  // api: ReturnType<typeof clientNgApi>;
  isLoading: boolean;
  isEditModal: boolean;
  isDeleteModal: boolean;
  editModalType: 'new' | 'edit';
  selectedIndex?: number;
  forms: FormsState;
  clientId?: ClientNgCustomerDetailOutputResponse['clientId'];
  clientName?: ClientNgCustomerDetailOutputResponse['clientName'];
  clientNgUserCount?: ClientNgCustomerDetailOutputResponse['clientNgUserCount'];
  updateAt?: ClientNgCustomerDetailOutputResponse['updateAt'];
  list: ClientNgCustomerDetailOutputResponse['clientNgCustomerList'];
  reasonTypes: ClientNgReasonTypeListOutputResponse[];
}
export const ClientNgEditPage: React.FC = () => {
  const testid = createTestId(ClientNgEditPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    api: new ClientNgApi(),
    // api: clientNgApi({}, { enableLogs: true }),
    isLoading: false,
    isEditModal: false,
    isDeleteModal: false,
    editModalType: 'new',
    list: [],
    reasonTypes: [],
    forms: {},
  });

  const history = useHistory();
  const { search } = useLocation();
  const reload = useRedirect(Url.TENSAKU.CLIENT_NG_EDIT + search);

  useEffect(() => {
    const clientId = Number(parseSearchQuery(search)?.id);
    mergeState({ isLoading: true });
    (async () => {
      try {
        const {
          data: { clientName, clientNgUserCount, updateAt, clientNgCustomerList },
        } = await $.api.clientNgCustomerDetail(clientId);
        const { data: reasonTypes } = await $.api.clientNgReasonTypeList();
        mergeState({
          clientId,
          clientName,
          clientNgUserCount,
          updateAt,
          list: clientNgCustomerList,
          reasonTypes,
          isLoading: false,
        });
      } catch {
        history.push(Url.COMMON_ERROR);
      }
    })();
  }, [$.api, search, mergeState, history]);

  const onSubmitEdit = useCallback(() => {
    const isExisting = $.editModalType === 'edit';
    if ($.clientId === undefined) return;
    const { ngCustomerId, ngReasonNote, ngReasonType } = $.forms;
    if (!ngCustomerId) return;
    mergeState({ isLoading: true });
    $.api
      .clientNgCustomerInputComplete({
        clientId: $.clientId,
        ngCustomerId: Number(ngCustomerId),
        ngReasonNote,
        ngReasonType: Number(ngReasonType),
        isExisting,
      })
      .then((res) => {
        console.log({ res });
        reload();
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      })
      .finally(() => {
        mergeState({ isLoading: false });
      });
  }, [$.api, $.forms, $.clientId, history, mergeState, reload]);

  const onSubmitDelete = useCallback(() => {
    if ($.selectedIndex === undefined) return;
    if ($.clientId === undefined) return;
    if ($.forms.ngCustomerId === undefined) return;
    mergeState({ isLoading: true });
    $.api
      .clientNgCustomerDelete($.clientId, $.forms.ngCustomerId)
      .then((res) => {
        console.log({ res });
        reload();
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      })
      .finally(() => {
        mergeState({ isLoading: false });
      });
  }, [$.api, $.selectedIndex, history, mergeState, reload]);

  const onDownloadCsv = useCallback(() => {
    if ($.clientId === undefined) return;
    mergeState({ isLoading: true });
    $.api.clientNgCustomerListDownload($.clientId).then((res) => {
      execDownload(res.data, 'text/csv', getFileName(res))
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        })
        .finally(() => {
          mergeState({ isLoading: false });
        });
    });
  }, [$.clientId, $.api, history, mergeState]);

  const getFileName = (res: AxiosResponse<string>): string => {
    console.log(res.headers['content-disposition']);
    const filenameRegex = /(\b[a-zA-Z0-9_\-]+\.[cC][sS][vV]\b)/; // eslint-disable-line
    const matches = filenameRegex.exec(res.headers['content-disposition']);
    // 取得できない場合はdata.csvとして返す
    let fileName = 'data.csv';
    if (matches != null && matches[1]) {
      fileName = matches[1].replace(/['"]/g, '');
    }
    return fileName;
  };

  const setForms = useBindSet('forms');

  return (
    <>
      <ClientNgEditModal
        isModal={$.isEditModal}
        reasonTypes={$.reasonTypes}
        forms={$.forms}
        setForms={setForms}
        modalType={$.editModalType}
        onHide={() =>
          mergeState({
            isEditModal: false,
            selectedIndex: undefined,
            forms: {},
          })
        }
        onSubmit={onSubmitEdit}
      />
      <DeleteModal
        isModal={$.isDeleteModal}
        onHide={() =>
          mergeState({
            isDeleteModal: false,
            selectedIndex: undefined,
          })
        }
        onCancel={() =>
          mergeState({
            isDeleteModal: false,
            selectedIndex: undefined,
          })
        }
        onOk={onSubmitDelete}
      >
        <ClientNgEditTable
          isDeleteModal
          list={$.selectedIndex === undefined ? [] : [$.list[$.selectedIndex]]}
          reasonTypes={$.reasonTypes}
          onEdit={(selectedIndex) =>
            mergeState({
              selectedIndex,
              isEditModal: true,
              editModalType: 'edit',
            })
          }
          onDelete={(selectedIndex) =>
            mergeState({
              selectedIndex,
              isDeleteModal: true,
            })
          }
        />
      </DeleteModal>
      <LoadingSpinner isLoading={$.isLoading}>
        <div className="d-flex justify-content-end mb-4">
          <Button
            data-testid={testid('finish-button')}
            variant="link"
            className="ms-2"
            onClick={() => history.push(Url.TENSAKU.CLIENT_NG)}
          >
            戻る
          </Button>
        </div>
        <h1 data-testid={testid('primary-title')}>{$.clientName}</h1>
        <p data-testid={testid('secondary-title')}>
          {Object.entries({
            クライアントID: $.clientId,
            NG登録会員数: $.clientNgUserCount,
            更新日: isoDateToInput('date', $.updateAt) ?? '',
          })
            .map(([k, v]) => `${k}: ${v}`)
            .join('\u3000')}
        </p>
        <div className="d-flex mb-4">
          <Button data-testid={testid('csv-button')} className="ms-2 justify-content-start" onClick={onDownloadCsv}>
            CSVダウンロード
          </Button>
        </div>
        <div className="d-flex justify-content-end mb-4">
          <Button
            data-testid={testid('add-button')}
            onClick={() =>
              mergeState({
                isEditModal: true,
                editModalType: 'new',
                forms: {},
              })
            }
          >
            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
            追加
          </Button>
        </div>
        <ClientNgEditTable
          list={$.list}
          reasonTypes={$.reasonTypes}
          onEdit={(selectedIndex) =>
            mergeState({
              selectedIndex,
              isEditModal: true,
              editModalType: 'edit',
              forms: {
                ngCustomerId: $.list[selectedIndex].ngCustomerId,
                ngReasonType: $.list[selectedIndex].ngReasonType,
                ngReasonNote: $.list[selectedIndex].ngReasonNote,
              },
            })
          }
          onDelete={(selectedIndex) =>
            mergeState({
              selectedIndex,
              isDeleteModal: true,
              forms: {
                ngCustomerId: $.list[selectedIndex].ngCustomerId,
                ngReasonType: $.list[selectedIndex].ngReasonType,
                ngReasonNote: $.list[selectedIndex].ngReasonNote,
              },
            })
          }
        />
      </LoadingSpinner>
    </>
  );
};
