import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { CorrectApi, CorrectEnqueteApi, CorrectionTemplateApi, CorrectReceiptApi, NoteApi } from '../../../api-client';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useLargeState } from '../../../hooks/useLargeState';
import { CorrectionPageState as State, CorrectionProvider } from '../../../store/correctionStore';
import { debugLog } from '../../../utils/functions';
import { CorrectStatusDisplay } from '../../molecules/CorrectStatusDisplay';
import { HeaderNav } from '../../organisms/Correction/HeaderNav';
import { ReceiptContents } from '../../organisms/Correction/Main/ReceiptContents';
import { EnqueteModal } from '../../organisms/Correction/Modal/EnqueteModal';
import { NGHistoryModal } from '../../organisms/Correction/Modal/NGHistoryModal';
import { NoteModal } from '../../organisms/Correction/Modal/NoteModal';
import { ReceiptInfoModal } from '../../organisms/Correction/Modal/ReceiptInfoModal';
import { ResultModal } from '../../organisms/Correction/Modal/ResultModal';
import { CorrectionTemplate } from '../../templates/CorrectionTemplate';
import * as CONSTANTS from './constants';
// eslint-disable-next-line import/no-cycle
import { debugLogCurrentUserDataForBackend } from './debug';
import { OkReleaseModal } from '../../organisms/Correction/Modal/OkReleaseModal';
import { ResultModalMode } from './constants';

// TODO: [添削] テスト未実装
export const CorrectionReceiptPage: React.FC = React.memo(() => {
  const largeState = useLargeState<State>({
    isLoading: true,
    isEnquete: false,

    applyId: Number(useParams<{ id: string | undefined }>().id),

    api_correct: new CorrectApi(),
    api_correctReceipt: new CorrectReceiptApi(),
    api_correctionTemplate: new CorrectionTemplateApi(),
    api_note: new NoteApi(),
    api_correctEnquete: new CorrectEnqueteApi(),
    isNoteModal: false,
    isResultModal: false,
    isNGHistoryModal: false,
    isEnqueteModal: false,
    isReceiptInfoModal: false,

    commentForms_ng: [],
    commentForms_escale: [],

    // 強制的にエスカレ回答を表示状態にする
    // debug_forceEscalateAnswer: true,
  });
  const { state: $, mergeState: mergeStateWithRender } = largeState;

  const { currentUser, isAuthChecking } = useCurrentUser();

  /** 初期フェッチ */
  useEffect(() => {
    (async () => {
      const applyInfoDisplayResponse = (await $.api_correct.applyInfoDisplay($.applyId)).data;
      mergeStateWithRender({
        isLoading: false,
        res_correctReceiptDisplayControl: {
          ...(await $.api_correctReceipt.receiptCorrectDisplayControl($.applyId)).data,
          // ...warnValue('表示制御', {
          //   // -- 添削OK、NGを許可
          //   // correctionExec: false,
          //   // correctionExec: true,
          //   // -- エスカレ権限
          //   // escalation: CONSTANTS.DisplayControlEscalate.DISABLE,
          //   // escalation: CONSTANTS.DisplayControlEscalate.DISPLAY,
          //   // escalation: CONSTANTS.DisplayControlEscalate.ANSWER,
          //   // -- ノート表示
          //   // noteUpdate: false,
          //   noteUpdate: true,
          //   // -- OK解除ボタン表示
          //   // okRelease: false,
          //   // okRelease: true,
          //   // -- 却下権限
          //   // reject: CONSTANTS.DisplayControlReject.DISABLE,
          //   // reject: CONSTANTS.DisplayControlReject.REQUEST,
          //   // reject: CONSTANTS.DisplayControlReject.APPROVE_WITH_ESCALE,
          //   // reject: CONSTANTS.DisplayControlReject.APPROVE_WITH_ANSWER,
          //   // reject: CONSTANTS.DisplayControlReject.EXECUTE,
          //   // -- 却下エスカレ先
          //   // rejectEscalationTargetName: 'ここにエスカレ先が入る',
          // }),
        },

        // - CorrectApi
        res_correctionCheckDisplay: (
          await $.api_correct.correctionCheckDisplay($.applyId, CONSTANTS.CorrectionTargetType.RECEIPT)
        ).data,
        res_correctionNgList: (await $.api_correct.correctionNgList($.applyId, CONSTANTS.CorrectionTargetType.RECEIPT))
          .data,
        res_answerAndNgHistoryDetail: (
          await $.api_correct.answerAndNgHistoryDetail($.applyId, CONSTANTS.CorrectionTargetType.RECEIPT)
        ).data,
        res_escalationInfoDisplay: (
          await $.api_correct.escalationInfoDisplay($.applyId, CONSTANTS.CorrectionTargetType.RECEIPT)
        ).data,
        res_applyInfoDisplay: applyInfoDisplayResponse,
        res_rejectInfoDisplay: (await $.api_correct.rejectInfoDisplay($.applyId)).data,
        res_receiptInfoDisplay: (await $.api_correct.receiptInfoDisplay($.applyId)).data,
        res_enqueteDisplay: (await $.api_correct.enqueteDisplay($.applyId)).data,
        res_correctionHistoryDisplay: (await $.api_correct.correctionHistoryDisplay($.applyId)).data,
        // - NoteApi
        res_noteDisplay: (await $.api_note.noteDisplay($.applyId)).data,
        enum_noteVisibleRangeType: (await $.api_note.noteVisibleRangeTypeList()).data,
        enum_noteVisibleTargetType: (await $.api_note.noteVisibleTargetTypeList()).data,
        res_noteTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.RECEIPT_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.NOTE,
          })
        ).data,
        // - TemplateApi
        res_ngTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.RECEIPT_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.NG,
          })
        ).data,
        res_rejectTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.RECEIPT_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.REJECT_REQUEST_MESSAGE_TO_CUSTOMER,
          })
        ).data,
        res_escalationTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.RECEIPT_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.ESCALATION,
          })
        ).data,
        res_answerTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.RECEIPT_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.ESCALATION_ANSWER,
          })
        ).data,
        res_correctionStatus: (await $.api_correct.correctionStatusDisplay($.applyId)).data,
        // 以下、テスト用のツール。自動で各チェックが通ったことになり、OKフォームが表示される
        // receiptForm_tellCheck: { result: true, checkbox: true },
        // receiptForm_dateCheck: {
        //   result: true,
        //   date: new Date().toISOString().split('T')[0],
        //   checkbox: true,
        // },
        // receiptForm_receiptCheck: { result: true, checkbox: true },
        // isResultModal: true,
        // resultFormMode: ResultModalMode.OK,
        // ここまでテスト用のツール
      });
    })();
  }, [$.api_correct, $.api_correctReceipt, $.api_note, $.api_correctionTemplate, mergeStateWithRender, $.applyId]);

  // -----------------------------------------------------------------------------

  // FIXME: [添削] バックエンドのデバッグ用
  useEffect(() => {
    debugLogCurrentUserDataForBackend({ currentUser, isAuthChecking });
  }, [currentUser, isAuthChecking]);

  debugLog('再描画された', $, { ...CONSTANTS });

  return (
    <CorrectionProvider largeState={largeState}>
      <NoteModal />
      <ResultModal />
      <NGHistoryModal />
      <EnqueteModal />
      <ReceiptInfoModal />
      <OkReleaseModal />
      <CorrectionTemplate>
        <CorrectStatusDisplay />
        <HeaderNav />
        <ReceiptContents />
      </CorrectionTemplate>
    </CorrectionProvider>
  );
});
