import React from 'react';
import { Row, Col, Breadcrumb } from 'react-bootstrap';

export const BreadCrumbLayout = () => {
  return (
    <>
      <Row className="border-bottom">
        <Col className="px-4 py-2">
          <Breadcrumb className="d-flex align-items-center">
            {/* <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
            <Breadcrumb.Item href="https://getbootstrap.com/docs/4.0/components/breadcrumb/">Library</Breadcrumb.Item>
            <Breadcrumb.Item active>Data</Breadcrumb.Item> */}
          </Breadcrumb>
        </Col>
      </Row>
    </>
  );
};
