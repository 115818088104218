import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { ShoppingApi } from '../../../api-client';
import type { AutoCorrectMonitorOutputResponse } from '../../../api-client';
import { when } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import { CorrectDataInputTargetListTable } from '../../organisms/Table/CorrectDataInputTargetListTable';

export interface CorrectDataInputTargetListState {
  shoppingApi: ShoppingApi;
  isLoading: boolean;
  output: AutoCorrectMonitorOutputResponse;
}

export type TestIds = 'ok-button' | 'draft-button' | 'submit-button';

export const CorrectDataInputTargetListPage: React.FC = () => {
  const initialOutput: AutoCorrectMonitorOutputResponse = {
    escalationAnswerFlg: false,
    monitorList: [],
    total: 0,
  };

  const { state: $, mergeState } = useLargeState<CorrectDataInputTargetListState>({
    shoppingApi: new ShoppingApi(),
    isLoading: false,
    output: initialOutput,
  });

  const history = useHistory();

  // 1ページの最大数（指定しなくてもバックエンド側でデフォルト値[50]が適用される)
  const MAX_RECORD_PER_PAGE = 50;

  const [pageCount, setPageCount] = useState<number>(0);
  const [page, setPage] = useState(0);

  useEffect(() => {
    onSearch();
  }, [history, mergeState, $.shoppingApi, page]);

  const onSearch = () => {
    mergeState({ isLoading: true });
    $.shoppingApi
      .autoCorrectMonitorList(page, MAX_RECORD_PER_PAGE)
      .then((res: AxiosResponse<AutoCorrectMonitorOutputResponse>) => {
        setPageCount(res.data.total / MAX_RECORD_PER_PAGE);
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          history.push(Url.COMMON_ERROR);
        } else {
          mergeState({
            output: res.data,
          });
        }
      })
      .then(() => {
        mergeState({ isLoading: false });
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  };

  const handlePageChange = (selectedPage: number) => {
    setPage(selectedPage);
  };

  return (
    <>
      <LoadingSpinner isLoading={$.isLoading}>
        <h4 className="my-5">データ入力案件一覧</h4>
        {when(
          $.output.monitorList.length > 0,
          <CorrectDataInputTargetListTable
            escalationAnswerFlg={$.output.escalationAnswerFlg}
            list={$.output.monitorList}
          />
        )}
        {when(
          $.output.monitorList.length > 0,
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={(selected) => handlePageChange(selected.selected)}
            activeClassName="active"
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        )}
      </LoadingSpinner>
    </>
  );
};
