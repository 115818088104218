import React, { useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { createTestId } from '../../../utils/functions';
/* eslint-disable import/no-cycle */
import { ApplicationInfoListApi, ApplicationInfoListOutputResponse } from '../../../api-client';
import { TITLE } from '../../../constants/Title';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { Title } from '../../atoms/Title';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { ApplicationTable } from '../../organisms/Table/ApplicationTable';
import { Url } from '../../../constants/Url';
/* eslint-enable import/no-cycle */

interface SearchParams {
  clientKeyword: string | undefined;
  roiMemberName: string | undefined;
  contractStartDateFrom: string | undefined;
  contractStartDateTo: string | undefined;
  contractEndDateFrom: string | undefined;
  contractEndDateTo: string | undefined;
  activeFlg: boolean;
}

export type State = {
  api: ApplicationInfoListApi;
  searchParams: SearchParams;
  currentClient: { clientId?: number; applicationId?: number; isRequested?: boolean };
  list: ApplicationInfoListOutputResponse['applicationList'];
  listPerPage: ApplicationInfoListOutputResponse['applicationList'];
  currentPage: number;
  totalPage: number;
};

export const REQUEST_STATUS = ['未依頼', '依頼中', '依頼済み'] as const;
const MAX_RECORD_PER_PAGE = 10;

export const ApplicationPage: React.VFC = () => {
  const testid = createTestId(ApplicationPage);

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    api: new ApplicationInfoListApi(),
    searchParams: {
      clientKeyword: undefined,
      roiMemberName: undefined,
      contractStartDateFrom: undefined,
      contractStartDateTo: undefined,
      contractEndDateFrom: undefined,
      contractEndDateTo: undefined,
      activeFlg: false,
    },
    currentClient: {},
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  });

  const history = useHistory();

  const setPagingStates = useCallback(
    (list: ApplicationInfoListOutputResponse['applicationList']) => {
      const sortList = list.sort((a, b) => b.applicationId - a.applicationId);
      mergeState({
        list: sortList,
        listPerPage: sortList.slice(0, MAX_RECORD_PER_PAGE),
        totalPage: Math.ceil(Number(sortList?.length) / MAX_RECORD_PER_PAGE),
        currentPage: 1,
        currentClient:
          sortList.length !== 0
            ? {
                clientId: sortList[0].clientId,
                applicationId: sortList[0].applicationId,
                isRequested: sortList[0].postingRequestStatus === REQUEST_STATUS.indexOf('依頼済み'),
              }
            : {},
      });
    },
    [mergeState]
  );

  useEffect(() => {
    $.api.applicationInfoList().then((res: AxiosResponse<ApplicationInfoListOutputResponse>) => {
      const { applicationList: list } = res.data;
      setPagingStates(list);
    });
  }, [$.api, setPagingStates]);

  const onSearch = () => {
    const searchparam: SearchParams = {
      clientKeyword: $.searchParams.clientKeyword || undefined,
      roiMemberName: $.searchParams.roiMemberName || undefined,
      contractStartDateFrom: $.searchParams.contractStartDateFrom || undefined,
      contractStartDateTo: $.searchParams.contractStartDateTo || undefined,
      contractEndDateFrom: $.searchParams.contractEndDateFrom || undefined,
      contractEndDateTo: $.searchParams.contractEndDateTo || undefined,
      activeFlg: $.searchParams.activeFlg,
    };
    $.api
      .applicationInfoList(...Object.values(searchparam))
      .then((res: AxiosResponse<ApplicationInfoListOutputResponse>) => {
        const { applicationList: list } = res.data;
        setPagingStates(list);
      });
  };

  return (
    <>
      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.APPLICATION}
      </Title>

      <div className="d-flex justify-content-end mb-4">
        {$.currentClient.applicationId && (
          <Button
            variant="link"
            data-testid={testid('change-link')}
            onClick={() => {
              history.push({
                pathname: Url.KEISAI.POSTING_REQUEST,
                state: { id: $.currentClient.applicationId, applicationFormFlg: false },
              });
            }}
          >
            掲載変更依頼
          </Button>
        )}

        {$.currentClient.applicationId && (
          <Button
            variant="link"
            data-testid={testid('input-link')}
            onClick={() => {
              history.push({
                pathname: Url.KEISAI.POSTING_REQUEST,
                state: { id: $.currentClient.applicationId, applicationFormFlg: true },
              });
            }}
          >
            掲載依頼入力
          </Button>
        )}

        <Button variant="link" data-testid={testid('enquete-link')} onClick={() => history.push(Url.KEISAI.ENQUETE)}>
          アンケート作成
        </Button>
      </div>

      <Form.Group className="mb-4">
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="クライアント名/ID" data-testid={testid('clientKeyword')}>
              <Form.Control
                type="text"
                placeholder="クライアント名/ID"
                value={$.searchParams.clientKeyword || ''}
                onChange={(e) => mergeState({ searchParams: { ...$.searchParams, clientKeyword: e.target.value } })}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="担当営業" data-testid={testid('roiMemberName')}>
              <Form.Control
                type="text"
                placeholder="担当営業"
                value={$.searchParams.roiMemberName || ''}
                onChange={(e) => mergeState({ searchParams: { ...$.searchParams, roiMemberName: e.target.value } })}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="契約期間開始年月日from" data-testid={testid('contractStartDateFrom')}>
              <Form.Control
                type="date"
                placeholder="契約期間開始年月日from"
                value={$.searchParams.contractStartDateFrom || ''}
                onChange={(e) =>
                  mergeState({
                    searchParams: { ...$.searchParams, contractStartDateFrom: e.target.value },
                  })
                }
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="契約期間開始年月日to" data-testid={testid('contractStartDateTo')}>
              <Form.Control
                type="date"
                placeholder="契約期間開始年月日to"
                value={$.searchParams.contractStartDateTo || ''}
                onChange={(e) =>
                  mergeState({
                    searchParams: { ...$.searchParams, contractStartDateTo: e.target.value },
                  })
                }
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="契約期間終了年月日from" data-testid={testid('contractEndDateFrom')}>
              <Form.Control
                type="date"
                placeholder="契約期間終了年月日from"
                value={$.searchParams.contractEndDateFrom || ''}
                onChange={(e) =>
                  mergeState({
                    searchParams: { ...$.searchParams, contractEndDateFrom: e.target.value },
                  })
                }
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="契約期間終了年月日to" data-testid={testid('contractEndDateTo')}>
              <Form.Control
                type="date"
                placeholder="契約期間終了年月日to"
                value={$.searchParams.contractEndDateTo || ''}
                onChange={(e) =>
                  mergeState({
                    searchParams: { ...$.searchParams, contractEndDateTo: e.target.value },
                  })
                }
              />
            </FloatingLabel>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Check id="activeFlg">
              <Form.Check.Input
                className="me-2"
                type="checkbox"
                data-testid={testid('activeFlg')}
                checked={$.searchParams.activeFlg}
                onChange={() =>
                  mergeState({
                    searchParams: { ...$.searchParams, activeFlg: !$.searchParams.activeFlg },
                  })
                }
              />
              <Form.Check.Label>有効な契約のみ表示する</Form.Check.Label>
            </Form.Check>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center">
          <Col className="col-4">
            <Button className="w-100 py-3" data-testid={testid('search-button')} onClick={onSearch}>
              検索
            </Button>
          </Col>
        </Row>
      </Form.Group>

      <ApplicationTable
        listPerPage={$.listPerPage}
        currentClient={$.currentClient}
        setCurrentClient={useBindSet('currentClient')}
      />

      <div className="mt-4" data-testid={testid('pagination')}>
        <PaginationWithEllipsis
          currentPage={$.currentPage}
          totalPage={$.totalPage}
          handleClick={(page) => {
            if (!page || page > $.totalPage) return;
            mergeState({
              listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
              currentPage: page,
            });
          }}
        />
      </div>
    </>
  );
};
