import { Form } from 'react-bootstrap';

interface Props {
  label: string;
  name: string;
  value?: string;
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  isInvalid?: boolean;
}

export const PasswordForm: React.FC<Props> = ({ label, name, value, isInvalid = false, setValue }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        name={name}
        type="password"
        value={value}
        isInvalid={isInvalid}
        onChange={(e) => setValue(e.target.value)}
      />
    </Form.Group>
  );
};
