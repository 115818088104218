import { faLink, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleVisitTimeSetOutputTimeInfoResponse,
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoMonitorRuleVisitTimeSetOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { when } from '../../utils/functions';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「時間」Contents
export const MonitorRuleSetTimeContents: React.VFC<Props> = ({
  data,
  setData,
  index,
  rowData,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  // 入店時間・退店時間テキスト有効無効化フラグ
  const [radioDisableFlg, setRadioDisableFlg] = useState<boolean>(false);
  // 最低滞在時間指定テキスト有効無効化フラグ
  const [checkDisableFlg, setCheckDisableFlg] = useState<boolean>(false);

  useEffect(() => {
    setRadioDisableFlg(data.monitorRuleList.find((obj) => obj.monitorRuleType === 3)?.visitTimeSetList.length === 0);
    setCheckDisableFlg(!!data.monitorRuleList.find((obj) => obj.monitorRuleType === 3)?.stayingTime);
  }, [data.monitorRuleList]);

  // 「HH:mm」形式変換関数
  const formatISO = (ISODate: Date | undefined) => {
    if (ISODate === undefined) return;

    return new Intl.DateTimeFormat('ja-jp', {
      hour: '2-digit',
      minute: '2-digit',
    }).format(ISODate);
  };

  // 桁数を判定する関数
  const numDigits = (num: number | undefined) => {
    if (num || num === 0) {
      if (num.toString().length === 1) {
        return `00${num}`.slice(-2);
      }
    }
    return num;
  };

  return (
    <>
      <div className="d-flex ">
        <Form.Label className="d-block" style={{ fontWeight: 'bold' }}>
          入退店時間指定
        </Form.Label>
      </div>
      <Form.Check id="radio1" className="d-flex">
        <Form.Check.Input
          data-testid="unspecifiedTimeRadio"
          className="flex-shrink-0 me-2"
          type="radio"
          name="radioGroup01"
          onChange={() => {
            setRadioDisableFlg(true);
          }}
          onClick={() => {
            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((row) => {
                return row.monitorRuleType === 3
                  ? {
                      ...row,
                      visitTimeSetList: [],
                    }
                  : { ...row };
              }),
            });
          }}
          checked={radioDisableFlg}
        />
        <Form.Check.Label>指定なし</Form.Check.Label>
      </Form.Check>
      <Form.Group>
        <Form.Check id="radio2" className="d-flex">
          <Form.Check.Input
            data-testid="specifyTimeRadio"
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup01"
            onClick={() => {
              setRadioDisableFlg(false);
            }}
            onChange={() => {}}
            checked={!radioDisableFlg}
          />
          <Form.Check.Label>調査時間範囲を指定</Form.Check.Label>
        </Form.Check>
      </Form.Group>
      {data.monitorRuleList
        .find((r) => {
          return r.monitorRuleType === 3;
        })
        ?.visitTimeSetList?.map((_, i) => {
          return (
            <div key={i.toString()} className="d-flex align-items-center ">
              <Col className="col-5">
                <Form.Group className="d-flex align-items-center ms-5 mt-3" controlId="readOnly1">
                  <Form.Label className="mt-2 me-2">入店時間</Form.Label>
                  <Col>
                    <Form.Control
                      data-testid="enteringTimeText"
                      disabled={radioDisableFlg}
                      type="time"
                      value={`${numDigits(_.timeFrom?.hour)}:${numDigits(_.timeFrom?.minute)}`}
                      onChange={(e) => {
                        const newDate = new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          new Date().getDay(),
                          Number(e.target.value.substring(0, 2)),
                          Number(e.target.value.substring(3))
                        );

                        const time: MonitorRuleSetInfoMonitorRuleVisitTimeSetOutputTimeInfoResponse = {
                          hour: Number(e.target.value.substring(0, 2)),
                          minute: Number(e.target.value.substring(3)),
                        };

                        setData({
                          ...data,
                          monitorRuleList: data?.monitorRuleList.map((row, ind) => {
                            return row.monitorRuleType === 3
                              ? {
                                  ...row,
                                  visitTimeSetList: row.visitTimeSetList?.map((r, c) => {
                                    return c === i
                                      ? { ...r, timeFrom: e.target.value === '' ? undefined : time }
                                      : { ...r };
                                  }),
                                }
                              : { ...row };
                          }),
                        });
                      }}
                    />
                  </Col>
                  <Form.Label className="mt-2 me-2 ms-2">～</Form.Label>
                  <Form.Label className="mt-2 me-2">退店時間</Form.Label>
                  <Col>
                    <Form.Control
                      data-testid="exitTimeText"
                      disabled={radioDisableFlg}
                      type="time"
                      value={`${numDigits(_.timeTo?.hour)}:${numDigits(_.timeTo?.minute)}`}
                      onChange={(e) => {
                        const newDate = new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          new Date().getDay(),
                          Number(e.target.value.substring(0, 2)),
                          Number(e.target.value.substring(3))
                        );

                        const time: MonitorRuleSetInfoMonitorRuleVisitTimeSetOutputTimeInfoResponse = {
                          hour: Number(e.target.value.substring(0, 2)),
                          minute: Number(e.target.value.substring(3)),
                        };

                        setData({
                          ...data,
                          monitorRuleList: data?.monitorRuleList.map((row, ind) => {
                            return row.monitorRuleType === 3
                              ? {
                                  ...row,
                                  visitTimeSetList: row.visitTimeSetList?.map((r, c) => {
                                    return c === i
                                      ? { ...r, timeTo: e.target.value === '' ? undefined : time }
                                      : { ...r };
                                  }),
                                }
                              : { ...row };
                          }),
                        });
                      }}
                    />
                  </Col>
                  {i === 0 && (
                    <Col>
                      <Button
                        data-testid="timesAddButton"
                        variant="link"
                        className="text-secondary"
                        disabled={radioDisableFlg}
                        onClick={() => {
                          const newTimeSetList = Object.assign(
                            [],
                            data.monitorRuleList.find((r) => {
                              return r.monitorRuleType === 3;
                            })?.visitTimeSetList
                          );
                          newTimeSetList.push({
                            timeFrom: undefined,
                            timeTo: undefined,
                          });

                          setData({
                            ...data,
                            monitorRuleList: data?.monitorRuleList.map((row, ind) => {
                              return row.monitorRuleType === 3
                                ? { ...row, visitTimeSetList: newTimeSetList }
                                : { ...row };
                            }),
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                      </Button>
                    </Col>
                  )}
                </Form.Group>
              </Col>
            </div>
          );
        })}
      {when(
        data.monitorRuleList.find((r) => r.monitorRuleType === 3)?.visitTimeSetList.length === 0,
        <Button
          data-testid="timesAddButton"
          variant="link"
          className="text-secondary mt-3"
          disabled={radioDisableFlg}
          onClick={() => {
            const newTimeSetList = Object.assign(
              [],
              data.monitorRuleList.find((r) => {
                return r.monitorRuleType === 3;
              })?.visitTimeSetList
            );
            newTimeSetList.push({
              timeFrom: undefined,
              timeTo: undefined,
            });

            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((row) => {
                return row.monitorRuleType === 3 ? { ...row, visitTimeSetList: newTimeSetList } : { ...row };
              }),
            });
          }}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
        </Button>
      )}
      <Form.Group className="mt-4 mb-4">
        <Form.Label className="d-block" style={{ fontWeight: 'bold' }}>
          滞在時間指定
        </Form.Label>
        <Form.Group className="d-flex">
          <Form.Check className="pt-2" id="checkBox1">
            <Form.Check.Label>指定あり</Form.Check.Label>
            <Form.Check.Input
              data-testid="stayingTimeCheckBox"
              className="flex-shrink-0 ms-2 me-3"
              type="checkbox"
              name="checkBoxGroup01"
              checked={checkDisableFlg}
              onChange={() => {
                if (checkDisableFlg) {
                  setData({
                    ...data,
                    monitorRuleList: data?.monitorRuleList.map((row) => {
                      return row.monitorRuleType === 3
                        ? {
                            ...row,
                            stayingTime: undefined,
                          }
                        : { ...row };
                    }),
                  });
                }
                setCheckDisableFlg(!checkDisableFlg);
              }}
            />
          </Form.Check>
          <Form.Group className="d-flex align-items-center" controlId="readOnly1">
            <Form.Label className="pe-2 mb-0">最低滞在時間</Form.Label>
            <Col className="me-2">
              <Form.Control
                data-testid="stayingTimeText"
                type="number"
                placeholder="テキスト入力"
                disabled={!checkDisableFlg}
                value={
                  data.monitorRuleList.find((r) => {
                    return r.monitorRuleType === 3;
                  })?.stayingTime || ''
                }
                onChange={(e) => {
                  setData({
                    ...data,
                    monitorRuleList: data?.monitorRuleList.map((row, ind) => {
                      return row.monitorRuleType === 3
                        ? {
                            ...row,
                            stayingTime: Number.isNaN(parseInt(e.target.value, 10))
                              ? undefined
                              : parseInt(e.target.value, 10),
                          }
                        : { ...row };
                    }),
                  });
                }}
              />
            </Col>
            分以上
          </Form.Group>
          <Button
            className="ms-5"
            variant="secondary"
            onClick={() => {
              let reflectionText = '';
              const visitTimeSetList: MonitorRuleSetInfoMonitorRuleVisitTimeSetOutputResponse[] = Object.assign(
                [],
                data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 3;
                })?.visitTimeSetList
              );
              const stayingTime: number | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 3;
              })?.stayingTime;
              if (visitTimeSetList) {
                for (let i = 0; i < visitTimeSetList.length; i += 1) {
                  // 入店時間 ✕ 退店時間 ✕
                  // if(visitTimeSetList[i].timeFrom === undefined && visitTimeSetList[i].timeTo === undefined ){
                  //   continue ;
                  // }
                  // 入店時間 〇 退店時間 〇
                  if (visitTimeSetList[i].timeFrom !== undefined && visitTimeSetList[i].timeTo !== undefined) {
                    // reflectionText === "" ?  reflectionText = "" : reflectionText = `${reflectionText}（祝日を除く）`;
                    if (reflectionText === '') {
                      reflectionText = `${visitTimeSetList[i].timeFrom?.hour}:${numDigits(
                        visitTimeSetList[i].timeFrom?.minute
                      )} ～ ${visitTimeSetList[i].timeTo?.hour}:${numDigits(visitTimeSetList[i].timeTo?.minute)} の間`;
                    } else {
                      reflectionText = `${reflectionText}、または ${visitTimeSetList[i].timeFrom?.hour}:${numDigits(
                        visitTimeSetList[i].timeFrom?.minute
                      )} ～ ${numDigits(visitTimeSetList[i].timeTo?.hour)}:${numDigits(
                        visitTimeSetList[i].timeTo?.minute
                      )} の間`;
                    }
                  }
                  // 入店時間 〇 退店時間 ✕
                  if (visitTimeSetList[i].timeFrom !== undefined && visitTimeSetList[i].timeTo === undefined) {
                    if (reflectionText === '') {
                      reflectionText = `${numDigits(visitTimeSetList[i].timeFrom?.hour)}:${numDigits(
                        visitTimeSetList[i].timeFrom?.minute
                      )} 以降`;
                    } else {
                      reflectionText = `${reflectionText}、または ${visitTimeSetList[i].timeFrom?.hour}:${numDigits(
                        visitTimeSetList[i].timeFrom?.minute
                      )} 以降`;
                    }
                  }
                  // 入店時間 ✕ 退店時間 〇
                  if (visitTimeSetList[i].timeFrom === undefined && visitTimeSetList[i].timeTo !== undefined) {
                    if (reflectionText === '') {
                      reflectionText = `${visitTimeSetList[i].timeTo?.hour}:${numDigits(
                        visitTimeSetList[i].timeTo?.minute
                      )} まで`;
                    } else {
                      reflectionText = `${reflectionText}、または ${visitTimeSetList[i].timeTo?.hour}:${numDigits(
                        visitTimeSetList[i].timeTo?.minute
                      )} まで`;
                    }
                  }
                }
                if (reflectionText === '') {
                  reflectionText = '営業時間内にお店に入ってください。';
                } else if (reflectionText !== '') {
                  reflectionText = `${reflectionText}にお店に入ってください。`;
                }
              }

              let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                [],
                data.monitorRuleList.find((supplement) => {
                  return supplement.monitorRuleType === rowData.monitorRuleType;
                })?.supplementList
              );
              if (data.monitorRuleSetType === 6) {
                copySupplementList = copySupplementList.map((r, i) => {
                  return i === 0
                    ? {
                        ...r,
                        supplement: !stayingTime
                          ? '※最終受付・閉店時間は事前にご確認ください。'
                          : `※最終受付・閉店時間は事前にご確認ください。\n※${stayingTime}分以上は滞在してください。`,
                      }
                    : { ...r };
                });
              } else {
                copySupplementList = copySupplementList.map((r, i) => {
                  return i === 0
                    ? {
                        ...r,
                        supplement: !stayingTime
                          ? '※ラストオーダーや閉店時間は事前にご確認ください。'
                          : `※ラストオーダーや閉店時間は事前にご確認ください。\n※${stayingTime}分以上は滞在してください。`,
                      }
                    : { ...r };
                });
              }

              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 3
                    ? {
                        ...row,
                        beforeApplyContent: reflectionText,
                        afterApplyContent: reflectionText,
                        supplementList: copySupplementList,
                      }
                    : { ...row };
                }),
              });
            }}
          >
            反映
          </Button>
        </Form.Group>
      </Form.Group>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
