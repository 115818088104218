import React, { useState, Dispatch, SetStateAction, useContext } from 'react';
import { Table, Form, Card, Row } from 'react-bootstrap';
import {
  UnBillingApprovalListOutputResponse,
  UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum,
} from '../../../api-client';
import { BILLING_APPROVAL_TYPE } from '../../../BillingConstants';

export interface Props {
  row: UnBillingApprovalListOutputResponse;
  handleChangeYuuComiSuperior: (newValue: UnBillingApprovalListOutputResponse) => void;
  type: string;
}

export const YuucomiSuperiorTable: React.VFC<Props> = ({ row, handleChangeYuuComiSuperior, type }) => {
  const convertEnumValue = (v: UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum | undefined) => {
    if (v === UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum.Approve) {
      return 'Approve';
    }
    if (v === UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum.Reject) {
      return 'Reject';
    }
    return '';
  };

  return (
    <>
      <tr key={row.billingApprovalId} className="align-middle">
        <td>{row.clientName}</td>
        <td>{row.shopName}</td>
        <td>{row.applyId}</td>
        <td>
          <Form.Check.Input
            className="flex-shrink-0 me-2"
            checked={row.unBillingFlg}
            type="checkbox"
            name="unBilling"
            disabled
          />
        </td>
        <td>
          <Form.Check.Input
            className="flex-shrink-0 me-2"
            checked={row.hideAnswerFlg}
            type="checkbox"
            name="unBilling"
            disabled
          />
        </td>
        <td>
          <Form.Check.Input
            className="flex-shrink-0 me-2"
            checked={row.increaseSlotFlg}
            type="checkbox"
            name="unBilling"
            disabled
          />
        </td>
        <td>{row.reason}</td>
        <td>{row.applicant}</td>
        {Number(type) === BILLING_APPROVAL_TYPE.SALES && <td>{row.approver}</td>}
        <td>
          <Form.Select
            value={convertEnumValue(row.unBillingApprovalStatus)}
            onChange={(e) => {
              if (e.target.value === '') {
                handleChangeYuuComiSuperior({
                  ...row,
                  unBillingApprovalStatus: undefined,
                });
              } else {
                handleChangeYuuComiSuperior({
                  ...row,
                  unBillingApprovalStatus:
                    UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum[
                      e.target.value as keyof typeof UnBillingApprovalListOutputResponseUnBillingApprovalStatusEnum
                    ],
                });
              }
            }}
          >
            <option value="">&nbsp;</option>
            <option value="Approve">承認</option>
            <option value="Reject">却下</option>
          </Form.Select>
        </td>
        <td>
          <Form.Control
            type="text"
            value={row.rejectComment ?? ''}
            onChange={(e) => {
              handleChangeYuuComiSuperior({
                ...row,
                rejectComment: e.target.value,
              });
            }}
          />
        </td>
      </tr>
    </>
  );
};
