import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';
import { MonitorRuleSetOrderConditionContents } from './MonitorRuleSetOrderConditionContents';
import { MonitorRuleSetCommonContents2 } from './MonitorRuleSetCommonContents2';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「調査日」Contents
export const MonitorRuleSetSurveyDateContents: React.VFC<Props> = ({
  data,
  setData,
  rowData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  // const [reflectionText, setReflectionText] = useState<string>("");

  /* eslint-disable */
  return (
    <>
      <div className="d-flex">
        <Form.Label className="d-block" style={{ fontWeight: 'bold' }}>
          来店日
        </Form.Label>
      </div>
      <div className="d-flex justify-content-start mb-4 ">
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="monday"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2 ? { ...row, mondayVisitsFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 2;
              })?.mondayVisitsFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 2;
                  })?.mondayVisitsFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="monday">
            月
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="tuesday"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2 ? { ...row, tuesdayVisitsFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 2;
              })?.tuesdayVisitsFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 2;
                  })?.tuesdayVisitsFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="tuesday">
            火
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="wednesday"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2 ? { ...row, wednesdayVisitsFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 2;
              })?.wednesdayVisitsFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 2;
                  })?.wednesdayVisitsFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="wednesday">
            水
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="thursday"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2 ? { ...row, thursdayVisitsFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 2;
              })?.thursdayVisitsFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 2;
                  })?.thursdayVisitsFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="thursday">
            木
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="friday"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2 ? { ...row, fridayVisitsFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 2;
              })?.fridayVisitsFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 2;
                  })?.fridayVisitsFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="friday">
            金
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="saturday"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2 ? { ...row, saturdayVisitsFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 2;
              })?.saturdayVisitsFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 2;
                  })?.saturdayVisitsFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="saturday">
            土
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="sunday"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2 ? { ...row, sundayVisitsFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 2;
              })?.sundayVisitsFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 2;
                  })?.sundayVisitsFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="sunday">
            日
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="holiday"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2 ? { ...row, publicHolidayVisitsFlg: e.target.checked } : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 2;
              })?.publicHolidayVisitsFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 2;
                  })?.publicHolidayVisitsFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="holiday">
            祝日
          </label>
        </div>
        <div className="me-3">
          <input
            type="checkbox"
            className="btn-check"
            id="preHoliday"
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2
                    ? { ...row, celebrationPreviousDayVisitsFlg: e.target.checked }
                    : { ...row };
                }),
              });
            }}
            checked={
              data?.monitorRuleList.find((_) => {
                return _.monitorRuleType === 2;
              })?.celebrationPreviousDayVisitsFlg === undefined
                ? false
                : data?.monitorRuleList.find((_) => {
                    return _.monitorRuleType === 2;
                  })?.celebrationPreviousDayVisitsFlg || false
            }
          />
          <label className="btn btn-outline-primary " htmlFor="preHoliday">
            祝日前
          </label>
        </div>
        <div>
          <Button
            className="ms-5"
            variant="secondary"
            onClick={() => {
              const list = data?.monitorRuleList.find((_) => _.monitorRuleType === 2);
              // @ts-ignore
              const allDay = {
                0: list?.mondayVisitsFlg,
                1: list?.tuesdayVisitsFlg,
                2: list?.wednesdayVisitsFlg,
                3: list?.thursdayVisitsFlg,
                4: list?.fridayVisitsFlg,
                5: list?.saturdayVisitsFlg,
                6: list?.sundayVisitsFlg,
              };
              const format = (index: number, week2: string) => {
                let text = '';
                // let text = '';
                let flg = false;
                for (let i = index; i >= 0; i--) {
                  // @ts-ignore
                  if (allDay[i]) {
                    flg = true;
                    if (i === 5) {
                      text = `土～${week2}`;
                    }
                    if (i === 4) {
                      text = `金～${week2}`;
                    }
                    if (i === 3) {
                      text = `木～${week2}`;
                    }
                    if (i === 2) {
                      text = `水～${week2}`;
                    }
                    if (i === 1) {
                      text = `火～${week2}`;
                    }
                    if (i === 0) {
                      text = `月～${week2}`;
                    }
                  } else if (text !== '') {
                    flg = false;
                    return text;
                  }
                  if (!flg) {
                    return text;
                  }
                }
                return text;
              };
              let reflectionText = '';
              // 日曜日
              if (list?.sundayVisitsFlg) {
                const temp = format(5, '日');
                if (temp === '' && reflectionText === '') {
                  reflectionText = '日曜日';
                } else {
                  reflectionText = temp;
                }
              }
              // 土曜日
              if (list?.saturdayVisitsFlg) {
                const temp = format(4, '土');
                if (temp === '' && reflectionText === '') {
                  reflectionText = '土曜日';
                } else {
                  if (
                    reflectionText.indexOf('月') === -1 &&
                    reflectionText.indexOf('火') === -1 &&
                    reflectionText.indexOf('水') === -1 &&
                    reflectionText.indexOf('木') === -1 &&
                    reflectionText.indexOf('金') === -1 &&
                    reflectionText.indexOf('土') === -1 &&
                    temp !== ''
                  ) {
                    if (reflectionText !== '') {
                      reflectionText = `${temp}・${reflectionText}`;
                    } else {
                      reflectionText = `${temp}`;
                    }
                  }
                }
              }
              // 金曜日
              if (list?.fridayVisitsFlg) {
                const temp = format(3, '金');
                if (temp === '' && reflectionText === '') {
                  reflectionText = '金曜日';
                } else {
                  if (
                    reflectionText.indexOf('月') === -1 &&
                    reflectionText.indexOf('火') === -1 &&
                    reflectionText.indexOf('水') === -1 &&
                    reflectionText.indexOf('木') === -1 &&
                    reflectionText.indexOf('金') === -1 &&
                    temp !== ''
                  ) {
                    if (reflectionText !== '') {
                      reflectionText = `${temp}・${reflectionText}`;
                    } else {
                      reflectionText = `${temp}`;
                    }
                  } else if (reflectionText.indexOf('金') === -1 && temp === '') {
                    reflectionText = `金・${reflectionText}`;
                  }
                }
              }
              // 木曜日
              if (list?.thursdayVisitsFlg) {
                const temp = format(2, '木');
                if (temp === '' && reflectionText === '') {
                  reflectionText = '木曜日';
                } else {
                  if (
                    reflectionText.indexOf('月') === -1 &&
                    reflectionText.indexOf('火') === -1 &&
                    reflectionText.indexOf('水') === -1 &&
                    reflectionText.indexOf('木') === -1 &&
                    temp !== ''
                  ) {
                    if (reflectionText !== '') {
                      reflectionText = `${temp}・${reflectionText}`;
                    } else {
                      reflectionText = `${temp}`;
                    }
                  } else if (reflectionText.indexOf('木') === -1 && temp === '') {
                    reflectionText = `木・${reflectionText}`;
                  }
                }
              }
              // 水曜日
              if (list?.wednesdayVisitsFlg) {
                const temp = format(1, '水');
                if (temp === '' && reflectionText === '') {
                  reflectionText = '水曜日';
                } else {
                  if (
                    reflectionText.indexOf('月') === -1 &&
                    reflectionText.indexOf('火') === -1 &&
                    reflectionText.indexOf('水') === -1 &&
                    temp !== ''
                  ) {
                    if (reflectionText !== '') {
                      reflectionText = `${temp}・${reflectionText}`;
                    } else {
                      reflectionText = `${temp}`;
                    }
                  } else if (reflectionText.indexOf('水') === -1 && temp === '') {
                    reflectionText = `水・${reflectionText}`;
                  }
                }
              }
              // 火曜日
              if (list?.tuesdayVisitsFlg) {
                let temp = format(0, '火');
                if (temp === '' && reflectionText === '') {
                  reflectionText = '火曜日';
                } else {
                  if (reflectionText.indexOf('月') === -1 && reflectionText.indexOf('火') === -1 && temp !== '') {
                    if (reflectionText !== '') {
                      reflectionText = `${temp}・${reflectionText}`;
                    } else {
                      reflectionText = `${temp}`;
                    }
                  } else if (reflectionText.indexOf('火') === -1 && temp === '') {
                    reflectionText = `火・${reflectionText}`;
                  }
                }
              }
              // 月曜日
              if (list?.mondayVisitsFlg) {
                const temp = '月';
                if (reflectionText === '') {
                  reflectionText = `${temp}曜日`;
                } else {
                  if (reflectionText.indexOf('月') === -1) {
                    reflectionText = `${temp}・${reflectionText}`;
                  }
                }
              }
              // 祝日
              if (!list?.publicHolidayVisitsFlg) {
                reflectionText === '' ? (reflectionText = '') : (reflectionText = `${reflectionText}（祝日を除く）`);
              }
              // 祝日前
              if (!list?.celebrationPreviousDayVisitsFlg) {
                if (reflectionText === '') {
                  reflectionText = '稼働日なし';
                } else if (reflectionText.indexOf('祝日') !== -1) {
                  const res = reflectionText.slice(0, reflectionText.indexOf('（')) + '（祝日・祝日前を除く）';

                  reflectionText = res;
                } else {
                  reflectionText = `${reflectionText}（祝日前を除く）`;
                }
              }
              let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                [],
                data.monitorRuleList.find((supplement) => {
                  return supplement.monitorRuleType === rowData.monitorRuleType;
                })?.supplementList
              );
              copySupplementList = copySupplementList.map((r, i) => {
                return i === 0 ? { ...r, supplement: '※定休日は各店舗情報をご確認ください。' } : { ...r };
              });

              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 2
                    ? {
                        ...row,
                        beforeApplyContent: reflectionText,
                        afterApplyContent: reflectionText,
                        supplementList: copySupplementList,
                      }
                    : { ...row };
                }),
              });
            }}
          >
            反映
          </Button>
        </div>
      </div>
      {/* <Form.Group className="mt-4 mb-4">
        <Form.Label className="d-block" style={{fontWeight : "bold"}}>備考</Form.Label>
        <Form.Control type='text'></Form.Control>
      </Form.Group> */}
      <MonitorRuleSetCommonContents2
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg={true}
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
  /* eslint-disable */
};
