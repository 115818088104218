import React, { useCallback, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { Alert, Form } from 'react-bootstrap';
import { Button } from '../../../atoms/Button';
import { Modal } from '../../../molecules/Modal';
import { when } from '../../../../utils/functions';
import { useLargeState } from '../../../../hooks/useLargeState';
// eslint-disable-next-line import/no-cycle
import { ShoppingApi, CommonMasterListApi } from '../../../../api-client';
import type {
  ChainStoreMasterRegisterEditFormResponse,
  CommonMasterListOutputResponse,
  IncResultOutputResponse,
} from '../../../../api-client';
import { Url } from '../../../../constants/Url';
import { CorrectPhoneMasterEditModalForms } from '../../Forms/CorrectPhoneMasterEditModalForms';

interface Props {
  isModal: boolean;
  info?: ChainStoreMasterRegisterEditFormResponse;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide: () => any;
  handleSearchCorrectPhoneMaster: () => void;
}

export interface ModalState {
  api: ShoppingApi;
  commonApi: CommonMasterListApi;
  forms: ChainStoreMasterRegisterEditFormResponse;
  prefectureList: CommonMasterListOutputResponse[];
  errorMessage: string;
}

export const CorrectPhoneMasterEditModal: React.FC<Props> = ({
  isModal,
  onHide,
  info,
  handleSearchCorrectPhoneMaster,
}) => {
  const {
    state: $modal,
    useBindSet,
    mergeState,
  } = useLargeState<ModalState>({
    api: new ShoppingApi(),
    commonApi: new CommonMasterListApi(),
    forms: {
      chainName: info?.chainName || '',
      storeName: info?.storeName || '',
      prefectureId: info?.prefectureId || -1,
      storePhoneNumber: info?.storePhoneNumber || '',
      chainStoreMasterId:
        !!info?.chainStoreMasterId && info?.chainStoreMasterId > 0 ? info?.chainStoreMasterId : undefined,
    },
    prefectureList: [],
    errorMessage: '',
  });

  const setForms = useBindSet('forms');

  const history = useHistory();

  useEffect(() => {
    const PREFECTURE_KEY = 'PREFECTURE';

    $modal.commonApi
      .commonMasterList(PREFECTURE_KEY)
      .then((res: AxiosResponse<Array<CommonMasterListOutputResponse>>) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          history.push(Url.COMMON_ERROR);
        } else {
          mergeState({ prefectureList: res.data });
        }
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (body: ChainStoreMasterRegisterEditFormResponse) => {
      const notFill = ![body.chainName, body.storePhoneNumber, body.storeName].every((data) => data);
      const notSelect = !(body.prefectureId && body.prefectureId >= 0);
      if (notFill || notSelect) {
        mergeState({ errorMessage: '入力されていない項目があります' });
        return;
      }
      $modal.api
        .chainStoreMasterRegisterEdit(body)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (res.data.result) {
            handleSearchCorrectPhoneMaster();
            onHide();
            // history.go(0);
          } else {
            history.push(Url.COMMON_ERROR);
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    },
    [$modal.api, history, mergeState, onHide]
  );

  return (
    <>
      <Modal
        closeButton
        centered
        scrollable
        size="lg"
        isModal={isModal}
        onHide={onHide}
        title="追加・編集"
        body={
          <>
            {when($modal.errorMessage.length > 0, <Alert variant="danger">{$modal.errorMessage}</Alert>)}
            <Form>
              <CorrectPhoneMasterEditModalForms
                forms={$modal.forms}
                setForms={setForms}
                prefectureList={$modal.prefectureList}
              />
            </Form>
            <div className="d-flex justify-content-end mt-2">
              <Button className="me-2" variant="outline-danger" onClick={onHide}>
                キャンセル
              </Button>
              <Button className="me-2" variant="primary" onClick={() => onSubmit($modal.forms)}>
                更新
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
