import React from 'react';
// import '../../App.css';
import { Card, CardGroup, Col, Container, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
// import MainTemplate from '../templates/MainTemplate';
import Head from '../../organisms/Head';

// サンプル
const SamplePage: React.FC = () => {
  // 認証情報取得
  const { currentUser, isAuthChecking } = useCurrentUser();
  if (isAuthChecking) return <div>ログイン情報を確認中…</div>;

  if (!currentUser) return <div>ログインしていません</div>;
  return (
    // <MainTemplate>
    <div>
      <Head
        title="マイページテスト"
        description="マイページテスト説明文"
        keywords="aaa,bbb,ccc"
        thumbnail="http://test.com/img.png"
        url="http://test.com"
        noindex="noindex"
      />
      <div className="app-body c-default-layout flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="4">
              <CardGroup>
                <Card className="p-4">
                  <Card.Body />
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="app">
        <div className="mt-20 block-card paper-root paper-elevation3">
          <h3 className="text-center center">{currentUser.name}</h3>
        </div>
        <div className="mt-20 block-card paper-root paper-elevation3" />
      </div>
    </div>
    // </MainTemplate>
  );
};
export default SamplePage;
