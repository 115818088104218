/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ReceiptPriceInfoSearchOutputResponse } from '../domains';
// @ts-ignore
import { ReceiptPriceModifyingDisplayFormResponse } from '../domains';
// @ts-ignore
import { ReceiptPriceModifyingDisplayOutputResponse } from '../domains';
// @ts-ignore
import { ReceiptPriceModifyingUpdateFormResponse } from '../domains';
// @ts-ignore
import { ReceiptPriceModifyingUpdateOutputResponse } from '../domains';
/**
 * ReceiptPriceApi - axios parameter creator
 * @export
 */
export const ReceiptPriceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary レシート金額修正画面表示API
         * @param {ReceiptPriceModifyingDisplayFormResponse} receiptPriceModifyingDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptPriceModifyingDisplay: async (receiptPriceModifyingDisplayFormResponse: ReceiptPriceModifyingDisplayFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptPriceModifyingDisplayFormResponse' is not null or undefined
            assertParamExists('receiptPriceModifyingDisplay', 'receiptPriceModifyingDisplayFormResponse', receiptPriceModifyingDisplayFormResponse)
            const localVarPath = `/api/receipt-price/modify/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptPriceModifyingDisplayFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 承認金額修正時レシート情報検索API
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptPriceModifyingReceiptInfoSearch: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('receiptPriceModifyingReceiptInfoSearch', 'applyId', applyId)
            const localVarPath = `/api/receipt-price/search/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary レシート金額修正情報保存API
         * @param {ReceiptPriceModifyingUpdateFormResponse} receiptPriceModifyingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptPriceModifyingUpdate: async (receiptPriceModifyingUpdateFormResponse: ReceiptPriceModifyingUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptPriceModifyingUpdateFormResponse' is not null or undefined
            assertParamExists('receiptPriceModifyingUpdate', 'receiptPriceModifyingUpdateFormResponse', receiptPriceModifyingUpdateFormResponse)
            const localVarPath = `/api/receipt-price/modify/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptPriceModifyingUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReceiptPriceApi - functional programming interface
 * @export
 */
export const ReceiptPriceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReceiptPriceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary レシート金額修正画面表示API
         * @param {ReceiptPriceModifyingDisplayFormResponse} receiptPriceModifyingDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptPriceModifyingDisplay(receiptPriceModifyingDisplayFormResponse: ReceiptPriceModifyingDisplayFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptPriceModifyingDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptPriceModifyingDisplay(receiptPriceModifyingDisplayFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 承認金額修正時レシート情報検索API
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptPriceModifyingReceiptInfoSearch(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptPriceInfoSearchOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptPriceModifyingReceiptInfoSearch(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary レシート金額修正情報保存API
         * @param {ReceiptPriceModifyingUpdateFormResponse} receiptPriceModifyingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptPriceModifyingUpdate(receiptPriceModifyingUpdateFormResponse: ReceiptPriceModifyingUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptPriceModifyingUpdateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptPriceModifyingUpdate(receiptPriceModifyingUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReceiptPriceApi - factory interface
 * @export
 */
export const ReceiptPriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReceiptPriceApiFp(configuration)
    return {
        /**
         * 
         * @summary レシート金額修正画面表示API
         * @param {ReceiptPriceModifyingDisplayFormResponse} receiptPriceModifyingDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptPriceModifyingDisplay(receiptPriceModifyingDisplayFormResponse: ReceiptPriceModifyingDisplayFormResponse, options?: any): AxiosPromise<ReceiptPriceModifyingDisplayOutputResponse> {
            return localVarFp.receiptPriceModifyingDisplay(receiptPriceModifyingDisplayFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 承認金額修正時レシート情報検索API
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptPriceModifyingReceiptInfoSearch(applyId: number, options?: any): AxiosPromise<ReceiptPriceInfoSearchOutputResponse> {
            return localVarFp.receiptPriceModifyingReceiptInfoSearch(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary レシート金額修正情報保存API
         * @param {ReceiptPriceModifyingUpdateFormResponse} receiptPriceModifyingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptPriceModifyingUpdate(receiptPriceModifyingUpdateFormResponse: ReceiptPriceModifyingUpdateFormResponse, options?: any): AxiosPromise<ReceiptPriceModifyingUpdateOutputResponse> {
            return localVarFp.receiptPriceModifyingUpdate(receiptPriceModifyingUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReceiptPriceApi - object-oriented interface
 * @export
 * @class ReceiptPriceApi
 * @extends {BaseAPI}
 */
export class ReceiptPriceApi extends BaseAPI {
    /**
     * 
     * @summary レシート金額修正画面表示API
     * @param {ReceiptPriceModifyingDisplayFormResponse} receiptPriceModifyingDisplayFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptPriceApi
     */
    public receiptPriceModifyingDisplay(receiptPriceModifyingDisplayFormResponse: ReceiptPriceModifyingDisplayFormResponse, options?: AxiosRequestConfig) {
        return ReceiptPriceApiFp(this.configuration).receiptPriceModifyingDisplay(receiptPriceModifyingDisplayFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 承認金額修正時レシート情報検索API
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptPriceApi
     */
    public receiptPriceModifyingReceiptInfoSearch(applyId: number, options?: AxiosRequestConfig) {
        return ReceiptPriceApiFp(this.configuration).receiptPriceModifyingReceiptInfoSearch(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary レシート金額修正情報保存API
     * @param {ReceiptPriceModifyingUpdateFormResponse} receiptPriceModifyingUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReceiptPriceApi
     */
    public receiptPriceModifyingUpdate(receiptPriceModifyingUpdateFormResponse: ReceiptPriceModifyingUpdateFormResponse, options?: AxiosRequestConfig) {
        return ReceiptPriceApiFp(this.configuration).receiptPriceModifyingUpdate(receiptPriceModifyingUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
