import React, { useCallback, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { CustomerInfoApi } from '../../../api-client';
import type {
  RelatedCustomerListSearchFormResponse,
  RelatedCustomerListSearchOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { RelatedCustomerListForms } from '../../organisms/Forms/RelatedCustomerListForms';
import { RelatedCustomerListTable } from '../../organisms/Table/RelatedCustomerListTable';
import { createTestId } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { CustomerInfoHeader } from './CustomerInfoHeader';

export interface RelatedCustomerListState {
  api: CustomerInfoApi;
  isLoading: boolean;
  list: RelatedCustomerListSearchOutputResponse[];
  forms: RelatedCustomerListSearchFormResponse;
  errorMessage: string[];
}
export type TestIds = 'search-button' | 'link-button';

export const RelatedCustomerListPage: React.FC = () => {
  const testid = createTestId<TestIds>(RelatedCustomerListPage);
  const { id } = useParams<{ id: string | undefined }>();
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<RelatedCustomerListState>({
    api: new CustomerInfoApi(),
    isLoading: false,
    list: [],
    forms: { customerId: Number(id) },
    errorMessage: [],
  });

  const setForms = useBindSet('forms');

  // 初期フェッチ
  useEffect(() => {
    const customerId = Number(id);
    onSearch({ customerId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 検索
  const onSearch = useCallback(
    (body: RelatedCustomerListSearchFormResponse) => {
      mergeState({ isLoading: true });
      $.api
        .relatedCustomerListSearch(body)
        .then((res: AxiosResponse<RelatedCustomerListSearchOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            // 降順にソート
            const list = res.data.sort((a, b) => {
              return a.occurrenceAt > b.occurrenceAt ? -1 : 1;
            });
            mergeState({ list, errorMessage: [], isLoading: false });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
    },
    [$.api, mergeState]
  );

  return (
    <>
      <CustomerInfoHeader activeKey="relatedCustomer" customerId={Number(id)} />
      {$.errorMessage.length
        ? $.errorMessage.map((errorMessage) => (
            <Alert variant="danger" key={errorMessage}>
              {errorMessage}
            </Alert>
          ))
        : undefined}
      <Form className="mt-4">
        <Row className="g-2 mb-4">
          <div className="d-flex">
            <RelatedCustomerListForms setForms={setForms} forms={$.forms} />
            <span className="mt-2 ms-2">
              <Button
                data-testid={testid('search-button')}
                className="mt-4 center-block"
                onClick={() => {
                  onSearch($.forms);
                }}
              >
                検索
              </Button>
            </span>
          </div>
        </Row>
      </Form>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.list.length ? <RelatedCustomerListTable dataList={$.list} /> : undefined}
      </LoadingSpinner>
    </>
  );
};
