import React from 'react';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { createTestId } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import type { State as PageState } from '../../pages/ShopReplacePage';
import { Url } from '../../../constants/Url';
import { getSurveyProofCorrectPageUrl } from '../../pages/ApplySearch/utils';

export const ShopReplaceConnectedShopTable: React.FC<{
  shopData: NonNullable<PageState['shopData']>;
  applyId: number | undefined;
}> = ({ shopData, applyId }) => {
  const testId = createTestId(ShopReplaceConnectedShopTable);
  const history = useHistory();
  return (
    <Table data-testid={testId('table')}>
      <thead>
        <tr className="align-middle">
          {['店舗ID', '店舗名', 'モニターベースID', 'モニター月', '購入証明添削', 'アンケート添削'].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className="align-middle">
          <td>{shopData.shopId}</td>
          <td>{shopData.shopName}</td>
          <td>{shopData.monitorBaseId}</td>
          <td>{shopData.monitorMonth}</td>
          <td>
            <Button
              onClick={() => history.push(getSurveyProofCorrectPageUrl(shopData.surveyProofCorrectTypeCode, applyId))}
              data-testid={testId('purchase-button')}
            >
              移動
            </Button>
          </td>
          <td>
            <Button
              onClick={() => history.push(`${Url.TENSAKU.CORRECT_ENQUETE}/${applyId}`)}
              data-testid={testId('question-button')}
            >
              移動
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
