import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SqlQueryApi } from '../../../api-client';
import { SqlQueryInfoTable } from '../../organisms/Table/SqlQueryInfoTable';
import { PrimaryButton } from '../../atoms/Button/PrimaryButton';
import { Url } from '../../../constants/Url';
import { UpdateForm } from './SqlQueryUpdatePage';

export const SqlQueryUpdateConfirmPage = () => {
  const history = useHistory();
  const location = useLocation();
  const updateContents = location.state as UpdateForm;

  const ClickConfirm = () => {
    const sqlQueryApi = new SqlQueryApi();
    sqlQueryApi.sqlQueryUpdate(updateContents.queryId, updateContents).then((response) => {
      history.push(Url.KEISAI.SQL_QUERY_COMPLETION);
    });
  };
  return (
    <>
      <p>変更を確定します。よろしいですか？</p>
      <SqlQueryInfoTable tableTitle="SQLクエリ編集確認" tableData={updateContents} />
      <PrimaryButton onClick={ClickConfirm}>変更確定</PrimaryButton>
    </>
  );
};
