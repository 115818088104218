/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AuthUserOutputResponse } from '../domains';
/**
 * GetUserControllerApi - axios parameter creator
 * @export
 */
export const GetUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザ情報を取得する
         * @summary ユーザ情報取得
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetUserControllerApi - functional programming interface
 * @export
 */
export const GetUserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetUserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザ情報を取得する
         * @summary ユーザ情報取得
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async user(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthUserOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.user(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetUserControllerApi - factory interface
 * @export
 */
export const GetUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetUserControllerApiFp(configuration)
    return {
        /**
         * ユーザ情報を取得する
         * @summary ユーザ情報取得
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(body?: string, options?: any): AxiosPromise<AuthUserOutputResponse> {
            return localVarFp.user(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetUserControllerApi - object-oriented interface
 * @export
 * @class GetUserControllerApi
 * @extends {BaseAPI}
 */
export class GetUserControllerApi extends BaseAPI {
    /**
     * ユーザ情報を取得する
     * @summary ユーザ情報取得
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetUserControllerApi
     */
    public user(body?: string, options?: AxiosRequestConfig) {
        return GetUserControllerApiFp(this.configuration).user(body, options).then((request) => request(this.axios, this.basePath));
    }
}
