import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getHistoryState } from '../../utils/functions';

/**
 *
 * @constructor
 */
export const BlankPage: React.VFC = () => {
  const history = useHistory();
  const nextPath = getHistoryState(history.location.state, 'nextPath');
  useEffect(() => {
    history.push(nextPath, { noReload: true });
  }, []);

  return <></>;
};
