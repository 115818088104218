import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import { DefaultApi, ClientOfRequiredNumberTargetUpdateApi } from '../../../api-client';
import type {
  ClientOfRequiredNumberTargetUpdateFormResponse,
  ClientOfRequiredNumberListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { TITLE } from '../../../constants/Title';
import { Alert } from '../../atoms/Alert';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { SearchFrom } from '../../molecules/Form/SearchFrom';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
// eslint-disable-next-line import/no-cycle
import { ClientOfRequiredNumberTargetTable } from '../../organisms/Table/ClientOfRequiredNumberTargetTable';

const sampleGetList = [...Array(101)].map((_, i) => {
  return { clientId: i + 1, clientName: '会社A', requiredNumber: 3, salesStaffName: 'サトウ' };
});

const sampleSearchList = [...Array(1)].map((_, i) => {
  return { clientId: i + 1, clientName: '会社B', requiredNumber: 3, salesStaffName: 'ヤマダ' };
});

const MAX_RECORD_PER_PAGE = 20;

export const ClientOfRequiredNumberTargetPage: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<{ id: number }>();

  const [searchText, setSearchText] = useState<string>('');
  const [list, setList] = useState<ClientOfRequiredNumberListOutputResponse[]>([]);
  const [listPerPage, setListPerPage] = useState<ClientOfRequiredNumberListOutputResponse[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [updData, setUpdData] = useState<ClientOfRequiredNumberTargetUpdateFormResponse>({
    clientOfRequiredNumberTargets: [],
  });
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>({
    result: false,
    errorCode: '',
    errorMessage: '',
  });

  const defaultApi = new DefaultApi();
  const clientOfRequiredNumberTargetUpdateApi = new ClientOfRequiredNumberTargetUpdateApi();

  useEffect(() => {
    const param = location.state?.id?.toString() || '';
    setSearchText(param);

    defaultApi
      .clientOfRequiredNumberList(false, param)
      .then((res: AxiosResponse<ClientOfRequiredNumberListOutputResponse[]>) => {
        // let data;
        // if (!param) {
        //   data = sampleGetList;
        // } else {
        //   data = sampleSearchList;
        // }
        setList(res.data);
        setListPerPage(res.data.slice(0, MAX_RECORD_PER_PAGE));
        setTotalPage(Math.ceil(Number(res.data?.length) / MAX_RECORD_PER_PAGE));
      });
  }, []);

  useEffect(() => {
    setUpdData({
      clientOfRequiredNumberTargets: [],
    });
  }, [list, currentPage]);

  const handleClickSearchBtn = (): void => {
    defaultApi
      .clientOfRequiredNumberList(false, searchText)
      .then((res: AxiosResponse<ClientOfRequiredNumberListOutputResponse[]>) => {
        setList(res.data);
        setListPerPage(res.data.slice(0, MAX_RECORD_PER_PAGE));
        setTotalPage(Math.ceil(Number(res.data?.length) / MAX_RECORD_PER_PAGE));
        setCurrentPage(1);
      });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    if (updData.clientOfRequiredNumberTargets.length === 0) return;

    clientOfRequiredNumberTargetUpdateApi
      .clientOfRequiredNumberTargetUpdate(updData)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        setUpdResult(res.data);

        if (!res.data.result) return;

        setUpdData({
          clientOfRequiredNumberTargets: [],
        });
        let isUpdatedRequiredNumber: number | null = null;
        const newList: ClientOfRequiredNumberListOutputResponse[] = [];
        list.forEach((listItem) => {
          updData.clientOfRequiredNumberTargets.forEach((targetItem) => {
            if (listItem.clientId === targetItem.clientId) {
              isUpdatedRequiredNumber = targetItem.requiredNumber;
            }
          });
          if (isUpdatedRequiredNumber) {
            newList.push({ ...listItem, requiredNumber: isUpdatedRequiredNumber });
          } else {
            newList.push({ ...listItem });
          }
          isUpdatedRequiredNumber = null;
        });
        setList(newList);
      });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.CLIENT_OF_REQUIRED_NUMBER_TARGET}</Title>
      {updResult?.result && <Alert variant="success">更新しました。</Alert>}
      {updResult?.errorCode && updResult?.errorMessage && (
        <Alert variant="danger">{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}
      <Form className="mb-4" onSubmit={handleSubmit}>
        <div className="d-flex justify-content-end mb-4">
          <Button
            variant="link"
            onClick={() => {
              history.goBack();
            }}
          >
            キャンセル
          </Button>
          <Button type="submit" className="ms-2">
            更新
          </Button>
        </div>

        <SearchFrom
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          onClick={handleClickSearchBtn}
        />
        <ClientOfRequiredNumberTargetTable
          listPerPage={listPerPage}
          setListPerPage={setListPerPage}
          updData={updData}
          setUpdData={setUpdData}
        />
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />
      </Form>
    </>
  );
};
