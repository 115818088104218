import { faBan, faBell, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isBefore, setHours, setMinutes, setSeconds } from 'date-fns';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../../../../../hooks/useLargeState';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext, CorrectionPageState } from '../../../../../../../store/correctionStore';
import { Button } from '../../../../../../atoms/Button';
import { SideBy } from '../../../../../../atoms/SideBy';
import { TinyHead } from '../../../../../../atoms/TinyHead';
import { VisitDateTimeCheckOutputResponse } from '../../../../../../../api-client';
import { VISIT_AT_CHECK_RESULT_TYPE } from '../../../../../../../Constants';

export const DateCheck: React.FC = () => {
  const {
    largeState: { state: $, setState },
  } = useSafeContext(CorrectionContext);
  const {
    state: f,
    mergeState,
    onChangeCheckbox: check,
    onChangeSet: set,
  } = useLargeState<NonNullable<CorrectionPageState['receiptForm_dateCheck']>>($.receiptForm_dateCheck ?? {});

  // 初期処理
  useEffect(() => {
    if (f.date == null || f.date.length === 0) {
      mergeState({ result: VISIT_AT_CHECK_RESULT_TYPE.NG });
      mergeState({ errorMessage: '入力してください' });
    }
  }, []);

  const onClickForceOk = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setState((prev) => ({
        ...prev,
        receiptForm_dateCheck: {
          ...f,
          result: VISIT_AT_CHECK_RESULT_TYPE.ALLOWED,
          errorMessage: undefined,
          forceOkFlg: true,
        },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        receiptForm_dateCheck: { ...f, forceOkFlg: false },
      }));
    }
  };

  const onCheck = useCallback(() => {
    if (f.date == null || f.date.length === 0) {
      mergeState({ result: VISIT_AT_CHECK_RESULT_TYPE.NG });
      mergeState({ errorMessage: '日付は必須です' });
      return;
    }
    $.api_correctReceipt
      .visitDateTimeCheck({
        applyId: $.applyId,
        noVisitTimeFlg: f.checkbox ?? false,
        visitedDate: f.date!,
        visitedTime: f.time,
      })
      .then((res: AxiosResponse<VisitDateTimeCheckOutputResponse>) => {
        if (res.data.resultType !== VISIT_AT_CHECK_RESULT_TYPE.ALLOWED) {
          setState((prev) => ({
            ...prev,
            receiptForm_dateCheck: { ...f, result: res.data.resultType, errorMessage: res.data.errorMessage },
          }));
        } else {
          setState((prev) => ({
            ...prev,
            receiptForm_dateCheck: {
              ...(prev.receiptForm_dateCheck ?? {}),
              ...f,
              result: res.data.resultType,
              errorMessage: undefined,
            },
          }));
        }
      });
  }, [$.api_correctReceipt, $.applyId, f, mergeState, setState]);

  const DateAlert = () => {
    if (f.result === VISIT_AT_CHECK_RESULT_TYPE.ALLOWED) {
      return (
        <Alert variant="success">
          <FontAwesomeIcon icon={faCheckCircle} /> 問題なし
        </Alert>
      );
    }
    if (f.result === VISIT_AT_CHECK_RESULT_TYPE.NG) {
      return (
        <>
          <Alert variant="danger">
            <FontAwesomeIcon icon={faBan} />
            {f.errorMessage}
          </Alert>
          {f.errorMessage !== '入力してください' && (
            <Form.Check>
              <Form.Check.Label htmlFor="date_force_flg">確認の上問題なし</Form.Check.Label>
              <Form.Check.Input
                id="date_force_flg"
                checked={f.forceOkFlg ?? false}
                onChange={(e) => onClickForceOk(e)}
              />
            </Form.Check>
          )}
        </>
      );
    }
    if (f.result === VISIT_AT_CHECK_RESULT_TYPE.ALERT) {
      return (
        <Alert variant="warning">
          <FontAwesomeIcon icon={faBell} />
          {f.errorMessage}
        </Alert>
      );
    }

    // ここは通らないはず
    return <></>;
  };

  return (
    <>
      <TinyHead>来店日時チェック</TinyHead>
      <Form>
        <Form.Group>
          <Form.Control size="lg" type="date" value={f.date ?? ''} onChange={set('date')} />
        </Form.Group>
        <SideBy>
          <Form.Group>
            <Form.Check>
              <Form.Check.Input type="checkbox" checked={f.checkbox ?? false} onChange={check('checkbox')} />
              <Form.Check.Label>時刻なし</Form.Check.Label>
            </Form.Check>
          </Form.Group>
          <Form.Group>
            <Form.Control size="lg" disabled={f.checkbox} type="time" value={f.time ?? ''} onChange={set('time')} />
          </Form.Group>
        </SideBy>
        <Button onClick={onCheck}>チェックして添削結果に反映</Button>
        <DateAlert />
      </Form>
    </>
  );
};
