import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { Form } from 'react-bootstrap';
import { SqlQueryApi } from '../../../api-client';
import {
  SqlQueryCreateFormResponse,
  SqlQueryInfoOutputResponse,
  SqlTemplateInfoOutputResponse,
} from '../../../api-client/domains';
import { Url } from '../../../constants/Url';
import { SqlQueryInputTable } from '../../organisms/Table/SqlQueryInputTable';
import { SubmitPrimaryButton } from '../../atoms/Button/SubmitPrimaryButton';
import { pickParameter } from '../SqlTemplate/SqlTemplateCreatePage';
import { sqlQuerySchema } from '../../../constants/YupSchema';

export const SqlQueryCreatePage = () => {
  const history = useHistory();
  const location = useLocation<any>();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    getValues,
  } = useForm<SqlQueryCreateFormResponse>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    shouldFocusError: false,
    defaultValues: {
      execType: '1',
      validFlag: true,
      skipFlag: false,
      personalInformationProtectionFlag: false,
      executeTimestamp: '',
      repeatType: '1',
      repeatTime: '',
    },
    resolver: yupResolver(sqlQuerySchema),
  });

  const {
    fields: listFields,
    append: listAppend,
    remove: listRemove,
  } = useFieldArray({
    control,
    name: 'parameterList',
  });

  const {
    fields: addressFields,
    append: addressAppend,
    remove: addressRemove,
  } = useFieldArray({
    control,
    name: 'mailaddressList',
  });

  useEffect(() => {
    if (location.state) {
      const sqlQueryApi = new SqlQueryApi();
      if (location.state.templateFlg) {
        const templateData: SqlTemplateInfoOutputResponse = location.state.listData;
        reset({
          queryName: templateData.templateName ? templateData.templateName : '',
          sqlString: templateData.sqlString ? templateData.sqlString : '',
          parameterList: pickParameter(templateData),
          personalInformationProtectionFlag: templateData.personalInformationProtectionFlag
            ? templateData.personalInformationProtectionFlag
            : false,
          executeTimestamp: '',
          repeatType: '1',
          repeatTime: '',
          execType: '1',
          validFlag: true,
          skipFlag: false,
          mailaddressList: [],
        });
        return;
      }

      const listData = location.state.listData as SqlQueryInfoOutputResponse;

      sqlQueryApi.sqlQueryInfo(listData.queryId).then((response: AxiosResponse<SqlQueryInfoOutputResponse>) => {
        const obj: SqlQueryInfoOutputResponse = response.data;
        reset({
          queryName: obj.queryName,
          sqlString: obj.sqlString,
          execType: obj.execType,
          executeTimestamp: obj.executeTimestamp,
          repeatType: obj.repeatType,
          repeatTime: obj.repeatTime,
          validFlag: obj.validFlag,
          mailaddressList: obj.mailaddressList,
          superiorApprovalUrl: obj.superiorApprovalUrl,
          skipFlag: obj.skipFlag,
          parameterList: pickParameter(obj),
          personalInformationProtectionFlag: obj.personalInformationProtectionFlag,
        });
      });
    }
  }, []);

  const onSubmit: SubmitHandler<SqlQueryCreateFormResponse> = (data: SqlQueryCreateFormResponse) => {
    history.push({
      pathname: Url.KEISAI.SQL_QUERY_CREATE_CONFIRM,
      state: data,
    });
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SqlQueryInputTable
          tableTitle="SQLクエリページ作成"
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
          listFields={listFields}
          listAppend={listAppend}
          listRemove={listRemove}
          addressFields={addressFields}
          addressAppend={addressAppend}
          addressRemove={addressRemove}
        />
        <SubmitPrimaryButton>確認</SubmitPrimaryButton>
      </Form>
    </>
  );
};
