import { Form, Table } from 'react-bootstrap';
import * as React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';
import { SelectOption } from '../../../interfaces/SqlTemplate';
import { sqlTemplateTableCellTitle } from './SqlTemplateInfoTable';
import { PrimaryButton } from '../../atoms/Button/PrimaryButton';
import { ValidationFormControl } from '../../molecules/Form/ValidationFormControl';
import { parameterCellTitle } from './SqlTemplateParameterTable';
import { FormControl } from '../../molecules/Form/FormControl';
import { AutoChangeHeightTextArea } from '../TextArea/AutoChangeHeightTextArea';
import { FormSelect } from '../../molecules/Form/FormSelect';

export const parameterTypeOption: SelectOption[] = [
  { value: '', text: '選択してください' },
  { value: '1', text: '文字列' },
  { value: '2', text: '日付(yyyy-MM-dd)' },
  { value: '3', text: '日時(yyyy-MM-dd HH:mm:ss)' },
  { value: '4', text: 'long' },
  { value: '5', text: 'int' },
  { value: '6', text: 'double' },
  { value: '7', text: 'boolean(true or false)' },
];

export const personalInformationProtectionFlagOption: SelectOption[] = [
  { value: 'ON', text: 'ON' },
  { value: 'OFF', text: 'OFF' },
];

// エラーメッセージを表示
export const ErrorMessage = (message: string | undefined) => {
  if (message === undefined) return;
  return <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>;
};

export const SqlTemplateInputTable = (props: any) => {
  const { tableTitle, sqlUpdateDatas, register, errors, control, getValues, fields, append, remove } = props;

  const FlagSelectBox = (arg: Omit<ControllerProps<any>, 'render'>) => (
    <Controller
      {...arg}
      render={({ field }) => (
        <Form.Group className="mb-4" controlId="formId3">
          <Form.Select
            onChange={(e) => field.onChange(e.target.value === 'ON')}
            defaultValue={getValues('personalInformationProtectionFlag') === true ? 'ON' : 'OFF'}
          >
            {personalInformationProtectionFlagOption.map((option) => (
              <option key={option.value} value={option.value}>
                {option.text}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
    />
  );
  return (
    <Table>
      <thead>
        <tr>
          <th>{tableTitle}</th>
        </tr>
      </thead>
      <tbody>
        {sqlUpdateDatas?.templateId ? (
          <tr>
            <th>{sqlTemplateTableCellTitle[0]}</th>
            <td>{sqlUpdateDatas?.templateId}</td>
          </tr>
        ) : null}
        <tr>
          <th>{sqlTemplateTableCellTitle[1]}</th>
          <td>
            <ValidationFormControl
              type="text"
              register={register}
              id="templateName"
              registerName="templateName"
              errorsTargetName={errors.templateName}
            />
          </td>
        </tr>
        <tr>
          <th>{sqlTemplateTableCellTitle[2]}</th>
          <td>
            <AutoChangeHeightTextArea
              control={control}
              getValues={getValues}
              register={register}
              errorTargetName={errors.sqlString}
            />
          </td>
        </tr>
        <tr>
          <th>{sqlTemplateTableCellTitle[3]}</th>
          <td>
            <FlagSelectBox control={control} name="personalInformationProtectionFlag" />
            {errors.personalInformationProtectionFlag && ErrorMessage(errors.personalInformationProtectionFlag.message)}
          </td>
        </tr>
        <tr>
          <th>{sqlTemplateTableCellTitle[4]}</th>
          <td>
            <Table>
              <thead>
                <tr>
                  {parameterCellTitle.map((title: any) => (
                    <th key={title}>{title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fields.map((e: any, index: any) => (
                  <tr key={index}>
                    <td>
                      <ValidationFormControl
                        type="text"
                        register={register}
                        id="parameterDisplayName"
                        registerName={
                          `parameterList.${index}.parameterDisplayName` as 'parameterList.0.parameterDisplayName'
                        }
                        errorsTargetName={errors?.parameterList?.[index]?.parameterDisplayName}
                      />
                    </td>
                    <td>
                      <ValidationFormControl
                        type="text"
                        register={register}
                        id="parameterName"
                        registerName={`parameterList.${index}.parameterName` as 'parameterList.0.parameterName'}
                        errorsTargetName={errors?.parameterList?.[index]?.parameterName}
                      />
                    </td>
                    <td>
                      <FormSelect
                        getValues={getValues}
                        register={register}
                        registerName={`parameterList.${index}.parameterType` as 'parameterList.0.parameterType'}
                        errorsTargetName={errors?.parameterList?.[index]?.parameterType}
                        selectOption={parameterTypeOption}
                      />
                    </td>
                    <td>
                      <FormControl
                        type="text"
                        register={register}
                        id="parameterValue"
                        registerName={`parameterList.${index}.parameterValue` as 'parameterList.0.parameterValue'}
                      />
                    </td>
                    <td className="mb-4">
                      <PrimaryButton onClick={() => remove(index)}>削除</PrimaryButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <PrimaryButton onClick={() => append({})}>追加</PrimaryButton>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
