import React, { useState, useEffect, SetStateAction } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Col, InputGroup, ListGroup } from 'react-bootstrap';
import { UseFormSetValue, UseFormStateProps } from 'react-hook-form';
import { log } from 'console';
import {
  BrandApi,
  BrandForImageTargetMonitorBaseListFormResponse,
  BrandForImageTargetMonitorBaseListOutputResponse,
  BrandMonitorBaseImageInfoOutputResponse,
} from '../../../api-client';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { SEARCH_TYPE } from '../../pages/Brand/BrandDetailModifyPage';

const sampleShopGetResult = [...Array(101)].map((_, i) => {
  return { id: i + 1, name: `店舗${i + 1}` };
});

const sampleShopSearchResult = [...Array(3)].map((_, i) => {
  return { id: i + 11, name: `店舗${i + 11}` };
});

export interface Props {
  isMonitorBaseImageModal: boolean;
  setIsMonitorImageModal: React.Dispatch<React.SetStateAction<boolean>>;
  setValue: UseFormSetValue<any>;
  setImageUrlByTargetMonitorBase: React.Dispatch<React.SetStateAction<string>>;
}

const MAX_RECORD_PER_PAGE = 20;

export const MonitorImageModal: React.VFC<Props> = ({
  isMonitorBaseImageModal: isMonitorImageModal,
  setIsMonitorImageModal,
  setValue,
  setImageUrlByTargetMonitorBase,
}) => {
  const [monitorBaseSearchParam, setMonitorBaseSearchParam] = useState<BrandForImageTargetMonitorBaseListFormResponse>({
    type: 1,
    keyword: '',
  });

  const [list, setList] = useState<{ id: number; name: string }[]>([]);
  const [listPerPage, setListPerPage] = useState<{ id: number; name: string }[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const brandApi = new BrandApi();

  useEffect(() => {
    brandApi
      .brandForImageTargetMonitorBaseList(monitorBaseSearchParam)
      .then((res: AxiosResponse<BrandForImageTargetMonitorBaseListOutputResponse[]>) => {
        // res.data = sampleShopGetResult;
        setList(res.data);
        setListPerPage(res.data.slice(0, MAX_RECORD_PER_PAGE));
        setTotalPage(Math.ceil(Number(res.data?.length) / MAX_RECORD_PER_PAGE));
      });
  }, []);

  useEffect(() => {
    setListPerPage(list.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(list?.length) / MAX_RECORD_PER_PAGE));
  }, [list, currentPage]);

  const handleClickSearch = () => {
    brandApi
      .brandForImageTargetMonitorBaseList(monitorBaseSearchParam)
      .then((res: AxiosResponse<BrandForImageTargetMonitorBaseListOutputResponse[]>) => {
        // res.data = sampleShopSearchResult;
        setList(res.data);
        setCurrentPage(1);
      });
  };

  const setImage = (monitorbaseId: number) => {
    brandApi
      .brandMonitorBaseImageInfo(monitorbaseId)
      .then((res: AxiosResponse<BrandMonitorBaseImageInfoOutputResponse>) => {
        setImageUrlByTargetMonitorBase(res.data.imageUrl || '');
      });
  };

  return (
    <Modal
      onHide={() => setIsMonitorImageModal(false)}
      isModal={isMonitorImageModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <InputGroup className="mb-4">
            <Form.Select
              value={monitorBaseSearchParam.type}
              onChange={(e) => setMonitorBaseSearchParam({ ...monitorBaseSearchParam, type: Number(e.target.value) })}
            >
              <option value="1">{SEARCH_TYPE.CLIENT}</option>
              <option value="2">{SEARCH_TYPE.SHOP}</option>
            </Form.Select>
            <Form.Control
              className="w-50"
              type="text"
              value={monitorBaseSearchParam.keyword}
              onChange={(e) => setMonitorBaseSearchParam({ ...monitorBaseSearchParam, keyword: e.target.value })}
            />
            <Button className="flex-shrink-1" onClick={handleClickSearch}>
              検索
            </Button>
          </InputGroup>
          <div className="mb-4">
            <PaginationWithEllipsis
              currentPage={currentPage}
              totalPage={totalPage}
              handleClick={(page) => {
                if (!page || page > totalPage) return;
                setListPerPage(list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
                setCurrentPage(page);
              }}
            />
          </div>

          <Col style={{ minHeight: '50vh' }}>
            <ListGroup>
              {listPerPage?.map((item) => {
                return (
                  <ListGroup.Item
                    key={item.id}
                    onClick={() => {
                      setValue('targetMonitorBaseId', item.id);
                      setImage(item.id);
                      setIsMonitorImageModal(false);
                    }}
                    action
                  >
                    {`${item.id} ${item.name}`}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
        </>
      }
    />
  );
};
