/* eslint-disable import/no-cycle */
import React from 'react';
import { Table } from 'react-bootstrap';
import { ExecutiveStaffApplyStatusSummaryDisplayOutputResponse } from '../../../api-client';
import { createTestId } from '../../../utils/functions';

interface Props {
  summary?: ExecutiveStaffApplyStatusSummaryDisplayOutputResponse;
}
export type TestIds = 'table';

export const ExecutiveStaffApplyManagementTable: React.FC<Props> = ({ summary }) => {
  const testid = createTestId<TestIds>(ExecutiveStaffApplyManagementTable);

  return (
    <Table data-testid={testid('table')} style={{ maxWidth: '800px' }} bordered>
      <thead>
        <tr className="align-middle">
          <th colSpan={2}>
            <></>
          </th>
          <th>
            <span>短納期</span>
          </th>
          <th colSpan={2}>
            <span>標準納期</span>
          </th>
          <th>
            <span>長納期</span>
          </th>
        </tr>
        <tr className="align-middle">
          <th>
            <></>
          </th>
          <th>
            <span>合計</span>
          </th>
          <th>
            <span>納品日まで2日以内</span>
          </th>
          <th>
            <span>選任</span>
          </th>
          <th>
            <span>選任以外</span>
          </th>
          <th>
            <></>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="align-middle">
          <td>
            <span>チェック待ち件数</span>
          </td>
          <td>
            <span>{summary?.waitingForCheck.totalApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForCheck.quickDueApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForCheck.electedPersonCorrectableApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForCheck.anyoneCorrectableApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForCheck.slowDueApplyCount}</span>
          </td>
        </tr>
        <tr className="align-middle">
          <td>
            <span>エスカレ・却下_事務局回答待ち（未着手）</span>
          </td>
          <td>
            <span>{summary?.waitingForExecutiveStaffAnswer.totalApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForExecutiveStaffAnswer.quickDueApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForExecutiveStaffAnswer.electedPersonCorrectableApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForExecutiveStaffAnswer.anyoneCorrectableApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForExecutiveStaffAnswer.slowDueApplyCount}</span>
          </td>
        </tr>
        <tr className="align-middle">
          <td>
            <span>エスカレ・却下_ユーコミ回答待ち（未着手）</span>
          </td>
          <td>
            <span>{summary?.waitingForUcomiAnswer.totalApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForUcomiAnswer.quickDueApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForUcomiAnswer.electedPersonCorrectableApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForUcomiAnswer.anyoneCorrectableApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForUcomiAnswer.slowDueApplyCount}</span>
          </td>
        </tr>
        <tr className="align-middle">
          <td>
            <span>エスカレ・却下_他部署回答待ち（確認中）</span>
          </td>
          <td>
            <span>{summary?.waitingForOtherDepartmentsAnswer.totalApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForOtherDepartmentsAnswer.quickDueApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForOtherDepartmentsAnswer.electedPersonCorrectableApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForOtherDepartmentsAnswer.anyoneCorrectableApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForOtherDepartmentsAnswer.slowDueApplyCount}</span>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
