import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Card, Form, ToggleButton } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import type {
  BannerItemCreateUpdateFormAreaListItemResponse,
  BannerItemCreateUpdateFormResponse,
  BannerItemInfoOutputResponse,
  CommonMasterListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
// eslint-disable-next-line import/no-cycle
import {
  BannerItemCreateUpdateApi,
  BannerItemInfoApi,
  CommonMasterListApi,
  ImageUpdateFormResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { TITLE } from '../../../constants/Title';
import { Url } from '../../../constants/Url';
import { isoDateToInput } from '../../../utils/functions';
import { Title } from '../../atoms/Title';
import { Alert } from '../../atoms/Alert';
import { Button } from '../../atoms/Button';
import { Dropzone } from '../../molecules/Dropzone';
import { ImageCard } from '../../molecules/ImageCard';
// eslint-disable-next-line import/no-cycle
import { BANNER_TYPE, BannerType, CATEGORY_OPTIONS } from './BannerListPage';
import { getImageSize, getPresignedUrl, s3Delete, s3Upload, s3UploadAlignment } from '../../../utils/s3';
import { IMAGE_TYPE_ID, OBJECT_TYPE } from '../../../Constants';

const AVAIILABLE_MIN_AGE = 16;
const ALL_PREFECTURES = 47;
const PAGE_TYPE = {
  MODIFY: 1,
  CONFIRM: 2,
} as const;

type PageType = typeof PAGE_TYPE[keyof typeof PAGE_TYPE];

interface ImageProp {
  isDefault: boolean;
  id?: number;
  name?: string;
  path?: string;
  file?: File;
  contentType?: string;
  width?: number;
  height?: number;
  format?: string;
}

export const BannerItemModifyPage: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<{ bannerType: BannerType; categoryId: number; id?: number }>();

  const {
    state: $,
    mergeState,
    onChangeSet,
  } = useLargeState<{
    api: BannerItemInfoApi;
    updateApi: BannerItemCreateUpdateApi;
    commonApi: CommonMasterListApi;
    pageType: PageType;
    id?: number;
    bannerType: BannerType;
    categoryId?: number;
    imageURL?: string;
    imageName?: string;
    prefectureOpts: CommonMasterListOutputResponse[];
    memberIdsText?: string;
    monitorIdsText?: string;
    validation: { result: boolean; message: string };
    updResult: IncResultOutputResponse;
    imageData: ImageProp;
  }>({
    api: new BannerItemInfoApi(),
    updateApi: new BannerItemCreateUpdateApi(),
    commonApi: new CommonMasterListApi(),
    id: location?.state?.id,
    categoryId: location?.state?.categoryId,
    pageType: PAGE_TYPE.MODIFY,
    bannerType: location.state?.bannerType,
    prefectureOpts: [],
    validation: { result: false, message: '' },
    updResult: { result: false },
    imageData: { isDefault: false },
  });

  const {
    state: f,
    onChangeSet: fOnChangeSet,
    mergeState: fMergeState,
  } = useLargeState<Partial<BannerItemCreateUpdateFormResponse>>({
    bannerTypeId: location?.state.bannerType.id,
    bannerItemId: location?.state?.id,
    areaList: [],
    imageURL: '',
    gender: 2,
    validFlg: false,
  });

  useEffect(() => {
    if (!$.id) return;

    $.api.bannerItemInfo($.id).then((res: AxiosResponse<BannerItemInfoOutputResponse>) => {
      const { imageId, imageURL, memberList, monitorList, startAt, endAt } = res.data;
      mergeState({
        imageURL,
        memberIdsText: memberList.join(','),
        monitorIdsText: monitorList.join(','),
        imageData: { id: imageId, path: imageURL, isDefault: !!imageId },
      });
      fMergeState({
        ...res.data,
        startAt: startAt.replace(/ /, 'T'),
        endAt: endAt.replace(/ /, 'T'),
      });
    });
  }, [$.api, $.id, mergeState, fMergeState]);

  useEffect(() => {
    if ($.prefectureOpts.length !== 0) return;
    $.commonApi.commonMasterList('prefecture').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
      mergeState({ prefectureOpts: res.data });
    });
  }, [$.commonApi, $.prefectureOpts.length, mergeState]);

  const onConfirm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let message = '';
    if (!f.minAge || !f.maxAge) {
      message = '年齢を選択してください。';
    } else if (f.minAge > f.maxAge) {
      message = '年齢の選択範囲に誤りがあります。';
    } else if (!f.type) {
      message = 'バナー遷移先を選択してください。';
    } else if ($.bannerType.id === BANNER_TYPE.MODAL && !$.imageData.path) {
      message = 'モーダルバナーはバナー画像が必須です。';
    } else if (
      $.bannerType.id !== BANNER_TYPE.KURUKURU &&
      $.bannerType.id !== BANNER_TYPE.CATETOP &&
      $.memberIdsText &&
      !$.memberIdsText?.trim().match(/^(\d+,)*[^,\D]+$/)
    ) {
      message = '「表示対象の会員IDs」の入力形式に誤りがあります。';
    } else if (
      $.bannerType.id === BANNER_TYPE.RECOMMEND &&
      $.monitorIdsText &&
      !$.monitorIdsText?.trim().match(/^(\d+,)*[^,\D]+$/)
    ) {
      message = '「指定のモニターID完了者にのみ表示」の入力形式に誤りがあります。';
    } else if (f.type !== 'URL指定' && !Number(f.idURL)) {
      message = '「ID/URL」に数値を入力してください。';
    }

    if (message) {
      mergeState({ validation: { result: true, message } });
      return;
    }

    window.history.pushState(null, '', Url.KEISAI.ANNOUNCE_MODIFY_CONFIRM);
    setTimeout(() => mergeState({ pageType: PAGE_TYPE.CONFIRM, validation: { result: false, message: '' } }), 50);
  };

  const uploadImage = async (image: ImageProp) => {
    const { isDefault, path, contentType, file, width, height, format, id, name } = image;

    let uploadImageId: number | undefined = id;

    try {
      if (file && name && contentType && width && height && format) {
        const presignedUrl = await getPresignedUrl({
          contentType,
          fileKey: name,
          objectType: OBJECT_TYPE.BANNER,
        });
        const uploadTime = await s3Upload(presignedUrl, contentType, file);

        const alignmentParam: ImageUpdateFormResponse = { width, height, format };
        if (isDefault) {
          alignmentParam.imageId = id;
        } else {
          alignmentParam.imageTypeId = IMAGE_TYPE_ID.BANNER;

          const presignedPath = presignedUrl.replace(/\?.*$/, '');
          alignmentParam.path = presignedPath.substring(
            presignedPath.indexOf('amazonaws.com/') + 'amazonaws.com/'.length
          );
          alignmentParam.uploadTime = uploadTime;
        }

        await s3UploadAlignment(alignmentParam).then((res: IncResultOutputResponse) => {
          if (!res?.result) {
            mergeState({ updResult: res });
          }
          uploadImageId = res.id;
        });
      } else if (isDefault && !path && id) {
        await s3Delete(id).then((res) => {
          if (!res?.result) {
            mergeState({ updResult: res });
            return;
          }
          mergeState({ imageData: { isDefault: false } });

          uploadImageId = undefined;
        });
      }

      return uploadImageId;
    } catch {
      history.push(Url.COMMON_ERROR);
    }
  };

  const onSave = async () => {
    const imageId = await uploadImage($.imageData);

    $.updateApi
      .bannerItemCreateUpdate({
        ...f,
        category: $.categoryId,
        startAt: f.startAt?.length === 16 ? (f.startAt += ':00') : f.startAt,
        endAt: f.endAt?.length === 16 ? (f.endAt += ':00') : f.endAt,
        memberList: $.memberIdsText ? $.memberIdsText.split(',')?.map((id) => Number(id)) : undefined,
        monitorList: $.monitorIdsText ? $.monitorIdsText.split(',')?.map((id) => Number(id)) : undefined,
        imageId,
      } as BannerItemCreateUpdateFormResponse)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
        history.push(Url.KEISAI.BANNER_LIST);
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  return (
    <>
      <Title className="mb-4">{`${$.bannerType?.name}${
        $.pageType === PAGE_TYPE.MODIFY ? TITLE.KEISAI.BANNER_ITEM_MODIFY : TITLE.KEISAI.BANNER_ITEM_MODIFY_CONFIRM
      }`}</Title>

      {$.updResult.result && <Alert variant="success">確定しました。</Alert>}
      {$.updResult.errorMessage && (
        <Alert variant="danger">{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}
      {$.validation.result && <Alert variant="danger">{$.validation.message}</Alert>}

      <Form onSubmit={onConfirm}>
        <div className="d-flex justify-content-end mb-4">
          {$.pageType === PAGE_TYPE.MODIFY ? (
            <>
              <Button variant="link" className="ms-2" onClick={() => history.goBack()}>
                キャンセル
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="link"
                className="ms-2"
                onClick={() => {
                  mergeState({ pageType: PAGE_TYPE.MODIFY, updResult: { result: false } });
                  history.goBack();
                }}
              >
                キャンセル
              </Button>
              <Button className="ms-2" onClick={onSave}>
                確定
              </Button>
            </>
          )}
        </div>

        <fieldset disabled={$.pageType === PAGE_TYPE.CONFIRM}>
          {$.bannerType.id === BANNER_TYPE.CATETOP && (
            <Form.Group className="mb-4">
              <Form.Label>カテゴリ</Form.Label>
              <Form.Select value={$.categoryId} onChange={(e) => mergeState({ categoryId: Number(e.target.value) })}>
                {CATEGORY_OPTIONS.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}

          <Form.Group className="mb-4">
            <Form.Label>
              広告名称<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control required type="text" value={f.title || ''} onChange={fOnChangeSet('title')} />
          </Form.Group>

          <Card className="mb-4">
            <Card.Body>
              <h6 className="mb-4">セグメント設定</h6>

              <Form.Group>
                <Form.Label>
                  年齢<span className="text-danger">*</span>
                </Form.Label>
                <div className="d-flex align-items-center gap-2 mb-4">
                  <Form.Select
                    required
                    className="w-auto"
                    value={f.minAge ?? ''}
                    onChange={fOnChangeSet('minAge', Number)}
                  >
                    <option hidden>&nbsp;</option>
                    {[...Array(84)]
                      .map((_, i) => i + AVAIILABLE_MIN_AGE)
                      .map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                  </Form.Select>
                  {' ～ '}
                  <Form.Select
                    required
                    className="w-auto"
                    value={f.maxAge ?? ''}
                    onChange={fOnChangeSet('maxAge', Number)}
                  >
                    <option hidden>&nbsp;</option>
                    {[...Array(84)]
                      .map((_, i) => i + AVAIILABLE_MIN_AGE)
                      .map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>性別</Form.Label>
                <Form.Select required className="w-auto" value={f.gender ?? ''} onChange={fOnChangeSet('gender')}>
                  <option hidden>&nbsp;</option>
                  {[
                    { displayName: '指定なし', code: 2 },
                    { displayName: '男性', code: 0 },
                    { displayName: '女性', code: 1 },
                  ].map(({ displayName, code }) => (
                    <option key={displayName} value={code}>
                      {displayName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label className="d-block">エリア</Form.Label>

                <ToggleButton
                  id="allPrefecture"
                  type="checkbox"
                  variant="outline-primary"
                  value="allPrefecture"
                  checked={(f.areaList || []).length === ALL_PREFECTURES}
                  onChange={() => {
                    fMergeState({ areaList: (f.areaList || []).length !== ALL_PREFECTURES ? $.prefectureOpts : [] });
                  }}
                  className="mb-3"
                  style={{ width: '90px' }}
                >
                  全国
                </ToggleButton>

                <div className="d-flex flex-wrap gap-3">
                  {$.prefectureOpts.map(({ id, name }) => (
                    <ToggleButton
                      id={String(id)}
                      key={id}
                      type="checkbox"
                      variant="outline-primary"
                      value={id}
                      checked={
                        !!(f.areaList || []).find(
                          (item: BannerItemCreateUpdateFormAreaListItemResponse) => item.id === id
                        )
                      }
                      onChange={() => {
                        fMergeState({
                          areaList: (f.areaList || []).find(
                            (item: BannerItemCreateUpdateFormAreaListItemResponse) => item.id === id
                          )
                            ? (f.areaList || []).filter(
                                (item: BannerItemCreateUpdateFormAreaListItemResponse) => item.id !== id
                              )
                            : [...(f.areaList || []), { id, name }],
                        });
                      }}
                      style={{ width: '90px' }}
                    >
                      {name}
                    </ToggleButton>
                  ))}
                </div>
              </Form.Group>
            </Card.Body>
          </Card>

          {$.bannerType.id === BANNER_TYPE.RECOMMEND && (
            <>
              <Form.Group className="mb-4">
                <Form.Label>緯度</Form.Label>
                <Form.Control type="number" value={f.latitude || ''} onChange={fOnChangeSet('latitude', Number)} />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>経度</Form.Label>
                <Form.Control type="number" value={f.longitude || ''} onChange={fOnChangeSet('longitude', Number)} />
              </Form.Group>

              <Form.Group className="mb-4">
                <Form.Label>範囲</Form.Label>
                <div className="d-flex align-items-baseline">
                  <Form.Control type="number" value={f.range || ''} onChange={fOnChangeSet('range', Number)} />
                  <span className="ms-1">km</span>
                </div>
              </Form.Group>
            </>
          )}

          {$.bannerType.id !== BANNER_TYPE.KURUKURU && $.bannerType.id !== BANNER_TYPE.CATETOP && (
            <Form.Group className="mb-4">
              <Form.Label>表示対象の会員IDs</Form.Label>
              <Form.Control type="text" value={$.memberIdsText || ''} onChange={onChangeSet('memberIdsText')} />
            </Form.Group>
          )}

          {$.bannerType.id === BANNER_TYPE.RECOMMEND && (
            <Form.Group className="mb-4">
              <Form.Label>指定のモニターID完了者にのみ表示</Form.Label>
              <Form.Control type="text" value={$.monitorIdsText || ''} onChange={onChangeSet('monitorIdsText')} />
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label>
              表示期間<span className="text-danger">*</span>
            </Form.Label>
            <div className="d-flex align-items-center gap-2 mb-4">
              <Form.Control
                required
                type="datetime-local"
                step="1"
                className="w-auto"
                value={f.startAt || ''}
                onChange={fOnChangeSet('startAt')}
              />
              {' ～ '}
              <Form.Control
                required
                type="datetime-local"
                step="1"
                className="w-auto"
                value={f.endAt || ''}
                onChange={fOnChangeSet('endAt')}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              バナー遷移先<span className="text-danger">*</span>
            </Form.Label>
            <Form.Select required value={f.type ?? ''} onChange={fOnChangeSet('type')}>
              <option hidden>&nbsp;</option>
              {['モニターベース', 'ブランド', 'キャンペーン', 'URL指定'].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              ID/URL<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control required type="text" value={f.idURL ?? ''} onChange={fOnChangeSet('idURL')} />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              バナー画像{$.bannerType.id === BANNER_TYPE.MODAL && <span className="text-danger">*</span>}
            </Form.Label>
            {$.imageData.path ? (
              <ImageCard
                url={$.imageData.path || ''}
                name={$.imageData.name || ''}
                onDelete={() => {
                  mergeState({ imageData: { ...$.imageData, name: undefined, path: undefined } });
                }}
              />
            ) : (
              <Dropzone
                onDrop={async (files: File[]) => {
                  if (files === null || files.length === 0) return;
                  const file = files[0];

                  const { name, type: contentType } = file;
                  const path = window.URL.createObjectURL(file);
                  const format = name.split('.').pop();
                  const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));

                  mergeState({
                    imageData: {
                      ...$.imageData,
                      ...size,
                      file,
                      name,
                      contentType,
                      format,
                      path,
                      isDefault: false,
                    },
                  });
                }}
              />
            )}
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>バナーテキスト文</Form.Label>
            <Form.Control type="text" value={f.content ?? ''} onChange={fOnChangeSet('content')} />
          </Form.Group>

          <Form.Check id="validFlg" className="d-flex mb-4">
            <Form.Check.Input
              className="flex-shrink-0 me-2"
              type="checkbox"
              checked={f.validFlg || false}
              onChange={() => fMergeState({ validFlg: !f.validFlg })}
            />
            <Form.Check.Label>有効</Form.Check.Label>
          </Form.Check>
        </fieldset>
        {$.pageType === PAGE_TYPE.MODIFY && (
          <div className="d-flex justify-content-end mb-4">
            <Button type="submit">確認画面へ</Button>
          </div>
        )}
      </Form>
    </>
  );
};
