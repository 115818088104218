import * as React from 'react';
import { Table } from 'react-bootstrap';
import { ParameterListTable } from './SqlTemplateParameterTable';

export const sqlTemplateTableCellTitle: string[] = [
  'ID',
  'テンプレート名(*)',
  'SQL(*)',
  '個人情報保護フラグ(*)',
  'パラメータ一覧',
];

export const SqlTemplateInfoTable = (props: any) => {
  const { tableTitle, cellData, userInfo } = props;
  return (
    <Table>
      <thead>
        <tr>
          <th>{tableTitle}</th>
        </tr>
      </thead>
      <tbody>
        {cellData?.templateId ? (
          <tr>
            <th>{sqlTemplateTableCellTitle[0]}</th>
            <td>{cellData?.templateId}</td>
          </tr>
        ) : null}
        <tr>
          <th>{sqlTemplateTableCellTitle[1]}</th>
          <td>{cellData?.templateName}</td>
        </tr>
        <tr>
          <th>{sqlTemplateTableCellTitle[2]}</th>
          <td>{cellData?.sqlString}</td>
        </tr>
        <tr>
          <th>{sqlTemplateTableCellTitle[3]}</th>
          <td>{cellData?.personalInformationProtectionFlag ? 'ON' : 'OFF'}</td>
        </tr>
        <tr>
          <th>{sqlTemplateTableCellTitle[4]}</th>
          <td colSpan={3}>
            <ParameterListTable parameterList={cellData?.parameterList} />
          </td>
        </tr>
        {userInfo ? (
          <tr>
            <th>作成者</th>
            <td>{userInfo?.lastUpdater}</td>
            <th>作成日時</th>
            <td>{userInfo?.createTime}</td>
          </tr>
        ) : null}
        {userInfo ? (
          <tr>
            <th>最終更新者</th>
            <td>{userInfo?.lastUpdater}</td>
            <th>更新日時</th>
            <td>{userInfo?.updateTime}</td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
};
