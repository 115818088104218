import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import type { RelatedCustomerListState } from '../../pages/Customer/RelatedCustomerListPage';
import { InputNumber } from '../../atoms/InputNumber';

export type State = RelatedCustomerListState['forms'];
interface Props {
  setForms: DispatchSetState<State>;
  forms: RelatedCustomerListState['forms'];
}

export type TestIds = keyof State;

export const RelatedCustomerListForms: React.FC<Props> = ({ setForms, forms }) => {
  const testId = createTestId<TestIds>(RelatedCustomerListForms);
  const { state: $form, onChangeSet } = useLargeState<RelatedCustomerListState['forms']>(forms);

  useEffect(() => {
    setForms({ ...$form });
  }, [setForms, $form]);

  return (
    <>
      <Form.Group
        data-testid={testId('customerRelatedId')}
        controlId="customerRelatedId"
        key="customerRelatedId"
        style={{ width: '20%' }}
      >
        <Form.Label>会員ID</Form.Label>
        <InputNumber value={$form.customerRelatedId} onChange={onChangeSet('customerRelatedId', Number)} />
      </Form.Group>
      <Form.Group
        data-testid={testId('customerName')}
        className="ms-2"
        controlId="customerName"
        key="customerName"
        style={{ width: '20%' }}
      >
        <Form.Label>会員名</Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$form.customerName || ''}
          onChange={onChangeSet('customerName', String)}
        />
      </Form.Group>
      <Form.Group
        data-testid={testId('reason')}
        className="ms-2"
        controlId="reason"
        key="reason"
        style={{ width: '20%' }}
      >
        <Form.Label>名前 </Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$form.reason || ''}
          onChange={onChangeSet('reason', String)}
        />
      </Form.Group>
      <Form.Group
        data-testid={testId('matchedValue')}
        className="ms-2"
        controlId="matchedValue"
        key="matchedValue"
        style={{ width: '20%' }}
      >
        <Form.Label>一致項目値</Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$form.matchedValue || ''}
          onChange={onChangeSet('matchedValue', String)}
        />
      </Form.Group>
    </>
  );
};
