/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { QuestionListOutputResponse } from '../domains';
/**
 * QuestionListApi - axios parameter creator
 * @export
 */
export const QuestionListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 設問一覧を取得する
         * @summary 設問一覧取得
         * @param {number} [id] 
         * @param {string} [content] 
         * @param {Array<number>} [categoryList] 
         * @param {number} [clientId] 
         * @param {number} [enqueteType] 
         * @param {Array<number>} [questionLevels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionList: async (id?: number, content?: string, categoryList?: Array<number>, clientId?: number, enqueteType?: number, questionLevels?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/question-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (categoryList) {
                localVarQueryParameter['categoryList'] = categoryList;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (enqueteType !== undefined) {
                localVarQueryParameter['enqueteType'] = enqueteType;
            }

            if (questionLevels) {
                localVarQueryParameter['questionLevels'] = questionLevels;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionListApi - functional programming interface
 * @export
 */
export const QuestionListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionListApiAxiosParamCreator(configuration)
    return {
        /**
         * 設問一覧を取得する
         * @summary 設問一覧取得
         * @param {number} [id] 
         * @param {string} [content] 
         * @param {Array<number>} [categoryList] 
         * @param {number} [clientId] 
         * @param {number} [enqueteType] 
         * @param {Array<number>} [questionLevels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionList(id?: number, content?: string, categoryList?: Array<number>, clientId?: number, enqueteType?: number, questionLevels?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionList(id, content, categoryList, clientId, enqueteType, questionLevels, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionListApi - factory interface
 * @export
 */
export const QuestionListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionListApiFp(configuration)
    return {
        /**
         * 設問一覧を取得する
         * @summary 設問一覧取得
         * @param {number} [id] 
         * @param {string} [content] 
         * @param {Array<number>} [categoryList] 
         * @param {number} [clientId] 
         * @param {number} [enqueteType] 
         * @param {Array<number>} [questionLevels] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionList(id?: number, content?: string, categoryList?: Array<number>, clientId?: number, enqueteType?: number, questionLevels?: Array<number>, options?: any): AxiosPromise<Array<QuestionListOutputResponse>> {
            return localVarFp.questionList(id, content, categoryList, clientId, enqueteType, questionLevels, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionListApi - object-oriented interface
 * @export
 * @class QuestionListApi
 * @extends {BaseAPI}
 */
export class QuestionListApi extends BaseAPI {
    /**
     * 設問一覧を取得する
     * @summary 設問一覧取得
     * @param {number} [id] 
     * @param {string} [content] 
     * @param {Array<number>} [categoryList] 
     * @param {number} [clientId] 
     * @param {number} [enqueteType] 
     * @param {Array<number>} [questionLevels] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionListApi
     */
    public questionList(id?: number, content?: string, categoryList?: Array<number>, clientId?: number, enqueteType?: number, questionLevels?: Array<number>, options?: AxiosRequestConfig) {
        return QuestionListApiFp(this.configuration).questionList(id, content, categoryList, clientId, enqueteType, questionLevels, options).then((request) => request(this.axios, this.basePath));
    }
}
