import { addMonths, format } from 'date-fns';
import { LOCALDATE_HYPHEN_SHORT_FORMAT } from '../Constants';
import { BillingInvoiceDetailContractOutputResponse } from '../api-client';
import { BILLING_FEE_RATE_TYPE, BILLING_FLOW_STATUS, BILLING_STATUS } from '../BillingConstants';

/**
 * 数値を金額表示に変更する
 * @param v
 */
export const convertMoneyText = (v: number | undefined | null) => {
  if (v === undefined || v === null) {
    return 'ー';
  }
  return `¥${v.toLocaleString()}`;
};

/**
 * 検索時に使用する日付を取得する
 */
export const getTargetMonth = () => {
  let date = new Date();
  if (date.getDate() < 16) {
    date = addMonths(date, -1);
  }
  return format(date, LOCALDATE_HYPHEN_SHORT_FORMAT);
};

/**
 * 税抜き小計を計算
 * @param billingContract
 */
export const calcNonTaxSubTotal = (billingContract: BillingInvoiceDetailContractOutputResponse) => {
  let amount = 0;
  billingContract.billingInvoiceDetailItemList.map((billingItemDetail) => {
    if (billingItemDetail.billingStatus === BILLING_STATUS.BILLING) {
      amount += billingItemDetail.amount;
    }
  });
  return amount;
};

/**
 * フィーの計算を行う
 * 固定の場合は上限下限の設定がされていても無視する
 * @param feeType
 * @param amount
 * @param feeValue 100倍された値を持ってるのでパーセントに直して使う
 * @param lower
 * @param upper
 */
export const calcFee = (feeType: string, amount: number, feeValue: number, lower?: number, upper?: number) => {
  const fee = feeType === BILLING_FEE_RATE_TYPE.RATIO ? Math.floor((amount * feeValue) / 100) : feeValue;
  if (feeType === BILLING_FEE_RATE_TYPE.RATIO && lower !== undefined && fee < lower) {
    return lower;
  }
  if (feeType === BILLING_FEE_RATE_TYPE.RATIO && upper !== undefined && fee > upper) {
    return upper;
  }
  return fee;
};

/**
 * 税抜き合計を計算
 * @param amountPerTaxRate
 */
export const calcNonTaxAmount = (amountPerTaxRate: number[][] | undefined) => {
  let amount = 0;

  amountPerTaxRate?.map((v) => {
    amount += v[1];
  });
  return amount;
};

/**
 * 税区分ごとに合計金額を計算する
 * @param data
 */
export const calcAmountPerTaxRate = (
  data: BillingInvoiceDetailContractOutputResponse[] | undefined
): number[][] | undefined => {
  if (data === undefined || data.length === 0) {
    return undefined;
  }

  const amount = new Map<number, number>();
  // 合計を出す
  data?.map((billingContractList) => {
    billingContractList.billingInvoiceDetailItemList.map((itemDetail) => {
      if (itemDetail.billingStatus === BILLING_STATUS.BILLING) {
        const tmp = amount.get(itemDetail.taxRate);
        if (tmp) {
          // すでに同一の税率で金額がある
          amount.set(itemDetail.taxRate, itemDetail.amount + tmp);
        } else {
          amount.set(itemDetail.taxRate, itemDetail.amount);
        }
      }
    });
  });

  return Array.from(
    new Map(
      [...amount].sort((a, b) => {
        return b[0] - a[0];
      })
    ).entries()
  );
};

/**
 * 率の表記を返す
 * isTaxがtrueの場合、0パーセントは非課税表記になる
 * @param rate 値
 * @param isTax 消費税かどうか
 */
export const getRateText = (rate: number, isTax = true) => {
  if (isTax) {
    return rate === 0 ? '非課税' : `${rate}%`;
  }
  return `${rate}%`;
};

/**
 * 税区分ごとの消費税額を計算する
 * @param amountPerTaxRate 税率と税抜きのmap
 */
export const calcTaxAmountPerTaxRate = (amountPerTaxRate: number[][] | undefined) => {
  const taxAmount = new Map<number, number>();
  amountPerTaxRate?.map((v) => {
    if (v[0] !== 0) {
      // 税率0の場合は消費税は必ず0なのでスキップする
      taxAmount.set(v[0], Math.floor(v[1] * (v[0] / 100)));
    }
  });
  return taxAmount;
};

/**
 * 税区分ごとの消費税額を計算する
 * @param data
 */
export const calcTaxAmountPerTaxRate2 = (
  data: BillingInvoiceDetailContractOutputResponse[] | undefined
): undefined | number[][] => {
  if (data === undefined || data.length === 0) {
    return undefined;
  }
  const amountPerTaxRate = calcAmountPerTaxRate(data);
  const taxAmount = new Map<number, number>();
  amountPerTaxRate?.map((v) => {
    if (v[0] !== 0) {
      // 税率0の場合は消費税は必ず0なのでスキップする
      taxAmount.set(v[0], Math.floor(v[1] * (v[0] / 100)));
    }
  });
  return Array.from(taxAmount.entries());
};

/**
 * 税込み合計を算出する
 * @param amountPerTaxRate 税率と税抜きのmap
 */
export const calcIncludeTaxAmount = (amountPerTaxRate: number[][] | undefined) => {
  let amount = 0;
  amountPerTaxRate?.map((v) => {
    amount += v[1] + Math.floor(v[1] * (v[0] / 100));
  });
  return amount;
};

/**
 * 管理部承認が終わったかどうか
 * @param billingFlowStatus
 */
export const isManageDptCommitted = (billingFlowStatus: number) => {
  return billingFlowStatus >= BILLING_FLOW_STATUS.UNPUBLISHED;
};

/**
 * NaNをundefinedに変換する
 * 一旦検索に使うのみの想定なので、ojbが配列の要素を持っている場合などは考慮しない
 * @param obj
 */
export const convertNanToUndefined = (obj: any) => {
  const newObj = Object.fromEntries(
    // 配列に変換して map を実行、その後 fromEntries でオブジェクトに戻します
    Object.entries(obj).map(([key, value]) => {
      if (Number.isNaN(value)) {
        return [key, undefined];
      }
      return [key, value];
    })
  );
  return newObj as any;
};

/**
 * 特定の文字数でsplitした結果を返す
 * 文字数のカウントは半角は1、全角は2としては扱う
 * @param count
 * @param str
 */
export const getStringLenght = (str: string) => {
  let length = 0;
  for (let i = 0; i < str.length; i += 1) {
    const c = str.charCodeAt(i);
    if ((c >= 0x0 && c < 0x81) || c === 0xf8f0 || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
      length += 1;
    } else {
      length += 2;
    }
  }
  return length;
};

/**
 * PDF出力するにあたって文字数を制限する
 * colの幅に応じて文字数は変わる
 * @param colSize
 * @param str
 */
export const getRowHeightByStrLen = (str: string, colSize: number, maxSize: number) => {
  return Math.min(Math.floor(getStringLenght(str) / (7 * colSize)) + 1, maxSize);
};

/**
 * 文字列をnumberのarrayにして返却
 * @param str
 * @param separator
 */
export const convertNumberArray = (str: string | null | undefined, separator = ',') => {
  const list = str?.split(separator);
  return list?.map((v) => Number(v));
};

export default {
  convertMoneyText,
  getTargetMonth,
  calcNonTaxSubTotal,
  calcAmountPerTaxRate,
  calcTaxAmountPerTaxRate,
  calcIncludeTaxAmount,
  isManageDptCommitted,
  convertNanToUndefined,
  calcFee,
  getStringLenght,
  getRowHeightByStrLen,
  convertNumberArray,
};
