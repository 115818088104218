/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OemCustomerApplyCsvDownloadOutputResponse } from '../domains';
// @ts-ignore
import { OemCustomerApplyOutputResponse } from '../domains';
/**
 * OemCustomerApplyListApi - axios parameter creator
 * @export
 */
export const OemCustomerApplyListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 会員・応募情報を照会する
         * @summary 会員・応募情報照会
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [customerId] 
         * @param {number} [applyId] 
         * @param {number} [apiClientId] 
         * @param {number} [applyStatusCode] 
         * @param {string} [completeAtStart] 
         * @param {string} [completeAtEnd] 
         * @param {string} [createAtStart] 
         * @param {string} [createAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemCustomerApplyList: async (pageNum: number, pagePerItems: number, customerId?: number, applyId?: number, apiClientId?: number, applyStatusCode?: number, completeAtStart?: string, completeAtEnd?: string, createAtStart?: string, createAtEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNum' is not null or undefined
            assertParamExists('oemCustomerApplyList', 'pageNum', pageNum)
            // verify required parameter 'pagePerItems' is not null or undefined
            assertParamExists('oemCustomerApplyList', 'pagePerItems', pagePerItems)
            const localVarPath = `/api/oem-customer-apply-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (applyId !== undefined) {
                localVarQueryParameter['applyId'] = applyId;
            }

            if (apiClientId !== undefined) {
                localVarQueryParameter['apiClientId'] = apiClientId;
            }

            if (applyStatusCode !== undefined) {
                localVarQueryParameter['applyStatusCode'] = applyStatusCode;
            }

            if (completeAtStart !== undefined) {
                localVarQueryParameter['completeAtStart'] = completeAtStart;
            }

            if (completeAtEnd !== undefined) {
                localVarQueryParameter['completeAtEnd'] = completeAtEnd;
            }

            if (createAtStart !== undefined) {
                localVarQueryParameter['createAtStart'] = createAtStart;
            }

            if (createAtEnd !== undefined) {
                localVarQueryParameter['createAtEnd'] = createAtEnd;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pagePerItems !== undefined) {
                localVarQueryParameter['pagePerItems'] = pagePerItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 会員・応募情報を照会する
         * @summary 会員・応募情報照会
         * @param {number} [customerId] 
         * @param {number} [applyId] 
         * @param {number} [apiClientId] 
         * @param {number} [applyStatusCode] 
         * @param {string} [completeAtStart] 
         * @param {string} [completeAtEnd] 
         * @param {string} [createAtStart] 
         * @param {string} [createAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemCustomerApplyListCsvDownload: async (customerId?: number, applyId?: number, apiClientId?: number, applyStatusCode?: number, completeAtStart?: string, completeAtEnd?: string, createAtStart?: string, createAtEnd?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/oem-customer-apply-list-csv-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (applyId !== undefined) {
                localVarQueryParameter['applyId'] = applyId;
            }

            if (apiClientId !== undefined) {
                localVarQueryParameter['apiClientId'] = apiClientId;
            }

            if (applyStatusCode !== undefined) {
                localVarQueryParameter['applyStatusCode'] = applyStatusCode;
            }

            if (completeAtStart !== undefined) {
                localVarQueryParameter['completeAtStart'] = completeAtStart;
            }

            if (completeAtEnd !== undefined) {
                localVarQueryParameter['completeAtEnd'] = completeAtEnd;
            }

            if (createAtStart !== undefined) {
                localVarQueryParameter['createAtStart'] = createAtStart;
            }

            if (createAtEnd !== undefined) {
                localVarQueryParameter['createAtEnd'] = createAtEnd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OemCustomerApplyListApi - functional programming interface
 * @export
 */
export const OemCustomerApplyListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OemCustomerApplyListApiAxiosParamCreator(configuration)
    return {
        /**
         * 会員・応募情報を照会する
         * @summary 会員・応募情報照会
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [customerId] 
         * @param {number} [applyId] 
         * @param {number} [apiClientId] 
         * @param {number} [applyStatusCode] 
         * @param {string} [completeAtStart] 
         * @param {string} [completeAtEnd] 
         * @param {string} [createAtStart] 
         * @param {string} [createAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemCustomerApplyList(pageNum: number, pagePerItems: number, customerId?: number, applyId?: number, apiClientId?: number, applyStatusCode?: number, completeAtStart?: string, completeAtEnd?: string, createAtStart?: string, createAtEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OemCustomerApplyOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemCustomerApplyList(pageNum, pagePerItems, customerId, applyId, apiClientId, applyStatusCode, completeAtStart, completeAtEnd, createAtStart, createAtEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 会員・応募情報を照会する
         * @summary 会員・応募情報照会
         * @param {number} [customerId] 
         * @param {number} [applyId] 
         * @param {number} [apiClientId] 
         * @param {number} [applyStatusCode] 
         * @param {string} [completeAtStart] 
         * @param {string} [completeAtEnd] 
         * @param {string} [createAtStart] 
         * @param {string} [createAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemCustomerApplyListCsvDownload(customerId?: number, applyId?: number, apiClientId?: number, applyStatusCode?: number, completeAtStart?: string, completeAtEnd?: string, createAtStart?: string, createAtEnd?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OemCustomerApplyCsvDownloadOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemCustomerApplyListCsvDownload(customerId, applyId, apiClientId, applyStatusCode, completeAtStart, completeAtEnd, createAtStart, createAtEnd, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OemCustomerApplyListApi - factory interface
 * @export
 */
export const OemCustomerApplyListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OemCustomerApplyListApiFp(configuration)
    return {
        /**
         * 会員・応募情報を照会する
         * @summary 会員・応募情報照会
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [customerId] 
         * @param {number} [applyId] 
         * @param {number} [apiClientId] 
         * @param {number} [applyStatusCode] 
         * @param {string} [completeAtStart] 
         * @param {string} [completeAtEnd] 
         * @param {string} [createAtStart] 
         * @param {string} [createAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemCustomerApplyList(pageNum: number, pagePerItems: number, customerId?: number, applyId?: number, apiClientId?: number, applyStatusCode?: number, completeAtStart?: string, completeAtEnd?: string, createAtStart?: string, createAtEnd?: string, options?: any): AxiosPromise<OemCustomerApplyOutputResponse> {
            return localVarFp.oemCustomerApplyList(pageNum, pagePerItems, customerId, applyId, apiClientId, applyStatusCode, completeAtStart, completeAtEnd, createAtStart, createAtEnd, options).then((request) => request(axios, basePath));
        },
        /**
         * 会員・応募情報を照会する
         * @summary 会員・応募情報照会
         * @param {number} [customerId] 
         * @param {number} [applyId] 
         * @param {number} [apiClientId] 
         * @param {number} [applyStatusCode] 
         * @param {string} [completeAtStart] 
         * @param {string} [completeAtEnd] 
         * @param {string} [createAtStart] 
         * @param {string} [createAtEnd] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemCustomerApplyListCsvDownload(customerId?: number, applyId?: number, apiClientId?: number, applyStatusCode?: number, completeAtStart?: string, completeAtEnd?: string, createAtStart?: string, createAtEnd?: string, options?: any): AxiosPromise<OemCustomerApplyCsvDownloadOutputResponse> {
            return localVarFp.oemCustomerApplyListCsvDownload(customerId, applyId, apiClientId, applyStatusCode, completeAtStart, completeAtEnd, createAtStart, createAtEnd, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OemCustomerApplyListApi - object-oriented interface
 * @export
 * @class OemCustomerApplyListApi
 * @extends {BaseAPI}
 */
export class OemCustomerApplyListApi extends BaseAPI {
    /**
     * 会員・応募情報を照会する
     * @summary 会員・応募情報照会
     * @param {number} pageNum 
     * @param {number} pagePerItems 
     * @param {number} [customerId] 
     * @param {number} [applyId] 
     * @param {number} [apiClientId] 
     * @param {number} [applyStatusCode] 
     * @param {string} [completeAtStart] 
     * @param {string} [completeAtEnd] 
     * @param {string} [createAtStart] 
     * @param {string} [createAtEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OemCustomerApplyListApi
     */
    public oemCustomerApplyList(pageNum: number, pagePerItems: number, customerId?: number, applyId?: number, apiClientId?: number, applyStatusCode?: number, completeAtStart?: string, completeAtEnd?: string, createAtStart?: string, createAtEnd?: string, options?: AxiosRequestConfig) {
        return OemCustomerApplyListApiFp(this.configuration).oemCustomerApplyList(pageNum, pagePerItems, customerId, applyId, apiClientId, applyStatusCode, completeAtStart, completeAtEnd, createAtStart, createAtEnd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 会員・応募情報を照会する
     * @summary 会員・応募情報照会
     * @param {number} [customerId] 
     * @param {number} [applyId] 
     * @param {number} [apiClientId] 
     * @param {number} [applyStatusCode] 
     * @param {string} [completeAtStart] 
     * @param {string} [completeAtEnd] 
     * @param {string} [createAtStart] 
     * @param {string} [createAtEnd] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OemCustomerApplyListApi
     */
    public oemCustomerApplyListCsvDownload(customerId?: number, applyId?: number, apiClientId?: number, applyStatusCode?: number, completeAtStart?: string, completeAtEnd?: string, createAtStart?: string, createAtEnd?: string, options?: AxiosRequestConfig) {
        return OemCustomerApplyListApiFp(this.configuration).oemCustomerApplyListCsvDownload(customerId, applyId, apiClientId, applyStatusCode, completeAtStart, completeAtEnd, createAtStart, createAtEnd, options).then((request) => request(this.axios, this.basePath));
    }
}
