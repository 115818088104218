import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
/* eslint-disable import/no-cycle */
import {
  MonitorBaseInfoApi,
  MonitorBaseUpdateApi,
  MonitorBaseInfoOutputResponse,
  MonitorBaseUpdateFormResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { DispatchSetState } from '../../../interfaces/utils';
// import { monitorBaseInfoApi } from '../../../test/mocks/api/MonitorBaseInfoApi';
import { createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Alert } from '../../atoms/Alert';
import { Button } from '../../atoms/Button';
import { TagsInput } from '../../molecules/TagsInput';
import { Modal } from '../../molecules/Modal';
/* eslint-enable import/no-cycle */

export interface Props {
  isModal: boolean;
  setIsModal: DispatchSetState<boolean>;
  monitorBaseId: number;
  setMonitorBaseId: DispatchSetState<number>;
  modifyFlg: boolean;
}

export interface State {
  getApi: MonitorBaseInfoApi;
  // getApi: ReturnType<typeof monitorBaseInfoApi>;
  updateApi: MonitorBaseUpdateApi;
  alias: MonitorBaseUpdateFormResponse['alias'];
  textTags: MonitorBaseUpdateFormResponse['textTags'];
  countdownDefault: MonitorBaseUpdateFormResponse['countdownDefault'];
  submissionDeadline: MonitorBaseUpdateFormResponse['submissionDeadline'];
  updResult: IncResultOutputResponse;
  monitorBaseName: MonitorBaseInfoOutputResponse['monitorBaseName'];
  contractShopName: MonitorBaseInfoOutputResponse['contractShopName'];
}

export const BasicInfoModal: React.VFC<Props> = ({
  isModal,
  setIsModal,
  monitorBaseId,
  setMonitorBaseId,
  modifyFlg,
}) => {
  const testid = createTestId(BasicInfoModal);

  const {
    state: $,
    mergeState,
    onChangeSet,
  } = useLargeState<State>({
    getApi: new MonitorBaseInfoApi(),
    updateApi: new MonitorBaseUpdateApi(),
    alias: '',
    textTags: [],
    countdownDefault: undefined,
    submissionDeadline: undefined,
    updResult: { result: false },
    monitorBaseName: '',
    contractShopName: '',
  });

  const [tags, setTags] = useState<string[]>([]);

  const [errorFlg, setErrorFlg] = useState<boolean>(false);

  const onHide = () => {
    setMonitorBaseId(0);
    setErrorFlg(false);
    setIsModal(false);
  };

  useEffect(
    () => mergeState({ textTags: tags.map((name) => ({ id: $.textTags.find((t) => t.name === name)?.id, name })) }),
    [tags, mergeState]
  );

  useEffect(() => {
    if (!isModal || !monitorBaseId) return;

    $.getApi.monitorBaseInfo(monitorBaseId).then((res: AxiosResponse<MonitorBaseInfoOutputResponse>) => {
      mergeState(res.data);
      setTags(res.data.textTags.map(({ name }) => name));
    });
  }, [isModal, monitorBaseId, $.getApi, mergeState, setTags]);

  const onSave = () => {
    const { alias, textTags, countdownDefault, submissionDeadline } = $;
    if (!alias) {
      setErrorFlg(true);
      return;
    }
    $.updateApi
      .monitorBaseUpdate({ monitorBaseId, alias, textTags, countdownDefault, submissionDeadline })
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
        onHide();
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  return (
    <Modal
      onHide={onHide}
      isModal={isModal}
      closeButton
      centered
      size="lg"
      title={`契約店舗名：${$.contractShopName}, モニター名：${$.monitorBaseName}`}
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button
              type="button"
              className="ms-2"
              onClick={onSave}
              data-testid={testid('save-button')}
              disabled={!modifyFlg}
            >
              保存
            </Button>
          </div>

          {$.updResult.errorMessage && (
            <Alert testId={testid('failure-alert')} variant="danger">
              {`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}
            </Alert>
          )}

          {errorFlg && <Alert variant="danger">モニターベース別名（集計用名称）を入力してください。</Alert>}

          <Form.Group className="mb-4" data-testid={testid('alias')}>
            <Form.Label>
              モニターベース別名（集計用名称）<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control type="text" value={$.alias || ''} onChange={onChangeSet('alias')} disabled={!modifyFlg} />
          </Form.Group>

          <Form.Group className="mb-4" data-testid={testid('textTags')}>
            <Form.Label>テキストタグ</Form.Label>
            {modifyFlg ? (
              <TagsInput tags={tags} setTags={setTags} pill setButton />
            ) : (
              <TagsInput tags={tags} setTags={setTags} disabled pill />
            )}
          </Form.Group>

          <Form.Group className="mb-4" data-testid={testid('countdownDefault')}>
            <Form.Label>カウントダウン(デフォルト)</Form.Label>
            <Form.Select
              value={$.countdownDefault ?? ''}
              onChange={onChangeSet('countdownDefault', Number)}
              disabled={!modifyFlg}
            >
              <option value="">&nbsp;</option>
              {[...Array(18)].map((_, i) => (
                <option key={i + 11} value={i + 11}>
                  {i + 11}日
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group data-testid={testid('submissionDeadline')}>
            <Form.Label>提出期限</Form.Label>
            <div className="d-flex align-items-end">
              <Form.Control
                type="number"
                min={0}
                value={$.submissionDeadline ?? ''}
                onChange={onChangeSet('submissionDeadline', Number)}
                disabled={!modifyFlg}
              />
              <span className="text-nowrap">日間</span>
            </div>
          </Form.Group>
        </>
      }
    />
  );
};
