import React, { Fragment } from 'react';
import { formatISODate, uuid } from '../../../../../utils/functions';
import { BadgeHead } from '../../../../atoms/BadgeHead';
import { SideBy } from '../../../../atoms/SideBy';
import { TinyHead } from '../../../../atoms/TinyHead';

export const SituatorPost: React.FC<{
  posts: {
    text?: string;
    role?: string;
    id?: string;
    name?: string;
    at: string;
    from?: string;
    ticketUrl?: string;
  }[];
}> = React.memo(({ posts }) => (
  <>
    {posts.map(({ text, role, id, name, at, from, ticketUrl }, pi, { length: postLength }) => {
      return (
        <Fragment key={uuid()}>
          <p>
            {(text ?? '').split(/\n/).map((line, i, { length }) => (
              <span key={uuid()}>
                {line}
                {i !== length - 1 ? <br /> : <></>}
              </span>
            ))}
          </p>
          <SideBy style={{ color: 'gray' }}>
            {role && <BadgeHead>{role}</BadgeHead>}
            {id && <span>ID: {id}</span>}
            {name && <span>{name}</span>}
            {from && <span>{from}</span>}
          </SideBy>
          <span style={{ color: 'gray' }}>{formatISODate(at, 'yyyy-MM-dd HH:mm:ss')}</span>
          {ticketUrl && (
            <>
              <TinyHead>エスカレチケットURL</TinyHead>
              <a href={ticketUrl}>{ticketUrl}</a>
            </>
          )}
          {pi !== postLength - 1 ? <hr /> : <></>}
        </Fragment>
      );
    })}
    <hr />
  </>
));
