import React, { useMemo, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import type { CustomerListState } from '../../pages/Customer/CustomerListPage';
import { InputNumber } from '../../atoms/InputNumber';
import { OemSelectBox } from '../../atoms/OemSelectBox';

export type State = CustomerListState['forms'];
interface Props {
  setForms: DispatchSetState<State>;
}

export type TestIds = keyof State;

export const CustomerListForms: React.FC<Props> = ({ setForms }) => {
  const testId = createTestId<TestIds>(CustomerListForms);
  const { state: $form, onChangeSet } = useLargeState<CustomerListState['forms']>({ oemId: -1, moral: -1 });

  useEffect(() => {
    setForms({ ...$form });
  }, [setForms, $form]);

  const oemOptions: [string, number][] = useMemo(
    () =>
      Object.entries({
        選択してください: -1,
        ファンくる: 1,
        Tモニター: 2,
      }),
    []
  );

  const moralOptions: [string, number][] = useMemo(
    () =>
      Object.entries({
        選択してください: -1,
        '普通(当選率100%)': 0,
        '悪い1回目(当選率40%)': 1,
        '悪い2回目(当選率40→0%)': 2,
        '適正なし(当選率0%)': 3,
        '再登録不可(当選率0%)': 4,
      }),
    []
  );

  return (
    <>
      <Row>
        <Form.Group data-testid={testId('customerId')} controlId="customerId" key="customerId" className="col-md-3">
          <Form.Label>会員ID</Form.Label>
          <InputNumber value={$form.customerId} onChange={onChangeSet('customerId', Number)} />
        </Form.Group>
        <Form.Group data-testid={testId('oemId')} controlId="oemId" key="oemId" className="col-md-3">
          <Form.Label>OEM</Form.Label>
          <OemSelectBox onChange={onChangeSet('oemId', String)} />
        </Form.Group>
        <Form.Group
          data-testid={testId('customerName')}
          controlId="customerName"
          key="customerName"
          className="col-md-3"
        >
          <Form.Label>名前 </Form.Label>
          <Form.Control
            type="text"
            autoComplete="off"
            value={$form.customerName || ''}
            onChange={onChangeSet('customerName', String)}
          />
        </Form.Group>
        <Form.Group data-testid={testId('birthday')} controlId="birthday" key="birthday" className="col-md-3">
          <Form.Label>生年月日 </Form.Label>
          <Form.Control
            type="date"
            autoComplete="off"
            value={$form.birthday || ''}
            onChange={onChangeSet('birthday')}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group data-testid={testId('mailAddress')} controlId="mailAddress" key="mailAddress" className="col-md-4">
          <Form.Label>メールアドレス</Form.Label>
          <Form.Control
            type="text"
            autoComplete="off"
            value={$form.mailAddress || ''}
            onChange={onChangeSet('mailAddress', String)}
          />
        </Form.Group>
        <Form.Group data-testid={testId('phoneNumber')} controlId="phoneNumber" key="phoneNumber" className="col-md-4">
          <Form.Label>電話番号 </Form.Label>
          <Form.Control
            type="text"
            autoComplete="off"
            value={$form.phoneNumber || ''}
            onChange={onChangeSet('phoneNumber', String)}
          />
        </Form.Group>
        <Form.Group data-testid={testId('moral')} controlId="moral" key="moral" className="col-md-4">
          <Form.Label>モラル </Form.Label>
          <Form.Select value={$form.moral} onChange={onChangeSet('moral', String)}>
            {moralOptions.map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
    </>
  );
};
