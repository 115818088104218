/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BillingAgentFeeDetailOutputResponse } from '../domains';
// @ts-ignore
import { BillingAgentFeeListOutputResponse } from '../domains';
// @ts-ignore
import { BillingCsvAllDownloadOutputResponse } from '../domains';
// @ts-ignore
import { BillingDeliveryNoteUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationAutoApprovalUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationContractBulkDeleteFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationContractBulkUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationContractDeleteFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationContractListOutputResponse } from '../domains';
// @ts-ignore
import { BillingDestinationContractUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationCopyFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationCreateFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationDetailOutputResponse } from '../domains';
// @ts-ignore
import { BillingDestinationInfoOutputResponse } from '../domains';
// @ts-ignore
import { BillingDestinationListOutputResponse } from '../domains';
// @ts-ignore
import { BillingDestinationRelationHeaderOutputResponse } from '../domains';
// @ts-ignore
import { BillingDestinationShopBulkDeleteFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationShopBulkUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationShopDeleteFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationShopListOutputResponse } from '../domains';
// @ts-ignore
import { BillingDestinationShopUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingDestinationUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingDivideInvoiceFormResponse } from '../domains';
// @ts-ignore
import { BillingFileDownloadFormResponse } from '../domains';
// @ts-ignore
import { BillingFileUploadFormResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceApproverListOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceCarryOverFormResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceDetailApplicationSearchForPdfOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceDetailApplicationSearchOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceDetailApplicationUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceDetailHeaderOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceDetailItemResultForPdfOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceDetailItemResultOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceDetailItemUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceDownloadCsvFormResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceDownloadListOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceFileUploadValidationFormResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceFileUploadValidationOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceFlowStatusOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceListOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceStatusUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceStoreDetailItemOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceTotalingDetailItemForPdfOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceTotalingDetailItemOutputResponse } from '../domains';
// @ts-ignore
import { BillingInvoiceTotalingDetailItemUpdateFormResponse } from '../domains';
// @ts-ignore
import { BillingPdfAllDownloadFormResponse } from '../domains';
// @ts-ignore
import { BillingPdfAllDownloadOutputResponse } from '../domains';
// @ts-ignore
import { BillingPdfDownloadOutputResponse } from '../domains';
// @ts-ignore
import { BillingSuggestListOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { JobExecutionHistoryOutputResponse } from '../domains';
// @ts-ignore
import { PaidApplyFormResponse } from '../domains';
// @ts-ignore
import { PrefecturesByPostalcodeOutputResponse } from '../domains';
// @ts-ignore
import { UnBillingApplyFormResponse } from '../domains';
// @ts-ignore
import { UnBillingApprovalListOutputResponse } from '../domains';
// @ts-ignore
import { UnBillingApproveFormResponse } from '../domains';
// @ts-ignore
import { UnBillingApproveOutputResponse } from '../domains';
// @ts-ignore
import { UnBillingListOutputResponse } from '../domains';
/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 代理店フィー明細を取得する
         * @summary 代理店フィー明細取得
         * @param {number} [agentFeeListHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentFeeDetail: async (agentFeeListHeaderId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing-agent/billing-agent-fee-detail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (agentFeeListHeaderId !== undefined) {
                localVarQueryParameter['agentFeeListHeaderId'] = agentFeeListHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 代理店フィー一覧を取得する
         * @summary 代理店フィー一覧取得
         * @param {number} [billingAgentId] 
         * @param {number} [salesIncAccountId] 
         * @param {number} [clientId] 
         * @param {string} [targetMonth] 
         * @param {number} [agentFeeStatus] 
         * @param {boolean} [hasUnapproved] 
         * @param {number} [approverIncAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentFeeList: async (billingAgentId?: number, salesIncAccountId?: number, clientId?: number, targetMonth?: string, agentFeeStatus?: number, hasUnapproved?: boolean, approverIncAccountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing-agent/billing-agent-fee-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingAgentId !== undefined) {
                localVarQueryParameter['billingAgentId'] = billingAgentId;
            }

            if (salesIncAccountId !== undefined) {
                localVarQueryParameter['salesIncAccountId'] = salesIncAccountId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (targetMonth !== undefined) {
                localVarQueryParameter['targetMonth'] = targetMonth;
            }

            if (agentFeeStatus !== undefined) {
                localVarQueryParameter['agentFeeStatus'] = agentFeeStatus;
            }

            if (hasUnapproved !== undefined) {
                localVarQueryParameter['hasUnapproved'] = hasUnapproved;
            }

            if (approverIncAccountId !== undefined) {
                localVarQueryParameter['approverIncAccountId'] = approverIncAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書承認者リストの取得をする
         * @summary 請求書承認者リストの取得
         * @param {number} incAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingApproverList: async (incAccountId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'incAccountId' is not null or undefined
            assertParamExists('billingApproverList', 'incAccountId', incAccountId)
            const localVarPath = `/api/billing/billing-approver-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (incAccountId !== undefined) {
                localVarQueryParameter['incAccountId'] = incAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 配送メモを更新する
         * @summary 配送メモ更新
         * @param {BillingDeliveryNoteUpdateFormResponse} billingDeliveryNoteUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDeliveryNoteUpdate: async (billingDeliveryNoteUpdateFormResponse: BillingDeliveryNoteUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDeliveryNoteUpdateFormResponse' is not null or undefined
            assertParamExists('billingDeliveryNoteUpdate', 'billingDeliveryNoteUpdateFormResponse', billingDeliveryNoteUpdateFormResponse)
            const localVarPath = `/api/billing/deliverynote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDeliveryNoteUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先と契約の紐付けを一括解除する
         * @summary 請求送付先契約紐付け一括解除
         * @param {BillingDestinationContractBulkDeleteFormResponse} billingDestinationContractBulkDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractBulkDelete: async (billingDestinationContractBulkDeleteFormResponse: BillingDestinationContractBulkDeleteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationContractBulkDeleteFormResponse' is not null or undefined
            assertParamExists('billingDestContractBulkDelete', 'billingDestinationContractBulkDeleteFormResponse', billingDestinationContractBulkDeleteFormResponse)
            const localVarPath = `/api/billing/billing-dest-contract-bulk-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationContractBulkDeleteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先と契約の紐付けを一括紐づけする
         * @summary 請求送付先契約紐付け一括紐づけ
         * @param {BillingDestinationContractBulkUpdateFormResponse} billingDestinationContractBulkUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractBulkUpdate: async (billingDestinationContractBulkUpdateFormResponse: BillingDestinationContractBulkUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationContractBulkUpdateFormResponse' is not null or undefined
            assertParamExists('billingDestContractBulkUpdate', 'billingDestinationContractBulkUpdateFormResponse', billingDestinationContractBulkUpdateFormResponse)
            const localVarPath = `/api/billing/billing-dest-contract-bulk-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationContractBulkUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先契約の紐付けを解除する
         * @summary 請求送付先契約紐付け解除
         * @param {BillingDestinationContractDeleteFormResponse} billingDestinationContractDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractDelete: async (billingDestinationContractDeleteFormResponse: BillingDestinationContractDeleteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationContractDeleteFormResponse' is not null or undefined
            assertParamExists('billingDestContractDelete', 'billingDestinationContractDeleteFormResponse', billingDestinationContractDeleteFormResponse)
            const localVarPath = `/api/billing/billing-dest-contract-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationContractDeleteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先契約一覧を取得する
         * @summary 請求送付先_契約一覧取得
         * @param {number} originalBillingDestinationId 
         * @param {number} [clientId] 
         * @param {number} [incAccountId] 
         * @param {string} [contractShopIds] 
         * @param {number} [billingDestinationBranchNumber] 
         * @param {string} [billingDestinationName] 
         * @param {boolean} [relationFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractList: async (originalBillingDestinationId: number, clientId?: number, incAccountId?: number, contractShopIds?: string, billingDestinationBranchNumber?: number, billingDestinationName?: string, relationFlg?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'originalBillingDestinationId' is not null or undefined
            assertParamExists('billingDestContractList', 'originalBillingDestinationId', originalBillingDestinationId)
            const localVarPath = `/api/billing/billing-dest-contract-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (originalBillingDestinationId !== undefined) {
                localVarQueryParameter['originalBillingDestinationId'] = originalBillingDestinationId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (incAccountId !== undefined) {
                localVarQueryParameter['incAccountId'] = incAccountId;
            }

            if (contractShopIds !== undefined) {
                localVarQueryParameter['contractShopIds'] = contractShopIds;
            }

            if (billingDestinationBranchNumber !== undefined) {
                localVarQueryParameter['billingDestinationBranchNumber'] = billingDestinationBranchNumber;
            }

            if (billingDestinationName !== undefined) {
                localVarQueryParameter['billingDestinationName'] = billingDestinationName;
            }

            if (relationFlg !== undefined) {
                localVarQueryParameter['relationFlg'] = relationFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先と契約を紐付ける
         * @summary 請求送付先契約紐付け
         * @param {BillingDestinationContractUpdateFormResponse} billingDestinationContractUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractUpdate: async (billingDestinationContractUpdateFormResponse: BillingDestinationContractUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationContractUpdateFormResponse' is not null or undefined
            assertParamExists('billingDestContractUpdate', 'billingDestinationContractUpdateFormResponse', billingDestinationContractUpdateFormResponse)
            const localVarPath = `/api/billing/billing-dest-contract-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationContractUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先店舗の紐付けを一括解除する
         * @summary 請求送付先店舗紐付け一括解除
         * @param {BillingDestinationShopBulkDeleteFormResponse} billingDestinationShopBulkDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopBulkDelete: async (billingDestinationShopBulkDeleteFormResponse: BillingDestinationShopBulkDeleteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationShopBulkDeleteFormResponse' is not null or undefined
            assertParamExists('billingDestShopBulkDelete', 'billingDestinationShopBulkDeleteFormResponse', billingDestinationShopBulkDeleteFormResponse)
            const localVarPath = `/api/billing/billing-dest-shop-bulk-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationShopBulkDeleteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先店舗の紐付けを一括紐づけする
         * @summary 請求送付先店舗紐付け一括紐づけ
         * @param {BillingDestinationShopBulkUpdateFormResponse} billingDestinationShopBulkUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopBulkUpdate: async (billingDestinationShopBulkUpdateFormResponse: BillingDestinationShopBulkUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationShopBulkUpdateFormResponse' is not null or undefined
            assertParamExists('billingDestShopBulkUpdate', 'billingDestinationShopBulkUpdateFormResponse', billingDestinationShopBulkUpdateFormResponse)
            const localVarPath = `/api/billing/billing-dest-shop-bulk-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationShopBulkUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先店舗の紐付けを解除する
         * @summary 請求送付先店舗紐付け解除
         * @param {BillingDestinationShopDeleteFormResponse} billingDestinationShopDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopDelete: async (billingDestinationShopDeleteFormResponse: BillingDestinationShopDeleteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationShopDeleteFormResponse' is not null or undefined
            assertParamExists('billingDestShopDelete', 'billingDestinationShopDeleteFormResponse', billingDestinationShopDeleteFormResponse)
            const localVarPath = `/api/billing/billing-dest-shop-delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationShopDeleteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先店舗一覧を取得する
         * @summary 請求送付先店舗一覧取得
         * @param {number} originalBillingDestinationId 
         * @param {number} [clientId] 
         * @param {number} [salesId] 
         * @param {string} [shopId] 
         * @param {number} [branchNumber] 
         * @param {string} [billingDestName] 
         * @param {boolean} [relationFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopList: async (originalBillingDestinationId: number, clientId?: number, salesId?: number, shopId?: string, branchNumber?: number, billingDestName?: string, relationFlg?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'originalBillingDestinationId' is not null or undefined
            assertParamExists('billingDestShopList', 'originalBillingDestinationId', originalBillingDestinationId)
            const localVarPath = `/api/billing/billing-dest-shop-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (originalBillingDestinationId !== undefined) {
                localVarQueryParameter['originalBillingDestinationId'] = originalBillingDestinationId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (salesId !== undefined) {
                localVarQueryParameter['salesId'] = salesId;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['shopId'] = shopId;
            }

            if (branchNumber !== undefined) {
                localVarQueryParameter['branchNumber'] = branchNumber;
            }

            if (billingDestName !== undefined) {
                localVarQueryParameter['billingDestName'] = billingDestName;
            }

            if (relationFlg !== undefined) {
                localVarQueryParameter['relationFlg'] = relationFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先店舗を紐付ける
         * @summary 請求送付先店舗紐付け
         * @param {BillingDestinationShopUpdateFormResponse} billingDestinationShopUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopUpdate: async (billingDestinationShopUpdateFormResponse: BillingDestinationShopUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationShopUpdateFormResponse' is not null or undefined
            assertParamExists('billingDestShopUpdate', 'billingDestinationShopUpdateFormResponse', billingDestinationShopUpdateFormResponse)
            const localVarPath = `/api/billing/billing-dest-shop-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationShopUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先の自動承認を更新する
         * @summary 請求送付先自動承認更新
         * @param {BillingDestinationAutoApprovalUpdateFormResponse} billingDestinationAutoApprovalUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationAutoApprovalUpdate: async (billingDestinationAutoApprovalUpdateFormResponse: BillingDestinationAutoApprovalUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationAutoApprovalUpdateFormResponse' is not null or undefined
            assertParamExists('billingDestinationAutoApprovalUpdate', 'billingDestinationAutoApprovalUpdateFormResponse', billingDestinationAutoApprovalUpdateFormResponse)
            const localVarPath = `/api/billing/billingdestination-autoapproval-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationAutoApprovalUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先をコピーする
         * @summary 請求送付先コピー
         * @param {Array<BillingDestinationCopyFormResponse>} billingDestinationCopyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationCopy: async (billingDestinationCopyFormResponse: Array<BillingDestinationCopyFormResponse>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationCopyFormResponse' is not null or undefined
            assertParamExists('billingDestinationCopy', 'billingDestinationCopyFormResponse', billingDestinationCopyFormResponse)
            const localVarPath = `/api/billing/billing-destination/copy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationCopyFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先を作成する
         * @summary 請求送付先作成
         * @param {BillingDestinationCreateFormResponse} billingDestinationCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationCreate: async (billingDestinationCreateFormResponse: BillingDestinationCreateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationCreateFormResponse' is not null or undefined
            assertParamExists('billingDestinationCreate', 'billingDestinationCreateFormResponse', billingDestinationCreateFormResponse)
            const localVarPath = `/api/billing/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先明細を取得する
         * @summary 請求送付先明細取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationDetail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingDestinationDetail', 'id', id)
            const localVarPath = `/api/billing/billing-destination-detail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先等の情報を取得
         * @summary 請求送付先等の情報を取得
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationInfo: async (billingHeaderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingHeaderId' is not null or undefined
            assertParamExists('billingDestinationInfo', 'billingHeaderId', billingHeaderId)
            const localVarPath = `/api/billing/billing-destination-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先一覧を取得する
         * @summary 請求送付先一覧取得
         * @param {number} [clientId] 
         * @param {number} [incAccountId] 
         * @param {string} [billingDestinationName] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationList: async (clientId?: number, incAccountId?: number, billingDestinationName?: string, contractId?: number, contractShopId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/billing-destination-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (incAccountId !== undefined) {
                localVarQueryParameter['incAccountId'] = incAccountId;
            }

            if (billingDestinationName !== undefined) {
                localVarQueryParameter['billingDestinationName'] = billingDestinationName;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先紐づけ画面のヘッダ情報を取得
         * @summary 請求送付先紐づけ画面のヘッダ情報を取得
         * @param {number} billingDestinationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationRelationHeader: async (billingDestinationId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationId' is not null or undefined
            assertParamExists('billingDestinationRelationHeader', 'billingDestinationId', billingDestinationId)
            const localVarPath = `/api/billing/billing-destination-relation-header`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingDestinationId !== undefined) {
                localVarQueryParameter['billingDestinationId'] = billingDestinationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求送付先を更新する
         * @summary 請求送付先更新
         * @param {BillingDestinationUpdateFormResponse} billingDestinationUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationUpdate: async (billingDestinationUpdateFormResponse: BillingDestinationUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDestinationUpdateFormResponse' is not null or undefined
            assertParamExists('billingDestinationUpdate', 'billingDestinationUpdateFormResponse', billingDestinationUpdateFormResponse)
            const localVarPath = `/api/billing/billingdestination-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDestinationUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 按分設定データを作成する
         * @summary 按分手動作成
         * @param {BillingDivideInvoiceFormResponse} billingDivideInvoiceFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDivideInvoice: async (billingDivideInvoiceFormResponse: BillingDivideInvoiceFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingDivideInvoiceFormResponse' is not null or undefined
            assertParamExists('billingDivideInvoice', 'billingDivideInvoiceFormResponse', billingDivideInvoiceFormResponse)
            const localVarPath = `/api/billing/billing-divide-invoice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingDivideInvoiceFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書PDFダウンロード
         * @summary 請求PDF/Excelダウンロード
         * @param {BillingFileDownloadFormResponse} billingFileDownloadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingFileDownload: async (billingFileDownloadFormResponse: BillingFileDownloadFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingFileDownloadFormResponse' is not null or undefined
            assertParamExists('billingFileDownload', 'billingFileDownloadFormResponse', billingFileDownloadFormResponse)
            const localVarPath = `/api/billing/billing-file-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingFileDownloadFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書PDFデータのアップロード
         * @summary 請求書PDFデータのアップロード
         * @param {BillingFileUploadFormResponse} billingFileUploadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingFileUpload: async (billingFileUploadFormResponse: BillingFileUploadFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingFileUploadFormResponse' is not null or undefined
            assertParamExists('billingFileUpload', 'billingFileUploadFormResponse', billingFileUploadFormResponse)
            const localVarPath = `/api/billing/billing-file-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingFileUploadFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書の翌月繰越を行う
         * @summary 請求書翌月繰越
         * @param {BillingInvoiceCarryOverFormResponse} billingInvoiceCarryOverFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceCarryOver: async (billingInvoiceCarryOverFormResponse: BillingInvoiceCarryOverFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingInvoiceCarryOverFormResponse' is not null or undefined
            assertParamExists('billingInvoiceCarryOver', 'billingInvoiceCarryOverFormResponse', billingInvoiceCarryOverFormResponse)
            const localVarPath = `/api/billing/billing-invoice-carry-over`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingInvoiceCarryOverFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_派遣別明細を取得する
         * @summary 請求書_派遣別明細
         * @param {number} billingHeaderId 
         * @param {number} [contractShopId] 
         * @param {string} [applyIds] 
         * @param {string} [visitAt] 
         * @param {string} [completeAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailApplication: async (billingHeaderId: number, contractShopId?: number, applyIds?: string, visitAt?: string, completeAt?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingHeaderId' is not null or undefined
            assertParamExists('billingInvoiceDetailApplication', 'billingHeaderId', billingHeaderId)
            const localVarPath = `/api/billing/billing-invoice-detail-application-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (applyIds !== undefined) {
                localVarQueryParameter['applyIds'] = applyIds;
            }

            if (visitAt !== undefined) {
                localVarQueryParameter['visitAt'] = visitAt;
            }

            if (completeAt !== undefined) {
                localVarQueryParameter['completeAt'] = completeAt;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_派遣別明細を取得する。PDFダウンロード用
         * @summary 請求書_派遣別明細
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailApplicationForPdf: async (billingHeaderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingHeaderId' is not null or undefined
            assertParamExists('billingInvoiceDetailApplicationForPdf', 'billingHeaderId', billingHeaderId)
            const localVarPath = `/api/billing/billing-invoice-detail-application-search-for-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_派遣別明細を更新する
         * @summary 請求書_派遣別明細更新
         * @param {BillingInvoiceDetailApplicationUpdateFormResponse} billingInvoiceDetailApplicationUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailApplicationUpdate: async (billingInvoiceDetailApplicationUpdateFormResponse: BillingInvoiceDetailApplicationUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingInvoiceDetailApplicationUpdateFormResponse' is not null or undefined
            assertParamExists('billingInvoiceDetailApplicationUpdate', 'billingInvoiceDetailApplicationUpdateFormResponse', billingInvoiceDetailApplicationUpdateFormResponse)
            const localVarPath = `/api/billing/billing-invoice-detail-application-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingInvoiceDetailApplicationUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_明細ヘッダ情報を取得する
         * @summary 請求書_明細ヘッダ情報
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailHeader: async (billingHeaderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingHeaderId' is not null or undefined
            assertParamExists('billingInvoiceDetailHeader', 'billingHeaderId', billingHeaderId)
            const localVarPath = `/api/billing/billing-invoice-detail-header`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_商品別明細を取得する
         * @summary 請求書_商品別明細
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingInvoiceDetailItem', 'id', id)
            const localVarPath = `/api/billing/billing-invoice-detail-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_商品別明細を取得する
         * @summary 請求書_商品別明細
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailItemForPdf: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingInvoiceDetailItemForPdf', 'id', id)
            const localVarPath = `/api/billing/billing-invoice-detail-item-pdf/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_商品別明細を更新する
         * @summary 請求書_商品別明細更新
         * @param {BillingInvoiceDetailItemUpdateFormResponse} billingInvoiceDetailItemUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailItemUpdate: async (billingInvoiceDetailItemUpdateFormResponse: BillingInvoiceDetailItemUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingInvoiceDetailItemUpdateFormResponse' is not null or undefined
            assertParamExists('billingInvoiceDetailItemUpdate', 'billingInvoiceDetailItemUpdateFormResponse', billingInvoiceDetailItemUpdateFormResponse)
            const localVarPath = `/api/billing/billing-invoice-detail-item-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingInvoiceDetailItemUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書帳票(CSV)をダウンロードする
         * @summary 請求書帳票ダウンロードCSV
         * @param {BillingInvoiceDownloadCsvFormResponse} billingInvoiceDownloadCsvFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDownloadCsv: async (billingInvoiceDownloadCsvFormResponse: BillingInvoiceDownloadCsvFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingInvoiceDownloadCsvFormResponse' is not null or undefined
            assertParamExists('billingInvoiceDownloadCsv', 'billingInvoiceDownloadCsvFormResponse', billingInvoiceDownloadCsvFormResponse)
            const localVarPath = `/api/billing/billing-invoice-download-csv`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingInvoiceDownloadCsvFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書ダウンロード一覧を取得する
         * @summary 請求書ダウンロード一覧取得
         * @param {number} [clientId] 
         * @param {number} [salesIncAccountId] 
         * @param {number} [contractShopId] 
         * @param {string} [targetMonth] 
         * @param {Array<number>} [billingFlowStatuses] 
         * @param {boolean} [hasInformart] 
         * @param {string} [deliveryNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDownloadList: async (clientId?: number, salesIncAccountId?: number, contractShopId?: number, targetMonth?: string, billingFlowStatuses?: Array<number>, hasInformart?: boolean, deliveryNote?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/billing-invoice-download-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (salesIncAccountId !== undefined) {
                localVarQueryParameter['salesIncAccountId'] = salesIncAccountId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (targetMonth !== undefined) {
                localVarQueryParameter['targetMonth'] = targetMonth;
            }

            if (billingFlowStatuses) {
                localVarQueryParameter['billingFlowStatuses'] = billingFlowStatuses;
            }

            if (hasInformart !== undefined) {
                localVarQueryParameter['hasInformart'] = hasInformart;
            }

            if (deliveryNote !== undefined) {
                localVarQueryParameter['deliveryNote'] = deliveryNote;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求フローステータスを取得する
         * @summary 請求フローステータス取得
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceFlowStatus: async (billingHeaderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingHeaderId' is not null or undefined
            assertParamExists('billingInvoiceFlowStatus', 'billingHeaderId', billingHeaderId)
            const localVarPath = `/api/billing/billing-invoice-flow-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書一覧を取得する
         * @summary 請求書一覧取得
         * @param {number} [clientId] 
         * @param {number} [salesIncAccountId] 
         * @param {number} [contractShopId] 
         * @param {string} [targetMonth] 
         * @param {Array<number>} [billingFlowStatuses] 
         * @param {boolean} [hasBilling] 
         * @param {number} [billingAgentId] 
         * @param {number} [approverIncAccountId] 
         * @param {boolean} [publishBillingFlg] 
         * @param {string} [deliveryNote] 
         * @param {number} [billingHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceList: async (clientId?: number, salesIncAccountId?: number, contractShopId?: number, targetMonth?: string, billingFlowStatuses?: Array<number>, hasBilling?: boolean, billingAgentId?: number, approverIncAccountId?: number, publishBillingFlg?: boolean, deliveryNote?: string, billingHeaderId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/billing-invoice-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (salesIncAccountId !== undefined) {
                localVarQueryParameter['salesIncAccountId'] = salesIncAccountId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (targetMonth !== undefined) {
                localVarQueryParameter['targetMonth'] = targetMonth;
            }

            if (billingFlowStatuses) {
                localVarQueryParameter['billingFlowStatuses'] = billingFlowStatuses;
            }

            if (hasBilling !== undefined) {
                localVarQueryParameter['hasBilling'] = hasBilling;
            }

            if (billingAgentId !== undefined) {
                localVarQueryParameter['billingAgentId'] = billingAgentId;
            }

            if (approverIncAccountId !== undefined) {
                localVarQueryParameter['approverIncAccountId'] = approverIncAccountId;
            }

            if (publishBillingFlg !== undefined) {
                localVarQueryParameter['publishBillingFlg'] = publishBillingFlg;
            }

            if (deliveryNote !== undefined) {
                localVarQueryParameter['deliveryNote'] = deliveryNote;
            }

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求ステータス更新
         * @summary 請求ステータス更新
         * @param {BillingInvoiceStatusUpdateFormResponse} billingInvoiceStatusUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceStatusUpdate: async (billingInvoiceStatusUpdateFormResponse: BillingInvoiceStatusUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingInvoiceStatusUpdateFormResponse' is not null or undefined
            assertParamExists('billingInvoiceStatusUpdate', 'billingInvoiceStatusUpdateFormResponse', billingInvoiceStatusUpdateFormResponse)
            const localVarPath = `/api/billing/billing-invoice-status-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingInvoiceStatusUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_店舗別明細を取得する。帳票出力にのみ利用
         * @summary 請求書_店舗別明細
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceStoreDetailItem: async (billingHeaderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingHeaderId' is not null or undefined
            assertParamExists('billingInvoiceStoreDetailItem', 'billingHeaderId', billingHeaderId)
            const localVarPath = `/api/billing/billing-invoice-store-detail-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_店舗商品合算明細を取得する
         * @summary 請求書_店舗商品合算明細
         * @param {number} billingHeaderId 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceTotalingDetailItem: async (billingHeaderId: number, contractShopId?: number, monitorBaseId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingHeaderId' is not null or undefined
            assertParamExists('billingInvoiceTotalingDetailItem', 'billingHeaderId', billingHeaderId)
            const localVarPath = `/api/billing/billing-invoice-totaling-detail-item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_調査別明細を取得する
         * @summary 請求書_調査別明細_帳票ダウンロード用
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceTotalingDetailItemForPdf: async (billingHeaderId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingHeaderId' is not null or undefined
            assertParamExists('billingInvoiceTotalingDetailItemForPdf', 'billingHeaderId', billingHeaderId)
            const localVarPath = `/api/billing/billing-invoice-totaling-detail-item-for-pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書_店舗商品合算明細を更新する
         * @summary 請求書_店舗商品合算明細更新
         * @param {BillingInvoiceTotalingDetailItemUpdateFormResponse} billingInvoiceTotalingDetailItemUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceTotalingDetailItemUpdate: async (billingInvoiceTotalingDetailItemUpdateFormResponse: BillingInvoiceTotalingDetailItemUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingInvoiceTotalingDetailItemUpdateFormResponse' is not null or undefined
            assertParamExists('billingInvoiceTotalingDetailItemUpdate', 'billingInvoiceTotalingDetailItemUpdateFormResponse', billingInvoiceTotalingDetailItemUpdateFormResponse)
            const localVarPath = `/api/billing/billing-invoice-totaling-detail-item-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingInvoiceTotalingDetailItemUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 請求書PDF一斉ダウンロード
         * @summary 請求PDF一斉ダウンロード
         * @param {BillingPdfAllDownloadFormResponse} billingPdfAllDownloadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingPdfAllDownload: async (billingPdfAllDownloadFormResponse: BillingPdfAllDownloadFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingPdfAllDownloadFormResponse' is not null or undefined
            assertParamExists('billingPdfAllDownload', 'billingPdfAllDownloadFormResponse', billingPdfAllDownloadFormResponse)
            const localVarPath = `/api/billing/billing-pdf-all-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingPdfAllDownloadFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 代理店のサジェスト項目を取得する
         * @summary サジェスト項目取得（代理店）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestBillingAgentListList: async (keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/billing-suggest-billing-agent-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアントのサジェスト項目を取得する
         * @summary サジェスト項目取得（クライアント）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestClientList: async (keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/billing-suggest-client-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 契約のサジェスト項目を取得する
         * @summary サジェスト項目取得（契約）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestContractList: async (keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/billing-suggest-contract-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 契約店舗のサジェスト項目を取得する
         * @summary サジェスト項目取得（契約店舗）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestContractShopList: async (keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/billing-suggest-contract-shop-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 社内システム用アカウントのサジェスト項目を取得する
         * @summary サジェスト項目取得（社内システム用アカウント）
         * @param {string} [roleName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestIncAccountList: async (roleName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/billing-suggest-inc-account-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (roleName !== undefined) {
                localVarQueryParameter['roleName'] = roleName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニターのサジェスト項目を取得する
         * @summary サジェスト項目取得（モニター）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestMonitorBaseList: async (keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/billing-suggest-monitor-base-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 流し込み請求書作成の事前バリデーション用API
         * @summary 流し込み請求書作成API
         * @param {BillingInvoiceFileUploadValidationFormResponse} billingInvoiceFileUploadValidationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creaetBillingDataByFileUploadValidation: async (billingInvoiceFileUploadValidationFormResponse: BillingInvoiceFileUploadValidationFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingInvoiceFileUploadValidationFormResponse' is not null or undefined
            assertParamExists('creaetBillingDataByFileUploadValidation', 'billingInvoiceFileUploadValidationFormResponse', billingInvoiceFileUploadValidationFormResponse)
            const localVarPath = `/api/billing/upload-for-createbillingdata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingInvoiceFileUploadValidationFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ジョブ実行履歴を取得する
         * @summary ジョブ実行履歴取得
         * @param {string} [jobName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobExecutionHistory: async (jobName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/job-execution-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jobName !== undefined) {
                localVarQueryParameter['jobName'] = jobName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 応募IDを元に入金消し込みする
         * @summary 入金消し込み
         * @param {PaidApplyFormResponse} paidApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidapply: async (paidApplyFormResponse: PaidApplyFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paidApplyFormResponse' is not null or undefined
            assertParamExists('paidapply', 'paidApplyFormResponse', paidApplyFormResponse)
            const localVarPath = `/api/billing/paidapply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paidApplyFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 郵便番号から都道府県を取得する
         * @summary 郵便番号から都道府県を取得
         * @param {string} postalcode Postal Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prefecturesByPostalcode: async (postalcode: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postalcode' is not null or undefined
            assertParamExists('prefecturesByPostalcode', 'postalcode', postalcode)
            const localVarPath = `/api/billing/prefectures-by-postalcode/{postalcode}`
                .replace(`{${"postalcode"}}`, encodeURIComponent(String(postalcode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 非請求承認一覧を取得する
         * @summary 非請求承認一覧取得
         * @param {number} [type] 
         * @param {number} [incAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unBillingApprovalList: async (type?: number, incAccountId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/unbilling-approval-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (incAccountId !== undefined) {
                localVarQueryParameter['incAccountId'] = incAccountId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 非請求申請ステータスを更新する
         * @summary 非請求申請
         * @param {UnBillingApplyFormResponse} unBillingApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbillingApply: async (unBillingApplyFormResponse: UnBillingApplyFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unBillingApplyFormResponse' is not null or undefined
            assertParamExists('unbillingApply', 'unBillingApplyFormResponse', unBillingApplyFormResponse)
            const localVarPath = `/api/billing/apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unBillingApplyFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 非請求申請を承認または却下する
         * @summary 非請求承認
         * @param {UnBillingApproveFormResponse} unBillingApproveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbillingApprove: async (unBillingApproveFormResponse: UnBillingApproveFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'unBillingApproveFormResponse' is not null or undefined
            assertParamExists('unbillingApprove', 'unBillingApproveFormResponse', unBillingApproveFormResponse)
            const localVarPath = `/api/billing/unbilling-approve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(unBillingApproveFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 非請求申請一覧を取得する
         * @summary 非請求申請一覧取得
         * @param {number} [type] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {string} [startYm] 
         * @param {string} [endYm] 
         * @param {string} [applicationIds] 
         * @param {number} [billingHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbillingList: async (type?: number, contractShopId?: number, monitorBaseId?: number, startYm?: string, endYm?: string, applicationIds?: string, billingHeaderId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing/unbilling-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }

            if (startYm !== undefined) {
                localVarQueryParameter['startYm'] = startYm;
            }

            if (endYm !== undefined) {
                localVarQueryParameter['endYm'] = endYm;
            }

            if (applicationIds !== undefined) {
                localVarQueryParameter['applicationIds'] = applicationIds;
            }

            if (billingHeaderId !== undefined) {
                localVarQueryParameter['billingHeaderId'] = billingHeaderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 代理店フィー明細を取得する
         * @summary 代理店フィー明細取得
         * @param {number} [agentFeeListHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingAgentFeeDetail(agentFeeListHeaderId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingAgentFeeDetailOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingAgentFeeDetail(agentFeeListHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 代理店フィー一覧を取得する
         * @summary 代理店フィー一覧取得
         * @param {number} [billingAgentId] 
         * @param {number} [salesIncAccountId] 
         * @param {number} [clientId] 
         * @param {string} [targetMonth] 
         * @param {number} [agentFeeStatus] 
         * @param {boolean} [hasUnapproved] 
         * @param {number} [approverIncAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingAgentFeeList(billingAgentId?: number, salesIncAccountId?: number, clientId?: number, targetMonth?: string, agentFeeStatus?: number, hasUnapproved?: boolean, approverIncAccountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingAgentFeeListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingAgentFeeList(billingAgentId, salesIncAccountId, clientId, targetMonth, agentFeeStatus, hasUnapproved, approverIncAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書承認者リストの取得をする
         * @summary 請求書承認者リストの取得
         * @param {number} incAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingApproverList(incAccountId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceApproverListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingApproverList(incAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 配送メモを更新する
         * @summary 配送メモ更新
         * @param {BillingDeliveryNoteUpdateFormResponse} billingDeliveryNoteUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDeliveryNoteUpdate(billingDeliveryNoteUpdateFormResponse: BillingDeliveryNoteUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDeliveryNoteUpdate(billingDeliveryNoteUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先と契約の紐付けを一括解除する
         * @summary 請求送付先契約紐付け一括解除
         * @param {BillingDestinationContractBulkDeleteFormResponse} billingDestinationContractBulkDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestContractBulkDelete(billingDestinationContractBulkDeleteFormResponse: BillingDestinationContractBulkDeleteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestContractBulkDelete(billingDestinationContractBulkDeleteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先と契約の紐付けを一括紐づけする
         * @summary 請求送付先契約紐付け一括紐づけ
         * @param {BillingDestinationContractBulkUpdateFormResponse} billingDestinationContractBulkUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestContractBulkUpdate(billingDestinationContractBulkUpdateFormResponse: BillingDestinationContractBulkUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestContractBulkUpdate(billingDestinationContractBulkUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先契約の紐付けを解除する
         * @summary 請求送付先契約紐付け解除
         * @param {BillingDestinationContractDeleteFormResponse} billingDestinationContractDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestContractDelete(billingDestinationContractDeleteFormResponse: BillingDestinationContractDeleteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestContractDelete(billingDestinationContractDeleteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先契約一覧を取得する
         * @summary 請求送付先_契約一覧取得
         * @param {number} originalBillingDestinationId 
         * @param {number} [clientId] 
         * @param {number} [incAccountId] 
         * @param {string} [contractShopIds] 
         * @param {number} [billingDestinationBranchNumber] 
         * @param {string} [billingDestinationName] 
         * @param {boolean} [relationFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestContractList(originalBillingDestinationId: number, clientId?: number, incAccountId?: number, contractShopIds?: string, billingDestinationBranchNumber?: number, billingDestinationName?: string, relationFlg?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingDestinationContractListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestContractList(originalBillingDestinationId, clientId, incAccountId, contractShopIds, billingDestinationBranchNumber, billingDestinationName, relationFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先と契約を紐付ける
         * @summary 請求送付先契約紐付け
         * @param {BillingDestinationContractUpdateFormResponse} billingDestinationContractUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestContractUpdate(billingDestinationContractUpdateFormResponse: BillingDestinationContractUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestContractUpdate(billingDestinationContractUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先店舗の紐付けを一括解除する
         * @summary 請求送付先店舗紐付け一括解除
         * @param {BillingDestinationShopBulkDeleteFormResponse} billingDestinationShopBulkDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestShopBulkDelete(billingDestinationShopBulkDeleteFormResponse: BillingDestinationShopBulkDeleteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestShopBulkDelete(billingDestinationShopBulkDeleteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先店舗の紐付けを一括紐づけする
         * @summary 請求送付先店舗紐付け一括紐づけ
         * @param {BillingDestinationShopBulkUpdateFormResponse} billingDestinationShopBulkUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestShopBulkUpdate(billingDestinationShopBulkUpdateFormResponse: BillingDestinationShopBulkUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestShopBulkUpdate(billingDestinationShopBulkUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先店舗の紐付けを解除する
         * @summary 請求送付先店舗紐付け解除
         * @param {BillingDestinationShopDeleteFormResponse} billingDestinationShopDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestShopDelete(billingDestinationShopDeleteFormResponse: BillingDestinationShopDeleteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestShopDelete(billingDestinationShopDeleteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先店舗一覧を取得する
         * @summary 請求送付先店舗一覧取得
         * @param {number} originalBillingDestinationId 
         * @param {number} [clientId] 
         * @param {number} [salesId] 
         * @param {string} [shopId] 
         * @param {number} [branchNumber] 
         * @param {string} [billingDestName] 
         * @param {boolean} [relationFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestShopList(originalBillingDestinationId: number, clientId?: number, salesId?: number, shopId?: string, branchNumber?: number, billingDestName?: string, relationFlg?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingDestinationShopListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestShopList(originalBillingDestinationId, clientId, salesId, shopId, branchNumber, billingDestName, relationFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先店舗を紐付ける
         * @summary 請求送付先店舗紐付け
         * @param {BillingDestinationShopUpdateFormResponse} billingDestinationShopUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestShopUpdate(billingDestinationShopUpdateFormResponse: BillingDestinationShopUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestShopUpdate(billingDestinationShopUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先の自動承認を更新する
         * @summary 請求送付先自動承認更新
         * @param {BillingDestinationAutoApprovalUpdateFormResponse} billingDestinationAutoApprovalUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestinationAutoApprovalUpdate(billingDestinationAutoApprovalUpdateFormResponse: BillingDestinationAutoApprovalUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestinationAutoApprovalUpdate(billingDestinationAutoApprovalUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先をコピーする
         * @summary 請求送付先コピー
         * @param {Array<BillingDestinationCopyFormResponse>} billingDestinationCopyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestinationCopy(billingDestinationCopyFormResponse: Array<BillingDestinationCopyFormResponse>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestinationCopy(billingDestinationCopyFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先を作成する
         * @summary 請求送付先作成
         * @param {BillingDestinationCreateFormResponse} billingDestinationCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestinationCreate(billingDestinationCreateFormResponse: BillingDestinationCreateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestinationCreate(billingDestinationCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先明細を取得する
         * @summary 請求送付先明細取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestinationDetail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDestinationDetailOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestinationDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先等の情報を取得
         * @summary 請求送付先等の情報を取得
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestinationInfo(billingHeaderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDestinationInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestinationInfo(billingHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先一覧を取得する
         * @summary 請求送付先一覧取得
         * @param {number} [clientId] 
         * @param {number} [incAccountId] 
         * @param {string} [billingDestinationName] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestinationList(clientId?: number, incAccountId?: number, billingDestinationName?: string, contractId?: number, contractShopId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingDestinationListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestinationList(clientId, incAccountId, billingDestinationName, contractId, contractShopId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先紐づけ画面のヘッダ情報を取得
         * @summary 請求送付先紐づけ画面のヘッダ情報を取得
         * @param {number} billingDestinationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestinationRelationHeader(billingDestinationId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingDestinationRelationHeaderOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestinationRelationHeader(billingDestinationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求送付先を更新する
         * @summary 請求送付先更新
         * @param {BillingDestinationUpdateFormResponse} billingDestinationUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDestinationUpdate(billingDestinationUpdateFormResponse: BillingDestinationUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDestinationUpdate(billingDestinationUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 按分設定データを作成する
         * @summary 按分手動作成
         * @param {BillingDivideInvoiceFormResponse} billingDivideInvoiceFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingDivideInvoice(billingDivideInvoiceFormResponse: BillingDivideInvoiceFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingDivideInvoice(billingDivideInvoiceFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書PDFダウンロード
         * @summary 請求PDF/Excelダウンロード
         * @param {BillingFileDownloadFormResponse} billingFileDownloadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingFileDownload(billingFileDownloadFormResponse: BillingFileDownloadFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingPdfDownloadOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingFileDownload(billingFileDownloadFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書PDFデータのアップロード
         * @summary 請求書PDFデータのアップロード
         * @param {BillingFileUploadFormResponse} billingFileUploadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingFileUpload(billingFileUploadFormResponse: BillingFileUploadFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingFileUpload(billingFileUploadFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書の翌月繰越を行う
         * @summary 請求書翌月繰越
         * @param {BillingInvoiceCarryOverFormResponse} billingInvoiceCarryOverFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceCarryOver(billingInvoiceCarryOverFormResponse: BillingInvoiceCarryOverFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceCarryOver(billingInvoiceCarryOverFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_派遣別明細を取得する
         * @summary 請求書_派遣別明細
         * @param {number} billingHeaderId 
         * @param {number} [contractShopId] 
         * @param {string} [applyIds] 
         * @param {string} [visitAt] 
         * @param {string} [completeAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceDetailApplication(billingHeaderId: number, contractShopId?: number, applyIds?: string, visitAt?: string, completeAt?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingInvoiceDetailApplicationSearchOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceDetailApplication(billingHeaderId, contractShopId, applyIds, visitAt, completeAt, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_派遣別明細を取得する。PDFダウンロード用
         * @summary 請求書_派遣別明細
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceDetailApplicationForPdf(billingHeaderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceDetailApplicationSearchForPdfOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceDetailApplicationForPdf(billingHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_派遣別明細を更新する
         * @summary 請求書_派遣別明細更新
         * @param {BillingInvoiceDetailApplicationUpdateFormResponse} billingInvoiceDetailApplicationUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceDetailApplicationUpdate(billingInvoiceDetailApplicationUpdateFormResponse: BillingInvoiceDetailApplicationUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceDetailApplicationUpdate(billingInvoiceDetailApplicationUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_明細ヘッダ情報を取得する
         * @summary 請求書_明細ヘッダ情報
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceDetailHeader(billingHeaderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceDetailHeaderOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceDetailHeader(billingHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_商品別明細を取得する
         * @summary 請求書_商品別明細
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceDetailItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceDetailItemResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceDetailItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_商品別明細を取得する
         * @summary 請求書_商品別明細
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceDetailItemForPdf(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceDetailItemResultForPdfOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceDetailItemForPdf(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_商品別明細を更新する
         * @summary 請求書_商品別明細更新
         * @param {BillingInvoiceDetailItemUpdateFormResponse} billingInvoiceDetailItemUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceDetailItemUpdate(billingInvoiceDetailItemUpdateFormResponse: BillingInvoiceDetailItemUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceDetailItemUpdate(billingInvoiceDetailItemUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書帳票(CSV)をダウンロードする
         * @summary 請求書帳票ダウンロードCSV
         * @param {BillingInvoiceDownloadCsvFormResponse} billingInvoiceDownloadCsvFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceDownloadCsv(billingInvoiceDownloadCsvFormResponse: BillingInvoiceDownloadCsvFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingCsvAllDownloadOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceDownloadCsv(billingInvoiceDownloadCsvFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書ダウンロード一覧を取得する
         * @summary 請求書ダウンロード一覧取得
         * @param {number} [clientId] 
         * @param {number} [salesIncAccountId] 
         * @param {number} [contractShopId] 
         * @param {string} [targetMonth] 
         * @param {Array<number>} [billingFlowStatuses] 
         * @param {boolean} [hasInformart] 
         * @param {string} [deliveryNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceDownloadList(clientId?: number, salesIncAccountId?: number, contractShopId?: number, targetMonth?: string, billingFlowStatuses?: Array<number>, hasInformart?: boolean, deliveryNote?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingInvoiceDownloadListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceDownloadList(clientId, salesIncAccountId, contractShopId, targetMonth, billingFlowStatuses, hasInformart, deliveryNote, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求フローステータスを取得する
         * @summary 請求フローステータス取得
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceFlowStatus(billingHeaderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceFlowStatusOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceFlowStatus(billingHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書一覧を取得する
         * @summary 請求書一覧取得
         * @param {number} [clientId] 
         * @param {number} [salesIncAccountId] 
         * @param {number} [contractShopId] 
         * @param {string} [targetMonth] 
         * @param {Array<number>} [billingFlowStatuses] 
         * @param {boolean} [hasBilling] 
         * @param {number} [billingAgentId] 
         * @param {number} [approverIncAccountId] 
         * @param {boolean} [publishBillingFlg] 
         * @param {string} [deliveryNote] 
         * @param {number} [billingHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceList(clientId?: number, salesIncAccountId?: number, contractShopId?: number, targetMonth?: string, billingFlowStatuses?: Array<number>, hasBilling?: boolean, billingAgentId?: number, approverIncAccountId?: number, publishBillingFlg?: boolean, deliveryNote?: string, billingHeaderId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingInvoiceListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceList(clientId, salesIncAccountId, contractShopId, targetMonth, billingFlowStatuses, hasBilling, billingAgentId, approverIncAccountId, publishBillingFlg, deliveryNote, billingHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求ステータス更新
         * @summary 請求ステータス更新
         * @param {BillingInvoiceStatusUpdateFormResponse} billingInvoiceStatusUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceStatusUpdate(billingInvoiceStatusUpdateFormResponse: BillingInvoiceStatusUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceStatusUpdate(billingInvoiceStatusUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_店舗別明細を取得する。帳票出力にのみ利用
         * @summary 請求書_店舗別明細
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceStoreDetailItem(billingHeaderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceStoreDetailItemOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceStoreDetailItem(billingHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_店舗商品合算明細を取得する
         * @summary 請求書_店舗商品合算明細
         * @param {number} billingHeaderId 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceTotalingDetailItem(billingHeaderId: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceTotalingDetailItemOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceTotalingDetailItem(billingHeaderId, contractShopId, monitorBaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_調査別明細を取得する
         * @summary 請求書_調査別明細_帳票ダウンロード用
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceTotalingDetailItemForPdf(billingHeaderId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingInvoiceTotalingDetailItemForPdfOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceTotalingDetailItemForPdf(billingHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書_店舗商品合算明細を更新する
         * @summary 請求書_店舗商品合算明細更新
         * @param {BillingInvoiceTotalingDetailItemUpdateFormResponse} billingInvoiceTotalingDetailItemUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingInvoiceTotalingDetailItemUpdate(billingInvoiceTotalingDetailItemUpdateFormResponse: BillingInvoiceTotalingDetailItemUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingInvoiceTotalingDetailItemUpdate(billingInvoiceTotalingDetailItemUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 請求書PDF一斉ダウンロード
         * @summary 請求PDF一斉ダウンロード
         * @param {BillingPdfAllDownloadFormResponse} billingPdfAllDownloadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingPdfAllDownload(billingPdfAllDownloadFormResponse: BillingPdfAllDownloadFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingPdfAllDownloadOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingPdfAllDownload(billingPdfAllDownloadFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 代理店のサジェスト項目を取得する
         * @summary サジェスト項目取得（代理店）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSuggestBillingAgentListList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingSuggestListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSuggestBillingAgentListList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアントのサジェスト項目を取得する
         * @summary サジェスト項目取得（クライアント）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSuggestClientList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingSuggestListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSuggestClientList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 契約のサジェスト項目を取得する
         * @summary サジェスト項目取得（契約）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSuggestContractList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingSuggestListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSuggestContractList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 契約店舗のサジェスト項目を取得する
         * @summary サジェスト項目取得（契約店舗）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSuggestContractShopList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingSuggestListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSuggestContractShopList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 社内システム用アカウントのサジェスト項目を取得する
         * @summary サジェスト項目取得（社内システム用アカウント）
         * @param {string} [roleName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSuggestIncAccountList(roleName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingSuggestListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSuggestIncAccountList(roleName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニターのサジェスト項目を取得する
         * @summary サジェスト項目取得（モニター）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingSuggestMonitorBaseList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingSuggestListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingSuggestMonitorBaseList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 流し込み請求書作成の事前バリデーション用API
         * @summary 流し込み請求書作成API
         * @param {BillingInvoiceFileUploadValidationFormResponse} billingInvoiceFileUploadValidationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async creaetBillingDataByFileUploadValidation(billingInvoiceFileUploadValidationFormResponse: BillingInvoiceFileUploadValidationFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingInvoiceFileUploadValidationOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.creaetBillingDataByFileUploadValidation(billingInvoiceFileUploadValidationFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ジョブ実行履歴を取得する
         * @summary ジョブ実行履歴取得
         * @param {string} [jobName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobExecutionHistory(jobName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobExecutionHistoryOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobExecutionHistory(jobName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 応募IDを元に入金消し込みする
         * @summary 入金消し込み
         * @param {PaidApplyFormResponse} paidApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paidapply(paidApplyFormResponse: PaidApplyFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paidapply(paidApplyFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 郵便番号から都道府県を取得する
         * @summary 郵便番号から都道府県を取得
         * @param {string} postalcode Postal Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prefecturesByPostalcode(postalcode: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrefecturesByPostalcodeOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prefecturesByPostalcode(postalcode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 非請求承認一覧を取得する
         * @summary 非請求承認一覧取得
         * @param {number} [type] 
         * @param {number} [incAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unBillingApprovalList(type?: number, incAccountId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnBillingApprovalListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unBillingApprovalList(type, incAccountId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 非請求申請ステータスを更新する
         * @summary 非請求申請
         * @param {UnBillingApplyFormResponse} unBillingApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unbillingApply(unBillingApplyFormResponse: UnBillingApplyFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unbillingApply(unBillingApplyFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 非請求申請を承認または却下する
         * @summary 非請求承認
         * @param {UnBillingApproveFormResponse} unBillingApproveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unbillingApprove(unBillingApproveFormResponse: UnBillingApproveFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UnBillingApproveOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unbillingApprove(unBillingApproveFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 非請求申請一覧を取得する
         * @summary 非請求申請一覧取得
         * @param {number} [type] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {string} [startYm] 
         * @param {string} [endYm] 
         * @param {string} [applicationIds] 
         * @param {number} [billingHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unbillingList(type?: number, contractShopId?: number, monitorBaseId?: number, startYm?: string, endYm?: string, applicationIds?: string, billingHeaderId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UnBillingListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unbillingList(type, contractShopId, monitorBaseId, startYm, endYm, applicationIds, billingHeaderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingApiFp(configuration)
    return {
        /**
         * 代理店フィー明細を取得する
         * @summary 代理店フィー明細取得
         * @param {number} [agentFeeListHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentFeeDetail(agentFeeListHeaderId?: number, options?: any): AxiosPromise<BillingAgentFeeDetailOutputResponse> {
            return localVarFp.billingAgentFeeDetail(agentFeeListHeaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 代理店フィー一覧を取得する
         * @summary 代理店フィー一覧取得
         * @param {number} [billingAgentId] 
         * @param {number} [salesIncAccountId] 
         * @param {number} [clientId] 
         * @param {string} [targetMonth] 
         * @param {number} [agentFeeStatus] 
         * @param {boolean} [hasUnapproved] 
         * @param {number} [approverIncAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentFeeList(billingAgentId?: number, salesIncAccountId?: number, clientId?: number, targetMonth?: string, agentFeeStatus?: number, hasUnapproved?: boolean, approverIncAccountId?: number, options?: any): AxiosPromise<Array<BillingAgentFeeListOutputResponse>> {
            return localVarFp.billingAgentFeeList(billingAgentId, salesIncAccountId, clientId, targetMonth, agentFeeStatus, hasUnapproved, approverIncAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書承認者リストの取得をする
         * @summary 請求書承認者リストの取得
         * @param {number} incAccountId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingApproverList(incAccountId: number, options?: any): AxiosPromise<BillingInvoiceApproverListOutputResponse> {
            return localVarFp.billingApproverList(incAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 配送メモを更新する
         * @summary 配送メモ更新
         * @param {BillingDeliveryNoteUpdateFormResponse} billingDeliveryNoteUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDeliveryNoteUpdate(billingDeliveryNoteUpdateFormResponse: BillingDeliveryNoteUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDeliveryNoteUpdate(billingDeliveryNoteUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先と契約の紐付けを一括解除する
         * @summary 請求送付先契約紐付け一括解除
         * @param {BillingDestinationContractBulkDeleteFormResponse} billingDestinationContractBulkDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractBulkDelete(billingDestinationContractBulkDeleteFormResponse: BillingDestinationContractBulkDeleteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestContractBulkDelete(billingDestinationContractBulkDeleteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先と契約の紐付けを一括紐づけする
         * @summary 請求送付先契約紐付け一括紐づけ
         * @param {BillingDestinationContractBulkUpdateFormResponse} billingDestinationContractBulkUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractBulkUpdate(billingDestinationContractBulkUpdateFormResponse: BillingDestinationContractBulkUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestContractBulkUpdate(billingDestinationContractBulkUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先契約の紐付けを解除する
         * @summary 請求送付先契約紐付け解除
         * @param {BillingDestinationContractDeleteFormResponse} billingDestinationContractDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractDelete(billingDestinationContractDeleteFormResponse: BillingDestinationContractDeleteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestContractDelete(billingDestinationContractDeleteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先契約一覧を取得する
         * @summary 請求送付先_契約一覧取得
         * @param {number} originalBillingDestinationId 
         * @param {number} [clientId] 
         * @param {number} [incAccountId] 
         * @param {string} [contractShopIds] 
         * @param {number} [billingDestinationBranchNumber] 
         * @param {string} [billingDestinationName] 
         * @param {boolean} [relationFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractList(originalBillingDestinationId: number, clientId?: number, incAccountId?: number, contractShopIds?: string, billingDestinationBranchNumber?: number, billingDestinationName?: string, relationFlg?: boolean, options?: any): AxiosPromise<Array<BillingDestinationContractListOutputResponse>> {
            return localVarFp.billingDestContractList(originalBillingDestinationId, clientId, incAccountId, contractShopIds, billingDestinationBranchNumber, billingDestinationName, relationFlg, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先と契約を紐付ける
         * @summary 請求送付先契約紐付け
         * @param {BillingDestinationContractUpdateFormResponse} billingDestinationContractUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestContractUpdate(billingDestinationContractUpdateFormResponse: BillingDestinationContractUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestContractUpdate(billingDestinationContractUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先店舗の紐付けを一括解除する
         * @summary 請求送付先店舗紐付け一括解除
         * @param {BillingDestinationShopBulkDeleteFormResponse} billingDestinationShopBulkDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopBulkDelete(billingDestinationShopBulkDeleteFormResponse: BillingDestinationShopBulkDeleteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestShopBulkDelete(billingDestinationShopBulkDeleteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先店舗の紐付けを一括紐づけする
         * @summary 請求送付先店舗紐付け一括紐づけ
         * @param {BillingDestinationShopBulkUpdateFormResponse} billingDestinationShopBulkUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopBulkUpdate(billingDestinationShopBulkUpdateFormResponse: BillingDestinationShopBulkUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestShopBulkUpdate(billingDestinationShopBulkUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先店舗の紐付けを解除する
         * @summary 請求送付先店舗紐付け解除
         * @param {BillingDestinationShopDeleteFormResponse} billingDestinationShopDeleteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopDelete(billingDestinationShopDeleteFormResponse: BillingDestinationShopDeleteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestShopDelete(billingDestinationShopDeleteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先店舗一覧を取得する
         * @summary 請求送付先店舗一覧取得
         * @param {number} originalBillingDestinationId 
         * @param {number} [clientId] 
         * @param {number} [salesId] 
         * @param {string} [shopId] 
         * @param {number} [branchNumber] 
         * @param {string} [billingDestName] 
         * @param {boolean} [relationFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopList(originalBillingDestinationId: number, clientId?: number, salesId?: number, shopId?: string, branchNumber?: number, billingDestName?: string, relationFlg?: boolean, options?: any): AxiosPromise<Array<BillingDestinationShopListOutputResponse>> {
            return localVarFp.billingDestShopList(originalBillingDestinationId, clientId, salesId, shopId, branchNumber, billingDestName, relationFlg, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先店舗を紐付ける
         * @summary 請求送付先店舗紐付け
         * @param {BillingDestinationShopUpdateFormResponse} billingDestinationShopUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestShopUpdate(billingDestinationShopUpdateFormResponse: BillingDestinationShopUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestShopUpdate(billingDestinationShopUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先の自動承認を更新する
         * @summary 請求送付先自動承認更新
         * @param {BillingDestinationAutoApprovalUpdateFormResponse} billingDestinationAutoApprovalUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationAutoApprovalUpdate(billingDestinationAutoApprovalUpdateFormResponse: BillingDestinationAutoApprovalUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestinationAutoApprovalUpdate(billingDestinationAutoApprovalUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先をコピーする
         * @summary 請求送付先コピー
         * @param {Array<BillingDestinationCopyFormResponse>} billingDestinationCopyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationCopy(billingDestinationCopyFormResponse: Array<BillingDestinationCopyFormResponse>, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestinationCopy(billingDestinationCopyFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先を作成する
         * @summary 請求送付先作成
         * @param {BillingDestinationCreateFormResponse} billingDestinationCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationCreate(billingDestinationCreateFormResponse: BillingDestinationCreateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestinationCreate(billingDestinationCreateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先明細を取得する
         * @summary 請求送付先明細取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationDetail(id: number, options?: any): AxiosPromise<BillingDestinationDetailOutputResponse> {
            return localVarFp.billingDestinationDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先等の情報を取得
         * @summary 請求送付先等の情報を取得
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationInfo(billingHeaderId: number, options?: any): AxiosPromise<BillingDestinationInfoOutputResponse> {
            return localVarFp.billingDestinationInfo(billingHeaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先一覧を取得する
         * @summary 請求送付先一覧取得
         * @param {number} [clientId] 
         * @param {number} [incAccountId] 
         * @param {string} [billingDestinationName] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationList(clientId?: number, incAccountId?: number, billingDestinationName?: string, contractId?: number, contractShopId?: number, options?: any): AxiosPromise<Array<BillingDestinationListOutputResponse>> {
            return localVarFp.billingDestinationList(clientId, incAccountId, billingDestinationName, contractId, contractShopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先紐づけ画面のヘッダ情報を取得
         * @summary 請求送付先紐づけ画面のヘッダ情報を取得
         * @param {number} billingDestinationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationRelationHeader(billingDestinationId: number, options?: any): AxiosPromise<BillingDestinationRelationHeaderOutputResponse> {
            return localVarFp.billingDestinationRelationHeader(billingDestinationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求送付先を更新する
         * @summary 請求送付先更新
         * @param {BillingDestinationUpdateFormResponse} billingDestinationUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDestinationUpdate(billingDestinationUpdateFormResponse: BillingDestinationUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDestinationUpdate(billingDestinationUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 按分設定データを作成する
         * @summary 按分手動作成
         * @param {BillingDivideInvoiceFormResponse} billingDivideInvoiceFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingDivideInvoice(billingDivideInvoiceFormResponse: BillingDivideInvoiceFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingDivideInvoice(billingDivideInvoiceFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書PDFダウンロード
         * @summary 請求PDF/Excelダウンロード
         * @param {BillingFileDownloadFormResponse} billingFileDownloadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingFileDownload(billingFileDownloadFormResponse: BillingFileDownloadFormResponse, options?: any): AxiosPromise<BillingPdfDownloadOutputResponse> {
            return localVarFp.billingFileDownload(billingFileDownloadFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書PDFデータのアップロード
         * @summary 請求書PDFデータのアップロード
         * @param {BillingFileUploadFormResponse} billingFileUploadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingFileUpload(billingFileUploadFormResponse: BillingFileUploadFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingFileUpload(billingFileUploadFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書の翌月繰越を行う
         * @summary 請求書翌月繰越
         * @param {BillingInvoiceCarryOverFormResponse} billingInvoiceCarryOverFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceCarryOver(billingInvoiceCarryOverFormResponse: BillingInvoiceCarryOverFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingInvoiceCarryOver(billingInvoiceCarryOverFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_派遣別明細を取得する
         * @summary 請求書_派遣別明細
         * @param {number} billingHeaderId 
         * @param {number} [contractShopId] 
         * @param {string} [applyIds] 
         * @param {string} [visitAt] 
         * @param {string} [completeAt] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailApplication(billingHeaderId: number, contractShopId?: number, applyIds?: string, visitAt?: string, completeAt?: string, options?: any): AxiosPromise<Array<BillingInvoiceDetailApplicationSearchOutputResponse>> {
            return localVarFp.billingInvoiceDetailApplication(billingHeaderId, contractShopId, applyIds, visitAt, completeAt, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_派遣別明細を取得する。PDFダウンロード用
         * @summary 請求書_派遣別明細
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailApplicationForPdf(billingHeaderId: number, options?: any): AxiosPromise<BillingInvoiceDetailApplicationSearchForPdfOutputResponse> {
            return localVarFp.billingInvoiceDetailApplicationForPdf(billingHeaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_派遣別明細を更新する
         * @summary 請求書_派遣別明細更新
         * @param {BillingInvoiceDetailApplicationUpdateFormResponse} billingInvoiceDetailApplicationUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailApplicationUpdate(billingInvoiceDetailApplicationUpdateFormResponse: BillingInvoiceDetailApplicationUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingInvoiceDetailApplicationUpdate(billingInvoiceDetailApplicationUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_明細ヘッダ情報を取得する
         * @summary 請求書_明細ヘッダ情報
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailHeader(billingHeaderId: number, options?: any): AxiosPromise<BillingInvoiceDetailHeaderOutputResponse> {
            return localVarFp.billingInvoiceDetailHeader(billingHeaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_商品別明細を取得する
         * @summary 請求書_商品別明細
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailItem(id: number, options?: any): AxiosPromise<BillingInvoiceDetailItemResultOutputResponse> {
            return localVarFp.billingInvoiceDetailItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_商品別明細を取得する
         * @summary 請求書_商品別明細
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailItemForPdf(id: number, options?: any): AxiosPromise<BillingInvoiceDetailItemResultForPdfOutputResponse> {
            return localVarFp.billingInvoiceDetailItemForPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_商品別明細を更新する
         * @summary 請求書_商品別明細更新
         * @param {BillingInvoiceDetailItemUpdateFormResponse} billingInvoiceDetailItemUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDetailItemUpdate(billingInvoiceDetailItemUpdateFormResponse: BillingInvoiceDetailItemUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingInvoiceDetailItemUpdate(billingInvoiceDetailItemUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書帳票(CSV)をダウンロードする
         * @summary 請求書帳票ダウンロードCSV
         * @param {BillingInvoiceDownloadCsvFormResponse} billingInvoiceDownloadCsvFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDownloadCsv(billingInvoiceDownloadCsvFormResponse: BillingInvoiceDownloadCsvFormResponse, options?: any): AxiosPromise<BillingCsvAllDownloadOutputResponse> {
            return localVarFp.billingInvoiceDownloadCsv(billingInvoiceDownloadCsvFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書ダウンロード一覧を取得する
         * @summary 請求書ダウンロード一覧取得
         * @param {number} [clientId] 
         * @param {number} [salesIncAccountId] 
         * @param {number} [contractShopId] 
         * @param {string} [targetMonth] 
         * @param {Array<number>} [billingFlowStatuses] 
         * @param {boolean} [hasInformart] 
         * @param {string} [deliveryNote] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceDownloadList(clientId?: number, salesIncAccountId?: number, contractShopId?: number, targetMonth?: string, billingFlowStatuses?: Array<number>, hasInformart?: boolean, deliveryNote?: string, options?: any): AxiosPromise<Array<BillingInvoiceDownloadListOutputResponse>> {
            return localVarFp.billingInvoiceDownloadList(clientId, salesIncAccountId, contractShopId, targetMonth, billingFlowStatuses, hasInformart, deliveryNote, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求フローステータスを取得する
         * @summary 請求フローステータス取得
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceFlowStatus(billingHeaderId: number, options?: any): AxiosPromise<BillingInvoiceFlowStatusOutputResponse> {
            return localVarFp.billingInvoiceFlowStatus(billingHeaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書一覧を取得する
         * @summary 請求書一覧取得
         * @param {number} [clientId] 
         * @param {number} [salesIncAccountId] 
         * @param {number} [contractShopId] 
         * @param {string} [targetMonth] 
         * @param {Array<number>} [billingFlowStatuses] 
         * @param {boolean} [hasBilling] 
         * @param {number} [billingAgentId] 
         * @param {number} [approverIncAccountId] 
         * @param {boolean} [publishBillingFlg] 
         * @param {string} [deliveryNote] 
         * @param {number} [billingHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceList(clientId?: number, salesIncAccountId?: number, contractShopId?: number, targetMonth?: string, billingFlowStatuses?: Array<number>, hasBilling?: boolean, billingAgentId?: number, approverIncAccountId?: number, publishBillingFlg?: boolean, deliveryNote?: string, billingHeaderId?: number, options?: any): AxiosPromise<Array<BillingInvoiceListOutputResponse>> {
            return localVarFp.billingInvoiceList(clientId, salesIncAccountId, contractShopId, targetMonth, billingFlowStatuses, hasBilling, billingAgentId, approverIncAccountId, publishBillingFlg, deliveryNote, billingHeaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求ステータス更新
         * @summary 請求ステータス更新
         * @param {BillingInvoiceStatusUpdateFormResponse} billingInvoiceStatusUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceStatusUpdate(billingInvoiceStatusUpdateFormResponse: BillingInvoiceStatusUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingInvoiceStatusUpdate(billingInvoiceStatusUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_店舗別明細を取得する。帳票出力にのみ利用
         * @summary 請求書_店舗別明細
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceStoreDetailItem(billingHeaderId: number, options?: any): AxiosPromise<BillingInvoiceStoreDetailItemOutputResponse> {
            return localVarFp.billingInvoiceStoreDetailItem(billingHeaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_店舗商品合算明細を取得する
         * @summary 請求書_店舗商品合算明細
         * @param {number} billingHeaderId 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceTotalingDetailItem(billingHeaderId: number, contractShopId?: number, monitorBaseId?: number, options?: any): AxiosPromise<BillingInvoiceTotalingDetailItemOutputResponse> {
            return localVarFp.billingInvoiceTotalingDetailItem(billingHeaderId, contractShopId, monitorBaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_調査別明細を取得する
         * @summary 請求書_調査別明細_帳票ダウンロード用
         * @param {number} billingHeaderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceTotalingDetailItemForPdf(billingHeaderId: number, options?: any): AxiosPromise<BillingInvoiceTotalingDetailItemForPdfOutputResponse> {
            return localVarFp.billingInvoiceTotalingDetailItemForPdf(billingHeaderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書_店舗商品合算明細を更新する
         * @summary 請求書_店舗商品合算明細更新
         * @param {BillingInvoiceTotalingDetailItemUpdateFormResponse} billingInvoiceTotalingDetailItemUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingInvoiceTotalingDetailItemUpdate(billingInvoiceTotalingDetailItemUpdateFormResponse: BillingInvoiceTotalingDetailItemUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingInvoiceTotalingDetailItemUpdate(billingInvoiceTotalingDetailItemUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 請求書PDF一斉ダウンロード
         * @summary 請求PDF一斉ダウンロード
         * @param {BillingPdfAllDownloadFormResponse} billingPdfAllDownloadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingPdfAllDownload(billingPdfAllDownloadFormResponse: BillingPdfAllDownloadFormResponse, options?: any): AxiosPromise<BillingPdfAllDownloadOutputResponse> {
            return localVarFp.billingPdfAllDownload(billingPdfAllDownloadFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 代理店のサジェスト項目を取得する
         * @summary サジェスト項目取得（代理店）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestBillingAgentListList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: any): AxiosPromise<Array<BillingSuggestListOutputResponse>> {
            return localVarFp.billingSuggestBillingAgentListList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアントのサジェスト項目を取得する
         * @summary サジェスト項目取得（クライアント）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestClientList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: any): AxiosPromise<Array<BillingSuggestListOutputResponse>> {
            return localVarFp.billingSuggestClientList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 契約のサジェスト項目を取得する
         * @summary サジェスト項目取得（契約）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestContractList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: any): AxiosPromise<Array<BillingSuggestListOutputResponse>> {
            return localVarFp.billingSuggestContractList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 契約店舗のサジェスト項目を取得する
         * @summary サジェスト項目取得（契約店舗）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestContractShopList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: any): AxiosPromise<Array<BillingSuggestListOutputResponse>> {
            return localVarFp.billingSuggestContractShopList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 社内システム用アカウントのサジェスト項目を取得する
         * @summary サジェスト項目取得（社内システム用アカウント）
         * @param {string} [roleName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestIncAccountList(roleName?: string, options?: any): AxiosPromise<Array<BillingSuggestListOutputResponse>> {
            return localVarFp.billingSuggestIncAccountList(roleName, options).then((request) => request(axios, basePath));
        },
        /**
         * モニターのサジェスト項目を取得する
         * @summary サジェスト項目取得（モニター）
         * @param {string} [keyword] 
         * @param {number} [billingHeaderId] 
         * @param {number} [contractId] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingSuggestMonitorBaseList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: any): AxiosPromise<Array<BillingSuggestListOutputResponse>> {
            return localVarFp.billingSuggestMonitorBaseList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 流し込み請求書作成の事前バリデーション用API
         * @summary 流し込み請求書作成API
         * @param {BillingInvoiceFileUploadValidationFormResponse} billingInvoiceFileUploadValidationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creaetBillingDataByFileUploadValidation(billingInvoiceFileUploadValidationFormResponse: BillingInvoiceFileUploadValidationFormResponse, options?: any): AxiosPromise<Array<BillingInvoiceFileUploadValidationOutputResponse>> {
            return localVarFp.creaetBillingDataByFileUploadValidation(billingInvoiceFileUploadValidationFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ジョブ実行履歴を取得する
         * @summary ジョブ実行履歴取得
         * @param {string} [jobName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobExecutionHistory(jobName?: string, options?: any): AxiosPromise<Array<JobExecutionHistoryOutputResponse>> {
            return localVarFp.jobExecutionHistory(jobName, options).then((request) => request(axios, basePath));
        },
        /**
         * 応募IDを元に入金消し込みする
         * @summary 入金消し込み
         * @param {PaidApplyFormResponse} paidApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidapply(paidApplyFormResponse: PaidApplyFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.paidapply(paidApplyFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 郵便番号から都道府県を取得する
         * @summary 郵便番号から都道府県を取得
         * @param {string} postalcode Postal Code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prefecturesByPostalcode(postalcode: string, options?: any): AxiosPromise<PrefecturesByPostalcodeOutputResponse> {
            return localVarFp.prefecturesByPostalcode(postalcode, options).then((request) => request(axios, basePath));
        },
        /**
         * 非請求承認一覧を取得する
         * @summary 非請求承認一覧取得
         * @param {number} [type] 
         * @param {number} [incAccountId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unBillingApprovalList(type?: number, incAccountId?: number, options?: any): AxiosPromise<Array<UnBillingApprovalListOutputResponse>> {
            return localVarFp.unBillingApprovalList(type, incAccountId, options).then((request) => request(axios, basePath));
        },
        /**
         * 非請求申請ステータスを更新する
         * @summary 非請求申請
         * @param {UnBillingApplyFormResponse} unBillingApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbillingApply(unBillingApplyFormResponse: UnBillingApplyFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.unbillingApply(unBillingApplyFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 非請求申請を承認または却下する
         * @summary 非請求承認
         * @param {UnBillingApproveFormResponse} unBillingApproveFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbillingApprove(unBillingApproveFormResponse: UnBillingApproveFormResponse, options?: any): AxiosPromise<UnBillingApproveOutputResponse> {
            return localVarFp.unbillingApprove(unBillingApproveFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 非請求申請一覧を取得する
         * @summary 非請求申請一覧取得
         * @param {number} [type] 
         * @param {number} [contractShopId] 
         * @param {number} [monitorBaseId] 
         * @param {string} [startYm] 
         * @param {string} [endYm] 
         * @param {string} [applicationIds] 
         * @param {number} [billingHeaderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unbillingList(type?: number, contractShopId?: number, monitorBaseId?: number, startYm?: string, endYm?: string, applicationIds?: string, billingHeaderId?: number, options?: any): AxiosPromise<Array<UnBillingListOutputResponse>> {
            return localVarFp.unbillingList(type, contractShopId, monitorBaseId, startYm, endYm, applicationIds, billingHeaderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * 代理店フィー明細を取得する
     * @summary 代理店フィー明細取得
     * @param {number} [agentFeeListHeaderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingAgentFeeDetail(agentFeeListHeaderId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingAgentFeeDetail(agentFeeListHeaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 代理店フィー一覧を取得する
     * @summary 代理店フィー一覧取得
     * @param {number} [billingAgentId] 
     * @param {number} [salesIncAccountId] 
     * @param {number} [clientId] 
     * @param {string} [targetMonth] 
     * @param {number} [agentFeeStatus] 
     * @param {boolean} [hasUnapproved] 
     * @param {number} [approverIncAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingAgentFeeList(billingAgentId?: number, salesIncAccountId?: number, clientId?: number, targetMonth?: string, agentFeeStatus?: number, hasUnapproved?: boolean, approverIncAccountId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingAgentFeeList(billingAgentId, salesIncAccountId, clientId, targetMonth, agentFeeStatus, hasUnapproved, approverIncAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書承認者リストの取得をする
     * @summary 請求書承認者リストの取得
     * @param {number} incAccountId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingApproverList(incAccountId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingApproverList(incAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 配送メモを更新する
     * @summary 配送メモ更新
     * @param {BillingDeliveryNoteUpdateFormResponse} billingDeliveryNoteUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDeliveryNoteUpdate(billingDeliveryNoteUpdateFormResponse: BillingDeliveryNoteUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDeliveryNoteUpdate(billingDeliveryNoteUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先と契約の紐付けを一括解除する
     * @summary 請求送付先契約紐付け一括解除
     * @param {BillingDestinationContractBulkDeleteFormResponse} billingDestinationContractBulkDeleteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestContractBulkDelete(billingDestinationContractBulkDeleteFormResponse: BillingDestinationContractBulkDeleteFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestContractBulkDelete(billingDestinationContractBulkDeleteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先と契約の紐付けを一括紐づけする
     * @summary 請求送付先契約紐付け一括紐づけ
     * @param {BillingDestinationContractBulkUpdateFormResponse} billingDestinationContractBulkUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestContractBulkUpdate(billingDestinationContractBulkUpdateFormResponse: BillingDestinationContractBulkUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestContractBulkUpdate(billingDestinationContractBulkUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先契約の紐付けを解除する
     * @summary 請求送付先契約紐付け解除
     * @param {BillingDestinationContractDeleteFormResponse} billingDestinationContractDeleteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestContractDelete(billingDestinationContractDeleteFormResponse: BillingDestinationContractDeleteFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestContractDelete(billingDestinationContractDeleteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先契約一覧を取得する
     * @summary 請求送付先_契約一覧取得
     * @param {number} originalBillingDestinationId 
     * @param {number} [clientId] 
     * @param {number} [incAccountId] 
     * @param {string} [contractShopIds] 
     * @param {number} [billingDestinationBranchNumber] 
     * @param {string} [billingDestinationName] 
     * @param {boolean} [relationFlg] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestContractList(originalBillingDestinationId: number, clientId?: number, incAccountId?: number, contractShopIds?: string, billingDestinationBranchNumber?: number, billingDestinationName?: string, relationFlg?: boolean, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestContractList(originalBillingDestinationId, clientId, incAccountId, contractShopIds, billingDestinationBranchNumber, billingDestinationName, relationFlg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先と契約を紐付ける
     * @summary 請求送付先契約紐付け
     * @param {BillingDestinationContractUpdateFormResponse} billingDestinationContractUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestContractUpdate(billingDestinationContractUpdateFormResponse: BillingDestinationContractUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestContractUpdate(billingDestinationContractUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先店舗の紐付けを一括解除する
     * @summary 請求送付先店舗紐付け一括解除
     * @param {BillingDestinationShopBulkDeleteFormResponse} billingDestinationShopBulkDeleteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestShopBulkDelete(billingDestinationShopBulkDeleteFormResponse: BillingDestinationShopBulkDeleteFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestShopBulkDelete(billingDestinationShopBulkDeleteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先店舗の紐付けを一括紐づけする
     * @summary 請求送付先店舗紐付け一括紐づけ
     * @param {BillingDestinationShopBulkUpdateFormResponse} billingDestinationShopBulkUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestShopBulkUpdate(billingDestinationShopBulkUpdateFormResponse: BillingDestinationShopBulkUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestShopBulkUpdate(billingDestinationShopBulkUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先店舗の紐付けを解除する
     * @summary 請求送付先店舗紐付け解除
     * @param {BillingDestinationShopDeleteFormResponse} billingDestinationShopDeleteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestShopDelete(billingDestinationShopDeleteFormResponse: BillingDestinationShopDeleteFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestShopDelete(billingDestinationShopDeleteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先店舗一覧を取得する
     * @summary 請求送付先店舗一覧取得
     * @param {number} originalBillingDestinationId 
     * @param {number} [clientId] 
     * @param {number} [salesId] 
     * @param {string} [shopId] 
     * @param {number} [branchNumber] 
     * @param {string} [billingDestName] 
     * @param {boolean} [relationFlg] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestShopList(originalBillingDestinationId: number, clientId?: number, salesId?: number, shopId?: string, branchNumber?: number, billingDestName?: string, relationFlg?: boolean, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestShopList(originalBillingDestinationId, clientId, salesId, shopId, branchNumber, billingDestName, relationFlg, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先店舗を紐付ける
     * @summary 請求送付先店舗紐付け
     * @param {BillingDestinationShopUpdateFormResponse} billingDestinationShopUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestShopUpdate(billingDestinationShopUpdateFormResponse: BillingDestinationShopUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestShopUpdate(billingDestinationShopUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先の自動承認を更新する
     * @summary 請求送付先自動承認更新
     * @param {BillingDestinationAutoApprovalUpdateFormResponse} billingDestinationAutoApprovalUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestinationAutoApprovalUpdate(billingDestinationAutoApprovalUpdateFormResponse: BillingDestinationAutoApprovalUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestinationAutoApprovalUpdate(billingDestinationAutoApprovalUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先をコピーする
     * @summary 請求送付先コピー
     * @param {Array<BillingDestinationCopyFormResponse>} billingDestinationCopyFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestinationCopy(billingDestinationCopyFormResponse: Array<BillingDestinationCopyFormResponse>, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestinationCopy(billingDestinationCopyFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先を作成する
     * @summary 請求送付先作成
     * @param {BillingDestinationCreateFormResponse} billingDestinationCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestinationCreate(billingDestinationCreateFormResponse: BillingDestinationCreateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestinationCreate(billingDestinationCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先明細を取得する
     * @summary 請求送付先明細取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestinationDetail(id: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestinationDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先等の情報を取得
     * @summary 請求送付先等の情報を取得
     * @param {number} billingHeaderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestinationInfo(billingHeaderId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestinationInfo(billingHeaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先一覧を取得する
     * @summary 請求送付先一覧取得
     * @param {number} [clientId] 
     * @param {number} [incAccountId] 
     * @param {string} [billingDestinationName] 
     * @param {number} [contractId] 
     * @param {number} [contractShopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestinationList(clientId?: number, incAccountId?: number, billingDestinationName?: string, contractId?: number, contractShopId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestinationList(clientId, incAccountId, billingDestinationName, contractId, contractShopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先紐づけ画面のヘッダ情報を取得
     * @summary 請求送付先紐づけ画面のヘッダ情報を取得
     * @param {number} billingDestinationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestinationRelationHeader(billingDestinationId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestinationRelationHeader(billingDestinationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求送付先を更新する
     * @summary 請求送付先更新
     * @param {BillingDestinationUpdateFormResponse} billingDestinationUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDestinationUpdate(billingDestinationUpdateFormResponse: BillingDestinationUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDestinationUpdate(billingDestinationUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 按分設定データを作成する
     * @summary 按分手動作成
     * @param {BillingDivideInvoiceFormResponse} billingDivideInvoiceFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingDivideInvoice(billingDivideInvoiceFormResponse: BillingDivideInvoiceFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingDivideInvoice(billingDivideInvoiceFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書PDFダウンロード
     * @summary 請求PDF/Excelダウンロード
     * @param {BillingFileDownloadFormResponse} billingFileDownloadFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingFileDownload(billingFileDownloadFormResponse: BillingFileDownloadFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingFileDownload(billingFileDownloadFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書PDFデータのアップロード
     * @summary 請求書PDFデータのアップロード
     * @param {BillingFileUploadFormResponse} billingFileUploadFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingFileUpload(billingFileUploadFormResponse: BillingFileUploadFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingFileUpload(billingFileUploadFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書の翌月繰越を行う
     * @summary 請求書翌月繰越
     * @param {BillingInvoiceCarryOverFormResponse} billingInvoiceCarryOverFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceCarryOver(billingInvoiceCarryOverFormResponse: BillingInvoiceCarryOverFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceCarryOver(billingInvoiceCarryOverFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_派遣別明細を取得する
     * @summary 請求書_派遣別明細
     * @param {number} billingHeaderId 
     * @param {number} [contractShopId] 
     * @param {string} [applyIds] 
     * @param {string} [visitAt] 
     * @param {string} [completeAt] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceDetailApplication(billingHeaderId: number, contractShopId?: number, applyIds?: string, visitAt?: string, completeAt?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceDetailApplication(billingHeaderId, contractShopId, applyIds, visitAt, completeAt, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_派遣別明細を取得する。PDFダウンロード用
     * @summary 請求書_派遣別明細
     * @param {number} billingHeaderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceDetailApplicationForPdf(billingHeaderId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceDetailApplicationForPdf(billingHeaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_派遣別明細を更新する
     * @summary 請求書_派遣別明細更新
     * @param {BillingInvoiceDetailApplicationUpdateFormResponse} billingInvoiceDetailApplicationUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceDetailApplicationUpdate(billingInvoiceDetailApplicationUpdateFormResponse: BillingInvoiceDetailApplicationUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceDetailApplicationUpdate(billingInvoiceDetailApplicationUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_明細ヘッダ情報を取得する
     * @summary 請求書_明細ヘッダ情報
     * @param {number} billingHeaderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceDetailHeader(billingHeaderId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceDetailHeader(billingHeaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_商品別明細を取得する
     * @summary 請求書_商品別明細
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceDetailItem(id: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceDetailItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_商品別明細を取得する
     * @summary 請求書_商品別明細
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceDetailItemForPdf(id: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceDetailItemForPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_商品別明細を更新する
     * @summary 請求書_商品別明細更新
     * @param {BillingInvoiceDetailItemUpdateFormResponse} billingInvoiceDetailItemUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceDetailItemUpdate(billingInvoiceDetailItemUpdateFormResponse: BillingInvoiceDetailItemUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceDetailItemUpdate(billingInvoiceDetailItemUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書帳票(CSV)をダウンロードする
     * @summary 請求書帳票ダウンロードCSV
     * @param {BillingInvoiceDownloadCsvFormResponse} billingInvoiceDownloadCsvFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceDownloadCsv(billingInvoiceDownloadCsvFormResponse: BillingInvoiceDownloadCsvFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceDownloadCsv(billingInvoiceDownloadCsvFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書ダウンロード一覧を取得する
     * @summary 請求書ダウンロード一覧取得
     * @param {number} [clientId] 
     * @param {number} [salesIncAccountId] 
     * @param {number} [contractShopId] 
     * @param {string} [targetMonth] 
     * @param {Array<number>} [billingFlowStatuses] 
     * @param {boolean} [hasInformart] 
     * @param {string} [deliveryNote] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceDownloadList(clientId?: number, salesIncAccountId?: number, contractShopId?: number, targetMonth?: string, billingFlowStatuses?: Array<number>, hasInformart?: boolean, deliveryNote?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceDownloadList(clientId, salesIncAccountId, contractShopId, targetMonth, billingFlowStatuses, hasInformart, deliveryNote, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求フローステータスを取得する
     * @summary 請求フローステータス取得
     * @param {number} billingHeaderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceFlowStatus(billingHeaderId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceFlowStatus(billingHeaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書一覧を取得する
     * @summary 請求書一覧取得
     * @param {number} [clientId] 
     * @param {number} [salesIncAccountId] 
     * @param {number} [contractShopId] 
     * @param {string} [targetMonth] 
     * @param {Array<number>} [billingFlowStatuses] 
     * @param {boolean} [hasBilling] 
     * @param {number} [billingAgentId] 
     * @param {number} [approverIncAccountId] 
     * @param {boolean} [publishBillingFlg] 
     * @param {string} [deliveryNote] 
     * @param {number} [billingHeaderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceList(clientId?: number, salesIncAccountId?: number, contractShopId?: number, targetMonth?: string, billingFlowStatuses?: Array<number>, hasBilling?: boolean, billingAgentId?: number, approverIncAccountId?: number, publishBillingFlg?: boolean, deliveryNote?: string, billingHeaderId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceList(clientId, salesIncAccountId, contractShopId, targetMonth, billingFlowStatuses, hasBilling, billingAgentId, approverIncAccountId, publishBillingFlg, deliveryNote, billingHeaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求ステータス更新
     * @summary 請求ステータス更新
     * @param {BillingInvoiceStatusUpdateFormResponse} billingInvoiceStatusUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceStatusUpdate(billingInvoiceStatusUpdateFormResponse: BillingInvoiceStatusUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceStatusUpdate(billingInvoiceStatusUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_店舗別明細を取得する。帳票出力にのみ利用
     * @summary 請求書_店舗別明細
     * @param {number} billingHeaderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceStoreDetailItem(billingHeaderId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceStoreDetailItem(billingHeaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_店舗商品合算明細を取得する
     * @summary 請求書_店舗商品合算明細
     * @param {number} billingHeaderId 
     * @param {number} [contractShopId] 
     * @param {number} [monitorBaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceTotalingDetailItem(billingHeaderId: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceTotalingDetailItem(billingHeaderId, contractShopId, monitorBaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_調査別明細を取得する
     * @summary 請求書_調査別明細_帳票ダウンロード用
     * @param {number} billingHeaderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceTotalingDetailItemForPdf(billingHeaderId: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceTotalingDetailItemForPdf(billingHeaderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書_店舗商品合算明細を更新する
     * @summary 請求書_店舗商品合算明細更新
     * @param {BillingInvoiceTotalingDetailItemUpdateFormResponse} billingInvoiceTotalingDetailItemUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingInvoiceTotalingDetailItemUpdate(billingInvoiceTotalingDetailItemUpdateFormResponse: BillingInvoiceTotalingDetailItemUpdateFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingInvoiceTotalingDetailItemUpdate(billingInvoiceTotalingDetailItemUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 請求書PDF一斉ダウンロード
     * @summary 請求PDF一斉ダウンロード
     * @param {BillingPdfAllDownloadFormResponse} billingPdfAllDownloadFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingPdfAllDownload(billingPdfAllDownloadFormResponse: BillingPdfAllDownloadFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingPdfAllDownload(billingPdfAllDownloadFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 代理店のサジェスト項目を取得する
     * @summary サジェスト項目取得（代理店）
     * @param {string} [keyword] 
     * @param {number} [billingHeaderId] 
     * @param {number} [contractId] 
     * @param {number} [contractShopId] 
     * @param {number} [monitorBaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingSuggestBillingAgentListList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingSuggestBillingAgentListList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアントのサジェスト項目を取得する
     * @summary サジェスト項目取得（クライアント）
     * @param {string} [keyword] 
     * @param {number} [billingHeaderId] 
     * @param {number} [contractId] 
     * @param {number} [contractShopId] 
     * @param {number} [monitorBaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingSuggestClientList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingSuggestClientList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 契約のサジェスト項目を取得する
     * @summary サジェスト項目取得（契約）
     * @param {string} [keyword] 
     * @param {number} [billingHeaderId] 
     * @param {number} [contractId] 
     * @param {number} [contractShopId] 
     * @param {number} [monitorBaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingSuggestContractList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingSuggestContractList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 契約店舗のサジェスト項目を取得する
     * @summary サジェスト項目取得（契約店舗）
     * @param {string} [keyword] 
     * @param {number} [billingHeaderId] 
     * @param {number} [contractId] 
     * @param {number} [contractShopId] 
     * @param {number} [monitorBaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingSuggestContractShopList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingSuggestContractShopList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 社内システム用アカウントのサジェスト項目を取得する
     * @summary サジェスト項目取得（社内システム用アカウント）
     * @param {string} [roleName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingSuggestIncAccountList(roleName?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingSuggestIncAccountList(roleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニターのサジェスト項目を取得する
     * @summary サジェスト項目取得（モニター）
     * @param {string} [keyword] 
     * @param {number} [billingHeaderId] 
     * @param {number} [contractId] 
     * @param {number} [contractShopId] 
     * @param {number} [monitorBaseId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public billingSuggestMonitorBaseList(keyword?: string, billingHeaderId?: number, contractId?: number, contractShopId?: number, monitorBaseId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).billingSuggestMonitorBaseList(keyword, billingHeaderId, contractId, contractShopId, monitorBaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 流し込み請求書作成の事前バリデーション用API
     * @summary 流し込み請求書作成API
     * @param {BillingInvoiceFileUploadValidationFormResponse} billingInvoiceFileUploadValidationFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public creaetBillingDataByFileUploadValidation(billingInvoiceFileUploadValidationFormResponse: BillingInvoiceFileUploadValidationFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).creaetBillingDataByFileUploadValidation(billingInvoiceFileUploadValidationFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ジョブ実行履歴を取得する
     * @summary ジョブ実行履歴取得
     * @param {string} [jobName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public jobExecutionHistory(jobName?: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).jobExecutionHistory(jobName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 応募IDを元に入金消し込みする
     * @summary 入金消し込み
     * @param {PaidApplyFormResponse} paidApplyFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public paidapply(paidApplyFormResponse: PaidApplyFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).paidapply(paidApplyFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 郵便番号から都道府県を取得する
     * @summary 郵便番号から都道府県を取得
     * @param {string} postalcode Postal Code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public prefecturesByPostalcode(postalcode: string, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).prefecturesByPostalcode(postalcode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 非請求承認一覧を取得する
     * @summary 非請求承認一覧取得
     * @param {number} [type] 
     * @param {number} [incAccountId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public unBillingApprovalList(type?: number, incAccountId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).unBillingApprovalList(type, incAccountId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 非請求申請ステータスを更新する
     * @summary 非請求申請
     * @param {UnBillingApplyFormResponse} unBillingApplyFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public unbillingApply(unBillingApplyFormResponse: UnBillingApplyFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).unbillingApply(unBillingApplyFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 非請求申請を承認または却下する
     * @summary 非請求承認
     * @param {UnBillingApproveFormResponse} unBillingApproveFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public unbillingApprove(unBillingApproveFormResponse: UnBillingApproveFormResponse, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).unbillingApprove(unBillingApproveFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 非請求申請一覧を取得する
     * @summary 非請求申請一覧取得
     * @param {number} [type] 
     * @param {number} [contractShopId] 
     * @param {number} [monitorBaseId] 
     * @param {string} [startYm] 
     * @param {string} [endYm] 
     * @param {string} [applicationIds] 
     * @param {number} [billingHeaderId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public unbillingList(type?: number, contractShopId?: number, monitorBaseId?: number, startYm?: string, endYm?: string, applicationIds?: string, billingHeaderId?: number, options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).unbillingList(type, contractShopId, monitorBaseId, startYm, endYm, applicationIds, billingHeaderId, options).then((request) => request(this.axios, this.basePath));
    }
}
