import { useQuery } from '@tanstack/react-query';
import { BillingApi } from '../api-client';
import { CACHE_KEY_BILLING_AGENT } from '../Constants';

export default function useBillingSuggestAgent() {
  const billingApi = new BillingApi();

  const result = useQuery({
    queryKey: [CACHE_KEY_BILLING_AGENT],
    queryFn: async () =>
      billingApi.billingSuggestBillingAgentListList().then((res) => {
        return res;
      }),
  });
  return {
    isSuccess: result.isSuccess,
    data: result.data?.data,
  };
}
