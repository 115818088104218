import { faLink, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Form, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「指定商品」Contents
export const MonitorRuleSetSpecifyItemContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  const [itemList, setItemList] = useState<MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[]>([]);
  const [ngItemList, setNGItemList] = useState<string[]>();
  const [requireMessage, setRequireMessage] = useState<string>();

  // 購入、注文、飲食のどちらか
  const eitherWord = getEitherWord(data.monitorRuleSetType);

  const defaultSupplementSuffix = getDefaultSupplementSuffix(data.monitorRuleSetType);

  useEffect(() => {
    if (data?.monitorRuleList?.find((_) => _.monitorRuleType === 6)?.targetItemList.length) {
      setItemList(
        Object.assign(
          [],
          data?.monitorRuleList?.find((_) => {
            return _.monitorRuleType === 6;
          })?.targetItemList
        )
      );
    } else {
      setDataItemList([
        {
          itemName: undefined,
          orderQuantity: undefined,
          supplement: undefined,
          imageFlg: undefined,
          imageQuantity: undefined,
          excludedItem: undefined,
        },
      ] as MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[]);
    }

    setNGItemList(
      Object.assign(
        [],
        data?.monitorRuleList?.find((_) => {
          return _.monitorRuleType === 6;
        })?.ngItemList
      )
    );
  }, [
    data?.monitorRuleList?.find((_) => {
      return _.monitorRuleType === 6;
    })?.targetItemList,
  ]);

  const setDataItemList = (list: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[]) => {
    setData({
      ...data,
      monitorRuleList: data?.monitorRuleList.map((row, inde) => {
        if (row.monitorRuleType === 6) {
          // 指定商品
          return {
            ...row,
            targetItemList: list,
          };
        }
        return { ...row };
      }),
    });
    setItemList(list);
  };

  function getEitherWord(ruleSetType: number) {
    // 購入、注文、飲食のどちらか
    let either = '購入';
    if (data.monitorRuleSetType === 1) {
      either = '飲食';
    } else if (data.monitorRuleSetType === 3) {
      either = '注文';
    }

    return either;
  }

  function getDefaultSupplementSuffix(ruleSetType: number) {
    let suffix = '';
    if (data.monitorRuleSetType === 5) {
      // ショッピングの場合。なので「購入」でOK。
      suffix =
        '※類似商品との間違いにお気を付けください。\n' +
        '※類似商品との間違いにお気を付けください。下記は同時に購入は可能ですが、指定商品ではありません。\n' +
        '・●●\n' +
        '・××\n' +
        '※謝礼は指定個数分に対してのみ支払われます。\n' +
        '※「指定商品」を複数購入されたり、他商品を同時購入された場合、謝礼はお支払いはできません。';
    }

    return suffix;
  }

  return (
    <>
      {requireMessage && <div style={{ color: 'red' }}>{requireMessage}</div>}
      <div className="d-flex ">
        <Form.Label style={{ fontWeight: 'bold' }}>指定商品</Form.Label>
      </div>
      <Table className="table-borderless" width="100%">
        <thead>
          <tr>
            <th> </th>
            <th>商品</th>
            <th>数量</th>
            <th>補足</th>
            <th style={{ textAlign: 'center' }}>撮影</th>
            <th>撮影数量</th>
            <th>除外商品</th>
          </tr>
        </thead>
        <tbody>
          {itemList?.map((_: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, ind: number) => {
            return (
              <tr key={ind.toString()} className="align-middle">
                <td>{ind + 1}</td>
                <td>
                  <Form.Control
                    data-testid="itemNameText"
                    value={_.itemName || ''}
                    type="text"
                    onChange={(e) => {
                      const newItemList: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[] = itemList.map(
                        (row: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, i) => {
                          return i === ind ? { ...row, itemName: e.target.value } : { ...row };
                        }
                      );

                      setDataItemList(newItemList);
                    }}
                  />
                </td>
                <td width="8%">
                  <Form.Control
                    data-testid="itemOrderQuantityText"
                    value={_.orderQuantity || ''}
                    type="number"
                    onChange={(e) => {
                      const newItemList: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[] = itemList.map(
                        (row: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, i) => {
                          return i === ind ? { ...row, orderQuantity: Number(e.target.value) } : { ...row };
                        }
                      );

                      setDataItemList(newItemList);
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    data-testid="itemSupplementText"
                    as="textarea"
                    rows={3}
                    value={_.supplement || ''}
                    type="text"
                    onChange={(e) => {
                      const newItemList: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[] = itemList.map(
                        (row: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, i) => {
                          return i === ind ? { ...row, supplement: e.target.value } : { ...row };
                        }
                      );

                      setDataItemList(newItemList);
                    }}
                  />
                </td>
                <td width="4%" align="center">
                  <Form.Check.Input
                    data-testid="itemImageCheckBox"
                    className="flex-shrink-0"
                    type="checkbox"
                    name="checkBoxGroup01"
                    checked={_.imageFlg}
                    onChange={(e) => {
                      const newItemList: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[] = itemList.map(
                        (row: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, i) => {
                          return i === ind ? { ...row, imageFlg: e.target.checked } : { ...row };
                        }
                      );

                      setDataItemList(newItemList);
                    }}
                  />
                </td>
                <td width="8%">
                  <Form.Control
                    data-testid="itemImageQuantityText"
                    value={_.imageQuantity || ''}
                    type="number"
                    onChange={(e) => {
                      const newItemList: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[] = itemList.map(
                        (row: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, i) => {
                          return i === ind ? { ...row, imageQuantity: Number(e.target.value) } : { ...row };
                        }
                      );

                      setDataItemList(newItemList);
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    data-testid="excludedItemText"
                    value={_.excludedItem || ''}
                    type="text"
                    onChange={(e) => {
                      const newItemList: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[] = itemList.map(
                        (row: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse, i) => {
                          return i === ind ? { ...row, excludedItem: e.target.value } : { ...row };
                        }
                      );

                      setDataItemList(newItemList);
                    }}
                  />
                </td>
                <td>
                  <Button
                    data-testid={`itemDeleteButton${ind + 1}`}
                    className="text-secondary bg-transparent border-0"
                    onClick={() => {
                      setDataItemList(
                        itemList.filter((row, i) => {
                          return i !== ind;
                        })
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end mb-2">
        <Button
          data-testid="itemAddButton"
          variant="link"
          className="text-secondary p-0"
          onClick={() => {
            const newTargetList = Object.assign([], itemList);
            newTargetList.push({
              itemName: undefined,
              orderQuantity: undefined,
              supplement: undefined,
              imageFlg: undefined,
              imageQuantity: undefined,
              excludedItem: undefined,
            });

            setDataItemList(newTargetList);
          }}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          <span style={{ fontSize: '1rem' }}>追加</span>
        </Button>
      </div>
      <Form.Label style={{ fontWeight: 'bold' }}>{eitherWord}NG商品</Form.Label>
      <Table className="table-borderless" width="100%">
        <thead>
          <tr>
            <th> </th>
            <th>商品</th>
          </tr>
        </thead>
        <tbody>
          {data?.monitorRuleList
            ?.find((_) => {
              return _.monitorRuleType === 6;
            })
            ?.ngItemList?.map((_: string, ind: number) => {
              return (
                <tr key={ind.toString()} className="align-middle">
                  <td width="3%">{ind + 1}</td>
                  <td>
                    <Form.Control
                      data-testid="ngItemText"
                      value={_ || ''}
                      type="text"
                      onChange={(e) => {
                        setData({
                          ...data,
                          monitorRuleList: data?.monitorRuleList.map((row, i) => {
                            return row.monitorRuleType === 6
                              ? {
                                  ...row,
                                  ngItemList: row.ngItemList?.map((r, c) => {
                                    return c === ind ? e.target.value : r;
                                  }),
                                }
                              : { ...row };
                          }),
                        });
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end mb-4">
        <Button
          data-testid="ngItemAddButton"
          variant="link"
          className="text-secondary p-0"
          onClick={() => {
            const newNgTargetList = Object.assign([], ngItemList);
            newNgTargetList.push('');
            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((row, i) => {
                return row.monitorRuleType === 6 ? { ...row, ngItemList: newNgTargetList } : { ...row };
              }),
            });
          }}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          <span style={{ fontSize: '1rem' }}>追加</span>
        </Button>
        <Button
          data-testid="itemReflectButton"
          className="ms-3"
          variant="secondary"
          onClick={() => {
            let reflectionText = '';
            let supplementText = '';
            let supplementItemCount = '';
            let supplementNg = '';
            let supplementExcluded = '';
            const targetItemList: MonitorRuleSetInfoMonitorRuleTargetItemOutputResponse[] | undefined =
              data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 6;
              })?.targetItemList;
            const targetNGItemList: string[] | undefined = data?.monitorRuleList?.find((r) => {
              return r.monitorRuleType === 6;
            })?.ngItemList;
            if (itemList) {
              let defaultPrefix = '';
              if (data.monitorRuleSetType !== 5) {
                // ショッピング以外の場合
                defaultPrefix = '有料メニューの中から、';
              }

              // 指定商品の数で分岐
              if (itemList.length === 1) {
                reflectionText = `${defaultPrefix}下記(1)を含んだ${eitherWord}をしてください。\n`;
                supplementItemCount = '(1)';
              } else if (itemList.length === 0) {
                reflectionText = '';
                setRequireMessage('指定商品を入力してください。');
              } else {
                reflectionText = `${defaultPrefix}下記（1～${itemList.length}）を含んだ${eitherWord}をしてください。\n`;
                supplementItemCount = `（1～${itemList.length}）`;
              }

              for (let i = 0; i < itemList.length; i += 1) {
                reflectionText = `${reflectionText}${i + 1}.`;

                // 補足文の入力があるかどうか
                if (itemList[i].orderQuantity) {
                  const image = itemList[i].imageFlg ? '📷' : '';
                  if (
                    itemList[i].supplement !== undefined &&
                    itemList[i].supplement !== '' &&
                    itemList[i].supplement !== null
                  ) {
                    if (data.monitorRuleSetType === 5) {
                      // ショッピングの場合
                      reflectionText = `${reflectionText}下記の中からいずれかを${itemList[i].orderQuantity}点`;
                    } else {
                      reflectionText = `${reflectionText}下記から${itemList[i].orderQuantity}品`;
                    }
                    if (itemList[i].excludedItem) {
                      // 除外商品がある場合
                      reflectionText = `${reflectionText}（${itemList[i].excludedItem}は除く）${image}\n`;
                    } else {
                      // 除外商品がない場合
                      reflectionText = `${reflectionText}${image}\n`;
                    }
                    reflectionText = `${reflectionText}${itemList[i].supplement}\n`;
                    setRequireMessage('');
                  }
                  // 補足文の入力がない場合
                  else if (
                    itemList[i].itemName !== undefined &&
                    itemList[i].itemName !== '' &&
                    itemList[i].itemName !== null
                  ) {
                    reflectionText = `${reflectionText}${itemList[i].itemName}を${itemList[i].orderQuantity}品`;
                    if (itemList[i].excludedItem) {
                      // 除外商品がある場合
                      reflectionText = `${reflectionText}（${itemList[i].excludedItem}は除く）${image}\n`;
                    } else {
                      // 除外商品がない場合
                      reflectionText = `${reflectionText}${image}\n`;
                    }
                    setRequireMessage('');
                  } else {
                    reflectionText = '';
                    setRequireMessage('数量と商品または補足欄を入力してください。');
                  }
                } else {
                  reflectionText = '';
                  setRequireMessage('数量と商品または補足欄を入力してください。');
                }
              }

              // 除外商品の有無で分岐
              if (itemList.some(({ excludedItem }) => excludedItem)) {
                supplementExcluded = '（モニター除外商品含む）';
              }

              if (reflectionText) {
                // 購入NG商品の入力があるかどうか
                if (targetNGItemList?.length) {
                  reflectionText = `${reflectionText}【${eitherWord}NG商品】\n下記をご${eitherWord}した場合は謝礼のお支払いはできません。\n`;
                  for (let i = 0; i < targetNGItemList.length; i += 1) {
                    if (targetNGItemList[i] !== '') {
                      reflectionText = `${reflectionText}・${targetNGItemList[i]}\n`;
                    }
                  }
                  supplementNg = `${eitherWord}NG商品を除き、`;
                }
              }

              // 補足文を組み立て
              supplementText = `※${supplementNg}${supplementItemCount}以外のご${eitherWord}は自由${supplementExcluded}です。\n${defaultSupplementSuffix}\n`;

              // 写真撮影対象があるかどうか
              if (itemList.some(({ imageFlg }) => imageFlg)) {
                supplementText = `${supplementText}※📷は写真撮影対象です。\n`;
              }
            }

            // 指定商品・写真撮影の追加・更新--------------------------------------------------------
            let copyImageRuleList = Object.assign(
              [],
              data.monitorRuleList.find((r, i) => {
                return r.monitorRuleType === 7;
              })?.imageRuleList
            );
            const firstItem: any[] | undefined = itemList
              .filter((_) => _.imageFlg)
              .map((_) => {
                return { itemName: _.itemName, orderQuantity: _.orderQuantity };
              });
            copyImageRuleList = copyImageRuleList.filter((copyImage) => firstItem.includes(copyImage[0]));
            const addItemCount = firstItem.length - copyImageRuleList.length;
            for (let ind = 0; ind < addItemCount; ind += 1) {
              if (firstItem) {
                for (let j = 0; j < firstItem[ind].orderQuantity; j += 1) {
                  copyImageRuleList.push({
                    angle: 1,
                    angleFreeText: undefined,
                    objective: '',
                    other: undefined,
                    range: 1,
                    rangeFreeText: undefined,
                    timing: undefined,
                    imageTargetList: [firstItem[ind].itemName],
                    imageList: [],
                  });
                }
              }
            }

            let imageReflectionText = '';
            firstItem.map((item: any) => {
              imageReflectionText = `${imageReflectionText}${item.itemName}\n`;
            });

            let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
              [],
              data.monitorRuleList.find((supplement) => {
                return supplement.monitorRuleType === rowData.monitorRuleType;
              })?.supplementList
            );
            copySupplementList = copySupplementList.map((r, i) => {
              return i === 0 ? { ...r, supplement: supplementText } : { ...r };
            });

            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                if (row.monitorRuleType === 6) {
                  // 指定商品
                  return {
                    ...row,
                    beforeApplyContent: reflectionText,
                    afterApplyContent: reflectionText,
                    supplementList: copySupplementList,
                    // targetItemList: itemList,
                  };
                }
                if (row.monitorRuleType === 7) {
                  // 写真撮影
                  return {
                    ...row,
                    imageRuleList: copyImageRuleList,
                    beforeApplyContent: imageReflectionText,
                    afterApplyContent: imageReflectionText,
                  };
                }
                return { ...row };
              }),
            });
          }}
        >
          反映
        </Button>
      </div>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
