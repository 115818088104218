import React, { useState, Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import {
  BillingInvoiceDetailItemResultOutputResponse,
  BillingInvoiceDetailItemOutputResponse,
  BillingInvoiceDetailContractOutputResponse,
  BillingInvoiceDetailItemListDataOutputResponse,
} from '../../../api-client';
import { BillingInvoiceModal } from '../Modal/BillingInvoiceModal';
import { BillingDivideInvoiceModal } from '../Modal/BillingDivideInvoiceModal';
import { BillingContext } from '../../context/BillingContext';
import { BILLING_DIVISION, BILLING_ITEM_RELATION_TYPE, BILLING_STATUS } from '../../../BillingConstants';
import {
  calcNonTaxSubTotal,
  calcAmountPerTaxRate,
  calcIncludeTaxAmount,
  convertMoneyText,
  getRateText,
  calcTaxAmountPerTaxRate2,
} from '../../../utils/functionsForBilling';

interface Props {
  detail: BillingInvoiceDetailItemResultOutputResponse;
  setDetail: Dispatch<SetStateAction<BillingInvoiceDetailItemResultOutputResponse>>;
  setBillingAmount: Dispatch<SetStateAction<number>>;
  billingAmount: number;
}

interface BillingInvoiceDetailContractProps {
  billingContract: BillingInvoiceDetailContractOutputResponse;
}

interface BillingInvoiceDetailItemListDataProps {
  billingContract: BillingInvoiceDetailContractOutputResponse;
  billingItemDetail: BillingInvoiceDetailItemListDataOutputResponse;
  index: number;
}

export interface BillingInvoiceDetailItemProps {
  data: BillingInvoiceDetailItemOutputResponse;
}

export interface AmountPerTaxRateProps {
  taxRate: number;
  value: number;
  isFirst: boolean;
}

export interface BillingAmountProps {
  billingAmount_: number;
}

const EDIT_TYPE = {
  ADD: 'add',
  EDIT: 'edit',
};

export const BillingInvoiceDetailProductTable: React.VFC<Props> = ({
  detail,
  setDetail,
  setBillingAmount,
  billingAmount,
}) => {
  const [item, setItem] = useState<BillingInvoiceDetailItemListDataOutputResponse>();
  const [contract, setContract] = useState<BillingInvoiceDetailContractOutputResponse>();
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [editType, setEditType] = useState<string>(EDIT_TYPE.ADD);
  const [isDivideModal, setIsDivideModal] = useState<boolean>(false);

  const openModal = (
    editFlg: boolean,
    targetContract?: BillingInvoiceDetailContractOutputResponse,
    targetItem?: BillingInvoiceDetailItemListDataOutputResponse
  ) => {
    setItem(targetItem);
    setContract(targetContract);
    setEditType(editFlg ? EDIT_TYPE.EDIT : EDIT_TYPE.ADD);
    setIsEditModal(true);
  };

  const billingContext = useContext(BillingContext);
  const isSalesDptEditable = billingContext!.isSalesDptEditable!;
  const isAccDptMgrEditable = billingContext!.isAccDptMgrEditable!;

  const openDivideModal = (targetItem: BillingInvoiceDetailItemListDataOutputResponse) => {
    setItem(targetItem);
    setIsDivideModal(true);
  };

  useEffect(() => {
    // 帳票の請求金額も変更する
    setBillingAmount(
      calcIncludeTaxAmount(calcAmountPerTaxRate(detail.billingInvoiceDetailItemData.billingInvoiceItem))
    );
  }, [detail]);

  const changeBillingStatus = (e: any, billingItemDetailId: number) => {
    setDetail((prevState) => ({
      ...prevState,
      billingInvoiceDetailItemData: {
        ...prevState.billingInvoiceDetailItemData,
        billingInvoiceItem: prevState.billingInvoiceDetailItemData.billingInvoiceItem?.map(
          (billingInvoiceContractDetail) => {
            return {
              ...billingInvoiceContractDetail,
              billingInvoiceDetailItemList: billingInvoiceContractDetail.billingInvoiceDetailItemList.map(
                (billingInvoiceItemDetail) => {
                  if (billingInvoiceItemDetail.billingItemDetailId === billingItemDetailId) {
                    return { ...billingInvoiceItemDetail, billingStatus: Number(e.target.value) };
                  }
                  return billingInvoiceItemDetail;
                }
              ),
            };
          }
        ),
      },
    }));
  };

  const ItemColumns: React.VFC<BillingInvoiceDetailContractProps> = ({ billingContract }) => {
    return (
      <>
        {billingContract.billingInvoiceDetailItemList.map((billingItemDetail, index) => {
          return (
            <ItemColumn
              billingContract={billingContract}
              billingItemDetail={billingItemDetail}
              index={index}
              key={index + 23988777}
            />
          );
        })}
      </>
    );
  };

  const ItemColumn: React.VFC<BillingInvoiceDetailItemListDataProps> = ({
    billingContract,
    billingItemDetail,
    index,
  }) => {
    return (
      <>
        <tr key={billingItemDetail.billingItemDetailId + 10000}>
          <td>{index === 0 ? billingContract.contractName : ''}</td>
          <td>
            {isAccDptMgrEditable && billingItemDetail.divisionName === BILLING_DIVISION.SPOT ? (
              <a href="#" onClick={() => openDivideModal(billingItemDetail)}>
                {billingItemDetail.billingItemName}
              </a>
            ) : (
              billingItemDetail.billingItemName
            )}
          </td>

          <td>{billingItemDetail.itemNameForDisplay}</td>
          <td>{billingItemDetail.unitPrice === 0 ? '-' : convertMoneyText(billingItemDetail.unitPrice)}</td>
          <td>{billingItemDetail.quantity}</td>
          <td>{billingItemDetail.unitName}</td>
          <td>{convertMoneyText(billingItemDetail.amount)}</td>
          <td>{getRateText(billingItemDetail.taxRate)}</td>
          <td>
            <Form.Select
              value={billingItemDetail.billingStatus}
              disabled={
                !(
                  billingItemDetail.relationType === BILLING_ITEM_RELATION_TYPE.CONTRACT &&
                  isSalesDptEditable &&
                  billingItemDetail.billingStatus !== BILLING_STATUS.CARRY_OVER
                )
              }
              onChange={(e) => changeBillingStatus(e, billingItemDetail.billingItemDetailId)}
            >
              <option value={BILLING_STATUS.BILLING}>請求</option>
              <option value={BILLING_STATUS.UN_BILLING}>非請求</option>
              <option value={BILLING_STATUS.CARRY_OVER} className="d-none">
                翌月繰越
              </option>
            </Form.Select>
          </td>
          <td>
            {billingItemDetail.billingStatus === BILLING_STATUS.BILLING ? (
              <Button
                disabled={!isSalesDptEditable}
                variant="secondary"
                onClick={() => openModal(true, billingContract, billingItemDetail)}
              >
                編集
              </Button>
            ) : (
              ''
            )}
          </td>
        </tr>
        {
          billingContract.billingInvoiceDetailItemList.length - 1 === index && (
            <SubTotalColumn billingContract={billingContract} key={23824342} />
          ) // 小計
        }
      </>
    );
  };

  const SubTotalColumn: React.VFC<BillingInvoiceDetailContractProps> = ({ billingContract }) => {
    return (
      <tr key={billingContract.contractId + 20000}>
        <td>小計</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{convertMoneyText(calcNonTaxSubTotal(billingContract))}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
      </tr>
    );
  };

  /**
   * 税率ごとの合計
   * @param key
   * @param value
   * @param isFirst
   * @constructor
   */
  const NontaxTotalColumn: React.VFC<AmountPerTaxRateProps> = ({ taxRate, value, isFirst }) => {
    return (
      <tr key={taxRate + 30000}>
        <td>{isFirst ? '合計' : ''}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{convertMoneyText(value)}</td>
        <td>{getRateText(taxRate)}</td>
        <td>{}</td>
        <td>{}</td>
      </tr>
    );
  };

  /**
   * 消費税金額
   * @param key
   * @param value
   * @param isFirst
   * @constructor
   */
  const ConsumptiontaxColumn: React.VFC<AmountPerTaxRateProps> = ({ taxRate, value, isFirst }) => {
    return (
      <tr key={taxRate + 40000}>
        <td>{isFirst ? '消費税等' : ''}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{convertMoneyText(value)}</td>
        <td>{getRateText(taxRate)}</td>
        <td>{}</td>
        <td>{}</td>
      </tr>
    );
  };

  /**
   * 合計金額
   * @param billingAmount_
   * @constructor
   */
  const BillingAmountColumn: React.VFC<BillingAmountProps> = ({ billingAmount_ }) => {
    return (
      <tr key={50000}>
        <td>ご請求金額</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{convertMoneyText(billingAmount_)}</td>
        <td>{}</td>
        <td>{}</td>
        <td>{}</td>
      </tr>
    );
  };

  return (
    <>
      {isEditModal && (
        <BillingInvoiceModal
          detail={detail}
          setDetail={setDetail}
          item={item}
          contract={contract}
          isEditModal={isEditModal}
          setIsEditModal={setIsEditModal}
          editType={editType}
        />
      )}
      {isDivideModal && (
        <BillingDivideInvoiceModal
          isEditModal={isDivideModal}
          setIsEditModal={setIsDivideModal}
          name={item?.billingItemName ? item.billingItemName : ''}
          unitPrice={item?.unitPrice ? item.unitPrice : 0}
          quantity={item?.quantity ? item.quantity : 0}
          amount={item?.amount ? item.amount : 0}
          taxRate={item?.taxRate ? item.taxRate : 0}
          billingUseItemId={item?.billingUseItemId ? item.billingUseItemId : 0}
          billingHeaderId={billingContext?.billingId ? billingContext?.billingId : 0}
          billingMonth={detail.billingInvoiceDetailItemData.billingMonth}
        />
      )}
      <Table className="mt-4 mb-4" style={{ tableLayout: 'fixed' }}>
        <thead className="text-nowrap">
          <tr>
            <th>契約名</th>
            <th>品目・品名</th>
            <th>帳票表示品名</th>
            <th>単価</th>
            <th>数量</th>
            <th>単位</th>
            <th>金額（円）</th>
            <th>税区分</th>
            <th>請求ステータス</th>
            <th>{}</th>
          </tr>
        </thead>
        <tbody>
          {detail.billingInvoiceDetailItemData.billingInvoiceItem?.map((invoiceItem) => {
            return <ItemColumns billingContract={invoiceItem} key={invoiceItem.contractId + 39874297443} />;
          })}
          {calcAmountPerTaxRate(detail.billingInvoiceDetailItemData.billingInvoiceItem)?.map((v, index) => {
            return <NontaxTotalColumn value={v[1]} taxRate={v[0]} isFirst={index === 0} key={v[0] + 23982893} />;
          })}
          {calcTaxAmountPerTaxRate2(detail.billingInvoiceDetailItemData.billingInvoiceItem)?.map((v, index) => {
            return <ConsumptiontaxColumn value={v[1]} taxRate={v[0]} isFirst={index === 0} key={v[0] + 9998333} />;
          })}
          {detail.billingInvoiceDetailItemData.billingInvoiceItem && (
            <BillingAmountColumn billingAmount_={billingAmount} />
          )}
          {/* {detail.billingInvoiceDetailItemData.billingInvoiceItem ? ( */}
          {/*  <TotalColumn data={detail.billingInvoiceDetailItemData} key={3439099} /> */}
          {/* ) : ( */}
          {/*  <tr> */}
          {/*    <td>{}</td> */}
          {/*  </tr> */}
          {/* )} */}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end mb-4">
        <Button
          disabled={!isSalesDptEditable}
          variant="secondary"
          className="me-4"
          onClick={() => openModal(false, undefined, undefined)}
        >
          明細追加
        </Button>
      </div>
    </>
  );
};
