import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import type {
  DisplayPointOutputResponse,
  SelectionInfoForCorrectOutputResponse,
} from '../../../../../../../api-client';
import { uuid, when } from '../../../../../../../utils/functions';
import { AnswerMethod, FormEditStatus } from '../../../../../../pages/Correction/constants';
import { Button } from '../../../../../../atoms/Button';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext, SelectEditFormState } from '../../../../../../../store/correctionStore';

interface Props {
  sendingDisplayPoint: Partial<DisplayPointOutputResponse>;
  selectionList: SelectionInfoForCorrectOutputResponse[];
  answerChoiceList: SelectionInfoForCorrectOutputResponse[];
  answerMethodCode: number;
  isEditable: boolean;
}

export const SelectQuestion: React.FC<Props> = (props: Props) => {
  const { answerChoiceList, isEditable, sendingDisplayPoint, selectionList, answerMethodCode } = props;
  return (
    <>
      {answerChoiceList.map(
        ({
          content,
          // TODO: [添削] 今回は実装しない
          // contentVertical,
          // selectionId,
        }) => (
          <div className="m-4" key={uuid()}>
            {content}
          </div>
        )
      )}
      {when(!!isEditable, <SelectionEditor props={props} />)}
    </>
  );
};

const SelectionEditor: React.FC<{ props: Props }> = ({ props }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  const { sendingDisplayPoint } = props;

  const initFormState = $.selectedForms?.find((value) => value.dp.targetId === sendingDisplayPoint.targetId)
    ? FormEditStatus.SAVED
    : FormEditStatus.INIT;
  const [editStatus, setEditStatus] = useState(initFormState);

  // ステータスごとに画面構築
  if (editStatus === FormEditStatus.INIT) return <InitialDisplay setEditStatus={setEditStatus} />;
  if (editStatus === FormEditStatus.EDITING) return <EditForm editProps={{ props, setEditStatus }} />;
  if (editStatus === FormEditStatus.SAVED) return <SavedDisplay props={props} setEditStatus={setEditStatus} />;
  return <></>;
};

const SavedDisplay: React.FC<{ props: Props; setEditStatus: React.Dispatch<React.SetStateAction<number>> }> = ({
  props,
  setEditStatus,
}) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  const { sendingDisplayPoint, selectionList } = props;
  const formData = $.selectedForms?.find((form) => form.dp.targetId === sendingDisplayPoint.targetId);
  const child = formData?.selectionIds?.map((selected) => (
    <p key={uuid()}>{selectionList.find((selection) => selection.selectionId === selected)?.content}</p>
  ));
  return (
    <>
      <Card>
        <Card.Body>{child}</Card.Body>
      </Card>
      <InitialDisplay setEditStatus={setEditStatus} />
    </>
  );
};

interface EditProps {
  props: Props;
  setEditStatus: React.Dispatch<React.SetStateAction<number>>;
}

const EditForm: React.FC<{ editProps: EditProps }> = ({ editProps }) => {
  const {
    largeState: { state: $, mergeState },
  } = useSafeContext(CorrectionContext);
  const { props, setEditStatus } = editProps;
  const { answerMethodCode, selectionList, sendingDisplayPoint, answerChoiceList } = props;

  const getSelectedFormsWithoutThis = () => {
    const list = $.selectedForms ?? [];
    return list.filter((value) => value.dp.targetId !== sendingDisplayPoint.targetId);
  };
  const save = () => {
    const list = getSelectedFormsWithoutThis();
    if (answerChoiceList.map((value) => value.selectionId) !== selectedList)
      list.push({ dp: sendingDisplayPoint, selectionIds: selectedList });
    mergeState({ selectedForms: list });
    setEditStatus(FormEditStatus.SAVED);
  };
  const dispose = () => {
    const list = getSelectedFormsWithoutThis();
    mergeState({ selectedForms: list });
    setEditStatus(FormEditStatus.INIT);
  };

  const thisSelectedForms = $.selectedForms?.find((value) => value.dp.targetId === sendingDisplayPoint.targetId);

  // 添削画面で変更があった場合は変更後の選択肢IDリストを、そうじゃない場合はAPIの戻り値の選択肢IDリストを設定
  const initSelectedIdList = (): number[] => {
    return thisSelectedForms?.selectionIds ?? answerChoiceList.map((value) => value.selectionId);
  };
  const [selectedList, setSelectedList] = useState<number[]>(initSelectedIdList);

  switch (answerMethodCode) {
    case AnswerMethod.MULTI:
      return (
        <>
          <CheckBoxQuestion
            selectionList={selectionList}
            selectedIdList={selectedList}
            setSelectedList={setSelectedList}
          />
          <EditingButton save={save} dispose={dispose} />
        </>
      );
    default:
      return (
        <>
          <RadioQuestion
            selectionList={selectionList}
            selectedIdList={selectedList}
            setSelectedList={setSelectedList}
          />
          <EditingButton save={save} dispose={dispose} />
        </>
      );
  }
};

const InitialDisplay: React.FC<{ setEditStatus: React.Dispatch<React.SetStateAction<number>> }> = ({
  setEditStatus,
}) => {
  return (
    <Button
      variant="link"
      style={{ display: 'block', width: '200px' }}
      onClick={() => setEditStatus(FormEditStatus.EDITING)}
    >
      <FontAwesomeIcon icon={faPlus} />
      回答編集
    </Button>
  );
};

const EditingButton: React.FC<{
  save: () => void;
  dispose: () => void;
}> = ({ save, dispose }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  return (
    <>
      <Button variant="light" onClick={() => save()}>
        <FontAwesomeIcon icon={faSave} /> 保存{' '}
      </Button>
      <Button variant="light" onClick={() => dispose()}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    </>
  );
};

const RadioQuestion: React.FC<{
  selectionList: SelectionInfoForCorrectOutputResponse[];
  selectedIdList: number[];
  setSelectedList: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({ selectionList, selectedIdList, setSelectedList }) => {
  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedList([Number(event.target.value)]);
  };
  const isChecked = (value: number) => !!selectedIdList.find((selectedId) => selectedId === value);

  return (
    <Card>
      {selectionList.map((selection) => {
        return (
          <div key={uuid()}>
            <input
              type="radio"
              value={selection.selectionId}
              checked={isChecked(selection.selectionId)}
              onChange={change}
              id={`${selection.selectionId}`}
            />
            <label htmlFor={`${selection.selectionId}`}>{selection.content}</label>
          </div>
        );
      })}
    </Card>
  );
};

const CheckBoxQuestion: React.FC<{
  selectionList: SelectionInfoForCorrectOutputResponse[];
  selectedIdList: number[];
  setSelectedList: React.Dispatch<React.SetStateAction<number[]>>;
}> = ({ selectionList, selectedIdList, setSelectedList }) => {
  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    // 選択した値リストにあれば削除、なければ追加
    if (selectedIdList.includes(value)) {
      setSelectedList(selectedIdList.filter((id) => id !== value));
    } else {
      setSelectedList([value, ...selectedIdList]);
    }
  };
  const isChecked = (value: number) => !!selectedIdList.find((selectedId) => selectedId === value);

  return (
    <Card>
      {selectionList.map((selection) => {
        return (
          <div key={uuid()}>
            <input
              type="checkbox"
              value={selection.selectionId}
              checked={isChecked(selection.selectionId)}
              onChange={change}
              id={`${selection.selectionId}`}
            />
            <label htmlFor={`${selection.selectionId}`}>{selection.content}</label>
          </div>
        );
      })}
    </Card>
  );
};
