import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
import { createTestId, formatISODate } from '../../../utils/functions';
import type { MailBulkDeliveryListState } from '../../pages/MailBulkDelivery/MailBulkDeliveryListPage';
import type { MailBulkDeliverySubListOutputResponse } from '../../../api-client';
import { PaginationTable } from './PaginationTable';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';

interface Props {
  dataList: MailBulkDeliveryListState['list'];
}
export type TestIds = 'table' | 'link-button';

export const MailBulkDeliveryListTable: React.FC<Props> = React.memo(({ dataList }) => {
  const testId = createTestId<TestIds>(MailBulkDeliveryListTable);
  const MAX_RECORD_PER_PAGE = useMemo(() => 20, []);
  const history = useHistory();

  return (
    <PaginationTable
      data-testid={testId('table')}
      resultNum={MAX_RECORD_PER_PAGE}
      list={dataList}
      headerNames={[
        'メール一括配信履歴ID',
        'タイトル',
        '会員指定方法',
        '送信日時',
        'ステータス',
        '設定人数',
        '失敗件数',
      ]}
    >
      {(list) => {
        return (list as MailBulkDeliverySubListOutputResponse[]).map(
          (
            { mailBulkDelivery, title, targetCustomerSpecifiedType, executeAt, status, settingCount, failureCount },
            i
          ) => {
            return (
              <tr className="align-middle" key={mailBulkDelivery}>
                <td>
                  <Button
                    variant="link"
                    onClick={() => history.push(`${Url.TENSAKU.MAIL_BULK_DELIVERY_DETAIL}/${mailBulkDelivery}`)}
                    data-testid={testId('link-button', i)}
                  >
                    {mailBulkDelivery}
                  </Button>
                </td>
                <td>{title}</td>
                <td>{targetCustomerSpecifiedType}</td>
                <td>{formatISODate(executeAt, DATETIME_DISPLAY_FORMAT)}</td>
                <td>{status}</td>
                <td>{settingCount}</td>
                <td>{failureCount}</td>
              </tr>
            );
          }
        );
      }}
    </PaginationTable>
  );
});
