import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  CustomerTagListApi,
  F5DetailInfoApi,
  F5DetailInfoOutputResponse,
  F5DetailUpdateApi,
  F5DetailUpdateFormResponse,
  F5TestDeliveryApi,
  F5TestDeliveryFormResponse,
  IncResultOutputResponse,
  MailMagazineCustomerTagOutputResponse,
  MailMagazinePrefectureFormResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { formatISODate } from '../../../utils/functions';
import { Datepicker } from '../../atoms/Datepicker';
import { Title } from '../../atoms/Title';

const streamingModeTypeList = [
  {
    id: 0,
    name: '本番配信',
  },
  {
    id: 1,
    name: '配信停止',
  },
];

const ageList = [...Array(100)].map((_: any, i) => {
  return {
    id: i + 1,
    name: `${i + 1}歳`,
  };
});

export const F5MailDetailModifyPage = () => {
  const location = useLocation<{ id: number }>();
  const history = useHistory();

  const infoApi = new F5DetailInfoApi();
  const updateApi = new F5DetailUpdateApi();
  const testApi = new F5TestDeliveryApi();
  const commonMasterListApi = new CommonMasterListApi();
  const customerTagListApi = new CustomerTagListApi();

  // 配信対象ユーザー用ラジオフラグ
  const [radioDisableFlg, setRadioDisableFlg] = useState<boolean>(true);
  // 配信対象ユーザー用checkboxフラグ
  const [applicationcheckBoxDisableFlg, setApplicationCheckBoxDisableFlg] = useState<boolean>(false);
  const [memberRegistrationCheckBoxDisableFlg, setmemberRegistrationCheckBoxDisableFlg] = useState<boolean>(false);

  const [data, setData] = useState<F5DetailInfoOutputResponse>({
    memo: '',
    category: 1,
    title: '',
    bannerText: '',
    bannerHTML: '',
    sendScheduleTimeStamp: '',
    memberRegistrationDateStart: '',
    memberRegistrationDateEnd: '',
    noApplicationDays: 0,
    customerList: [],
    maxSendShopCount: 0,
    minMonitorLimit: 0,
    customerTagList: { customerTagList: [] },
    rangeFromShop1: 0,
    areaList1: [],
    rangeFromShop2: 0,
    areaList2: [],
    sendMode: 0,
    sendType: 0,
    testMailaddressList: [],
  });

  // 配信日時（日付）
  const [streamingDay, setStreamingDay] = useState<string>();
  // 配信日時（時間）
  const [streamingTime, setStreamingTime] = useState<string>();
  // テスト配信
  const [testStreamingText, setTestStreamingText] = useState<string>();
  // ユーザーID
  const [userIdsText, setUserIdsText] = useState<string>('');
  // 都道府県一覧
  const [prefectures, setPrefectures] = useState<CommonMasterListOutputResponse[]>();
  // 会員属性一覧
  const [customerTagList, setCustomerTagList] = useState<MailMagazineCustomerTagOutputResponse>({
    customerTagList: [],
  });
  //  カテゴリリスト
  const [categoryList, setCategoryList] = useState<CommonMasterListOutputResponse[]>([]);
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const [testResult, setTestResult] = useState<IncResultOutputResponse>();

  const diff = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() + 9);
    return parsedate;
  };
  const diffInitial = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() - 9);
    return parsedate;
  };

  useEffect(() => {
    // 詳細画面に直接アクセスされた場合はエラー画面に遷移させる。
    if (location.pathname === '/keisai/f3-mail-detail' && location.state === undefined) {
      history.push(Url.COMMON_ERROR);
      return;
    }
    // パラメータが渡されてきていなければInfoAPI叩かない
    if (location.state !== undefined) {
      infoApi.f5DetailInfo(location.state.id).then((res: AxiosResponse<F5DetailInfoOutputResponse>) => {
        setRadioDisableFlg(res.data.sendType === 0);
        if (res.data?.sendScheduleTimeStamp) {
          setStreamingDay(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'yyyy-MM-dd')
          );
          setStreamingTime(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'HH:mm:ss')
          );
        }

        // テスト配信テキストの加工
        if (res.data.testMailaddressList) {
          for (let index = 0; index < res.data.testMailaddressList.length; index += 1) {
            if (res.data.testMailaddressList.length !== index + 1) {
              res.data.testMailaddressList[index] = `${res.data.testMailaddressList[index]}\n`;
            }
          }
          setTestStreamingText(res.data.testMailaddressList.join(''));
        }
        // ユーザーIDテキストの加工
        let userIds = '';
        if (res.data.customerList) {
          for (let index = 0; index < res.data.customerList.length; index += 1) {
            if (index !== 0) {
              userIds = `${userIds}${res.data.customerList[index]},`;
            } else {
              userIds = `${res.data.customerList[index]},`;
            }
          }
          setUserIdsText(userIds.substring(0, userIds.length - 1));
        }
        setData(res.data);
      });
    }
    // 共通APIで「都道府県」一覧取得
    commonMasterListApi.commonMasterList('prefecture').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
      setPrefectures(res.data);
    });
    // 共通APIで「カテゴリ」一覧取得
    commonMasterListApi.commonMasterList('category').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
      setCategoryList(res.data);
    });

    customerTagListApi.customerTagList().then((res: AxiosResponse<MailMagazineCustomerTagOutputResponse>) => {
      setCustomerTagList(res.data);
    });
  }, []);

  // エリアSelect Changeイベント
  const onChangeMultiSelect = (selectedContents: MultiValue<{ id: number; name: string }>, areaNumber: number) => {
    const copyAreaList: MailMagazinePrefectureFormResponse[] = [];

    for (let index = 0; index < selectedContents.length; index += 1) {
      copyAreaList.push({
        prefectureId: selectedContents[index].id,
        prefectureName: selectedContents[index].name,
        selectedFlg: true,
      });
    }

    if (areaNumber === 1) {
      setData({ ...data, areaList1: copyAreaList });
    } else {
      setData({ ...data, areaList2: copyAreaList });
    }
  };

  // 会員属性Select Changeイベント
  const onChangeCustomerSelect = (selectedContents: SingleValue<{ tagId: number; tagName: string }>, ind: number) => {
    if (selectedContents !== null) {
      const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
      const targetSelectionList = customerTagList.customerTagList
        .find((r) => {
          return r.tagId === selectedContents.tagId;
        })
        ?.customerTagAnswerList.slice(0, 1);

      if (targetSelectionList) {
        setData({
          ...data,
          customerTagList: {
            customerTagList: addList.customerTagList.map((_, index) => {
              return index === ind
                ? {
                    ..._,
                    tagId: selectedContents.tagId,
                    tagName: selectedContents.tagName,
                    customerTagAnswerList: targetSelectionList,
                  }
                : { ..._ };
            }),
          },
        });
      }
    }
  };

  // 保存イベント
  const submit = () => {
    let sendScheduleTimeStamp = '';

    if (streamingDay !== undefined && streamingDay !== '' && streamingTime !== undefined && streamingTime !== '') {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} ${streamingTime}`))
        .toISOString()
        .slice(0, -5);
    } else if (
      streamingDay !== undefined &&
      streamingDay !== '' &&
      (streamingTime === undefined || streamingTime === '')
    ) {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} 00:00:00`))
        .toISOString()
        .slice(0, -5);
    }

    const param: F5DetailUpdateFormResponse = {
      id: location.state === undefined ? undefined : location.state.id,
      memo: data.memo === undefined ? '' : data.memo,
      category: data.category,
      title: data.title,
      bannerText: data.bannerText === undefined ? '' : data.bannerText,
      bannerHTML: data.bannerHTML === undefined ? '' : data.bannerHTML,
      sendScheduleTimeStamp: sendScheduleTimeStamp === '' ? undefined : sendScheduleTimeStamp,
      sendType: data.sendType,
      memberRegistrationDateStart:
        data.memberRegistrationDateStart === '' ? undefined : data.memberRegistrationDateStart,
      memberRegistrationDateEnd: data.memberRegistrationDateEnd === '' ? undefined : data.memberRegistrationDateEnd,
      noApplicationDays: data.noApplicationDays,
      maxSendShopCount: data.maxSendShopCount === undefined ? 0 : data.maxSendShopCount,
      minMonitorLimit: data.minMonitorLimit === undefined ? 0 : data.minMonitorLimit,
      areaList1: data.areaList1,
      rangeFromShop1: data.rangeFromShop1,
      areaList2: data.areaList2,
      rangeFromShop2: data.rangeFromShop2,
      customerTagList: data.customerTagList.customerTagList,
      sendMode: data.sendMode === undefined ? 0 : data.sendMode,
      testMailaddressList: testStreamingText?.split('\n'),
      userIdList: userIdsText === '' ? undefined : userIdsText,
    };

    updateApi.f5DetailUpdate(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(res.data);
    });
  };

  // テスト配信イベント
  const testStreaming = () => {
    const param: F5TestDeliveryFormResponse = {
      bannerContent: data.bannerText === undefined ? '' : data.bannerText,
      bannerHTML: data.bannerHTML === undefined ? '' : data.bannerHTML,
      category: data.category.toString(),
      title: data.title,
      testMailaddressList: testStreamingText !== undefined ? testStreamingText.split('\n') : [],
    };

    testApi.f5TestDelivery(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setTestResult(res.data);
    });
  };

  return (
    <>
      <Title className="mb-4">{location.state === undefined ? 'F5メール編集画面' : 'F5メール詳細画面'}</Title>
      <div className="d-flex justify-content-end mb-4">
        <Button type="button" onClick={testStreaming} className="ms-2">
          テスト配信
        </Button>
        <Button type="button" onClick={submit} className="ms-2">
          保存
        </Button>
      </div>
      {updResult?.result && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          更新しました。
        </Alert>
      )}
      {testResult?.result && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          テスト配信が完了しました。
        </Alert>
      )}
      {updResult !== undefined && updResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}
      {testResult !== undefined && testResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${testResult.errorMessage} (エラーコード：${testResult.errorCode})`}</Alert>
      )}
      <Form.Group className="mb-4">
        <Form.Label>メモ</Form.Label>
        <Form.Control
          as="textarea"
          value={data.memo}
          onChange={(e) => {
            setData({ ...data, memo: e.target.value });
          }}
          rows={5}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>カテゴリ</Form.Label>
        <Form.Select
          style={{ width: '50%' }}
          value={data.category}
          onChange={(e) => {
            setData({ ...data, category: Number(e.target.value) });
          }}
        >
          {categoryList?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>メール件名</Form.Label>
        <Form.Control
          value={data.title}
          onChange={(e) => {
            setData({ ...data, title: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>お知らせバナーテキスト</Form.Label>
        <Form.Control
          as="textarea"
          value={data.bannerText}
          onChange={(e) => {
            setData({ ...data, bannerText: e.target.value });
          }}
          rows={5}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>お知らせバナーHTML</Form.Label>
        <Form.Control
          as="textarea"
          value={data.bannerHTML}
          onChange={(e) => {
            setData({ ...data, bannerHTML: e.target.value === '' ? undefined : e.target.value });
          }}
          rows={5}
        />
      </Form.Group>
      <Form.Label>配信日時</Form.Label>
      <Form.Group className="d-flex align-items-center mb-4" controlId="readOnly1" style={{ width: '50%' }}>
        <Datepicker
          value={streamingDay === undefined ? '' : streamingDay}
          className="me-2"
          onChange={(e) => {
            setStreamingDay(e.target.value);
          }}
          data-testid="datepicker"
        />
        <Form.Control
          type="time"
          step="1"
          value={streamingTime === undefined ? '' : streamingTime}
          className="me-2"
          onChange={(e) => {
            setStreamingTime(e.target.value);
          }}
          disabled={streamingDay === undefined || streamingDay === ''}
        />
      </Form.Group>
      <Form.Check.Label>配信対象ユーザー</Form.Check.Label>
      <Form.Check id="radio1" className="d-flex ms-4 mt-2">
        <Form.Check.Input
          data-testid="unspecifiedTimeRadio"
          className="flex-shrink-0 me-2"
          type="radio"
          name="radioGroup01"
          value={0}
          onChange={(e) => {
            setData({ ...data, sendType: Number(e.target.value) });
            setRadioDisableFlg(true);
          }}
          checked={data.sendType === 0}
        />
        <Form.Check.Label>期間指定</Form.Check.Label>
      </Form.Check>
      <Form.Check.Label className="mt-2" style={{ marginLeft: '6rem' }}>
        会員登録期間
      </Form.Check.Label>
      <div className="d-flex align-items-center " style={{ width: '49.5%' }}>
        <Col className="col-12">
          <Form.Group className="d-flex align-items-center ms-5 mt-2" controlId="readOnly1">
            <Col>
              <Form.Check.Input
                data-testid="couponUseOkCheckBox"
                className="flex-shrink-0 ms-5"
                type="checkbox"
                name="checkBoxGroup01"
                disabled={!radioDisableFlg}
                checked={memberRegistrationCheckBoxDisableFlg}
                onChange={(e) => {
                  setmemberRegistrationCheckBoxDisableFlg(!memberRegistrationCheckBoxDisableFlg);
                }}
              />
            </Col>
            <Col className="col-5">
              <Form.Control
                data-testid="enteringTimeText"
                disabled={!(radioDisableFlg && memberRegistrationCheckBoxDisableFlg)}
                type="date"
                value={data.memberRegistrationDateStart === undefined ? '' : data.memberRegistrationDateStart}
                onChange={(e) => {
                  setData({ ...data, memberRegistrationDateStart: e.target.value });
                }}
              />
            </Col>
            <Form.Label className="mt-2 me-2 ms-2">～</Form.Label>
            <Col className="col-5">
              <Form.Control
                data-testid="exitTimeText"
                disabled={!(radioDisableFlg && memberRegistrationCheckBoxDisableFlg)}
                type="date"
                value={data.memberRegistrationDateEnd === undefined ? '' : data.memberRegistrationDateEnd}
                onChange={(e) => {
                  setData({ ...data, memberRegistrationDateEnd: e.target.value });
                }}
              />
            </Col>
          </Form.Group>
        </Col>
      </div>
      <Form.Check.Label className="mt-2" style={{ marginLeft: '6rem' }}>
        案件未応募期間
      </Form.Check.Label>
      <div className="d-flex align-items-center " style={{ width: '49.5%' }}>
        <Col className="col-12">
          <Form.Group className="d-flex align-items-center ms-5 mt-2" controlId="readOnly1">
            <Col>
              <Form.Check.Input
                className="flex-shrink-0 ms-5"
                type="checkbox"
                name="checkBoxGroup01"
                checked={applicationcheckBoxDisableFlg}
                disabled={!radioDisableFlg}
                onChange={(e) => {
                  setApplicationCheckBoxDisableFlg(!applicationcheckBoxDisableFlg);
                }}
              />
            </Col>
            <Col className="col-10">
              <Form.Control
                type="number"
                disabled={!(radioDisableFlg && applicationcheckBoxDisableFlg)}
                value={data.noApplicationDays}
                onChange={(e) => {
                  setData({ ...data, noApplicationDays: Number(e.target.value) });
                }}
              />
            </Col>
            日間
          </Form.Group>
        </Col>
      </div>
      <div className="align-items-center " style={{ width: '49.5%' }}>
        <Form.Check id="radio2" className="d-flex ms-4 mt-2 mb-2">
          <Form.Check.Input
            data-testid="userIdRadio"
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup01"
            value={1}
            onChange={(e) => {
              setData({ ...data, sendType: Number(e.target.value) });
              setRadioDisableFlg(false);
            }}
            checked={data.sendType === 1}
          />
          <Form.Check.Label>
            {' '}
            ユーザーID指定&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ※（複数の場合は半角カンマ（,）で入力してください。例： 12,20）
          </Form.Check.Label>
        </Form.Check>
        <div className="ms-5 mb-4">
          <Form.Control
            disabled={radioDisableFlg}
            value={userIdsText}
            onChange={(e) => {
              setUserIdsText(e.target.value);
            }}
          />
        </div>
        <Form.Group className="mb-4">
          <Form.Label>最大配信店舗数</Form.Label>
          <Form.Control
            type="number"
            value={data.maxSendShopCount}
            onChange={(e) => {
              setData({ ...data, maxSendShopCount: Number(e.target.value) });
            }}
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Label>配信対象店舗の空き枠数</Form.Label>
          <Form.Control
            type="number"
            value={data.minMonitorLimit}
            onChange={(e) => {
              setData({ ...data, minMonitorLimit: Number(e.target.value) });
            }}
          />
        </Form.Group>
        <Form.Check.Label>エリア</Form.Check.Label>
        <Form.Group className="ms-4 mt-2">
          <Form.Label>都道府県1</Form.Label>
          <Select
            id="prefecture"
            onChange={(e) => {
              onChangeMultiSelect(e, 1);
            }}
            closeMenuOnSelect={false}
            value={data.areaList1.map((d) => {
              return { id: d.prefectureId, name: d.prefectureName };
            })}
            isMulti
            getOptionLabel={(option) => {
              return option.name;
            }}
            getOptionValue={(option) => {
              return option.id.toString();
            }}
            options={prefectures}
            placeholder="エリアを選択してください。"
          />
        </Form.Group>
        <Form.Group className="ms-4 mt-4">
          <Form.Label>対象店舗の範囲1</Form.Label>
          <Form.Group className="d-flex align-items-center" controlId="readOnly1">
            応募店舗からの抽出対象範囲 半径
            <Col className="col-7.5">
              <Form.Control
                type="number"
                value={data.rangeFromShop1}
                onChange={(e) => {
                  setData({ ...data, rangeFromShop1: Number(e.target.value) });
                }}
              />
            </Col>
            km
          </Form.Group>
        </Form.Group>
        <Form.Group className="ms-4 mt-4">
          <Form.Label>都道府県2</Form.Label>
          <Select
            id="prefecture"
            onChange={(e) => {
              onChangeMultiSelect(e, 2);
            }}
            closeMenuOnSelect={false}
            value={data.areaList2.map((d) => {
              return { id: d.prefectureId, name: d.prefectureName };
            })}
            isMulti
            getOptionLabel={(option) => {
              return option.name;
            }}
            getOptionValue={(option) => {
              return option.id.toString();
            }}
            options={prefectures}
            placeholder="エリアを選択してください。"
          />
        </Form.Group>
        <Form.Group className="ms-4 mt-4 mb-4">
          <Form.Label>対象店舗の範囲2</Form.Label>
          <Form.Group className="d-flex align-items-center" controlId="readOnly1">
            応募店舗からの抽出対象範囲 半径
            <Col className="col-7.5">
              <Form.Control
                type="number"
                value={data.rangeFromShop2}
                onChange={(e) => {
                  setData({ ...data, rangeFromShop2: Number(e.target.value) });
                }}
              />
            </Col>
            km
          </Form.Group>
        </Form.Group>
        <Form.Label>会員属性</Form.Label>
        {data.customerTagList.customerTagList?.map((_, i) => {
          return (
            <Col key={i.toString()} className="col-12 mt-2">
              <Form.Group className="d-flex align-items-center" controlId="readOnly1">
                <Form.Label className="mt-2 me-2 ms-4">属性</Form.Label>
                <Col data-testid="attributeSelect">
                  <Select
                    id="prefecture"
                    onChange={(e) => {
                      onChangeCustomerSelect(e, i);
                    }}
                    closeMenuOnSelect
                    value={data.customerTagList.customerTagList[i]}
                    getOptionLabel={(option) => {
                      return option.tagName;
                    }}
                    getOptionValue={(option) => {
                      return option.tagId.toString();
                    }}
                    options={customerTagList.customerTagList}
                  />
                </Col>
                <Form.Label className="mt-2 me-2 ms-2">選択肢</Form.Label>
                <Col>
                  <Form.Select
                    data-testid="ChoiceSelect"
                    value={data.customerTagList.customerTagList[i].customerTagAnswerList[0].id}
                    onChange={(e) => {
                      const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                      const targetTagName = customerTagList.customerTagList
                        .find((row) => {
                          return row.tagId === Number(data.customerTagList.customerTagList[i].tagId);
                        })
                        ?.customerTagAnswerList.find((r) => {
                          return r.id === Number(e.target.value);
                        })?.tagValue;

                      if (targetTagName) {
                        setData({
                          ...data,
                          customerTagList: {
                            customerTagList: addList.customerTagList.map((row, index) => {
                              return index === i
                                ? {
                                    ...row,
                                    customerTagAnswerList: [{ id: Number(e.target.value), tagValue: targetTagName }],
                                  }
                                : { ...row };
                            }),
                          },
                        });
                      }
                    }}
                  >
                    {customerTagList.customerTagList
                      .find((row) => {
                        return row.tagId === _.tagId;
                      })
                      ?.customerTagAnswerList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.tagValue}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          );
        })}
        {customerTagList.customerTagList.length > 0 && (
          <div className="d-flex justify-content-end mb-4 mt-4">
            <Button
              data-testid="add"
              variant="link"
              className="text-secondary p-0"
              onClick={() => {
                const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                addList.customerTagList.push({
                  tagId: customerTagList.customerTagList[0].tagId,
                  tagName: customerTagList.customerTagList[0].tagName,
                  customerTagAnswerList: customerTagList.customerTagList[0].customerTagAnswerList,
                });

                setData({ ...data, customerTagList: addList });
              }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              <span style={{ fontSize: '1rem' }}>追加</span>
            </Button>
          </div>
        )}
        <Form.Group className="mb-4">
          <Form.Label>配信モード</Form.Label>
          <Form.Select
            value={data.sendMode}
            onChange={(e) => {
              setData({ ...data, sendMode: Number(e.target.value) });
            }}
          >
            {streamingModeTypeList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <Form.Group className="mb-4">
        <Form.Label>テスト配信先</Form.Label>
        <Form.Control
          value={testStreamingText}
          onChange={(e) => {
            setTestStreamingText(e.target.value);
          }}
          as="textarea"
          rows={3}
        />
      </Form.Group>
    </>
  );
};
