import React, { useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Button, Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { useLargeState } from '../../../hooks/useLargeState';
import { uuid } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import type { CorrectSettingEachClientState } from '../../pages/CorrectSetting/CorrectSettingEachClientPage';

type State = CorrectSettingEachClientState['correctSettingForm'];
type OutputType = CorrectSettingEachClientState['clientInfo'];
type FormState = { electedPersonId: number; electedPersonName: string };

interface Props {
  setForms: DispatchSetState<State>;
  clientInfo: OutputType;
}

export const CorrectSettingEachClientForms: React.FC<Props> = React.memo(({ setForms, clientInfo }) => {
  const initialCorrectForm: State = useMemo(() => {
    return {
      clientId: clientInfo.clientId,
      correctionExecutionFlg: clientInfo.correctionExecutionFlg ?? true,
      doubleCheckFlg: clientInfo.doubleCheckFlg ?? false,
      electedPersonList: clientInfo.electedPersonList,
    };
  }, [clientInfo]);

  const { state: $, mergeState } = useLargeState<State>(initialCorrectForm);

  useEffect(() => {
    mergeState(initialCorrectForm);
  }, [clientInfo, initialCorrectForm, mergeState]);

  useEffect(() => {
    setForms({ ...$ });
  }, [setForms, $]);

  const { state: $f, mergeState: fMergeState } = useLargeState<FormState>({
    electedPersonId: 0,
    electedPersonName: '',
  });

  const onClickAdd = () => {
    if (!Number.isInteger($f.electedPersonId) || $.electedPersonList.some(({ id }) => id === $f.electedPersonId)) {
      fMergeState({ electedPersonId: 0, electedPersonName: '' });
      return;
    }

    const newList = $.electedPersonList;
    newList.push({ id: $f.electedPersonId, name: $f.electedPersonName });
    mergeState({ electedPersonList: newList });
    fMergeState({ electedPersonId: 0, electedPersonName: '' });
  };

  const tableData: {
    label: string;
    name: keyof State['electedPersonList'][0];
  }[] = useMemo(() => {
    return [
      { label: 'ID', name: 'id' },
      { label: '選任者', name: 'name' },
    ];
  }, []);

  const onDelete = (i: number) => {
    const newList = $.electedPersonList;
    newList.splice(i, 1);
    mergeState({ electedPersonList: newList });
  };

  return (
    <>
      <Row>
        <div className="col-md-3">
          <h5>添削開始</h5>
          <div className="text-secondary">*OFFにすると、このクライアントに紐づく全てのモニターの添削が停止します</div>
        </div>
        <div className="col-md-9">
          <Form.Check
            type="switch"
            onChange={() => mergeState({ correctionExecutionFlg: !$.correctionExecutionFlg })}
            checked={$.correctionExecutionFlg}
          />
        </div>
      </Row>
      <hr />
      <Row>
        <div className="col-md-3">
          <h5>Wチェック</h5>
          <div className="text-secondary">*通常添削のモニターでのみ適用されます</div>
        </div>
        <div className="col-md-9">
          <Form.Check
            type="switch"
            onChange={() => mergeState({ doubleCheckFlg: !$.doubleCheckFlg })}
            checked={$.doubleCheckFlg}
          />
        </div>
      </Row>
      <hr />
      <Row>
        <div className="col-md-3">
          <h5>選任設定</h5>
          <div className="text-secondary">*通常添削のモニターでのみ適用されます</div>
        </div>
        <div className="col-md-9" style={{ maxWidth: '600px' }}>
          <div className="d-flex justify-content-start">
            <div style={{ width: '80%' }}>
              <Select
                isClearable
                isSearchable
                placeholder=""
                value={{ value: $f.electedPersonId, label: $f.electedPersonName }}
                options={clientInfo.selectablePersonList.map(({ id, name }) => {
                  return { label: name, value: id };
                })}
                onChange={(e) => fMergeState({ electedPersonId: e?.value, electedPersonName: e?.label })}
              />
            </div>
            <Button className="ms-2" onClick={onClickAdd} disabled={!$f.electedPersonId}>
              追加
            </Button>
          </div>
          <Table className="mt-2">
            <thead>
              <tr className="align-middle">
                {[...tableData.map(({ label }) => label), ''].map((label) => (
                  <th key={label}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {$.electedPersonList.map((item, i) => (
                <tr className="align-middle" key={item.id}>
                  {tableData.map(({ name }) => (
                    <td key={String(name)}>{item[name]}</td>
                  ))}
                  <td key={uuid()}>
                    <Button variant="link" onClick={() => onDelete(i)}>
                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Row>
    </>
  );
});
