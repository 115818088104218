import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { Form, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../../../../constants/Url';
import { useLargeState } from '../../../../../../hooks/useLargeState';
import { useSafeContext } from '../../../../../../hooks/useSafeContext';
import sass from '../../../../../../scss/templates/CorrectionTemplate.module.scss';
import { CorrectionContext } from '../../../../../../store/correctionStore';
import { debugLog, when } from '../../../../../../utils/functions';
import { BadgeHead } from '../../../../../atoms/BadgeHead';
import { Button } from '../../../../../atoms/Button';
import { TinyHead } from '../../../../../atoms/TinyHead';
import {
  CorrectionWorkType,
  DisplayControlReject,
  RejectAnswerCTIdType,
  TemplateFormBodyState,
  TemplateFormMode,
} from '../../../../../pages/Correction/constants';
import {
  getCommonCommentFormList,
  isForceCorrect,
  jumpTo,
  nativeAlert,
  nativeConfirm,
  noCorrectionTarget,
  questionPreSend,
} from '../../../../../pages/Correction/utils';
import { Escalation } from '../../../Escalation';
import { CommonBody } from '../../../TemplateForm/bodies/CommonBody';
import { AccordionList } from './AccordionList';

export const RejectForm: React.FC = () => {
  const {
    largeState: { state: $, useBindSet },
  } = useSafeContext(CorrectionContext);
  const {
    state: f,
    onChangeSet: set,
    mergeState,
  } = useLargeState<{
    notice?: string;
    templateFormState?: TemplateFormBodyState;
    execMessage?: string;
    backMessage?: string;
    escaleMessage?: string;
  }>({});
  const setLoading = useBindSet('isLoading');
  const history = useHistory();
  const [isRequest, hasApprove, isExcute, isOtherDispatch] = useMemo(() => {
    const { reject } = $.res_correctReceiptDisplayControl ?? $.res_correctEnqueteDisplayControl ?? {};
    return [
      reject === DisplayControlReject.REQUEST,
      // eslint-disable-next-line no-nested-ternary
      reject === DisplayControlReject.APPROVE_WITH_ESCALE
        ? ('ESCALE' as const)
        : reject === DisplayControlReject.APPROVE_WITH_ANSWER
        ? ('ANSWER' as const)
        : false,
      reject === DisplayControlReject.EXECUTE,
      reject === DisplayControlReject.APPROVE_WITH_ANSWER,
    ];
  }, [$.res_correctReceiptDisplayControl, $.res_correctEnqueteDisplayControl]);
  const onChangeTemplateForm = useCallback(
    (state) => {
      debugLog(state);
      mergeState({ templateFormState: state });
    },
    [mergeState]
  );
  const onReject = useCallback(
    async (mode: DisplayControlReject.REQUEST | DisplayControlReject.EXECUTE) => {
      try {
        if (
          !(await questionPreSend(
            () => ({
              common: {
                applyId: $.applyId,
                correctionNgList: getCommonCommentFormList(TemplateFormMode.NG, $).map(
                  ({ dp, tempId: templateId, content: ngMessage }) => ({
                    displayPoint: { typeCode: dp.type ?? NaN, targetId: dp.targetId },
                    templateId,
                    ngMessage,
                  })
                ),
                correctionUrl: document.location.href,
                forcedCorrectFlg: isForceCorrect(
                  $.res_correctReceiptDisplayControl,
                  $.res_correctEnqueteDisplayControl
                ),
              },
              commonInfo: {
                correctionTemplateId: f.templateFormState?.templateId ?? NaN,
                rejectMessage: f.templateFormState?.content ?? '',
                additionalNoticeWord: f.notice ?? '',
              },
            }),
            async ({ common, commonInfo }) => {
              if (common.correctionNgList.find((x) => x.templateId === undefined)) {
                nativeAlert('テンプレートIDが設定されていない項目があります');
              }
              if (mode === DisplayControlReject.REQUEST) {
                if (!$.isEnquete) {
                  await $.api_correctReceipt
                    .receiptCorrectionSendRejectionApprovalRequest({
                      ...common,
                      rejectionApprovalRequestInfo: {
                        ...commonInfo,
                        additionalNoticeWord: f.notice ?? '',
                      },
                    })
                    .then(() => {
                      $.api_correct
                        .normalCorrectTargetDistribute({
                          beforeApplyId: $.applyId,
                          workTypeCode: CorrectionWorkType.RESUBMIT_REQUEST,
                        })
                        .then(({ data }) => jumpTo(history, data))
                        .catch(() => noCorrectionTarget(history));
                    });
                } else {
                  await $.api_correctEnquete
                    .enqueteCorrectionSendRejectionApprovalRequest({
                      ...common,
                      rejectionApprovalRequestInfo: {
                        ...commonInfo,
                        additionalNoticeWord: f.notice ?? '',
                      },
                    })
                    .then(() => {
                      $.api_correct
                        .normalCorrectTargetDistribute({
                          beforeApplyId: $.applyId,
                          workTypeCode: CorrectionWorkType.RESUBMIT_REQUEST,
                        })
                        .then(({ data }) => jumpTo(history, data))
                        .catch(() => noCorrectionTarget(history));
                    });
                }
              } else if (!$.isEnquete) {
                await $.api_correctReceipt
                  .receiptCorrectionRejectExec({
                    ...common,
                    rejectionApprovalRequestInfo: commonInfo,
                  })
                  .then(() => {
                    $.api_correct
                      .normalCorrectTargetDistribute({
                        beforeApplyId: $.applyId,
                        workTypeCode: CorrectionWorkType.RESUBMIT_REQUEST,
                      })
                      .then(({ data }) => jumpTo(history, data))
                      .catch(() => noCorrectionTarget(history));
                  });
              } else {
                await $.api_correctEnquete
                  .enqueteCorrectionRejectExec({
                    ...common,
                    rejectionApprovalRequestInfo: commonInfo,
                  })
                  .then(() => {
                    $.api_correct
                      .normalCorrectTargetDistribute({
                        beforeApplyId: $.applyId,
                        workTypeCode: CorrectionWorkType.RESUBMIT_REQUEST,
                      })
                      .then(({ data }) => jumpTo(history, data))
                      .catch(() => noCorrectionTarget(history));
                  });
              }
            },
            undefined,
            setLoading
          ))
        )
          return;
      } catch (error) {
        console.error(error);
        nativeAlert(error);
      }
    },
    [$, f.notice, f.templateFormState, history, setLoading]
  );
  const onApprove = useCallback(
    async <T extends number>(rejectAnswerCorrectionTemplateId: T extends RejectAnswerCTIdType ? T : never) => {
      try {
        if (
          !(await questionPreSend(
            () => ({
              common: {
                rejectId: $.res_rejectInfoDisplay?.rejectApproveRequest?.rejectId ?? NaN,
              },
            }),
            async ({ common }) => {
              let messageToApplicant = '';
              if (rejectAnswerCorrectionTemplateId === RejectAnswerCTIdType.APPROVE)
                messageToApplicant = f.execMessage ?? '';
              else if (rejectAnswerCorrectionTemplateId === RejectAnswerCTIdType.BACK)
                messageToApplicant = f.backMessage ?? '';
              else if (rejectAnswerCorrectionTemplateId === RejectAnswerCTIdType.UCOMI_ESCALE)
                messageToApplicant = f.escaleMessage ?? '';
              else if (rejectAnswerCorrectionTemplateId === RejectAnswerCTIdType.SALES_ESCALE)
                messageToApplicant = f.escaleMessage ?? '';
              if (!$.isEnquete) {
                await $.api_correctReceipt
                  .correctRejectApprovalExecute({
                    ...common,
                    rejectionApprovalRequestAnswerInfo: {
                      rejectAnswerCorrectionTemplateId,
                      messageToApplicant,
                      rejectionMessageCorrectionTemplateId: Number(f.templateFormState?.templateId),
                      rejectMessage: f.templateFormState?.content ?? '',
                    },
                  })
                  .then(() => {
                    nativeAlert('処理が終了しました');
                    window.location.reload();
                  });
              } else {
                await $.api_correctEnquete
                  .enqueteCorrectRejectApprovalExecute({
                    ...common,
                    rejectionApprovalRequestAnswerInfo: {
                      rejectAnswerCorrectionTemplateId,
                      messageToApplicant,
                      rejectionMessageCorrectionTemplateId: Number(f.templateFormState?.templateId),
                      rejectMessage: f.templateFormState?.content ?? '',
                    },
                  })
                  .then(() => {
                    nativeAlert('処理が終了しました');
                    window.location.reload();
                  });
              }
            },
            undefined,
            setLoading
          ))
        )
          return;
      } catch (error) {
        console.error(error);
        debugLog({ error });
        nativeAlert(error);
      }
    },
    [
      $.isEnquete,
      $.api_correctReceipt,
      $.api_correctEnquete,
      $.res_rejectInfoDisplay,
      f.backMessage,
      f.escaleMessage,
      f.execMessage,
      f.templateFormState,
      $.api_correct,
      $.applyId,
      history,
      setLoading,
    ]
  );
  const rejectEscalation = useMemo(
    // 再描画対策
    () => (
      <>
        <Escalation
          isReject
          escaletes={
            $.res_rejectInfoDisplay?.rejectApproveRequest ? [$.res_rejectInfoDisplay?.rejectApproveRequest] : []
          }
        />
      </>
    ),
    [$.res_rejectInfoDisplay]
  );
  const escalationTarget =
    $.res_correctReceiptDisplayControl?.rejectEscalationTargetName ??
    $.res_correctEnqueteDisplayControl?.rejectEscalationTargetName;
  const rejectAnswerCTIdType =
    escalationTarget === '営業担当' ? RejectAnswerCTIdType.SALES_ESCALE : RejectAnswerCTIdType.UCOMI_ESCALE;
  return (
    <>
      {when(!hasApprove, <AccordionList mode={TemplateFormMode.NG} />)}
      {when(!!hasApprove, <BadgeHead>{$.res_rejectInfoDisplay?.rejectApproveRequest?.status.name}</BadgeHead>)}
      <CommonBody
        name="却下"
        templateList={$.res_rejectTemplateList ?? []}
        onChange={onChangeTemplateForm}
        initTempId={$.res_rejectInfoDisplay?.rejectApproveRequest?.template.id}
        initContent={$.res_rejectInfoDisplay?.rejectApproveRequest?.messageForCustomer}
      />
      {when(
        isRequest,
        <>
          <Form.Group>
            <TinyHead>事務局に通知するメッセージ（任意）</TinyHead>
            <Form.Control size="lg" value={f.notice ?? ''} onChange={set('notice')} />
          </Form.Group>
          <Button onClick={() => onReject(DisplayControlReject.REQUEST)}>
            <FontAwesomeIcon icon={faBan} /> 却下承認依頼
          </Button>
        </>
      )}
      {when(
        isExcute,
        <Button onClick={() => onReject(DisplayControlReject.EXECUTE)}>
          <FontAwesomeIcon icon={faBan} /> 却下
        </Button>
      )}
      {when(
        !!hasApprove,
        <>
          {rejectEscalation}
          <Tabs defaultActiveKey="exec" className="mb-3">
            <Tab eventKey="exec" title="承認実行">
              <Form.Group>
                <Form.Label>申請者へのメッセージ</Form.Label>
                <Form.Control
                  size="lg"
                  as="textarea"
                  placeholder={'当選前来店のため、今回は却下いたします。\n次回以降お気をつけください。'}
                  value={f.execMessage ?? ''}
                  onChange={set('execMessage')}
                />
              </Form.Group>
              <Button className={sass.btn_red} onClick={() => onApprove(RejectAnswerCTIdType.APPROVE)}>
                承認実行
              </Button>
            </Tab>
            <Tab eventKey="back" title="差し戻し">
              <Form.Group>
                <Form.Label>申請者へのメッセージ</Form.Label>
                <Form.Control
                  size="lg"
                  as="textarea"
                  placeholder={'当選前来店のため、今回は却下いたします。\n次回以降お気をつけください。'}
                  value={f.backMessage ?? ''}
                  onChange={set('backMessage')}
                />
              </Form.Group>
              <Button onClick={() => onApprove(RejectAnswerCTIdType.BACK)}>差戻実行</Button>
            </Tab>
            <Tab eventKey="escale" title="エスカレ">
              <TinyHead>エスカレ先</TinyHead>
              <p>{escalationTarget}</p>
              <Form.Group>
                <Form.Label>申請者へのメッセージ</Form.Label>
                <Form.Control
                  size="lg"
                  as="textarea"
                  placeholder={'当選前来店のため、今回は却下いたします。\n次回以降お気をつけください。'}
                  value={f.escaleMessage ?? ''}
                  onChange={set('escaleMessage')}
                />
              </Form.Group>
              <Button className={sass.btn_gray} onClick={() => onApprove(rejectAnswerCTIdType)}>
                エスカレ実行
              </Button>
            </Tab>
            {when(
              isOtherDispatch,
              <Tab eventKey="answerToSv" title="SVへの回答">
                <Form.Group>
                  <Form.Label>SVクルーへのメッセージ</Form.Label>
                  <Form.Control
                    size="lg"
                    as="textarea"
                    placeholder="初回ですので、今回は再提出依頼にしてください。"
                    value={f.escaleMessage ?? ''}
                    onChange={set('escaleMessage')}
                  />
                </Form.Group>
                <Button className={sass.btn_gray} onClick={() => onApprove(RejectAnswerCTIdType.ANSWER_TO_SV)}>
                  回答実行
                </Button>
              </Tab>
            )}
          </Tabs>
        </>
      )}
    </>
  );
};
