import React, { useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import type { ShopReplaceExecuteFormResponse } from '../../../api-client';
import { DispatchSetState } from '../../../interfaces/utils';
import { createTestId } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import type { State as PageState } from '../../pages/ShopReplacePage';
import { Alert } from '../../atoms/Alert';

export const ShopReplaceConfirmModal: React.FC<{
  isModal: PageState['isConfirmModal'];
  setShow: DispatchSetState<PageState['isConfirmModal']>;
  confirmedData: NonNullable<PageState['confirmedData']>;
  onSubmit: (shopReplaceExecutionFormResponse: ShopReplaceExecuteFormResponse) => void;
  resultMessage: string | undefined;
}> = ({
  isModal,
  setShow,
  confirmedData: { applyId, beforeMonitor, afterMonitor, comment, alertMessage },
  onSubmit,
  resultMessage,
}) => {
  const testid = createTestId(ShopReplaceConfirmModal);

  return (
    <Modal
      closeButton={!resultMessage}
      centered
      scrollable
      isModal={isModal}
      onHide={() => resultMessage || setShow(false)}
      title={resultMessage ? '完了' : 'よろしいですか？'}
      size="lg"
      body={
        resultMessage ? (
          <>
            <h3 data-testid={testid('result-message')}>{resultMessage}</h3>
            <Button data-testid={testid('back-button')} onClick={() => window.location.reload()}>
              戻る
            </Button>
          </>
        ) : (
          <>
            {alertMessage ? <Alert variant="danger">{alertMessage}</Alert> : <></>}
            <Form>
              <Row className="g-2 mb-4">
                <Col>
                  <Form.Group data-testid={testid('apply-id')} controlId="colForm1">
                    <Form.Label>応募ID</Form.Label>
                    <Form.Control type="text" disabled value={applyId || ''} />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            {[beforeMonitor, afterMonitor].map(({ shopId, shopName, monitorBaseId, monitorMonth }, isAfter) => {
              const alias = isAfter ? 'after' : 'before';
              return (
                <Table data-testid={testid(`${alias}-table`)} key={alias}>
                  <thead>
                    <tr className="align-middle">
                      {['店舗ID', '店舗名', 'モニターベースID', 'モニター月'].map((label) => (
                        <th key={label}>{label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="align-middle">
                      <td>{shopId}</td>
                      <td>{shopName}</td>
                      <td>{monitorBaseId}</td>
                      <td>{monitorMonth}</td>
                    </tr>
                  </tbody>
                </Table>
              );
            })}
            <Form>
              <Row className="g-2 mb-4">
                <Col>
                  <Form.Group data-testid={testid('memo')} controlId="colForm1">
                    <Form.Label>作業メモ</Form.Label>
                    <Form.Control type="text" disabled value={comment || ''} />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Button
              onClick={() => {
                onSubmit({
                  applyId,
                  afterReplaceMonitorBaseId: afterMonitor.monitorBaseId as number,
                  comment,
                  noRemainingMonitorForceExecFlg: false,
                });
              }}
              data-testid={testid('submit-button')}
            >
              付け替え実行
            </Button>
          </>
        )
      }
    />
  );
};
