/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { NoteDisplayOutputResponse } from '../domains';
// @ts-ignore
import { NoteTemplateOutputResponse } from '../domains';
// @ts-ignore
import { NoteUpdateFormResponse } from '../domains';
// @ts-ignore
import { NoteUpdateOutputResponse } from '../domains';
// @ts-ignore
import { NoteVisibleRangeTypeListOutputResponse } from '../domains';
// @ts-ignore
import { NoteVisibleTargetTypeListOutputResponse } from '../domains';
/**
 * NoteApi - axios parameter creator
 * @export
 */
export const NoteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ノートに削除フラグを立てる
         * @summary ノート削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('noteDelete', 'id', id)
            const localVarPath = `/api/note/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ノートの内容を表示する
         * @summary ノート内容表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('noteDisplay', 'applyId', applyId)
            const localVarPath = `/api/note/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ノートテンプレートの一覧を表示する
         * @summary ノートテンプレート一覧表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteTemplateList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/note/note-template-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ノートの内容を更新する
         * @summary ノート内容更新
         * @param {NoteUpdateFormResponse} noteUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteUpdate: async (noteUpdateFormResponse: NoteUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noteUpdateFormResponse' is not null or undefined
            assertParamExists('noteUpdate', 'noteUpdateFormResponse', noteUpdateFormResponse)
            const localVarPath = `/api/note`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noteUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 表示範囲を一覧で取得するAPI
         * @summary 表示範囲タイプ取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteVisibleRangeTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/note/visible-range-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 表示対象者種別を一覧で取得するAPI
         * @summary 表示対象者種別タイプ取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteVisibleTargetTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/note/visible-target-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoteApi - functional programming interface
 * @export
 */
export const NoteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoteApiAxiosParamCreator(configuration)
    return {
        /**
         * ノートに削除フラグを立てる
         * @summary ノート削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noteDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ノートの内容を表示する
         * @summary ノート内容表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noteDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noteDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ノートテンプレートの一覧を表示する
         * @summary ノートテンプレート一覧表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noteTemplateList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteTemplateOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noteTemplateList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ノートの内容を更新する
         * @summary ノート内容更新
         * @param {NoteUpdateFormResponse} noteUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noteUpdate(noteUpdateFormResponse: NoteUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NoteUpdateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noteUpdate(noteUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 表示範囲を一覧で取得するAPI
         * @summary 表示範囲タイプ取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noteVisibleRangeTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteVisibleRangeTypeListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noteVisibleRangeTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 表示対象者種別を一覧で取得するAPI
         * @summary 表示対象者種別タイプ取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noteVisibleTargetTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NoteVisibleTargetTypeListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noteVisibleTargetTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoteApi - factory interface
 * @export
 */
export const NoteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoteApiFp(configuration)
    return {
        /**
         * ノートに削除フラグを立てる
         * @summary ノート削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteDelete(id: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.noteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ノートの内容を表示する
         * @summary ノート内容表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteDisplay(applyId: number, options?: any): AxiosPromise<NoteDisplayOutputResponse> {
            return localVarFp.noteDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * ノートテンプレートの一覧を表示する
         * @summary ノートテンプレート一覧表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteTemplateList(options?: any): AxiosPromise<Array<NoteTemplateOutputResponse>> {
            return localVarFp.noteTemplateList(options).then((request) => request(axios, basePath));
        },
        /**
         * ノートの内容を更新する
         * @summary ノート内容更新
         * @param {NoteUpdateFormResponse} noteUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteUpdate(noteUpdateFormResponse: NoteUpdateFormResponse, options?: any): AxiosPromise<NoteUpdateOutputResponse> {
            return localVarFp.noteUpdate(noteUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 表示範囲を一覧で取得するAPI
         * @summary 表示範囲タイプ取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteVisibleRangeTypeList(options?: any): AxiosPromise<Array<NoteVisibleRangeTypeListOutputResponse>> {
            return localVarFp.noteVisibleRangeTypeList(options).then((request) => request(axios, basePath));
        },
        /**
         * 表示対象者種別を一覧で取得するAPI
         * @summary 表示対象者種別タイプ取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noteVisibleTargetTypeList(options?: any): AxiosPromise<Array<NoteVisibleTargetTypeListOutputResponse>> {
            return localVarFp.noteVisibleTargetTypeList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoteApi - object-oriented interface
 * @export
 * @class NoteApi
 * @extends {BaseAPI}
 */
export class NoteApi extends BaseAPI {
    /**
     * ノートに削除フラグを立てる
     * @summary ノート削除
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public noteDelete(id: number, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).noteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ノートの内容を表示する
     * @summary ノート内容表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public noteDisplay(applyId: number, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).noteDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ノートテンプレートの一覧を表示する
     * @summary ノートテンプレート一覧表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public noteTemplateList(options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).noteTemplateList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ノートの内容を更新する
     * @summary ノート内容更新
     * @param {NoteUpdateFormResponse} noteUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public noteUpdate(noteUpdateFormResponse: NoteUpdateFormResponse, options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).noteUpdate(noteUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 表示範囲を一覧で取得するAPI
     * @summary 表示範囲タイプ取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public noteVisibleRangeTypeList(options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).noteVisibleRangeTypeList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 表示対象者種別を一覧で取得するAPI
     * @summary 表示対象者種別タイプ取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoteApi
     */
    public noteVisibleTargetTypeList(options?: AxiosRequestConfig) {
        return NoteApiFp(this.configuration).noteVisibleTargetTypeList(options).then((request) => request(this.axios, this.basePath));
    }
}
