import React from 'react';
import { Form } from 'react-bootstrap';
import { Button } from '../../atoms/Button';
import { DispatchSetState } from '../../../interfaces/utils';
import { Modal } from '../../molecules/Modal';
import type { FormsState, State as EditPageState } from '../../pages/ClientNg/ClientNgEditPage';
import { createTestId } from '../../../utils/functions';
import { ClientNgEditModalForms } from '../Forms/ClientNgEditModalForms';

interface Props {
  isModal: boolean;
  forms: EditPageState['forms'];
  setForms: DispatchSetState<FormsState>;
  modalType: EditPageState['editModalType'];
  reasonTypes: EditPageState['reasonTypes'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide: () => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: () => any;
}
export const ClientNgEditModal: React.FC<Props> = ({
  isModal,
  onHide,
  forms,
  setForms,
  modalType,
  onSubmit,
  reasonTypes,
}) => {
  const testid = createTestId(ClientNgEditModal);
  return (
    <Modal
      closeButton
      centered
      scrollable
      size="lg"
      isModal={isModal}
      onHide={onHide}
      title={modalType === 'new' ? '新規作成' : '編集'}
      body={
        <>
          <Form>
            <ClientNgEditModalForms forms={forms} setForms={setForms} modalType={modalType} reasonTypes={reasonTypes} />
          </Form>
          <Button
            data-testid={testid('submit-button')}
            disabled={!forms?.ngCustomerId || forms?.ngReasonType === undefined}
            onClick={onSubmit}
          >
            保存
          </Button>
        </>
      }
    />
  );
};
