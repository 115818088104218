import React, { useState, Dispatch, SetStateAction } from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import scss from '../../scss/molecules/tagsinput.module.scss';
import { createTestId, when } from '../../utils/functions';

export interface Props {
  tags: string[];
  setTags: Dispatch<SetStateAction<string[]>>;
  options?: string[];
  placeholder?: string;
  className?: string;
  pill?: boolean;
  disabled?: boolean;
  setButton?: boolean;
}

export const TagsInput: React.VFC<Props> = ({
  options,
  tags,
  setTags,
  placeholder,
  className,
  pill,
  disabled,
  setButton,
}) => {
  const testId = createTestId(TagsInput);
  const [inputText, setInputText] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className="position-relative" data-testid={testId('parent')}>
      <div className={`${scss.wrap_tagsinput} ${className}`}>
        <ul className={scss.tags}>
          {tags?.map((tag, index) => (
            <li key={tag} className={`${scss.tag} ${pill && 'rounded-pill'}`}>
              <span>{tag}</span>
              <span
                className={scss.tag_close}
                onClick={() => setTags([...tags.filter((_, currentIndex) => currentIndex !== index)])}
                aria-hidden="true"
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth />
              </span>
            </li>
          ))}
          <li className={scss.wrap_taginput}>
            <div className="d-flex align-items-center">
              <input
                type="text"
                data-testid={testId('input')}
                value={inputText ?? ''}
                className={`${scss.taginput}`}
                placeholder={placeholder}
                onFocus={() => setIsOpen(true)}
                onBlur={() => setIsOpen(false)}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Enter' || setButton) return;
                  e.preventDefault();

                  const { value } = e.target as HTMLInputElement;
                  if (!value || tags.find((tag) => tag === value)) return;

                  if (!options || (options && options.includes(value))) {
                    setTags([...tags, value]);
                    setInputText('');
                  }
                }}
                disabled={disabled}
              />
              {when(
                !!setButton,
                <Button
                  className="text-right"
                  style={{ minWidth: '100px' }}
                  onClick={() => {
                    const value = inputText;
                    if (!value || tags.find((tag) => tag === value)) return;

                    if (!options || (options && options.includes(value))) {
                      setTags([...tags, value]);
                      setInputText('');
                    }
                  }}
                >
                  タグ作成
                </Button>
              )}
            </div>
          </li>
        </ul>
      </div>

      {options && isOpen && (
        <ListGroup
          data-testid={testId('listGroup')}
          className="border position-absolute w-100"
          style={{ maxHeight: '202px', overflowY: 'scroll', zIndex: 4 }}
        >
          {options
            .filter((item) => !inputText || item.includes(inputText))
            .filter((item) => !tags.includes(item))
            .map((item, i) => (
              <ListGroup.Item
                key={item}
                className="border-0"
                data-testid={testId('listItem', i)}
                onMouseDown={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  setTags([...tags, e.currentTarget.innerHTML]);
                  setInputText('');
                }}
                action
              >
                {item}
              </ListGroup.Item>
            ))}
        </ListGroup>
      )}
    </div>
  );
};
