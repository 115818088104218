import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import {
  CustomerInfoApi,
  CustomerInformationDetailDisplayOutputResponse,
  MailHistoryOemDisplayOutputResponse,
} from '../../../api-client';
import { createTestId } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Url } from '../../../constants/Url';
import { MailHistoryListOemForms } from '../../organisms/Forms/MailHistoryListOemForms';
import { MailHistoryListOemTable } from '../../organisms/Table/MailHistoryListOemTable';
import { CustomerInfoHeader } from '../Customer/CustomerInfoHeader';

type MailHistoryListRoiForm = { customerId: number; messageType: number | undefined };

export interface MailHistoryListOemState {
  api: CustomerInfoApi;
  isLoading: boolean;
  data: MailHistoryOemDisplayOutputResponse;
  customerData: CustomerInformationDetailDisplayOutputResponse;
  forms: MailHistoryListRoiForm;
  mailTypeList: [string, number | undefined][];
  errorMessage: string[];
}
export type TestIds = 'search-button' | 'customerData';

export const MailHistoryListOemPage: React.FC = () => {
  const testid = createTestId<TestIds>(MailHistoryListOemPage);
  const { id } = useParams<{ id: string | undefined }>();
  const MAX_RECORD_PER_PAGE = 20;

  const initialData: CustomerInformationDetailDisplayOutputResponse = {
    bounceMailFlg: false,
    createAt: '',
    customerId: -1,
    customerStatus: -1,
    customerStatusName: '',
    phoneNumber: '',
    phoneNumberAuthedFlg: false,
    mailAddress: '',
    mailSendFailCount: -1,
    moral: -1,
    moralName: '',
    ownedPoints: -1,
    pointFreezeFlg: false,
    pointExchangeStatusName: '',
    testCustomerFlg: false,
    updateAt: '',
    referenceSecretQuestionFlg: false,
    oldSystemCustomerDetailUrl: '',
  };

  const messageType: [string, number | undefined][] = useMemo(
    () =>
      Object.entries({
        全て: undefined,
        当選: 1,
        キャンセル: 2,
        アンケート提出: 3,
        アンケート再提出: 4,
        アンケートNG: 5,
        レシート提出: 6,
        レシート再提出: 7,
        レシートNG: 8,
        アンケート承認: 9,
        レシート承認: 10,
        モニター完了: 11,
      }),
    []
  );

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<MailHistoryListOemState>({
    api: new CustomerInfoApi(),
    isLoading: false,
    data: { total: 0, list: [] },
    customerData: initialData,
    forms: { customerId: Number(id), messageType: undefined },
    mailTypeList: messageType,
    errorMessage: [],
  });

  const history = useHistory();
  const setForms = useBindSet('forms');

  useEffect(() => {
    if (id && Number(id) > 0) {
      $.api
        .customerInformationDetailDisplay(Number(id))
        .then((res: AxiosResponse<CustomerInformationDetailDisplayOutputResponse>) => {
          mergeState({
            customerData: res.data,
          });
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    }
  }, [$.api, history, id, mergeState]);

  // 初期フェッチ
  useEffect(() => {
    onSearch($.forms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 検索
  const onSearch = useCallback(
    (body: MailHistoryListRoiForm) => {
      mergeState({ isLoading: true });
      $.api
        .mailHistoryOemDisplay(1, MAX_RECORD_PER_PAGE, body.customerId, body.messageType)
        .then((res) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ data: undefined, errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            mergeState({
              data: res.data,
              errorMessage: [],
              isLoading: false,
            });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    },
    [$.api, history, mergeState]
  );

  const onClickPagenation = useCallback(
    (pageNum: number) => {
      mergeState({ isLoading: true });
      $.api
        .mailHistoryOemDisplay(1, MAX_RECORD_PER_PAGE, $.forms.customerId, $.forms.messageType)
        .then((res) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ data: undefined, errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            mergeState({
              data: res.data,
              errorMessage: [],
              isLoading: false,
            });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    },
    [$.api, history, mergeState]
  );

  return (
    <>
      {$.errorMessage.length
        ? $.errorMessage.map((errorMessage) => (
            <Alert variant="danger" key={errorMessage}>
              {errorMessage}
            </Alert>
          ))
        : undefined}
      <CustomerInfoHeader activeKey="mailOem" customerId={Number(id)} />
      <Form className="mt-4">
        <Row className="g-2 mb-4">
          <div style={{ background: 'rgba(0, 0, 0, 0.05)' }} className="p-2">
            <small data-testid={testid('customerData')}>
              会員ID {$.customerData.customerId}&emsp; {$.customerData.oemName}
            </small>
            <div className="d-flex">
              <MailHistoryListOemForms setForms={setForms} forms={$.forms} mailTypeList={$.mailTypeList} />
              <span className="mt-2 ms-2">
                <Button
                  data-testid={testid('search-button')}
                  className="mt-4 center-block"
                  onClick={() => {
                    onSearch($.forms);
                  }}
                >
                  検索
                </Button>
              </span>
            </div>
          </div>
        </Row>
      </Form>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.data.list.length > 0 ? (
          <MailHistoryListOemTable data={$.data} onClickPagenation={onClickPagenation} />
        ) : undefined}
      </LoadingSpinner>
    </>
  );
};
