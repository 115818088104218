import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { CustomerInfoApi } from '../../../api-client';
import type {
  CustomerInformationChangeHistoryDisplayOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { CustomerEditHistoryTable } from '../../organisms/Table/CustomerEditHistoryTable';
import { Alert } from '../../atoms/Alert';

interface Props {
  id: string;
}

export interface CustomerEditHistoryState {
  api: CustomerInfoApi;
  isLoading: boolean;
  list: CustomerInformationChangeHistoryDisplayOutputResponse[];
  errorMessage: string[];
}

export const CustomerEditHistoryPage: React.FC<Props> = ({ id }) => {
  const { state: $, mergeState } = useLargeState<CustomerEditHistoryState>({
    api: new CustomerInfoApi(),
    isLoading: false,
    list: [],
    errorMessage: [],
  });

  useEffect(() => {
    mergeState({ isLoading: true });
    if (id) {
      $.api
        .customerInformationChangeHistoryDisplay(Number(id))
        .then((res: AxiosResponse<CustomerInformationChangeHistoryDisplayOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            mergeState({ list: res.data, errorMessage: [], isLoading: false });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
    }
  }, [$.api, id, mergeState]);

  return (
    <>
      {$.errorMessage.length
        ? $.errorMessage.map((errorMessage) => (
            <Alert variant="danger" key={errorMessage}>
              {errorMessage}
            </Alert>
          ))
        : undefined}
      <LoadingSpinner isLoading={$.isLoading}>
        {$.list.length ? <CustomerEditHistoryTable dataList={$.list} /> : undefined}
      </LoadingSpinner>
    </>
  );
};
