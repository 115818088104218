import React from 'react';
import { Button } from 'react-bootstrap';
import { TemplateSelectContent } from '../../../molecules/TemplateSelectContent';
import type { CorrectionTemplateListOutputResponse } from '../../../../api-client';

export const MessageForUser: React.FC<{
  ngTemplateList: CorrectionTemplateListOutputResponse[];
  onChangeTemplateCode: (id?: number) => void;
  onChangeTemplateText: (text?: string) => void;
  onSave: () => void;
}> = React.memo(({ ngTemplateList, onChangeTemplateCode, onChangeTemplateText, onSave }) => {
  return (
    <div className="d-flex justify-content-end">
      <div className="border border-secondary w-75 p-2 m-2 d-block">
        <h6>ユーザーに通知するメッセージ</h6>
        {/* TODO <Button
            variant="link"
          >
            <FontAwesomeIcon icon={faWindowMaximize} />
            テンプレート一覧
          </Button> */}
        <TemplateSelectContent
          templateList={ngTemplateList.map(({ templateId, templateName, templateText }) => {
            return { id: templateId, name: templateName, text: templateText };
          })}
          onChangeTemplateCode={onChangeTemplateCode}
          onChangeTemplateText={onChangeTemplateText}
        />
        <div className=" d-flex justify-content-end">
          <Button variant="outline-primary" className="mt-2" onClick={onSave}>
            保存
          </Button>
        </div>
      </div>
    </div>
  );
});
