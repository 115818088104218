import React, { useMemo, useCallback } from 'react';
import { Table, Button } from 'react-bootstrap';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { createTestId, formatISODate } from '../../../utils/functions';
import type {
  ExtendDeadlineHistoryState,
  OnDownloadCsvFunc,
} from '../../pages/ExtendDeadline/ExtendDeadlineHistoryPage';
import { Url } from '../../../constants/Url';
import { OriginalLink } from '../../atoms/OriginalLink';

interface Props {
  onDownloadCsv: OnDownloadCsvFunc;
  list: ExtendDeadlineHistoryState['list'];
}
export type TestIds = 'table' | 'edit-button' | 'csv-button';

export const ExtendDeadlineHistoryTable: React.FC<Props> = React.memo(({ list, onDownloadCsv }) => {
  const testId = createTestId<TestIds>(ExtendDeadlineHistoryTable);
  const tableData: {
    label: string;
    name: keyof ExtendDeadlineHistoryState['list'][0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parser?: (x?: any) => any;
  }[] = useMemo(() => {
    return [
      { label: '応募ID', name: 'applyIdList', parser: Number },
      { label: '登録日時/応募者', name: 'createAt', parser: String },
      { label: '延長理由', name: 'reason', parser: Number },
      { label: '延長前期限/延長後期限', name: 'beforeDeadline', parser: String },
      { label: '当選復帰有無', name: 'reWinFlg', parser: String },
      { label: '残枠なし除外', name: 'hasExcludeNoRemainFlg', parser: String },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (item: ExtendDeadlineHistoryState['list'][0], labelName: keyof ExtendDeadlineHistoryState['list'][0]) => {
      // 加工が必要なセル
      const combineName = ['createAt', 'applyIdList', 'beforeDeadline', 'reWinFlg', 'hasExcludeNoRemainFlg'];
      if (!combineName.includes(labelName)) {
        return item[labelName];
      }

      if (labelName === 'createAt') {
        return (
          <>
            {item.createAt ? formatISODate(item.createAt, DATETIME_DISPLAY_FORMAT) : ''}
            <br />
            {item.createIncAccount.name}
          </>
        );
      }
      if (labelName === 'applyIdList') {
        if (item.applyIdList.length === 1) {
          return <ApplyIdLink applyId={item.applyIdList[0]} />;
        }
        if (item.applyIdList.length === 2) {
          return (
            <>
              <ApplyIdLink applyId={item.applyIdList[0]} />
              <br />
              <ApplyIdLink applyId={item.applyIdList[1]} />
              &emsp;計{item.applyIdCount ? item.applyIdCount : ''}件
            </>
          );
        }
        return <></>;
      }
      if (labelName === 'beforeDeadline') {
        const MULTI_DEADLINE = '複数あり';
        return item.beforeDeadline && item.afterDeadline ? (
          <>
            {item.beforeDeadline === MULTI_DEADLINE
              ? MULTI_DEADLINE
              : formatISODate(item.beforeDeadline, DATETIME_DISPLAY_FORMAT)}
            <br />
            {formatISODate(item.afterDeadline, DATETIME_DISPLAY_FORMAT)}
          </>
        ) : (
          <></>
        );
      }
      if (labelName === 'reWinFlg') {
        return item.reWinFlg ? 'あり' : 'なし';
      }
      if (labelName === 'hasExcludeNoRemainFlg') {
        return item.hasExcludeNoRemainFlg ? <strong style={{ color: 'red' }}>除外あり</strong> : '';
      }

      return '';
    },
    []
  );

  return (
    <Table data-testid={testId('table')}>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label), '詳細ファイル'].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item, i) => (
          <tr className="align-middle" key={item.id}>
            {tableData.map(({ name }) => (
              <td key={name}>{createDisplayElement(item, name)}</td>
            ))}
            <td key="csv">
              <Button data-testid={testId('csv-button', i)} variant="link" onClick={() => onDownloadCsv(item.id)}>
                DL
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
});

const ApplyIdLink: React.FC<{ applyId: number }> = ({ applyId }) => {
  return <OriginalLink url={`${Url.TENSAKU.APPLY_INFO}/${applyId}`} anotherTag={false} title={applyId.toString()} />;
};
