/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 請求利用商品IDのリスト
 * @export
 * @interface BillingUseItemOutputResponse
 */
export interface BillingUseItemOutputResponse {
    /**
     * 請求利用商品ID
     * @type {number}
     * @memberof BillingUseItemOutputResponse
     */
    'id': number;
    /**
     * 商品名
     * @type {string}
     * @memberof BillingUseItemOutputResponse
     */
    'name': string;
    /**
     * どの単位に紐づくか
     * @type {string}
     * @memberof BillingUseItemOutputResponse
     */
    'relation': BillingUseItemOutputResponseRelationEnum;
}

export const BillingUseItemOutputResponseRelationEnum = {
    Contract: 'Contract',
    ContractShop: 'ContractShop',
    Monitor: 'Monitor',
    Apply: 'Apply'
} as const;

export type BillingUseItemOutputResponseRelationEnum = typeof BillingUseItemOutputResponseRelationEnum[keyof typeof BillingUseItemOutputResponseRelationEnum];


