import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import { Url } from '../../constants/Url';

export const TopPage: React.VFC = () => {
  return (
    <>
      <Link to={Url.PASSWORD_EDIT}>パスワード変更</Link>
      <Alert variant="danger">※初期パスワードの方は必ずパスワード変更をお願いします</Alert>
    </>
  );
};
