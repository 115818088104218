import React from 'react';
import { when } from '../../../../utils/functions';
import { Tag } from '../../../atoms/Tag';

export const NgComment: React.FC<{
  label: string;
  ngComment: string;
}> = React.memo(({ label, ngComment }) => {
  return (
    <div className="my-2">
      <div>
        <Tag label={label} />
      </div>
      {when(
        !!ngComment,
        <div className="bg-light p-2 my-2">
          <div className="bg-white p-2">{ngComment}</div>
        </div>
      )}
    </div>
  );
});
