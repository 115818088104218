import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { format } from 'date-fns';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { DATE_DISPLAY_FORMAT, ESCALATION_ID } from '../../../../../Constants';
import { EscalationInfoDisplayOutputResponse } from '../../../../../api-client';
import { toUnique } from '../../../../../utils/functions';

interface Escalation {
  escalationId: number;
  inquiry: string;
  answeredAt?: string;
}
export const PageLinkList: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  /**
   * 表示される内容をユニークにする
   * TODO バックエンドで対応
   * 添削エスカレNo.623
   * @param list
   */
  const uniqueList = (list: EscalationInfoDisplayOutputResponse[] | undefined) => {
    const result: Escalation[] = [];
    list?.map((v) => {
      result.push({ escalationId: v.escalationId, inquiry: v.inquiry, answeredAt: v.answeredAt });
    });
    return toUnique(result);
  };
  return (
    <>
      <ListGroup>
        {uniqueList($.res_escalationInfoDisplay).map((item) => (
          <ListGroup.Item
            href={`#escalationId${item.escalationId}`}
            action
            onClick={() => {
              localStorage.setItem(ESCALATION_ID, `#escalationId${item.escalationId}`);
            }}
          >
            <>
              {`エスカレーションID：${item.escalationId}`}
              {item.answeredAt && `回答済み ${item.answeredAt.replace(/ .*/, '')}`}
            </>
            <br />
            {`質問：${item.inquiry.length > 30 ? `${item.inquiry.substr(0, 30)}...` : item.inquiry}`}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </>
  );
};
