import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Form, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { EnqueteQuestionSettingOutputResponse, QuestionListOutputResponse } from '../../../api-client';
import { ENQUETE_TYPE } from '../../pages/Enquete/EnqueteListPage';
import { QuestionInfoPopover } from '../Popover/QuestionInfoPopover';

export interface Props {
  data?: EnqueteQuestionSettingOutputResponse[];
  listPerPage: QuestionListOutputResponse[];
  enqueteType: number;
  setSelectedQuestionIdList: Dispatch<SetStateAction<number[]>>;
  selectedQuestionIdList: number[];
}

export const QuestionListModalTable: React.VFC<Props> = ({
  data,
  listPerPage,
  enqueteType,
  setSelectedQuestionIdList,
  selectedQuestionIdList,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedQuestionIdList((prevState) => {
      const id = Number(e.target.id);
      const newState = prevState.filter((it) => it !== id);
      if (e.target.checked) {
        newState.push(id);
      }
      return newState;
    });
  };

  const overLayButton = (row: QuestionListOutputResponse) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Popover style={{ maxWidth: '25rem' }}>
            <QuestionInfoPopover questionId={row.questionId} enqueteType={enqueteType} />
          </Popover>
        }
      >
        <label className="mb-0" style={{ borderBottom: 'solid' }}>
          {row.questionContent}
        </label>
      </OverlayTrigger>
    );
  };

  // 【事前アンケート編集】
  // 【積上式アンケート編集】
  // 【その他アンケート編集】
  // 【アンケートモニターアンケート編集】
  // からの遷移の場合のPageContents
  const tableContents1 = (
    <>
      {listPerPage && (
        <>
          <thead>
            <tr>
              <th style={{ width: '5%' }}>&nbsp;</th>
              <th style={{ width: '10%' }} className="text-center">
                設問ID
              </th>
              {enqueteType === ENQUETE_TYPE.PILEUP ? (
                <>
                  <th style={{ width: '55%' }}>設問/回答方法</th>
                  <th style={{ width: '30%' }}>使用クライアント名</th>
                </>
              ) : (
                <th style={{ width: '85%' }}>設問/回答方法</th>
              )}
            </tr>
          </thead>
          <tbody>
            {listPerPage.map((row: QuestionListOutputResponse) => {
              return (
                <tr key={row.questionId} className="align-middle">
                  <td className="text-center">
                    <Form.Check.Input
                      id={String(row.questionId)}
                      className="flex-shrink-0 me-2"
                      type="checkbox"
                      checked={selectedQuestionIdList.includes(row.questionId)}
                      onChange={onChange}
                      disabled={data?.some((_) => _.questionId === row.questionId)}
                    />
                  </td>
                  <td className="text-center">{row?.questionId}</td>
                  <td>
                    {overLayButton(row)}
                    <br />
                    {row.questionAnswerMethodName}
                  </td>
                  {enqueteType === ENQUETE_TYPE.PILEUP && <td>{row?.clientName}</td>}
                </tr>
              );
            })}
          </tbody>
        </>
      )}
    </>
  );

  // 【選択式アンケート編集】
  // からの遷移の場合のPageContents
  const tableContents2 = (
    <>
      {listPerPage && (
        <>
          <thead>
            <tr>
              <th className="text-center" style={{ width: '5%' }}>
                &nbsp;
              </th>
              <th className="text-center" style={{ width: '5%' }}>
                設問ID
              </th>
              <th className="text-center" style={{ width: '5%' }}>
                レベル
              </th>
              <th className="text-center" style={{ width: '10%' }}>
                カテゴリ
              </th>
              <th style={{ width: '50%' }}>設問/回答方法</th>
              <th className="text-center" style={{ width: '25%' }}>
                使用クライアント名
              </th>
            </tr>
          </thead>
          <tbody>
            {listPerPage.map((row: QuestionListOutputResponse) => {
              return (
                <tr key={row.questionId} className="align-middle">
                  <td className="text-center">
                    <Form.Check
                      id={String(row.questionId)}
                      className="flex-shrink-0 me-2"
                      type="checkbox"
                      onChange={onChange}
                      checked={selectedQuestionIdList.includes(row.questionId)}
                      disabled={data?.some((_) => _.questionId === row.questionId)}
                    />
                  </td>

                  <td className="text-center">{row?.questionId}</td>
                  <td className="text-center">{row?.level}</td>
                  <td className="text-center">{row?.categoryName}</td>
                  <td>
                    {overLayButton(row)}
                    <br />
                    {row.questionAnswerMethodName}
                  </td>
                  <td className="text-center">{row?.level === 5 && row?.clientName}</td>
                </tr>
              );
            })}
          </tbody>
        </>
      )}
    </>
  );

  return (
    <>
      <Table id="targetTable">
        {enqueteType === ENQUETE_TYPE.BEFOREHAND && tableContents1}
        {enqueteType === ENQUETE_TYPE.SELECT && tableContents2}
        {enqueteType === ENQUETE_TYPE.PILEUP && tableContents1}
        {enqueteType === ENQUETE_TYPE.OTHER && tableContents1}
        {enqueteType === ENQUETE_TYPE.MEMBERSHIP && tableContents1}
        {enqueteType === ENQUETE_TYPE.ENQUETE_MONITOR && tableContents1}
      </Table>
    </>
  );
};
