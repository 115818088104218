import { faWindowRestore, faSun, faMoon, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Url, WhiteList } from '../../constants/Url';
import { useLargeState } from '../../hooks/useLargeState';
import ReactPanZoom from '../../libs/react-image-pan-zoom-rotate/src';
import { dial, uuid, when } from '../../utils/functions';
import { Button } from '../atoms/Button';
import { SideBy } from '../atoms/SideBy';
import { EnqueteAnswerListFormResponse } from '../../api-client';

const whiteList = [
  ...(process.env.REACT_APP_ENVIRONMENT === 'local'
    ? [
        // モック画像
        'picsum.photos',
        'minio:9000',
        'localhost:9000',
      ]
    : []),
  ...WhiteList,
];

export const ImageLens: React.FC<{
  list: {
    imageId: number;
    exifInfo?: string;
    imageUrl: string;
    rotate?: number;
  }[];
  onChangeExif?: (exif?: string) => void;
  height?: React.CSSProperties['maxHeight'];
  linkType?: 'PAGE' | 'DIRECT' | '';
  handleChangeRotate?: (imageId: number, rotate: number) => void;
  changedRotateState?: EnqueteAnswerListFormResponse[];
}> = React.memo(({ list, onChangeExif, height, linkType = '', handleChangeRotate, changedRotateState }) => {
  const {
    state: f,
    mergeState,
    onChangeSet,
  } = useLargeState({
    selectedImageId: list[0].imageId,
    brightness: 1.0,
    contrast: 1.0,
  });

  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    mergeState({ selectedImageId: list[0].imageId });
  }, [mergeState, list]);

  // アンケート画像と保存方法が異なるためめんどくさい。
  // 最終的にどちらかに統一するか、コンポーネントを分けたほうがよい
  const getDefaultRotate = (): number => {
    let rotate: number | null | undefined = null;
    if (changedRotateState !== undefined) {
      // アンケートで使われる場合
      changedRotateState?.map((v) => {
        v.enqueteAnswerImageListForm?.map((vv) => {
          if (vv.imageId === f.selectedImageId) {
            rotate = vv.rotate === null ? null : vv.rotate;
          }
        });
      });
    } else {
      rotate = list.find((v) => v?.imageId === f.selectedImageId)?.rotate;
    }
    if (rotate === null) {
      rotate = list.find(({ imageId }) => imageId === f.selectedImageId)?.rotate;
    }
    if (rotate !== undefined) {
      return rotate;
    }
    return 0;
  };
  useEffect(() => {
    const exifInfo = list.find(({ imageId }) => imageId === f.selectedImageId)?.exifInfo;
    onChangeExif?.(exifInfo);
    setRotation(getDefaultRotate);
  }, [f.selectedImageId, list, onChangeExif]);

  const selectedImageUrl = useMemo(
    () => list.find(({ imageId }) => f.selectedImageId === imageId)?.imageUrl,
    [f.selectedImageId, list]
  );

  if (list[0].imageId === undefined) return <></>;
  if (!whiteList.find((path) => selectedImageUrl?.includes(path))) return <>ERROR: 無許可の画像です</>;
  return (
    <div style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
      {when(
        list.length > 1,
        <SideBy>
          {list.map(({ imageId, exifInfo, imageUrl }) => (
            <Button
              key={uuid()}
              variant={f.selectedImageId === imageId ? 'primary' : 'secondary'}
              onClick={() => {
                mergeState({ selectedImageId: imageId });
                onChangeExif?.(exifInfo);
              }}
            >
              <img src={imageUrl} width="80" height="80" alt="" />
            </Button>
          ))}
        </SideBy>
      )}
      {when(
        !!selectedImageUrl,
        <div style={{ position: 'relative', overflow: 'hidden', width: 'auto', maxHeight: height ?? '500px' }}>
          <ReactPanZoom
            image={selectedImageUrl ?? ''}
            brightness={f.brightness}
            contrast={f.contrast}
            rotation={rotation}
            setRotation={setRotation}
          />
        </div>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <Button
          variant="outline-primary"
          style={{ width: '25%' }}
          onClick={() =>
            mergeState({
              brightness: 1,
              contrast: 1,
            })
          }
        >
          明暗リセット
        </Button>
        <Button
          variant="outline-primary"
          style={{ width: '25%' }}
          onClick={() =>
            mergeState({
              brightness: 0.58,
              contrast: 1.5,
            })
          }
        >
          プリセット 1
        </Button>
        <Button
          variant="outline-primary"
          style={{ width: '25%' }}
          onClick={() =>
            mergeState({
              brightness: 0.75,
              contrast: 1.2,
            })
          }
        >
          プリセット 2
        </Button>
        <Button
          variant="outline-primary"
          style={{ width: '25%' }}
          onClick={() =>
            mergeState({
              brightness: 0.9,
              contrast: 0.8,
            })
          }
        >
          プリセット 3
        </Button>
      </div>
      <SideBy>
        <FontAwesomeIcon size="lg" icon={faSun} />
        <Form.Range min={0.5} max={1.5} step={0.1} value={f.brightness} onChange={onChangeSet('brightness')} />
      </SideBy>
      <SideBy>
        <FontAwesomeIcon size="lg" icon={faMoon} />
        <Form.Range min={0.5} max={1.5} step={0.1} value={f.contrast} onChange={onChangeSet('contrast')} />
      </SideBy>
      {when(
        !!selectedImageUrl,
        dial(linkType, {
          PAGE: (
            <Button
              variant="link"
              onClick={() => window.open(`${Url.IMAGE_PREVIEW}?url=${encodeURIComponent(selectedImageUrl ?? '')}`)}
            >
              <FontAwesomeIcon icon={faWindowRestore} /> 別タブでプレビューを開く
            </Button>
          ),
          DIRECT: (
            <Button variant="link" onClick={() => window.open(selectedImageUrl)}>
              <FontAwesomeIcon icon={faWindowRestore} /> 別タブで画像を直接開く
            </Button>
          ),
        })
      )}
      {handleChangeRotate && (
        <Button variant="link" onClick={() => handleChangeRotate?.(f.selectedImageId, rotation)}>
          <FontAwesomeIcon icon={faSave} /> 回転の向きを保存
        </Button>
      )}
    </div>
  );
});
