import * as React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../atoms/Button';

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
}

export const SearchFrom: React.FC<Props> = ({ value, onChange, onClick }) => {
  return (
    <InputGroup className="mb-4" data-testid="searchFrom">
      <Form.Control type="text" placeholder="検索" value={value} onChange={onChange} />
      <Button variant="primary" onClick={onClick}>
        <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
      </Button>
    </InputGroup>
  );
};
