import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  rows?: number;
  placeholder?: string;
  className?: string;
}

export const Textarea: React.VFC<Props> = ({ rows = 4, placeholder = 'テキストを入力', ...rest }) => {
  return <Form.Control as="textarea" rows={rows} placeholder={placeholder} {...rest} />;
};
