import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import { CorrectApi } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { jumpTo, noCorrectionTarget } from './utils';

export const CorrectionDistributePage: React.FC = React.memo(() => {
  const history = useHistory();
  const { state: $, mergeState } = useLargeState<{
    api: CorrectApi;
    isLoading: boolean;
  }>({
    api: new CorrectApi(),
    isLoading: true,
  });
  useEffect(() => {
    $.api
      .normalCorrectTargetDistribute({})
      .then(({ data }) => jumpTo(history, data))
      .catch(() => noCorrectionTarget(history))
      .finally(() => mergeState({ isLoading: false }));
  }, [$.api, history, mergeState]);
  return <LoadingSpinner isLoading={$.isLoading} />;
});
