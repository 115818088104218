/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HolidayCreateFormResponse } from '../domains';
// @ts-ignore
import { HolidayDetailOutputResponse } from '../domains';
// @ts-ignore
import { HolidayListOutputResponse } from '../domains';
// @ts-ignore
import { HolidayUpdateFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * HolidayApi - axios parameter creator
 * @export
 */
export const HolidayApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 祝祭日を作成する
         * @summary 祝祭日作成
         * @param {HolidayCreateFormResponse} holidayCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoliday: async (holidayCreateFormResponse: HolidayCreateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'holidayCreateFormResponse' is not null or undefined
            assertParamExists('createHoliday', 'holidayCreateFormResponse', holidayCreateFormResponse)
            const localVarPath = `/api/holiday/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(holidayCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 祝祭日を削除する
         * @summary 祝祭日削除
         * @param {number} id Delete ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoliday: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteHoliday', 'id', id)
            const localVarPath = `/api/holiday/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 祝祭日一覧を取得する
         * @summary 祝祭日一覧取得
         * @param {string} year Holidays Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoliday: async (year: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getHoliday', 'year', year)
            const localVarPath = `/api/holiday/list/{year}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 祝祭日詳細を取得する
         * @summary 祝祭日詳細取得
         * @param {string} id Holiday ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidayDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getHolidayDetail', 'id', id)
            const localVarPath = `/api/holiday/detail/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 祝祭日を更新する
         * @summary 祝祭日更新
         * @param {HolidayUpdateFormResponse} holidayUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoliday: async (holidayUpdateFormResponse: HolidayUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'holidayUpdateFormResponse' is not null or undefined
            assertParamExists('updateHoliday', 'holidayUpdateFormResponse', holidayUpdateFormResponse)
            const localVarPath = `/api/holiday/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(holidayUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HolidayApi - functional programming interface
 * @export
 */
export const HolidayApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HolidayApiAxiosParamCreator(configuration)
    return {
        /**
         * 祝祭日を作成する
         * @summary 祝祭日作成
         * @param {HolidayCreateFormResponse} holidayCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createHoliday(holidayCreateFormResponse: HolidayCreateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createHoliday(holidayCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 祝祭日を削除する
         * @summary 祝祭日削除
         * @param {number} id Delete ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHoliday(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHoliday(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 祝祭日一覧を取得する
         * @summary 祝祭日一覧取得
         * @param {string} year Holidays Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHoliday(year: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HolidayListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHoliday(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 祝祭日詳細を取得する
         * @summary 祝祭日詳細取得
         * @param {string} id Holiday ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHolidayDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HolidayDetailOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHolidayDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 祝祭日を更新する
         * @summary 祝祭日更新
         * @param {HolidayUpdateFormResponse} holidayUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateHoliday(holidayUpdateFormResponse: HolidayUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateHoliday(holidayUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HolidayApi - factory interface
 * @export
 */
export const HolidayApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HolidayApiFp(configuration)
    return {
        /**
         * 祝祭日を作成する
         * @summary 祝祭日作成
         * @param {HolidayCreateFormResponse} holidayCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createHoliday(holidayCreateFormResponse: HolidayCreateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.createHoliday(holidayCreateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 祝祭日を削除する
         * @summary 祝祭日削除
         * @param {number} id Delete ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHoliday(id: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.deleteHoliday(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 祝祭日一覧を取得する
         * @summary 祝祭日一覧取得
         * @param {string} year Holidays Year
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoliday(year: string, options?: any): AxiosPromise<Array<HolidayListOutputResponse>> {
            return localVarFp.getHoliday(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 祝祭日詳細を取得する
         * @summary 祝祭日詳細取得
         * @param {string} id Holiday ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHolidayDetail(id: string, options?: any): AxiosPromise<HolidayDetailOutputResponse> {
            return localVarFp.getHolidayDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 祝祭日を更新する
         * @summary 祝祭日更新
         * @param {HolidayUpdateFormResponse} holidayUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateHoliday(holidayUpdateFormResponse: HolidayUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.updateHoliday(holidayUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HolidayApi - object-oriented interface
 * @export
 * @class HolidayApi
 * @extends {BaseAPI}
 */
export class HolidayApi extends BaseAPI {
    /**
     * 祝祭日を作成する
     * @summary 祝祭日作成
     * @param {HolidayCreateFormResponse} holidayCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayApi
     */
    public createHoliday(holidayCreateFormResponse: HolidayCreateFormResponse, options?: AxiosRequestConfig) {
        return HolidayApiFp(this.configuration).createHoliday(holidayCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 祝祭日を削除する
     * @summary 祝祭日削除
     * @param {number} id Delete ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayApi
     */
    public deleteHoliday(id: number, options?: AxiosRequestConfig) {
        return HolidayApiFp(this.configuration).deleteHoliday(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 祝祭日一覧を取得する
     * @summary 祝祭日一覧取得
     * @param {string} year Holidays Year
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayApi
     */
    public getHoliday(year: string, options?: AxiosRequestConfig) {
        return HolidayApiFp(this.configuration).getHoliday(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 祝祭日詳細を取得する
     * @summary 祝祭日詳細取得
     * @param {string} id Holiday ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayApi
     */
    public getHolidayDetail(id: string, options?: AxiosRequestConfig) {
        return HolidayApiFp(this.configuration).getHolidayDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 祝祭日を更新する
     * @summary 祝祭日更新
     * @param {HolidayUpdateFormResponse} holidayUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HolidayApi
     */
    public updateHoliday(holidayUpdateFormResponse: HolidayUpdateFormResponse, options?: AxiosRequestConfig) {
        return HolidayApiFp(this.configuration).updateHoliday(holidayUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
