import { faLink, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import { MonitorRuleSetInfoMonitorRuleOutputResponse, MonitorRuleSetInfoOutputResponse } from '../../api-client';
import { Dropzone } from '../molecules/Dropzone';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「購入店舗」Contents
export const MonitorRuleSetPurchaseShopContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  // 予約ラジオボタン有効無効化フラグ
  const [radioDisableFlg, setRadioDisableFlg] = useState<boolean>(true);
  // 「電話予約」かどうか
  const [reserveTypePhoneFlg, setreserveTypePhoneFlg] = useState<boolean>(false);
  // 「WEB予約」かどうか
  const [reserveTypeWEBFlg, setreserveTypeWEBFlg] = useState<boolean>(false);

  const listTypeList = [
    {
      id: 1,
      name: '購入対象店舗',
    },
    {
      id: 2,
      name: '購入実績店舗',
    },
    {
      id: 3,
      name: '推奨購入店舗',
    },
  ];

  return (
    <>
      <div className="d-flex ">
        <Col className="col-5">
          <Form.Group className="d-flex align-items-center" controlId="readOnly1">
            <Form.Label className="mt-1 me-4" style={{ fontWeight: 'bold' }}>
              一覧タイプ
            </Form.Label>
            <Col>
              <Form.Select>
                {listTypeList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        </Col>
      </div>
      <Col className="col-5 mt-4 mb-4">
        <Form.Group className="d-flex align-items-center" controlId="readOnly1">
          <Form.Label className="mt-1" style={{ fontWeight: 'bold', marginRight: '1.8rem' }}>
            店舗一覧
          </Form.Label>
          <Col>
            {/* {data.monitorRuleList[index].purchaseShopFileName === undefined ? (
          <Dropzone
            onDrop={(files) => {
              setData({
                ...data,
                monitorRuleSetList: data?.monitorRuleSetList.map((_, i) => {
                  return i === index ? { ..._, purchaseShopFileName : files[0].name } : { ..._ };
                }),
              });
            }}
            type="excel"
            maxFiles={1}
          />
        ) : (
        <div style={{ display: 'flex' }}>
          <div  >{data.monitorRuleSetList[index].purchaseShopFileName}</div>
          <Button
            variant="link"
            className="text-secondary pt-0"
            onClick={() => {
              setData({
                ...data,
                monitorRuleSetList: data?.monitorRuleSetList.map((_, i) => {
                  return i === index ? { ..._, purchaseShopFileName : undefined } : { ..._ };
                }),
              });
            }}
            data-html
          >
            <FontAwesomeIcon
              data-testid="deleteButton"
              className="text-secondary"
              icon={faTimesCircle}
            />
          </Button>
        </div>
              )} */}
            <Dropzone
              onDrop={(files) => {
                // setData({
                //   ...data,
                //   monitorRuleSetList: data?.monitorRuleSetList.map((_, i) => {
                //     return i === index ? { ..._, purchaseShopFileName : files[0].name } : { ..._ };
                //   }),
                // });
              }}
              type="excel"
              maxFiles={1}
            />
          </Col>
        </Form.Group>
      </Col>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
