import * as React from 'react';
import { Table } from 'react-bootstrap';

const parameterType = (type: string) => {
  switch (type) {
    case '1':
      return 'STRING';
    case '2':
      return 'DATE';
    case '3':
      return 'TIMESTAMP';
    case '4':
      return 'LONG';
    case '5':
      return 'NUMBER';
    case '6':
      return 'DOUBLE';
    case '7':
      return 'BOOLEAN';
    default:
  }
};

export const parameterCellTitle: string[] = ['名前(*)', 'SQLパラメータ名(*)', '型(*)', 'デフォルト値'];

export const ParameterListTable = (props: any) => {
  const { parameterList } = props;
  return (
    <Table>
      <thead>
        <tr>
          {parameterCellTitle.map((title: string) => (
            <th key={title}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {parameterList?.map((e: any, index: any) => {
          return (
            <tr key={index}>
              <td hidden>{e.parameterId}</td>
              <td>{e.parameterDisplayName}</td>
              <td>{e.parameterName}</td>
              <td>{parameterType(e.parameterType)}</td>
              <td>{e.parameterValue}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
