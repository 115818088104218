import React, { useContext } from 'react';

// TODO: [添削] テスト未実装
/**
 * createSafeContext によって作られた Context を扱う
 *
 * Provider の value が空の状態で呼び出すとエラーになる
 * ```tsx
 * // hogePageContext.ts
 * export const HogePageContext = createSafeContext<HogeState>();
 * // HogePage.tsx
 * const [state] = useState<HogeState>({ x: 1 });
 * return (
 *    <PointGrantContext.Provider value={state}>
 *      <Component />
 *    </PointGrantContext.Provider>
 * )
 * // Component.tsx
 * const state = useSafeContext(HogePageContext);
 * return <p>{state.x}</p>
 * ```
 */
export function useSafeContext<T>(context: React.Context<T>): T {
  const c = useContext(context);
  if (c === undefined)
    throw new Error('useSafeContext は undefined でない value が設定された Provider 内に配置する必要があります');
  return c;
}
