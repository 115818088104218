import { Form } from 'react-bootstrap';
import * as React from 'react';
import { ErrorMessage } from '../../organisms/Table/SqlTemplateInputTable';

export const ValidationFormControl = (props: any) => {
  const { type, register, id, registerName, errorsTargetName, rows, as, valueAsNumber } = props;

  return (
    <Form.Group className="mb-4">
      <Form.Control
        data-testid={id}
        type={type}
        as={as}
        id={id}
        rows={rows}
        {...register(registerName)}
        className={`form-control ${errorsTargetName ? 'is-invalid' : ''}`}
      />
      {errorsTargetName ? ErrorMessage(errorsTargetName.message) : ''}
    </Form.Group>
  );
};
