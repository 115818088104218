import React, { useEffect, useMemo, useState } from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { uuid, when } from '../../../../utils/functions';
import { ImageLens } from '../../ImageLens';
import { SurveyProofDtoResponse } from '../../../../api-client';

// TODO: [添削] テスト未実装
const StateTabs: React.FC<{
  activeId: number | string;
  tabs: {
    id: number | string;
    label: string;
    disabled?: boolean;
  }[];
  onSelect?: (id: number | string) => void;
}> = ({ activeId, tabs, onSelect }) => {
  const [selectedId, setSelectedId] = useState(activeId);
  return (
    <ul className="nav nav-tabs">
      {tabs.map(({ id, label, disabled }) => (
        <li className="nav-item" key={uuid()}>
          <Button
            variant="link"
            className={['nav-link']
              .concat(id === selectedId ? 'active' : [])
              .concat(disabled ? 'disabled' : [])
              .join(' ')}
            onClick={() => {
              setSelectedId(id);
              onSelect?.(id);
            }}
          >
            {label}
          </Button>
        </li>
      ))}
    </ul>
  );
};

export const SurveyProofImageCard: React.FC<{
  surveyProofList: SurveyProofDtoResponse[] | undefined;
  userComment?: string;
}> = React.memo(({ surveyProofList, userComment }) => {
  const [selectedSurveyProofVersion, setSelectedSurveyProofVersion] = useState(surveyProofList?.[0].versionName);
  const list = useMemo(
    () =>
      surveyProofList?.find(({ versionName }) => selectedSurveyProofVersion === versionName)
        ?.surveyProofImageInfoDtoList ?? [],
    [surveyProofList, selectedSurveyProofVersion]
  );
  useEffect(() => {
    if (!selectedSurveyProofVersion) {
      setSelectedSurveyProofVersion(surveyProofList?.[0].versionName);
    }
  }, [surveyProofList, selectedSurveyProofVersion]);

  if (selectedSurveyProofVersion === undefined || !surveyProofList?.[0].surveyProofImageInfoDtoList?.[0].imageId)
    return <></>;

  return (
    <>
      <Card className="ms-2">
        <Accordion defaultActiveKey={['0']} flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>購入証明情報</Accordion.Header>
            <Accordion.Body>
              {when(surveyProofList?.length === 0, <div>画像はありません</div>)}
              {when(
                surveyProofList?.length > 0,
                <>
                  <StateTabs
                    activeId={selectedSurveyProofVersion}
                    tabs={surveyProofList.map(({ versionName }) => ({
                      id: versionName,
                      label: versionName,
                    }))}
                    onSelect={(id) => {
                      setSelectedSurveyProofVersion(id as string);
                    }}
                  />
                  <ImageLens list={list} />
                </>
              )}
              {when(
                !!userComment,
                <div>
                  <hr className="m-2" />
                  <h6>ユーザーからのコメント</h6>
                  <div style={{ whiteSpace: 'pre-wrap' }}>{userComment}</div>
                </div>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
    </>
  );
});
