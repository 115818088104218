/* eslint-disable import/no-cycle */
import React from 'react';
import { Table } from 'react-bootstrap';
import { QcCrewApplyStatusSummaryDisplayOutputResponse } from '../../../api-client';
import { createTestId } from '../../../utils/functions';

interface Props {
  summary?: QcCrewApplyStatusSummaryDisplayOutputResponse;
}
export type TestIds = 'table';

export const QcCrewApplyManagementTable: React.FC<Props> = ({ summary }) => {
  const testid = createTestId<TestIds>(QcCrewApplyManagementTable);

  return (
    <Table data-testid={testid('table')} style={{ maxWidth: '500px' }} bordered>
      <thead>
        <tr className="align-middle">
          <th>
            <></>
          </th>
          <th>
            <span>合計</span>
          </th>
          <th>
            <span>選任</span>
          </th>
          <th>
            <span>選任以外</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr className="align-middle">
          <td>
            <span>チェック待ち件数</span>
          </td>
          <td>
            <span>{summary?.waitingForCheck.totalApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForCheck.electedPersonCorrectableApplyCount}</span>
          </td>
          <td>
            <span>{summary?.waitingForCheck.anyoneCorrectableApplyCount}</span>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
