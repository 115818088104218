import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { width } from '@fortawesome/free-solid-svg-icons/faLink';
import { useHistory, useParams } from 'react-router-dom';
import { async } from 'q';
import { AxiosError } from 'axios';
import { Button } from '../../atoms/Button';
import {
  ReceiptPriceApi,
  ReceiptPriceInfoSearchOutputResponse,
  CorrectApi,
  ApplyInfoDisplayOutputResponse,
  PointApi,
  ApprovedAmountModifyHistoryInfoOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { ReceiptPriceContens } from '../../organisms/ReceiptPrice/ReceiptPriceContents';
import { ReceiptPriceApplyInfo } from '../../organisms/ReceiptPrice/ReceiptPriceApplyInfo';
import { ReceiptPricePurchaseProofInfo } from '../../organisms/ReceiptPrice/ReceiptPricePurchaseProofInfo';
import { InputNumber } from '../../atoms/InputNumber';
import { useLargeState } from '../../../hooks/useLargeState';
import { ReceiptPriceModifyHistoryModal } from '../../organisms/Correction/Modal/ReceiptPriceModifyHistoryModal';
import { Alert } from '../../atoms/Alert';
import { when } from '../../../utils/functions';
import { Url } from '../../../constants/Url';

export type State = {
  api: ReceiptPriceApi;
  applyApi: CorrectApi;
  historyApi: PointApi;
  applyId?: number;
  receiptPriceInfo: ReceiptPriceInfoSearchOutputResponse | undefined;
  applyInfo: ApplyInfoDisplayOutputResponse | undefined;
  reciptPriceModifyHistory: ApprovedAmountModifyHistoryInfoOutputResponse | undefined;
  exif: string | undefined;
};

export const ReceiptPriceModifyContext = createContext({} as State);

export const ReceiptPriceModifyPage: React.FC = () => {
  const { applyId: applyIdParam } = useParams<{ applyId: string | undefined }>();
  const [error, setError] = useState<string | undefined>();
  const history = useHistory();

  const {
    state: $,
    mergeState,
    onChangeSet,
  } = useLargeState<State>({
    api: new ReceiptPriceApi(),
    applyApi: new CorrectApi(),
    historyApi: new PointApi(),
    applyId: undefined,
    receiptPriceInfo: undefined,
    applyInfo: undefined,
    reciptPriceModifyHistory: undefined,
    exif: undefined,
  });
  const [isHistoryModal, setIsHistoryModal] = useState<boolean>(false);

  // 初期フェッチ
  useEffect(() => {
    if (applyIdParam === undefined) return;
    mergeState({ applyId: Number(applyIdParam) });
    execSearch(Number(applyIdParam));
  }, []);

  const onSearch = (_applyId?: number) => {
    const applyId = _applyId || $.applyId;
    if (applyId) {
      history.push(`${Url.TENSAKU.RECEIPT_PRICE_MODIFY}/${applyId}`);
      window.location.reload();
    } else {
      setError('応募IDを指定してください。');
    }
  };

  const execSearch = (applyId: number) => {
    $.api
      .receiptPriceModifyingReceiptInfoSearch(applyId)
      .then((res) => {
        mergeState({ receiptPriceInfo: res.data });
        setError(undefined);

        $.applyApi.applyInfoDisplay(applyId).then((applyInfoRes) => {
          mergeState({ applyInfo: applyInfoRes.data });
        });
      })
      .catch((e: AxiosError<IncResultOutputResponse>) => {
        console.log(e);
        setError(e.response?.data.errorMessage);
      });
  };

  const onHistory = () => {
    (async () => {
      if ($.applyId) {
        await $.historyApi.approvedAmountModifyHistoryInfo($.applyId).then((res) => {
          mergeState({ reciptPriceModifyHistory: res.data });
        });
        setIsHistoryModal(true);
      }
    })();
  };
  return (
    <>
      <ReceiptPriceModifyContext.Provider value={$}>
        <ReceiptPriceModifyHistoryModal isModal={isHistoryModal} setIsModal={setIsHistoryModal} />
        <Form>
          <Row>
            <Col>
              <Row className="mb-3">
                {when(!!error, <Alert variant="danger">{error}</Alert>)}
                <Col className="col-9">
                  <FloatingLabel controlId="applyId" label="応募ID">
                    <InputNumber value={$.applyId} min={0} onChange={onChangeSet('applyId')} />
                  </FloatingLabel>
                </Col>
                <Col className="col-3 mt-2">
                  <Button onClick={() => onSearch()}>検索</Button>
                </Col>
              </Row>
              {$.receiptPriceInfo ? (
                <>
                  <Button className="mb-3" onClick={onHistory}>
                    履歴確認
                  </Button>
                  <ReceiptPriceContens applyId={$.applyId || 0} receiptPriceInfo={$.receiptPriceInfo} />
                </>
              ) : null}
            </Col>
            {$.applyInfo ? (
              <>
                <Col>
                  <ReceiptPriceApplyInfo applyInfo={$?.applyInfo?.applyInfo} />
                </Col>
                <Col>
                  <ReceiptPricePurchaseProofInfo
                    applyInfoDisplay={$?.applyInfo}
                    onChangeExif={(exifInfo) => mergeState({ exif: exifInfo })}
                  />
                </Col>
              </>
            ) : null}
          </Row>
        </Form>
      </ReceiptPriceModifyContext.Provider>
    </>
  );
};
