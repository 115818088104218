// FixMe アンケートモーダルからEnqueteDisplayOutputResponse[]を受け取るように書き換える。

import React from 'react';
import { Card } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { EnqueteDisplayOutputResponse } from '../../api-client';
import { AnswerMethodSummary } from '../pages/Correction/constants';
import { WrappableText } from '../atoms/WrappableText';

interface ListProps {
  props: EnqueteDisplayOutputResponse[];
}

interface Props {
  props: EnqueteDisplayOutputResponse;
}

export const AnswerListTable: React.FC<ListProps> = ({ props }) => {
  return (
    <>
      {props.map((value) => (
        <AnswerItem props={value} />
      ))}
    </>
  );
};

const AnswerItem: React.FC<Props> = ({ props }) => {
  switch (props.answerMethodSummaryCode) {
    case AnswerMethodSummary.TEXT:
      return <TextAnswerItem props={props} />;
    case AnswerMethodSummary.SELECT:
      return <SelectionAnswerItem props={props} />;
    case AnswerMethodSummary.IMAGE:
      return <ImageAnswerItem props={props} />;
    default:
      return <></>;
  }
};

const TextAnswerItem: React.FC<Props> = ({ props }) => {
  return (
    <Card key={props.questionNo}>
      <Card.Header>
        設問No{props.questionNo}.{props.content}
      </Card.Header>
      <Card.Body>
        <WrappableText memo={props.answerContent} height={20} />
      </Card.Body>
    </Card>
  );
};

const SelectionAnswerItem: React.FC<Props> = ({ props }) => {
  return (
    <Card key={props.questionNo}>
      <Card.Header>
        設問No{props.questionNo}.{props.content}
      </Card.Header>
      <Card.Body>
        {props.answerChoiceList.map((value) => (
          <>
            <p>{value.content}</p>
            <p>{value.contentVertical}</p>
          </>
        ))}
      </Card.Body>
    </Card>
  );
};

const ImageAnswerItem: React.FC<Props> = ({ props }) => {
  return (
    <Card key={props.questionNo}>
      <Card.Header>
        設問No{props.questionNo}.{props.content}
      </Card.Header>
      <Card.Body>
        {props.imageUrlList.map((value) => (
          <img src={value.url} alt="" width="500px" />
        ))}
      </Card.Body>
    </Card>
  );
};
