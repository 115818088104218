import React from 'react';
import { Link } from 'react-router-dom';
import { Url } from '../../constants/Url';

export const OemManagementHeader = ({ title }: { title: string }) => {
  return (
    <div className="d-flex justify-content-between">
      <h3>{title}</h3>
      <div style={{ fontSize: 'large' }}>
        <Link to={Url.OEM.TOP}>Topに戻る</Link>
      </div>
    </div>
  );
};
