/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CampaignInfoOutputResponse } from '../domains';
// @ts-ignore
import { CampaignListOutputResponse } from '../domains';
// @ts-ignore
import { CampaignRecommendApiclientListFormResponse } from '../domains';
// @ts-ignore
import { CampaignTargetMonitorBaseListFormResponse } from '../domains';
// @ts-ignore
import { CampaignUpdateFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { KeywordFormResponse } from '../domains';
// @ts-ignore
import { RecommendApiClientListOutputResponse } from '../domains';
// @ts-ignore
import { RichInfoOutputResponse } from '../domains';
// @ts-ignore
import { RichUpdateFormResponse } from '../domains';
// @ts-ignore
import { ShowApiclientUpdateFormResponse } from '../domains';
// @ts-ignore
import { TargetMonitorbaseListOutputResponse } from '../domains';
/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * キャンペーン情報を削除する
         * @summary キャンペーン情報削除
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignDelete: async (campaignId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('campaignDelete', 'campaignId', campaignId)
            const localVarPath = `/api/campaign/{campaign-id}`
                .replace(`{${"campaign-id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン情報を取得する
         * @summary キャンペーン情報取得
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignInfo: async (campaignId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('campaignInfo', 'campaignId', campaignId)
            const localVarPath = `/api/campaign/{campaign-id}`
                .replace(`{${"campaign-id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン一覧を取得する
         * @summary キャンペーン一覧取得
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignList: async (keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaign-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 表示推奨OEM一覧を取得する
         * @summary 表示推奨OEM一覧取得API（キャンペーン）
         * @param {CampaignRecommendApiclientListFormResponse} campaignRecommendApiclientListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRecommendApiclientList: async (campaignRecommendApiclientListFormResponse: CampaignRecommendApiclientListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignRecommendApiclientListFormResponse' is not null or undefined
            assertParamExists('campaignRecommendApiclientList', 'campaignRecommendApiclientListFormResponse', campaignRecommendApiclientListFormResponse)
            const localVarPath = `/api/campaign-recommend-oem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignRecommendApiclientListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーンリッチ化情報を取得する
         * @summary キャンペーンリッチ化情報取得
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRichInfo: async (campaignId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('campaignRichInfo', 'campaignId', campaignId)
            const localVarPath = `/api/campaign-rich/{campaign-id}`
                .replace(`{${"campaign-id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーンリッチ化情報を更新する
         * @summary キャンペーンリッチ化情報更新
         * @param {number} campaignId 
         * @param {RichUpdateFormResponse} richUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRichUpdate: async (campaignId: number, richUpdateFormResponse: RichUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('campaignRichUpdate', 'campaignId', campaignId)
            // verify required parameter 'richUpdateFormResponse' is not null or undefined
            assertParamExists('campaignRichUpdate', 'richUpdateFormResponse', richUpdateFormResponse)
            const localVarPath = `/api/campaign-rich/{campaign-id}`
                .replace(`{${"campaign-id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(richUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン紐付け店舗のを即更新する
         * @summary キャンペーン紐付け店舗の即更新
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignShopImmediateUpdate: async (campaignId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('campaignShopImmediateUpdate', 'campaignId', campaignId)
            const localVarPath = `/api/campaign-target-shop-immidate/{campaign-id}`
                .replace(`{${"campaign-id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン表示OEM一覧を取得する
         * @summary キャンペーン表示OEM一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignShowApiclientList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/campaign-show-oem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン表示OEMを更新する
         * @summary キャンペーン表示OEM更新
         * @param {ShowApiclientUpdateFormResponse} showApiclientUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignShowApiclientUpdate: async (showApiclientUpdateFormResponse: ShowApiclientUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'showApiclientUpdateFormResponse' is not null or undefined
            assertParamExists('campaignShowApiclientUpdate', 'showApiclientUpdateFormResponse', showApiclientUpdateFormResponse)
            const localVarPath = `/api/campaign-show-oem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(showApiclientUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン紐付け対象OEM一覧を取得する
         * @summary キャンペーン紐付け対象OEM一覧取得
         * @param {KeywordFormResponse} keywordFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTargetApiclientList: async (keywordFormResponse: KeywordFormResponse, campaignId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'keywordFormResponse' is not null or undefined
            assertParamExists('campaignTargetApiclientList', 'keywordFormResponse', keywordFormResponse)
            const localVarPath = `/api/campaign-target-oem-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign-id'] = campaignId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(keywordFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン紐付け対象モニターベース一覧を取得する
         * @summary キャンペーン紐付け対象モニターベース一覧取得
         * @param {CampaignTargetMonitorBaseListFormResponse} campaignTargetMonitorBaseListFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTargetMonitorBaseList: async (campaignTargetMonitorBaseListFormResponse: CampaignTargetMonitorBaseListFormResponse, campaignId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignTargetMonitorBaseListFormResponse' is not null or undefined
            assertParamExists('campaignTargetMonitorBaseList', 'campaignTargetMonitorBaseListFormResponse', campaignTargetMonitorBaseListFormResponse)
            const localVarPath = `/api/{campaign-id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign-id'] = campaignId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignTargetMonitorBaseListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーン紐付け対象モニターベース一覧を取得する
         * @summary キャンペーン紐付け対象モニターベース一覧取得
         * @param {CampaignTargetMonitorBaseListFormResponse} campaignTargetMonitorBaseListFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTargetMonitorBaseList1: async (campaignTargetMonitorBaseListFormResponse: CampaignTargetMonitorBaseListFormResponse, campaignId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignTargetMonitorBaseListFormResponse' is not null or undefined
            assertParamExists('campaignTargetMonitorBaseList1', 'campaignTargetMonitorBaseListFormResponse', campaignTargetMonitorBaseListFormResponse)
            const localVarPath = `/api/campaign-target-monitor-base-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign-id'] = campaignId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignTargetMonitorBaseListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * キャンペーンを更新する
         * @summary キャンペーン更新
         * @param {CampaignUpdateFormResponse} campaignUpdateFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignUpdate: async (campaignUpdateFormResponse: CampaignUpdateFormResponse, campaignId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'campaignUpdateFormResponse' is not null or undefined
            assertParamExists('campaignUpdate', 'campaignUpdateFormResponse', campaignUpdateFormResponse)
            const localVarPath = `/api/campaign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (campaignId !== undefined) {
                localVarQueryParameter['campaign-id'] = campaignId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * キャンペーン情報を削除する
         * @summary キャンペーン情報削除
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignDelete(campaignId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignDelete(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーン情報を取得する
         * @summary キャンペーン情報取得
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignInfo(campaignId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignInfo(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーン一覧を取得する
         * @summary キャンペーン一覧取得
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignList(keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CampaignListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignList(keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 表示推奨OEM一覧を取得する
         * @summary 表示推奨OEM一覧取得API（キャンペーン）
         * @param {CampaignRecommendApiclientListFormResponse} campaignRecommendApiclientListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignRecommendApiclientList(campaignRecommendApiclientListFormResponse: CampaignRecommendApiclientListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecommendApiClientListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignRecommendApiclientList(campaignRecommendApiclientListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーンリッチ化情報を取得する
         * @summary キャンペーンリッチ化情報取得
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignRichInfo(campaignId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignRichInfo(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーンリッチ化情報を更新する
         * @summary キャンペーンリッチ化情報更新
         * @param {number} campaignId 
         * @param {RichUpdateFormResponse} richUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignRichUpdate(campaignId: number, richUpdateFormResponse: RichUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignRichUpdate(campaignId, richUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーン紐付け店舗のを即更新する
         * @summary キャンペーン紐付け店舗の即更新
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignShopImmediateUpdate(campaignId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignShopImmediateUpdate(campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーン表示OEM一覧を取得する
         * @summary キャンペーン表示OEM一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignShowApiclientList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecommendApiClientListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignShowApiclientList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーン表示OEMを更新する
         * @summary キャンペーン表示OEM更新
         * @param {ShowApiclientUpdateFormResponse} showApiclientUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignShowApiclientUpdate(showApiclientUpdateFormResponse: ShowApiclientUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignShowApiclientUpdate(showApiclientUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーン紐付け対象OEM一覧を取得する
         * @summary キャンペーン紐付け対象OEM一覧取得
         * @param {KeywordFormResponse} keywordFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTargetApiclientList(keywordFormResponse: KeywordFormResponse, campaignId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecommendApiClientListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignTargetApiclientList(keywordFormResponse, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーン紐付け対象モニターベース一覧を取得する
         * @summary キャンペーン紐付け対象モニターベース一覧取得
         * @param {CampaignTargetMonitorBaseListFormResponse} campaignTargetMonitorBaseListFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTargetMonitorBaseList(campaignTargetMonitorBaseListFormResponse: CampaignTargetMonitorBaseListFormResponse, campaignId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TargetMonitorbaseListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignTargetMonitorBaseList(campaignTargetMonitorBaseListFormResponse, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーン紐付け対象モニターベース一覧を取得する
         * @summary キャンペーン紐付け対象モニターベース一覧取得
         * @param {CampaignTargetMonitorBaseListFormResponse} campaignTargetMonitorBaseListFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignTargetMonitorBaseList1(campaignTargetMonitorBaseListFormResponse: CampaignTargetMonitorBaseListFormResponse, campaignId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TargetMonitorbaseListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignTargetMonitorBaseList1(campaignTargetMonitorBaseListFormResponse, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * キャンペーンを更新する
         * @summary キャンペーン更新
         * @param {CampaignUpdateFormResponse} campaignUpdateFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignUpdate(campaignUpdateFormResponse: CampaignUpdateFormResponse, campaignId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignUpdate(campaignUpdateFormResponse, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * キャンペーン情報を削除する
         * @summary キャンペーン情報削除
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignDelete(campaignId: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.campaignDelete(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン情報を取得する
         * @summary キャンペーン情報取得
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignInfo(campaignId: number, options?: any): AxiosPromise<CampaignInfoOutputResponse> {
            return localVarFp.campaignInfo(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン一覧を取得する
         * @summary キャンペーン一覧取得
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignList(keyword?: string, options?: any): AxiosPromise<Array<CampaignListOutputResponse>> {
            return localVarFp.campaignList(keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * 表示推奨OEM一覧を取得する
         * @summary 表示推奨OEM一覧取得API（キャンペーン）
         * @param {CampaignRecommendApiclientListFormResponse} campaignRecommendApiclientListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRecommendApiclientList(campaignRecommendApiclientListFormResponse: CampaignRecommendApiclientListFormResponse, options?: any): AxiosPromise<Array<RecommendApiClientListOutputResponse>> {
            return localVarFp.campaignRecommendApiclientList(campaignRecommendApiclientListFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーンリッチ化情報を取得する
         * @summary キャンペーンリッチ化情報取得
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRichInfo(campaignId: number, options?: any): AxiosPromise<RichInfoOutputResponse> {
            return localVarFp.campaignRichInfo(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーンリッチ化情報を更新する
         * @summary キャンペーンリッチ化情報更新
         * @param {number} campaignId 
         * @param {RichUpdateFormResponse} richUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignRichUpdate(campaignId: number, richUpdateFormResponse: RichUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.campaignRichUpdate(campaignId, richUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン紐付け店舗のを即更新する
         * @summary キャンペーン紐付け店舗の即更新
         * @param {number} campaignId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignShopImmediateUpdate(campaignId: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.campaignShopImmediateUpdate(campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン表示OEM一覧を取得する
         * @summary キャンペーン表示OEM一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignShowApiclientList(options?: any): AxiosPromise<Array<RecommendApiClientListOutputResponse>> {
            return localVarFp.campaignShowApiclientList(options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン表示OEMを更新する
         * @summary キャンペーン表示OEM更新
         * @param {ShowApiclientUpdateFormResponse} showApiclientUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignShowApiclientUpdate(showApiclientUpdateFormResponse: ShowApiclientUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.campaignShowApiclientUpdate(showApiclientUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン紐付け対象OEM一覧を取得する
         * @summary キャンペーン紐付け対象OEM一覧取得
         * @param {KeywordFormResponse} keywordFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTargetApiclientList(keywordFormResponse: KeywordFormResponse, campaignId?: number, options?: any): AxiosPromise<RecommendApiClientListOutputResponse> {
            return localVarFp.campaignTargetApiclientList(keywordFormResponse, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン紐付け対象モニターベース一覧を取得する
         * @summary キャンペーン紐付け対象モニターベース一覧取得
         * @param {CampaignTargetMonitorBaseListFormResponse} campaignTargetMonitorBaseListFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTargetMonitorBaseList(campaignTargetMonitorBaseListFormResponse: CampaignTargetMonitorBaseListFormResponse, campaignId?: number, options?: any): AxiosPromise<TargetMonitorbaseListOutputResponse> {
            return localVarFp.campaignTargetMonitorBaseList(campaignTargetMonitorBaseListFormResponse, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーン紐付け対象モニターベース一覧を取得する
         * @summary キャンペーン紐付け対象モニターベース一覧取得
         * @param {CampaignTargetMonitorBaseListFormResponse} campaignTargetMonitorBaseListFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignTargetMonitorBaseList1(campaignTargetMonitorBaseListFormResponse: CampaignTargetMonitorBaseListFormResponse, campaignId?: number, options?: any): AxiosPromise<TargetMonitorbaseListOutputResponse> {
            return localVarFp.campaignTargetMonitorBaseList1(campaignTargetMonitorBaseListFormResponse, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * キャンペーンを更新する
         * @summary キャンペーン更新
         * @param {CampaignUpdateFormResponse} campaignUpdateFormResponse 
         * @param {number} [campaignId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignUpdate(campaignUpdateFormResponse: CampaignUpdateFormResponse, campaignId?: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.campaignUpdate(campaignUpdateFormResponse, campaignId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * キャンペーン情報を削除する
     * @summary キャンペーン情報削除
     * @param {number} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignDelete(campaignId: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignDelete(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン情報を取得する
     * @summary キャンペーン情報取得
     * @param {number} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignInfo(campaignId: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignInfo(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン一覧を取得する
     * @summary キャンペーン一覧取得
     * @param {string} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignList(keyword?: string, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignList(keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 表示推奨OEM一覧を取得する
     * @summary 表示推奨OEM一覧取得API（キャンペーン）
     * @param {CampaignRecommendApiclientListFormResponse} campaignRecommendApiclientListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignRecommendApiclientList(campaignRecommendApiclientListFormResponse: CampaignRecommendApiclientListFormResponse, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignRecommendApiclientList(campaignRecommendApiclientListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーンリッチ化情報を取得する
     * @summary キャンペーンリッチ化情報取得
     * @param {number} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignRichInfo(campaignId: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignRichInfo(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーンリッチ化情報を更新する
     * @summary キャンペーンリッチ化情報更新
     * @param {number} campaignId 
     * @param {RichUpdateFormResponse} richUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignRichUpdate(campaignId: number, richUpdateFormResponse: RichUpdateFormResponse, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignRichUpdate(campaignId, richUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン紐付け店舗のを即更新する
     * @summary キャンペーン紐付け店舗の即更新
     * @param {number} campaignId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignShopImmediateUpdate(campaignId: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignShopImmediateUpdate(campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン表示OEM一覧を取得する
     * @summary キャンペーン表示OEM一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignShowApiclientList(options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignShowApiclientList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン表示OEMを更新する
     * @summary キャンペーン表示OEM更新
     * @param {ShowApiclientUpdateFormResponse} showApiclientUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignShowApiclientUpdate(showApiclientUpdateFormResponse: ShowApiclientUpdateFormResponse, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignShowApiclientUpdate(showApiclientUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン紐付け対象OEM一覧を取得する
     * @summary キャンペーン紐付け対象OEM一覧取得
     * @param {KeywordFormResponse} keywordFormResponse 
     * @param {number} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignTargetApiclientList(keywordFormResponse: KeywordFormResponse, campaignId?: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignTargetApiclientList(keywordFormResponse, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン紐付け対象モニターベース一覧を取得する
     * @summary キャンペーン紐付け対象モニターベース一覧取得
     * @param {CampaignTargetMonitorBaseListFormResponse} campaignTargetMonitorBaseListFormResponse 
     * @param {number} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignTargetMonitorBaseList(campaignTargetMonitorBaseListFormResponse: CampaignTargetMonitorBaseListFormResponse, campaignId?: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignTargetMonitorBaseList(campaignTargetMonitorBaseListFormResponse, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーン紐付け対象モニターベース一覧を取得する
     * @summary キャンペーン紐付け対象モニターベース一覧取得
     * @param {CampaignTargetMonitorBaseListFormResponse} campaignTargetMonitorBaseListFormResponse 
     * @param {number} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignTargetMonitorBaseList1(campaignTargetMonitorBaseListFormResponse: CampaignTargetMonitorBaseListFormResponse, campaignId?: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignTargetMonitorBaseList1(campaignTargetMonitorBaseListFormResponse, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * キャンペーンを更新する
     * @summary キャンペーン更新
     * @param {CampaignUpdateFormResponse} campaignUpdateFormResponse 
     * @param {number} [campaignId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignUpdate(campaignUpdateFormResponse: CampaignUpdateFormResponse, campaignId?: number, options?: AxiosRequestConfig) {
        return CampaignApiFp(this.configuration).campaignUpdate(campaignUpdateFormResponse, campaignId, options).then((request) => request(this.axios, this.basePath));
    }
}
