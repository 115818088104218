import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap';
import { Url } from '../../../constants/Url';
import type { MonitorListOutputResponse } from '../../../api-client';
import { createTestId, uuid, when } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { DispatchSetState } from '../../../interfaces/utils';

export interface Props {
  listPerPage: MonitorListOutputResponse[];
  setModal: (id: number) => void;
  setIsBasicInfoModal: DispatchSetState<boolean>;
  setMonitorBaseId: DispatchSetState<number>;
  salesFlg: boolean;
}

export const MonitorListTable: React.VFC<Props> = ({
  listPerPage,
  setModal,
  setIsBasicInfoModal,
  setMonitorBaseId,
  salesFlg,
}) => {
  const testid = createTestId(MonitorListTable);

  const CHANGE_TARGET = {
    CONTRACT: '契約',
    POSTING: '掲載',
  };

  return (
    <Table className="mb-4" data-testid={testid('table')}>
      <thead>
        <tr className="align-middle">
          <th>モニターベースID</th>
          <th>モニターID</th>
          <th>モニター名</th>
          <th>実店舗ID</th>
          <th>実店舗名</th>
          <th>クライアントID</th>
          <th>クライアント名</th>
          <th>表示ステータス</th>
          <th>期間</th>
          <th>基本情報</th>
          <th>プレビュー</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {listPerPage.map(
          ({
            monitorId,
            monitorBaseId,
            clientId,
            clientName,
            monitorName,
            period,
            postingRsvs,
            contractRsvs,
            contractShopName,
            shopId,
            shopName,
            status,
            previewUrl,
          }) => (
            <React.Fragment key={uuid()}>
              <tr key={uuid()}>
                <td>{monitorBaseId}</td>
                <td>{monitorId}</td>
                <td>{monitorName}</td>
                <td>{shopId}</td>
                <td>
                  {when(
                    !salesFlg,
                    <a href={`${Url.KEISAI.SHOP_CONFIRM}/${shopId}`} target="_blank" rel="noreferrer">
                      {shopName}
                    </a>
                  )}
                  {when(salesFlg, <>{shopName}</>)}
                </td>
                <td>{clientId}</td>
                <td>{clientName}</td>
                <td>{status}</td>
                <td>{period}</td>
                <td className="border-0 text-center">
                  <FontAwesomeIcon
                    icon={faListAlt}
                    fixedWidth
                    className="me-1"
                    style={{ fontSize: '1rem' }}
                    onClick={() => {
                      setMonitorBaseId(monitorBaseId);
                      setIsBasicInfoModal(true);
                    }}
                  />
                </td>
                <td className=" text-center">
                  {when(
                    !!previewUrl,
                    <a className="text-decoration-none text-dark" target="_blank" rel="noreferrer" href={previewUrl}>
                      <FontAwesomeIcon icon={faEye} fixedWidth style={{ fontSize: '1rem' }} />
                    </a>
                  )}
                </td>
                <td>
                  <Button variant="link" className="p-0" onClick={() => setModal(monitorBaseId)}>
                    ステータス予約状況
                  </Button>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td colSpan={10}>
                  <div className="px-2 mb-4">
                    <Table className="mb-0">
                      <thead>
                        <tr>
                          <th className="col-1">
                            掲載予約
                            <br />
                            予約反映日時
                          </th>
                          <th className="col-1">依頼ID</th>
                          <th className="col-2">事前アンケート</th>
                          <th className="col-2">事後アンケート</th>
                          <th className="col-2">モニタールールセット</th>
                          <th className="col-1">詳細表示</th>
                          <th className="col-1">プレビュー</th>
                        </tr>
                      </thead>
                      <tbody className="py-4">
                        {postingRsvs.map((mr) => (
                          <React.Fragment key={uuid()}>
                            <tr className="border-0">
                              <td className="border-0">{mr.applicationStartDate}</td>
                              <td className="border-0">{mr.postingRequestId}</td>
                              <td className="border-0">
                                {mr.preEnqueteId}:{mr.preEnqueteName}
                              </td>
                              <td className="border-0">
                                {mr.postEnqueteId}:{mr.postEnqueteName}
                              </td>
                              <td className="border-0">
                                {mr.ruleSetId}:{mr.ruleSetName}
                              </td>
                              <td className="border-0 text-center">
                                <FontAwesomeIcon
                                  icon={faListAlt}
                                  fixedWidth
                                  className="me-1"
                                  style={{ fontSize: '1rem' }}
                                  onClick={() => {
                                    localStorage.setItem(
                                      'key',
                                      JSON.stringify({
                                        clientName,
                                        postingRequestId: mr.postingRequestId,
                                        monitorBaseId,
                                        disableFlg: true,
                                        contractShopName,
                                      })
                                    );
                                    window.open(Url.KEISAI.MONITOR_BASE_MODIFY);
                                  }}
                                />
                              </td>
                              <td className="border-0 text-center">
                                {when(
                                  !!mr.previewUrl,
                                  <a
                                    className="text-decoration-none text-dark"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={mr.previewUrl}
                                  >
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      fixedWidth
                                      className="me-1"
                                      style={{ fontSize: '1rem' }}
                                    />
                                  </a>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </Table>
                    <Table className="mb-0">
                      <thead>
                        <tr>
                          <th className="col-1">
                            契約予約
                            <br />
                            予約反映日時
                          </th>
                          <th className="col-1">依頼ID</th>
                          <th className="col-2">店舗SPOT</th>
                          <th className="col-2">店舗STOCK</th>
                          <th className="col-2">&nbsp;</th>
                          <th className="col-1">詳細表示</th>
                          <th className="col-1">プレビュー</th>
                        </tr>
                      </thead>
                      <tbody className="py-4">
                        {contractRsvs.map((cr) => (
                          <React.Fragment key={uuid()}>
                            <tr className="border-0">
                              <td className="border-0">{cr.applicationStartDate}</td>
                              <td className="border-0">{cr.postingRequestId}</td>
                              <td className="border-0">{cr.storeSpotAmount}</td>
                              <td className="border-0">{cr.storeStockAmount}</td>
                              <td className="border-0">&nbsp;</td>
                              <td className="border-0 text-center">
                                <FontAwesomeIcon
                                  icon={faListAlt}
                                  fixedWidth
                                  className="me-1"
                                  style={{ fontSize: '1rem' }}
                                  onClick={() => {
                                    localStorage.setItem('key', JSON.stringify({ rsvId: cr.id, monitorName }));
                                    window.open(Url.KEISAI.CONTRACT_RESERVATION);
                                  }}
                                />
                              </td>
                              <td className="border-0 text-center">
                                {when(
                                  !!cr.previewUrl,
                                  <a
                                    className="text-decoration-none text-dark"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={cr.previewUrl}
                                  >
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      fixedWidth
                                      className="me-1"
                                      style={{ fontSize: '1rem' }}
                                    />
                                  </a>
                                )}
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </td>
              </tr>
            </React.Fragment>
          )
        )}
      </tbody>
    </Table>
  );
};
