import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
/* eslint-disable import/no-cycle */
import { getPresignedUrl, s3Upload, s3Delete, s3UploadAlignment } from '../../../utils/s3';
import {
  CampaignApi,
  RichInfoOutputResponse,
  RichUpdateFormResponse,
  ImageUpdateFormResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { IMAGE_TYPE_ID, OBJECT_TYPE, PARAM_TYPE } from '../../../Constants';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Alert } from '../../atoms/Alert';
import { Button } from '../../atoms/Button';
import { RichDragDropContext } from '../../organisms/RichDragDropContext';
import { PARAM_NAME, RequiredRichUpdateFormResponse, Image } from '../Brand/BrandRichPage';
/* eslint-enable import/no-cycle */

const sampleGetRes = {
  richShowFlg: true,
  richDesignPatternId: '2',
  designPatternList: [
    {
      setNo: '1',
      paramList: [
        { id: 1, name: '見出し', type: 1, value: '見出しa' },
        {
          id: 2,
          name: '画像',
          type: 8,
          value: 'http://fancrew.jp/api/3.0/image/brand/0000/4320/2117960-1641275727657.jpg',
          imageId: 100,
        },
        { id: 3, name: '文言', type: 1, value: '文言a' },
      ],
    },
    {
      setNo: '2',
      paramList: [
        { id: 4, name: '見出し', type: 1, value: '見出しb' },
        {
          id: 5,
          name: '画像',
          type: 8,
          value: 'http://fancrew.jp/api/3.0/image/brand/0000/4320/2117960-1641275727657.jpg',
          imageId: 200,
        },
        { id: 6, name: '文言', type: 1, value: '文言b' },
      ],
    },
  ],
};

export interface State {
  temporarySetNo: number; // セット追加時、setNoに他と重複しない仮の値をセットする
  updResult: IncResultOutputResponse;
  images: Image[];
}

const initialState = {
  temporarySetNo: 100,
  updResult: { result: false, errorCode: '', errorMessage: '' },
  images: [],
};

export const CampaignRichPage: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<{ id: number }>();
  const campaignApi = new CampaignApi();
  const { register, handleSubmit, control, reset, getValues, setValue } = useForm<RequiredRichUpdateFormResponse>();
  const { fields, append } = useFieldArray({ control, name: 'designPatternList' });
  const { state: $, mergeState, useBindSet } = useLargeState<State>(initialState);

  const campaignId = location.state?.id;

  useEffect(() => {
    if (!campaignId) return;
    campaignApi.campaignRichInfo(campaignId).then((res: AxiosResponse<RichInfoOutputResponse>) => {
      // const { data } = res;
      const data = sampleGetRes;
      reset(data);
      mergeState({
        images: [...Array(data.designPatternList.length)].map((_, i) => ({
          isDefault: true,
          id: data.designPatternList[i].paramList[findParamIndex(i, PARAM_NAME.IMAGE)]?.imageId,
          value: data.designPatternList[i].paramList[findParamIndex(i, PARAM_NAME.IMAGE)]?.value,
        })),
      });
    });
  }, []);

  const findParamIndex = (index: number, str: string): number => {
    const paramList = getValues(`designPatternList.${index}.paramList`);
    return paramList?.findIndex((item) => item.name === str);
  };

  const onSubmit: SubmitHandler<RichUpdateFormResponse> = async (data) => {
    const { designPatternList } = data;

    const updParam = {
      ...data,
      designPatternList: designPatternList?.map((item, idx) => {
        return {
          setNo: String(idx + 1),
          paramList: item.paramList.map((param) => {
            const { file, isDefault, id } = $.images[idx];

            if (param.name === PARAM_NAME.IMAGE) {
              const type = PARAM_TYPE.IMAGE;
              const value = '';
              const { name } = param;
              if (file) {
                if (!isDefault) return { type, value, name };
                return { type, value, name, id: param.id, imageId: id };
              }

              if (isDefault && !value) return { type, value, name, id: param.id };
            }
            return param;
          }),
        };
      }),
    };

    try {
      // eslint-disable-next-line no-restricted-syntax
      for await (const [index, image] of $.images.entries()) {
        const { isDefault, id: imageId, value, name, contentType, file, width, height, format } = image;

        if (file && name && contentType && width && height && format) {
          const presignedUrl = await getPresignedUrl({ contentType, fileKey: name, objectType: OBJECT_TYPE.CAMPAIGN });
          const uploadTime = await s3Upload(presignedUrl, contentType, file);

          const alignmentParam: ImageUpdateFormResponse = { width, height, format };
          if (isDefault) {
            alignmentParam.imageId = imageId;
          } else {
            alignmentParam.imageTypeId = IMAGE_TYPE_ID.CAMPAIGN_RICH;
            alignmentParam.path = presignedUrl.replace(/\?.*$/, '');
            alignmentParam.uploadTime = uploadTime;
          }

          await s3UploadAlignment(alignmentParam).then((res) => {
            if (res && !res.result) {
              mergeState({ updResult: res });
            }
          });
        } else if (isDefault && !value && imageId) {
          await s3Delete(imageId).then((res) => {
            if (res && !res.result) {
              mergeState({ updResult: res });
              return;
            }
            mergeState({
              images: $.images.map(({ id, ...rest }, i) =>
                index === i ? { ...rest, isDefault: false } : { ...rest, id }
              ),
            });
          });
        }
      }

      campaignApi.campaignRichUpdate(campaignId, updParam).then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      });
    } catch {
      history.push(Url.COMMON_ERROR);
    }
  };

  const handleOnAdd = () => {
    append({
      setNo: String($.temporarySetNo),
      paramList: [
        { name: PARAM_NAME.HEADING, type: PARAM_TYPE.STRING, value: '' },
        { name: PARAM_NAME.IMAGE, type: PARAM_TYPE.IMAGE, value: '' },
        { name: PARAM_NAME.WORDING, type: PARAM_TYPE.STRING, value: '' },
      ],
    });
    mergeState({ temporarySetNo: $.temporarySetNo + 1, images: [...$.images, { isDefault: false, value: '' }] });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.CAMPAIGN_RICH}</Title>
      {$.updResult?.result && <Alert variant="success">更新しました。</Alert>}
      {$.updResult?.errorCode && $.updResult?.errorMessage && (
        <Alert variant="danger">{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-end mb-4">
          <Button
            variant="link"
            className="ms-2"
            onClick={() => {
              history.goBack();
            }}
          >
            キャンセル
          </Button>
          <Button className="ms-2" type="submit">
            保存
          </Button>
        </div>

        <Form.Check id="richShowFlg" className="d-flex mb-4">
          <Form.Check.Input className="flex-shrink-0 me-2" type="checkbox" {...register('richShowFlg')} />
          <Form.Check.Label>リッチ化の内容を公開する</Form.Check.Label>
        </Form.Check>

        <Form.Group className="mb-4">
          <Form.Label>デザインパターン</Form.Label>
          <Form.Select defaultValue={1} {...register('richDesignPatternId')}>
            <option value={1}>パターン1</option>
            <option value={2}>パターン2</option>
            <option value={3}>パターン3</option>
          </Form.Select>
        </Form.Group>

        <RichDragDropContext
          fields={fields}
          register={register}
          getValues={getValues}
          setValue={setValue}
          findParamIndex={findParamIndex}
          images={$.images}
          setImages={useBindSet('images')}
        />

        <Button variant="link" className="text-secondary d-block ms-auto mb-4" onClick={() => handleOnAdd()}>
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          追加
        </Button>
      </Form>
    </>
  );
};
