import React, { useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useLargeState } from '../../../hooks/useLargeState';
import { Url } from '../../../constants/Url';
// eslint-disable-next-line import/no-cycle
import { ClientListApi, ClientListOutputResponse, ClientResponse, AllClientResponse } from '../../../api-client';
import { clientListApi } from '../../../test/mocks/api/ClientListApi';
import { clientList as sampleList } from '../../../test/mocks/constants/clientListApi';
import { Button } from '../../atoms/Button';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { RecommendSearchBar } from '../../molecules/RecommendSearchBar';
import { createTestId } from '../../../utils/functions';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';

export type State = {
  api: ClientListApi;
  // api: ReturnType<typeof clientListApi>;
  searchText: string;
  clientId?: number;
  clientName: string;
  allClientList: AllClientResponse[];
  list: ClientResponse[];
  listPerPage: ClientResponse[];
  currentPage: number;
  totalPage: number;
};

const MAX_RECORD_PER_PAGE = 100;

export const ClientPage: React.VFC = () => {
  const testid = createTestId(ClientPage);
  const history = useHistory();

  const {
    state: $,
    mergeState,
    onChangeSet,
  } = useLargeState<State>({
    api: new ClientListApi(),
    // api: clientListApi(),
    searchText: '',
    clientName: '',
    allClientList: [],
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  });

  const setPagingStates = useCallback(
    (list: ClientResponse[]) => {
      const sortList = list.sort((a, b) => b.clientId - a.clientId);
      mergeState({
        list: sortList,
        listPerPage: sortList.slice(0, MAX_RECORD_PER_PAGE),
        totalPage: Math.ceil(Number(sortList?.length) / MAX_RECORD_PER_PAGE),
        currentPage: 1,
      });
    },
    [mergeState]
  );

  useEffect(() => {
    $.api.clientList().then((res: AxiosResponse<ClientListOutputResponse>) => {
      const { clientList, allClientList } = res.data;
      mergeState({ allClientList });
      setPagingStates(clientList);
    });
  }, [$.api, mergeState, setPagingStates]);

  const onSearch = () => {
    $.api.clientList($.searchText).then((res: AxiosResponse<ClientListOutputResponse>) => {
      const { clientList } = res.data;
      setPagingStates(clientList);
    });
  };

  return (
    <>
      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.CLIENT}
      </Title>

      <RecommendSearchBar
        value={$.searchText}
        options={$.allClientList.map(({ clientName }) => clientName)}
        onSearch={onSearch}
        onChange={onChangeSet('searchText')}
        onClickItem={(e) => mergeState({ searchText: e.currentTarget.innerHTML })}
      />

      <Table data-testid={testid('table')}>
        <thead>
          <tr>
            <th>ID</th>
            <th>クライアント名</th>
            <th>営業担当者</th>
            <th>説明</th>
          </tr>
        </thead>
        <tbody>
          {$.listPerPage?.map(({ clientId, clientName, salesList, description }, i) => (
            <tr key={clientId} className="align-middle">
              <td>{clientId}</td>
              <td>
                <Link to={`${Url.KEISAI.CLIENT_DETAIL}/${clientId}`} target="_blank">
                  {clientName}
                </Link>
              </td>
              <td>{salesList.join(',')}</td>
              <td>{description}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="mt-4" data-testid={testid('pagination')}>
        <PaginationWithEllipsis
          currentPage={$.currentPage}
          totalPage={$.totalPage}
          handleClick={(page) => {
            if (!page || page > $.totalPage) return;
            mergeState({
              listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
              currentPage: page,
            });
          }}
        />
      </div>
    </>
  );
};
