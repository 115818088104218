import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import scss from '../../scss/atoms/tooltip.module.scss';

export const LinkWithTooltip: React.FC<{ id?: string; children: React.ReactNode; href: string; tooltip: string }> = ({
  id,
  children,
  href,
  tooltip,
}) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip className={scss.myTooltip} id={id}>
          {tooltip}
        </Tooltip>
      }
      placement="top"
      delay={{ show: 300, hide: 150 }}
    >
      <a href={href}>{children}</a>
    </OverlayTrigger>
  );
};
