import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { SubmitHandler, useFieldArray, useFormContext } from 'react-hook-form';
import { BillingUpdateResultModal } from '../Modal/BillingUpdateResultModal';
import { BILLING_DEFAULT_FADE_TIME } from '../../../BillingConstants';
import { BillingCommonRow } from '../../molecules/BillingCommonRow';
import { BillingCommonInputText } from '../../molecules/BillingCommonInputText';
import {
  BillingAgentMailUpdateFormResponse,
  BillingAgentOutputResponse,
  BillingAgentUpdateApi,
  BillingAgentUpdateFormResponse,
  CommonDtoResponse,
  IncResultOutputResponse,
  PaymentConditionOutputResponse,
  PaymentTermOutputResponse,
} from '../../../api-client';
import { BillingCommonIncAccountSuggest } from '../../molecules/BillingCommonIncAccountSuggest';
import { BillingCommonInputSelect } from '../../molecules/BillingCommonInputSelect';
import { BillingCommonCheckBox } from '../../molecules/BillingCommonCheckBox';
import { QuestionCircleTooltip } from '../../molecules/QuestionCircleTooltip';

export const BillingAgentDetailFrom = ({
  id,
  billingAgent,
  mailTypeMaster,
  paymentTermCriteriaMaster,
  paymentConditionMaster,
  paymentTermMaster,
  spotfeePaymentConditionMaster,
}: {
  id: string;
  billingAgent?: BillingAgentOutputResponse;
  mailTypeMaster: CommonDtoResponse[];
  paymentConditionMaster: PaymentConditionOutputResponse[];
  paymentTermCriteriaMaster: CommonDtoResponse[];
  paymentTermMaster: PaymentTermOutputResponse[];
  spotfeePaymentConditionMaster: CommonDtoResponse[];
}) => {
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const billingAgentUpdateApi = new BillingAgentUpdateApi();
  const { handleSubmit, control } = useFormContext<BillingAgentUpdateFormResponse>();
  const { fields } = useFieldArray({ control, name: 'billingAgentMail' });

  const onSubmit: SubmitHandler<BillingAgentUpdateFormResponse> = (data) => {
    (async () => {
      try {
        const res = await billingAgentUpdateApi.updateBillingAgent(data);
        setUpdResult(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  };

  return (
    <>
      <h3>代理店明細</h3>
      {updResult !== undefined && (
        <BillingUpdateResultModal
          fadeoutTime={BILLING_DEFAULT_FADE_TIME}
          updResult={updResult}
          setUpdResult={setUpdResult}
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <BillingCommonRow>
          <BillingCommonInputText<BillingAgentUpdateFormResponse> registerName="name" labelName="代理店名" required />
          {/* {(typeof getValues('incAccountId') === 'number' || id === 'new') && ( */}
          <BillingCommonIncAccountSuggest registerName="incAccountId" control={control} required />
          {/* )} */}
        </BillingCommonRow>
        <BillingCommonRow>
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="contractStartDate"
            labelName="契約開始月"
            type="month"
          />
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="salesforceId"
            labelName="SalesforceId"
          />
        </BillingCommonRow>
        <BillingCommonRow>
          <BillingCommonInputSelect<BillingAgentUpdateFormResponse>
            registerName="spotFeePaymentConditionCode"
            labelName="SPOTフィー発生条件"
            options={spotfeePaymentConditionMaster}
            required
          />
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="spotFee"
            labelName="デフォルトSPOTフィー[%]"
            type="number"
            required
          />
        </BillingCommonRow>
        <BillingCommonRow>
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="stockFee"
            labelName="デフォルトSTOCKフィー[%]"
            type="number"
            required
          />
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="paymentFee"
            labelName="デフォルト手数料フィー[%]"
            type="number"
            required
          />
        </BillingCommonRow>
        <BillingCommonRow>
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="rewardFee"
            labelName="デフォルト謝礼フィー[%]"
            type="number"
            required
          />
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="imageFee"
            labelName="デフォルト画像フィー[%]"
            type="number"
            required
          />
        </BillingCommonRow>
        <BillingCommonRow>
          <BillingCommonInputSelect<BillingAgentUpdateFormResponse>
            registerName="paymentTermCriteriaCode"
            labelName="支払期限基準"
            options={paymentTermCriteriaMaster}
            required
          />
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="paymentLimit"
            labelName="支払い期限(カ月)"
            required
            type="number"
          >
            <QuestionCircleTooltip tooltip="0の場合、無期限に支払いが発生します。" />
          </BillingCommonInputText>
        </BillingCommonRow>
        <BillingCommonRow>
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="accountsPayableCode"
            labelName="買掛コード"
          />
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="subsidiaryAccountCode"
            labelName="補助コード"
          />
        </BillingCommonRow>
        <BillingCommonRow>
          <BillingCommonInputSelect<BillingAgentUpdateFormResponse>
            registerName="paymentTermId"
            labelName="支払サイト"
            options={paymentTermMaster}
            required
          />
          <BillingCommonInputText<BillingAgentUpdateFormResponse>
            registerName="invoiceNumber"
            labelName="インボイス発行番号"
          />
        </BillingCommonRow>
        <BillingCommonRow>
          <BillingCommonInputSelect<BillingAgentUpdateFormResponse>
            registerName="paymentConditionId"
            labelName="お支払方法"
            options={paymentConditionMaster}
            required
          />
          <BillingCommonInputText<BillingAgentUpdateFormResponse> registerName="payAccountNo" labelName="支払先口座" />
        </BillingCommonRow>
        <BillingCommonRow>
          <BillingCommonCheckBox<BillingAgentUpdateFormResponse>
            registerName="sendmailFlg"
            labelName="メール送信フラグ"
          />
          <BillingCommonCheckBox<BillingAgentUpdateFormResponse> registerName="disableFlg" labelName="無効フラグ" />
        </BillingCommonRow>
        {fields.map((field: BillingAgentMailUpdateFormResponse & { id: string }, index) => {
          return (
            <Row key={field.id}>
              <BillingCommonInputText<BillingAgentUpdateFormResponse>
                registerName={`billingAgentMail.${index}.mailaddress`}
                labelName="メールアドレス"
                type="email"
              />
              <BillingCommonInputText<BillingAgentUpdateFormResponse>
                registerName={`billingAgentMail.${index}.status`}
                labelName="ステータス"
                readOnly
              />
            </Row>
          );
        })}
        <div className="d-flex justify-content-center m-2">
          <Button variant="secondary" type="submit">
            {id === 'new' ? '作成' : '更新'}
          </Button>
        </div>
      </Form>
    </>
  );
};
