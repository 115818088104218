import React, { useCallback, useRef } from 'react';

// TODO: [添削] テスト未実装
/**
 * 値が変更されると再描画されずにコールバックが走る変数を定義する
 * ```tsx
 * const [dataRef, setData] = useSignal<{value: number}>(
 *   // 初期値
 *   {value: 0},
 *   // 値が変更された時のコールバック関数
 *   useCallback((current) => {
 *     console.log("値が変更された:", current);
 *   }, []),
 *   // 省略可。最初・再描画時に上記関数を実行するか
 *   false,
 * );
 * <button onClick={() => {
 *   setData(Math.random());
 * }}>設定</button>
 * ```
 */
export function useSignal<T>(
  defaultValue: T | null,
  onChange: (value: T | null) => void = () => {},
  initialExcute?: boolean
): [React.RefObject<T>, (value: (T | null) | ((prev: T | null) => T | null)) => void] {
  type NullableT = T | null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ref = new Proxy(useRef<NullableT>(defaultValue), {
    set(obj, prop, value, ...rest) {
      onChange(value);
      return Reflect.set(obj, prop, value, ...rest);
    },
  });
  const set = useCallback(
    (value: NullableT | ((prev: NullableT) => NullableT)) => {
      ref.current = value instanceof Function ? value(ref.current) : value;
      onChange(ref.current as NullableT);
    },
    [ref, onChange]
  );
  if (initialExcute) set(defaultValue as T);
  return [ref, set];
}
