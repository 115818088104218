import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import {
  DataInputWCheckApplyDistributionOutputResponse,
  IncResultOutputResponse,
  ShoppingApi,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';

export const DataInputWCheckDstributePage: React.FC = () => {
  const { monitorId } = useParams<{ monitorId: string | undefined }>();
  const history = useHistory();
  const api = new ShoppingApi();
  const [isLoading] = useState<boolean>(true);

  useEffect(() => {
    if (monitorId === undefined) {
      // eslint-disable-next-line no-alert
      window.alert('モニターIDをURLで指定してください');
      history.push(Url.MY);
    }
    api
      .dataInputWCheckApplyDistribution(Number(monitorId))
      .then((res: AxiosResponse<DataInputWCheckApplyDistributionOutputResponse>) => {
        const { applyId } = res.data;
        console.log(applyId);
        if (applyId != null) return history.push(`${Url.TENSAKU.CORRECT_DATA_WCHECK}/${applyId}`);

        // eslint-disable-next-line no-alert
        window.alert('添削対象物がありません。お疲れ様でした。');
        history.push(Url.MY);
      })
      .catch((error: AxiosError<IncResultOutputResponse>) => {
        // eslint-disable-next-line no-alert
        window.alert(error.response?.data.errorMessage);
        history.push(Url.MY);
      });
  }, []);

  return <LoadingSpinner isLoading={isLoading} />;
};
