/* eslint-disable import/no-cycle */
import { AxiosResponse } from 'axios';
import { FC, useCallback, useEffect } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useParams } from 'react-router-dom';
import {
  ApplyHistoryListDisplayFormResponse,
  ApplyHistoryListDisplayOutputResponse,
  ApplyStatusListOutputResponse,
  CustomerInfoApi,
  CustomerInformationDetailDisplayOutputResponse,
  OemScodeCreateApi,
  PaidServicePointReturnApi,
  PaidServiceTypeListOutputResponse,
  SubmissionStatusListOutputResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, isOemUser } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { ApplyHistoryListForms } from '../../organisms/Forms/ApplyHistoryListForms';
import { ApplyHistoryListTable } from '../../organisms/Table/ApplyHistoryListTable';
import { CustomerInfoHeader } from '../Customer/CustomerInfoHeader';
import { OEM_API_VERSION } from '../../../Constants';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useTensakuOem } from '../../../hooks/useTensakuOem';
import { tensakuOem } from '../../../states/tensakuOem';

interface FormsState {
  applyId?: number;
  applyStatusId?: number;
  enqueteStatusId?: number;
  paidServiceCode?: number;
  shopNameMonitorName?: string;
  surveyProofStatusId?: number;
  winningAtEnd?: string;
  winningAtStart?: string;
}
const initialForms: FormsState = {
  applyId: undefined,
  applyStatusId: undefined,
  enqueteStatusId: undefined,
  paidServiceCode: undefined,
  shopNameMonitorName: undefined,
  surveyProofStatusId: undefined,
  winningAtEnd: undefined,
  winningAtStart: undefined,
};

export type State = {
  api: CustomerInfoApi;
  paidServicePointReturnApi: PaidServicePointReturnApi;
  forms: FormsState;
  applyStatusList: ApplyStatusListOutputResponse[];
  submissionStatusList: SubmissionStatusListOutputResponse[];
  paidServiceTypeList: PaidServiceTypeListOutputResponse[];
  list: ApplyHistoryListDisplayOutputResponse[];
  listPerPage: ApplyHistoryListDisplayOutputResponse[];
  currentPage: number;
  totalPage: number;
  oemScodeCreateApi: OemScodeCreateApi;
  sCode?: string;
};

const MAX_RECORD_PER_PAGE = 100;

export const ApplyHistoryListPage: FC = () => {
  const testid = createTestId(ApplyHistoryListPage);
  const { id } = useParams<{ id: string | undefined }>();
  const [tensakuOemState, setTensakuOemState] = useRecoilState(tensakuOem);
  const resetTensakuOem = useResetRecoilState(tensakuOem);

  const initialState = {
    api: new CustomerInfoApi(),
    paidServicePointReturnApi: new PaidServicePointReturnApi(),
    forms: initialForms,
    applyStatusList: [],
    submissionStatusList: [],
    paidServiceTypeList: [],
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
    oemScodeCreateApi: new OemScodeCreateApi(),
    sCode: undefined,
  };

  const { state: $, mergeState, useBindSet } = useLargeState<State>(initialState);

  const setPagingStates = useCallback(
    (list: ApplyHistoryListDisplayOutputResponse[]) => {
      const modifyList = list.sort((a, b) => b.applyId - a.applyId);

      mergeState({
        list: modifyList,
        listPerPage: modifyList.slice(0, MAX_RECORD_PER_PAGE),
        totalPage: Math.ceil(Number(modifyList?.length) / MAX_RECORD_PER_PAGE),
        currentPage: 1,
      });
    },
    [mergeState]
  );

  useEffect(() => {
    const form = {} as ApplyHistoryListDisplayFormResponse;
    $.api
      .applyHistoryListDisplay(Number(id), form)
      .then((res: AxiosResponse<ApplyHistoryListDisplayOutputResponse[]>) => {
        setPagingStates(res.data);
      });

    $.api.applyStatusList().then((res: AxiosResponse<ApplyStatusListOutputResponse[]>) => {
      mergeState({ applyStatusList: res.data });
    });

    $.api.submissionStatusList().then((res: AxiosResponse<SubmissionStatusListOutputResponse[]>) => {
      mergeState({ submissionStatusList: res.data });
    });

    $.paidServicePointReturnApi
      .paidServiceTypeList()
      .then((res: AxiosResponse<PaidServiceTypeListOutputResponse[]>) => {
        mergeState({ paidServiceTypeList: res.data });
      });

    const apiClientId = tensakuOemState?.apiClientId;
    if (apiClientId === undefined) {
      // URL直でこのページに来た場合。この辺の取り回しは要修正
      $.api
        .customerInformationDetailDisplay(Number(id))
        .then((res: AxiosResponse<CustomerInformationDetailDisplayOutputResponse>) => {
          resetTensakuOem();
          setTensakuOemState({ customerId: Number(id), apiClientId: res.data.oemId ?? 1 });
          return res.data.oemId;
        })
        .then((res2) => {
          $.oemScodeCreateApi.incOemScodeCreate(OEM_API_VERSION, Number(id), res2 ?? 1).then((res) => {
            mergeState({ sCode: res.data.scode });
          });
        });
    } else if (isOemUser(apiClientId)) {
      $.oemScodeCreateApi.incOemScodeCreate(OEM_API_VERSION, Number(id), apiClientId).then((res) => {
        mergeState({ sCode: res.data.scode });
      });
    }
  }, [$.api, $.paidServicePointReturnApi, mergeState, setPagingStates]);

  const onSearch = () => {
    const form = {
      applyId: $.forms.applyId,
      applyStatusId: $.forms.applyStatusId,
      enqueteStatusId: $.forms.enqueteStatusId,
      paidServiceCode: $.forms.paidServiceCode,
      shopNameMonitorName: $.forms.shopNameMonitorName,
      surveyProofStatusId: $.forms.surveyProofStatusId,
      winningAtEnd: $.forms.winningAtEnd,
      winningAtStart: $.forms.winningAtStart,
    } as ApplyHistoryListDisplayFormResponse;
    $.api
      .applyHistoryListDisplay(Number(id), form)
      .then((res: AxiosResponse<ApplyHistoryListDisplayOutputResponse[]>) => {
        setPagingStates(res.data);
      });
  };

  const setForms = useBindSet('forms');

  return (
    <>
      <CustomerInfoHeader activeKey="applicationHistory" customerId={Number(id)} />
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="1">
          <Accordion.Header>絞り込み</Accordion.Header>
          <Accordion.Body>
            <ApplyHistoryListForms
              setForms={setForms}
              applyStatusList={$.applyStatusList}
              submissionStatusList={$.submissionStatusList}
              paidServiceTypeList={$.paidServiceTypeList}
            />
            <Row className="d-flex justify-content-center">
              <Col className="col-4">
                <Button data-testid={testid('search-button')} className="w-100 py-3" onClick={onSearch}>
                  検索
                </Button>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr />
      <ApplyHistoryListTable listPerPage={$.listPerPage} resultNum={100} customerId={Number(id)} sCode={$.sCode} />
    </>
  );
};
