/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { QuestionCategoryListOutputResponse } from '../domains';
/**
 * QuestionCategoryListApi - axios parameter creator
 * @export
 */
export const QuestionCategoryListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 設問カテゴリ一覧を取得する
         * @summary 設問カテゴリ一覧取得
         * @param {Array<number>} [clientIds] 
         * @param {boolean} [masterFlg] 
         * @param {boolean} [displayWithParentsCategoryFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCategoryList: async (clientIds?: Array<number>, masterFlg?: boolean, displayWithParentsCategoryFlg?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/question-category-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientIds) {
                localVarQueryParameter['clientIds'] = clientIds;
            }

            if (masterFlg !== undefined) {
                localVarQueryParameter['masterFlg'] = masterFlg;
            }

            if (displayWithParentsCategoryFlg !== undefined) {
                localVarQueryParameter['displayWithParentsCategoryFlg'] = displayWithParentsCategoryFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionCategoryListApi - functional programming interface
 * @export
 */
export const QuestionCategoryListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionCategoryListApiAxiosParamCreator(configuration)
    return {
        /**
         * 設問カテゴリ一覧を取得する
         * @summary 設問カテゴリ一覧取得
         * @param {Array<number>} [clientIds] 
         * @param {boolean} [masterFlg] 
         * @param {boolean} [displayWithParentsCategoryFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionCategoryList(clientIds?: Array<number>, masterFlg?: boolean, displayWithParentsCategoryFlg?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<QuestionCategoryListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionCategoryList(clientIds, masterFlg, displayWithParentsCategoryFlg, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionCategoryListApi - factory interface
 * @export
 */
export const QuestionCategoryListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionCategoryListApiFp(configuration)
    return {
        /**
         * 設問カテゴリ一覧を取得する
         * @summary 設問カテゴリ一覧取得
         * @param {Array<number>} [clientIds] 
         * @param {boolean} [masterFlg] 
         * @param {boolean} [displayWithParentsCategoryFlg] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCategoryList(clientIds?: Array<number>, masterFlg?: boolean, displayWithParentsCategoryFlg?: boolean, options?: any): AxiosPromise<Array<QuestionCategoryListOutputResponse>> {
            return localVarFp.questionCategoryList(clientIds, masterFlg, displayWithParentsCategoryFlg, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionCategoryListApi - object-oriented interface
 * @export
 * @class QuestionCategoryListApi
 * @extends {BaseAPI}
 */
export class QuestionCategoryListApi extends BaseAPI {
    /**
     * 設問カテゴリ一覧を取得する
     * @summary 設問カテゴリ一覧取得
     * @param {Array<number>} [clientIds] 
     * @param {boolean} [masterFlg] 
     * @param {boolean} [displayWithParentsCategoryFlg] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionCategoryListApi
     */
    public questionCategoryList(clientIds?: Array<number>, masterFlg?: boolean, displayWithParentsCategoryFlg?: boolean, options?: AxiosRequestConfig) {
        return QuestionCategoryListApiFp(this.configuration).questionCategoryList(clientIds, masterFlg, displayWithParentsCategoryFlg, options).then((request) => request(this.axios, this.basePath));
    }
}
