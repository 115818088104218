import React, { useEffect, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { format, subDays } from 'date-fns';
import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import { BannerInsiteListApi } from '../../../api-client';
import type { BannerInsiteListOutputResponse, BannerInsiteListOutputListItemResponse } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { BannerInsiteTable } from '../../organisms/Table/BannerInsiteTable';
import type { BannerType } from './BannerListPage';

const PERIOD_OPTIONS = [
  { key: 1, name: '過去7日間' },
  { key: 2, name: '過去14日間' },
  { key: 3, name: '過去30日間' },
  { key: 4, name: 'カスタマイズ' },
] as const;

const PERIOD_TYPE_CUSTOM = 4;
const MAX_RECORD_PER_PAGE = 100;

export const BannerInsitePage: React.VFC = () => {
  const history = useHistory();
  const { state: bannerType } = useLocation<BannerType>();

  const today = format(new Date(), 'yyyy-MM-dd');
  const oneWeekBefore = format(subDays(new Date(), 7), 'yyyy-MM-dd');

  const {
    state: $,
    mergeState,
    onChangeSet,
  } = useLargeState<{
    api: BannerInsiteListApi;
    periodType: number;
    startAt: string;
    endAt: string;
    list: BannerInsiteListOutputListItemResponse[];
    listPerPage: BannerInsiteListOutputListItemResponse[];
    currentPage: number;
    totalPage: number;
  }>({
    api: new BannerInsiteListApi(),
    periodType: 1,
    startAt: oneWeekBefore,
    endAt: today,
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  });

  const setPagingStates = useCallback(
    (list: BannerInsiteListOutputListItemResponse[]) => {
      const sortedList = list.sort((a, b) => b.id - a.id);
      mergeState({
        list: sortedList,
        listPerPage: sortedList.slice(0, MAX_RECORD_PER_PAGE),
        totalPage: Math.ceil(Number(sortedList?.length) / MAX_RECORD_PER_PAGE),
        currentPage: 1,
      });
    },
    [mergeState]
  );

  useEffect(() => {
    if (!bannerType?.id) return;
    $.api
      .bannerInsiteList(
        bannerType?.id,
        $.periodType,
        $.periodType === PERIOD_TYPE_CUSTOM ? $.startAt : undefined,
        $.periodType === PERIOD_TYPE_CUSTOM ? $.endAt : undefined
      )
      .then((res: AxiosResponse<BannerInsiteListOutputResponse>) => {
        setPagingStates(res.data.list);
      });
  }, [bannerType?.id, $.api, $.periodType, $.startAt, $.endAt, setPagingStates]);

  return (
    <>
      <Title className="mb-4">{`${bannerType.name}${TITLE.KEISAI.BANNER_INSITE}`}</Title>

      <div className="d-flex justify-content-end mb-4">
        <Button variant="link" className="ms-2" onClick={() => history.goBack()}>
          キャンセル
        </Button>
      </div>

      <Form.Select
        value={$.periodType}
        onChange={(e) => mergeState({ periodType: Number(e.target.value) })}
        className="w-auto mb-4"
      >
        {PERIOD_OPTIONS.map(({ key, name }) => (
          <option key={key} value={key}>
            {name}
          </option>
        ))}
      </Form.Select>

      <Form.Group className="d-flex align-items-center gap-2 mb-4">
        <Form.Control
          type="date"
          value={$.startAt}
          onChange={onChangeSet('startAt')}
          disabled={$.periodType !== PERIOD_TYPE_CUSTOM}
          className="w-auto"
        />
        <span>~</span>
        <Form.Control
          type="date"
          value={$.endAt}
          onChange={onChangeSet('endAt')}
          disabled={$.periodType !== PERIOD_TYPE_CUSTOM}
          className="w-auto"
        />
      </Form.Group>

      <BannerInsiteTable listPerPage={$.listPerPage} />

      <div className="mb-4">
        <PaginationWithEllipsis
          currentPage={$.currentPage}
          totalPage={$.totalPage}
          handleClick={(page) => {
            if (!page || page > $.totalPage) return;
            mergeState({
              listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
              currentPage: page,
            });
          }}
        />
      </div>
    </>
  );
};
