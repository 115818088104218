import * as React from 'react';
import { useEffect, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { sqlQuerySchema } from '../../../constants/YupSchema';
import {
  SqlParamInfoOutputResponse,
  SqlQueryApi,
  SqlQueryInfoOutputResponse,
  SqlQueryUpdateFormResponse,
} from '../../../api-client';
import { SubmitPrimaryButton } from '../../atoms/Button/SubmitPrimaryButton';
import { SqlQueryInputTable } from '../../organisms/Table/SqlQueryInputTable';
import { Url } from '../../../constants/Url';

export type UpdateForm = SqlQueryUpdateFormResponse & {
  queryId: number;
  updateAt?: string;
  updateBy: string;
  resultFilePath?: string;
};

export const SqlQueryUpdatePage = () => {
  const [updateDatas, setUpdateDatas] = useState<UpdateForm>();
  const history = useHistory();

  const { id } = useParams<{ id: string | '0' }>();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    getValues,
  } = useForm<UpdateForm>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    shouldFocusError: false,
    resolver: yupResolver(sqlQuerySchema),
  });

  const {
    fields: listFields,
    append: listAppend,
    remove: listRemove,
  } = useFieldArray({
    control,
    name: 'parameterList',
  });

  const {
    fields: addressFields,
    append: addressAppend,
    remove: addressRemove,
  } = useFieldArray({
    control,
    name: 'mailaddressList',
  });

  useEffect(() => {
    const sqlQueryApi = new SqlQueryApi();
    sqlQueryApi.sqlQueryInfo(Number(id)).then((response: AxiosResponse<SqlQueryInfoOutputResponse>) => {
      const updateForm: UpdateForm = {
        ...response.data,
        parameterList: response.data.parameterList.map((param: SqlParamInfoOutputResponse) => {
          return {
            parameterDisplayName: param.parameterDisplayName,
            parameterName: param.parameterName,
            parameterType: param.parameterType,
            parameterValue: `${param.parameterValue}`,
            parameterId: param.parameterId,
          };
        }),
      };
      setUpdateDatas(updateForm);
      const obj: UpdateForm = updateForm;
      reset({
        queryId: obj.queryId,
        queryName: obj.queryName,
        sqlString: obj.sqlString,
        execType: obj.execType,
        executeTimestamp: obj.executeTimestamp,
        repeatType: obj.repeatType,
        repeatTime: obj.repeatTime,
        validFlag: obj.validFlag,
        mailaddressList: obj.mailaddressList,
        superiorApprovalUrl: obj.superiorApprovalUrl,
        skipFlag: obj.skipFlag,
        parameterList: obj.parameterList,
        personalInformationProtectionFlag: obj.personalInformationProtectionFlag,
        updateBy: '',
      });
    });
  }, []);
  const onSubmit: SubmitHandler<UpdateForm> = (data: UpdateForm) => {
    history.push({
      pathname: Url.KEISAI.SQL_QUERY_UPDATE_CONFIRM,
      state: data,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SqlQueryInputTable
          tableTitle="SQLクエリページ編集"
          sqlUpdatas={updateDatas}
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
          listFields={listFields}
          listAppend={listAppend}
          listRemove={listRemove}
          addressFields={addressFields}
          addressAppend={addressAppend}
          addressRemove={addressRemove}
        />
        <SubmitPrimaryButton>確認</SubmitPrimaryButton>
      </Form>
    </>
  );
};
