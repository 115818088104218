import React from 'react';

import scss from '../../scss/atoms/environment.module.scss';

export const Environment: React.FC = () => {
  if (document.domain === 'localhost') {
    return <div className={scss.local}>ローカル環境</div>;
  }
  if (document.domain === 'inc.dev.fancrew.com') {
    return <div className={scss.dev}>結合環境（dev環境）</div>;
  }
  if (document.domain === 'inc.fancrew.jp') {
    return <div className={scss.prod}>本番環境</div>;
  }
  return <div className={scss.unknown}>不明 ({document.domain})</div>;
};
