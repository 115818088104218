import React, { useMemo, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { createTestId } from '../../../utils/functions';
import type { PaidServicePointReturnRegisterState } from '../../pages/PaidServicePointReturn/PaidServicePointReturnRegisterPage';

interface Props {
  list: PaidServicePointReturnRegisterState['list'];
}
export type TestIds = 'table';

export const PaidServicePointReturnRegisterTable: React.FC<Props> = ({ list }) => {
  const testId = createTestId<TestIds>(PaidServicePointReturnRegisterTable);
  const tableData: {
    label: string;
    name: keyof PaidServicePointReturnRegisterState['list'][0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parser?: (x?: any) => any;
  }[] = useMemo(() => {
    return [
      { label: '会員ID', name: 'customerId', parser: String },
      { label: '応募ID', name: 'applyId', parser: Number },
      { label: '応募ステータス', name: 'applyStatus', parser: String },
      { label: '店舗ID', name: 'shopId', parser: Number },
      { label: '店舗名', name: 'shopName', parser: String },
      { label: 'モニターベースID', name: 'monitorBaseId', parser: Number },
      { label: 'モニターベース名', name: 'monitorBaseName', parser: String },
      { label: 'ポイント返還理由', name: 'pointReturnReason', parser: String },
      { label: '対象サービス', name: 'targetService', parser: String },
    ];
  }, []);

  const createDisplayName = useCallback(
    (
      item: PaidServicePointReturnRegisterState['list'][0],
      labelName: keyof PaidServicePointReturnRegisterState['list'][0],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parser?: (x?: any) => any
    ) => {
      const objLabel = ['targetService'];
      if (!objLabel.includes(labelName)) {
        return parser ? parser(item[labelName]) : item[labelName];
      }

      if (labelName === 'targetService') {
        return item.targetService.name;
      }

      return '';
    },
    []
  );

  return (
    <Table data-testid={testId('table')}>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label)].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <tr className="align-middle" key={item.applyId}>
            {tableData.map(({ name, parser }) => (
              <td key={name}>{createDisplayName(item, name, parser)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
