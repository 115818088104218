import React, { useCallback } from 'react';
import { ModalDialog } from 'react-bootstrap';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { useSignal } from '../../../../hooks/useSignal';
import { CorrectionContext } from '../../../../store/correctionStore';
import { Modal } from '../../../molecules/Modal';

const CustomDialog: React.FC = (props) => {
  const [ref] = useSignal<HTMLDivElement>(
    null,
    useCallback((current) => {
      if (!current) return;
      // 親ノードを取得
      const parent = current?.parentNode as HTMLDivElement;
      // モーダルの左余白を除去
      const { paddingLeft } = parent.style;
      if (paddingLeft) {
        parent.style.paddingLeft = '0px';
      }
      // 幅によってモーダル幅を調整
      if (parent.clientWidth < 1835) {
        current.classList.remove('modal-lg');
      } else {
        current.classList.add('modal-lg');
      }
    }, [])
  );
  return (
    <ModalDialog
      {...props}
      ref={ref}
      style={
        // モーダルの中央寄せを解除
        { marginLeft: 'unset' }
      }
    />
  );
};

// TODO: [添削] テスト未実装
export const CorrectionModalBase: React.FC<{
  title: string;
  isModal: boolean;
}> = ({ title, isModal, children }) => {
  const {
    largeState: { mergeState },
  } = useSafeContext(CorrectionContext);
  return (
    <Modal
      isModal={!!isModal}
      onHide={() =>
        mergeState({
          isNoteModal: false,
          isResultModal: false,
          isNGHistoryModal: false,
          isEnqueteModal: undefined,
          isReceiptInfoModal: undefined,
          dpForNGHistoryModal: undefined,
          titleForNGHistoryModal: undefined,
          isOkReleaseModal: undefined,
        })
      }
      title={title}
      scrollable
      centered
      closeButton
      size="lg" // refで動的変更される
      dialogAs={() => CustomDialog}
      body={<>{children}</>}
    />
  );
};
