import React from 'react';
import { Row, Col } from 'react-bootstrap';

export const FooterLayout = () => {
  return (
    <>
      <Row className="border-top">
        <Col className="d-flex align-items-center px-4 py-2">
          <p className="text-secondary m-0" style={{ fontSize: '0.75rem' }}>
            © Fancrew Inc.
          </p>
        </Col>
      </Row>
    </>
  );
};
