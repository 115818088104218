import { Table } from 'react-bootstrap';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { LinkButton } from '../../atoms/Button/LinkButton';
import { pageTransition } from '../../../utils/pageTransition';
import { SqlTemplateListOutputResponse } from '../../../api-client/domains';

export const SqlTemplateTable = (props: any) => {
  const { cellTitle, tableData } = props;
  const history = useHistory();
  return (
    <Table>
      <thead>
        <tr>
          {cellTitle?.map((title: string) => (
            <th key={title}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData?.map((data: SqlTemplateListOutputResponse) => {
          return (
            <tr key={data.templateId}>
              <th>{data.templateId}</th>
              <th>
                <LinkButton
                  text={data.templateName}
                  onClick={() => pageTransition({ type: 'SQL_TMP_INFO' }, data, history)}
                />
              </th>
              <th>{data.lastUpdater}</th>
              <th>{data.createTime}</th>
              <th>{data.updateTime}</th>
              <th>
                <LinkButton text="クエリ生成" onClick={() => pageTransition({ type: 'SQL_TMP_INFO' }, data, history)} />
                <LinkButton text="コピー" onClick={() => pageTransition({ type: 'SQL_TMP_COPY' }, data, history)} />
                <LinkButton text="編集" onClick={() => pageTransition({ type: 'SQL_TMP_UPDATE' }, data, history)} />
                <LinkButton text="削除" onClick={() => pageTransition({ type: 'SQL_TMP_DELETE' }, data, history)} />
              </th>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
