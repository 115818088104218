import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  BillingAgentApi,
  BillingSuggestListOutputResponse,
  BillingAgentMonitorBaseOutputResponse,
  BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse,
  BillingAgentMonitorBaseCreateRelationCheckedItemFormCheckedItemResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import useBillingSuggestClient from '../../../hooks/useBillingSuggestClient';
import useBillingSuggestContractShop from '../../../hooks/useBillingSuggestContractShop';
import { BillingUpdateResultModal } from '../../organisms/Modal/BillingUpdateResultModal';
import { BILLING_DEFAULT_FADE_TIME } from '../../../BillingConstants';

interface Props {
  id: number;
  clientId: number;
  billingSuggestClientResult: { isSuccess: boolean; data: BillingSuggestListOutputResponse[] | undefined };
  billingSuggestIncAccountResult: { isSuccess: boolean; data: BillingSuggestListOutputResponse[] | undefined };
}

interface SearchParams {
  clientId?: number;
  contractShopId?: number;
  monitorBaseName?: string;
}
export const BillingAgentMonitorBaseRelationPage: React.VFC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const billingAgentId = id ? Number(id) : 0;
  const billingAgentApi = new BillingAgentApi();
  const [billingAgentInfo, setBillingAgentInfo] = useState<BillingAgentMonitorBaseOutputResponse>({
    billingAgentMonitorBaseHeaderOutput: {
      id: 0,
      imageFeeDefault: 0,
      incAccountId: 0,
      incAccountName: '',
      name: '',
      paymentFeeDefault: 0,
      spotFeeDefault: 0,
      stockFeeDefault: 0,
    },
    billingAgentMonitorBaseList: [],
  });
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();

  useEffect(() => {
    billingAgentApi.billingAgentMonitorBaseList(billingAgentId).then((res) => {
      setBillingAgentInfo(res.data);
    });
  }, []);
  const billingSuggestClientResult = useBillingSuggestClient();
  const billingSuggestContractShopResult = useBillingSuggestContractShop();

  const onSubmit: SubmitHandler<SearchParams> = (data) => {
    billingAgentApi
      .billingAgentMonitorBaseList(billingAgentId, data.clientId, data.contractShopId, data.monitorBaseName)
      .then((res) => {
        setBillingAgentInfo(res.data);
      });
  };

  /**
   * 紐づけ解除
   * @param monitorBaseId
   */
  const handleClickRemoveRelation = (monitorBaseId: number) => {
    billingAgentApi
      .billingagentMonitorBaseRemoveRelation([
        {
          billingAgentId,
          monitorBaseId,
        },
      ])
      .then((res) => {
        setUpdResult(res.data);
      });
  };
  /**
   * 紐づけ全削除
   * @param billingAgentId_
   */
  const handleClickRemoveRelationAll = () => {
    billingAgentApi.billingAgentMonitorBaseRemoveRelationAll({ billingAgentId }).then((res) => {
      setUpdResult(res.data);
    });
  };

  /**
   * チェックがついてるアイテムについて代理店との紐付きを作成する
   */
  const createRelationCheckedItem = () => {
    const checkedList: BillingAgentMonitorBaseCreateRelationCheckedItemFormCheckedItemResponse[] = [];
    billingAgentInfo.billingAgentMonitorBaseList
      .filter((v) => v.related)
      .map((v) => {
        checkedList.push({
          monitorBaseId: v.monitorBaseId,
          spotFee: v.spotFee,
          stockFee: v.stockFee,
          imageFee: v.imageFee,
          paymentFee: v.paymentFee,
        } as BillingAgentMonitorBaseCreateRelationCheckedItemFormCheckedItemResponse);
      });
    const data: BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse = {
      id: billingAgentId,
      checkedMonitorBaseInfoList: checkedList,
    };
    billingAgentApi.billingagentMonitorBaseCreateRelationCheckedItem(data).then((res) => {
      setUpdResult(res.data);
    });
  };

  /**
   * 検索結果を紐づけ
   * @param searchParams
   */
  const createRelationSearchResult = () => {
    billingAgentApi
      .billingAgentMonitorBaseCreateRelationSearchResult({
        billingAgentId: billingAgentId,
        clientId: getValues('clientId'),
        contractShopId: getValues('contractShopId'),
        monitorBaseName: getValues('monitorBaseName'),
      })
      .then((res) => {
        setUpdResult(res.data);
      });
  };
  const {
    register,
    handleSubmit,
    // formState: { errors },
    setValue,
    getValues,
  } = useForm<SearchParams>();

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h3>紐付け先</h3>
              <Row className="g-2 mb-4">
                <Col>
                  <Form.Group>
                    <Row>
                      <Col className="col-4">
                        <Form.Label className="pt-2">代理店</Form.Label>
                      </Col>
                      <Col className="col-8">
                        <Form.Control
                          readOnly
                          type="text"
                          name="client"
                          value={`${billingAgentInfo?.billingAgentMonitorBaseHeaderOutput.id}:${billingAgentInfo?.billingAgentMonitorBaseHeaderOutput.name}`}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Row>
                      <Col className="col-4">
                        <Form.Label className="pt-2">担当者名</Form.Label>
                      </Col>
                      <Col className="col-8">
                        <Form.Control
                          readOnly
                          type="text"
                          name="incAccount"
                          value={`${billingAgentInfo?.billingAgentMonitorBaseHeaderOutput.incAccountId}:${billingAgentInfo?.billingAgentMonitorBaseHeaderOutput.incAccountName}`}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Form.Control
            hidden
            id="spotFeeDefault"
            value={billingAgentInfo?.billingAgentMonitorBaseHeaderOutput.spotFeeDefault}
          />
          <Form.Control
            hidden
            id="stockFeeDefault"
            value={billingAgentInfo?.billingAgentMonitorBaseHeaderOutput.stockFeeDefault}
          />
          <Form.Control
            hidden
            id="imageFeeDefault"
            value={billingAgentInfo?.billingAgentMonitorBaseHeaderOutput.imageFeeDefault}
          />
          <Form.Control
            hidden
            id="paymentFeeDefault"
            value={billingAgentInfo?.billingAgentMonitorBaseHeaderOutput.paymentFeeDefault}
          />
          <Card>
            <Card.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="g-2 mb-4">
                  <Col>
                    <Form.Group>
                      <Row>
                        <Col className="col-4">
                          <Form.Label htmlFor="clientId" className="pt-2">
                            クライアント
                          </Form.Label>
                        </Col>
                        <Col className="col-8">
                          {billingSuggestClientResult.isSuccess && (
                            <Select
                              id="clientId"
                              isSearchable
                              isClearable
                              options={billingSuggestClientResult.data}
                              onChange={(e) => setValue('clientId', e?.value)}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Row>
                        <Col className="col-4">
                          <Form.Label htmlFor="contractShopId" className="pt-2">
                            契約店舗
                          </Form.Label>
                        </Col>
                        <Col className="col-8">
                          {billingSuggestContractShopResult.isSuccess && (
                            <Select
                              id="contractShopId"
                              isSearchable
                              isClearable
                              options={billingSuggestContractShopResult.data}
                              onChange={(e) => setValue('contractShopId', e?.value)}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="g-2 mb-4">
                  <Col>
                    <Form.Group>
                      <Row>
                        <Col className="col-4">
                          <Form.Label className="pt-2" htmlFor="monitorBaseName">
                            モニターベース名
                          </Form.Label>
                        </Col>
                        <Col className="col-8">
                          <Form.Control id="monitorBaseName" type="text" {...register('monitorBaseName')} />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>{}</Col>
                </Row>
                <div className="d-flex justify-content-center">
                  <Button type="submit">検索する</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-center">
                <div className="m-2">
                  <Button onClick={() => createRelationCheckedItem()}>チェック紐づけ</Button>
                </div>
                <div className="m-2">
                  <Button onClick={() => createRelationSearchResult()}>検索結果紐づけ</Button>
                </div>
                <div className="m-2">
                  <Button onClick={() => handleClickRemoveRelationAll()}>まとめて紐づけ解除</Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {updResult !== undefined && (
        <BillingUpdateResultModal
          fadeoutTime={BILLING_DEFAULT_FADE_TIME}
          updResult={updResult}
          setUpdResult={setUpdResult}
        />
      )}
      <Table>
        <thead>
          <tr>
            <th>クライアント</th>
            <th>契約店舗</th>
            <th>モニターベース</th>
            <th style={{ width: '5rem' }}>スポットフィー</th>
            <th style={{ width: '5rem' }}>ストックフィー</th>
            <th style={{ width: '5rem' }}>撮影画像フィー</th>
            <th style={{ width: '5rem' }}>手数料フィー</th>
            <th>代理店</th>
            <th>紐づき</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {billingAgentInfo?.billingAgentMonitorBaseList.map((v, index) => {
            return (
              <tr key={index}>
                <td>{`${v.clientId}:${v.clientName}`}</td>
                <td>{`${v.contractShopId}:${v.contractShopName}`}</td>
                <td>{`${v.monitorBaseId}:${v.monitorBaseName}`}</td>
                <td>
                  <Form.Control
                    type="number"
                    value={v.spotFee}
                    onChange={(e) => {
                      setBillingAgentInfo({
                        ...billingAgentInfo,
                        billingAgentMonitorBaseList: billingAgentInfo.billingAgentMonitorBaseList.map((v_, i_) => {
                          if (i_ === index) {
                            return { ...v_, spotFee: e.target.value === '' ? undefined : Number(e.target.value) };
                          }
                          return v_;
                        }),
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={v.stockFee}
                    onChange={(e) => {
                      setBillingAgentInfo({
                        ...billingAgentInfo,
                        billingAgentMonitorBaseList: billingAgentInfo.billingAgentMonitorBaseList.map((v_, i_) => {
                          if (i_ === index) {
                            return { ...v_, stockFee: e.target.value === '' ? undefined : Number(e.target.value) };
                          }
                          return v_;
                        }),
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={v.imageFee}
                    onChange={(e) => {
                      setBillingAgentInfo({
                        ...billingAgentInfo,
                        billingAgentMonitorBaseList: billingAgentInfo.billingAgentMonitorBaseList.map((v_, i_) => {
                          if (i_ === index) {
                            return { ...v_, imageFee: e.target.value === '' ? undefined : Number(e.target.value) };
                          }
                          return v_;
                        }),
                      });
                    }}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={v.paymentFee}
                    onChange={(e) => {
                      setBillingAgentInfo({
                        ...billingAgentInfo,
                        billingAgentMonitorBaseList: billingAgentInfo.billingAgentMonitorBaseList.map((v_, i_) => {
                          if (i_ === index) {
                            return { ...v_, paymentFee: e.target.value === '' ? undefined : Number(e.target.value) };
                          }
                          return v_;
                        }),
                      });
                    }}
                  />
                </td>
                <td>
                  {v.billingAgentList.map((vv) => {
                    return <span className="d-block">{vv}</span>;
                  })}
                </td>
                <td>
                  <Form.Check.Input
                    type="checkbox"
                    checked={v.related}
                    onClick={(e) => {
                      setBillingAgentInfo({
                        ...billingAgentInfo,
                        billingAgentMonitorBaseList: billingAgentInfo.billingAgentMonitorBaseList.map((v_, i_) => {
                          if (i_ === index) {
                            return { ...v_, related: !v_.related };
                          }
                          return v_;
                        }),
                      });
                    }}
                  />
                </td>
                <td>
                  <Button
                    onClick={() => {
                      setBillingAgentInfo({
                        ...billingAgentInfo,
                        billingAgentMonitorBaseList: billingAgentInfo.billingAgentMonitorBaseList.map((v_, i_) => {
                          if (i_ === index) {
                            return {
                              ...v_,
                              ...{
                                spotFee: billingAgentInfo.billingAgentMonitorBaseHeaderOutput.spotFeeDefault,
                                stockFee: billingAgentInfo.billingAgentMonitorBaseHeaderOutput.stockFeeDefault,
                                imageFee: billingAgentInfo.billingAgentMonitorBaseHeaderOutput.imageFeeDefault,
                                paymentFee: billingAgentInfo.billingAgentMonitorBaseHeaderOutput.paymentFeeDefault,
                              },
                            };
                          }
                          return v_;
                        }),
                      });
                    }}
                  >
                    デフォルト値反映
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {/* <BillingDestinationContractTable */}
      {/*    billingDestinationId={id} */}
      {/*    list={list} */}
      {/*    setList={setList} */}
      {/*    page={currentPage} */}
      {/*    setUpdResult={setUpdResult} */}
      {/*    destContractBulkUpdateForm={destContractBulkUpdateForm} */}
      {/*    handleClickSearchBtn={handleClickSearchBtn} */}
      {/* /> */}

      {/* <div className="mt-4 d-flex justify-content-center"> */}
      {/*  <PaginationWithEllipsisBilling */}
      {/*      maxRecordPerPage={MAX_RECORD_PER_PAGE} */}
      {/*      totalDataNum={list.length} */}
      {/*      handleClick={(e) => handleSetCurrentPage(e.page)} */}
      {/*  /> */}
      {/* </div> */}
    </>
  );
};
