import { useQuery } from '@tanstack/react-query';
import { BillingApi } from '../api-client';
import { CACHE_KEY_BILLING_CLIENT } from '../Constants';

export default function useBillingSuggestClient() {
  const billingApi = new BillingApi();

  const result = useQuery({
    queryKey: [CACHE_KEY_BILLING_CLIENT],
    queryFn: async () =>
      billingApi.billingSuggestClientList().then((res) => {
        return res;
      }),
  });
  return {
    isSuccess: result.isSuccess,
    data: result.data?.data,
  };
}
