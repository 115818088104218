import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useLargeState } from '../../../../hooks/useLargeState';
import { createTestId } from '../../../../utils/functions';
import type { CustomerDetailState } from '../CustomerDetailPage';
import type { CustomerInCompanyMemoEditConfirmedFormResponse } from '../../../../api-client';
import { CustomerMemoEditModal } from '../../../organisms/Modal/Customer/CustomerMemoEditModal';
import { WrappableText } from '../../../atoms/WrappableText';

export type State = CustomerDetailState['customerData'];
interface Props {
  customerData: State;
  oemFlag: boolean;
}

export interface MemoState {
  isModal: boolean;
  forms: CustomerInCompanyMemoEditConfirmedFormResponse;
}

export type TestIds = keyof State | 'pen';

export const MemoCard: React.FC<Props> = ({ customerData, oemFlag }) => {
  const testid = createTestId<TestIds>(MemoCard);

  const { state: $info } = useLargeState<State>(customerData);

  const initialData: CustomerInCompanyMemoEditConfirmedFormResponse = useMemo(
    () => ({
      customerId: customerData.customerId,
      inCompanyMemo: customerData.incMemo || '',
    }),
    [customerData]
  );

  const {
    state: $modal,
    mergeState: mergeModal,
    useBindSet,
  } = useLargeState<MemoState>({
    isModal: false,
    forms: initialData,
  });

  const setForms = useBindSet('forms');

  return (
    <>
      <CustomerMemoEditModal
        isModal={$modal.isModal}
        forms={$modal.forms}
        setForms={setForms}
        onHide={() =>
          mergeModal({
            isModal: false,
            forms: initialData,
          })
        }
      />
      <Card className="mt-2 ms-2" style={{ width: oemFlag ? '24.25%' : '49%' }}>
        <div className="d-flex justify-content-between mt-2">
          <h6>社内メモ</h6>
          <div style={{ cursor: 'pointer' }} data-testid={testid('pen')}>
            <FontAwesomeIcon icon={faPen} onClick={() => mergeModal({ isModal: true })} />
          </div>
        </div>

        <small>内容</small>
        <br />
        <WrappableText memo={$info.incMemo} height={192} />
      </Card>
    </>
  );
};
