import * as React from 'react';
import { Form, Table } from 'react-bootstrap';
import type { MonitorListOutputResponse } from '../../../api-client';
import { uuid } from '../../../utils/functions';

export interface Props {
  listPerPage: MonitorListOutputResponse[];
  selectedMonitorId?: number;
  setSelectedMonitorId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const MonitorBaseModifyMonitorListTable: React.VFC<Props> = ({
  listPerPage,
  setSelectedMonitorId,
  selectedMonitorId,
}) => {
  return (
    <Table className="mb-4">
      <thead>
        <tr className="align-middle">
          <th>&nbsp;</th>
          <th>モニターID</th>
          <th>モニター名</th>
          <th>実店舗名</th>
          <th>クライアント名</th>
          <th>表示ステータス</th>
          <th>期間</th>
        </tr>
      </thead>
      <tbody>
        {listPerPage.map(({ monitorId, shopName, clientName, monitorName, period, status }) => (
          <React.Fragment key={uuid()}>
            <tr key={uuid()}>
              <td>
                <Form.Check.Input
                  type="radio"
                  name="radioGroup01"
                  value={monitorId}
                  checked={selectedMonitorId === monitorId}
                  onChange={(e) => {
                    setSelectedMonitorId(Number(e.target.value));
                  }}
                />
              </td>
              <td>{monitorId}</td>
              <td>{monitorName}</td>
              <td>{shopName}</td>
              <td>{clientName}</td>
              <td>{status}</td>
              <td>{period}</td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};
