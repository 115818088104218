import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQuery = () => {
  // useLocationだとrouterにqueryを設定した場合に正しく取得できない
  // const { search } = useLocation();
  const { search } = window.location;
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function useSearchQuery() {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  const addQueryStringToUrl = () => {
    history.push(`${location.pathname}?${query.toString()}`);
  };

  const getQueryString = () => {
    return location.search;
  };

  const setSearchQuery = (name: string, value: string) => {
    query.set(name, value);
  };

  const removeSearchQuery = (name: string) => {
    query.delete(name);
  };

  const getSearchParams = (name: string) => {
    return query.get(name);
  };
  return { addQueryStringToUrl, setSearchQuery, getSearchParams, removeSearchQuery, getQueryString };
}
