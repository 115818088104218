import React from 'react';
import { Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faTimesCircle, faFileUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

export const RegisterList = () => {
  return (
    <>
      <Row>
        <Col>
          <Card className="mb-4">
            <Card.Body>
              <h4>List</h4>
              <Form>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="ms-2">
                    キャンセル
                  </Button>
                  <Button variant="outline-secondary" className="ms-2">
                    プレビュー
                  </Button>
                  <Button variant="primary" className="ms-2">
                    保存
                  </Button>
                </div>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="text-secondary">
                    <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                    追加
                  </Button>
                </div>
                <ListGroup className="mb-2" variant="flush">
                  <ListGroup.Item>
                    <Row className="align-items-center gx-3">
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button type="button" className="text-secondary">
                          <FontAwesomeIcon icon={faGripLines} fixedWidth />
                        </button>
                      </Col>
                      <Col>
                        <Form.Group controlId="formId2_1">
                          <Form.Control type="text" placeholder="プレースホルダー" />
                        </Form.Group>
                      </Col>
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button type="button" className="text-secondary">
                          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        </button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row className="align-items-center gx-3">
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button type="button" className="text-secondary">
                          <FontAwesomeIcon icon={faGripLines} fixedWidth />
                        </button>
                      </Col>
                      <Col>
                        <Form.Group controlId="formId2_1">
                          <Form.Control type="text" placeholder="プレースホルダー" disabled />
                        </Form.Group>
                      </Col>
                      <Col className="flex-shrink-0 flex-grow-0">
                        <button type="button" className="text-secondary">
                          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        </button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
