import React, { Fragment } from 'react';
import { faCalendar, faCheckSquare, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisplayPointOutputResponse } from '../../../../../../api-client';
import { useSafeContext } from '../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../store/correctionStore';
import { dial, uuid } from '../../../../../../utils/functions';
import { Button } from '../../../../../atoms/Button';
import { AnswerMethodSummary, CanCorrectType, DisplayPointType } from '../../../../../pages/Correction/constants';
import { getCheckListSectionlabel } from '../../../../../pages/Correction/utils';
import { CommonPanel } from '../CommonPanel';
import { ImageQuestion } from './questions/ImageQuestion';
import { SelectQuestion } from './questions/SelectQuestion';
import { TextQuestion } from './questions/TextQuestion';
import { ANSWER_METHOD } from '../../../../Modal/Question/QuestionConfirmModal';

export const EnqueteCheckListSection: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const getDisplayPoint = (answerId?: number): DisplayPointOutputResponse => {
    return { type: DisplayPointType.ANSWER, targetId: answerId };
  };

  const saveButton = () => {
    if ($.res_correctEnqueteDisplayControl?.correctionExec === CanCorrectType.CORRECTING) return;
    if ($.res_correctEnqueteDisplayControl?.answerEdit) return <Button onClick={$.saveAnswer!}>修正結果保存</Button>;
    return <>修正結果は保存できません。</>;
  };

  /**
   * Icon
   * TODO 外部ファイルへ
   * @param answerMethod
   * @param answerMethodSummaryCode
   * @constructor
   */
  const CorrectionIcon: React.FC<{ answerMethod: number; answerMethodSummaryCode: number }> = ({
    answerMethod,
    answerMethodSummaryCode,
  }) => {
    if (answerMethod === ANSWER_METHOD.TIME) return <FontAwesomeIcon icon={faCheckSquare} fixedWidth />;
    if (answerMethod === ANSWER_METHOD.DATE) return <FontAwesomeIcon icon={faCalendar} fixedWidth />;
    if (answerMethodSummaryCode === AnswerMethodSummary.TEXT) return <FontAwesomeIcon icon={faComment} fixedWidth />;
    if (answerMethodSummaryCode === AnswerMethodSummary.SELECT)
      return <FontAwesomeIcon icon={faCheckSquare} fixedWidth />;
    return <FontAwesomeIcon icon={faComment} fixedWidth />;
  };

  return (
    <>
      {$.res_enqueteDisplay?.map(
        ({
          questionId,
          questionNo,
          answerId,
          content,
          answerMethodCode,
          answerMethodSummaryCode,
          answerContent,
          ngWordList,
          imageUrlList,
          answerChoiceList,
          selectionList,
          minimumInputLength,
          maxInputLength,
          isInputOnlyNumber,
          showChangeShopReviewCheckBoxFlg,
          shopReviewDisplayFlg,
        }) => (
          <Fragment key={uuid()}>
            <CorrectionIcon answerMethod={answerMethodCode} answerMethodSummaryCode={answerMethodSummaryCode} />
            <CommonPanel
              label={getCheckListSectionlabel(true, questionNo, content)}
              displayPoint={{
                type: DisplayPointType.QUESTION,
                targetId: questionId,
              }}
              displayPointForEditor={{
                type: DisplayPointType.ANSWER,
                targetId: answerId,
              }}
            >
              {dial(answerMethodSummaryCode, {
                [AnswerMethodSummary.TEXT]: (
                  <TextQuestion
                    sendingDisplayPoint={{ type: DisplayPointType.ANSWER, targetId: answerId }}
                    isEditable
                    answerContent={answerContent}
                    ngWordList={ngWordList}
                    contentCharLengthMin={minimumInputLength}
                    contentCharLengthMax={maxInputLength}
                    isInputOnlyNumber={isInputOnlyNumber}
                    isEnquete
                    showChangeShopReviewCheckBoxFlg={showChangeShopReviewCheckBoxFlg}
                    shopReviewDisplayFlg={shopReviewDisplayFlg}
                    answerMethodCode={answerMethodCode}
                  />
                ),
                [AnswerMethodSummary.SELECT]: (
                  <SelectQuestion
                    sendingDisplayPoint={{ type: DisplayPointType.ANSWER, targetId: answerId }}
                    selectionList={selectionList}
                    answerChoiceList={answerChoiceList}
                    answerMethodCode={answerMethodCode}
                    isEditable
                  />
                ),
                [AnswerMethodSummary.IMAGE]: (
                  <ImageQuestion
                    imageUrlList={imageUrlList}
                    displayPoint={getDisplayPoint(answerId)}
                    answerId={answerId}
                  />
                ),
              })}
            </CommonPanel>
            <hr />
          </Fragment>
        )
      )}
      {saveButton()}
      <hr />
    </>
  );
};
