import { faPlus, faTimes, faTimesCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Form, InputGroup, Modal, Row, Table } from 'react-bootstrap';
import scss from '../../../scss/atoms/tagsinput.module.scss';
import type {
  BranchSelectionInfoOutputResponse,
  EnqueteInfoOutputResponse,
  EnqueteQuestionBranchOutputResponse,
  EnqueteQuestionSettingOutputResponse,
  SelectionInfoForBranchOutputResponse,
} from '../../../api-client';
import { ENQUETE_TYPE } from '../../pages/Enquete/EnqueteListPage';

export interface Props {
  data?: EnqueteInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<EnqueteInfoOutputResponse>>;
  branchList: EnqueteQuestionBranchOutputResponse[] | undefined;
  setBranchList: React.Dispatch<React.SetStateAction<EnqueteQuestionBranchOutputResponse[] | undefined>>;
  showFlg: boolean;
  setShowFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setJumpQuestionSelectedModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedBranch: React.Dispatch<React.SetStateAction<EnqueteQuestionBranchOutputResponse | undefined>>;
  selectedQuestionId: number | undefined;
  setSelectedQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const JumpQuestionModal: React.VFC<Props> = ({
  data,
  setData,
  branchList,
  setBranchList,
  showFlg,
  setShowFlg,
  setJumpQuestionSelectedModalFlg,
  setSelectedBranch,
  selectedQuestionId,
  setSelectedQuestionId,
}) => {
  // エラーメッセージ
  const [error, setError] = useState<string>();

  useEffect(() => {
    // 分岐情報が存在する場合
    if (
      data &&
      data.branches?.filter((row) => {
        return selectedQuestionId === row.targetQuestionId && !row.disableFlg;
      }) !== undefined
    ) {
      setBranchList(() => {
        const branch = data.branches.find((row) => {
          return selectedQuestionId === row.targetQuestionId && !row.disableFlg;
        });

        if (branch) {
          let selections: BranchSelectionInfoOutputResponse[] = [];
          data.enqueteQuestionsList
            .filter((row) => row.questionId === branch.conditionQuestionId)
            .forEach((row) => {
              selections = row.selections.map((it) => {
                return {
                  selectionId: it.selectionId,
                  content: it.content,
                  selectFlg: !!branch?.selections.find((it2) => it2.selectionId === it.selectionId && it2.selectFlg),
                } as BranchSelectionInfoOutputResponse;
              });
            });

          const newBranch = {
            branchId: branch?.branchId,
            condition: true,
            conditionQuestionId: branch.conditionQuestionId,
            selections,
            targetQuestionId: selectedQuestionId,
          } as EnqueteQuestionBranchOutputResponse;

          return [newBranch];
        }
        return [];
      });
    } else {
      setBranchList(undefined);
    }
  }, [selectedQuestionId]);

  // キャンセルまたはモーダルを閉じた場合のイベント
  const onHide = () => {
    setShowFlg(false);
    setBranchList(undefined);
    setSelectedQuestionId(undefined);
    setError(undefined);
  };

  // 追加ボタンイベント
  const onAdd = () => {
    if (data && selectedQuestionId && branchList) {
      const questionIdList: number[] = [];
      // 選択中の設問IDをセット
      questionIdList.push(selectedQuestionId);
      // 紐づけている設問IDをセット
      branchList?.forEach((_) => {
        questionIdList.push(_.conditionQuestionId);
      });

      let addConditionQustionId = 0;
      let selections: BranchSelectionInfoOutputResponse[] = [];
      data.enqueteQuestionsList.forEach((row, index) => {
        if (questionIdList?.indexOf(row.questionId) !== -1 && index > 0) {
          const prevRow = data.enqueteQuestionsList[index - 1];
          addConditionQustionId = prevRow.questionId;
          selections = prevRow.selections.map((it) => {
            return {
              selectionId: it.selectionId,
              content: it.content,
              selectFlg: false,
            } as BranchSelectionInfoOutputResponse;
          });
        }
      });

      const newBranch = {
        condition: true,
        conditionQuestionId: addConditionQustionId,
        selections,
        targetQuestionId: selectedQuestionId,
      } as EnqueteQuestionBranchOutputResponse;
      setBranchList((prevState) => prevState?.concat(newBranch));
    }
  };

  // 「保存」ボタンイベント
  const onhandleSave = () => {
    if (data?.enqueteQuestionsList && branchList) {
      branchList.forEach((branchItem) => {
        const { conditionQuestionId, targetQuestionId } = branchItem;

        // 選択肢のチェック（選択されているか）
        if (branchItem.selections.length === 0 || !branchItem.selections.find((it) => it.selectFlg === true)) {
          setError('選択肢を設定してください。');
          return;
        }

        let addFlg = true;
        const newBranchList: EnqueteQuestionBranchOutputResponse[] = data.branches.map((_) => {
          if (_.targetQuestionId === targetQuestionId) {
            addFlg = false;
            const selectList = branchItem.selections.map((it) => {
              return {
                content: it.content,
                selectionId: it.selectionId,
                selectFlg: it.selectFlg,
              } as BranchSelectionInfoOutputResponse;
            });
            return { ..._, selections: selectList, conditionQuestionId, disableFlg: false };
          }
          return _;
        });

        if (addFlg) {
          const addBranch = Object.assign({}, branchItem);
          addBranch.disableFlg = false;

          setData({
            ...data,
            branches: [...data.branches, addBranch],
          });
        } else {
          setData({
            ...data,
            branches: newBranchList,
          });
        }
      });
      if (branchList.length === 0) {
        console.log('selectedQuestionId', selectedQuestionId);
        const newBranchList: EnqueteQuestionBranchOutputResponse[] = data.branches.map((_) => {
          if (_.targetQuestionId === selectedQuestionId) {
            return { ..._, disableFlg: true };
          }
          return _;
        });
        setData({
          ...data,
          branches: newBranchList,
        });
      }
    }
    setShowFlg(false);
    setBranchList(undefined);
    setSelectedQuestionId(undefined);
    setError(undefined);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, selectionId: number) => {
    if (branchList) {
      const newSelections = branchList[0].selections.map((it) => {
        if (it.selectionId === selectionId) {
          return { ...it, selectFlg: e.target.checked };
        }
        return it;
      });

      const newBranch = {
        branchId: branchList[0].branchId,
        condition: true,
        conditionQuestionId: branchList[0].conditionQuestionId,
        selections: newSelections,
        targetQuestionId: selectedQuestionId,
      } as EnqueteQuestionBranchOutputResponse;
      setBranchList([newBranch]);
    }
  };

  return (
    <Modal size="lg" show={showFlg} onHide={onHide} scrollable centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="d-flex justify-content-end mb-4">
          <Button variant="link" className="ms-2" onClick={onHide}>
            キャンセル
          </Button>
          <Button className="ms-2" onClick={onhandleSave}>
            保存
          </Button>
        </div>
        {error && (
          <Alert variant="danger" style={{ marginTop: 10 }}>
            {error}
          </Alert>
        )}
        <div className="mb-4" style={{ fontWeight: 'bold' }}>
          <Form.Group className="mb-4">
            <Form.Label className="d-inline">{`「${
              data?.enqueteQuestionsList.find((_) => {
                return _.questionId === selectedQuestionId;
              })?.content
            }」`}</Form.Label>
            <div className="d-inline">を表示する条件を設定してください。</div>
          </Form.Group>
        </div>
        {branchList?.map((row, ind) => {
          return (
            <Card key={ind.toString()} className="mb-3" style={{ width: '100%' }}>
              <div className="d-flex">
                <Card.Body>
                  <Form.Group className="mb-4">
                    <Form.Label>設問</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        value={
                          data?.enqueteQuestionsList.find((_) => {
                            return row.conditionQuestionId === _.questionId;
                          })?.content
                        }
                        readOnly
                      />
                      <Button
                        onClick={() => {
                          setJumpQuestionSelectedModalFlg(true);
                          setSelectedBranch(row);
                        }}
                      >
                        選択
                      </Button>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="mb-4" style={{ marginBottom: '0px' }}>
                    <div>
                      <Row
                        className="gx-3"
                        style={{ fontWeight: 'bolder', borderBottom: '1px solid rgba(0,0,0,.125)' }}
                      >
                        <Col className="col-6">選択肢</Col>
                        <Col className="col-4">表示</Col>
                      </Row>
                      {row.selections.map((selection: BranchSelectionInfoOutputResponse) => (
                        <Row key={selection.selectionId} className="gx-3">
                          <Col className="col-6">{selection.content}</Col>
                          <Col className="col-4">
                            <Form.Check.Input
                              id="selectFlg"
                              className="flex-shrink-0 me-4"
                              type="checkbox"
                              defaultChecked={selection.selectFlg}
                              onChange={(e) => onChange(e, selection.selectionId)}
                            />
                          </Col>
                        </Row>
                      ))}
                    </div>
                  </Form.Group>
                </Card.Body>
                <div className="d-flex align-items-center justify-content-end ms-3">
                  <Button
                    data-testid="deleteButton"
                    variant="link"
                    className="text-secondary"
                    onClick={() => {
                      const newData = branchList.filter((question) => {
                        return question.branchId !== row.branchId;
                      });
                      setBranchList(newData);
                    }}
                    data-html
                  >
                    <FontAwesomeIcon className="text-secondary" icon={faTimesCircle} />
                  </Button>
                </div>
              </div>
            </Card>
          );
        })}
        <div className="d-flex justify-content-end">
          {branchList && branchList?.length === 0 && (
            <Button variant="link" className="text-secondary" onClick={onAdd}>
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              追加
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
