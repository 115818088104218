/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import sass from '../../../scss/templates/CorrectionTemplate.module.scss';
// eslint-disable-next-line import/no-cycle
import { ShoppingApi, CommonMasterListApi, CorrectionTemplateApi, CorrectApi } from '../../../api-client';
import type {
  CommonMasterListOutputResponse,
  CorrectionTemplateListOutputResponse,
  DataInputWCheckApproveConfirmFormResponse,
  DataInputWCheckApproveConfirmOutputResponse,
  DataInputWCheckDisplayOutputResponse,
  EscalationInfoDisplayOutputResponse,
  MonitorRuleDtoResponse,
  ReceiptReadResultFormResponse,
  ShoppingDisplayControlOutputResponse,
  SurveyProofImageInfoDtoResponse,
} from '../../../api-client';
import { isoDateToInput, when } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import * as CONSTANTS from './constants';
import { DataInputNoteContent } from '../../organisms/Correction/DataInput/DataInputContent';
import { CorrectDataInputWcheckForm } from '../../organisms/Forms/CorrectDataInputWcheckForm';
import { SurveyProofImageCard } from '../../organisms/Correction/DataInput/SurveyProofImageCard';
import { ApplyNgInfoCard } from '../../organisms/Correction/DataInput/ApplyNgInfoCard';
import { CorrectDataInputWcheckApproveConfirmPage } from './CorrectDataInputWcheckApproveConfirmPage';
import { ShoppingControlAlert } from '../../organisms/Correction/DataInput/ShoppingControlAlert';

export interface ErrorMessagesForInput {
  target: string;
  message: string;
}
export interface CorrectDataInputWcheckState {
  shoppingApi: ShoppingApi;
  correctApi: CorrectApi;
  commonMasterListApi: CommonMasterListApi;
  correctionTemplateApi: CorrectionTemplateApi;
  isLoading: boolean;
  output: DataInputWCheckDisplayOutputResponse;
  confirmOutput: DataInputWCheckApproveConfirmOutputResponse;
  forms: DataInputWCheckApproveConfirmFormResponse;
  chainNameList: string[];
  prefectureList: CommonMasterListOutputResponse[];
  imageList: SurveyProofImageInfoDtoResponse[];
  controlInfo: ShoppingDisplayControlOutputResponse;
  confirmFlg: boolean;
  escalationTemplateList: CorrectionTemplateListOutputResponse[];
  monitorRuleList: MonitorRuleDtoResponse[];
}
export const CorrectDataInputWcheckPage: React.FC = () => {
  const PREFECTURE_KEY = 'PREFECTURE';

  const initialOutput: DataInputWCheckDisplayOutputResponse = {
    monitorId: 0,
    applyId: 0,
    receiptReadResultList: [],
    selectableTaxRateList: [],
    autoCorrectResult: 0,
    surveyProofInfo: { surveyProofDtoList: [] },
  };

  const initialForm: DataInputWCheckApproveConfirmFormResponse = {
    applyId: 0,
    receiptReadResultFormList: [],
    forcedCorrectFlg: false,
  };

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<CorrectDataInputWcheckState>({
    shoppingApi: new ShoppingApi(),
    correctApi: new CorrectApi(),
    commonMasterListApi: new CommonMasterListApi(),
    correctionTemplateApi: new CorrectionTemplateApi(),
    isLoading: false,
    output: initialOutput,
    confirmOutput: { commonRuleNgList: [], eachMonitorBaseRuleNgList: [] },
    forms: initialForm,
    chainNameList: [],
    prefectureList: [],
    imageList: [],
    controlInfo: {
      applyId: 0,
      enqueteStatus: '',
      isDataInputEscalationAnswerPossible: false,
      isDataInputPossible: false,
      surveyProofStatus: '',
      isEscalationAnswerForSv: false,
      isForcedCorrect: false,
    },
    confirmFlg: false,
    escalationTemplateList: [],
    monitorRuleList: [],
  });

  const history = useHistory();
  const setForms = useBindSet('forms');
  const { applyId } = useParams<{ applyId: string | undefined }>();

  const createInitialFormData = useCallback((data: DataInputWCheckDisplayOutputResponse, id: number) => {
    const initialData: DataInputWCheckApproveConfirmFormResponse = {
      applyId: id,
      totalAmountIncludeTax: data.totalAmountIncludeTax,
      receiptReadResultFormList: [
        ...data.receiptReadResultList.map((obj) => {
          const returnData: ReceiptReadResultFormResponse = {
            amountReadableFlg: !!obj.receiptAmount?.amountReadableFlg,
            receiptAmount: obj.receiptAmount?.amount,
            autoCorrectReceiptApproveId: obj.autoCorrectReceiptId,
            chainNameReadableFlg: !!obj.chain?.chainNameReadableFlg,
            chainName: obj.chain?.chainName,
            inFocusFlg: !!obj.inFocusCheck?.inFocusCheckFlg,
            prefectureReadableFlg: !!obj.receiptReadResultPrefecture?.prefectureReadableFlg,
            selectPrefectureId: obj.receiptReadResultPrefecture?.selectPrefectureId,
            purchaseCheckReadableFlg: !!obj.purchaseCheckResult?.purchaseCheckReadableFlg,
            purchaseCheckResultFlg: !!obj.purchaseCheckResult?.purchaseCheckResultFlg,
            receiptDateTimeReadableFlg: !!obj.receiptDateTime?.receiptDateTimeReadableFlg,
            receiptDateTime: isoDateToInput('datetime-local', obj.receiptDateTime?.dateTime || '') as string,
            receiptNumberReadableFlg: !!obj.receiptNumber?.receiptNumberReadableFlg,
            shopPhoneNumberReadableFlg: !!obj.shopPhoneNumber?.shopPhoneNumberReadableFlg,
            shopPhoneNumber: obj.shopPhoneNumber?.phoneNumber,
            storeReadableFlg: !!obj.store?.storeReadableFlg,
            storeName: obj.store?.storeName,
            visibleAllCornerFlg: !!obj.visibleAllCorner?.visibleAllCornerFlg,
            inquiryContents: obj.inputUserQuestion?.message,
            receiptAmountIncludeTax: obj.receiptAmountIncludeTax,
            taxRateCode: obj.selectedTaxRateCode,
          };
          return returnData;
        }),
      ],
      forcedCorrectFlg: false,
    };
    return initialData;
  }, []);

  const LATEST = '最新';

  useEffect(() => {
    mergeState({ isLoading: true });
    if (applyId && Number(applyId) > 0) {
      $.shoppingApi
        .dataInputWCheckDisplay(Number(applyId))
        .then((res: AxiosResponse<DataInputWCheckDisplayOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            const initialData: DataInputWCheckApproveConfirmFormResponse = createInitialFormData(
              res.data,
              Number(applyId)
            );
            const latestSurveyProofDtoList = res.data.surveyProofInfo.surveyProofDtoList.filter(
              ({ versionName }) => versionName === LATEST
            );
            const imageList =
              latestSurveyProofDtoList.length === 1 ? latestSurveyProofDtoList[0].surveyProofImageInfoDtoList : [];
            mergeState({
              output: res.data,
              forms: initialData,
              imageList,
            });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });

      $.shoppingApi
        .chainNameList()
        .then((res: AxiosResponse<Array<string>>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            mergeState({ chainNameList: res.data });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });

      $.shoppingApi
        .shoppingDisplayControl(Number(applyId), true)
        .then((res: AxiosResponse<ShoppingDisplayControlOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            mergeState({ controlInfo: res.data });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });

      $.shoppingApi
        .dataInputMonitorRuleDisplay(Number(applyId))
        .then((res: AxiosResponse<Array<MonitorRuleDtoResponse>>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            mergeState({ monitorRuleList: res.data });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });

      $.commonMasterListApi
        .commonMasterList(PREFECTURE_KEY)
        .then((res: AxiosResponse<Array<CommonMasterListOutputResponse>>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            mergeState({ prefectureList: res.data });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });

      const CORRECTION_TYPE = 3;
      const ESCALATION_ANSWER_TYPE = 4;
      $.correctionTemplateApi
        .correctionTemplateList({ correctionType: CORRECTION_TYPE, operationTypeGroup: ESCALATION_ANSWER_TYPE })
        .then((res: AxiosResponse<Array<CorrectionTemplateListOutputResponse>>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            mergeState({ escalationTemplateList: res.data });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    }
    mergeState({ isLoading: false });
  }, [
    history,
    applyId,
    mergeState,
    $.shoppingApi,
    $.commonMasterListApi,
    createInitialFormData,
    $.correctApi,
    $.correctionTemplateApi,
  ]);

  const onConfirm = (data: DataInputWCheckApproveConfirmOutputResponse) => {
    mergeState({ confirmOutput: data, confirmFlg: true });
  };

  const onGoBack = () => {
    mergeState({ confirmFlg: false });
  };

  const onSubmitStart = () => {
    mergeState({ isLoading: true });
  };

  const onSubmitEnd = () => {
    mergeState({ isLoading: false });
  };

  return (
    <>
      <LoadingSpinner isLoading={$.isLoading}>
        {when(
          $.output.applyId > 0,
          <>
            {!$.confirmFlg ? (
              <div className="d-flex">
                <div className={`w-50 h-100 ${sass.correction_contentsCol}`}>
                  <ShoppingControlAlert controlInfo={$.controlInfo} wcheckFlg />
                  <DataInputNoteContent output={$.output} />
                  <CorrectDataInputWcheckForm
                    formData={$.forms}
                    setForms={setForms}
                    monitorId={$.output.monitorId}
                    output={$.output.receiptReadResultList}
                    prefectureList={$.prefectureList}
                    chainNameList={$.chainNameList}
                    taxRateList={$.output.selectableTaxRateList}
                    controlInfo={$.controlInfo}
                    onConfirm={onConfirm}
                    onSubmitStart={onSubmitStart}
                    onSubmitEnd={onSubmitEnd}
                  />
                </div>
                <div className={`w-50 h-100 sticky-top ${sass.correction_contentsCol}`} style={{ zIndex: 2 }}>
                  <SurveyProofImageCard
                    surveyProofList={$.output.surveyProofInfo.surveyProofDtoList}
                    userComment={$.output.surveyProofInfo.userComment || ''}
                  />
                  <ApplyNgInfoCard
                    infoForApply={$.output.infoForApply}
                    escalationTemplateList={$.escalationTemplateList}
                    isAnswer={$.controlInfo.isEscalationAnswerForSv}
                    applyId={$.output.applyId}
                    receiptReadResultFormList={$.forms.receiptReadResultFormList}
                    totalAmountIncludeTax={$.forms.totalAmountIncludeTax}
                    monitorRuleList={$.monitorRuleList}
                  />
                </div>
              </div>
            ) : (
              <CorrectDataInputWcheckApproveConfirmPage
                monitorId={$.output.monitorId}
                approveForm={$.forms}
                confirmOutput={$.confirmOutput}
                onGoBack={onGoBack}
                onSubmitStart={onSubmitStart}
                onSubmitEnd={onSubmitEnd}
              />
            )}
          </>
        )}
      </LoadingSpinner>
    </>
  );
};
