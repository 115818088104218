import React, { useCallback, useEffect, useMemo } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { format, parseISO } from 'date-fns';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { MailBulkDeliveryApi, MailTemplateApi } from '../../../api-client';
import type {
  MailBulkDeliveryUpdateFormResponse,
  MailBulkDeliveryDetailOutputResponse,
  MailTemplateListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { MailBulkDeliveryDetailForms } from '../../organisms/Forms/MailBulkDeliveryDetailForms';
import { createTestId, isoDateToInput } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Url } from '../../../constants/Url';
import { Modal } from '../../molecules/Modal';
import { HOUR_MINUTE } from '../../../Constants';

export interface FormState {
  mailBulkDeliveryId: number;
  status: string;
  targetOemType?: number;
  targetType?: string;
  targetCustomerId?: string;
  targetMailAddress?: string;
  targetApplyId?: string;
  title: string;
  content: string;
  notificationType: number;
  executeAt: string;
}

export interface MailBulkDeliveryDetailState {
  mailBulkDeliveryApi: MailBulkDeliveryApi;
  mailTemplateApi: MailTemplateApi;
  isLoading: boolean;
  forms: FormState;
  templateList: MailTemplateListOutputResponse[];
  isModal: boolean;
  modalMessage: string;
  errorMessage: string[];
}
export type TestIds = 'ok-button' | 'draft-button' | 'submit-button';

export const MailBulkDeliveryDetailPage: React.FC = () => {
  const testid = createTestId<TestIds>(MailBulkDeliveryDetailPage);

  const initialState: FormState = useMemo(
    () => ({
      mailBulkDeliveryId: -1,
      status: '',
      targetOemType: undefined,
      targetType: '',
      targetCustomerId: '',
      targetMailAddress: '',
      targetApplyId: '',
      title: '',
      content: '',
      notificationType: 1,
      executeAt: '',
    }),
    []
  );

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<MailBulkDeliveryDetailState>({
    mailBulkDeliveryApi: new MailBulkDeliveryApi(),
    mailTemplateApi: new MailTemplateApi(),
    isLoading: false,
    forms: initialState,
    templateList: [{ title: '', content: '', mailTemplateId: -1, type: '' }],
    isModal: false,
    modalMessage: '',
    errorMessage: [],
  });

  const history = useHistory();
  const setForms = useBindSet('forms');
  const { id } = useParams<{ id: string | undefined }>();

  const createInitialData = useCallback((data: MailBulkDeliveryDetailOutputResponse) => {
    let targetType = '';
    if (data.targetApplyId) {
      targetType = 'targetApplyId';
    } else if (data.targetCustomerId) {
      targetType = 'targetCustomerId';
    } else if (data.targetMailAddress) {
      targetType = 'targetMailAddress';
    }
    return {
      mailBulkDeliveryId: data.mailBulkDeliveryId || 0,
      status: data.status,
      targetOemType: data.targetOemType || -1,
      targetType,
      targetCustomerId: data.targetCustomerId || '',
      targetMailAddress: data.targetMailAddress || '',
      targetApplyId: data.targetApplyId || '',
      title: data.title || '',
      content: data.content || '',
      notificationType: data.notificationType || 1,
      executeAt:
        data.executeAt || ''
          ? (isoDateToInput('datetime-local', data.executeAt || '') as string)
          : `${format(new Date(), 'yyyy-MM-dd')}T${format(new Date(), HOUR_MINUTE)}`,
    };
  }, []);

  useEffect(() => {
    mergeState({ isLoading: true });
    if (id && Number(id) > 0) {
      $.mailBulkDeliveryApi
        .mailBulkDeliveryDetail(Number(id))
        .then((res: AxiosResponse<MailBulkDeliveryDetailOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            const initialData: FormState = createInitialData(res.data);
            mergeState({ forms: initialData, errorMessage: [] });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
    } else {
      mergeState({
        forms: {
          mailBulkDeliveryId: 0,
          status: '',
          targetOemType: 0,
          targetType: '',
          targetCustomerId: '',
          targetMailAddress: '',
          targetApplyId: '',
          title: '',
          content: '',
          notificationType: 1,
          executeAt: '',
        },
      });
    }

    $.mailTemplateApi
      .mailTemplateList()
      .then((res: AxiosResponse<MailTemplateListOutputResponse[]>) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          mergeState({ errorMessage: ['エラーが発生しました。'] });
        } else {
          mergeState({ templateList: res.data, errorMessage: [] });
        }
      })
      .catch((errorList: IncResultOutputResponse[]) => {
        const errorMessageList: string[] = [];
        errorList.forEach((error) => {
          if (error.errorMessage) {
            errorMessageList.push(error.errorMessage);
          }
        });
        mergeState({ errorMessage: errorMessageList, isLoading: false });
      });
    mergeState({ isLoading: false });
  }, [$.mailBulkDeliveryApi, $.mailTemplateApi, createInitialData, history, id, mergeState]);

  const createSubmitBody = useCallback((body: FormState, draftFlag: boolean) => {
    return {
      mailBulkDeliveryId: body.mailBulkDeliveryId > 0 ? body.mailBulkDeliveryId : undefined,
      targetOemType: Number(body.targetOemType),
      targetCustomerId: body.targetType === 'targetCustomerId' ? body.targetCustomerId : undefined,
      targetMailAddress: body.targetType === 'targetMailAddress' ? body.targetMailAddress : undefined,
      targetApplyId: body.targetType === 'targetApplyId' ? body.targetApplyId : undefined,
      title: body.title,
      content: body.content,
      notificationType: body.notificationType,
      executeAt: body.executeAt ? parseISO(body.executeAt).toISOString() : body.executeAt,
      draftFlag,
    };
  }, []);

  // 実行
  const onSubmit = useCallback(
    (body: FormState, draftFlag: boolean) => {
      // 送信の場合、配信対象会員・タイトル・本文・送信日時を記入しないとエラー
      if (
        !draftFlag &&
        (!body.targetType ||
          !body[body.targetType as keyof FormState] ||
          !body.title ||
          !body.content ||
          !body.executeAt)
      ) {
        const requiredItem = [];
        if (!body.targetType || !body[body.targetType as keyof FormState]) {
          requiredItem.push('配信対象会員');
        }
        if (!body.title) {
          requiredItem.push(requiredItem.length === 0 ? 'タイトル' : '・タイトル');
        }
        if (!body.content) {
          requiredItem.push(requiredItem.length === 0 ? '本文' : '・本文');
        }
        if (!body.executeAt) {
          requiredItem.push(requiredItem.length === 0 ? '送信日時' : '・送信日時');
        }
        mergeState({ errorMessage: [`${requiredItem.join('')}は必須項目です`] });
        return;
      }

      const submitBody: MailBulkDeliveryUpdateFormResponse = createSubmitBody(body, draftFlag);

      mergeState({ isLoading: true });

      $.mailBulkDeliveryApi
        .mailBulkDeliveryUpdate(submitBody)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (res.data.result) {
            const message = draftFlag ? '下書き保存しました。' : '送信しました。';
            mergeState({ isLoading: false, isModal: true, modalMessage: message, errorMessage: [] });
          } else {
            mergeState({ isLoading: false, errorMessage: ['エラーが発生しました。'] });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
    },
    [$.mailBulkDeliveryApi, createSubmitBody, mergeState]
  );

  return (
    <>
      <Modal
        centered
        isModal={$.isModal}
        body={
          <>
            <p className="text-center">{$.modalMessage}</p>
            <div className="text-center">
              <Button
                data-testid={testid('ok-button')}
                onClick={() => history.push(Url.TENSAKU.MAIL_BULK_DELIVERY_LIST)}
              >
                OK
              </Button>
            </div>
          </>
        }
      />
      <LoadingSpinner isLoading={$.isLoading}>
        {$.errorMessage.length
          ? $.errorMessage.map((errorMessage) => (
              <Alert variant="danger" key={errorMessage}>
                {errorMessage}
              </Alert>
            ))
          : undefined}
        {$.forms.mailBulkDeliveryId >= 0 && $.templateList[0].mailTemplateId >= 0 ? (
          <Form className="mt-4">
            <Row className="g-2 mb-4">
              <MailBulkDeliveryDetailForms setForms={setForms} formData={$.forms} templateDataList={$.templateList} />
            </Row>
            <Button
              data-testid={testid('draft-button')}
              className="me-2"
              onClick={() => {
                onSubmit($.forms, true);
              }}
            >
              下書き保存
            </Button>
            <Button
              data-testid={testid('submit-button')}
              onClick={() => {
                onSubmit($.forms, false);
              }}
            >
              送信する
            </Button>
          </Form>
        ) : undefined}
      </LoadingSpinner>
    </>
  );
};
