import React from 'react';
import { Form, Table } from 'react-bootstrap';

import { PointExchangeDestinationListOutputResponse, IncResultOutputResponse } from '../../../api-client';
import { Textarea } from '../../atoms/Textarea';
import { DateAndTime } from '../../molecules/DateAndTime';
import { Button } from '../../atoms/Button';
import { Title } from '../../atoms/Title';
import { Checkbox } from '../../atoms/Checkbox';
import { Alert } from '../../atoms/Alert';

export interface Props {
  item: PointExchangeDestinationListOutputResponse;
  handleChangeTextarea: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDatepickerBegin: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeTimepickerBegin: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDatepickerEnd: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeTimepickerEnd: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  updResult: IncResultOutputResponse;
}

export const PointExchangeTable: React.VFC<Props> = ({
  item,
  handleChangeTextarea,
  handleChangeCheckbox,
  handleChangeDatepickerBegin,
  handleChangeTimepickerBegin,
  handleChangeDatepickerEnd,
  handleChangeTimepickerEnd,
  handleSubmit,
  updResult,
}): JSX.Element => {
  return (
    <>
      <Title className="mb-4">{item.name}</Title>
      {updResult?.result && <Alert variant="success">更新しました。</Alert>}
      {updResult?.errorCode && updResult?.errorMessage && (
        <Alert variant="danger">{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}
      {!updResult?.errorCode && updResult?.errorMessage && <Alert variant="danger">{updResult.errorMessage}</Alert>}
      <Form className="mb-4" onSubmit={handleSubmit}>
        <Table>
          <tbody>
            <tr>
              <th className="col-2">交換停止時のメッセージ</th>
              <td>
                <Textarea value={item.disableMessage} onChange={handleChangeTextarea} />
              </td>
            </tr>
            <tr>
              <th>停止期間</th>
              <td className="py-4">
                <Checkbox label="すぐに交換停止する" checked={item.immediateStopFlg} onChange={handleChangeCheckbox} />
                <hr />
                あるいは、以下の期間停止する：
                <br />
                <div className="mt-2">
                  開始日時：
                  <div className="d-inline">
                    <DateAndTime
                      timestamp={item.disableBeginTimestamp!}
                      handleChangeDatepicker={handleChangeDatepickerBegin}
                      handleChangeTimepicker={handleChangeTimepickerBegin}
                    />
                    <span className="d-inline-block mx-2">
                      - こちらを指定せずに終了日時だけ指定した場合、すぐに交換停止します。
                    </span>
                  </div>
                  <br />
                  終了日時：
                  <div className="d-inline">
                    <DateAndTime
                      timestamp={item.disableEndTimestamp!}
                      handleChangeDatepicker={handleChangeDatepickerEnd}
                      handleChangeTimepicker={handleChangeTimepickerEnd}
                    />
                    <span className="d-inline-block mx-2">
                      - こちらを指定せずに開始日時だけ指定した場合、開始日時から無期限に交換停止します。
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <Button type="submit">更新</Button>
      </Form>
    </>
  );
};
