import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
// eslint-disable-next-line import/no-cycle
import { AnnounceListApi } from '../../../api-client';
import type { AnnounceListOutputResponse, AnnounceListOutputListItemResponse } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
// eslint-disable-next-line import/no-cycle
import { AnnounceTable } from '../../organisms/Table/AnnounceTable';

const MAX_RECORD_PER_PAGE = 100;

export interface State {
  api: AnnounceListApi;
  list: AnnounceListOutputListItemResponse[];
  listPerPage: AnnounceListOutputListItemResponse[];
  currentPage: number;
  totalPage: number;
}

export const AnnouncePage: React.VFC = () => {
  const history = useHistory();

  const { state: $, mergeState } = useLargeState<State>({
    api: new AnnounceListApi(),
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  });

  useEffect(() => {
    $.api.announceList().then((res: AxiosResponse<AnnounceListOutputResponse>) => {
      const sortedList = res.data.list.sort((a, b) => b.id - a.id);
      mergeState({
        list: sortedList,
        listPerPage: sortedList.slice(0, MAX_RECORD_PER_PAGE),
        totalPage: Math.ceil(Number(sortedList?.length) / MAX_RECORD_PER_PAGE),
      });
    });
  }, [$.api, mergeState]);

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.ANNOUNCE}</Title>
      <div className="d-flex justify-content-end mb-4">
        <Button variant="link" className="text-secondary" onClick={() => history.push(Url.KEISAI.ANNOUNCE_MODIFY)}>
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          新規作成
        </Button>
      </div>
      <AnnounceTable listPerPage={$.listPerPage} />
      <div className="mb-4">
        <PaginationWithEllipsis
          currentPage={$.currentPage}
          totalPage={$.totalPage}
          handleClick={(page) => {
            if (!page || page > $.totalPage) return;
            mergeState({
              listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
              currentPage: page,
            });
          }}
        />
      </div>
    </>
  );
};
