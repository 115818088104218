import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import {
  BillingApi,
  BillingDestinationShopBulkUpdateFormResponse,
  BillingSuggestListOutputResponse,
  IncResultOutputResponse,
  BillingDestinationShopListOutputResponse,
} from '../../../api-client';
import { BillingDestinationShopTable } from '../../organisms/Table/BillingDestinationShopTable';
import { convertNanToUndefined } from '../../../utils/functionsForBilling';
import { PaginationWithEllipsisBilling } from '../../molecules/PaginationWithEllipsisBilling';
import { BILLING_DEFAULT_FADE_TIME } from '../../../BillingConstants';
import { BillingUpdateResultModal } from '../../organisms/Modal/BillingUpdateResultModal';

interface Props {
  id: number;
  clientId: number;
  billingSuggestClientResult: { isSuccess: boolean; data: BillingSuggestListOutputResponse[] | undefined };
  billingSuggestIncAccountResult: { isSuccess: boolean; data: BillingSuggestListOutputResponse[] | undefined };
}

export const BillingDestShopRelationPage: React.VFC<Props> = ({
  id,
  clientId,
  billingSuggestClientResult,
  billingSuggestIncAccountResult,
}) => {
  const billingApi = new BillingApi();
  const [list, setList] = useState<BillingDestinationShopListOutputResponse[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const MAX_RECORD_PER_PAGE = 20;
  /*
   * 一括紐付け
   */
  const [destShopBulkUpdateForm, setDestShopBulkUpdateForm] = useState({
    billingDestinationId: id,
    clientId,
    incAccountId: undefined,
    contractShopIds: undefined,
    branchNumber: undefined,
    billingDestName: undefined,
    relationFlg: false,
  } as BillingDestinationShopBulkUpdateFormResponse);

  useEffect(() => {
    handleClickSearchBtn();
  }, []);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const handleClickSearchBtn = () => {
    const converted = convertNanToUndefined(destShopBulkUpdateForm) as BillingDestinationShopBulkUpdateFormResponse;

    billingApi
      .billingDestShopList(
        id,
        converted.clientId,
        converted.incAccountId,
        converted.contractShopIds,
        converted.branchNumber,
        converted.billingDestName,
        converted.relationFlg
      )
      .then((res) => {
        setList(res.data);
        setCurrentPage(1);
      });
  };

  return (
    <>
      {updResult !== undefined && (
        <BillingUpdateResultModal
          fadeoutTime={BILLING_DEFAULT_FADE_TIME}
          updResult={updResult}
          setUpdResult={setUpdResult}
        />
      )}
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h3>検索</h3>
              <Form noValidate>
                <Row className="g-2 mb-4">
                  <Col>
                    <Form.Group controlId="clientId">
                      <Row>
                        <Col className="col-4">
                          <Form.Label className="pt-2">クライアント</Form.Label>
                        </Col>
                        <Col className="col-8">
                          {billingSuggestClientResult.isSuccess && (
                            <Select
                              isSearchable
                              isClearable
                              isDisabled
                              defaultValue={billingSuggestClientResult.data?.find((v) => v.value === clientId)}
                              options={billingSuggestClientResult.data}
                              // onChange={(event) => {
                              //   setDestShopBulkUpdateForm({
                              //     ...destShopBulkUpdateForm,
                              //     clientId: Number(event?.value),
                              //   });
                              // }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="salesId">
                      <Row>
                        <Col className="col-4">
                          <Form.Label className="pt-2">担当営業</Form.Label>
                        </Col>
                        <Col className="col-8">
                          {billingSuggestIncAccountResult.isSuccess && (
                            <Select
                              isSearchable
                              isClearable
                              options={billingSuggestIncAccountResult.data}
                              onChange={(event) => {
                                setDestShopBulkUpdateForm({
                                  ...destShopBulkUpdateForm,
                                  incAccountId: Number(event?.value),
                                });
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="g-2 mb-4">
                  <Col>
                    <Form.Group controlId="shopId">
                      <Row>
                        <Col className="col-4">
                          <Form.Label className="pt-2">契約店舗ID（カンマ区切りで複数可）</Form.Label>
                        </Col>
                        <Col className="col-8">
                          <Form.Control
                            type="text"
                            name="contractShopId"
                            placeholder="契約店舗ID（カンマ区切りで複数可）"
                            onChange={(event) => {
                              setDestShopBulkUpdateForm({
                                ...destShopBulkUpdateForm,
                                contractShopIds: event.target.value,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="branchNumber">
                      <Row>
                        <Col className="col-4">
                          <Form.Label className="pt-2">請求送付先枝番</Form.Label>
                        </Col>
                        <Col className="col-8">
                          <Form.Control
                            type="number"
                            name="branchNumber"
                            placeholder="11111"
                            onChange={(event) => {
                              setDestShopBulkUpdateForm({
                                ...destShopBulkUpdateForm,
                                branchNumber: event.target.value === '' ? undefined : Number(event.target.value),
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="g-2 mb-4">
                  <Col>
                    <Form.Group controlId="billingDestName">
                      <Row>
                        <Col className="col-4">
                          <Form.Label className="pt-2">請求送付先案件名</Form.Label>
                        </Col>
                        <Col className="col-8">
                          <Form.Control
                            type="text"
                            name="billingDestName"
                            placeholder="請求送付先案件名"
                            onChange={(event) => {
                              setDestShopBulkUpdateForm({
                                ...destShopBulkUpdateForm,
                                billingDestName: event.target.value,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="relationFlg">
                      <Row>
                        <Col className="col-4">
                          <Form.Label className="pt-2">紐付きフラグ</Form.Label>
                        </Col>
                        <Col className="col-8">
                          <Form.Check.Input
                            className="flex-shrink-0 me-2"
                            type="checkbox"
                            name="relationFlg"
                            style={{ marginTop: '0.75rem' }}
                            onChange={(event) => {
                              setDestShopBulkUpdateForm({
                                ...destShopBulkUpdateForm,
                                relationFlg: event.target.checked,
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center">
                  <Button onClick={handleClickSearchBtn}>検索する</Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <BillingDestinationShopTable
            billingDestinationId={id}
            list={list}
            setList={setList}
            page={currentPage}
            setUpdResult={setUpdResult}
            destShopBulkUpdateForm={destShopBulkUpdateForm}
            handleClickSearchBtn={handleClickSearchBtn}
          />

          <div className="mt-4 d-flex justify-content-center">
            <PaginationWithEllipsisBilling
              maxRecordPerPage={MAX_RECORD_PER_PAGE}
              totalDataNum={list.length}
              handleClick={(e) => handleSetCurrentPage(e.page)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
