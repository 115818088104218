import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Col, InputGroup, ListGroup, Tabs, Tab } from 'react-bootstrap';

import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { BrandApi, CampaignApi, RecommendApiClientListOutputResponse } from '../../../api-client';

const sampleGetData = {
  searchOemList: [...Array(21)].map((_, i) => {
    return { apiClientId: i + 1, apiClientName: `OEM${i + 1}` };
  }),
  oemList: [
    { apiClientId: 1, apiClientName: 'OEM1' },
    { apiClientId: 2, apiClientName: 'OEM2' },
    { apiClientId: 3, apiClientName: 'OEM3' },
  ],
};

const sampleSearchData = {
  searchOemList: [...Array(3)].map((_, i) => {
    return { apiClientId: i + 11, apiClientName: `OEM${i + 11}` };
  }),
  oemList: [
    { apiClientId: 1, apiClientName: 'OEM1' },
    { apiClientId: 2, apiClientName: 'OEM2' },
    { apiClientId: 3, apiClientName: 'OEM3' },
  ],
};

export interface Props {
  mode: 'brand' | 'campaign';
  id?: number;
  isOemModal: boolean;
  setIsOemModal: React.Dispatch<React.SetStateAction<boolean>>;
  oemList: { oemId: number; oemName: string }[];
  setOemList: React.Dispatch<React.SetStateAction<{ oemId: number; oemName: string }[]>>;
}

const TAB_TYPE = {
  ALL: 'all',
  SELECTED: 'selected',
} as const;

const MODE = {
  BRAND: 'brand',
  CAMPAIGN: 'campaign',
} as const;

const MAX_RECORD_PER_PAGE = 10;

export const OemModal: React.VFC<Props> = ({ mode, id, isOemModal, setIsOemModal, oemList, setOemList }) => {
  const [searchText, setSearchText] = useState<string>('');

  const [searchList, setSearchList] = useState<{ apiClientId: number; apiClientName: string; selected: boolean }[]>([]);
  const [searchListPerPage, setSearchListPerPage] = useState<any[]>([]);
  const [searchListCurrentPage, setSearchListCurrentPage] = useState<number>(1);
  const [searchListTotalPage, setSearchListTotalPage] = useState<number>(1);

  const [selectedList, setSelectedList] = useState<{ apiClientId: number; apiClientName: string; selected: boolean }[]>(
    []
  );
  const [selectedListPerPage, setSelectedListPerPage] = useState<any[]>([]);
  const [selectedListCurrentPage, setSelectedListCurrentPage] = useState<number>(1);
  const [selectedListTotalPage, setSelectedListTotalPage] = useState<number>(1);

  const [tabType, setTabType] = useState<'all' | 'selected'>('all');

  const brandApi = new BrandApi();
  const campaignApi = new CampaignApi();

  useEffect(() => {
    if (mode === MODE.BRAND) {
      brandApi
        .brandTargetApiclientList({ keyword: '' }, id)
        .then((res: AxiosResponse<RecommendApiClientListOutputResponse>) => {
          // const data = sampleGetData;
          const { data } = res;
          initialSetup(data);
        });
    } else if (mode === MODE.CAMPAIGN) {
      campaignApi
        .campaignTargetApiclientList({ keyword: '' }, id)
        .then((res: AxiosResponse<RecommendApiClientListOutputResponse>) => {
          // const data = sampleGetData;
          const { data } = res;
          initialSetup(data);
        });
    }
  }, [isOemModal]);

  const initialSetup = (data: RecommendApiClientListOutputResponse | null) => {
    const searchOemList =
      data?.searchOemList.map((item) => {
        return {
          ...item,
          selected: oemList.some((selectedItem) => item.apiClientId === selectedItem.oemId),
        };
      }) || [];
    setSearchList(searchOemList);
    setSearchListPerPage(searchOemList.slice(0, MAX_RECORD_PER_PAGE));
    setSearchListTotalPage(Math.ceil(Number(searchOemList.length) / MAX_RECORD_PER_PAGE));
    setSearchListCurrentPage(1);
    const selectOemList =
      oemList.map((item) => {
        return { apiClientId: item.oemId, apiClientName: item.oemName, selected: true };
      }) || [];
    setSelectedList(selectOemList);
    setSelectedListPerPage(selectOemList.slice(0, MAX_RECORD_PER_PAGE));
    setSelectedListTotalPage(Math.ceil(Number(selectOemList.length) / MAX_RECORD_PER_PAGE));
    setSelectedListCurrentPage(1);
  };

  const handleClickSearch = () => {
    if (mode === MODE.BRAND) {
      brandApi
        .brandTargetApiclientList({ keyword: searchText }, id)
        .then((res: AxiosResponse<RecommendApiClientListOutputResponse>) => {
          // const data = sampleSearchData;
          const { data } = res;
          searchSetup(data);
        });
    } else if (mode === MODE.CAMPAIGN) {
      campaignApi
        .campaignTargetApiclientList({ keyword: searchText }, id)
        .then((res: AxiosResponse<RecommendApiClientListOutputResponse>) => {
          // const data = sampleGetData;
          const { data } = res;
          initialSetup(data);
        });
    }
  };

  const searchSetup = (data: RecommendApiClientListOutputResponse) => {
    const searchOemList = data.searchOemList.map((item) => {
      return {
        ...item,
        selected: selectedList.some((selectedItem) => item.apiClientId === selectedItem.apiClientId),
      };
    });
    setSearchList(searchOemList);
    setSearchListPerPage(searchOemList.slice(0, MAX_RECORD_PER_PAGE));
    setSearchListTotalPage(Math.ceil(Number(searchOemList.length) / MAX_RECORD_PER_PAGE));
    setSearchListCurrentPage(1);
  };

  const handleClickSelectAll = () => {
    const nonSeletedSearchList = searchList
      .filter((item) => !item.selected)
      .map((item) => {
        return { ...item, selected: true };
      });
    const newSearchList = searchList.map((item) => {
      return { ...item, selected: true };
    });
    setSearchList(newSearchList);
    setSearchListPerPage(
      newSearchList.slice(
        (searchListCurrentPage - 1) * MAX_RECORD_PER_PAGE,
        searchListCurrentPage * MAX_RECORD_PER_PAGE
      )
    );

    const newSelectedList = [...selectedList, ...nonSeletedSearchList].sort((a, b) => a.apiClientId - b.apiClientId);
    setSelectedList(newSelectedList);
    setSelectedListPerPage(
      newSelectedList.slice(
        (selectedListCurrentPage - 1) * MAX_RECORD_PER_PAGE,
        selectedListCurrentPage * MAX_RECORD_PER_PAGE
      )
    );
    setSelectedListTotalPage(Math.ceil(Number(newSelectedList.length) / MAX_RECORD_PER_PAGE));
    setSelectedListCurrentPage(1);
  };

  const handleClickSave = () => {
    setOemList(
      selectedList.map((item) => {
        return { oemId: item.apiClientId, oemName: item.apiClientName };
      })
    );
    onHide();
  };

  const onHide = () => {
    setIsOemModal(false);
    setTabType(TAB_TYPE.ALL);
    setSearchText('');
  };

  const handleClickCheckbox = (target: { apiClientId: number; apiClientName: string; selected: boolean }) => {
    const newSearchList = searchList.map((item) => {
      return item.apiClientId === target.apiClientId ? { ...target, selected: !target.selected } : item;
    });
    setSearchList(newSearchList);
    setSearchListPerPage(
      newSearchList.slice(
        (searchListCurrentPage - 1) * MAX_RECORD_PER_PAGE,
        searchListCurrentPage * MAX_RECORD_PER_PAGE
      )
    );

    let newSelectedList;
    if (tabType === TAB_TYPE.ALL && !target.selected) {
      newSelectedList = [...selectedList, { ...target, selected: true }].sort((a, b) => a.apiClientId - b.apiClientId);
    } else {
      newSelectedList = selectedList.filter((item) => item.apiClientId !== target.apiClientId);
    }
    setSelectedList(newSelectedList);
    setSelectedListPerPage(
      newSelectedList.slice(
        (selectedListCurrentPage - 1) * MAX_RECORD_PER_PAGE,
        selectedListCurrentPage * MAX_RECORD_PER_PAGE
      )
    );
    setSelectedListTotalPage(Math.ceil(Number(newSelectedList.length) / MAX_RECORD_PER_PAGE));
  };

  const renderListItem = (): JSX.Element[] => {
    const list = tabType === TAB_TYPE.ALL ? searchListPerPage : selectedListPerPage;

    return list.map((item) => {
      return (
        <ListGroup.Item key={item.apiClientId}>
          <Form.Check id={String(item.apiClientId)} className="d-flex">
            <Form.Check.Input
              className="flex-shrink-0 me-2"
              type="checkbox"
              onChange={() => handleClickCheckbox(item)}
              checked={item.selected}
            />
            <Form.Check.Label className="w-100">{`${item.apiClientId} ${item.apiClientName}`}</Form.Check.Label>
          </Form.Check>
        </ListGroup.Item>
      );
    });
  };

  const renderTabInner = (): JSX.Element => {
    return (
      <>
        <InputGroup className="mb-4">
          <Form.Control
            className="w-50"
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Button className="flex-shrink-1" onClick={handleClickSearch} disabled={tabType === TAB_TYPE.SELECTED}>
            検索
          </Button>
        </InputGroup>
        {tabType === TAB_TYPE.ALL && searchList.length > 0 && (
          <div className="mb-4">
            <PaginationWithEllipsis
              currentPage={searchListCurrentPage}
              totalPage={searchListTotalPage}
              handleClick={(page) => {
                if (!page || page > searchListTotalPage) return;
                setSearchListPerPage(searchList.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
                setSearchListCurrentPage(page);
              }}
            />
          </div>
        )}
        {tabType === TAB_TYPE.SELECTED && selectedList.length > 0 && (
          <div className="mb-4">
            <PaginationWithEllipsis
              currentPage={selectedListCurrentPage}
              totalPage={selectedListTotalPage}
              handleClick={(page) => {
                if (!page || page > selectedListTotalPage) return;
                setSelectedListPerPage(
                  selectedList.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE)
                );
                setSelectedListCurrentPage(page);
              }}
            />
          </div>
        )}

        <Button size="sm" onClick={handleClickSelectAll} className="mb-4" disabled={tabType === TAB_TYPE.SELECTED}>
          全選択
        </Button>
        <Col style={{ minHeight: '50vh' }}>
          <ListGroup>{renderListItem()}</ListGroup>
        </Col>
      </>
    );
  };

  return (
    <Modal
      onHide={onHide}
      isModal={isOemModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button variant="link" className="ms-2" onClick={onHide}>
              キャンセル
            </Button>
            <Button className="ms-2" onClick={handleClickSave}>
              保存
            </Button>
          </div>

          <Tabs
            defaultActiveKey="all"
            activeKey={tabType}
            onSelect={(key) => setTabType(key as 'all' | 'selected')}
            className="mb-4"
          >
            <Tab eventKey={TAB_TYPE.ALL} title="全て">
              {renderTabInner()}
            </Tab>

            <Tab eventKey={TAB_TYPE.SELECTED} title="選択済み">
              {renderTabInner()}
            </Tab>
          </Tabs>
        </>
      }
    />
  );
};
