import React, { useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLargeState } from '../../../../../../hooks/useLargeState';
import { useSafeContext } from '../../../../../../hooks/useSafeContext';
import sass from '../../../../../../scss/templates/CorrectionTemplate.module.scss';
import { CorrectionContext } from '../../../../../../store/correctionStore';
import { when } from '../../../../../../utils/functions';
import { Button } from '../../../../../atoms/Button';
import { SideBy } from '../../../../../atoms/SideBy';
import { CorrectionWorkType, TemplateFormMode } from '../../../../../pages/Correction/constants';
import {
  getCommonCommentFormList,
  isForceCorrect,
  jumpTo,
  nativeAlert,
  nativeConfirm,
  noCorrectionTarget,
  questionPreSend,
} from '../../../../../pages/Correction/utils';
import { AccordionList } from './AccordionList';

export const ResendForm: React.FC = () => {
  const {
    largeState: { state: $, useBindSet },
  } = useSafeContext(CorrectionContext);
  const { state: f, onChangeSet: set } = useLargeState<{
    note?: string;
  }>({});
  const setLoading = useBindSet('isLoading');
  const history = useHistory();

  const onConfirm = useCallback(
    async (alsoEnqueteNgFlg: boolean) => {
      try {
        const ret = await questionPreSend(
          () => ({
            common: {
              additionalNoticeWord: f.note,
              applyId: $.applyId,
              ngCommentList: getCommonCommentFormList(TemplateFormMode.NG, $).map(
                ({ dp, tempId: templateId, content: ngMessage }) => ({
                  displayPoint: { typeCode: dp.type ?? NaN, targetId: dp.targetId },
                  templateId,
                  ngMessage,
                })
              ),
              forcedCorrectFlg: isForceCorrect($.res_correctReceiptDisplayControl, $.res_correctEnqueteDisplayControl),
            },
          }),
          async ({ common }) => {
            if (common.ngCommentList.find((x) => x.templateId === undefined)) {
              nativeAlert('テンプレートIDが設定されていない項目があります');
              return;
            }
            if (!common.additionalNoticeWord && common.ngCommentList.length === 0) {
              nativeAlert('NGコメントを入力してください');
              return;
            }
            if (!$.isEnquete) {
              await $.api_correctReceipt
                .receiptCorrectionNgExecute({
                  ...common,
                  alsoEnqueteNgFlg,
                })
                .then(() => {
                  $.api_correct
                    .normalCorrectTargetDistribute({
                      beforeApplyId: $.applyId,
                      workTypeCode: CorrectionWorkType.RESUBMIT_REQUEST,
                    })
                    .then(({ data }) => jumpTo(history, data))
                    .catch(() => noCorrectionTarget(history));
                });
            } else {
              $.saveAnswer!();
              await $.api_correctEnquete.enqueteCorrectionNgExecute(common).then(() => {
                $.api_correct
                  .normalCorrectTargetDistribute({
                    beforeApplyId: $.applyId,
                    workTypeCode: CorrectionWorkType.RESUBMIT_REQUEST,
                  })
                  .then(({ data }) => jumpTo(history, data))
                  .catch(() => noCorrectionTarget(history));
              });
            }
          },
          undefined,
          setLoading
        );
      } catch (error) {
        console.error(error);
        nativeAlert('エラーが発生しました');
      }
    },
    [$, f.note, history, setLoading]
  );
  return (
    <>
      <AccordionList mode={TemplateFormMode.NG} />
      <Form>
        <Form.Group>
          <Form.Label>追加の通知文言</Form.Label>
          <p>※各項目の文言以外にユーザに伝えたいことがある場合に入力</p>
          <Form.Control size="lg" value={f.note ?? ''} onChange={set('note')} />
        </Form.Group>
      </Form>
      <SideBy>
        <Button className={sass.btn_red} onClick={() => onConfirm(false)}>
          NG（再提出）
        </Button>
        {when(
          !$.isEnquete,
          <Button variant="outline-danger" onClick={() => onConfirm(true)}>
            アンケートもすべてNG（再提出）
          </Button>
        )}
      </SideBy>
    </>
  );
};
