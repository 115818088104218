import { Table } from 'react-bootstrap';
import React from 'react';
import scss from '../../scss/organisms/scrollTable.module.scss';
import type { AnswerForAnswerNgHistoryListResponse, AnswerNgHistoryListOutputResponse } from '../../api-client';

interface Props {
  data?: AnswerNgHistoryListOutputResponse;
}

export const AnswerHistoryListTable: React.FC<Props> = ({ data }) => {
  const headers = data?.headers ?? [];
  const questionAnswerList = data?.questionAnswers ?? [];
  const colwidth = 300;
  const tableWidth = `${headers.length * colwidth}px`;
  return (
    <div style={{ overflow: 'auto', width: '100vw', height: '100vh' }}>
      <Table className={scss.table_sticky} style={{ width: tableWidth }} bordered>
        <thead>
          <tr>
            {headers.map(({ title, type }) => (
              <th className={HEADER_ARRAY.get(type)} style={{ width: `${colwidth}px` }}>
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {questionAnswerList.map(({ question, answers }) => (
            <tr>
              <th className={`bg-light ${scss.table_sticky_th}`}>{question}</th>
              {answers.map((answer) => createAnswerHistoryContent(answer))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const createAnswerHistoryContent = (answer: AnswerForAnswerNgHistoryListResponse) => {
  const { imageUrls, contents } = answer;
  if (imageUrls.length > 0)
    return imageUrls.map((url) => (
      <td>
        <img src={url} alt="" width="300px" />
      </td>
    ));

  return <td>{contents}</td>;
};

enum AnswerHistoryHeaderType {
  QUESTION,
  CUSTOMERS_ANSWER,
  INCS_ANSWER,
  NG,
}

const HEADER_ARRAY = new Map<AnswerHistoryHeaderType, string>([
  [AnswerHistoryHeaderType.QUESTION, `bg-dark text-white ${scss.table_stick_question_th}`],
  [AnswerHistoryHeaderType.CUSTOMERS_ANSWER, `bg-success text-white ${scss.table_sticky_th}`],
  [AnswerHistoryHeaderType.INCS_ANSWER, `bg-secondary text-white ${scss.table_sticky_th}`],
  [AnswerHistoryHeaderType.NG, `bg-warning text-white ${scss.table_sticky_th}`],
]);
