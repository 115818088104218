import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import scss from '../../scss/molecules/tagsChoice.module.scss';
import { Button } from '../atoms/Button';

export interface Props {
  tags: string[];
  className?: string;
  pill?: boolean;
  onDelete: (tag: string) => void;
  onApply: () => void;
  disabled?: boolean;
}

export const TagsChoice: React.VFC<Props> = ({ tags, onDelete, onApply, className, pill = true, disabled }) => {
  return (
    <div className="position-relative">
      <div className={`${scss.wrap_tagsinput} ${className}`}>
        <ul className={scss.tags} style={{ paddingRight: '100px' }}>
          {tags?.map((tag) => (
            <li key={tag} className={`${scss.tag} ${pill && 'rounded-pill'}`}>
              <span>{tag}</span>
              <span className={scss.tag_close} onClick={() => onDelete(tag)} aria-hidden="true">
                <FontAwesomeIcon icon={faTimes} fixedWidth />
              </span>
            </li>
          ))}
        </ul>
        <div className="position-absolute top-50 translate-middle-y" style={{ right: '1rem' }}>
          <Button className="text-nowrap" onClick={onApply} disabled={disabled}>
            選択
          </Button>
        </div>
      </div>
    </div>
  );
};
