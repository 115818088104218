import React, { useEffect } from 'react';
import { Accordion, ListGroup } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBriefcase,
  faChartBar,
  faClipboardList,
  faCommentDollar,
  faUserCheck,
} from '@fortawesome/free-solid-svg-icons';
import scss from '../../scss/organisms/sidebarlayout.module.scss';
import logo from '../../img/layout/corporate_white.svg';
import { Url } from '../../constants/Url';
import { MymenuListApi, MymenuListOutputResponse } from '../../api-client';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useLargeState } from '../../hooks/useLargeState';
import { BILLING_APPROVAL_TYPE } from '../../BillingConstants';
import { isInternalWorker, isInternalWorkerOrQc } from '../../RoleConstants';
import { Environment } from '../atoms/Environment';

const KEISAI_SCREENS = [
  { label: 'クライアント一覧', url: Url.KEISAI.CLIENT, displayIfRoleIsSales: false },
  { label: 'アンケート一覧', url: Url.KEISAI.ENQUETE, displayIfRoleIsSales: true },
  { label: '設問一覧', url: Url.KEISAI.QUESTION, displayIfRoleIsSales: false },
  { label: '申込書一覧', url: Url.KEISAI.APPLICATION, displayIfRoleIsSales: false },
  { label: '掲載依頼一覧', url: Url.KEISAI.POSTING_REQUEST_LIST, displayIfRoleIsSales: false },
  { label: 'モニター一覧', url: Url.KEISAI.MONITOR_LIST, displayIfRoleIsSales: true },
  { label: 'モニター応募履歴一覧', url: Url.KEISAI.MONITOR_APPLY_HISTORY_LIST, displayIfRoleIsSales: true },
  { label: 'モニタールールセット一覧', url: Url.KEISAI.MONITOR_RULE_SET, displayIfRoleIsSales: false },
  { label: '実店舗一覧', url: Url.KEISAI.SHOP, displayIfRoleIsSales: false },
  { label: '一括ステータス予約', url: Url.KEISAI.BULK_STATUS_RESERVATION, displayIfRoleIsSales: false },
  { label: 'クライアントアカウント設定', url: Url.KEISAI.CLIENT_ACCOUNT_SETTING, displayIfRoleIsSales: true },
  { label: 'クライアント組織設定', url: Url.KEISAI.CLIENT_ORGANIZATION_SETTING, displayIfRoleIsSales: true },
  { label: '掲載予約一覧', url: Url.KEISAI.POSTING_RESERVATION_LIST, displayIfRoleIsSales: false },
  { label: '営業時間文言設定', url: Url.KEISAI.ABOUT_OPENING, displayIfRoleIsSales: false },
  { label: 'ポイント交換停止設定', url: Url.KEISAI.POINT_EXCHANGE, displayIfRoleIsSales: false },
  { label: 'APIクライアント一覧', url: Url.KEISAI.API_CLIENT, displayIfRoleIsSales: false },
  { label: 'クライアント派遣マスト数一覧', url: Url.KEISAI.CLIENT_OF_REQUIRED_NUMBER, displayIfRoleIsSales: false },
  { label: '契約店舗一覧', url: Url.KEISAI.CONTRACT_SHOP, displayIfRoleIsSales: false },
  { label: 'ブランド一覧', url: Url.KEISAI.BRAND, displayIfRoleIsSales: false },
  { label: 'OEM表示先設定(ブランド)', url: Url.KEISAI.BRAND_OEM, displayIfRoleIsSales: false },
  { label: 'キャンペーン一覧', url: Url.KEISAI.CAMPAIGN, displayIfRoleIsSales: false },
  { label: 'OEM表示先設定(キャンペーン)', url: Url.KEISAI.CAMPAIGN_OEM, displayIfRoleIsSales: false },
  { label: '強制当選', url: Url.KEISAI.FORCE_WINNING, displayIfRoleIsSales: false },
  { label: '強制落選', url: Url.KEISAI.FORCE_UNSEATED, displayIfRoleIsSales: false },
  { label: '配信メール一覧', url: Url.KEISAI.MAIL, displayIfRoleIsSales: false },
  { label: 'テンプレートメール一覧', url: Url.KEISAI.MAIL_TEMPLATE, displayIfRoleIsSales: false },
  { label: '事前アンケートダウンロード', url: Url.KEISAI.PRIOR_ENQUETE_FILE, displayIfRoleIsSales: true },
  { label: '事後アンケートダウンロード', url: Url.KEISAI.POST_ENQUETE_FILE, displayIfRoleIsSales: true },
  { label: 'レシートダウンロード', url: Url.KEISAI.RECEIPT_FILE, displayIfRoleIsSales: true },
  { label: 'アンケート画像ダウンロード ', url: Url.KEISAI.ENQUETE_FILE, displayIfRoleIsSales: true },
  { label: 'お知らせ一覧', url: Url.KEISAI.ANNOUNCE, displayIfRoleIsSales: false },
  { label: '運営お知らせ一覧', url: Url.KEISAI.MANAGEMENT_ANNOUNCE, displayIfRoleIsSales: false },
  { label: 'バナー広告設定一覧', url: Url.KEISAI.BANNER_LIST, displayIfRoleIsSales: false },
  { label: 'SQLテンプレート一覧', url: Url.KEISAI.SQL_TMP, displayIfRoleIsSales: false },
  { label: 'SQLクエリ一覧', url: Url.KEISAI.SQL_QUERY, displayIfRoleIsSales: false },
  { label: 'OEM表示範囲設定', url: Url.KEISAI.MONITOR_APICLIENTS, displayIfRoleIsSales: false },
];

const BILLING_SCREENS = [
  { label: '請求データ消込', url: Url.BILLING.BILLING_PAID_APPLY, displayIfRoleIsSales: false },
  { label: '請求書一覧（営業）', url: `${Url.BILLING.BILLING_LIST}/isSales`, displayIfRoleIsSales: true },
  { label: '請求書一覧（営業上長）', url: `${Url.BILLING.BILLING_LIST}/isSmanager`, displayIfRoleIsSales: false },
  { label: '請求書一覧（管理部）', url: `${Url.BILLING.BILLING_LIST}/isAccounting`, displayIfRoleIsSales: false },
  { label: '請求送付先一覧', url: Url.BILLING.BILLING_DESTINATION, displayIfRoleIsSales: false },
  { label: '祝祭日管理', url: Url.BILLING.HOLIDAY_MANAGE, displayIfRoleIsSales: false },
  { label: 'ジョブ実行履歴', url: Url.BILLING.JOB_HISTORY, displayIfRoleIsSales: false },
  { label: '代理店一覧', url: Url.BILLING.BILLING_AGENT_LIST, displayIfRoleIsSales: false },
  { label: '代理店フィー一覧', url: Url.BILLING.BILLING_AGENT_FEE_LIST, displayIfRoleIsSales: false },
  { label: '請求書ダウンロード', url: Url.BILLING.BILLING_FORM_DOWNLOAD, displayIfRoleIsSales: false },
  { label: '入金データダウンロード', url: Url.BILLING.DEPOSIT_DOWNLOAD, displayIfRoleIsSales: false },
  { label: '入金データアップロード', url: Url.BILLING.DEPOSIT_UPLOAD, displayIfRoleIsSales: false },
  {
    label: '非請求申請(ユーコミ)',
    url: `${Url.BILLING.YUUCOMI_MANAGER}/${BILLING_APPROVAL_TYPE.UUCOMI}`,
    displayIfRoleIsSales: false,
  },
  {
    label: '非請求承認(ユーコミ)',
    url: `${Url.BILLING.YUUCOMI_SUPERIOR}/${BILLING_APPROVAL_TYPE.UUCOMI}`,
    displayIfRoleIsSales: false,
  },
  {
    label: '非請求申請(営業)',
    url: `${Url.BILLING.YUUCOMI_MANAGER}/${BILLING_APPROVAL_TYPE.SALES}`,
    displayIfRoleIsSales: false,
  },
  {
    label: '非請求承認(営業)',
    url: `${Url.BILLING.YUUCOMI_SUPERIOR}/${BILLING_APPROVAL_TYPE.SALES}`,
    displayIfRoleIsSales: false,
  },
];

// roleConstants で定義している ROLE_NAME に追加するべきだが、コンフリクトが発生してうまくマージできないので暫定対応でここに定義している。
// 後で上記のファイルに移動する。
const SIDE_BAR_ROLE_NAME = {
  SALES: '営業',
  SENIOR_SALES: '営業上長',
};

export interface State {
  api: MymenuListApi;
  groupList: MymenuListOutputResponse[];
}

export const SideBarLayout: React.VFC = () => {
  const { currentUser } = useCurrentUser();
  const history = useHistory();

  const { state: $, mergeState } = useLargeState<State>({ api: new MymenuListApi(), groupList: [] });

  useEffect(() => {
    if (!currentUser?.id) return;
    $.api.mymenuList(currentUser?.id).then((res: AxiosResponse<MymenuListOutputResponse[]>) => {
      mergeState({ groupList: res.data });
    });
  }, [currentUser?.id, $.api, mergeState]);

  const handleTransition = (url: string, state?: Record<string, unknown>) => {
    history.push({ state, pathname: url.startsWith('/') ? url : `/${url}` });
  };

  return (
    <>
      <div className="sidebar text-light">
        <div className={`${scss.wrap_logo} d-flex align-items-center p-3`}>
          <img src={logo} alt="ファンくるロゴ" className={`${scss.logo} "d-block"`} />
        </div>
        <br />
        <Environment />
        <p className={scss.sidebar_title}>マイメニュー</p>
        {$.groupList.map((g) => (
          <Accordion key={g.menuGroupId} defaultActiveKey="1" flush>
            <Accordion.Item eventKey="0" className="accordionInSidebar bg-transparent">
              <Accordion.Header>{g.menuGroupName}</Accordion.Header>
              <Accordion.Body className="p-0">
                <ListGroup>
                  {g.menus.map(({ id, name, url }) => (
                    <ListGroup.Item action key={id} onClick={() => handleTransition(url)}>
                      {name}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        ))}

        {
          // 社内ユーザの時だけ表示する
          isInternalWorker(currentUser?.role) && (
            <>
              <p className={scss.sidebar_title}>カテゴリタイトル</p>
              <Accordion defaultActiveKey="1" flush>
                <Accordion.Item eventKey="0" className="accordionInSidebar bg-transparent">
                  <Accordion.Header>
                    <FontAwesomeIcon icon={faChartBar} fixedWidth />
                    掲載
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <ListGroup>
                      {KEISAI_SCREENS.map(({ label, url, displayIfRoleIsSales }) => (
                        <>
                          {(currentUser?.role === SIDE_BAR_ROLE_NAME.SALES ||
                            currentUser?.role === SIDE_BAR_ROLE_NAME.SENIOR_SALES) &&
                            displayIfRoleIsSales && (
                              <ListGroup.Item action key={label} onClick={() => handleTransition(url)}>
                                {label}
                              </ListGroup.Item>
                            )}
                          {currentUser?.role !== SIDE_BAR_ROLE_NAME.SALES &&
                            currentUser?.role !== SIDE_BAR_ROLE_NAME.SENIOR_SALES && (
                              <ListGroup.Item action key={label} onClick={() => handleTransition(url)}>
                                {label}
                              </ListGroup.Item>
                            )}
                        </>
                      ))}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className="accordionInSidebar bg-transparent">
                  <Accordion.Header>
                    <FontAwesomeIcon icon={faChartBar} fixedWidth />
                    請求
                  </Accordion.Header>
                  <Accordion.Body className="p-0">
                    <ListGroup>
                      {BILLING_SCREENS.map(({ label, url, displayIfRoleIsSales }) => (
                        <>
                          {currentUser?.role === SIDE_BAR_ROLE_NAME.SALES && displayIfRoleIsSales && (
                            <ListGroup.Item action key={label} onClick={() => handleTransition(url)}>
                              {label}
                            </ListGroup.Item>
                          )}
                          {currentUser?.role === SIDE_BAR_ROLE_NAME.SENIOR_SALES &&
                            (displayIfRoleIsSales || url === `${Url.BILLING.BILLING_LIST}/isSmanager`) && (
                              <ListGroup.Item action key={label} onClick={() => handleTransition(url)}>
                                {label}
                              </ListGroup.Item>
                            )}
                          {currentUser?.role !== SIDE_BAR_ROLE_NAME.SALES &&
                            currentUser?.role !== SIDE_BAR_ROLE_NAME.SENIOR_SALES && (
                              <ListGroup.Item action key={label} onClick={() => handleTransition(url)}>
                                {label}
                              </ListGroup.Item>
                            )}
                        </>
                      ))}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          )
        }

        {currentUser?.role !== SIDE_BAR_ROLE_NAME.SALES && currentUser?.role !== SIDE_BAR_ROLE_NAME.SENIOR_SALES && (
          <>
            <p className={scss.sidebar_title}>カテゴリ</p>
            {isInternalWorker(currentUser?.role) && (
              <button type="button" className="sidebar_item">
                <FontAwesomeIcon icon={faBriefcase} fixedWidth />
                営業
              </button>
            )}
            {isInternalWorker(currentUser?.role) && (
              <button type="button" className="sidebar_item" onClick={() => handleTransition(Url.KEISAI.TOP)}>
                <FontAwesomeIcon icon={faClipboardList} fixedWidth />
                掲載
              </button>
            )}
            {isInternalWorker(currentUser?.role) && (
              <button type="button" className="sidebar_item" onClick={() => handleTransition(Url.TENSAKU.TOP)}>
                <FontAwesomeIcon icon={faUserCheck} fixedWidth />
                ユーコミ
              </button>
            )}
            {isInternalWorker(currentUser?.role) && (
              <button type="button" className="sidebar_item">
                <FontAwesomeIcon icon={faCommentDollar} fixedWidth />
                請求
              </button>
            )}
            {isInternalWorkerOrQc(currentUser?.role) && (
              <button type="button" className="sidebar_item" onClick={() => handleTransition(Url.TENSAKU.QCTOP)}>
                <FontAwesomeIcon icon={faUserCheck} fixedWidth />
                QCクルー
              </button>
            )}
            <button
              type="button"
              className="sidebar_item"
              onClick={() => handleTransition(Url.TENSAKU.DATA_INPUT_WORKER)}
            >
              <FontAwesomeIcon icon={faUserCheck} fixedWidth />
              データ入力作業者
            </button>
            {isInternalWorker(currentUser?.role) && (
              <button type="button" className="sidebar_item fw-bold">
                全メニュー
              </button>
            )}
          </>
        )}
      </div>
    </>
  );
};
