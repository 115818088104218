import React, { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { CustomerInfoApi } from '../../../api-client';
import type {
  CustomerInformationListDisplayFormResponse,
  CustomerInformationListDisplayOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { CustomerListForms } from '../../organisms/Forms/CustomerListForms';
import { CustomerListTable } from '../../organisms/Table/CustomerListTable';
import { Alert } from '../../atoms/Alert';
import { Title } from '../../atoms/Title';

export interface CustomerListState {
  api: CustomerInfoApi;
  isLoading: boolean;
  list: CustomerInformationListDisplayOutputResponse[];
  forms: CustomerInformationListDisplayFormResponse;
  errorMessage: string[];
}
export type TestIds = 'search-button' | 'link-button';

export const CustomerListPage: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const mailAddressParam = query.get('mailAddress') ?? undefined;

  const testid = createTestId<TestIds>(CustomerListPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<CustomerListState>({
    api: new CustomerInfoApi(),
    isLoading: false,
    list: [],
    forms: { oemId: -1, moral: -1 },
    errorMessage: [],
  });

  const setForms = useBindSet('forms');

  const createSearchBody = useCallback((body: CustomerInformationListDisplayFormResponse) => {
    const form: CustomerInformationListDisplayFormResponse = {
      customerId: body.customerId || undefined,
      oemId: Number(body.oemId) === -1 ? undefined : Number(body.oemId),
      customerName: body.customerName || undefined,
      birthday: body.birthday ? parseISO(body.birthday as string).toISOString() : undefined,
      mailAddress: body.mailAddress || undefined,
      phoneNumber: body.phoneNumber || undefined,
      moral: Number(body.moral) === -1 ? undefined : Number(body.moral),
    };

    return form;
  }, []);

  // 検索初期実行
  useEffect(() => {
    // メールアドレスが設定されていたら検索実行
    if (mailAddressParam !== undefined) onSearch({ mailAddress: mailAddressParam });
  }, []);

  // 検索
  const onSearch = useCallback(
    (body: CustomerInformationListDisplayFormResponse) => {
      if (
        ![body.customerId, body.customerName, body.birthday, body.mailAddress, body.phoneNumber].some(Boolean) &&
        Number(body.oemId) === -1 &&
        Number(body.moral) === -1
      ) {
        mergeState({ errorMessage: ['応募条件のうち少なくとも一つを設定してください'], list: [] });
        return;
      }
      mergeState({ isLoading: true });
      const searchBody: CustomerInformationListDisplayFormResponse = createSearchBody(body);
      $.api
        .customerInformationListDisplay(searchBody)
        .then((res: AxiosResponse<CustomerInformationListDisplayOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            mergeState({ list: res.data, errorMessage: [], isLoading: false });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
    },
    [$.api, createSearchBody, mergeState]
  );

  return (
    <div style={{ maxWidth: '1000px' }}>
      {$.errorMessage.length
        ? $.errorMessage.map((errorMessage) => (
            <Alert variant="danger" key={errorMessage}>
              {errorMessage}
            </Alert>
          ))
        : undefined}
      <Title>会員一覧</Title>
      <Form className="mt-4">
        <Row className="g-2 mb-4">
          <Col>
            <CustomerListForms setForms={setForms} />
            <Button
              data-testid={testid('search-button')}
              className="mt-4 center-block"
              onClick={() => {
                onSearch($.forms);
              }}
            >
              検索
            </Button>
          </Col>
        </Row>
      </Form>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.list.length ? <CustomerListTable dataList={$.list} /> : undefined}
      </LoadingSpinner>
    </div>
  );
};
