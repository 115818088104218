import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SqlTemplateApi, SqlTemplateInfoOutputResponse, IncResultOutputResponse } from '../../../api-client';
import { SqlTemplateInfoTable } from '../../organisms/Table/SqlTemplateInfoTable';
import { Url } from '../../../constants/Url';
import { PrimaryButton } from '../../atoms/Button/PrimaryButton';

export const SqlTemplateDeleteConfirmPage = () => {
  const [sqlDeleteInfo, setSqlDeleteInfo] = useState<SqlTemplateInfoOutputResponse[]>();
  const location = useLocation<any>();
  const history = useHistory();
  const sqlTemplateApi = new SqlTemplateApi();
  const listData = location.state.listData as SqlTemplateInfoOutputResponse;

  useEffect(() => {
    sqlTemplateApi.sqlTemplateInfo(listData.templateId).then((response: AxiosResponse<any>) => {
      setSqlDeleteInfo(response.data);
    });
  }, []);

  const clickDelete = () => {
    sqlTemplateApi.sqlTemplateDelete(listData.templateId).then((response: AxiosResponse<IncResultOutputResponse>) => {
      history.push(Url.KEISAI.SQL_TMP_COMPLETION);
    });
  };
  return (
    <>
      <SqlTemplateInfoTable tableTitle="SQLテンプレート削除確認" cellData={sqlDeleteInfo} userInfo={listData} />
      <p>ボタンを押すと削除実行します。</p>
      <PrimaryButton onClick={clickDelete}>削除する</PrimaryButton>
    </>
  );
};
