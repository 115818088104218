import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { createTestId, formatISODate } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import type { GiftTicketPageState } from '../../pages/GiftTicketPage';

interface Props {
  list: GiftTicketPageState['list'];
  onEdit: (id: Required<GiftTicketPageState['editForms']>['giftTicketId']) => void;
}
export type TestIds = 'table' | 'edit-button';

export const GiftTicketListTable: React.FC<Props> = ({ list, onEdit: setEditForms }) => {
  const testId = createTestId<TestIds>(GiftTicketListTable);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableData: { label: string; name: keyof GiftTicketPageState['list'][0]; parser?: (x?: any) => any }[] =
    useMemo(() => {
      const date = (x?: string) => (x ? formatISODate(x, 'yyyy/MM/dd') : '');
      const dateTime = (x?: string) => (x ? formatISODate(x, 'yyyy/MM/dd HH:mm:ss') : '');
      return [
        { label: 'ギフト券ID', name: 'giftTicketId', parser: Number },
        { label: '応募ID', name: 'applyId', parser: Number },
        { label: '利用状態', name: 'giftUseStateName', parser: String },
        { label: '店舗ID', name: 'issuedShopId', parser: Number },
        { label: '店舗名', name: 'issuedShopName', parser: String },
        { label: '金額', name: 'amount', parser: Number },
        { label: '内部コメント', name: 'internalComment', parser: String },
        { label: '取得日時', name: 'createAt', parser: dateTime },
        { label: '更新日時', name: 'updateAt', parser: date },
        { label: '有効期限', name: 'expirationDate', parser: date },
        { label: '利用日時', name: 'usedDate', parser: dateTime },
      ];
    }, []);
  return (
    <Table data-testid={testId('table')}>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label), '編集'].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item, index) => (
          <tr className="align-middle" key={item.giftTicketId}>
            {tableData.map(({ name, parser }) => (
              <td key={name}>{parser ? parser(item[name]) : item[name]}</td>
            ))}
            <td>
              <Button data-testid={testId('edit-button', index)} onClick={() => setEditForms(item.giftTicketId)}>
                編集
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
