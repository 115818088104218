import React from 'react';
import { Button, Card, Col, Form, InputGroup, Pagination, Row, Table, Tabs, Tab, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export const SelectTable = () => {
  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4>複数選択Tableパターン</h4>
              <Form>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="ms-2">
                    キャンセル
                  </Button>
                  <Button variant="primary" className="ms-2">
                    保存
                  </Button>
                </div>
                <div className="mb-3">
                  <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-2">
                    <Tab eventKey="1" title="一覧">
                      <InputGroup className="bg-light p-2 mb-4">
                        <Form.Control type="text" placeholder="検索" defaultValue="" />
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
                        </Button>
                      </InputGroup>
                      <Pagination>
                        <Pagination.First disabled />
                        <Pagination.Prev disabled />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Item>{4}</Pagination.Item>
                        <Pagination.Item>{5}</Pagination.Item>
                        <Pagination.Next />
                        <Pagination.Last />
                      </Pagination>
                      <div className="d-flex justify-content-end mb-4">
                        <Button variant="link" className="text-secondary">
                          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                          追加
                        </Button>
                      </div>
                      <Table>
                        <thead>
                          <tr className="align-bottom">
                            <th className="text-center" style={{ width: '2rem' }}>
                              {' '}
                            </th>
                            <th>設問/カテゴリ</th>
                            <th className="text-center" style={{ width: '4rem' }}>
                              再来店
                              <br />
                              への
                              <br />
                              影響
                            </th>
                            <th className="text-center" style={{ width: '4rem' }}>
                              設問設
                              <br />
                              定率
                            </th>
                            <th className="text-center" style={{ width: '4rem' }}>
                              他社
                              <br />
                              達成度
                              <br />
                              平均
                            </th>
                            <th className="text-center" style={{ width: '2rem' }}>
                              {' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="align-middle">
                            <td className="text-center">
                              <Form.Check id="tableCheck1" className="d-flex">
                                <Form.Check.Input
                                  className="flex-shrink-0"
                                  type="checkbox"
                                  name="checkBoxGroup01"
                                  defaultChecked
                                />
                              </Form.Check>
                            </td>
                            <td>
                              <Form.Check.Label htmlFor="tableCheck1" className="w-100">
                                <div>入店時の店員ごとの挨拶の早さはどうでしたか？</div>
                                <div className="text-secondary">マトリクス単一選択 / 0〜20点 / 必須</div>
                                <Badge bg="light" text="dark">
                                  接客
                                </Badge>
                              </Form.Check.Label>
                            </td>
                            <td className="text-center">○</td>
                            <td className="text-center">高</td>
                            <td className="text-center">85</td>
                            <td className="text-center">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                              </button>
                            </td>
                          </tr>
                          <tr className="align-middle">
                            <td className="text-center">
                              <Form.Check id="tableCheck1" className="d-flex">
                                <Form.Check.Input className="flex-shrink-0" type="checkbox" name="checkBoxGroup01" />
                              </Form.Check>
                            </td>
                            <td>
                              <Form.Check.Label htmlFor="tableCheck1" className="w-100">
                                <div>入店時の店員の対応はいかがでしたか？</div>
                                <div className="text-secondary">ラジオボタン単一選択 / 0〜100点 / 必須</div>
                                <Badge bg="light" text="dark">
                                  接客
                                </Badge>
                              </Form.Check.Label>
                            </td>
                            <td className="text-center">-</td>
                            <td className="text-center">低</td>
                            <td className="text-center">40</td>
                            <td className="text-center">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                    <Tab eventKey="2" title="選択中">
                      <InputGroup className="bg-light p-2 mb-4">
                        <Form.Control type="text" placeholder="検索" defaultValue="" />
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
                        </Button>
                      </InputGroup>
                      <Pagination>
                        <Pagination.First disabled />
                        <Pagination.Prev disabled />
                        <Pagination.Item active>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Item>{4}</Pagination.Item>
                        <Pagination.Item>{5}</Pagination.Item>
                        <Pagination.Next />
                        <Pagination.Last />
                      </Pagination>
                      <Table>
                        <thead>
                          <tr className="align-bottom">
                            <th className="text-center" style={{ width: '2rem' }}>
                              {' '}
                            </th>
                            <th>設問/カテゴリ</th>
                            <th className="text-center" style={{ width: '4rem' }}>
                              再来店
                              <br />
                              への
                              <br />
                              影響
                            </th>
                            <th className="text-center" style={{ width: '4rem' }}>
                              設問設
                              <br />
                              定率
                            </th>
                            <th className="text-center" style={{ width: '4rem' }}>
                              他社
                              <br />
                              達成度
                              <br />
                              平均
                            </th>
                            <th className="text-center" style={{ width: '2rem' }}>
                              {' '}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="align-middle">
                            <td className="text-center">
                              <Form.Check id="tableCheck1" className="d-flex">
                                <Form.Check.Input
                                  className="flex-shrink-0"
                                  type="checkbox"
                                  name="checkBoxGroup01"
                                  defaultChecked
                                />
                              </Form.Check>
                            </td>
                            <td>
                              <Form.Check.Label htmlFor="tableCheck1" className="w-100">
                                <div>入店時の店員ごとの挨拶の早さはどうでしたか？</div>
                                <div className="text-secondary">マトリクス単一選択 / 0〜20点 / 必須</div>
                                <Badge bg="light" text="dark">
                                  接客
                                </Badge>
                              </Form.Check.Label>
                            </td>
                            <td className="text-center">○</td>
                            <td className="text-center">高</td>
                            <td className="text-center">85</td>
                            <td className="text-center">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
