import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { createTestId, nameof } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import type { MemoState as ParentState } from '../../pages/Customer/Card/MemoCard';

interface Props {
  forms: ParentState['forms'];
  setForms: DispatchSetState<ParentState['forms']>;
}
export const CustomerMemoEditModalForms: React.FC<Props> = ({ forms, setForms }) => {
  const testid = createTestId<keyof ParentState['forms'] | 'auto-fill-button'>(CustomerMemoEditModalForms);
  const { state: $, onChangeSet, mergeState } = useLargeState<ParentState['forms']>(forms ?? {});

  const name = (x: keyof ParentState['forms']) => nameof(x);
  useEffect(() => setForms($), [setForms, $]);

  return (
    <Form.Group controlId={name('inCompanyMemo')} data-testid={testid('inCompanyMemo')}>
      <Form.Label>内容</Form.Label>
      <Form.Control
        as="textarea"
        autoComplete="off"
        value={$.inCompanyMemo ?? ''}
        onChange={onChangeSet('inCompanyMemo')}
      />
    </Form.Group>
  );
};
