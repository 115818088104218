import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import {
  ForceApi,
  CancelReasonDLApi,
  ForceWinningProcessExecutionFormResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { toBlob, createTestId } from '../../../utils/functions';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { Alert } from '../../atoms/Alert';
import { Dropzone } from '../../molecules/Dropzone';
import { Tag } from '../../atoms/Tag';

export const ForceWinningPage: React.VFC = () => {
  const testid = createTestId(ForceWinningPage);
  const {
    state: $,
    mergeState,
    onChangeSet,
    setState,
  } = useLargeState<{
    forceApi: ForceApi;
    dlApi: CancelReasonDLApi;
    monitorIds: string;
    userIds: string;
    winningMailSendFlg: boolean;
    file?: string;
    fileName?: string;
    downloadUrl: string;
    isFormatAlert: boolean;
    updResult: IncResultOutputResponse;
  }>({
    forceApi: new ForceApi(),
    dlApi: new CancelReasonDLApi(),
    monitorIds: '',
    userIds: '',
    winningMailSendFlg: false,
    downloadUrl: '',
    isFormatAlert: false,
    updResult: { result: false },
  });

  useEffect(() => {
    $.dlApi.forceWinningTemplateFileDL().then((res: AxiosResponse<string>) => {
      const blob = toBlob(res.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8');
      mergeState({ downloadUrl: blob ? window.URL.createObjectURL(blob) : '#' });
    });
  }, [$.dlApi, mergeState]);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles === null || acceptedFiles.length === 0) return;
    const file = acceptedFiles[0];

    const reader = new FileReader();
    reader.onload = () => {
      mergeState({ file: reader.result?.toString().replace(/data:.*\/.*;base64,/, ''), fileName: file.name });
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const { monitorIds, winningMailSendFlg, file, userIds } = $;
    if (
      (monitorIds && !monitorIds.trim().match(/^(\d+,)*[^,\D]+$/)) || // カンマ区切りの数値
      (userIds && !userIds.trim().match(/^(\d+,)*[^,\D]+$/))
    ) {
      mergeState({ isFormatAlert: true });
      return;
    }
    mergeState({ isFormatAlert: false });

    const monitorList = monitorIds.split(',').map((id) => Number(id));
    const userList = userIds.split(',').map((id) => Number(id));

    const params = {
      monitorIds: monitorList,
      userIds: userList,
      winningMailSendFlg,
    } as ForceWinningProcessExecutionFormResponse;
    if (file) params.file = file;

    $.forceApi.forceWinningProcessExecution(params).then((res: AxiosResponse<IncResultOutputResponse>) => {
      mergeState({ updResult: res.data });
    });
  };

  return (
    <>
      <Title data-testid={testid('title')} className="mb-4">
        {TITLE.KEISAI.FORCE_WINNING}
      </Title>

      {$.updResult.result && (
        <Alert testId={testid('success-alert')} variant="success">
          実施しました。
        </Alert>
      )}
      {$.updResult.errorMessage && (
        <Alert
          testId={testid('failure-alert')}
          variant="danger"
        >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}
      {$.isFormatAlert && (
        <Alert testId={testid('validation-alert')} variant="danger">
          入力内容に誤りがあります。
        </Alert>
      )}
      <Form onSubmit={onSubmit}>
        <div className="d-flex justify-content-end mb-4">
          {$.downloadUrl && (
            <a
              data-testid={testid('download-link')}
              href={$.downloadUrl}
              className="btn btn-outline-secondary"
              download="【強制当選】テンプレートファイル.xlsx"
            >
              流し込みテンプレートダウンロード
            </a>
          )}

          <Button
            data-testid={testid('action-button')}
            className="ms-2"
            type="submit"
            disabled={!$.file && !($.monitorIds && $.userIds)}
          >
            処理実施
          </Button>
        </div>

        <Form.Group data-testid={testid('monitorIds')} className="mb-4">
          <Form.Label>モニターID</Form.Label>
          <Form.Control
            type="text"
            value={$.monitorIds}
            onChange={onChangeSet('monitorIds')}
            placeholder="複数の場合はカンマ区切りで入力"
          />
        </Form.Group>

        <Form.Group data-testid={testid('userIds')} className="mb-4">
          <Form.Label>当選させるユーザー</Form.Label>
          <Form.Control
            type="text"
            value={$.userIds}
            onChange={onChangeSet('userIds')}
            placeholder="複数の場合はカンマ区切りで入力"
          />
        </Form.Group>

        <Form.Check id="winningMailSendFlg" className="d-flex mb-4">
          <Form.Check.Input
            data-testid={testid('winningMailSendFlg')}
            className="flex-shrink-0 me-2"
            type="checkbox"
            checked={$.winningMailSendFlg}
            onChange={() => mergeState({ winningMailSendFlg: !$.winningMailSendFlg })}
          />
          <Form.Check.Label>当選メール配信</Form.Check.Label>
        </Form.Check>

        <Form.Group data-testid={testid('template-file')} className="mb-4">
          <Form.Label>流し込みテンプレート</Form.Label>
          {$.fileName ? (
            <div className="d-block">
              <Tag
                label={$.fileName}
                onDelete={() => {
                  const { file, fileName, ...rest } = $;
                  setState(rest);
                }}
              />
            </div>
          ) : (
            <Dropzone onDrop={onDrop} type="excel" />
          )}
        </Form.Group>
      </Form>
    </>
  );
};
