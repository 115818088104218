import React from 'react';
import { Row, Col, Nav, Popover, OverlayTrigger, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faCog, faBell, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import scss from '../../scss/organisms/headerlayout.module.scss';
import { useLargeState } from '../../hooks/useLargeState';
import { useCurrentUser } from '../../hooks/useCurrentUser';
// eslint-disable-next-line import/no-cycle
import { SideMenuEditModal } from './Modal/SideMenuEditModal';
import { Url } from '../../constants/Url';

const BLANK_URL = '#';

/* お知らせアイコンポップオーバーの中身 */
// const bellIconPopover = (
//   <Popover id="popover-user">
//     <Popover.Body>
//       <a href={BLANK_URL} className="d-block text-decoration-none text-center mb-2">
//         お知らせ1
//       </a>
//       <a href={BLANK_URL} className="d-block text-decoration-none text-center">
//         お知らせ2
//       </a>
//     </Popover.Body>
//   </Popover>
// );

export const HeaderLayout: React.VFC<{ isOem?: boolean }> = ({ isOem }) => {
  const { currentUser } = useCurrentUser();
  const { state: $, mergeState, useBindSet } = useLargeState<{ isModal: boolean }>({ isModal: false });

  /* アカウントアイコンポップオーバーの中身 */
  const userIconPopover = (
    <Popover id="popover-user">
      <Popover.Body style={{ zIndex: 9999 }}>
        <div className="text-center">
          {currentUser?.name}
          <div className={scss.role_name}>{currentUser?.role}</div>
          <a href={Url.LOGOUT} className="d-block">
            ログアウト
          </a>
          <a href={Url.PASSWORD_EDIT} className="d-block">
            パスワード変更
          </a>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <SideMenuEditModal isModal={$.isModal} setIsModal={useBindSet('isModal')} accountId={currentUser?.id} />

      <Row className="border-bottom flex-nowrap" style={{ paddingLeft: '4rem' }}>
        <Col className="d-flex align-items-center flex-grow-1 p-0">
          <Nav activeKey="/home" className="flex-nowrap">
            {/* <Nav.Item>
              <Nav.Link className={scss.nav_link} href="/home">
                Active
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={scss.nav_link} eventKey="link-1">
                Link
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={scss.nav_link} eventKey="link-2">
                Link
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link className={scss.nav_link} eventKey="disabled" disabled>
                Disabled
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
        </Col>
        <Col className="d-flex align-items-center flex-grow-0 flex-shrink-0 px-4">
          {/* <div className="d-flex align-items-center justify-content-center ms-1 position-relative">
            <span
              className="text-danger position-absolute"
              style={{
                fontSize: '0.4rem',
                padding: '0.15rem',
                top: '-0.5rem',
                left: '0.7rem',
                minWidth: '0.9rem',
              }}
            >
              ●
            </span>
            <OverlayTrigger trigger="click" placement="bottom" overlay={bellIconPopover}>
              <a
                href={BLANK_URL}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <FontAwesomeIcon icon={faBell} className="text-secondary d-block" fixedWidth />
              </a>
            </OverlayTrigger>
          </div> */}
          {/* <div className="d-flex align-items-center justify-content-center ms-2 position-relative">
            <Badge
              pill
              bg="danger"
              className="position-absolute"
              style={{
                fontSize: '0.1rem',
                padding: '0.15rem',
                top: '-0.2rem',
                left: '0.65rem',
                minWidth: '1rem',
              }}
            >
              99+
            </Badge>
            <a
              href={BLANK_URL}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faEnvelopeOpen} className="text-secondary" fixedWidth />
            </a>
          </div> */}

          {currentUser && (
            <div className="d-flex align-items-center justify-content-end fs-2 gap-2 ms-3">
              {isOem && (
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body className="p-2 text-center">サイドバーメニュー編集</Popover.Body>
                    </Popover>
                  }
                >
                  <button type="button" className="ms-2" onClick={() => mergeState({ isModal: true })}>
                    <FontAwesomeIcon icon={faCog} className="text-secondary" fixedWidth />
                  </button>
                </OverlayTrigger>
              )}

              <OverlayTrigger trigger="click" placement="bottom" overlay={userIconPopover}>
                <a
                  href={BLANK_URL}
                  className="d-block"
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FontAwesomeIcon icon={faUserCircle} className="text-secondary" fixedWidth />
                </a>
              </OverlayTrigger>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};
