import * as React from 'react';
import { Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { sqlTemplateSchema } from '../../../constants/YupSchema';
import { SqlTemplateApi, SqlTemplateUpdateFormResponse, SqlTemplateListOutputResponse } from '../../../api-client';
import { SqlTemplateInputTable } from '../../organisms/Table/SqlTemplateInputTable';
import { Url } from '../../../constants/Url';
import { SubmitPrimaryButton } from '../../atoms/Button/SubmitPrimaryButton';

export const SqlTemplateUpdatePage = () => {
  const [sqlUpdateDatas, setSqlUpdateDatas] = useState<SqlTemplateUpdateFormResponse>();
  const location = useLocation<any>();
  const history = useHistory();
  const listData = location.state.listData as SqlTemplateListOutputResponse;
  // const listData = location.state.listData as SqlTemplateUpdateFormResponse;

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    getValues,
  } = useForm<SqlTemplateUpdateFormResponse>({
    mode: 'onSubmit',
    criteriaMode: 'all',
    shouldFocusError: false,
    defaultValues: {
      personalInformationProtectionFlag: sqlUpdateDatas?.personalInformationProtectionFlag,
    },
    resolver: yupResolver(sqlTemplateSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parameterList',
  });

  // これも差分をだすためだけ。
  useEffect(() => {
    const sqlInfo = new SqlTemplateApi();
    sqlInfo.sqlTemplateInfo(listData.templateId).then((response: AxiosResponse<any>) => {
      setSqlUpdateDatas(response.data);
      const obj: SqlTemplateUpdateFormResponse = response.data;
      // 初期値セット
      // 差分を出すためだけのコメント
      reset({
        personalInformationProtectionFlag: obj.personalInformationProtectionFlag,
        sqlString: obj.sqlString,
        templateId: obj.templateId,
        templateName: obj.templateName,
        parameterList: obj.parameterList,
      });
    });
  }, []);

  const onSubmit: SubmitHandler<SqlTemplateUpdateFormResponse> = (data) => {
    history.push({
      pathname: Url.KEISAI.SQL_TMP_UPDATE_CONFIRM,
      state: data,
    });
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SqlTemplateInputTable
          tableTitle="SQLテンプレート編集"
          sqlUpdateDatas={sqlUpdateDatas}
          register={register}
          errors={errors}
          control={control}
          getValues={getValues}
          fields={fields}
          append={append}
          remove={remove}
        />
        <SubmitPrimaryButton>確認</SubmitPrimaryButton>
      </Form>
    </>
  );
};
