import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Button, Col, Row } from 'react-bootstrap';
import { BillingAgentApi, DepositFileUploadFormResponse, DepositFileUploadOutputResponse } from '../../../api-client';

export const BillingAgentDepositUploadPage: React.VFC = () => {
  const [file, setFile] = useState<File>();
  const [content, setContent] = useState<DepositFileUploadFormResponse>({
    data: '',
  });
  const [uploadResult, setUploadResult] = useState<DepositFileUploadOutputResponse>({
    list: {},
    result: false,
  });

  const billingAgentApi = new BillingAgentApi();

  const handleClickDownloadTemplate = () => {};

  const handleChangeFile = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleClickPaidApply = (e: any) => {
    e.preventDefault();

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {};
      reader.onload = () => {
        let fileContent = String(reader.result);
        fileContent = fileContent.substring(fileContent.indexOf('base64,') + 7);
        setContent({ ...content, data: fileContent });

        billingAgentApi.uploadDepositFile(content).then((response: AxiosResponse<DepositFileUploadOutputResponse>) => {
          setUploadResult(response.data);
        });
      };
      reader.onerror = (error) => {
        console.log('FileReader Error: ', error);
      };
    }
  };

  return (
    <>
      <h3>入金データアップロード</h3>
      <Row className="mb-4">
        <Col className="col-4">
          <Button variant="secondary" onClick={handleClickDownloadTemplate}>
            入金消し込みテンプレートダウンロード
          </Button>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-4">
          <input type="file" id="csvFile" accept=".csv" onChange={handleChangeFile} />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-4">
          <Button variant="secondary" className="mb-4" onClick={handleClickPaidApply}>
            消し込み
          </Button>
        </Col>
      </Row>
    </>
  );
};
