import React from 'react';
import { Card } from 'react-bootstrap';
import { BadgeHead, BadgeType } from '../../../../atoms/BadgeHead';

export const Thread: React.FC<{ badgeType?: BadgeType; mark: string; posts: React.ReactNode }> = ({
  badgeType,
  mark,
  posts,
}) => (
  <Card>
    <Card.Header>
      <BadgeHead type={badgeType}>{mark}</BadgeHead>
    </Card.Header>
    <Card.Body>{posts}</Card.Body>
  </Card>
);
