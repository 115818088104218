import { faLink, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「注文方法」Contents
export const MonitorRuleSetOrderConditionContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  // 「電話番号」かどうか
  const [orderMethodPhoneNumberFlg, setOrderMethodPhoneNumberFlg] = useState<boolean>(false);
  // 「URL」かどうか
  const [orderMethodUrlFlg, setOrderMethodUrlFlg] = useState<boolean>(false);

  useEffect(() => {
    const rule = data.monitorRuleList.find((_) => _.monitorRuleType === 18);
    setOrderMethodPhoneNumberFlg(rule?.orderMethodPhoneNumberFlg || false);
    setOrderMethodUrlFlg(rule?.orderMethodUrlFlg || false);
  }, [data.monitorRuleList]);

  const changeUrlCheck = (isUrl: boolean) => {
    setOrderMethodUrlFlg(isUrl);
    setData({
      ...data,
      monitorRuleList: data?.monitorRuleList.map((_, i) => {
        return i === index
          ? {
              ..._,
              orderMethodUrlFlg: isUrl,
              orderMethodURL: !isUrl ? undefined : _.orderMethodURL,
            }
          : { ..._ };
      }),
    });
  };

  const changePhoneNumberCheck = (isPhoneNumber: boolean) => {
    setOrderMethodPhoneNumberFlg(isPhoneNumber);
    setData({
      ...data,
      monitorRuleList: data?.monitorRuleList.map((_, i) => {
        return i === index
          ? {
              ..._,
              orderMethodPhoneNumberFlg: isPhoneNumber,
              orderMethodPhoneNumber: !isPhoneNumber ? undefined : _.orderMethodPhoneNumber,
            }
          : { ..._ };
      }),
    });
  };

  return (
    <>
      <div className="d-flex ">
        <Row>
          <Form.Group className="d-flex align-items-center" controlId="readOnly1">
            <Form.Label className="mt-1" style={{ fontWeight: 'bold', marginRight: '1.8rem' }}>
              電話番号
            </Form.Label>
            <Form.Check.Input
              data-testid="orderMethodPhoneNumberFlg"
              className="flex-shrink-0 ms-2 me-3"
              type="checkbox"
              name="checkBoxGroup03"
              checked={orderMethodPhoneNumberFlg}
              onChange={() => {
                changePhoneNumberCheck(!orderMethodPhoneNumberFlg);
              }}
            />
            <Col classNmae="col-2">
              <Form.Control
                style={{ width: '200px' }}
                data-testid="phoneNumberText"
                value={
                  data?.monitorRuleList?.find((r) => {
                    return r.monitorRuleType === 18;
                  })?.orderMethodPhoneNumber === undefined
                    ? ''
                    : data?.monitorRuleList?.find((r) => {
                        return r.monitorRuleType === 18;
                      })?.orderMethodPhoneNumber || ''
                }
                disabled={!orderMethodPhoneNumberFlg}
                maxLength={13}
                type="text"
                onChange={(e) => {
                  setData({
                    ...data,
                    monitorRuleList: data?.monitorRuleList.map((_, i) => {
                      return i === index
                        ? { ..._, orderMethodPhoneNumber: e.target.value === '' ? undefined : e.target.value }
                        : { ..._ };
                    }),
                  });
                }}
              />
            </Col>
            （ハイフンありで入力）
          </Form.Group>
        </Row>
      </div>
      <Row>
        <Form.Group className="d-flex align-items-center mt-3" controlId="readOnly1">
          <Form.Label className="mt-1" style={{ fontWeight: 'bold', marginRight: '0.7rem' }}>
            WEBアドレス
          </Form.Label>
          <Form.Check.Input
            data-testid="orderMethodUrlFlg"
            className="flex-shrink-0 ms-2 me-3"
            type="checkbox"
            name="checkBoxGroup01"
            checked={orderMethodUrlFlg}
            onChange={() => {
              changeUrlCheck(!orderMethodUrlFlg);
            }}
          />
          <Col className="col-2">
            <Form.Control
              style={{ width: '200px' }}
              data-testid="urlText"
              value={
                data?.monitorRuleList?.find((r) => {
                  return r.monitorRuleType === 18;
                })?.orderMethodURL === undefined
                  ? ''
                  : data?.monitorRuleList?.find((r) => {
                      return r.monitorRuleType === 18;
                    })?.orderMethodURL || ''
              }
              disabled={!orderMethodUrlFlg}
              type="text"
              onChange={(e) => {
                setData({
                  ...data,
                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                    return i === index
                      ? { ..._, orderMethodURL: e.target.value === '' ? undefined : e.target.value }
                      : { ..._ };
                  }),
                });
              }}
            />
          </Col>
        </Form.Group>
      </Row>
      <Form.Group className="d-flex align-items-center">
        <Form.Label style={{ fontWeight: 'bold' }} className="mt-2 me-2">
          ※「電話番号」「WEBアドレス」の場合、「電話番号」または「URL」の入力が無い場合、店舗の情報が表示されます。
        </Form.Label>
      </Form.Group>
      <Form.Group className="d-flex align-items-center">
        <Button
          className="ms-5 mb-3"
          variant="secondary"
          onClick={() => {
            let reflectionText = '';
            let supplementText = '';
            const orderMethodPhoneNumber: string | undefined = rowData?.orderMethodPhoneNumber;
            const orderMethodURL: string | undefined = rowData?.orderMethodURL;
            // 「電話番号」にチェックがある場合
            if (orderMethodPhoneNumberFlg) {
              reflectionText = `必須（電話）のみ\n ＜注文方法＞\n ・下記に表示されている番号より、営業時間内に注文を行ってください。\n 📞${
                orderMethodPhoneNumber || '[店舗情報より取得]'
              }`;
              supplementText =
                '※ネット注文・お持ち帰りなど、指定以外の方法や電話番号で注文された場合、謝礼のお支払いはできません。\n' +
                '※お届け時間の予約をせず最短での配達を注文してください。';
            }
            // 「URL」にチェックがある場合
            if (orderMethodUrlFlg) {
              reflectionText = `必須（WEB）のみ\n ＜注文方法＞\n ・下記に表示されているURLより、WEBにて注文を行ってください。\n ${
                orderMethodURL || '[店舗情報より取得]'
              }`;
              supplementText =
                '※電話注文・お持ち帰りなど、指定以外の方法やサイトで注文された場合、謝礼のお支払いはできません。\n' +
                '※お届け時間の予約をせず最短での配達を注文してください。';
            }
            // 「電話番号・URL」両方にチェックがある場合場合
            if (orderMethodPhoneNumberFlg && orderMethodUrlFlg) {
              reflectionText = `必須（電話・WEB可）\n ＜注文方法＞\n  [電話の場合]\n   ・下記に表示されている番号より、営業時間内に注文を行ってください。\n     📞${
                orderMethodPhoneNumber || '[店舗情報より取得]'
              }\n  [WEBの場合]\n   ・下記に表示されているURLより、WEBにて注文を行ってください。\n     ${
                orderMethodURL || '[店舗情報より取得]'
              } `;
              supplementText =
                '※お持ち帰りなど、指定以外の方法やサイトおよび電話番号で注文された場合、謝礼のお支払いはできません。\n' +
                '※お届け時間の予約をせず最短での配達を注文してください。';
            }

            let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
              [],
              data.monitorRuleList.find((supplement) => {
                return supplement.monitorRuleType === rowData.monitorRuleType;
              })?.supplementList
            );
            copySupplementList = copySupplementList.map((r, i) => {
              return i === 0 ? { ...r, supplement: supplementText } : { ...r };
            });

            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                return row.monitorRuleType === 18
                  ? {
                      ...row,
                      beforeApplyContent: reflectionText,
                      afterApplyContent: reflectionText,
                      supplementList: copySupplementList,
                    }
                  : { ...row };
              }),
            });
          }}
        >
          反映
        </Button>
      </Form.Group>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
