import React, { useCallback } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Button } from '../../atoms/Button';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, isoDateToInput } from '../../../utils/functions';
// eslint-disable-next-line import/no-cycle
import { ClientNgApi, ClientNgListFormResponse, ClientNgListOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { ClientNgPageForms } from '../../organisms/Forms/ClientNgPageForms';
import { Title } from '../../atoms/Title';

interface FormsState {
  searchType: keyof ClientNgListFormResponse;
  searchValue: string | number;
}
const initialForms: FormsState = {
  searchType: 'clientId',
  searchValue: '',
};

export type State = {
  api: ClientNgApi;
  // api: ReturnType<typeof clientNgApi>;
  isLoading: boolean;
  forms: FormsState;
  list: ClientNgListOutputResponse[];
};

export const ClientNgPage: React.FC = () => {
  const testid = createTestId(ClientNgPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    // api: clientNgApi({}, { enableLogs: true }),
    api: new ClientNgApi(),
    isLoading: false,
    forms: initialForms,
    list: [],
  });

  const history = useHistory();

  const onSearch = useCallback(() => {
    const value = $.forms.searchType === 'clientName' ? $.forms.searchValue : Number($.forms.searchValue);
    mergeState({ isLoading: true });
    $.api
      .clientNgList({ [$.forms.searchType]: value })
      .then((res) => {
        mergeState({ list: res.data, isLoading: false });
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [$.api, $.forms.searchType, $.forms.searchValue, mergeState, history]);

  const setForms = useBindSet('forms');

  return (
    <LoadingSpinner isLoading={$.isLoading}>
      <Title>クライアントNG</Title>
      <Form>
        <Row className="g-2 mb-4">
          <ClientNgPageForms setForms={setForms} />
          <Col>
            <Button data-testid={testid('search-button')} disabled={!$.forms.searchValue} onClick={() => onSearch()}>
              検索
            </Button>
          </Col>
        </Row>
      </Form>
      <hr />
      {$.list.length !== 0 ? (
        <Table>
          <thead>
            <tr className="align-middle">
              <th>クライアントID</th>
              <th>クライアント名</th>
              <th>NG登録会員数</th>
              <th>更新日</th>
            </tr>
          </thead>
          <tbody>
            {$.list.map(({ clientId, clientName, clientNgUserCount, updateAt }, i) => (
              <tr key={clientId} className="align-middle">
                <td>{clientId}</td>
                <td>
                  <Button
                    variant="link"
                    data-testid={testid('edit-button', i)}
                    onClick={() => {
                      history.push(`${Url.TENSAKU.CLIENT_NG_EDIT}?id=${clientId}`);
                    }}
                  >
                    {clientName}
                  </Button>
                </td>
                <td>{clientNgUserCount}</td>
                <td>{isoDateToInput('date', updateAt) ?? ''}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <></>
      )}
    </LoadingSpinner>
  );
};
