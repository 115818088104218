import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
/* eslint-disable import/no-cycle */
import {
  MonitorStatusRsvListApi,
  MonitorStatusRsvCreateApi,
  MonitorStatusRsvListOutputResponse,
  IncResultOutputResponse,
  MonitorStatusRsvListItemOutputResponse,
} from '../../../api-client';
import { DispatchSetState } from '../../../interfaces/utils';
import { createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Badge } from '../../atoms/Badge';
import { Modal } from '../../molecules/Modal';
/* eslint-enable import/no-cycle */

export interface Props {
  isModal: boolean;
  setIsModal: DispatchSetState<boolean>;
  monitorBaseId: number | undefined;
  setMonitorBaseId: DispatchSetState<number | undefined>;
}

export interface State {
  getApi: MonitorStatusRsvListApi;
  createApi: MonitorStatusRsvCreateApi;
  isInValid: boolean;
  status: MonitorStatusRsvListOutputResponse['status'] | null;
  reasonForHiding: MonitorStatusRsvListOutputResponse['reasonForHiding'] | null;
  statusRsvs: MonitorStatusRsvListItemOutputResponse[] | null;
  updResult: IncResultOutputResponse;
  monitorBaseName: MonitorStatusRsvListOutputResponse['monitorBaseName'];
  contractShopName: MonitorStatusRsvListOutputResponse['contractShopName'];
}

export const MonitorStatusModal: React.VFC<Props> = ({ isModal, setIsModal, monitorBaseId, setMonitorBaseId }) => {
  const testid = createTestId(MonitorStatusModal);

  const { state: $, mergeState } = useLargeState<State>({
    getApi: new MonitorStatusRsvListApi(),
    createApi: new MonitorStatusRsvCreateApi(),
    isInValid: false,
    status: undefined,
    reasonForHiding: undefined,
    statusRsvs: null,
    updResult: { result: false },
    monitorBaseName: '',
    contractShopName: '',
  });

  useEffect(() => {
    if (!isModal || !monitorBaseId) return;

    $.getApi.monitorStatusRsvList(monitorBaseId).then((res: AxiosResponse<MonitorStatusRsvListOutputResponse>) => {
      const { ...rest } = res.data;
      mergeState(rest);
    });
  }, [isModal, monitorBaseId, $.getApi, mergeState]);

  const onHide = () => {
    mergeState({ status: undefined, reasonForHiding: undefined, isInValid: undefined });
    setIsModal(false);
    setMonitorBaseId(undefined);
  };

  return (
    <Modal
      onHide={onHide}
      isModal={isModal}
      closeButton
      centered
      size="lg"
      title={`契約店舗名：${$.contractShopName}, モニター名：${$.monitorBaseName}`}
      body={
        <>
          {$.status && (
            <Badge bg="secondary" className="px-4 mb-4" style={{ fontSize: '0.875rem' }} pill>
              {$.status}
            </Badge>
          )}

          {$.statusRsvs && $.statusRsvs.length > 0 && (
            <div className="mt-4" style={{ backgroundColor: 'white' }}>
              {$.statusRsvs.map((rsv) => (
                <ul
                  style={{
                    listStyle: 'none',
                    padding: '0.5rem',
                  }}
                >
                  <li>変更日時 {rsv.applyingAt}</li>
                  <li>変更ステータス {rsv.status}</li>
                  <li>非表示理由 {rsv.reasonForHiding}</li>
                </ul>
              ))}
            </div>
          )}
        </>
      }
    />
  );
};
