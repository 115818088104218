import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { EnqueteDisplayOutputResponse } from '../../../api-client/domains';
import { AnswerListTable } from '../../molecules/AnswerListTable';
// eslint-disable-next-line import/no-cycle
import { CorrectApi } from '../../../api-client';

export const AnswerListPage: React.FC = () => {
  const { applyId } = useParams<{ applyId: string }>();

  const [state, setState] = useState<EnqueteDisplayOutputResponse[]>();
  useEffect(() => {
    new CorrectApi().enqueteDisplay(Number(applyId)).then((res) => setState(res.data));
  }, []);
  if (state === undefined) return <></>;
  return <AnswerListTable props={state} />;
};
