import React from 'react';
import type { DisplayPointOutputResponse, SupplementUrlResponse } from '../../../../../../api-client';
import { useSafeContext } from '../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../store/correctionStore';
import { uuid } from '../../../../../../utils/functions';
import {
  ApplyReceiptCheckType,
  DisplayPointType,
  ReceiptComplicateCheckType,
} from '../../../../../pages/Correction/constants';
import { getCheckListSectionlabel } from '../../../../../pages/Correction/utils';
import { CommonPanel } from '../CommonPanel';
import { DateCheck } from './checks/DateCheck';
import { ReceiptCheck } from './checks/ReceiptCheck';
import { TellCheck } from './checks/TellCheck';
import { DuplicateImages } from './parts/DuplicateImages';
import { EvidenceList } from './parts/EvidenceList';
import { LinkIllegalMoralCheck } from './parts/LinkIllegalMoralCheck';
import { MonitorRuleDtoResponse } from '../../../../../../api-client';

// レシートでのCheckForm
const CheckForm: React.FC<{
  displayPoint: DisplayPointOutputResponse;
  title: string;
  urls?: SupplementUrlResponse[];
  monitorRule?: MonitorRuleDtoResponse;
}> = ({ displayPoint, title, urls = [], monitorRule, children }) => (
  <>
    <CommonPanel
      label={getCheckListSectionlabel(false, displayPoint.targetId ?? undefined, title)}
      urls={urls}
      displayPoint={displayPoint}
      monitorRule={monitorRule}
    >
      {children}
    </CommonPanel>
    <hr />
  </>
);

export const ReceiptCheckListSection: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const findMonitorRule = (monitorRuleTypeCode?: number) => {
    if (monitorRuleTypeCode === undefined) return undefined;
    return $.res_correctionCheckDisplay?.monitorRuleList.find((v) => v.type === monitorRuleTypeCode);
  };

  return (
    <>
      {$.res_correctionCheckDisplay?.applyReceiptCheckList.map(
        ({ receiptCheckItemId, checkTypeCode, checkItemContents, supplementUrl, monitorRuleTypeCode }) => {
          let child = null;
          const displayPoint = {
            type: DisplayPointType.APPLY_RECEIPT_CHECK_POINT,
            targetId: receiptCheckItemId,
          };
          if (ReceiptComplicateCheckType.includes(checkTypeCode)) {
            switch (checkTypeCode) {
              case ApplyReceiptCheckType.VISITS_SHOP:
                child = <TellCheck />;
                break;
              case ApplyReceiptCheckType.TARGET_SURVEY_PROOF:
                child = <EvidenceList />;
                break;
              case ApplyReceiptCheckType.VISIT_AT:
                child = <DateCheck />;
                break;
              case ApplyReceiptCheckType.RECEIPT_NO:
                child = <ReceiptCheck />;
                break;
              case ApplyReceiptCheckType.DUPLICATE_IMAGE:
                child = <DuplicateImages displayPoint={displayPoint} />;
                break;
              case ApplyReceiptCheckType.LINK_ILLEGAL_MORAL_CHECK:
                child = <LinkIllegalMoralCheck />;
                break;
              default:
            }
          }
          return (
            <CheckForm
              key={uuid()}
              displayPoint={displayPoint}
              title={checkItemContents}
              urls={supplementUrl ?? []}
              monitorRule={findMonitorRule(monitorRuleTypeCode)}
            >
              {child}
            </CheckForm>
          );
        }
      )}
    </>
  );
};
