import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const Title: React.FC<Props> = ({ children, ...rest }) => {
  return <h2 {...rest}>{children}</h2>;
};
