import React, { useEffect, useMemo, useState } from 'react';
import { useSafeContext } from '../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../store/correctionStore';
import { uuid } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { ImageLens } from '../ImageLens';
import {
  ApplyInfoDisplayOutputResponse,
  ApplyInfoOutputResponse,
  CorrectSurveyProofOutputResponse,
  ImageApi,
  SurveyProofImageInfoOutputResponse,
} from '../../../api-client';

// TODO: [添削] テスト未実装
const StateTabs: React.FC<{
  activeId: number | string;
  tabs: {
    id: number | string;
    label: string;
    disabled?: boolean;
  }[];
  onSelect?: (id: number | string) => void;
}> = ({ activeId, tabs, onSelect }) => {
  const [selectedId, setSelectedId] = useState(activeId);
  return (
    <ul className="nav nav-tabs">
      {tabs.map(({ id, label, disabled }) => (
        <li className="nav-item" key={uuid()}>
          <Button
            variant="link"
            className={['nav-link']
              .concat(id === selectedId ? 'active' : [])
              .concat(disabled ? 'disabled' : [])
              .join(' ')}
            onClick={() => {
              setSelectedId(id);
              onSelect?.(id);
            }}
          >
            {label}
          </Button>
        </li>
      ))}
    </ul>
  );
};

export const ImageViewer: React.FC<{ onChangeExif: (exif?: string) => void }> = React.memo(({ onChangeExif }) => {
  const {
    largeState: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      state: { res_applyInfoDisplay },
      mergeState,
    },
  } = useSafeContext(CorrectionContext);
  const [selectedSurveyProofVersion, setSelectedSurveyProofVersion] = useState(
    res_applyInfoDisplay?.surveyProofList?.[0].versionName
  );
  const list = useMemo(
    () =>
      res_applyInfoDisplay?.surveyProofList?.find(({ versionName }) => selectedSurveyProofVersion === versionName)
        ?.surveyProofImageInfoDtoList ?? [],
    [res_applyInfoDisplay?.surveyProofList, selectedSurveyProofVersion]
  );
  useEffect(() => {
    if (!selectedSurveyProofVersion) {
      setSelectedSurveyProofVersion(res_applyInfoDisplay?.surveyProofList?.[0].versionName);
    }
  }, [onChangeExif, res_applyInfoDisplay?.surveyProofList, selectedSurveyProofVersion]);

  if (res_applyInfoDisplay?.surveyProofList?.length === 0) return <div>画像はありません</div>;

  if (
    selectedSurveyProofVersion === undefined ||
    !res_applyInfoDisplay?.surveyProofList?.[0].surveyProofImageInfoDtoList?.[0].imageId
  )
    return <></>;
  const handleChangeRotate = (imageId: number, rotate: number) => {
    const imageApi = new ImageApi();
    imageApi
      .saveImageRotate({
        imageId,
        rotate,
      })
      .then((res) => {
        const huga = {
          ...res_applyInfoDisplay,
          surveyProofList: res_applyInfoDisplay?.surveyProofList?.map((v) => {
            return {
              ...v,
              surveyProofImageInfoDtoList: v.surveyProofImageInfoDtoList.map((vv) => {
                if (vv.imageId === imageId) {
                  return { ...vv, rotate: rotate } as SurveyProofImageInfoOutputResponse;
                }
                return vv;
              }),
            } as CorrectSurveyProofOutputResponse;
          }),
        } as ApplyInfoDisplayOutputResponse;
        mergeState({
          res_applyInfoDisplay: {
            ...res_applyInfoDisplay,
            surveyProofList: res_applyInfoDisplay?.surveyProofList?.map((v) => {
              return {
                ...v,
                surveyProofImageInfoDtoList: v.surveyProofImageInfoDtoList.map((vv) => {
                  if (vv.imageId === imageId) {
                    return { ...vv, rotate: rotate } as SurveyProofImageInfoOutputResponse;
                  }
                  return vv;
                }),
              } as CorrectSurveyProofOutputResponse;
            }),
          } as ApplyInfoDisplayOutputResponse,
        });
      });
  };
  return (
    <>
      <StateTabs
        activeId={selectedSurveyProofVersion}
        tabs={res_applyInfoDisplay.surveyProofList.map(({ versionName }) => ({
          id: versionName,
          label: versionName,
        }))}
        onSelect={(id) => {
          setSelectedSurveyProofVersion(id as string);
        }}
      />
      <ImageLens list={list} onChangeExif={onChangeExif} linkType="PAGE" handleChangeRotate={handleChangeRotate} />
    </>
  );
});
