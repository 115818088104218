import React, { Fragment, useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
  MonitorRuleApi,
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../../api-client';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { ImageProp, sampleMonitorRuleSetKindData } from './MonitorRuleSetModifyPage';
import { monitorRuleTypeName } from '../../organisms/MonitorRuleCard';

export const MonitorRuleSetDtailPage: React.VFC = () => {
  const [data, setData] = useState<MonitorRuleSetInfoOutputResponse & ImageProp>({
    id: 0,
    name: '',
    monitorRuleList: [],
    monitorRuleSetType: 1,
    description: undefined,
    linkedMonitors: [],
    imageList: [],
  });

  const { ruleSetId } = useParams<{
    ruleSetId: string | undefined;
  }>();

  // 初期表示処理
  useEffect(() => {
    const monitorRuleApi = new MonitorRuleApi();
    if (ruleSetId !== null) {
      monitorRuleApi
        .monitorRuleSetInfo(Number(ruleSetId))
        .then((res: AxiosResponse<MonitorRuleSetInfoOutputResponse>) => {
          const defaultImageList: ImageProp['imageList'] = [];

          res.data.monitorRuleList.forEach((monitorRuleList, idx) => {
            monitorRuleList.imageRuleList.forEach((imageRuleList, ind) => {
              imageRuleList.imageList.forEach(({ imageId, imageURL }) => {
                defaultImageList.push({
                  id: imageId,
                  path: imageURL,
                  monitorRuleListIdx: idx,
                  imageRuleListIdx: ind,
                  isDefault: !!imageId,
                });
              });
            });
          });
          setData({ ...res.data, imageList: defaultImageList });
        });
    } else {
      setData({
        ...data,
        monitorRuleList: newInitialSetting(),
      });
    }
  }, []);

  const newInitialSetting = () => {
    const copyMonitorRuleSetList: MonitorRuleSetInfoMonitorRuleOutputResponse[] = Object.assign(
      [],
      data?.monitorRuleList
    );
    // モニタールール種別「グルメ（イートイン）」のContents項目をセットする

    for (let index = 0; index < 17; index += 1) {
      let monitorRuleName = '';
      let monitorRuleType = 0;

      switch (index) {
        case 1:
          monitorRuleName = '調査日';
          monitorRuleType = 2;
          break;
        case 2:
          monitorRuleName = '時間';
          monitorRuleType = 3;
          break;
        case 3:
          monitorRuleName = '人数・同伴者';
          monitorRuleType = 4;
          break;
        case 4:
          monitorRuleName = '予約';
          monitorRuleType = 5;
          break;
        case 5:
          monitorRuleName = '指定商品';
          monitorRuleType = 6;
          break;
        case 6:
          monitorRuleName = '写真撮影';
          monitorRuleType = 7;
          break;
        case 7:
          monitorRuleName = '店員調査';
          monitorRuleType = 8;
          break;
        case 8:
          monitorRuleName = 'トイレ調査';
          monitorRuleType = 9;
          break;
        case 9:
          monitorRuleName = '清掃状況調査';
          monitorRuleType = 10;
          break;
        case 10:
          monitorRuleName = '外観調査';
          monitorRuleType = 11;
          break;
        case 11:
          monitorRuleName = '支払方法';
          monitorRuleType = 12;
          break;
        case 12:
          monitorRuleName = 'クーポン・割引';
          monitorRuleType = 13;
          break;
        case 13:
          monitorRuleName = '調査証明';
          monitorRuleType = 14;
          break;
        case 14:
          monitorRuleName = '特記事項';
          monitorRuleType = 15;
          break;
        case 15:
          monitorRuleName = '注意事項';
          monitorRuleType = 16;
          break;
        default:
          monitorRuleName = '応募条件';
          monitorRuleType = 17;
          break;
      }

      copyMonitorRuleSetList.push({
        monitorRuleType,
        monitorRuleId: 0,
        monitorRuleName,
        disableFlg: false,
        imageRuleList: [],
        index: 0,
        ngItemList: [],
        targetItemList: [],
        visitTimeSetList: [],
        supplementList: [
          {
            id: 0,
            supplement: '',
          },
        ],
        orderMethodPhoneNumberFlg: false,
        orderMethodUrlFlg: false,
      });
    }

    return copyMonitorRuleSetList;
  };

  useEffect(() => {
    changeMonitorRuleSetType(data.monitorRuleSetType);
  }, [data.monitorRuleSetType]);

  // 「モニタールールセット種別」変更時の処理
  const changeMonitorRuleSetType = (monitorRuleSetNumber: number) => {
    const copyMonitorRuleSetList: MonitorRuleSetInfoMonitorRuleOutputResponse[] = Object.assign(
      [],
      data?.monitorRuleList
    );

    const monitorRule = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 1;
    });
    const surveyDate = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 2;
    });
    const time = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 3;
    });
    const numberPeople = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 4;
    });
    const reserve = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 5;
    });
    const item = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 6;
    });
    const photoShot = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 7;
    });
    const clerkSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 8;
    });
    const toiletSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 9;
    });
    const cleaningSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 10;
    });
    const otherSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 11;
    });
    const paymentMethod = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 12;
    });
    const coupon = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 13;
    });
    const surveyProof = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 14;
    });
    const remarks = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 15;
    });
    const notes = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 16;
    });
    const applicationCondition = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 17;
    });
    const orderCondition = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 18;
    });
    const targetBrand = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 19;
    });
    const applicationProcess = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 20;
    });
    const exteriorSurvey = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 21;
    });
    const purchaseShop = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 22;
    });
    const menu = copyMonitorRuleSetList.find((_) => {
      return _.monitorRuleType === 23;
    });

    const newCopyMonitorRuleSetList: MonitorRuleSetInfoMonitorRuleOutputResponse[] = [];

    // 入力された「モニタールールセット種別」によって分岐
    switch (monitorRuleSetNumber) {
      case 2:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 時間
        settingData(newCopyMonitorRuleSetList, time, 3, 0, '時間', 2);
        // 人数・同伴者
        settingData(newCopyMonitorRuleSetList, numberPeople, 4, 0, '人数・同伴者', 3);
        // 予約
        settingData(newCopyMonitorRuleSetList, reserve, 5, 0, '予約', 4);
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 5);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 6);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 7);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 8);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 9);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 10);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 11);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 12);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 13);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 14);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 15);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 16);

        break;
      case 3:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 時間
        settingData(newCopyMonitorRuleSetList, time, 3, 0, '時間', 2);
        // 注文方法
        settingData(newCopyMonitorRuleSetList, orderCondition, 18, 0, '注文方法', 3);
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 4);
        // 対象ブランド
        settingData(newCopyMonitorRuleSetList, targetBrand, 19, 0, '対象ブランド', 5);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 6);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 7);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 8);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 9);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 10);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 11);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 12);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 13);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 14);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 15);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 16);
        break;
      case 4:
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 1);
        // 申込方法
        settingData(newCopyMonitorRuleSetList, applicationProcess, 20, 0, '申込方法', 2);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 3);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 4);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 5);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 6);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 7);
        break;
      case 1:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 時間
        settingData(newCopyMonitorRuleSetList, time, 3, 0, '時間', 2);
        // 人数・同伴者
        settingData(newCopyMonitorRuleSetList, numberPeople, 4, 0, '人数・同伴者', 3);
        // 予約
        settingData(newCopyMonitorRuleSetList, reserve, 5, 0, '予約', 4);
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 5);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 6);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 7);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 8);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 9);
        // 外観調査
        settingData(newCopyMonitorRuleSetList, exteriorSurvey, 21, 0, '外観調査', 10);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 11);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 12);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 13);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 14);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 15);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 16);
        break;
      case 5:
        // 購入店舗
        settingData(newCopyMonitorRuleSetList, purchaseShop, 22, 0, '購入店舗', 1);
        // 指定商品
        settingData(newCopyMonitorRuleSetList, item, 6, 0, '指定商品', 2);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 3);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 4);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 5);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 6);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 7);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 8);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 9);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 10);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 11);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 12);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 13);
        break;
      case 6:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 時間
        settingData(newCopyMonitorRuleSetList, time, 3, 0, '時間', 2);
        // 予約
        settingData(newCopyMonitorRuleSetList, reserve, 5, 0, '予約', 3);
        // 指定メニュー・コース
        settingData(newCopyMonitorRuleSetList, menu, 23, 0, '指定メニュー・コース', 4);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 5);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 6);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 7);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 8);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 9);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 10);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 11);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 12);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 13);
        break;
      default:
        // 調査日
        settingData(newCopyMonitorRuleSetList, surveyDate, 2, 0, '調査日', 1);
        // 予約
        settingData(newCopyMonitorRuleSetList, reserve, 5, 0, '予約', 2);
        // 指定メニュー・コース
        settingData(newCopyMonitorRuleSetList, menu, 23, 0, '指定メニュー・コース', 3);
        // 写真撮影
        settingData(newCopyMonitorRuleSetList, photoShot, 7, 0, '写真撮影', 4);
        // 店員調査
        settingData(newCopyMonitorRuleSetList, clerkSurvey, 8, 0, '店員調査', 5);
        // トイレ調査
        settingData(newCopyMonitorRuleSetList, toiletSurvey, 9, 0, 'トイレ調査', 6);
        // 清掃状況調査
        settingData(newCopyMonitorRuleSetList, cleaningSurvey, 10, 0, '清掃状況調査', 7);
        // その他調査
        settingData(newCopyMonitorRuleSetList, otherSurvey, 11, 0, 'その他調査', 8);
        // 支払方法
        settingData(newCopyMonitorRuleSetList, paymentMethod, 12, 0, '支払方法', 9);
        // クーポン・割引
        settingData(newCopyMonitorRuleSetList, coupon, 13, 0, 'クーポン・割引', 10);
        // 調査証明
        settingData(newCopyMonitorRuleSetList, surveyProof, 14, 0, '調査証明', 11);
        // 特記事項
        settingData(newCopyMonitorRuleSetList, remarks, 15, 0, '特記事項', 12);
        // 注意事項
        settingData(newCopyMonitorRuleSetList, notes, 16, 0, '注意事項', 13);
        // 応募条件
        settingData(newCopyMonitorRuleSetList, applicationCondition, 17, 0, '応募条件', 14);
        break;
    }

    setData({ ...data, monitorRuleList: newCopyMonitorRuleSetList });
  };

  const settingData = (
    addList: MonitorRuleSetInfoMonitorRuleOutputResponse[],
    targetList: MonitorRuleSetInfoMonitorRuleOutputResponse | undefined,
    monitorRuleType: number,
    monitorRuleId: number,
    monitorRuleName: string,
    index: number
  ) => {
    // 写真撮影でない場合
    if (targetList?.monitorRuleName !== '写真撮影') {
      // 入力データが存在している場合
      if (targetList !== undefined) {
        addList.push({ ...targetList, monitorRuleId });
        return;
      }
      addList.push({
        monitorRuleType,
        monitorRuleId,
        monitorRuleName,
        disableFlg: false,
        evidenceType: monitorRuleType === 14 ? 0 : undefined,
        imageRuleList: [],
        index,
        ngItemList: [],
        targetItemList: [],
        visitTimeSetList: monitorRuleType === 3 ? [{ timeFrom: undefined, timeTo: undefined }] : [],
        supplementList: [
          {
            id: 0,
            supplement: '',
          },
        ],
        orderMethodPhoneNumberFlg: false,
        orderMethodUrlFlg: false,
      });
    } else {
      // 入力データが存在している && 「モニタールール種別」の変更がない場合（編集初期時）
      if (targetList !== undefined) {
        addList.push({ ...targetList, monitorRuleId });
        return;
      }
      addList.push({
        monitorRuleType,
        monitorRuleId,
        monitorRuleName,
        disableFlg: false,
        imageRuleList: [],
        index,
        ngItemList: [],
        targetItemList: [],
        visitTimeSetList: [],
        supplementList: [
          {
            id: 0,
            supplement: '',
          },
        ],
        orderMethodPhoneNumberFlg: false,
        orderMethodUrlFlg: false,
      });
    }
  };

  return (
    <>
      {data && (
        <>
          <Title className="mb-4">{TITLE.KEISAI.MONITOR_RULE_SET_DETAIL}</Title>
          <Card className="mb-4">
            <Card.Body>
              <h4 className="mb-4">モニタールールセット</h4>
              <Table>
                <tbody>
                  <tr>
                    <th style={{ width: '15rem' }}>モニタールールセット名</th>
                    <td>{data.name}</td>
                  </tr>
                  <tr>
                    <th>モニタールールセット種別</th>
                    <td>{sampleMonitorRuleSetKindData.find((it) => it.id === data.monitorRuleSetType)?.name}</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Body>
              <h4 className="mb-4">モニタールール</h4>
              <Table>
                <thead>
                  <tr>
                    <th>調査項目</th>
                    <th>応募前文言</th>
                    <th>応募後文言</th>
                    <th>補足</th>
                  </tr>
                </thead>
                <tbody>
                  {data.monitorRuleList
                    .filter((item) => !item.disableFlg)
                    .map((item, idx) => (
                      <tr key={idx} style={{ whiteSpace: 'pre-wrap' }}>
                        <td style={{ width: '10%' }}>{monitorRuleTypeName(item.monitorRuleType)}</td>
                        <td style={{ width: '30%' }}>{item.beforeApplyContent}</td>
                        <td style={{ width: '30%' }}>{item.afterApplyContent}</td>
                        <td style={{ width: '30%' }}>{item.supplementList.map((row) => `${row.supplement}\n`)}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};
