import * as React from 'react';
import { ReactNode } from 'react';
import { FieldPathValue } from 'react-hook-form/dist/types/utils';
import { Col, Form } from 'react-bootstrap';
import { FieldError, FieldPath, Path, UnpackNestedValue, useController, useFormContext } from 'react-hook-form';
import sass from '../../scss/molecules/billingCommon.module.scss';
import useGetValidationRule from '../../hooks/useGetValidationRule';

interface Props<T> {
  className?: string;
  registerName: FieldPath<T>;
  labelName: string;
  readOnly?: boolean;
  type?: 'email' | 'number' | 'month';
  required?: boolean;
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  children?: ReactNode;
}

/**
 * FormProviderでラップされたコンポーネントで使うことが前提
 * FIXME setValueで値をセットしているが、それなしで値が更新されるようにしたい
 * @param className
 * @param register
 * @param registerName
 * @param labelName
 * @param readOnly
 * @param type
 * @param required
 * @param min
 * @param max
 * @param minLength
 * @param maxLength
 * @constructor
 */
export const BillingCommonInputText = <T,>({
  className,
  registerName,
  labelName,
  readOnly,
  type,
  required,
  min,
  max,
  minLength,
  maxLength,
  children,
}: Props<T>) => {
  const { getRules } = useGetValidationRule();

  const initialType = () => {
    if (type === 'number' || type === undefined) {
      return 'text';
    }
    return type;
  };

  const { register, control, setValue } = useFormContext<T>();
  const {
    fieldState: { error },
  } = useController({ name: registerName, control: control });

  return (
    <Col className="col-6">
      {error !== undefined && <span className={sass.errorMessage}>{(error as FieldError).message}</span>}
      <Form.Group className={`d-flex justify-content-start ${className}`}>
        <Form.Label
          htmlFor={registerName.toString()}
          style={{ width: '65%', margin: '0.5rem' }}
          className={required ? sass.required : ''}
        >
          {labelName}
          {children}
        </Form.Label>
        <Form.Control
          readOnly={readOnly}
          id={registerName.toString()}
          type={initialType()}
          {...register(registerName, getRules({ required, min, max, minLength, maxLength }, type))}
          onBlur={(e) => setValue(registerName, e.target.value as UnpackNestedValue<FieldPathValue<any, Path<T>>>)}
        />
      </Form.Group>
    </Col>
  );
};
