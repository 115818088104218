import React, { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import { ManagementAnnounceCreateUpdateApi } from '../../../api-client';
import type {
  IncResultOutputResponse,
  ManagementAnnounceCreateUpdateFormResponse,
  ManagementAnnounceListOutputListItemResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { useLargeState } from '../../../hooks/useLargeState';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { Alert } from '../../atoms/Alert';

const PAGE_TYPE = {
  MODIFY: 1,
  CONFIRM: 2,
} as const;

type FormData = {
  content: string;
  endAt: string;
  id?: number | undefined;
  startAt: string;
  status?: number;
  targetType?: number;
  title: string;
};

type PageType = typeof PAGE_TYPE[keyof typeof PAGE_TYPE];

export const ManagementAnnounceModifyPage: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<ManagementAnnounceListOutputListItemResponse>();

  const { state: $, mergeState } = useLargeState<{
    api: ManagementAnnounceCreateUpdateApi;
    pageType: PageType;
    updResult: IncResultOutputResponse;
    errorMessage: string;
  }>({
    api: new ManagementAnnounceCreateUpdateApi(),
    pageType: PAGE_TYPE.MODIFY,
    updResult: { result: false },
    errorMessage: '',
  });

  const {
    state: f,
    onChangeSet: fOnChangeSet,
    setState: fSetState,
  } = useLargeState<FormData>({
    content: '',
    endAt: '',
    startAt: '',
    status: 0,
    title: '',
    targetType: 0,
  });

  useEffect(() => {
    if (!location.state) return;
    const { startAt, endAt, ...rest } = location.state;
    fSetState({
      ...rest,
      startAt: startAt.replace(/ /, 'T'),
      endAt: endAt.replace(/ /, 'T'),
    });
  }, [location.state, fSetState]);

  const onConfirm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      ![f.content, f.endAt, f.startAt, f.title].every(Boolean) &&
      !(f.targetType === undefined && f.targetType === null)
    ) {
      mergeState({ errorMessage: '入力されていない項目があります。' });
      return;
    }
    window.history.pushState(null, '', Url.KEISAI.ANNOUNCE_MODIFY_CONFIRM);
    mergeState({ pageType: PAGE_TYPE.CONFIRM, errorMessage: '' });
  };

  const createUpdateBody = (form: FormData) => {
    let startAt = form.startAt.toString();
    if (startAt.length === 16) {
      startAt += ':00';
    }
    let endAt = form.endAt.toString();
    if (endAt.length === 16) {
      endAt += ':00';
    }
    return {
      id: f.id,
      content: form.content,
      endAt: endAt,
      startAt: startAt,
      status: form.status!,
      title: form.title,
      targetType: form.targetType!,
    };
  };

  const onUpdate = () => {
    const body: ManagementAnnounceCreateUpdateFormResponse = createUpdateBody(f);
    $.api
      .managementAnnounceCreateUpdate(body)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  const statusOptions: [string, number][] = useMemo(
    () =>
      Object.entries({
        表示: 0,
        非表示: 1,
      }),
    []
  );

  const options: [string, number][] = useMemo(
    () =>
      Object.entries({
        すべて: 0,
        未ログインユーザーのみ: 1,
        ログインユーザーのみ: 2,
      }),
    []
  );

  return (
    <>
      <Title className="mb-4">
        {$.pageType === PAGE_TYPE.MODIFY
          ? TITLE.KEISAI.MANAGEMENT_ANNOUNCE_MODIFY
          : TITLE.KEISAI.MANAGEMENT_ANNOUNCE_MODIFY_CONFIRM}
      </Title>
      {$.updResult?.result && <Alert variant="success">{f.id ? '更新' : '確定'}しました。</Alert>}
      {$.updResult?.errorCode && $.updResult?.errorMessage && (
        <Alert variant="danger">{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}
      {$.errorMessage.length > 0 && <Alert variant="danger">{`${$.errorMessage}`}</Alert>}
      <Form onSubmit={onConfirm}>
        <div className="d-flex justify-content-end mb-4">
          {$.pageType === PAGE_TYPE.MODIFY ? (
            <>
              <Button variant="link" className="ms-2" onClick={() => history.goBack()}>
                キャンセル
              </Button>
              <Button className="ms-2" type="submit">
                確認画面へ
              </Button>
            </>
          ) : (
            <>
              {$.updResult?.result ? (
                <>
                  <Button
                    variant="link"
                    className="ms-2"
                    onClick={() => {
                      history.push(Url.KEISAI.MANAGEMENT_ANNOUNCE);
                    }}
                  >
                    一覧に戻る
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="link"
                    className="ms-2"
                    onClick={() => {
                      mergeState({ pageType: PAGE_TYPE.MODIFY, updResult: { result: false } });
                      history.goBack();
                    }}
                  >
                    キャンセル
                  </Button>
                  <Button onClick={onUpdate} className="ms-2">
                    {f.id ? '更新' : '確定'}
                  </Button>
                </>
              )}
            </>
          )}
        </div>

        <fieldset disabled={$.pageType === PAGE_TYPE.CONFIRM}>
          <Form.Group>
            <Form.Label>表示期間</Form.Label>
            <div className="d-flex align-items-center gap-2 mb-4">
              <Form.Control
                required
                type="datetime-local"
                step="1"
                className="w-auto"
                value={f.startAt || ''}
                onChange={fOnChangeSet('startAt')}
              />
              {' ～ '}
              <Form.Control
                required
                type="datetime-local"
                step="1"
                className="w-auto"
                value={f.endAt || ''}
                onChange={fOnChangeSet('endAt')}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>タイトル</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                value={f.startAt?.substring(0, f.startAt.indexOf('T')) || ''}
                className="w-auto"
                readOnly
              />
              <Form.Control required type="text" value={f.title || ''} onChange={fOnChangeSet('title')} />
            </div>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>状態</Form.Label>
            <Form.Select required value={f.status ?? ''} onChange={fOnChangeSet('status', Number)}>
              {statusOptions.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>対象種別</Form.Label>
            <Form.Select required value={f.targetType ?? ''} onChange={fOnChangeSet('targetType', Number)}>
              {options.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              本文 <span className="text-secondary">※htmlタグ利用可</span>
            </Form.Label>
            <Form.Control
              required
              value={f.content || ''}
              onChange={fOnChangeSet('content')}
              type="text"
              as="textarea"
              rows={6}
            />
          </Form.Group>
        </fieldset>
      </Form>
    </>
  );
};
