import * as React from 'react';
import { LinkButton } from '../atoms/Button/LinkButton';

interface IProps {
  text: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Completion: React.FC<IProps> = ({ text, onClick }) => {
  return (
    <>
      <h2>完了しました。</h2>
      <LinkButton text={text} onClick={onClick} />
    </>
  );
};
