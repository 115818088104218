/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { PaidServicePointReturnHistoryListFormResponse } from '../domains';
// @ts-ignore
import { PaidServicePointReturnHistoryListOutputResponse } from '../domains';
// @ts-ignore
import { PaidServicePointReturnRegisterDisplayFormResponse } from '../domains';
// @ts-ignore
import { PaidServicePointReturnRegisterDisplayOutputResponse } from '../domains';
// @ts-ignore
import { PaidServicePointReturnRegisterExecutionFormResponse } from '../domains';
// @ts-ignore
import { PaidServiceTypeListOutputResponse } from '../domains';
// @ts-ignore
import { ResultOutputResponse } from '../domains';
/**
 * PaidServicePointReturnApi - axios parameter creator
 * @export
 */
export const PaidServicePointReturnApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 有料サービスのポイント変換履歴の検索結果を返す
         * @summary 有料サービスポイント返還履歴
         * @param {PaidServicePointReturnHistoryListFormResponse} paidServicePointReturnHistoryListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidServicePointReturnHistoryList: async (paidServicePointReturnHistoryListFormResponse: PaidServicePointReturnHistoryListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paidServicePointReturnHistoryListFormResponse' is not null or undefined
            assertParamExists('paidServicePointReturnHistoryList', 'paidServicePointReturnHistoryListFormResponse', paidServicePointReturnHistoryListFormResponse)
            const localVarPath = `/api/paid-service-point-return/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paidServicePointReturnHistoryListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 有料サービスのポイント変換内容確認画面
         * @summary 有料サービスポイント返還確認
         * @param {PaidServicePointReturnRegisterDisplayFormResponse} paidServicePointReturnRegisterDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidServicePointReturnRegisterDisplay: async (paidServicePointReturnRegisterDisplayFormResponse: PaidServicePointReturnRegisterDisplayFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paidServicePointReturnRegisterDisplayFormResponse' is not null or undefined
            assertParamExists('paidServicePointReturnRegisterDisplay', 'paidServicePointReturnRegisterDisplayFormResponse', paidServicePointReturnRegisterDisplayFormResponse)
            const localVarPath = `/api/paid-service-point-return/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paidServicePointReturnRegisterDisplayFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 有料サービスポイント変換登録実行
         * @summary 有料サービスポイント返還実行
         * @param {Array<PaidServicePointReturnRegisterExecutionFormResponse>} paidServicePointReturnRegisterExecutionFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidServicePointReturnRegisterExecution: async (paidServicePointReturnRegisterExecutionFormResponse: Array<PaidServicePointReturnRegisterExecutionFormResponse>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paidServicePointReturnRegisterExecutionFormResponse' is not null or undefined
            assertParamExists('paidServicePointReturnRegisterExecution', 'paidServicePointReturnRegisterExecutionFormResponse', paidServicePointReturnRegisterExecutionFormResponse)
            const localVarPath = `/api/paid-service-point-return`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paidServicePointReturnRegisterExecutionFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 有料サービスの一覧を返却する
         * @summary 返還対象サービス一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidServiceTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/paid-service/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaidServicePointReturnApi - functional programming interface
 * @export
 */
export const PaidServicePointReturnApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaidServicePointReturnApiAxiosParamCreator(configuration)
    return {
        /**
         * 有料サービスのポイント変換履歴の検索結果を返す
         * @summary 有料サービスポイント返還履歴
         * @param {PaidServicePointReturnHistoryListFormResponse} paidServicePointReturnHistoryListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paidServicePointReturnHistoryList(paidServicePointReturnHistoryListFormResponse: PaidServicePointReturnHistoryListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaidServicePointReturnHistoryListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paidServicePointReturnHistoryList(paidServicePointReturnHistoryListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 有料サービスのポイント変換内容確認画面
         * @summary 有料サービスポイント返還確認
         * @param {PaidServicePointReturnRegisterDisplayFormResponse} paidServicePointReturnRegisterDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paidServicePointReturnRegisterDisplay(paidServicePointReturnRegisterDisplayFormResponse: PaidServicePointReturnRegisterDisplayFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaidServicePointReturnRegisterDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paidServicePointReturnRegisterDisplay(paidServicePointReturnRegisterDisplayFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 有料サービスポイント変換登録実行
         * @summary 有料サービスポイント返還実行
         * @param {Array<PaidServicePointReturnRegisterExecutionFormResponse>} paidServicePointReturnRegisterExecutionFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paidServicePointReturnRegisterExecution(paidServicePointReturnRegisterExecutionFormResponse: Array<PaidServicePointReturnRegisterExecutionFormResponse>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paidServicePointReturnRegisterExecution(paidServicePointReturnRegisterExecutionFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 有料サービスの一覧を返却する
         * @summary 返還対象サービス一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paidServiceTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaidServiceTypeListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paidServiceTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaidServicePointReturnApi - factory interface
 * @export
 */
export const PaidServicePointReturnApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaidServicePointReturnApiFp(configuration)
    return {
        /**
         * 有料サービスのポイント変換履歴の検索結果を返す
         * @summary 有料サービスポイント返還履歴
         * @param {PaidServicePointReturnHistoryListFormResponse} paidServicePointReturnHistoryListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidServicePointReturnHistoryList(paidServicePointReturnHistoryListFormResponse: PaidServicePointReturnHistoryListFormResponse, options?: any): AxiosPromise<Array<PaidServicePointReturnHistoryListOutputResponse>> {
            return localVarFp.paidServicePointReturnHistoryList(paidServicePointReturnHistoryListFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 有料サービスのポイント変換内容確認画面
         * @summary 有料サービスポイント返還確認
         * @param {PaidServicePointReturnRegisterDisplayFormResponse} paidServicePointReturnRegisterDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidServicePointReturnRegisterDisplay(paidServicePointReturnRegisterDisplayFormResponse: PaidServicePointReturnRegisterDisplayFormResponse, options?: any): AxiosPromise<Array<PaidServicePointReturnRegisterDisplayOutputResponse>> {
            return localVarFp.paidServicePointReturnRegisterDisplay(paidServicePointReturnRegisterDisplayFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 有料サービスポイント変換登録実行
         * @summary 有料サービスポイント返還実行
         * @param {Array<PaidServicePointReturnRegisterExecutionFormResponse>} paidServicePointReturnRegisterExecutionFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidServicePointReturnRegisterExecution(paidServicePointReturnRegisterExecutionFormResponse: Array<PaidServicePointReturnRegisterExecutionFormResponse>, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.paidServicePointReturnRegisterExecution(paidServicePointReturnRegisterExecutionFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 有料サービスの一覧を返却する
         * @summary 返還対象サービス一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paidServiceTypeList(options?: any): AxiosPromise<Array<PaidServiceTypeListOutputResponse>> {
            return localVarFp.paidServiceTypeList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaidServicePointReturnApi - object-oriented interface
 * @export
 * @class PaidServicePointReturnApi
 * @extends {BaseAPI}
 */
export class PaidServicePointReturnApi extends BaseAPI {
    /**
     * 有料サービスのポイント変換履歴の検索結果を返す
     * @summary 有料サービスポイント返還履歴
     * @param {PaidServicePointReturnHistoryListFormResponse} paidServicePointReturnHistoryListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidServicePointReturnApi
     */
    public paidServicePointReturnHistoryList(paidServicePointReturnHistoryListFormResponse: PaidServicePointReturnHistoryListFormResponse, options?: AxiosRequestConfig) {
        return PaidServicePointReturnApiFp(this.configuration).paidServicePointReturnHistoryList(paidServicePointReturnHistoryListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 有料サービスのポイント変換内容確認画面
     * @summary 有料サービスポイント返還確認
     * @param {PaidServicePointReturnRegisterDisplayFormResponse} paidServicePointReturnRegisterDisplayFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidServicePointReturnApi
     */
    public paidServicePointReturnRegisterDisplay(paidServicePointReturnRegisterDisplayFormResponse: PaidServicePointReturnRegisterDisplayFormResponse, options?: AxiosRequestConfig) {
        return PaidServicePointReturnApiFp(this.configuration).paidServicePointReturnRegisterDisplay(paidServicePointReturnRegisterDisplayFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 有料サービスポイント変換登録実行
     * @summary 有料サービスポイント返還実行
     * @param {Array<PaidServicePointReturnRegisterExecutionFormResponse>} paidServicePointReturnRegisterExecutionFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidServicePointReturnApi
     */
    public paidServicePointReturnRegisterExecution(paidServicePointReturnRegisterExecutionFormResponse: Array<PaidServicePointReturnRegisterExecutionFormResponse>, options?: AxiosRequestConfig) {
        return PaidServicePointReturnApiFp(this.configuration).paidServicePointReturnRegisterExecution(paidServicePointReturnRegisterExecutionFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 有料サービスの一覧を返却する
     * @summary 返還対象サービス一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaidServicePointReturnApi
     */
    public paidServiceTypeList(options?: AxiosRequestConfig) {
        return PaidServicePointReturnApiFp(this.configuration).paidServiceTypeList(options).then((request) => request(this.axios, this.basePath));
    }
}
