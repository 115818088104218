import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SqlTemplateApi, SqlTemplateInfoOutputResponse, SqlTemplateListOutputResponse } from '../../../api-client';
import { LinkButton } from '../../atoms/Button/LinkButton';
import { SqlTemplateInfoTable } from '../../organisms/Table/SqlTemplateInfoTable';
import { pageTransition } from '../../../utils/pageTransition';

export const SqlTemplateInfoPage = () => {
  const [sqlInfoDatas, setSqlInfoDatas] = useState<SqlTemplateInfoOutputResponse>({
    parameterList: [],
    personalInformationProtectionFlag: false,
    sqlString: '',
    templateId: 0,
    templateName: '',
  });
  const location = useLocation<any>();
  const history = useHistory();
  const listData = location.state.listData as SqlTemplateListOutputResponse;

  useEffect(() => {
    const sqlInfo = new SqlTemplateApi();
    sqlInfo.sqlTemplateInfo(listData.templateId).then((response: AxiosResponse<any>) => {
      setSqlInfoDatas(response.data);
    });
  }, []);
  return (
    <>
      <LinkButton text="編集" onClick={() => pageTransition({ type: 'SQL_TMP_UPDATE' }, sqlInfoDatas, history)} />
      <LinkButton
        text="SQLクエリ生成"
        onClick={() => pageTransition({ type: 'SQL_QUERY_CREATE' }, sqlInfoDatas, history)}
      />
      <SqlTemplateInfoTable tableTitle="SQLテンプレート詳細" cellData={sqlInfoDatas} userInfo={listData} />
    </>
  );
};
