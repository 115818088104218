import React, { useEffect, useMemo } from 'react';
import { Button, Card, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useLargeState } from '../../../../hooks/useLargeState';
import { formatISODate, uuid, when } from '../../../../utils/functions';
import type { CorrectSettingEachMonitorBaseState } from '../../../pages/CorrectSetting/CorrectSettingEachMonitorPage';
// eslint-disable-next-line import/no-cycle
import { initialInfo } from '../../../pages/CorrectSetting/CorrectSettingEachMonitorPage';
import { DATETIME_DISPLAY_FORMAT } from '../../../../Constants';
import { Url } from '../../../../constants/Url';
import { DispatchSetState } from '../../../../interfaces/utils';
import { Modal } from '../../../molecules/Modal';
import { CorrectSettingApi } from '../../../../api-client';

export type State = CorrectSettingEachMonitorBaseState['monitorCorrectionInfo'];

type FormState = CorrectSettingEachMonitorBaseState['correctSettingForm'];
type AutoCorrectState = CorrectSettingEachMonitorBaseState['autoCorrectInfo'];

interface Props {
  eachMonitorInfo: State;
  setForms: DispatchSetState<FormState>;
  autoCorrectionInfo: AutoCorrectState;
}

export interface CustomerInfoState {
  isModal: boolean;
  forms: FormState;
}

export type TestIds = keyof State | 'pen';

export const MonitorClientInfoCard: React.FC<Props> = ({ eachMonitorInfo, setForms, autoCorrectionInfo }) => {
  const history = useHistory();
  const api = new CorrectSettingApi();
  const { monitorId } = useParams<{ monitorId: string | undefined }>();

  // const { state: $info, mergeState } = useLargeState<State>(initialInfo);
  const { state: $info, mergeState } = useLargeState<State>(initialInfo);

  useEffect(() => {
    mergeState({ ...eachMonitorInfo });
  }, [eachMonitorInfo, mergeState, autoCorrectionInfo]);

  const initialData: FormState = useMemo(
    () => ({
      monitorId: eachMonitorInfo.monitorId,
      correctStartFlg: eachMonitorInfo.monitorCorrectionExecutionFlg,
    }),
    [eachMonitorInfo.monitorId, eachMonitorInfo.monitorCorrectionExecutionFlg]
  );

  const { state: $modal, mergeState: mergeModal } = useLargeState<CustomerInfoState>({
    isModal: false,
    forms: initialData,
  });

  useEffect(() => {
    setForms({ ...$modal.forms });
  }, [$modal.forms, setForms]);

  // const setForms = useBindSet('forms');

  const clientTooltip = useMemo(() => <Popover id="button-tooltip">クライアント毎添削設定</Popover>, []);

  // TODO:B モーダル内にOKボタンを配置し、OKされたらモニター毎添削設定の更新APIを呼ぶ
  return (
    <>
      <Modal
        isModal={$modal.isModal}
        closeButton
        onHide={() =>
          mergeModal({
            isModal: false,
          })
        }
        body={<>添削を{$modal.forms.correctStartFlg ? '開始' : '停止'}します</>}
      />
      {when(
        $info.monitorId > 0,
        <div className="w-25 d-inline-block">
          <Card className="mt-4 me-2">
            <div className="m-2">
              <h6>モニター情報</h6>
              {$info.monitorId} {$info.monitorName}
            </div>
            <div className="m-2">
              <h6>店舗情報</h6>
              {$info.contractShopId} {$info.contractShopName}
            </div>
            <div className="m-2">
              <h6>クライアント情報</h6>
              {$info.clientId} {$info.clientName}
              <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={clientTooltip}>
                <Button
                  variant="link"
                  onClick={() => {
                    history.push(`${Url.TENSAKU.CORRECT_SETTING_EACH_CLIENT}/${$info.clientId}`, {
                      prevPage: `${Url.TENSAKU.CORRECT_SETTING_EACH_MONITOR}/${monitorId}`,
                    });
                  }}
                  className={`${!$info.clientSettingConfigureFlg ? '.text-secondary' : ''}`}
                  disabled={false}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
              </OverlayTrigger>
              <br />
              添削実行：{$info.clientCorrectionExecutionFlg ? 'ON' : 'OFF'}
              <br />
              Wチェック：{$info.doubleCheckFlg ? 'ON' : 'OFF'}
              <br />
              選任設定：
              <div className="bg-light mt-2 p-2">
                {$info.electedPersonList.map((obj, i) => {
                  const returnObj = (
                    <p key={uuid()}>
                      {obj.id} {obj.name}
                    </p>
                  );
                  return (
                    <React.Fragment key={uuid()}>
                      {i > 0 ? <br /> : undefined}
                      {returnObj}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </Card>
          <Card className="mt-4 me-2">
            <div className="m-2 d-flex justify-content-between">
              <h6>添削開始</h6>

              <Form.Check
                type="switch"
                onChange={() => {
                  mergeModal({
                    forms: { ...$modal.forms, correctStartFlg: !$modal.forms.correctStartFlg },
                    isModal: true,
                  });
                  (async () => {
                    const updateResult = await api.monitorCorrectionSettingUpdate($info.monitorId, {
                      correctStartFlg: !$modal.forms.correctStartFlg,
                      monitorId: $info.monitorId,
                    });
                    const res = await api.monitorCorrectionSettingInfo(Number(monitorId));
                    mergeState({
                      ...eachMonitorInfo,
                      monitorCorrectionExecutionFlg: res.data.monitorCorrectionExecutionFlg,
                      updateAt: res.data.updateAt,
                      updateIncName: res.data.updateIncName,
                    });
                  })();
                }}
                checked={$modal.forms.correctStartFlg}
              />
            </div>
            <div className="ms-3 text-secondary">※OFFにするとこのモニターの添削が全て停止します</div>
            <div className="ms-3 text-secondary">※クライアント全体の添削停止設定が優先されます</div>
            <div className="m-2">
              <p className="mb-0">
                最終更新日時
                {$info.updateAt ? formatISODate($info.updateAt, DATETIME_DISPLAY_FORMAT) : ''}
              </p>
              <p className="mb-0">最終更新者 {$info.updateIncName}</p>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};
