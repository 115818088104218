import React, { useCallback } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, debugLog, formatISODate, isoDateToInput } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
// eslint-disable-next-line import/no-cycle
import { ApplySearchApi, QcCrewApplySearchOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { QcCrewApplySearchForms } from '../../organisms/Forms/QcCrewApplySearchForms';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { Title } from '../../atoms/Title';
import { getSurveyProofCorrectPageUrl } from './utils';

interface FormsState {
  searchType: string;
  searchValue: number;
  targetStatus: number;
}
const initialForms: FormsState = {
  searchType: 'applyId',
  searchValue: 0,
  targetStatus: 0,
};

export type State = {
  api: ApplySearchApi;
  isLoading: boolean;
  forms: FormsState;
  list: QcCrewApplySearchOutputResponse[];
};

export const QcCrewApplySearchPage: React.FC = () => {
  const testid = createTestId(QcCrewApplySearchPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    api: new ApplySearchApi(),
    isLoading: false,
    forms: initialForms,
    list: [],
  });

  const history = useHistory();

  const onSearch = useCallback(() => {
    debugLog($.forms);
    const searchType = String($.forms.searchType);
    const searchValue = Number($.forms.searchValue);
    const targetStatus = Number($.forms.targetStatus);
    const applyId = searchType === 'applyId' && searchValue !== 0 ? searchValue : undefined;
    const contractShopId = searchType === 'contractShopId' ? searchValue : undefined;
    const clientId = searchType === 'clientId' ? searchValue : undefined;
    mergeState({ isLoading: true });
    $.api
      .qcCrewApplySearch(targetStatus, applyId, contractShopId, clientId)
      .then((res) => {
        mergeState({ list: res.data, isLoading: false });
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [$.forms, $.api, mergeState, history]);

  const setForms = useBindSet('forms');

  return (
    <LoadingSpinner isLoading={$.isLoading}>
      <Title>QCクルー案件検索</Title>
      <Form>
        <Row className="mb-3">
          <QcCrewApplySearchForms setForms={setForms} />
          <Col>
            <Button data-testid={testid('search-button')} onClick={() => onSearch()}>
              検索
            </Button>
          </Col>
        </Row>
      </Form>
      <hr />
      {$.list.length !== 0 ? (
        <Table data-testid={testid('table')}>
          <thead>
            <tr className="align-middle">
              <th data-testid={testid('label-1')}>
                <span>応募ID</span>
                <br />
                <span>当選ステータス</span>
              </th>
              <th data-testid={testid('label-2')}>
                <span>自身の最終処理日時</span>
                <br />
                <span>内容</span>
              </th>
              <th data-testid={testid('label-3')}>
                <span>クライアントID</span>
                <br />
                <span>クライアント名</span>
              </th>
              <th data-testid={testid('label-4')}>
                <span>店舗ID</span>
                <br />
                <span>店舗・モニター名</span>
              </th>
              <th data-testid={testid('label-5')}>調査証明</th>
              <th data-testid={testid('label-6')}>アンケート</th>
            </tr>
          </thead>
          <tbody>
            {$.list.map((apply: QcCrewApplySearchOutputResponse, i) => (
              <tr key={apply.applyId} className="align-middle">
                <td data-testid={testid('applyInfo', i)}>
                  <span>{apply.applyId}</span>
                  <br />
                  <span>{apply.applyStatusName}</span>
                </td>
                <td data-testid={testid('dateInfo', i)}>
                  <span>{formatISODate(apply.maxExecutionDate, DATETIME_DISPLAY_FORMAT)}</span>
                  <br />
                  <span>{apply.operationTypeName}</span>
                </td>
                <td data-testid={testid('clientInfo', i)}>
                  <span>{apply.clientId}</span>
                  <br />
                  <span>{apply.clientName}</span>
                </td>
                <td data-testid={testid('contractShopInfo', i)}>
                  <span>{apply.contractShopId}</span>
                  <br />
                  <span>{`${apply.contractShopName}・${apply.monitorBaseName}`}</span>
                </td>
                <td>
                  <Button
                    variant="link"
                    data-testid={testid('surveyProofCorrect-button', i)}
                    onClick={() => {
                      history.push(getSurveyProofCorrectPageUrl(apply.surveyProofCorrectType, apply.applyId));
                    }}
                  >
                    {apply.surveyProofStatusName}
                  </Button>
                </td>
                <td>
                  <Button
                    variant="link"
                    data-testid={testid('enqueteCorrect-button', i)}
                    onClick={() => {
                      history.push(`${Url.TENSAKU.CORRECT_ENQUETE}/${apply.applyId}`);
                    }}
                  >
                    {apply.enqueteStatusName}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <></>
      )}
    </LoadingSpinner>
  );
};
