/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { ShopReplaceConfirmDisplayFormResponse } from '../domains';
// @ts-ignore
import { ShopReplaceConfirmDisplayOutputResponse } from '../domains';
// @ts-ignore
import { ShopReplaceExecuteFormResponse } from '../domains';
// @ts-ignore
import { ShopReplaceMonitorSearchFormResponse } from '../domains';
// @ts-ignore
import { ShopReplaceMonitorSearchOutputResponse } from '../domains';
// @ts-ignore
import { ShopReplaceSearchOutputResponse } from '../domains';
/**
 * ShopReplaceApi - axios parameter creator
 * @export
 */
export const ShopReplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 応募IDから当選店舗付け替えをする応募の情報を取得する
         * @summary 当選店舗付け替え対象応募検索
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceApplySearch: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('shopReplaceApplySearch', 'applyId', applyId)
            const localVarPath = `/api/shop-replace/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 当選店舗付け替えの確認画面を表示する
         * @summary 当選店舗付け替え確認画面表示
         * @param {ShopReplaceConfirmDisplayFormResponse} shopReplaceConfirmDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceConfirm: async (shopReplaceConfirmDisplayFormResponse: ShopReplaceConfirmDisplayFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopReplaceConfirmDisplayFormResponse' is not null or undefined
            assertParamExists('shopReplaceConfirm', 'shopReplaceConfirmDisplayFormResponse', shopReplaceConfirmDisplayFormResponse)
            const localVarPath = `/api/shop-replace/confirm/display`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopReplaceConfirmDisplayFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 当選店舗付け替え実行
         * @summary 当選店舗付け替え実行
         * @param {ShopReplaceExecuteFormResponse} shopReplaceExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceExecute: async (shopReplaceExecuteFormResponse: ShopReplaceExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopReplaceExecuteFormResponse' is not null or undefined
            assertParamExists('shopReplaceExecute', 'shopReplaceExecuteFormResponse', shopReplaceExecuteFormResponse)
            const localVarPath = `/api/shop-replace/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopReplaceExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 当選店舗付け替え先のモニター一覧を検索する
         * @summary 当選店舗付け替え対象モニター一覧表示
         * @param {ShopReplaceMonitorSearchFormResponse} shopReplaceMonitorSearchFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceMonitorSearch: async (shopReplaceMonitorSearchFormResponse: ShopReplaceMonitorSearchFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopReplaceMonitorSearchFormResponse' is not null or undefined
            assertParamExists('shopReplaceMonitorSearch', 'shopReplaceMonitorSearchFormResponse', shopReplaceMonitorSearchFormResponse)
            const localVarPath = `/api/shop-replace/monitor-search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopReplaceMonitorSearchFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopReplaceApi - functional programming interface
 * @export
 */
export const ShopReplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopReplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 応募IDから当選店舗付け替えをする応募の情報を取得する
         * @summary 当選店舗付け替え対象応募検索
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopReplaceApplySearch(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopReplaceSearchOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopReplaceApplySearch(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 当選店舗付け替えの確認画面を表示する
         * @summary 当選店舗付け替え確認画面表示
         * @param {ShopReplaceConfirmDisplayFormResponse} shopReplaceConfirmDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopReplaceConfirm(shopReplaceConfirmDisplayFormResponse: ShopReplaceConfirmDisplayFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopReplaceConfirmDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopReplaceConfirm(shopReplaceConfirmDisplayFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 当選店舗付け替え実行
         * @summary 当選店舗付け替え実行
         * @param {ShopReplaceExecuteFormResponse} shopReplaceExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopReplaceExecute(shopReplaceExecuteFormResponse: ShopReplaceExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopReplaceExecute(shopReplaceExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 当選店舗付け替え先のモニター一覧を検索する
         * @summary 当選店舗付け替え対象モニター一覧表示
         * @param {ShopReplaceMonitorSearchFormResponse} shopReplaceMonitorSearchFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopReplaceMonitorSearch(shopReplaceMonitorSearchFormResponse: ShopReplaceMonitorSearchFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShopReplaceMonitorSearchOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopReplaceMonitorSearch(shopReplaceMonitorSearchFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopReplaceApi - factory interface
 * @export
 */
export const ShopReplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopReplaceApiFp(configuration)
    return {
        /**
         * 応募IDから当選店舗付け替えをする応募の情報を取得する
         * @summary 当選店舗付け替え対象応募検索
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceApplySearch(applyId: number, options?: any): AxiosPromise<ShopReplaceSearchOutputResponse> {
            return localVarFp.shopReplaceApplySearch(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 当選店舗付け替えの確認画面を表示する
         * @summary 当選店舗付け替え確認画面表示
         * @param {ShopReplaceConfirmDisplayFormResponse} shopReplaceConfirmDisplayFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceConfirm(shopReplaceConfirmDisplayFormResponse: ShopReplaceConfirmDisplayFormResponse, options?: any): AxiosPromise<ShopReplaceConfirmDisplayOutputResponse> {
            return localVarFp.shopReplaceConfirm(shopReplaceConfirmDisplayFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 当選店舗付け替え実行
         * @summary 当選店舗付け替え実行
         * @param {ShopReplaceExecuteFormResponse} shopReplaceExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceExecute(shopReplaceExecuteFormResponse: ShopReplaceExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.shopReplaceExecute(shopReplaceExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 当選店舗付け替え先のモニター一覧を検索する
         * @summary 当選店舗付け替え対象モニター一覧表示
         * @param {ShopReplaceMonitorSearchFormResponse} shopReplaceMonitorSearchFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopReplaceMonitorSearch(shopReplaceMonitorSearchFormResponse: ShopReplaceMonitorSearchFormResponse, options?: any): AxiosPromise<Array<ShopReplaceMonitorSearchOutputResponse>> {
            return localVarFp.shopReplaceMonitorSearch(shopReplaceMonitorSearchFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopReplaceApi - object-oriented interface
 * @export
 * @class ShopReplaceApi
 * @extends {BaseAPI}
 */
export class ShopReplaceApi extends BaseAPI {
    /**
     * 応募IDから当選店舗付け替えをする応募の情報を取得する
     * @summary 当選店舗付け替え対象応募検索
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopReplaceApi
     */
    public shopReplaceApplySearch(applyId: number, options?: AxiosRequestConfig) {
        return ShopReplaceApiFp(this.configuration).shopReplaceApplySearch(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 当選店舗付け替えの確認画面を表示する
     * @summary 当選店舗付け替え確認画面表示
     * @param {ShopReplaceConfirmDisplayFormResponse} shopReplaceConfirmDisplayFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopReplaceApi
     */
    public shopReplaceConfirm(shopReplaceConfirmDisplayFormResponse: ShopReplaceConfirmDisplayFormResponse, options?: AxiosRequestConfig) {
        return ShopReplaceApiFp(this.configuration).shopReplaceConfirm(shopReplaceConfirmDisplayFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 当選店舗付け替え実行
     * @summary 当選店舗付け替え実行
     * @param {ShopReplaceExecuteFormResponse} shopReplaceExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopReplaceApi
     */
    public shopReplaceExecute(shopReplaceExecuteFormResponse: ShopReplaceExecuteFormResponse, options?: AxiosRequestConfig) {
        return ShopReplaceApiFp(this.configuration).shopReplaceExecute(shopReplaceExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 当選店舗付け替え先のモニター一覧を検索する
     * @summary 当選店舗付け替え対象モニター一覧表示
     * @param {ShopReplaceMonitorSearchFormResponse} shopReplaceMonitorSearchFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopReplaceApi
     */
    public shopReplaceMonitorSearch(shopReplaceMonitorSearchFormResponse: ShopReplaceMonitorSearchFormResponse, options?: AxiosRequestConfig) {
        return ShopReplaceApiFp(this.configuration).shopReplaceMonitorSearch(shopReplaceMonitorSearchFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
