/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BillingAgentDetailOutputResponse } from '../domains';
// @ts-ignore
import { BillingAgentListOutputResponse } from '../domains';
// @ts-ignore
import { BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse } from '../domains';
// @ts-ignore
import { BillingAgentMonitorBaseCreateRelationSearchResultFormResponse } from '../domains';
// @ts-ignore
import { BillingAgentMonitorBaseOutputResponse } from '../domains';
// @ts-ignore
import { BillingAgentMonitorBaseRemoveRelationAllFormResponse } from '../domains';
// @ts-ignore
import { BillingAgentMonitorBaseRemoveRelationFormResponse } from '../domains';
// @ts-ignore
import { DepositFileDownloadOutputResponse } from '../domains';
// @ts-ignore
import { DepositFileUploadFormResponse } from '../domains';
// @ts-ignore
import { DepositFileUploadOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * BillingAgentApi - axios parameter creator
 * @export
 */
export const BillingAgentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {BillingAgentMonitorBaseCreateRelationSearchResultFormResponse} billingAgentMonitorBaseCreateRelationSearchResultFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentMonitorBaseCreateRelationSearchResult: async (billingAgentMonitorBaseCreateRelationSearchResultFormResponse: BillingAgentMonitorBaseCreateRelationSearchResultFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingAgentMonitorBaseCreateRelationSearchResultFormResponse' is not null or undefined
            assertParamExists('billingAgentMonitorBaseCreateRelationSearchResult', 'billingAgentMonitorBaseCreateRelationSearchResultFormResponse', billingAgentMonitorBaseCreateRelationSearchResultFormResponse)
            const localVarPath = `/api/billing-agent/relation/create/searched`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingAgentMonitorBaseCreateRelationSearchResultFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {number} id 
         * @param {number} [clientId] 
         * @param {number} [contractShopId] 
         * @param {string} [monitorBaseName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentMonitorBaseList: async (id: number, clientId?: number, contractShopId?: number, monitorBaseName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingAgentMonitorBaseList', 'id', id)
            const localVarPath = `/api/billing-agent-monitorbase-list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }

            if (monitorBaseName !== undefined) {
                localVarQueryParameter['monitorBaseName'] = monitorBaseName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 代理店に紐づくモニターベースをすべて解除する
         * @summary 代理店モニターベース紐づけ全解除
         * @param {BillingAgentMonitorBaseRemoveRelationAllFormResponse} billingAgentMonitorBaseRemoveRelationAllFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentMonitorBaseRemoveRelationAll: async (billingAgentMonitorBaseRemoveRelationAllFormResponse: BillingAgentMonitorBaseRemoveRelationAllFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingAgentMonitorBaseRemoveRelationAllFormResponse' is not null or undefined
            assertParamExists('billingAgentMonitorBaseRemoveRelationAll', 'billingAgentMonitorBaseRemoveRelationAllFormResponse', billingAgentMonitorBaseRemoveRelationAllFormResponse)
            const localVarPath = `/api/billing-agent/relation/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingAgentMonitorBaseRemoveRelationAllFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 代理店明細を取得する
         * @summary 代理店明細取得
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingagentDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('billingagentDetail', 'id', id)
            const localVarPath = `/api/billing-agent/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 代理店一覧を取得する
         * @summary 代理店一覧取得
         * @param {number} [id] 
         * @param {number} [incAccountId] 
         * @param {number} [clientId] 
         * @param {number} [contractShopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingagentList: async (id?: number, incAccountId?: number, clientId?: number, contractShopId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/billing-agent/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (incAccountId !== undefined) {
                localVarQueryParameter['incAccountId'] = incAccountId;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (contractShopId !== undefined) {
                localVarQueryParameter['contractShopId'] = contractShopId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse} billingAgentMonitorBaseCreateRelationCheckedItemFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingagentMonitorBaseCreateRelationCheckedItem: async (billingAgentMonitorBaseCreateRelationCheckedItemFormResponse: BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingAgentMonitorBaseCreateRelationCheckedItemFormResponse' is not null or undefined
            assertParamExists('billingagentMonitorBaseCreateRelationCheckedItem', 'billingAgentMonitorBaseCreateRelationCheckedItemFormResponse', billingAgentMonitorBaseCreateRelationCheckedItemFormResponse)
            const localVarPath = `/api/billing-agent/relation/create/checked`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingAgentMonitorBaseCreateRelationCheckedItemFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {Array<BillingAgentMonitorBaseRemoveRelationFormResponse>} billingAgentMonitorBaseRemoveRelationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingagentMonitorBaseRemoveRelation: async (billingAgentMonitorBaseRemoveRelationFormResponse: Array<BillingAgentMonitorBaseRemoveRelationFormResponse>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'billingAgentMonitorBaseRemoveRelationFormResponse' is not null or undefined
            assertParamExists('billingagentMonitorBaseRemoveRelation', 'billingAgentMonitorBaseRemoveRelationFormResponse', billingAgentMonitorBaseRemoveRelationFormResponse)
            const localVarPath = `/api/billing-agent/relation/remove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(billingAgentMonitorBaseRemoveRelationFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 代理店請求一覧CSVファイルを取得する
         * @summary 代理店支払い入金データダウンロード
         * @param {string} yyyyMm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDepositFile: async (yyyyMm: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'yyyyMm' is not null or undefined
            assertParamExists('downloadDepositFile', 'yyyyMm', yyyyMm)
            const localVarPath = `/api/billing-agent/download-deposit-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (yyyyMm !== undefined) {
                localVarQueryParameter['yyyyMm'] = yyyyMm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 入金消込ファイルをアップロードする
         * @summary 代理店支払い入金データダウンロード
         * @param {DepositFileUploadFormResponse} uploadFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDepositFile: async (uploadFile: DepositFileUploadFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadFile' is not null or undefined
            assertParamExists('uploadDepositFile', 'uploadFile', uploadFile)
            const localVarPath = `/api/billing-agent/upload-deposit-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uploadFile !== undefined) {
                localVarQueryParameter['uploadFile'] = uploadFile;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingAgentApi - functional programming interface
 * @export
 */
export const BillingAgentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingAgentApiAxiosParamCreator(configuration)
    return {
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {BillingAgentMonitorBaseCreateRelationSearchResultFormResponse} billingAgentMonitorBaseCreateRelationSearchResultFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingAgentMonitorBaseCreateRelationSearchResult(billingAgentMonitorBaseCreateRelationSearchResultFormResponse: BillingAgentMonitorBaseCreateRelationSearchResultFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingAgentMonitorBaseCreateRelationSearchResult(billingAgentMonitorBaseCreateRelationSearchResultFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {number} id 
         * @param {number} [clientId] 
         * @param {number} [contractShopId] 
         * @param {string} [monitorBaseName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingAgentMonitorBaseList(id: number, clientId?: number, contractShopId?: number, monitorBaseName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingAgentMonitorBaseOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingAgentMonitorBaseList(id, clientId, contractShopId, monitorBaseName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 代理店に紐づくモニターベースをすべて解除する
         * @summary 代理店モニターベース紐づけ全解除
         * @param {BillingAgentMonitorBaseRemoveRelationAllFormResponse} billingAgentMonitorBaseRemoveRelationAllFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingAgentMonitorBaseRemoveRelationAll(billingAgentMonitorBaseRemoveRelationAllFormResponse: BillingAgentMonitorBaseRemoveRelationAllFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingAgentMonitorBaseRemoveRelationAll(billingAgentMonitorBaseRemoveRelationAllFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 代理店明細を取得する
         * @summary 代理店明細取得
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingagentDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BillingAgentDetailOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingagentDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 代理店一覧を取得する
         * @summary 代理店一覧取得
         * @param {number} [id] 
         * @param {number} [incAccountId] 
         * @param {number} [clientId] 
         * @param {number} [contractShopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingagentList(id?: number, incAccountId?: number, clientId?: number, contractShopId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BillingAgentListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingagentList(id, incAccountId, clientId, contractShopId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse} billingAgentMonitorBaseCreateRelationCheckedItemFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingagentMonitorBaseCreateRelationCheckedItem(billingAgentMonitorBaseCreateRelationCheckedItemFormResponse: BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingagentMonitorBaseCreateRelationCheckedItem(billingAgentMonitorBaseCreateRelationCheckedItemFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {Array<BillingAgentMonitorBaseRemoveRelationFormResponse>} billingAgentMonitorBaseRemoveRelationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async billingagentMonitorBaseRemoveRelation(billingAgentMonitorBaseRemoveRelationFormResponse: Array<BillingAgentMonitorBaseRemoveRelationFormResponse>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.billingagentMonitorBaseRemoveRelation(billingAgentMonitorBaseRemoveRelationFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 代理店請求一覧CSVファイルを取得する
         * @summary 代理店支払い入金データダウンロード
         * @param {string} yyyyMm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDepositFile(yyyyMm: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositFileDownloadOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDepositFile(yyyyMm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 入金消込ファイルをアップロードする
         * @summary 代理店支払い入金データダウンロード
         * @param {DepositFileUploadFormResponse} uploadFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDepositFile(uploadFile: DepositFileUploadFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepositFileUploadOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDepositFile(uploadFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingAgentApi - factory interface
 * @export
 */
export const BillingAgentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingAgentApiFp(configuration)
    return {
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {BillingAgentMonitorBaseCreateRelationSearchResultFormResponse} billingAgentMonitorBaseCreateRelationSearchResultFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentMonitorBaseCreateRelationSearchResult(billingAgentMonitorBaseCreateRelationSearchResultFormResponse: BillingAgentMonitorBaseCreateRelationSearchResultFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingAgentMonitorBaseCreateRelationSearchResult(billingAgentMonitorBaseCreateRelationSearchResultFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {number} id 
         * @param {number} [clientId] 
         * @param {number} [contractShopId] 
         * @param {string} [monitorBaseName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentMonitorBaseList(id: number, clientId?: number, contractShopId?: number, monitorBaseName?: string, options?: any): AxiosPromise<BillingAgentMonitorBaseOutputResponse> {
            return localVarFp.billingAgentMonitorBaseList(id, clientId, contractShopId, monitorBaseName, options).then((request) => request(axios, basePath));
        },
        /**
         * 代理店に紐づくモニターベースをすべて解除する
         * @summary 代理店モニターベース紐づけ全解除
         * @param {BillingAgentMonitorBaseRemoveRelationAllFormResponse} billingAgentMonitorBaseRemoveRelationAllFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingAgentMonitorBaseRemoveRelationAll(billingAgentMonitorBaseRemoveRelationAllFormResponse: BillingAgentMonitorBaseRemoveRelationAllFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingAgentMonitorBaseRemoveRelationAll(billingAgentMonitorBaseRemoveRelationAllFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 代理店明細を取得する
         * @summary 代理店明細取得
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingagentDetail(id: string, options?: any): AxiosPromise<BillingAgentDetailOutputResponse> {
            return localVarFp.billingagentDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 代理店一覧を取得する
         * @summary 代理店一覧取得
         * @param {number} [id] 
         * @param {number} [incAccountId] 
         * @param {number} [clientId] 
         * @param {number} [contractShopId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingagentList(id?: number, incAccountId?: number, clientId?: number, contractShopId?: number, options?: any): AxiosPromise<Array<BillingAgentListOutputResponse>> {
            return localVarFp.billingagentList(id, incAccountId, clientId, contractShopId, options).then((request) => request(axios, basePath));
        },
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse} billingAgentMonitorBaseCreateRelationCheckedItemFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingagentMonitorBaseCreateRelationCheckedItem(billingAgentMonitorBaseCreateRelationCheckedItemFormResponse: BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingagentMonitorBaseCreateRelationCheckedItem(billingAgentMonitorBaseCreateRelationCheckedItemFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 代理店モニターベース紐づけ情報取得する
         * @summary 代理店モニターベース紐づけ情報取得
         * @param {Array<BillingAgentMonitorBaseRemoveRelationFormResponse>} billingAgentMonitorBaseRemoveRelationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        billingagentMonitorBaseRemoveRelation(billingAgentMonitorBaseRemoveRelationFormResponse: Array<BillingAgentMonitorBaseRemoveRelationFormResponse>, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.billingagentMonitorBaseRemoveRelation(billingAgentMonitorBaseRemoveRelationFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 代理店請求一覧CSVファイルを取得する
         * @summary 代理店支払い入金データダウンロード
         * @param {string} yyyyMm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDepositFile(yyyyMm: string, options?: any): AxiosPromise<DepositFileDownloadOutputResponse> {
            return localVarFp.downloadDepositFile(yyyyMm, options).then((request) => request(axios, basePath));
        },
        /**
         * 入金消込ファイルをアップロードする
         * @summary 代理店支払い入金データダウンロード
         * @param {DepositFileUploadFormResponse} uploadFile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDepositFile(uploadFile: DepositFileUploadFormResponse, options?: any): AxiosPromise<DepositFileUploadOutputResponse> {
            return localVarFp.uploadDepositFile(uploadFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingAgentApi - object-oriented interface
 * @export
 * @class BillingAgentApi
 * @extends {BaseAPI}
 */
export class BillingAgentApi extends BaseAPI {
    /**
     * 代理店モニターベース紐づけ情報取得する
     * @summary 代理店モニターベース紐づけ情報取得
     * @param {BillingAgentMonitorBaseCreateRelationSearchResultFormResponse} billingAgentMonitorBaseCreateRelationSearchResultFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAgentApi
     */
    public billingAgentMonitorBaseCreateRelationSearchResult(billingAgentMonitorBaseCreateRelationSearchResultFormResponse: BillingAgentMonitorBaseCreateRelationSearchResultFormResponse, options?: AxiosRequestConfig) {
        return BillingAgentApiFp(this.configuration).billingAgentMonitorBaseCreateRelationSearchResult(billingAgentMonitorBaseCreateRelationSearchResultFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 代理店モニターベース紐づけ情報取得する
     * @summary 代理店モニターベース紐づけ情報取得
     * @param {number} id 
     * @param {number} [clientId] 
     * @param {number} [contractShopId] 
     * @param {string} [monitorBaseName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAgentApi
     */
    public billingAgentMonitorBaseList(id: number, clientId?: number, contractShopId?: number, monitorBaseName?: string, options?: AxiosRequestConfig) {
        return BillingAgentApiFp(this.configuration).billingAgentMonitorBaseList(id, clientId, contractShopId, monitorBaseName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 代理店に紐づくモニターベースをすべて解除する
     * @summary 代理店モニターベース紐づけ全解除
     * @param {BillingAgentMonitorBaseRemoveRelationAllFormResponse} billingAgentMonitorBaseRemoveRelationAllFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAgentApi
     */
    public billingAgentMonitorBaseRemoveRelationAll(billingAgentMonitorBaseRemoveRelationAllFormResponse: BillingAgentMonitorBaseRemoveRelationAllFormResponse, options?: AxiosRequestConfig) {
        return BillingAgentApiFp(this.configuration).billingAgentMonitorBaseRemoveRelationAll(billingAgentMonitorBaseRemoveRelationAllFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 代理店明細を取得する
     * @summary 代理店明細取得
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAgentApi
     */
    public billingagentDetail(id: string, options?: AxiosRequestConfig) {
        return BillingAgentApiFp(this.configuration).billingagentDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 代理店一覧を取得する
     * @summary 代理店一覧取得
     * @param {number} [id] 
     * @param {number} [incAccountId] 
     * @param {number} [clientId] 
     * @param {number} [contractShopId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAgentApi
     */
    public billingagentList(id?: number, incAccountId?: number, clientId?: number, contractShopId?: number, options?: AxiosRequestConfig) {
        return BillingAgentApiFp(this.configuration).billingagentList(id, incAccountId, clientId, contractShopId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 代理店モニターベース紐づけ情報取得する
     * @summary 代理店モニターベース紐づけ情報取得
     * @param {BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse} billingAgentMonitorBaseCreateRelationCheckedItemFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAgentApi
     */
    public billingagentMonitorBaseCreateRelationCheckedItem(billingAgentMonitorBaseCreateRelationCheckedItemFormResponse: BillingAgentMonitorBaseCreateRelationCheckedItemFormResponse, options?: AxiosRequestConfig) {
        return BillingAgentApiFp(this.configuration).billingagentMonitorBaseCreateRelationCheckedItem(billingAgentMonitorBaseCreateRelationCheckedItemFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 代理店モニターベース紐づけ情報取得する
     * @summary 代理店モニターベース紐づけ情報取得
     * @param {Array<BillingAgentMonitorBaseRemoveRelationFormResponse>} billingAgentMonitorBaseRemoveRelationFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAgentApi
     */
    public billingagentMonitorBaseRemoveRelation(billingAgentMonitorBaseRemoveRelationFormResponse: Array<BillingAgentMonitorBaseRemoveRelationFormResponse>, options?: AxiosRequestConfig) {
        return BillingAgentApiFp(this.configuration).billingagentMonitorBaseRemoveRelation(billingAgentMonitorBaseRemoveRelationFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 代理店請求一覧CSVファイルを取得する
     * @summary 代理店支払い入金データダウンロード
     * @param {string} yyyyMm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAgentApi
     */
    public downloadDepositFile(yyyyMm: string, options?: AxiosRequestConfig) {
        return BillingAgentApiFp(this.configuration).downloadDepositFile(yyyyMm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 入金消込ファイルをアップロードする
     * @summary 代理店支払い入金データダウンロード
     * @param {DepositFileUploadFormResponse} uploadFile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingAgentApi
     */
    public uploadDepositFile(uploadFile: DepositFileUploadFormResponse, options?: AxiosRequestConfig) {
        return BillingAgentApiFp(this.configuration).uploadDepositFile(uploadFile, options).then((request) => request(this.axios, this.basePath));
    }
}
