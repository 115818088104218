import React from 'react';
import type { DisplayPointOutputResponse } from '../../../../../api-client';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import sass from '../../../../../scss/templates/CorrectionTemplate.module.scss';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { equalDp, useMergeStateOfCommentEditors } from '../../../../pages/Correction/utils';
import { CommentEditor } from '../parts/CommentEditor';

export const NGEditor: React.FC<{
  /** 送信に使用するDisplayPoint */
  sendingDisplayPoint?: Partial<DisplayPointOutputResponse>;
}> = ({ sendingDisplayPoint: sdp }) => {
  const {
    largeState: { state: $, setState },
  } = useSafeContext(CorrectionContext);
  const mergeArray = useMergeStateOfCommentEditors(sdp, setState);
  return (
    <CommentEditor
      title="NG添削"
      templateName="NG"
      formState={($.commentForms_ng ?? []).find(equalDp(sdp))}
      onChange={(state) => mergeArray(state, 'commentForms_ng')}
      templateList={$.res_ngTemplateList ?? []}
      // linkColor="white"
      styleClassName={sass.editor_ng}
      buttonVariant="warning"
    />
  );
};
