import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Card, Form, FloatingLabel, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faGripLines, faTimesCircle, faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { DispatchSetState, Overwrite } from '../../../interfaces/utils';
import { uuid, createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Alert } from '../../atoms/Alert';
import { Modal } from '../../molecules/Modal';
import { RecommendFloatingLabel } from '../../molecules/RecommendFloatingLabel';
import {
  MymenuListApi,
  MymenuUpsertApi,
  CommonMasterListApi,
  MymenuListOutputResponse,
  MenuOutputResponse,
  CommonMasterListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';

export interface Props {
  isModal: boolean;
  setIsModal: DispatchSetState<boolean>;
  accountId?: number;
}

type MenuItem = Partial<MenuOutputResponse> & { key: string };
type ListItem = Overwrite<
  MymenuListOutputResponse & { key: string; isOpen: boolean },
  { menuGroupId?: number; mymenuId?: number; menus: MenuItem[] }
>;

export interface State {
  api: MymenuListApi;
  commonApi: CommonMasterListApi;
  upsertApi: MymenuUpsertApi;
  list: ListItem[];
  initialList: ListItem[];
  allMenu: CommonMasterListOutputResponse[];
  updResult: IncResultOutputResponse;
}

export const SideMenuEditModal: React.VFC<Props> = ({ isModal, setIsModal, accountId }) => {
  const testid = createTestId(SideMenuEditModal);

  const { state: $, mergeState } = useLargeState<State>({
    api: new MymenuListApi(),
    commonApi: new CommonMasterListApi(),
    upsertApi: new MymenuUpsertApi(),
    list: [],
    initialList: [],
    allMenu: [],
    updResult: { result: false },
  });

  useEffect(() => {
    if (!isModal || !accountId) return;

    (async () => {
      const { data: allMenu } = await $.commonApi.commonMasterList('menu');
      const { data: menuList } = await $.api.mymenuList(accountId);
      const list = menuList.map((g) => ({
        ...g,
        key: uuid(),
        isOpen: false,
        menus: g.menus.map((m) => ({ ...m, key: uuid() })),
      }));
      mergeState({ allMenu, list, initialList: list });
    })();
  }, [isModal, accountId, $.api, $.commonApi, mergeState]);

  const onDragEnd = (result: DropResult) => {
    const { type, source, destination, draggableId } = result;
    if (!destination) return;

    const movedList = Array.from($.list);
    if (type === 'groupArea') {
      const [draggedItem] = movedList.splice(source.index, 1);
      movedList.splice(destination.index, 0, draggedItem);
    } else if (type === 'menuArea') {
      const groupKey = draggableId.substring(0, draggableId.indexOf('/'));
      const groupIndex = $.list.findIndex(({ key }) => key === groupKey);
      if (groupIndex === -1) return;

      const { menus } = movedList[groupIndex];
      const [draggedItem] = menus.splice(source.index, 1);
      menus.splice(destination.index, 0, draggedItem);
    }
    mergeState({ list: movedList });
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!accountId) return;
    const mymenus = $.list.map((g) => {
      const { key, isOpen, mymenuId, menus, ...rest } = g;
      const menuIds = menus.filter(({ id }) => !!id).map(({ id }) => id) as number[];
      return { ...rest, menuIds };
    });

    $.upsertApi
      .mymenuUpsert({ incAccountId: accountId, mymenus })
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
        setIsModal(!res.data.result);
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  const menuArea = (g: ListItem, gi: number) => (
    <div className="border p-4 my-4" data-testid={testid('menu-area', gi)}>
      <Droppable droppableId={`menuArea${g.key}`} type="menuArea">
        {(menuDroppableProvided) => (
          <ul
            style={{ listStyle: 'none' }}
            className="ps-0"
            {...menuDroppableProvided.droppableProps}
            ref={menuDroppableProvided.innerRef}
          >
            {g.menus.map((m, mi) => (
              <React.Fragment key={m.key}>
                <Draggable draggableId={`${g.key}/${m.key}`} index={mi}>
                  {(menuDraggableProvided) => (
                    <li ref={menuDraggableProvided.innerRef} {...menuDraggableProvided.draggableProps}>
                      <div className="d-flex justify-content-between align-items-center gap-4 mb-4">
                        <button type="button" style={{ cursor: 'default' }} {...menuDraggableProvided.dragHandleProps}>
                          <FontAwesomeIcon style={{ cursor: 'grab' }} icon={faGripLines} fixedWidth />
                        </button>

                        <RecommendFloatingLabel
                          options={$.allMenu.map(({ name }) => name)}
                          label="メニュー"
                          value={m.name || ''}
                          onChange={() => {}}
                          onDelete={() =>
                            mergeState({
                              list: $.list.map((a, ai) =>
                                ai === gi
                                  ? {
                                      ...a,
                                      menus: g.menus.map((b, bi) => {
                                        const { id, name, ...rest } = b;
                                        return bi === mi ? { ...rest } : b;
                                      }),
                                    }
                                  : a
                              ),
                            })
                          }
                          onClickItem={(e) => {
                            const targetOption = $.allMenu.find(({ name }) => name === e.currentTarget.innerHTML);
                            if (!targetOption) return;
                            const { id, name } = targetOption;
                            mergeState({
                              list: $.list.map((a, ai) =>
                                ai === gi
                                  ? { ...a, menus: g.menus.map((b, bi) => (bi === mi ? { ...b, id, name } : b)) }
                                  : a
                              ),
                            });
                          }}
                          className="flex-grow-1"
                        />

                        <Button
                          className="text-secondary bg-transparent border-0"
                          onClick={() =>
                            mergeState({
                              list: $.list.map((a, i) =>
                                i === gi ? { ...a, menus: a.menus.filter((_, idx) => idx !== mi) } : a
                              ),
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                        </Button>
                      </div>
                    </li>
                  )}
                </Draggable>
              </React.Fragment>
            ))}
            {menuDroppableProvided.placeholder}
          </ul>
        )}
      </Droppable>
      <div className="d-flex justify-content-end">
        <Button
          variant="link"
          className="text-secondary"
          onClick={() =>
            mergeState({
              list: $.list.map((a, i) => (i === gi ? { ...a, menus: [...a.menus, { name: '', key: uuid() }] } : a)),
            })
          }
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          追加
        </Button>
      </div>
    </div>
  );

  return (
    <Modal
      closeButton
      centered
      scrollable
      isModal={isModal}
      onHide={() => setIsModal(false)}
      size="lg"
      body={
        <>
          {$.updResult.errorMessage && (
            <Alert
              testId={testid('failure-alert')}
              variant="danger"
            >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
          )}

          <Form onSubmit={onSubmit}>
            <div className="d-flex justify-content-end mb-4 gap-2">
              <Button variant="link" onClick={() => setIsModal(false)} data-testid={testid('cancel-button')}>
                キャンセル
              </Button>

              <Button
                variant="outline-secondary"
                onClick={() => mergeState({ list: $.initialList })}
                data-testid={testid('initialize-button')}
              >
                初期状態に戻す
              </Button>

              <Button type="submit" data-testid={testid('save-button')}>
                保存
              </Button>
            </div>

            <h5 className="mb-4" data-testid={testid('title')}>
              マイメニュー編集
            </h5>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="groupArea" type="groupArea">
                {(groupDroppableProvided) => (
                  <ul
                    style={{ listStyle: 'none' }}
                    className="ps-0"
                    {...groupDroppableProvided.droppableProps}
                    ref={groupDroppableProvided.innerRef}
                  >
                    {$.list.map((g, gi) => (
                      <React.Fragment key={g.key}>
                        <Draggable draggableId={g.key} index={gi}>
                          {(groupDraggableProvided) => (
                            <li
                              ref={groupDraggableProvided.innerRef}
                              {...groupDraggableProvided.draggableProps}
                              className={$.list.length !== gi + 1 ? 'mb-4' : ''}
                            >
                              <Card>
                                <Card.Body>
                                  <div className="d-flex justify-content-between align-items-center gap-4">
                                    <button
                                      type="button"
                                      style={{ cursor: 'default' }}
                                      {...groupDraggableProvided.dragHandleProps}
                                    >
                                      <FontAwesomeIcon style={{ cursor: 'grab' }} icon={faGripLines} fixedWidth />
                                    </button>

                                    <button
                                      type="button"
                                      onClick={() =>
                                        mergeState({
                                          list: $.list.map((a, idx) => (gi === idx ? { ...a, isOpen: !a.isOpen } : a)),
                                        })
                                      }
                                    >
                                      <FontAwesomeIcon icon={g.isOpen ? faAngleUp : faAngleDown} fixedWidth />
                                    </button>

                                    <FloatingLabel
                                      label="グループタイトル"
                                      className="flex-grow-1"
                                      data-testid={testid('menuGroupName', gi)}
                                    >
                                      <Form.Control
                                        required
                                        type="text"
                                        placeholder="グループタイトル"
                                        value={g.menuGroupName}
                                        onChange={(e) =>
                                          mergeState({
                                            list: $.list.map((a, i) =>
                                              gi === i ? { ...a, menuGroupName: e.target.value } : a
                                            ),
                                          })
                                        }
                                      />
                                    </FloatingLabel>

                                    <Button
                                      className="text-secondary bg-transparent border-0"
                                      onClick={() => mergeState({ list: $.list.filter((_, i) => i !== gi) })}
                                    >
                                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                                    </Button>
                                  </div>

                                  {g.isOpen && menuArea(g, gi)}
                                </Card.Body>
                              </Card>
                            </li>
                          )}
                        </Draggable>
                      </React.Fragment>
                    ))}
                    {groupDroppableProvided.placeholder}
                  </ul>
                )}
              </Droppable>
              <div className="d-flex justify-content-end">
                <Button
                  variant="link"
                  className="text-secondary"
                  data-testid={testid('group-add-button')}
                  onClick={() =>
                    mergeState({
                      list: [
                        ...$.list,
                        { menuGroupName: '', openCloseFlg: false, menus: [], key: uuid(), isOpen: false },
                      ],
                    })
                  }
                >
                  <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                  追加
                </Button>
              </div>
            </DragDropContext>
          </Form>
        </>
      }
    />
  );
};
