import { Font, Text, StyleSheet } from '@react-pdf/renderer';
import React from 'react';
import { BILLING_PDF_SETTINGS } from '../../../BillingConstants';

type Props = {
  th?: boolean;
  maxLines?: number;
  marginLeft?: number;
};

Font.register({
  family: 'Nasu-Regular',
  src: '/fonts/Nasu-Regular.ttf',
});

export const TextStyle: React.FC<Props> = ({ th, maxLines, marginLeft, children }) => {
  const styles = StyleSheet.create({
    text: {
      fontFamily: 'Nasu-Regular',
      fontWeight: th ? 'bold' : 'normal',
      // fontSize: 7,
      maxLines: maxLines === undefined ? BILLING_PDF_SETTINGS.MAX_LINE_DEFAULT : maxLines,
      marginLeft,
    },
  });
  // これを入れないと改行時にハイフンが入ったり、word-break:normalのようになってしまう
  Font.registerHyphenationCallback((word) => {
    if (!th) {
      return Array.from(word).flatMap((char) => [char, '']);
    }
    return [word];
  });

  return <Text style={styles.text}>{children}</Text>;
};
