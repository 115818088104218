import React from 'react';
import { Spinner } from 'react-bootstrap';
import { createTestId } from '../../../utils/functions';
import './LoadingSpinner.css';

export type TestIds = 'label';
/**
 * 読み込み中はスピナーを表示、そうでない時は子要素を表示
 */
export const LoadingSpinner: React.FC<{
  isLoading: boolean | undefined;
}> = ({ isLoading, children }) => {
  const testId = createTestId<TestIds>(LoadingSpinner);
  return (
    <>
      {children}
      {isLoading ? (
        <div className="loading-spinner">
          <div className="loading-spinner mask">
            <div className="loading-spinner spinner">
              <Spinner animation="border" role="status">
                <span className="visually-hidden" data-testid={testId('label')}>
                  Loading...
                </span>
              </Spinner>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
