import { faLink, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「クーポン・割引」Contents
export const MonitorRuleSetCouponContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  return (
    <>
      <div className="d-flex mb-4">
        <Form.Check.Input
          data-testid="couponUseOkCheckBox"
          className="flex-shrink-0 me-3"
          type="checkbox"
          name="checkBoxGroup01"
          checked={
            data.monitorRuleList.find((_) => {
              return _.monitorRuleType === 13;
            })?.couponUseOkFlg === undefined
              ? false
              : data.monitorRuleList.find((_) => {
                  return _.monitorRuleType === 13;
                })?.couponUseOkFlg || false
          }
          onChange={() => {
            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((_, i) => {
                return _.monitorRuleType === 13 ? { ..._, couponUseOkFlg: !_.couponUseOkFlg } : { ..._ };
              }),
            });
          }}
        />
        <Form.Label className="me-5">利用可能</Form.Label>
        <Button
          className="ms-5"
          variant="secondary"
          onClick={() => {
            let reflectionText = '';
            let supplementText = '';
            const couponFlg: boolean | undefined = data?.monitorRuleList?.find((r) => {
              return r.monitorRuleType === 13;
            })?.couponUseOkFlg;

            if (couponFlg) {
              reflectionText = '【〇】利用可能';
            } else {
              reflectionText = '【×】利用不可';
              supplementText =
                '※同伴者含め、「店舗や他サービス」が発行するクーポン・割引などのご利用は禁止です。\n   利用が確認された場合、謝礼のお支払いはできません。';
            }

            let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
              [],
              data.monitorRuleList.find((supplement) => {
                return supplement.monitorRuleType === rowData.monitorRuleType;
              })?.supplementList
            );
            copySupplementList = copySupplementList.map((r, i) => {
              return i === 0 ? { ...r, supplement: supplementText } : { ...r };
            });

            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                return row.monitorRuleType === 13
                  ? {
                      ...row,
                      beforeApplyContent: reflectionText,
                      afterApplyContent: reflectionText,
                      supplementList: copySupplementList,
                    }
                  : { ...row };
              }),
            });
          }}
        >
          反映
        </Button>
      </div>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
