import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SqlQueryApi } from '../../../api-client';
import { SqlQueryCreateFormResponse } from '../../../api-client/domains';
import { SqlQueryInfoTable } from '../../organisms/Table/SqlQueryInfoTable';
import { PrimaryButton } from '../../atoms/Button/PrimaryButton';
import { Url } from '../../../constants/Url';

export type QueryCreateForm = SqlQueryCreateFormResponse & {
  updateAt?: string;
  updateBy: string;
  resultFilePath?: string;
  queryId?: number;
};
export const SqlQueryCreateConfirmPage = () => {
  const location = useLocation();
  const history = useHistory();
  const createContents = location.state as QueryCreateForm;

  const ClickConfirm = () => {
    const sqlQueryApi = new SqlQueryApi();
    sqlQueryApi.sqlQueryCreate(createContents).then((response) => {
      history.push(Url.KEISAI.SQL_QUERY_COMPLETION);
    });
  };
  return (
    <>
      <p>作成します。よろしいですか？</p>
      <SqlQueryInfoTable tableTitle="SQLクエリ作成確認" tableData={createContents} />
      <PrimaryButton onClick={ClickConfirm}>作成</PrimaryButton>
    </>
  );
};
