import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Tab, Tabs, Modal as CustomModal, Alert } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  DirectMailListApi,
  F3ListApi,
  F4ListApi,
  F5ListApi,
  IncResultOutputResponse,
  MailMagazineListOutputResponse,
  MailResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { Datepicker } from '../../atoms/Datepicker';
import { Title } from '../../atoms/Title';
import { MailListTable } from '../../organisms/Table/MailListTable';

export const MailListPage = () => {
  const [directMailList, setDirectMailList] = useState<MailResponse[]>([]);
  const [f3List, setF3List] = useState<MailResponse[]>([]);
  const [f4List, setF4List] = useState<MailResponse[]>([]);
  const [f5List, setF5List] = useState<MailResponse[]>([]);
  // const [data, setData] = useState<MailResponse[]>([]);

  const history = useHistory();

  const [searchParams, setSearchParms] = useState<{
    startDate: string | undefined;
    startTime: string | undefined;
    endDate: string | undefined;
    endTime: string | undefined;
  }>({
    startDate: undefined,
    startTime: undefined,
    endDate: undefined,
    endTime: undefined,
  });

  //  ページ関連変数
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const [totalPage, setTotalPage] = useState<number>(1);
  // const [listPerPage, setListPerPage] = useState<MailResponse[]>([]);
  // const MAX_RECORD_PER_PAGE = 20;

  // Copy結果
  const [copyResult, setCopyResult] = useState<IncResultOutputResponse>();

  // タブKey
  // 1: 「ダイレクト」タブ
  // 2: 「F3」タブ
  // 3: 「F4」タブ
  // 4: 「F5」タブ
  const [topTabKey, setTopTabKey] = useState<string>('1');

  const directMailListApi = new DirectMailListApi();
  const f3ListApi = new F3ListApi();
  const f4ListApi = new F4ListApi();
  const f5ListApi = new F5ListApi();

  useEffect(() => {
    // ダイレクトメール一覧取得
    directMailListApi.directMailList().then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
      // res.data.mailList[0].emailTitle = 'ダイレクトメール';
      setDirectMailList(res.data.mailList.sort((a, b) => (a.id > b.id ? -1 : 1)));
    });
    // F3一覧情報を取得
    f3ListApi.f3List().then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
      // res.data.mailList[0].emailTitle = 'F3メール';
      setF3List(res.data.mailList.sort((a, b) => (a.id > b.id ? -1 : 1)));
    });
    // F4一覧情報を取得
    f4ListApi.f4List().then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
      // res.data.mailList[0].emailTitle = 'F4メール';
      setF4List(res.data.mailList.sort((a, b) => (a.id > b.id ? -1 : 1)));
    });
    // F5一覧情報を取得
    f5ListApi.f5List().then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
      // res.data.mailList[0].emailTitle = 'F5メール';
      setF5List(res.data.mailList.sort((a, b) => (a.id > b.id ? -1 : 1)));
    });

    setCopyResult(undefined);
  }, [copyResult?.result === true]);

  const diff = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() + 9);
    return parsedate;
  };

  // 検索ボタンイベント
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    switch (topTabKey) {
      case '1':
        // 開始日：未入力
        if (searchParams.startDate === undefined || searchParams.startDate === '') {
          // 終了日：未入力 終了時間:未入力
          if (searchParams.endDate === undefined || searchParams.endDate === '') {
            directMailListApi.directMailList().then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
              setDirectMailList(res.data.mailList);
            });
          }
          // 終了日：入力 終了時間:未入力
          else if (
            searchParams.endDate !== undefined &&
            searchParams.endDate !== '' &&
            (searchParams.endTime === undefined || searchParams.endTime === '')
          ) {
            directMailListApi
              .directMailList(undefined, new Date(`${searchParams.endDate}`).toISOString())
              .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                setDirectMailList(res.data.mailList);
              });
          }
          // 終了日：入力 終了時間:入力
          else {
            directMailListApi
              .directMailList(
                undefined,
                diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString()
              )
              .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                setDirectMailList(res.data.mailList);
              });
          }
        }
        // 開始日：入力
        else if (searchParams.startDate !== undefined && searchParams.startDate !== '') {
          // 開始時間:未入力
          if (searchParams.startTime === undefined || searchParams.startTime === '') {
            // 終了日：入力 終了時間:未入力
            if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime === undefined || searchParams.endTime === '')
            ) {
              directMailListApi
                .directMailList(
                  new Date(`${searchParams.startDate}`).toISOString(),
                  new Date(`${searchParams.endDate}`).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setDirectMailList(res.data.mailList);
                });
            }
            // 終了日：入力 終了時間:入力
            else if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime !== undefined || searchParams.endTime !== '')
            ) {
              directMailListApi
                .directMailList(
                  new Date(`${searchParams.startDate}`).toISOString(),
                  diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setDirectMailList(res.data.mailList);
                });
            }
            // 終了日：未入力
            else {
              directMailListApi
                .directMailList(new Date(`${searchParams.startDate}`).toISOString(), undefined)
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setDirectMailList(res.data.mailList);
                });
            }
          }
          // 開始時間:入力
          else if (searchParams.startTime !== undefined && searchParams.startTime !== '') {
            // 終了日：入力 終了時間:未入力
            if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime === undefined || searchParams.endTime === '')
            ) {
              directMailListApi
                .directMailList(
                  diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(),
                  new Date(`${searchParams.endDate}`).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setDirectMailList(res.data.mailList);
                });
            }
            // 終了日：入力 終了時間:入力
            else if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime !== undefined || searchParams.endTime !== '')
            ) {
              directMailListApi
                .directMailList(
                  diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(),
                  diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setDirectMailList(res.data.mailList);
                });
            }
            // 終了日：未入力
            else {
              directMailListApi
                .directMailList(
                  diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(),
                  undefined
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setDirectMailList(res.data.mailList);
                });
            }
          }
        }
        break;
      case '2':
        // 開始日：未入力
        if (searchParams.startDate === undefined || searchParams.startDate === '') {
          // 終了日：未入力 終了時間:未入力
          if (searchParams.endDate === undefined || searchParams.endDate === '') {
            f3ListApi.f3List().then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
              setF3List(res.data.mailList);
            });
          }
          // 終了日：入力 終了時間:未入力
          else if (
            searchParams.endDate !== undefined &&
            searchParams.endDate !== '' &&
            (searchParams.endTime === undefined || searchParams.endTime === '')
          ) {
            f3ListApi
              .f3List(undefined, new Date(`${searchParams.endDate}`).toISOString())
              .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                setF3List(res.data.mailList);
              });
          }
          // 終了日：入力 終了時間:入力
          else {
            f3ListApi
              .f3List(undefined, diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString())
              .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                setF3List(res.data.mailList);
              });
          }
        }
        // 開始日：入力
        else if (searchParams.startDate !== undefined && searchParams.startDate !== '') {
          // 開始時間:未入力
          if (searchParams.startTime === undefined || searchParams.startTime === '') {
            // 終了日：入力 終了時間:未入力
            if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime === undefined || searchParams.endTime === '')
            ) {
              f3ListApi
                .f3List(
                  new Date(`${searchParams.startDate}`).toISOString(),
                  new Date(`${searchParams.endDate}`).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF3List(res.data.mailList);
                });
            }
            // 終了日：入力 終了時間:入力
            else if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime !== undefined || searchParams.endTime !== '')
            ) {
              f3ListApi
                .f3List(
                  new Date(`${searchParams.startDate}`).toISOString(),
                  diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF3List(res.data.mailList);
                });
            }
            // 終了日：未入力
            else {
              f3ListApi
                .f3List(new Date(`${searchParams.startDate}`).toISOString(), undefined)
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF3List(res.data.mailList);
                });
            }
          }
          // 開始時間:入力
          else if (searchParams.startTime !== undefined && searchParams.startTime !== '') {
            // 終了日：入力 終了時間:未入力
            if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime === undefined || searchParams.endTime === '')
            ) {
              f3ListApi
                .f3List(
                  diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(),
                  new Date(`${searchParams.endDate}`).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF3List(res.data.mailList);
                });
            }
            // 終了日：入力 終了時間:入力
            else if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime !== undefined || searchParams.endTime !== '')
            ) {
              f3ListApi
                .f3List(
                  diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(),
                  diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF3List(res.data.mailList);
                });
            }
            // 終了日：未入力
            else {
              f3ListApi
                .f3List(diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(), undefined)
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF3List(res.data.mailList);
                });
            }
          }
        }
        break;
      case '3':
        // 開始日：未入力
        if (searchParams.startDate === undefined || searchParams.startDate === '') {
          // 終了日：未入力 終了時間:未入力
          if (searchParams.endDate === undefined || searchParams.endDate === '') {
            f4ListApi.f4List().then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
              setF4List(res.data.mailList);
            });
          }
          // 終了日：入力 終了時間:未入力
          else if (
            searchParams.endDate !== undefined &&
            searchParams.endDate !== '' &&
            (searchParams.endTime === undefined || searchParams.endTime === '')
          ) {
            f4ListApi
              .f4List(undefined, new Date(`${searchParams.endDate}`).toISOString())
              .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                setF4List(res.data.mailList);
              });
          }
          // 終了日：入力 終了時間:入力
          else {
            f4ListApi
              .f4List(undefined, diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString())
              .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                setF4List(res.data.mailList);
              });
          }
        }
        // 開始日：入力
        else if (searchParams.startDate !== undefined && searchParams.startDate !== '') {
          // 開始時間:未入力
          if (searchParams.startTime === undefined || searchParams.startTime === '') {
            // 終了日：入力 終了時間:未入力
            if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime === undefined || searchParams.endTime === '')
            ) {
              f4ListApi
                .f4List(
                  new Date(`${searchParams.startDate}`).toISOString(),
                  new Date(`${searchParams.endDate}`).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF4List(res.data.mailList);
                });
            }
            // 終了日：入力 終了時間:入力
            else if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime !== undefined || searchParams.endTime !== '')
            ) {
              f4ListApi
                .f4List(
                  new Date(`${searchParams.startDate}`).toISOString(),
                  diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF4List(res.data.mailList);
                });
            }
            // 終了日：未入力
            else {
              f4ListApi
                .f4List(new Date(`${searchParams.startDate}`).toISOString(), undefined)
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF4List(res.data.mailList);
                });
            }
          }
          // 開始時間:入力
          else if (searchParams.startTime !== undefined && searchParams.startTime !== '') {
            // 終了日：入力 終了時間:未入力
            if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime === undefined || searchParams.endTime === '')
            ) {
              f4ListApi
                .f4List(
                  diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(),
                  new Date(`${searchParams.endDate}`).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF4List(res.data.mailList);
                });
            }
            // 終了日：入力 終了時間:入力
            else if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime !== undefined || searchParams.endTime !== '')
            ) {
              f4ListApi
                .f4List(
                  diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(),
                  diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF4List(res.data.mailList);
                });
            }
            // 終了日：未入力
            else {
              f4ListApi
                .f4List(diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(), undefined)
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF4List(res.data.mailList);
                });
            }
          }
        }
        break;
      default:
        // 開始日：未入力
        if (searchParams.startDate === undefined || searchParams.startDate === '') {
          // 終了日：未入力 終了時間:未入力
          if (searchParams.endDate === undefined || searchParams.endDate === '') {
            f5ListApi.f5List().then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
              setF5List(res.data.mailList);
            });
          }
          // 終了日：入力 終了時間:未入力
          else if (
            searchParams.endDate !== undefined &&
            searchParams.endDate !== '' &&
            (searchParams.endTime === undefined || searchParams.endTime === '')
          ) {
            f5ListApi
              .f5List(undefined, new Date(`${searchParams.endDate}`).toISOString())
              .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                setF5List(res.data.mailList);
              });
          }
          // 終了日：入力 終了時間:入力
          else {
            f5ListApi
              .f5List(undefined, diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString())
              .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                setF5List(res.data.mailList);
              });
          }
        }
        // 開始日：入力
        else if (searchParams.startDate !== undefined && searchParams.startDate !== '') {
          // 開始時間:未入力
          if (searchParams.startTime === undefined || searchParams.startTime === '') {
            // 終了日：入力 終了時間:未入力
            if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime === undefined || searchParams.endTime === '')
            ) {
              f5ListApi
                .f5List(
                  new Date(`${searchParams.startDate}`).toISOString(),
                  new Date(`${searchParams.endDate}`).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF5List(res.data.mailList);
                });
            }
            // 終了日：入力 終了時間:入力
            else if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime !== undefined || searchParams.endTime !== '')
            ) {
              f5ListApi
                .f5List(
                  new Date(`${searchParams.startDate}`).toISOString(),
                  diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF5List(res.data.mailList);
                });
            }
            // 終了日：未入力
            else {
              f5ListApi
                .f5List(new Date(`${searchParams.startDate}`).toISOString(), undefined)
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF5List(res.data.mailList);
                });
            }
          }
          // 開始時間:入力
          else if (searchParams.startTime !== undefined && searchParams.startTime !== '') {
            // 終了日：入力 終了時間:未入力
            if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime === undefined || searchParams.endTime === '')
            ) {
              f5ListApi
                .f5List(
                  diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(),
                  new Date(`${searchParams.endDate}`).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF5List(res.data.mailList);
                });
            }
            // 終了日：入力 終了時間:入力
            else if (
              searchParams.endDate !== undefined &&
              searchParams.endDate !== '' &&
              (searchParams.endTime !== undefined || searchParams.endTime !== '')
            ) {
              f5ListApi
                .f5List(
                  diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(),
                  diff(new Date(`${searchParams.endDate} ${searchParams.endTime}`)).toISOString()
                )
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF5List(res.data.mailList);
                });
            }
            // 終了日：未入力
            else {
              f5ListApi
                .f5List(diff(new Date(`${searchParams.startDate} ${searchParams.startTime}`)).toISOString(), undefined)
                .then((res: AxiosResponse<MailMagazineListOutputResponse>) => {
                  setF5List(res.data.mailList);
                });
            }
          }
        }
        break;
    }
  };

  // タブ変更イベント
  // param : key 押下したタブキー
  const onChangeTopTab = (key: string | null) => {
    setTopTabKey(key === null ? '' : key);
  };

  const pageContents1 = (
    <>
      <div className="bg-light p-2 mb-4">
        <div className="d-flex align-items-center ">
          <Col className="col-8">
            <Form.Group className="d-flex align-items-center mt-3" controlId="readOnly1">
              <Datepicker
                // value={timestamp.slice(0, timestamp.indexOf(' ')).replace(/\//g, '-')}
                value={searchParams.startDate === undefined ? '' : searchParams.startDate}
                className="me-2"
                onChange={(e) => {
                  setSearchParms({ ...searchParams, startDate: e.target.value });
                }}
                data-testid="startDate"
              />
              <Form.Control
                type="time"
                step="1"
                value={searchParams.startTime === undefined ? '' : searchParams.startTime}
                className="me-2"
                onChange={(e) => {
                  setSearchParms({ ...searchParams, startTime: e.target.value });
                }}
                data-testid="startTime"
              />

              <Form.Label className="mt-2 me-2 ms-2">～</Form.Label>
              <Datepicker
                // value={timestamp.slice(0, timestamp.indexOf(' ')).replace(/\//g, '-')}
                value={searchParams.endDate === undefined ? '' : searchParams.endDate}
                className="me-2 ms-2"
                onChange={(e) => {
                  setSearchParms({ ...searchParams, endDate: e.target.value });
                }}
                data-testid="endDate"
              />
              <Form.Control
                type="time"
                step="1"
                value={searchParams.endTime === undefined ? '' : searchParams.endTime}
                className="me-2"
                onChange={(e) => {
                  setSearchParms({ ...searchParams, endTime: e.target.value });
                }}
                data-testid="endTime"
              />

              <div className="ms-2" style={{ textAlign: 'center', width: '30%' }}>
                <Button type="button" style={{ width: '100%', height: '100%' }} onClick={handleOnSubmit}>
                  検索
                </Button>
              </div>
            </Form.Group>
          </Col>
        </div>
      </div>
      <div className="mb-0" style={{ textAlign: 'right' }}>
        <Button
          data-testid="add"
          variant="link"
          className="text-secondary p-0"
          onClick={() => {
            if (topTabKey === '1') {
              history.push({
                pathname: `${Url.KEISAI.DIRECTMAIL_MODIFY}`,
              });
            } else if (topTabKey === '2') {
              history.push({
                pathname: `${Url.KEISAI.F3MAIL_MODIFY}`,
              });
            } else if (topTabKey === '3') {
              history.push({
                pathname: `${Url.KEISAI.F4MAIL_MODIFY}`,
              });
            } else {
              history.push({
                pathname: `${Url.KEISAI.F5MAIL_MODIFY}`,
              });
            }
          }}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          <span style={{ fontSize: '1rem' }}>追加</span>
        </Button>
      </div>
      <MailListTable
        directMailList={directMailList}
        f3List={f3List}
        f4List={f4List}
        f5List={f5List}
        setCopyResult={setCopyResult}
        topTabKey={topTabKey}
      />
    </>
  );

  return (
    <>
      <Title className="mb-4">配信メール一覧画面</Title>
      {copyResult !== null && copyResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${copyResult.errorMessage} (エラーコード：${copyResult.errorCode})`}</Alert>
      )}
      <Tabs
        defaultActiveKey={topTabKey}
        id="uncontrolled-tab-example"
        onSelect={(key) => {
          onChangeTopTab(key);
        }}
        className="mb-4"
      >
        <Tab eventKey="1" title="ダイレクト">
          {pageContents1}
        </Tab>
        <Tab eventKey="2" title="F3">
          {pageContents1}
        </Tab>
        <Tab eventKey="3" title="F4">
          {pageContents1}
        </Tab>
        <Tab eventKey="4" title="F5">
          {pageContents1}
        </Tab>
      </Tabs>
    </>
  );
};
