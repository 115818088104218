import React, { useState, useEffect, useMemo } from 'react';
import { Button } from '../../atoms/Button';
import { uuid } from '../../../utils/functions';
import { ApplyInfoDisplayOutputResponse } from '../../../api-client';
import { ImageLens } from '../ImageLens';

// TODO: [添削] テスト未実装
const StateTabs: React.FC<{
  activeId: number | string;
  tabs: {
    id: number | string;
    label: string;
    disabled?: boolean;
  }[];
  onSelect?: (id: number | string) => void;
}> = ({ activeId, tabs, onSelect }) => {
  const [selectedId, setSelectedId] = useState(activeId);
  return (
    <ul className="nav nav-tabs">
      {tabs.map(({ id, label, disabled }) => (
        <li className="nav-item" key={uuid()}>
          <Button
            variant="link"
            className={['nav-link']
              .concat(id === selectedId ? 'active' : [])
              .concat(disabled ? 'disabled' : [])
              .join(' ')}
            onClick={() => {
              setSelectedId(id);
              onSelect?.(id);
            }}
          >
            {label}
          </Button>
        </li>
      ))}
    </ul>
  );
};
// 金額修正の購入証明情報コンポーネント
export const ReceiptPricePurchaseProofInfo: React.FC<{
  applyInfoDisplay: ApplyInfoDisplayOutputResponse | undefined;
  onChangeExif: (exif?: string) => void;
}> = React.memo(({ applyInfoDisplay, onChangeExif }) => {
  const [selectedSurveyProofVersion, setSelectedSurveyProofVersion] = useState(
    applyInfoDisplay?.surveyProofList?.[0].versionName
  );
  const list = useMemo(
    () =>
      applyInfoDisplay?.surveyProofList?.find(({ versionName }) => selectedSurveyProofVersion === versionName)
        ?.surveyProofImageInfoDtoList ?? [],
    [applyInfoDisplay?.surveyProofList, selectedSurveyProofVersion]
  );
  useEffect(() => {
    if (!selectedSurveyProofVersion) {
      setSelectedSurveyProofVersion(applyInfoDisplay?.surveyProofList?.[0].versionName);
    }
  }, []);

  if (applyInfoDisplay?.surveyProofList?.length === 0) return <div>画像はありません</div>;

  if (
    selectedSurveyProofVersion === undefined ||
    !applyInfoDisplay?.surveyProofList?.[0].surveyProofImageInfoDtoList?.[0].imageId
  )
    return (
      <>
        <h5>購入証明情報</h5>
      </>
    );

  return (
    <>
      <h5>購入証明情報</h5>
      <StateTabs
        activeId={selectedSurveyProofVersion}
        tabs={applyInfoDisplay.surveyProofList.map(({ versionName }) => ({
          id: versionName,
          label: versionName,
        }))}
        onSelect={(id) => {
          setSelectedSurveyProofVersion(id as string);
        }}
      />
      <ImageLens list={list} linkType="PAGE" />
    </>
  );
});
