/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplyInfoDisplayOutputResponse } from '../domains';
// @ts-ignore
import { CorrectionCheckDisplayOutputResponse } from '../domains';
// @ts-ignore
import { CorrectionHistoryDisplayOutputResponse } from '../domains';
// @ts-ignore
import { CorrectionNGListOutputResponse } from '../domains';
// @ts-ignore
import { CorrectionStatusDisplayOutputResponse } from '../domains';
// @ts-ignore
import { EnqueteDisplayOutputResponse } from '../domains';
// @ts-ignore
import { EscalationInfoDisplayOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { ManualApplyAssignFormResponse } from '../domains';
// @ts-ignore
import { NgAndOtherHistoryDetailOutputResponse } from '../domains';
// @ts-ignore
import { NoRewardFlgUpdateFormResponse } from '../domains';
// @ts-ignore
import { NormalCorrectApplyDistributeFormResponse } from '../domains';
// @ts-ignore
import { NormalCorrectApplyDistributeOutputResponse } from '../domains';
// @ts-ignore
import { ReceiptCorrectionStepDisplayOutputResponse } from '../domains';
// @ts-ignore
import { ReceiptInfoDisplayOutputResponse } from '../domains';
// @ts-ignore
import { RejectInfoDisplayResultOutputResponse } from '../domains';
/**
 * CorrectApi - axios parameter creator
 * @export
 */
export const CorrectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 回答およびNG履歴詳細
         * @param {number} applyId 
         * @param {number} correctionTargetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerAndNgHistoryDetail: async (applyId: number, correctionTargetType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('answerAndNgHistoryDetail', 'applyId', applyId)
            // verify required parameter 'correctionTargetType' is not null or undefined
            assertParamExists('answerAndNgHistoryDetail', 'correctionTargetType', correctionTargetType)
            const localVarPath = `/api/correct/answer-ng-history-detail/{apply-id}/{correction-target-type}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)))
                .replace(`{${"correction-target-type"}}`, encodeURIComponent(String(correctionTargetType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 応募情報を表示する
         * @summary 応募情報表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyInfoDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('applyInfoDisplay', 'applyId', applyId)
            const localVarPath = `/api/correct/apply-info-display/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 添削チェック内容を表示する
         * @summary 添削チェック内容表示
         * @param {number} applyId 
         * @param {number} correctionTargetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionCheckDisplay: async (applyId: number, correctionTargetType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('correctionCheckDisplay', 'applyId', applyId)
            // verify required parameter 'correctionTargetType' is not null or undefined
            assertParamExists('correctionCheckDisplay', 'correctionTargetType', correctionTargetType)
            const localVarPath = `/api/correct/check-point-display/{apply-id}/{correction-target-type}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)))
                .replace(`{${"correction-target-type"}}`, encodeURIComponent(String(correctionTargetType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 添削履歴表示
         * @summary 添削履歴表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionHistoryDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('correctionHistoryDisplay', 'applyId', applyId)
            const localVarPath = `/api/correct/correction-history/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 添削チェック結果を表示する
         * @summary 添削チェック結果表示
         * @param {number} applyId 
         * @param {number} correctionTargetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionNgList: async (applyId: number, correctionTargetType: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('correctionNgList', 'applyId', applyId)
            // verify required parameter 'correctionTargetType' is not null or undefined
            assertParamExists('correctionNgList', 'correctionTargetType', correctionTargetType)
            const localVarPath = `/api/correct/correction-ng/list/{apply-id}/{correction-target-type}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)))
                .replace(`{${"correction-target-type"}}`, encodeURIComponent(String(correctionTargetType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 添削関係のステータス等を表示するためのAPい
         * @summary 添削ステータス取得
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionStatusDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('correctionStatusDisplay', 'applyId', applyId)
            const localVarPath = `/api/correct/correction-status-display/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート情報を表示する
         * @summary アンケート表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('enqueteDisplay', 'applyId', applyId)
            const localVarPath = `/api/correct/enquete-display/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エスカレーション情報を表示する
         * @summary エスカレーション情報表示
         * @param {number} applyId 
         * @param {number} correctionTargetTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        escalationInfoDisplay: async (applyId: number, correctionTargetTypeCode: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('escalationInfoDisplay', 'applyId', applyId)
            // verify required parameter 'correctionTargetTypeCode' is not null or undefined
            assertParamExists('escalationInfoDisplay', 'correctionTargetTypeCode', correctionTargetTypeCode)
            const localVarPath = `/api/correct/escalation-info-display/{apply-id}/{correction-target-type-code}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)))
                .replace(`{${"correction-target-type-code"}}`, encodeURIComponent(String(correctionTargetTypeCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 手動案件アサイン
         * @summary 手動案件アサイン
         * @param {ManualApplyAssignFormResponse} manualApplyAssignFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualApplyAssign: async (manualApplyAssignFormResponse: ManualApplyAssignFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'manualApplyAssignFormResponse' is not null or undefined
            assertParamExists('manualApplyAssign', 'manualApplyAssignFormResponse', manualApplyAssignFormResponse)
            const localVarPath = `/api/correct/manual-apply-assign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualApplyAssignFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 報酬なし添削保存
         * @param {NoRewardFlgUpdateFormResponse} noRewardFlgUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noRewardFlgUpdate: async (noRewardFlgUpdateFormResponse: NoRewardFlgUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'noRewardFlgUpdateFormResponse' is not null or undefined
            assertParamExists('noRewardFlgUpdate', 'noRewardFlgUpdateFormResponse', noRewardFlgUpdateFormResponse)
            const localVarPath = `/api/correct/no-reward-flg`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noRewardFlgUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 通常添削案件分配
         * @summary 通常添削案件分配
         * @param {NormalCorrectApplyDistributeFormResponse} normalCorrectApplyDistributeFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        normalCorrectTargetDistribute: async (normalCorrectApplyDistributeFormResponse: NormalCorrectApplyDistributeFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'normalCorrectApplyDistributeFormResponse' is not null or undefined
            assertParamExists('normalCorrectTargetDistribute', 'normalCorrectApplyDistributeFormResponse', normalCorrectApplyDistributeFormResponse)
            const localVarPath = `/api/correct/target-distribute/normal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(normalCorrectApplyDistributeFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レシート添削進捗を表示する
         * @summary レシート添削進捗表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionStepDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('receiptCorrectionStepDisplay', 'applyId', applyId)
            const localVarPath = `/api/correct/receipt/step-display/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レシート情報を表示する
         * @summary レシート情報表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptInfoDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('receiptInfoDisplay', 'applyId', applyId)
            const localVarPath = `/api/correct/receipt/receipt-info/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 却下情報を表示する
         * @summary 却下情報表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectInfoDisplay: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('rejectInfoDisplay', 'applyId', applyId)
            const localVarPath = `/api/correct/reject-info-display/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorrectApi - functional programming interface
 * @export
 */
export const CorrectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorrectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 回答およびNG履歴詳細
         * @param {number} applyId 
         * @param {number} correctionTargetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async answerAndNgHistoryDetail(applyId: number, correctionTargetType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NgAndOtherHistoryDetailOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.answerAndNgHistoryDetail(applyId, correctionTargetType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 応募情報を表示する
         * @summary 応募情報表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applyInfoDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApplyInfoDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applyInfoDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 添削チェック内容を表示する
         * @summary 添削チェック内容表示
         * @param {number} applyId 
         * @param {number} correctionTargetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctionCheckDisplay(applyId: number, correctionTargetType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorrectionCheckDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctionCheckDisplay(applyId, correctionTargetType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 添削履歴表示
         * @summary 添削履歴表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctionHistoryDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CorrectionHistoryDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctionHistoryDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 添削チェック結果を表示する
         * @summary 添削チェック結果表示
         * @param {number} applyId 
         * @param {number} correctionTargetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctionNgList(applyId: number, correctionTargetType: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CorrectionNGListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctionNgList(applyId, correctionTargetType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 添削関係のステータス等を表示するためのAPい
         * @summary 添削ステータス取得
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctionStatusDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorrectionStatusDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctionStatusDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート情報を表示する
         * @summary アンケート表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnqueteDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * エスカレーション情報を表示する
         * @summary エスカレーション情報表示
         * @param {number} applyId 
         * @param {number} correctionTargetTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async escalationInfoDisplay(applyId: number, correctionTargetTypeCode: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EscalationInfoDisplayOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.escalationInfoDisplay(applyId, correctionTargetTypeCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 手動案件アサイン
         * @summary 手動案件アサイン
         * @param {ManualApplyAssignFormResponse} manualApplyAssignFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualApplyAssign(manualApplyAssignFormResponse: ManualApplyAssignFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualApplyAssign(manualApplyAssignFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 報酬なし添削保存
         * @param {NoRewardFlgUpdateFormResponse} noRewardFlgUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async noRewardFlgUpdate(noRewardFlgUpdateFormResponse: NoRewardFlgUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.noRewardFlgUpdate(noRewardFlgUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 通常添削案件分配
         * @summary 通常添削案件分配
         * @param {NormalCorrectApplyDistributeFormResponse} normalCorrectApplyDistributeFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async normalCorrectTargetDistribute(normalCorrectApplyDistributeFormResponse: NormalCorrectApplyDistributeFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NormalCorrectApplyDistributeOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.normalCorrectTargetDistribute(normalCorrectApplyDistributeFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レシート添削進捗を表示する
         * @summary レシート添削進捗表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCorrectionStepDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptCorrectionStepDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptCorrectionStepDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レシート情報を表示する
         * @summary レシート情報表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptInfoDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptInfoDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptInfoDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 却下情報を表示する
         * @summary 却下情報表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectInfoDisplay(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RejectInfoDisplayResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectInfoDisplay(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CorrectApi - factory interface
 * @export
 */
export const CorrectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorrectApiFp(configuration)
    return {
        /**
         * 
         * @summary 回答およびNG履歴詳細
         * @param {number} applyId 
         * @param {number} correctionTargetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        answerAndNgHistoryDetail(applyId: number, correctionTargetType: number, options?: any): AxiosPromise<Array<NgAndOtherHistoryDetailOutputResponse>> {
            return localVarFp.answerAndNgHistoryDetail(applyId, correctionTargetType, options).then((request) => request(axios, basePath));
        },
        /**
         * 応募情報を表示する
         * @summary 応募情報表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyInfoDisplay(applyId: number, options?: any): AxiosPromise<ApplyInfoDisplayOutputResponse> {
            return localVarFp.applyInfoDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 添削チェック内容を表示する
         * @summary 添削チェック内容表示
         * @param {number} applyId 
         * @param {number} correctionTargetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionCheckDisplay(applyId: number, correctionTargetType: number, options?: any): AxiosPromise<CorrectionCheckDisplayOutputResponse> {
            return localVarFp.correctionCheckDisplay(applyId, correctionTargetType, options).then((request) => request(axios, basePath));
        },
        /**
         * 添削履歴表示
         * @summary 添削履歴表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionHistoryDisplay(applyId: number, options?: any): AxiosPromise<Array<CorrectionHistoryDisplayOutputResponse>> {
            return localVarFp.correctionHistoryDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 添削チェック結果を表示する
         * @summary 添削チェック結果表示
         * @param {number} applyId 
         * @param {number} correctionTargetType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionNgList(applyId: number, correctionTargetType: number, options?: any): AxiosPromise<Array<CorrectionNGListOutputResponse>> {
            return localVarFp.correctionNgList(applyId, correctionTargetType, options).then((request) => request(axios, basePath));
        },
        /**
         * 添削関係のステータス等を表示するためのAPい
         * @summary 添削ステータス取得
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionStatusDisplay(applyId: number, options?: any): AxiosPromise<CorrectionStatusDisplayOutputResponse> {
            return localVarFp.correctionStatusDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート情報を表示する
         * @summary アンケート表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteDisplay(applyId: number, options?: any): AxiosPromise<Array<EnqueteDisplayOutputResponse>> {
            return localVarFp.enqueteDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * エスカレーション情報を表示する
         * @summary エスカレーション情報表示
         * @param {number} applyId 
         * @param {number} correctionTargetTypeCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        escalationInfoDisplay(applyId: number, correctionTargetTypeCode: number, options?: any): AxiosPromise<Array<EscalationInfoDisplayOutputResponse>> {
            return localVarFp.escalationInfoDisplay(applyId, correctionTargetTypeCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 手動案件アサイン
         * @summary 手動案件アサイン
         * @param {ManualApplyAssignFormResponse} manualApplyAssignFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualApplyAssign(manualApplyAssignFormResponse: ManualApplyAssignFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.manualApplyAssign(manualApplyAssignFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 報酬なし添削保存
         * @param {NoRewardFlgUpdateFormResponse} noRewardFlgUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        noRewardFlgUpdate(noRewardFlgUpdateFormResponse: NoRewardFlgUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.noRewardFlgUpdate(noRewardFlgUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 通常添削案件分配
         * @summary 通常添削案件分配
         * @param {NormalCorrectApplyDistributeFormResponse} normalCorrectApplyDistributeFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        normalCorrectTargetDistribute(normalCorrectApplyDistributeFormResponse: NormalCorrectApplyDistributeFormResponse, options?: any): AxiosPromise<NormalCorrectApplyDistributeOutputResponse> {
            return localVarFp.normalCorrectTargetDistribute(normalCorrectApplyDistributeFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * レシート添削進捗を表示する
         * @summary レシート添削進捗表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionStepDisplay(applyId: number, options?: any): AxiosPromise<ReceiptCorrectionStepDisplayOutputResponse> {
            return localVarFp.receiptCorrectionStepDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * レシート情報を表示する
         * @summary レシート情報表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptInfoDisplay(applyId: number, options?: any): AxiosPromise<ReceiptInfoDisplayOutputResponse> {
            return localVarFp.receiptInfoDisplay(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 却下情報を表示する
         * @summary 却下情報表示
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectInfoDisplay(applyId: number, options?: any): AxiosPromise<RejectInfoDisplayResultOutputResponse> {
            return localVarFp.rejectInfoDisplay(applyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CorrectApi - object-oriented interface
 * @export
 * @class CorrectApi
 * @extends {BaseAPI}
 */
export class CorrectApi extends BaseAPI {
    /**
     * 
     * @summary 回答およびNG履歴詳細
     * @param {number} applyId 
     * @param {number} correctionTargetType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public answerAndNgHistoryDetail(applyId: number, correctionTargetType: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).answerAndNgHistoryDetail(applyId, correctionTargetType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 応募情報を表示する
     * @summary 応募情報表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public applyInfoDisplay(applyId: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).applyInfoDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 添削チェック内容を表示する
     * @summary 添削チェック内容表示
     * @param {number} applyId 
     * @param {number} correctionTargetType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public correctionCheckDisplay(applyId: number, correctionTargetType: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).correctionCheckDisplay(applyId, correctionTargetType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 添削履歴表示
     * @summary 添削履歴表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public correctionHistoryDisplay(applyId: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).correctionHistoryDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 添削チェック結果を表示する
     * @summary 添削チェック結果表示
     * @param {number} applyId 
     * @param {number} correctionTargetType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public correctionNgList(applyId: number, correctionTargetType: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).correctionNgList(applyId, correctionTargetType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 添削関係のステータス等を表示するためのAPい
     * @summary 添削ステータス取得
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public correctionStatusDisplay(applyId: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).correctionStatusDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート情報を表示する
     * @summary アンケート表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public enqueteDisplay(applyId: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).enqueteDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エスカレーション情報を表示する
     * @summary エスカレーション情報表示
     * @param {number} applyId 
     * @param {number} correctionTargetTypeCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public escalationInfoDisplay(applyId: number, correctionTargetTypeCode: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).escalationInfoDisplay(applyId, correctionTargetTypeCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 手動案件アサイン
     * @summary 手動案件アサイン
     * @param {ManualApplyAssignFormResponse} manualApplyAssignFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public manualApplyAssign(manualApplyAssignFormResponse: ManualApplyAssignFormResponse, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).manualApplyAssign(manualApplyAssignFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 報酬なし添削保存
     * @param {NoRewardFlgUpdateFormResponse} noRewardFlgUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public noRewardFlgUpdate(noRewardFlgUpdateFormResponse: NoRewardFlgUpdateFormResponse, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).noRewardFlgUpdate(noRewardFlgUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 通常添削案件分配
     * @summary 通常添削案件分配
     * @param {NormalCorrectApplyDistributeFormResponse} normalCorrectApplyDistributeFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public normalCorrectTargetDistribute(normalCorrectApplyDistributeFormResponse: NormalCorrectApplyDistributeFormResponse, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).normalCorrectTargetDistribute(normalCorrectApplyDistributeFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * レシート添削進捗を表示する
     * @summary レシート添削進捗表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public receiptCorrectionStepDisplay(applyId: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).receiptCorrectionStepDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * レシート情報を表示する
     * @summary レシート情報表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public receiptInfoDisplay(applyId: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).receiptInfoDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 却下情報を表示する
     * @summary 却下情報表示
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectApi
     */
    public rejectInfoDisplay(applyId: number, options?: AxiosRequestConfig) {
        return CorrectApiFp(this.configuration).rejectInfoDisplay(applyId, options).then((request) => request(this.axios, this.basePath));
    }
}
