import React from 'react';
import { Button } from 'react-bootstrap';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../store/correctionStore';
import { DisplayPointType } from '../../../pages/Correction/constants';
import { Escalation } from '../Escalation';
import { Section, Step } from './parts/separations';
import { CheckListSection } from './sections/CheckListSection';
import { CommonPanel } from './sections/CommonPanel';
import { ResultFormSection } from './sections/ResultFormSection';
import { CustomerRemarksSection } from './sections/CustomerRemarksSection';

// TODO: [添削] テスト未実装
export const EnqueteContents: React.FC = () => {
  const {
    largeState: { state: $, mergeState },
  } = useSafeContext(CorrectionContext);

  return (
    <>
      <Button variant="link" onClick={() => mergeState({ isReceiptInfoModal: true })}>
        レシート情報表示
      </Button>
      <Step name="">
        <Section title="全体のノート・NG・エスカレ履歴" description="下記ノートを確認し問題なければチェックOK">
          <CommonPanel
            displayPoint={{
              type: DisplayPointType.FOR_ALL,
              targetId: null,
            }}
            noForm
          />
        </Section>
        <Section title="却下承認依頼履歴">
          <Escalation isReject escaletes={$.res_rejectInfoDisplay?.rejectHistory ?? []} />
        </Section>
      </Step>
      <Step name="アンケート添削">
        <CheckListSection />
      </Step>
      <Step name="添削結果入力">
        <ResultFormSection />
      </Step>
    </>
  );
};
