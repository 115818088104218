import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Modal } from '../../molecules/Modal';
import { BillingApi, BillingInvoiceDetailItemResultOutputResponse } from '../../../api-client';

export interface DeliveryNoteUpdate {
  billingHeaderId: number;
  deliveryNote: string;
  isTmp: boolean;
}

export interface Props {
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  detail: BillingInvoiceDetailItemResultOutputResponse;
  setDetail: React.Dispatch<React.SetStateAction<BillingInvoiceDetailItemResultOutputResponse>>;
}

/**
 * TODO 似たようなモーダル作っちゃってるのでリファクタ
 * @param isModal
 * @param setIsModal
 * @param detail
 * @param setDetail
 * @constructor
 */
export const BillingDetailMemoModal: React.VFC<Props> = ({ isModal, setIsModal, detail, setDetail }) => {
  const billingApi = new BillingApi();
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDetail((prevState) => ({
      ...prevState,
      billingInvoiceDetailItemData: { ...prevState.billingInvoiceDetailItemData, deliveryNote: event.target.value },
    }));
  };

  const onClickSave = (isTmp: boolean) => {
    billingApi
      .billingDeliveryNoteUpdate({
        billingHeaderId: detail.billingInvoiceDetailItemData.billingHeaderId,
        deliveryNote: detail.billingInvoiceDetailItemData.deliveryNote,
        isTmp,
      })
      .then((res) => {
        onHide();
      });
  };

  const onHide = () => {
    setIsModal(false);
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            <Row>
              <Form.Group controlId="memo">
                <Form.Label>配送memo</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="memo"
                  value={detail.billingInvoiceDetailItemData.deliveryNote}
                  onChange={onChangeText}
                />
              </Form.Group>
            </Row>
            <div className="d-flex justify-content-center mt-4 mb-2">
              <Button variant="secondary" className="me-4" onClick={() => onClickSave(false)}>
                テンプレとして保存
              </Button>
              <Button variant="secondary" className="me-4" onClick={() => onClickSave(true)}>
                今回のみ
              </Button>
              <Button variant="secondary" className="me-4" onClick={onHide}>
                キャンセル
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
