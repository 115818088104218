import React, { useState, useEffect } from 'react';
import { Form, Button, Table } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { TITLE } from '../../../constants/Title';
import { Url } from '../../../constants/Url';
/* eslint-disable import/no-cycle */
import {
  ClientOrganizationSettingFileUploadApi,
  ClientOrganizationSettingFileDownloadApi,
  ClientOrganizationSettingTemplateFileDownloadApi,
  ClientOrganizationSettingFileCheckApi,
  ClientOrganizationSettingFileCheckOutputResponse,
  ClientOrganizationSettingFileCheckOutputListItemResponse,
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  IncResultOutputResponse,
  ClientOrganizationSettingFileUploadFormListItemResponse,
  RoleListApi,
  IdNameOutputResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, execDownload, when } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Title } from '../../atoms/Title';
import { Dropzone } from '../../molecules/Dropzone';
import { TagsInput } from '../../molecules/TagsInput';

/* eslint-enable import/no-cycle */

export interface State {
  uploadApi: ClientOrganizationSettingFileUploadApi;
  downloadApi: ClientOrganizationSettingFileDownloadApi;
  templateDownloadApi: ClientOrganizationSettingTemplateFileDownloadApi;
  fileCheckApi: ClientOrganizationSettingFileCheckApi;
  commonApi: CommonMasterListApi;
  clientOpts: CommonMasterListOutputResponse[];
  clientIds: number[];
  layersCount?: number;
  checkResult: boolean;
  resultMessageList: string[];
  list: ClientOrganizationSettingFileCheckOutputListItemResponse[];
  updResult: IncResultOutputResponse;
}

export const ClientOrganizationSettingPage: React.VFC = () => {
  const testid = createTestId(ClientOrganizationSettingPage);
  const history = useHistory();

  const { state: $, mergeState } = useLargeState<State>({
    uploadApi: new ClientOrganizationSettingFileUploadApi(),
    downloadApi: new ClientOrganizationSettingFileDownloadApi(),
    templateDownloadApi: new ClientOrganizationSettingTemplateFileDownloadApi(),
    fileCheckApi: new ClientOrganizationSettingFileCheckApi(),
    commonApi: new CommonMasterListApi(),
    clientIds: [],
    clientOpts: [],
    checkResult: false,
    resultMessageList: [],
    list: [],
    updResult: { result: false },
  });

  const [tags, setTags] = useState<string[]>([]);

  // 営業ロール保有者フラグ
  const [salesFlg, setSalesFlg] = useState<boolean>(false);

  const roleListApi = new RoleListApi();

  // 画面初期表示時に一回だけロール一覧を取得し、営業ロールを持っているか確認する。
  useEffect(() => {
    roleListApi.roleList().then((res: AxiosResponse<IdNameOutputResponse[]>) => {
      setSalesFlg(res.data.some((role) => role.name === '営業'));
    });
  }, []);

  useEffect(() => {
    $.commonApi.commonMasterList('client').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
      mergeState({ clientOpts: res.data });
    });
  }, [$.commonApi, mergeState]);

  useEffect(
    () =>
      mergeState({
        clientIds: tags.map((name) => Number(name.substring(0, name.indexOf('：')))).filter(Boolean),
      }),
    [tags, $.clientOpts, mergeState]
  );

  const onDownload = () => {
    if ($.clientIds.length === 0) return;
    $.downloadApi.clientOrganizationSettingFileDownload($.clientIds).then((res: AxiosResponse<string>) => {
      execDownload(
        res.data,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '【クライアント組織】ダウンロード.xlsx'
      ).catch(() => {
        history.push(Url.COMMON_ERROR);
      });
    });
  };

  const onDrop = (files: File[]) => {
    if (files === null || files.length === 0) return;
    const file = files[0];

    const reader = new FileReader();
    reader.onload = () => {
      $.fileCheckApi
        .clientOrganizationSettingFileCheck({
          file: reader.result?.toString().replace(/data:.*\/.*;base64,/, '') || '',
        })
        .then((res: AxiosResponse<ClientOrganizationSettingFileCheckOutputResponse>) => {
          console.log('onDrop res.data', res.data);
          const { result, ...rest } = res.data;
          mergeState({ checkResult: result, ...rest });
        });
    };
    reader.readAsDataURL(file);
  };

  const onUpload = () => {
    const list: ClientOrganizationSettingFileUploadFormListItemResponse[] = $.list.map(
      ({ clientId, clientName, shopId, shopName, organizationList }) => {
        return {
          clientId: `${clientId}`,
          clientName: `${clientName}`,
          organizationList: organizationList.map((item) => {
            return {
              organizationId: item.organizationId,
              organizationLevelName: `${item.organizationLevelName}`,
              organizationName: `${item.organizationName}`,
            };
          }),
          shopId: `${shopId}`,
          shopName: `${shopName}`,
        };
      }
    );
    $.uploadApi
      .clientOrganizationSettingFileUpload({ list })
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  return (
    <>
      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.CLIENT_ORGANIZATION_SETTING}
      </Title>

      {$.updResult.result && (
        <Alert testId={testid('success-alert')} variant="success">
          保存しました。
        </Alert>
      )}
      {$.updResult.errorMessage && (
        <Alert
          testId={testid('failure-alert')}
          variant="danger"
        >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}
      {$.resultMessageList.length > 0 && (
        <Alert testId={testid('check-alert')} variant="danger">
          {$.resultMessageList?.map((m, i) => (
            <div key={i}>{m}</div>
          ))}
        </Alert>
      )}

      <div className="d-flex justify-content-end mb-4">
        {$.list.length === 0 ? (
          <>
            <Button
              variant="outline-secondary"
              data-testid={testid('download-button')}
              className="ms-2"
              onClick={() => onDownload()}
              disabled={$.clientIds.length === 0}
            >
              ダウンロード
            </Button>
          </>
        ) : (
          <>
            {!$.updResult.result && (
              <Button
                variant="link"
                className="ms-2"
                data-testid={testid('cancel-button')}
                onClick={() => {
                  mergeState({
                    list: [],
                    checkResult: false,
                    resultMessageList: [],
                    updResult: { result: false },
                  });
                }}
              >
                キャンセル
              </Button>
            )}
            <Button data-testid={testid('save-button')} className="ms-2" onClick={onUpload} disabled={!$.checkResult}>
              保存
            </Button>
          </>
        )}
      </div>

      {$.list.length === 0 ? (
        <>
          <Form.Group className="mb-4" data-testid={testid('client')}>
            <Form.Label>クライアント</Form.Label>
            <TagsInput
              tags={tags}
              setTags={setTags}
              options={$.clientOpts.map(({ id, name }) => `${id}：${name}`)}
              pill
            />
          </Form.Group>

          {when(
            !salesFlg,
            <>
              <Dropzone onDrop={onDrop} type="excel" />
            </>
          )}
        </>
      ) : (
        <div style={{ width: 'calc(100vw - 20rem)', overflowX: 'scroll' }} className="mb-4">
          <Table bordered className="mt-4 text-nowrap" data-testid={testid('table')}>
            <thead>
              <tr>
                {[...Array($.list[0].organizationList.length + 2)].map((_, i) => (
                  <React.Fragment key={i}>
                    <th className="border-end-0" colSpan={i >= 1 && i <= $.list[0].organizationList.length ? 3 : 2}>{`${
                      i + 1
                    }階層目`}</th>
                  </React.Fragment>
                ))}
                <th>エラー内容</th>
              </tr>
            </thead>
            <tbody>
              {$.list.map(({ clientId, clientName, organizationList, shopId, shopName, errorMessageList }) => (
                <tr key={shopId} className="align-middle">
                  <td>{clientId}</td>
                  <td>{clientName}</td>
                  {organizationList.map(({ organizationId, organizationName, organizationLevelName }) => (
                    <React.Fragment key={organizationId}>
                      <td>{organizationId}</td>
                      <td>{organizationName}</td>
                      <td>{organizationLevelName}</td>
                    </React.Fragment>
                  ))}
                  <td>{shopId}</td>
                  <td>{shopName}</td>
                  <td>
                    {errorMessageList?.map((e, i) => (
                      <div key={i} className="text-danger">
                        {e}
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};
