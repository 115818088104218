import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { createTestId } from '../../utils/functions';

interface IProps {
  // １ページの表示件数
  resultNum: number;
  // 読み込み時の表示するデータ
  initialData: any;
  setSliceData: Dispatch<SetStateAction<any | undefined>>;
  pageNationClassName?: string;
}
export const PaginationComponent: React.FC<IProps> = ({
  resultNum,
  initialData,
  setSliceData,
  pageNationClassName,
}) => {
  const testId = createTestId(PaginationComponent);

  const [currentPage, setCurrentPage] = useState<number>(0);
  // ページ数
  const displayNum = Math.ceil(Number(initialData?.length) / resultNum);
  const sliceData = (start: number, end: number) => {
    return initialData?.slice(start, start + end);
  };
  const clickPagination = (id: number) => {
    const start = id * resultNum;
    setSliceData(sliceData(start, resultNum));
    setCurrentPage(id);
  };
  const clickBack = () => {
    if (currentPage === 0) return;
    const start = (currentPage - 1) * resultNum;
    setSliceData(sliceData(start, resultNum));
    setCurrentPage(currentPage - 1);
  };

  const clickNext = () => {
    if (currentPage === displayNum - 1) return;
    const start = (currentPage + 1) * resultNum;
    setSliceData(sliceData(start, resultNum));
    setCurrentPage(currentPage + 1);
  };

  const pages = [];
  for (let i = 0; i < displayNum; i += 1) {
    pages.push(
      <Pagination.Item
        data-testid={testId('number', i)}
        key={i}
        onClick={() => clickPagination(i)}
        active={i === currentPage}
      >
        {i + 1}
      </Pagination.Item>
    );
  }

  return (
    <Pagination style={{ overflowX: 'auto' }} className={pageNationClassName}>
      <Pagination.Prev data-testid={testId('prev')} onClick={clickBack} />
      {pages}
      <Pagination.Next data-testid={testId('next')} onClick={clickNext} />
    </Pagination>
  );
};
