import React, { useEffect } from 'react';
import { Form, Row, Col, Table, FloatingLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import type { DispatchSetState } from '../../../interfaces/utils';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, uuid } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import type { ContractShop } from '../../pages/PostingRequest/PostingRequestPage';

export interface Props {
  isModal: boolean;
  setIsModal: DispatchSetState<boolean>;
  contractShops: ContractShop[];
  setContractShops: DispatchSetState<ContractShop[]>;
}

export interface State {
  list: (ContractShop & { checked: boolean })[];
  name?: string;
  storeSpotAmount?: number;
  storeStockAmount?: number;
  plusCount?: number;
}

const initialState = {
  list: [],
};

export const ShopBulkChangeModal: React.VFC<Props> = ({ isModal, setIsModal, contractShops, setContractShops }) => {
  const testid = createTestId(ShopBulkChangeModal);

  const { state: $, mergeState, onChangeSet } = useLargeState<State>(initialState);

  useEffect(() => {
    mergeState({ list: contractShops.map((v) => ({ ...v, uuid: uuid(), checked: false })) });
  }, [mergeState, contractShops, isModal]);

  useEffect(() => {}, [$]);

  const onSave = () => {
    setContractShops($.list.map(({ checked, ...rest }) => rest));
    setIsModal(false);
  };

  return (
    <Modal
      onHide={() => setIsModal(false)}
      title="契約店舗一括変更"
      isModal={isModal}
      size="lg"
      closeButton
      centered
      body={
        <>
          <div className="d-flex justify-content-end mb-4 gap-2">
            <Button
              type="button"
              data-testid={testid('reflection-button')}
              onClick={() => {
                mergeState({
                  list: $.list.map((v) =>
                    v.checked
                      ? {
                          ...v,
                          name: $.name || v.name,
                          storeSpotAmount: $.storeSpotAmount || v.storeSpotAmount,
                          storeStockAmount: $.storeStockAmount || v.storeStockAmount,
                        }
                      : v
                  ),
                });
              }}
            >
              チェックした店舗に反映
            </Button>

            <Button type="button" onClick={onSave} data-testid={testid('save-button')}>
              保存
            </Button>
          </div>

          <Row className="mb-4">
            <Col className="col-6">
              <FloatingLabel label="契約店舗名" data-testid={testid('name')}>
                <Form.Control
                  type="text"
                  placeholder="契約店舗名"
                  value={$.name || ''}
                  onChange={onChangeSet('name')}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel label="店舗SPOT" data-testid={testid('storeSpotAmount')}>
                <Form.Control
                  type="number"
                  placeholder="店舗SPOT"
                  value={$.storeSpotAmount || ''}
                  onChange={onChangeSet('storeSpotAmount', Number)}
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel label="店舗STOCK" data-testid={testid('storeStockAmount')}>
                <Form.Control
                  type="number"
                  placeholder="店舗STOCK"
                  value={$.storeStockAmount || ''}
                  onChange={onChangeSet('storeStockAmount', Number)}
                />
              </FloatingLabel>
            </Col>
          </Row>

          <div className="d-flex justify-content-between align-items-center mb-2">
            <div>
              <Button
                type="button"
                size="sm"
                data-testid={testid('select-button')}
                onClick={() => mergeState({ list: $.list.map((v) => ({ ...v, checked: true })) })}
              >
                全選択
              </Button>

              <Button
                type="button"
                variant="outline-secondary"
                size="sm"
                className="ms-2"
                data-testid={testid('unselect-button')}
                onClick={() => mergeState({ list: $.list.map((v) => ({ ...v, checked: false })) })}
              >
                全解除
              </Button>
            </div>

            <div className="d-flex">
              <div className="d-flex align-items-center gap-1" data-testid={testid('plusCount')}>
                <Form.Control
                  type="number"
                  size="sm"
                  style={{ width: '60px' }}
                  value={$.plusCount || ''}
                  onChange={onChangeSet('plusCount', Number)}
                />
                件
              </div>

              <Button
                type="button"
                size="sm"
                className="ms-2"
                data-testid={testid('add-button')}
                onClick={() => {
                  const arr = [...Array($.plusCount)].map(() => ({
                    uuid: uuid(),
                    monitorBases: [],
                    name: $.name || '',
                    storeSpotAmount: $.storeSpotAmount || 0,
                    storeStockAmount: $.storeStockAmount || 0,
                    checked: false,
                  }));

                  mergeState({
                    list: [...$.list, ...arr],
                  });
                }}
              >
                追加
              </Button>
            </div>
          </div>

          <Table className="mb-4" data-testid={testid('table')}>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>契約店舗名</th>
                <th>店舗SPOT</th>
                <th>店舗STOCK</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {$.list.map((v, i) => (
                <tr key={v.uuid} className="align-middle">
                  <td className="border-0">
                    <Form.Check.Input
                      type="checkbox"
                      name="checkBoxGroup01"
                      checked={v.checked}
                      onChange={() =>
                        mergeState({
                          list: $.list.map((item, idx) => (i === idx ? { ...item, checked: !item.checked } : item)),
                        })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="text"
                      value={v.name}
                      onChange={(e) =>
                        mergeState({
                          list: $.list.map((item, idx) => (i === idx ? { ...item, name: e.target.value } : item)),
                        })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={v.storeSpotAmount}
                      onChange={(e) =>
                        mergeState({
                          list: $.list.map((item, idx) =>
                            i === idx ? { ...item, storeSpotAmount: Number(e.target.value) } : item
                          ),
                        })
                      }
                    />
                  </td>
                  <td>
                    <Form.Control
                      type="number"
                      value={v.storeStockAmount}
                      onChange={(e) =>
                        mergeState({
                          list: $.list.map((item, idx) =>
                            i === idx ? { ...item, storeStockAmount: Number(e.target.value) } : item
                          ),
                        })
                      }
                    />
                  </td>
                  <td>
                    <Button
                      variant="light"
                      className="text-secondary bg-transparent ms-2"
                      data-testid={testid('delete-button')}
                      onClick={() => mergeState({ list: $.list.filter((_, idx) => i !== idx) })}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      }
    />
  );
};
