import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { BillingDestinationMailDtoResponse } from '../../../api-client';

export interface Props {
  mail: BillingDestinationMailDtoResponse;
  index: number;
  onChangeAddress: (i: number, v: string) => void;
}

export const BillingDestinationMailTable: React.VFC<Props> = ({ mail, index, onChangeAddress }) => {
  return (
    <>
      <Row>
        <Col className="col-2">
          <Form.Label>アドレス</Form.Label>
        </Col>
        <Col className="col-4">
          <Form.Control
            type="email"
            value={mail.mailAddress}
            onChange={(e) => onChangeAddress(index, e.target.value)}
          />
        </Col>
        <Col className="col-2">
          <Form.Label>ステータス</Form.Label>
        </Col>
        <Col className="col-4">
          <Form.Control readOnly defaultValue={mail.status} />
        </Col>
      </Row>
    </>
  );
};
