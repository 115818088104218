import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../store/correctionStore';
import { TemplateFormMode } from '../../../pages/Correction/constants';
import { NoteBody } from './bodies/NoteBody';
import { CommonBody } from './bodies/CommonBody';

// TODO: [添削] テスト未実装
export const TemplateForm: React.FC<{
  mode: TemplateFormMode | undefined;
}> = ({ mode }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const [name, list] = useMemo(() => {
    if (mode === TemplateFormMode.NOTE) return [];
    if (mode === TemplateFormMode.NG) return ['NG', $.res_ngTemplateList];
    if (mode === TemplateFormMode.ESCALE) return ['エスカレ', $.res_escalationTemplateList];
    if (mode === TemplateFormMode.REJECT) return ['却下', $.res_rejectTemplateList];
    throw new Error('フォームの存在条件に反しています');
  }, [mode, $.res_ngTemplateList, $.res_escalationTemplateList, $.res_rejectTemplateList]);

  return (
    <>
      <Form>
        {$.templateFormMode !== TemplateFormMode.NOTE ? (
          <CommonBody name={name ?? ''} templateList={list ?? []} />
        ) : (
          <NoteBody />
        )}
      </Form>
    </>
  );
};
