import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { BillingApi, PaidApplyFormResponse, IncResultOutputResponse } from '../../../api-client';
import { BillingUpdateResultModal } from '../../organisms/Modal/BillingUpdateResultModal';
import { BILLING_DEFAULT_FADE_TIME } from '../../../BillingConstants';

const SUBSTR_LENGTH = 7;

export const BillingPaidApplyPage: React.VFC = () => {
  const [file, setFile] = useState();
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();

  const billingApi = new BillingApi();

  const handleChangeFile = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleClickPaidApply = (e: any) => {
    e.preventDefault();

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (reader.result) {
          const fileContent = String(reader.result);
          const data: PaidApplyFormResponse = {
            applyIds: fileContent.substring(fileContent.indexOf('base64,') + SUBSTR_LENGTH),
          };

          billingApi
            .paidapply(data)
            .then((response: AxiosResponse<IncResultOutputResponse>) => {
              setUpdResult(response.data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      };
      reader.onerror = (error) => {
        console.log('FileReader Error: ', error);
      };
    }
  };

  return (
    <>
      <Container>
        {updResult !== undefined && (
          <BillingUpdateResultModal
            fadeoutTime={BILLING_DEFAULT_FADE_TIME}
            updResult={updResult}
            setUpdResult={setUpdResult}
          />
        )}
        <h3>請求データ消し込み(指定した応募IDを請求済みにする)</h3>
        <div className="d-flex flex-column">
          <Row>
            <Col className="mb-1">
              <input type="file" id="csvFile" accept=".csv" onChange={handleChangeFile} />
            </Col>
          </Row>
          <Row>
            <Col className="mb-1">
              <Button variant="secondary" className="me-4" onClick={handleClickPaidApply}>
                消し込み
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
