import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Form, Tab, Table, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  AllClientResponse,
  ClientListApi,
  ClientListOutputResponse,
  IdNameOutputResponse,
  QuestionCategoryForEachHierarchListOutputResponse,
  QuestionCategoryForEachHierarchyListApi,
  QuestionCopyApi,
  QuestionCopyFormResponse,
  QuestionCopyOutputResponse,
  QuestionDeleteApi,
  QuestionDeleteOutputResponse,
  QuestionListApi,
  QuestionListOutputResponse,
  RoleListApi,
} from '../../../api-client';
import { when } from '../../../utils/functions';
import { Title } from '../../atoms/Title';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { QuestionConfirmModal } from '../../organisms/Modal/Question/QuestionConfirmModal';
import { QuestionListTable } from '../../organisms/Table/QuestionListTable';
import { ENQUETE_TYPE } from '../Enquete/EnqueteListPage';

export interface DataListType {
  id: number;
  category: string;
  clientName: string;
  questionContent: string;
  questionAnswerMethod: string;
  showFlg: boolean;
  startTime: string;
  endTime: string;
}

export const QuestionListPage: React.VFC = () => {
  const dummyData = [
    {
      id: 1,
      category: 'グルメ',
      clientName: 'テストクライアント１',
      questionContent: 'あなたの年齢を教えてください。',
      questionAnswerMethod: 'プルダウン単一選択/必須',
      showFlg: false,
      startTime: '2022-03-09 00:00',
      endTime: '',
    },
    {
      id: 2,
      category: '接客',
      clientName: 'テストクライアント２',
      questionContent: '入店時の店員の対応はいかがでしたか？',
      questionAnswerMethod: 'ラジオボタン単一選択 / 0〜100点 / 必須',
      showFlg: true,
      startTime: '',
      endTime: '2022-03-09 00:00',
    },
    {
      id: 3,
      category: '独自',
      clientName: 'テストクライアント３',
      questionContent: 'チンチロリンハイボールの写真を投稿してください。',
      questionAnswerMethod: '画像アップロード',
      showFlg: false,
      startTime: '2022-03-09 23:59',
      endTime: '',
    },
  ];

  // // ダミーデータ
  // const [data2, setData2] = useState<DataListType[]>(dummyData);

  // レスポンスデータ
  const [data, setData] = useState<QuestionListOutputResponse[]>([]);

  const history = useHistory();

  // 検索パラメータ保持用
  const [searchParams, setSearchParms] = useState<{
    questionId?: number;
    question?: string;
    category?: number;
    client?: number;
  }>({});

  //  ページ関連変数
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [listPerPage, setListPerPage] = useState<QuestionListOutputResponse[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const MAX_RECORD_PER_PAGE = 20;

  // 削除モーダル開閉フラグ
  const [deleteRunModal, setDeleteRunModal] = useState<boolean>(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState<boolean>(false);

  // 設問編集モーダル開閉フラグ
  const [confirmModalFlg, setConfirmModalFlg] = useState<boolean>(false);
  // 質問・回答入力方法モーダル開閉フラグ
  const [answerMethodModalFlg, setAnswerMethodModalFlg] = useState<boolean>(false);

  // TOPページ用押下タブKey
  // 1: 「事前」タブ
  // 2: 「事後 選択式」タブ
  // 3: 「事後 積上式」タブ
  // 4: 「事後 その他」タブ
  // 5: 「事後 会員属性」タブ
  // 6: 「事後 アンケートモニター」タブ
  const [enqueteType, setEnqueteType] = useState<number>(1);
  // 選択式内タブKey
  const [selectType, setSelectType] = useState<'global' | 'company' | undefined>();

  // 設問編集モーダルパラメータ
  const [questionId, setQuestionId] = useState<number>();
  // 使用クライアントセレクトボックスデータ
  const [clientList, setClientList] = useState<AllClientResponse[]>();

  // レスポンスデータ複製（会員属性PTで使用）
  const [copyData, setCopyData] = useState<QuestionListOutputResponse[]>([]);
  //  カテゴリリスト
  const [categories, setCategories] = useState<Partial<QuestionCategoryForEachHierarchListOutputResponse>>({});

  const [globalSearch, setGlobalSearch] = useState<boolean>(false);
  const [companySearch, setCompanySearch] = useState<boolean>(false);
  const [questionModalSubmit, setQuestionModalSubmit] = useState<boolean>(false);

  const questionListApi = new QuestionListApi();
  const questionCategoryForEachHierarchyListApi = new QuestionCategoryForEachHierarchyListApi();
  const clientListApi = new ClientListApi();
  const copyApi = new QuestionCopyApi();

  const [adminFlg, setAdminFlg] = useState<boolean>(false);
  const roleListApi = new RoleListApi();

  const noneQuestionCategoryData: IdNameOutputResponse = {
    id: -1,
    name: 'カテゴリ無し',
  };

  // 画面初期表示時に一回だけロール一覧を取得し、グローバル設問を追加・更新・削除できる権限を持っているか確認する。
  useEffect(() => {
    roleListApi.roleList().then((res: AxiosResponse<IdNameOutputResponse[]>) => {
      setAdminFlg(res.data.some((role) => role.name === '設問管理者'));
    });
  }, []);

  useEffect(() => {
    if (enqueteType === ENQUETE_TYPE.SELECT || enqueteType === ENQUETE_TYPE.PILEUP) {
      if (!clientList) {
        clientListApi.clientList().then((res: AxiosResponse<ClientListOutputResponse>) => {
          setClientList(res.data.allClientList);
        });
      }
    }
    let level: number[] | undefined;
    if (enqueteType === ENQUETE_TYPE.SELECT) {
      if (companySearch || selectType === 'company') {
        setSelectType('company');
        level = [5];
      } else if (globalSearch || selectType === 'global') {
        setSelectType('global');
        level = [1, 2, 3, 4];
      }
    }
    questionListApi
      .questionList(undefined, undefined, undefined, undefined, enqueteType, level)
      .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
        setData(res.data.sort((a, b) => b.questionId - a.questionId));
        setCopyData(res.data.sort((a, b) => b.questionId - a.questionId));
        setCurrentPage(1);
      });
    if (questionModalSubmit) setQuestionModalSubmit(false);
  }, [enqueteType, questionModalSubmit]);

  useEffect(() => {
    if (enqueteType !== ENQUETE_TYPE.SELECT || categories?.companyMediumQuestionCategories !== undefined) return;
    questionCategoryForEachHierarchyListApi
      .questionCategoryForEachHierarchyList()
      .then((res: AxiosResponse<QuestionCategoryForEachHierarchListOutputResponse>) => {
        if (res.data?.companyMediumQuestionCategories !== undefined)
          res.data.companyMediumQuestionCategories.push(noneQuestionCategoryData);
        setCategories(res.data);
      });
  }, [enqueteType]);

  useEffect(() => {
    setListPerPage(data?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(data.length) / MAX_RECORD_PER_PAGE));
  }, [data, currentPage]);

  const customStyles = useMemo(
    () => ({
      control: (provided: any) => ({
        ...provided,
        width: 380,
        height: 58,
      }),
    }),
    []
  );

  // 検索ボタンイベント
  const handleOnSubmit = async (e?: any, key?: typeof selectType) => {
    if (e) e.preventDefault();

    let type = key;
    if (enqueteType === ENQUETE_TYPE.SELECT && !key) type = selectType;
    const selectedCategoryList: number[] | undefined = [];
    if (searchParams.category) selectedCategoryList?.push(searchParams.category);
    let level: number[] | undefined;
    if (enqueteType === ENQUETE_TYPE.SELECT) {
      if (type === 'company') {
        level = [5];
      } else if (type === 'global') {
        level = [1, 2, 3, 4];
      }
    }

    questionListApi
      .questionList(
        searchParams.questionId,
        searchParams.question,
        selectedCategoryList,
        searchParams.client,
        enqueteType,
        level
      )
      .then((res: AxiosResponse<QuestionListOutputResponse[]>) => {
        setData(res.data.sort((a, b) => b.questionId - a.questionId));
        setCopyData(res.data.sort((a, b) => b.questionId - a.questionId));
        setCurrentPage(1);
      });
  };

  // コピーボタンイベント
  const onCopy = (targetRow: QuestionListOutputResponse) => {
    const copyParam: QuestionCopyFormResponse = {
      editRoute: 1,
      questionId: targetRow.questionId,
    };

    copyApi.questionCopy(copyParam).then((res: AxiosResponse<QuestionCopyOutputResponse>) => {
      if (res.data.questionId !== undefined) {
        setData(
          [...data, { ...targetRow, questionId: res.data.questionId }].sort((a, b) =>
            a.questionId > b.questionId ? -1 : 1
          )
        );
      }
    });
  };

  // 各種Text系変更イベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'questionId':
        setSearchParms({
          ...searchParams,
          questionId: Number.isNaN(parseInt(event.target.value, 10)) ? undefined : parseInt(event.target.value, 10),
        });
        break;
      case 'question':
        setSearchParms({ ...searchParams, question: event.target.value || undefined });
        break;
      default:
        break;
    }
  };

  // カテゴリセレクトボックスchangeイベント
  const onChangeSelectCategory = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchParms({ ...searchParams, category: Number(event.target.value) });
  };

  // モーダル（YES）イベント
  const handleClickModalYes = (): void => {
    const api = new QuestionDeleteApi();
    api
      .questionDelete({ confirmOnlyFlg: false, questionId: questionId! })
      .then((res: AxiosResponse<QuestionDeleteOutputResponse>) => {
        if (res.data.usingEnqueteFlg) {
          setDeleteRunModal(false);
          setDeleteInfoModal(true);
        } else {
          setData((prevState) => prevState.filter((it) => it.questionId !== questionId));
          setQuestionId(undefined);
          setDeleteRunModal(false);
        }
      });
  };

  // モーダル（No）イベント
  const onChangeSelectType = (key: typeof selectType) => {
    setSelectType(key);
    setGlobalSearch(key === 'global');
    setCompanySearch(key === 'company');
    handleOnSubmit(undefined, key);
  };

  const companySearchForm = (
    <Card className="mb-4 mt-4">
      <Table className="table-borderless" style={{ width: '100%', height: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '25%' }}>
              <div className="form-floating mt-3" style={{ marginLeft: 9 }}>
                <input
                  data-testid="search_questionId"
                  type="number"
                  className="form-control"
                  id="questionId"
                  placeholder="入力してください。"
                  value={searchParams.questionId || ''}
                  onChange={onChangeText}
                />
                <label>設問ID</label>
              </div>
            </td>
            <td style={{ width: '25%' }}>
              <div className="mt-3 form-floating">
                <Form.Select
                  data-testid="search_category"
                  defaultValue=""
                  className="form-select"
                  onChange={onChangeSelectCategory}
                >
                  <option value="">選択してください</option>
                  {categories &&
                    categories?.companyMediumQuestionCategories?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                </Form.Select>
                <label htmlFor="floatingSelect">カテゴリ</label>
              </div>
            </td>
            <td>
              <div className="form-floating mt-3">
                <input
                  data-testid="search_question"
                  type="text"
                  className="form-control"
                  id="question"
                  placeholder="入力してください。"
                  value={searchParams.question || ''}
                  onChange={onChangeText}
                />
                <label htmlFor="contractShopName">設問</label>
              </div>
            </td>

            <td style={{ width: '25%' }}>
              <div className="form-floating mt-3 me-2">
                <Select
                  id="client"
                  styles={customStyles}
                  onChange={(current) => setSearchParms({ ...searchParams, client: current?.clientId })}
                  closeMenuOnSelect
                  getOptionLabel={(option: any) => option.clientName}
                  getOptionValue={(option: any) => option.clientId?.toString()}
                  options={clientList}
                  placeholder="使用クライアント"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
      <div style={{ width: '49%', height: '80%', margin: 'auto', textAlign: 'center' }}>
        <Button
          data-testid="search"
          type="submit"
          className="mt-3 mb-4"
          style={{ width: '100%', height: '100%' }}
          onClick={() => setCompanySearch(true)}
        >
          検索
        </Button>
      </div>
    </Card>
  );

  const globalSearchForm = (
    <Card className="mb-4 mt-4">
      <Table className="table-borderless" style={{ width: '100%', height: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '25%' }}>
              <div className="form-floating mt-3" style={{ marginLeft: 9 }}>
                <input
                  data-testid="search_questionId"
                  type="number"
                  className="form-control"
                  id="questionId"
                  placeholder="入力してください。"
                  value={searchParams.questionId || ''}
                  onChange={onChangeText}
                />
                <label>設問ID</label>
              </div>
            </td>
            <td style={{ width: '25%' }}>
              <div className="mt-3 form-floating">
                <Form.Select
                  data-testid="search_category"
                  defaultValue=""
                  className="form-select"
                  onChange={onChangeSelectCategory}
                >
                  <option value="">選択してください</option>
                  {categories &&
                    categories?.companyMediumQuestionCategories?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                </Form.Select>
                <label htmlFor="floatingSelect">カテゴリ</label>
              </div>
            </td>
            <td>
              <div className="form-floating mt-3">
                <input
                  data-testid="search_question"
                  type="text"
                  className="form-control"
                  id="question"
                  placeholder="入力してください。"
                  value={searchParams.question || ''}
                  onChange={onChangeText}
                />
                <label htmlFor="contractShopName">設問</label>
              </div>
            </td>
            <td style={{ textAlign: 'center' }}>
              <p style={{ width: '95%', height: '80%', marginRight: 7 }}>
                <Button
                  data-testid="search"
                  type="submit"
                  className="mt-3"
                  style={{ width: '95%', height: '100%' }}
                  onClick={() => setGlobalSearch(true)}
                >
                  検索
                </Button>
              </p>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );

  const searchForm = (
    <Card className="mb-4 p-1">
      <Table className="table-borderless" style={{ width: '100%', height: '100%' }}>
        <tbody>
          <tr>
            <td style={{ width: '20%' }}>
              <div className="form-floating mt-3" style={{ marginLeft: 9 }}>
                <input
                  data-testid="search_questionId"
                  type="number"
                  className="form-control"
                  id="questionId"
                  placeholder="入力してください。"
                  value={searchParams.questionId || ''}
                  onChange={onChangeText}
                />
                <label>設問ID</label>
              </div>
            </td>
            <td>
              <div className="form-floating mt-3">
                <input
                  data-testid="search_question"
                  type="text"
                  className="form-control"
                  id="question"
                  placeholder="入力してください。"
                  value={searchParams.question || ''}
                  onChange={onChangeText}
                />
                <label htmlFor="contractShopName">設問</label>
              </div>
            </td>
            {enqueteType === ENQUETE_TYPE.PILEUP && (
              <td style={{ width: '20%' }}>
                <div className="form-floating mt-3">
                  <Select
                    id="client"
                    styles={customStyles}
                    onChange={(current) => setSearchParms({ ...searchParams, client: current?.clientId })}
                    closeMenuOnSelect
                    getOptionLabel={(option: any) => option.clientName}
                    getOptionValue={(option: any) => option.clientId?.toString()}
                    options={clientList}
                    placeholder="使用クライアント"
                  />
                </div>
              </td>
            )}
            <td style={{ textAlign: 'center' }}>
              <p style={{ width: '98%', height: '85%' }}>
                <Button data-testid="search" type="submit" className="mt-3" style={{ width: '98%', height: '100%' }}>
                  検索
                </Button>
              </p>
            </td>
          </tr>
        </tbody>
      </Table>
    </Card>
  );

  return (
    <>
      <Modal
        title="削除してよろしいですか？"
        footer={
          <>
            <Button variant="secondary" onClick={() => setDeleteRunModal(false)}>
              いいえ
            </Button>
            <Button variant="primary" onClick={handleClickModalYes}>
              はい
            </Button>
          </>
        }
        isModal={deleteRunModal}
        onHide={() => setDeleteRunModal(false)}
      />

      <Modal
        title="アンケートに使用されているので削除できません。"
        footer={
          <>
            <Button variant="secondary" onClick={() => setDeleteInfoModal(false)}>
              閉じる
            </Button>
          </>
        }
        isModal={deleteInfoModal}
        onHide={() => setDeleteInfoModal(false)}
      />

      <QuestionConfirmModal
        key={questionId}
        isModal={confirmModalFlg}
        setIsModal={setConfirmModalFlg}
        setAnswerMethodModalFlg={setAnswerMethodModalFlg}
        questionId={questionId}
        setQuestionId={setQuestionId}
        enqueteType={enqueteType}
        selectType={selectType}
        setModalSubmit={setQuestionModalSubmit}
        adminFlg={adminFlg}
      />

      {/* <QuestionAnswerMethodModal 
        isModal={answerMethodModalFlg} 
        setIsModal={setAnswerMethodModalFlg} 
        setConfirmModalFlg={setConfirmModalFlg}
        questionId={questionId}
        setQuestionId={setQuestionId}
        pageType={pageType}
        subTabkey={subTabkey}
        topTabKey={topTabkey}
      /> */}

      <Title className="mb-4">設問一覧</Title>
      <Form onSubmit={handleOnSubmit}>
        <Tabs
          activeKey={enqueteType}
          id="uncontrolled-tab-example"
          onSelect={(key) => {
            setEnqueteType(Number(key));
            setSelectType(Number(key) === ENQUETE_TYPE.SELECT ? 'global' : undefined);
          }}
          className="mb-4"
        >
          <Tab eventKey={ENQUETE_TYPE.BEFOREHAND} title="事前" />
          <Tab eventKey={ENQUETE_TYPE.SELECT} title="事後 選択式">
            <Tabs
              activeKey={selectType}
              id="uncontrolled-tab-example"
              onSelect={(key) => onChangeSelectType(key as typeof selectType)}
              className="mb-2"
            >
              <Tab eventKey="global" title="事後 選択式グローバル設問" />
              <Tab eventKey="company" title="事後 選択式企業設問" />
            </Tabs>
          </Tab>
          <Tab eventKey={ENQUETE_TYPE.PILEUP} title="事後 積上式" />
          <Tab eventKey={ENQUETE_TYPE.OTHER} title="事後 その他" />
          <Tab eventKey={ENQUETE_TYPE.MEMBERSHIP} title="会員属性" />
          <Tab eventKey={ENQUETE_TYPE.ENQUETE_MONITOR} title="アンケートモニター" />
        </Tabs>

        {when(enqueteType === ENQUETE_TYPE.SELECT && (!selectType || selectType === 'global'), globalSearchForm)}
        {when(enqueteType === ENQUETE_TYPE.SELECT && selectType === 'company', companySearchForm)}
        {when(enqueteType !== ENQUETE_TYPE.SELECT, searchForm)}

        <PaginationWithEllipsis
          data-testid="page"
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(data.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />

        <div className="mb-0" style={{ textAlign: 'right' }}>
          <Button
            data-testid="add"
            variant="link"
            className="text-secondary p-0"
            onClick={() => {
              setConfirmModalFlg(true);
            }}
            disabled={selectType === 'global' && !adminFlg}
          >
            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
            <span style={{ fontSize: '1rem' }}>追加</span>
          </Button>
        </div>

        <QuestionListTable
          data={data}
          setData={setData}
          setDeleteRunModal={setDeleteRunModal}
          setDeleteInfoModal={setDeleteInfoModal}
          setConfirmModalFlg={setConfirmModalFlg}
          setQuestionId={setQuestionId}
          onCopy={onCopy}
          listPerPage={listPerPage}
          enqueteType={enqueteType}
          selectType={selectType}
          adminFlg={adminFlg}
        />
      </Form>
    </>
  );
};
