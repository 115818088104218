import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, Button, Form, Table } from 'react-bootstrap';
import { ApiClientApi, ApiClientInfoOutputResponse, IncResultOutputResponse } from '../../../api-client';
import { Title } from '../../atoms/Title';

export const ApiClientConfirmPage = () => {
  const intialData: ApiClientInfoOutputResponse = {
    id: 0,
    name: '',
    serviceName: '',
    companyName: '',
    accessKey: '',
    highLoadModeFlg: false,
  };

  const [data, setData] = useState<ApiClientInfoOutputResponse>(intialData);
  const [updateResult, setUpdateResult] = useState<IncResultOutputResponse>();
  const { id } = useParams<{ id: string | undefined }>();
  const [checkFlg, setCheckFlg] = useState<boolean>(false);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    const getApi = new ApiClientApi();
    getApi.apiClientInfo(Number(id)).then((res: AxiosResponse<ApiClientInfoOutputResponse>) => {
      setData(res.data);
    });
  }, []);

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'name':
        setData({ ...data, name: event.target.value });
        break;
      case 'serviceName':
        setData({ ...data, serviceName: event.target.value });
        break;
      case 'companyName':
        setData({ ...data, companyName: event.target.value });
        break;
      case 'key':
        setData({ ...data, accessKey: event.target.value });
        break;
      case 'secretKey':
        setData({ ...data, secretKey: event.target.value });
        break;
      case 'pointName':
        setData({ ...data, feeUnitName: event.target.value });
        break;
      case 'unitConversion':
        if (event.target.validity.valid) {
          setData({ ...data, feeRate: Number(event.target.value) });
        } else {
          setValidated(true);
        }
        break;
      case 'controllerURL':
        setData({ ...data, controllerUrl: event.target.value });
        break;
      case 'eventMessage_URL':
        setData({ ...data, eventMessageNotifyUrl: event.target.value });
        break;
      case 'eventMessage_Basic_ID':
        setData({ ...data, eventMessageCredentialId: event.target.value });
        break;
      case 'eventMessage_Basic_Pass':
        setData({ ...data, eventMessageCredentialPassword: event.target.value });
        break;
      case 'head':
        setData({ ...data, headerHtml: event.target.value });
        break;
      case 'foot':
        setData({ ...data, footerHtml: event.target.value });
        break;
      case 'rewardRate':
        if (event.target.validity.valid) setData({ ...data, feeRateAdjustPercent: Number(event.target.value) });
        break;
      case 'rewardRate_Minimum':
        if (event.target.validity.valid) setData({ ...data, feeRateMinPercent: Number(event.target.value) });
        break;
      case 'highBurden_switch':
        setData({ ...data, highLoadModeFlg: event.target.checked });
        break;
      case 'disableFlg':
        setData({ ...data, disableFlg: event.target.checked });
        break;
      default:
        break;
    }
  };

  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value === '0' || event.target.value === '1') {
      setData({ ...data, feeRateAdjustType: Number(event.target.value) });
    } else {
      setData({ ...data, feeRateAdjustType: undefined });
    }
  };

  const handleOnSubmit = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setCheckFlg(false);
    } else {
      const { ...rest } = data;
      const updateParam: any = rest;
      const getApi = new ApiClientApi();
      getApi.apiClientUpdate(updateParam).then((res: AxiosResponse<IncResultOutputResponse>) => {
        setUpdateResult(res.data);
      });
      e.preventDefault();
      setCheckFlg(true);
    }

    setValidated(true);
  };

  return (
    <>
      <Form
        validated={validated}
        onSubmit={handleOnSubmit}
        onInvalid={() => {
          setCheckFlg(false);
        }}
      >
        <Title className="mb-4">APIClient編集</Title>
        <Table style={{ borderSpacing: 10, borderCollapse: 'separate' }}>
          <tbody>
            <tr>
              <th>ID</th>
              <td>{data?.id}</td>
            </tr>
            <tr>
              <th>名前</th>
              <td>
                <Form.Group className="mb-4" controlId="name">
                  <Form.Control type="text" defaultValue={data.name} onChange={onChangeText} />
                </Form.Group>
              </td>
            </tr>
            <tr className="align-middle">
              <th>サービス名</th>
              <td>
                <Form.Group className="mb-4" controlId="serviceName">
                  <Form.Control type="text" defaultValue={data.serviceName} onChange={onChangeText} />
                </Form.Group>
              </td>
            </tr>
            <tr className="align-middle">
              <th>会社名</th>
              <td>
                <Form.Group className="mb-4" controlId="companyName">
                  <Form.Control type="text" defaultValue={data.companyName} onChange={onChangeText} />
                </Form.Group>
              </td>
            </tr>
            <tr className="align-middle">
              <th>key</th>
              <td>
                <Form.Group className="mb-4" controlId="key">
                  <Form.Control type="text" defaultValue={data.accessKey} onChange={onChangeText} />
                </Form.Group>
              </td>
            </tr>
            <tr className="align-middle">
              <th>暗号化キーワード</th>
              <td>
                <Form.Group className="mb-4" controlId="secretKey">
                  <Form.Control type="text" defaultValue={data.secretKey} onChange={onChangeText} />
                </Form.Group>
              </td>
            </tr>
            <tr className="align-middle">
              <th>ポイント名称（単位）</th>
              <td>
                <Form.Group className="mb-4" controlId="pointName">
                  <Form.Control type="text" defaultValue={data.feeUnitName} onChange={onChangeText} />
                </Form.Group>
              </td>
            </tr>
            <tr className="align-middle">
              <th>単位換算率</th>
              <td>
                <Form.Group className="mb-4" controlId="unitConversion">
                  <Form.Control
                    data-testid="unitConversionText"
                    type="text"
                    pattern="^[+-]?[0-9]+\.[0-9]+([eE][+-]?[0-9]+)?$"
                    defaultValue={data.feeRate}
                    onChange={onChangeText}
                  />
                  <Form.Control.Feedback type="invalid">半角数字・少数形式で入力してください。</Form.Control.Feedback>
                </Form.Group>
                （円 ✕ 換算率＝ポイント）
                <br />
                ※半角数字・少数形式のみ有効 「入力例： 1.0 , 2.15」
              </td>
            </tr>
            <tr className="align-middle">
              <th style={{ border: 'none' }}>ファンくるAPI向け設定項目</th>
            </tr>
            <tr className="align-middle">
              <th>OEM側コントローラーURL</th>
              <td>
                <Form.Group className="mb-4" controlId="controllerURL">
                  <Form.Control type="text" defaultValue={data.controllerUrl} />
                </Form.Group>
                ※ URLの最後が ? か & であること
              </td>
            </tr>
            <tr className="align-middle">
              <th>イベントメッセージ通知先URL</th>
              <td>
                <Form.Group className="mb-4" controlId="eventMessage_URL">
                  <Form.Control type="text" defaultValue={data.eventMessageNotifyUrl} onChange={onChangeText} />
                </Form.Group>
              </td>
            </tr>
            <tr className="align-middle">
              <th>イベントメッセージBASIC認証 ID</th>
              <td>
                <Form.Group className="mb-4" controlId="eventMessage_Basic_ID">
                  <Form.Control type="text" defaultValue={data.eventMessageCredentialId} onChange={onChangeText} />
                </Form.Group>
                イベントメッセージ通知で先方のサーバにアクセスする際に必要な場合は設定します
              </td>
            </tr>
            <tr className="align-middle">
              <th>イベントメッセージBASIC認証 Pass</th>
              <td>
                <Form.Group className="mb-4" controlId="eventMessage_Basic_Pass">
                  <Form.Control
                    type="text"
                    defaultValue={data.eventMessageCredentialPassword}
                    onChange={onChangeText}
                  />
                </Form.Group>
                イベントメッセージ通知で先方のサーバにアクセスする際に必要な場合は設定します
              </td>
            </tr>
            <tr className="align-middle">
              <th>ヘッダー</th>
              <td>
                <Form.Group className="mb-4" controlId="head">
                  <Form.Control as="textarea" rows={15} defaultValue={data.headerHtml} onChange={onChangeText} />
                </Form.Group>
              </td>
            </tr>
            <tr className="align-middle">
              <th>フッター</th>
              <td>
                <Form.Group className="mb-4" controlId="foot">
                  <Form.Control as="textarea" rows={15} defaultValue={data.footerHtml} onChange={onChangeText} />
                </Form.Group>
              </td>
            </tr>
            <tr className="align-middle">
              <th>謝礼調整率</th>
              <td>
                <Form.Group className="mb-4" controlId="rewardRate">
                  <Form.Control
                    data-testid="rewardRateText"
                    type="text"
                    pattern="[+-]?([1-9]\d*|0)$"
                    defaultValue={data.feeRateAdjustPercent}
                    onChange={onChangeText}
                  />
                  <Form.Control.Feedback type="invalid">半角整数で入力してください。</Form.Control.Feedback>
                </Form.Group>
                ※半角整数のみ有効 入力例「10 , +10 , -1 」
              </td>
            </tr>
            <tr className="align-middle">
              <th>最低謝礼調整率</th>
              <td>
                <Form.Group className="mb-4" controlId="rewardRate_Minimum">
                  <Form.Control
                    data-testid="rewardRate_MinimumText"
                    type="text"
                    pattern="[+-]?([1-9]\d*|0)$"
                    defaultValue={data.feeRateMinPercent}
                    onChange={onChangeText}
                  />
                  <Form.Control.Feedback type="invalid">半角整数で入力してください。</Form.Control.Feedback>
                </Form.Group>
                ※半角整数のみ有効 入力例「10 , +10 , -1 」
              </td>
            </tr>
            <tr className="align-middle">
              <th>謝礼調整率の計算方式</th>
              <td>
                <Form.Group className="mb-4" controlId="calculation_method">
                  <Form.Select
                    data-testid="calculation_methodCheckbox"
                    value={data.feeRateAdjustType}
                    style={{ width: 'calc(100% - 65%)' }}
                    onChange={onChangeSelect}
                  >
                    <option value="">選択してください</option>
                    <option value="0">減算方式</option>
                    <option value="1">一律方式</option>
                  </Form.Select>
                </Form.Group>
                減算方式 : ファンくるサイト上の謝礼（％） ー 謝礼調整率（％） = OEMサイト上の謝礼（％）
                <br />
                一律方式 : ファンくるサイト上の謝礼（％） ✕ (100 - 謝礼調整率（％）) / 100 = OEMサイト上の謝礼（％）
              </td>
            </tr>
            <tr className="align-middle">
              <th>高負荷スイッチ</th>
              <td>
                <Form.Check id="highBurden_switch" data-testid="highLoadModeCheckbox">
                  <Form.Check.Input
                    data-testid="highLoadModeCheckbox"
                    className="me-2"
                    type="checkbox"
                    checked={data.highLoadModeFlg}
                    onChange={onChangeText}
                  />
                </Form.Check>
                ※ 高負荷が予想される場合は，一時的にここをtrueにします。
                <br />
                検索APIでキャッシュが効くようになります(その分，OneToOneの最適化は犠牲になります)。
              </td>
            </tr>
            <tr className="align-middle">
              <th>有効無効フラグ</th>
              <td>
                <Form.Check id="disableFlg">
                  <Form.Check.Input
                    data-testid="disableFlgCheckbox"
                    className="me-2"
                    type="checkbox"
                    checked={data.disableFlg}
                    onChange={onChangeText}
                  />
                </Form.Check>
              </td>
            </tr>
          </tbody>
        </Table>
        <Button type="submit" className="mb-4">
          確認
        </Button>
      </Form>
      {checkFlg && updateResult?.result && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          更新しました。
        </Alert>
      )}
      {checkFlg && updateResult !== undefined && updateResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${updateResult.errorMessage} (エラーコード：${updateResult.errorCode})`}</Alert>
      )}
    </>
  );
};
