import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import type {
  ClientDetailInfoOutputResponse,
  FloatingRewardListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
// eslint-disable-next-line import/no-cycle
import {
  ClientDetailInfoApi,
  FloatingRewardListApi,
  FloatingRewardListDLApi,
  FloatingRewardUpdateApi,
  FloatingRewardUpdateFormResponse,
  FloatingRewardUpdateListRowDataResponse,
} from '../../../api-client';
import { toBlob } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { Alert } from '../../atoms/Alert';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { FormControl } from '../../molecules/Form/FormControl';
import { FloatingRewardSummaryTable } from '../../organisms/Table/FloatingRewardSummaryTable';
// eslint-disable-next-line import/no-cycle
import { FloatingRewardDetailTable } from '../../organisms/Table/FloatingRewardDetailTable';

// const clientDetailInfoSample = {
//   id: 1,
//   clientName: '会社A',
//   clientAddress: '東京都新宿区・・・',
//   clientPhoneNumber: '000-0000-0000',
//   roiMemberName: '佐藤',
//   clientRepresentative: '田中',
//   requiredNumber: 3,
//   note: 'bbbb',
//   contractList: [
//     {
//       contractId: 1,
//       contractType: 2,
//       contractStatus: 3,
//     },
//   ],
// };

// const sampleDraftGetRes = {
//   draftAt: '2022/02/17 09:27',
//   draftId: 111,
//   details: [
//     {
//       shopId: 1,
//       shopName: '新宿店',
//       prefectureName: '東京都',
//       areaName: '新宿・代々木',
//       monitorId: 111,
//       monitorName: 'ディナー',
//       status: '公開中',
//       floatingRewardPreference: 'あり',
//       number: 10,
//       applyNumber: 4,
//       winningNumber: 5,
//       remainingNumber: 6,
//       completeNumber: 3,
//       requiredDeliveryNumber: 3,
//       requiredMissingNumber: 2,
//       pvNumber: 10,
//       digestionRate: 50,
//       lossRate: 30,
//       cancelRate: 20,
//       receiptTotalAmount: 10000,
//       grossProfit: 2000,
//       fee: 300,
//       feeMax: 1000,
//       stock: 3000,
//       userRewardType: 2,
//       userRewardRate: 10,
//       userRewardAmount: 2000,
//       floatingRewardId: 4,
//       floatingRewardType: 1,
//       floatingRewardRate: 12,
//       floatingRewardAmount: 2500,
//       floatingRewardNumber: 2,
//     },
//     {
//       shopId: 2,
//       shopName: '渋谷店',
//       prefectureName: '東京都',
//       areaName: '新宿・代々木',
//       monitorId: 112,
//       monitorName: 'ディナー',
//       status: '公開中',
//       floatingRewardPreference: 'あり',
//       number: 50,
//       applyNumber: 4,
//       winningNumber: 5,
//       remainingNumber: 6,
//       completeNumber: 5,
//       requiredDeliveryNumber: 5,
//       requiredMissingNumber: 4,
//       pvNumber: 10,
//       digestionRate: 50,
//       lossRate: 30,
//       cancelRate: 20,
//       receiptTotalAmount: 10000,
//       grossProfit: 2000,
//       fee: 300,
//       feeMax: 1000,
//       stock: 3000,
//       userRewardType: 2,
//       userRewardRate: 10,
//       userRewardAmount: 2000,
//       floatingRewardId: 4,
//       floatingRewardType: 1,
//       floatingRewardRate: 12,
//       floatingRewardAmount: 2500,
//       floatingRewardNumber: 7,
//     },
//   ],
// };
// const sampleGetRes = [
//   {
//     shopId: 1,
//     shopName: '新宿店',
//     prefectureName: '東京都',
//     areaName: '新宿・代々木',
//     monitorId: 111,
//     monitorName: 'ディナー',
//     status: '公開中',
//     floatingRewardPreference: 'あり',
//     number: 10,
//     applyNumber: 4,
//     winningNumber: 5,
//     remainingNumber: 6,
//     completeNumber: 3,
//     requiredDeliveryNumber: 3,
//     requiredMissingNumber: 2,
//     // pvNumber: 10,
//     digestionRate: 50,
//     lossRate: 30,
//     cancelRate: 20,
//     receiptTotalAmount: 10000,
//     grossProfit: 2000,
//     fee: 300,
//     feeMax: 1000,
//     stock: 3000,
//     userRewardType: 2,
//     userRewardRate: 10,
//     userRewardAmount: 2000,
//     floatingRewardId: 4,
//     floatingRewardType: 1,
//     floatingRewardRate: 12,
//     floatingRewardAmount: 2500,
//     floatingRewardNumber: 2,
//   },
//   {
//     shopId: 2,
//     shopName: '渋谷店',
//     prefectureName: '東京都',
//     areaName: '新宿・代々木',
//     monitorId: 112,
//     monitorName: 'ディナー',
//     status: '公開中',
//     floatingRewardPreference: 'あり',
//     number: 50,
//     applyNumber: 4,
//     winningNumber: 5,
//     remainingNumber: 6,
//     completeNumber: 5,
//     requiredDeliveryNumber: 5,
//     requiredMissingNumber: 4,
//     // pvNumber: 10,
//     digestionRate: 50,
//     lossRate: 30,
//     cancelRate: 20,
//     receiptTotalAmount: 10000,
//     grossProfit: 2000,
//     fee: 300,
//     feeMax: 1000,
//     stock: 3000,
//     userRewardType: 2,
//     userRewardRate: 10,
//     userRewardAmount: 2000,
//     floatingRewardId: 4,
//     floatingRewardType: 1,
//     floatingRewardRate: 12,
//     floatingRewardAmount: 2500,
//     floatingRewardNumber: 7,
//   },
// ];

type NewFloatingRewardType = {
  newFloatingRewardNumber?: number;
  newFloatingRewardType?: number;
};

export type ListItem = FloatingRewardListOutputResponse & NewFloatingRewardType;

export const FloatingRewardPage: React.VFC = () => {
  const history = useHistory();
  const { id: clientId } = useParams<{ id: string }>();
  const [downloadUrl, setDownloadUrl] = useState<string>('');

  const { register, handleSubmit, control, watch, getValues, setValue, reset } = useForm({
    defaultValues: {
      note: '',
      list: [] as ListItem[],
    },
  });
  const { fields } = useFieldArray({ control, name: 'list' });

  const [clientDetailInfo, setClientDetailInfo] = useState<any>(null);

  const [watchTargetRequiredDeliveryNumber, setWatchTargetRequiredDeliveryNumber] = useState<
    `list.${number}.requiredDeliveryNumber`[]
  >([]);
  const watchRequiredDeliveryNumber = watch(watchTargetRequiredDeliveryNumber) as number[];

  const [watchTargetFloatingRewardNumber, setWatchTargetFloatingRewardNumber] = useState<
    `list.${number}.floatingRewardNumber`[]
  >([]);
  const watchFloatingRewardNumber = watch(watchTargetFloatingRewardNumber) as number[];

  const [watchTargetNewFloatingRewardNumber, setWatchTargetNewFloatingRewardNumber] = useState<
    `list.${number}.newFloatingRewardNumber`[]
  >([]);
  const watchNewFloatingRewardNumber = watch(watchTargetNewFloatingRewardNumber) as number[];

  const [watchTargetNewFloatingRewardType, setWatchTargetNewFloatingRewardType] = useState<
    `list.${number}.newFloatingRewardType`[]
  >([]);
  const watchNewFloatingRewardType = watch(watchTargetNewFloatingRewardType) as number[];

  let missingNumberCounter = 0;
  const [achievementRate, setAchievementRate] = useState<number>(0);

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>({
    result: false,
    errorCode: '',
    errorMessage: '',
  });

  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [feeAlertIndex, setFeeAlertIndex] = useState<number[]>([]);

  const floatingRewardListApi = new FloatingRewardListApi();
  const floatingRewardListDLApi = new FloatingRewardListDLApi();
  const floatingRewardUpdateApi = new FloatingRewardUpdateApi();
  const clientDetailInfoApi = new ClientDetailInfoApi();

  const initialSetup = (list: ListItem[]) => {
    reset({ note: getValues('note'), list });

    const requiredDeliveryNumber: `list.${number}.requiredDeliveryNumber`[] = [];
    const floatingRewardNumber: `list.${number}.floatingRewardNumber`[] = [];
    const newFloatingRewardNumber: `list.${number}.newFloatingRewardNumber`[] = [];
    const newFloatingRewardType: `list.${number}.newFloatingRewardType`[] = [];
    const closeFlg: `list.${number}.closeFlg`[] = [];

    list.forEach((_, i) => {
      requiredDeliveryNumber.push(`list.${i}.requiredDeliveryNumber`);
      floatingRewardNumber.push(`list.${i}.floatingRewardNumber`);
      newFloatingRewardNumber.push(`list.${i}.newFloatingRewardNumber`);
      newFloatingRewardType.push(`list.${i}.newFloatingRewardType`);
      closeFlg.push(`list.${i}.closeFlg`);
    });
    setWatchTargetRequiredDeliveryNumber(requiredDeliveryNumber);
    setWatchTargetFloatingRewardNumber(floatingRewardNumber);
    setWatchTargetNewFloatingRewardNumber(newFloatingRewardNumber);
    setWatchTargetNewFloatingRewardType(newFloatingRewardType);

    missingNumberCounter = list.filter(
      (item: ListItem) => item.requiredMissingNumber && item.requiredMissingNumber <= 0
    ).length;
    if (list.length > 0) {
      setAchievementRate(Math.round((missingNumberCounter / list.length) * 100));
    }
  };

  useEffect(() => {
    clientDetailInfoApi
      .clientDetailInfo(Number(clientId))
      .then((res: AxiosResponse<ClientDetailInfoOutputResponse>) => {
        // TODO: API修正待ち
        // setClientDetailInfo(clientDetailInfoSample);
        // setValue('note', clientDetailInfoSample.note);
        setClientDetailInfo(res.data);
        if (res.data.note) {
          setValue('note', res.data.note);
        }
      });

    floatingRewardListDLApi.floatingRewardListDL(Number(clientId)).then((res: AxiosResponse<string>) => {
      const blob = toBlob(res.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8');
      if (!blob) return;
      setDownloadUrl(window.URL.createObjectURL(blob));
    });

    floatingRewardListApi
      .floatingRewardList(Number(clientId))
      .then((res: AxiosResponse<FloatingRewardListOutputResponse[]>) => {
        // const data = sampleGetRes;
        const { data } = res;
        initialSetup(data);
      });
  }, [clientId, setValue]);

  const handleClickConfirm = () => {
    const baseUrl = Url.KEISAI.FLOATING_REWARD_CONFIRM;
    window.history.pushState(null, '', `${baseUrl}/${clientId}`);
    // 確認ボタン押下時にonSubmitも発火してしまう為、遅延させる
    setTimeout(() => setIsConfirm(true), 100);

    const resultIndex: number[] = [];
    getValues('list').forEach((item: any, idx) => {
      if (
        item.feeMax >= 0 &&
        item.floatingRewardAmount >= 0 &&
        item.newFloatingRewardAmount >= 0 &&
        item.feeMax + item.floatingRewardAmount - Number(item.newFloatingRewardAmount) < 0
      ) {
        resultIndex.push(idx);
      }
    });
    setFeeAlertIndex(resultIndex);
  };

  const handleClickBack = () => {
    setIsConfirm(false);
    setUpdResult({ result: false, errorCode: '', errorMessage: '' });
    history.goBack();
  };

  const onSubmit: SubmitHandler<{ note: string; list: ListItem[] }> = (data) => {
    const list: FloatingRewardUpdateListRowDataResponse[] = data.list.map((item: any) => {
      const newFloatingRewardType = item.newFloatingRewardType
        ? { newFloatingRewardType: Number(item.newFloatingRewardType) }
        : undefined;
      const newFloatingRewardRate = item.newFloatingRewardRate
        ? { newFloatingRewardRate: Number(item.newFloatingRewardRate) }
        : undefined;
      const newFloatingRewardAmount = item.newFloatingRewardAmount
        ? { newFloatingRewardAmount: Number(item.newFloatingRewardAmount) }
        : undefined;
      const newFloatingRewardNumber = item.newFloatingRewardNumber
        ? { newFloatingRewardNumber: Number(item.newFloatingRewardNumber) }
        : undefined;

      return {
        monitorId: item.monitorId,
        requiredDeliveryNumber: Number(item.requiredDeliveryNumber),
        floatingRewardId: item.floatingRewardId,
        floatingRewardNumber: Number(item.floatingRewardNumber),
        closeFlg: item.closeFlg,
        ...newFloatingRewardType,
        ...newFloatingRewardRate,
        ...newFloatingRewardAmount,
        ...newFloatingRewardNumber,
      };
    });

    const updData: FloatingRewardUpdateFormResponse = {
      clientId: Number(clientId),
      note: data.note,
      list,
    };

    floatingRewardUpdateApi.floatingRewardUpdate(updData).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(res.data);
    });
  };

  const getTitle = () => {
    const base = !isConfirm ? TITLE.KEISAI.FLOATING_REWARD : TITLE.KEISAI.FLOATING_REWARD_CONFIRM;
    const suffix = '';
    return `${base}${suffix}`;
  };

  return (
    <>
      <Title className="mb-4">{getTitle()}</Title>
      {updResult?.result && <Alert variant="success">更新しました。</Alert>}
      {updResult?.errorCode && updResult?.errorMessage && (
        <Alert variant="danger">{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}
      <FloatingRewardSummaryTable clientDetailInfo={clientDetailInfo} achievementRate={achievementRate} />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          type="text"
          as="textarea"
          rows={4}
          className="w-50 mb-4"
          disabled={isConfirm}
          register={register}
          registerName="note"
        />
        {feeAlertIndex.length > 0 && (
          <Alert variant="danger">手数料を超えてROIで金額が持ち出しになる設定があります。</Alert>
        )}
        <FloatingRewardDetailTable
          fields={fields}
          register={register}
          clientDetailInfo={clientDetailInfo}
          isConfirm={isConfirm}
          feeAlertIndex={feeAlertIndex}
          watchRequiredDeliveryNumber={watchRequiredDeliveryNumber}
          watchNewFloatingRewardType={watchNewFloatingRewardType}
        />
        {!isConfirm ? (
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <a
                href={downloadUrl}
                className="btn btn-primary ms-2"
                download={`【変動謝礼】${clientDetailInfo?.clientName}.xlsx`}
              >
                ダウンロード
              </a>
            </div>
            <Button onClick={handleClickConfirm}>確認画面へ</Button>
          </div>
        ) : (
          <div className="d-flex justify-content-between">
            <Button variant="link" className="me-5" onClick={handleClickBack}>
              戻る
            </Button>
            <Button type="submit">更新</Button>
          </div>
        )}
      </Form>
    </>
  );
};
