import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { TokenObj } from '../interfaces/TokenObj';
import { ACCESS_TOKEN, FANCREW_CUSTOMER_ID_COOKIE_TOKEN, REFRESH_TOKEN } from '../Constants';

export class TokenCookies {
  isCleaned = false;

  /**
   * 一度だけ`path=/`以外のトークンを強制削除する
   * @param force 再度実行する
   */
  cleanupTokens(force?: true): void {
    if (!force && this.isCleaned) return;
    this.isCleaned = true;
    document.cookie.split(';').forEach((c) => {
      window.location.pathname
        .split('/')
        .slice(1)
        .map((_, i, s) => `/${s.slice(0, i + 1).join('/')}`)
        .forEach((path) => {
          document.cookie = c.replace(/^ +/, '').replace(/=.*/, `=;expires=${new Date().toUTCString()};path=${path}`);
        });
    });
  }

  setTokens(tokenObj: TokenObj): void {
    this.cleanupTokens();
    setCookie(null, ACCESS_TOKEN, tokenObj[ACCESS_TOKEN], { path: '/' });
    setCookie(null, REFRESH_TOKEN, tokenObj[REFRESH_TOKEN], { path: '/' });
  }

  // eslint-disable-next-line class-methods-use-this
  getTokens(): [ACCESS_TOKEN: string, REFRESH_TOKEN: string] {
    return [parseCookies()[ACCESS_TOKEN], parseCookies()[REFRESH_TOKEN]];
  }

  // 会員Cookie履歴用のCookie
  // eslint-disable-next-line class-methods-use-this
  getCustomerCookie = (): string => {
    const cookie = parseCookies()[FANCREW_CUSTOMER_ID_COOKIE_TOKEN];
    if (cookie) {
      return cookie;
    }
    return '';
  };

  clearTokens(): void {
    this.cleanupTokens();
    destroyCookie(null, ACCESS_TOKEN);
    destroyCookie(null, REFRESH_TOKEN);
  }
}
