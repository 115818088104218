import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { formatISODate, uuid } from '../../../utils/functions';
import type { CorrectDataInputTargetListState } from '../../pages/Correction/CorrectDataInputTargetListPage';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { Url } from '../../../constants/Url';

type ListType = CorrectDataInputTargetListState['output']['monitorList'];

interface Props {
  escalationAnswerFlg: boolean;
  list: ListType;
}

export const CorrectDataInputTargetListTable: React.FC<Props> = ({ escalationAnswerFlg, list }) => {
  const history = useHistory();

  const submissionDataLabel = escalationAnswerFlg
    ? 'チェック待ち自動添削後のうち最古提出日'
    : 'データ入力待ちのうち最古提出日';
  const applicationSelectLinkName = escalationAnswerFlg ? 'Wチェック画面' : 'データ入力画面';
  const applicationSelectLinkValue = escalationAnswerFlg
    ? Url.TENSAKU.DATA_INPUT_W_CHECK_DISTRIBUTE
    : Url.TENSAKU.DATA_INPUT_DISTRIBUTE;

  const tableData: {
    label: string;
    name: keyof ListType[0];
  }[] = useMemo(() => {
    return [
      { label: 'モニターID', name: 'monitorId' },
      { label: 'モニター名', name: 'monitorName' },
      { label: '実際の枠数', name: 'winningBorderCount' },
      { label: '残枠', name: 'remainingBorderNumber' },
      { label: '入力待ち', name: 'waitingInputNumber' },
      { label: 'チェック待ち', name: 'waitingCheckNumber' },
      { label: submissionDataLabel, name: 'oldestSubmissionDate' },
      { label: 'カウントダウン設定日', name: 'countDownDate' },
    ];
  }, [submissionDataLabel]);

  const createDisplayElement = useCallback((item: ListType[0], labelName: keyof ListType[0]) => {
    if (labelName === 'oldestSubmissionDate' || labelName === 'countDownDate') {
      const date = item[labelName];
      if (date) {
        return formatISODate(date, DATETIME_DISPLAY_FORMAT);
      }
      return 'なし';
    }

    return item[labelName];
  }, []);

  return (
    <Table>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label), '作業開始'].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <tr className="align-middle" key={uuid()}>
            {tableData.map(({ name }) => (
              <td key={uuid()}>{createDisplayElement(item, name)}</td>
            ))}
            <td key={uuid()}>
              <Button variant="link" onClick={() => history.push(`${applicationSelectLinkValue}/${item.monitorId}`)}>
                {applicationSelectLinkName}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
