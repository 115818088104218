import { AxiosResponse } from 'axios';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  OverlayTrigger,
  Popover,
  Table,
  useAccordionButton,
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import type {
  ContractShopInfoOutputResponse,
  ContractShopUpdateFormResponse,
  IncResultOutputResponse,
} from '../../../api-client';
// eslint-disable-next-line import/no-cycle
import { ContractShopInfoApi, ContractShopUpdateApi } from '../../../api-client';

export const ContractShopConfirmPage: React.VFC = () => {
  const date = new Date();
  const diff = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() + 9);
    return parsedate;
  };
  const formatDate = new Intl.DateTimeFormat('ja-jp', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);

  const initialData: ContractShopInfoOutputResponse = {
    id: 0,
    name: '',
    shopId: 0,
    shopName: '',
    // targetShopList: [
    //   {
    //     id: 0,
    //     name: '',
    //   },
    // ],
  };

  // レスポンス
  const [data, setData] = useState<ContractShopInfoOutputResponse>(initialData);
  // 更新結果
  const [updResult, setUpdResult] = useState<IncResultOutputResponse | null>(null);

  const [shopErrorFlg, setshopErrorFlg] = useState<boolean>(false);

  // モーダル（絞り込み検索結果保持）
  // const [modalSearchData, setModalSearchData] = useState<ShopDataResponse[]>([]);

  // モーダルページ管理関連
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [listPerPage, setListPerPage] = useState<any[]>([]);
  const MAX_RECORD_PER_PAGE = 20;

  // モーダル内パラメータ
  const [searchShopParam, setsearchShopParam] = useState<string>();

  // モーダル内選択項目
  const [selectedShopId, setSelectedShopId] = useState<number>();
  const [selectedShopName, setSelectedShopName] = useState<string>();

  const history = useHistory();

  // 追加ボタンフラグ
  const [addFlg, setAddFlg] = useState<boolean>(false);
  // 保存ボタンフラグ
  const [keepFlg, setKeepFlg] = useState<boolean>(false);
  // 選択ボタン（実店舗）フラグ
  const [handleShowSelectShopFlg, setHandleShowSelectShopFlg] = useState<boolean>(false);

  const { id } = useParams<{ id: string | undefined }>();

  const contractShopInfoApi = new ContractShopInfoApi();
  const updateApi = new ContractShopUpdateApi();

  useEffect(() => {
    contractShopInfoApi.contractShopInfo(Number(id)).then((res: AxiosResponse<ContractShopInfoOutputResponse>) => {
      setData(res.data);
    });
  }, []);

  // 保存ボタンイベント
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    // TODO API

    if (!data.shopId) {
      setshopErrorFlg(true);
      return;
    }

    const updParam: ContractShopUpdateFormResponse = {
      id: data.id,
      shopId: data.shopId,
    };

    updateApi.contractShopUpdate(updParam).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(res.data);
    });

    setKeepFlg(true);
  };

  // 保存ボタン（モーダル内（店舗））イベント
  const handleKeepShop = () => {
    if (selectedShopId !== undefined && selectedShopName !== undefined) {
      setData({ ...data, shopId: selectedShopId, shopName: selectedShopName });
    }

    setKeepFlg(false);

    setHandleShowSelectShopFlg(false);
    setCurrentPage(1);
    setListPerPage([]);
    setTotalPage(0);
  };

  // セレクトボックスChangeイベント
  const onChangeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setKeepFlg(false);
  };

  // テキストChangeイベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'shopLinkingData':
        setData({ ...data, shopName: event.target.value });
        setKeepFlg(false);
        break;
      case 'searchShop':
        setsearchShopParam(event.target.value);
        break;

      default:
        break;
    }
  };

  // 選択ボタンイベント(実店舗)
  const handleShowSelectShop = () => {
    setCurrentPage(1);
    // setListPerPage(
    //   data.targetShopList?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE)
    // );
    // setTotalPage(Math.ceil(Number(data.targetShopList?.length) / MAX_RECORD_PER_PAGE));
    setHandleShowSelectShopFlg(true);
  };

  // モーダル（選択）クローズイベント
  const handleCloseModal = () => {
    setHandleShowSelectShopFlg(false);
    setCurrentPage(1);
    setListPerPage([]);
    setTotalPage(0);
  };

  // 検索ボタン(実店舗)イベント
  const handleSerachShop = () => {
    if (searchShopParam !== undefined) {
      // const serachResult: ShopDataResponse[] = data.targetShopList.filter((value) =>
      //   new RegExp(searchShopParam, 'ig').test(value.name)
      // );

      // if (serachResult === undefined) return;

      // setSelectedShopId(serachResult[0].id);
      // setSelectedShopName(serachResult[0].name);
      setCurrentPage(1);
      // setListPerPage(serachResult.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
      // setTotalPage(Math.ceil(Number(serachResult?.length) / MAX_RECORD_PER_PAGE));
    }
  };

  const shopLinkingHelpText = (
    <Popover id="button-tooltip" style={{ maxWidth: '7.5rem' }}>
      契約店舗に紐付く 実店舗を選択する。
    </Popover>
  );

  // カスタムアコーディオン
  const CustomAccordion = ({ eventKey }: { eventKey: string }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      setAddFlg(!addFlg);
    });

    return (
      <>
        <div className="d-flex">
          <div className="me-auto" style={{ verticalAlign: 'center' }}>
            <strong style={{ fontSize: '1rem' }}>ステータス変更予約</strong>
          </div>
          <div>
            <Button
              data-testid="add"
              variant="link"
              className="text-secondary mb-1 pt-0 pe-0"
              onClick={decoratedOnClick}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              追加
            </Button>
          </div>
        </div>
      </>
    );
  };

  const statusHeight = !addFlg ? 'calc(100% - 50%)' : '';

  return (
    <>
      <Form onSubmit={handleOnSubmit}>
        <h3 style={{ marginBottom: '20px' }}>契約店舗編集</h3>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {keepFlg && updResult?.result && (
            <Alert variant="success" style={{ marginBottom: '24px' }}>
              保存が完了しました。
            </Alert>
          )}
          {keepFlg && updResult !== undefined && updResult?.result === false && (
            <Alert
              variant="danger"
              style={{ marginTop: 10 }}
            >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
          )}
          {shopErrorFlg && (
            <Alert variant="danger" style={{ marginBottom: '24px' }}>
              実店舗が設定されていません。
            </Alert>
          )}
          <div style={{ display: 'flex', order: 2 }}>
            <Card style={{ marginTop: '24px', marginRight: 40, width: 'calc(100% - 50%)' }}>
              <Card.Header>
                <h4 style={{ margin: 'auto' }}>契約店舗情報</h4>
              </Card.Header>
              <Card.Body>
                <Form.Group>
                  <Form.Label style={{ fontWeight: 'bolder' }}>契約店舗ID</Form.Label>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>{data?.id}</Form.Label>
                </Form.Group>
                <Form.Group className="mb-4" controlId="contractShopName">
                  <Form.Label style={{ fontWeight: 'bolder' }}>契約店舗名</Form.Label>
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>{data?.name}</Form.Label>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="me-1" style={{ fontWeight: 'bolder' }}>
                    実店舗紐づけ
                  </Form.Label>
                  <OverlayTrigger trigger="click" placement="right" overlay={shopLinkingHelpText} rootClose>
                    <Button
                      data-testid="question"
                      variant="link"
                      className="text-secondary"
                      data-html
                      style={{ padding: 0 }}
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} fixedWidth style={{ verticalAlign: 0 }} />
                    </Button>
                  </OverlayTrigger>
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Group controlId="shopLinkingData">
                    <InputGroup>
                      <Form.Control type="text" onChange={onChangeText} defaultValue={data?.shopName} readOnly />
                      <Button onClick={handleShowSelectShop}>選択</Button>
                    </InputGroup>
                  </Form.Group>
                  {/* 選択モーダル */}
                  <Modal size="lg" show={handleShowSelectShopFlg} onHide={handleCloseModal} scrollable centered>
                    <Modal.Header closeButton />
                    <Modal.Body>
                      <div style={{ textAlign: 'right', marginBottom: 30 }}>
                        <Button
                          variant="outline-primary"
                          onClick={handleCloseModal}
                          style={{ marginRight: 10, marginTop: 10 }}
                        >
                          キャンセル
                        </Button>
                        <Button onClick={handleKeepShop} style={{ marginTop: 10 }} data-testid="modalKeepButton">
                          保存
                        </Button>
                      </div>
                      <InputGroup className="bg-light p-2 mb-4">
                        <Form.Control
                          type="text"
                          placeholder="店舗を検索"
                          id="searchShop"
                          onChange={onChangeText}
                          data-testid="searchShop"
                        />
                        {/* TODO 検索処理実装 */}
                        <Button variant="primary" onClick={handleSerachShop} data-testid="searchButton">
                          <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
                        </Button>
                      </InputGroup>
                      <PaginationWithEllipsis
                        currentPage={currentPage}
                        totalPage={totalPage}
                        handleClick={(page) => {
                          if (!page || page > totalPage) return;
                          // setListPerPage(
                          //   Object.assign([], data?.targetShopList)?.slice(
                          //     (page - 1) * MAX_RECORD_PER_PAGE,
                          //     page * MAX_RECORD_PER_PAGE
                          //   )
                          // );
                          setCurrentPage(page);
                        }}
                      />
                      <ListGroup className="mt-4" defaultActiveKey="0">
                        {listPerPage.map((row, index) => {
                          return (
                            <ListGroup.Item
                              data-testid="shopItem"
                              key={index}
                              action
                              href={index.toString()}
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedShopId(row.id);
                                setSelectedShopName(row.name);
                              }}
                            >
                              {row.name}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </Modal.Body>
                  </Modal>
                </Form.Group>
              </Card.Body>
            </Card>
          </div>
          <div className="position-sticky top-0" style={{ order: 1 }}>
            <Card>
              <Table style={{ margin: 'auto' }}>
                <tbody>
                  <tr className="align-middle">
                    <td className="pe-0" style={{ textAlign: 'right' }}>
                      <div style={{ marginRight: '1.3rem', paddingRight: 0 }}>
                        <Button id="keep" type="submit" style={{ textAlign: 'center' }}>
                          保存
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </div>
        </div>
      </Form>
    </>
  );
};
