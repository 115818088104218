import { useSafeContext } from '../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../store/correctionStore';
import { CorrectionModalBase } from './CorrectionModalBase';
import { OKForm } from './ResultModal/forms/OKForm';

// TODO: [添削] テスト未実装
export const ReceiptInfoModal: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  return (
    <CorrectionModalBase isModal={!!$.isReceiptInfoModal} title="レシート情報モーダル">
      <OKForm isViewOnly _isEnquete={false} />
    </CorrectionModalBase>
  );
};
