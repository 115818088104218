import React from 'react';
import { Accordion, Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faTimesCircle, faFileUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

export const RegisterTableCardAccordionComplex = () => {
  return (
    <>
      <Row>
        <Col>
          <Card className="mb-4">
            <Card.Body>
              <h4>カード型：Table アコーディオン版 複雑パターン</h4>
              <Form>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="ms-2">
                    キャンセル
                  </Button>
                  <Button variant="outline-secondary" className="ms-2">
                    プレビュー
                  </Button>
                  <Button variant="primary" className="ms-2">
                    保存
                  </Button>
                </div>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="text-secondary">
                    <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                    追加
                  </Button>
                </div>
                <div className="border rounded mb-3 p-3">
                  <Row className="align-items-center gx-3">
                    <Col className="flex-grow-0 flex-shrink-0">
                      <button type="button" className="text-secondary">
                        <FontAwesomeIcon icon={faGripLines} fixedWidth />
                      </button>
                    </Col>
                    <Col className="flex-grow-1">
                      <Accordion defaultActiveKey="0" flush className="flex-grow-1">
                        <Accordion.Item eventKey="0">
                          <Row className="align-items-center gx-3">
                            <Col className="flex-shrink-0 flex-grow-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faEye} fixedWidth />
                              </button>
                            </Col>
                            <Col>
                              <Form.Group controlId="formId2_1">
                                <Form.Control type="text" placeholder="プレースホルダー" />
                              </Form.Group>
                            </Col>
                            <Col className="flex-shrink-0 flex-grow-0">
                              <Accordion.Header className="border-0"> </Accordion.Header>
                            </Col>
                          </Row>
                          <Accordion.Body className="mt-2 p-0">
                            <div className="d-flex justify-content-end bg-light mt-2">
                              <Button variant="link" className="text-secondary p-3">
                                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                                追加
                              </Button>
                            </div>
                            <Table className="w-auto bg-light mb-0">
                              <thead>
                                <tr>
                                  <th> </th>
                                  <th> </th>
                                  <th>選択肢</th>
                                  <th className="d-flex align-items-center">
                                    <Form.Check type="switch" id="scoreSwitch" defaultChecked />
                                    得点
                                  </th>
                                  <th> </th>
                                  <th> </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="align-middle">
                                    <button type="button" className="text-secondary">
                                      <FontAwesomeIcon icon={faGripLines} fixedWidth />
                                    </button>
                                  </td>
                                  <td className="align-middle">
                                    <button type="button" className="text-secondary">
                                      <FontAwesomeIcon icon={faEye} fixedWidth />
                                    </button>
                                  </td>
                                  <td className="align-middle col-10">
                                    <Form.Group controlId="tableFormId1">
                                      <Form.Control type="text" placeholder="プレースホルダー" />
                                    </Form.Group>
                                  </td>
                                  <td className="align-middle col-2">
                                    <Form.Group controlId="tableFormId1">
                                      <Form.Control type="text" />
                                    </Form.Group>
                                  </td>
                                  <td className="align-middle">
                                    <button
                                      type="button"
                                      className="d-flex align-items-center"
                                      style={{ width: '10rem' }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faFileUpload}
                                        className="flex-shrink-0 text-secondary me-1"
                                        fixedWidth
                                      />
                                      <span className="flex-grow-1 text-truncate text-start text-secondary">
                                        画像アップロード
                                      </span>
                                    </button>
                                  </td>
                                  <td className="align-middle">
                                    <button type="button" className="ms-2 text-secondary">
                                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle">
                                    <button type="button" className="text-secondary">
                                      <FontAwesomeIcon icon={faGripLines} fixedWidth />
                                    </button>
                                  </td>
                                  <td className="align-middle">
                                    <button type="button" className="text-secondary">
                                      <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
                                    </button>
                                  </td>
                                  <td className="align-middle col-10">
                                    <Form.Group controlId="tableFormId1">
                                      <Form.Control type="text" placeholder="プレースホルダー" />
                                    </Form.Group>
                                  </td>
                                  <td className="align-middle col-2">
                                    <Form.Group controlId="tableFormId1">
                                      <Form.Control type="text" disabled />
                                    </Form.Group>
                                  </td>
                                  <td className="align-middle">
                                    <button
                                      type="button"
                                      className="d-flex align-items-center"
                                      style={{ width: '10rem' }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faFileUpload}
                                        className="flex-shrink-0 text-secondary me-1"
                                        fixedWidth
                                      />
                                      <span className="flex-grow-1 text-truncate text-start text-secondary">
                                        sampleImageSource.jpg
                                      </span>
                                    </button>
                                  </td>
                                  <td className="align-middle">
                                    <button type="button" className="ms-2 text-secondary">
                                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                    <Col className="flex-grow-0 flex-shrink-0">
                      <button type="button" className="text-secondary">
                        <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className="border rounded mb-3 p-3">
                  <Row className="align-items-center gx-3">
                    <Col className="flex-grow-0 flex-shrink-0">
                      <button type="button" className="text-secondary">
                        <FontAwesomeIcon icon={faGripLines} fixedWidth />
                      </button>
                    </Col>
                    <Col className="flex-grow-1">
                      <Accordion defaultActiveKey="0" flush className="flex-grow-1">
                        <Accordion.Item eventKey="0">
                          <Row className="align-items-center gx-3">
                            <Col className="flex-shrink-0 flex-grow-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
                              </button>
                            </Col>
                            <Col>
                              <Form.Group controlId="formId2_1">
                                <Form.Control type="text" placeholder="プレースホルダー" />
                              </Form.Group>
                            </Col>
                            <Col className="flex-shrink-0 flex-grow-0">
                              <Accordion.Header className="border-0"> </Accordion.Header>
                            </Col>
                          </Row>
                          <Accordion.Body className="mt-2 p-0">
                            <div className="d-flex justify-content-end bg-light mt-2">
                              <Button variant="link" className="text-secondary p-3">
                                <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                                追加
                              </Button>
                            </div>
                            <Table className="w-auto bg-light mb-0">
                              <thead>
                                <tr>
                                  <th> </th>
                                  <th> </th>
                                  <th>選択肢</th>
                                  <th className="d-flex align-items-center">
                                    <Form.Check type="switch" id="scoreSwitch" defaultChecked />
                                    得点
                                  </th>
                                  <th> </th>
                                  <th> </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td className="align-middle">
                                    <button type="button" className="text-secondary">
                                      <FontAwesomeIcon icon={faGripLines} fixedWidth />
                                    </button>
                                  </td>
                                  <td className="align-middle">
                                    <button type="button" className="text-secondary">
                                      <FontAwesomeIcon icon={faEye} fixedWidth />
                                    </button>
                                  </td>
                                  <td className="align-middle col-10">
                                    <Form.Group controlId="tableFormId1">
                                      <Form.Control type="text" placeholder="プレースホルダー" />
                                    </Form.Group>
                                  </td>
                                  <td className="align-middle col-2">
                                    <Form.Group controlId="tableFormId1">
                                      <Form.Control type="text" />
                                    </Form.Group>
                                  </td>
                                  <td className="align-middle">
                                    <button
                                      type="button"
                                      className="d-flex align-items-center"
                                      style={{ width: '10rem' }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faFileUpload}
                                        className="flex-shrink-0 text-secondary me-1"
                                        fixedWidth
                                      />
                                      <span className="flex-grow-1 text-truncate text-start text-secondary">
                                        画像アップロード
                                      </span>
                                    </button>
                                  </td>
                                  <td className="align-middle">
                                    <button type="button" className="ms-2 text-secondary">
                                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                                    </button>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle">
                                    <button type="button" className="text-secondary">
                                      <FontAwesomeIcon icon={faGripLines} fixedWidth />
                                    </button>
                                  </td>
                                  <td className="align-middle">
                                    <button type="button" className="text-secondary">
                                      <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
                                    </button>
                                  </td>
                                  <td className="align-middle col-10">
                                    <Form.Group controlId="tableFormId1">
                                      <Form.Control type="text" placeholder="プレースホルダー" />
                                    </Form.Group>
                                  </td>
                                  <td className="align-middle col-2">
                                    <Form.Group controlId="tableFormId1">
                                      <Form.Control type="text" disabled />
                                    </Form.Group>
                                  </td>
                                  <td className="align-middle">
                                    <button
                                      type="button"
                                      className="d-flex align-items-center"
                                      style={{ width: '10rem' }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faFileUpload}
                                        className="flex-shrink-0 text-secondary me-1"
                                        fixedWidth
                                      />
                                      <span className="flex-grow-1 text-truncate text-start text-secondary">
                                        sampleImageSource.jpg
                                      </span>
                                    </button>
                                  </td>
                                  <td className="align-middle">
                                    <button type="button" className="ms-2 text-secondary">
                                      <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                                    </button>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                    <Col className="flex-grow-0 flex-shrink-0">
                      <button type="button" className="text-secondary">
                        <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                      </button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
