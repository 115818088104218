import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { faWindowMaximize } from '@fortawesome/free-regular-svg-icons';
import { execDownload, createTestId } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { Badge } from '../../atoms/Badge';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { MonitorApi } from '../../../api-client';

const sampleGetData = {
  list: [...Array(31)].map((_, i) => {
    return {
      id: i + 100,
      startYm: '2021年11月',
      status: (i % 4) + 1,
    };
  }),
};

export interface Props {
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  monitorId: number;
  monitorName: string;
}

export interface State {
  api: MonitorApi;
  list: any[];
  listPerPage: any[];
  currentPage: number;
  totalPage: number;
}

const MAX_RECORD_PER_PAGE = 5;

const STATUS = [
  { name: '掲載準備中', bg: 'success' },
  { name: '公開中', bg: 'primary' },
  { name: '限定公開中', bg: 'warning' },
  { name: '非公開中', bg: 'secondary' },
] as const;

export const MonitorHistoryModal: React.VFC<Props> = ({ isModal, setIsModal, monitorId, monitorName }) => {
  const testid = createTestId(MonitorHistoryModal);
  const { state: $, mergeState } = useLargeState<State>({
    api: new MonitorApi(),
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  });

  const history = useHistory();

  useEffect(() => {
    if (!isModal) return;
    $.api.monitorHistoryList(monitorId).then((res: AxiosResponse<any>) => {
      // const { list } = res.data;
      const { list } = sampleGetData;

      const sortList = list.sort((a: any, b: any) => b.id - a.id);
      mergeState({
        list: sortList,
        listPerPage: sortList.slice(0, MAX_RECORD_PER_PAGE),
        totalPage: Math.ceil(Number(sortList?.length) / MAX_RECORD_PER_PAGE),
      });
    });
  }, [isModal, mergeState, $.api, monitorId]);

  const onDownload = (id: number) => {
    $.api.enqueteFileDownloadList(id).then((res: AxiosResponse<string>) => {
      execDownload(
        res.data,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        `【アンケート結果】${id}.xlsx`
      ).catch(() => {
        history.push(Url.COMMON_ERROR);
      });
    });
  };

  return (
    <Modal
      onHide={() => setIsModal(false)}
      isModal={isModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <h4 className="mb-4" data-testid={testid('title')}>
            モニター履歴
          </h4>

          {monitorName && (
            <div className="bg-light p-2 mb-4">
              <span>{monitorName}</span>
            </div>
          )}

          <div className="mb-4" data-testid={testid('pagination')}>
            <PaginationWithEllipsis
              currentPage={$.currentPage}
              totalPage={$.totalPage}
              handleClick={(page) => {
                if (!page || page > $.totalPage) return;
                mergeState({
                  listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
                  currentPage: page,
                });
              }}
            />
          </div>

          <div className="d-flex justify-content-end mb-4">
            <a
              href={Url.KEISAI.FORCE_WINNING}
              target="_blank"
              rel="noreferrer"
              className="text-secondary btn btn-link"
              data-testid={testid('forceLottery-button')}
            >
              <FontAwesomeIcon icon={faWindowMaximize} fixedWidth className="me-1" />
              強制抽選
            </a>
          </div>

          <Table data-testid={testid('table')}>
            <thead>
              <tr>
                <th>履歴ID</th>
                <th>ステータス</th>
                <th>開始月</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {$.listPerPage.map(({ id, startYm, status }, i) => {
                return (
                  <React.Fragment key={id}>
                    <tr>
                      <td>{id}</td>
                      <td>
                        <Badge pill style={{ fontSize: '0.875rem', width: '100%' }} bg={STATUS[status - 1].bg}>
                          {STATUS[status - 1].name}
                        </Badge>
                      </td>
                      <td>
                        <Button
                          variant="link"
                          className="p-0"
                          onClick={() => {
                            history.push({
                              pathname: Url.KEISAI.MONITOR_MODIFY,
                              // state: { id: data.id, type: 'detail' },
                            });
                          }}
                        >
                          {startYm}
                        </Button>
                      </td>
                      <td className="col-6 text-end">
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Popover id="popover-basic" className="mw-100">
                              <Popover.Body className="p-1">
                                アンケート結果をダウンロード
                                <br />
                                (事前・事後含む)
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <button
                            type="button"
                            onClick={() => onDownload(id)}
                            data-testid={testid('download-button', i)}
                          >
                            <FontAwesomeIcon className="text-secondary" icon={faFileDownload} fixedWidth />
                          </button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </>
      }
    />
  );
};
