import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useFieldArray, useForm, FormProvider } from 'react-hook-form';
import {
  BillingAgentApi,
  BillingAgentUpdateApi,
  BillingAgentDetailOutputResponse,
  IncResultOutputResponse,
  BillingAgentUpdateFormResponse,
  BillingAgentMailUpdateFormResponse,
} from '../../../api-client';
import { UNSAVED_ID } from '../../../BillingConstants';
import { LOCALDATE_HYPHEN_SHORT_FORMAT } from '../../../Constants';
import { BillingAgentDetailFrom } from '../../organisms/Forms/BillingAgentDetailFrom';

export const BillingAgentDetailPage: React.VFC = () => {
  const { id } = useParams<{ id: string | undefined }>();
  const [billingAgentData, setBillingAgentData] = useState<BillingAgentDetailOutputResponse>();
  const billingAgentApi = new BillingAgentApi();

  const methods = useForm<BillingAgentUpdateFormResponse>({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });
  const initBillingAgentMail = () => {
    const MAX_BILLING_AGENT_MAIL_COUNT = 10;
    const result: BillingAgentMailUpdateFormResponse[] = [];
    for (let i = 0; i < MAX_BILLING_AGENT_MAIL_COUNT; i += 1) {
      result.push({
        billingAgentId: 0,
        mailTypeCode: 0,
        mailaddress: '',
        status: '',
      });
    }
    return result;
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await billingAgentApi.billingagentDetail(id ?? '');
        methods.reset({
          accountsPayableCode: res.data.billingAgent?.accountsPayableCode,
          billingAgentMail: res.data.billingAgent?.billingAgentMail ?? initBillingAgentMail(),
          contractStartDate:
            res.data.billingAgent?.contractStartDate.split('-').slice(0, -1).join('-') ??
            format(new Date(), LOCALDATE_HYPHEN_SHORT_FORMAT),
          disableFlg: res.data.billingAgent?.disableFlg,
          id: res.data.billingAgent?.id ?? UNSAVED_ID,
          imageFee: res.data.billingAgent?.imageFee.value,
          incAccountId: res.data.billingAgent?.incAccountId,
          invoiceNumber: res.data.billingAgent?.invoiceNumber,
          name: res.data.billingAgent?.name,
          payAccountNo: res.data.billingAgent?.payAccountNo,
          paymentConditionId: res.data.billingAgent?.paymentConditionId,
          paymentFee: res.data.billingAgent?.paymentFee.value,
          paymentLimit: res.data.billingAgent?.paymentLimit,
          paymentTermCriteriaCode: res.data.billingAgent?.paymentTermCriteriaCode,
          paymentTermId: res.data.billingAgent?.paymentTermId,
          remarks: res.data.billingAgent?.remarks ?? '',
          salesforceId: res.data.billingAgent?.salesforceId,
          sendmailFlg: res.data.billingAgent?.sendmailFlg,
          spotFee: res.data.billingAgent?.spotFee.value,
          spotFeePaymentConditionCode: res.data.billingAgent?.spotfeePaymentConditionCode,
          stockFee: res.data.billingAgent?.stockFee.value,
          rewardFee: res.data.billingAgent?.rewardFee.value,
          subsidiaryAccountCode: res.data.billingAgent?.subsidiaryAccountCode,
        });
        setBillingAgentData(res.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <>
      {billingAgentData !== undefined && (
        <FormProvider {...methods}>
          <BillingAgentDetailFrom id={id ?? ''} {...billingAgentData} />
        </FormProvider>
      )}
    </>
  );
};
