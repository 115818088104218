import React, { useState } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { Alert } from '../../atoms/Alert';
import { Modal } from '../../molecules/Modal';
import {
  HolidayApi,
  HolidayListOutputResponse,
  HolidayUpdateFormResponse,
  IncResultOutputResponse,
} from '../../../api-client';

export interface Props {
  isEditModal: boolean;
  setIsEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  editItem?: HolidayListOutputResponse;
  setList: React.Dispatch<React.SetStateAction<HolidayListOutputResponse[]>>;
}
export const HolidayManageEditModal: React.VFC<Props> = ({ isEditModal, setIsEditModal, editItem, setList }) => {
  const [updData, setUpdData] = useState<HolidayUpdateFormResponse>({
    id: editItem !== undefined ? Number(editItem.id) : 0,
    billingFlg: true,
    disableFlg: false,
    holiday: '',
    name: '',
    toCFlg: false,
  });
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>({
    result: false,
    errorCode: '',
    errorMessage: '',
  });
  const [modalValidated, setModalValidated] = useState(false);

  const holidayApi = new HolidayApi();

  const handleModalSubmit = (e: any): void => {
    const form = e.currentTarget;
    let data = {} as HolidayUpdateFormResponse;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setModalValidated(false);
    } else {
      if (editItem !== undefined) {
        data = {
          id: Number(editItem.id),
          name: updData.name !== '' ? updData.name : editItem.name,
          holiday: updData.holiday !== '' ? updData.holiday : editItem.holiday,
          billingFlg: updData.billingFlg,
          disableFlg: updData.disableFlg,
          toCFlg: updData.toCFlg,
        };
      }

      holidayApi
        .updateHoliday(data)
        .then((response: AxiosResponse<IncResultOutputResponse>) => {
          setUpdResult(response.data);
          if (response.data.result) {
            setList((current) => {
              const newList = current;
              const target = current.find((obj) => obj.id === updData.id.toString());
              const targetIndex = current.findIndex((obj) => obj.id === updData.id.toString());
              if (target !== undefined) {
                target.billingFlg = updData.billingFlg;
                target.disableFlg = updData.disableFlg;
                target.holiday = updData.holiday;
                target.name = updData.name;
                target.tocFlg = updData.toCFlg;

                newList[targetIndex] = target;
              }
              return newList;
            });
            setIsEditModal(false);
          }
        })
        .catch((error) => {
          console.error('Error holiday manage update:', error);
        });
      e.preventDefault();
      e.stopPropagation();
      onHide();
    }
  };

  const onHide = () => {
    setUpdData((current) => {
      const newUpd = current;
      newUpd.id = 0;
      newUpd.name = '';
      newUpd.holiday = '';
      newUpd.billingFlg = true;
      newUpd.toCFlg = false;
      return newUpd;
    });
    setIsEditModal(false);
    window.location.reload();
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'holiday':
        setUpdData({ ...updData, holiday: event.target.value });
        break;
      case 'name':
        setUpdData({ ...updData, name: event.target.value });
        break;
      case 'toCFlg':
        setUpdData({ ...updData, toCFlg: event.target.checked });
        break;
      case 'billingFlg':
        setUpdData({ ...updData, billingFlg: event.target.checked });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isEditModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            {updResult?.errorCode && updResult?.errorMessage && (
              <Alert variant="danger">{updResult.errorMessage}</Alert>
            )}
            <Form noValidate validated={modalValidated}>
              <Form.Group className="app__stats mb-4" controlId="holiday">
                <Form.Label className="pt-2">祝祭日</Form.Label>
                <Col className="col-8">
                  <Form.Control
                    required
                    type="date"
                    defaultValue={editItem?.holiday.replace(/\//g, '-')}
                    onChange={onChangeText}
                  />
                  <Form.Control.Feedback type="invalid">日付は必須入力項目です。</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group className="app__stats mb-4" controlId="name">
                <Form.Label className="pt-2">理由</Form.Label>
                <Col className="col-8">
                  <Form.Control required type="text" defaultValue={editItem?.name} onChange={onChangeText} />
                  <Form.Control.Feedback type="invalid">理由は必須入力項目です。</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Check id="toCFlg" className="app__stats mb-4">
                <p className="pt-2">toCフラグ</p>
                <Col className="col-8">
                  <Form.Check.Input
                    className="flex-shrink-0 me-2"
                    type="checkbox"
                    name="toCFlg"
                    defaultChecked={editItem?.tocFlg}
                    onChange={onChangeText}
                    style={{ marginTop: '0.75rem' }}
                  />
                </Col>
              </Form.Check>
              <Form.Check id="billingFlg" className="app__stats mb-4">
                <p className="pt-2">請求フラグ</p>
                <Col className="col-8">
                  <Form.Check.Input
                    className="flex-shrink-0 me-2"
                    type="checkbox"
                    name="billingFlg"
                    defaultChecked={editItem?.billingFlg}
                    onChange={onChangeText}
                    style={{ marginTop: '0.75rem' }}
                  />
                </Col>
              </Form.Check>
            </Form>
            <div className="d-flex justify-content-end mb-4">
              <Button variant="secondary" onClick={handleModalSubmit}>
                更新
              </Button>
              <Button variant="secondary" onClick={onHide}>
                キャンセル
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
