/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { F4TestDeliveryFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * F4TestDeliveryApi - axios parameter creator
 * @export
 */
export const F4TestDeliveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * F4をテスト配信する
         * @summary F4テスト配信
         * @param {F4TestDeliveryFormResponse} f4TestDeliveryFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f4TestDelivery: async (f4TestDeliveryFormResponse: F4TestDeliveryFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'f4TestDeliveryFormResponse' is not null or undefined
            assertParamExists('f4TestDelivery', 'f4TestDeliveryFormResponse', f4TestDeliveryFormResponse)
            const localVarPath = `/api/f4-mail-test-sending`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(f4TestDeliveryFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * F4TestDeliveryApi - functional programming interface
 * @export
 */
export const F4TestDeliveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = F4TestDeliveryApiAxiosParamCreator(configuration)
    return {
        /**
         * F4をテスト配信する
         * @summary F4テスト配信
         * @param {F4TestDeliveryFormResponse} f4TestDeliveryFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async f4TestDelivery(f4TestDeliveryFormResponse: F4TestDeliveryFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.f4TestDelivery(f4TestDeliveryFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * F4TestDeliveryApi - factory interface
 * @export
 */
export const F4TestDeliveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = F4TestDeliveryApiFp(configuration)
    return {
        /**
         * F4をテスト配信する
         * @summary F4テスト配信
         * @param {F4TestDeliveryFormResponse} f4TestDeliveryFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        f4TestDelivery(f4TestDeliveryFormResponse: F4TestDeliveryFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.f4TestDelivery(f4TestDeliveryFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * F4TestDeliveryApi - object-oriented interface
 * @export
 * @class F4TestDeliveryApi
 * @extends {BaseAPI}
 */
export class F4TestDeliveryApi extends BaseAPI {
    /**
     * F4をテスト配信する
     * @summary F4テスト配信
     * @param {F4TestDeliveryFormResponse} f4TestDeliveryFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof F4TestDeliveryApi
     */
    public f4TestDelivery(f4TestDeliveryFormResponse: F4TestDeliveryFormResponse, options?: AxiosRequestConfig) {
        return F4TestDeliveryApiFp(this.configuration).f4TestDelivery(f4TestDeliveryFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
