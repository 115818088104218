import React, { useEffect, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Row, Col, Table, FloatingLabel } from 'react-bootstrap';
import { createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { EnqueteListApi, EnqueteListOutputResponse, CommonMasterListOutputResponse } from '../../../api-client';
import { RecommendFloatingLabel } from '../../molecules/RecommendFloatingLabel';

const sampleGetData = {
  enqueteList: [...Array(31)].map((_, i) => {
    return {
      enqueteId: i + 1,
      category: `業種${i + 1}`,
      businessCategory: `提供業態${i + 1}`,
      clientName: `管理クライアント${i + 1}`,
      enqueteName: `アンケート名${i + 1}`,
      description: `説明${i + 1}`,
    };
  }),
};

const sampleSearchData = {
  enqueteList: [...Array(1)].map((_, i) => {
    return {
      enqueteId: i + 99,
      category: `業種${i + 99}`,
      businessCategory: `提供業態${i + 99}`,
      clientName: `管理クライアント${i + 99}`,
      enqueteName: `アンケート名${i + 99}`,
      description: `説明${i + 99}`,
    };
  }),
};

export interface Props {
  isModal: boolean;
  onSave: (id: number, name: string) => void;
  onHide: () => void;
}

export interface State {
  api: EnqueteListApi;
  currentEnqueteId: number | null;
  currentEnqueteName: string;
  list: EnqueteListOutputResponse[];
  listPerPage: EnqueteListOutputResponse[];
  currentPage: number;
  totalPage: number;
  searchParams: {
    enqueteId: number | undefined;
    enqueteName: string | undefined;
    questionId: number | undefined;
    questionContent: string | undefined;
    clientName: string | undefined;
    enqueteType: 1;
  };
}

const MAX_RECORD_PER_PAGE = 100;

export const MonitorPreEnqueteModal: React.VFC<Props> = ({ isModal, onSave, onHide }) => {
  const testid = createTestId(MonitorPreEnqueteModal);

  const { state: $, mergeState } = useLargeState<State>({
    api: new EnqueteListApi(),
    currentEnqueteId: null,
    currentEnqueteName: '',
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
    searchParams: {
      enqueteId: undefined,
      enqueteName: undefined,
      questionId: undefined,
      questionContent: undefined,
      clientName: undefined,
      enqueteType: 1,
    },
  });

  const setPagingStates = useCallback(
    (list: EnqueteListOutputResponse[]) => {
      const sortList = list.sort((a, b) => b.enqueteId - a.enqueteId);
      mergeState({
        list: sortList,
        listPerPage: sortList.slice(0, MAX_RECORD_PER_PAGE),
        totalPage: Math.ceil(Number(sortList?.length) / MAX_RECORD_PER_PAGE),
        currentPage: 1,
      });
    },
    [mergeState]
  );

  useEffect(() => {
    if (!isModal) return;
    const { enqueteId, enqueteName, questionId, questionContent, clientName, enqueteType } = $.searchParams;
    $.api
      .enqueteList(enqueteType, enqueteId, enqueteName, questionId, questionContent, clientName)
      .then((res: AxiosResponse<EnqueteListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
  }, [isModal]);

  const onSearch = () => {
    const { enqueteId, enqueteName, questionId, questionContent, clientName, enqueteType } = $.searchParams;
    $.api
      .enqueteList(enqueteType, enqueteId, enqueteName, questionId, questionContent, clientName)
      .then((res: AxiosResponse<EnqueteListOutputResponse[]>) => {
        setPagingStates(res.data);
      });
  };

  return (
    <Modal
      onHide={onHide}
      isModal={isModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <h4 className="mb-4" data-testid={testid('title')}>
            事前アンケート一覧
          </h4>

          <div className="d-flex justify-content-end mb-4">
            <Button variant="link" className="ms-2" onClick={onHide} data-testid={testid('cancel')}>
              キャンセル
            </Button>
            <Button
              className="ms-2"
              disabled={!$.currentEnqueteId}
              onClick={() => onSave($.currentEnqueteId as number, $.currentEnqueteName)}
              data-testid={testid('save-button')}
            >
              保存
            </Button>
          </div>

          <Form.Group className="bg-light p-4 mb-4">
            <Row className="mb-3">
              <Col className="px-2">
                <FloatingLabel label="アンケートID" data-testid={testid('enqueteId')}>
                  <Form.Control
                    type="number"
                    placeholder="アンケートID"
                    value={$.searchParams.enqueteId || ''}
                    onChange={(e) =>
                      mergeState({
                        searchParams: {
                          ...$.searchParams,
                          enqueteId: Number(e.target.value) || undefined,
                        },
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col className="px-2">
                <FloatingLabel label="アンケート名" data-testid={testid('enqueteName')}>
                  <Form.Control
                    type="text"
                    placeholder="アンケート名"
                    value={$.searchParams.enqueteName || ''}
                    onChange={(e) =>
                      mergeState({
                        searchParams: { ...$.searchParams, enqueteName: e.target.value || undefined },
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="px-2">
                <FloatingLabel label="設問ID" data-testid={testid('questionId')}>
                  <Form.Control
                    type="number"
                    placeholder="設問ID"
                    value={$.searchParams.questionId || ''}
                    onChange={(e) =>
                      mergeState({
                        searchParams: { ...$.searchParams, questionId: Number(e.target.value) || undefined },
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col className="px-2">
                <FloatingLabel label="設問" data-testid={testid('questionContent')}>
                  <Form.Control
                    type="text"
                    placeholder="設問"
                    value={$.searchParams.questionContent || ''}
                    onChange={(e) =>
                      mergeState({ searchParams: { ...$.searchParams, questionContent: e.target.value || undefined } })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col className="px-2">
                <FloatingLabel label="管理クライアント" data-testid={testid('clientName')}>
                  <Form.Control
                    type="text"
                    placeholder="管理クライアント"
                    value={$.searchParams.clientName || ''}
                    onChange={(e) =>
                      mergeState({ searchParams: { ...$.searchParams, clientName: e.target.value || undefined } })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col className="col-4 px-2">
                <Button className="w-100 py-2" onClick={onSearch} data-testid={testid('search-button')}>
                  検索
                </Button>
              </Col>
            </Row>
          </Form.Group>

          <div className="mb-4" data-testid={testid('pagination')}>
            <PaginationWithEllipsis
              currentPage={$.currentPage}
              totalPage={$.totalPage}
              handleClick={(page) => {
                if (!page || page > $.totalPage) return;
                mergeState({
                  listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
                  currentPage: page,
                });
              }}
            />
          </div>

          <Table className="text-nowrap" data-testid={testid('table')}>
            <thead>
              <tr>
                <th> </th>
                <th>アンケートID</th>
                <th>アンケート名 / 説明</th>
                <th>管理クライアント</th>
              </tr>
            </thead>
            <tbody>
              {$.listPerPage.map((item, i) => {
                return (
                  <tr key={item.enqueteId} className="align-middle">
                    <td>
                      <Form.Check
                        id={String(item.enqueteId)}
                        type="radio"
                        name="radioGroup01"
                        data-testid={testid('radio', i)}
                        checked={$.currentEnqueteId === item.enqueteId}
                        onChange={() =>
                          mergeState({ currentEnqueteId: item.enqueteId, currentEnqueteName: item.enqueteName })
                        }
                      />
                    </td>
                    <td>{item.enqueteId}</td>
                    <td>
                      {item.enqueteName}
                      <br />
                      <span className="text-secondary">{item.description}</span>
                    </td>
                    <td>{item.clientName}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      }
    />
  );
};
