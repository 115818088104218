import React from 'react';
import { Form } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { ForceApi, IncResultOutputResponse } from '../../../api-client';
import { createTestId } from '../../../utils/functions';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { Alert } from '../../atoms/Alert';

export const ForceUnseatedPage: React.VFC = () => {
  const testid = createTestId(ForceUnseatedPage);
  const {
    state: $,
    mergeState,
    onChangeSet,
  } = useLargeState<{
    api: ForceApi;
    monitorIds: string;
    isFormatAlert: boolean;
    updResult: IncResultOutputResponse;
  }>({ api: new ForceApi(), monitorIds: '', isFormatAlert: false, updResult: { result: false } });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    // カンマ区切りの数値
    if (!$.monitorIds.trim().match(/^(\d+,)*[^,\D]+$/)) {
      mergeState({ isFormatAlert: true });
      return;
    }
    mergeState({ isFormatAlert: false });

    $.api
      .forceUnseatedProcessExecution({ monitorIds: $.monitorIds.split(',').map((id) => Number(id)) })
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      });
  };

  return (
    <>
      <Title data-testid={testid('title')} className="mb-4">
        {TITLE.KEISAI.FORCE_UNSEATED}
      </Title>

      {$.updResult.result && (
        <Alert testId={testid('success-alert')} variant="success">
          実施しました。
        </Alert>
      )}
      {$.updResult.errorMessage && (
        <Alert
          testId={testid('failure-alert')}
          variant="danger"
        >{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}
      {$.isFormatAlert && (
        <Alert testId={testid('validation-alert')} variant="danger">
          入力内容に誤りがあります。
        </Alert>
      )}

      <Form onSubmit={onSubmit}>
        <div className="d-flex justify-content-end mb-4">
          <Button data-testid={testid('action-button')} className="ms-2" type="submit" disabled={!$.monitorIds}>
            処理実施
          </Button>
        </div>

        <Form.Group data-testid={testid('monitorIds')} className="mb-4">
          <Form.Label>モニターID</Form.Label>
          <Form.Control
            type="text"
            value={$.monitorIds}
            onChange={onChangeSet('monitorIds')}
            placeholder="複数の場合はカンマ区切りで入力"
          />
        </Form.Group>
      </Form>
    </>
  );
};
