import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';

type Props = {
  width_?: number;
  th?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
  right?: boolean;
  left?: boolean;
};

export const Col: React.FC<Props> = ({
  width_,
  th,
  borderBottom,
  borderLeft,
  borderRight,
  borderTop,
  right,
  left,
  children,
}) => {
  const cellWidth = (width: number) => {
    return `${width}cm`;
  };

  const styles = StyleSheet.create({
    col: {
      padding: 1,
      flexDirection: 'row',
      width: cellWidth(width_ || 1),
      display: 'flex',
      // eslint-disable-next-line no-nested-ternary
      justifyContent: right ? 'flex-end' : left ? 'flex-start' : 'center',
      alignItems: 'center',
      backgroundColor: th ? '#E4E4E4' : '#ffffff',
      borderStyle: th ? 'solid' : 'dashed',
      borderLeftWidth: borderLeft ? 1 : 0,
      borderRightWidth: borderRight ? 1 : 0,
      borderTopWidth: borderTop ? 1 : 0,
      borderBottomWidth: borderBottom ? 1 : 0,
    },
  });

  return <View style={styles.col}>{children}</View>;
};
