import React from 'react';
import { Table } from 'react-bootstrap';
import { createTestId } from '../../../utils/functions';
import type { MailHistoryListOemState } from '../../pages/MailHistory/MailHistoryListOemPage';
import { PaginationWithEllipsisBilling } from '../../molecules/PaginationWithEllipsisBilling';

interface Props {
  data: MailHistoryListOemState['data'];
  onClickPagenation: (pageNum: number) => void;
}

export type TestIds = 'table' | 'textFlg' | 'subject-button';

export const MailHistoryListOemTable: React.FC<Props> = React.memo(({ data, onClickPagenation }) => {
  const testId = createTestId<TestIds>(MailHistoryListOemTable);

  const MAX_RECORD_PER_PAGE = 20;

  const getProperty = (d: { [key: string]: string } | undefined) => {
    const result: JSX.Element[] = [];

    if (d === undefined) return result;
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(d)) {
      result.push(<span className="d-block">{`${key}/${value}`}</span>);
    }
    return result;
  };

  return (
    <>
      <div>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>作成日時</th>
              <th>更新日時</th>
              <th>イベント種別</th>
              <th>送信タイミング</th>
              <th>送信状態</th>
              <th>送信日時</th>
              <th>エラー理由</th>
              <th>応募ID</th>
              <th>ユーザーID</th>
              <th>プロパティ(key/value)</th>
            </tr>
          </thead>
          <tbody>
            {data.list.length > 0 &&
              data.list.map((v) => {
                return (
                  <tr key={v.id}>
                    <td>{v.id}</td>
                    <td>{v.createAt}</td>
                    <td>{v.updateAt}</td>
                    <td>{v.messageTypeName}</td>
                    <td>{v.sendTiming}</td>
                    <td>{v.sendStatus.name}</td>
                    <td>{v.sendAt}</td>
                    <td>{v.sendErrorReason}</td>
                    <td>{v.applyId}</td>
                    <td>{v.customerId}</td>
                    <td>{getProperty(v.property).map((vv) => vv)}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      <div className="d-flex justify-content-center">
        <PaginationWithEllipsisBilling
          maxRecordPerPage={MAX_RECORD_PER_PAGE}
          totalDataNum={data.total}
          handleClick={(e) => onClickPagenation(e.page)}
        />
      </div>
    </>
  );
});
