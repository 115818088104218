import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import {
  ReceiptPriceApi,
  ReceiptPriceInfoSearchOutputResponse,
  ReceiptPriceModifyingDisplayOutputResponse,
  ReceiptPriceModifyingDisplayFormResponse,
} from '../../../api-client';
import { formatISODate } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { ReceiptPriceModifyConfirmModal } from '../Correction/Modal/ReceiptPriceModifyConfirmModal';
import { RateView } from '../RateView';

interface Props {
  applyId: number;
  receiptPriceInfo: ReceiptPriceInfoSearchOutputResponse | undefined;
}

// 金額修正の現情報と金額入力画面
export const ReceiptPriceContens: React.FC<Props> = ({ applyId, receiptPriceInfo }) => {
  const [receiptPriceParam, setReceiptPriceParam] = useState<ReceiptPriceModifyingDisplayFormResponse>({
    applyId,
    priceAfterModify: 0,
    workingMemo: '',
  });
  const [reciptPriceModifyDisplay, setReciptPriceModifyDisplay] =
    useState<ReceiptPriceModifyingDisplayOutputResponse>();
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const confirmParam = () => {
    const api = new ReceiptPriceApi();
    (async () => {
      await api
        .receiptPriceModifyingDisplay(receiptPriceParam)
        .then((res: AxiosResponse<ReceiptPriceModifyingDisplayOutputResponse>) => {
          setReciptPriceModifyDisplay(res.data);
          setIsConfirmModal(true);
        });
    })();
  };
  return (
    <>
      <ReceiptPriceModifyConfirmModal
        isModal={isConfirmModal}
        setIsModal={setIsConfirmModal}
        receiptPriceParam={receiptPriceParam}
        receiptPriceModifyDisplay={reciptPriceModifyDisplay}
      />
      <div>
        <Card>
          <Card.Header>利用金額</Card.Header>
          <Card.Body>
            <h5>ユーザー入力金額</h5>
            <p>{receiptPriceInfo?.userInputAmount}円</p>
            <h5>承認済み利用金額</h5>
            <p>{receiptPriceInfo?.approvedUseAmount}円</p>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>謝礼条件</Card.Header>
          <Card.Body>
            <h5>謝礼タイプ</h5> <p>{receiptPriceInfo?.reward?.rewardType}</p>
            <RateView props={{ ...receiptPriceInfo!.reward }} />
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>その他</Card.Header>
          <Card.Body>
            <h5>承認日時</h5>
            <p>
              {receiptPriceInfo?.approvalDate &&
                formatISODate(receiptPriceInfo.approvalDate, 'yyyy年MM月dd日 HH時mm分')}
            </p>
            <h5>謝礼</h5>
            {receiptPriceInfo?.pointAmount && <p>ポイント {receiptPriceInfo?.pointAmount}pt</p>}
            {receiptPriceInfo?.giftTicketAmount && <p>ギフト券 {receiptPriceInfo?.giftTicketAmount}円</p>}
            <h5>OEM手数料</h5>
            {receiptPriceInfo?.oemFee && <p>{receiptPriceInfo?.oemFee}円</p>}
          </Card.Body>
        </Card>

        <Form.Group className="mt-2">
          <h5>修正後利用金額</h5>
          <Form.Control
            className="mb-2"
            onChange={(e) => setReceiptPriceParam({ ...receiptPriceParam, priceAfterModify: Number(e.target.value) })}
          />
          <h5>作業メモ</h5>
          <Form.Control
            className="mb-2"
            as="textarea"
            onChange={(e) => setReceiptPriceParam({ ...receiptPriceParam, workingMemo: e.target.value })}
          />
        </Form.Group>
        <Button onClick={confirmParam}>確認</Button>
      </div>
    </>
  );
};
