import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SqlQueryApi, SqlQueryInfoOutputResponse } from '../../../api-client';
import { SqlQueryInfoTable } from '../../organisms/Table/SqlQueryInfoTable';
import { Url } from '../../../constants/Url';

export const SqlQueryInfoPage = () => {
  const [tableData, setTableData] = useState<SqlQueryInfoOutputResponse>();
  const { id } = useParams<{ id: string | '0' }>();
  const sqlQueryApi = new SqlQueryApi();
  useEffect(() => {
    sqlQueryApi.sqlQueryInfo(Number(id)).then((response) => {
      setTableData(response.data);
    });
  }, []);

  return (
    <>
      <Link to={`${Url.KEISAI.SQL_QUERY_UPDATE}/${id}`}>編集</Link>
      {tableData && <SqlQueryInfoTable tableTitle="SQLクエリ詳細" tableData={tableData} />}
    </>
  );
};
