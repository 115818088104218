import * as React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';

type VariantColors = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light';
export type ButtonVariant = VariantColors | 'link' | `outline-${VariantColors}`;
interface Props {
  variant?: ButtonVariant;
  className?: string;
  type?: 'button' | 'submit';
  onClick?: () => void;
  href?: string;
  testId?: string;
  disabled?: boolean;
  size?: 'sm' | 'lg';
}

export const Button: React.FC<Props & React.ComponentProps<typeof BootstrapButton>> = ({
  children,
  testId,
  ...rest
}) => {
  return (
    <BootstrapButton data-testid={testId} {...rest}>
      {children}
    </BootstrapButton>
  );
};
