/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BrandAllMonitorBaseOutputResponse } from '../domains';
// @ts-ignore
import { BrandForImageTargetMonitorBaseListFormResponse } from '../domains';
// @ts-ignore
import { BrandForImageTargetMonitorBaseListOutputResponse } from '../domains';
// @ts-ignore
import { BrandInfoOutputResponse } from '../domains';
// @ts-ignore
import { BrandListOutputResponse } from '../domains';
// @ts-ignore
import { BrandMonitorBaseImageInfoOutputResponse } from '../domains';
// @ts-ignore
import { BrandTargetApiclientListFormResponse } from '../domains';
// @ts-ignore
import { BrandTargetMonitorBaseListFormResponse } from '../domains';
// @ts-ignore
import { BrandUpdateFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { RecommendApiClientListOutputResponse } from '../domains';
// @ts-ignore
import { RichInfoOutputResponse } from '../domains';
// @ts-ignore
import { RichUpdateFormResponse } from '../domains';
// @ts-ignore
import { ShowApiclientUpdateFormResponse } from '../domains';
// @ts-ignore
import { TargetMonitorbaseListOutputResponse } from '../domains';
/**
 * BrandApi - axios parameter creator
 * @export
 */
export const BrandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 全モニターベース一覧を取得する
         * @summary 全モニターベース一覧取得処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMonitorBase: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/all-monitor-base`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランド情報を削除する
         * @summary ブランド情報削除
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandDelete: async (brandId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('brandDelete', 'brandId', brandId)
            const localVarPath = `/api/brand/{brand-id}`
                .replace(`{${"brand-id"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランド画像用の指定モニターベース一覧を取得する
         * @summary ブランド画像用の指定モニターベース一覧取得
         * @param {BrandForImageTargetMonitorBaseListFormResponse} brandForImageTargetMonitorBaseListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandForImageTargetMonitorBaseList: async (brandForImageTargetMonitorBaseListFormResponse: BrandForImageTargetMonitorBaseListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandForImageTargetMonitorBaseListFormResponse' is not null or undefined
            assertParamExists('brandForImageTargetMonitorBaseList', 'brandForImageTargetMonitorBaseListFormResponse', brandForImageTargetMonitorBaseListFormResponse)
            const localVarPath = `/api/brand-image-target-monitor-base`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandForImageTargetMonitorBaseListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランド情報を取得する
         * @summary ブランド情報取得
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandInfo: async (brandId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('brandInfo', 'brandId', brandId)
            const localVarPath = `/api/brand/{brand-id}`
                .replace(`{${"brand-id"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランド一覧を取得する
         * @summary ブランド一覧取得
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandList: async (keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/brand-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランドモニターベース画像情報を取得する
         * @summary ブランドモニターベース画像情報取得
         * @param {number} monitorBaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandMonitorBaseImageInfo: async (monitorBaseId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorBaseId' is not null or undefined
            assertParamExists('brandMonitorBaseImageInfo', 'monitorBaseId', monitorBaseId)
            const localVarPath = `/api/brand-monitor-base-image/{monitor-base-id}`
                .replace(`{${"monitor-base-id"}}`, encodeURIComponent(String(monitorBaseId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 表示推奨OEM一覧を取得する
         * @summary 表示推奨OEM一覧取得
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandRecommendApiclientList: async (brandId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('brandRecommendApiclientList', 'brandId', brandId)
            const localVarPath = `/api/brand-recommend-oem/{brand-id}`
                .replace(`{${"brand-id"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランドリッチ化情報を取得する
         * @summary ブランドリッチ化情報取得
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandRichInfo: async (brandId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('brandRichInfo', 'brandId', brandId)
            const localVarPath = `/api/brand-rich/{brand-id}`
                .replace(`{${"brand-id"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランドリッチ化情報を更新する
         * @summary ブランドリッチ化情報更新
         * @param {number} brandId 
         * @param {RichUpdateFormResponse} richUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandRichUpdate: async (brandId: number, richUpdateFormResponse: RichUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('brandRichUpdate', 'brandId', brandId)
            // verify required parameter 'richUpdateFormResponse' is not null or undefined
            assertParamExists('brandRichUpdate', 'richUpdateFormResponse', richUpdateFormResponse)
            const localVarPath = `/api/brand-rich/{brand-id}`
                .replace(`{${"brand-id"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(richUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランド表示OEM一覧を取得する
         * @summary ブランド表示OEM一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandShowApiclientList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/brand-show-oem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランド表示OEMを更新する
         * @summary ブランド表示OEM更新
         * @param {ShowApiclientUpdateFormResponse} showApiclientUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandShowApiclientUpdate: async (showApiclientUpdateFormResponse: ShowApiclientUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'showApiclientUpdateFormResponse' is not null or undefined
            assertParamExists('brandShowApiclientUpdate', 'showApiclientUpdateFormResponse', showApiclientUpdateFormResponse)
            const localVarPath = `/api/brand-show-oem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(showApiclientUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランド紐付け対象OEM一覧を取得する
         * @summary ブランド紐付け対象OEM一覧取得
         * @param {BrandTargetApiclientListFormResponse} brandTargetApiclientListFormResponse 
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandTargetApiclientList: async (brandTargetApiclientListFormResponse: BrandTargetApiclientListFormResponse, brandId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandTargetApiclientListFormResponse' is not null or undefined
            assertParamExists('brandTargetApiclientList', 'brandTargetApiclientListFormResponse', brandTargetApiclientListFormResponse)
            const localVarPath = `/api/brand-target-oem-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (brandId !== undefined) {
                localVarQueryParameter['brand-id'] = brandId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandTargetApiclientListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランド紐付け対象モニターベース一覧を取得する
         * @summary ブランド紐付け対象モニターベース一覧取得
         * @param {BrandTargetMonitorBaseListFormResponse} brandTargetMonitorBaseListFormResponse 
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandTargetMonitorBaseList: async (brandTargetMonitorBaseListFormResponse: BrandTargetMonitorBaseListFormResponse, brandId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandTargetMonitorBaseListFormResponse' is not null or undefined
            assertParamExists('brandTargetMonitorBaseList', 'brandTargetMonitorBaseListFormResponse', brandTargetMonitorBaseListFormResponse)
            const localVarPath = `/api/brand-target-monitor-base-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (brandId !== undefined) {
                localVarQueryParameter['brand-id'] = brandId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandTargetMonitorBaseListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ブランドを更新する
         * @summary ブランド更新
         * @param {BrandUpdateFormResponse} brandUpdateFormResponse 
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandUpdate: async (brandUpdateFormResponse: BrandUpdateFormResponse, brandId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandUpdateFormResponse' is not null or undefined
            assertParamExists('brandUpdate', 'brandUpdateFormResponse', brandUpdateFormResponse)
            const localVarPath = `/api/brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (brandId !== undefined) {
                localVarQueryParameter['brand-id'] = brandId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandApi - functional programming interface
 * @export
 */
export const BrandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandApiAxiosParamCreator(configuration)
    return {
        /**
         * 全モニターベース一覧を取得する
         * @summary 全モニターベース一覧取得処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allMonitorBase(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandAllMonitorBaseOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allMonitorBase(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランド情報を削除する
         * @summary ブランド情報削除
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandDelete(brandId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandDelete(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランド画像用の指定モニターベース一覧を取得する
         * @summary ブランド画像用の指定モニターベース一覧取得
         * @param {BrandForImageTargetMonitorBaseListFormResponse} brandForImageTargetMonitorBaseListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandForImageTargetMonitorBaseList(brandForImageTargetMonitorBaseListFormResponse: BrandForImageTargetMonitorBaseListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandForImageTargetMonitorBaseListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandForImageTargetMonitorBaseList(brandForImageTargetMonitorBaseListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランド情報を取得する
         * @summary ブランド情報取得
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandInfo(brandId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandInfo(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランド一覧を取得する
         * @summary ブランド一覧取得
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandList(keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandList(keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランドモニターベース画像情報を取得する
         * @summary ブランドモニターベース画像情報取得
         * @param {number} monitorBaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandMonitorBaseImageInfo(monitorBaseId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandMonitorBaseImageInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandMonitorBaseImageInfo(monitorBaseId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 表示推奨OEM一覧を取得する
         * @summary 表示推奨OEM一覧取得
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandRecommendApiclientList(brandId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecommendApiClientListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandRecommendApiclientList(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランドリッチ化情報を取得する
         * @summary ブランドリッチ化情報取得
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandRichInfo(brandId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RichInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandRichInfo(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランドリッチ化情報を更新する
         * @summary ブランドリッチ化情報更新
         * @param {number} brandId 
         * @param {RichUpdateFormResponse} richUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandRichUpdate(brandId: number, richUpdateFormResponse: RichUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandRichUpdate(brandId, richUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランド表示OEM一覧を取得する
         * @summary ブランド表示OEM一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandShowApiclientList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RecommendApiClientListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandShowApiclientList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランド表示OEMを更新する
         * @summary ブランド表示OEM更新
         * @param {ShowApiclientUpdateFormResponse} showApiclientUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandShowApiclientUpdate(showApiclientUpdateFormResponse: ShowApiclientUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandShowApiclientUpdate(showApiclientUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランド紐付け対象OEM一覧を取得する
         * @summary ブランド紐付け対象OEM一覧取得
         * @param {BrandTargetApiclientListFormResponse} brandTargetApiclientListFormResponse 
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandTargetApiclientList(brandTargetApiclientListFormResponse: BrandTargetApiclientListFormResponse, brandId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RecommendApiClientListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandTargetApiclientList(brandTargetApiclientListFormResponse, brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランド紐付け対象モニターベース一覧を取得する
         * @summary ブランド紐付け対象モニターベース一覧取得
         * @param {BrandTargetMonitorBaseListFormResponse} brandTargetMonitorBaseListFormResponse 
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandTargetMonitorBaseList(brandTargetMonitorBaseListFormResponse: BrandTargetMonitorBaseListFormResponse, brandId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TargetMonitorbaseListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandTargetMonitorBaseList(brandTargetMonitorBaseListFormResponse, brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ブランドを更新する
         * @summary ブランド更新
         * @param {BrandUpdateFormResponse} brandUpdateFormResponse 
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brandUpdate(brandUpdateFormResponse: BrandUpdateFormResponse, brandId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brandUpdate(brandUpdateFormResponse, brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandApi - factory interface
 * @export
 */
export const BrandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandApiFp(configuration)
    return {
        /**
         * 全モニターベース一覧を取得する
         * @summary 全モニターベース一覧取得処理
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allMonitorBase(options?: any): AxiosPromise<BrandAllMonitorBaseOutputResponse> {
            return localVarFp.allMonitorBase(options).then((request) => request(axios, basePath));
        },
        /**
         * ブランド情報を削除する
         * @summary ブランド情報削除
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandDelete(brandId: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.brandDelete(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランド画像用の指定モニターベース一覧を取得する
         * @summary ブランド画像用の指定モニターベース一覧取得
         * @param {BrandForImageTargetMonitorBaseListFormResponse} brandForImageTargetMonitorBaseListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandForImageTargetMonitorBaseList(brandForImageTargetMonitorBaseListFormResponse: BrandForImageTargetMonitorBaseListFormResponse, options?: any): AxiosPromise<Array<BrandForImageTargetMonitorBaseListOutputResponse>> {
            return localVarFp.brandForImageTargetMonitorBaseList(brandForImageTargetMonitorBaseListFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランド情報を取得する
         * @summary ブランド情報取得
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandInfo(brandId: number, options?: any): AxiosPromise<BrandInfoOutputResponse> {
            return localVarFp.brandInfo(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランド一覧を取得する
         * @summary ブランド一覧取得
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandList(keyword?: string, options?: any): AxiosPromise<Array<BrandListOutputResponse>> {
            return localVarFp.brandList(keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランドモニターベース画像情報を取得する
         * @summary ブランドモニターベース画像情報取得
         * @param {number} monitorBaseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandMonitorBaseImageInfo(monitorBaseId: number, options?: any): AxiosPromise<BrandMonitorBaseImageInfoOutputResponse> {
            return localVarFp.brandMonitorBaseImageInfo(monitorBaseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 表示推奨OEM一覧を取得する
         * @summary 表示推奨OEM一覧取得
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandRecommendApiclientList(brandId: number, options?: any): AxiosPromise<Array<RecommendApiClientListOutputResponse>> {
            return localVarFp.brandRecommendApiclientList(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランドリッチ化情報を取得する
         * @summary ブランドリッチ化情報取得
         * @param {number} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandRichInfo(brandId: number, options?: any): AxiosPromise<RichInfoOutputResponse> {
            return localVarFp.brandRichInfo(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランドリッチ化情報を更新する
         * @summary ブランドリッチ化情報更新
         * @param {number} brandId 
         * @param {RichUpdateFormResponse} richUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandRichUpdate(brandId: number, richUpdateFormResponse: RichUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.brandRichUpdate(brandId, richUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランド表示OEM一覧を取得する
         * @summary ブランド表示OEM一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandShowApiclientList(options?: any): AxiosPromise<Array<RecommendApiClientListOutputResponse>> {
            return localVarFp.brandShowApiclientList(options).then((request) => request(axios, basePath));
        },
        /**
         * ブランド表示OEMを更新する
         * @summary ブランド表示OEM更新
         * @param {ShowApiclientUpdateFormResponse} showApiclientUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandShowApiclientUpdate(showApiclientUpdateFormResponse: ShowApiclientUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.brandShowApiclientUpdate(showApiclientUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランド紐付け対象OEM一覧を取得する
         * @summary ブランド紐付け対象OEM一覧取得
         * @param {BrandTargetApiclientListFormResponse} brandTargetApiclientListFormResponse 
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandTargetApiclientList(brandTargetApiclientListFormResponse: BrandTargetApiclientListFormResponse, brandId?: number, options?: any): AxiosPromise<RecommendApiClientListOutputResponse> {
            return localVarFp.brandTargetApiclientList(brandTargetApiclientListFormResponse, brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランド紐付け対象モニターベース一覧を取得する
         * @summary ブランド紐付け対象モニターベース一覧取得
         * @param {BrandTargetMonitorBaseListFormResponse} brandTargetMonitorBaseListFormResponse 
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandTargetMonitorBaseList(brandTargetMonitorBaseListFormResponse: BrandTargetMonitorBaseListFormResponse, brandId?: number, options?: any): AxiosPromise<TargetMonitorbaseListOutputResponse> {
            return localVarFp.brandTargetMonitorBaseList(brandTargetMonitorBaseListFormResponse, brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * ブランドを更新する
         * @summary ブランド更新
         * @param {BrandUpdateFormResponse} brandUpdateFormResponse 
         * @param {number} [brandId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brandUpdate(brandUpdateFormResponse: BrandUpdateFormResponse, brandId?: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.brandUpdate(brandUpdateFormResponse, brandId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandApi - object-oriented interface
 * @export
 * @class BrandApi
 * @extends {BaseAPI}
 */
export class BrandApi extends BaseAPI {
    /**
     * 全モニターベース一覧を取得する
     * @summary 全モニターベース一覧取得処理
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public allMonitorBase(options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).allMonitorBase(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランド情報を削除する
     * @summary ブランド情報削除
     * @param {number} brandId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandDelete(brandId: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandDelete(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランド画像用の指定モニターベース一覧を取得する
     * @summary ブランド画像用の指定モニターベース一覧取得
     * @param {BrandForImageTargetMonitorBaseListFormResponse} brandForImageTargetMonitorBaseListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandForImageTargetMonitorBaseList(brandForImageTargetMonitorBaseListFormResponse: BrandForImageTargetMonitorBaseListFormResponse, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandForImageTargetMonitorBaseList(brandForImageTargetMonitorBaseListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランド情報を取得する
     * @summary ブランド情報取得
     * @param {number} brandId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandInfo(brandId: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandInfo(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランド一覧を取得する
     * @summary ブランド一覧取得
     * @param {string} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandList(keyword?: string, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandList(keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランドモニターベース画像情報を取得する
     * @summary ブランドモニターベース画像情報取得
     * @param {number} monitorBaseId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandMonitorBaseImageInfo(monitorBaseId: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandMonitorBaseImageInfo(monitorBaseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 表示推奨OEM一覧を取得する
     * @summary 表示推奨OEM一覧取得
     * @param {number} brandId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandRecommendApiclientList(brandId: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandRecommendApiclientList(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランドリッチ化情報を取得する
     * @summary ブランドリッチ化情報取得
     * @param {number} brandId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandRichInfo(brandId: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandRichInfo(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランドリッチ化情報を更新する
     * @summary ブランドリッチ化情報更新
     * @param {number} brandId 
     * @param {RichUpdateFormResponse} richUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandRichUpdate(brandId: number, richUpdateFormResponse: RichUpdateFormResponse, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandRichUpdate(brandId, richUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランド表示OEM一覧を取得する
     * @summary ブランド表示OEM一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandShowApiclientList(options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandShowApiclientList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランド表示OEMを更新する
     * @summary ブランド表示OEM更新
     * @param {ShowApiclientUpdateFormResponse} showApiclientUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandShowApiclientUpdate(showApiclientUpdateFormResponse: ShowApiclientUpdateFormResponse, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandShowApiclientUpdate(showApiclientUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランド紐付け対象OEM一覧を取得する
     * @summary ブランド紐付け対象OEM一覧取得
     * @param {BrandTargetApiclientListFormResponse} brandTargetApiclientListFormResponse 
     * @param {number} [brandId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandTargetApiclientList(brandTargetApiclientListFormResponse: BrandTargetApiclientListFormResponse, brandId?: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandTargetApiclientList(brandTargetApiclientListFormResponse, brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランド紐付け対象モニターベース一覧を取得する
     * @summary ブランド紐付け対象モニターベース一覧取得
     * @param {BrandTargetMonitorBaseListFormResponse} brandTargetMonitorBaseListFormResponse 
     * @param {number} [brandId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandTargetMonitorBaseList(brandTargetMonitorBaseListFormResponse: BrandTargetMonitorBaseListFormResponse, brandId?: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandTargetMonitorBaseList(brandTargetMonitorBaseListFormResponse, brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ブランドを更新する
     * @summary ブランド更新
     * @param {BrandUpdateFormResponse} brandUpdateFormResponse 
     * @param {number} [brandId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public brandUpdate(brandUpdateFormResponse: BrandUpdateFormResponse, brandId?: number, options?: AxiosRequestConfig) {
        return BrandApiFp(this.configuration).brandUpdate(brandUpdateFormResponse, brandId, options).then((request) => request(this.axios, this.basePath));
    }
}
