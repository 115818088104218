export const TimeList = [
  { id: '10:00', name: '10:00' },
  { id: '10:30', name: '10:30' },
  { id: '11:00', name: '11:00' },
  { id: '11:30', name: '11:30' },
  { id: '12:00', name: '12:00' },
  { id: '12:30', name: '12:30' },
  { id: '13:00', name: '13:00' },
  { id: '13:30', name: '13:30' },
  { id: '14:00', name: '14:00' },
  { id: '14:30', name: '14:30' },
  { id: '15:00', name: '15:00' },
  { id: '15:30', name: '15:30' },
  { id: '16:00', name: '16:00' },
  { id: '16:30', name: '16:30' },
  { id: '17:00', name: '17:00' },
  { id: '17:30', name: '17:30' },
  { id: '18:00', name: '18:00' },
  { id: '18:30', name: '18:30' },
  { id: '19:00', name: '19:00' },
  { id: '19:30', name: '19:30' },
  { id: '20:00', name: '20:00' },
  { id: '20:30', name: '20:30' },
  { id: '21:00', name: '21:00' },
  { id: '21:30', name: '21:30' },
  { id: '22:00', name: '22:00' },
  { id: '22:30', name: '22:30' },
  { id: '23:00', name: '23:00' },
  { id: '23:30', name: '23:30' },
  { id: '0:00', name: '0:00' },
  { id: '0:30', name: '0:30' },
  { id: '1:00', name: '1:00' },
  { id: '1:30', name: '1:30' },
  { id: '2:00', name: '2:00' },
  { id: '2:30', name: '2:30' },
  { id: '3:00', name: '3:00' },
  { id: '3:30', name: '3:30' },
  { id: '4:00', name: '4:00' },
  { id: '4:30', name: '4:30' },
  { id: '5:00', name: '5:00' },
  { id: '5:30', name: '5:30' },
  { id: '6:00', name: '6:00' },
  { id: '6:30', name: '6:30' },
  { id: '7:00', name: '7:00' },
  { id: '7:30', name: '7:30' },
  { id: '8:00', name: '8:00' },
  { id: '8:30', name: '8:30' },
  { id: '9:00', name: '9:00' },
  { id: '9:30', name: '9:30' },
];
