import React from 'react';
import { Accordion } from 'react-bootstrap';
import type { InquiryAnswerHistoryOutputResponse } from '../../../../../api-client';
import { uuid } from '../../../../../utils/functions';
import { SituatorPost } from './SituatorPost';
import { Thread } from './Thread';

/** やりとり経緯 */
export const Situator: React.FC<{ list: InquiryAnswerHistoryOutputResponse[] }> = React.memo(({ list }) => {
  if (list.length === 0) return <></>;
  return (
    <Accordion>
      <Accordion.Header>
        <h5>やりとり経緯 ({list.length})</h5>
      </Accordion.Header>
      <Accordion.Body>
        <Thread
          mark=""
          posts={
            <>
              {list.map(({ createAt, note: text }) => (
                <SituatorPost
                  key={uuid()}
                  posts={[
                    {
                      text,
                      at: createAt,
                    },
                  ]}
                />
              ))}
            </>
          }
        />
      </Accordion.Body>
    </Accordion>
  );
});
