import { BILLING_INVOICE_PDF_TYPE } from '../BillingConstants';
import { EXTENSION_EXCEL } from '../Constants';

export const fileDownLoad = (blob: Blob, filename?: string) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);

  if (filename === undefined) {
    window.open(link.href);
  } else {
    link.setAttribute('download', filename);
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
};

export const getDownLoadFileName = (
  invoiceType: Array<string>,
  clientName: string,
  billingMonth: string,
  extension: string,
  billingDestinationName?: string
) => {
  let fileName = `${clientName}様_`;

  if (billingDestinationName && billingDestinationName.length > 0) {
    fileName += `${billingDestinationName}_`;
  }
  fileName += `${billingMonth}度_`;

  if (extension === EXTENSION_EXCEL) {
    // if (invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Item)) {
    //   fileName += '商品・';
    // }
    if (invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Shop)) {
      fileName += '店舗・';
    }
    if (invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Monitor)) {
      fileName += '調査・';
    }
    if (invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Apply)) {
      fileName += '派遣・';
    }
    return `${fileName.replace(/・$/, '')}別明細.${extension}`;
  }
  return `${fileName}請求書.${extension}`;
};
