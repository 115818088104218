/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import {
  ApplyStatusListOutputResponse,
  PaidServiceTypeListOutputResponse,
  SubmissionStatusListOutputResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import { createTestId } from '../../../utils/functions';
import type { State as PageState } from '../../pages/ApplyHistory/ApplyHistoryListPage';

export const initialForms = {
  applyId: undefined,
  applyStatusId: undefined,
  enqueteStatusId: undefined,
  paidServiceCode: undefined,
  shopNameMonitorName: undefined,
  surveyProofStatusId: undefined,
  winningAtEnd: undefined,
  winningAtStart: undefined,
};

export type State = PageState['forms'];

interface Props {
  setForms: DispatchSetState<State>;
  applyStatusList: ApplyStatusListOutputResponse[];
  submissionStatusList: SubmissionStatusListOutputResponse[];
  paidServiceTypeList: PaidServiceTypeListOutputResponse[];
}

export const ApplyHistoryListForms: React.FC<Props> = ({
  setForms,
  applyStatusList,
  submissionStatusList,
  paidServiceTypeList,
}) => {
  const testid = createTestId(ApplyHistoryListForms);
  const { state: $, mergeState } = useLargeState<State>(initialForms);

  useEffect(() => setForms($), [setForms, $]);
  return (
    <>
      <Row className="mb-3">
        <Col>
          <Form.Group data-testid={testid('shopNameMonitorNameForm')}>
            <FloatingLabel label="店舗/モニター">
              <Form.Control
                type="text"
                placeholder="店舗/モニター"
                value={$.shopNameMonitorName || ''}
                onChange={(e) => mergeState({ shopNameMonitorName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group data-testid={testid('applyIdFrom')}>
            <FloatingLabel label="応募ID">
              <Form.Control
                type="number"
                placeholder="応募ID"
                value={$.applyId || ''}
                onChange={(e) => mergeState({ applyId: Number(e.target.value) || undefined })}
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group data-testid={testid('applyStatusIdForm')}>
            <FloatingLabel label="応募ステータス">
              <Form.Select
                value={$.applyStatusId}
                onChange={(e) =>
                  mergeState({
                    applyStatusId: e.target.value !== '' ? Number(e.target.value) : undefined,
                  })
                }
              >
                <option value="">&nbsp;</option>
                {applyStatusList.map((v) => (
                  <option key={v.code} value={v.code}>
                    {v.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group data-testid={testid('surveyProofStatusIdForm')}>
            <FloatingLabel label="調査証明">
              <Form.Select
                value={$.surveyProofStatusId}
                onChange={(e) =>
                  mergeState({
                    surveyProofStatusId: e.target.value !== '' ? Number(e.target.value) : undefined,
                  })
                }
              >
                <option value="">&nbsp;</option>
                {submissionStatusList.map((v) => (
                  <option key={v.code} value={v.code}>
                    {v.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group data-testid={testid('enqueteStatusIdForm')}>
            <FloatingLabel label="アンケート">
              <Form.Select
                value={$.enqueteStatusId}
                onChange={(e) =>
                  mergeState({
                    enqueteStatusId: e.target.value !== '' ? Number(e.target.value) : undefined,
                  })
                }
              >
                <option value="">&nbsp;</option>
                {submissionStatusList.map((v) => (
                  <option key={v.code} value={v.code}>
                    {v.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group data-testid={testid('paidServiceCodeForm')}>
            <FloatingLabel label="サービス">
              <Form.Select
                value={$.paidServiceCode}
                onChange={(e) =>
                  mergeState({
                    paidServiceCode: e.target.value !== '' ? Number(e.target.value) : undefined,
                  })
                }
              >
                <option value="">&nbsp;</option>
                {paidServiceTypeList.map((v) => (
                  <option key={v.code} value={v.code}>
                    {v.name}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group data-testid={testid('winningAtStartForm')}>
            <FloatingLabel label="当選日From">
              <Form.Control
                type="date"
                placeholder="当選日From"
                value={$.winningAtStart ? $.winningAtStart.replace(/\//g, '-') : ''}
                onChange={(e) =>
                  mergeState({ winningAtStart: e.target.value ? e.target.value.replace(/-/g, '/') : undefined })
                }
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group data-testid={testid('winningAtEndForm')}>
            <FloatingLabel label="当選日To">
              <Form.Control
                type="date"
                placeholder="当選日To"
                value={$.winningAtEnd ? $.winningAtEnd.replace(/\//g, '-') : ''}
                onChange={(e) =>
                  mergeState({ winningAtEnd: e.target.value ? e.target.value.replace(/-/g, '/') : undefined })
                }
              />
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
