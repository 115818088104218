import React from 'react';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../store/correctionStore';
import { TemplateFormMode } from '../../../pages/Correction/constants';
import { TemplateForm } from '../TemplateForm';
import { CorrectionModalBase } from './CorrectionModalBase';

// TODO: [添削] テスト未実装
export const NoteModal: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  return (
    <CorrectionModalBase isModal={$.isNoteModal} title="ノートテンプレートフォーム">
      <TemplateForm mode={TemplateFormMode.NOTE} />
    </CorrectionModalBase>
  );
};
