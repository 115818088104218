import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
import type { MonitorListOutputResponse } from '../../../api-client';
import { createTestId, uuid, when } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { MonitorApplyHistoryListOutputResponse } from '../../../api-client';

export interface Props {
  listPerPage: MonitorApplyHistoryListOutputResponse[];
}

export const MonitorApplyHistoryListTable: React.VFC<Props> = ({ listPerPage }) => {
  const testid = createTestId(MonitorApplyHistoryListTable);
  const history = useHistory();

  return (
    <div
      className="mb-4"
      style={{ width: 'calc(100vw - 21.5rem)', maxHeight: '400px', overflowX: 'scroll', overflowY: 'auto' }}
    >
      <Table className="mb-4" data-testid={testid('table')}>
        <thead className="text-nowrap">
          <tr className="align-middle" style={{ position: 'sticky', top: '0', left: '0', backgroundColor: '#f8f9fa' }}>
            <div
              style={{
                content: '',
                position: 'absolute',
                top: '1px',
                width: '100%',
                height: '100%',
                borderBottom: '2px solid',
                marginLeft: '0',
              }}
            />
            <th>モニターベースID</th>
            <th>モニターID</th>
            <th>モニター名</th>
            <th>実店舗ID</th>
            <th>実店舗名</th>
            <th>クライアントID</th>
            <th>クライアント名</th>
            <th>表示ステータス</th>
            <th>モニター期間</th>
            <th>募集方法</th>
            <th>契約枠数</th>
            <th>当選枠数</th>
            <th>申込数</th>
            <th>当選数</th>
            <th>完了数</th>
            <th>キャンセル数</th>
            <th>残枠数</th>
            <th>アンケート提出待</th>
            <th>アンケート添削済</th>
            <th>調査証明提出待</th>
            <th>調査証明添削済</th>
            <th>非請求数</th>
            <th>非表示数</th>
          </tr>
        </thead>
        <tbody>
          {listPerPage.map(
            ({
              baseId,
              monitorId,
              monitorName,
              shopId,
              shopName,
              clientId,
              clientName,
              monitorStatus,
              monitorPeriod,
              recruitmentMethod,
              contractBorderCount,
              winningBorderCount,
              applyCnt,
              winCnt,
              completeCnt,
              cancelCnt,
              remainingCnt,
              enqueteSubmissionWaitingCnt,
              enqueteCorrectedCnt,
              receiptSubmissionWaitingCnt,
              receiptCorrectedCnt,
              unBillingCnt,
              hideAnswerCnt,
            }) => (
              <React.Fragment key={uuid()}>
                <tr key={uuid()} className="text-nowrap align-middle">
                  <td>&nbsp;</td>
                  <td>{baseId}</td>
                  <td>{monitorId}</td>
                  <td>{monitorName}</td>
                  <td>{shopId}</td>
                  <td>{shopName}</td>
                  <td>{clientId}</td>
                  <td>{clientName}</td>
                  <td>{monitorStatus}</td>
                  <td>{monitorPeriod}</td>
                  <td>{recruitmentMethod}</td>
                  <td>{contractBorderCount}</td>
                  <td>{winningBorderCount}</td>
                  <td>{applyCnt}</td>
                  <td>{winCnt}</td>
                  <td>{completeCnt}</td>
                  <td>{cancelCnt}</td>
                  <td>{remainingCnt}</td>
                  <td>{enqueteSubmissionWaitingCnt}</td>
                  <td>{enqueteCorrectedCnt}</td>
                  <td>{receiptSubmissionWaitingCnt}</td>
                  <td>{receiptCorrectedCnt}</td>
                  <td>{unBillingCnt}</td>
                  <td>{hideAnswerCnt}</td>
                </tr>
              </React.Fragment>
            )
          )}
        </tbody>
      </Table>
    </div>
  );
};
