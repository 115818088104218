import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import scss from '../../scss/molecules/tagsInputForm.module.scss';
import { DispatchSetState } from '../../interfaces/utils';
import { createTestId } from '../../utils/functions';

export interface Props {
  tags: string[];
  type: 'number' | 'text';
  className?: string;
  placeholder?: string;
  setInput?: DispatchSetState<string[]>;
  onChange?: (tags: string[]) => void;
}

export type TestIds = 'tag' | 'input';

export const TagsInputForm: React.VFC<Props> = ({ tags, type, className, placeholder, setInput, onChange }) => {
  const testId = createTestId<TestIds>(TagsInputForm);
  /* tagsの変更state */
  const [tagData, setTagData] = useState(tags);
  /* inputのvalue変更state */
  const [inputValue, setInputValue] = useState('');

  const removeTagData = useCallback(
    (indexToRemove: number) => {
      setTagData([...tagData.filter((_: string, index: number) => index !== indexToRemove)]);
    },
    [tagData]
  );

  const newLineCode = useMemo(() => {
    return /\r\n|\n/;
  }, []);

  const addTagData = useCallback(
    (target: string) => {
      const dataList = target
        .split(newLineCode)
        .map((val: string) => {
          if (type === 'number') {
            return val.replace(/[^\d]/g, '');
          }
          if (type === 'text') {
            return val;
          }
          return '';
        })
        .filter(Boolean);
      setTagData([...tagData, ...dataList]);
      setInputValue('');
    },
    [newLineCode, tagData, type]
  );

  const onChenge = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (newLineCode.test(event.target.value)) {
        addTagData(event.target.value);
      } else {
        setInputValue(event.target.value);
      }
    },
    [addTagData, newLineCode]
  );

  useEffect(() => {
    if (setInput) {
      setInput(tagData);
    }
    if (onChange) {
      onChange(tagData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagData]);

  return (
    <div className={`${scss.wrap_taginput} ${className}`}>
      <ul className={scss.tags}>
        {tagData.map((tag: string, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} className={`${scss.tag} d-flex align-items-center`} data-testid={testId('tag', index)}>
            <span>{tag}</span>
            <span className={scss.tag_close} onClick={() => removeTagData(index)} aria-hidden="true">
              <FontAwesomeIcon icon={faTimes} fixedWidth />
            </span>
          </li>
        ))}
      </ul>
      <textarea
        data-testid={testId('input')}
        className={scss.taginput}
        value={inputValue}
        placeholder={placeholder || 'Press enter to add a tag'}
        onChange={(e) => onChenge(e)}
      />
    </div>
  );
};
