import React, { useMemo } from 'react';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../store/correctionStore';
import { HistoryNgList } from '../../../molecules/HistoryNgList';
import { DisplayPointType } from '../../../pages/Correction/constants';
import { dpToData, getCheckListSectionlabel } from '../../../pages/Correction/utils';
import { CorrectionModalBase } from './CorrectionModalBase';

// TODO: [添削] テスト未実装
export const NGHistoryModal: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  const [title, label, details] = useMemo(() => {
    const dp = $.dpForNGHistoryModal;
    const parser = dpToData(dp);
    const m = parser.toMeta(!$.isEnquete ? $.res_correctionCheckDisplay?.applyReceiptCheckList : $.res_enqueteDisplay);
    const h = parser.toNGHistoryDetails($.res_answerAndNgHistoryDetail);
    return [
      $.titleForNGHistoryModal,
      dp?.type === DisplayPointType.MONITOR_RULE
        ? parser.toMonitorRule($.res_correctionCheckDisplay?.monitorRuleList).name
        : getCheckListSectionlabel($.isEnquete, m.no, m.title),
      h,
    ];
  }, [
    $.dpForNGHistoryModal,
    $.isEnquete,
    $.res_answerAndNgHistoryDetail,
    $.res_correctionCheckDisplay?.applyReceiptCheckList,
    $.res_correctionCheckDisplay?.monitorRuleList,
    $.res_enqueteDisplay,
    $.titleForNGHistoryModal,
  ]);
  return (
    <CorrectionModalBase isModal={!!$.isNGHistoryModal} title={title ?? ''}>
      <HistoryNgList label={label} details={details} />
    </CorrectionModalBase>
  );
};
