import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { createTestId } from '../../utils/functions';

interface IProps {
  isModal: boolean;
  title?: string;
  // eslint-disable-next-line no-undef
  body?: JSX.Element | null;
  // eslint-disable-next-line no-undef
  footer?: JSX.Element | null;
  size?: 'sm' | 'lg' | 'xl';
  onHide?: () => void;
  scrollable?: boolean;
  centered?: boolean;
  closeButton?: boolean;
  /**
   * ダイアログを編集
   * ```tsx
   * // 使い方
   * <Modal
   *   dialogAs={(Dialog) => (props) => <Dialog {...props} />}
   * />
   * // モーダルの親ノードを取得したい場合
   * import React, { useRef, useEffect } from 'react';
   * import { ModalDialog } from 'bootstrap';
   * const CustomDialog: React.FC = (props) => {
   *   const ref = useRef<HTMLDivElement>(null);
   *   useEffect(() => {
   *     console.log(ref.current?.parentNode);
   *   }, [ref.current])
   *   return <ModalDialog {...props} ref={ref} />
   * }
   * <Modal
   *   dialogAs={() => CustomDialog}
   * />
   * ```
   */
  dialogAs?: (Dialog: typeof BootstrapModal['Dialog']) => React.ComponentProps<typeof BootstrapModal>['dialogAs'];
}

export const Modal: React.VFC<IProps> = ({
  title,
  body,
  footer,
  isModal,
  onHide,
  size,
  scrollable,
  centered,
  closeButton,
  dialogAs,
}) => {
  const testid = createTestId(Modal);
  return (
    <BootstrapModal
      show={isModal}
      onHide={onHide}
      size={size}
      scrollable={scrollable}
      centered={centered}
      dialogAs={dialogAs && dialogAs(BootstrapModal.Dialog)}
    >
      <BootstrapModal.Header closeButton={closeButton}>
        {title && (
          <BootstrapModal.Title className="mb-0" data-testid={testid('title')}>
            {title}
          </BootstrapModal.Title>
        )}
      </BootstrapModal.Header>
      {body && <BootstrapModal.Body>{body}</BootstrapModal.Body>}
      {footer && <BootstrapModal.Footer>{footer}</BootstrapModal.Footer>}
    </BootstrapModal>
  );
};
