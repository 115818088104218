import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';

type Props = {
  th?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
  borderTop?: boolean;
  borderBottom?: boolean;
  fixed?: boolean;
  break_?: boolean;
  fontSize?: number;
};

export const Row: React.FC<Props> = ({
  th,
  borderBottom,
  borderLeft,
  borderRight,
  borderTop,
  fixed,
  break_,
  fontSize,
  children,
}) => {
  const styles = StyleSheet.create({
    col: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      borderStyle: th ? 'solid' : 'dashed',
      borderLeftWidth: borderLeft ? 1 : 0,
      borderRightWidth: borderRight ? 1 : 0,
      borderTopWidth: borderTop ? 1 : 0,
      borderBottomWidth: borderBottom ? 1 : 0,
      borderLeftStyle: 'solid',
      borderRightStyle: 'solid',
      fontSize: fontSize === undefined ? 7 : fontSize,
      textAlign: th ? 'center' : undefined,
    },
  });

  return (
    <View style={styles.col} fixed={fixed} break={break_}>
      {children}
    </View>
  );
};
