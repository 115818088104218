import React, { useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { PaidServicePointReturnApi } from '../../../api-client';
import type {
  PaidServicePointReturnHistoryListFormResponse,
  PaidServicePointReturnHistoryListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { PaidServicePointReturnHistoryForms } from '../../organisms/Forms/PaidServicePointReturnHistoryForms';
import { PaidServicePointReturnHistoryTable } from '../../organisms/Table/PaidServicePointReturnHistoryTable';
import { createTestId } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Url } from '../../../constants/Url';
import { Title } from '../../atoms/Title';

export interface PaidServicePointReturnHistoryState {
  api: PaidServicePointReturnApi;
  isLoading: boolean;
  list: PaidServicePointReturnHistoryListOutputResponse[];
  forms: PaidServicePointReturnHistoryListFormResponse;
  errorMessage: string;
}
export type TestIds = 'search-button' | 'link-button';

export const PaidServicePointReturnHistoryPage: React.FC = () => {
  const testid = createTestId<TestIds>(PaidServicePointReturnHistoryPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<PaidServicePointReturnHistoryState>({
    api: new PaidServicePointReturnApi(),
    isLoading: false,
    list: [],
    forms: {},
    errorMessage: '',
  });

  const history = useHistory();
  const setForms = useBindSet('forms');

  const createSearchBody = useCallback((body: PaidServicePointReturnHistoryListFormResponse) => {
    return {
      applyId: body.applyId,
      customerId: body.customerId,
      createAtFrom: body.createAtFrom ? parseISO(body.createAtFrom as string).toISOString() : body.createAtFrom,
      createAtTo: body.createAtTo ? parseISO(body.createAtTo as string).toISOString() : body.createAtTo,
    };
  }, []);

  // 検索
  const onSearch = useCallback(
    (body: PaidServicePointReturnHistoryListFormResponse) => {
      if (![body.applyId, body.customerId, body.createAtFrom, body.createAtTo].some(Boolean)) {
        mergeState({ errorMessage: '応募ID、会員ID、登録日の少なくともいずれか一つを設定してください', list: [] });
        return;
      }
      const searchBody = createSearchBody(body);
      $.api
        .paidServicePointReturnHistoryList(searchBody)
        .then((res: AxiosResponse<PaidServicePointReturnHistoryListOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessage: 'エラーが発生しました。' });
          } else {
            mergeState({ list: res.data, errorMessage: '' });
          }
        })
        .catch((error: IncResultOutputResponse) => {
          mergeState({ errorMessage: error.errorMessage, list: [] });
        });
    },
    [$.api, createSearchBody, mergeState]
  );

  return (
    <>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.errorMessage.length ? <Alert variant="danger">{$.errorMessage}</Alert> : undefined}
        <Title>有料サービスポイント返還</Title>
        <Button
          variant="link"
          data-testid={testid('link-button')}
          onClick={() => {
            history.push(Url.TENSAKU.PAID_SERVICE_POINT_RETURN_REGISTER);
          }}
        >
          新規作成
        </Button>
        <Form className="mt-4">
          <Row className="g-2 mb-4">
            <Col>
              <PaidServicePointReturnHistoryForms setForms={setForms} />
              <Button
                data-testid={testid('search-button')}
                className="mt-4"
                onClick={() => {
                  onSearch($.forms);
                }}
              >
                検索
              </Button>
            </Col>
          </Row>
        </Form>
        {$.list.length ? <PaidServicePointReturnHistoryTable list={$.list} /> : undefined}
      </LoadingSpinner>
    </>
  );
};
