import React, { useState } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Modal,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Pagination,
  Popover,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import testImage from '../../../img/test/image.jpg';

/* Popoverの中身 */
const popover = (
  <Popover id="popover-basic" style={{ maxWidth: '25rem' }}>
    <Popover.Body>
      <p>
        ポップオーバー内にはJSXやコンポーネントを入れることができる。さらに
        <strong style={{ color: 'red' }}>CSS</strong>を当てることも可能。
        .popoverの初期max-widthが17.25remなので、変更する場合はPopoverコンポーネントのstyleを編集する。
      </p>
      <Button variant="primary">Primary</Button>
    </Popover.Body>
  </Popover>
);
/* Tooltipの中身 */
const tooltip = (
  <Popover id="popover-basic" style={{ maxWidth: '25rem' }}>
    <Popover.Body>
      <p>
        ツールチップ内にはJSXやコンポーネントを入れることができる。さらに
        <strong style={{ color: 'red' }}>CSS</strong>を当てることも可能。
        .popoverの初期max-widthが17.25remなので、変更する場合はPopoverコンポーネントのstyleを編集する。
      </p>
      <Button variant="primary">Primary</Button>
    </Popover.Body>
  </Popover>
);

export const SampleTopPage = () => {
  /* バリデーションエラーチェック */
  const [validated, setValidated] = useState(false);
  const handleSubmit = (event: any) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  /* 単体モーダル */
  const [singleShow, singleSetShow] = useState(false);
  const singleHandleClose = () => singleSetShow(false);
  const singleHandleShow = () => singleSetShow(true);
  /* モーダル1 */
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  /* モーダル2 */
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  /* 選択モーダル */
  const [showSelect, setShowSelect] = useState(false);
  const handleCloseSelect = () => setShowSelect(false);
  const handleShowSelect = () => setShowSelect(true);
  /* ナビゲーションボタン押下時のテスト実行関数 */
  const handleSelect = (eventKey: any) => alert(`selected ${eventKey}`);

  return (
    <>
      <Row>
        <Col>
          <h3>h3タイトル</h3>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col className="col-6">
          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Body>
                  <h4>Form</h4>
                  <Form>
                    <Form.Group className="mb-4" controlId="formId1">
                      <Form.Label>テキスト</Form.Label>
                      <Form.Control type="text" placeholder="プレースホルダー" />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formId2">
                      <Form.Label>テキスト：非活性</Form.Label>
                      <Form.Control defaultValue="テキスト非活性" disabled />
                    </Form.Group>
                    <Row className="g-2 mb-4">
                      <Col>
                        <Form.Group controlId="colForm1">
                          <Form.Label>横並び1</Form.Label>
                          <Form.Control type="text" placeholder="プレースホルダー" />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="colForm2">
                          <Form.Label>横並び2</Form.Label>
                          <Form.Control type="text" placeholder="プレースホルダー" />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <p className="mb-2">横並びラベルなし</p>
                        <Row className="g-2">
                          <Col>
                            <Form.Group controlId="labelLessColForm1">
                              <Form.Control type="text" placeholder="横並び1" />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group controlId="labelLessColForm2">
                              <Form.Control type="text" placeholder="横並び2" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Form.Group className="mb-4" controlId="formId3">
                      <Form.Label>プルダウン</Form.Label>
                      <Form.Select defaultValue="">
                        <option value="">選択してください</option>
                        <option value="1">選択肢1</option>
                        <option value="2">選択肢2</option>
                        <option value="3">選択肢3</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formId4">
                      <Form.Label>プルダウン：非活性</Form.Label>
                      <Form.Select disabled>
                        <option>プルダウン非活性</option>
                      </Form.Select>
                    </Form.Group>
                    <div className="mb-4">
                      <p className="mb-2">ラジオボタン</p>
                      <Form.Check id="radio1" className="d-flex">
                        <Form.Check.Input
                          className="flex-shrink-0 me-2"
                          type="radio"
                          name="radioGroup01"
                          defaultChecked
                        />
                        <Form.Check.Label>ラジオ1</Form.Check.Label>
                      </Form.Check>
                      <Form.Check id="radio2" className="d-flex">
                        <Form.Check.Input className="flex-shrink-0 me-2" type="radio" name="radioGroup01" />
                        <Form.Check.Label>ラジオ2</Form.Check.Label>
                      </Form.Check>
                      <Form.Check id="radio3" className="d-flex">
                        <Form.Check.Input className="flex-shrink-0 me-2" type="radio" name="radioGroup01" disabled />
                        <Form.Check.Label>ラジオ3：非活性</Form.Check.Label>
                      </Form.Check>
                    </div>
                    <div className="mb-4">
                      <p className="mb-2">ラジオボタン：インライン</p>
                      <Form.Check inline id="inlineRadio1">
                        <Form.Check.Input className="me-2" type="radio" name="inlineRadioGroup01" defaultChecked />
                        <Form.Check.Label>R1</Form.Check.Label>
                      </Form.Check>
                      <Form.Check inline id="inlineRadio2">
                        <Form.Check.Input className="me-2" type="radio" name="inlineRadioGroup01" />
                        <Form.Check.Label>R2</Form.Check.Label>
                      </Form.Check>
                      <Form.Check inline id="inlineRadio3">
                        <Form.Check.Input className="me-2" type="radio" name="inlineRadioGroup01" disabled />
                        <Form.Check.Label>R3:NA</Form.Check.Label>
                      </Form.Check>
                    </div>
                    <div className="mb-4">
                      <p className="mb-2">チェックボックス</p>
                      <Form.Check id="checkBox1" className="d-flex">
                        <Form.Check.Input
                          className="flex-shrink-0 me-2"
                          type="checkbox"
                          name="checkBoxGroup01"
                          defaultChecked
                        />
                        <Form.Check.Label>チェックボックス1</Form.Check.Label>
                      </Form.Check>
                      <Form.Check id="checkBox2" className="d-flex">
                        <Form.Check.Input className="flex-shrink-0 me-2" type="checkbox" name="checkBoxGroup01" />
                        <Form.Check.Label>チェックボックス2</Form.Check.Label>
                      </Form.Check>
                      <Form.Check id="checkBox3" className="d-flex">
                        <Form.Check.Input
                          className="flex-shrink-0 me-2"
                          type="checkbox"
                          name="checkBoxGroup01"
                          disabled
                        />
                        <Form.Check.Label>チェックボックス3：非活性</Form.Check.Label>
                      </Form.Check>
                    </div>
                    <div className="mb-4">
                      <p className="mb-2">チェックボックス：インライン</p>
                      <Form.Check inline id="inlineCheckBox1">
                        <Form.Check.Input
                          className="me-2"
                          type="checkbox"
                          name="inlineCheckBoxGroup01"
                          defaultChecked
                        />
                        <Form.Check.Label>C1</Form.Check.Label>
                      </Form.Check>
                      <Form.Check inline id="inlineCheckBox2">
                        <Form.Check.Input className="me-2" type="checkbox" name="inlineCheckBoxGroup01" />
                        <Form.Check.Label>C2</Form.Check.Label>
                      </Form.Check>
                      <Form.Check inline id="inlineCheckBox3">
                        <Form.Check.Input className="me-2" type="checkbox" name="inlineCheckBoxGroup01" disabled />
                        <Form.Check.Label>C3：NA</Form.Check.Label>
                      </Form.Check>
                    </div>
                    <div className="mb-4">
                      <p className="mb-2">トグルスイッチ</p>
                      <Form.Check type="switch" id="custom-switch" label="トグルスイッチ" defaultChecked />
                      <Form.Check disabled type="switch" label="トグルスイッチ非活性" id="disabled-custom-switch" />
                    </div>

                    <Form.Group className="mb-4" controlId="textarea1">
                      <Form.Label>テキストエリア</Form.Label>
                      <Form.Control as="textarea" rows={3} placeholder="テキストを入力" />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="textarea2">
                      <Form.Label>テキストエリア：非活性</Form.Label>
                      <Form.Control as="textarea" rows={3} defaultValue="テキストエリア非活性" disabled />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formEmail">
                      <Form.Label>メールアドレス</Form.Label>
                      <Form.Control type="email" placeholder="Enter email" />
                      <Form.Text className="text-muted">Well never share your email with anyone else.</Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formPassword">
                      <Form.Label>パスワード</Form.Label>
                      <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center" controlId="readOnly1">
                      <Form.Label className="pt-2 pe-2 pb-2 mb-0">横並びラベル1</Form.Label>
                      <Col className="col-8 p-2">
                        <Form.Control type="text" placeholder="テキスト入力" />
                      </Col>
                    </Form.Group>
                    <Form.Group className="d-flex align-items-center mb-4" controlId="readOnly2">
                      <Form.Label className="pt-2 pe-2 pb-2 mb-0">横並びラベル2</Form.Label>
                      <Col className="col-8 p-2">
                        <Form.Control type="text" defaultValue="編集不可フォーム" plaintext readOnly />
                      </Col>
                    </Form.Group>

                    <InputGroup className="mb-4">
                      <Form.Control type="text" placeholder="ボタン横並びテキスト" />
                      <Button variant="outline-primary">ボタン</Button>
                    </InputGroup>
                    <InputGroup className="mb-4">
                      <Form.Select className="flex-shrink-1" defaultValue="">
                        <option value="">選択してください</option>
                        <option value="1">選1</option>
                        <option value="2">選2</option>
                        <option value="3">選3</option>
                      </Form.Select>
                      <Form.Control className="w-50" type="text" placeholder="プルダウン横並びテキスト" />
                      <Button className="flex-shrink-1" variant="outline-primary">
                        ボタン
                      </Button>
                    </InputGroup>
                    <InputGroup className="bg-light p-2 mb-4">
                      <Form.Control type="text" placeholder="検索" defaultValue="" />
                      <Button variant="primary">
                        <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
                      </Button>
                    </InputGroup>

                    <Form.Group controlId="formFileMultiple" className="mb-4">
                      <Form.Label>Multiple files input example</Form.Label>
                      <Form.Control type="file" multiple />
                    </Form.Group>
                    <Form.Group controlId="formRange" className="mb-4">
                      <Form.Label>Range</Form.Label>
                      <Form.Range min={0} max={4} step={1} />
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Body>
                  <h4>Fontawesomeサンプル</h4>
                  <h5>使い方</h5>
                  <p>
                    <a href="https://fontawesome.com/">fontawesome Ver5公式サイト</a>
                    で利用したいアイコンを探す <br />
                    (利用できるのはFreeアイコンのみ)
                  </p>
                  <p>
                    fontawesome公式サイトでアイコンのクラス名が fa-calendar-check
                    なら、「-」をなくしてつなげた文字列をキャメルケースにすればOK。
                  </p>
                  <pre className="bg-light p-2 mb-3" style={{ whiteSpace: 'pre-wrap' }}>
                    <code>
                      import {'{'}faCalendarCheck{'}'} from &quot;@fortawesome/free-regular-svg-icons&quot;;
                    </code>
                  </pre>
                  <h6>インポート</h6>
                  <p>font種類がRegular StyleかSolid Styleか確認し、 Regularならfree-regular-svg-iconsからインポート</p>
                  <pre className="bg-light p-2 mb-3" style={{ whiteSpace: 'pre-wrap' }}>
                    <code>
                      import {'{'}faCalendarCheck{'}'} from &quot;@fortawesome/free-regular-svg-icons&quot;;
                    </code>
                  </pre>
                  <p>Solidならfree-solid-svg-iconsからインポート</p>
                  <pre className="bg-light p-2" style={{ whiteSpace: 'pre-wrap' }}>
                    <code>
                      import {'{'}faChevronLeft{'}'} from &quot;@fortawesome/free-solid-svg-icons&quot;;
                    </code>
                  </pre>
                  <h5>アイコン幅を揃える場合</h5>
                  <p>縦並びにした時にfontawesomeのアイコン幅に差が出るのを防ぐには fixedWidthプロパティを追記する</p>
                  <pre className="bg-light p-2" style={{ whiteSpace: 'pre-wrap' }}>
                    <code>
                      &lt;FontAwesomeIcon icon=
                      {'{'}faBalanceScale{'}'} fixedWidth /&gt;
                    </code>
                  </pre>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className="col-6">
          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Body>
                  <h4>Form：バリデーション</h4>
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-4" controlId="validationCustom01">
                      <Form.Label>氏名</Form.Label>
                      <Form.Control required type="text" placeholder="山田太郎" defaultValue="" />
                      <Form.Control.Feedback>入力成功</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">名前を入力してください</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="formBasicEmail01">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control required type="email" placeholder="Enter email" />
                      <Form.Text className="text-muted">Well never share your email with anyone else.</Form.Text>
                      <Form.Control.Feedback>入力成功</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">
                        メールアドレスを正しく入力してください
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-4" controlId="validationCustom03">
                      <Form.Label>年代</Form.Label>
                      <Form.Control required as="select" className="form-select">
                        <option value="">選択してください</option>
                        <option value="1">20代</option>
                        <option value="2">30代</option>
                        <option value="3">40代</option>
                      </Form.Control>
                      <Form.Control.Feedback>選択成功</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">選択してください</Form.Control.Feedback>
                    </Form.Group>

                    <div className="mb-4">
                      <p className="mb-2">性別</p>
                      <Form.Check id="radioValidation01">
                        <Form.Check.Input className="me-2" type="radio" name="radioValidateGroup01" required />
                        <Form.Check.Label>男性</Form.Check.Label>
                      </Form.Check>
                      <Form.Check id="radioValidation02">
                        <Form.Check.Input className="me-2" type="radio" name="radioValidateGroup01" required />
                        <Form.Check.Label>女性</Form.Check.Label>
                        <Form.Control.Feedback type="valid">選択成功</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">性別を選択してください</Form.Control.Feedback>
                      </Form.Check>
                    </div>
                    <div className="mb-4">
                      <p className="mb-2">好きなもの</p>
                      <Form.Check id="checkBoxValidation01">
                        <Form.Check.Input className="me-2" type="checkbox" name="checkBoxValidateGroup01" required />
                        <Form.Check.Label>グルメ</Form.Check.Label>
                      </Form.Check>
                      <Form.Check id="checkBoxValidation02">
                        <Form.Check.Input className="me-2" type="checkbox" name="checkBoxValidateGroup01" required />
                        <Form.Check.Label>美容</Form.Check.Label>
                      </Form.Check>
                      <Form.Check id="checkBoxValidation03">
                        <Form.Check.Input className="me-2" type="checkbox" name="checkBoxValidateGroup01" required />
                        <Form.Check.Label>通販</Form.Check.Label>
                        <Form.Control.Feedback type="valid">選択成功</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">好きなものを選択してください</Form.Control.Feedback>
                      </Form.Check>
                    </div>
                    <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                      <Form.Label>Example textarea</Form.Label>
                      <Form.Control required as="textarea" rows={3} />
                      <Form.Control.Feedback type="valid">入力成功</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">入力してください</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" className="mb-4">
                      Submit form
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Body>
                  <h4>InputSelect(自作コンポーネント)</h4>
                  <InputGroup className="mb-4">
                    <Form.Control type="text" placeholder="Normal text" defaultValue="クライアント１" />
                    <Button variant="outline-primary" onClick={handleShowSelect}>
                      選択
                    </Button>
                  </InputGroup>
                  {/* 選択モーダル */}
                  <Modal size="lg" show={showSelect} onHide={handleCloseSelect} backdrop="static" scrollable centered>
                    <Modal.Header closeButton />
                    <Modal.Body>
                      <Form>
                        <InputGroup className="bg-light p-2 mb-4">
                          <Form.Control type="text" placeholder="選択項目を検索" defaultValue="" />
                          <Button variant="primary" onClick={handleShowSelect}>
                            <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
                          </Button>
                        </InputGroup>
                        <Pagination>
                          <Pagination.First disabled />
                          <Pagination.Prev disabled />
                          <Pagination.Item active>{1}</Pagination.Item>
                          <Pagination.Item>{2}</Pagination.Item>
                          <Pagination.Item>{3}</Pagination.Item>
                          <Pagination.Item>{4}</Pagination.Item>
                          <Pagination.Item>{5}</Pagination.Item>
                          <Pagination.Next />
                          <Pagination.Last />
                        </Pagination>
                        <ListGroup defaultActiveKey="#link1">
                          <ListGroup.Item
                            action
                            href="#link1"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント1
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#link2"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント2
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#link3"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント3
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#link4"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント4
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#link5"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント5
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#link6"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント6
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#link7"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント7
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#link8"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント8
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#link9"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント9
                          </ListGroup.Item>
                          <ListGroup.Item
                            action
                            href="#link10"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          >
                            クライアント10
                          </ListGroup.Item>
                        </ListGroup>
                      </Form>
                    </Modal.Body>
                  </Modal>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Body>
                  <h4>Modal</h4>
                  <Button className="mb-4" variant="primary" onClick={singleHandleShow}>
                    モーダル起動
                  </Button>{' '}
                  {/* 単体モーダル */}
                  <Modal show={singleShow} onHide={singleHandleClose} backdrop="static" scrollable centered>
                    <Modal.Header closeButton>
                      <Modal.Title>単体Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                        qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                        mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                        dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                        laborum.
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={singleHandleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Button className="mb-4" variant="primary" onClick={handleShow}>
                    複数モーダル起動
                  </Button>
                  {/* モーダル1 */}
                  <Modal show={show} onHide={handleClose} backdrop="static" scrollable centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Modal1 heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum
                        dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
                        qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                        minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                        mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                        dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                        sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                        laborum.
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={handleShow2}>
                        モーダル2起動
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* モーダル2 */}
                  <Modal
                    show={show2}
                    onHide={handleClose2}
                    backdrop="static"
                    scrollable
                    centered
                    style={{ zIndex: 1070 }}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Modal2 heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, youre reading this text in a modal!</Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose2}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Body>
                  <h4>Accordion</h4>
                  <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>アコーディオン１</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>アコーディオン2</Accordion.Header>
                      <Accordion.Body>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                        laboris nisi ut aliquip ex ea commodo consequat.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Body>
                  <h4>Table</h4>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Username</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td colSpan={2}>Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Card>
                <Card.Body>
                  <h4>Pagination</h4>
                  <Pagination>
                    <Pagination.First disabled />
                    <Pagination.Prev disabled />
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{2}</Pagination.Item>
                    <Pagination.Item>{3}</Pagination.Item>
                    <Pagination.Item>{4}</Pagination.Item>
                    <Pagination.Item>{5}</Pagination.Item>
                    <Pagination.Next />
                    <Pagination.Last />
                  </Pagination>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <h4>h4タグサンプル</h4>
                  <h5>h5タグサンプル</h5>
                  <p>
                    pタグサンプル通常テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                  </p>
                  <p>
                    pタグサンプル通常テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                  </p>
                  <h6>h6タグサンプル</h6>
                  <p>
                    pタグサンプル通常テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <h4>ListGroup</h4>
              <Row className="g-4">
                <Col>
                  <ListGroup variant="flush">
                    <ListGroup.Item>罫線なしリスト</ListGroup.Item>
                    <ListGroup.Item>罫線なしリスト</ListGroup.Item>
                    <ListGroup.Item>罫線なしリスト</ListGroup.Item>
                    <ListGroup.Item>罫線なしリスト</ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup>
                    <ListGroup.Item>罫線ありリスト</ListGroup.Item>
                    <ListGroup.Item>罫線ありリスト</ListGroup.Item>
                    <ListGroup.Item>罫線ありリスト</ListGroup.Item>
                    <ListGroup.Item>罫線ありリスト </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col>
                  <ListGroup defaultActiveKey="#link1">
                    <ListGroup.Item
                      action
                      href="#link1"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      罫線あり+クリック可リスト
                    </ListGroup.Item>
                    <ListGroup.Item
                      action
                      href="#link2"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      罫線あり+クリック可リスト
                    </ListGroup.Item>
                    <ListGroup.Item
                      action
                      href="#link3"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      罫線あり+クリック可リスト
                    </ListGroup.Item>
                    <ListGroup.Item
                      action
                      href="#link4"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      罫線あり+クリック可リスト
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <h4>imgタグサンプル</h4>
              <Row className="g-2">
                <Col>
                  <img className="w-100" src={testImage} alt="テスト画像" />
                </Col>
                <Col>
                  <img className="w-100" src={testImage} alt="テスト画像" />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <h4>Button</h4>
              <div className="mb-4">
                <Button variant="primary">Primary</Button> <Button variant="secondary">Secondary</Button>{' '}
                <Button variant="success">Success</Button> <Button variant="warning">Warning</Button>{' '}
                <Button variant="danger">Danger</Button> <Button variant="info">Info</Button>{' '}
                <Button variant="light">Light</Button> <Button variant="dark">Dark</Button>{' '}
                <Button variant="link">Link</Button>
              </div>

              <div className="mb-4">
                <h5>アウトライン</h5>
                <Button variant="outline-primary">Primary</Button>{' '}
                <Button variant="outline-secondary">Secondary</Button>{' '}
                <Button variant="outline-success">Success</Button> <Button variant="outline-warning">Warning</Button>{' '}
                <Button variant="outline-danger">Danger</Button> <Button variant="outline-info">Info</Button>{' '}
                <Button variant="outline-light">Light</Button> <Button variant="outline-dark">Dark</Button>
              </div>
              <div className="mb-4">
                <h5>button/input/href</h5>
                <Button href="#">Link</Button> <Button type="submit">Button：Submit</Button>{' '}
                <Button as="input" type="button" value="Input：Button" />{' '}
                <Button as="input" type="submit" value="Input：Submit" />{' '}
                <Button as="input" type="reset" value="Input：Reset" />
              </div>
              <div className="mb-4">
                <h5>サイズ別</h5>
                <Button variant="primary" size="lg">
                  大きいボタン
                </Button>{' '}
                <Button variant="primary" size="sm">
                  小さいボタン
                </Button>
              </div>
              <div className="d-grid gap-2 mb-4">
                <h5>全幅</h5>
                <Button variant="primary">全幅ボタン</Button>
              </div>
              <div>
                <h5>非活性</h5>
                <Button variant="primary" className="mb-4" disabled>
                  非活性ボタン
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <h4>Badge（タグとしても利用可）</h4>
              <div className="d-inline-flex align-items-center mb-4 me-3">
                <span>テキストにバッジを追加</span>
                <Badge bg="danger" className="ms-1">
                  New
                </Badge>
              </div>
              <Button className="mb-4" variant="outline-primary">
                ボタンにバッジを追加{' '}
                <Badge pill bg="danger">
                  9
                </Badge>
                <span className="visually-hidden">unread messages</span>
              </Button>
              <div className="mb-4">
                <Badge bg="primary">Primary</Badge> <Badge bg="secondary">Secondary</Badge>{' '}
                <Badge bg="success">Success</Badge> <Badge bg="danger">Danger</Badge>{' '}
                <Badge bg="warning" text="dark">
                  Warning
                </Badge>{' '}
                <Badge bg="info">Info</Badge>{' '}
                <Badge bg="light" text="dark">
                  Light
                </Badge>{' '}
                <Badge bg="dark">Dark</Badge>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <h4>Tab</h4>
              <h5>Tab：通常デザイン</h5>
              <div className="mb-4">
                <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mb-2">
                  <Tab eventKey="1" title="Tab1" style={{ height: '4rem', overflowY: 'auto' }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Tab>
                  <Tab eventKey="2" title="Tab2" style={{ height: '4rem', overflowY: 'auto' }}>
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                    commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                  </Tab>
                  <Tab eventKey="3" title="Tab3：非活性" style={{ height: '4rem', overflowY: 'auto' }} disabled>
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                    ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                    nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </Tab>
                </Tabs>
              </div>
              <div className="mb-4">
                <h5>Tab：pillデザイン</h5>
                <Tab.Container id="tabs-pills-example" defaultActiveKey="1">
                  <Nav variant="pills" className="mb-2">
                    <Nav.Item>
                      <Nav.Link eventKey="1">Tab1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="2">Tab2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="3" disabled>
                        Tab3：非活性
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="1" style={{ height: '4rem', overflowY: 'auto' }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                      nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    </Tab.Pane>
                    <Tab.Pane eventKey="2" style={{ height: '4rem', overflowY: 'auto' }}>
                      sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                      nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                      commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                    </Tab.Pane>
                    <Tab.Pane eventKey="3" style={{ height: '4rem', overflowY: 'auto' }}>
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                      consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                      incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur
                      adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
              <div className="mb-4">
                <h5>Tab：縦並びpillデザイン</h5>
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <Row className="g-3">
                    <Col className="col-3">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="1">縦並びTab1</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="2">縦並びTab2</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="3" disabled>
                            縦並びTab3：非活性
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col>
                      <Tab.Content>
                        <Tab.Pane eventKey="1" style={{ height: '7rem', overflowY: 'auto' }}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                          labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                          laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur
                          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                          minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                          consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                          nisi ut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud exercitation
                          ullamco laboris nisi ut aliquip ex ea commodo consequat. Ut enim ad minim veniam, quis nostrud
                          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        </Tab.Pane>
                        <Tab.Pane eventKey="2" style={{ height: '7rem', overflowY: 'auto' }}>
                          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum
                          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                          ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit, Lorem ipsum dolor sit amet, consectetur adipiscing elit, Lorem
                          ipsum dolor sit amet, consectetur adipiscing elit,
                        </Tab.Pane>
                        <Tab.Pane eventKey="3" style={{ height: '7rem', overflowY: 'auto' }}>
                          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                          exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit
                          amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                          aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore
                          et dolore magna aliqua. sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Body>
              <h4>Nav</h4>
              <Nav variant="pills" className="mb-4" activeKey="1" onSelect={handleSelect}>
                <Nav.Item>
                  <Nav.Link eventKey="1" href="#/home">
                    NavLink 1 content
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2" title="Item">
                    NavLink 2 content
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="3" disabled>
                    NavLink 3 content
                  </Nav.Link>
                </Nav.Item>
                <NavDropdown title="Dropdown" id="nav-dropdown">
                  <NavDropdown.Header>Dropdown header1</NavDropdown.Header>
                  <NavDropdown.Item eventKey="4.1">Action</NavDropdown.Item>
                  <NavDropdown.Item eventKey="4.2">Another action</NavDropdown.Item>
                  <NavDropdown.Item eventKey="4.3">Something else here</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Header>Dropdown header2</NavDropdown.Header>
                  <NavDropdown.Item eventKey="4.4">Separated link</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="me-2">
            <Card.Body>
              <h4>Popover</h4>
              <OverlayTrigger trigger="click" placement="auto" overlay={popover}>
                <Button variant="primary" className="mb-4">
                  Popover起動
                </Button>
              </OverlayTrigger>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <h4>Tooltip</h4>
              <OverlayTrigger trigger="hover" placement="auto" overlay={tooltip}>
                <Button variant="primary" className="mb-4">
                  マウスホバーでTooltip起動
                </Button>
              </OverlayTrigger>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
