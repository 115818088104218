import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import sass from '../../../scss/templates/CorrectionTemplate.module.scss';
import { formatISODate } from '../../../utils/functions';
import { VerticalTable } from '../../molecules/VerticalTable';
import { OriginalLink } from '../../atoms/OriginalLink';
import { Url } from '../../../constants/Url';
import { TensakuApplyInfoOutputResponse } from '../../../api-client';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { ROLE_NAME } from '../../../RoleConstants';

interface Props {
  applyInfo: TensakuApplyInfoOutputResponse | undefined;
}

// 金額修正の応募情報コンポーネント
export const ReceiptPriceApplyInfo: React.FC<Props> = ({ applyInfo }) => {
  const { currentUser } = useCurrentUser();
  const createVerticalBody = () => {
    let result = {
      店舗ID: [applyInfo?.shopId],
      応募ID: [applyInfo?.applyId],
      謝礼条件: [applyInfo?.rewardWord],
      モラル: [applyInfo?.moralName],
      完了数: [applyInfo?.completeCount],
      調査証明NG数: [applyInfo?.surveyProofNgCount],
      アンケートNG数: [applyInfo?.answerNgCount],
    };
    if (currentUser?.role === ROLE_NAME.QC_CREW) {
      result = {
        ...result,
        ...{
          会員ID: [applyInfo?.customerId],
        },
      };
    } else {
      result = {
        ...result,
        ...{
          会員ID: [
            <OriginalLink
              url={`${Url.TENSAKU.CUSTOMER_INFO}/${applyInfo?.customerId}`}
              anotherTag
              title={applyInfo?.customerId.toString()}
            />,
          ],
        },
      };
    }

    if (applyInfo?.monitorBudget) {
      result = {
        ...result,
        ...{
          モニター予算: [applyInfo?.monitorBudget],
        },
      };
    }
    if (applyInfo?.shopBudget) {
      result = {
        ...result,
        ...{
          '店舗の平均予算(一人当たり)': [applyInfo?.shopBudget],
        },
      };
    }

    result = {
      ...result,
      ...{
        ホームページ: [
          <a href={applyInfo?.homePage} target="_blank" rel="noreferrer">
            {applyInfo?.homePage}
            <FontAwesomeIcon icon={faWindowRestore} className="ms-1" />
          </a>,
        ],
        クライアント名: [applyInfo?.client],
        購入番号ルール: [applyInfo?.purchaseNoRule],
        サンプル: [applyInfo?.sample],
        営業担当ID: [applyInfo?.salesIncId],
        営業担当者名: [applyInfo?.salesIncName],
        ファンくる店舗ページURL: [
          <OriginalLink
            url={applyInfo?.fancrewShopPageUrl || 'https://www.fancrew.co.jp'}
            anotherTag
            title={applyInfo?.fancrewShopPageUrl}
          />,
        ],
      },
    };

    return result;
  };
  return (
    <>
      <h3>応募情報</h3>
      <div className={sass.correction_appinfo}>
        <p>{applyInfo?.categoryName}</p>
        <h5>店舗名</h5>
        <p>{applyInfo?.shopName}</p>
        <h5>モニターベース名</h5>
        <p>{applyInfo?.monitorBaseName}</p>
        <p>{applyInfo?.businessCategoryName}</p>
        <h5>住所</h5>
        <p>{applyInfo?.address}</p>
        <h5>当選日時</h5>
        <p>{applyInfo?.winningAt && formatISODate(applyInfo.winningAt, 'yyyy年MM月dd日 HH時mm分')}</p>
      </div>
      <hr />
      <VerticalTable isPlainTable className={`${sass.correction_table} w-100`} body={createVerticalBody()} />
    </>
  );
};
