import React from 'react';
// TODO 後ほど必ず確認すること
// import './Loading.css';
const Loading: React.FC = () => {
  return (
    <div className="loader">
      <div className="loader_inner">
        <div className="loader_line_wrap">
          <div className="loader_line" />
        </div>
        <div className="loader_line_wrap">
          <div className="loader_line" />
        </div>
        <div className="loader_line_wrap">
          <div className="loader_line" />
        </div>
        <div className="loader_line_wrap">
          <div className="loader_line" />
        </div>
        <div className="loader_line_wrap">
          <div className="loader_line" />
        </div>
      </div>
    </div>
  );
};

export default Loading;
