import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  IncResultOutputResponse,
  MailTemplateDetailInfoApi,
  MailTemplateDetailInfoOutputResponse,
  MailTemplateUpdateApi,
  MailTemplateUpdateFormResponse,
} from '../../../api-client';
import { Title } from '../../atoms/Title';

export const MailTemplateModifyPage = () => {
  const location = useLocation<{ id: number }>();

  const infoApi = new MailTemplateDetailInfoApi();
  const updApi = new MailTemplateUpdateApi();

  const [data, setData] = useState<MailTemplateDetailInfoOutputResponse>({
    type: '',
    title: '',
    content: '',
    key: '',
    disableFlg: true,
    footerFlg: false,
  });

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();

  const { templateId } = useParams<{ templateId: string | undefined }>();

  useEffect(() => {
    // パラメータが渡されてきていなければInfoAPI叩かない
    if (templateId != null) {
      infoApi
        .mailTemplateDetailInfo(Number(templateId))
        .then((res: AxiosResponse<MailTemplateDetailInfoOutputResponse>) => {
          setData(res.data);
        });
    }
  }, []);

  // 保存イベント
  const submit = () => {
    const param: MailTemplateUpdateFormResponse = {
      id: templateId === undefined ? undefined : Number(templateId),
      type: data.type,
      title: data.title,
      content: data.content,
      disableFlg: false,
      footerFlg: false,
      key: data.key,
    };
    updApi.mailTemplateUpdate(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(res.data);
    });
  };

  return (
    <>
      <Title className="mb-4">
        {location.state === undefined ? 'メールテンプレート編集画面' : 'メールテンプレート詳細画面'}
      </Title>
      <div className="d-flex justify-content-end mb-4">
        <Button type="button" onClick={submit} className="ms-2">
          保存
        </Button>
      </div>
      {updResult?.result && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          更新しました。
        </Alert>
      )}
      {updResult !== undefined && updResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}
      <Form.Group className="mb-4">
        <Form.Label>キー</Form.Label>
        <Form.Control
          value={data.key}
          onChange={(e) => {
            setData({ ...data, key: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>メールタイプ</Form.Label>
        <Form.Control
          value={data.type}
          onChange={(e) => {
            setData({ ...data, type: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>タイトル</Form.Label>
        <Form.Control
          value={data.title}
          onChange={(e) => {
            setData({ ...data, title: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>メール内容</Form.Label>
        <Form.Control
          as="textarea"
          value={data.content}
          onChange={(e) => {
            setData({ ...data, content: e.target.value });
          }}
          rows={15}
        />
      </Form.Group>
    </>
  );
};
