import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { getuid } from 'process';
import { Url } from '../../constants/Url';
import { CustomerInfoApi, OemScodeCreateApi } from '../../api-client';
import { isOemUser } from '../../utils/functions';
import { OEM_API_VERSION } from '../../Constants';

export type TestIds = 'table' | 'link-button' | 'icon-button';
interface Props {
  customerId: number;
  apiClientId?: number;
}

export const AgentLogin: React.FC<Props> = React.memo(({ customerId, apiClientId }) => {
  const [url, setUrl] = useState('');
  useEffect(() => {
    if (isOemUser(apiClientId)) {
      const oemSCodeCreateApi = new OemScodeCreateApi();
      oemSCodeCreateApi.incOemScodeCreate(OEM_API_VERSION, customerId, apiClientId).then((res) => {
        setUrl(`${Url.TOC_OEM.PROGRESS}?sCode=${res.data.scode}`);
      });
    } else {
      new CustomerInfoApi().agentLoginDisplay(customerId).then((res) => {
        setUrl(res.data.url);
      });
    }
  }, []);

  const tooltipText = useMemo(
    () => (
      <Popover id="button-tooltip" style={{ maxWidth: '7.5rem' }}>
        成り替わりログイン
      </Popover>
    ),
    []
  );

  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltipText}>
      <a href={url} target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={faSignInAlt} />
      </a>
    </OverlayTrigger>
  );
});
