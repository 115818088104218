import { pdf } from '@react-pdf/renderer';
import { BillingApi } from '../../api-client';
import { DispatchedDetailInvoice } from '../../components/organisms/DispatchedDetailIIncoice';
import { ShopDetailInvoice } from '../../components/organisms/ShopDetailInvoice';
import { ItemDetailInvoice } from '../../components/organisms/ItemDetailInvoice';
import { SurveyDetailInvoice } from '../../components/organisms/SurveyDetailInvoice';
import { calcFee } from '../../utils/functionsForBilling';

export interface DownloadPdf {
  item: Blob;
  shop: Blob;
  monitor: Blob;
  apply: Blob;
}
// 請求書明細ダウンロード(PDF)
// FIXME downloadInvoicePdfの処理だとアップロードしたPDFのうち一番最後のPDF以外の日本語が文字化けしてしまう。ほんとはそっち使いたい。
export const downloadInvoicePdfForUpload = async (billingId: number) => {
  const billingApi = new BillingApi();

  const createItemPdf = async () => {
    const res = await billingApi.billingInvoiceDetailItemForPdf(billingId);

    const pdfLayout = ItemDetailInvoice({
      headerData: res.data.billingInvoiceDetailItemData,
      billingInvoiceItem: res.data.billingInvoiceDetailItemData.billingInvoiceItem,
    });
    return pdf(pdfLayout).toBlob();
  };

  const createShopPdf = async () => {
    const res = await billingApi.billingInvoiceStoreDetailItem(billingId);

    let totalReport = 0;
    let totalUserAmount = 0;
    let totalSpot = 0;
    let totalStock = 0;
    let totalOption = 0;
    let totalRewardFee = 0;
    let totalFee = 0;
    let totalOthers = 0;
    let totalAmount = 0;

    res.data.billingInvoiceStoreDetailItemRowDataOutputList.forEach((value) => {
      totalReport += Number(value.reportCount);
      totalUserAmount += Number(value.userAmount);
      totalSpot += Number(value.spot);
      totalStock += Number(value.stock);
      totalOption += Number(value.imageOption);
      totalRewardFee += Number(value.billingRewardFee);
      totalFee += Number(value.billingFee);
      totalOthers += Number(value.others);
      totalAmount += Number(value.amount);
    });

    const pdfLayout = ShopDetailInvoice({
      lists: res.data.billingInvoiceStoreDetailItemRowDataOutputList,
      headers: res.data.billingInvoiceHeaderInfoForPdfOutput,
      tableHeaders: res.data.billingInvoiceStoreDetailItemHeaderForPdfOutput,
      totals: {
        reportCount: totalReport,
        userAmount: totalUserAmount,
        spot: totalSpot,
        stock: totalStock,
        imageOption: totalOption,
        billingRewardFee: totalRewardFee,
        billingFee: totalFee,
        others: totalOthers,
        amount: totalAmount,
      },
      nontaxAmountPerTaxRate: res.data.billingInvoiceStoreDetailItemAmountForPdfOutputList,
    });
    return pdf(pdfLayout).toBlob();
  };

  const createMonitorPdf = async () => {
    const res = await billingApi.billingInvoiceTotalingDetailItemForPdf(billingId);

    let totalReport = 0;
    let totalUserAmount = 0;
    let totalSpot = 0;
    let totalStock = 0;
    let totalOption = 0;
    let totalRewardFee = 0;
    let totalFee = 0;
    let totalOthers = 0;
    let totalAmount = 0;

    res.data.billingInvoiceTotalingDetailItemForPdfRowDataOutput.forEach((value) => {
      totalReport += Number(value.reportCount);
      totalUserAmount += Number(value.userAmount);
      totalSpot += Number(value.spot);
      totalStock += Number(value.stock);
      totalOption += Number(value.imageOption);
      totalRewardFee += Number(value.billingRewardFee);
      totalFee += Number(value.billingFee);
      totalOthers += Number(value.others);
      totalAmount += Number(value.amount);
    });

    const pdfLayout = SurveyDetailInvoice({
      lists: res.data.billingInvoiceTotalingDetailItemForPdfRowDataOutput,
      headers: res.data.billingInvoiceHeaderInfoForPdfOutput,
      tableHeaders: res.data.billingInvoiceTotalingDetailItemHeaderForPdfRowDataOutput,
      totals: {
        reportCount: totalReport,
        userAmount: totalUserAmount,
        spot: totalSpot,
        stock: totalStock,
        imageOption: totalOption,
        billingRewardFee: totalRewardFee,
        billingFee: totalFee,
        others: totalOthers,
        amount: totalAmount,
      },
    });
    return pdf(pdfLayout).toBlob();
  };

  const createApplyPdf = async () => {
    const res = await billingApi.billingInvoiceDetailApplicationForPdf(billingId);

    let totalAmount = 0;
    let totalRewardFee = 0;
    let totalFee = 0;
    res.data.billingInvoiceDetailApplicationSearchOutputList.forEach((value) => {
      totalAmount += value.customerAmount;
      totalRewardFee += calcFee(
        value.billingRewardFeeType,
        value.customerAmount,
        value.billingRewardFeeValue,
        value.billingRewardFeeLower,
        value.billingRewardFeeUpper
      );
      totalFee += calcFee(
        value.billingFeeType,
        value.customerAmount,
        value.billingFeeValue,
        value.billingFeeLower,
        value.billingFeeUpper
      );
    });

    const pdfLayout = DispatchedDetailInvoice({
      lists: res.data,
      totals: {
        amount: totalAmount,
        rewardFee: totalRewardFee,
        fee: totalFee,
      },
    });
    return pdf(pdfLayout).toBlob();
  };

  // メインの処理
  const itemPdf = await createItemPdf();
  const shopPdf = await createShopPdf();
  const monitorPdf = await createMonitorPdf();
  const applyPdf = await createApplyPdf();

  const result: DownloadPdf = {
    item: itemPdf,
    shop: shopPdf,
    monitor: monitorPdf,
    apply: applyPdf,
  };
  return result;
};
