import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import Select from 'react-select';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { BillingAgentApi, BillingAgentListOutputResponse } from '../../../api-client';
import useBillingSuggestClient from '../../../hooks/useBillingSuggestClient';
import useBillingSuggestIncAccount from '../../../hooks/useBillingSuggestIncAccount';
import useBillingSuggestContractShop from '../../../hooks/useBillingSuggestContractShop';
import useBillingSuggestAgent from '../../../hooks/useBillingSuggestAgent';
import { Url } from '../../../constants/Url';

interface SearchConditions {
  id?: number;
  incAccountId?: number;
  clientId?: number;
  contractShopId?: number;
}

export const BillingAgentListPage: React.VFC = () => {
  const [list, setList] = useState<BillingAgentListOutputResponse[]>([]);

  const billingSuggestClientResult = useBillingSuggestClient();
  const billingSuggestIncAccountResult = useBillingSuggestIncAccount();
  const billingSuggestContractShopResult = useBillingSuggestContractShop();
  const billingSuggestAgentResult = useBillingSuggestAgent();

  const billingAgentApi = new BillingAgentApi();

  const history = useHistory();

  const fetchData = (data: SearchConditions) => {
    billingAgentApi
      .billingagentList(data.id, data.incAccountId, data.clientId, data.contractShopId)
      .then((response: AxiosResponse<BillingAgentListOutputResponse[]>) => {
        setList(response.data);
      });
  };

  useEffect(() => {
    fetchData({
      id: undefined,
      incAccountId: undefined,
      clientId: undefined,
      contractShopId: undefined,
    } as SearchConditions);
  }, []);

  const onSubmit: SubmitHandler<SearchConditions> = (data) => {
    billingAgentApi
      .billingagentList(data.id, data.incAccountId, data.clientId, data.contractShopId)
      .then((response: AxiosResponse<BillingAgentListOutputResponse[]>) => {
        setList(response.data);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<SearchConditions>({
    defaultValues: {
      id: undefined,
      incAccountId: undefined,
      clientId: undefined,
      contractShopId: undefined,
    },
  });
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="m-2">検索</Row>
        <Row>
          <Col className="col-6">
            <Form.Group className="d-flex flex-column mb-4" controlId="distributor">
              <Form.Label className="pt-2 pe-4 pb-2 mb-0 text-nowrap">代理店</Form.Label>
              {billingSuggestAgentResult.isSuccess && (
                <Select
                  isSearchable
                  isClearable
                  {...register('id')}
                  options={billingSuggestAgentResult.data}
                  onChange={(e) => setValue('id', e?.value)}
                />
              )}
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group className="d-flex flex-column mb-4" controlId="manager">
              <Form.Label className="pt-2 pe-4 pb-2 mb-0 text-nowrap">担当者</Form.Label>
              {billingSuggestIncAccountResult.isSuccess && (
                <Select
                  isSearchable
                  isClearable
                  {...register('incAccountId')}
                  options={billingSuggestIncAccountResult.data}
                  onChange={(e) => {
                    setValue('incAccountId', e?.value);
                  }}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="col-6">
            <Form.Group className="d-flex flex-column mb-4" controlId="clientId">
              <Form.Label className="pt-2 pe-4 pb-2 mb-0 text-nowrap">クライアント</Form.Label>
              {billingSuggestClientResult.isSuccess && (
                <Select
                  isSearchable
                  isClearable
                  {...register('clientId')}
                  options={billingSuggestClientResult.data}
                  onChange={(e) => {
                    setValue('clientId', e?.value);
                  }}
                />
              )}
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group className="d-flex flex-column mb-4" controlId="contractShopId">
              <Form.Label className="pt-2 pe-4 pb-2 mb-0 text-nowrap">契約店舗</Form.Label>
              {billingSuggestContractShopResult.isSuccess && (
                <Select
                  isSearchable
                  isClearable
                  {...register('contractShopId')}
                  options={billingSuggestContractShopResult.data}
                  onChange={(e) => {
                    setValue('contractShopId', e?.value);
                  }}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-center m-2">
          <Button variant="secondary" style={{ margin: '20px' }} type="submit">
            検索
          </Button>
          <a href={`${Url.BILLING.BILLING_AGENT_DETAIL}/new`} target="_blank" rel="noreferrer">
            <Button variant="secondary" style={{ margin: '20px' }} type="button">
              新規作成
            </Button>
          </a>
        </div>
      </Form>
      <Table>
        <thead>
          <tr>
            <th>代理店Id</th>
            <th>代理店</th>
            <th>担当者名</th>
            <th>無効/有効</th>
            <th>詳細</th>
            <th>モニターベース紐づけ</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item: BillingAgentListOutputResponse, idx: number) => {
            return (
              <tr key={idx}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.incAccountName}</td>
                <td>{item.disableFlg ? '無効' : '有効'}</td>
                <td>
                  <Button
                    variant="link"
                    className="text-primary"
                    onClick={() => {
                      history.push(`${Url.BILLING.BILLING_AGENT_DETAIL}/${item.id}`);
                    }}
                  >
                    編集
                  </Button>
                </td>
                <td>
                  <Button
                    variant="link"
                    className="text-primary"
                    onClick={() => {
                      history.push(`${Url.BILLING.BILLING_AGENT_MONITOR_BASE_LIST}/${item.id}`);
                    }}
                  >
                    編集
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
