import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { DispatchSetState } from '../../../interfaces/utils';
import { createTestId } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import type { GiftTicketPageState } from '../../pages/GiftTicketPage';
import { GiftTicketEditForms, Props as EditFormsProps } from '../Forms/GiftTicketEditForms';

type Props = {
  disableSubmit: boolean;
  isModal: GiftTicketPageState['isModal'];
  setModal: DispatchSetState<GiftTicketPageState['isModal']>;
  onSubmit: (body: GiftTicketPageState['editForms']) => void;
} & EditFormsProps;
export type TestIds = 'form' | 'submit';

export const GiftTicketEditModal: React.FC<Props> = ({ disableSubmit, isModal, setModal, onSubmit, ...rest }) => {
  const testid = createTestId<TestIds>(GiftTicketEditModal);
  const hideModal = () => setModal(false);
  return (
    <Modal
      closeButton
      centered
      scrollable
      isModal={isModal}
      onHide={hideModal}
      title="ギフト編集"
      size="lg"
      body={
        <>
          <Form data-testid={testid('form')}>
            <Row className="g-2 mb-4">
              <Col>
                <GiftTicketEditForms {...rest} />
              </Col>
            </Row>
          </Form>
          <Button data-testid={testid('submit')} onClick={() => onSubmit(rest.forms)} disabled={disableSubmit}>
            更新
          </Button>
        </>
      }
    />
  );
};
