import { Table } from 'react-bootstrap';
import React from 'react';
import { formatISODate } from '../../../utils/functions';
import { CorrectionHistoryDisplayOutputResponse } from '../../../api-client';
import { DATE_DISPLAY_FORMAT, HOUR_MINUTE } from '../../../Constants';

export interface Props {
  correctionHistoryList: CorrectionHistoryDisplayOutputResponse[] | undefined;
  correctionHistoryStyle: string;
  correctionHistoryDateStyle: string;
}

export const CorrectionHistoryTable: React.VFC<Props> = ({
  correctionHistoryList,
  correctionHistoryStyle,
  correctionHistoryDateStyle,
}) => {
  return (
    <Table>
      <thead>
        <tr className={correctionHistoryStyle}>
          <th>日時</th>
          <th>実行者</th>
          <th>添削対象</th>
          <th>操作</th>
        </tr>
      </thead>
      {correctionHistoryList?.map((correctionHistory) => (
        <tbody>
          <tr className={correctionHistoryStyle}>
            <td className={correctionHistoryDateStyle}>
              {formatISODate(correctionHistory.executeDateTime, `${DATE_DISPLAY_FORMAT} ${HOUR_MINUTE}`)}
            </td>
            <td>{correctionHistory.correctionPersonName}</td>
            <td>{correctionHistory.correctionTargetName}</td>
            <td>{correctionHistory.action}</td>
          </tr>
        </tbody>
      ))}
    </Table>
  );
};
