import React, { useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { MailBulkDeliveryApi } from '../../../api-client';
import type {
  MailBulkDeliveryListFormResponse,
  MailBulkDeliveryListOutputResponse,
  MailBulkDeliverySubListOutputResponse,
  InlineObjectResponse,
  PageableResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { MailBulkDeliveryListForms } from '../../organisms/Forms/MailBulkDeliveryListForms';
import { MailBulkDeliveryListTable } from '../../organisms/Table/MailBulkDeliveryListTable';
import { createTestId } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Url } from '../../../constants/Url';
import { Title } from '../../atoms/Title';

export interface MailBulkDeliveryListState {
  api: MailBulkDeliveryApi;
  isLoading: boolean;
  forms: MailBulkDeliveryListFormResponse;
  list: MailBulkDeliverySubListOutputResponse[];
  errorMessage: string[];
}
export type TestIds = 'search-button' | 'link-button';

export const MailBulkDeliveryListPage: React.FC = () => {
  const testid = createTestId<TestIds>(MailBulkDeliveryListPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<MailBulkDeliveryListState>({
    api: new MailBulkDeliveryApi(),
    isLoading: false,
    forms: { oemType: -1 },
    list: [],
    errorMessage: [],
  });

  const history = useHistory();
  const setForms = useBindSet('forms');

  const createSearchBody = useCallback((body: MailBulkDeliveryListFormResponse) => {
    const pageable: PageableResponse = { page: 0, size: 20 };
    const form: MailBulkDeliveryListFormResponse = {
      applyId: body.applyId,
      customerId: body.customerId,
      content: body.content,
      mailAddress: body.mailAddress,
      oemType: Number(body.oemType) !== -1 ? Number(body.oemType) : undefined,
      title: body.title,
    };
    return { form, pageable };
  }, []);

  // 検索
  const onSearch = useCallback(
    (body: MailBulkDeliveryListFormResponse) => {
      if (
        ![body.applyId, body.customerId, body.content, body.mailAddress, body.title].some(Boolean) &&
        Number(body.oemType) === -1
      ) {
        mergeState({ errorMessage: ['検索条件のうち少なくとも一つを設定してください'], list: [] });
        return;
      }
      const searchBody: InlineObjectResponse = createSearchBody(body);

      $.api
        .mailBulkDeliveryList(searchBody)
        .then((res: AxiosResponse<MailBulkDeliveryListOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessage: ['エラーが発生しました。'] });
          } else {
            mergeState({ list: res.data.list, errorMessage: [] });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
    },
    [$.api, createSearchBody, mergeState]
  );

  return (
    <>
      <Title>メール一括送信履歴</Title>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.errorMessage.length
          ? $.errorMessage.map((errorMessage) => (
              <Alert variant="danger" key={errorMessage}>
                {errorMessage}
              </Alert>
            ))
          : undefined}
        <Button
          variant="link"
          data-testid={testid('link-button')}
          onClick={() => {
            history.push(`${Url.TENSAKU.MAIL_BULK_DELIVERY_DETAIL}/0`);
          }}
        >
          新規作成
        </Button>
        <Form className="mt-4">
          <Row className="g-2 mb-4">
            <Col>
              <MailBulkDeliveryListForms setForms={setForms} />
              <Button
                data-testid={testid('search-button')}
                className="mt-4"
                onClick={() => {
                  onSearch($.forms);
                }}
              >
                検索
              </Button>
            </Col>
          </Row>
        </Form>
        {$.list.length ? <MailBulkDeliveryListTable dataList={$.list} /> : undefined}
      </LoadingSpinner>
    </>
  );
};
