import React from 'react';
import { Accordion } from 'react-bootstrap';

import { createTestId } from '../../../utils/functions';

export type TestIds = 'accordion-head' | 'accordion-body';

export const CustomerDetailOemAccordion: React.FC = () => {
  const testid = createTestId<TestIds>(CustomerDetailOemAccordion);

  return (
    <Accordion flush className="col-md-12">
      <Accordion.Item eventKey="0">
        <Accordion.Header data-testid={testid('accordion-head')}>非表示項目</Accordion.Header>
        <Accordion.Body data-testid={testid('accordion-body')}>
          <div className="d-flex justify-content-between">
            <div>
              <h6>会員情報</h6>
              <small>姓名</small>
              <br />
              <small>郵便番号</small>
              <br />
              <small>電話番号</small>
              <br />
              <small>口座番号</small>
              <br />
              <small>秘密の質問/答え</small>
            </div>
            <div>
              <h6>メール</h6>
              <small>メールアドレス</small>
              <br />
              <small>メルマガ配信</small>
              <br />
              <small>メール不達ステータス</small>
              <br />
              <small>メール送信失敗回数</small>
            </div>
            <div>
              <h6>会員ステータス</h6>
              <small>ポイント交換ステータス</small>
              <br />
              <small>保有ポイント</small>
            </div>
            <div>
              <h6>ソーシャルログイン</h6>
              <small>ソーシャルログイン先</small>
              <br />
              <small>ソーシャルログインアカウントID</small>
              <br />
              <small>流入元</small>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
