import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { CampaignApi, RecommendApiClientListOutputResponse, IncResultOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Alert } from '../../atoms/Alert';
import { Button } from '../../atoms/Button';
import { OemTable } from '../../organisms/Table/OemTable';
import { AllList } from '../Brand/BrandOemPage';

const sampleGetLists = {
  oemList: [
    {
      apiClientId: 1,
      apiClientName: 'OEM1',
    },
    {
      apiClientId: 2,
      apiClientName: 'OEM2',
    },
    {
      apiClientId: 3,
      apiClientName: 'OEM3',
    },
  ],
  searchOemList: [
    {
      apiClientId: 4,
      apiClientName: 'OEM4',
    },
    {
      apiClientId: 5,
      apiClientName: 'OEM5',
    },
    {
      apiClientId: 6,
      apiClientName: 'OEM6',
    },
  ],
} as RecommendApiClientListOutputResponse;

export const CampaignOemPage: React.VFC = () => {
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>({
    result: false,
    errorCode: '',
    errorMessage: '',
  });

  const { register, handleSubmit, control, reset, getValues } = useForm<AllList>({
    defaultValues: { allList: [] },
  });
  const { fields } = useFieldArray({ control, name: 'allList' });

  const history = useHistory();
  const campaignApi = new CampaignApi();

  useEffect(() => {
    campaignApi.campaignShowApiclientList().then((res: AxiosResponse<RecommendApiClientListOutputResponse[]>) => {
      // const data = sampleGetLists;
      const data = res.data[0];
      const oemList = data.oemList.map((item) => {
        return { ...item, checked: true };
      });
      const searchOemList = data.searchOemList.map((item) => {
        return { ...item, checked: false };
      });

      reset({ allList: [...oemList, ...searchOemList] });
    });
  }, []);

  const handleClickConfirm = () => {
    window.history.pushState(null, '', Url.KEISAI.CAMPAIGN_OEM_CONFIRM);
    setTimeout(() => setIsConfirm(true), 100);
  };

  const onSubmit: SubmitHandler<AllList> = (data) => {
    const updParam = {
      oemList: data.allList
        .filter((item) => item.checked)
        .map((item) => {
          return {
            oemId: item.apiClientId,
          };
        }),
    };

    campaignApi.campaignShowApiclientUpdate(updParam).then((res: AxiosResponse<IncResultOutputResponse>) => {
      // res.data = {
      //   result: true,
      //   errorCode: 'AAA-0000',
      //   errorMessage: '更新できませんでした。',
      // };
      setUpdResult(res.data);
    });
  };

  return (
    <>
      <Title className="mb-4">{!isConfirm ? TITLE.KEISAI.CAMPAIGN_OEM : TITLE.KEISAI.CAMPAIGN_OEM_CONFIRM}</Title>
      {updResult?.result && <Alert variant="success">更新しました。</Alert>}
      {updResult?.errorCode && updResult?.errorMessage && (
        <Alert variant="danger">{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex justify-content-end mb-4">
          <Button
            variant="link"
            className="ms-2"
            onClick={() => {
              if (isConfirm) {
                setIsConfirm(false);
                setUpdResult({
                  result: false,
                  errorCode: '',
                  errorMessage: '',
                });
              }
              history.goBack();
            }}
          >
            キャンセル
          </Button>
          {!isConfirm ? (
            <Button className="ms-2" onClick={handleClickConfirm}>
              確認画面へ
            </Button>
          ) : (
            <Button className="ms-2" type="submit">
              確定
            </Button>
          )}
        </div>

        <OemTable fields={fields} register={register} disabled={isConfirm} />
      </Form>
    </>
  );
};
