import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { createTestId, uuid } from '../../utils/functions';

interface Props {
  isPlainTable?: boolean;
  className?: string;
  body: Record<string, React.ReactNode[]>;
  tableHead?: React.ReactNode;
  headStyle?: React.CSSProperties;
  bodyStyle?: React.CSSProperties;
}

export const VerticalTable: React.FC<Props> = ({ isPlainTable, className, tableHead, headStyle, bodyStyle, body }) => {
  const testid = createTestId(VerticalTable);
  const TableComponent: React.FC<{ className?: typeof className }> = useMemo(
    () => (isPlainTable ? (props) => <table {...props} /> : Table),
    [isPlainTable]
  );
  return (
    <TableComponent data-testid={testid('table')} className={`${className ?? ''}`}>
      {tableHead && <thead>{tableHead}</thead>}
      <tbody>
        {Object.entries(body).map(([label, values]) => (
          <tr key={uuid()}>
            <th style={headStyle}>{label}</th>
            {values.map((value) => (
              <td key={uuid()} style={bodyStyle}>
                {value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </TableComponent>
  );
};
