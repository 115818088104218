/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApplicationInfoUpdateFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * ApplicationInfoUpdateApi - axios parameter creator
 * @export
 */
export const ApplicationInfoUpdateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 申請書情報を更新する
         * @summary 申請書情報更新
         * @param {ApplicationInfoUpdateFormResponse} applicationInfoUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationInfoUpdate: async (applicationInfoUpdateFormResponse: ApplicationInfoUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationInfoUpdateFormResponse' is not null or undefined
            assertParamExists('applicationInfoUpdate', 'applicationInfoUpdateFormResponse', applicationInfoUpdateFormResponse)
            const localVarPath = `/api/application-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(applicationInfoUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationInfoUpdateApi - functional programming interface
 * @export
 */
export const ApplicationInfoUpdateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApplicationInfoUpdateApiAxiosParamCreator(configuration)
    return {
        /**
         * 申請書情報を更新する
         * @summary 申請書情報更新
         * @param {ApplicationInfoUpdateFormResponse} applicationInfoUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async applicationInfoUpdate(applicationInfoUpdateFormResponse: ApplicationInfoUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.applicationInfoUpdate(applicationInfoUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApplicationInfoUpdateApi - factory interface
 * @export
 */
export const ApplicationInfoUpdateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApplicationInfoUpdateApiFp(configuration)
    return {
        /**
         * 申請書情報を更新する
         * @summary 申請書情報更新
         * @param {ApplicationInfoUpdateFormResponse} applicationInfoUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applicationInfoUpdate(applicationInfoUpdateFormResponse: ApplicationInfoUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.applicationInfoUpdate(applicationInfoUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationInfoUpdateApi - object-oriented interface
 * @export
 * @class ApplicationInfoUpdateApi
 * @extends {BaseAPI}
 */
export class ApplicationInfoUpdateApi extends BaseAPI {
    /**
     * 申請書情報を更新する
     * @summary 申請書情報更新
     * @param {ApplicationInfoUpdateFormResponse} applicationInfoUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationInfoUpdateApi
     */
    public applicationInfoUpdate(applicationInfoUpdateFormResponse: ApplicationInfoUpdateFormResponse, options?: AxiosRequestConfig) {
        return ApplicationInfoUpdateApiFp(this.configuration).applicationInfoUpdate(applicationInfoUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
