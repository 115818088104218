import { faBan, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useLargeState } from '../../../../../../../hooks/useLargeState';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext, CorrectionPageState } from '../../../../../../../store/correctionStore';
import { when } from '../../../../../../../utils/functions';
import { Button } from '../../../../../../atoms/Button';
import { SideBy } from '../../../../../../atoms/SideBy';
import { TinyHead } from '../../../../../../atoms/TinyHead';

export const TellCheck: React.FC = () => {
  const {
    largeState: { state: $, setState },
  } = useSafeContext(CorrectionContext);
  const {
    state: f,
    onChangeCheckbox: check,
    onChangeSet: set,
  } = useLargeState<NonNullable<CorrectionPageState['receiptForm_tellCheck']>>($.receiptForm_tellCheck ?? {});

  const onCheck = useCallback(() => {
    const { phoneNo } = f;
    const onOk = (result: boolean) => {
      setState((prev) => ({
        ...prev,
        receiptForm_tellCheck: {
          ...(prev.receiptForm_tellCheck ?? {}),
          ...f,
          result,
        },
      }));
    };
    if (f.checkbox) return onOk(true);
    $.api_correctReceipt
      .correctionTellNoCheck({ applyId: $.applyId, shopPhoneNo: phoneNo ?? '' })
      .then(({ data: { result } }) => {
        onOk(result);
      })
      .catch(() => {
        setState((prev) => ({
          ...prev,
          receiptForm_tellCheck: {
            ...(prev.receiptForm_tellCheck ?? {}),
            ...f,
            result: false,
          },
        }));
      });
  }, [$.api_correctReceipt, $.applyId, $.receiptForm_tellCheck?.phoneNo, f, setState]);

  return (
    <>
      <TinyHead>店舗名・住所・電話番号をチェック</TinyHead>
      <Form>
        <Form.Group>
          <Form.Check>
            <Form.Check.Input type="checkbox" checked={f.checkbox ?? false} onChange={check('checkbox')} />
            <Form.Check.Label>電話番号なし</Form.Check.Label>
          </Form.Check>
        </Form.Group>
        <SideBy>
          <Form.Group>
            <Form.Control size="lg" disabled={f.checkbox} value={f.phoneNo ?? ''} onChange={set('phoneNo')} />
          </Form.Group>
          <Button onClick={onCheck}>チェックして添削結果に反映</Button>
        </SideBy>
        {when(
          f.result === true,
          <Alert variant="success">
            <FontAwesomeIcon icon={faCheckCircle} /> チェックOK
          </Alert>
        )}
        {when(
          f.result === false,
          <Alert variant="danger">
            <FontAwesomeIcon icon={faBan} /> 登録されている電話番号と違います。店舗名を注意して確認してください。
          </Alert>
        )}
      </Form>
    </>
  );
};
