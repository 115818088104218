import React, { useState, Dispatch, SetStateAction } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Modal } from '../../molecules/Modal';
import {
  BillingInvoiceDetailItemResultOutputResponse,
  BillingInvoiceDetailItemListDataOutputResponse,
  BillingInvoiceDetailContractOutputResponse,
} from '../../../api-client';
import { Alert } from '../../atoms/Alert';
import { BILLING_ITEM_RELATION_TYPE, BILLING_STATUS, DEFAULT_TAX_RATE, UNSAVED_ID } from '../../../BillingConstants';
import { InputNumber } from '../../atoms/InputNumber';

export interface Props {
  detail: BillingInvoiceDetailItemResultOutputResponse;
  setDetail: Dispatch<SetStateAction<BillingInvoiceDetailItemResultOutputResponse>>;
  item?: BillingInvoiceDetailItemListDataOutputResponse;
  contract?: BillingInvoiceDetailContractOutputResponse;
  isEditModal: boolean;
  setIsEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  editType: string;
}

const EDIT_TYPE = {
  ADD: 'add',
  EDIT: 'edit',
};

const RATIO_MIN = 0;
const RATIO_MAX = 100;

type UpdateData = {
  contractId: number;
  contractName: string;
  billingItemName: string;
  billingUseItemId: number;
  billingItemDetailId: number;
  itemNameForDisplay?: string;
  unitPrice?: number;
  quantity?: number;
  unitName?: string;
  amount: number;
  taxRate?: number;
  billingStatus: number;
  editableQuantityBillingInvoiceDetailItemFlg: boolean;
  divisionName?: string;
  relation: number;
  remarks?: string;
};

export const BillingInvoiceModal: React.VFC<Props> = ({
  detail,
  setDetail,
  item,
  contract,
  isEditModal,
  setIsEditModal,
  editType,
}) => {
  const [validated, setValidated] = useState(false);
  const [updData, setUpdData] = useState<UpdateData>({
    contractId: contract !== undefined ? contract.contractId : 0,
    contractName: contract !== undefined ? contract.contractName : '',
    billingItemName: item !== undefined ? item.billingItemName : '',
    billingUseItemId: item !== undefined ? item.billingUseItemId : 0,
    billingItemDetailId: item !== undefined ? item.billingItemDetailId : UNSAVED_ID,
    itemNameForDisplay: item?.itemNameForDisplay,
    unitPrice: item?.unitPrice,
    quantity: item?.quantity,
    unitName: item?.unitName,
    amount: item?.amount || 0,
    taxRate: item?.taxRate !== undefined ? item.taxRate : DEFAULT_TAX_RATE,
    billingStatus: item?.billingStatus || BILLING_STATUS.BILLING,
    editableQuantityBillingInvoiceDetailItemFlg: item?.editableQuantityBillingInvoiceDetailItemFlg || true,
    divisionName: item?.divisionName,
    relation: item?.relationType || BILLING_ITEM_RELATION_TYPE.CONTRACT,
    remarks: item?.remarks,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [editableQuantityFlg, setEditableQuantityFlg] = useState<boolean>(true);

  const getAmount = () => {
    return updData.unitPrice !== undefined && updData.quantity !== undefined ? updData.unitPrice * updData.quantity : 0;
  };

  const getContractName = (contractId: number) => {
    const contractItem = detail.contractList.find((v) => {
      return Number(v.id) === contractId;
    });
    return contractItem ? contractItem.name : '';
  };

  const getBillingItemName = (billingUseItemId: number) => {
    const billingItem = detail.billingItemList.find((data: any) => {
      return Number(data.id) === Number(billingUseItemId);
    });
    return billingItem ? billingItem.billingItemName : '';
  };

  const getBillingUnitName = () => {
    const billingItem = detail.billingItemList.find((data: any) => {
      return Number(data.id) === Number(updData.billingUseItemId);
    });
    return billingItem ? billingItem.billingUnitName : '';
  };

  const handleChangeTaxRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdData({
      ...updData,
      taxRate:
        event.target.value === '' ? undefined : Math.max(RATIO_MIN, Math.min(RATIO_MAX, Number(event.target.value))),
    });
  };

  const isEditableQuantity = (editableQuantityBillingInvoiceDetailItemFlg: boolean | undefined) => {
    if (editableQuantityBillingInvoiceDetailItemFlg !== undefined) {
      return editableQuantityBillingInvoiceDetailItemFlg;
    }
    return editableQuantityFlg;
  };

  const onHide = () => {
    setIsEditModal(false);
  };

  // 契約名、品目・品名、単価、税区分が全て一致する項目がある場合はエラーを返す
  const itemValidation = () => {
    let validation = true;

    if (updData.contractId === 0) {
      setErrorMessage((prevState) => prevState.concat('\n契約を選択してください。'));
      validation = false;
    }
    if (updData.billingUseItemId === 0) {
      setErrorMessage((prevState) => prevState.concat('\n品目・品名を選択してください。'));
      validation = false;
    }

    if (updData.taxRate === undefined || updData.taxRate < 0) {
      setErrorMessage((prevState) => prevState.concat('\n税率は0以上の整数を設定して下さい。'));
      validation = false;
    }

    if (updData.unitPrice === undefined || updData.unitPrice === 0) {
      setErrorMessage((prevState) => prevState.concat('\n単価は0以外の整数を設定して下さい。'));
      validation = false;
    }

    if (
      updData.editableQuantityBillingInvoiceDetailItemFlg &&
      (updData.quantity === undefined || updData.quantity <= 0)
    ) {
      // 数量変更ができる商品で、数量が未設定または0以下の場合はエラーにする
      setErrorMessage((prevState) => prevState.concat('\n数量は1以上の整数を設定して下さい。'));
      validation = false;
    }

    if (editType === EDIT_TYPE.ADD || (editType === EDIT_TYPE.EDIT && isChangeUniqueKey())) {
      // 新規編集または修正でキーとなる項目の内容が変更になった場合、重複チェックを行う
      const invoiceItem = detail.billingInvoiceDetailItemData.billingInvoiceItem?.find(
        (obj) => obj.contractId === updData.contractId
      );
      if (invoiceItem) {
        invoiceItem.billingInvoiceDetailItemList.forEach((billingInvoiceItemDetail) => {
          if (
            billingInvoiceItemDetail.billingUseItemId === updData.billingUseItemId &&
            billingInvoiceItemDetail.unitPrice === updData.unitPrice &&
            billingInvoiceItemDetail.taxRate === updData.taxRate &&
            billingInvoiceItemDetail.billingStatus === updData.billingStatus
          ) {
            setErrorMessage((prevState) =>
              prevState.concat(
                '\n既に同じ契約、品目・品名、単価で明細を登録済みです。お手数ですがモーダルを閉じて編集リンクから編集してください。'
              )
            );
            validation = false;
          }
        });
      }
    }

    return validation;
  };

  /**
   * 編集を謳歌したときにユニークとなるキーの情報が変更されたかどうか
   */
  const isChangeUniqueKey = () => {
    return !(
      updData.billingUseItemId === item?.billingUseItemId &&
      updData.unitPrice === item.unitPrice &&
      updData.billingUseItemId === item.billingUseItemId &&
      updData.taxRate === item.taxRate &&
      updData.billingStatus === item.billingStatus
    );
  };

  const handleDelete = () => {
    setDetail((prevState) => ({
      ...prevState,
      billingInvoiceDetailItemData: {
        ...prevState.billingInvoiceDetailItemData,
        billingInvoiceItem: prevState.billingInvoiceDetailItemData.billingInvoiceItem?.map((billingContractDetail) => {
          return {
            ...billingContractDetail,
            billingInvoiceDetailItemList: billingContractDetail.billingInvoiceDetailItemList.filter(
              (billingItemDetail) =>
                !(
                  billingItemDetail.billingItemDetailId === item?.billingItemDetailId &&
                  billingItemDetail.billingUseItemId === item?.billingUseItemId &&
                  billingItemDetail.unitPrice === item?.unitPrice &&
                  contract?.contractId === billingContractDetail.contractId &&
                  billingItemDetail.billingStatus === item?.billingStatus &&
                  billingItemDetail.taxRate === item?.taxRate
                )
            ),
          };
        }),
      },
    }));
    onHide();
  };

  // 契約ID、品目IDが既存の場合はアラートを出して更新しない
  const handleSubmit = (e: any) => {
    setErrorMessage('');
    e.preventDefault();
    // const form = e.currentTarget;
    // if (!form.checkValidity() || !itemValidation()) {
    if (!itemValidation()) {
      e.stopPropagation();
      console.log(errorMessage);
      return;
    }
    setValidated(true);

    if (editType === EDIT_TYPE.ADD) {
      insert();
    } else {
      update();
    }
    setIsEditModal(false);
  };

  const insert = () => {
    // 選択した契約がすでに存在しているかどうか
    const hasContract = detail.billingInvoiceDetailItemData.billingInvoiceItem?.filter(
      (v) => v.contractId === updData.contractId
    );

    // 登録するデータ
    const data = {
      amount: getAmount(),
      billingHeaderId: detail.billingInvoiceDetailItemData.billingHeaderId,
      billingItemDetailId: updData.billingItemDetailId,
      billingItemName: updData.billingItemName,
      billingStatus: updData.billingStatus,
      billingUseItemId: updData.billingUseItemId,
      divisionName: updData.divisionName,
      editableQuantityBillingInvoiceDetailItemFlg: updData.editableQuantityBillingInvoiceDetailItemFlg,
      itemNameForDisplay: updData.itemNameForDisplay,
      quantity: updData.quantity,
      relationType: updData.relation,
      remarks: updData.remarks,
      taxRate: updData.taxRate || 0,
      unitName: updData.unitName,
      unitPrice: updData.unitPrice,
    } as BillingInvoiceDetailItemListDataOutputResponse;

    const newDetailData = { ...detail };
    if (hasContract !== undefined && hasContract.length > 0) {
      // 契約がすでにある
      newDetailData.billingInvoiceDetailItemData.billingInvoiceItem?.forEach((billingContractDetail) => {
        if (billingContractDetail.contractId === updData.contractId) {
          // 単純に商品を追加する。同一の商品はバリデーションではじいてるのでただの追加
          billingContractDetail.billingInvoiceDetailItemList.push(data);
        }
      });
    } else {
      // 契約がない
      const contractData = {
        contractId: updData.contractId,
        contractName: updData.contractName,
        billingInvoiceDetailItemList: [data],
      } as BillingInvoiceDetailContractOutputResponse;
      if (newDetailData.billingInvoiceDetailItemData.billingInvoiceItem) {
        newDetailData.billingInvoiceDetailItemData.billingInvoiceItem.push(contractData);
      } else {
        newDetailData.billingInvoiceDetailItemData.billingInvoiceItem = [];
        newDetailData.billingInvoiceDetailItemData.billingInvoiceItem.push(contractData);
      }
    }
    setDetail(newDetailData);
  };

  const update = () => {
    const newDetailData = {
      ...detail,
      billingInvoiceDetailItemData: {
        ...detail.billingInvoiceDetailItemData,
        billingInvoiceItem: detail.billingInvoiceDetailItemData.billingInvoiceItem?.map((billingContractDetail) => {
          return {
            ...billingContractDetail,
            billingInvoiceDetailItemList: billingContractDetail.billingInvoiceDetailItemList.map(
              (billingItemDetail) => {
                if (
                  billingItemDetail.billingUseItemId === item?.billingUseItemId &&
                  billingItemDetail.unitPrice === item.unitPrice &&
                  billingItemDetail.billingUseItemId === item.billingUseItemId &&
                  billingItemDetail.taxRate === item.taxRate &&
                  billingItemDetail.billingStatus === item.billingStatus
                ) {
                  return {
                    ...billingItemDetail,
                    ...{
                      itemNameForDisplay: updData.itemNameForDisplay,
                      unitPrice: updData.unitPrice || 0,
                      quantity: updData.quantity || 0,
                      taxRate: updData.taxRate || 0,
                      amount: getAmount(),
                    },
                  };
                }
                return billingItemDetail;
              }
            ),
          };
        }),
      },
    };

    setDetail(newDetailData);
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isEditModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            <h3>明細追加モーダル</h3>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-4" controlId="contract">
                <Row>
                  <Col className="col-2">
                    <Form.Label className="pt-2">契約</Form.Label>
                  </Col>
                  <Col className="col-10">
                    <Form.Select
                      disabled={editType === EDIT_TYPE.EDIT}
                      required
                      value={contract?.contractId}
                      onChange={(event) => {
                        setUpdData({
                          ...updData,
                          ...{
                            contractId: Number(event.target.value),
                            contractName: getContractName(Number(event.target.value)),
                          },
                        });
                      }}
                    >
                      {editType === EDIT_TYPE.ADD && (
                        <option selected disabled value="">
                          選択してください
                        </option>
                      )}
                      {detail.contractList.map((contractItem: any) => {
                        return <option value={contractItem.id}>{contractItem.name}</option>;
                      })}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-4" controlId="product">
                <Row>
                  <Col className="col-2">
                    <Form.Label className="pt-2">品目・品名</Form.Label>
                  </Col>
                  <Col className="col-10">
                    <Form.Select
                      disabled={editType === EDIT_TYPE.EDIT}
                      required
                      value={updData?.billingUseItemId || ''}
                      onChange={(event) => {
                        const newEditableQuantityFlg = detail.billingItemList.find(
                          (v) => v.id === Number(event.target.value)
                        )!.editableQuantityBillingInvoiceDetailItemFlg;

                        setUpdData((prevState) => ({
                          ...prevState,
                          ...{
                            billingUseItemId: Number(event.target.value),
                            billingItemName: getBillingItemName(Number(event.target.value)),
                            editableQuantityBillingInvoiceDetailItemFlg: newEditableQuantityFlg,
                          },
                        }));
                        setEditableQuantityFlg(
                          detail.billingItemList.find((v) => v.id === Number(event.target.value))!
                            .editableQuantityBillingInvoiceDetailItemFlg
                        );
                        if (!newEditableQuantityFlg) {
                          // 修正不可能な商品の場合
                          setUpdData((prevState) => ({
                            ...prevState,
                            quantity: 1,
                          }));
                        }
                      }}
                    >
                      {editType === EDIT_TYPE.ADD && (
                        <option selected disabled value="">
                          選択してください
                        </option>
                      )}
                      {editType === EDIT_TYPE.EDIT && item?.relationType !== BILLING_ITEM_RELATION_TYPE.CONTRACT
                        ? detail.billingItemList.map((value) => {
                            return <option value={value?.id}>{value?.billingItemName}</option>;
                          })
                        : detail.billingItemList
                            .filter((v) => v.relationCode === BILLING_ITEM_RELATION_TYPE.CONTRACT)
                            .map((value) => {
                              return <option value={value?.id}>{value?.billingItemName}</option>;
                            })}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-4" controlId="itemNameForDisplay">
                <Row>
                  <Col className="col-2">
                    <Form.Label className="pt-2">帳票表示名</Form.Label>
                  </Col>
                  <Col className="col-10">
                    <Form.Control
                      type="text"
                      value={updData?.itemNameForDisplay}
                      onChange={(event) => {
                        setUpdData({ ...updData, itemNameForDisplay: event.target.value });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-4" controlId="unitPrice">
                <Row>
                  <Col className="col-2">
                    <Form.Label className="pt-2">単価</Form.Label>
                  </Col>
                  <Col className="col-10">
                    <InputNumber
                      required
                      readOnly={
                        editType === EDIT_TYPE.EDIT && item?.relationType !== BILLING_ITEM_RELATION_TYPE.CONTRACT
                      }
                      min={undefined}
                      value={updData.unitPrice}
                      onChange={(event) => {
                        setUpdData({
                          ...updData,
                          unitPrice: event.target.value === '' ? undefined : Number(event.target.value),
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-4" controlId="quantity">
                <Row>
                  <Col className="col-2">
                    <Form.Label className="pt-2">数量</Form.Label>
                  </Col>
                  <Col className="col-10">
                    <InputNumber
                      readOnly={!isEditableQuantity(item?.editableQuantityBillingInvoiceDetailItemFlg)}
                      required
                      value={updData.quantity}
                      onChange={(event) => {
                        setUpdData({
                          ...updData,
                          quantity: event.target.value === '' ? undefined : Number(event.target.value),
                        });
                      }}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-4" controlId="unitName">
                <Row>
                  <Col className="col-2">
                    <Form.Label className="pt-2">単位</Form.Label>
                  </Col>
                  <Col className="col-10">
                    <Form.Control readOnly type="text" value={getBillingUnitName()} />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-4" controlId="amount">
                <Row>
                  <Col className="col-2">
                    <Form.Label className="pt-2">金額</Form.Label>
                  </Col>
                  <Col className="col-10">
                    <Form.Control readOnly type="text" value={getAmount()} />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-4" controlId="taxRate">
                <Row>
                  <Col className="col-2">
                    <Form.Label className="pt-2">税区分</Form.Label>
                  </Col>
                  <Col className="col-10">
                    <InputNumber
                      required
                      readOnly={
                        editType === EDIT_TYPE.EDIT && item?.relationType !== BILLING_ITEM_RELATION_TYPE.CONTRACT
                      }
                      value={updData.taxRate}
                      onChange={(e) => handleChangeTaxRate(e)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row className="d-flex align-items-center">
                {editType === EDIT_TYPE.ADD ? (
                  <Button type="submit" variant="secondary" className="mt-2">
                    追加
                  </Button>
                ) : (
                  <>
                    <Button type="submit" variant="secondary" className="mt-2">
                      更新
                    </Button>
                    <Button variant="secondary" onClick={handleDelete} className="mt-2">
                      削除
                    </Button>
                  </>
                )}
                <Button variant="secondary" onClick={onHide} className="mt-2">
                  キャンセル
                </Button>
              </Row>
            </Form>
          </>
        }
      />
    </>
  );
};
