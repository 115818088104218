import React, { useCallback, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { createTestId, nameof } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import type { MailInfoState as ParentState } from '../../pages/Customer/Card/MailInfoCard';

interface Props {
  forms: ParentState['forms'];
  setForms: DispatchSetState<ParentState['forms']>;
}
export const CustomerMailInfoEditModalForms: React.FC<Props> = ({ forms, setForms }) => {
  const testid = createTestId<keyof ParentState['forms']>(CustomerMailInfoEditModalForms);
  const { state: $, onChangeSet, mergeState } = useLargeState<ParentState['forms']>(forms ?? {});

  const name = (x: keyof ParentState['forms']) => nameof(x);
  useEffect(() => setForms($), [setForms, $]);

  const onchangeNewsletterSendFlg = useCallback(
    (newsletterSendFlg: boolean) => {
      mergeState({ newsletterSendFlg: !newsletterSendFlg });
    },
    [mergeState]
  );

  const onchangeMailUnreachableFlg = useCallback(
    (mailUnreachableFlg: boolean) => {
      mergeState({ mailUnreachableFlg: !mailUnreachableFlg });
    },
    [mergeState]
  );

  const options: [string, number][] = useMemo(
    () =>
      Object.entries({
        '0回': 0,
        '1回': 1,
        '2回': 2,
        '3回以上': 3,
      }),
    []
  );

  return (
    <>
      <Form.Group controlId={name('mailAddress')} data-testid={testid('mailAddress')}>
        <Form.Label>メールアドレス</Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$.mailAddress ?? ''}
          onChange={onChangeSet('mailAddress')}
        />
      </Form.Group>
      <Form.Group controlId={name('mailAddress2')} data-testid={testid('mailAddress2')}>
        <Form.Label>メールアドレス2</Form.Label>
        <small className="text-danger">
          →※旧基盤には連携されないため、変更した場合、必ず旧基盤も変更してください。
        </small>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$.mailAddress2 ?? ''}
          onChange={onChangeSet('mailAddress2')}
        />
      </Form.Group>
      <Form.Group controlId={name('newsletterSendFlg')} data-testid={testid('newsletterSendFlg')}>
        <Form.Label>メルマガ配信</Form.Label>
        <Form.Check
          type="switch"
          onChange={() => onchangeNewsletterSendFlg($.newsletterSendFlg)}
          checked={$.newsletterSendFlg}
        />
      </Form.Group>
      <Form.Group controlId={name('mailUnreachableFlg')} data-testid={testid('mailUnreachableFlg')}>
        <Form.Label>メール不達ステータス</Form.Label>
        <Form.Check
          type="switch"
          onChange={() => onchangeMailUnreachableFlg($.mailUnreachableFlg)}
          checked={$.mailUnreachableFlg}
        />
      </Form.Group>
      <Form.Group controlId={name('mailSendFailCount')} data-testid={testid('mailSendFailCount')}>
        <Form.Label>メール送信失敗回数</Form.Label>
        <Form.Select value={$.mailSendFailCount} onChange={onChangeSet('mailSendFailCount')}>
          {options.map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </>
  );
};
