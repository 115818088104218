import React, { useState } from 'react';
import { Form, FloatingLabel, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { createTestId } from '../../utils/functions';
import { Button } from '../atoms/Button';

export interface Props {
  value: string;
  label?: string;
  options: string[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDelete: () => void;
  onClickItem: (e: React.MouseEvent<HTMLElement>) => void;
  onBlur?: () => void;
  className?: string;
  disable?: boolean;
  required?: boolean;
}

export const RecommendFloatingLabel: React.VFC<Props> = ({
  value,
  label,
  options,
  onChange,
  onDelete,
  onClickItem,
  onBlur,
  className,
  disable,
  required,
}) => {
  const testid = createTestId(RecommendFloatingLabel);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <FloatingLabel
      controlId={label}
      label={label}
      className={`position-relative ${className}`}
      data-testid={testid('parent')}
    >
      <Form.Control
        type="text"
        autoComplete="off"
        value={value}
        onChange={onChange}
        onFocus={() => setIsOpen(true)}
        onBlur={() => {
          setIsOpen(false);
          if (onBlur) onBlur();
        }}
        placeholder={label}
        disabled={disable}
        required={required}
        data-testid={testid('input')}
      />
      <Button
        variant="link"
        className={`position-absolute top-50 end-0 translate-middle-y text-secondary ${!value && 'invisible'}`}
        onClick={onDelete}
        data-testid={testid('delete-button')}
        disabled={disable}
      >
        <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
      </Button>

      {isOpen && (
        <ListGroup
          className="border position-absolute w-100"
          style={{ maxHeight: '202px', overflowY: 'scroll', zIndex: 4 }}
          data-testid={testid('list')}
        >
          {options
            .filter((item) => !value || item.includes(value))
            .map((item) => (
              <ListGroup.Item key={item} className="border-0" onMouseDown={onClickItem} action>
                {item}
              </ListGroup.Item>
            ))}
        </ListGroup>
      )}
    </FloatingLabel>
  );
};
