import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { currentUserState } from '../../states/currentUser';
import {
  BillingApi,
  BillingInvoiceDetailItemResultOutputResponse,
  BillingInvoiceDetailItemUpdateFormResponse,
  BillingInvoiceDetailSelectableBillingUseItemOutputResponse,
  BillingInvoiceDetailSelectableContractOutputResponse,
  BillingInvoiceItemUpdateFormResponse,
  BillingInvoiceStatusUpdateFormResponse,
  ContractOptionsOutputResponse,
  IncResultOutputResponse,
  PaymentConditionOutputResponse,
} from '../../api-client';
import { BillingInvoiceDetailProductTable } from './Table/BillingInvoiceDetailProductTable';
import { BillingContext } from '../context/BillingContext';
import {
  BILLING_DEFAULT_FADE_TIME,
  BILLING_FILE_EXTENSION,
  BILLING_FILE_TYPE,
  BILLING_FLOW_STATUS,
  BILLING_INVOICE_PDF_TYPE,
  BILLING_SEND_MAIL_BATCH_URL,
  BILLING_SEND_MAIL_BATCH_URL_BILLINGHEADERID,
  DATAURI_REG_EXP,
  SAMA,
} from '../../BillingConstants';
import { ResultAlert } from './Alert/ResultAlert';
import { BillingInvoiceCarryOverModal } from './Modal/BillingInvoiceCarryOverModal';
import { BillingInvoiceFirstConfirmModal } from './Modal/BillingInvoiceFirstConfirmModal';
import { convertMoneyText } from '../../utils/functionsForBilling';
import { downloadInvoicePdfForUpload } from '../../services/billing/BillingInvoiceDownloadPdfService2';
import { BillingDetailMemoModal } from './Modal/BillingDetailMemoModal';
import { HTTPStatusCode } from '../../constants/HTTPStatusCode';
import { downloadInvoiceExcel } from '../../services/billing/BillingInvoiceDownloadExcelService';
import { LOCALDATETIME_HYPHEN_PADDING_START_DAY_FORMAT } from '../../Constants';

export const BillingInvoiceDetailProductTab = () => {
  const billingApi = new BillingApi();
  const currentUser = useRecoilState(currentUserState);
  const [isEditCarryOverModal, setIsEditCarryOverModal] = useState<boolean>(false);
  const [isEditAutoApprovalConfigModal, setIsEditAutoApprovalConfigModal] = useState<boolean>(false);
  const [isEditMemoModal, setIsEditMemoModal] = useState<boolean>(false);
  const currentUserId = currentUser[0]!.id;
  const [billingAmount, setBillingAmount] = useState<number>(0);
  const history = useHistory();

  const [billingItemList, setBillingItemList] = useState<BillingInvoiceDetailSelectableBillingUseItemOutputResponse[]>(
    []
  );
  const [contractAmountList, setContractAmountList] = useState<ContractOptionsOutputResponse[]>([]);
  const [contractList, setContractList] = useState<BillingInvoiceDetailSelectableContractOutputResponse[]>([]);
  const [paymentConditionList, setPaymentConditionList] = useState<PaymentConditionOutputResponse[]>([]);

  const [detail, setDetail] = useState<BillingInvoiceDetailItemResultOutputResponse>({
    billingInvoiceDetailItemData: {
      billingFlowStatus: BILLING_FLOW_STATUS.SALES_DPT_UNCOMMITTED,
      applyComment: '',
      billingDestinationAddress: '',
      billingDestinationBillingStaff: '',
      billingDestinationBuilding: '',
      billingDestinationCity: '',
      billingDestinationCompanyName: '',
      billingDestinationDepartment: '',
      billingDestinationName: '',
      billingDestinationPostalcode: '',
      billingDestinationPrefecture: '',
      billingHeaderId: 0,
      billingInvoiceItem: [],
      billingPublishedDate: '',
      clientName: '',
      paymentConditonId: 0,
      paymentDate: '',
      rejectComment: '',
      remarks: '',
      roiAccountName: '',
      roiAddress: '',
      roiBuilding: '',
      roiCity: '',
      roiFax: '',
      roiName: '',
      roiPostalcode: '',
      roiPrefecture: '',
      roiTel: '',
      subject: '',
      invoiceRegisterNumber: '',
      billingMonth: '',
      reportCount: 0,
      customerAmount: 0,
      canProgressBillingFlowStatus: true,
      billingApproverDefaultId: undefined,
      deliveryNote: '',
    },
    billingItemList,
    contractAmountList,
    contractList,
    paymentConditionList,
    updateAt: '',
    isFirst: false,
    applicantId: undefined,
    billingDestinationId: 0,
    billingApproverList: [],
  });

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();

  const billingContext = useContext(BillingContext);
  const billingId = billingContext!.billingId!;
  const isSalesDptEditable = billingContext!.isSalesDptEditable!;
  const isSalesDptMgrEditable = billingContext!.isSalesDptMgrEditable!;
  const isAccDptMgrEditable = billingContext!.isAccDptMgrEditable!;

  const openCarryOverModal = () => {
    setIsEditCarryOverModal(true);
  };

  const openApprovalConfigModal = () => {
    if (detail.isFirst) {
      setIsEditAutoApprovalConfigModal(true);
    } else {
      updateAndProgressStatus(false, false, false);
    }
  };

  /**
   * 配送メモ更新モーダルを開く
   */
  const openEditMemoModal = () => {
    setIsEditMemoModal(true);
  };

  const fetchData = () => {
    if (!billingId) return;
    billingApi
      .billingInvoiceDetailItem(billingId)
      .then((res: AxiosResponse<BillingInvoiceDetailItemResultOutputResponse>) => {
        setDetail(res.data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const PaymentConditionControls = () => {
    return (
      <Form.Group className="d-flex mt-2 mb-2" controlId="paymentConditonId">
        <Form.Label className="pt-2 me-2 col-2">支払条件</Form.Label>
        <Col>
          <Form.Select
            disabled={!isSalesDptEditable}
            name="condition"
            value={detail.billingInvoiceDetailItemData.paymentConditonId}
            onChange={(e) =>
              setDetail((prevState) => ({
                ...prevState,
                billingInvoiceDetailItemData: {
                  ...prevState.billingInvoiceDetailItemData,
                  paymentConditonId: Number(e.target.value),
                },
              }))
            }
          >
            {detail.paymentConditionList.map((condition) => {
              return (
                <option value={condition.id} key={condition.id}>
                  {condition.name}
                </option>
              );
            })}
          </Form.Select>
        </Col>
      </Form.Group>
    );
  };
  const update = () => {
    const updateBillingInvoiceItemList: BillingInvoiceItemUpdateFormResponse[] = [];
    detail.billingInvoiceDetailItemData.billingInvoiceItem?.forEach((contract) => {
      contract.billingInvoiceDetailItemList.forEach((item) => {
        updateBillingInvoiceItemList.push({
          amount: item.amount,
          billingUseItemId: item.billingUseItemId,
          contractId: contract.contractId,
          itemNameForDisplay: item.itemNameForDisplay || '',
          quantity: item.quantity,
          remarks: item.remarks,
          taxRate: item.taxRate,
          unitPrice: item.unitPrice,
          billingStatus: item.billingStatus,
        });
      });
    });

    const updateData = {
      applyComment: detail.billingInvoiceDetailItemData.applyComment,
      billingHeaderId: detail.billingInvoiceDetailItemData.billingHeaderId,
      billingHeaderTitle: detail.billingInvoiceDetailItemData.subject,
      billingInvoiceItem: updateBillingInvoiceItemList,
      paymentDate: detail.billingInvoiceDetailItemData.paymentDate,
      remarks: detail.billingInvoiceDetailItemData.remarks,
      paymentConditonId: detail.billingInvoiceDetailItemData.paymentConditonId,
      rejectComment: detail.billingInvoiceDetailItemData.rejectComment,
    } as BillingInvoiceDetailItemUpdateFormResponse;
    billingApi
      .billingInvoiceDetailItemUpdate(updateData)
      .then((response: AxiosResponse<IncResultOutputResponse>) => {
        history.go(0);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /**
   * 営業確認時に実行
   * TODO きれいに.本来トランザクション当を考慮すると1ユーザアクションで1リクエストにすべき
   * 工数ケチってる
   */
  const updateAndProgressStatus = (
    forceReturnSalesDptUncommittedFlg: boolean,
    rejectFlg: boolean,
    cancelApplyFlg: boolean
  ) => {
    let comment;
    if (rejectFlg) {
      // 却下の場合
      comment = detail.billingInvoiceDetailItemData.rejectComment;
    } else {
      // 申請の場合
      comment = detail.billingInvoiceDetailItemData.applyComment;
    }
    if (comment) {
      // 申請コメントがある場合
      // 更新処理を実施してからステータスを進める。
      const updateBillingInvoiceItemList: BillingInvoiceItemUpdateFormResponse[] = [];
      detail.billingInvoiceDetailItemData.billingInvoiceItem?.forEach((contract) => {
        contract.billingInvoiceDetailItemList.forEach((item) => {
          updateBillingInvoiceItemList.push({
            amount: item.amount,
            billingUseItemId: item.billingUseItemId,
            contractId: contract.contractId,
            itemNameForDisplay: item.itemNameForDisplay || '',
            quantity: item.quantity,
            remarks: item.remarks,
            taxRate: item.taxRate,
            unitPrice: item.unitPrice,
            billingStatus: item.billingStatus,
          });
        });
      });

      const updateData: BillingInvoiceDetailItemUpdateFormResponse = {
        applyComment: detail.billingInvoiceDetailItemData.applyComment,
        rejectComment: detail.billingInvoiceDetailItemData.rejectComment,
        billingHeaderId: detail.billingInvoiceDetailItemData.billingHeaderId,
        billingHeaderTitle: detail.billingInvoiceDetailItemData.subject,
        billingInvoiceItem: updateBillingInvoiceItemList,
        paymentDate: detail.billingInvoiceDetailItemData.paymentDate,
        remarks: detail.billingInvoiceDetailItemData.remarks,
        paymentConditonId: detail.billingInvoiceDetailItemData.paymentConditonId,
      };
      billingApi
        .billingInvoiceDetailItemUpdate(updateData)
        .then((response: AxiosResponse<IncResultOutputResponse>) => {
          updateInvoiceStatus(
            forceReturnSalesDptUncommittedFlg,
            rejectFlg,
            cancelApplyFlg,
            format(new Date(), LOCALDATETIME_HYPHEN_PADDING_START_DAY_FORMAT)
          );
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      // 申請コメントがない場合は普通にステータスの更新を行う
      updateInvoiceStatus(forceReturnSalesDptUncommittedFlg, rejectFlg, cancelApplyFlg);
    }
  };

  /**
   *
   * @param forceReturnSalesDptUncommittedFlg 強制取り消し
   * @param rejectFlg 申請を否認する場合true
   * @param cancelApplyFlg 申請を取り消しする場合true
   * @param updateAt 申請コメントアリの場合、更新してからフローを進めるが普通にやると「別ユーザ更新済み」エラーになってしまうので、その場合だけ特例でupdateAtを別で渡す
   */
  const updateInvoiceStatus = (
    forceReturnSalesDptUncommittedFlg: boolean,
    rejectFlg: boolean,
    cancelApplyFlg: boolean,
    updateAt?: string
  ) => {
    const updateStatus: BillingInvoiceStatusUpdateFormResponse = {
      billingInvoiceStatusUpdateData: [
        {
          applyComment: detail.billingInvoiceDetailItemData.applyComment || '',
          billingHeaderId: detail.billingInvoiceDetailItemData.billingHeaderId,
          rejectComment: detail.billingInvoiceDetailItemData.rejectComment || '',
          updateAt: updateAt || detail.updateAt,
        },
      ],
      forceReturnSalesDptUncommittedFlg,
      incAccountId: currentUserId,
      rejectFlg,
      cancelApplyFlg,
      approverId: detail.billingInvoiceDetailItemData.billingApproverDefaultId,
    };
    billingApi
      .billingInvoiceStatusUpdate(updateStatus)
      .then(() => {
        billingApi.billingInvoiceFlowStatus(billingId).then((response) => {
          const newBillingFlowStatus = response.data.billingFlowStatusCode;
          if (newBillingFlowStatus > BILLING_FLOW_STATUS.MANAGE_DPT_UNCOMMITTED) {
            (async () => {
              const pdfs = await downloadInvoicePdfForUpload(billingId);
              const excels = await downloadInvoiceExcel(billingId, [
                BILLING_INVOICE_PDF_TYPE.Shop,
                BILLING_INVOICE_PDF_TYPE.Monitor,
                BILLING_INVOICE_PDF_TYPE.Apply,
              ]);
              const uploadResponse = await Promise.all([
                uploadFile(pdfs.item, BILLING_FILE_TYPE.ITEM, BILLING_FILE_EXTENSION.PDF),
                uploadFile(pdfs.shop, BILLING_FILE_TYPE.SHOP, BILLING_FILE_EXTENSION.PDF),
                uploadFile(pdfs.monitor, BILLING_FILE_TYPE.MONITOR, BILLING_FILE_EXTENSION.PDF),
                uploadFile(pdfs.apply, BILLING_FILE_TYPE.APPLY, BILLING_FILE_EXTENSION.PDF),
                uploadFile(
                  new Blob([excels], { type: 'application/octet-binary' }),
                  BILLING_FILE_TYPE.ALL,
                  BILLING_FILE_EXTENSION.XLSX
                ),
              ]);
              if (uploadResponse.every((v) => v === HTTPStatusCode.OK)) {
                // uploadが問題なく成功した場合、メール送信処理にリクエストを投げてリロードする
                const url = BILLING_SEND_MAIL_BATCH_URL.replace(
                  BILLING_SEND_MAIL_BATCH_URL_BILLINGHEADERID,
                  billingId.toString()
                );
                const req = new XMLHttpRequest();
                req.open('get', url);
                req.send();
                history.go(0);
              }
            })();
          } else {
            history.go(0);
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /**
   * 商品別明細のPDF作成を行う
   */
  const uploadFile = async (pdf: Blob, type: number, extension: number): Promise<number> => {
    const reader = new FileReader();
    reader.readAsDataURL(pdf);
    return new Promise((resolve) => {
      reader.onload = async () => {
        const res = await billingApi.billingFileUpload({
          data: [
            {
              billingHeaderId: billingId,
              data: reader.result?.toString().replace(DATAURI_REG_EXP, '') || '',
              type,
              extension,
            },
          ],
        });
        return resolve(res.status);
      };
    });
  };

  return (
    <>
      {updResult?.result !== undefined && (
        <ResultAlert updResult={updResult} fadeoutTime={BILLING_DEFAULT_FADE_TIME} setUpdResult={setUpdResult} />
      )}

      {isEditCarryOverModal && (
        <BillingInvoiceCarryOverModal
          isEditModal={isEditCarryOverModal}
          setIsEditModal={setIsEditCarryOverModal}
          billingId={billingId}
          setUpdResult={setUpdResult}
          updateAt={detail.updateAt}
        />
      )}
      {isEditAutoApprovalConfigModal && (
        <BillingInvoiceFirstConfirmModal
          isEditModal={isEditAutoApprovalConfigModal}
          setIsEditModal={setIsEditAutoApprovalConfigModal}
          billingId={billingId}
          setUpdResult={setUpdResult}
          updateAndProgressStatus={updateAndProgressStatus}
        />
      )}
      {isEditMemoModal && (
        <BillingDetailMemoModal
          isModal={isEditMemoModal}
          setIsModal={setIsEditMemoModal}
          detail={detail}
          setDetail={setDetail}
        />
      )}

      <Row className="d-flex justify-content-between">
        <Col className="">
          <div>〒{detail.billingInvoiceDetailItemData.billingDestinationPostalcode}</div>
          <div>
            {detail.billingInvoiceDetailItemData.billingDestinationPrefecture}
            {detail.billingInvoiceDetailItemData.billingDestinationCity}
            {detail.billingInvoiceDetailItemData.billingDestinationAddress}
          </div>
          <div>{detail.billingInvoiceDetailItemData.billingDestinationBuilding}</div>
          <div>{detail.billingInvoiceDetailItemData.billingDestinationCompanyName}</div>
          <div>{detail.billingInvoiceDetailItemData.billingDestinationDepartment}</div>
          <div>
            {detail.billingInvoiceDetailItemData.billingDestinationBillingStaff}
            {SAMA}
          </div>
        </Col>
        <Col>
          <div className="text-end">{detail.billingInvoiceDetailItemData.billingPublishedDate}</div>
          <div className="text-end">請求書番号：{detail.billingInvoiceDetailItemData.billingHeaderId}</div>
        </Col>
      </Row>
      <h3 className="text-center center">
        {detail.billingInvoiceDetailItemData.paymentConditonId === 1 ? '請求書' : '口座振替通知書'}
      </h3>
      <Row>
        <Col className="col-6">
          <div className="d-flex justify-content-between align-items-end">
            <div>
              <p className="my-0">{detail.billingInvoiceDetailItemData.clientName}</p>
              <p className="my-0">{detail.billingInvoiceDetailItemData.billingDestinationName}</p>
            </div>
            <div>{'      '}御中</div>
          </div>
          <Form.Group className="d-flex mt-2 mb-2" controlId="title">
            <Form.Label className="pt-2 me-2 col-2">件名</Form.Label>
            <Col>
              <Form.Control
                readOnly={!isSalesDptEditable}
                type="text"
                value={detail.billingInvoiceDetailItemData.subject}
                onChange={(e) =>
                  setDetail((prevState) => ({
                    ...prevState,
                    billingInvoiceDetailItemData: {
                      ...prevState.billingInvoiceDetailItemData,
                      subject: e.target.value,
                    },
                  }))
                }
              />
            </Col>
          </Form.Group>

          <Row>
            <Col className="col-2 pe-0">請求金額</Col>
            <Col>{convertMoneyText(billingAmount)}(税込)</Col>
          </Row>

          <Form.Group className="d-flex mt-2 mb-2" controlId="paymentDate">
            <Form.Label className="pt-2 me-2 col-2">
              {detail.billingInvoiceDetailItemData.paymentConditonId === 1 ? '支払日' : '引落日'}
            </Form.Label>
            <Col>
              <Form.Control
                type="date"
                name="dueDate"
                value={detail.billingInvoiceDetailItemData.paymentDate}
                readOnly={!isSalesDptEditable}
                onChange={(e) =>
                  setDetail((prevState) => ({
                    ...prevState,
                    billingInvoiceDetailItemData: {
                      ...prevState.billingInvoiceDetailItemData,
                      paymentDate: e.target.value,
                    },
                  }))
                }
              />
            </Col>
          </Form.Group>
          <PaymentConditionControls key={8433428} />
        </Col>

        <Col className="col-6 mt-6">
          <div>{detail.billingInvoiceDetailItemData.roiName}</div>
          <div>〒{detail.billingInvoiceDetailItemData.roiPostalcode}</div>
          <div>
            {detail.billingInvoiceDetailItemData.roiPrefecture
              .concat(detail.billingInvoiceDetailItemData.roiCity)
              .concat(detail.billingInvoiceDetailItemData.roiAddress)}
          </div>
          <div>{detail.billingInvoiceDetailItemData.roiBuilding}</div>
          <div>TEL : {detail.billingInvoiceDetailItemData.roiTel}</div>
          <div>FAX : {detail.billingInvoiceDetailItemData.roiFax}</div>
          <div>担当 : {detail.billingInvoiceDetailItemData.roiAccountName}</div>
          <div>登録番号 : {detail.billingInvoiceDetailItemData.invoiceRegisterNumber}</div>

          {detail.billingInvoiceDetailItemData.paymentConditonId === 1 ? (
            <>
              <div>振込先</div>
              <div>りそな銀行 市ヶ谷支店 普通 1783519</div>
              <div>三菱UFJ銀行 飯田橋支店 普通 0036372</div>
              <div>みずほ銀行 飯田橋支店 普通 2565732 </div>
              <div>口座名義: カ）ファンクル</div>
              <div>※振込手数料は貴社にてご負担頂きますよう宜しく御願い申し上げます。</div>
            </>
          ) : (
            <>
              <div>
                下記ご指定の口座よりお振替させていただきます。振替日の前日までにご準備いただきますようお願いいたします。
              </div>
              <div>引落口座：{detail.billingInvoiceDetailItemData.accountCode}</div>
            </>
          )}
        </Col>
      </Row>
      <Row className="me-4">
        <Form.Group controlId="remarks">
          <Form.Label>備考</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="remarks"
            value={detail.billingInvoiceDetailItemData.remarks || ''}
            readOnly={!isSalesDptEditable}
            onChange={(e) =>
              setDetail((prevState) => ({
                ...prevState,
                billingInvoiceDetailItemData: { ...prevState.billingInvoiceDetailItemData, remarks: e.target.value },
              }))
            }
          />
        </Form.Group>
      </Row>
      <BillingInvoiceDetailProductTable
        detail={detail}
        setDetail={setDetail}
        setBillingAmount={setBillingAmount}
        billingAmount={billingAmount}
        key={billingId + 13}
      />
      {isSalesDptEditable && detail.billingApproverList && (
        <Row className="d-flex align-items-center">
          <Form.Group controlId="approverId">
            <Form.Label>承認者</Form.Label>
            <Form.Select
              value={detail.billingInvoiceDetailItemData.billingApproverDefaultId || 0}
              onChange={(e) =>
                setDetail((prevState) => ({
                  ...prevState,
                  billingInvoiceDetailItemData: {
                    ...prevState.billingInvoiceDetailItemData,
                    billingApproverDefaultId: Number(e.target.value),
                  },
                }))
              }
            >
              {detail.billingApproverList.map((v) => {
                if (v.id !== currentUserId) {
                  // 自分自身を承認者として選択できないようにする
                  return (
                    <option key={v.id} value={v.id}>
                      {v.name}
                    </option>
                  );
                }
              })}
            </Form.Select>
          </Form.Group>
        </Row>
      )}
      <Row className="d-flex align-items-center">
        <Form.Group controlId="applyComment">
          <Form.Label>申請コメント</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name="applyComment"
            value={detail.billingInvoiceDetailItemData.applyComment || ''}
            onChange={(e) =>
              setDetail((prevState) => ({
                ...prevState,
                billingInvoiceDetailItemData: {
                  ...prevState.billingInvoiceDetailItemData,
                  applyComment: e.target.value,
                },
              }))
            }
            readOnly={!isSalesDptEditable}
          />
        </Form.Group>
      </Row>
      <Row className="d-flex align-items-center">
        <Form.Group controlId="rejectComment">
          <Form.Label>差戻コメント</Form.Label>
          <Form.Control
            readOnly={!(isSalesDptMgrEditable || isAccDptMgrEditable)}
            as="textarea"
            rows={3}
            name="rejectComment"
            value={detail.billingInvoiceDetailItemData.rejectComment || ''}
            onChange={(e) =>
              setDetail((prevState) => ({
                ...prevState,
                billingInvoiceDetailItemData: {
                  ...prevState.billingInvoiceDetailItemData,
                  rejectComment: e.target.value,
                },
              }))
            }
          />
        </Form.Group>
      </Row>
      <Row className="d-flex align-items-center">
        <Form.Group controlId="deliveryNote">
          <Form.Label>配送メモ</Form.Label>
          <Form.Control
            readOnly
            as="textarea"
            rows={3}
            name="deliveryNote"
            value={detail.billingInvoiceDetailItemData.deliveryNote || ''}
          />
        </Form.Group>
      </Row>

      <div className="d-flex justify-content-end mt-4 mb-4">
        <Button variant="secondary" className="me-4" onClick={() => openEditMemoModal()}>
          配送メモ更新
        </Button>
        {(isSalesDptMgrEditable || isAccDptMgrEditable) && (
          <>
            <Button variant="secondary" className="me-4" onClick={() => updateInvoiceStatus(false, false, false)}>
              承認
            </Button>
            <Button variant="secondary" className="me-4" onClick={() => updateAndProgressStatus(false, true, false)}>
              差し戻し
            </Button>
            <Button
              className="me-4"
              disabled={!billingContext!.handleCheckCanDrop(detail.applicantId, currentUserId)}
              variant="secondary"
              onClick={() => updateInvoiceStatus(false, false, true)}
            >
              確認取り下げ
            </Button>
          </>
        )}
        {isSalesDptEditable && (
          <>
            <Button variant="secondary" className="me-4" onClick={openCarryOverModal}>
              翌月繰越
            </Button>
            <Button variant="secondary" className="me-4" onClick={update}>
              更新
            </Button>
            <Button
              variant="secondary"
              className="me-4"
              onClick={openApprovalConfigModal}
              disabled={!detail.billingInvoiceDetailItemData.canProgressBillingFlowStatus}
            >
              営業確認
            </Button>
          </>
        )}
        {billingContext!.handleCheckCanForceDrop(currentUserId) && (
          <Button className="me-4" variant="secondary" onClick={() => updateInvoiceStatus(true, false, false)}>
            強制ステータス戻し
          </Button>
        )}
      </div>
    </>
  );
};
