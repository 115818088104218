import { AxiosResponse } from 'axios';
import { faCopy, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dispatch, useState } from 'react';
import { Button, Modal, OverlayTrigger, Popover, Table, Tooltip } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
// eslint-disable-next-line import/no-cycle
import {
  EnqueteCopyApi,
  EnqueteCopyFormResponse,
  EnqueteCopyOutputResponse,
  EnqueteDeleteApi,
  EnqueteForClientDownloadApi,
  EnqueteListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { ENQUETE_TYPE } from '../../pages/Enquete/EnqueteListPage';
import { execDownload, when } from '../../../utils/functions';

export interface Props {
  data: EnqueteListOutputResponse[];
  setData: Dispatch<React.SetStateAction<EnqueteListOutputResponse[]>>;
  listPerPage: EnqueteListOutputResponse[];
  enqueteType: number;
  setDeleteResult: Dispatch<React.SetStateAction<IncResultOutputResponse | undefined>>;
  onDownload: (enqueteId: number, enqueteName: string) => void;
  salesFlg: boolean;
}

export const EnqueteListTable: React.VFC<Props> = ({
  data,
  setData,
  listPerPage,
  enqueteType,
  setDeleteResult,
  onDownload,
  salesFlg,
}) => {
  // 削除モーダル開閉フラグ
  const [deleteModalFlg, setDeleteModal] = useState<boolean>(false);

  // 選択したアンケートID
  const [enqueteId, setEnqueteId] = useState<number>();

  const enqueteDeleteApi = new EnqueteDeleteApi();
  const enqueteCopyApi = new EnqueteCopyApi();
  const enqueteForDownloadApi = new EnqueteForClientDownloadApi();

  const history = useHistory();

  const companyDownloadTooltip = (
    <Popover id="popover-basic">
      <Popover.Body className="p-1">社内用</Popover.Body>
    </Popover>
  );

  const clientDownloadTooltip = (
    <Popover id="popover-basic">
      <Popover.Body className="p-1">クライアント用</Popover.Body>
    </Popover>
  );

  const copyTooltip = (
    <Tooltip id="button-tooltip" style={{ maxWidth: '25rem' }}>
      Copied!
    </Tooltip>
  );

  // モーダル（YES）イベント
  const handleClickModalYes = (): void => {
    const newData = data?.filter((item, i) => item.enqueteId !== enqueteId);
    if (enqueteId) {
      enqueteDeleteApi.enqueteDelete(enqueteId).then((res: AxiosResponse<IncResultOutputResponse>) => {
        // res.data.errorCode ="TXXXX"
        // res.data.result = false;
        // res.data.errorMessage = "削除が正常に完了しませんでした。"
        if (res.data.result) {
          setData(newData.sort((a, b) => (a.enqueteId > b.enqueteId ? -1 : 1)));
        }
        setDeleteResult(res.data);
      });
    }

    setDeleteModal(false);
  };

  // モーダル（No）イベント
  const handleClickModalNo = (): void => {
    setDeleteModal(false);
    setEnqueteId(undefined);
  };

  // クライアント用ダウンロードイベント
  const onDownloadForClient = (id: number, name: string) => {
    enqueteForDownloadApi
      .enqueteForClientDownload(id)
      .then((res: AxiosResponse<string>) => {
        execDownload(
          res.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          `${name}.xlsx`
        ).catch(() => history.push(Url.COMMON_ERROR));
      })
      .catch(() => history.push(Url.COMMON_ERROR));
  };

  // コピーボタンイベント
  const onCopy = (targetRow: EnqueteListOutputResponse) => {
    const copyParam: EnqueteCopyFormResponse = {
      enqueteId: targetRow.enqueteId,
    };

    enqueteCopyApi.enqueteCopy(copyParam).then((res: AxiosResponse<EnqueteCopyOutputResponse>) => {
      if (res.data.enqueteId !== undefined && res.data.enqueteName !== undefined) {
        setData(
          [...data, { ...targetRow, enqueteId: res.data.enqueteId, enqueteName: res.data.enqueteName }].sort((a, b) =>
            a.enqueteId > b.enqueteId ? -1 : 1
          )
        );
      }
    });
  };

  // 【事前】
  // 【事後 積上式】
  // 【その他】
  // 【会員属性】
  // 【アンケートモニター】タブの場合のTableContents
  const tableContents1 = (
    <>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>アンケートID</th>
          <th>アンケート名/説明</th>
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((row: EnqueteListOutputResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'center' }}>{row?.enqueteId}</td>
              <td className="text-secondary">
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events */}
                {when(
                  !salesFlg,
                  <a
                    href={`${Url.KEISAI.ENQUETE_MODIFY}/${enqueteType}/${row.enqueteId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row?.enqueteName}
                  </a>
                )}
                {when(salesFlg, <>{row?.enqueteName}</>)}
                <br />
                {row?.description}
              </td>
              <td style={{ textAlign: 'center' }}>
                <OverlayTrigger placement="top-end" overlay={companyDownloadTooltip} rootClose>
                  <Button
                    data-testid="add"
                    variant="link"
                    className="text-secondary p-0"
                    onClick={() => {
                      console.log('download');
                      onDownload(row.enqueteId, row.enqueteName);
                    }}
                  >
                    <FontAwesomeIcon icon={faFileDownload} fixedWidth className="me-1" />
                  </Button>
                </OverlayTrigger>
                {when(
                  enqueteType === ENQUETE_TYPE.BEFOREHAND || enqueteType === ENQUETE_TYPE.PILEUP,
                  <>
                    <OverlayTrigger placement="top-end" overlay={clientDownloadTooltip} rootClose>
                      <Button
                        variant="link"
                        className="text-secondary ms-2"
                        onClick={() => onDownloadForClient(row.enqueteId, row.enqueteName)}
                      >
                        <FontAwesomeIcon icon={faFileDownload} fixedWidth className="me-1" />
                      </Button>
                    </OverlayTrigger>
                  </>
                )}
                {when(
                  !salesFlg,
                  <OverlayTrigger trigger="click" placement="top-end" overlay={copyTooltip} rootClose>
                    <Button data-testid="copy" variant="link" className="text-secondary" onClick={() => onCopy(row)}>
                      <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
                    </Button>
                  </OverlayTrigger>
                )}

                {/* <Button
                  data-testid="delete"
                  variant="link"
                  className="text-secondary"
                  onClick={() => {
                    setEnqueteId(row.enqueteId);
                    setDeleteModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faTimesCircle} fixedWidth className="me-1" />
                </Button> */}
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  // 【事後 選択式】タブの場合のTableContents
  const tableContents2 = (
    <>
      <thead>
        <tr>
          <th style={{ textAlign: 'center' }}>アンケートID</th>
          <th>アンケート名/説明</th>
          <th style={{ textAlign: 'center' }}>
            管理
            <br />
            クライアント
          </th>
        </tr>
      </thead>
      <tbody>
        {listPerPage?.map((row: EnqueteListOutputResponse, index: number) => {
          return (
            <tr key={index} className="align-middle">
              <td style={{ textAlign: 'center' }}>{row?.enqueteId}</td>
              <td className="text-secondary">
                {when(
                  !salesFlg,
                  <a
                    href={`${Url.KEISAI.ENQUETE_MODIFY}/${enqueteType}/${row.enqueteId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {row?.enqueteName}
                  </a>
                )}
                {when(salesFlg, <>{row?.enqueteName}</>)}
                <br />
                {row?.description}
              </td>
              <td width="15%" style={{ textAlign: 'center' }}>
                {row?.clientName}
              </td>
              <td width="15%">
                <div style={{ textAlign: 'center' }}>
                  <OverlayTrigger placement="top-end" overlay={companyDownloadTooltip} rootClose>
                    <Button
                      data-testid="add"
                      variant="link"
                      className="text-secondary p-0"
                      onClick={() => {
                        console.log('download');
                        onDownload(row.enqueteId, row.enqueteName);
                      }}
                    >
                      <FontAwesomeIcon icon={faFileDownload} fixedWidth className="me-1" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top-end" overlay={clientDownloadTooltip} rootClose>
                    <Button
                      variant="link"
                      className="text-secondary ms-2"
                      onClick={() => onDownloadForClient(row.enqueteId, row.enqueteName)}
                    >
                      <FontAwesomeIcon icon={faFileDownload} fixedWidth className="me-1" />
                    </Button>
                  </OverlayTrigger>
                  {when(
                    !salesFlg,
                    <OverlayTrigger trigger="click" placement="top-end" overlay={copyTooltip} rootClose>
                      <Button data-testid="copy" variant="link" className="text-secondary" onClick={() => onCopy(row)}>
                        <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
                      </Button>
                    </OverlayTrigger>
                  )}
                  {/* <Button
                    data-testid="delete"
                    variant="link"
                    className="text-secondary"
                    onClick={() => {
                      setEnqueteId(row.enqueteId);
                      setDeleteModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} fixedWidth className="me-1" />
                  </Button> */}
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </>
  );

  return (
    <>
      <Modal show={deleteModalFlg} onHide={handleClickModalNo}>
        <Modal.Header style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>削除してよろしいですか？</Modal.Header>
        <Modal.Footer>
          <Button className="me-2" variant="secondary" onClick={handleClickModalNo}>
            いいえ
          </Button>
          <Button variant="primary" onClick={handleClickModalYes}>
            はい
          </Button>
        </Modal.Footer>
      </Modal>
      <Table>
        {enqueteType === ENQUETE_TYPE.BEFOREHAND && tableContents1}
        {enqueteType === ENQUETE_TYPE.SELECT && tableContents2}
        {enqueteType === ENQUETE_TYPE.PILEUP && tableContents1}
        {enqueteType === ENQUETE_TYPE.OTHER && tableContents1}
        {enqueteType === ENQUETE_TYPE.MEMBERSHIP && tableContents1}
        {enqueteType === ENQUETE_TYPE.ENQUETE_MONITOR && tableContents1}
      </Table>
    </>
  );
};
