import { useQuery } from '@tanstack/react-query';
import { ApiClientApi, BillingApi } from '../api-client';
import { CACHE_KEY_API_CLIENT, CACHE_KEY_BILLING_AGENT } from '../Constants';

export default function useSuggestApiClient() {
  const apiClientApi = new ApiClientApi();

  const result = useQuery({
    queryKey: [CACHE_KEY_API_CLIENT],
    queryFn: async () =>
      apiClientApi.apiClientList().then((res) => {
        return res;
      }),
  });
  return {
    isSuccess: result.isSuccess,
    data: result.data?.data,
  };
}
