import { Form } from 'react-bootstrap';
import * as React from 'react';
import { UseFormRegister } from 'react-hook-form';

interface Props {
  register: UseFormRegister<any>;
  registerName: string;
  type?: string;
  as?: 'input' | 'textarea';
  id?: string;
  rows?: number;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  step?: string;
}

export const FormControl: React.VFC<Props> = ({ register, registerName, className = 'mb-4', ...rest }) => {
  return <Form.Control {...register(registerName)} className={className} {...rest} />;
};
