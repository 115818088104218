import * as React from 'react';
import { Form, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
/* eslint-disable import/no-cycle */
import { ApplicationInfoListOutputResponse } from '../../../api-client';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import { Url } from '../../../constants/Url';
import { Button } from '../../atoms/Button';
import { REQUEST_STATUS } from '../../pages/Application/ApplicationPage';
/* eslint-enable import/no-cycle */

export interface Props {
  listPerPage: ApplicationInfoListOutputResponse['applicationList'];
  currentClient: { clientId?: number; applicationId?: number };
  setCurrentClient: DispatchSetState<{ clientId?: number; applicationId?: number; isRequested?: boolean }>;
}

export const APPLICATION_TYPE = ['新規', '追加', '変更', '解約', '休止'] as const;
export const APPLICATION_STATUS = ['承認依頼中', '承認', '差戻', '削除'] as const;
export const CONTRACT_STATUS = ['通常', '休止中', '解約'] as const;

export const ApplicationTable: React.VFC<Props> = ({ listPerPage, currentClient, setCurrentClient }) => {
  const testid = createTestId(ApplicationTable);
  const history = useHistory();

  return (
    <Table className="mb-4" data-testid={testid('table')}>
      <thead>
        <tr>
          <th>&nbsp;</th>
          <th>申込書ID</th>
          <th>クライアントID</th>
          <th>クライアント名</th>
          <th>契約名</th>
          <th>契約ステータス</th>
          <th>申込書名</th>
          <th>申込種別</th>
          <th>期間</th>
          <th>依頼状況</th>
          <th>申込書締結状況</th>
          <th>営業担当者</th>
        </tr>
      </thead>
      <tbody>
        {listPerPage.map(
          (
            {
              clientId,
              applicationId,
              applicationName,
              clientName,
              contractName,
              contractStatus,
              applicationType,
              contractStartDate,
              contractEndDate,
              postingRequestStatus,
              applicationStatus,
              roiMemberName,
            },
            i
          ) => (
            <tr key={applicationId} className="align-middle">
              <td>
                <Form.Check.Input
                  type="radio"
                  name="radioGroup01"
                  data-testid={testid('radio', i)}
                  checked={currentClient.applicationId === applicationId}
                  onChange={() =>
                    setCurrentClient({
                      clientId,
                      applicationId,
                      isRequested: postingRequestStatus === REQUEST_STATUS.indexOf('依頼済み'),
                    })
                  }
                />
              </td>
              <td>{applicationId}</td>
              <td>{clientId}</td>
              <td>{clientName}</td>
              <td>{contractName}</td>
              <td>{contractStatus !== undefined && CONTRACT_STATUS[contractStatus]}</td>
              <td>
                <Button
                  variant="link"
                  className="p-0"
                  onClick={() => {
                    history.push({
                      pathname: Url.KEISAI.APPLICATION_MODIFY,
                      state: { applicationId, clientId, clientName },
                    });
                  }}
                >
                  {applicationName}
                </Button>
              </td>
              <td>{APPLICATION_TYPE[applicationType]}</td>
              <td>
                {contractStartDate} ~ {contractEndDate}
              </td>
              <td>{REQUEST_STATUS[postingRequestStatus]}</td>
              <td>{APPLICATION_STATUS[applicationStatus]}</td>
              <td>{roiMemberName}</td>
            </tr>
          )
        )}
      </tbody>
    </Table>
  );
};
