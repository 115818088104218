import { AxiosResponse } from 'axios';
import { TokenObj } from '../interfaces/TokenObj';
// eslint-disable-next-line import/no-cycle
import { AuthUserOutputResponse, GetUserControllerApi, LoginEndpointApi } from '../api-client';
// import type { LoginEndpointApi } from '../api-client'
import { CurrentUser } from '../interfaces/user';
import type { IFormDatas } from '../components/pages/LoginPage';
import { TokenCookies } from './CookiesService';

const cookie = new TokenCookies();

// ログイン処理
export const login = async (loginData: IFormDatas): Promise<unknown> => {
  return new Promise((resolve) => {
    const loginApi = new LoginEndpointApi();

    loginApi
      .apiLoginPost(loginData)
      .then((response: AxiosResponse<unknown>) => {
        const tokenObj: TokenObj = {
          access_token: response.headers.accesstoken,
          refresh_token: response.headers.refreshtoken,
        };
        cookie.setTokens(tokenObj);
        resolve(response.headers.accesstoken);
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  });
};

// JWTより情報取得
// export const getJwt = async () => {
//   return new Promise((resolve, reject) => {
//     const refresh = new RefreshTokenControllerApi();
//
//     refresh
//       .refreshToken()
//       .then(
//         (
//           response: AxiosResponse<{
//             auth: boolean;
//             accessToken: string;
//             refreshToken: string;
//           }>
//         ) => {
//           if (!response.data.auth) {
//             logout();
//             resolve(false);
//           } else {
//             const tokenObj: TokenObj = {
//               access_token: response.data.accessToken,
//               refresh_token: response.data.refreshToken,
//             };
//             LocalStrageService.setToken(tokenObj);
//             resolve(response.data);
//           }
//         }
//       )
//       .catch((error) => {
//         console.log(error);
//       });
//   });
// };

/**
 * ログイン済みのユーザ情報を取得する
 */
export const getUser = async (): Promise<false | void | CurrentUser> => {
  const userApi = new GetUserControllerApi();
  try {
    return await userApi
      .user()
      .then((response: AxiosResponse<AuthUserOutputResponse>) => {
        const user: CurrentUser = response.data;
        if (!user.auth) {
          return false;
        }
        return user;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    return false;
  }
};
/**
 * ログアウト
 */
export const logout: () => void = () => {
  cookie.clearTokens();
};

// 現在のユーザ
export const getCurrentJwt: () => TokenObj | boolean = () => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [access_token, refresh_token] = cookie.getTokens();

  if (access_token != null && refresh_token != null) {
    const tokenObj: TokenObj = {
      access_token,
      refresh_token,
    };
    return tokenObj;
  }
  return false;
};
