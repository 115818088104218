import React from 'react';
import { useSafeContext } from '../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../store/correctionStore';
import { EnqueteCheckListSection } from './EnqueteCheckListSection';
import { ReceiptCheckListSection } from './ReceiptCheckListSection';

export const CheckListSection: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  return !$.isEnquete ? <ReceiptCheckListSection /> : <EnqueteCheckListSection />;
};
