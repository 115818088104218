import React, { useState } from 'react';
import { Col, Form, Row, Table } from 'react-bootstrap';
import type { ShopReplaceExecuteFormResponse } from '../../../api-client';
import { DispatchSetState } from '../../../interfaces/utils';
import { createTestId } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import type { State as PageState } from '../../pages/ShopReplacePage';
import { Alert } from '../../atoms/Alert';

export const ShopReplaceForceExecuteModal: React.FC<{
  applyId: number;
  afterMonitorBaseId: number;
  comment: string | undefined;
  onSubmit: (shopReplaceExecutionFormResponse: ShopReplaceExecuteFormResponse) => void;
  isNotRemainingBorder: boolean;
}> = ({ applyId, afterMonitorBaseId, comment, onSubmit, isNotRemainingBorder }) => {
  const [isForceModal, setIsForceModal] = useState(isNotRemainingBorder);

  return (
    <>
      <Modal
        isModal={isForceModal}
        centered
        closeButton
        onHide={() => setIsForceModal(false)}
        body={
          <>
            <div>残枠なし強制実行をしてもよろしいですか？</div>
            <div className="d-flex justify-content-center mt-2">
              <Button
                className="me-2"
                onClick={() => {
                  onSubmit({
                    applyId,
                    afterReplaceMonitorBaseId: afterMonitorBaseId,
                    comment,
                    noRemainingMonitorForceExecFlg: true,
                  });
                }}
              >
                実行
              </Button>
              <Button onClick={() => setIsForceModal(false)}>キャンセル</Button>
            </div>
          </>
        }
      />
    </>
  );
};
