import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { createTestId } from '../../utils/functions';

export type Props = DropzoneOptions & {
  // useDropzoneの引数以外のPropsを設定する場合はここに追加する
  type?: 'image' | 'excel';
};

export const Dropzone: React.VFC<Props> = ({
  // useDropzoneの引数以外のPropsを設定する場合はここに追加する,
  type,
  // 残りは本家に流す
  ...rest
}) => {
  const testid = createTestId(Dropzone);
  const accept = useMemo(() => {
    if (!type) return undefined;
    switch (type) {
      case 'image':
        return 'image/*';
      case 'excel':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:
        throw new Error('無効なtype');
    }
  }, [type]);

  const dropzoneOptions = rest || {};
  const { getRootProps, getInputProps } = useDropzone({
    // useDropzoneの引数以外のPropsを設定する場合はここに追加する,
    accept,
    // 残りは本家とマージ
    ...dropzoneOptions,
  });
  const { disabled } = dropzoneOptions;

  return (
    <Card
      className={`py-4 ${disabled ? 'text-muted' : ''}`}
      style={{ border: '1px dashed #ced4da', cursor: `${disabled ? 'default' : 'pointer'}` }}
      data-testid={testid('parent')}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <div className="m-auto">
        <div>
          <span data-testid={testid('label-top')}>ここにファイルをドラッグ</span>
          <br />
          <span data-testid={testid('label-bottom')} className="d-block text-center text-decoration-underline">
            ファイルを添付
          </span>
        </div>
      </div>
    </Card>
  );
};
