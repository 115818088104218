import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../store/correctionStore';
import { uuid, when } from '../../../../utils/functions';
import { Answer, EscalationItem } from './parts/Answer';
import { Post } from './parts/Post';
import { Situator } from './parts/Situator';
import { Thread } from './parts/Thread';
import { BadgeType } from '../../../atoms/BadgeHead';
import { EscalationRejectStatus } from '../../../pages/Correction/constants';
import { ESCALATION_ID } from '../../../../Constants';

// TODO: [添削] テスト未実装
/** エスカレ */
export const Escalation: React.FC<{
  isReject?: boolean;
  escaletes: EscalationItem[];
  enableEmptyLabel?: true;
}> = React.memo(({ isReject, escaletes, enableEmptyLabel }) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  useEffect(() => {
    const escalaionId = String(localStorage.getItem(ESCALATION_ID));
    if (escalaionId) {
      const targetElem = document.getElementById(escalaionId);
      if (targetElem) targetElem.scrollIntoView();
    }
    localStorage.setItem(ESCALATION_ID, '');
  }, [localStorage.getItem(ESCALATION_ID)]);
  if (escaletes.length === 0) return <></>;
  return (
    <Card style={{ marginTop: '10px' }}>
      <Card.Header>
        {!isReject ? (
          <Card.Title>エスカレ履歴 {escaletes.length === 1 ? '' : `(${escaletes.length})`}</Card.Title>
        ) : (
          <Card.Title>{escaletes.length} 件の履歴</Card.Title>
        )}
      </Card.Header>
      <Card.Body>
        {(escaletes ?? []).map((escalationItem) => {
          const {
            status,
            template,
            createAt,
            inquiry: text,
            inquiryPerson,
            messageForExecutiveStaff,
            messageForCustomer,
            rejectRequestPerson,
            inquiryAnswerHistoryList,
            escalationId,
          } = escalationItem;
          const { roleName = '', id = NaN, name = '' } = (!isReject ? inquiryPerson : rejectRequestPerson) ?? {};
          // エスカレの場合かつ完了じゃないときはステータスが目立つようにしたいので、そのためのBadgeTypeを設定する
          const badgeType =
            !isReject && status?.id !== EscalationRejectStatus.COMPLETE ? BadgeType.MUST_ACTION : BadgeType.INFO;
          return (
            <React.Fragment key={uuid()}>
              {/* TODO:B 本当はidでページ内遷移したいが動かないので直す */}
              <div id={escalationId ? `escalationId${escalationId.toString()}` : ''}>
                <Thread
                  mark={(isReject ? template?.name : status?.name) ?? ''}
                  badgeType={badgeType}
                  posts={
                    <>
                      <Post
                        header="質問"
                        indent={0}
                        posts={[
                          {
                            text,
                            messageForExecutiveStaff,
                            messageForCustomer,
                            createAt,
                            roleName,
                            id,
                            name,
                          },
                        ]}
                      />
                      <hr />
                      {when(
                        !isReject,
                        <Answer
                          escalationItem={escalationItem}
                          displayControl={$.res_correctReceiptDisplayControl ?? $.res_correctEnqueteDisplayControl}
                        />
                      )}
                    </>
                  }
                />
                <Situator list={inquiryAnswerHistoryList ?? []} />
              </div>
            </React.Fragment>
          );
        })}
      </Card.Body>
    </Card>
  );
});
