import { faLink, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Button, Col, Form, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { MonitorRuleSetCommonContents } from './MonitorRuleSetCommonContents';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「調査証明」Contents
export const MonitorRuleSetSurveyProofContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  // 予約ラジオボタン有効無効化フラグ
  const [radioDisableFlg, setRadioDisableFlg] = useState<boolean>(true);
  // 「電話予約」かどうか
  const [reserveTypePhoneFlg, setreserveTypePhoneFlg] = useState<boolean>(false);
  // 「WEB予約」かどうか
  const [reserveTypeWEBFlg, setreserveTypeWEBFlg] = useState<boolean>(false);

  const evidenceTypeList = [
    {
      id: 0,
      name: 'レシート',
    },
    {
      id: 1,
      name: '領収書',
    },
    {
      id: 2,
      name: '食券',
    },
    {
      id: 3,
      name: '食券または領収書',
    },
    {
      id: 4,
      name: 'レシートまたは領収書',
    },
    {
      id: 5,
      name: 'その他追加',
    },
  ];

  return (
    <>
      <div className="d-flex ">
        <Form.Label style={{ fontWeight: 'bold' }}>利用可能</Form.Label>
      </div>
      <div className="d-flex">
        <Form.Select
          className="mb-4"
          value={
            data?.monitorRuleList?.find((r) => {
              return r.monitorRuleType === 14;
            })?.evidenceType || ''
          }
          style={{ width: '25%' }}
          onChange={(e) => {
            setData({
              ...data,
              monitorRuleList: data?.monitorRuleList.map((_, i) => {
                return _.monitorRuleType === 14 ? { ..._, evidenceType: Number(e.target.value) } : { ..._ };
              }),
            });
          }}
        >
          {evidenceTypeList.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </Form.Select>

        <div>
          <Button
            className="ms-5"
            variant="secondary"
            onClick={() => {
              let reflectionText = '';
              let supplementText = '';
              const evidenceType: number | undefined = data?.monitorRuleList?.find((r) => {
                return r.monitorRuleType === 14;
              })?.evidenceType;
              if (evidenceType !== undefined) {
                // 「レシート」を選択した場合
                if (evidenceType === 0) {
                  reflectionText = `モニター調査の証明として、下記「証明物」を【レシートを提出する】ボタンより提出してください。\n 【証明物】\n  ${
                    data.monitorRuleSetType === 6 ? '施術内容' : '飲食内容'
                  }が明記された「レシート」の画像`;
                  supplementText =
                    '※クレジットカード控え・領収書では受理できません。\n※全体（四隅や印字内容すべて）が写るように撮影してください。\n※同伴者含め「領収書の発行を依頼する行為」は原則禁止です。領収書の発行を依頼することにより、レシートの発行ができなくなります。\n   ただし「レシート」を店舗都合で受け取れない場合に限り、領収書の発行を依頼し、提出してください。事務局から店舗に事実確認を行います。\n※調査証明を他サービスと兼用することは禁止です。\n※提出後の訂正は認められませんので、受け取った直後、その場で内容をご確認ください。\n   内容に誤りがある場合、その場で店舗に訂正を依頼してください。';
                }
                // 「領収書」を選択した場合
                else if (evidenceType === 1) {
                  reflectionText =
                    'モニター調査の証明として、下記「証明物」を【レシートを提出する】より提出してください。\n 【証明物】\n  日付・店舗名が明記された「領収書」の画像';
                  supplementText =
                    '※全体（四隅や印字内容すべて）が写るように撮影してください。\n※クレジットカード控えでは受理できません。\n※券売機の場合、領収書発行ボタンで領収書を発行してください。ボタンがない場合は、店舗スタッフへ領収書発行をご依頼ください。\n※調査証明を他サービスと兼用することは禁止です。\n※提出後の訂正は認められませんので、受け取った直後、その場で内容をご確認ください。\n   内容に誤りがある場合、その場で店舗に訂正を依頼してください。';
                }
                // 「食券」を選択した場合
                else if (evidenceType === 2) {
                  reflectionText =
                    'モニター調査の証明として、下記「証明物」を【レシートを提出する】より提出してください。\n 【証明物】\n  「食券」の画像';
                  supplementText =
                    '※食券販売機にて食券を購入後、その場で食券を写真撮影し、マイページより提出してください。\n   承認が完了するまで保管しておいてください。（店内にて食券が回収される為、食券購入後、すぐに食券販売機前で撮影をお済ませください。）\n※全体（四隅や印字内容すべて）が写るように撮影してください。\n※クレジットカード控え・領収書では受理できません。\n※調査証明を他サービスと兼用することは禁止です。\n※提出後の訂正は認められませんので、受け取った直後、その場で内容をご確認ください。\n   内容に誤りがある場合、その場で店舗に訂正を依頼してください。';
                }
                // 「食券または領収書」を選択した場合
                else if (evidenceType === 3) {
                  reflectionText =
                    'モニター調査の証明として、下記「証明物」を【レシートを提出する】より提出してください。\n 【証明物】\n  「食券」または、日付・店舗名が明記された「領収書」の画像';
                  supplementText =
                    '※食券販売機にて食券を購入後、その場で食券を写真撮影し、マイページより提出してください。\n   承認が完了するまで保管しておいてください。（店内にて食券が回収される為、食券購入後、すぐに食券販売機前で撮影をお済ませください。）\n※全体（四隅や印字内容すべて）が写るように撮影してください。\n※クレジットカード控えでは受理できません。\n※調査証明を他サービスと兼用することは禁止です。\n※提出後の訂正は認められませんので、受け取った直後、その場で内容をご確認ください。\n   内容に誤りがある場合、その場で店舗に訂正を依頼してください。';
                }
                // 「レシートまたは領収書」を選択した場合
                else if (evidenceType === 4) {
                  reflectionText =
                    'モニター調査の証明として、下記「証明物」を【レシートを提出する】より提出してください。\n【証明物】\n「レシート」または、日付・店舗名が明記された「領収書」の画像';
                  supplementText =
                    '※全体（四隅や印字内容すべて）が写るように撮影してください。\n※クレジットカード控えでは受理できません。\n※調査証明を他サービスと兼用することは禁止です。\n※提出後の訂正は認められませんので、受け取った直後、その場で内容をご確認ください。\n   内容に誤りがある場合、その場で店舗に訂正を依頼してください。';
                } else {
                  reflectionText =
                    'モニター調査の証明として、下記「証明物」を【レシートを提出する】より提出してください。\n【証明物】\n「クレジットカード控え」の画像';
                  supplementText =
                    '※全体（四隅や印字内容すべて）が写るように撮影してください。\n※調査証明を他サービスと兼用することは禁止です。\n※提出後の訂正は認められませんので、受け取った直後、その場で内容をご確認ください。\n   内容に誤りがある場合、その場で店舗に訂正を依頼してください。';
                }
              }

              let copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                [],
                data.monitorRuleList.find((supplement) => {
                  return supplement.monitorRuleType === rowData.monitorRuleType;
                })?.supplementList
              );
              copySupplementList = copySupplementList.map((r, i) => {
                return i === 0 ? { ...r, supplement: supplementText } : { ...r };
              });

              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return row.monitorRuleType === 14
                    ? {
                        ...row,
                        beforeApplyContent: reflectionText,
                        afterApplyContent: reflectionText,
                        supplementList: copySupplementList,
                      }
                    : { ...row };
                }),
              });
            }}
          >
            反映
          </Button>
        </div>
      </div>
      <MonitorRuleSetCommonContents
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
