import React, { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { CustomerInfoApi, OemFeeListOutputResponse } from '../../../api-client';
import { PaginationTable } from './PaginationTable';
import { formatISODate } from '../../../utils/functions';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';

interface Props {
  customerId: number;
}

export const OemFeeListTable: React.FC<Props> = ({ customerId }) => {
  const [dataList, setList] = useState<OemFeeListOutputResponse[]>([]);
  const api = useMemo(() => new CustomerInfoApi(), []);
  const MAX_RECORD_PER_PAGE = useMemo(() => 20, []);
  const headers = useMemo(() => ['ID', '送信日時', '利用金額', '謝礼', '手数料', 'OEM先', '応募ID', '店舗名'], []);

  // 初期フェッチ
  useEffect(() => {
    api.oemFeeList(customerId).then((res: AxiosResponse<OemFeeListOutputResponse[]>) => {
      setList(res.data);
    });
  }, []);
  return (
    <>
      <PaginationTable resultNum={MAX_RECORD_PER_PAGE} list={dataList} headerNames={headers}>
        {(list) => {
          return (list as OemFeeListOutputResponse[]).map((data: OemFeeListOutputResponse) => (
            <React.Fragment key={data.eventMessageId}>
              <tr className="align-middle" key={`${data.eventMessageId}`}>
                <OemFeeTableRecord data={data} />
              </tr>
            </React.Fragment>
          ));
        }}
      </PaginationTable>
    </>
  );
};

const OemFeeTableRecord: React.FC<{ data: OemFeeListOutputResponse }> = ({ data }) => {
  const { eventMessageId, sendAt, approveAmount, appliedFee, chargeFee, oemName, applyId, shopName } = data;
  return (
    <>
      <td key={eventMessageId}>{eventMessageId}</td>
      <td key={sendAt}>{formatISODate(sendAt, DATETIME_DISPLAY_FORMAT)}</td>
      <td key={approveAmount}>{approveAmount}</td>
      <td key={appliedFee}>{appliedFee}</td>
      <td key={chargeFee}>{chargeFee}</td>
      <td key={oemName}>{oemName}</td>
      <td key={applyId}>{applyId}</td>
      <td key={shopName}>{shopName}</td>
    </>
  );
};
