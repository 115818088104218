import * as React from 'react';
import { Table } from 'react-bootstrap';

export interface Props {
  listPerPage: any[];
}

export const BannerInsiteTable: React.VFC<Props> = ({ listPerPage }) => {
  return (
    <Table className="mb-4">
      <thead>
        <tr>
          <th>ID</th>
          <th>広告名称</th>
          <th>インプレッション</th>
          <th>クリック数</th>
          <th>CTR</th>
        </tr>
      </thead>
      <tbody>
        {listPerPage.map(({ id, title, impression, clickNum, ctr }) => {
          return (
            <tr key={id} className="align-middle">
              <td>{id}</td>
              <td>{title}</td>
              <td>{impression}</td>
              <td>{clickNum}</td>
              <td>{ctr}%</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
