/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CorrectRejectApprovalExecuteFormResponse } from '../domains';
// @ts-ignore
import { CorrectionOkExecuteFormResponse } from '../domains';
// @ts-ignore
import { CorrectionTellNoCheckFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { ReceiptCorrectDisplayControlOutputResponse } from '../domains';
// @ts-ignore
import { ReceiptCorrectRejectApprovalRequestFormResponse } from '../domains';
// @ts-ignore
import { ReceiptCorrectionEscalationAnswerExecuteFormResponse } from '../domains';
// @ts-ignore
import { ReceiptCorrectionEscalationExecuteFormResponse } from '../domains';
// @ts-ignore
import { ReceiptCorrectionNgExecuteFormResponse } from '../domains';
// @ts-ignore
import { ReceiptCorrectionOkReleaseFormResponse } from '../domains';
// @ts-ignore
import { ReceiptNoDuplicateCheckFormResponse } from '../domains';
// @ts-ignore
import { ReceiptNoDuplicateCheckOutputResponse } from '../domains';
// @ts-ignore
import { ResultOutputResponse } from '../domains';
// @ts-ignore
import { VisitDateTimeCheckFormResponse } from '../domains';
// @ts-ignore
import { VisitDateTimeCheckOutputResponse } from '../domains';
/**
 * CorrectReceiptApi - axios parameter creator
 * @export
 */
export const CorrectReceiptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 却下承認依頼の回答を実行する
         * @summary 却下承認依頼回答実行
         * @param {CorrectRejectApprovalExecuteFormResponse} correctRejectApprovalExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctRejectApprovalExecute: async (correctRejectApprovalExecuteFormResponse: CorrectRejectApprovalExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'correctRejectApprovalExecuteFormResponse' is not null or undefined
            assertParamExists('correctRejectApprovalExecute', 'correctRejectApprovalExecuteFormResponse', correctRejectApprovalExecuteFormResponse)
            const localVarPath = `/api/correct/receipt/rejection-approval-request-answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correctRejectApprovalExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 添削OK処理を実行する
         * @summary 調査証明添削OK実行
         * @param {CorrectionOkExecuteFormResponse} correctionOkExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionOkExecute: async (correctionOkExecuteFormResponse: CorrectionOkExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'correctionOkExecuteFormResponse' is not null or undefined
            assertParamExists('correctionOkExecute', 'correctionOkExecuteFormResponse', correctionOkExecuteFormResponse)
            const localVarPath = `/api/correct/receipt/correction-ok`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correctionOkExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 電話番号が同様かチェックする
         * @summary 調査証明添削電話番号チェック
         * @param {CorrectionTellNoCheckFormResponse} correctionTellNoCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionTellNoCheck: async (correctionTellNoCheckFormResponse: CorrectionTellNoCheckFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'correctionTellNoCheckFormResponse' is not null or undefined
            assertParamExists('correctionTellNoCheck', 'correctionTellNoCheckFormResponse', correctionTellNoCheckFormResponse)
            const localVarPath = `/api/correct/receipt/tell-no-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correctionTellNoCheckFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レシート添削画面制御
         * @summary レシート添削画面制御
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectDisplayControl: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('receiptCorrectDisplayControl', 'applyId', applyId)
            const localVarPath = `/api/correct/receipt/display-control/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エスカレ回答を実行する
         * @summary エスカレ回答実行
         * @param {ReceiptCorrectionEscalationAnswerExecuteFormResponse} receiptCorrectionEscalationAnswerExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionEscalationAnswerExecute: async (receiptCorrectionEscalationAnswerExecuteFormResponse: ReceiptCorrectionEscalationAnswerExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptCorrectionEscalationAnswerExecuteFormResponse' is not null or undefined
            assertParamExists('receiptCorrectionEscalationAnswerExecute', 'receiptCorrectionEscalationAnswerExecuteFormResponse', receiptCorrectionEscalationAnswerExecuteFormResponse)
            const localVarPath = `/api/correct/receipt/escalation-answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptCorrectionEscalationAnswerExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エスカレを実行する
         * @summary エスカレ実行
         * @param {ReceiptCorrectionEscalationExecuteFormResponse} receiptCorrectionEscalationExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionEscalationExecute: async (receiptCorrectionEscalationExecuteFormResponse: ReceiptCorrectionEscalationExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptCorrectionEscalationExecuteFormResponse' is not null or undefined
            assertParamExists('receiptCorrectionEscalationExecute', 'receiptCorrectionEscalationExecuteFormResponse', receiptCorrectionEscalationExecuteFormResponse)
            const localVarPath = `/api/correct/receipt/escalation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptCorrectionEscalationExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 添削NG処理を実行する
         * @summary 添削NG実行
         * @param {ReceiptCorrectionNgExecuteFormResponse} receiptCorrectionNgExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionNgExecute: async (receiptCorrectionNgExecuteFormResponse: ReceiptCorrectionNgExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptCorrectionNgExecuteFormResponse' is not null or undefined
            assertParamExists('receiptCorrectionNgExecute', 'receiptCorrectionNgExecuteFormResponse', receiptCorrectionNgExecuteFormResponse)
            const localVarPath = `/api/correct/receipt/correction-ng`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptCorrectionNgExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 添削OKを解除する
         * @summary 添削OK解除
         * @param {ReceiptCorrectionOkReleaseFormResponse} receiptCorrectionOkReleaseFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionOkRelease: async (receiptCorrectionOkReleaseFormResponse: ReceiptCorrectionOkReleaseFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptCorrectionOkReleaseFormResponse' is not null or undefined
            assertParamExists('receiptCorrectionOkRelease', 'receiptCorrectionOkReleaseFormResponse', receiptCorrectionOkReleaseFormResponse)
            const localVarPath = `/api/correct/receipt/correct-ok-cancel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptCorrectionOkReleaseFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 購入証明添削却下を実施する
         * @summary 購入証明添削　却下実施
         * @param {ReceiptCorrectRejectApprovalRequestFormResponse} receiptCorrectRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionRejectExec: async (receiptCorrectRejectApprovalRequestFormResponse: ReceiptCorrectRejectApprovalRequestFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptCorrectRejectApprovalRequestFormResponse' is not null or undefined
            assertParamExists('receiptCorrectionRejectExec', 'receiptCorrectRejectApprovalRequestFormResponse', receiptCorrectRejectApprovalRequestFormResponse)
            const localVarPath = `/api/correct/receipt/rejection-exec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptCorrectRejectApprovalRequestFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 却下承認依頼を実行する
         * @summary 却下承認依頼送信
         * @param {ReceiptCorrectRejectApprovalRequestFormResponse} receiptCorrectRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionSendRejectionApprovalRequest: async (receiptCorrectRejectApprovalRequestFormResponse: ReceiptCorrectRejectApprovalRequestFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptCorrectRejectApprovalRequestFormResponse' is not null or undefined
            assertParamExists('receiptCorrectionSendRejectionApprovalRequest', 'receiptCorrectRejectApprovalRequestFormResponse', receiptCorrectRejectApprovalRequestFormResponse)
            const localVarPath = `/api/correct/receipt/rejection-approval-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptCorrectRejectApprovalRequestFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * レシートNo重複情報を取得する。
         * @summary レシートNoの重複チェック
         * @param {ReceiptNoDuplicateCheckFormResponse} receiptNoDuplicateCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptNoDuplicateCheck: async (receiptNoDuplicateCheckFormResponse: ReceiptNoDuplicateCheckFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'receiptNoDuplicateCheckFormResponse' is not null or undefined
            assertParamExists('receiptNoDuplicateCheck', 'receiptNoDuplicateCheckFormResponse', receiptNoDuplicateCheckFormResponse)
            const localVarPath = `/api/correct/receipt/receipt-no-duplicate-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(receiptNoDuplicateCheckFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 来店日チェック
         * @param {VisitDateTimeCheckFormResponse} visitDateTimeCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitDateTimeCheck: async (visitDateTimeCheckFormResponse: VisitDateTimeCheckFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'visitDateTimeCheckFormResponse' is not null or undefined
            assertParamExists('visitDateTimeCheck', 'visitDateTimeCheckFormResponse', visitDateTimeCheckFormResponse)
            const localVarPath = `/api/correct/receipt/visit-datetime-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visitDateTimeCheckFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorrectReceiptApi - functional programming interface
 * @export
 */
export const CorrectReceiptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorrectReceiptApiAxiosParamCreator(configuration)
    return {
        /**
         * 却下承認依頼の回答を実行する
         * @summary 却下承認依頼回答実行
         * @param {CorrectRejectApprovalExecuteFormResponse} correctRejectApprovalExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctRejectApprovalExecute(correctRejectApprovalExecuteFormResponse: CorrectRejectApprovalExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctRejectApprovalExecute(correctRejectApprovalExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 添削OK処理を実行する
         * @summary 調査証明添削OK実行
         * @param {CorrectionOkExecuteFormResponse} correctionOkExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctionOkExecute(correctionOkExecuteFormResponse: CorrectionOkExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctionOkExecute(correctionOkExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 電話番号が同様かチェックする
         * @summary 調査証明添削電話番号チェック
         * @param {CorrectionTellNoCheckFormResponse} correctionTellNoCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctionTellNoCheck(correctionTellNoCheckFormResponse: CorrectionTellNoCheckFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctionTellNoCheck(correctionTellNoCheckFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レシート添削画面制御
         * @summary レシート添削画面制御
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCorrectDisplayControl(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReceiptCorrectDisplayControlOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptCorrectDisplayControl(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * エスカレ回答を実行する
         * @summary エスカレ回答実行
         * @param {ReceiptCorrectionEscalationAnswerExecuteFormResponse} receiptCorrectionEscalationAnswerExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCorrectionEscalationAnswerExecute(receiptCorrectionEscalationAnswerExecuteFormResponse: ReceiptCorrectionEscalationAnswerExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptCorrectionEscalationAnswerExecute(receiptCorrectionEscalationAnswerExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * エスカレを実行する
         * @summary エスカレ実行
         * @param {ReceiptCorrectionEscalationExecuteFormResponse} receiptCorrectionEscalationExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCorrectionEscalationExecute(receiptCorrectionEscalationExecuteFormResponse: ReceiptCorrectionEscalationExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptCorrectionEscalationExecute(receiptCorrectionEscalationExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 添削NG処理を実行する
         * @summary 添削NG実行
         * @param {ReceiptCorrectionNgExecuteFormResponse} receiptCorrectionNgExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCorrectionNgExecute(receiptCorrectionNgExecuteFormResponse: ReceiptCorrectionNgExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptCorrectionNgExecute(receiptCorrectionNgExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 添削OKを解除する
         * @summary 添削OK解除
         * @param {ReceiptCorrectionOkReleaseFormResponse} receiptCorrectionOkReleaseFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCorrectionOkRelease(receiptCorrectionOkReleaseFormResponse: ReceiptCorrectionOkReleaseFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptCorrectionOkRelease(receiptCorrectionOkReleaseFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 購入証明添削却下を実施する
         * @summary 購入証明添削　却下実施
         * @param {ReceiptCorrectRejectApprovalRequestFormResponse} receiptCorrectRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCorrectionRejectExec(receiptCorrectRejectApprovalRequestFormResponse: ReceiptCorrectRejectApprovalRequestFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptCorrectionRejectExec(receiptCorrectRejectApprovalRequestFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 却下承認依頼を実行する
         * @summary 却下承認依頼送信
         * @param {ReceiptCorrectRejectApprovalRequestFormResponse} receiptCorrectRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptCorrectionSendRejectionApprovalRequest(receiptCorrectRejectApprovalRequestFormResponse: ReceiptCorrectRejectApprovalRequestFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptCorrectionSendRejectionApprovalRequest(receiptCorrectRejectApprovalRequestFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * レシートNo重複情報を取得する。
         * @summary レシートNoの重複チェック
         * @param {ReceiptNoDuplicateCheckFormResponse} receiptNoDuplicateCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async receiptNoDuplicateCheck(receiptNoDuplicateCheckFormResponse: ReceiptNoDuplicateCheckFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReceiptNoDuplicateCheckOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.receiptNoDuplicateCheck(receiptNoDuplicateCheckFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 来店日チェック
         * @param {VisitDateTimeCheckFormResponse} visitDateTimeCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async visitDateTimeCheck(visitDateTimeCheckFormResponse: VisitDateTimeCheckFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisitDateTimeCheckOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.visitDateTimeCheck(visitDateTimeCheckFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CorrectReceiptApi - factory interface
 * @export
 */
export const CorrectReceiptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorrectReceiptApiFp(configuration)
    return {
        /**
         * 却下承認依頼の回答を実行する
         * @summary 却下承認依頼回答実行
         * @param {CorrectRejectApprovalExecuteFormResponse} correctRejectApprovalExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctRejectApprovalExecute(correctRejectApprovalExecuteFormResponse: CorrectRejectApprovalExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.correctRejectApprovalExecute(correctRejectApprovalExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 添削OK処理を実行する
         * @summary 調査証明添削OK実行
         * @param {CorrectionOkExecuteFormResponse} correctionOkExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionOkExecute(correctionOkExecuteFormResponse: CorrectionOkExecuteFormResponse, options?: any): AxiosPromise<ResultOutputResponse> {
            return localVarFp.correctionOkExecute(correctionOkExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 電話番号が同様かチェックする
         * @summary 調査証明添削電話番号チェック
         * @param {CorrectionTellNoCheckFormResponse} correctionTellNoCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctionTellNoCheck(correctionTellNoCheckFormResponse: CorrectionTellNoCheckFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.correctionTellNoCheck(correctionTellNoCheckFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * レシート添削画面制御
         * @summary レシート添削画面制御
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectDisplayControl(applyId: number, options?: any): AxiosPromise<ReceiptCorrectDisplayControlOutputResponse> {
            return localVarFp.receiptCorrectDisplayControl(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * エスカレ回答を実行する
         * @summary エスカレ回答実行
         * @param {ReceiptCorrectionEscalationAnswerExecuteFormResponse} receiptCorrectionEscalationAnswerExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionEscalationAnswerExecute(receiptCorrectionEscalationAnswerExecuteFormResponse: ReceiptCorrectionEscalationAnswerExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.receiptCorrectionEscalationAnswerExecute(receiptCorrectionEscalationAnswerExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * エスカレを実行する
         * @summary エスカレ実行
         * @param {ReceiptCorrectionEscalationExecuteFormResponse} receiptCorrectionEscalationExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionEscalationExecute(receiptCorrectionEscalationExecuteFormResponse: ReceiptCorrectionEscalationExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.receiptCorrectionEscalationExecute(receiptCorrectionEscalationExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 添削NG処理を実行する
         * @summary 添削NG実行
         * @param {ReceiptCorrectionNgExecuteFormResponse} receiptCorrectionNgExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionNgExecute(receiptCorrectionNgExecuteFormResponse: ReceiptCorrectionNgExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.receiptCorrectionNgExecute(receiptCorrectionNgExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 添削OKを解除する
         * @summary 添削OK解除
         * @param {ReceiptCorrectionOkReleaseFormResponse} receiptCorrectionOkReleaseFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionOkRelease(receiptCorrectionOkReleaseFormResponse: ReceiptCorrectionOkReleaseFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.receiptCorrectionOkRelease(receiptCorrectionOkReleaseFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 購入証明添削却下を実施する
         * @summary 購入証明添削　却下実施
         * @param {ReceiptCorrectRejectApprovalRequestFormResponse} receiptCorrectRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionRejectExec(receiptCorrectRejectApprovalRequestFormResponse: ReceiptCorrectRejectApprovalRequestFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.receiptCorrectionRejectExec(receiptCorrectRejectApprovalRequestFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 却下承認依頼を実行する
         * @summary 却下承認依頼送信
         * @param {ReceiptCorrectRejectApprovalRequestFormResponse} receiptCorrectRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptCorrectionSendRejectionApprovalRequest(receiptCorrectRejectApprovalRequestFormResponse: ReceiptCorrectRejectApprovalRequestFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.receiptCorrectionSendRejectionApprovalRequest(receiptCorrectRejectApprovalRequestFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * レシートNo重複情報を取得する。
         * @summary レシートNoの重複チェック
         * @param {ReceiptNoDuplicateCheckFormResponse} receiptNoDuplicateCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiptNoDuplicateCheck(receiptNoDuplicateCheckFormResponse: ReceiptNoDuplicateCheckFormResponse, options?: any): AxiosPromise<Array<ReceiptNoDuplicateCheckOutputResponse>> {
            return localVarFp.receiptNoDuplicateCheck(receiptNoDuplicateCheckFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 来店日チェック
         * @param {VisitDateTimeCheckFormResponse} visitDateTimeCheckFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        visitDateTimeCheck(visitDateTimeCheckFormResponse: VisitDateTimeCheckFormResponse, options?: any): AxiosPromise<VisitDateTimeCheckOutputResponse> {
            return localVarFp.visitDateTimeCheck(visitDateTimeCheckFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CorrectReceiptApi - object-oriented interface
 * @export
 * @class CorrectReceiptApi
 * @extends {BaseAPI}
 */
export class CorrectReceiptApi extends BaseAPI {
    /**
     * 却下承認依頼の回答を実行する
     * @summary 却下承認依頼回答実行
     * @param {CorrectRejectApprovalExecuteFormResponse} correctRejectApprovalExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public correctRejectApprovalExecute(correctRejectApprovalExecuteFormResponse: CorrectRejectApprovalExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).correctRejectApprovalExecute(correctRejectApprovalExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 添削OK処理を実行する
     * @summary 調査証明添削OK実行
     * @param {CorrectionOkExecuteFormResponse} correctionOkExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public correctionOkExecute(correctionOkExecuteFormResponse: CorrectionOkExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).correctionOkExecute(correctionOkExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 電話番号が同様かチェックする
     * @summary 調査証明添削電話番号チェック
     * @param {CorrectionTellNoCheckFormResponse} correctionTellNoCheckFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public correctionTellNoCheck(correctionTellNoCheckFormResponse: CorrectionTellNoCheckFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).correctionTellNoCheck(correctionTellNoCheckFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * レシート添削画面制御
     * @summary レシート添削画面制御
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public receiptCorrectDisplayControl(applyId: number, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).receiptCorrectDisplayControl(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エスカレ回答を実行する
     * @summary エスカレ回答実行
     * @param {ReceiptCorrectionEscalationAnswerExecuteFormResponse} receiptCorrectionEscalationAnswerExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public receiptCorrectionEscalationAnswerExecute(receiptCorrectionEscalationAnswerExecuteFormResponse: ReceiptCorrectionEscalationAnswerExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).receiptCorrectionEscalationAnswerExecute(receiptCorrectionEscalationAnswerExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エスカレを実行する
     * @summary エスカレ実行
     * @param {ReceiptCorrectionEscalationExecuteFormResponse} receiptCorrectionEscalationExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public receiptCorrectionEscalationExecute(receiptCorrectionEscalationExecuteFormResponse: ReceiptCorrectionEscalationExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).receiptCorrectionEscalationExecute(receiptCorrectionEscalationExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 添削NG処理を実行する
     * @summary 添削NG実行
     * @param {ReceiptCorrectionNgExecuteFormResponse} receiptCorrectionNgExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public receiptCorrectionNgExecute(receiptCorrectionNgExecuteFormResponse: ReceiptCorrectionNgExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).receiptCorrectionNgExecute(receiptCorrectionNgExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 添削OKを解除する
     * @summary 添削OK解除
     * @param {ReceiptCorrectionOkReleaseFormResponse} receiptCorrectionOkReleaseFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public receiptCorrectionOkRelease(receiptCorrectionOkReleaseFormResponse: ReceiptCorrectionOkReleaseFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).receiptCorrectionOkRelease(receiptCorrectionOkReleaseFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 購入証明添削却下を実施する
     * @summary 購入証明添削　却下実施
     * @param {ReceiptCorrectRejectApprovalRequestFormResponse} receiptCorrectRejectApprovalRequestFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public receiptCorrectionRejectExec(receiptCorrectRejectApprovalRequestFormResponse: ReceiptCorrectRejectApprovalRequestFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).receiptCorrectionRejectExec(receiptCorrectRejectApprovalRequestFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 却下承認依頼を実行する
     * @summary 却下承認依頼送信
     * @param {ReceiptCorrectRejectApprovalRequestFormResponse} receiptCorrectRejectApprovalRequestFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public receiptCorrectionSendRejectionApprovalRequest(receiptCorrectRejectApprovalRequestFormResponse: ReceiptCorrectRejectApprovalRequestFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).receiptCorrectionSendRejectionApprovalRequest(receiptCorrectRejectApprovalRequestFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * レシートNo重複情報を取得する。
     * @summary レシートNoの重複チェック
     * @param {ReceiptNoDuplicateCheckFormResponse} receiptNoDuplicateCheckFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public receiptNoDuplicateCheck(receiptNoDuplicateCheckFormResponse: ReceiptNoDuplicateCheckFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).receiptNoDuplicateCheck(receiptNoDuplicateCheckFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 来店日チェック
     * @param {VisitDateTimeCheckFormResponse} visitDateTimeCheckFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectReceiptApi
     */
    public visitDateTimeCheck(visitDateTimeCheckFormResponse: VisitDateTimeCheckFormResponse, options?: AxiosRequestConfig) {
        return CorrectReceiptApiFp(this.configuration).visitDateTimeCheck(visitDateTimeCheckFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
