import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  CustomerTagListApi,
  CustomerTagResponse,
  DirectMailDetailInfoApi,
  DirectMailDetailInfoOutputResponse,
  DirectMailDetailUpdateApi,
  DirectMailDetailUpdateFormResponse,
  DirectMailTestDeliveryApi,
  DirectMailTestDeliveryFormResponse,
  IncResultOutputResponse,
  MailMagazineCustomerTagOutputResponse,
  MailMagazinePrefectureFormResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { formatISODate } from '../../../utils/functions';
import { Datepicker } from '../../atoms/Datepicker';
import { Title } from '../../atoms/Title';

const streamingModeTypeList = [
  {
    id: 0,
    name: '本番配信',
  },
  {
    id: 1,
    name: '配信停止',
  },
];

const ageList = [...Array(100)].map((_: any, i) => {
  return {
    id: i + 1,
    name: `${i + 1}歳`,
  };
});

export const DirectMailDetailModifyPage = () => {
  const location = useLocation<{ id: number }>();
  const history = useHistory();

  const infoApi = new DirectMailDetailInfoApi();
  const updateApi = new DirectMailDetailUpdateApi();
  const testApi = new DirectMailTestDeliveryApi();
  const commonMasterListApi = new CommonMasterListApi();
  const customerTagListApi = new CustomerTagListApi();

  // 配信対象ユーザー用ラジオフラグ
  const [radioDisableFlg, setRadioDisableFlg] = useState<boolean>(true);
  const [data, setData] = useState<DirectMailDetailInfoOutputResponse>({
    memo: '',
    emailTitle: '',
    emailContent: '',
    emailHTML: undefined,
    sendScheduleTimeStamp: undefined,
    sendType: 0,
    memberRegistrationDateStart: undefined,
    memberRegistrationDateEnd: undefined,
    customerList: [],
    areaType: 0,
    areaList: [],
    customerTagList: { customerTagList: [] },
    forceSendFlg: false,
    sendMode: 0,
    testMailaddressList: undefined,
  });

  // 配信日時（日付）
  const [streamingDay, setStreamingDay] = useState<string>();
  // 配信日時（時間）
  const [streamingTime, setStreamingTime] = useState<string>();
  // テスト配信先
  const [testStreamingText, setTestStreamingText] = useState<string>();
  // ユーザーID
  const [userIdsText, setUserIdsText] = useState<string>('');

  // 都道府県一覧
  const [prefectures, setPrefectures] = useState<CommonMasterListOutputResponse[]>();
  // 会員属性一覧
  const [customerTagList, setCustomerTagList] = useState<MailMagazineCustomerTagOutputResponse>({
    customerTagList: [],
  });

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const [testResult, setTestResult] = useState<IncResultOutputResponse>();

  const diff = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() + 9);
    return parsedate;
  };

  const diffInitial = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() - 9);
    return parsedate;
  };

  useEffect(() => {
    // 詳細画面に直接アクセスされた場合はエラー画面に遷移させる。
    if (location.pathname === '/keisai/direct-mail-detail' && location.state === undefined) {
      history.push(Url.COMMON_ERROR);
      return;
    }
    // パラメータが渡されてきていなければInfoAPI叩かない
    if (location.state !== undefined) {
      infoApi.directMailDetailInfo(location.state.id).then((res: AxiosResponse<DirectMailDetailInfoOutputResponse>) => {
        if (res.data?.sendScheduleTimeStamp) {
          setStreamingDay(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'yyyy-MM-dd')
          );
          setStreamingTime(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'HH:mm:ss')
          );
        }

        setRadioDisableFlg(res.data.sendType === 0);

        // テスト配信テキストの加工
        if (res.data.testMailaddressList) {
          for (let index = 0; index < res.data.testMailaddressList.length; index += 1) {
            if (res.data.testMailaddressList.length !== index + 1) {
              res.data.testMailaddressList[index] = `${res.data.testMailaddressList[index]}\n`;
            }
          }
          setTestStreamingText(res.data.testMailaddressList.join(''));
        }
        // ユーザーIDテキストの加工
        let userIds = '';
        if (res.data.customerList) {
          for (let index = 0; index < res.data.customerList.length; index += 1) {
            if (index !== 0) {
              userIds = `${userIds}${res.data.customerList[index]},`;
            } else {
              userIds = `${res.data.customerList[index]},`;
            }
          }
          setUserIdsText(userIds.substring(0, userIds.length - 1));
        }
        setData(res.data);
      });
    }

    commonMasterListApi.commonMasterList('prefecture').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
      setPrefectures(res.data);
    });

    customerTagListApi.customerTagList().then((res: AxiosResponse<MailMagazineCustomerTagOutputResponse>) => {
      setCustomerTagList(res.data);
    });
  }, []);

  // エリアSelect Changeイベント
  const onChangeAreaSelect = (selectedContents: MultiValue<{ id: number; name: string }>) => {
    const copyAreaList: MailMagazinePrefectureFormResponse[] = [];

    for (let index = 0; index < selectedContents.length; index += 1) {
      copyAreaList.push({
        prefectureId: selectedContents[index].id,
        prefectureName: selectedContents[index].name,
        selectedFlg: true,
      });
    }

    setData({ ...data, areaList: copyAreaList });
  };

  // 会員属性Select Changeイベント
  const onChangeCustomerSelect = (selectedContents: SingleValue<{ tagId: number; tagName: string }>, ind: number) => {
    if (selectedContents !== null) {
      const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
      const targetSelectionList = customerTagList.customerTagList
        .find((r) => {
          return r.tagId === selectedContents.tagId;
        })
        ?.customerTagAnswerList.slice(0, 1);

      if (targetSelectionList) {
        setData({
          ...data,
          customerTagList: {
            customerTagList: addList.customerTagList.map((_, index) => {
              return index === ind
                ? {
                    ..._,
                    tagId: selectedContents.tagId,
                    tagName: selectedContents.tagName,
                    customerTagAnswerList: targetSelectionList,
                  }
                : { ..._ };
            }),
          },
        });
      }
    }
  };

  // 保存イベント
  const submit = () => {
    let sendScheduleTimeStamp = '';

    if (streamingDay !== undefined && streamingDay !== '' && streamingTime !== undefined && streamingTime !== '') {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} ${streamingTime}`))
        .toISOString()
        .slice(0, -5);
    } else if (
      streamingDay !== undefined &&
      streamingDay !== '' &&
      (streamingTime === undefined || streamingTime === '')
    ) {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} 00:00:00`))
        .toISOString()
        .slice(0, -5);
    }

    const param: DirectMailDetailUpdateFormResponse = {
      id: location.state === undefined ? undefined : location.state.id,
      memo: data.memo,
      emailTitle: data.emailTitle,
      emailContent: data.emailContent,
      emailHTML: data.emailHTML,
      sendScheduleTimeStamp: sendScheduleTimeStamp === '' ? undefined : sendScheduleTimeStamp,
      sendType: data.sendType,
      memberRegistrationDateStart:
        data.memberRegistrationDateStart === '' ? undefined : data.memberRegistrationDateStart,
      memberRegistrationDateEnd: data.memberRegistrationDateEnd === '' ? undefined : data.memberRegistrationDateEnd,
      areaType: data.areaType,
      areaList: data.areaList,
      customerTagList: data.customerTagList.customerTagList,
      forceSendFlg: data.forceSendFlg,
      sendMode: data.sendMode,
      testMailaddressList: testStreamingText?.split('\n'),
      userIdList: userIdsText === '' ? undefined : userIdsText,
    };

    updateApi.directMailDetailUpdate(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(res.data);
    });
  };

  // テスト配信イベント
  const testStreaming = () => {
    const param: DirectMailTestDeliveryFormResponse = {
      emailTitle: data.emailTitle,
      emailContent: data.emailContent,
      emailHTML: data.emailHTML,
      testMailaddressList: testStreamingText !== undefined ? testStreamingText.split('\n') : [],
    };

    testApi.directMailTestDelivery(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setTestResult(res.data);
    });
  };

  return (
    <>
      <Title className="mb-4">
        {location.state === undefined ? 'ダイレクトメール編集画面' : 'ダイレクトメール詳細画面'}
      </Title>
      <div className="d-flex justify-content-end mb-4">
        <Button type="button" onClick={testStreaming} className="ms-2">
          テスト配信
        </Button>
        <Button type="button" onClick={submit} className="ms-2">
          保存
        </Button>
      </div>
      {updResult?.result && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          更新しました。
        </Alert>
      )}
      {testResult?.result && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          テスト配信が完了しました。
        </Alert>
      )}
      {updResult !== undefined && updResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}
      {testResult !== undefined && testResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${testResult.errorMessage} (エラーコード：${testResult.errorCode})`}</Alert>
      )}
      <Form.Group className="mb-4">
        <Form.Label>メモ</Form.Label>
        <Form.Control
          as="textarea"
          value={data.memo}
          onChange={(e) => {
            setData({ ...data, memo: e.target.value });
          }}
          rows={5}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>メール件名</Form.Label>
        <Form.Control
          value={data.emailTitle}
          onChange={(e) => {
            setData({ ...data, emailTitle: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>メール内容（テキスト）</Form.Label>
        <Form.Control
          as="textarea"
          value={data.emailContent}
          onChange={(e) => {
            setData({ ...data, emailContent: e.target.value });
          }}
          rows={5}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>メール内容（HTML）</Form.Label>
        <Form.Control
          as="textarea"
          value={data.emailHTML}
          onChange={(e) => {
            setData({ ...data, emailHTML: e.target.value === '' ? undefined : e.target.value });
          }}
          rows={5}
        />
      </Form.Group>
      <Form.Label>配信日時</Form.Label>
      <Form.Group className="d-flex align-items-center mb-4" controlId="readOnly1" style={{ width: '50%' }}>
        <Datepicker
          value={streamingDay === undefined ? '' : streamingDay}
          className="me-2"
          onChange={(e) => {
            setStreamingDay(e.target.value);
          }}
          data-testid="datepicker"
        />
        <Form.Control
          type="time"
          step="1"
          value={streamingTime === undefined ? '' : streamingTime}
          className="me-2"
          onChange={(e) => {
            setStreamingTime(e.target.value);
          }}
          disabled={streamingDay === undefined || streamingDay === ''}
        />
      </Form.Group>
      <Form.Check.Label>配信対象ユーザー</Form.Check.Label>
      <Form.Check id="radio1" className="d-flex ms-4 mt-2">
        <Form.Check.Input
          data-testid="customerRegisterRadio"
          className="flex-shrink-0 me-2"
          type="radio"
          name="radioGroup01"
          value={0}
          onChange={(e) => {
            setData({ ...data, sendType: Number(e.target.value) });
            setRadioDisableFlg(true);
          }}
          checked={data.sendType === 0}
        />
        <Form.Check.Label>会員登録期間指定</Form.Check.Label>
      </Form.Check>
      <div className="d-flex align-items-center " style={{ width: '49.5%' }}>
        <Col className="col-12">
          <Form.Group className="d-flex align-items-center ms-5 mt-2" controlId="readOnly1">
            <Col>
              <Form.Control
                data-testid="enteringTimeText"
                disabled={!radioDisableFlg}
                type="date"
                value={data.memberRegistrationDateStart === undefined ? '' : data.memberRegistrationDateStart}
                onChange={(e) => {
                  setData({ ...data, memberRegistrationDateStart: e.target.value });
                }}
              />
            </Col>
            <Form.Label className="mt-2 me-2 ms-2">～</Form.Label>
            <Col>
              <Form.Control
                data-testid="exitTimeText"
                disabled={!radioDisableFlg}
                type="date"
                value={data.memberRegistrationDateEnd === undefined ? '' : data.memberRegistrationDateEnd}
                onChange={(e) => {
                  setData({ ...data, memberRegistrationDateEnd: e.target.value });
                }}
              />
            </Col>
          </Form.Group>
        </Col>
      </div>
      <div className="align-items-center " style={{ width: '49.5%' }}>
        <Form.Check id="radio2" className="d-flex ms-4 mt-2 mb-2">
          <Form.Check.Input
            data-testid="userIdRadio"
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup01"
            value={1}
            onChange={(e) => {
              setData({ ...data, sendType: Number(e.target.value) });
              setRadioDisableFlg(false);
            }}
            checked={data.sendType === 1}
          />
          <Form.Check.Label>
            {' '}
            ユーザーID指定&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            ※（複数の場合は半角カンマ（,）で入力してください。例： 12,20）
          </Form.Check.Label>
        </Form.Check>
        <div className="ms-5">
          <Form.Control
            disabled={radioDisableFlg}
            value={userIdsText}
            onChange={(e) => {
              setUserIdsText(e.target.value);
            }}
          />
        </div>
        <Form.Check.Label className="mt-4">エリア</Form.Check.Label>
        <Form.Check id="radio1" className="d-flex ms-4 mt-2">
          <Form.Check.Input
            data-testid="areaTypeResidenceRadio"
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup02"
            value={0}
            onChange={(e) => {
              setData({ ...data, areaType: Number(e.target.value) });
            }}
            checked={data.areaType === 0}
          />
          <Form.Check.Label>選択したエリアに居住している</Form.Check.Label>
        </Form.Check>
        <Form.Check id="radio1" className="d-flex ms-4 mt-2">
          <Form.Check.Input
            data-testid="areaTypeApplicationRadio"
            className="flex-shrink-0 me-2"
            type="radio"
            name="radioGroup02"
            value={1}
            onChange={(e) => {
              setData({ ...data, areaType: Number(e.target.value) });
            }}
            checked={data.areaType === 1}
          />
          <Form.Check.Label>選択したエリアで応募実績がある</Form.Check.Label>
        </Form.Check>
        <Select
          className="ms-4 mt-4 mb-4"
          id="prefecture"
          onChange={(e) => {
            onChangeAreaSelect(e);
          }}
          closeMenuOnSelect={false}
          value={data.areaList.map((d) => {
            return { id: d.prefectureId, name: d.prefectureName };
          })}
          isMulti
          getOptionLabel={(option) => {
            return option.name;
          }}
          getOptionValue={(option) => {
            return option.id.toString();
          }}
          options={prefectures}
          placeholder="エリアを選択してください。"
        />
        <Form.Label>会員属性</Form.Label>
        {data.customerTagList.customerTagList?.map((_, i) => {
          return (
            <Col key={i.toString()} className="col-12 mt-2">
              <Form.Group className="d-flex align-items-center" controlId="readOnly1">
                <Form.Label className="mt-2 me-2 ms-4">属性</Form.Label>
                <Col data-testid="attributeSelect">
                  <Select
                    data-testid="select"
                    onChange={(e) => {
                      onChangeCustomerSelect(e, i);
                    }}
                    closeMenuOnSelect
                    value={data.customerTagList.customerTagList[i]}
                    getOptionLabel={(option) => {
                      return option.tagName;
                    }}
                    getOptionValue={(option) => {
                      return option.tagId.toString();
                    }}
                    options={customerTagList.customerTagList}
                  />
                </Col>
                <Form.Label className="mt-2 me-2 ms-2">選択肢</Form.Label>
                <Col>
                  <Form.Select
                    data-testid="ChoiceSelect"
                    value={data.customerTagList.customerTagList[i].customerTagAnswerList[0].id}
                    onChange={(e) => {
                      const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                      const targetTagName = customerTagList.customerTagList
                        .find((row) => {
                          return row.tagId === Number(data.customerTagList.customerTagList[i].tagId);
                        })
                        ?.customerTagAnswerList.find((r) => {
                          return r.id === Number(e.target.value);
                        })?.tagValue;

                      if (targetTagName) {
                        setData({
                          ...data,
                          customerTagList: {
                            customerTagList: addList.customerTagList.map((row, index) => {
                              return index === i
                                ? {
                                    ...row,
                                    customerTagAnswerList: [{ id: Number(e.target.value), tagValue: targetTagName }],
                                  }
                                : { ...row };
                            }),
                          },
                        });
                      }
                    }}
                  >
                    {customerTagList.customerTagList
                      .find((row) => {
                        return row.tagId === _.tagId;
                      })
                      ?.customerTagAnswerList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.tagValue}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          );
        })}

        {customerTagList.customerTagList.length > 0 && (
          <div className="d-flex justify-content-end mb-4 mt-4">
            <Button
              data-testid="add"
              variant="link"
              className="text-secondary p-0"
              onClick={() => {
                const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                addList.customerTagList.push({
                  tagId: customerTagList.customerTagList[0].tagId,
                  tagName: customerTagList.customerTagList[0].tagName,
                  customerTagAnswerList: customerTagList.customerTagList[0].customerTagAnswerList,
                });

                setData({ ...data, customerTagList: addList });
              }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              <span style={{ fontSize: '1rem' }}>追加</span>
            </Button>
          </div>
        )}
        <div className="d-flex mb-4 mt-4">
          <Form.Check.Input
            data-testid="ForcedTransmissionCheckBox"
            className="flex-shrink-0 me-3"
            type="checkbox"
            name="checkBoxGroup01"
            checked={data.forceSendFlg}
            onChange={(e) => {
              setData({ ...data, forceSendFlg: e.target.checked });
            }}
          />
          <Form.Label className="me-5">強制送信</Form.Label>
        </div>
        <Form.Group className="mb-4">
          <Form.Label>配信モード</Form.Label>
          <Form.Select
            value={data.sendMode}
            onChange={(e) => {
              setData({ ...data, sendMode: Number(e.target.value) });
            }}
          >
            {streamingModeTypeList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <Form.Group className="mb-4">
        <Form.Label>テスト配信先</Form.Label>
        <Form.Control
          value={testStreamingText}
          onChange={(e) => {
            setTestStreamingText(e.target.value);
          }}
          as="textarea"
          rows={3}
        />
      </Form.Group>
    </>
  );
};
