import React, { useMemo, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { format, subMonths } from 'date-fns';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState, FormElement } from '../../../interfaces/utils';
import { InputNumber } from '../../atoms/InputNumber';
import type { ExtendDeadlineHistoryState } from '../../pages/ExtendDeadline/ExtendDeadlineHistoryPage';

/** 数値入力フォーム */
type NumberFormsState = Pick<ExtendDeadlineHistoryState['forms'], 'applyId'>;
/** 日付入力フォーム */
type DateFormsState = Pick<ExtendDeadlineHistoryState['forms'], 'createAtTo' | 'createAtFrom'>;

export type State = NumberFormsState & DateFormsState;
interface Props {
  setForms: DispatchSetState<State>;
}

export type TestIds = keyof State;

export const ExtendDeadlineHistoryForms: React.FC<Props> = React.memo(({ setForms }) => {
  const testId = createTestId<TestIds>(ExtendDeadlineHistoryForms);
  const { state: $number, onChangeSet: onChangeNumber } = useLargeState<NumberFormsState>({ applyId: undefined });

  const { state: $date, onChangeSet: onChangeDate } = useLargeState<DateFormsState>({
    createAtFrom: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    createAtTo: '',
  });
  const dateForms: {
    label: string;
    start: FormElement<DateFormsState>;
    end: FormElement<DateFormsState>;
  } = useMemo(
    () => ({
      label: '登録日',
      start: {
        name: 'createAtFrom',
        onChange: onChangeDate('createAtFrom'),
      },
      end: {
        name: 'createAtTo',
        onChange: onChangeDate('createAtTo'),
      },
    }),

    [onChangeDate]
  );

  useEffect(() => {
    setForms({ ...$number, ...$date });
  }, [setForms, $number, $date]);

  return (
    <>
      <Row>
        <Form.Group data-testid={testId('applyId')} controlId="applyId" key="applyId" className="col-md-6">
          <Form.Label>応募ID</Form.Label>
          <InputNumber value={$number.applyId} min={0} onChange={onChangeNumber('applyId', Number)} />
        </Form.Group>
      </Row>
      <Row key={dateForms.label}>
        <Form.Label>{dateForms.label}</Form.Label>
        <Col>
          <Form.Group data-testid={testId(dateForms.start.name)} controlId={dateForms.start.name}>
            <Form.Control
              type="date"
              autoComplete="off"
              value={$date[dateForms.start.name] || ''}
              onChange={dateForms.start.onChange}
              max={$date[dateForms.end.name] || ''}
            />
          </Form.Group>
        </Col>
        <Col xs="1">～</Col>
        <Col>
          <Form.Group data-testid={testId(dateForms.end.name)} controlId={dateForms.end.name}>
            <Form.Control
              type="date"
              autoComplete="off"
              value={$date[dateForms.end.name] || ''}
              onChange={dateForms.end.onChange}
              min={$date[dateForms.start.name] || ''}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
});
