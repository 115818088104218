/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { ShopInfoOutputResponse } from '../domains';
// @ts-ignore
import { ShopListOutputResponse } from '../domains';
// @ts-ignore
import { ShopUpdateFormResponse } from '../domains';
// @ts-ignore
import { ShopUpdateOutputResponse } from '../domains';
// @ts-ignore
import { ShopUploadFormResponse } from '../domains';
/**
 * ShopApi - axios parameter creator
 * @export
 */
export const ShopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 実店舗をコピーする。
         * @summary 実店舗コピー
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCopy: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shopCopy', 'id', id)
            const localVarPath = `/api/shop-copy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 実店舗を削除(論理)する。
         * @summary 実店舗削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shopDelete', 'id', id)
            const localVarPath = `/api/shop/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 実店舗をダウンロードする
         * @summary 実店舗ダウンロード
         * @param {number} postingRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopDownload: async (postingRequestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postingRequestId' is not null or undefined
            assertParamExists('shopDownload', 'postingRequestId', postingRequestId)
            const localVarPath = `/api/shop-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (postingRequestId !== undefined) {
                localVarQueryParameter['postingRequestId'] = postingRequestId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 実店舗情報を取得する
         * @summary 実店舗情報 取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shopInfo', 'id', id)
            const localVarPath = `/api/shop/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 実店舗一覧を取得する
         * @summary 実店舗一覧取得
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {string} [clientName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopList: async (id?: number, name?: string, clientName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (clientName !== undefined) {
                localVarQueryParameter['clientName'] = clientName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 実店舗を更新する。
         * @summary 実店舗更新
         * @param {ShopUpdateFormResponse} shopUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopUpdate: async (shopUpdateFormResponse: ShopUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopUpdateFormResponse' is not null or undefined
            assertParamExists('shopUpdate', 'shopUpdateFormResponse', shopUpdateFormResponse)
            const localVarPath = `/api/shop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 実店舗をアップロードーする。
         * @summary 実店舗のアップロード
         * @param {ShopUploadFormResponse} shopUploadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopUpload: async (shopUploadFormResponse: ShopUploadFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shopUploadFormResponse' is not null or undefined
            assertParamExists('shopUpload', 'shopUploadFormResponse', shopUploadFormResponse)
            const localVarPath = `/api/shop-upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shopUploadFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopApi - functional programming interface
 * @export
 */
export const ShopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopApiAxiosParamCreator(configuration)
    return {
        /**
         * 実店舗をコピーする。
         * @summary 実店舗コピー
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopCopy(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopCopy(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 実店舗を削除(論理)する。
         * @summary 実店舗削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 実店舗をダウンロードする
         * @summary 実店舗ダウンロード
         * @param {number} postingRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopDownload(postingRequestId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopDownload(postingRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 実店舗情報を取得する
         * @summary 実店舗情報 取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 実店舗一覧を取得する
         * @summary 実店舗一覧取得
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {string} [clientName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopList(id?: number, name?: string, clientName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShopListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopList(id, name, clientName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 実店舗を更新する。
         * @summary 実店舗更新
         * @param {ShopUpdateFormResponse} shopUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopUpdate(shopUpdateFormResponse: ShopUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShopUpdateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopUpdate(shopUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 実店舗をアップロードーする。
         * @summary 実店舗のアップロード
         * @param {ShopUploadFormResponse} shopUploadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopUpload(shopUploadFormResponse: ShopUploadFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopUpload(shopUploadFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopApi - factory interface
 * @export
 */
export const ShopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopApiFp(configuration)
    return {
        /**
         * 実店舗をコピーする。
         * @summary 実店舗コピー
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCopy(id: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.shopCopy(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 実店舗を削除(論理)する。
         * @summary 実店舗削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopDelete(id: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.shopDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 実店舗をダウンロードする
         * @summary 実店舗ダウンロード
         * @param {number} postingRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopDownload(postingRequestId: number, options?: any): AxiosPromise<string> {
            return localVarFp.shopDownload(postingRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 実店舗情報を取得する
         * @summary 実店舗情報 取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopInfo(id: number, options?: any): AxiosPromise<ShopInfoOutputResponse> {
            return localVarFp.shopInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 実店舗一覧を取得する
         * @summary 実店舗一覧取得
         * @param {number} [id] 
         * @param {string} [name] 
         * @param {string} [clientName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopList(id?: number, name?: string, clientName?: string, options?: any): AxiosPromise<Array<ShopListOutputResponse>> {
            return localVarFp.shopList(id, name, clientName, options).then((request) => request(axios, basePath));
        },
        /**
         * 実店舗を更新する。
         * @summary 実店舗更新
         * @param {ShopUpdateFormResponse} shopUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopUpdate(shopUpdateFormResponse: ShopUpdateFormResponse, options?: any): AxiosPromise<ShopUpdateOutputResponse> {
            return localVarFp.shopUpdate(shopUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 実店舗をアップロードーする。
         * @summary 実店舗のアップロード
         * @param {ShopUploadFormResponse} shopUploadFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopUpload(shopUploadFormResponse: ShopUploadFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.shopUpload(shopUploadFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopApi - object-oriented interface
 * @export
 * @class ShopApi
 * @extends {BaseAPI}
 */
export class ShopApi extends BaseAPI {
    /**
     * 実店舗をコピーする。
     * @summary 実店舗コピー
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopCopy(id: number, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopCopy(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 実店舗を削除(論理)する。
     * @summary 実店舗削除
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopDelete(id: number, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 実店舗をダウンロードする
     * @summary 実店舗ダウンロード
     * @param {number} postingRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopDownload(postingRequestId: number, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopDownload(postingRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 実店舗情報を取得する
     * @summary 実店舗情報 取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopInfo(id: number, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 実店舗一覧を取得する
     * @summary 実店舗一覧取得
     * @param {number} [id] 
     * @param {string} [name] 
     * @param {string} [clientName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopList(id?: number, name?: string, clientName?: string, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopList(id, name, clientName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 実店舗を更新する。
     * @summary 実店舗更新
     * @param {ShopUpdateFormResponse} shopUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopUpdate(shopUpdateFormResponse: ShopUpdateFormResponse, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopUpdate(shopUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 実店舗をアップロードーする。
     * @summary 実店舗のアップロード
     * @param {ShopUploadFormResponse} shopUploadFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopUpload(shopUploadFormResponse: ShopUploadFormResponse, options?: AxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopUpload(shopUploadFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
