import React, { useEffect, useCallback } from 'react';
import { Form, Row } from 'react-bootstrap';
import Select, { MultiValue } from 'react-select';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import type {
  PaidServicePointReturnRegisterState,
  OptionType,
} from '../../pages/PaidServicePointReturn/PaidServicePointReturnRegisterPage';
import { TagsInputForm } from '../../molecules/TagsInputForm';
/** 入力フォーム */
export type State = Pick<
  PaidServicePointReturnRegisterState['forms'],
  'applyIdList' | 'pointReturnReason' | 'targetServiceCode'
>;

interface Props {
  setForms: DispatchSetState<State>;
  paidServiceTypeList: OptionType[];
}

export type TestIds = keyof State;

export const PaidServicePointReturnRegisterForms: React.FC<Props> = ({ setForms, paidServiceTypeList }) => {
  const testId = createTestId<TestIds>(PaidServicePointReturnRegisterForms);
  const {
    state: $formState,
    mergeState,
    useBindSet,
    onChangeSet: onChangeInput,
  } = useLargeState<State>({
    applyIdList: [],
    pointReturnReason: '',
    targetServiceCode: [0, 1],
  });
  const setApplyIdList = useBindSet('applyIdList');

  useEffect(() => {
    setForms($formState);
  }, [setForms, $formState]);

  const onChangeServiceType = useCallback(
    (value: MultiValue<OptionType>) => {
      const selectedServiceTypeList = value.map((obj: OptionType) => {
        return obj.value;
      });
      mergeState({ targetServiceCode: selectedServiceTypeList });
    },
    [mergeState]
  );

  return (
    <>
      <Row>
        <Form.Group data-testid={testId('applyIdList')} controlId="applyIdList" key="applyIdList">
          <Form.Label>応募ID</Form.Label>
          <TagsInputForm
            type="number"
            tags={$formState.applyIdList.map((applyId) => String(applyId)) || []}
            placeholder="応募IDを入力後enterを押下してください"
            setInput={setApplyIdList}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group data-testid={testId('pointReturnReason')} controlId="pointReturnReason" key="pointReturnReason">
          <Form.Label>ポイント返還理由</Form.Label>
          <Form.Control
            type="string"
            autoComplete="off"
            required
            value={$formState.pointReturnReason || ''}
            onChange={onChangeInput('pointReturnReason', String)}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group data-testid={testId('targetServiceCode')} controlId="targetServiceCode" key="targetServiceCode">
          <Form.Label>返還対象サービス</Form.Label>
          <Select
            options={paidServiceTypeList}
            isMulti
            name="targetServiceCode"
            placeholder="選択してください。"
            defaultValue={paidServiceTypeList}
            onChange={(e) => onChangeServiceType(e)}
          />
        </Form.Group>
      </Row>
    </>
  );
};
