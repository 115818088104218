import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { OemMonitorListApi, OemMonitorListOutputResponse } from '../../../api-client';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { nothingWithHyphenStr } from '../../../utils/functions';
import { PaginationWithEllipsisBilling } from '../../molecules/PaginationWithEllipsisBilling';
import { OemSelectBox } from '../../atoms/OemSelectBox';
import { OemManagementHeader } from '../../molecules/OemManagementHeader';
import sass from '../../../scss/organisms/scrollTable.module.scss';
import { CalenderTable } from '../../organisms/Table/CalenderTable';

interface SearchParams {
  monitorId?: number;
  monitorName?: string;
  shopId?: number;
  shopName?: string;
  apiClientId?: number;
}

export const OemMonitorListPage: React.FC = () => {
  const currentUser = useCurrentUser();
  const oemMonitorListApi = new OemMonitorListApi();
  const [list, setList] = useState<OemMonitorListOutputResponse[]>([]);
  const [dataCount, setDataCount] = useState<number>();
  const MAX_RECORD_PER_PAGE = 20;

  useEffect(() => {
    const apiclientId =
      typeof currentUser.currentUser?.apiclientId === 'number' ? currentUser.currentUser?.apiclientId : undefined;
    oemMonitorListApi
      .oemMonitorList(1, MAX_RECORD_PER_PAGE, undefined, undefined, undefined, undefined, apiclientId)
      .then((res) => {
        setList(res.data.list);
        setDataCount(res.data.total);
      });
  }, []);

  const handleSearch = (data: SearchParams) => {
    const apiclientId =
      typeof currentUser.currentUser?.apiclientId === 'number'
        ? currentUser.currentUser?.apiclientId
        : data.apiClientId;

    oemMonitorListApi
      .oemMonitorList(1, MAX_RECORD_PER_PAGE, data.monitorId, data.monitorName, data.shopId, data.shopName, apiclientId)
      .then((res) => {
        setList(res.data.list);
      });
  };

  const onClickPageNation = (clickPage: number) => {
    const apiClientId =
      typeof currentUser.currentUser?.apiclientId === 'number'
        ? currentUser.currentUser?.apiclientId
        : getValues('apiClientId');
    oemMonitorListApi
      .oemMonitorList(
        clickPage,
        MAX_RECORD_PER_PAGE,
        getValues('monitorId'),
        getValues('monitorName'),
        getValues('shopId'),
        getValues('shopName'),
        apiClientId
      )
      .then((res) => {
        setList(res.data.list);
        setDataCount(res.data.total);
      });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<SearchParams>({
    defaultValues: {},
  });
  const onSubmit: SubmitHandler<SearchParams> = (data) => {
    handleSearch(data);
  };

  return (
    <>
      <OemManagementHeader title="モニター一覧" />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Form.Group controlId="shopId">
              <Form.Label>実店舗ID</Form.Label>
              <Form.Control type="number" {...register('shopId')} />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="shopName">
              <Form.Label>実店舗名</Form.Label>
              <Form.Control type="text" {...register('shopName')} />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="monitorId">
              <Form.Label>モニターID</Form.Label>
              <Form.Control type="number" {...register('monitorId')} />
            </Form.Group>
          </Col>
          {typeof currentUser.currentUser?.apiclientId !== 'number' ? (
            <Col>
              <Form.Group controlId="apiclientid">
                <Form.Label>OEM</Form.Label>
                <OemSelectBox<SearchParams> register={register} registerName="apiClientId" />
              </Form.Group>
            </Col>
          ) : (
            <Col>{}</Col>
          )}
        </Row>
        <div className="d-flex justify-content-center m-2">
          <Button type="submit">検索</Button>
        </div>
      </Form>
      <div className={sass.oemMonitorListTable}>
        <Table>
          <thead>
            <tr>
              <th className={sass.fiveText}>実店舗ID</th>
              <th className={sass.tenText}>実店舗名</th>
              <th className={sass.fiveText}>モニターID</th>
              <th className={sass.tenText}>モニター名</th>
              <th className={sass.oemTableDateTime}>モニター開始日時</th>
              <th className={sass.oemTableDateTime}>モニター終了日時</th>
              <th className={sass.fiveText}>種別</th>
              <th className={sass.fiveText}>モニター提出期限</th>
              <th className={sass.oemTableDateTime}>カウントダウン</th>
              <th className={sass.tenText}>謝礼付与時期</th>
              <th className={sass.fiveText}>クライアントID</th>
              <th className={sass.tenText}>クライアント名</th>
              <th className={sass.fiveText}>表示ステータス</th>
              <th className={sass.fiveText}>都道府県</th>
              <th className={sass.fiveText}>エリア</th>
              <th className={sass.tenText}>住所</th>
              <th className={sass.fiveText}>アクセス</th>
              <th className={sass.tenText}>URL</th>
              <th className={sass.tenText}>平均予算(一人あたり)</th>
              <th className={sass.fiveText}>謝礼タイプ</th>
              <th className={sass.fiveText}>謝礼</th>
              <th className={sass.fiveText}>謝礼上限</th>
              <th className={sass.surveyDate}>調査日</th>
              <th className={sass.tenText}>来店時間</th>
              <th className={sass.tenText}>来店人数</th>
              <th className={sass.fiveText}>応募条件</th>
              <th className={sass.thirtyText}>予約要否</th>
              <th className={sass.thirtyText}>指定商品</th>
              <th className={sass.thirtyText}>写真撮影</th>
              <th className={sass.tenText}>定員調査</th>
              <th className={sass.tenText}>トイレ調査</th>
              <th className={sass.tenText}>清掃状況調査</th>
              <th className={sass.tenText}>その他調査</th>
              <th className={sass.thirtyText}>支払方法</th>
              <th className={sass.tenText}>クーポン・割引</th>
              <th className={sass.thirtyText}>調査証明</th>
              <th className={sass.twentyText}>特記事項</th>
              <th className={sass.thirtyText}>注意事項</th>
              <th className={sass.thirtyText}>注文方法</th>
              <th className={sass.tenText}>対象ブランド</th>
              <th className={sass.thirtyText}>申込方法</th>
              <th className={sass.thirtyText}>外観調査</th>
              <th className={sass.thirtyText}>購入店舗</th>
              <th className={sass.thirtyText}>施術調査</th>
              <th className={sass.thirtyText}>体験調査</th>
              <th className={sass.thirtyText}>その他</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 &&
              list.map((v) => {
                return (
                  <tr key={v.monitorId}>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.shopId)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.shopName)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.monitorId)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.monitorName)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.startAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.endAt)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.categoryName)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.submitDeadline)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.countdown)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.rewardGrantTime)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.clientId)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.clientName)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.status)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.prefecture)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.area)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.address)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.access)}</td>
                    <td style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>{nothingWithHyphenStr(v.url)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.averageBudget)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.rewardType)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.reward)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.rewardLimit)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>
                      <CalenderTable monitorRuleSettingAttribute={v.surveyDate} />
                    </td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.visitTime)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.numberOfPeople)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.qualification)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.reservation)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.targetItem)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.photo)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.clerkInvestigation)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.toiletInvestigation)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.cleaningInvestigation)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.otherInvestigation)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.payment)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.coupon)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.investigationEvidence)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.remark)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.note)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.orderMethod)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.targetBrand)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.applicationMethod)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.appearanceInvestigation)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.purchaseStore)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.operationInvestigation)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.experieceInvestigation)}</td>
                    <td style={{ whiteSpace: 'pre-wrap' }}>{nothingWithHyphenStr(v.other)}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
      {dataCount !== undefined && (
        <div className="d-flex justify-content-center">
          <PaginationWithEllipsisBilling
            maxRecordPerPage={MAX_RECORD_PER_PAGE}
            totalDataNum={dataCount}
            handleClick={(e) => onClickPageNation(e.page)}
          />
        </div>
      )}
    </>
  );
};
