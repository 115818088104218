import axios, { Axios, AxiosError, AxiosResponse } from 'axios';
import React, { useCallback, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import type {
  IncResultOutputResponse,
  ShopReplaceConfirmDisplayFormResponse as Forms,
  ShopReplaceConfirmDisplayOutputResponse,
  ShopReplaceExecuteFormResponse,
  ShopReplaceMonitorSearchFormResponse,
  ShopReplaceMonitorSearchOutputResponse,
  ShopReplaceSearchOutputResponse,
} from '../../api-client';
// import { shopReplaceApi } from '../../test/mocks/api/ShopReplaceApi';
// eslint-disable-next-line import/no-cycle
import { ShopReplaceApi } from '../../api-client';
import { Url } from '../../constants/Url';
import { useLargeState } from '../../hooks/useLargeState';
import { createTestId } from '../../utils/functions';
import { Button } from '../atoms/Button';
import { LoadingSpinner } from '../molecules/Loading/LoadingSpinner';
import { ShopReplaceConfirmModal } from '../organisms/Modal/ShopReplaceConfirmModal';
import { ShopReplaceMonitorSearchModal } from '../organisms/Modal/ShopReplaceMonitorSearchModal';
import { ShopReplaceConnectedShopTable } from '../organisms/Table/ShopReplaceConnectedShopTable';
import { Title } from '../atoms/Title';
import { HTTPStatusCode } from '../../constants/HTTPStatusCode';
import { ShopReplaceForceExecuteModal } from '../organisms/Modal/ShopReplaceForceExecuteModal';

export interface State {
  // api: ReturnType<typeof shopReplaceApi>;
  api: ShopReplaceApi;
  isLoading: boolean;
  isMonitorSearchModal: boolean;
  isConfirmModal: boolean;
  pageFormsError?: { [K in keyof Forms]?: string };
  shopData?: ShopReplaceSearchOutputResponse;
  monitorSearchList: ShopReplaceMonitorSearchOutputResponse[];
  confirmedData?: ShopReplaceConfirmDisplayOutputResponse;
  resultMessage?: string;
}
export type ShopData = Exclude<State['shopData'], undefined>;

export const ShopReplacePage: React.FC = () => {
  const testid = createTestId(ShopReplacePage);
  const formid = (x: keyof Forms) => testid(x);

  const history = useHistory();

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    // api: shopReplaceApi({}, {}),
    api: new ShopReplaceApi(),
    isLoading: false,
    isMonitorSearchModal: false,
    isConfirmModal: false,
    monitorSearchList: [],
  });
  const { state: f, onChangeSet, useBindSet: useBindSetForms } = useLargeState<Partial<Forms>>({});
  const [isNotRemainingBorder, setIsNotRemainingBorder] = useState(false);

  const onSearchApplyId = useCallback(
    () =>
      (async () => {
        if (f.applyId === undefined) return;
        mergeState({ isLoading: true });
        try {
          const { data: shopData } = await $.api.shopReplaceApplySearch(f.applyId);
          mergeState({ isLoading: false, shopData });
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.status === HTTPStatusCode.UnprocessableEntity) {
            mergeState({ isLoading: false, shopData: undefined });
          } else {
            history.push(Url.COMMON_ERROR);
          }
        }
      })(),
    [$.api, f.applyId, mergeState, history]
  );

  const onSearchMonitorList = useCallback(
    (forms: ShopReplaceMonitorSearchFormResponse) =>
      (async () => {
        mergeState({ isLoading: true });
        try {
          const { data: monitorSearchList } = await $.api.shopReplaceMonitorSearch(forms);
          mergeState({ isLoading: false, monitorSearchList });
        } catch (e) {
          history.push(Url.COMMON_ERROR);
        }
      })(),
    [$.api, mergeState, history]
  );

  const onConfirm = useCallback(() => {
    if (!f.afterReplaceMonitorBaseId || !f.applyId) return;
    mergeState({ isLoading: true });
    $.api
      .shopReplaceConfirm(f as Forms)
      .then((res) => {
        const { data: confirmedData } = res as AxiosResponse<ShopReplaceConfirmDisplayOutputResponse>;
        mergeState({
          isLoading: false,
          confirmedData,
          pageFormsError: undefined,
          isConfirmModal: true,
        });
      })
      .catch((e) => {
        const { response } = e as AxiosError<IncResultOutputResponse>;
        mergeState({
          isLoading: false,
          confirmedData: undefined,
          pageFormsError: {
            afterReplaceMonitorBaseId: response?.data.errorMessage ?? 'そのモニターベースIDを使用することはできません',
          },
        });
      });
  }, [$.api, f, mergeState]);

  const onSubmit = useCallback(
    (forms: ShopReplaceExecuteFormResponse) => {
      mergeState({ isLoading: true });
      $.api
        .shopReplaceExecute(forms)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          mergeState({
            isLoading: false,
            resultMessage: '完了しました。',
          });
          setIsNotRemainingBorder(false);
        })
        .catch((e: AxiosError<IncResultOutputResponse>) => {
          mergeState({
            isLoading: false,
            resultMessage: `エラーが発生しました: ${e.response?.data.errorMessage || 'unknown'}`,
          });
          setIsNotRemainingBorder(true);
        });
    },
    [$.api, mergeState]
  );

  const setConfirmModal = useBindSet('isConfirmModal');
  const renderModal = () => {
    if (!$.confirmedData || !$.confirmedData.afterMonitor) {
      return <></>;
    }

    if (isNotRemainingBorder) {
      return (
        <ShopReplaceForceExecuteModal
          applyId={$.confirmedData.applyId}
          afterMonitorBaseId={$.confirmedData.afterMonitor.monitorBaseId}
          comment={$.confirmedData.comment}
          onSubmit={onSubmit}
          isNotRemainingBorder={isNotRemainingBorder}
        />
      );
    }
    return (
      <ShopReplaceConfirmModal
        isModal={$.isConfirmModal}
        setShow={setConfirmModal}
        confirmedData={$.confirmedData}
        onSubmit={onSubmit}
        resultMessage={$.resultMessage}
      />
    );
  };

  return (
    <LoadingSpinner isLoading={$.isLoading}>
      <ShopReplaceMonitorSearchModal
        isModal={$.isMonitorSearchModal}
        setShow={useBindSet('isMonitorSearchModal')}
        applyId={f.applyId}
        beforeMonitorId={$.shopData?.monitorBaseId}
        monitorSearchList={$.monitorSearchList}
        onSearchMonitorList={onSearchMonitorList}
        setAfterReplaceMonitorBaseId={useBindSetForms('afterReplaceMonitorBaseId')}
      />
      <Title>店舗付け替え</Title>
      {renderModal()}
      <Row className="g-2 mb-4">
        <Col>
          <Form.Group data-testid={formid('applyId')} className="col-md-8">
            <Form.Label>応募ID</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  type="number"
                  placeholder="00000"
                  autoComplete="off"
                  value={f.applyId || ''}
                  onChange={onChangeSet('applyId', Number)}
                  isInvalid={!!$.pageFormsError?.applyId}
                />
                <Form.Control.Feedback type="invalid">{$.pageFormsError?.applyId}</Form.Control.Feedback>
              </Col>
              <Col>
                <Button data-testid={testid('apply-search-button')} disabled={!f.applyId} onClick={onSearchApplyId}>
                  検索
                </Button>
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>

      {$.shopData ? (
        <>
          <ShopReplaceConnectedShopTable shopData={$.shopData} applyId={f.applyId} />
          <h4 data-testid={testid('head-label')}>付け替え対象先指定</h4>
          <Form>
            <Row className="g-2 mb-4">
              <Col>
                <Form.Group data-testid={formid('afterReplaceMonitorBaseId')} className="col-md-8">
                  <Form.Label>モニターベースID</Form.Label>
                  <Row>
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="00000"
                        autoComplete="off"
                        value={f.afterReplaceMonitorBaseId || ''}
                        onChange={onChangeSet('afterReplaceMonitorBaseId', Number)}
                        isInvalid={!!$.pageFormsError?.afterReplaceMonitorBaseId}
                      />
                      <Form.Control.Feedback type="invalid">
                        {$.pageFormsError?.afterReplaceMonitorBaseId}
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Button
                        data-testid={testid('monitor-search-button')}
                        onClick={() => mergeState({ isMonitorSearchModal: true })}
                        variant="link"
                      >
                        モニター検索
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className="g-2 mb-4">
              <Col>
                <Form.Group data-testid={formid('comment')} className="col-md-4">
                  <Form.Label>作業メモ</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="○○のため変更"
                    autoComplete="off"
                    value={f.comment || ''}
                    onChange={onChangeSet('comment')}
                    isInvalid={!!$.pageFormsError?.comment}
                  />
                  <Form.Control.Feedback type="invalid">{$.pageFormsError?.comment}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Form>
          <Button
            data-testid={testid('confirm-button')}
            disabled={!f.afterReplaceMonitorBaseId}
            onClick={() => onConfirm()}
          >
            確認
          </Button>
        </>
      ) : (
        <></>
      )}
    </LoadingSpinner>
  );
};
