/* eslint-disable import/no-cycle */
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Nav, Row, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { valueContainerCSS } from 'react-select/dist/declarations/src/components/containers';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, formatISODate, when } from '../../../utils/functions';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { Alert } from '../../atoms/Alert';
import { BillingApi, CustomerInfoApi } from '../../../api-client';
import type {
  CustomerInformationDetailDisplayOutputResponse,
  CustomerInformationEditConfirmFormResponse,
  MailAddressEditConfirmedFormResponse,
  CustomerStatusEditConfirmFormResponse,
  CustomerMoralEditConfirmedFormResponse,
  CustomerInCompanyMemoEditConfirmedFormResponse,
  PrefecturesByPostalcodeOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { CustomerInfoCard } from './Card/CustomerInfoCard';
import { MailInfoCard } from './Card/MailInfoCard';
import { StatusInfoCard } from './Card/StatusInfoCard';
import { MoralInfoCard } from './Card/MoralInfoCard';
import { SocialInfoCard } from './Card/SocialInfoCard';
import { MemoCard } from './Card/MemoCard';
import { CustomerEditHistoryPage } from './CustomerEditHistoryPage';
import { CustomerDetailProvider } from '../../../store/customerInfoStore';
import { CustomerDetailOemAccordion } from './CustomerDetailOemAccordion';

interface Props {
  id: string;
}

export interface CustomerDetailState {
  customerApi: CustomerInfoApi;
  billingApi: BillingApi;
  customerData: CustomerInformationDetailDisplayOutputResponse;
  oemFlag: boolean;
  tabKey: string;
  isLoading: boolean;
  errorMessage: string[];
}

export interface ContextValueType {
  reload: () => void;
  customer: (body: CustomerInformationEditConfirmFormResponse) => Promise<boolean>;
  mail: (body: MailAddressEditConfirmedFormResponse) => Promise<boolean>;
  status: (body: CustomerStatusEditConfirmFormResponse) => Promise<boolean>;
  moral: (body: CustomerMoralEditConfirmedFormResponse) => Promise<boolean>;
  memo: (body: CustomerInCompanyMemoEditConfirmedFormResponse) => Promise<boolean>;
  prefecture: (body: string) => Promise<PrefecturesByPostalcodeOutputResponse>;
}

export type TestIds = keyof CustomerInformationDetailDisplayOutputResponse | 'search-button' | 'link-button';

export const CustomerDetailPage: React.FC<Props> = ({ id }) => {
  const testid = createTestId<TestIds>(CustomerDetailPage);

  const initialData: CustomerInformationDetailDisplayOutputResponse = {
    bounceMailFlg: false,
    createAt: '',
    customerId: -1,
    customerStatus: -1,
    customerStatusName: '',
    phoneNumberAuthedFlg: false,
    phoneNumber: '',
    mailAddress: '',
    mailSendFailCount: -1,
    moral: -1,
    moralName: '',
    ownedPoints: -1,
    pointFreezeFlg: false,
    pointExchangeStatusName: '',
    testCustomerFlg: false,
    updateAt: '',
    referenceSecretQuestionFlg: false,
    oldSystemCustomerDetailUrl: '',
  };

  const { state: $, mergeState } = useLargeState<CustomerDetailState>({
    customerApi: new CustomerInfoApi(),
    billingApi: new BillingApi(),
    customerData: initialData,
    oemFlag: true,
    tabKey: 'cunstomerDetail',
    isLoading: false,
    errorMessage: [],
  });

  const history = useHistory();

  useEffect(() => {
    if (id && Number(id) > 0) {
      $.customerApi
        .customerInformationDetailDisplay(Number(id))
        .then((res: AxiosResponse<CustomerInformationDetailDisplayOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            mergeState({
              customerData: res.data,
              oemFlag: res.data.oemId !== 1,
              errorMessage: [],
              isLoading: false,
            });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
    }
  }, [$.customerApi, id, mergeState]);

  const onSubmitOk = useCallback(() => {
    history.go(0);
  }, [history]);

  const createMailSubmitBody = useCallback((body: MailAddressEditConfirmedFormResponse) => {
    return {
      customerId: body.customerId,
      mailAddress: body.mailAddress,
      mailAddress2: body.mailAddress2,
      mailSendFailCount: Number(body.mailSendFailCount),
      mailUnreachableFlg: body.mailUnreachableFlg,
      newsletterSendFlg: body.newsletterSendFlg,
    };
  }, []);

  const onSubmitMailInfo = useCallback(
    async (body: MailAddressEditConfirmedFormResponse) => {
      let updateFlg = false;

      const submitBody: MailAddressEditConfirmedFormResponse = createMailSubmitBody(body);
      await $.customerApi
        .mailAddressEditConfirmed(submitBody)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (!res.data.result) {
            mergeState({ errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            updateFlg = true;
            mergeState({ errorMessage: [], isLoading: false });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
      return updateFlg;
    },
    [$.customerApi, createMailSubmitBody, mergeState]
  );

  const createCustomerInfoSubmitBody = useCallback((body: CustomerInformationEditConfirmFormResponse) => {
    return {
      customerId: body.customerId,
      gender: Number(body.gender),
      birthday: body.birthday ? parseISO(body.birthday as string).toISOString() : body.birthday,
      firstName: body.firstName,
      lastName: body.lastName,
      zipCode: body.zipCode,
      prefectureId: body.prefectureId,
    };
  }, []);

  const onSubmitCustomerInfo = useCallback(
    async (body: CustomerInformationEditConfirmFormResponse) => {
      const submitBody: CustomerInformationEditConfirmFormResponse = createCustomerInfoSubmitBody(body);

      // FIXME パイロット後に追加。住所を更新出来るようにする
      // await $.billingApi
      //   .prefecturesByPostalcode(body.zipCode)
      //   .then((res: AxiosResponse<PrefecturesByPostalcodeOutputResponse>) => {
      //     if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
      //       mergeState({ errorMessage: ['エラーが発生しました。'] });
      //     } else {
      //       submitBody.prefectureId = res.data.prefecturesId as number;
      //       mergeState({ errorMessage: [] });
      //     }
      //   })
      //   .catch((errorList: IncResultOutputResponse[]) => {
      //     const errorMessageList: string[] = [];
      //     errorList.forEach((error) => {
      //       if (error.errorMessage) {
      //         errorMessageList.push(error.errorMessage);
      //       }
      //     });
      //     mergeState({ errorMessage: errorMessageList, isLoading: false });
      //   });

      let updateFlg = false;
      // FIXME 本来は画面から更新したいが、パイロットではリリースを優先
      submitBody.zipCode = body.zipCode;
      submitBody.prefectureId = body.prefectureId;

      await $.customerApi
        .customerInformationEditConfirm(submitBody)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (!res.data.result) {
            mergeState({ errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            mergeState({ errorMessage: [], isLoading: false });
            updateFlg = true;
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });

      return updateFlg;
    },
    [$.customerApi, createCustomerInfoSubmitBody, mergeState]
  );

  const onSubmitStatusInfo = useCallback(
    async (form: CustomerStatusEditConfirmFormResponse) => {
      let updateFlg = false;

      const body: CustomerStatusEditConfirmFormResponse = {
        ...form,
        // ポイント凍結理由はクリアした場合nullを返したいので、空文字をundefinedに変換する
        pointFreezeReason: form.pointFreezeReason || undefined,
      };

      await $.customerApi
        .customerStatusEditConfirm(body)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (!res.data.result) {
            mergeState({ errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            updateFlg = true;

            mergeState({ errorMessage: [], isLoading: false });
          }
        })
        .catch((error: AxiosError<IncResultOutputResponse>) => {
          window.alert(error.response?.data.errorMessage);
        });
      return updateFlg;
    },
    [$.customerApi, mergeState]
  );

  const onSubmitMoralInfo = useCallback(
    async (body: CustomerMoralEditConfirmedFormResponse) => {
      let updateFlg = false;

      await $.customerApi
        .customerMoralEditConfirmed(body)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (!res.data.result) {
            mergeState({ errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            updateFlg = true;

            mergeState({ errorMessage: [], isLoading: false });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
      return updateFlg;
    },
    [$.customerApi, mergeState]
  );

  const onSubmitMemoInfo = useCallback(
    async (body: CustomerInCompanyMemoEditConfirmedFormResponse) => {
      let updateFlg = false;

      await $.customerApi
        .customerInCompanyMemoEditConfirmed(body)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (!res.data.result) {
            mergeState({ errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            updateFlg = true;

            mergeState({ errorMessage: [], isLoading: false });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
      return updateFlg;
    },
    [$.customerApi, mergeState]
  );

  const onClickGetPrefecture = useCallback(
    async (body: string) => {
      const prefecture: PrefecturesByPostalcodeOutputResponse = {
        city: '',
        prefecturesId: undefined,
        prefecturesName: '',
      };
      await $.billingApi
        .prefecturesByPostalcode(body)
        .then((res: AxiosResponse<PrefecturesByPostalcodeOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ errorMessage: ['エラーが発生しました。'] });
          } else {
            prefecture.city = res.data.city;
            prefecture.prefecturesName = res.data.prefecturesName;
            mergeState({ errorMessage: [] });
          }
        })
        .catch((errorList: IncResultOutputResponse[]) => {
          const errorMessageList: string[] = [];
          errorList.forEach((error) => {
            if (error.errorMessage) {
              errorMessageList.push(error.errorMessage);
            }
          });
          mergeState({ errorMessage: errorMessageList, isLoading: false });
        });
      return prefecture;
    },
    [$.billingApi, mergeState]
  );

  const topParam: {
    label: string;
    name: keyof CustomerDetailState['customerData'];
  }[] = useMemo(() => {
    return [
      { label: 'OEM', name: 'oemName' },
      { label: '口座番号', name: 'accountNo' },
      { label: '保有ポイント', name: 'ownedPoints' },
      { label: '秘密の質問/答え', name: 'secretQuestion' },
      { label: '電話番号', name: 'phoneNumber' },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (label: string, name: keyof CustomerDetailState['customerData'], data: CustomerDetailState['customerData']) => {
      let value;
      if (data[name] == null) value = '';
      else value = `${data[name]}`;

      if (name === 'ownedPoints') {
        return (
          <CustomerBasicInfoBlock
            label={label}
            name={name}
            value={value}
            smallValue={`最終交換先 ${data.exchangePointsServiceName}`}
          />
        );
      }
      if (name === 'secretQuestion') {
        // 秘密の質問に回答を追加
        value += `/${data.secretAnswer}`;
        if (data.referenceSecretQuestionFlg) return <CustomerBasicInfoBlock label={label} name={name} value={value} />;
        return <></>;
      }

      if (name === 'phoneNumber') {
        if (value === '認証未実施')
          value = (
            <>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              {value}
            </>
          );
      }
      return <CustomerBasicInfoBlock label={label} name={name} value={value} />;
    },
    [testid]
  );

  return (
    <>
      {$.errorMessage.length
        ? $.errorMessage.map((errorMessage) => (
            <Alert variant="danger" key={errorMessage}>
              {errorMessage}
            </Alert>
          ))
        : undefined}
      <LoadingSpinner isLoading={$.isLoading}>
        {$.customerData.customerId === -1 ? undefined : (
          <>
            <Tab.Container defaultActiveKey="customerDetail">
              <Nav className="mt-2 ms-2" variant="pills">
                <Nav.Item style={{ cursor: 'pointer' }}>
                  <Nav.Link eventKey="customerDetail">会員詳細</Nav.Link>
                </Nav.Item>
                {$.oemFlag ? (
                  <></>
                ) : (
                  <Nav.Item style={{ cursor: 'pointer' }}>
                    <Nav.Link eventKey="customerChangeHistory">会員変更履歴</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
              <Tab.Content>
                <CustomerDetailProvider
                  customer={onSubmitCustomerInfo}
                  mail={onSubmitMailInfo}
                  memo={onSubmitMemoInfo}
                  moral={onSubmitMoralInfo}
                  prefecture={onClickGetPrefecture}
                  reload={onSubmitOk}
                  status={onSubmitStatusInfo}
                >
                  <Tab.Pane eventKey="customerDetail">
                    <p className="d-flex justify-content-between mt-2" style={{ backgroundColor: '#EEEEEE' }}>
                      <span>
                        <strong data-testid={testid('customerId')}>会員ID {$.customerData.customerId}</strong>
                        <span className="text-secondary ms-3" data-testid={testid('loginAt')}>
                          最終ログイン
                          {$.customerData.loginAt ? formatISODate($.customerData.loginAt, 'yyyy/MM/dd HH:mm:ss') : ''}
                        </span>
                      </span>
                      <span className="text-secondary" data-testid={testid('updateAt')}>
                        最終更新日時
                        {$.customerData.updateAt ? formatISODate($.customerData.updateAt, 'yyyy/MM/dd HH:mm:ss') : ''}
                      </span>
                    </p>
                    {$.oemFlag ? (
                      <>
                        <Row>
                          <span className="col-md-2">OEM</span>
                          <span className="col-md-4" data-testid={testid('oemName')}>
                            {$.customerData.oemName}
                          </span>
                        </Row>
                        <Row>
                          <CustomerInfoCard customerData={$.customerData} oemFlag />
                          <StatusInfoCard customerData={$.customerData} oemFlag />
                          <MoralInfoCard customerData={$.customerData} />
                          <MemoCard customerData={$.customerData} oemFlag />
                        </Row>
                        <CustomerDetailOemAccordion />
                      </>
                    ) : (
                      <>
                        <Row>
                          {topParam.map(({ label, name }) => {
                            return createDisplayElement(label, name, $.customerData);
                          })}
                        </Row>
                        <Row>
                          <CustomerInfoCard customerData={$.customerData} oemFlag={$.oemFlag} />
                          <MailInfoCard customerData={$.customerData} />
                          <StatusInfoCard customerData={$.customerData} oemFlag={$.oemFlag} />
                          <MoralInfoCard customerData={$.customerData} />
                        </Row>
                        <Row>
                          <SocialInfoCard customerData={$.customerData} />
                          <MemoCard customerData={$.customerData} oemFlag={$.oemFlag} />
                        </Row>
                      </>
                    )}
                  </Tab.Pane>
                </CustomerDetailProvider>
                {$.oemFlag ? (
                  <></>
                ) : (
                  <Tab.Pane eventKey="customerChangeHistory">
                    <CustomerEditHistoryPage id={`${$.customerData.customerId}`} />
                  </Tab.Pane>
                )}
              </Tab.Content>
            </Tab.Container>
          </>
        )}
      </LoadingSpinner>
    </>
  );
};

const CustomerBasicInfoBlock: React.FC<{
  label: string;
  name: keyof CustomerDetailState['customerData'];
  value: React.ReactNode;
  smallValue?: string;
}> = ({ label, name, value, smallValue }) => {
  const testid = createTestId<TestIds>(CustomerDetailPage);

  return (
    <React.Fragment key={name}>
      <span className="col-md-2">{label}</span>
      <span className="col-md-4 text-left" data-testid={testid(name)}>
        {value}
        {when(!!smallValue, <small className="ms-3 text-secondary">{smallValue}</small>)}
      </span>
    </React.Fragment>
  );
};
