import { AxiosResponse } from 'axios';
import { faCopy, faPen, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { IncResultOutputResponse, ShopApi, ShopListOutputResponse } from '../../../api-client';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';

export const ShopListPage = () => {
  const history = useHistory();

  // TODO レスポンス型指定
  const [data, setData] = useState<ShopListOutputResponse[]>([]);
  // 更新結果データ
  const [updResult, setUpdResult] = useState<IncResultOutputResponse | null>(null);
  // コピー結果データ
  const [copyResult, setCopyResult] = useState<IncResultOutputResponse | null>(null);
  // 削除結果
  const [deleteResult, setDeleteResult] = useState<any>();
  // 検索パラメータ
  const [searchParams, setSearchParms] = useState<any>({
    id: undefined,
    name: undefined,
    clientName: undefined,
  });

  // ページ関連データ
  const [listPerPage, setListPerPage] = useState<ShopListOutputResponse[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const MAX_RECORD_PER_PAGE = 20;

  // 削除モーダル開閉フラグ
  const [isModal, setIsModal] = useState<boolean>(false);
  // 削除用ID格納
  const [deleteId, setDeleteId] = useState<number>();

  const shopApi = new ShopApi();

  useEffect(() => {
    shopApi.shopList().then((res: AxiosResponse<ShopListOutputResponse[]>) => {
      setData(res.data);
    });
    setCurrentPage(1);
    setListPerPage(data?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(data?.length) / MAX_RECORD_PER_PAGE));
    setDeleteResult(history?.location.state);
    if (history?.action) history.action = 'POP';
  }, [history?.action]);

  useEffect(() => {
    setListPerPage(data?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(data?.length) / MAX_RECORD_PER_PAGE));
  }, [data, currentPage]);

  // 検索ボタンイベント
  const handleOnSearch = async (e: any) => {
    shopApi
      .shopList(searchParams.id, searchParams.name, searchParams.clientName)
      .then((res: AxiosResponse<ShopListOutputResponse[]>) => {
        setData(res.data);
      });
    setCurrentPage(1);
    setListPerPage(data?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(data?.length) / MAX_RECORD_PER_PAGE));

    setUpdResult(null);
    setDeleteResult(undefined);
  };

  // 検索パラメータ検知イベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'shopId':
        setSearchParms({
          ...searchParams,
          id: Number.isNaN(parseInt(event.target.value, 10)) ? undefined : parseInt(event.target.value, 10),
        });
        break;
      case 'shopName':
        setSearchParms({ ...searchParams, name: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'clientName':
        setSearchParms({ ...searchParams, clientName: event.target.value === '' ? undefined : event.target.value });
        break;
      default:
        break;
    }
  };

  // モーダル（YES）イベント
  const handleClickModalYes = (): void => {
    if (deleteId === undefined) return;

    shopApi.shopDelete(deleteId).then((res: AxiosResponse<IncResultOutputResponse>) => {
      if (res.data.result) {
        setData(data?.filter((item, i) => item.id !== deleteId));
      }
      setUpdResult(res.data);
    });

    setDeleteResult(undefined);
    setIsModal(false);
  };

  // モーダル（No）イベント
  const handleClickModalNo = (): void => {
    setIsModal(false);
    setDeleteId(undefined);
  };

  // コピーボタンイベント
  const copyTextToClipboard = (shopId?: number) => {
    if (shopId === undefined) return;

    shopApi.shopCopy(shopId).then((res: AxiosResponse<IncResultOutputResponse>) => {
      // setCopyResult({...res.data,result:false});
      setCopyResult(res.data);
    });
  };

  const copyPopover = copyResult?.result ? (
    <Popover id="button-tooltip" style={{ maxWidth: '25rem', color: 'green' }}>
      Successful Copy!
    </Popover>
  ) : (
    <Popover id="popover-basic" style={{ maxWidth: '25rem', color: 'red' }}>
      <Popover.Header>Copy failure</Popover.Header>
      <Popover.Body>
        <p style={{ color: 'red' }}>
          ErrorCode : {`${copyResult?.errorCode}`}
          <br />
          Message : {`${copyResult?.errorCode}`}
        </p>
      </Popover.Body>
    </Popover>
  );

  const rows = listPerPage?.map((row: ShopListOutputResponse, index: number) => {
    return (
      <tr key={index} className="align-middle">
        <td>{row.id}</td>
        <td>{row.name}</td>
        <td>{row.clientName}</td>
        <td style={{ textAlign: 'center' }}>
          <Button
            data-testid="pen"
            variant="link"
            className="text-secondary"
            onClick={() => {
              history.push(`./shop-modify/${row.id}`);
            }}
          >
            <FontAwesomeIcon icon={faPen} fixedWidth className="me-1" />
          </Button>
          <OverlayTrigger trigger="click" placement="top" overlay={copyPopover} rootClose>
            <Button
              data-testid="copy"
              variant="link"
              className="text-secondary"
              onClick={() => {
                copyTextToClipboard(row.id);
              }}
            >
              <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
            </Button>
          </OverlayTrigger>
          {/* <Button
            data-testid="delete"
            variant="link"
            className="text-secondary"
            onClick={() => {
              setIsModal(true);
              setDeleteId(row?.id);
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} fixedWidth className="me-1" />
          </Button> */}
        </td>
      </tr>
    );
  });

  return (
    <>
      <Row>
        <Col>
          <h3 style={{ marginBottom: '20px' }}>実店舗一覧</h3>
          {updResult?.result ? (
            <Alert variant="success" style={{ marginTop: 10 }}>
              削除しました。
            </Alert>
          ) : (
            deleteResult?.result && (
              <Alert variant="success" style={{ marginTop: 10 }}>
                削除しました。
              </Alert>
            )
          )}
          {updResult !== null && updResult?.result === false && (
            <Alert
              variant="danger"
              style={{ marginTop: 10 }}
            >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
          )}
          <Card>
            <div className="d-flex pe-3 ps-3 pt-4 pb-4" style={{ width: '100%', height: '100%' }}>
              <Row style={{ width: '100%' }}>
                <Col className="col-2" style={{ width: '30%' }}>
                  <div className="form-floating" style={{ marginLeft: 8 }}>
                    <input
                      type="number"
                      className="form-control"
                      id="shopId"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      data-testid="shopId"
                    />
                    <label>実店舗ID</label>
                  </div>
                </Col>
                <Col className="col-2" style={{ width: '30%' }}>
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="shopName"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      data-testid="shopName"
                    />
                    <label htmlFor="contractShopName">実店舗名</label>
                  </div>
                </Col>
                <Col className="col-2" style={{ width: '30%' }}>
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="clientName"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      data-testid="clientName"
                    />
                    <label htmlFor="clientName">クライアント名</label>
                  </div>
                </Col>
                <Col className="pe-0 me-0" style={{ textAlign: 'center' }}>
                  <p style={{ width: '100%', height: '100%' }}>
                    <Button onClick={handleOnSearch} type="button" style={{ width: '100%', height: '100%' }}>
                      検索
                    </Button>
                  </p>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      <div style={{ paddingTop: '24px' }}>
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(data.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />
      </div>
      <>
        <Modal
          title="削除してよろしいですか？"
          footer={
            <>
              <Button variant="secondary" onClick={handleClickModalNo}>
                いいえ
              </Button>
              <Button variant="primary" onClick={handleClickModalYes}>
                はい
              </Button>
            </>
          }
          isModal={isModal}
          onHide={handleClickModalNo}
        />
        <Table className="mt-3" style={{ tableLayout: 'auto', width: '100%' }}>
          <thead>
            <tr className="align-middle">
              <th data-testid="shopIdHead" style={{ width: '10%' }}>
                実店舗ID
              </th>
              <th data-testid="shopNameHead">実店舗名</th>
              <th data-testid="clientNameHead">クライアント名</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </>
    </>
  );
};
