import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import type { MailHistoryListOemState } from '../../pages/MailHistory/MailHistoryListOemPage';

export type State = MailHistoryListOemState['forms'];
interface Props {
  setForms: DispatchSetState<State>;
  forms: MailHistoryListOemState['forms'];
  mailTypeList: [string, number | undefined][];
}

export type TestIds = keyof State;

export const MailHistoryListOemForms: React.FC<Props> = ({ setForms, forms, mailTypeList }) => {
  const testId = createTestId<TestIds>(MailHistoryListOemForms);
  const { state: $form, onChangeSet } = useLargeState<MailHistoryListOemState['forms']>(forms);

  useEffect(() => {
    setForms({ ...$form });
  }, [setForms, $form]);

  return (
    <>
      <Form.Group
        data-testid={testId('messageType')}
        controlId="customerRelatedId"
        key="customerRelatedId"
        style={{ width: '80%' }}
      >
        <Form.Label>メール種別</Form.Label>
        <Form.Select value={$form.messageType} onChange={onChangeSet('messageType')}>
          {mailTypeList.map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </>
  );
};
