/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { OemMonitorOutputResponse } from '../domains';
/**
 * OemMonitorListApi - axios parameter creator
 * @export
 */
export const OemMonitorListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * モニター情報を照会する
         * @summary モニター情報照会
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [monitorId] 
         * @param {string} [monitorName] 
         * @param {number} [shopId] 
         * @param {string} [shopName] 
         * @param {number} [apiClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemMonitorList: async (pageNum: number, pagePerItems: number, monitorId?: number, monitorName?: string, shopId?: number, shopName?: string, apiClientId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageNum' is not null or undefined
            assertParamExists('oemMonitorList', 'pageNum', pageNum)
            // verify required parameter 'pagePerItems' is not null or undefined
            assertParamExists('oemMonitorList', 'pagePerItems', pagePerItems)
            const localVarPath = `/api/oem-monitor-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (monitorId !== undefined) {
                localVarQueryParameter['monitorId'] = monitorId;
            }

            if (monitorName !== undefined) {
                localVarQueryParameter['monitorName'] = monitorName;
            }

            if (shopId !== undefined) {
                localVarQueryParameter['shopId'] = shopId;
            }

            if (shopName !== undefined) {
                localVarQueryParameter['shopName'] = shopName;
            }

            if (apiClientId !== undefined) {
                localVarQueryParameter['apiClientId'] = apiClientId;
            }

            if (pageNum !== undefined) {
                localVarQueryParameter['pageNum'] = pageNum;
            }

            if (pagePerItems !== undefined) {
                localVarQueryParameter['pagePerItems'] = pagePerItems;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OemMonitorListApi - functional programming interface
 * @export
 */
export const OemMonitorListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OemMonitorListApiAxiosParamCreator(configuration)
    return {
        /**
         * モニター情報を照会する
         * @summary モニター情報照会
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [monitorId] 
         * @param {string} [monitorName] 
         * @param {number} [shopId] 
         * @param {string} [shopName] 
         * @param {number} [apiClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async oemMonitorList(pageNum: number, pagePerItems: number, monitorId?: number, monitorName?: string, shopId?: number, shopName?: string, apiClientId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OemMonitorOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.oemMonitorList(pageNum, pagePerItems, monitorId, monitorName, shopId, shopName, apiClientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OemMonitorListApi - factory interface
 * @export
 */
export const OemMonitorListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OemMonitorListApiFp(configuration)
    return {
        /**
         * モニター情報を照会する
         * @summary モニター情報照会
         * @param {number} pageNum 
         * @param {number} pagePerItems 
         * @param {number} [monitorId] 
         * @param {string} [monitorName] 
         * @param {number} [shopId] 
         * @param {string} [shopName] 
         * @param {number} [apiClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oemMonitorList(pageNum: number, pagePerItems: number, monitorId?: number, monitorName?: string, shopId?: number, shopName?: string, apiClientId?: number, options?: any): AxiosPromise<OemMonitorOutputResponse> {
            return localVarFp.oemMonitorList(pageNum, pagePerItems, monitorId, monitorName, shopId, shopName, apiClientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OemMonitorListApi - object-oriented interface
 * @export
 * @class OemMonitorListApi
 * @extends {BaseAPI}
 */
export class OemMonitorListApi extends BaseAPI {
    /**
     * モニター情報を照会する
     * @summary モニター情報照会
     * @param {number} pageNum 
     * @param {number} pagePerItems 
     * @param {number} [monitorId] 
     * @param {string} [monitorName] 
     * @param {number} [shopId] 
     * @param {string} [shopName] 
     * @param {number} [apiClientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OemMonitorListApi
     */
    public oemMonitorList(pageNum: number, pagePerItems: number, monitorId?: number, monitorName?: string, shopId?: number, shopName?: string, apiClientId?: number, options?: AxiosRequestConfig) {
        return OemMonitorListApiFp(this.configuration).oemMonitorList(pageNum, pagePerItems, monitorId, monitorName, shopId, shopName, apiClientId, options).then((request) => request(this.axios, this.basePath));
    }
}
