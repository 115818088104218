import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Row, Col, FloatingLabel } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import { PostingRequestRsvInfoListApi, PostingRequestRsvInfoListOutputResponse } from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { PostingReservationListTable } from '../../organisms/Table/PostingReservationListTable';

const getSampleData = (num: number): PostingRequestRsvInfoListOutputResponse[] => {
  return [...Array(num)].map((_, i) => ({
    applicationFormId: i + 1,
    postingRequestId: i + 1,
    clientId: i + 1,
    clientName: `クライアント名${i + 1}`,
    applicationFormType: '新規',
    applicationStartYm: '2022/01/01',
    contractShops: [...Array(3)].map((__, ci) => ({
      id: ci + 1,
      name: `契約店舗名${ci + 1}`,
      monitorPostingRsvs: [...Array(3)].map((___, mi) => ({
        monitorPostingRsvId: mi + 1,
        monitorId: mi + 1,
        monitorName: `モニター名${mi + 1}`,
        preEnqueteName: `事前アンケート${mi + 1}`,
        postEnqueteName: `事後アンケート${mi + 1}`,
      })),
    })),
  }));
};

export interface State {
  api: PostingRequestRsvInfoListApi;
  clientIdOrName: string | undefined;
  fromDate: string | undefined;
  toDate: string | undefined;
  shopName: string | undefined;
  monitorName: string | undefined;
  enqueteName: string | undefined;
  list: PostingRequestRsvInfoListOutputResponse[];
  listPerPage: PostingRequestRsvInfoListOutputResponse[];
  currentPage: number;
  totalPage: number;
}

const MAX_RECORD_PER_PAGE = 100;

export const PostingReservationListPage: React.VFC = () => {
  const testid = createTestId(PostingReservationListPage);
  const initialState = {
    api: new PostingRequestRsvInfoListApi(),
    clientIdOrName: undefined,
    fromDate: undefined,
    toDate: undefined,
    shopName: undefined,
    monitorName: undefined,
    enqueteName: undefined,
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
  };

  const { state: $, mergeState } = useLargeState<State>(initialState);

  useEffect(() => {
    $.api.postingRequestRsvInfoList().then((res: AxiosResponse<PostingRequestRsvInfoListOutputResponse[]>) => {
      setPagingStates(res.data);
      // setPagingStates(getSampleData(3));
    });
  }, []);

  const onSearch = () => {
    $.api
      .postingRequestRsvInfoList($.clientIdOrName, $.fromDate, $.toDate, $.shopName, $.monitorName, $.enqueteName)
      .then((res: AxiosResponse<PostingRequestRsvInfoListOutputResponse[]>) => {
        setPagingStates(res.data);
        // setPagingStates(getSampleData(1));
      });
  };

  const setPagingStates = (list: PostingRequestRsvInfoListOutputResponse[]) => {
    const modifyList = list.sort((a, b) => b.postingRequestId - a.postingRequestId);

    mergeState({
      list: modifyList,
      listPerPage: modifyList.slice(0, MAX_RECORD_PER_PAGE),
      totalPage: Math.ceil(Number(modifyList?.length) / MAX_RECORD_PER_PAGE),
      currentPage: 1,
    });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.POSTING_RESERVATION_LIST}</Title>

      <Form.Group className="mb-4">
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="クライアント名/ID" data-testid={testid('clientIdOrName')}>
              <Form.Control
                type="text"
                placeholder="クライアント名/ID"
                value={$.clientIdOrName || ''}
                onChange={(e) => mergeState({ clientIdOrName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="表示開始日From" data-testid={testid('fromDate')}>
              <Form.Control
                type="date"
                placeholder="表示開始日From"
                value={$.fromDate ? $.fromDate.replace(/\//g, '-') : ''}
                onChange={(e) =>
                  mergeState({ fromDate: e.target.value ? e.target.value.replace(/-/g, '/') : undefined })
                }
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="表示開始日To" data-testid={testid('toDate')}>
              <Form.Control
                type="date"
                placeholder="表示開始日To"
                value={$.toDate ? $.toDate.replace(/\//g, '-') : ''}
                onChange={(e) => mergeState({ toDate: e.target.value ? e.target.value.replace(/-/g, '/') : undefined })}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="契約店舗名" data-testid={testid('shopName')}>
              <Form.Control
                type="text"
                placeholder="契約店舗名"
                value={$.shopName || ''}
                onChange={(e) => mergeState({ shopName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="モニター名" data-testid={testid('monitorName')}>
              <Form.Control
                type="text"
                placeholder="モニター名"
                value={$.monitorName || ''}
                onChange={(e) => mergeState({ monitorName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="アンケート名" data-testid={testid('enqueteName')}>
              <Form.Control
                type="text"
                placeholder="アンケート名"
                value={$.enqueteName || ''}
                onChange={(e) => mergeState({ enqueteName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col className="col-4">
            <Button className="w-100 py-3" onClick={onSearch} data-testid={testid('search-button')}>
              検索
            </Button>
          </Col>
        </Row>
      </Form.Group>

      <PostingReservationListTable listPerPage={$.listPerPage} />

      <div className="mb-4" data-testid={testid('pagination')}>
        <PaginationWithEllipsis
          currentPage={$.currentPage}
          totalPage={$.totalPage}
          handleClick={(page) => {
            if (!page || page > $.totalPage) return;
            mergeState({
              listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
              currentPage: page,
            });
          }}
        />
      </div>
    </>
  );
};
