/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AboutOpeningHourSetInfoOutputResponse } from '../domains';
// @ts-ignore
import { AboutOpeningHourSetUpdateFormResponse } from '../domains';
// @ts-ignore
import { ClientOfRequiredNumberListOutputResponse } from '../domains';
// @ts-ignore
import { ClientOfRequiredNumberTargetListOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 営業時間文言設定情報取得を取得する
         * @summary 営業時間文言設定情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutOpeningHourSetInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/aboutOpeningHourSet/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 営業時間文言設定を更新する
         * @summary 営業時間文言設定更新
         * @param {AboutOpeningHourSetUpdateFormResponse} aboutOpeningHourSetUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutOpeningHourSetUpdate: async (aboutOpeningHourSetUpdateFormResponse: AboutOpeningHourSetUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aboutOpeningHourSetUpdateFormResponse' is not null or undefined
            assertParamExists('aboutOpeningHourSetUpdate', 'aboutOpeningHourSetUpdateFormResponse', aboutOpeningHourSetUpdateFormResponse)
            const localVarPath = `/api/aboutOpeningHourSet`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aboutOpeningHourSetUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント派遣マスト数一覧を取得する
         * @summary クライアント派遣マスト数一覧取得
         * @param {boolean} configuredFlg 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientOfRequiredNumberList: async (configuredFlg: boolean, keyword?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configuredFlg' is not null or undefined
            assertParamExists('clientOfRequiredNumberList', 'configuredFlg', configuredFlg)
            const localVarPath = `/api/clientOfRequiredNumber/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (configuredFlg !== undefined) {
                localVarQueryParameter['configuredFlg'] = configuredFlg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント派遣マスト数対象一覧を取得する
         * @summary クライアント派遣マスト数対象一覧取得
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientOfRequiredNumberTargetList: async (key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/clientOfRequiredNumberTarget/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 営業時間文言設定情報取得を取得する
         * @summary 営業時間文言設定情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutOpeningHourSetInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AboutOpeningHourSetInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutOpeningHourSetInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 営業時間文言設定を更新する
         * @summary 営業時間文言設定更新
         * @param {AboutOpeningHourSetUpdateFormResponse} aboutOpeningHourSetUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async aboutOpeningHourSetUpdate(aboutOpeningHourSetUpdateFormResponse: AboutOpeningHourSetUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.aboutOpeningHourSetUpdate(aboutOpeningHourSetUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント派遣マスト数一覧を取得する
         * @summary クライアント派遣マスト数一覧取得
         * @param {boolean} configuredFlg 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientOfRequiredNumberList(configuredFlg: boolean, keyword?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientOfRequiredNumberListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientOfRequiredNumberList(configuredFlg, keyword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント派遣マスト数対象一覧を取得する
         * @summary クライアント派遣マスト数対象一覧取得
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientOfRequiredNumberTargetList(key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientOfRequiredNumberTargetListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientOfRequiredNumberTargetList(key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 営業時間文言設定情報取得を取得する
         * @summary 営業時間文言設定情報取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutOpeningHourSetInfo(options?: any): AxiosPromise<AboutOpeningHourSetInfoOutputResponse> {
            return localVarFp.aboutOpeningHourSetInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 営業時間文言設定を更新する
         * @summary 営業時間文言設定更新
         * @param {AboutOpeningHourSetUpdateFormResponse} aboutOpeningHourSetUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        aboutOpeningHourSetUpdate(aboutOpeningHourSetUpdateFormResponse: AboutOpeningHourSetUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.aboutOpeningHourSetUpdate(aboutOpeningHourSetUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント派遣マスト数一覧を取得する
         * @summary クライアント派遣マスト数一覧取得
         * @param {boolean} configuredFlg 
         * @param {string} [keyword] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientOfRequiredNumberList(configuredFlg: boolean, keyword?: string, options?: any): AxiosPromise<Array<ClientOfRequiredNumberListOutputResponse>> {
            return localVarFp.clientOfRequiredNumberList(configuredFlg, keyword, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント派遣マスト数対象一覧を取得する
         * @summary クライアント派遣マスト数対象一覧取得
         * @param {string} [key] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientOfRequiredNumberTargetList(key?: string, options?: any): AxiosPromise<Array<ClientOfRequiredNumberTargetListOutputResponse>> {
            return localVarFp.clientOfRequiredNumberTargetList(key, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 営業時間文言設定情報取得を取得する
     * @summary 営業時間文言設定情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aboutOpeningHourSetInfo(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aboutOpeningHourSetInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 営業時間文言設定を更新する
     * @summary 営業時間文言設定更新
     * @param {AboutOpeningHourSetUpdateFormResponse} aboutOpeningHourSetUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public aboutOpeningHourSetUpdate(aboutOpeningHourSetUpdateFormResponse: AboutOpeningHourSetUpdateFormResponse, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).aboutOpeningHourSetUpdate(aboutOpeningHourSetUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント派遣マスト数一覧を取得する
     * @summary クライアント派遣マスト数一覧取得
     * @param {boolean} configuredFlg 
     * @param {string} [keyword] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientOfRequiredNumberList(configuredFlg: boolean, keyword?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientOfRequiredNumberList(configuredFlg, keyword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント派遣マスト数対象一覧を取得する
     * @summary クライアント派遣マスト数対象一覧取得
     * @param {string} [key] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clientOfRequiredNumberTargetList(key?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).clientOfRequiredNumberTargetList(key, options).then((request) => request(this.axios, this.basePath));
    }
}
