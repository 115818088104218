import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
/* eslint-disable import/no-cycle */
import { ApplicationFormResponse, ClientDetailInfoApi, ClientDetailInfoOutputResponse } from '../../../api-client';
import { TITLE } from '../../../constants/Title';
import { createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Title } from '../../atoms/Title';
/* eslint-enable import/no-cycle */

export type State = {
  api: ClientDetailInfoApi;
  // api: ReturnType<typeof clientDetailInfoApi>;
  applicationFormList: ApplicationFormResponse[];
  salesList: string[];
  isFormatAlert: boolean;
};

export const APPLICATION_TYPE = ['新規', '追加', '変更', '解約', '休止'] as const;
export const APPLICATION_STATUS = ['承認依頼中', '承認', '差戻', '削除'] as const;

export const ClientModifyPage: React.VFC = () => {
  const testid = createTestId(ClientModifyPage);

  const { clientId } = useParams<{
    clientId: string | undefined;
  }>();

  const { state: $, mergeState } = useLargeState<State>({
    api: new ClientDetailInfoApi(),
    applicationFormList: [],
    salesList: [],
    isFormatAlert: false,
  });

  const {
    state: f,
    mergeState: fMergeState,
    onChangeSet: fOnChangeSet,
  } = useLargeState<ClientDetailInfoOutputResponse>({
    clientName: '',
    taxationFlg: true,
    id: 1,
    applicationFormList: [],
    salesList: [],
  });

  useEffect(() => {
    if (clientId) {
      $.api.clientDetailInfo(Number(clientId)).then((res: AxiosResponse<ClientDetailInfoOutputResponse>) => {
        const { applicationFormList, salesforceId, salesList, ...rest } = res.data;
        mergeState({ applicationFormList: applicationFormList.sort((a, b) => b.id - a.id), salesList });
        fMergeState({ ...rest });
      });
    }
  }, [$.api, mergeState, fMergeState, clientId]);

  return (
    <>
      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.CLIENT_DETAIL}
      </Title>

      <Form>
        <Form.Label data-testid={testid('id')}>ID</Form.Label>
        <Form.Control readOnly type="text" className="mb-4" value={clientId} />

        {$.salesList.length !== 0 && (
          <>
            <Form.Label data-testid={testid('salesList')}>営業担当者</Form.Label>
            <Form.Control readOnly type="text" className="mb-4" value={$.salesList?.join(',') || ''} />
          </>
        )}

        <Form.Label data-testid={testid('clientName')}>会社名</Form.Label>
        <Form.Control readOnly type="text" className="mb-4" value={f.clientName || ''} />

        <Form.Label data-testid={testid('clientRepresentative')}>代表者</Form.Label>
        <Form.Control readOnly type="text" className="mb-4" value={f.clientRepresentative || ''} />

        <Form.Label data-testid={testid('taxationFlg')}>課税フラグ</Form.Label>
        <Form.Control type="text" readOnly className="mb-4" value={f.taxationFlg ? '課税' : '非課税'} />

        <Form.Label data-testid={testid('taxationFlg')}>説明</Form.Label>
        <Form.Control
          readOnly
          type="text"
          as="textarea"
          className="mb-4"
          style={{ height: '100px' }}
          value={f.description || ''}
        />

        {f.id && (
          <Form.Group className="mt-4">
            <Form.Label className="fw-bold mb-2">申込書一覧</Form.Label>
            <div style={{ maxHeight: '468px', overflowY: 'scroll' }}>
              <Table data-testid={testid('table')} className="mb-0">
                <thead className="sticky-top" style={{ backgroundColor: '#f8f9fa' }}>
                  <tr>
                    <th>ID</th>
                    <th>申込書名</th>
                    <th>種別</th>
                    <th>ステータス</th>
                    <th>契約開始日</th>
                    <th>契約終了日</th>
                  </tr>
                </thead>
                <tbody>
                  {$.applicationFormList.map(({ id, name, type, status, contractStartDate, contractEndDate }) => (
                    <tr key={id} className="align-middle">
                      <td>{id}</td>
                      <td>{name}</td>
                      <td>{APPLICATION_TYPE[type]}</td>
                      <td>{APPLICATION_STATUS[status]}</td>
                      <td>{contractStartDate}</td>
                      <td>{contractEndDate}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Form.Group>
        )}
      </Form>
    </>
  );
};
