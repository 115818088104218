import React, { useCallback, useMemo } from 'react';
import { Button, Form, Stack } from 'react-bootstrap';
import type { NoteListOutputResponse, NoteUpdateFormResponse } from '../../../../../api-client';
import { useLargeState } from '../../../../../hooks/useLargeState';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { debugLog } from '../../../../../utils/functions';
import { TemplateFormBodyState } from '../../../../pages/Correction/constants';
import { nativeAlert } from '../../../../pages/Correction/utils';
import { CommonBody } from './CommonBody';

interface State {
  range?: NoteListOutputResponse['visibleRangeCode'];
  target?: NoteListOutputResponse['visibleTarget']['code'];
  importantFlg: NoteListOutputResponse['importantFlg'];
  // TODO: [添削] ノートの画像系は今回実装しない
  // uploadedImages: NoteListOutputResponse['noteAttachedImageList'];
  // deleteUploadedImages: NoteListOutputResponse['noteAttachedImageList'][0]['attachedImageId'][];
  // newImages: File[];
  templateFormState?: TemplateFormBodyState;
}

export const NoteBody: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  const { state, onChangeSet, mergeState } = useLargeState<State>({
    range: $.form_editNote?.visibleRangeCode,
    target: $.form_editNote?.visibleTarget?.code,
    importantFlg: !!$.form_editNote?.importantFlg,
    // TODO: [添削] ノートの画像系は今回実装しない
    // uploadedImages: $.form_editNote?.noteAttachedImageList ?? [],
    // uploadedImages: [
    //   { attachedImageId: 0, filePath: uuid() },
    //   { attachedImageId: 1, filePath: uuid() },
    //   { attachedImageId: 2, filePath: uuid() },
    //   { attachedImageId: 3, filePath: uuid() },
    //   { attachedImageId: 4, filePath: uuid() },
    // ],
    // deleteUploadedImages: [],
    // newImages: [],
  });
  const onChangeTemplateForm = useCallback(
    (templateFormState) => {
      debugLog(templateFormState);
      mergeState({ templateFormState });
    },
    [mergeState]
  );
  const initContent = useMemo(() => $.form_editNote?.content, [$.form_editNote?.content]);
  const onSubmit = useCallback(() => {
    (async () => {
      // TODO: [添削] ノートの画像系は今回実装しない
      // const newImages = await Promise.all(state.newImages.map(fileToDataUrl));
      // if (!newImages.every((x) => typeof x === 'string')) {
      //   nativeAlert('エラーが発生しました。もう一度実行してください。');
      //   return;
      // }
      // const newUploadImages = newImages.filter(Boolean);
      const data: NoteUpdateFormResponse = {
        applyId: $.applyId,
        content: state.templateFormState?.content ?? '',
        displayPoint: {
          targetId: $.form_editNote?.displayPoint?.targetId,
          typeCode: $.form_editNote?.displayPoint?.type,
        },
        importantFlg: state.importantFlg,
        // TODO: [添削] ノートの画像系は今回実装しない
        // noteAttachedImage: [
        //   ...state.uploadedImages
        //     .filter((x) => !state.deleteUploadedImages.includes(x.attachedImageId))
        //     .map((x) => x.filePath),
        //   ...newUploadImages,
        // ].map((filePath, id) => ({
        //   id,
        //   filePath,
        // })),
        noteId: $.form_editNote?.noteId,
        visibleRangeCode: Number(state.range),
        visibleTargetCode: Number(state.target),
      } as NoteUpdateFormResponse;
      try {
        await $.api_note.noteUpdate(data);
        debugLog({ data });
      } catch (error) {
        nativeAlert('エラーが発生しました。もう一度やりなおしてください。');
      }
      window.location.reload();
    })();
  }, [$.applyId, $.form_editNote, state, $.api_note]);
  return (
    <>
      <Form.Group>
        <Form.Label>表示範囲</Form.Label>
        <Form.Select size="lg" value={state.range ?? ''} onChange={onChangeSet('range')}>
          <option hidden value="">
            選択してください
          </option>
          {($.enum_noteVisibleRangeType ?? []).map(({ code, name }) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>閲覧制限</Form.Label>
        <Form.Select size="lg" value={state.target ?? ''} onChange={onChangeSet('target')}>
          <option hidden value="">
            選択してください
          </option>
          {($.enum_noteVisibleTargetType ?? []).map(({ code, name }) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group style={{ marginBottom: 8, marginTop: 8 }}>
        <Form.Check>
          <Form.Check.Input
            type="checkbox"
            checked={state.importantFlg}
            onChange={(e) => {
              mergeState({ importantFlg: e.target.checked });
            }}
          />
          <Form.Check.Label>重要</Form.Check.Label>
        </Form.Check>
      </Form.Group>
      {/* TODO: [添削] ノートの画像系は今回実装しない */}
      {/* <Dropbox onChange={(files) => mergeState({ newImages: files })} />
      {when(
        !!state.uploadedImages.length,
        <>
          <ul>
            {state.uploadedImages
              .filter((x) => !state.deleteUploadedImages.includes(x.attachedImageId))
              .map(({ filePath, attachedImageId }) => (
                <SideBy key={attachedImageId}>
                  <li>{filePath}</li>
                  <Button
                    variant="danger"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        deleteUploadedImages: [...prev.deleteUploadedImages, attachedImageId],
                      }));
                      debugLog(filePath, attachedImageId);
                    }}
                  >
                    削除
                  </Button>
                </SideBy>
              ))}
          </ul>
          {when(
            !state.deleteUploadedImages.length,
            <Button
              variant="danger"
              onClick={() => mergeState({ deleteUploadedImages: state.uploadedImages.map((x) => x.attachedImageId) })}
            >
              クラウドから全画像を削除
            </Button>
          )}
        </>
      )} */}
      <CommonBody
        name="ノート"
        templateList={$.res_noteTemplateList ?? []}
        initContent={initContent}
        isOptionalTempId={!!initContent}
        onChange={onChangeTemplateForm}
      />
      <hr />
      <Stack>
        <Button className="border ms-auto" onClick={onSubmit}>
          送信
        </Button>
      </Stack>
    </>
  );
};
