import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Controller, FieldArrayWithId, UseFormRegister, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import {
  Card,
  OverlayTrigger,
  Popover,
  Form,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  FloatingLabel,
  Row,
  FormControl,
  Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faGripLines, faTimesCircle, faAngleUp, faLink, faLaptopCode } from '@fortawesome/free-solid-svg-icons';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { Dropzone } from '../molecules/Dropzone';
// import { FormControl } from '../molecules/FormControl';
import { ImageCard } from '../molecules/ImageCard';
import {
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoMonitorRuleSupplementOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;
  index: number;
  contentsFlg?: boolean;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId?: React.Dispatch<React.SetStateAction<number>>;
}
export const MonitorRuleSetCommonContents2: React.VFC<Props> = ({
  data,
  setData,
  rowData,
  index,
  contentsFlg,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  const [imgUrl, setImgUrl] = useState<string | undefined>(
    data?.monitorRuleList?.find((supplement) => {
      return supplement.monitorRuleType === rowData.monitorRuleType;
    })?.imageURL
  );

  useEffect(() => {
    setImgUrl(
      data?.monitorRuleList?.find((supplement) => {
        return supplement.monitorRuleType === rowData.monitorRuleType;
      })?.imageURL
    );
  }, [
    data?.monitorRuleList?.find((supplement) => {
      return supplement.monitorRuleType === rowData.monitorRuleType;
    })?.imageURL,
  ]);

  // 画像添付イベント
  const onImgDrop = (acceptedFiles: any) => {
    const createObject = window.webkitURL.createObjectURL;
    // 1ファイルの場合の処理
    if (acceptedFiles.length !== 0 && acceptedFiles.length === 1) {
      const reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = () => {
        setData({
          ...data,
          monitorRuleList: data?.monitorRuleList.map((_, i) => {
            return i === index
              ? { ..._, imageId: 1, imageURL: reader.result?.toString() === undefined ? '' : reader.result.toString() }
              : { ..._ };
          }),
        });
      };
    }
  };

  // 画像削除イベント
  const onImageDelete = () => {
    // const newImgList : ImageListData[]  = Object.arguments([],data?.monitorRuleSetList?.find((d,i)=>{return i === index})?.imageRuleList![ind]?.imageList);
    setData({
      ...data,
      monitorRuleList: data?.monitorRuleList.map((row, inde) => {
        return inde === index ? { ...row, imageId: undefined, imageURL: undefined } : { ...row };
      }),
    });
  };

  const iconOptions: [string, number][] = Object.entries({
    飲食調査: 1,
    電話調査: 2,
    写真撮影: 3,
    トイレ調査: 4,
    スタッフ調査: 5,
    外観調査: 6,
    施術調査: 7,
    購入店舗: 8,
    商品調査: 9,
    体験調査: 10,
    清掃調査: 11,
    予約調査: 12,
    その他: 99,
  });

  return (
    <>
      <div className={contentsFlg === undefined ? 'flex-grow-1 ms-3' : 'flex-grow-1'}>
        <FloatingLabel controlId="monitorRuleType" label="アイコン" className="mb-4">
          {/* TDOO 参照元が不明 */}
          <Form.Select
            value={
              data.monitorRuleList.find((_, ind) => {
                return ind === index;
              })?.iconType || ''
            }
            onChange={(e) => {
              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                  return inde === index ? { ...row, iconType: Number(e.target.value) } : { ...row };
                }),
              });
            }}
          >
            <option value="">&nbsp;</option>
            {iconOptions.map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>

        {data.monitorRuleList
          .find((_, ind) => {
            return ind === index;
          })
          ?.supplementList?.map((_, i) => {
            return (
              <div key={i.toString()} className="d-flex mb-4">
                <FloatingLabel controlId="supplement" label="補足" className="flex-grow-1">
                  <FormControl
                    as="textarea"
                    style={{ height: '150px' }}
                    placeholder="補足"
                    value={
                      data.monitorRuleList.find((_row, ind) => {
                        return ind === index;
                      })?.supplementList?.[i].supplement || ''
                    }
                    onChange={(e) => {
                      const copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                        [],
                        data.monitorRuleList.find((row, ind) => {
                          return ind === index;
                        })?.supplementList
                      );

                      setData({
                        ...data,
                        monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                          return inde === index
                            ? {
                                ...row,
                                supplementList: copySupplementList.map((supple, ind) => {
                                  return ind === i ? { ...supple, supplement: e.target.value } : { ...supple };
                                }),
                              }
                            : { ...row };
                        }),
                      });
                    }}
                  />
                </FloatingLabel>
                {i !== 0 && (
                  <Button
                    data-testid="supplementDeleteButton"
                    className="text-secondary bg-transparent border-0"
                    onClick={() => {
                      const copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                        [],
                        data.monitorRuleList.find((row, ind) => {
                          return ind === index;
                        })?.supplementList
                      );

                      setData({
                        ...data,
                        monitorRuleList: data?.monitorRuleList.map((row, inde) => {
                          return inde === index
                            ? {
                                ...row,
                                supplementList: copySupplementList.filter((supplement, ind) => {
                                  return ind !== i;
                                }),
                              }
                            : { ...row };
                        }),
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                  </Button>
                )}
              </div>
            );
          })}
        <div className="d-flex justify-content-end mb-4">
          <Button
            data-testid="supplementAddButton"
            variant="link"
            className="text-secondary p-0"
            onClick={() => {
              const copySupplementList: MonitorRuleSetInfoMonitorRuleSupplementOutputResponse[] = Object.assign(
                [],
                data.monitorRuleList.find((_, ind) => {
                  return ind === index;
                })?.supplementList
              );
              copySupplementList.push({
                // id: copySupplementList[copySupplementList.length - 1].id + 1,
                id: undefined,
                supplement: '',
              });

              setData({
                ...data,
                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                  return i === index ? { ..._, supplementList: copySupplementList } : { ..._ };
                }),
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
            <span style={{ fontSize: '1rem' }}>追加</span>
          </Button>
        </div>
        {imgUrl ? <ImageCard url={imgUrl} onDelete={onImageDelete} /> : <Dropzone onDrop={onImgDrop} />}
      </div>
    </>
  );
};
