/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CommonMasterListOutputResponse } from '../domains';
/**
 * CommonMasterListApi - axios parameter creator
 * @export
 */
export const CommonMasterListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * マスタデータ一覧を取得する
         * @summary マスタデータ一覧取得
         * @param {string} key 
         * @param {number} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonMasterList: async (key: string, type?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('commonMasterList', 'key', key)
            const localVarPath = `/api/commonMaster-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonMasterListApi - functional programming interface
 * @export
 */
export const CommonMasterListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonMasterListApiAxiosParamCreator(configuration)
    return {
        /**
         * マスタデータ一覧を取得する
         * @summary マスタデータ一覧取得
         * @param {string} key 
         * @param {number} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonMasterList(key: string, type?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommonMasterListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonMasterList(key, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommonMasterListApi - factory interface
 * @export
 */
export const CommonMasterListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonMasterListApiFp(configuration)
    return {
        /**
         * マスタデータ一覧を取得する
         * @summary マスタデータ一覧取得
         * @param {string} key 
         * @param {number} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonMasterList(key: string, type?: number, options?: any): AxiosPromise<Array<CommonMasterListOutputResponse>> {
            return localVarFp.commonMasterList(key, type, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonMasterListApi - object-oriented interface
 * @export
 * @class CommonMasterListApi
 * @extends {BaseAPI}
 */
export class CommonMasterListApi extends BaseAPI {
    /**
     * マスタデータ一覧を取得する
     * @summary マスタデータ一覧取得
     * @param {string} key 
     * @param {number} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonMasterListApi
     */
    public commonMasterList(key: string, type?: number, options?: AxiosRequestConfig) {
        return CommonMasterListApiFp(this.configuration).commonMasterList(key, type, options).then((request) => request(this.axios, this.basePath));
    }
}
