import React, { useCallback } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { Modal } from '../../molecules/Modal';
// eslint-disable-next-line import/no-cycle
import { ErrorObjectResponse, ExtendDeadlineHistoryApi } from '../../../api-client';
import type {
  ExtendDeadlineHisUpdateConfirmFormResponse,
  ExtendDeadlineHisUpdateConfirmOutputResponse,
  ExtendDeadlineHisUpdateFormResponse,
  ResultOutputResponse,
} from '../../../api-client';
import { ExtendDeadlineRegisterForms } from '../../organisms/Forms/ExtendDeadlineRegisterForms';
import { ExtendDeadlineRegisterTable } from '../../organisms/Table/ExtendDeadlineRegisterTable';
import { createTestId } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Url } from '../../../constants/Url';

export interface ExtendDeadlineRegisterState {
  api: ExtendDeadlineHistoryApi;
  isLoading: boolean;
  list: ExtendDeadlineHisUpdateConfirmOutputResponse[];
  forms: FormState;
  reason: string;
  isConfirm: boolean;
  isModal: boolean;
  modalMessage: string;
  errorMessages: string[];
}
export type TestIds = 'confirm-button' | 'submit-all-button' | 'submit-exclusion-button' | 'exit-button' | 'ok-button';
export type OptionType = { value: number; label: string };
export type FormState = { applyIdList: string[]; afterDeadline: string; reason: string };

export const ExtendDeadlineRegisterPage: React.FC = () => {
  const testid = createTestId<TestIds>(ExtendDeadlineRegisterPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<ExtendDeadlineRegisterState>({
    api: new ExtendDeadlineHistoryApi(),
    isLoading: false,
    list: [],
    forms: { applyIdList: [], afterDeadline: '', reason: '' },
    reason: '',
    isConfirm: false,
    isModal: false,
    modalMessage: '',
    errorMessages: [],
  });

  const history = useHistory();
  const setForms = useBindSet('forms');

  // 確認
  const createConfirmBody = useCallback((body: FormState) => {
    return {
      applyIdList: body.applyIdList.map((applyId: string) => Number(applyId)),
      afterDeadline: body.afterDeadline ? parseISO(body.afterDeadline as string).toISOString() : body.afterDeadline,
      reason: body.reason,
    };
  }, []);

  const onConfirm = useCallback(
    (body: FormState) => {
      if (body.applyIdList.length === 0 || body.afterDeadline.length === 0 || body.reason.length === 0) {
        mergeState({ errorMessages: ['入力されていない条件があります'], list: [] });
        return;
      }

      if (body.applyIdList.length !== Array.from(new Set(body.applyIdList)).length) {
        mergeState({ errorMessages: ['応募IDに重複があります。'], list: [] });
        return;
      }
      const confirmBody: ExtendDeadlineHisUpdateConfirmFormResponse = createConfirmBody(body);
      $.api
        .extendDeadlineHistoryUpdateConfirm(confirmBody)
        .then((res: AxiosResponse<ExtendDeadlineHisUpdateConfirmOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessages: ['エラーが発生しました'] });
          } else {
            mergeState({ list: res.data, reason: body.reason, errorMessages: [] });
          }
        })
        .catch((error) => {
          const errors = error.response?.data.errors;
          const errorMessages = errors.map((value: ErrorObjectResponse) => value.message);
          mergeState({ errorMessages, list: [] });
        });
    },
    [$.api, createConfirmBody, mergeState]
  );

  // 実行
  const createRegisterBody = useCallback(
    (body: ExtendDeadlineHisUpdateConfirmOutputResponse[], reason, excludeNoRemainFlg: boolean) => {
      const returnList: ExtendDeadlineHisUpdateFormResponse[] = [];

      body.forEach((rowData: ExtendDeadlineHisUpdateConfirmOutputResponse) => {
        returnList.push({
          applyId: rowData.applyId,
          afterDeadline: rowData.afterDeadline,
          reason,
          attentionPoint: rowData.attentionPoint,
          excludeNoRemainFlg,
        });
      });
      return returnList;
    },
    []
  );

  const onSubmit = useCallback(
    (body: ExtendDeadlineHisUpdateConfirmOutputResponse[], reason: string, excludeNoRemainFlg: boolean) => {
      const registerBody: ExtendDeadlineHisUpdateFormResponse[] = createRegisterBody(body, reason, excludeNoRemainFlg);
      mergeState({ isLoading: true });
      $.api
        .extendDeadlineHistoryUpdate(registerBody)
        .then((res: AxiosResponse<ResultOutputResponse>) => {
          if (res.data.result) {
            mergeState({ isLoading: false, isModal: true, modalMessage: '登録に成功しました。', errorMessages: [] });
          } else {
            mergeState({ isLoading: false, errorMessages: ['登録に失敗しました。'] });
          }
        })
        .catch((error) => {
          const errors = [...error.response?.data.errors];
          const errorMessages = errors.map((value: ErrorObjectResponse) => value.message);
          mergeState({ errorMessages, list: [] });
        });
    },
    [$.api, createRegisterBody, mergeState]
  );

  return (
    <>
      <Modal
        centered
        isModal={$.isModal}
        body={
          <>
            <p className="text-center">{$.modalMessage}</p>
            <div className="text-center">
              <Button
                data-testid={testid('ok-button')}
                onClick={() => history.push(Url.TENSAKU.EXTEND_DEADLINE_HISTORY)}
              >
                OK
              </Button>
            </div>
          </>
        }
      />
      <LoadingSpinner isLoading={$.isLoading}>
        {$.errorMessages.map((message) => (
          <Alert variant="danger">{message}</Alert>
        ))}
        <Form className="mt-4">
          <Row className="g-2 mb-4">
            <Col>
              <ExtendDeadlineRegisterForms setForms={setForms} onConfirm={onConfirm} />
              <Button
                data-testid={testid('confirm-button')}
                className="mt-4"
                onClick={() => {
                  onConfirm($.forms);
                }}
              >
                確認
              </Button>
            </Col>
          </Row>
        </Form>
        {$.list.length ? (
          <>
            残枠なし応募件数：
            {
              $.list.filter((val) => {
                return val.attentionPoint.includes('残枠なし');
              }).length
            }
            件
            <ExtendDeadlineRegisterTable list={$.list} reason={$.reason} />
            <Button
              data-testid={testid('submit-all-button')}
              className="mt-4"
              onClick={() => {
                onSubmit($.list, $.reason, false);
              }}
            >
              全て実施
            </Button>
            <br />
            <Button
              data-testid={testid('submit-exclusion-button')}
              className="mt-4"
              onClick={() => {
                onSubmit($.list, $.reason, true);
              }}
            >
              残枠なしを除外して実施
            </Button>
            <br />
            <Button
              data-testid={testid('exit-button')}
              className="mt-4"
              onClick={() => history.push(Url.TENSAKU.EXTEND_DEADLINE_HISTORY)}
            >
              戻る
            </Button>
          </>
        ) : undefined}
      </LoadingSpinner>
    </>
  );
};
