import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { PasswordForm } from '../atoms/PasswordForm';
import { InputValidateError } from '../atoms/InputValidateError';
import { IncAccountApi } from '../../api-client';
import { when } from '../../utils/functions';
import { Url } from '../../constants/Url';

export const PassworEdit: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [currentPassword, setCurrent] = useState<string>();
  const [newPassword, setNew] = useState<string>();
  const [newPasswordError, setNewPasswordError] = useState<string>();
  const [newPasswordInvalidFlg, setNewPasswordInvalidFlg] = useState<boolean>(false);
  const [confirmPassword, setConfirm] = useState<string>();
  const [confirmPasswordError, setConfirmPassworError] = useState<string>();
  const [confirmInvalidFlg, setConfirmInvalidFlg] = useState<boolean>(false);
  const [api, setApi] = useState<IncAccountApi>();

  useEffect(() => {
    setApi(new IncAccountApi());
  }, []);

  useEffect(() => {
    api?.passwordCheck().then((res) => {
      // パスワードチェックでfalseが変えるということは脆弱性が高いので、強制的にパスワード変更画面に飛んできたことになる
      // 混乱を避けるため、エラーメッセージを表示しておく
      if (!res.data.result) setErrorMessage('脆弱性が高いのでパスワードを変更してください');
    });
  }, [api]);

  useEffect(() => {
    if (newPassword === confirmPassword) {
      setConfirmInvalidFlg(false);
      setConfirmPassworError('');
    } else {
      setConfirmInvalidFlg(true);
      setConfirmPassworError('パスワードが一致していません');
    }
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    if (newPassword && newPassword?.length < 8) {
      setNewPasswordError('パスワードは8文字以上にしてください');
      return;
    }

    setNewPasswordError(undefined);
  }, [newPassword]);

  const exec = useCallback(() => {
    const form = { currentPassword: currentPassword!, newPassword: newPassword! };
    api
      ?.passwordEdit(form)
      .then(() => {
        // パスワード変更に成功したので、強制的にログアウト
        window.location.href = Url.LOGOUT;
      })
      .catch(({ response }) => {
        console.log('失敗');
        setErrorMessage(response.data.message);
      });
  }, [api, currentPassword, newPassword]);

  return (
    <div className="mb-3">
      <Card style={{ width: '800px', margin: 'auto', padding: '10px' }}>
        <div style={{ width: '500px', margin: 'auto' }}>
          {when(!!errorMessage, <Alert variant="danger">{errorMessage}</Alert>)}
          <PasswordForm label="現在のパスワード" name="current" value={currentPassword} setValue={setCurrent} />
          <PasswordForm
            label="新パスワード"
            name="new"
            value={newPassword}
            setValue={setNew}
            isInvalid={newPasswordInvalidFlg}
          />
          <InputValidateError error={newPasswordError} />
          <PasswordForm
            label="新パスワード（確認用）"
            name="confirm"
            value={confirmPassword}
            setValue={setConfirm}
            isInvalid={confirmInvalidFlg}
          />
          <InputValidateError error={confirmPasswordError} />
          <br />
          <Button onClick={exec}>変更</Button>
        </div>
      </Card>
    </div>
  );
};
