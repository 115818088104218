import React, { useEffect, useState } from 'react';
import { Alert } from '../../atoms/Alert';
import { IncResultOutputResponse } from '../../../api-client';

export interface Props {
  // children: React.ReactNode;
  updResult: IncResultOutputResponse | undefined;
  fadeoutTime: number | null;
  setUpdResult: React.Dispatch<React.SetStateAction<IncResultOutputResponse | undefined>>;
}

export const ResultAlert: React.VFC<Props> = ({ updResult, fadeoutTime, setUpdResult }) => {
  const [dispFlg, setDispFlg] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [msgType, setMsgType] = useState<'success' | 'danger' | undefined>();
  const getMessage = () => {
    if (updResult === undefined) {
      return '';
    }
    if (updResult.result) {
      return '更新しました。';
    }
    return `${updResult?.errorMessage} (エラーコード：${updResult?.errorCode})`;
  };
  const getMessageType = () => {
    if (updResult === undefined) {
      return undefined;
    }
    if (updResult.result) {
      return 'success';
    }
    return 'danger';
  };

  useEffect(() => {
    setDispFlg(updResult !== undefined);
    setMessage(getMessage);
    setMsgType(getMessageType);

    if (fadeoutTime !== null) {
      const timer = setTimeout(() => {
        // setDispFlg(false);
        setUpdResult(undefined);
      }, fadeoutTime);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [updResult]);

  return (
    <>
      {dispFlg && (
        <div className="m-2">
          <Alert variant={msgType} show={dispFlg}>
            {message}
          </Alert>
        </div>
      )}
    </>
  );
};
