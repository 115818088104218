import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AxiosResponse } from 'axios';
import ReactPaginate from 'react-paginate';
import { currentUserState } from '../../../states/currentUser';
import type { SqlQueryListOutputResponse, SqlQueryListParentOutputResponse } from '../../../api-client';
// eslint-disable-next-line import/no-cycle
import { SqlQueryApi } from '../../../api-client';
import { SqlQueryTable } from '../../organisms/Table/SqlQueryTable';
import { LinkButton } from '../../atoms/Button/LinkButton';
import { Url } from '../../../constants/Url';
import { when } from '../../../utils/functions';

export const SqlQueryPage: React.VFC = () => {
  const [listData, setListData] = useState<SqlQueryListOutputResponse[]>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const history = useHistory();
  const currentUser = useRecoilValue(currentUserState);

  // 1ページの最大数（指定しなくてもバックエンド側でデフォルト値[50]が適用される)
  const MAX_RECORD_PER_PAGE = 50;

  const [page, setPage] = useState(0);

  useEffect(() => {
    const sqlQueryApi = new SqlQueryApi();
    sqlQueryApi
      .sqlQueryList(currentUser!.id, page, MAX_RECORD_PER_PAGE)
      .then((response: AxiosResponse<SqlQueryListParentOutputResponse>) => {
        setPageCount(response.data.total / MAX_RECORD_PER_PAGE);
        setListData(response.data.list);
      });
  }, [page]);

  const handlePageChange = (selectedPage: number) => {
    setPage(selectedPage);
  };

  const handleOnClick = (type: string) => {
    switch (type) {
      case 'create':
        return history.push(Url.KEISAI.SQL_QUERY_CREATE);
      case 'sqlTemplateList':
        return history.push(Url.KEISAI.SQL_TMP);
      default:
        return history.push(Url.COMMON_ERROR);
    }
  };

  return (
    <>
      <LinkButton text="SQLテンプレート一覧へ" onClick={() => handleOnClick('sqlTemplateList')} />
      <LinkButton text="新規作成" onClick={() => handleOnClick('create')} />
      {when(listData.length > 0, <SqlQueryTable tableData={listData} />)}
      {when(
        listData.length > 0,
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={(selected) => handlePageChange(selected.selected)}
          activeClassName="active"
          containerClassName="pagination justify-content-center"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
        />
      )}
    </>
  );
};
