/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ImageUpdateFormResponse } from '../domains';
// @ts-ignore
import { ImageUrlCreateFormResponse } from '../domains';
// @ts-ignore
import { ImageUrlCreateOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { ResultOutputResponse } from '../domains';
// @ts-ignore
import { SaveImageRotateFormResponse } from '../domains';
/**
 * ImageApi - axios parameter creator
 * @export
 */
export const ImageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 画像を削除する
         * @summary 画像削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('imageDelete', 'id', id)
            const localVarPath = `/api/image/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 画像を更新する
         * @summary 画像更新
         * @param {ImageUpdateFormResponse} imageUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUpdate: async (imageUpdateFormResponse: ImageUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageUpdateFormResponse' is not null or undefined
            assertParamExists('imageUpdate', 'imageUpdateFormResponse', imageUpdateFormResponse)
            const localVarPath = `/api/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * S3画像アップロード署名付きURLを取得する
         * @summary S3画像アップロード署名付きURL要求API
         * @param {ImageUrlCreateFormResponse} imageUrlCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUrlCreate: async (imageUrlCreateFormResponse: ImageUrlCreateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageUrlCreateFormResponse' is not null or undefined
            assertParamExists('imageUrlCreate', 'imageUrlCreateFormResponse', imageUrlCreateFormResponse)
            const localVarPath = `/api/image-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(imageUrlCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 画像の回転を保存する
         * @summary 画像の回転の保存
         * @param {SaveImageRotateFormResponse} saveImageRotateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveImageRotate: async (saveImageRotateFormResponse: SaveImageRotateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'saveImageRotateFormResponse' is not null or undefined
            assertParamExists('saveImageRotate', 'saveImageRotateFormResponse', saveImageRotateFormResponse)
            const localVarPath = `/api/save-image-rotate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(saveImageRotateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageApi - functional programming interface
 * @export
 */
export const ImageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageApiAxiosParamCreator(configuration)
    return {
        /**
         * 画像を削除する
         * @summary 画像削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 画像を更新する
         * @summary 画像更新
         * @param {ImageUpdateFormResponse} imageUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUpdate(imageUpdateFormResponse: ImageUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUpdate(imageUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * S3画像アップロード署名付きURLを取得する
         * @summary S3画像アップロード署名付きURL要求API
         * @param {ImageUrlCreateFormResponse} imageUrlCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUrlCreate(imageUrlCreateFormResponse: ImageUrlCreateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageUrlCreateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUrlCreate(imageUrlCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 画像の回転を保存する
         * @summary 画像の回転の保存
         * @param {SaveImageRotateFormResponse} saveImageRotateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveImageRotate(saveImageRotateFormResponse: SaveImageRotateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveImageRotate(saveImageRotateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImageApi - factory interface
 * @export
 */
export const ImageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageApiFp(configuration)
    return {
        /**
         * 画像を削除する
         * @summary 画像削除
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageDelete(id: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.imageDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 画像を更新する
         * @summary 画像更新
         * @param {ImageUpdateFormResponse} imageUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUpdate(imageUpdateFormResponse: ImageUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.imageUpdate(imageUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * S3画像アップロード署名付きURLを取得する
         * @summary S3画像アップロード署名付きURL要求API
         * @param {ImageUrlCreateFormResponse} imageUrlCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUrlCreate(imageUrlCreateFormResponse: ImageUrlCreateFormResponse, options?: any): AxiosPromise<ImageUrlCreateOutputResponse> {
            return localVarFp.imageUrlCreate(imageUrlCreateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 画像の回転を保存する
         * @summary 画像の回転の保存
         * @param {SaveImageRotateFormResponse} saveImageRotateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveImageRotate(saveImageRotateFormResponse: SaveImageRotateFormResponse, options?: any): AxiosPromise<ResultOutputResponse> {
            return localVarFp.saveImageRotate(saveImageRotateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageApi - object-oriented interface
 * @export
 * @class ImageApi
 * @extends {BaseAPI}
 */
export class ImageApi extends BaseAPI {
    /**
     * 画像を削除する
     * @summary 画像削除
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public imageDelete(id: number, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).imageDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 画像を更新する
     * @summary 画像更新
     * @param {ImageUpdateFormResponse} imageUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public imageUpdate(imageUpdateFormResponse: ImageUpdateFormResponse, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).imageUpdate(imageUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * S3画像アップロード署名付きURLを取得する
     * @summary S3画像アップロード署名付きURL要求API
     * @param {ImageUrlCreateFormResponse} imageUrlCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public imageUrlCreate(imageUrlCreateFormResponse: ImageUrlCreateFormResponse, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).imageUrlCreate(imageUrlCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 画像の回転を保存する
     * @summary 画像の回転の保存
     * @param {SaveImageRotateFormResponse} saveImageRotateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageApi
     */
    public saveImageRotate(saveImageRotateFormResponse: SaveImageRotateFormResponse, options?: AxiosRequestConfig) {
        return ImageApiFp(this.configuration).saveImageRotate(saveImageRotateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
