/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { QuestionCategoryCreateFormResponse } from '../domains';
/**
 * QuestionCategoryCreateApi - axios parameter creator
 * @export
 */
export const QuestionCategoryCreateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 設問カテゴリ階層を登録する。
         * @summary 設問カテゴリ階層の登録
         * @param {QuestionCategoryCreateFormResponse} questionCategoryCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCategoryCreate: async (questionCategoryCreateFormResponse: QuestionCategoryCreateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'questionCategoryCreateFormResponse' is not null or undefined
            assertParamExists('questionCategoryCreate', 'questionCategoryCreateFormResponse', questionCategoryCreateFormResponse)
            const localVarPath = `/api/question-category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(questionCategoryCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionCategoryCreateApi - functional programming interface
 * @export
 */
export const QuestionCategoryCreateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionCategoryCreateApiAxiosParamCreator(configuration)
    return {
        /**
         * 設問カテゴリ階層を登録する。
         * @summary 設問カテゴリ階層の登録
         * @param {QuestionCategoryCreateFormResponse} questionCategoryCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionCategoryCreate(questionCategoryCreateFormResponse: QuestionCategoryCreateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionCategoryCreate(questionCategoryCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * QuestionCategoryCreateApi - factory interface
 * @export
 */
export const QuestionCategoryCreateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionCategoryCreateApiFp(configuration)
    return {
        /**
         * 設問カテゴリ階層を登録する。
         * @summary 設問カテゴリ階層の登録
         * @param {QuestionCategoryCreateFormResponse} questionCategoryCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionCategoryCreate(questionCategoryCreateFormResponse: QuestionCategoryCreateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.questionCategoryCreate(questionCategoryCreateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionCategoryCreateApi - object-oriented interface
 * @export
 * @class QuestionCategoryCreateApi
 * @extends {BaseAPI}
 */
export class QuestionCategoryCreateApi extends BaseAPI {
    /**
     * 設問カテゴリ階層を登録する。
     * @summary 設問カテゴリ階層の登録
     * @param {QuestionCategoryCreateFormResponse} questionCategoryCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionCategoryCreateApi
     */
    public questionCategoryCreate(questionCategoryCreateFormResponse: QuestionCategoryCreateFormResponse, options?: AxiosRequestConfig) {
        return QuestionCategoryCreateApiFp(this.configuration).questionCategoryCreate(questionCategoryCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
