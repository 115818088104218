import * as yup from 'yup';

export const sqlQuerySchema = yup.object({
  queryName: yup.string().required('入力必須項目です。'),
  sqlString: yup.string().required('入力必須項目です。'),
  parameterList: yup.array().of(
    yup.object().shape({
      parameterDisplayName: yup.string().required('入力必須項目です'),
      parameterName: yup.string().required('入力必須項目です'),
      parameterType: yup.string().required('入力必須項目です'),
    })
  ),
  personalInformationProtectionFlag: yup.boolean(),
  mailaddressList: yup.array().when('personalInformationProtectionFlag', {
    is: false,
    then: yup.array().of(
      yup.object().shape({
        mailaddress: yup
          .string()
          .required('入力するか、削除してください。')
          .matches(/^[a-zA-Z0-9_.+-]+@(j-roi.com|j-fancrew.slack.com|fancrew.co.jp)/, {
            message: '外部へのメール送信は上長の承認が必要です',
          }),
      })
    ),
    otherwise: yup.array().of(
      yup.object().shape({
        mailaddress: yup.string().required('入力するか、削除してください。'),
      })
    ),
  }),
  superiorApprovalUrl: yup.string().when('personalInformationProtectionFlag', {
    is: true,
    then: yup.string().required('入力必須項目です'),
  }),

  executionPlanTime: yup.string().when('executionType', {
    is: '1',
    then: yup.string().matches(/[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}/, { message: '日時を選択してください' }),
  }),

  executionExpectedDate: yup.string().when('executionType', {
    is: '2',
    then: yup.string().matches(/[^]/, { message: '日付を選択してください' }),
  }),

  executionExpectedTime: yup.string().when('executionType', {
    is: '2',
    then: yup.string().matches(/[0-9]{2}:[0-9]{2}/, { message: '時間を選択してください' }),
  }),
});

export const sqlTemplateSchema = yup.object({
  templateName: yup.string().required('入力必須項目です。'),
  sqlString: yup.string().required('入力必須項目です。'),
  parameterList: yup.array().of(
    yup.object().shape({
      parameterDisplayName: yup.string().required('入力必須項目です'),
      parameterName: yup.string().required('入力必須項目です'),
      parameterType: yup.string().required('入力必須項目です'),
    })
  ),
});

export const campaignSchema = yup.object({
  name: yup.string().required('入力必須項目です。'),
  nameKana: yup.string().required('入力必須項目です。'),
  overview: yup.string().required('入力必須項目です。'),
  catchCopy: yup.string().required('入力必須項目です。'),
  showType: yup.string().required('入力必須項目です。'),
  publicStartDate: yup.string().required('入力必須項目です。'),
  shopShowType: yup.string().required('入力必須項目です。'),
});

export const brandSchema = yup.object({
  name: yup.string().required('入力必須項目です。'),
  nameKana: yup.string().required('入力必須項目です。'),
  overview: yup.string().required('入力必須項目です。'),
  catchCopy: yup.string().required('入力必須項目です。'),
  showType: yup.string().required('入力必須項目です。'),
  shopShowType: yup.string().required('入力必須項目です。'),
});
