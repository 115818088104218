import React, { useMemo, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { format, subMonths } from 'date-fns';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState, FormElement } from '../../../interfaces/utils';
import type { PaidServicePointReturnHistoryState } from '../../pages/PaidServicePointReturn/PaidServicePointReturnHistoryPage';

/** 数値入力フォーム */
type NumberFormsState = Pick<PaidServicePointReturnHistoryState['forms'], 'applyId' | 'customerId'>;
/** 日付入力フォーム */
type DateFormsState = Pick<PaidServicePointReturnHistoryState['forms'], 'createAtTo' | 'createAtFrom'>;

export type State = NumberFormsState & DateFormsState;
interface Props {
  setForms: DispatchSetState<State>;
}

export type TestIds = keyof State;

export const PaidServicePointReturnHistoryForms: React.FC<Props> = ({ setForms }) => {
  const testId = createTestId<TestIds>(PaidServicePointReturnHistoryForms);
  const { state: $number, onChangeSet: onChangeNumber } = useLargeState<NumberFormsState>({});
  const numberForms: FormElement<NumberFormsState, { label: string }>[] = useMemo(
    () => [
      {
        label: '応募ID',
        name: 'applyId',
        onChange: onChangeNumber('applyId', Number),
      },
      {
        label: '会員ID',
        name: 'customerId',
        onChange: onChangeNumber('customerId', Number),
      },
    ],
    [onChangeNumber]
  );

  const { state: $date, onChangeSet: onChangeDate } = useLargeState<DateFormsState>({
    createAtFrom: format(subMonths(new Date(), 1), 'yyyy-MM-dd'),
    createAtTo: format(new Date(), 'yyyy-MM-dd'),
  });
  const dateForms: {
    label: string;
    start: FormElement<DateFormsState>;
    end: FormElement<DateFormsState>;
  }[] = useMemo(
    () => [
      {
        label: '登録日',
        start: {
          name: 'createAtFrom',
          onChange: onChangeDate('createAtFrom'),
        },
        end: {
          name: 'createAtTo',
          onChange: onChangeDate('createAtTo'),
        },
      },
    ],
    [onChangeDate]
  );

  useEffect(() => {
    setForms({ ...$number, ...$date });
  }, [setForms, $number, $date]);

  return (
    <>
      <Row>
        {numberForms.map(({ label, name, onChange }) => (
          <Form.Group data-testid={testId(name)} controlId={name} key={name} className="col-md-6">
            <Form.Label>{label}</Form.Label>
            <Form.Control type="number" autoComplete="off" value={$number[name] || ''} onChange={onChange} />
          </Form.Group>
        ))}
      </Row>
      {dateForms.map(({ label, start, end }) => (
        <Row key={label}>
          <Form.Label>{label}</Form.Label>
          <Col>
            <Form.Group data-testid={testId(start.name)} controlId={start.name}>
              <Form.Control
                type="date"
                autoComplete="off"
                value={$date[start.name] || ''}
                onChange={start.onChange}
                max={$date[end.name] || ''}
              />
            </Form.Group>
          </Col>
          <Col xs="1">～</Col>
          <Col>
            <Form.Group data-testid={testId(end.name)} controlId={end.name}>
              <Form.Control
                type="date"
                autoComplete="off"
                value={$date[end.name] || ''}
                onChange={end.onChange}
                min={$date[start.name] || ''}
              />
            </Form.Group>
          </Col>
        </Row>
      ))}
    </>
  );
};
