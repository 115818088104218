import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Modal } from '../../molecules/Modal';
import {
  BILLING_RECREATE_BATCH_URL,
  BILLING_RECREATE_BATCH_URL_BILLINGDESTINATIONID,
  BILLING_RECREATE_BATCH_URL_BILLINGHEADERID,
  BILLING_RECREATE_BATCH_URL_YMD,
} from '../../../BillingConstants';

export interface Props {
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  billingDestinationId: number;
  targetMonth: string;
  billingHeaderId?: number;
}

export const BillingReCreateConfirmingModal: React.VFC<Props> = ({
  isModal,
  setIsModal,
  billingDestinationId,
  targetMonth,
  billingHeaderId,
}) => {
  const history = useHistory();

  // 再作成ボタン押下する
  const handleClickReCreate = () => {
    const url = BILLING_RECREATE_BATCH_URL.replace(BILLING_RECREATE_BATCH_URL_YMD, `${targetMonth}-01`)
      .replace(BILLING_RECREATE_BATCH_URL_BILLINGDESTINATIONID, billingDestinationId.toString())
      .replace(
        BILLING_RECREATE_BATCH_URL_BILLINGHEADERID,
        billingHeaderId === undefined || billingHeaderId === null ? '' : billingHeaderId.toString()
      );
    const req = new XMLHttpRequest();
    req.open('get', url);
    req.send();
    history.go(0);
  };

  const onHide = () => {
    setIsModal(false);
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            <p>
              再作成を実施します。
              請求書に加えた変更は削除されシステムから出力される初期の状態に戻りますがよろしいですか？
            </p>
            <div className="d-flex justify-content-center mt-4 mb-2">
              <Button variant="secondary" className="me-4" onClick={() => handleClickReCreate()}>
                はい
              </Button>
              <Button variant="secondary" className="me-4" onClick={() => onHide()}>
                いいえ
              </Button>
            </div>
          </>
        }
      />
    </>
  );
};
