import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useSafeContext } from '../../hooks/useSafeContext';
import { CorrectionContext } from '../../store/correctionStore';
import { when } from '../../utils/functions';
import { Alert } from '../atoms/Alert';
import { CanCorrectType } from '../pages/Correction/constants';
import { CORRECT_BEFORE_ACTION_STATUS } from '../../Constants';

// TODO: [添削] テスト未実装
export const CorrectStatusDisplay: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const correctControll = $.res_correctReceiptDisplayControl || $.res_correctEnqueteDisplayControl;

  const getCorrectBeforeActionStr = () => {
    if ($.isEnquete && Number.isInteger($.res_correctEnqueteDisplayControl?.correctBeforeActionStatus))
      switch ($.res_correctEnqueteDisplayControl!.correctBeforeActionStatus) {
        case CORRECT_BEFORE_ACTION_STATUS.CUSTOMER_RESUBMIT:
          return 'NG出し後、ユーザーから再提出されました';
        case CORRECT_BEFORE_ACTION_STATUS.ESCALATION_ANSWERED:
          return '事務局からのエスカレの回答がありました';
        case CORRECT_BEFORE_ACTION_STATUS.ESCALATION_RELEASED:
          return 'エスカレ未処理のため、アサイン解除されました';
        default:
          return '';
      }
    if (!$.isEnquete && Number.isInteger($.res_correctReceiptDisplayControl?.correctBeforeActionStatus)) {
      switch ($.res_correctReceiptDisplayControl!.correctBeforeActionStatus) {
        case CORRECT_BEFORE_ACTION_STATUS.CUSTOMER_RESUBMIT:
          return 'NG出し後、ユーザーから再提出されました';
        case CORRECT_BEFORE_ACTION_STATUS.ESCALATION_ANSWERED:
          return '事務局からのエスカレの回答がありました';
        case CORRECT_BEFORE_ACTION_STATUS.ESCALATION_RELEASED:
          return 'エスカレ未処理のため、アサイン解除されました';
        default:
          return '';
      }
    }
  };
  const getCorrectBeforeActionIconColor = () => {
    if ($.isEnquete && Number.isInteger($.res_correctEnqueteDisplayControl?.correctBeforeActionStatus))
      switch ($.res_correctEnqueteDisplayControl!.correctBeforeActionStatus) {
        case CORRECT_BEFORE_ACTION_STATUS.CUSTOMER_RESUBMIT:
          return '#ff0000';
        case CORRECT_BEFORE_ACTION_STATUS.ESCALATION_ANSWERED:
          return '#0000ff';
        case CORRECT_BEFORE_ACTION_STATUS.ESCALATION_RELEASED:
          return '#90ee90';
        default:
          return '';
      }
    if (!$.isEnquete && Number.isInteger($.res_correctReceiptDisplayControl?.correctBeforeActionStatus)) {
      switch ($.res_correctReceiptDisplayControl!.correctBeforeActionStatus) {
        case CORRECT_BEFORE_ACTION_STATUS.CUSTOMER_RESUBMIT:
          return '#ff0000';
        case CORRECT_BEFORE_ACTION_STATUS.ESCALATION_ANSWERED:
          return '#0000ff';
        case CORRECT_BEFORE_ACTION_STATUS.ESCALATION_RELEASED:
          return '#90ee90';
        default:
          return '';
      }
    }
  };
  switch (correctControll?.correctionExec) {
    case CanCorrectType.CORRECTING:
      return (
        <Container style={{ marginBottom: '10px', textAlign: 'center', border: '1px solid' }} className="p-0">
          <>
            <div className="bg-success text-white mark h2 mb-0">
              {$.isEnquete ? 'アンケート' : 'レシート'}添削中です
            </div>
            {getCorrectBeforeActionStr() && (
              <div className="h6 mb-0 bg-white">
                <FontAwesomeIcon icon={faCircle} color={getCorrectBeforeActionIconColor()} />
                {getCorrectBeforeActionStr()}
              </div>
            )}
          </>
        </Container>
      );
    case CanCorrectType.CAN_ASSIGN:
      return (
        <Container className="bg-warning text-white mark h2" style={{ marginBottom: '10px', textAlign: 'center' }}>
          アサイン取得すれば添削可能です
        </Container>
      );
    case CanCorrectType.OTHERS_ASSIGNED:
      return (
        <Container className="bg-danger text-white mark h2" style={{ marginBottom: '10px', textAlign: 'center' }}>
          他の方が添削中です
          <br />
          （添削作業者：{$.res_correctionStatus?.corrector}さん）
        </Container>
      );
    case CanCorrectType.CANT_CORRECT_STATUS:
      return (
        <>
          <Container className="bg-secondary text-white mark" style={{ marginBottom: '10px', textAlign: 'center' }}>
            <p className="h2">ステータスが異なるため添削できません</p>
            <Row className="bg-secondary text-white mark" style={{ textAlign: 'left' }}>
              <Col>応募ステータス：{$.res_correctionStatus?.applyStatus}</Col>
              <Col>調査証明ステータス：{$.res_correctionStatus?.surveyProofStatus}</Col>
              <Col>アンケートステータス：{$.res_correctionStatus?.enqueteStatus}</Col>
            </Row>
          </Container>
          {when(correctControll.canForceCorrect, <Alert>強制添削が可能です</Alert>)}
        </>
      );
    default:
      return <></>;
  }
};
