/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AutoCorrectRuleInfoOutputResponse } from '../domains';
// @ts-ignore
import { AutoCorrectRuleUpdateFormResponse } from '../domains';
// @ts-ignore
import { ClientCorrectSettingInfoOutputResponse } from '../domains';
// @ts-ignore
import { ClientCorrectSettingUpdateFormResponse } from '../domains';
// @ts-ignore
import { ComparisonMethodListOutputResponse } from '../domains';
// @ts-ignore
import { CorrectMethodDisplayOutputResponse } from '../domains';
// @ts-ignore
import { CorrectSettingTargetListFormResponse } from '../domains';
// @ts-ignore
import { CorrectSettingTargetListOutputResponse } from '../domains';
// @ts-ignore
import { DataEntrySettingInfoOutputResponse } from '../domains';
// @ts-ignore
import { DataEntrySettingUpdateFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { MonitorCorrectionSettingInfoOutputResponse } from '../domains';
// @ts-ignore
import { MonitorCorrectionSettingUpdateFormResponse } from '../domains';
/**
 * CorrectSettingApi - axios parameter creator
 * @export
 */
export const CorrectSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 自動添削のルールを取得するAPI
         * @summary 自動添削ルール取得
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoCorrectRuleInfo: async (monitorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorId' is not null or undefined
            assertParamExists('autoCorrectRuleInfo', 'monitorId', monitorId)
            const localVarPath = `/api/correct-setting/each-monitor/{monitor-id}/auto-correct-rule`
                .replace(`{${"monitor-id"}}`, encodeURIComponent(String(monitorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自動添削ルール更新
         * @summary 自動添削ルール更新
         * @param {number} monitorId 
         * @param {AutoCorrectRuleUpdateFormResponse} autoCorrectRuleUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoCorrectRuleUpdate: async (monitorId: number, autoCorrectRuleUpdateFormResponse: AutoCorrectRuleUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorId' is not null or undefined
            assertParamExists('autoCorrectRuleUpdate', 'monitorId', monitorId)
            // verify required parameter 'autoCorrectRuleUpdateFormResponse' is not null or undefined
            assertParamExists('autoCorrectRuleUpdate', 'autoCorrectRuleUpdateFormResponse', autoCorrectRuleUpdateFormResponse)
            const localVarPath = `/api/correct-setting/each-monitor/{monitor-id}/auto-correct-rule`
                .replace(`{${"monitor-id"}}`, encodeURIComponent(String(monitorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(autoCorrectRuleUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント毎添削設定画面表示
         * @summary クライアント毎添削設定画面表示
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCorrectSettingInfo: async (clientId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('clientCorrectSettingInfo', 'clientId', clientId)
            const localVarPath = `/api/correct-setting/each-client/{client-id}`
                .replace(`{${"client-id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クライアント毎添削設定更新
         * @summary クライアント毎添削設定更新
         * @param {ClientCorrectSettingUpdateFormResponse} clientCorrectSettingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCorrectSettingUpdate: async (clientCorrectSettingUpdateFormResponse: ClientCorrectSettingUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientCorrectSettingUpdateFormResponse' is not null or undefined
            assertParamExists('clientCorrectSettingUpdate', 'clientCorrectSettingUpdateFormResponse', clientCorrectSettingUpdateFormResponse)
            const localVarPath = `/api/correct-setting/each-client`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientCorrectSettingUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 比較方法表示を行うAPI
         * @summary 比較方法表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        comparisonMethodList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/correct-setting/comparison-method-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 添削方法リスト取得
         * @summary 添削方法リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctMethodDisplay: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/correct-setting/correct-method-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 添削設定対象検索
         * @summary 添削設定対象検索
         * @param {CorrectSettingTargetListFormResponse} correctSettingTargetListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctSettingTargetList: async (correctSettingTargetListFormResponse: CorrectSettingTargetListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'correctSettingTargetListFormResponse' is not null or undefined
            assertParamExists('correctSettingTargetList', 'correctSettingTargetListFormResponse', correctSettingTargetListFormResponse)
            const localVarPath = `/api/correct-setting/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correctSettingTargetListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力設定情報取得
         * @summary データ入力設定情報取得
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataEntrySettingInfo: async (monitorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorId' is not null or undefined
            assertParamExists('dataEntrySettingInfo', 'monitorId', monitorId)
            const localVarPath = `/api/correct-setting/each-monitor/{monitor-id}/data-entry-setting`
                .replace(`{${"monitor-id"}}`, encodeURIComponent(String(monitorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * データ入力設定更新
         * @summary データ入力設定更新
         * @param {DataEntrySettingUpdateFormResponse} dataEntrySettingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataEntrySettingUpdate: async (dataEntrySettingUpdateFormResponse: DataEntrySettingUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dataEntrySettingUpdateFormResponse' is not null or undefined
            assertParamExists('dataEntrySettingUpdate', 'dataEntrySettingUpdateFormResponse', dataEntrySettingUpdateFormResponse)
            const localVarPath = `/api/correct-setting/each-monitor/{monitor-id}/data-entry-setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dataEntrySettingUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本設定取得
         * @summary モニター添削設定取得API
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorCorrectionSettingInfo: async (monitorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorId' is not null or undefined
            assertParamExists('monitorCorrectionSettingInfo', 'monitorId', monitorId)
            const localVarPath = `/api/correct-setting/each-monitor/{monitor-id}`
                .replace(`{${"monitor-id"}}`, encodeURIComponent(String(monitorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 基本設定更新
         * @summary 添削設定基本情報更新
         * @param {number} monitorId 
         * @param {MonitorCorrectionSettingUpdateFormResponse} monitorCorrectionSettingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorCorrectionSettingUpdate: async (monitorId: number, monitorCorrectionSettingUpdateFormResponse: MonitorCorrectionSettingUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorId' is not null or undefined
            assertParamExists('monitorCorrectionSettingUpdate', 'monitorId', monitorId)
            // verify required parameter 'monitorCorrectionSettingUpdateFormResponse' is not null or undefined
            assertParamExists('monitorCorrectionSettingUpdate', 'monitorCorrectionSettingUpdateFormResponse', monitorCorrectionSettingUpdateFormResponse)
            const localVarPath = `/api/correct-setting/each-monitor/{monitor-id}`
                .replace(`{${"monitor-id"}}`, encodeURIComponent(String(monitorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitorCorrectionSettingUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorrectSettingApi - functional programming interface
 * @export
 */
export const CorrectSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorrectSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * 自動添削のルールを取得するAPI
         * @summary 自動添削ルール取得
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoCorrectRuleInfo(monitorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AutoCorrectRuleInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoCorrectRuleInfo(monitorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自動添削ルール更新
         * @summary 自動添削ルール更新
         * @param {number} monitorId 
         * @param {AutoCorrectRuleUpdateFormResponse} autoCorrectRuleUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autoCorrectRuleUpdate(monitorId: number, autoCorrectRuleUpdateFormResponse: AutoCorrectRuleUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autoCorrectRuleUpdate(monitorId, autoCorrectRuleUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント毎添削設定画面表示
         * @summary クライアント毎添削設定画面表示
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCorrectSettingInfo(clientId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCorrectSettingInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCorrectSettingInfo(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クライアント毎添削設定更新
         * @summary クライアント毎添削設定更新
         * @param {ClientCorrectSettingUpdateFormResponse} clientCorrectSettingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clientCorrectSettingUpdate(clientCorrectSettingUpdateFormResponse: ClientCorrectSettingUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clientCorrectSettingUpdate(clientCorrectSettingUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 比較方法表示を行うAPI
         * @summary 比較方法表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async comparisonMethodList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComparisonMethodListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.comparisonMethodList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 添削方法リスト取得
         * @summary 添削方法リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctMethodDisplay(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CorrectMethodDisplayOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctMethodDisplay(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 添削設定対象検索
         * @summary 添削設定対象検索
         * @param {CorrectSettingTargetListFormResponse} correctSettingTargetListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctSettingTargetList(correctSettingTargetListFormResponse: CorrectSettingTargetListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CorrectSettingTargetListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctSettingTargetList(correctSettingTargetListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力設定情報取得
         * @summary データ入力設定情報取得
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataEntrySettingInfo(monitorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataEntrySettingInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataEntrySettingInfo(monitorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * データ入力設定更新
         * @summary データ入力設定更新
         * @param {DataEntrySettingUpdateFormResponse} dataEntrySettingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dataEntrySettingUpdate(dataEntrySettingUpdateFormResponse: DataEntrySettingUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dataEntrySettingUpdate(dataEntrySettingUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本設定取得
         * @summary モニター添削設定取得API
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorCorrectionSettingInfo(monitorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitorCorrectionSettingInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorCorrectionSettingInfo(monitorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 基本設定更新
         * @summary 添削設定基本情報更新
         * @param {number} monitorId 
         * @param {MonitorCorrectionSettingUpdateFormResponse} monitorCorrectionSettingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorCorrectionSettingUpdate(monitorId: number, monitorCorrectionSettingUpdateFormResponse: MonitorCorrectionSettingUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorCorrectionSettingUpdate(monitorId, monitorCorrectionSettingUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CorrectSettingApi - factory interface
 * @export
 */
export const CorrectSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorrectSettingApiFp(configuration)
    return {
        /**
         * 自動添削のルールを取得するAPI
         * @summary 自動添削ルール取得
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoCorrectRuleInfo(monitorId: number, options?: any): AxiosPromise<AutoCorrectRuleInfoOutputResponse> {
            return localVarFp.autoCorrectRuleInfo(monitorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 自動添削ルール更新
         * @summary 自動添削ルール更新
         * @param {number} monitorId 
         * @param {AutoCorrectRuleUpdateFormResponse} autoCorrectRuleUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autoCorrectRuleUpdate(monitorId: number, autoCorrectRuleUpdateFormResponse: AutoCorrectRuleUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.autoCorrectRuleUpdate(monitorId, autoCorrectRuleUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント毎添削設定画面表示
         * @summary クライアント毎添削設定画面表示
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCorrectSettingInfo(clientId: number, options?: any): AxiosPromise<ClientCorrectSettingInfoOutputResponse> {
            return localVarFp.clientCorrectSettingInfo(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * クライアント毎添削設定更新
         * @summary クライアント毎添削設定更新
         * @param {ClientCorrectSettingUpdateFormResponse} clientCorrectSettingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clientCorrectSettingUpdate(clientCorrectSettingUpdateFormResponse: ClientCorrectSettingUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.clientCorrectSettingUpdate(clientCorrectSettingUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 比較方法表示を行うAPI
         * @summary 比較方法表示
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        comparisonMethodList(options?: any): AxiosPromise<Array<ComparisonMethodListOutputResponse>> {
            return localVarFp.comparisonMethodList(options).then((request) => request(axios, basePath));
        },
        /**
         * 添削方法リスト取得
         * @summary 添削方法リスト取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctMethodDisplay(options?: any): AxiosPromise<CorrectMethodDisplayOutputResponse> {
            return localVarFp.correctMethodDisplay(options).then((request) => request(axios, basePath));
        },
        /**
         * 添削設定対象検索
         * @summary 添削設定対象検索
         * @param {CorrectSettingTargetListFormResponse} correctSettingTargetListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctSettingTargetList(correctSettingTargetListFormResponse: CorrectSettingTargetListFormResponse, options?: any): AxiosPromise<Array<CorrectSettingTargetListOutputResponse>> {
            return localVarFp.correctSettingTargetList(correctSettingTargetListFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力設定情報取得
         * @summary データ入力設定情報取得
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataEntrySettingInfo(monitorId: number, options?: any): AxiosPromise<DataEntrySettingInfoOutputResponse> {
            return localVarFp.dataEntrySettingInfo(monitorId, options).then((request) => request(axios, basePath));
        },
        /**
         * データ入力設定更新
         * @summary データ入力設定更新
         * @param {DataEntrySettingUpdateFormResponse} dataEntrySettingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dataEntrySettingUpdate(dataEntrySettingUpdateFormResponse: DataEntrySettingUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.dataEntrySettingUpdate(dataEntrySettingUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 基本設定取得
         * @summary モニター添削設定取得API
         * @param {number} monitorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorCorrectionSettingInfo(monitorId: number, options?: any): AxiosPromise<MonitorCorrectionSettingInfoOutputResponse> {
            return localVarFp.monitorCorrectionSettingInfo(monitorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 基本設定更新
         * @summary 添削設定基本情報更新
         * @param {number} monitorId 
         * @param {MonitorCorrectionSettingUpdateFormResponse} monitorCorrectionSettingUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorCorrectionSettingUpdate(monitorId: number, monitorCorrectionSettingUpdateFormResponse: MonitorCorrectionSettingUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.monitorCorrectionSettingUpdate(monitorId, monitorCorrectionSettingUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CorrectSettingApi - object-oriented interface
 * @export
 * @class CorrectSettingApi
 * @extends {BaseAPI}
 */
export class CorrectSettingApi extends BaseAPI {
    /**
     * 自動添削のルールを取得するAPI
     * @summary 自動添削ルール取得
     * @param {number} monitorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public autoCorrectRuleInfo(monitorId: number, options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).autoCorrectRuleInfo(monitorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自動添削ルール更新
     * @summary 自動添削ルール更新
     * @param {number} monitorId 
     * @param {AutoCorrectRuleUpdateFormResponse} autoCorrectRuleUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public autoCorrectRuleUpdate(monitorId: number, autoCorrectRuleUpdateFormResponse: AutoCorrectRuleUpdateFormResponse, options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).autoCorrectRuleUpdate(monitorId, autoCorrectRuleUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント毎添削設定画面表示
     * @summary クライアント毎添削設定画面表示
     * @param {number} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public clientCorrectSettingInfo(clientId: number, options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).clientCorrectSettingInfo(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クライアント毎添削設定更新
     * @summary クライアント毎添削設定更新
     * @param {ClientCorrectSettingUpdateFormResponse} clientCorrectSettingUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public clientCorrectSettingUpdate(clientCorrectSettingUpdateFormResponse: ClientCorrectSettingUpdateFormResponse, options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).clientCorrectSettingUpdate(clientCorrectSettingUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 比較方法表示を行うAPI
     * @summary 比較方法表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public comparisonMethodList(options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).comparisonMethodList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 添削方法リスト取得
     * @summary 添削方法リスト取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public correctMethodDisplay(options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).correctMethodDisplay(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 添削設定対象検索
     * @summary 添削設定対象検索
     * @param {CorrectSettingTargetListFormResponse} correctSettingTargetListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public correctSettingTargetList(correctSettingTargetListFormResponse: CorrectSettingTargetListFormResponse, options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).correctSettingTargetList(correctSettingTargetListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力設定情報取得
     * @summary データ入力設定情報取得
     * @param {number} monitorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public dataEntrySettingInfo(monitorId: number, options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).dataEntrySettingInfo(monitorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * データ入力設定更新
     * @summary データ入力設定更新
     * @param {DataEntrySettingUpdateFormResponse} dataEntrySettingUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public dataEntrySettingUpdate(dataEntrySettingUpdateFormResponse: DataEntrySettingUpdateFormResponse, options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).dataEntrySettingUpdate(dataEntrySettingUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本設定取得
     * @summary モニター添削設定取得API
     * @param {number} monitorId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public monitorCorrectionSettingInfo(monitorId: number, options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).monitorCorrectionSettingInfo(monitorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 基本設定更新
     * @summary 添削設定基本情報更新
     * @param {number} monitorId 
     * @param {MonitorCorrectionSettingUpdateFormResponse} monitorCorrectionSettingUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectSettingApi
     */
    public monitorCorrectionSettingUpdate(monitorId: number, monitorCorrectionSettingUpdateFormResponse: MonitorCorrectionSettingUpdateFormResponse, options?: AxiosRequestConfig) {
        return CorrectSettingApiFp(this.configuration).monitorCorrectionSettingUpdate(monitorId, monitorCorrectionSettingUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
