import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Url } from '../../constants/Url';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { TokenObj } from '../../interfaces/TokenObj';
// eslint-disable-next-line import/no-cycle
import { getCurrentJwt, getUser } from '../../services/AuthService';
import { TokenCookies } from '../../services/CookiesService';
import { currentUserState } from '../../states/currentUser';
// eslint-disable-next-line import/no-cycle
import { Layout } from '../templates/Layout';

const cookie = new TokenCookies();

// interface Props extends RouteComponentProps{}

const GuestRoute: React.FC<{ path: string }> = (props) => {
  const setCurrentUser = useSetRecoilState(currentUserState);
  const { isAuthChecking } = useCurrentUser();
  const history = useHistory();
  // JWT情報取得
  const jwtData: TokenObj | boolean = getCurrentJwt();
  useEffect(() => {
    // JWTが設定されていれば
    if (jwtData !== false) {
      // 認証されているかチェックする
      getUser()
        .then((result) => {
          if (typeof result === 'object') {
            setCurrentUser(result);
            // return <Redirect to="/my" />;
            history.push('/my');
          } else {
            setCurrentUser(null);
            cookie.clearTokens();
          }
        })
        .catch(() => {
          setCurrentUser(null);
          cookie.clearTokens();
          history.push(Url.COMMON_ERROR);
        });
    } else {
      setCurrentUser(null);
    }
  }, [history, jwtData, setCurrentUser]);
  // 既に認証されていれば、表示したいページにリダイレクト
  return (
    <>
      {!isAuthChecking ? (
        <Layout>
          {/* <AppInit/> */}
          <Route {...props} />
        </Layout>
      ) : null}
    </>
  );
};

export default GuestRoute;
