import React, { useEffect, useMemo } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import type { ClientNgListFormResponse } from '../../../api-client';
import { DispatchSetState } from '../../../interfaces/utils';
import type { State as PageState } from '../../pages/ClientNg/ClientNgPage';

export const initialForms: State = {
  searchType: 'clientId',
  searchValue: '',
};
export type State = PageState['forms'];

export const ClientNgPageForms: React.FC<{ setForms: DispatchSetState<State> }> = ({ setForms }) => {
  const testid = createTestId(ClientNgPageForms);
  const { state: $, onChangeSet } = useLargeState<State>(initialForms);
  const options: [string, keyof ClientNgListFormResponse][] = useMemo(
    () =>
      Object.entries({
        クライアントID: 'clientId',
        クライアント名: 'clientName',
        会員ID: 'customerId',
      }),
    []
  );
  useEffect(() => setForms($), [setForms, $]);
  return (
    <>
      <Col>
        <Form.Group data-testid={testid('searchType')}>
          <Form.Select value={$.searchType} onChange={onChangeSet('searchType')}>
            {options.map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group data-testid={testid('searchValue')}>
          <Form.Control
            type={$.searchType === 'clientName' ? 'text' : 'number'}
            autoComplete="off"
            value={$.searchValue ?? ''}
            onChange={onChangeSet('searchValue')}
          />
        </Form.Group>
      </Col>
    </>
  );
};
