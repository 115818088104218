import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { SqlQueryApi, SqlQueryInfoOutputResponse, SqlQueryListOutputResponse } from '../../../api-client';
import { SqlQueryInfoTable } from '../../organisms/Table/SqlQueryInfoTable';
import { PrimaryButton } from '../../atoms/Button/PrimaryButton';
import { Url } from '../../../constants/Url';

export const SqlQueryDeleteConfirmPage = () => {
  const [tableData, setTableData] = useState<SqlQueryInfoOutputResponse>();
  const history = useHistory();
  const state = history.location.state as any;
  const listData = state.listData as SqlQueryListOutputResponse;

  const sqlQueryApi = new SqlQueryApi();
  useEffect(() => {
    sqlQueryApi.sqlQueryInfo(listData.queryId).then((response) => {
      setTableData(response.data);
      // setTableData(mock);
    });
  }, []);

  const clickDelete = () => {
    sqlQueryApi.sqlQueryDelete(listData.queryId).then((response) => {
      history.push(Url.KEISAI.SQL_QUERY_COMPLETION);
    });
  };

  return (
    <>
      {tableData && <SqlQueryInfoTable tableTitle="SQLクエリ削除確認" tableData={tableData} />}
      <p>ボタンを押すと削除実行します。</p>
      <PrimaryButton onClick={clickDelete}>削除する</PrimaryButton>
    </>
  );
};
