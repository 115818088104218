import { useCurrentUser } from '../../../hooks/useCurrentUser';
// eslint-disable-next-line import/no-cycle
import { getCurrentJwt, getUser } from '../../../services/AuthService';
import { debugLog } from '../../../utils/functions';

// FIXME: [添削] バックエンドのデバッグのため一時的に実装中
/** バックエンドのデバッグ用に認証情報を表示 */
export const debugLogCurrentUserDataForBackend = (currentUserResult: ReturnType<typeof useCurrentUser>): void =>
  debugLog('%cログインアカウント', 'background-color: yellow', {
    ユーザ名: currentUserResult.currentUser?.name,
    ユーザID: currentUserResult.currentUser?.id,
    メールアドレス: currentUserResult.currentUser?.email,
    ログイン済か: currentUserResult.currentUser?.auth,
    ログイン情報を取得中か: currentUserResult.isAuthChecking,
    トークン情報: (() => {
      try {
        return getCurrentJwt();
      } catch {
        return '取得に失敗';
      }
    })(),
    ユーザー情報を再フェッチする関数を取得: async () => console.log('結果:', await getUser()),
  });
