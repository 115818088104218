import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosResponse } from 'axios';
// eslint-disable-next-line import/no-cycle
import { AnnounceCreateUpdateApi } from '../../../api-client';
import type {
  IncResultOutputResponse,
  AnnounceCreateUpdateFormResponse,
  AnnounceListOutputListItemResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { useLargeState } from '../../../hooks/useLargeState';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { Alert } from '../../atoms/Alert';
import { STATUS } from '../../organisms/Table/AnnounceTable';

const PAGE_TYPE = {
  MODIFY: 1,
  CONFIRM: 2,
} as const;

type PageType = typeof PAGE_TYPE[keyof typeof PAGE_TYPE];

export const AnnounceModifyPage: React.VFC = () => {
  const history = useHistory();
  const location = useLocation<AnnounceListOutputListItemResponse>();

  const { state: $, mergeState } = useLargeState<{
    api: AnnounceCreateUpdateApi;
    pageType: PageType;
    updResult: IncResultOutputResponse;
    errorMessage: string;
  }>({
    api: new AnnounceCreateUpdateApi(),
    pageType: PAGE_TYPE.MODIFY,
    updResult: { result: false },
    errorMessage: '',
  });

  const {
    state: f,
    onChangeSet: fOnChangeSet,
    setState: fSetState,
  } = useLargeState<AnnounceListOutputListItemResponse>({
    id: 0,
    content: '',
    endAt: '',
    startAt: '',
    status: 0,
    title: '',
    userIds: '',
  });

  useEffect(() => {
    if (!location.state) return;
    const { startAt, endAt, ...rest } = location.state;
    fSetState({ ...rest, startAt: startAt.replace(/ /, 'T'), endAt: endAt.replace(/ /, 'T') });
  }, [location.state, fSetState]);

  const onConfirm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (![f.content, f.endAt, f.startAt, f.title].every(Boolean)) {
      mergeState({ errorMessage: '入力されていない項目があります。' });
      return;
    }
    window.history.pushState(null, '', Url.KEISAI.ANNOUNCE_MODIFY_CONFIRM);
    mergeState({ pageType: PAGE_TYPE.CONFIRM, errorMessage: '' });
  };

  const createUpdateBody = (form: AnnounceListOutputListItemResponse) => {
    let startAt = form.startAt.toString();
    if (startAt.length === 16) {
      startAt += ':00';
    }
    let endAt = form.endAt.toString();
    if (endAt.length === 16) {
      endAt += ':00';
    }
    return {
      id: form.id ? form.id : undefined,
      content: form.content,
      endAt: endAt,
      startAt: startAt,
      status: form.status,
      title: form.title,
      userIds: form.userIds ? form.userIds.split(',') : undefined,
    } as AnnounceCreateUpdateFormResponse;
  };

  const onUpdate = () => {
    const body: AnnounceCreateUpdateFormResponse = createUpdateBody(f);
    $.api
      .announceCreateUpdate(body)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      })
      .catch((error: IncResultOutputResponse) => {
        mergeState({ updResult: error });
      });
  };

  return (
    <>
      <Title className="mb-4">
        {$.pageType === PAGE_TYPE.MODIFY ? TITLE.KEISAI.ANNOUNCE_MODIFY : TITLE.KEISAI.ANNOUNCE_MODIFY_CONFIRM}
      </Title>
      {$.updResult?.result && <Alert variant="success">確定しました。</Alert>}
      {$.updResult?.errorCode && $.updResult?.errorMessage && (
        <Alert variant="danger">{`${$.updResult.errorMessage} (エラーコード：${$.updResult.errorCode})`}</Alert>
      )}
      {$.errorMessage.length > 0 && <Alert variant="danger">{`${$.errorMessage}`}</Alert>}
      <Form onSubmit={onConfirm}>
        <div className="d-flex justify-content-end mb-4">
          {$.pageType === PAGE_TYPE.MODIFY ? (
            <>
              <Button variant="link" className="ms-2" onClick={() => history.goBack()}>
                キャンセル
              </Button>
              <Button className="ms-2" type="submit">
                確認画面へ
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="link"
                className="ms-2"
                onClick={() => {
                  mergeState({ pageType: PAGE_TYPE.MODIFY, updResult: { result: false } });
                  history.goBack();
                }}
              >
                キャンセル
              </Button>
              <Button onClick={onUpdate} className="ms-2">
                確定
              </Button>
            </>
          )}
        </div>

        <fieldset disabled={$.pageType === PAGE_TYPE.CONFIRM}>
          <Form.Group>
            <Form.Label>表示期間</Form.Label>
            <div className="d-flex align-items-center gap-2 mb-4">
              <Form.Control
                required
                type="datetime-local"
                step="1"
                className="w-auto"
                value={f.startAt || ''}
                onChange={fOnChangeSet('startAt')}
              />
              {' ～ '}
              <Form.Control
                required
                type="datetime-local"
                step="1"
                className="w-auto"
                value={f.endAt || ''}
                onChange={fOnChangeSet('endAt')}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>タイトル</Form.Label>
            <div className="d-flex">
              <Form.Control
                type="text"
                value={f.startAt?.substring(0, f.startAt.indexOf('T')) || ''}
                className="w-auto"
                readOnly
              />
              <Form.Control required type="text" value={f.title || ''} onChange={fOnChangeSet('title')} />
            </div>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>状態</Form.Label>
            <Form.Select required value={f.status ?? ''} onChange={fOnChangeSet('status', Number)}>
              <option hidden>&nbsp;</option>
              <option value={STATUS.DISPLAY}>表示</option>
              <option value={STATUS.HIDE}>非表示</option>
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>
              本文 <span className="text-secondary">※htmlタグ利用可</span>
            </Form.Label>
            <Form.Control
              required
              value={f.content || ''}
              onChange={fOnChangeSet('content')}
              type="text"
              as="textarea"
              rows={6}
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>ユーザーID</Form.Label>
            <Form.Control
              type="text"
              value={f.userIds}
              onChange={fOnChangeSet('userIds')}
              placeholder="複数の場合はカンマ区切りで入力"
            />
          </Form.Group>
        </fieldset>
      </Form>
    </>
  );
};
