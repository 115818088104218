/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 契約店舗別明細
 * @export
 * @interface BillingInvoiceTotalingDetailItemShopDetailOutputResponse
 */
export interface BillingInvoiceTotalingDetailItemShopDetailOutputResponse {
    /**
     * 金額
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'amount': number;
    /**
     * 店舗明細ID
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'billingContractShopDetailId': number;
    /**
     * 請求(0)/非請求(1)/翌月繰越(2)
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'billingStatus': number;
    /**
     * 利用商品ID
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'billingUseItemId': number;
    /**
     * 商品名
     * @type {string}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'billingUseItemName': string;
    /**
     * 削除フラグ。true:論理削除
     * @type {boolean}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'disableFlg': boolean;
    /**
     * 商品並び順
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'itemSortOrder': number;
    /**
     * モニター月
     * @type {string}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'monitorMonth'?: string;
    /**
     * どの単位に発生する商品か
     * @type {string}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'relation': BillingInvoiceTotalingDetailItemShopDetailOutputResponseRelationEnum;
    /**
     * 税区分
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemShopDetailOutputResponse
     */
    'taxRate': number;
}

export const BillingInvoiceTotalingDetailItemShopDetailOutputResponseRelationEnum = {
    Contract: 'Contract',
    ContractShop: 'ContractShop',
    Monitor: 'Monitor',
    Apply: 'Apply'
} as const;

export type BillingInvoiceTotalingDetailItemShopDetailOutputResponseRelationEnum = typeof BillingInvoiceTotalingDetailItemShopDetailOutputResponseRelationEnum[keyof typeof BillingInvoiceTotalingDetailItemShopDetailOutputResponseRelationEnum];


