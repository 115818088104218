import React, { useCallback, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { createTestId, formatISODate, uuid } from '../../../utils/functions';
import type { ExtendDeadlineRegisterState } from '../../pages/ExtendDeadline/ExtendDeadlineRegisterPage';

interface Props {
  list: ExtendDeadlineRegisterState['list'];
  reason: string;
}
export type TestIds = 'table';

export const ExtendDeadlineRegisterTable: React.FC<Props> = React.memo(({ list, reason }) => {
  const testId = createTestId<TestIds>(ExtendDeadlineRegisterTable);
  const tableData: {
    label: string;
    name: keyof ExtendDeadlineRegisterState['list'][0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parser?: (x?: any) => any;
  }[] = useMemo(() => {
    return [
      { label: '注意点', name: 'attentionPoint', parser: String },
      { label: '応募ID', name: 'applyId', parser: Number },
      { label: '店舗ID', name: 'shopId', parser: Number },
      { label: '店舗名', name: 'shopName', parser: String },
      { label: 'ユーザID', name: 'customerId', parser: Number },
      { label: '延長前期限/延長後期限', name: 'beforeDeadline', parser: String },
      { label: 'カウントダウン日付', name: 'countdown', parser: String },
      { label: '当選ステータス', name: 'applyStatusName', parser: String },
    ];
  }, []);

  const createDisplayName = useCallback(
    (
      item: ExtendDeadlineRegisterState['list'][0],
      labelName: keyof ExtendDeadlineRegisterState['list'][0],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      parser?: (x?: any) => any
    ) => {
      const objLabel = ['attentionPoint', 'beforeDeadline', 'countdown'];
      if (!objLabel.includes(labelName)) {
        return parser ? parser(item[labelName]) : item[labelName];
      }

      if (labelName === 'attentionPoint') {
        if (item.attentionPoint.length > 0) {
          return item.attentionPoint.length === 1
            ? item.attentionPoint[0]
            : item.attentionPoint.map((attentionPoint, i) => {
                return (
                  <span key={uuid()}>
                    {i === 0 ? <></> : <br />}
                    {attentionPoint}
                  </span>
                );
              });
        }
      }

      if (labelName === 'countdown') {
        return item.countdown ? formatISODate(item.countdown, DATETIME_DISPLAY_FORMAT) : '';
      }

      if (labelName === 'beforeDeadline') {
        return (
          <>
            {item.beforeDeadline ? formatISODate(item.beforeDeadline, DATETIME_DISPLAY_FORMAT) : ''}/
            <br />
            {item.afterDeadline ? formatISODate(item.afterDeadline, DATETIME_DISPLAY_FORMAT) : ''}
          </>
        );
      }

      return '';
    },
    []
  );

  return (
    <Table data-testid={testId('table')}>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label), '理由'].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <tr className="align-middle" key={item.applyId}>
            {tableData.map(({ name, parser }) => (
              <td key={name}>{createDisplayName(item, name, parser)}</td>
            ))}
            <td key={reason}>{reason}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
});
