import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Modal } from '../../molecules/Modal';
import { downloadInvoiceExcel } from '../../../services/billing/BillingInvoiceDownloadExcelService';
import { downloadInvoicePdf } from '../../../services/billing/BillingInvoiceDownloadPdfService';
import { BillingApi } from '../../../api-client';
import { BillingContext } from '../../context/BillingContext';
import { BILLING_FILE_EXTENSION, BILLING_FLOW_STATUS, BILLING_INVOICE_PDF_TYPE } from '../../../BillingConstants';
import { EXTENSION_EXCEL, EXTENSION_PDF } from '../../../Constants';
import { fileDownLoad, getDownLoadFileName } from '../../../services/PdfDownloadService';
import { Alert } from '../../atoms/Alert';

export interface Props {
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  clientName: string;
  billingMonth: string;
  billingDestinationName?: string;
}

export const BillingInvoiceDownloadModal: React.VFC<Props> = ({
  isModal,
  setIsModal,
  clientName,
  billingMonth,
  billingDestinationName,
}) => {
  const billingApi = new BillingApi();
  const billingContext = useContext(BillingContext);
  const billingId = billingContext!.billingId!;
  const billingFlowStatus = billingContext!.billingFlowStatus!;

  const onHide = () => {
    setIsModal(false);
  };
  const [invoiceType, setInvoiceType] = useState([
    BILLING_INVOICE_PDF_TYPE.Item,
    BILLING_INVOICE_PDF_TYPE.Shop,
    BILLING_INVOICE_PDF_TYPE.Monitor,
    BILLING_INVOICE_PDF_TYPE.Apply,
  ]);
  const [invoiceFormat, setInvoiceFormat] = useState(['pdf']);
  const [message, setMessage] = useState<string>();

  // DL対象の帳票種別チェックボックス
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInvoiceFormat([e.target.value]);
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (invoiceType.includes(e.target.value)) {
      setInvoiceType((prevState) => prevState.filter((item) => item !== e.target.value));
    } else {
      setInvoiceType([...invoiceType, e.target.value]);
    }
  };

  const onDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (invoiceFormat.includes('pdf')) {
      if (invoiceType.length === 0) {
        setMessage('ダウンロードする帳票の種別を選択してください。');
        return false;
      }
      setMessage(undefined);
    } else if (invoiceFormat.includes('excel')) {
      if (
        invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Shop) ||
        invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Monitor) ||
        invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Apply)
      ) {
        setMessage(undefined);
      } else {
        setMessage('ダウンロードする帳票の種別を選択してください。');
        return false;
      }
    }

    if (invoiceFormat.includes('excel')) {
      if (billingFlowStatus >= BILLING_FLOW_STATUS.UNPUBLISHED) {
        billingApi
          .billingFileDownload({
            monitor: invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Monitor),
            apply: invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Apply),
            shop: invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Shop),
            item: invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Item),
            billingHeaderId: billingId,
            extension: BILLING_FILE_EXTENSION.XLSX,
          })
          .then((res) => {
            const bytes = Buffer.from(res.data.data, 'base64');
            fileDownLoad(
              new Blob([bytes], { type: 'application/octet-binary' }),
              getDownLoadFileName(invoiceType, clientName, billingMonth, EXTENSION_EXCEL, billingDestinationName)
            );
          });
      } else {
        downloadInvoiceExcel(billingId, invoiceType).then((b: Buffer) => {
          fileDownLoad(
            new Blob([b], { type: 'application/octet-binary' }),
            getDownLoadFileName(invoiceType, clientName, billingMonth, EXTENSION_EXCEL, billingDestinationName)
          );
        });
      }
    } else if (invoiceFormat.includes('pdf')) {
      if (billingFlowStatus >= BILLING_FLOW_STATUS.UNPUBLISHED) {
        billingApi
          .billingFileDownload({
            monitor: invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Monitor),
            apply: invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Apply),
            shop: invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Shop),
            item: invoiceType.includes(BILLING_INVOICE_PDF_TYPE.Item),
            billingHeaderId: billingId,
            extension: BILLING_FILE_EXTENSION.PDF,
          })
          .then((res) => {
            const bytes = Buffer.from(res.data.data, 'base64');
            fileDownLoad(
              new Blob([bytes], { type: 'application/pdf' }),
              getDownLoadFileName(invoiceType, clientName, billingMonth, EXTENSION_PDF, billingDestinationName)
            );
          });
      } else {
        const pdfBlob = downloadInvoicePdf(billingId, invoiceType);
        pdfBlob.then((b: Blob) => {
          fileDownLoad(
            b,
            getDownLoadFileName(invoiceType, clientName, billingMonth, EXTENSION_PDF, billingDestinationName)
          );
        });
      }
    }
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isModal}
        size="lg"
        closeButton
        centered
        scrollable
        title="ダウンロードモーダル"
        body={
          <>
            {message && <Alert variant="danger">{message}</Alert>}
            <Form>
              <p className="mb-2">ファイル形式：</p>
              <Form.Group className="mb-4" controlId="">
                <Container>
                  <Row>
                    <Col>
                      <Form.Check.Input
                        data-testid="format-pdf"
                        id="format-pdf"
                        className="flex-shrink-0 me-2"
                        type="radio"
                        checked={Boolean(invoiceFormat.find((v) => v === 'pdf'))}
                        name="invoice-format"
                        value="pdf"
                        onChange={handleRadioChange}
                      />
                      <Form.Check.Label htmlFor="format-pdf">PDF</Form.Check.Label>
                    </Col>
                    <Col>
                      <Form.Check.Input
                        data-testid="format-excel"
                        id="format-excel"
                        className="flex-shrink-0 me-2"
                        type="radio"
                        checked={Boolean(invoiceFormat.find((v) => v === 'excel'))}
                        name="invoice-format"
                        value="excel"
                        onChange={handleRadioChange}
                      />
                      <Form.Check.Label htmlFor="format-excel">Excel</Form.Check.Label>
                    </Col>
                  </Row>
                </Container>
              </Form.Group>
              <p className="mb-2">帳票種別：</p>
              <Form.Group className="mb-4" controlId="">
                <Container>
                  <Row>
                    <Col>
                      <Form.Check id="product-panel">
                        <Form.Check.Input
                          data-testid="product-check"
                          id="product"
                          className="flex-shrink-0 me-2"
                          type="checkbox"
                          checked={Boolean(invoiceType.find((v) => v === BILLING_INVOICE_PDF_TYPE.Item))}
                          name="invoice-type"
                          value={BILLING_INVOICE_PDF_TYPE.Item}
                          onChange={handleCheckboxChange}
                          disabled={invoiceFormat.includes('excel')}
                        />
                        <Form.Check.Label htmlFor="product">請求書</Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col>
                      <Form.Check id="store-panel" className="d-flex">
                        <Form.Check.Input
                          data-testid="store-check"
                          id="store"
                          checked={Boolean(invoiceType.find((v) => v === BILLING_INVOICE_PDF_TYPE.Shop))}
                          className="flex-shrink-0 me-2"
                          type="checkbox"
                          name="invoice-type"
                          value={BILLING_INVOICE_PDF_TYPE.Shop}
                          onChange={handleCheckboxChange}
                        />
                        <Form.Check.Label htmlFor="store">店舗別明細</Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Check id="project-panel" className="d-flex">
                        <Form.Check.Input
                          data-testid="project-check"
                          id="project"
                          className="flex-shrink-0 me-2"
                          type="checkbox"
                          checked={Boolean(invoiceType.find((v) => v === BILLING_INVOICE_PDF_TYPE.Monitor))}
                          name="invoice-type"
                          value={BILLING_INVOICE_PDF_TYPE.Monitor}
                          onChange={handleCheckboxChange}
                        />
                        <Form.Check.Label htmlFor="project">案件別明細</Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col>
                      <Form.Check id="dispatch-panel" className="d-flex">
                        <Form.Check.Input
                          data-testid="dispatch-check"
                          id="dispatch"
                          className="flex-shrink-0 me-2"
                          type="checkbox"
                          checked={Boolean(invoiceType.find((v) => v === BILLING_INVOICE_PDF_TYPE.Apply))}
                          name="invoice-type"
                          value={BILLING_INVOICE_PDF_TYPE.Apply}
                          onChange={handleCheckboxChange}
                        />
                        <Form.Check.Label htmlFor="dispatch">派遣別明細</Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                </Container>
              </Form.Group>
            </Form>
          </>
        }
        footer={
          <Container>
            <Row>
              <Col>
                <Button data-testid="download-btn" variant="secondary" onClick={onDownload}>
                  ダウンロード
                </Button>
              </Col>
              <Col>
                <Button variant="secondary" onClick={onHide}>
                  キャンセル
                </Button>
              </Col>
            </Row>
          </Container>
        }
      />
    </>
  );
};
