import React from 'react';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { DispatchSetState } from '../../../interfaces/utils';
import { createTestId, formatISODate } from '../../../utils/functions';
import { Modal } from '../../molecules/Modal';
import type { MailHistoryListOemState } from '../../pages/MailHistory/MailHistoryListRoiPage';
import type { MailHistoryListTableState } from '../Table/MailHistoryListTable';

type Props = {
  item: MailHistoryListOemState['list'][0];
  isModal: MailHistoryListTableState['isModal'];
  setModal: DispatchSetState<MailHistoryListTableState['isModal']>;
};
export type TestIds = 'header' | 'subject' | 'text';

export const MailHistoryModal: React.FC<Props> = ({ item, setModal, isModal }) => {
  const testId = createTestId<TestIds>(MailHistoryModal);
  const hideModal = () => setModal(false);
  return (
    <Modal
      closeButton
      centered
      isModal={isModal}
      onHide={hideModal}
      size="lg"
      body={
        <>
          <p data-testid={testId('header')}>
            {item.sendAt ? formatISODate(item.sendAt, DATETIME_DISPLAY_FORMAT) : ''}&emsp;ID {item.id}&emsp;
            {item.mailAddress}
          </p>
          <p data-testid={testId('subject')}>{item.subject}</p>
          <div className="border border-secondary">
            <pre className="m-2" data-testid={testId('text')}>
              {item.text}
            </pre>
          </div>
        </>
      }
    />
  );
};
