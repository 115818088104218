import * as React from 'react';
import { Table } from 'react-bootstrap';

import { Textarea } from '../../atoms/Textarea';
import { DateAndTime } from '../../molecules/DateAndTime';
import { AboutOpeningHourSetInfoOutputResponse } from '../../../api-client';

export interface Props {
  data: AboutOpeningHourSetInfoOutputResponse;
  handleChangeTextarea: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDatepickerStart: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeTimepickerStart: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeDatepickerEnd: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeTimepickerEnd: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const AboutOpeningTable: React.VFC<Props> = ({
  data,
  handleChangeTextarea,
  handleChangeDatepickerStart,
  handleChangeTimepickerStart,
  handleChangeDatepickerEnd,
  handleChangeTimepickerEnd,
}) => {
  return (
    <Table>
      <tbody>
        <tr>
          <th className="w-25">Content Html</th>
          <td>
            <Textarea value={data.contentHtml} onChange={handleChangeTextarea} />
          </td>
        </tr>
        <tr>
          <th>
            有効期間
            <br />
            （両方が指定されていないと、有効になりません。）
          </th>
          <td>
            開始日時：
            <div className="d-inline">
              <DateAndTime
                timestamp={data.startTimestamp}
                handleChangeDatepicker={handleChangeDatepickerStart}
                handleChangeTimepicker={handleChangeTimepickerStart}
              />
            </div>
            <br />
            終了日時：
            <div className="d-inline">
              <DateAndTime
                timestamp={data.endTimestamp}
                handleChangeDatepicker={handleChangeDatepickerEnd}
                handleChangeTimepicker={handleChangeTimepickerEnd}
              />
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
