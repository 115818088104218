import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
// eslint-disable-next-line import/no-cycle
import {
  ContractShopUpdateApi,
  ContractShopUpdateFormResponse,
  PostingRequestInfoListApi,
  PostingRequestInfoListOutputResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Button } from '../../atoms/Button';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { PostingRequestListTable } from '../../organisms/Table/PostingRequestListTable';
import { ShopListModal } from '../../organisms/Modal/ShopListModal';
import { ContractShop, Index } from './PostingReservationPage';

const getSampleData = (num: number): PostingRequestInfoListOutputResponse[] => {
  return [...Array(num)].map((_, i) => ({
    applicationFormId: i + 1,
    postingRequestId: i + 1,
    clientId: i + 1,
    clientName: `クライアント名${i + 1}`,
    applicationFormType: '新規',
    postingRequestDate: '2022/02/03',
    postingRequestStatus: '依頼中',
    applicationFormStatus: '承認',
    contractStartDate: '2022/02/05',
    contractShops: [...Array(3)].map((__, ci) => ({
      id: ci + 1,
      contractShopName: `契約店舗名${ci + 1}`,
      monitorBases: [...Array(3)].map((___, mi) => ({
        id: mi + 1,
        name: `モニター名${mi + 1}`,
      })),
    })),
  }));
};

export interface Index2 {
  client?: number;
  shop?: number;
  monitor?: number;
}

export interface State {
  api: PostingRequestInfoListApi;
  clientIdOrName: string | undefined;
  fromDate: string | undefined;
  toDate: string | undefined;
  postingRequestStatus: number | undefined;
  applicationFormStatus: number | undefined;
  list: PostingRequestInfoListOutputResponse[];
  listPerPage: PostingRequestInfoListOutputResponse[];
  currentPage: number;
  totalPage: number;
  isShopListModal: boolean;
  index: Index2;
  updataApi: ContractShopUpdateApi;
}

const POSTING_REQUEST_STATUS = ['依頼前', '依頼中'] as const;
const APPLICATION_FORM_STATUS = ['承認依頼', '承認', '差戻', '削除'] as const;
const MAX_RECORD_PER_PAGE = 100;

export const PostingRequestListPage: React.VFC = () => {
  const testid = createTestId(PostingRequestListPage);

  const initialState = {
    api: new PostingRequestInfoListApi(),
    clientIdOrName: undefined,
    fromDate: undefined,
    toDate: undefined,
    postingRequestStatus: undefined,
    applicationFormStatus: undefined,
    list: [],
    listPerPage: [],
    currentPage: 1,
    totalPage: 1,
    isShopListModal: false,
    index: {},
    updataApi: new ContractShopUpdateApi(),
  };

  const { state: $, mergeState, useBindSet } = useLargeState<State>(initialState);

  useEffect(() => {
    $.api.postingRequestInfoList().then((res: AxiosResponse<PostingRequestInfoListOutputResponse[]>) => {
      setPagingStates(res.data);
      // setPagingStates(getSampleData(3));
    });
  }, [$.api]);

  const onSearch = async () => {
    await $.api
      .postingRequestInfoList($.clientIdOrName, $.fromDate, $.toDate, $.postingRequestStatus, $.applicationFormStatus)
      .then((res: AxiosResponse<PostingRequestInfoListOutputResponse[]>) => {
        setPagingStates(res.data);
        // setPagingStates(getSampleData(1));
      });
  };

  const setPagingStates = async (list: PostingRequestInfoListOutputResponse[]) => {
    const modifyList = await list.sort((a, b) => b.postingRequestId - a.postingRequestId);
    await mergeState({
      list: modifyList,
      listPerPage: modifyList.slice(0, MAX_RECORD_PER_PAGE),
      totalPage: Math.ceil(Number(modifyList?.length) / MAX_RECORD_PER_PAGE),
      currentPage: 1,
    });
  };

  const saveShopListModal = (id: number, shopName: string, url: string) => {
    if (
      $.index.client !== undefined &&
      $.index.client !== null &&
      $.index.shop !== undefined &&
      $.index.shop !== null
    ) {
      const form: ContractShopUpdateFormResponse = {
        id: $.listPerPage[$.index.client].contractShops[$.index.shop].id,
        shopId: id,
      };
      $.updataApi.contractShopUpdate(form).then(() => {
        const newContractShops = $.listPerPage[$.index.client!].contractShops.map((s, i) =>
          i === $.index.shop ? { ...s, shopId: id, shopName, url } : s
        );
        const newList = $.listPerPage.map((s, i) =>
          i === $.index.client ? { ...s, contractShops: newContractShops } : s
        );

        mergeState({
          listPerPage: newList,
          isShopListModal: false,
          index: {},
        });
      });
    }
  };

  const onHideShopListModal = () => mergeState({ index: {}, isShopListModal: false });

  const mergeShopParam = (id: number, shopName: string, url: string) => {
    const newContractShops = $.listPerPage[$.index.client!].contractShops.map((s, i) =>
      i === $.index.shop ? { ...s, shopId: id, shopName, url } : s
    );
    const newList = $.listPerPage.map((s, i) => (i === $.index.client ? { ...s, contractShops: newContractShops } : s));
    mergeState({
      listPerPage: newList,
    });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.POSTING_REQUEST_LIST}</Title>

      <Form.Group className="mb-4">
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="クライアント名/ID" data-testid={testid('clientIdOrName')}>
              <Form.Control
                type="text"
                placeholder="クライアント名/ID"
                value={$.clientIdOrName || ''}
                onChange={(e) => mergeState({ clientIdOrName: e.target.value || undefined })}
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="依頼日From" data-testid={testid('fromDate')}>
              <Form.Control
                type="date"
                placeholder="依頼日From"
                value={$.fromDate ? $.fromDate.replace(/\//g, '-') : ''}
                onChange={(e) =>
                  mergeState({ fromDate: e.target.value ? e.target.value.replace(/-/g, '/') : undefined })
                }
              />
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="依頼日To" data-testid={testid('toDate')}>
              <Form.Control
                type="date"
                placeholder="依頼日To"
                value={$.toDate ? $.toDate.replace(/\//g, '-') : ''}
                onChange={(e) => mergeState({ toDate: e.target.value ? e.target.value.replace(/-/g, '/') : undefined })}
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <FloatingLabel label="掲載状況" data-testid={testid('postingRequestStatus')}>
              <Form.Select
                value={$.postingRequestStatus}
                onChange={(e) =>
                  mergeState({
                    postingRequestStatus: e.target.value !== '' ? Number(e.target.value) : undefined,
                  })
                }
              >
                <option value="">&nbsp;</option>
                {POSTING_REQUEST_STATUS.map((v, i) => (
                  <option key={v} value={i}>
                    {v}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
          <Col>
            <FloatingLabel label="承認状況" data-testid={testid('applicationFormStatus')}>
              <Form.Select
                value={$.applicationFormStatus}
                onChange={(e) =>
                  mergeState({
                    applicationFormStatus: e.target.value !== '' ? Number(e.target.value) : undefined,
                  })
                }
              >
                <option value="">&nbsp;</option>
                {APPLICATION_FORM_STATUS.map((v, i) => (
                  <option key={v} value={i}>
                    {v}
                  </option>
                ))}
              </Form.Select>
            </FloatingLabel>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col className="col-4">
            <Button className="w-100 py-3" onClick={onSearch} data-testid={testid('search-button')}>
              検索
            </Button>
          </Col>
        </Row>
      </Form.Group>

      {($.index.client === 0 || $.index.client) && ($.index.shop === 0 || $.index.shop) && (
        <ShopListModal
          isModal={$.isShopListModal}
          onSave={saveShopListModal}
          onHide={onHideShopListModal}
          contractShopId={$.list[$.index.client].contractShops[$.index.shop].id}
          mergeParam={mergeShopParam}
        />
      )}

      <PostingRequestListTable
        listPerPage={$.listPerPage}
        setIsShopListModal={useBindSet('isShopListModal')}
        setIndex={useBindSet('index')}
      />

      <div className="mb-4" data-testid={testid('pagination')}>
        <PaginationWithEllipsis
          currentPage={$.currentPage}
          totalPage={$.totalPage}
          handleClick={(page) => {
            if (!page || page > $.totalPage) return;
            mergeState({
              listPerPage: $.list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
              currentPage: page,
            });
          }}
        />
      </div>
    </>
  );
};
