/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect } from 'react';
import { Alert, Nav, Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { getImageSize, getPresignedUrl, s3Delete, s3Upload, s3UploadAlignment } from '../../../utils/s3';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { IMAGE_TYPE_ID, OBJECT_TYPE } from '../../../Constants';
import { CommonMasterListApi, CorrectSettingApi } from '../../../api-client';
import type {
  CommonCheckOutputResponse,
  ImageUpdateFormResponse,
  TargetPeriodSettingFormResponse,
  TargetPeriodSettingOutputResponse,
  TargetPrefecturesSettingOutputResponse,
  TargetPriceSettingFormResponse,
  TargetPriceSettingOutputResponse,
  AutoCorrectRuleInfoOutputResponse,
  MonitorCorrectionSettingUpdateFormResponse,
  MonitorCorrectionSettingInfoOutputResponse,
  AutoCorrectRuleUpdateFormResponse,
  DataEntrySettingUpdateFormResponse,
  DataEntrySettingInfoOutputResponse,
  IncResultOutputResponse,
  ComparisonMethodListOutputResponse,
  ErrorObjectResponse,
  AutoCorrectRuleOutputResponse,
  CommonMasterListOutputResponse,
} from '../../../api-client';
import { isoDateToInput, uuid, when } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import { MonitorClientInfoCard } from '../../organisms/Card/CorrectSetting/MonitorClientInfoCard';
import { CorrectSettingEachMonitorBaseDataEntryForms } from '../../organisms/Forms/CorrectSettingEachMonitorBaseDataEntryForms';
import { CorrectSettingEachMonitorBaseAutoCorrectForms } from '../../organisms/Forms/CorrectSettingEachMonitorBaseAutoCorrectForms';
import { CorrectSettingEachMonitorBaseAutoCorrectTable } from '../../organisms/Table/CorrectSettingEachMonitorBaseAutoCorrectTable';
import { Modal } from '../../molecules/Modal';
import { BASE_PATH } from '../../../api-client/base';

export interface ImageProp {
  isDefault: boolean;
  id?: number;
  name?: string;
  path?: string;
  file?: File;
  contentType?: string;
  width?: number;
  height?: number;
  fileFormat?: string;
}

export type AutoCorrectChainStoreForm = {
  conditionList: Array<{ chainName: string; storeList: string[]; newFlg: boolean; deleteFlg: boolean }>;
  ngMessage: string;
  rejectAlertFlg: boolean;
};

type AutoCorrectForm = {
  lastUpdateAt: string;
  autoCorrectStartFlg: boolean;
  duplicateCheckTargetMonitorIdList: { monitorIdList: string; ngMessage: string; rejectAlertFlg: boolean };
  okTargetChainStoreSetting: AutoCorrectChainStoreForm;
  ngTargetChainStoreSetting: AutoCorrectChainStoreForm;
  targetPeriodSetting: TargetPeriodSettingFormResponse;
  targetPrefecturesSetting: {
    ngMessage: string;
    prefectureList: Array<{ id: number; name: string }>;
    rejectAlertFlg: boolean;
  };
  targetPriceSetting: TargetPriceSettingFormResponse;
};

type AutoCorrectOutput = {
  autoCorrectRule: {
    autoCorrectStartFlg?: boolean;
    commonCheckList?: Array<CommonCheckOutputResponse>;
    duplicateCheckTargetMonitorIdList?: { monitorIdList: string; ngMessage: string; rejectAlertFlg: boolean };
    targetOkChainStoreSettingList?: AutoCorrectChainStoreForm;
    targetNgChainStoreSettingList?: AutoCorrectChainStoreForm;
    targetPeriodSetting?: TargetPeriodSettingOutputResponse;
    targetPrefecturesSetting?: TargetPrefecturesSettingOutputResponse;
    targetPriceSettingList?: TargetPriceSettingOutputResponse;
    updateAt?: string;
    updateIncId?: number;
    updateIncName?: string;
  };
  correctMethodId: number;
  monitorBaseId: number;
};

export interface CorrectSettingEachMonitorBaseState {
  api: CorrectSettingApi;
  commonMasterListApi: CommonMasterListApi;
  isLoading: boolean;
  monitorCorrectionInfo: MonitorCorrectionSettingInfoOutputResponse;
  dataEntryInfo: DataEntrySettingInfoOutputResponse;
  autoCorrectInfo: AutoCorrectOutput;
  correctSettingForm: MonitorCorrectionSettingUpdateFormResponse;
  dataEntryForm: DataEntrySettingUpdateFormResponse;
  autoCorrectForm: AutoCorrectForm;
  imageData: ImageProp[];
  autoCorrectErrorMessages: string[];
  dataInputErrorMessages: string[];
  activeCorrectKey: 'surveyProof' | 'enquete';
  activeSettingKey: 'dataEntry' | 'autoCorrect';
  comparisonMethodList: ComparisonMethodListOutputResponse[];
  prefectureList: CommonMasterListOutputResponse[];
  dataUpdateFlg: boolean;
  isSubmitting: boolean;
}
export type TestIds = 'back-button' | 'save-button';

export const initialInfo: MonitorCorrectionSettingInfoOutputResponse = {
  clientId: 0,
  clientName: '',
  contractShopId: 0,
  contractShopName: '',
  doubleCheckFlg: false,
  monitorId: 0,
  clientSettingConfigureFlg: false,
  electedPersonList: [],
  updateAt: '',
  updateIncName: '',
  clientCorrectionExecutionFlg: false,
  enqueteCorrectionMethodId: 0,
  enqueteCorrectionMethodName: '',
  monitorName: '',
  monitorCorrectionExecutionFlg: false,
  surveyProofCorrectionMethodId: 0,
  surveyProofCorrectionMethodName: '',
};

export const initialDataEntryInfo: DataEntrySettingInfoOutputResponse = {
  correctionMethodId: 0,
  correctionMethodName: '',
  dataEntrySettings: {
    dataEntryPersonStartFlg: false,
    imageFileList: [],
    noteContent: '',
    noteTemplateList: [],
    priority: 0,
    taxExcluded8PercentFlg: false,
    taxExcluded10PercentFlg: false,
    taxIncludedFlg: false,
    updateAt: '',
    updateIncId: 0,
    updateIncName: '',
    countDownSettingDate: '',
  },
};

export const initialAutoCorrectInfo: AutoCorrectOutput = {
  correctMethodId: 0,
  monitorBaseId: 0,
  autoCorrectRule: {
    autoCorrectStartFlg: false,
    commonCheckList: [],
    duplicateCheckTargetMonitorIdList: { monitorIdList: '', ngMessage: '', rejectAlertFlg: false },
    targetOkChainStoreSettingList: { conditionList: [], ngMessage: '', rejectAlertFlg: false },
    targetNgChainStoreSettingList: { conditionList: [], ngMessage: '', rejectAlertFlg: false },
    targetPeriodSetting: { conditionList: [], ngMessage: '', rejectAlertFlg: false },
    targetPrefecturesSetting: { ngMessage: '', prefecturesList: [], rejectAlertFlg: false },
    targetPriceSettingList: { conditionList: [], ngMessage: '', rejectAlertFlg: false },
    updateAt: '',
    updateIncId: 0,
    updateIncName: '',
  },
};

export const CorrectSettingEachMonitorPage: React.FC = () => {
  const initialCorrectSettingForm: MonitorCorrectionSettingUpdateFormResponse = {
    monitorId: 0,
    correctStartFlg: false,
  };
  const initialAutoCorrectForm: AutoCorrectForm = {
    lastUpdateAt: '',
    autoCorrectStartFlg: false,
    duplicateCheckTargetMonitorIdList: { monitorIdList: '', ngMessage: '', rejectAlertFlg: false },
    okTargetChainStoreSetting: { conditionList: [], ngMessage: '', rejectAlertFlg: false },
    ngTargetChainStoreSetting: { conditionList: [], ngMessage: '', rejectAlertFlg: false },
    targetPeriodSetting: { conditionList: [], ngMessage: '', rejectAlertFlg: false },
    targetPrefecturesSetting: { prefectureList: [], ngMessage: '', rejectAlertFlg: false },
    targetPriceSetting: { conditionList: [], ngMessage: '', rejectAlertFlg: false },
  };

  const initialDataEntryForm: DataEntrySettingUpdateFormResponse = {
    dataEntryStartFlg: false,
    imageIdList: [],
    monitorId: 0,
    noteContent: '',
    priority: 0,
    taxExcluded8PercentFlg: false,
    taxExcluded10PercentFlg: false,
    taxIncludedFlg: true,
  };

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<CorrectSettingEachMonitorBaseState>({
    api: new CorrectSettingApi(),
    commonMasterListApi: new CommonMasterListApi(),
    isLoading: false,
    monitorCorrectionInfo: initialInfo,
    dataEntryInfo: initialDataEntryInfo,
    autoCorrectInfo: initialAutoCorrectInfo,
    correctSettingForm: initialCorrectSettingForm,
    autoCorrectForm: initialAutoCorrectForm,
    dataEntryForm: initialDataEntryForm,
    imageData: [],
    autoCorrectErrorMessages: [],
    dataInputErrorMessages: [],
    activeCorrectKey: 'surveyProof',
    activeSettingKey: query.get('key') === 'autoCorrect' ? 'autoCorrect' : 'dataEntry',
    comparisonMethodList: [],
    prefectureList: [],
    dataUpdateFlg: false,
    isSubmitting: false,
  });

  const { monitorId } = useParams<{ monitorId: string | undefined }>();
  const history = useHistory();
  const setCorrectSettingForm = useBindSet('correctSettingForm');
  const setAutoCorrectForm = useBindSet('autoCorrectForm');
  const setDataEntryForm = useBindSet('dataEntryForm');

  const createAutoCorrectInitialData = (data: AutoCorrectRuleInfoOutputResponse) => {
    const autoCorrectRule: AutoCorrectRuleOutputResponse = data.autoCorrectRule
      ? data.autoCorrectRule
      : { autoCorrectStartFlg: false, commonCheckList: [], updateAt: '', updateIncId: 0, updateIncName: '' };
    const returnObj: AutoCorrectOutput = {
      correctMethodId: data.correctMethodId,
      monitorBaseId: data.monitorId,
      autoCorrectRule: {
        ...data.autoCorrectRule,
        targetPeriodSetting: autoCorrectRule.targetPeriodSetting
          ? {
              conditionList: autoCorrectRule.targetPeriodSetting.conditionList.map((obj) => {
                return {
                  comparisonMethodId: obj.comparisonMethodId,
                  comparisonMethodName: obj.comparisonMethodName,
                  targetDate: isoDateToInput('date', obj.targetDate || '') as string,
                  targetDateEnd: isoDateToInput('date', obj.targetDateEnd || '') as string,
                  targetDateStart: isoDateToInput('date', obj.targetDateStart || '') as string,
                };
              }),
              ngMessage: autoCorrectRule.targetPeriodSetting.ngMessage || '',
              rejectAlertFlg: !!autoCorrectRule.targetPeriodSetting.rejectAlertFlg,
            }
          : { conditionList: [], ngMessage: '', rejectAlertFlg: false },
        duplicateCheckTargetMonitorIdList: autoCorrectRule.duplicateCheckTargetMonitorIdList
          ? {
              monitorIdList: autoCorrectRule.duplicateCheckTargetMonitorIdList.monitorIdList.join(',') || '',
              ngMessage: autoCorrectRule.duplicateCheckTargetMonitorIdList.ngMessage || '',
              rejectAlertFlg: !!autoCorrectRule.duplicateCheckTargetMonitorIdList.rejectAlertFlg,
            }
          : { monitorIdList: '', ngMessage: '', rejectAlertFlg: false },
        targetNgChainStoreSettingList: autoCorrectRule.targetNgChainStoreSetting
          ? {
              ...autoCorrectRule.targetNgChainStoreSetting,
              conditionList: autoCorrectRule.targetNgChainStoreSetting.conditionList.map((obj) => {
                return { ...obj, deleteFlg: false, newFlg: false };
              }),
            }
          : { conditionList: [], ngMessage: '', rejectAlertFlg: false },
        targetOkChainStoreSettingList: autoCorrectRule.targetOkChainStoreSetting
          ? {
              ...autoCorrectRule.targetOkChainStoreSetting,
              conditionList: autoCorrectRule.targetOkChainStoreSetting.conditionList.map((obj) => {
                return { ...obj, deleteFlg: false, newFlg: false };
              }),
            }
          : { conditionList: [], ngMessage: '', rejectAlertFlg: false },
      },
    };
    return returnObj;
  };

  /**
   * 初期化
   */
  const init = () => {
    mergeState({ isLoading: true });
    if (monitorId && Number(monitorId) > 0) {
      $.api
        .monitorCorrectionSettingInfo(Number(monitorId))
        .then((res: AxiosResponse<MonitorCorrectionSettingInfoOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ monitorCorrectionInfo: initialInfo, autoCorrectErrorMessages: ['エラーが発生しました。'] });
          } else {
            mergeState({ monitorCorrectionInfo: res.data, autoCorrectErrorMessages: [] });
          }
        });

      $.api.dataEntrySettingInfo(Number(monitorId)).then((res: AxiosResponse<DataEntrySettingInfoOutputResponse>) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          mergeState({ dataEntryInfo: initialDataEntryInfo, autoCorrectErrorMessages: ['エラーが発生しました。'] });
        } else {
          mergeState({ dataEntryInfo: res.data, autoCorrectErrorMessages: [] });
          mergeState({
            imageData: res.data.dataEntrySettings.imageFileList.map((data) => {
              return { id: data.id, path: data.imagePath, isDefault: !!data.id };
            }),
          });
        }
      });

      $.api.autoCorrectRuleInfo(Number(monitorId)).then((res: AxiosResponse<AutoCorrectRuleInfoOutputResponse>) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          mergeState({ autoCorrectInfo: initialAutoCorrectInfo, autoCorrectErrorMessages: ['エラーが発生しました。'] });
        } else {
          const initialData: AutoCorrectOutput = createAutoCorrectInitialData(res.data);
          mergeState({ autoCorrectInfo: initialData, autoCorrectErrorMessages: [] });
        }
      });
    }

    $.api.comparisonMethodList().then((res: AxiosResponse<Array<ComparisonMethodListOutputResponse>>) => {
      if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
        mergeState({ autoCorrectInfo: initialAutoCorrectInfo, autoCorrectErrorMessages: ['エラーが発生しました。'] });
      } else {
        mergeState({ comparisonMethodList: res.data, autoCorrectErrorMessages: [] });
      }
    });

    const PREFECTURE_KEY = 'PREFECTURE';
    $.commonMasterListApi
      .commonMasterList(PREFECTURE_KEY)
      .then((res: AxiosResponse<Array<CommonMasterListOutputResponse>>) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          mergeState({ autoCorrectInfo: initialAutoCorrectInfo, autoCorrectErrorMessages: ['エラーが発生しました。'] });
        } else {
          mergeState({ prefectureList: res.data });
        }
      });

    mergeState({ isLoading: false });
  };

  useEffect(() => {
    init();
  }, [$.api, $.commonMasterListApi, mergeState, monitorId]);

  useEffect(() => {
    if ($.dataUpdateFlg) {
      // データが更新されたら最新のデータを取得する
      init();
    }
  }, [$.dataUpdateFlg]);

  const uploadImage = async (imageInfo: ImageProp) => {
    const { isDefault, id, path, name, contentType, file, width, height, fileFormat } = imageInfo;

    let uploadId: number | undefined;

    try {
      if (file && name && contentType && width && height && fileFormat) {
        const presignedUrl = await getPresignedUrl({
          contentType,
          fileKey: name,
          objectType: OBJECT_TYPE.QUESTION /* FIXME */,
        });
        const uploadTime = await s3Upload(presignedUrl, contentType, file);

        const alignmentParam: ImageUpdateFormResponse = { width, height, format: fileFormat };
        if (isDefault) {
          alignmentParam.imageId = id;
        } else {
          alignmentParam.imageTypeId = IMAGE_TYPE_ID.QUESTION; /* FIXME */
          const presignedPath = presignedUrl.replace(/\?.*$/, '');
          alignmentParam.path = presignedPath.substring(
            presignedPath.indexOf('amazonaws.com/') + 'amazonaws.com/'.length
          );
          alignmentParam.uploadTime = uploadTime;
        }

        await s3UploadAlignment(alignmentParam).then((res) => {
          uploadId = res.id;
        });
      } else if (isDefault && !path && id) {
        await s3Delete(id).then(() => {
          uploadId = undefined;
        });
      }
    } catch {
      history.push(Url.COMMON_ERROR);
    }
    return uploadId;
  };

  // 保存
  const onSaveDataEntry = (
    correctSettingForm: MonitorCorrectionSettingUpdateFormResponse,
    dataEntryForm: DataEntrySettingUpdateFormResponse,
    imageList: ImageProp[]
  ) => {
    mergeState({ isSubmitting: true });
    $.api
      .monitorCorrectionSettingUpdate(Number(monitorId), correctSettingForm)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        if (!res.data.result) {
          mergeState({ dataInputErrorMessages: ['エラーが発生しました。'] });
        } else {
          mergeState({ dataInputErrorMessages: [] });
          // モニター毎添削設定が更新、保存できた時だけデータ入力設定の更新、保存を行う
          Promise.all(
            imageList.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (imageObj): Promise<any> =>
                new Promise((resolve, reject) => {
                  uploadImage(imageObj)
                    .then((imageRes) => resolve(imageRes))
                    .catch((err) => reject(new Error(err)));
                })
            )
          ).then(async (idList: number[]) => {
            const form: DataEntrySettingUpdateFormResponse = {
              ...dataEntryForm,
              imageIdList: idList.filter((id) => id),
            };
            $.api
              .dataEntrySettingUpdate(form)
              .then((dataEntrySettingRes: AxiosResponse<IncResultOutputResponse>) => {
                if (!dataEntrySettingRes.data.result) {
                  mergeState({ dataInputErrorMessages: ['エラーが発生しました。'] });
                } else {
                  mergeState({ dataInputErrorMessages: [], dataUpdateFlg: true });
                }
              })
              .catch((error) => {
                mergeState({ dataInputErrorMessages: [error.response.data.errorMessage] });
              });
          });
        }
      })
      .catch((error) => {
        const errors = [...error.response?.data.errors];
        const dataInputErrorMessages = errors.map((value: ErrorObjectResponse) => value.message);
        mergeState({ dataInputErrorMessages });
      })
      .finally(() => {
        mergeState({ isSubmitting: false });
      });
  };

  const createSaveAutoCorrectBody = (body: AutoCorrectForm) => {
    const isDuplicateFlg =
      !!body.duplicateCheckTargetMonitorIdList.monitorIdList ||
      !!body.duplicateCheckTargetMonitorIdList.ngMessage ||
      body.duplicateCheckTargetMonitorIdList.rejectAlertFlg;
    const isOkTargetFlg =
      body.okTargetChainStoreSetting.conditionList.length > 0 ||
      !!body.okTargetChainStoreSetting.ngMessage ||
      body.okTargetChainStoreSetting.rejectAlertFlg;
    const isNgTargetFlg =
      body.ngTargetChainStoreSetting.conditionList.length > 0 ||
      !!body.ngTargetChainStoreSetting.ngMessage ||
      body.ngTargetChainStoreSetting.rejectAlertFlg;
    const isTargetPrefecturesFlg =
      body.targetPrefecturesSetting.prefectureList.length > 0 ||
      !!body.targetPrefecturesSetting.ngMessage ||
      body.targetPrefecturesSetting.rejectAlertFlg;
    const isTargetPeriodFlg =
      body.targetPeriodSetting.conditionList.length > 0 ||
      !!body.targetPeriodSetting.ngMessage ||
      body.targetPeriodSetting.rejectAlertFlg;
    const isTargetPriceFlg =
      body.targetPriceSetting.conditionList.length > 0 ||
      !!body.targetPriceSetting.ngMessage ||
      body.targetPriceSetting.rejectAlertFlg;

    const returnObj: AutoCorrectRuleUpdateFormResponse = {
      ...body,
      targetPrefecturesSetting: isTargetPrefecturesFlg
        ? {
            ...body.targetPrefecturesSetting,
            prefectureList: body.targetPrefecturesSetting.prefectureList.map(({ id }) => id),
          }
        : undefined,
      targetPriceSetting: isTargetPriceFlg ? body.targetPriceSetting : undefined,
      targetPeriodSetting: isTargetPeriodFlg
        ? {
            ...body.targetPeriodSetting,
            conditionList: body.targetPeriodSetting.conditionList,
          }
        : undefined,
      ngTargetChainStoreSetting: isNgTargetFlg
        ? {
            ...body.ngTargetChainStoreSetting,
            conditionList: body.ngTargetChainStoreSetting.conditionList
              .filter(({ deleteFlg }) => !deleteFlg)
              .map(({ chainName, storeList }) => {
                return { chainName, storeList };
              }),
          }
        : undefined,
      okTargetChainStoreSetting: isOkTargetFlg
        ? {
            ...body.okTargetChainStoreSetting,
            conditionList: body.okTargetChainStoreSetting.conditionList
              .filter(({ deleteFlg }) => !deleteFlg)
              .map(({ chainName, storeList }) => {
                return { chainName, storeList };
              }),
          }
        : undefined,
      duplicateCheckTargetMonitorIdList: isDuplicateFlg
        ? {
            ...body.duplicateCheckTargetMonitorIdList,
            monitorIdList: body.duplicateCheckTargetMonitorIdList.monitorIdList
              .split(',')
              .filter((id) => id)
              .map((id: string) => Number(id)),
          }
        : undefined,
    };

    return returnObj;
  };
  // 保存
  const onSaveAutoCorrect = useCallback(
    (correctSettingForm: MonitorCorrectionSettingUpdateFormResponse, autoCorrectForm: AutoCorrectForm) => {
      mergeState({ isSubmitting: true });
      $.api
        .monitorCorrectionSettingUpdate(Number(monitorId), correctSettingForm)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (!res.data.result) {
            mergeState({ autoCorrectErrorMessages: ['エラーが発生しました。'], isSubmitting: false });
          } else {
            mergeState({ autoCorrectErrorMessages: [] });

            // モニター毎添削設定が作成or更新された後に自動添削ルール設定を保存する, dataUpdateFlg: true
            const onSaveAutoCorrectForm: AutoCorrectRuleUpdateFormResponse = createSaveAutoCorrectBody(autoCorrectForm);
            $.api
              .autoCorrectRuleUpdate(Number(monitorId), onSaveAutoCorrectForm)
              .then((autoCorrectRuleRes: AxiosResponse<IncResultOutputResponse>) => {
                if (!autoCorrectRuleRes.data.result) {
                  mergeState({ autoCorrectErrorMessages: ['エラーが発生しました。'] });
                } else {
                  mergeState({ autoCorrectErrorMessages: [], dataUpdateFlg: true });
                }
              })
              .catch((error) => {
                mergeState({ autoCorrectErrorMessages: [error.response.data.errorMessage] });
              })
              .finally(() => {
                mergeState({ isSubmitting: false });
              });
          }
        })
        .catch((error) => {
          const errors = [...error.response?.data.errors];
          const autoCorrectErrorMessages = errors.map((value: ErrorObjectResponse) => value.message);
          mergeState({ autoCorrectErrorMessages, isSubmitting: false });
        });
    },
    [$.api, mergeState, monitorId]
  );

  const searchDataEntrySetting = async (id: number) => {
    let targetSetting: DataEntrySettingInfoOutputResponse | undefined;

    await $.api
      .dataEntrySettingInfo(id)
      .then((res: AxiosResponse<DataEntrySettingInfoOutputResponse>) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          mergeState({ dataInputErrorMessages: ['エラーが発生しました。'] });
        } else {
          targetSetting = res.data;
        }
      })
      .catch((error) => {
        targetSetting = undefined;
        const errors = [...error.response?.data.errors];
        const dataInputErrorMessages = errors.map((value: ErrorObjectResponse) => value.message);
        mergeState({ dataInputErrorMessages });
      });

    return targetSetting;
  };

  const searchAutoCorrectSetting = async (id: number) => {
    let targetSetting: AutoCorrectOutput | undefined;

    await $.api
      .autoCorrectRuleInfo(id)
      .then((res: AxiosResponse<AutoCorrectRuleInfoOutputResponse>) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          mergeState({ autoCorrectErrorMessages: ['エラーが発生しました。'] });
        } else {
          targetSetting = createAutoCorrectInitialData(res.data);
        }
      })
      .catch((error) => {
        targetSetting = undefined;
        const errors = [...error.response?.data.errors];
        const autoCorrectErrorMessages = errors.map((value: ErrorObjectResponse) => value.message);
        mergeState({ autoCorrectErrorMessages });
      });

    return targetSetting;
  };

  const onDrop = async (files: File[]) => {
    if (files === null || files.length === 0) return;
    const file = files[0];

    const { name, type: contentType } = file;
    const path = window.URL.createObjectURL(file);
    const fileFormat = name.split('.').pop();
    const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));

    mergeState({
      imageData: [...$.imageData, { ...size, file, name, contentType, fileFormat, path, isDefault: false }],
    });
  };

  const onDelete = (url: string) => {
    const newData = $.imageData.map(({ path }, i) => {
      if (path === url) {
        return { ...$.imageData[i], path: undefined };
      }
      return $.imageData[i];
    });
    mergeState({ imageData: newData });
  };

  // レシート添削方法
  const RECEIPT_CORRECT_TYPE = {
    RECEIPT_INC_APPROVE: 0,
    RECEIPT_AUTO_APPROVE: 1,
    RECEIPT_DATA_INPUT: 2,
    OTHER_INC_APPROVE: 3,
    OTHER_AUTO_APPROVE: 4,
    DELIVERY_SLIP_INC_APPROVE_WITH_IMAGE: 5,
    DELIVERY_SLIP_CLIENT_APPROVE_WITH_IMAGE: 6,
    DELIVERY_SLIP_INC_CLIENT_APPROVE_WITH_IMAGE: 7,
    DELIVERY_SLIP_SYSTEM_APPROVE_WITHOUT_IMAGE: 8,
    DELIVERY_SLIP_CLIENT_APPROVE_WITHOUT_IMAGE: 9,
    NO_SUBMISSION: 10,
  };
  return (
    <>
      {$.dataUpdateFlg && (
        <Modal
          isModal={$.dataUpdateFlg}
          centered
          closeButton
          onHide={() => mergeState({ dataUpdateFlg: false })}
          body={
            <>
              <p>更新しました。</p>
              <div className="d-flex justify-content-center m-2">
                <Button onClick={() => mergeState({ dataUpdateFlg: false })}>閉じる</Button>
              </div>
            </>
          }
        />
      )}
      <LoadingSpinner isLoading={$.isLoading}>
        {when(
          $.monitorCorrectionInfo.monitorId > 0,
          <MonitorClientInfoCard
            eachMonitorInfo={$.monitorCorrectionInfo}
            setForms={setCorrectSettingForm}
            autoCorrectionInfo={$.autoCorrectInfo}
          />
        )}

        <div className="p-2 d-inline-block align-top w-75">
          <Tab.Container defaultActiveKey="surveyProof">
            <Nav className="mt-2 ms-2">
              <Nav.Item
                style={{ cursor: 'pointer' }}
                className="col text-center"
                onClick={() => mergeState({ activeCorrectKey: 'surveyProof' })}
              >
                <Nav.Link
                  className={`border-bottom text-dark ${$.activeCorrectKey === 'surveyProof' ? 'border-dark' : ''}`}
                  eventKey="surveyProof"
                >
                  購入証明
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                style={{ cursor: 'pointer' }}
                className="col text-center"
                onClick={() =>
                  mergeState({
                    activeCorrectKey: 'enquete',
                  })
                }
              >
                <Nav.Link
                  className={`border-bottom text-dark  ${$.activeCorrectKey === 'enquete' ? 'border-dark' : ''}`}
                  eventKey="enquete"
                >
                  アンケート
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className="d-block">
              <Tab.Pane eventKey="surveyProof">
                <h6 className="mt-2">添削方法</h6>
                {$.monitorCorrectionInfo.surveyProofCorrectionMethodName}
                <h6 className="mt-2">設定</h6>
                {$.dataEntryInfo.correctionMethodId === RECEIPT_CORRECT_TYPE.RECEIPT_DATA_INPUT ? (
                  <Tabs
                    id="customerPageTabs"
                    activeKey={$.activeSettingKey}
                    onSelect={(key) => {
                      mergeState({ activeSettingKey: key === 'dataEntry' ? 'dataEntry' : 'autoCorrect' });
                    }}
                  >
                    <Tab title="データ入力設定" eventKey="dataEntry">
                      <CorrectSettingEachMonitorBaseDataEntryForms
                        setForms={setDataEntryForm}
                        monitorId={$.correctSettingForm.monitorId}
                        dataEntryInfo={$.dataEntryInfo}
                        searchSetting={searchDataEntrySetting}
                        onDrop={onDrop}
                        onDelete={onDelete}
                        imageList={$.imageData}
                      />
                      {$.dataInputErrorMessages.map((v) => {
                        return (
                          <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
                            {v}
                          </Alert>
                        );
                      })}
                      <div className="d-flex justify-content-end">
                        <Button
                          className="m-2"
                          disabled={$.isSubmitting}
                          onClick={() => {
                            onSaveDataEntry($.correctSettingForm, $.dataEntryForm, $.imageData);
                          }}
                        >
                          保存
                        </Button>
                      </div>
                    </Tab>
                    <Tab title="自動添削ルール設定" eventKey="autoCorrect">
                      <CorrectSettingEachMonitorBaseAutoCorrectForms
                        autoCorrectInfo={$.autoCorrectInfo}
                        searchSetting={searchAutoCorrectSetting}
                        setForms={setAutoCorrectForm}
                        comparisonMethodList={$.comparisonMethodList}
                        prefectureList={$.prefectureList}
                      >
                        {$.autoCorrectInfo.autoCorrectRule.commonCheckList &&
                        $.autoCorrectInfo.autoCorrectRule.commonCheckList.length > 0 ? (
                          <CorrectSettingEachMonitorBaseAutoCorrectTable
                            list={$.autoCorrectInfo.autoCorrectRule.commonCheckList}
                          />
                        ) : (
                          <div className="m-2">全案件共通チェックはありません</div>
                        )}
                      </CorrectSettingEachMonitorBaseAutoCorrectForms>
                      {$.autoCorrectErrorMessages.map((v) => {
                        return (
                          <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
                            {v}
                          </Alert>
                        );
                      })}
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="warning"
                          className="m-2"
                          href={`${BASE_PATH}${Url.TENSAKU.RE_RUN_AUTO_CORRECT_BATCH}?p1=${$.correctSettingForm.monitorId}`}
                          target="_blank"
                        >
                          自動添削再実行
                        </Button>
                        <Button
                          className="m-2"
                          disabled={$.isSubmitting}
                          onClick={() => {
                            onSaveAutoCorrect($.correctSettingForm, $.autoCorrectForm);
                          }}
                        >
                          保存
                        </Button>
                      </div>
                    </Tab>
                  </Tabs>
                ) : (
                  <>レシート添削方法がレシート[データ入力]ではありません。</>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="enquete">
                <h6 className="mt-2">添削方法</h6>
                {$.monitorCorrectionInfo.enqueteCorrectionMethodName}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </LoadingSpinner>
    </>
  );
};
