import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { faEnvelope, faGripLines, faPlus, faReplyAll, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { Button, Form, InputGroup, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import type {
  CommonMasterListOutputResponse,
  EnqueteInfoOutputResponse,
  EnqueteQuestionsDataResponse,
  EnqueteQuestionSettingOutputResponse,
} from '../../../api-client';
import { QuestionCategoryListOutputResponse } from '../../../api-client';
import { ENQUETE_TYPE } from '../../pages/Enquete/EnqueteListPage';
import { QuestionInfoPopover } from '../Popover/QuestionInfoPopover';
import { when } from '../../../utils/functions';

export interface Props {
  data?: EnqueteInfoOutputResponse;
  categoryList: CommonMasterListOutputResponse[];
  questionCategoryList: QuestionCategoryListOutputResponse[];
  enqueteType: number;
  deleteQuestionList: EnqueteQuestionsDataResponse[];
  setDeleteModalFlg: Dispatch<SetStateAction<boolean>>;
  handleOnDragEndItem: (e: any) => void;
  setReportMailModalFlg: Dispatch<SetStateAction<boolean>>;
  setAllotmentPointCofirmModalFlg: Dispatch<SetStateAction<boolean>>;
  setJumpQuestionModalFlg: Dispatch<SetStateAction<boolean>>;
  setSelectedQuestionId: Dispatch<SetStateAction<number | undefined>>;
  setQuestionListModalFlg: Dispatch<SetStateAction<boolean>>;
  setSelectedQuestionIdx: React.Dispatch<React.SetStateAction<number>>;
  setOriginQuestion: React.Dispatch<
    React.SetStateAction<
      | {
          enqueteQuestionsId: number;
          questionId: number;
          mailContent: string | undefined;
          deleteFlg: boolean;
          questionCategoryId: number | undefined;
        }
      | undefined
    >
  >;
  setDeleteQuestionList: React.Dispatch<React.SetStateAction<EnqueteQuestionsDataResponse[]>>;
}

export const EnqueteQuestionListTable: React.VFC<Props> = ({
  data,
  categoryList,
  questionCategoryList,
  enqueteType,
  deleteQuestionList,
  setDeleteModalFlg: setDeleteModal,
  handleOnDragEndItem,
  setReportMailModalFlg,
  setAllotmentPointCofirmModalFlg,
  setJumpQuestionModalFlg,
  setSelectedQuestionId,
  setQuestionListModalFlg,
  setSelectedQuestionIdx,
  setOriginQuestion,
  setDeleteQuestionList,
}) => {
  const questionHelperText = (
    <Popover>
      設問を表示する
      <br />
      条件を設定
    </Popover>
  );

  const mailHelperText = (
    <Popover>
      速報メール
      <br />
      表示内容設定
    </Popover>
  );

  const overLayButton = (row: EnqueteQuestionSettingOutputResponse) => {
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Popover style={{ maxWidth: '25rem' }}>
            <QuestionInfoPopover questionId={row.questionId} enqueteType={enqueteType} />
          </Popover>
        }
      >
        <InputGroup>
          <Form.Control id="questionContext" type="text" value={row.content} disabled />
          <Button
            onClick={() => {
              setQuestionListModalFlg(true);
              setOriginQuestion({
                enqueteQuestionsId: row.enqueteQuestionsId,
                questionId: row.questionId,
                mailContent: row.mailContent,
                deleteFlg: true,
                questionCategoryId: row.questionCategoryId,
              });
            }}
          >
            選択
          </Button>
        </InputGroup>
      </OverlayTrigger>
    );
  };

  // アンケート一覧画面の
  // 【事後 積上式】タブからの場合のTableContents
  const tableContents1 = (
    <>
      <thead>
        <tr>
          <th> </th>
          <th>設問番号</th>
          <th>設問ID</th>
          <th>カテゴリ</th>
          <th>レベル</th>
          <th>設問</th>
          <th>積上配点（最大120点）</th>
          <th> </th>
        </tr>
      </thead>
      <Droppable droppableId="table-body">
        {(provided) => {
          return (
            <tbody style={{ width: '100%' }} ref={provided.innerRef} {...provided.droppableProps}>
              {data?.enqueteQuestionsList.map((row, index) => {
                return (
                  <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                    {(provided2) => {
                      return (
                        <tr
                          className="align-middle"
                          key={index.toString()}
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                        >
                          <td className="text-center">
                            <Button variant="link" className=" text-secondary" data-html {...provided2.dragHandleProps}>
                              <FontAwesomeIcon
                                data-testid="linesButton"
                                className="text-secondary"
                                icon={faGripLines}
                              />
                            </Button>
                          </td>
                          <td className="text-center" style={{ width: '5%' }}>
                            {(index + 1).toString()}
                          </td>
                          <td className="text-center" style={{ width: '5%' }}>
                            {row.questionId}
                          </td>
                          <td className="align-middle" style={{ width: '10%' }}>
                            <OverlayTrigger trigger={['hover', 'hover']} placement="left" overlay={questionHelperText}>
                              <Button
                                variant="link"
                                className=" text-secondary"
                                data-html
                                onClick={() => {
                                  setJumpQuestionModalFlg(true);
                                  setSelectedQuestionId(row.questionId);
                                  setSelectedQuestionIdx(index);
                                }}
                              >
                                <FontAwesomeIcon
                                  data-testid="arrowButton"
                                  className="text-secondary"
                                  icon={faReplyAll}
                                  style={{ transform: 'scaleX(-1)' }}
                                />
                              </Button>
                            </OverlayTrigger>
                            {when(
                              row.answerMethod === 1,
                              <OverlayTrigger trigger={['hover', 'hover']} placement="right" overlay={mailHelperText}>
                                <Button
                                  variant="link"
                                  className=" text-secondary"
                                  data-html
                                  onClick={() => {
                                    setReportMailModalFlg(true);
                                    setSelectedQuestionId(row.questionId);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    data-testid="mailButton"
                                    className="text-secondary"
                                    icon={faEnvelope}
                                  />
                                </Button>
                              </OverlayTrigger>
                            )}
                            <Form.Control disabled type="text" value={row.questionCategoryName || ''} />
                          </td>
                          <td className="align-bottom text-center" style={{ width: '5%' }}>
                            <Form.Control type="text" value={row.level} disabled />
                          </td>
                          <td className="align-bottom" style={{ width: '32.5%' }}>
                            {overLayButton(row)}
                          </td>
                          <td className="align-bottom" style={{ width: '32.5%' }}>
                            <InputGroup>
                              <Form.Control
                                type="text"
                                value={data.pointSettings
                                  .filter((_) => {
                                    return _.questionId === row.questionId;
                                  })
                                  .map((target) => {
                                    return target.point !== undefined ? target.point?.toString() : '';
                                  })}
                                readOnly
                              />
                              <Button
                                onClick={() => {
                                  setAllotmentPointCofirmModalFlg(true);
                                  setSelectedQuestionId(row.questionId);
                                }}
                              >
                                編集
                              </Button>
                            </InputGroup>
                          </td>
                          <td align="center">
                            <Button
                              variant="link"
                              className="text-secondary"
                              onClick={() => {
                                setSelectedQuestionId(row.questionId);
                                // 追加したもの以外を削除した際は控えておく
                                if (row.enqueteQuestionsId !== 0) {
                                  setDeleteQuestionList([
                                    ...deleteQuestionList,
                                    {
                                      id: row.enqueteQuestionsId,
                                      questionId: row.questionId,
                                      mailContent: row.mailContent,
                                      deleteFlg: true,
                                      questionCategoryId: row.questionCategoryId,
                                    },
                                  ]);
                                }
                                setDeleteModal(true);
                              }}
                              data-html
                            >
                              <FontAwesomeIcon
                                data-testid="deleteButton"
                                className="text-secondary"
                                icon={faTimesCircle}
                              />
                            </Button>
                          </td>
                        </tr>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </tbody>
          );
        }}
      </Droppable>
    </>
  );

  // アンケート一覧画面の
  // 【事前】
  // 【事後 その他】
  // 【会員属性】
  // 【アンケートモニター】タブからの場合のTableContents
  const tableContents2 = (
    <>
      <thead>
        <tr>
          <th> </th>
          <th>設問番号</th>
          <th>設問ID</th>
          {(enqueteType === ENQUETE_TYPE.OTHER || enqueteType === ENQUETE_TYPE.ENQUETE_MONITOR) && <th>レベル</th>}
          <th>設問</th>
        </tr>
      </thead>
      <Droppable droppableId="table-body">
        {(provided) => {
          return (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {data?.enqueteQuestionsList.map((row, index: number) => {
                return (
                  <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                    {(provided2) => {
                      return (
                        <tr
                          className="align-middle"
                          key={index.toString()}
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                        >
                          <td className="text-center" style={{ width: '5%' }}>
                            <Button variant="link" className=" text-secondary" data-html {...provided2.dragHandleProps}>
                              <FontAwesomeIcon
                                data-testid="linesButton"
                                className="text-secondary"
                                icon={faGripLines}
                              />
                            </Button>
                          </td>
                          <td className="text-center" style={{ width: '5%' }}>
                            {(index + 1).toString()}
                          </td>
                          <td className="text-center" style={{ width: '5%' }}>
                            {row.questionId}
                          </td>
                          {(enqueteType === ENQUETE_TYPE.OTHER || enqueteType === ENQUETE_TYPE.ENQUETE_MONITOR) && (
                            <td className="align-bottom text-center" style={{ width: '5%' }}>
                              <Form.Control type="text" value={row.level} disabled />
                            </td>
                          )}
                          <td className="align-middle" style={{ width: '75%' }}>
                            {when(
                              enqueteType === ENQUETE_TYPE.OTHER || enqueteType === ENQUETE_TYPE.ENQUETE_MONITOR,
                              <OverlayTrigger
                                trigger={['hover', 'hover']}
                                placement="left"
                                overlay={questionHelperText}
                              >
                                <Button
                                  variant="link"
                                  className=" text-secondary"
                                  data-html
                                  onClick={() => {
                                    setJumpQuestionModalFlg(true);
                                    setSelectedQuestionId(row.questionId);
                                    setSelectedQuestionIdx(index);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    data-testid="arrowButton"
                                    className="text-secondary"
                                    icon={faReplyAll}
                                    style={{ transform: 'scaleX(-1)' }}
                                  />
                                </Button>
                              </OverlayTrigger>
                            )}

                            {when(
                              row.answerMethod === 1,
                              <OverlayTrigger trigger={['hover', 'hover']} placement="right" overlay={mailHelperText}>
                                <Button
                                  variant="link"
                                  className=" text-secondary"
                                  data-html
                                  onClick={() => {
                                    setReportMailModalFlg(true);
                                    setSelectedQuestionId(row.questionId);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    data-testid="mailButton"
                                    className="text-secondary"
                                    icon={faEnvelope}
                                  />
                                </Button>
                              </OverlayTrigger>
                            )}

                            {overLayButton(row)}
                          </td>
                          <td align="center">
                            <Button
                              variant="link"
                              className="text-secondary"
                              onClick={() => {
                                setSelectedQuestionId(row.questionId);
                                // 追加したもの以外を削除した際は控えておく
                                if (row.enqueteQuestionsId !== 0) {
                                  setDeleteQuestionList([
                                    ...deleteQuestionList,
                                    {
                                      id: row.enqueteQuestionsId,
                                      questionId: row.questionId,
                                      mailContent: row.mailContent,
                                      deleteFlg: true,
                                      questionCategoryId: row.questionCategoryId,
                                    },
                                  ]);
                                }
                                setDeleteModal(true);
                              }}
                              data-html
                            >
                              <FontAwesomeIcon
                                data-testid="deleteButton"
                                className="text-secondary"
                                icon={faTimesCircle}
                              />
                            </Button>
                          </td>
                          <td> </td>
                        </tr>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </tbody>
          );
        }}
      </Droppable>
    </>
  );

  // アンケート一覧画面の
  // 【事後 選択式】タブからの場合のTableContents
  const tableContents3 = (
    <>
      <thead>
        <tr>
          <th> </th>
          <th>設問番号</th>
          <th>設問ID</th>
          <th>カテゴリ</th>
          <th>レベル</th>
          <th>設問</th>
          <th> </th>
        </tr>
      </thead>
      <Droppable droppableId="table-body">
        {(provided) => {
          return (
            <tbody style={{ width: '100%' }} ref={provided.innerRef} {...provided.droppableProps}>
              {data?.enqueteQuestionsList.map((row, index: number) => {
                return (
                  <Draggable key={index.toString()} draggableId={index.toString()} index={index}>
                    {(provided2) => {
                      return (
                        <tr
                          className="align-middle"
                          key={index.toString()}
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                        >
                          <td className="text-center" style={{ width: '5%' }}>
                            <Button variant="link" className=" text-secondary" data-html {...provided2.dragHandleProps}>
                              <FontAwesomeIcon
                                data-testid="linesButton"
                                className="text-secondary"
                                icon={faGripLines}
                              />
                            </Button>
                          </td>
                          <td className="text-center" style={{ width: '5%' }}>
                            {(index + 1).toString()}
                          </td>
                          <td className="text-center" style={{ width: '5%' }}>
                            {row.questionId}
                          </td>
                          <td className="align-middle" style={{ width: '15%' }}>
                            <OverlayTrigger trigger={['hover', 'hover']} placement="left" overlay={questionHelperText}>
                              <Button
                                variant="link"
                                className=" text-secondary"
                                data-html
                                onClick={() => {
                                  setJumpQuestionModalFlg(true);
                                  setSelectedQuestionId(row.questionId);
                                  setSelectedQuestionIdx(index);
                                }}
                              >
                                <FontAwesomeIcon
                                  data-testid="arrowButton"
                                  className="text-secondary"
                                  icon={faReplyAll}
                                  style={{ transform: 'scaleX(-1)' }}
                                />
                              </Button>
                            </OverlayTrigger>
                            {when(
                              row.answerMethod === 1,
                              <OverlayTrigger trigger={['hover', 'hover']} placement="right" overlay={mailHelperText}>
                                <Button
                                  variant="link"
                                  className=" text-secondary"
                                  data-html
                                  onClick={() => {
                                    setReportMailModalFlg(true);
                                    setSelectedQuestionId(row.questionId);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    data-testid="mailButton"
                                    className="text-secondary"
                                    icon={faEnvelope}
                                  />
                                </Button>
                              </OverlayTrigger>
                            )}
                            <Form.Control disabled type="text" value={row.questionCategoryName || ''} />
                          </td>
                          <td className="align-bottom text-center" style={{ width: '5%' }}>
                            <Form.Control type="text" value={row.level} disabled />
                          </td>
                          <td className="align-bottom" style={{ width: '60%' }}>
                            {overLayButton(row)}
                          </td>
                          <td align="center">
                            <Button
                              variant="link"
                              className="text-secondary"
                              onClick={() => {
                                setSelectedQuestionId(row.questionId);
                                // 追加したもの以外を削除した際は控えておく
                                if (row.enqueteQuestionsId !== 0) {
                                  setDeleteQuestionList([
                                    ...deleteQuestionList,
                                    {
                                      id: row.enqueteQuestionsId,
                                      questionId: row.questionId,
                                      mailContent: row.mailContent,
                                      deleteFlg: true,
                                      questionCategoryId: row.questionCategoryId,
                                    },
                                  ]);
                                }
                                setDeleteModal(true);
                              }}
                              data-html
                            >
                              <FontAwesomeIcon
                                data-testid="deleteButton"
                                className="text-secondary"
                                icon={faTimesCircle}
                              />
                            </Button>
                          </td>
                        </tr>
                      );
                    }}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </tbody>
          );
        }}
      </Droppable>
    </>
  );

  return (
    <>
      <div className="d-flex justify-content-end ">
        <Button
          variant="link"
          className="text-secondary"
          onClick={() => {
            if (data) {
              setQuestionListModalFlg(true);
            }
          }}
          data-html
        >
          <FontAwesomeIcon data-testid="add" className="text-secondary me-1" icon={faPlus} />
          追加
        </Button>
      </div>
      <DragDropContext onDragEnd={handleOnDragEndItem}>
        <Table>
          {enqueteType === ENQUETE_TYPE.BEFOREHAND && tableContents2}
          {enqueteType === ENQUETE_TYPE.SELECT && tableContents3}
          {enqueteType === ENQUETE_TYPE.PILEUP && tableContents1}
          {enqueteType === ENQUETE_TYPE.OTHER && tableContents2}
          {enqueteType === ENQUETE_TYPE.MEMBERSHIP && tableContents2}
          {enqueteType === ENQUETE_TYPE.ENQUETE_MONITOR && tableContents2}
        </Table>
      </DragDropContext>
    </>
  );
};
