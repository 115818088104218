import React, { useCallback, useEffect } from 'react';
import { Alert, Card, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
// eslint-disable-next-line import/no-cycle
import { ConfirmAutoCorrectRuleNgOutputResponse, DataInputSubmitFormResponse, ShoppingApi } from '../../../api-client';
import type {
  DataInputWCheckApproveConfirmOutputResponse,
  DataInputWCheckApproveFormResponse,
  ErrorObjectResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { uuid, when } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import { TinyHead } from '../../atoms/TinyHead';
import { Tag } from '../../atoms/Tag';
import { HTTPStatusCode } from '../../../constants/HTTPStatusCode';
import { ErrorMessagesForInput } from './CorrectDataInputPage';
import { convertApplyIdToLink, hasIncludeApplyIdText } from '../../organisms/Correction/DataInput/ConvertApplyInfoLink';

interface Props {
  monitorId: number;
  confirmOutput: DataInputWCheckApproveConfirmOutputResponse;
  approveForm: DataInputWCheckApproveFormResponse;
  onGoBack: () => void;
  onSubmitStart: () => void;
  onSubmitEnd: () => void;
}

interface CorrectDataInputWcheckApproveConfirmState {
  shoppingApi: ShoppingApi;
  confirmOutput: DataInputWCheckApproveConfirmOutputResponse;
  errorMessages: string[];
}

export const CorrectDataInputWcheckApproveConfirmPage: React.FC<Props> = ({
  monitorId,
  confirmOutput,
  approveForm,
  onGoBack,
  onSubmitStart,
  onSubmitEnd,
}) => {
  const { state: $, mergeState } = useLargeState<CorrectDataInputWcheckApproveConfirmState>({
    shoppingApi: new ShoppingApi(),
    confirmOutput: { commonRuleNgList: [], eachMonitorBaseRuleNgList: [] },
    errorMessages: [],
  });
  useEffect(() => {
    mergeState({ confirmOutput });
  }, [confirmOutput, mergeState]);

  const history = useHistory();

  // 実行
  const onApprove = useCallback(
    (body: DataInputWCheckApproveFormResponse) => {
      onSubmitStart();
      $.shoppingApi
        .dataInputWCheckApprove(body)
        .then((res: AxiosResponse<IncResultOutputResponse>) => {
          if (res.data.result) {
            history.push(`${Url.TENSAKU.DATA_INPUT_W_CHECK_DISTRIBUTE}/${monitorId}`);
          } else {
            mergeState({ errorMessages: [`${res.data.errorMessage}`] });
          }
        })
        .catch((e) => {
          if (e.response?.status === HTTPStatusCode.UnprocessableEntity) {
            mergeState({ errorMessages: [e.response.data.errorMessage] });
          }
        })
        .finally(() => {
          onSubmitEnd();
        });
    },
    [$.shoppingApi, history, mergeState, onSubmitEnd, onSubmitStart, monitorId]
  );

  return (
    <>
      <div className="border border-black p-2 m-2 d-flex justify-content-between h-100 sticky-top bg-light">
        <Button variant="link" onClick={() => onGoBack()}>
          &lt; 戻る
        </Button>
        <Button variant="primary" onClick={() => onApprove(approveForm)}>
          承認
        </Button>
      </div>
      {when(
        $.errorMessages.length > 0,
        <div className="text-danger">
          {$.errorMessages.map((text) => (
            <Alert variant="danger">{text}</Alert>
          ))}
        </div>
      )}
      <h4 className="mt-2">以下が自動添削NGになりました。確認してください。</h4>
      <h6 className="mt-2">全案件共通チェック内容の確認</h6>
      {confirmOutput.commonRuleNgList.length > 0 ? (
        <>
          {confirmOutput.commonRuleNgList.map((info, i) => {
            return (
              <Card key={uuid()}>
                {i + 1}. {info.checkTarget}
                <div className="p-2 mx-2 ms-5 me-2 border border-secondary">
                  <TinyHead>NG文言通知</TinyHead>
                  {hasIncludeApplyIdText(info.ngMessage) ? convertApplyIdToLink(info.ngMessage) : <>{info.ngMessage}</>}
                </div>
                <div className="d-flex justify-content-end m-2">
                  <p className="mt-1 ms-2 me-1">却下の可能性</p>
                  <Tag label={info.isRejectPossible ? 'あり' : 'なし'} minWidth="50px" />
                </div>
              </Card>
            );
          })}
        </>
      ) : (
        <p>NG結果はありませんでした</p>
      )}
      <h6 className="mt-2">当案件のチェック内容</h6>
      {/* tag ng ha kotei? */}
      {/* チェーン・ストア設定を確認 history.push */}
      {confirmOutput.eachMonitorBaseRuleNgList.length > 0 ? (
        <>
          {confirmOutput.eachMonitorBaseRuleNgList.map((info, i) => {
            return (
              <Card key={uuid()}>
                <div className="d-flex justify-content-between">
                  <div>
                    {i + 1}. {info.checkTarget}
                  </div>
                  <Button
                    variant="link"
                    onClick={() =>
                      history.push(`${Url.TENSAKU.CORRECT_SETTING_EACH_MONITOR}/${monitorId}?key=autoCorrect`)
                    }
                  >
                    チェーン・ストア設定を確認
                  </Button>
                </div>
                <div className="p-2 mx-2 ms-5 me-2 border border-secondary">
                  <h6>NG文言通知</h6>
                  {hasIncludeApplyIdText(info.ngMessage) ? convertApplyIdToLink(info.ngMessage) : <>{info.ngMessage}</>}
                </div>
                <div className="d-flex justify-content-end m-2">
                  <p className="mt-1 ms-2 me-1">却下の可能性</p>
                  <Tag label={info.isRejectPossible ? 'あり' : 'なし'} minWidth="50px" />
                </div>
              </Card>
            );
          })}
        </>
      ) : (
        <p>NG結果はありませんでした</p>
      )}
    </>
  );
};
