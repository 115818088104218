interface Props {
  url: string;
  anotherTag: boolean;
  title?: string;
}

/**
 * aタグを使う時に気をつけることを吸収
 * Linkという名称だとlintが混乱するみたいなので、名称変更
 */
export const OriginalLink: React.VFC<Props> = ({ url, anotherTag, title }) => {
  if (anotherTag) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {title}
      </a>
    );
  }

  return <a href={url}>{title}</a>;
};
