/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 調査別明細
 * @export
 * @interface BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
 */
export interface BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse {
    /**
     * 金額
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'amount': number;
    /**
     * 店舗明細ID
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'billingMonitorDetailId': number;
    /**
     * 請求(0)/非請求(1)/翌月繰越(2)
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'billingStatus': number;
    /**
     * 利用商品ID
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'billingUseItemId': number;
    /**
     * 品目
     * @type {string}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'billingUseItemName': string;
    /**
     * 削除フラグ。true:論理削除
     * @type {boolean}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'disableFlg': boolean;
    /**
     * 商品並び順
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'itemSortOrder': number;
    /**
     * モニターID
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'monitorId'?: number;
    /**
     * どの単位に発生する商品か
     * @type {string}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'relation': BillingInvoiceTotalingDetailItemMonitorDetailOutputResponseRelationEnum;
    /**
     * 税率
     * @type {number}
     * @memberof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponse
     */
    'taxRate': number;
}

export const BillingInvoiceTotalingDetailItemMonitorDetailOutputResponseRelationEnum = {
    Contract: 'Contract',
    ContractShop: 'ContractShop',
    Monitor: 'Monitor',
    Apply: 'Apply'
} as const;

export type BillingInvoiceTotalingDetailItemMonitorDetailOutputResponseRelationEnum = typeof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponseRelationEnum[keyof typeof BillingInvoiceTotalingDetailItemMonitorDetailOutputResponseRelationEnum];


