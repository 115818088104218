import { useQuery } from '@tanstack/react-query';
import { BillingApi } from '../api-client';
import { CACHE_KEY_BILLING_INC_ACCOUNT } from '../Constants';

export default function useBillingSuggestIncAccount() {
  const billingApi = new BillingApi();

  const result = useQuery({
    queryKey: [CACHE_KEY_BILLING_INC_ACCOUNT],
    queryFn: async () =>
      billingApi.billingSuggestIncAccountList().then((res) => {
        return res;
      }),
  });
  return {
    isSuccess: result.isSuccess,
    data: result.data?.data,
  };
}
