import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import CreatableSelect from 'react-select/creatable';
import { InputNumber } from '../../atoms/InputNumber';
import { useLargeState } from '../../../hooks/useLargeState';
import { formatISODate, uuid, when } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
// eslint-disable-next-line import/no-cycle
import { ShoppingApi, CorrectionTemplateApi } from '../../../api-client';
import type {
  DataInputShopPhoneNumberSearchFormResponse,
  DataInputShopPhoneNumberSearchOutputResponse,
  ReceiptReadResultOutputResponse,
  CommonMasterListOutputResponse,
  CorrectionTemplateListOutputResponse,
  DataInputWCheckApproveConfirmFormResponse,
  DataInputWCheckApproveConfirmOutputResponse,
  DataInputWCheckResubmitFormResponse,
  IncResultOutputResponse,
  DataInputWCheckRejectFormResponse,
  DataInputWCheckEscalationFormResponse,
  ShoppingDisplayControlOutputResponse,
  ErrorObjectResponse,
} from '../../../api-client';
import { Tag } from '../../atoms/Tag';
import { DoubleButton } from '../../molecules/DoubleButton';
import { Url } from '../../../constants/Url';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { NgComment } from '../Correction/DataInput/NgComment';
// eslint-disable-next-line import/no-cycle
import { CorrectResult } from '../Correction/DataInput/CorrectResult';
import { Modal } from '../../molecules/Modal';
import { HTTPStatusCode } from '../../../constants/HTTPStatusCode';
import { MessageForUser } from '../Correction/DataInput/MessageForUser';
import { convertApplyIdToLink, hasIncludeApplyIdText } from '../Correction/DataInput/ConvertApplyInfoLink';

export type State = DataInputWCheckApproveConfirmFormResponse;
export type PrefectureType = CommonMasterListOutputResponse[];
export type ChaneNameType = string[];
export type TaxRateListType = number[];
export interface ErrorMessagesForInput {
  target: string;
  message: string;
}
interface Props {
  setForms: DispatchSetState<DataInputWCheckApproveConfirmFormResponse>;
  formData: State;
  monitorId: number;
  output: ReceiptReadResultOutputResponse[];
  prefectureList: PrefectureType;
  chainNameList: ChaneNameType;
  taxRateList: TaxRateListType;
  controlInfo: ShoppingDisplayControlOutputResponse;
  onConfirm: (data: DataInputWCheckApproveConfirmOutputResponse) => void;
  // onApproveConfirm?: (data: DataInputWCheckApproveConfirmFormResponse) => void;
  onSubmitStart: () => void;
  onSubmitEnd: () => void;
}
interface PageState {
  shoppingApi: ShoppingApi;
  correctionTemplateApi: CorrectionTemplateApi;
  ngTemplateList: CorrectionTemplateListOutputResponse[];
  rejectTemplateList: CorrectionTemplateListOutputResponse[];
  escalationTemplateList: CorrectionTemplateListOutputResponse[];
  ngTemplateMessageList: { ngFlg: boolean; id?: number; ngMessage?: string }[];
  totalNgTemplateMessage: { id?: number; ngMessage?: string };
  output: Array<ReceiptReadResultOutputResponse & { newFlg: boolean }>;
  activeKey: string;
  isAlert: boolean;
  errorMessages: string[];
  isForceModal: boolean;
  submitType: number;
  alsoEnqueteNgFlg: boolean;
  errorMessagesForInput: ErrorMessagesForInput[];
}

export const ACTIVE_KEY = {
  CORRECT_OK: 'correctOk',
  CORRECT_RE_SUBMIT: 'correctReSubmit',
  CORRECT_REJECT: 'correctReject',
  ESCALATION: 'escalation',
};

export const CorrectDataInputWcheckForm: React.FC<Props> = React.memo(
  ({
    setForms,
    formData,
    monitorId,
    output,
    prefectureList,
    chainNameList,
    taxRateList,
    controlInfo,
    onConfirm,
    onSubmitStart,
    onSubmitEnd,
  }) => {
    const history = useHistory();

    const { state: $form, mergeState: mergeForm } = useLargeState<State>(formData);
    useEffect(() => {
      setForms({ ...$form });
    }, [setForms, $form]);

    const { state: $, mergeState } = useLargeState<PageState>({
      shoppingApi: new ShoppingApi(),
      correctionTemplateApi: new CorrectionTemplateApi(),
      ngTemplateList: [],
      escalationTemplateList: [],
      rejectTemplateList: [],
      ngTemplateMessageList: [],
      totalNgTemplateMessage: {},
      output: output.map((obj) => {
        return { ...obj, newFlg: false };
      }),
      activeKey: ACTIVE_KEY.CORRECT_OK,
      isAlert: false,
      errorMessages: [],
      isForceModal: false,
      submitType: 0, // 0:添削OK 1:添削NG 2:却下
      alsoEnqueteNgFlg: false,
      errorMessagesForInput: [],
    });

    const purchaseDoubleButtonOption = {
      yes: '購入している',
      no: '購入していない',
    };

    useEffect(() => {
      const CORRECTION_TYPE = 3;
      const NG_OPERATION_TYPE = 0;
      const REJECT_OPERATION_TYPE = 5;
      const ESCALATION_OPERATION_TYPE = 3;
      Promise.all(
        [
          { correctionType: CORRECTION_TYPE, operationTypeGroup: NG_OPERATION_TYPE },
          { correctionType: CORRECTION_TYPE, operationTypeGroup: REJECT_OPERATION_TYPE },
          { correctionType: CORRECTION_TYPE, operationTypeGroup: ESCALATION_OPERATION_TYPE },
        ].map(
          (form): Promise<CorrectionTemplateListOutputResponse[]> =>
            new Promise((resolve) => {
              $.correctionTemplateApi
                .correctionTemplateList(form)
                .then((res: AxiosResponse<CorrectionTemplateListOutputResponse[]>) => resolve(res.data))
                .catch(() => history.push(Url.COMMON_ERROR));
            })
        )
      ).then(([ngTemplateList, rejectTemplateList, escalationTemplateList]) => {
        mergeState({ ngTemplateList, rejectTemplateList, escalationTemplateList });
      });

      const ngTemplateMessageList: { ngFlg: boolean }[] = [];
      formData.receiptReadResultFormList.forEach(() => ngTemplateMessageList.push({ ngFlg: false }));
      mergeState({ ngTemplateMessageList });

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialReceiptForm: State['receiptReadResultFormList'][0] = {
      amountReadableFlg: true,
      taxRateCode: undefined,
      chainNameReadableFlg: true,
      inFocusFlg: undefined,
      prefectureReadableFlg: true,
      purchaseCheckReadableFlg: true,
      purchaseCheckResultFlg: undefined,
      receiptDateTimeReadableFlg: true,
      receiptNumberReadableFlg: undefined,
      shopPhoneNumberReadableFlg: true,
      storeReadableFlg: true,
      visibleAllCornerFlg: undefined,
    };

    const initialOutput: ReceiptReadResultOutputResponse & { newFlg: boolean } = {
      autoCorrectReceiptId: 0,
      chain: { chainNameReadableFlg: true },
      inFocusCheck: { inFocusCheckFlg: true },
      purchaseCheckResult: { purchaseCheckReadableFlg: true, purchaseCheckResultFlg: true },
      receiptAmount: { amountReadableFlg: true },
      receiptDateTime: { receiptDateTimeReadableFlg: true },
      receiptNumber: { receiptNumberReadableFlg: true },
      receiptReadResultPrefecture: { prefectureReadableFlg: true },
      shopPhoneNumber: { shopPhoneNumberReadableFlg: true },
      store: { storeReadableFlg: true },
      visibleAllCorner: { visibleAllCornerFlg: true },
      newFlg: true,
      commonNgList: [],
    };

    /**
     * システムによるルールの確認の結果問題なかったかどうか
     * return true -> OK
     */
    const systemCheckIsOk = (data: DataInputWCheckApproveConfirmOutputResponse) => {
      return data.commonRuleNgList.length === 0 && data.eachMonitorBaseRuleNgList.length === 0;
    };

    /**
     * 入力必須項目が入っているかどうか
     * @param body
     */
    const canSubmit = (body: DataInputWCheckApproveConfirmFormResponse) => {
      mergeState({ errorMessagesForInput: [] });
      let result = true;
      let tmp: ErrorMessagesForInput[] = [];
      body.receiptReadResultFormList.map((v, index) => {
        if (v.inFocusFlg === undefined) {
          tmp = [...tmp, { target: `inFocusFlg_${index}`, message: '必須の項目です。選択してください。' }];
          result = false;
        }
        if (v.visibleAllCornerFlg === undefined) {
          tmp = [...tmp, { target: `visibleAllCornerFlg_${index}`, message: '必須の項目です。選択してください。' }];
          result = false;
        }
        if (v.receiptNumberReadableFlg === undefined) {
          tmp = [
            ...tmp,
            { target: `receiptNumberReadableFlg_${index}`, message: '必須の項目です。選択してください。' },
          ];
          result = false;
        }
      });
      mergeState({
        errorMessagesForInput: [...tmp],
      });
      return result;
    };

    const onApproveConfirm = useCallback(
      (body: DataInputWCheckApproveConfirmFormResponse) => {
        onSubmitStart();
        if (canSubmit(body)) {
          $.shoppingApi
            .dataInputWCheckApproveConfirm(body)
            .then((res: AxiosResponse<DataInputWCheckApproveConfirmOutputResponse>) => {
              if (systemCheckIsOk(res.data)) {
                // ルールの自動チェックの結果問題ない場合は承認を行う
                $.shoppingApi
                  .dataInputWCheckApprove(body)
                  .then((res2: AxiosResponse<IncResultOutputResponse>) => {
                    if (res2.data.result) {
                      history.push(`${Url.TENSAKU.DATA_INPUT_W_CHECK_DISTRIBUTE}/${monitorId}`);
                    } else {
                      mergeState({ errorMessages: [`${res2.data.errorMessage}`] });
                    }
                  })
                  .catch((e) => {
                    if (e.response?.status === HTTPStatusCode.UnprocessableEntity) {
                      mergeState({ errorMessages: [e.response.data.errorMessage] });
                    }
                  })
                  .finally(() => {
                    onSubmitEnd();
                  });
              } else {
                onConfirm(res.data);
              }
            })
            .catch((e) => {
              if (e.response?.status === HTTPStatusCode.UnprocessableEntity) {
                mergeState({ errorMessages: [e.response.data.errorMessage] });
                onSubmitEnd();
                return;
              }
              history.push(Url.COMMON_ERROR);
            })
            .finally(() => {
              onSubmitEnd();
            });
        } else {
          onSubmitEnd();
        }
      },
      [$.shoppingApi, history, mergeState, onConfirm, onSubmitEnd, onSubmitStart]
    );

    const onCorrectNG = useCallback(
      (body: DataInputWCheckResubmitFormResponse, alsoEnqueteNgFlg: boolean) => {
        onSubmitStart();
        $.shoppingApi
          .dataInputWCheckResubmit({
            ...body,
            ngMessage: $.totalNgTemplateMessage.ngMessage,
            ngTemplateCode: $.totalNgTemplateMessage.id,
            alsoEnqueteNgFlg,
          })
          .then((res: AxiosResponse<IncResultOutputResponse>) => {
            if (res.data.result) {
              history.push(`${Url.TENSAKU.DATA_INPUT_W_CHECK_DISTRIBUTE}/${monitorId}`);
            } else {
              mergeState({ errorMessages: [`${res.data.errorMessage}`] });
            }
          })
          .catch((e) => {
            if (e.response?.status === HTTPStatusCode.UnprocessableEntity) {
              mergeState({ errorMessages: [e.response.data.errorMessage] });
            }
          })
          .finally(() => {
            onSubmitEnd();
          });
      },
      [$.shoppingApi, $.totalNgTemplateMessage, history, mergeState, onSubmitEnd, onSubmitStart, monitorId]
    );

    const onCorrectReject = useCallback(
      (body: DataInputWCheckRejectFormResponse) => {
        onSubmitStart();
        $.shoppingApi
          .dataInputWCheckReject({
            ...body,
            generalRejectMessage: $.totalNgTemplateMessage.ngMessage,
            generalRejectTemplateCode: $.totalNgTemplateMessage.id,
          })
          .then((res: AxiosResponse<IncResultOutputResponse>) => {
            if (res.data.result) {
              onSubmitEnd();
              history.push(`${Url.TENSAKU.DATA_INPUT_W_CHECK_DISTRIBUTE}/${monitorId}`);
            } else {
              mergeState({ errorMessages: [`${res.data.errorMessage}`] });
            }
          })
          .catch((e) => {
            if (e.response?.status === HTTPStatusCode.UnprocessableEntity) {
              mergeState({ errorMessages: [e.response.data.errorMessage] });
            }
          })
          .finally(() => {
            onSubmitEnd();
          });
      },
      [$.shoppingApi, $.totalNgTemplateMessage, history, mergeState, onSubmitEnd, onSubmitStart, monitorId]
    );

    const onEscalation = useCallback(
      (body: DataInputWCheckEscalationFormResponse) => {
        onSubmitStart();
        $.shoppingApi
          .dataInputWCheckEscalation({
            ...body,
            generalEscalationMessage: $.totalNgTemplateMessage.ngMessage,
            generalEscalationTemplateCode: $.totalNgTemplateMessage.id,
          })
          .then((res: AxiosResponse<IncResultOutputResponse>) => {
            if (res.data.result) {
              onSubmitEnd();
              history.push(`${Url.TENSAKU.DATA_INPUT_W_CHECK_DISTRIBUTE}/${monitorId}`);
            } else {
              mergeState({ errorMessages: [`${res.data.errorMessage}`] });
            }
          })
          .catch((e) => {
            if (e.response?.status === HTTPStatusCode.UnprocessableEntity) {
              mergeState({ errorMessages: [e.response.data.errorMessage] });
            }
          })
          .finally(() => {
            onSubmitEnd();
          });
      },
      [$.shoppingApi, $.totalNgTemplateMessage, history, mergeState, onSubmitEnd, onSubmitStart, monitorId]
    );

    const renderCommonNgList = (ngList: string[]): JSX.Element => {
      if (ngList.length === 0) {
        return <></>;
      }
      return (
        <>
          <div className="border border-danger p-2 m-2">
            {ngList.map((ng) => {
              return (
                <div key={uuid()}>・ {hasIncludeApplyIdText(ng) ? convertApplyIdToLink(ng) : <span>{ng}</span>}</div>
              );
            })}
          </div>
        </>
      );
    };

    const calculateAmount = (amount: number | undefined, tax: number, i: number) => {
      let taxIncludeAmount = amount;
      let newTotalAmount: number | undefined;

      if (taxIncludeAmount) {
        const taxAmount = Math.floor(tax === 0 ? 0 : (taxIncludeAmount * tax) / 100);
        taxIncludeAmount += taxAmount;
      }

      const newReceiptAmountList = $form.receiptReadResultFormList.map(({ receiptAmountIncludeTax }, idx) =>
        i === idx ? taxIncludeAmount : receiptAmountIncludeTax
      );
      // 全てのレシートフォームの利用金額がundefinedの時、税込み合計金額もundefinedにする
      if (newReceiptAmountList.some((receiptAmount) => receiptAmount !== undefined)) {
        newReceiptAmountList.forEach((receiptAmount) => {
          newTotalAmount = (newTotalAmount || 0) + (receiptAmount || 0);
        });
      }

      return { taxIncludeAmount, newTotalAmount };
    };

    const onChangeReceiptAmount = (receiptAmount: number | undefined, tax: number, i: number) => {
      let amount = {
        taxIncludeAmount: receiptAmount,
        newTotalAmount: $form.totalAmountIncludeTax,
      };
      amount = calculateAmount(receiptAmount, tax, i);
      mergeForm({
        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
          if (i !== idx) return obj;
          return {
            ...obj,
            receiptAmount,
            receiptAmountIncludeTax: amount.taxIncludeAmount,
          };
        }),
        totalAmountIncludeTax: amount.newTotalAmount,
      });
    };

    const onChangeTaxRate = (receiptAmount: number | undefined, tax: number, i: number) => {
      let amount = {
        taxIncludeAmount: receiptAmount,
        newTotalAmount: $form.totalAmountIncludeTax,
      };
      if (receiptAmount) {
        amount = calculateAmount(receiptAmount, tax, i);
      }
      mergeForm({
        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
          if (i !== idx) return obj;
          return {
            ...obj,
            taxRateCode: tax,
            receiptAmountIncludeTax: amount.taxIncludeAmount,
          };
        }),
        totalAmountIncludeTax: amount.newTotalAmount,
      });
    };

    const shopPhoneNumberSearch = (phoneNumber: string, i: number) => {
      const body: DataInputShopPhoneNumberSearchFormResponse = { phoneNumber };

      $.shoppingApi
        .dataInputShopPhoneNumberSearch(body)
        .then((res: AxiosResponse<DataInputShopPhoneNumberSearchOutputResponse>) => {
          const { chainName, prefectureId, storeName } = res.data;
          mergeForm({
            receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
              if (i !== idx) return obj;
              return {
                ...obj,
                chainName: chainName || undefined,
                storeName: storeName || undefined,
                selectPrefectureId: prefectureId || undefined,
              };
            }),
          });
        });
    };

    const onCloseForm = (i: number) => {
      const newArray = $form.receiptReadResultFormList;
      newArray.splice(i, 1);
      let newTotalAmount: number | undefined;
      if (newArray.length > 0 && newArray.some(({ receiptAmountIncludeTax }) => receiptAmountIncludeTax)) {
        newArray.forEach(({ receiptAmountIncludeTax }) => {
          newTotalAmount = (newTotalAmount || 0) + (receiptAmountIncludeTax || 0);
        });
      }
      mergeForm({
        receiptReadResultFormList: newArray,
        totalAmountIncludeTax: newTotalAmount,
      });

      const newNgMessageList = $.ngTemplateMessageList;
      newNgMessageList.splice(i, 1);
      const newOutputList = $.output;
      newOutputList.splice(i, 1);
      mergeState({ ngTemplateMessageList: newNgMessageList, output: newOutputList });
    };

    const renderLastMessage = (i: number, inquiryContents?: string, ngFlg?: boolean) => {
      const wcheckDoubleButtonOption = {
        yes: 'チェックOK',
        no: 'チェックNG',
      };
      return (
        <>
          <div className="mt-2 me-2 d-flex justify-content-end">
            <DoubleButton
              yesFlg={!ngFlg}
              onClickButton={(flg) => {
                const newArray = $.ngTemplateMessageList.map((obj, idx) => {
                  if (i !== idx) return obj;
                  return {
                    ...obj,
                    ngFlg: !flg,
                  };
                });
                mergeState({
                  ngTemplateMessageList: newArray,
                  activeKey: newArray.every(({ ngFlg: ng }) => !ng)
                    ? ACTIVE_KEY.CORRECT_OK
                    : ACTIVE_KEY.CORRECT_RE_SUBMIT,
                });
              }}
              checkFlg
              label={wcheckDoubleButtonOption}
            />
          </div>
          {when(
            !!ngFlg,
            <MessageForUser
              ngTemplateList={$.ngTemplateList}
              onChangeTemplateCode={(id?: number) => {
                const newArray = $.ngTemplateMessageList.map((obj, idx) => {
                  if (i !== idx) return obj;
                  return {
                    ...obj,
                    id,
                  };
                });
                mergeState({ ngTemplateMessageList: newArray });
              }}
              onChangeTemplateText={(text?: string) => {
                const newArray = $.ngTemplateMessageList.map((obj, idx) => {
                  if (i !== idx) return obj;
                  return {
                    ...obj,
                    ngMessage: text,
                  };
                });
                mergeState({ ngTemplateMessageList: newArray });
              }}
              onSave={() =>
                $.ngTemplateMessageList.forEach(({ id, ngMessage: text }, idx) => {
                  if (i !== idx) return;
                  mergeForm({
                    receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, index) => {
                      if (i !== index) return obj;
                      return {
                        ...obj,
                        ngTemplateCode: id,
                        ngMessage: text,
                      };
                    }),
                  });
                })
              }
            />
          )}
        </>
      );
    };

    const onClickApprove = () => {
      if ($.ngTemplateMessageList.some((ngObj) => ngObj.ngFlg)) {
        mergeState({ isAlert: true });
        return;
      }
      confirmForcedApprove();
    };

    const confirmForcedApprove = () => {
      if (controlInfo.isForcedCorrect) {
        mergeState({ isForceModal: true, submitType: 0 });
        return;
      }
      onApproveConfirm($form as DataInputWCheckApproveConfirmFormResponse);
    };

    const confirmForcedNg = (alsoEnqueteNgFlg: boolean) => {
      if (controlInfo.isForcedCorrect) {
        mergeState({ isForceModal: true, submitType: 1, alsoEnqueteNgFlg });
        return;
      }
      onCorrectNG($form as DataInputWCheckResubmitFormResponse, alsoEnqueteNgFlg);
    };

    const confirmForcedReject = () => {
      if (controlInfo.isForcedCorrect) {
        mergeState({ isForceModal: true, submitType: 2 });
        return;
      }
      onCorrectReject($form as DataInputWCheckRejectFormResponse);
    };

    return (
      <>
        <Modal
          isModal={$.isForceModal}
          centered
          closeButton
          onHide={() => mergeState({ isForceModal: false })}
          body={
            <>
              <div>強制添削をしてもよろしいですか？</div>
              <div className="d-flex justify-content-center mt-2">
                <Button
                  className="me-2"
                  onClick={() => {
                    mergeState({ isForceModal: false });
                    if ($.submitType === 0) {
                      onApproveConfirm($form as DataInputWCheckApproveConfirmFormResponse);
                    } else if ($.submitType === 1) {
                      onCorrectNG($form as DataInputWCheckResubmitFormResponse, $.alsoEnqueteNgFlg);
                    } else if ($.submitType === 2) {
                      onCorrectReject($form as DataInputWCheckRejectFormResponse);
                    }
                  }}
                >
                  実行
                </Button>
                <Button onClick={() => mergeState({ isForceModal: false })}>キャンセル</Button>
              </div>
            </>
          }
        />
        <Modal
          isModal={$.isAlert}
          closeButton
          centered
          onHide={() => mergeState({ isAlert: false })}
          body={
            <>
              <div>チェックNGのレシートがありますが、このまま添削OKを実行しますか？</div>
              <div className="d-flex justify-content-center mt-2">
                <Button
                  className="me-2"
                  onClick={() => {
                    mergeState({ isAlert: false });
                    confirmForcedApprove();
                  }}
                >
                  承認
                </Button>
                <Button onClick={() => mergeState({ isAlert: false })}>キャンセル</Button>
              </div>
            </>
          }
        />
        {$form.receiptReadResultFormList.map((form, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={i}>
              {when(
                i > 0,
                <div className="d-flex justify-content-end">
                  <Button variant="link" onClick={() => onCloseForm(i)}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                    {i + 1}枚目のレシート
                  </Button>
                </div>
              )}
              <Card className="p-2">
                {when(
                  !!$.output[i].inputUserQuestion && !!$.output[i].inputUserQuestion?.message,
                  <div className="bg-light m-2 p-2">
                    <h6>データ入力者からの質問</h6>
                    <div className="bg-white m-2 p-2">
                      {$.output[i].inputUserQuestion?.message}
                      <div className="d-flex justify-content-end">{$.output[i].inputUserQuestion?.name}</div>
                    </div>
                  </div>
                )}
                {renderCommonNgList($.output[i].commonNgList)}
                <h6>1.ピントが合っていて文字が全て確認できますか？</h6>
                {$.output[i].newFlg &&
                  $.errorMessagesForInput.filter((v) => v.target === `inFocusFlg_${i}`).length > 0 && (
                    <Alert variant="danger">
                      {$.errorMessagesForInput.filter((v) => v.target === `inFocusFlg_${i}`)[0].message}
                    </Alert>
                  )}
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={$.output[i].inFocusCheck?.inFocusCheckFlg ? 'はい' : 'いいえ'}
                    ngComment={$.output[i].inFocusCheck?.autoCorrectNgComment || ''}
                  />
                )}
                <div className="me-2 d-flex justify-content-end">
                  <DoubleButton
                    yesFlg={form.inFocusFlg}
                    onClickButton={(flg: boolean) => {
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return {
                            ...obj,
                            inFocusFlg: flg,
                          };
                        }),
                      });
                    }}
                    checkFlg
                  />
                </div>
                <h6 className="mt-2">2.折り曲げや切断された跡が無く、四隅が全て確認できますか？</h6>
                {$.output[i].newFlg &&
                  $.errorMessagesForInput.filter((v) => v.target === `visibleAllCornerFlg_${i}`).length > 0 && (
                    <Alert variant="danger">
                      {$.errorMessagesForInput.filter((v) => v.target === `visibleAllCornerFlg_${i}`)[0].message}
                    </Alert>
                  )}
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={$.output[i].visibleAllCorner?.visibleAllCornerFlg ? 'はい' : 'いいえ'}
                    ngComment={$.output[i].visibleAllCorner?.autoCorrectNgComment || ''}
                  />
                )}
                <div className="me-2 d-flex justify-content-end">
                  <DoubleButton
                    yesFlg={form.visibleAllCornerFlg}
                    onClickButton={(flg: boolean) => {
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return {
                            ...obj,
                            visibleAllCornerFlg: flg,
                          };
                        }),
                      });
                    }}
                    checkFlg
                  />
                </div>

                <h6 className="mt-2">3.レシート番号は確認できますか？</h6>
                {$.output[i].newFlg &&
                  $.errorMessagesForInput.filter((v) => v.target === `receiptNumberReadableFlg_${i}`).length > 0 && (
                    <Alert variant="danger">
                      {$.errorMessagesForInput.filter((v) => v.target === `receiptNumberReadableFlg_${i}`)[0].message}
                    </Alert>
                  )}
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={$.output[i].receiptNumber?.receiptNumberReadableFlg ? 'はい' : 'いいえ'}
                    ngComment={$.output[i].receiptNumber?.autoCorrectNgComment || ''}
                  />
                )}
                {when(
                  $.output[i].newFlg,
                  <div className="me-2 d-flex justify-content-end">
                    <DoubleButton
                      yesFlg={form.receiptNumberReadableFlg}
                      onClickButton={(flg: boolean) =>
                        mergeForm({
                          receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                            if (i !== idx) return obj;
                            return { ...obj, receiptNumberReadableFlg: flg };
                          }),
                        })
                      }
                      checkFlg
                    />
                  </div>
                )}

                <h6 className="mt-2">4.電話番号（ハイフンなし）を入力して下さい</h6>
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={$.output[i].shopPhoneNumber?.phoneNumber || ''}
                    ngComment={$.output[i].shopPhoneNumber?.autoCorrectNgComment || ''}
                  />
                )}
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={form.shopPhoneNumber || ''}
                    onChange={(e) => {
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, shopPhoneNumber: e.target.value };
                        }),
                      });
                    }}
                  />
                  <Button
                    className="ms-2"
                    style={{ minWidth: '150px' }}
                    variant="outline-primary"
                    onClick={() => shopPhoneNumberSearch(String(form.shopPhoneNumber), i)}
                  >
                    電話番号から
                    <br />
                    店舗検索
                  </Button>
                </div>
                <h6 className="mt-2">5.チェーン名を入力してください</h6>
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={$.output[i].chain?.chainName || ''}
                    ngComment={$.output[i].chain?.autoCorrectNgComment || ''}
                  />
                )}
                <div className="d-flex">
                  <div style={{ width: '80%' }}>
                    <CreatableSelect
                      isClearable
                      placeholder=""
                      formatCreateLabel={(value) => `${value} を作成`}
                      createOptionPosition="last"
                      value={{ value: form.chainName, label: form.chainName }}
                      options={chainNameList.map((name) => {
                        return { label: name, value: name };
                      })}
                      onChange={(e) =>
                        mergeForm({
                          receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                            if (i !== idx) return obj;
                            return { ...obj, chainName: e?.value };
                          }),
                        })
                      }
                    />
                  </div>
                </div>
                <h6 className="mt-2">6.ストア名を入力してください</h6>
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={$.output[i].store?.storeName || ''}
                    ngComment={$.output[i].store?.autoCorrectNgComment || ''}
                  />
                )}
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    value={form.storeName || ''}
                    onChange={(e) => {
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, storeName: e.target.value };
                        }),
                      });
                    }}
                  />
                  <div className="mt-1 ms-1">店</div>
                </div>
                <h6 className="mt-2">7.都道府県を入力してください</h6>
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={
                      prefectureList.filter(
                        ({ id }) => id === $.output[i].receiptReadResultPrefecture?.selectPrefectureId
                      ).length > 0
                        ? prefectureList.filter(
                            ({ id }) => id === $.output[i].receiptReadResultPrefecture?.selectPrefectureId
                          )[0].name
                        : ''
                    }
                    ngComment={$.output[i].receiptReadResultPrefecture?.autoCorrectNgComment || ''}
                  />
                )}
                <div className="d-flex">
                  <Form.Select
                    value={form.selectPrefectureId ?? ''}
                    onChange={(e) => {
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return {
                            ...obj,
                            selectPrefectureId: e.target.value === '' ? undefined : Number(e.target.value),
                          };
                        }),
                      });
                    }}
                  >
                    <option value="" key={uuid()}>
                      都道府県を選択してください
                    </option>
                    {prefectureList.map(({ id, name }) => {
                      return (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
                <h6 className="mt-2">8.レシート日時を入力してください</h6>
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={
                      $.output[i].receiptDateTime?.receiptDateTimeReadableFlg && $.output[i].receiptDateTime?.dateTime
                        ? `${formatISODate($.output[i].receiptDateTime?.dateTime || '', DATETIME_DISPLAY_FORMAT)}`
                        : '入力できない'
                    }
                    ngComment={$.output[i].receiptDateTime?.autoCorrectNgComment || ''}
                  />
                )}
                <div className="d-flex">
                  <Form.Control
                    type="datetime-local"
                    min="1900-01-01T00:00:00"
                    max="2100-12-31T23:59:59"
                    autoComplete="off"
                    value={form.receiptDateTime || ''}
                    onChange={(e) =>
                      mergeForm({
                        receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                          if (i !== idx) return obj;
                          return { ...obj, receiptDateTime: e.target.value };
                        }),
                      })
                    }
                  />
                </div>
                <h6 className="mt-2">9.対象商品を購入していますか</h6>
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={$.output[i].purchaseCheckResult?.purchaseCheckResultFlg ? 'はい' : 'いいえ'}
                    ngComment={$.output[i].purchaseCheckResult?.autoCorrectNgComment || ''}
                  />
                )}
                <div className="me-2 d-flex justify-content-between">
                  <div>
                    <DoubleButton
                      yesFlg={form.purchaseCheckResultFlg}
                      onClickButton={(flg: boolean) =>
                        mergeForm({
                          receiptReadResultFormList: $form.receiptReadResultFormList.map((obj, idx) => {
                            if (i !== idx) return obj;
                            return { ...obj, purchaseCheckResultFlg: flg };
                          }),
                        })
                      }
                      label={purchaseDoubleButtonOption}
                      checkFlg
                    />
                  </div>
                </div>

                <h6 className="mt-2">10.利用金額を入力してください</h6>
                {when(
                  !$.output[i].newFlg,
                  <NgComment
                    label={
                      $.output[i].receiptAmount?.amount !== undefined ? `${$.output[i].receiptAmount?.amount}円` : ''
                    }
                    ngComment={$.output[i].receiptAmount?.autoCorrectNgComment || ''}
                  />
                )}
                <div className="d-flex">
                  <InputNumber
                    value={form.receiptAmount !== undefined ? form.receiptAmount : undefined}
                    onChange={(e) =>
                      onChangeReceiptAmount(
                        e.target.value === '' ? undefined : Number(e.target.value),
                        form.taxRateCode || 0,
                        i
                      )
                    }
                  />
                  <div className="mt-1 ms-1">円</div>
                </div>
                <h6 className="mt-2">11.税率を選択してください</h6>
                {when(
                  !$.output[i].newFlg,
                  <div className="my-2">
                    <Tag
                      label={
                        $.output[i].selectedTaxRateCode === 0 ? '税込み' : `税抜き${$.output[i].selectedTaxRateCode}`
                      }
                    />
                  </div>
                )}
                {taxRateList && taxRateList.length > 1 && taxRateList ? (
                  taxRateList.map((tax) => {
                    return (
                      <Form.Check
                        type="radio"
                        key={tax}
                        id={`radio-${i}-${tax}`}
                        label={tax === 0 ? '税込' : `税抜き${tax}％`}
                        onChange={() => onChangeTaxRate(form.receiptAmount, tax, i)}
                        checked={tax === form.taxRateCode}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
                <hr className="mx-2" />
                <div className="d-flex justify-content-end">
                  <h6>対象商品の税込み合計金額 {form.receiptAmountIncludeTax} 円</h6>
                </div>
                {renderLastMessage(
                  i,
                  form.inquiryContents || '',
                  $.ngTemplateMessageList[i] ? $.ngTemplateMessageList[i].ngFlg : false
                )}
              </Card>
            </React.Fragment>
          );
        })}
        <div className="d-flex justify-content-end">
          <Button
            variant="link"
            onClick={() => {
              const newArray = $form.receiptReadResultFormList;
              newArray.push(initialReceiptForm);
              mergeForm({
                receiptReadResultFormList: newArray,
              });

              const newNgMessageList = $.ngTemplateMessageList;
              newNgMessageList.push({ ngFlg: false });
              const newOutputList = $.output;
              newOutputList.push(initialOutput);
              mergeState({
                ngTemplateMessageList: newNgMessageList,
                output: newOutputList,
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            フォームを追加
          </Button>
        </div>
        {when(
          controlInfo.isDataInputEscalationAnswerPossible,
          <>
            {when(
              $.errorMessages.length > 0,
              <div className="text-danger">
                {$.errorMessages.map((text) => (
                  <Alert variant="danger" style={{ whiteSpace: 'pre-wrap' }}>
                    {text}
                  </Alert>
                ))}
              </div>
            )}
            <h6>添削結果入力</h6>
            <CorrectResult
              totalAmount={formData.totalAmountIncludeTax}
              activeKey={$.activeKey}
              onSelect={(key) => mergeState({ activeKey: key || ACTIVE_KEY.CORRECT_OK })}
              onApproveConfirm={() => onClickApprove()}
              onCorrectNG={(alsoEnqueteNgFlg: boolean) => confirmForcedNg(alsoEnqueteNgFlg)}
              onCorrectReject={() => confirmForcedReject()}
              onEscalation={() => onEscalation($form)}
              ngTemplateList={$.ngTemplateList}
              rejectTemplateList={$.rejectTemplateList}
              escalationTemplateList={$.escalationTemplateList}
              ngTemplateMessageList={$.ngTemplateMessageList}
              onChangeTemplateCode={(id) => mergeState({ totalNgTemplateMessage: { ...$.totalNgTemplateMessage, id } })}
              onChangeTemplateText={(ngMessage) =>
                mergeState({ totalNgTemplateMessage: { ...$.totalNgTemplateMessage, ngMessage } })
              }
            />
          </>
        )}
      </>
    );
  }
);
