import React from 'react';
import { Link } from 'react-router-dom';
import { Url } from '../../constants/Url';

export const OemTopPage: React.VFC = () => {
  return (
    <>
      <Link to={Url.OEM.EVENT_MESSAGE} className="d-block">
        イベントメッセージ一覧
      </Link>
      <Link to={Url.OEM.MONITOR_LIST} className="d-block">
        モニター一覧
      </Link>
      <Link to={Url.OEM.APPLY_INFO_LIST} className="d-block">
        会員・応募一覧
      </Link>
    </>
  );
};
