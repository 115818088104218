/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncOemScodeCreateOutputResponse } from '../domains';
/**
 * OemScodeCreateApi - axios parameter creator
 * @export
 */
export const OemScodeCreateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 会員・応募情報を照会する
         * @summary 会員・応募情報照会
         * @param {string} version 
         * @param {number} customerId 
         * @param {number} [apiClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incOemScodeCreate: async (version: string, customerId: number, apiClientId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('incOemScodeCreate', 'version', version)
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('incOemScodeCreate', 'customerId', customerId)
            const localVarPath = `/api/inc-oem-scode-create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (apiClientId !== undefined) {
                localVarQueryParameter['api-client-id'] = apiClientId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customer-id'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OemScodeCreateApi - functional programming interface
 * @export
 */
export const OemScodeCreateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OemScodeCreateApiAxiosParamCreator(configuration)
    return {
        /**
         * 会員・応募情報を照会する
         * @summary 会員・応募情報照会
         * @param {string} version 
         * @param {number} customerId 
         * @param {number} [apiClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incOemScodeCreate(version: string, customerId: number, apiClientId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncOemScodeCreateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.incOemScodeCreate(version, customerId, apiClientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OemScodeCreateApi - factory interface
 * @export
 */
export const OemScodeCreateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OemScodeCreateApiFp(configuration)
    return {
        /**
         * 会員・応募情報を照会する
         * @summary 会員・応募情報照会
         * @param {string} version 
         * @param {number} customerId 
         * @param {number} [apiClientId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incOemScodeCreate(version: string, customerId: number, apiClientId?: number, options?: any): AxiosPromise<IncOemScodeCreateOutputResponse> {
            return localVarFp.incOemScodeCreate(version, customerId, apiClientId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OemScodeCreateApi - object-oriented interface
 * @export
 * @class OemScodeCreateApi
 * @extends {BaseAPI}
 */
export class OemScodeCreateApi extends BaseAPI {
    /**
     * 会員・応募情報を照会する
     * @summary 会員・応募情報照会
     * @param {string} version 
     * @param {number} customerId 
     * @param {number} [apiClientId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OemScodeCreateApi
     */
    public incOemScodeCreate(version: string, customerId: number, apiClientId?: number, options?: AxiosRequestConfig) {
        return OemScodeCreateApiFp(this.configuration).incOemScodeCreate(version, customerId, apiClientId, options).then((request) => request(this.axios, this.basePath));
    }
}
