import React, { useCallback, useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, debugLog } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
// eslint-disable-next-line import/no-cycle
import { ApplySearchApi, ExecutiveStaffApplySearchOutputResponse, PageableResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { ExecutiveStaffApplySearchForms } from '../../organisms/Forms/ExecutiveStaffApplySearchForms';
import { PaginationTable } from '../../organisms/Table/PaginationTable';
import { Title } from '../../atoms/Title';
import { getSurveyProofCorrectPageUrl } from './utils';

interface FormsState {
  searchType: string;
  searchValue: number | undefined | string;
  targetStatus: number;
}
const initialForms: FormsState = {
  searchType: 'applyId',
  searchValue: undefined,
  targetStatus: 0,
};

export type State = {
  api: ApplySearchApi;
  isLoading: boolean;
  forms: FormsState;
  list: ExecutiveStaffApplySearchOutputResponse[];
};

export const ExecutiveStaffApplySearchPage: React.FC = () => {
  const testid = createTestId(ExecutiveStaffApplySearchPage);
  const MAX_RECORD_PER_PAGE = useMemo(() => 20, []);

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    api: new ApplySearchApi(),
    isLoading: false,
    forms: initialForms,
    list: [],
  });

  const history = useHistory();

  const onSearch = useCallback(() => {
    debugLog($.forms);
    const searchType = String($.forms.searchType);
    const searchValue =
      $.forms.searchValue === undefined || $.forms.searchValue === '' ? undefined : Number($.forms.searchValue);
    const targetStatus = Number($.forms.targetStatus);
    const applyId = searchType === 'applyId' ? searchValue : undefined;
    const contractShopId = searchType === 'contractShopId' ? searchValue : undefined;
    const clientId = searchType === 'clientId' ? searchValue : undefined;
    const pageable: PageableResponse = { page: 0, size: 20 };
    mergeState({ isLoading: true });
    $.api
      .executiveStaffApplySearch(targetStatus, pageable, applyId, contractShopId, clientId)
      .then((res) => {
        mergeState({ list: res.data, isLoading: false });
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [$.forms, $.api, mergeState, history]);

  const setForms = useBindSet('forms');

  return (
    <LoadingSpinner isLoading={$.isLoading}>
      <Title>事務局案件検索</Title>
      <Form>
        <Row className="mb-3">
          <ExecutiveStaffApplySearchForms setForms={setForms} />
          <Col>
            <Button data-testid={testid('search-button')} onClick={() => onSearch()}>
              検索
            </Button>
          </Col>
        </Row>
      </Form>
      <hr />
      {$.list.length > 0 && <div className="d-flex justify-content-end">検索結果が {$.list.length} 件あります。</div>}
      {$.list.length !== 0 ? (
        <PaginationTable
          pageNationClassName="d-flex justify-content-center"
          data-testid={testid('table')}
          resultNum={MAX_RECORD_PER_PAGE}
          list={$.list}
          headerNames={[
            '応募ID/当選ステータス',
            'クライアントID/クライアント名',
            '店舗ID/店舗・モニター名',
            '調査証明',
            'アンケート',
          ]}
        >
          {(list) => {
            return (list as ExecutiveStaffApplySearchOutputResponse[]).map((apply, i) => {
              return (
                <tr className="align-middle" key={apply.applyId}>
                  <td data-testid={testid('applyInfo', i)}>
                    <span>{apply.applyId}</span>
                    <br />
                    <span>{apply.applyStatusName}</span>
                  </td>
                  <td data-testid={testid('clientInfo', i)}>
                    <span>{apply.clientId}</span>
                    <br />
                    <span>{apply.clientName}</span>
                  </td>
                  <td data-testid={testid('contractShopInfo', i)}>
                    <span>{apply.contractShopId}</span>
                    <br />
                    <span>{`${apply.contractShopName}・${apply.monitorBaseName}`}</span>
                  </td>
                  <td>
                    <Button
                      variant="link"
                      data-testid={testid('surveyProofCorrect-button', i)}
                      onClick={() => {
                        history.push(getSurveyProofCorrectPageUrl(apply.surveyProofCorrectType, apply.applyId));
                      }}
                    >
                      {apply.surveyProofStatusName}
                    </Button>
                  </td>
                  <td>
                    <Button
                      variant="link"
                      data-testid={testid('enqueteCorrect-button', i)}
                      onClick={() => {
                        history.push(`${Url.TENSAKU.CORRECT_ENQUETE}/${apply.applyId}`);
                      }}
                    >
                      {apply.enqueteStatusName}
                    </Button>
                  </td>
                </tr>
              );
            });
          }}
        </PaginationTable>
      ) : (
        <></>
      )}
    </LoadingSpinner>
  );
};
