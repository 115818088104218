import React, { useContext, useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { PointGrantContext } from '../../../../store/pointPageStore';
import { createTestId, execDownload, when } from '../../../../utils/functions';
import { Button } from '../../../atoms/Button';
import { Dropzone } from '../../../molecules/Dropzone';

interface Props {
  onConfirm: () => void;
}

export const PointGrantBulkRegisterPage: React.FC<Props> = ({ onConfirm }) => {
  const testid = createTestId(PointGrantBulkRegisterPage);
  const context = useContext(PointGrantContext);
  const { state: $, mergeState } = context;
  const errorAlert: { head: string; body: string } = useMemo(() => {
    const mes = $.fileError.split('\n');
    return {
      head: mes[0],
      body: mes.slice(1).join('\n'),
    };
  }, [$.fileError]);

  const templateDownload = () => {
    $.api.grantPointFileTemplateDlController().then((res: AxiosResponse<string>) => {
      execDownload(
        res.data,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '【テンプレート】一括ポイント付与.xlsx'
      );
    });
  };

  return when(
    !!context,
    <>
      <Button onClick={() => templateDownload()}>テンプレート</Button>
      {$.file === undefined ? (
        <Dropzone
          onDrop={(files) => {
            const file = files[0];
            mergeState({ file });
          }}
          type="excel"
          maxFiles={1}
        />
      ) : (
        <div style={{ display: 'flex' }}>
          <div data-testid={testid('filename')}>{$.file?.name}</div>
          <Button
            data-testid={testid('delete-button')}
            variant="danger"
            onClick={() => mergeState({ file: undefined, fileError: '' })}
          >
            削除
          </Button>
        </div>
      )}
      <hr />
      <Button data-testid={testid('confirm-button')} disabled={!$.file} onClick={onConfirm}>
        確認
      </Button>
    </>
  );
};
