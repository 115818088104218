import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { useLargeState } from '../../hooks/useLargeState';
import { Button } from '../atoms/Button';
import { LoadingSpinner } from '../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { GiftTicketApi, GiftTicketInfoOutputResponse } from '../../api-client';
import type {
  GiftTicketListDataOutputResponse,
  GiftTicketListFormResponse,
  GiftTicketListOutputResponse,
  GiftTicketUpdateFormResponse,
  GiftUseStateListOutputResponse,
  IncResultOutputResponse,
} from '../../api-client';
import { GiftTicketEditModal } from '../organisms/Modal/GiftTicketEditModal';
import { GiftTicketForms } from '../organisms/Forms/GiftTicketForms';
import { GiftTicketListTable } from '../organisms/Table/GiftTicketListTable';
import { createTestId, when } from '../../utils/functions';
import { CustomerInfoHeader } from './Customer/CustomerInfoHeader';
import { Title } from '../atoms/Title';

export interface GiftTicketPageState {
  api: GiftTicketApi;
  isLoading: boolean;
  isModal: boolean;
  list: GiftTicketListDataOutputResponse[];
  statusList: GiftUseStateListOutputResponse[];
  forms: GiftTicketListFormResponse;
  editForms: Partial<GiftTicketListDataOutputResponse>;
  oldSystemUrl?: string;
}
export type TestIds = 'search-button';

export const GiftTicketPage: React.FC = () => {
  const testid = createTestId<TestIds>(GiftTicketPage);
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<GiftTicketPageState>({
    api: new GiftTicketApi(),
    isLoading: false,
    isModal: false,
    list: [],
    statusList: [],
    forms: {},
    editForms: {},
  });

  const { id: customerId } = useParams<{ id: string | undefined }>();
  const setModal = useBindSet('isModal');
  const setForms = useBindSet('forms');
  const setEditForms = useBindSet('editForms');

  // enum取得
  useEffect(() => {
    mergeState({ isLoading: true });
    $.api.giftTicketUseStateList().then((res: AxiosResponse<GiftUseStateListOutputResponse[]>) => {
      mergeState({
        isLoading: false,
        statusList: res.data,
      });
    });

    if (customerId !== undefined) onSearch({ customerId: Number(customerId) });
  }, [$.api, mergeState]);

  // 送信情報
  const submitData: GiftTicketUpdateFormResponse | undefined = useMemo(() => {
    const {
      expirationDate,
      giftTicketId,
      internalComment,
      messageForCustomer,
      usedShopId: shopId,
      giftUseState,
      usedDate,
    } = $.editForms;
    if (![expirationDate, giftTicketId, internalComment, shopId].every(Boolean)) return undefined;
    try {
      return {
        expirationDate: parseISO(expirationDate as string).toISOString(),
        giftTicketId: giftTicketId as number,
        internalComment: internalComment as string,
        messageForCustomer: messageForCustomer as string,
        shopId: shopId as number,
        giftUseState: giftUseState as number,
        usedDate: usedDate ? parseISO(usedDate).toISOString() : undefined,
      } as GiftTicketUpdateFormResponse;
    } catch {
      return undefined;
    }
  }, [$.editForms]);

  const createSearchBody = useCallback((body: GiftTicketListFormResponse) => {
    return {
      applyId: body.applyId ? body.applyId : undefined,
      clientId: body.clientId ? body.clientId : undefined,
      createAtEnd: body.createAtEnd ? body.createAtEnd : undefined,
      createAtStart: body.createAtStart ? body.createAtStart : undefined,
      customerId: body.customerId ? body.customerId : undefined,
      expirationDateEnd: body.expirationDateEnd ? body.expirationDateEnd : undefined,
      expirationDateStart: body.expirationDateStart ? body.expirationDateStart : undefined,
      giftTicketId: body.giftTicketId ? body.giftTicketId : undefined,
      shopId: body.shopId ? body.shopId : undefined,
      usedDateEnd: body.usedDateEnd ? body.usedDateEnd : undefined,
      usedDateStart: body.usedDateStart ? body.usedDateStart : undefined,
    };
  }, []);

  // 検索
  const onSearch = useCallback(
    (body: GiftTicketListFormResponse) => {
      mergeState({ isLoading: true });
      const searchBody = createSearchBody(body);
      $.api.giftTicketList(searchBody).then((res: AxiosResponse<GiftTicketListOutputResponse>) => {
        mergeState({ list: res.data.list, isLoading: false, oldSystemUrl: res.data.oldSystemUrl });
      });
    },
    [$.api, createSearchBody, mergeState]
  );

  // 編集
  const onEdit = useCallback(
    (id: number) => {
      mergeState({ isLoading: true });
      $.api.giftTicketDetail(id).then((res: AxiosResponse<GiftTicketInfoOutputResponse>) => {
        mergeState({ editForms: res.data, isModal: true, isLoading: false });
      });
    },
    [$.api, mergeState]
  );

  // 送信
  const onSubmit = useCallback(() => {
    if (!submitData) throw new Error('無効なフォーム');
    mergeState({ isLoading: true });
    $.api.giftTicketUpdate(submitData).then((res: AxiosResponse<IncResultOutputResponse>) => {
      if (!res.data.result) throw new Error(res.data.errorMessage);
      window.location.reload();
    });
  }, [$.api, mergeState, submitData]);

  return (
    <>
      {customerId ? (
        <>
          <CustomerInfoHeader activeKey="giftTicketList" customerId={Number(customerId)} />
          {when(
            !!$.oldSystemUrl,
            <a href={`${$.oldSystemUrl}`} target="_blank" rel="noreferrer" style={{ marginRight: '10px' }}>
              旧管理のギフト券一覧
            </a>
          )}
        </>
      ) : (
        <Title>ギフト券一覧</Title>
      )}
      <GiftTicketEditModal
        isModal={$.isModal}
        setModal={setModal}
        forms={$.editForms}
        setForms={setEditForms}
        statusList={$.statusList}
        disableSubmit={!submitData}
        onSubmit={onSubmit}
      />
      <Form>
        <Row className="g-2 mb-4">
          <Col>
            <GiftTicketForms setForms={setForms} />
            <Button
              data-testid={testid('search-button')}
              onClick={() => {
                onSearch($.forms);
              }}
            >
              検索
            </Button>
          </Col>
        </Row>
      </Form>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.list.length ? <GiftTicketListTable list={$.list} onEdit={onEdit} /> : undefined}
      </LoadingSpinner>
    </>
  );
};
