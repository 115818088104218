import React, { useMemo, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { createTestId } from '../../../utils/functions';
import type { BulkRejectState } from '../../pages/BulkRejectPage';

interface Props {
  list: BulkRejectState['list'];
}
export type TestIds = 'table';

export const BulkRejectTable: React.FC<Props> = ({ list }) => {
  const testId = createTestId<TestIds>(BulkRejectTable);
  const tableData: {
    label: string;
    name: keyof BulkRejectState['list'][0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parser?: (x?: any) => any;
  }[] = useMemo(() => {
    return [
      { label: '会員ID', name: 'customerId', parser: String },
      { label: '会員ステータス', name: 'customerStatus', parser: String },
      { label: '応募ID', name: 'applyId', parser: Number },
      { label: '応募ステータス', name: 'applyStatus', parser: String },
      { label: '店舗・モニター情報', name: 'shopId', parser: String },
    ];
  }, []);

  const createDisplayElement = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: BulkRejectState['list'][0], labelName: keyof BulkRejectState['list'][0], parser?: (x?: any) => any) => {
      // 複数の情報を入れるセル
      const combineName = ['shopId'];

      if (!combineName.includes(labelName)) {
        return parser ? parser(item[labelName]) : item[labelName];
      }

      if (labelName === 'shopId') {
        return (
          <>
            {item.shopId}&emsp;{item.shopName}
            <br />
            {item.monitorBaseId}&emsp;{item.monitorBaseName}
          </>
        );
      }

      return '';
    },
    []
  );

  return (
    <Table data-testid={testId('table')}>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label)].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <tr className="align-middle" key={item.applyId}>
            {tableData.map(({ name, parser }) => (
              <td key={String(name)}>{createDisplayElement(item, name, parser)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
