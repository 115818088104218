import React, { useEffect, useState } from 'react';
import { Form, Table } from 'react-bootstrap';
import { FieldArrayWithId, UseFormRegister } from 'react-hook-form';
import { AxiosResponse } from 'axios';
import { toBlob, when } from '../../../utils/functions';
import { FormControl } from '../../molecules/Form/FormControl';
// eslint-disable-next-line import/no-cycle
import { CancelReasonDLApi, LostReasonDLApi } from '../../../api-client';
import type { ListItem } from '../../pages/FloatingReward/FloatingRewardPage';

export interface Props {
  fields: FieldArrayWithId<
    {
      note: string;
      list: ListItem[];
    },
    'list',
    'id'
  >[];
  register: UseFormRegister<{
    note: string;
    list: ListItem[];
  }>;
  clientDetailInfo: any;
  isConfirm: boolean;
  feeAlertIndex: number[];
  watchRequiredDeliveryNumber: number[];
  watchNewFloatingRewardType: number[];
}

const SUFFIX = {
  YEN: '円',
  PERCENT: '％',
  FRAME: '枠',
} as const;

const REWARD_TYPE = {
  FIXED: { CODE: 0, STR: '固定' },
  RATIO: { CODE: 1, STR: '割合' },
} as const;

export const FloatingRewardDetailTable: React.VFC<Props> = ({
  fields,
  register,
  clientDetailInfo,
  isConfirm,
  feeAlertIndex,
  watchRequiredDeliveryNumber,
  watchNewFloatingRewardType,
}) => {
  const [lostReasonUrls, setLostReasonUrls] = useState<string[]>([]);
  const [currentLostReasonUrl, setCurrentLostReasonUrl] = useState<string>('');

  const [cancelReasonUrls, setCancelReasonUrls] = useState<string[]>([]);
  const [currentCancelReasonUrl, setCurrentCancelReasonUrl] = useState<string>('');

  const lostReasonDLApi = new LostReasonDLApi();
  const cancelReasonDLApi = new CancelReasonDLApi();

  useEffect(() => {
    fields.forEach(({ monitorId }) => {
      let lostReasonUrl = '';
      lostReasonDLApi.file(monitorId).then((res: AxiosResponse<string>) => {
        const blob = toBlob(
          res.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        );
        lostReasonUrl = blob ? window.URL.createObjectURL(blob) : '#';
        setCurrentLostReasonUrl(lostReasonUrl);
      });

      let cancelReasonUrl = '';
      cancelReasonDLApi.file1(monitorId).then((res: AxiosResponse<string>) => {
        const blob = toBlob(
          res.data,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        );
        cancelReasonUrl = blob ? window.URL.createObjectURL(blob) : '#';
        setCurrentCancelReasonUrl(cancelReasonUrl);
      });
    });
  }, [fields]);

  useEffect(() => {
    if (!currentLostReasonUrl) return;
    setLostReasonUrls([...lostReasonUrls, currentLostReasonUrl]);
  }, [currentLostReasonUrl]);

  useEffect(() => {
    if (!currentCancelReasonUrl) return;
    setCancelReasonUrls([...cancelReasonUrls, currentCancelReasonUrl]);
  }, [currentCancelReasonUrl]);

  const getRequiredMissingNumber = (idx: number): string => {
    const requiredMissingNumber = clientDetailInfo?.requiredNumber
      ? watchRequiredDeliveryNumber[idx] - fields[idx]?.completeNumber
      : 0;
    return requiredMissingNumber <= 0 ? '-' : `${requiredMissingNumber}${SUFFIX.FRAME}`;
  };

  const getRewardType = (code: number): string =>
    code === REWARD_TYPE.FIXED.CODE ? REWARD_TYPE.FIXED.STR : REWARD_TYPE.RATIO.STR;

  return (
    <div style={{ width: 'calc(100vw - 20rem)', overflowX: 'scroll' }} className="mb-4">
      <Table>
        <thead>
          <tr className="text-nowrap">
            <th>店舗ID</th>
            <th>店舗名</th>
            <th>都道府県</th>
            <th>エリア</th>
            <th>モニターID</th>
            <th>モニター名</th>
            <th>開始日</th>
            <th>終了日</th>
            <th>ステータス</th>
            <th>変動謝礼設定</th>
            <th>枠数</th>
            <th>応募数</th>
            <th>当選中数</th>
            <th>残枠数</th>
            <th>完了数</th>
            <th>マスト納品数</th>
            <th>マスト不足数</th>
            <th>変動謝礼残枠数</th>
            {/* <th>PV数</th> */}
            <th>枠消化率</th>
            <th>落選率</th>
            <th>キャンセル率</th>
            <th>レシート合計金額</th>
            <th>派遣粗利</th>
            <th>手数料</th>
            <th>手数料上限</th>
            <th>STOCK</th>
            <th>元の謝礼情報</th>
            <th>現在の変動謝礼情報</th>
            <th>追加変更の変動謝礼情報</th>
            <th>現在の変動謝礼を削除する</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, idx) => {
            return (
              <tr key={item.shopId} className="text-nowrap align-middle">
                <td>{item.shopId}</td>
                <td>{item.shopName}</td>
                <td>{item.prefectureName}</td>
                <td>{item.areaName}</td>
                <td>{item.monitorId}</td>
                <td>{item.monitorName}</td>
                <td>{item.monitorStartAt}</td>
                <td>{item.monitorEndAt}</td>
                <td>{item.status}</td>
                <td>{item.floatingRewardPreference}</td>
                <td>
                  {item.number}
                  {SUFFIX.FRAME}
                </td>
                <td>
                  {item.applyNumber}
                  {SUFFIX.FRAME}
                </td>
                <td>
                  {item.winningNumber}
                  {SUFFIX.FRAME}
                </td>
                <td>
                  {item.remainingNumber}
                  {SUFFIX.FRAME}
                </td>
                <td>
                  {item.completeNumber}
                  {SUFFIX.FRAME}
                </td>
                <td className="pe-4">
                  <FormControl
                    type="number"
                    className="d-inline-block"
                    disabled={isConfirm}
                    register={register}
                    registerName={`list.${idx}.requiredDeliveryNumber`}
                  />
                  {SUFFIX.FRAME}
                </td>
                <td>{getRequiredMissingNumber(idx)}</td>
                <td>
                  {item.floatingRewarRemainingBorderNumber}
                  {SUFFIX.FRAME}
                </td>
                {/* <td>{item.pvNumber}</td> */}
                <td>
                  {item.digestionRate}
                  {SUFFIX.PERCENT}
                </td>
                {lostReasonUrls[idx] && (
                  <td>
                    <a
                      href={lostReasonUrls[idx]}
                      download={`【落選理由】${clientDetailInfo.clientName}_${item.shopName}.xlsx`}
                    >
                      {item.lossRate}
                      {SUFFIX.PERCENT}
                    </a>
                  </td>
                )}
                {cancelReasonUrls[idx] && (
                  <td>
                    <a
                      href={cancelReasonUrls[idx]}
                      download={`【キャンセル理由】${clientDetailInfo.clientName}_${item.shopName}.xlsx`}
                    >
                      {item.cancelRate}
                      {SUFFIX.PERCENT}
                    </a>
                  </td>
                )}
                <td>
                  {item.receiptTotalAmount}
                  {SUFFIX.YEN}
                </td>
                <td>
                  {item.grossProfit}
                  {SUFFIX.YEN}
                </td>
                <td>
                  {item.fee}
                  {SUFFIX.YEN}
                </td>
                <td>
                  {item.feeMax}
                  {SUFFIX.YEN}
                </td>
                <td>
                  {item.stock}
                  {SUFFIX.YEN}
                </td>
                <td>
                  <div className="d-flex gap-4">
                    <span>{getRewardType(item.userRewardType)}</span>
                    {when(
                      item.userRewardType === REWARD_TYPE.FIXED.CODE,
                      <>
                        <span>{SUFFIX.PERCENT}</span>
                        <span>
                          {item.userRewardRate}
                          {SUFFIX.YEN}
                        </span>
                      </>
                    )}
                    {when(
                      item.userRewardType === REWARD_TYPE.RATIO.CODE,
                      <>
                        <span>
                          {item.userRewardRate}
                          {SUFFIX.PERCENT}
                        </span>
                        <span>
                          {item.userRewardAmount}
                          {SUFFIX.YEN}
                        </span>
                      </>
                    )}
                  </div>
                </td>
                <td>
                  <div className="d-flex gap-4 align-items-center">
                    <span>{item.floatingRewardType != null && getRewardType(item.floatingRewardType)}</span>

                    {when(
                      item.floatingRewardType != null && item.floatingRewardType === REWARD_TYPE.FIXED.CODE,
                      <>
                        <span>{SUFFIX.PERCENT}</span>
                        <span>
                          {item.floatingRewardRate}
                          {SUFFIX.YEN}
                        </span>
                      </>
                    )}
                    {when(
                      item.floatingRewardType != null && item.floatingRewardType === REWARD_TYPE.RATIO.CODE,
                      <>
                        <span>
                          {item.floatingRewardRate}
                          {SUFFIX.PERCENT}
                        </span>
                        <span>
                          {item.floatingRewardAmount}
                          {SUFFIX.YEN}
                        </span>
                      </>
                    )}
                    {when(
                      item.floatingRewardType == null,
                      <>
                        <span>{SUFFIX.PERCENT}</span>
                        <span>{SUFFIX.YEN}</span>
                      </>
                    )}
                    <span style={{ width: '70px' }}>
                      <FormControl
                        type="number"
                        className="d-inline-block"
                        disabled={isConfirm}
                        register={register}
                        registerName={`list.${idx}.floatingRewardNumber`}
                      />
                      {SUFFIX.FRAME}
                    </span>
                    <span>
                      {item.floatingRewardNumber}
                      {SUFFIX.FRAME}
                    </span>
                  </div>
                </td>
                <td className="pe-4">
                  <div style={{ width: '400px' }} className="d-flex gap-4">
                    <span style={{ flexBasis: '20%' }}>
                      <Form.Select
                        defaultValue=""
                        className="w-auto d-inline-block"
                        disabled={isConfirm}
                        {...register(`list.${idx}.newFloatingRewardType`)}
                      >
                        <option value=""> </option>
                        <option value={REWARD_TYPE.FIXED.CODE}>{REWARD_TYPE.FIXED.STR}</option>
                        <option value={REWARD_TYPE.RATIO.CODE}>{REWARD_TYPE.RATIO.STR}</option>
                      </Form.Select>
                    </span>
                    <span style={{ flexBasis: '25%' }}>
                      <FormControl
                        type="number"
                        className="d-inline-block"
                        disabled={Number(watchNewFloatingRewardType[idx]) === REWARD_TYPE.FIXED.CODE || isConfirm}
                        register={register}
                        registerName={`list.${idx}.newFloatingRewardRate`}
                      />
                      <span className="d-inline-block">{SUFFIX.PERCENT}</span>
                    </span>
                    <span style={{ flexBasis: '30%' }}>
                      <FormControl
                        type="number"
                        as="input"
                        className={`d-inline-block ${feeAlertIndex.some((val) => val === idx) && 'text-danger'}`}
                        disabled={isConfirm}
                        register={register}
                        registerName={`list.${idx}.newFloatingRewardAmount`}
                      />
                      {SUFFIX.YEN}
                    </span>
                    <span style={{ flexBasis: '25%' }}>
                      <FormControl
                        type="number"
                        className="d-inline-block"
                        disabled={isConfirm}
                        register={register}
                        registerName={`list.${idx}.newFloatingRewardNumber`}
                      />
                      {SUFFIX.FRAME}
                    </span>
                  </div>
                </td>
                <td>
                  <span>
                    <FormControl
                      type="checkbox"
                      as="input"
                      disabled={isConfirm}
                      className="form-check-input"
                      register={register}
                      registerName={`list.${idx}.closeFlg`}
                    />
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};
