import React, { useMemo, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { Alert, Button, Form } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { formatISODate, uuid, when } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import { InputNumber } from '../../atoms/InputNumber';
import type {
  CorrectSettingEachMonitorBaseState,
  ImageProp,
} from '../../pages/CorrectSetting/CorrectSettingEachMonitorPage';
import type { DataEntrySettingInfoOutputResponse } from '../../../api-client';
import { DATETIME_DISPLAY_FORMAT, DATE_DISPLAY_FORMAT } from '../../../Constants';
import { ImageCard } from '../../molecules/ImageCard';

type State = CorrectSettingEachMonitorBaseState['dataEntryForm'];
type OutputType = CorrectSettingEachMonitorBaseState['dataEntryInfo'];
type SetStateType = {
  referencedMonitorId: number;
  templateContent: string;
  errorMessage: string;
  imageList: ImageProp[];
};

interface Props {
  setForms: DispatchSetState<State>;
  monitorId: number;
  dataEntryInfo: OutputType;
  searchSetting: (id: number) => Promise<DataEntrySettingInfoOutputResponse | undefined>;
  onDrop: (files: File[]) => void;
  onDelete: (url: string) => void;
  imageList: ImageProp[];
}

export const CorrectSettingEachMonitorBaseDataEntryForms: React.FC<Props> = React.memo(
  ({ setForms, monitorId, dataEntryInfo, searchSetting, onDrop, onDelete, imageList }) => {
    const initialDataEntryForm: State = useMemo(() => {
      return {
        dataEntryStartFlg: dataEntryInfo.dataEntrySettings.dataEntryPersonStartFlg,
        imageIdList: dataEntryInfo.dataEntrySettings.imageFileList.map((data) => data.id),
        monitorId,
        noteContent: dataEntryInfo.dataEntrySettings.noteContent || '',
        priority: dataEntryInfo.dataEntrySettings.priority || 0,
        taxExcluded8PercentFlg: dataEntryInfo.dataEntrySettings.taxExcluded8PercentFlg || false,
        taxExcluded10PercentFlg: dataEntryInfo.dataEntrySettings.taxExcluded10PercentFlg || false,
        taxIncludedFlg: dataEntryInfo.dataEntrySettings.taxIncludedFlg || true,
      };
    }, [dataEntryInfo, monitorId]);

    const { state: $, mergeState, onChangeSet } = useLargeState<State>(initialDataEntryForm);

    useEffect(() => {
      setForms({ ...$ });
    }, [setForms, $]);

    useEffect(() => {
      mergeState({ ...initialDataEntryForm });
    }, [dataEntryInfo, initialDataEntryForm, mergeState]);

    const {
      state: $set,
      mergeState: mergeSet,
      onChangeSet: onChangeSetSet,
    } = useLargeState<SetStateType>({
      referencedMonitorId: 0,
      errorMessage: '',
      templateContent: '',
      imageList,
    });

    useEffect(() => {
      mergeSet({ imageList });
    }, [imageList, mergeSet]);

    const onSetting = (id: number) => {
      if (!id) return;

      searchSetting(id).then((targetSetting) => {
        if (targetSetting && targetSetting.correctionMethodId === dataEntryInfo.correctionMethodId) {
          mergeState({
            dataEntryStartFlg: targetSetting.dataEntrySettings.dataEntryPersonStartFlg,
            imageIdList: [],
            monitorId,
            noteContent: targetSetting.dataEntrySettings.noteContent,
            priority: targetSetting.dataEntrySettings.priority,
            taxExcluded8PercentFlg: targetSetting.dataEntrySettings.taxExcluded8PercentFlg,
            taxExcluded10PercentFlg: targetSetting.dataEntrySettings.taxExcluded10PercentFlg,
            taxIncludedFlg: targetSetting.dataEntrySettings.taxIncludedFlg,
          });
          mergeSet({ errorMessage: '' });
        } else if (targetSetting && targetSetting.correctionMethodId !== dataEntryInfo.correctionMethodId) {
          mergeSet({ errorMessage: '添削方法が異なります' });
        }
      });
    };

    const priorityOptions: [string, number][] = useMemo(
      () =>
        Object.entries({
          優先度低い: 1,
          普通: 2,
          優先度高い: 3,
        }),
      []
    );

    return (
      <>
        <Form.Label className="mt-4">参考にするモニターID</Form.Label>
        <Form.Group controlId="referencedMonitorId" key="referencedMonitorId" className="d-flex">
          <InputNumber
            className="w-75"
            value={$set.referencedMonitorId || undefined}
            onChange={(e) => {
              mergeSet({ referencedMonitorId: Number(e.target.value) });
            }}
          />
          <Button
            className="mx-2"
            onClick={() => {
              onSetting($set.referencedMonitorId);
            }}
          >
            設定
          </Button>
        </Form.Group>
        {$set.errorMessage.length ? <Alert variant="danger">{$set.errorMessage}</Alert> : undefined}
        <div className="border border-dark p-2 mt-2">
          <h6>1. カウントダウン設定日を確認</h6>
          <div className="mt-2">
            {dataEntryInfo.dataEntrySettings.countDownSettingDate
              ? formatISODate(dataEntryInfo.dataEntrySettings.countDownSettingDate, DATE_DISPLAY_FORMAT)
              : ''}
          </div>

          <h6 className="mt-2">2. 優先度</h6>
          <Form.Select value={$.priority || 0} onChange={onChangeSet('priority', Number)}>
            <option key={0} value={0} hidden>
              --選択してください--
            </option>
            {priorityOptions.map(([label, value]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </Form.Select>

          <h6 className="mt-2">3. データ入力開始</h6>
          <Form.Check
            type="switch"
            onChange={() => {
              mergeState({
                ...$,
                dataEntryStartFlg: !$.dataEntryStartFlg,
              });
            }}
            checked={$.dataEntryStartFlg}
          />

          <h6 className="mt-2">4. 想定される税率を全て選択</h6>
          <Form.Group controlId="taxIncludedFlg">
            <Form.Check
              inline
              type="checkbox"
              key="taxIncludedFlg"
              name="taxIncludedFlg"
              label="税込み"
              checked
              disabled
            />
          </Form.Group>
          <Form.Group controlId="taxExcluded8PercentFlg">
            <Form.Check
              inline
              type="checkbox"
              key="taxExcluded8PercentFlg"
              name="taxExcluded8PercentFlg"
              label="税抜き8%"
              onChange={() => mergeState({ taxExcluded8PercentFlg: !$.taxExcluded8PercentFlg })}
              checked={$.taxExcluded8PercentFlg}
            />
          </Form.Group>
          <Form.Group controlId="taxExcluded10PercentFlg">
            <Form.Check
              inline
              type="checkbox"
              key="taxExcluded10PercentFlg"
              name="taxExcluded10PercentFlg"
              label="税抜き10%"
              onChange={() => mergeState({ taxExcluded10PercentFlg: !$.taxExcluded10PercentFlg })}
              checked={$.taxExcluded10PercentFlg}
            />
          </Form.Group>

          <h6 className="mt-2">5. データ入力に表示する注意事項を記入</h6>
          {/* TODO <div className="pull-right">
            <Button variant="link" onClick={() => {}}>
              <FontAwesomeIcon icon={faWindowMaximize} />
              ノートテンプレートを編集
            </Button>
          </div> */}
          <div className="d-flex">
            <Form.Select value={$set.templateContent || ''} onChange={onChangeSetSet('templateContent', String)}>
              <option value="">--</option>
              {dataEntryInfo.dataEntrySettings.noteTemplateList.map(({ templateName, templateText }) => (
                <option value={templateText} key={templateName}>
                  {templateName}
                </option>
              ))}
            </Form.Select>
            <Button className="ms-2" onClick={() => mergeState({ noteContent: $set.templateContent })}>
              挿入
            </Button>
          </div>
          <Form.Control
            as="textarea"
            className="mt-2"
            autoComplete="off"
            rows={5}
            value={$.noteContent || ''}
            onChange={onChangeSet('noteContent', String)}
          />

          {/* TODO <Dropzone onDrop={onDrop} multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{
                  border: '3px dotted #c0c0c0',
                  color: '#a0a0a0',
                  background: '#FFF',
                  textAlign: 'center',
                  height: '100px',
                }}
              >
                <input {...getInputProps()} />
                <div style={{ marginTop: 15 }}>
                  ここにファイルをドラッグ
                  <br />
                  <Button variant="link">
                    <p style={{ borderBottom: 'solid' }}>ファイルを添付</p>
                  </Button>
                </div>
              </div>
            )}
          </Dropzone>
          {$set.imageList?.length > 0 &&
            $set.imageList.map(({ path }) => {
              if (path) {
                return (
                  <ImageCard
                    key={uuid()}
                    url={path || ''}
                    onDelete={() => {
                      onDelete(path);
                    }}
                  />
                );
              }
              return undefined;
            })} */}
        </div>
        {when(
          !!dataEntryInfo.dataEntrySettings.updateAt,
          <p className="text-secondary mt-2 d-flex justify-content-end">
            最終更新日時{' '}
            {dataEntryInfo.dataEntrySettings.updateAt
              ? formatISODate(dataEntryInfo.dataEntrySettings.updateAt, DATETIME_DISPLAY_FORMAT)
              : ''}{' '}
            {dataEntryInfo.dataEntrySettings.updateIncName}
          </p>
        )}
      </>
    );
  }
);
