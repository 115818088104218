import React from 'react';
import { Accordion } from 'react-bootstrap';
import { when } from '../../../../utils/functions';
import type { ShoppingDisplayControlOutputResponse } from '../../../../api-client';

export const ShoppingControlAlert: React.FC<{
  controlInfo: ShoppingDisplayControlOutputResponse;
  wcheckFlg: boolean;
}> = React.memo(({ controlInfo, wcheckFlg }) => {
  return (
    <>
      {when(
        !!controlInfo.alertMessage,
        <div className="bg-danger text-center">
          <h4 className="text-white py-4">{controlInfo.alertMessage}</h4>
        </div>
      )}
      {when(
        wcheckFlg,
        <Accordion defaultActiveKey="0" className="bg-white mb-2">
          <Accordion.Item eventKey="0">
            <Accordion.Header>ステータス</Accordion.Header>
            <Accordion.Body>
              <div>
                <span className="fw-bold">調査証明</span>
                <span className="ms-2">{controlInfo.surveyProofStatus}</span>
              </div>
              <div>
                <span className="fw-bold">アンケート</span>
                <span className="ms-2">{controlInfo.enqueteStatus}</span>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
    </>
  );
});
