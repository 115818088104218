import React, { Dispatch, SetStateAction, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
  ClientOfRequiredNumberDeleteApi,
  ClientOfRequiredNumberListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';

export interface Props {
  list: ClientOfRequiredNumberListOutputResponse[];
  listPerPage: ClientOfRequiredNumberListOutputResponse[];
  setList: Dispatch<SetStateAction<ClientOfRequiredNumberListOutputResponse[]>>;
  setError: Dispatch<SetStateAction<IncResultOutputResponse | null>>;
}

export const ClientOfRequiredNumberTable: React.VFC<Props> = ({ list, setList, listPerPage, setError }) => {
  const history = useHistory();
  const [isModal, setIsModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  const clientOfRequiredNumberDeleteApi = new ClientOfRequiredNumberDeleteApi();

  const handleClickDelete = (id: number): void => {
    setIsModal(true);
    setDeleteId(id);
  };

  const handleClickModalYes = (): void => {
    if (!deleteId) return;
    clientOfRequiredNumberDeleteApi
      .clientOfRequiredNumberDelete(deleteId)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        if (res.data.result) setList(list.filter((item) => item.clientId !== deleteId));
        setError({ ...res.data });
        // setError({ result: true, errorCode: '', errorMessage: '' });
        // setError({ result: false, errorCode: 'AAAA-000', errorMessage: '削除できませんでした。' });
      });
    setIsModal(false);
  };

  const handleClickModalNo = (): void => {
    setIsModal(false);
    setDeleteId(null);
  };

  return (
    <>
      <Modal
        title="消して良いですか？"
        footer={
          <>
            <Button variant="secondary" onClick={handleClickModalNo}>
              いいえ
            </Button>
            <Button variant="primary" onClick={handleClickModalYes}>
              はい
            </Button>
          </>
        }
        isModal={isModal}
        onHide={handleClickModalNo}
      />
      <Table>
        <thead>
          <tr>
            <th>ID</th>
            <th>クライアント名</th>
            <th>担当営業</th>
            <th>派遣マスト数</th>
          </tr>
        </thead>
        <tbody>
          {listPerPage?.map((data: ClientOfRequiredNumberListOutputResponse) => {
            return (
              <tr key={data.clientId}>
                <th>{data.clientId}</th>
                <th>{data.clientName}</th>
                <th>{data.salesStaffName}</th>
                <th>{data.requiredNumber}</th>
                <th className="d-flex justify-content-between">
                  <Button
                    variant="link"
                    onClick={() => {
                      history.push({
                        pathname: Url.KEISAI.CLIENT_OF_REQUIRED_NUMBER_TARGET,
                        state: { id: data.clientId },
                      });
                    }}
                  >
                    編集
                  </Button>
                  <a href={`${Url.KEISAI.FLOATING_REWARD}/${data.clientId}`} target="_blank" rel="noreferrer">
                    変動謝礼設定
                  </a>
                  <Button
                    variant="light"
                    className="text-secondary"
                    testId="delete"
                    onClick={() => {
                      handleClickDelete(data.clientId);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                  </Button>
                </th>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};
