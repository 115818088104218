import React, { useMemo, useCallback } from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Button, Popover, OverlayTrigger } from 'react-bootstrap';
import { createTestId, uuid } from '../../../utils/functions';
import type { CorrectSettingSearchState } from '../../pages/CorrectSetting/CorrectSettingSearchPage';
import { Url } from '../../../constants/Url';

interface Props {
  list: CorrectSettingSearchState['list'];
}
export type TestIds = 'table' | 'edit-button' | 'client-button' | 'monitor-button';

export const CorrectSettingSearchTable: React.FC<Props> = React.memo(({ list }) => {
  const testId = createTestId<TestIds>(CorrectSettingSearchTable);
  const history = useHistory();

  const tableData: {
    label: string;
    name: keyof CorrectSettingSearchState['list'][0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parser?: (x?: any) => any;
  }[] = useMemo(() => {
    return [
      { label: 'クライアントID/クライアント名', name: 'clientId', parser: String },
      { label: '店舗ID/店舗名', name: 'contractShopId', parser: String },
      { label: 'モニターID/モニター名', name: 'monitorId', parser: String },
      { label: '購入証明/添削方法', name: 'surveyProofCorrectMethod', parser: String },
      { label: 'アンケート/添削方法', name: 'enqueteCorrectMethod', parser: String },
    ];
  }, []);

  const clientTooltip = useMemo(() => <Popover id="button-tooltip">クライアント毎添削設定</Popover>, []);
  const monitorTooltip = useMemo(() => <Popover id="button-tooltip">モニター毎添削設定</Popover>, []);

  const createDisplayElement = useCallback(
    (item: CorrectSettingSearchState['list'][0], labelName: keyof CorrectSettingSearchState['list'][0]) => {
      // 加工が必要なセル
      const combineName = [
        'clientId',
        'contractShopId',
        'monitorId',
        'surveyProofCorrectMethod',
        'enqueteCorrectMethod',
      ];
      if (!combineName.includes(labelName)) {
        return item[labelName];
      }

      if (labelName === 'clientId') {
        return (
          <div className="d-flex justify-content-between">
            <>
              {item.clientId} {item.clientName}
            </>
            <>
              <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={clientTooltip}>
                <Button
                  data-testid={testId('client-button')}
                  variant="link"
                  onClick={() => {
                    history.push(`${Url.TENSAKU.CORRECT_SETTING_EACH_CLIENT}/${item.clientId}`);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className={`${!item.clientSettingConfiguredFlg ? '.text-secondary' : ''}`}
                  />
                </Button>
              </OverlayTrigger>
            </>
          </div>
        );
      }
      if (labelName === 'contractShopId') {
        return (
          <>
            {item.contractShopId} {item.contractShopName}
          </>
        );
      }
      if (labelName === 'monitorId') {
        return (
          <div className="d-flex justify-content-between">
            <>
              {item.monitorId} {item.monitorName}
            </>
            <>
              <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={monitorTooltip}>
                <Button
                  data-testid={testId('monitor-button')}
                  variant="link"
                  onClick={() => {
                    history.push(`${Url.TENSAKU.CORRECT_SETTING_EACH_MONITOR}/${item.monitorId}`);
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEdit}
                    className={`${!item.monitorSettingConfiguredFlg ? '.text-secondary' : ''}`}
                  />
                </Button>
              </OverlayTrigger>
            </>
          </div>
        );
      }
      if (labelName === 'surveyProofCorrectMethod') {
        return <>{item.surveyProofCorrectMethod}</>;
      }
      if (labelName === 'enqueteCorrectMethod') {
        return <>{item.enqueteCorrectMethod}</>;
      }

      return '';
    },
    [clientTooltip, history, monitorTooltip, testId]
  );

  return (
    <Table data-testid={testId('table')}>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label)].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list.map((item) => (
          <tr className="align-middle" key={uuid()}>
            {tableData.map(({ name }) => (
              <td key={name}>{createDisplayElement(item, name)}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
});
