import { useCallback, useRef } from 'react';

// コールバックの呼び出しを間引く
export const useDebounce = (timeout = 500): ((fn: () => void) => void) => {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const debounce = useCallback(
    (fn) => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => fn(), timeout);
    },
    [timeout]
  );
  return debounce;
};
