/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { MonitorRuleAndQuestionAssociationFormResponse } from '../domains';
// @ts-ignore
import { MonitorRuleAndQuestionAssociationInfoFormResponse } from '../domains';
// @ts-ignore
import { MonitorRuleAndQuestionAssociationInfoOutputResponse } from '../domains';
// @ts-ignore
import { MonitorRuleSetInfoOutputResponse } from '../domains';
// @ts-ignore
import { MonitorRuleSetListOutputResponse } from '../domains';
// @ts-ignore
import { MonitorRuleSetTemplateListOutputResponse } from '../domains';
// @ts-ignore
import { MonitorRuleSetUpdateFormResponse } from '../domains';
// @ts-ignore
import { SelectEnqueteShowInfoOutputResponse } from '../domains';
/**
 * MonitorRuleApi - axios parameter creator
 * @export
 */
export const MonitorRuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * モニタールールと設問紐付けを更新する
         * @summary モニタールールと設問紐付け
         * @param {MonitorRuleAndQuestionAssociationFormResponse} monitorRuleAndQuestionAssociationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleAndQuestionAssociation: async (monitorRuleAndQuestionAssociationFormResponse: MonitorRuleAndQuestionAssociationFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorRuleAndQuestionAssociationFormResponse' is not null or undefined
            assertParamExists('monitorRuleAndQuestionAssociation', 'monitorRuleAndQuestionAssociationFormResponse', monitorRuleAndQuestionAssociationFormResponse)
            const localVarPath = `/api/monitor-rule-question-update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitorRuleAndQuestionAssociationFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニタールールと設問紐付け画面情報を取得する
         * @summary モニタールールと設問紐付け画面情報取得
         * @param {MonitorRuleAndQuestionAssociationInfoFormResponse} monitorRuleAndQuestionAssociationInfoFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleAndQuestionAssociationInfo: async (monitorRuleAndQuestionAssociationInfoFormResponse: MonitorRuleAndQuestionAssociationInfoFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorRuleAndQuestionAssociationInfoFormResponse' is not null or undefined
            assertParamExists('monitorRuleAndQuestionAssociationInfo', 'monitorRuleAndQuestionAssociationInfoFormResponse', monitorRuleAndQuestionAssociationInfoFormResponse)
            const localVarPath = `/api/monitor-rule-question-Info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitorRuleAndQuestionAssociationInfoFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニタールールセット情報を取得する
         * @summary モニタールールセット情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleSetInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('monitorRuleSetInfo', 'id', id)
            const localVarPath = `/api/monitor-rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニタールールセット一覧を取得する
         * @summary モニタールールセット一覧取得
         * @param {string} [monitorRuleName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleSetList: async (monitorRuleName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/monitor-rules-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (monitorRuleName !== undefined) {
                localVarQueryParameter['monitorRuleName'] = monitorRuleName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニタールールセットテンプレート一覧を取得する
         * @summary モニタールールセットテンプレート一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleSetTemplateList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/monitor-rules-temp-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニタールールセットを更新する
         * @summary モニタールールセット更新
         * @param {MonitorRuleSetUpdateFormResponse} monitorRuleSetUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleSetUpdate: async (monitorRuleSetUpdateFormResponse: MonitorRuleSetUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorRuleSetUpdateFormResponse' is not null or undefined
            assertParamExists('monitorRuleSetUpdate', 'monitorRuleSetUpdateFormResponse', monitorRuleSetUpdateFormResponse)
            const localVarPath = `/api/monitor-rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitorRuleSetUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新規作成時の表示情報を取得する
         * @summary 新規作成時の表示情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectEnqueteShowInfo: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectEnqueteShowInfo', 'id', id)
            const localVarPath = `/api/monitor-rules-enquete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonitorRuleApi - functional programming interface
 * @export
 */
export const MonitorRuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonitorRuleApiAxiosParamCreator(configuration)
    return {
        /**
         * モニタールールと設問紐付けを更新する
         * @summary モニタールールと設問紐付け
         * @param {MonitorRuleAndQuestionAssociationFormResponse} monitorRuleAndQuestionAssociationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorRuleAndQuestionAssociation(monitorRuleAndQuestionAssociationFormResponse: MonitorRuleAndQuestionAssociationFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorRuleAndQuestionAssociation(monitorRuleAndQuestionAssociationFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニタールールと設問紐付け画面情報を取得する
         * @summary モニタールールと設問紐付け画面情報取得
         * @param {MonitorRuleAndQuestionAssociationInfoFormResponse} monitorRuleAndQuestionAssociationInfoFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorRuleAndQuestionAssociationInfo(monitorRuleAndQuestionAssociationInfoFormResponse: MonitorRuleAndQuestionAssociationInfoFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitorRuleAndQuestionAssociationInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorRuleAndQuestionAssociationInfo(monitorRuleAndQuestionAssociationInfoFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニタールールセット情報を取得する
         * @summary モニタールールセット情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorRuleSetInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitorRuleSetInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorRuleSetInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニタールールセット一覧を取得する
         * @summary モニタールールセット一覧取得
         * @param {string} [monitorRuleName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorRuleSetList(monitorRuleName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonitorRuleSetListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorRuleSetList(monitorRuleName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニタールールセットテンプレート一覧を取得する
         * @summary モニタールールセットテンプレート一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorRuleSetTemplateList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonitorRuleSetTemplateListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorRuleSetTemplateList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニタールールセットを更新する
         * @summary モニタールールセット更新
         * @param {MonitorRuleSetUpdateFormResponse} monitorRuleSetUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorRuleSetUpdate(monitorRuleSetUpdateFormResponse: MonitorRuleSetUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorRuleSetUpdate(monitorRuleSetUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 新規作成時の表示情報を取得する
         * @summary 新規作成時の表示情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectEnqueteShowInfo(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SelectEnqueteShowInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectEnqueteShowInfo(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MonitorRuleApi - factory interface
 * @export
 */
export const MonitorRuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonitorRuleApiFp(configuration)
    return {
        /**
         * モニタールールと設問紐付けを更新する
         * @summary モニタールールと設問紐付け
         * @param {MonitorRuleAndQuestionAssociationFormResponse} monitorRuleAndQuestionAssociationFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleAndQuestionAssociation(monitorRuleAndQuestionAssociationFormResponse: MonitorRuleAndQuestionAssociationFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.monitorRuleAndQuestionAssociation(monitorRuleAndQuestionAssociationFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * モニタールールと設問紐付け画面情報を取得する
         * @summary モニタールールと設問紐付け画面情報取得
         * @param {MonitorRuleAndQuestionAssociationInfoFormResponse} monitorRuleAndQuestionAssociationInfoFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleAndQuestionAssociationInfo(monitorRuleAndQuestionAssociationInfoFormResponse: MonitorRuleAndQuestionAssociationInfoFormResponse, options?: any): AxiosPromise<MonitorRuleAndQuestionAssociationInfoOutputResponse> {
            return localVarFp.monitorRuleAndQuestionAssociationInfo(monitorRuleAndQuestionAssociationInfoFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * モニタールールセット情報を取得する
         * @summary モニタールールセット情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleSetInfo(id: number, options?: any): AxiosPromise<MonitorRuleSetInfoOutputResponse> {
            return localVarFp.monitorRuleSetInfo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * モニタールールセット一覧を取得する
         * @summary モニタールールセット一覧取得
         * @param {string} [monitorRuleName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleSetList(monitorRuleName?: string, options?: any): AxiosPromise<Array<MonitorRuleSetListOutputResponse>> {
            return localVarFp.monitorRuleSetList(monitorRuleName, options).then((request) => request(axios, basePath));
        },
        /**
         * モニタールールセットテンプレート一覧を取得する
         * @summary モニタールールセットテンプレート一覧取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleSetTemplateList(options?: any): AxiosPromise<Array<MonitorRuleSetTemplateListOutputResponse>> {
            return localVarFp.monitorRuleSetTemplateList(options).then((request) => request(axios, basePath));
        },
        /**
         * モニタールールセットを更新する
         * @summary モニタールールセット更新
         * @param {MonitorRuleSetUpdateFormResponse} monitorRuleSetUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorRuleSetUpdate(monitorRuleSetUpdateFormResponse: MonitorRuleSetUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.monitorRuleSetUpdate(monitorRuleSetUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 新規作成時の表示情報を取得する
         * @summary 新規作成時の表示情報取得
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectEnqueteShowInfo(id: number, options?: any): AxiosPromise<SelectEnqueteShowInfoOutputResponse> {
            return localVarFp.selectEnqueteShowInfo(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonitorRuleApi - object-oriented interface
 * @export
 * @class MonitorRuleApi
 * @extends {BaseAPI}
 */
export class MonitorRuleApi extends BaseAPI {
    /**
     * モニタールールと設問紐付けを更新する
     * @summary モニタールールと設問紐付け
     * @param {MonitorRuleAndQuestionAssociationFormResponse} monitorRuleAndQuestionAssociationFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorRuleApi
     */
    public monitorRuleAndQuestionAssociation(monitorRuleAndQuestionAssociationFormResponse: MonitorRuleAndQuestionAssociationFormResponse, options?: AxiosRequestConfig) {
        return MonitorRuleApiFp(this.configuration).monitorRuleAndQuestionAssociation(monitorRuleAndQuestionAssociationFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニタールールと設問紐付け画面情報を取得する
     * @summary モニタールールと設問紐付け画面情報取得
     * @param {MonitorRuleAndQuestionAssociationInfoFormResponse} monitorRuleAndQuestionAssociationInfoFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorRuleApi
     */
    public monitorRuleAndQuestionAssociationInfo(monitorRuleAndQuestionAssociationInfoFormResponse: MonitorRuleAndQuestionAssociationInfoFormResponse, options?: AxiosRequestConfig) {
        return MonitorRuleApiFp(this.configuration).monitorRuleAndQuestionAssociationInfo(monitorRuleAndQuestionAssociationInfoFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニタールールセット情報を取得する
     * @summary モニタールールセット情報取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorRuleApi
     */
    public monitorRuleSetInfo(id: number, options?: AxiosRequestConfig) {
        return MonitorRuleApiFp(this.configuration).monitorRuleSetInfo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニタールールセット一覧を取得する
     * @summary モニタールールセット一覧取得
     * @param {string} [monitorRuleName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorRuleApi
     */
    public monitorRuleSetList(monitorRuleName?: string, options?: AxiosRequestConfig) {
        return MonitorRuleApiFp(this.configuration).monitorRuleSetList(monitorRuleName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニタールールセットテンプレート一覧を取得する
     * @summary モニタールールセットテンプレート一覧取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorRuleApi
     */
    public monitorRuleSetTemplateList(options?: AxiosRequestConfig) {
        return MonitorRuleApiFp(this.configuration).monitorRuleSetTemplateList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニタールールセットを更新する
     * @summary モニタールールセット更新
     * @param {MonitorRuleSetUpdateFormResponse} monitorRuleSetUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorRuleApi
     */
    public monitorRuleSetUpdate(monitorRuleSetUpdateFormResponse: MonitorRuleSetUpdateFormResponse, options?: AxiosRequestConfig) {
        return MonitorRuleApiFp(this.configuration).monitorRuleSetUpdate(monitorRuleSetUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新規作成時の表示情報を取得する
     * @summary 新規作成時の表示情報取得
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorRuleApi
     */
    public selectEnqueteShowInfo(id: number, options?: AxiosRequestConfig) {
        return MonitorRuleApiFp(this.configuration).selectEnqueteShowInfo(id, options).then((request) => request(this.axios, this.basePath));
    }
}
