import { Document, Page, Text, StyleSheet, View, Image, Svg, Font } from '@react-pdf/renderer';
import React from 'react';
import { BillingInvoiceDetailContractOutputResponse, BillingInvoiceDetailItemOutputResponse } from '../../api-client';
import { ItemDetailTable } from './Table/ItemDetailTable';
import seal from '../../img/layout/seal.png';
import draft from '../../img/layout/draft.png';
import {
  calcAmountPerTaxRate,
  calcIncludeTaxAmount,
  convertMoneyText,
  isManageDptCommitted,
} from '../../utils/functionsForBilling';
import { SAMA } from '../../BillingConstants';

interface Props {
  billingInvoiceItem: Array<BillingInvoiceDetailContractOutputResponse> | undefined;
  headerData: BillingInvoiceDetailItemOutputResponse;
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Nasu-Regular',
    color: 'black',
    width: '100%',
  },
  tableTitle: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: 'left',
    marginLeft: 5,
    marginTop: 15,
  },
  clientAddress: {
    fontSize: 10,
    textAlign: 'left',
    marginLeft: 30,
  },
  address: {
    fontSize: 10,
    textAlign: 'left',
  },
  addressRoiCompanyName: {
    fontSize: 10,
    textAlign: 'left',
  },
  title: {
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
    marginTop: 25,
  },
  col: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  billingInfo: {
    marginLeft: 20,
  },
  additinal: {
    fontSize: 7,
    textAlign: 'left',
  },
  remarkBox: {
    width: '100%',
    // height: '7%',
    height: 80,
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    marginTop: 5,
  },
  remarkText: {
    fontSize: 9,
    margin: 5,
    textAlign: 'left',
    maxLines: 4,
  },
  img: {
    width: '2.5cm',
    height: '2.5cm',
    marginLeft: 125,
    marginTop: -30,
    position: 'absolute',
    zIndex: 10,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 15,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  headerMain: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  headerLeft: {
    flexGrow: 1,
    width: '60%',
    fontSize: 10,
    textAlign: 'left',
    position: 'relative',
  },
  headerLeftClientName: {
    fontSize: 12,
    textAlign: 'left',
    fontWeight: 'bold',
    maxLines: 1,
    width: '88%',
  },
  headerLeftOthers: {
    borderBottom: 1,
    borderStyle: 'solid',
    paddingBottom: 1,
    fontSize: 10,
    textAlign: 'left',
    marginVertical: 7,
    maxLines: 1,
  },
  headerLeftAmount: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  headerRight: {
    flexGrow: 1,
    marginHorizontal: 10,
    lineHeight: 1.2,
    width: '40%',
  },
  account: {
    marginVertical: 5,
    fontSize: 8,
    textAlign: 'left',
  },
  onchy: {
    position: 'absolute',
    right: 10,
    bottom: 3,
  },
});

export const ItemDetailInvoice = ({
  billingInvoiceItem,
  headerData,
}: {
  billingInvoiceItem: Array<BillingInvoiceDetailContractOutputResponse> | undefined;
  headerData: BillingInvoiceDetailItemOutputResponse;
}): JSX.Element => {
  return (
    <Document>
      <Page style={styles.body} size="A4">
        {/* header */}
        <View style={styles.col}>
          <View style={styles.clientAddress}>
            <Text>〒{headerData.billingDestinationPostalcode}</Text>
            <Text>
              {headerData.billingDestinationPrefecture}
              {headerData.billingDestinationCity}
              {headerData.billingDestinationAddress}
            </Text>
            <Text>{headerData.billingDestinationBuilding}</Text>
            <Text>{headerData.billingDestinationCompanyName}</Text>
            <Text>{headerData.billingDestinationDepartment}</Text>
            <Text>
              {headerData.billingDestinationBillingStaff}
              {SAMA}
            </Text>
          </View>
          <View style={styles.address}>
            <Text>{headerData.billingPublishedDate}</Text>
            <Text>請求書番号：{headerData.billingHeaderId}</Text>
          </View>
        </View>
        {/* main header */}
        <View>
          <Text style={styles.title}>{headerData.paymentConditonId === 1 ? '請求書' : '口座振替通知書'}</Text>
          <View style={styles.headerMain}>
            <View style={styles.headerLeft}>
              <View style={{ position: 'relative' }}>
                <Text style={styles.headerLeftClientName}>{headerData.clientName}</Text>
                <Text style={styles.headerLeftClientName}>{headerData.billingDestinationName}</Text>
                <Text style={styles.onchy}>御中</Text>
              </View>
              <Text style={styles.headerLeftOthers}>
                件名{'      '}
                {headerData.subject}
              </Text>
              <Text style={styles.headerLeftOthers}>
                請求金額{'      '}
                <Text style={styles.headerLeftAmount}>
                  {convertMoneyText(calcIncludeTaxAmount(calcAmountPerTaxRate(billingInvoiceItem)))}
                </Text>
                {'      '}
                <Text>(税込)</Text>
              </Text>
              <Text style={styles.headerLeftOthers}>
                {headerData.paymentConditonId === 1 ? '支払日' : '引落日'}
                {'      '}
                {headerData.paymentDate}
              </Text>
              <Text style={styles.headerLeftOthers}>
                支払条件{'      '}
                {/* TODO DBの値から出てくるように修正 */}
                {headerData.paymentConditonId === 1 ? '銀行振込' : '口座振替'}
              </Text>
            </View>
            <View style={styles.headerRight}>
              <View style={styles.address}>
                <Text style={styles.addressRoiCompanyName}>{headerData.roiName}</Text>
                <Text>〒{headerData.roiPostalcode}</Text>
                <Text>
                  {headerData.roiPrefecture}
                  {headerData.roiCity}
                  {headerData.roiAddress}
                </Text>
                <Text>{headerData.roiBuilding}</Text>
                <Text>TEL: {headerData.roiTel}</Text>
                <Text>FAX: {headerData.roiFax}</Text>
                <Text>担当: {headerData.roiAccountName}</Text>
                <Text>登録番号：{headerData.invoiceRegisterNumber}</Text>
              </View>
              <Image src={isManageDptCommitted(headerData.billingFlowStatus) ? seal : draft} style={styles.img} />

              {headerData.paymentConditonId === 1 ? (
                <View style={styles.account}>
                  <Text>振込先</Text>
                  <Text>りそな銀行 市ヶ谷支店 普通 1783519</Text>
                  <Text>三菱UFJ銀行 飯田橋支店 普通 0036372</Text>
                  <Text>みずほ銀行 飯田橋支店 普通 2565732</Text>
                  <Text>口座名義: カ）ファンクル</Text>
                  <Text style={styles.additinal}>
                    ※振込手数料は貴社にてご負担頂きますよう宜しく御願い申し上げます。
                  </Text>
                </View>
              ) : (
                <View style={styles.account}>
                  <Text>下記ご指定の口座よりお振替させていただきます。</Text>
                  <Text>振替日の前日までにご準備いただきますようお願いいたします。</Text>
                  <Text>引落口座：{headerData.accountCode}</Text>
                </View>
              )}
            </View>
          </View>
          <View style={styles.remarkBox}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ fontSize: 10, textAlign: 'left' }}>備考</Text>
              <Text style={{ fontSize: 9, margin: 1, marginLeft: 15 }}>
                ※レポート件数:{headerData.reportCount.toLocaleString()}
              </Text>
              <Text style={{ fontSize: 9, margin: 1, marginLeft: 5 }}>
                ユーザー利用金額:{convertMoneyText(headerData.customerAmount)}
              </Text>
            </View>
            <Text style={styles.remarkText}>{headerData.remarks}</Text>
          </View>
        </View>
        {/* 票の部分 */}
        <View>
          <Text style={styles.tableTitle}>請求書明細</Text>
          <ItemDetailTable billingInvoiceItem={billingInvoiceItem} />
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};
