import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import type {
  DisplayPointOutputResponse,
  MonitorRuleDtoResponse,
  SupplementUrlResponse,
} from '../../../../../api-client';
import scss from '../../../../../scss/templates/CorrectionTemplate.module.scss';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { uuid, when } from '../../../../../utils/functions';
import { Button } from '../../../../atoms/Button';
import { SideBy } from '../../../../atoms/SideBy';
import { Escalation } from '../../Escalation';
import { EscalateEditor } from '../commentEditors/EscalateEditor';
import { NGEditor } from '../commentEditors/NGEditor';
import { Logs } from '../parts/Logs';
import { MonitorRule } from '../parts/MonitorRule';
import { NoteList } from '../parts/NoteList';
import { equalDp } from '../../../../pages/Correction/utils';

/** ノート・NG添削・事務所へ質問 */
export const CommonPanel: React.FC<{
  label?: string;
  urls?: SupplementUrlResponse[];
  displayPoint: Partial<DisplayPointOutputResponse>;
  displayPointForEditor?: Partial<DisplayPointOutputResponse>;
  monitorRule?: MonitorRuleDtoResponse;
  noForm?: true;
  // モニタールールの参照機能用のflg。
  // ノート関連と背景色を変える
  isReadOnly?: boolean;
}> = ({
  label,
  urls,
  displayPoint: dp,
  displayPointForEditor: dp4e,
  monitorRule,
  children,
  noForm,
  isReadOnly = false,
}) => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const targetMonitorRule = useMemo(() => {
    if (monitorRule) return monitorRule;
    if (!$.isEnquete) return undefined;
    return $.res_correctionCheckDisplay?.monitorRuleList.find(
      (x) => x.monitorRuleId === $.res_enqueteDisplay?.find((y) => y.questionNo === dp?.targetId)?.monitorRuleId
    );
  }, [$.res_correctionCheckDisplay?.monitorRuleList, $.res_enqueteDisplay, dp?.targetId, monitorRule, $.isEnquete]);
  // eslint-disable-next-line no-nested-ternary
  const accordionOpen = ($.commentForms_ng && $.commentForms_ng?.length > 0
    ? $.commentForms_ng
    : $.commentForms_escale && $.commentForms_escale?.length > 0
    ? $.commentForms_escale
    : []
  ).find(equalDp(dp4e ?? dp))
    ? 'SHOW'
    : 'INIT';
  return (
    <>
      {when(!!label, <h5>{label}</h5>)}
      <SideBy>
        {(urls ?? []).map(({ name, url }) => (
          <React.Fragment key={uuid()}>
            <Button variant="link">
              <a href={url} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faWindowRestore} /> {name}
              </a>
            </Button>
          </React.Fragment>
        ))}
      </SideBy>
      <div style={{ paddingTop: 16 }}>
        {/* TODO 何でもかんでも詰めすぎなので分解したい */}
        {targetMonitorRule ? <MonitorRule monitorRule={targetMonitorRule} isReadOnly={isReadOnly} /> : <></>}
        {when(
          !isReadOnly,
          <>
            <NoteList displayAndSendPoint={dp} />
            <Card style={{ marginTop: '10px' }}>
              <Logs infoTitle="最近のNG添削履歴" detailTitle="NG添削履歴" displayPoint={dp} />
              {when(children != null, <Card body>{children}</Card>)}
            </Card>
            <Escalation
              escaletes={($.res_escalationInfoDisplay ?? []).filter(
                ({ displayPoint }) => displayPoint.type === dp?.type && displayPoint.targetId === dp?.targetId
              )}
              enableEmptyLabel={noForm}
            />
          </>
        )}
        {when(
          !noForm && !isReadOnly,
          <Card style={{ marginTop: '10px' }}>
            <Accordion
              defaultActiveKey={[accordionOpen === 'SHOW' ? '1' : '0']}
              aria-expanded={accordionOpen === 'SHOW'}
            >
              <Accordion.Item eventKey="1">
                <Accordion.Header style={{ height: '30px' }} className={`${scss.check_point_card_header} bg-warning`}>
                  <div>NGまたはエスカレ</div>
                </Accordion.Header>
                <Accordion.Body>
                  <div style={{ margin: '10px' }}>
                    <NGEditor sendingDisplayPoint={dp4e ?? dp} />
                  </div>
                  <div style={{ margin: '10px' }}>
                    <EscalateEditor sendingDisplayPoint={dp4e ?? dp} />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card>
        )}
      </div>
    </>
  );
};
