import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { parseSearchQuery } from '../../utils/functions';
import { Url } from '../../constants/Url';
import { Layout } from '../templates/Layout';

export const RedirectPage: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    const { url } = parseSearchQuery(history.location.search);
    const decodedUrl = decodeURIComponent(url);
    if (!url || !/^\//.test(decodedUrl)) {
      history.push(Url.COMMON_ERROR);
      return;
    }
    setTimeout(
      () => {
        history.push(decodedUrl);
      },
      process.env.NODE_ENV === 'test' ? 1 : 1000
    );
  }, [history]);
  return (
    <Layout>
      <h1>リダイレクトします・・・</h1>
    </Layout>
  );
};
