import { AxiosError, AxiosPromise } from 'axios';
import React, { useCallback, useEffect } from 'react';
import { Alert, Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../molecules/Modal';
// eslint-disable-next-line import/no-cycle
import {
  PointApi,
  GrantPointConfirmFormResponse,
  GrantPointConfirmOutputResponse,
  IncResultOutputResponse,
  PointTypeListOutputResponse,
  GrantPointCreateOutputResponse,
} from '../../../api-client';
// import { pointApi } from '../../../test/mocks/api/PointApi';
import { useLargeState } from '../../../hooks/useLargeState';
import { PointGrantProvider } from '../../../store/pointPageStore';
import { createTestId, dial, uuid, when } from '../../../utils/functions';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import { PointGrantBulkRegisterPage } from './Grant/PointGrantBulkRegisterPage';
import { PointGrantConfirmPage } from './Grant/PointGrantConfirmPage';
import { PointGrantRegisterPage } from './Grant/PointGrantRegisterPage';
import { Url } from '../../../constants/Url';
import { ErrorObjectResponse, ResultOutputResponse } from '../../../api-client/domains';

export interface State {
  // api: ReturnType<typeof pointApi>;
  api: PointApi;
  isLoading: boolean;
  phase: 'add' | 'bulk' | 'confirm';
  pointTypes: PointTypeListOutputResponse[];
  file: File | undefined;
  // fileUrl: string;
  fileError: string;
  list: GrantPointConfirmOutputResponse[];
  isModal: boolean;
  forms: undefined | GrantPointConfirmFormResponse;
  submitResult: GrantPointCreateOutputResponse;
  errorMessages: string[];
}

export const initialState: Omit<State, 'api'> = {
  isLoading: false,
  phase: 'add',
  pointTypes: [],
  file: undefined,
  fileError: '',
  list: [],
  isModal: false,
  forms: undefined,
  submitResult: { result: false, unSubscribedCustomerIds: [], provisionalCustomerIds: [] },
  errorMessages: [],
};

export const PointGrantPage: React.FC = () => {
  const testid = createTestId(PointGrantPage);
  const history = useHistory();
  const largeState = useLargeState<State>({
    // api: pointApi({ grantPointCreate: 400 }, { enableLogs: true }),
    api: new PointApi(),
    ...initialState,
  });
  const { state: $, mergeState } = largeState;
  useEffect(() => {
    mergeState({ isLoading: true });
    $.api.pointTypeList().then((res) => {
      mergeState({ isLoading: false, pointTypes: res.data });
    });
  }, [$.api, mergeState]);

  const onConfirmFromAdd = useCallback(
    (forms: State['forms']) => {
      if (!forms) return;
      mergeState({ isLoading: true });
      $.api
        .grantPointConfirm(forms)
        .then((res) => {
          mergeState({ isLoading: false, forms, list: res.data, phase: 'confirm' });
        })
        .catch((error) => {
          const { errors } = error.response.data;
          const errorMessages = errors.map((value: ErrorObjectResponse) => value.message);
          mergeState({ list: [], errorMessages, isLoading: false });
        });
    },
    [$.api, mergeState]
  );

  const onConfirmFromBulk = useCallback(() => {
    if (!$.file) return;
    mergeState({ isLoading: true });
    const reader = new FileReader();
    reader.readAsDataURL($.file);
    new Promise<ProgressEvent<FileReader>>((resolve, reject) => {
      reader.onload = resolve;
      reader.onerror = reject;
    })
      .then((e) => {
        const uploadFile = e.target?.result as string | undefined;
        if (!uploadFile) throw new Error('URL作成失敗');
        const formatData = uploadFile.slice(uploadFile.indexOf(',') + 1);
        $.api
          .grantPointFileUploadConfirm({ uploadFile: formatData })
          .then((res) => {
            mergeState({ isLoading: false, list: res.data, phase: 'confirm' });
          })
          .catch((error) => {
            const errors = [...error.response.data.errors!];
            const errorMessages = errors.map((value: ErrorObjectResponse) => value.message);
            mergeState({ list: [], errorMessages, isLoading: false });
          });
      })
      .catch((/* e: ProgressEvent<FileReader> */) => {
        mergeState({ fileError: 'ファイルの読み込みに失敗しました' });
      });
  }, [$.api, $.file, mergeState]);

  const onSubmit = useCallback(() => {
    if (!$.list) return;
    mergeState({ isLoading: true });
    $.api.grantPointCreate($.list).then((res) => {
      mergeState({ isLoading: false, isModal: true, submitResult: res.data });
    });
  }, [$.api, $.list, mergeState]);

  return (
    <LoadingSpinner isLoading={$.isLoading}>
      <Modal
        data-testid={testid('result-modal')}
        centered
        scrollable
        isModal={$.isModal}
        title={$.submitResult.result ? '完了しました。' : '処理が失敗しました。'}
        size="lg"
        body={
          <>
            {$.submitResult.unSubscribedCustomerIds.length > 0 && (
              <>
                <p>退会済みでポイント付与スキップした会員ID一覧</p>
                {$.submitResult.unSubscribedCustomerIds.map((v) => (
                  <p>{v}</p>
                ))}
              </>
            )}
            {$.submitResult.provisionalCustomerIds.length > 0 && (
              <>
                <p>仮登録でポイント付与スキップした会員ID一覧</p>
                {$.submitResult.provisionalCustomerIds.map((v) => (
                  <p>{v}</p>
                ))}
              </>
            )}
            {when(
              $.list.length === 1,
              <p>
                <Button
                  data-testid={testid('detail-button')}
                  onClick={() => history.push(`${Url.TENSAKU.CUSTOMER_INFO}/${$.list?.[0]?.customerId}`)}
                >
                  会員 {$.list?.[0]?.customerId} の詳細へ
                </Button>
              </p>
            )}
            <p>
              <Button data-testid={testid('ok-button')} onClick={() => history.push(Url.TOP)}>
                トップ画面へ
              </Button>
            </p>
          </>
        }
      />
      {when(
        $.errorMessages.length > 0,
        <>
          {$.errorMessages.map((message) => (
            <Alert variant="danger" data-testid={testid('alert-body')} key={uuid()}>
              {message}
            </Alert>
          ))}
        </>
      )}
      <PointGrantProvider largeState={largeState}>
        {dial($.phase, {
          add: <PointGrantRegisterPage onConfirm={onConfirmFromAdd} />,
          bulk: <PointGrantBulkRegisterPage onConfirm={onConfirmFromBulk} />,
          confirm: <PointGrantConfirmPage onSubmit={onSubmit} />,
        })}
      </PointGrantProvider>
    </LoadingSpinner>
  );
};
