/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExtendDeadlineHisListFormResponse } from '../domains';
// @ts-ignore
import { ExtendDeadlineHisListOutputResponse } from '../domains';
// @ts-ignore
import { ExtendDeadlineHisUpdateConfirmFormResponse } from '../domains';
// @ts-ignore
import { ExtendDeadlineHisUpdateConfirmOutputResponse } from '../domains';
// @ts-ignore
import { ExtendDeadlineHisUpdateFormResponse } from '../domains';
// @ts-ignore
import { ResultOutputResponse } from '../domains';
/**
 * ExtendDeadlineHistoryApi - axios parameter creator
 * @export
 */
export const ExtendDeadlineHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 期限延長当選復帰履歴ファイルダウンロード
         * @summary 期限延長当選復帰履歴ファイルダウンロード
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendDeadlineHistoryFileDownload: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('extendDeadlineHistoryFileDownload', 'id', id)
            const localVarPath = `/api/extend-deadline-history/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 期限延長当選復帰履歴一覧
         * @summary 期限延長当選復帰履歴一覧
         * @param {ExtendDeadlineHisListFormResponse} extendDeadlineHisListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendDeadlineHistoryList: async (extendDeadlineHisListFormResponse: ExtendDeadlineHisListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extendDeadlineHisListFormResponse' is not null or undefined
            assertParamExists('extendDeadlineHistoryList', 'extendDeadlineHisListFormResponse', extendDeadlineHisListFormResponse)
            const localVarPath = `/api/extend-deadline-history/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extendDeadlineHisListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 期限延長当選復帰履歴
         * @summary 期限延長当選復帰履歴
         * @param {Array<ExtendDeadlineHisUpdateFormResponse>} extendDeadlineHisUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendDeadlineHistoryUpdate: async (extendDeadlineHisUpdateFormResponse: Array<ExtendDeadlineHisUpdateFormResponse>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extendDeadlineHisUpdateFormResponse' is not null or undefined
            assertParamExists('extendDeadlineHistoryUpdate', 'extendDeadlineHisUpdateFormResponse', extendDeadlineHisUpdateFormResponse)
            const localVarPath = `/api/extend-deadline-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extendDeadlineHisUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 期限延長当選復帰履歴確認
         * @summary 期限延長当選復帰履歴確認
         * @param {ExtendDeadlineHisUpdateConfirmFormResponse} extendDeadlineHisUpdateConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendDeadlineHistoryUpdateConfirm: async (extendDeadlineHisUpdateConfirmFormResponse: ExtendDeadlineHisUpdateConfirmFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'extendDeadlineHisUpdateConfirmFormResponse' is not null or undefined
            assertParamExists('extendDeadlineHistoryUpdateConfirm', 'extendDeadlineHisUpdateConfirmFormResponse', extendDeadlineHisUpdateConfirmFormResponse)
            const localVarPath = `/api/extend-deadline-history/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(extendDeadlineHisUpdateConfirmFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtendDeadlineHistoryApi - functional programming interface
 * @export
 */
export const ExtendDeadlineHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExtendDeadlineHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 期限延長当選復帰履歴ファイルダウンロード
         * @summary 期限延長当選復帰履歴ファイルダウンロード
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendDeadlineHistoryFileDownload(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extendDeadlineHistoryFileDownload(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 期限延長当選復帰履歴一覧
         * @summary 期限延長当選復帰履歴一覧
         * @param {ExtendDeadlineHisListFormResponse} extendDeadlineHisListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendDeadlineHistoryList(extendDeadlineHisListFormResponse: ExtendDeadlineHisListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExtendDeadlineHisListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extendDeadlineHistoryList(extendDeadlineHisListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 期限延長当選復帰履歴
         * @summary 期限延長当選復帰履歴
         * @param {Array<ExtendDeadlineHisUpdateFormResponse>} extendDeadlineHisUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendDeadlineHistoryUpdate(extendDeadlineHisUpdateFormResponse: Array<ExtendDeadlineHisUpdateFormResponse>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extendDeadlineHistoryUpdate(extendDeadlineHisUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 期限延長当選復帰履歴確認
         * @summary 期限延長当選復帰履歴確認
         * @param {ExtendDeadlineHisUpdateConfirmFormResponse} extendDeadlineHisUpdateConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendDeadlineHistoryUpdateConfirm(extendDeadlineHisUpdateConfirmFormResponse: ExtendDeadlineHisUpdateConfirmFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ExtendDeadlineHisUpdateConfirmOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extendDeadlineHistoryUpdateConfirm(extendDeadlineHisUpdateConfirmFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExtendDeadlineHistoryApi - factory interface
 * @export
 */
export const ExtendDeadlineHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExtendDeadlineHistoryApiFp(configuration)
    return {
        /**
         * 期限延長当選復帰履歴ファイルダウンロード
         * @summary 期限延長当選復帰履歴ファイルダウンロード
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendDeadlineHistoryFileDownload(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.extendDeadlineHistoryFileDownload(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 期限延長当選復帰履歴一覧
         * @summary 期限延長当選復帰履歴一覧
         * @param {ExtendDeadlineHisListFormResponse} extendDeadlineHisListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendDeadlineHistoryList(extendDeadlineHisListFormResponse: ExtendDeadlineHisListFormResponse, options?: any): AxiosPromise<Array<ExtendDeadlineHisListOutputResponse>> {
            return localVarFp.extendDeadlineHistoryList(extendDeadlineHisListFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 期限延長当選復帰履歴
         * @summary 期限延長当選復帰履歴
         * @param {Array<ExtendDeadlineHisUpdateFormResponse>} extendDeadlineHisUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendDeadlineHistoryUpdate(extendDeadlineHisUpdateFormResponse: Array<ExtendDeadlineHisUpdateFormResponse>, options?: any): AxiosPromise<ResultOutputResponse> {
            return localVarFp.extendDeadlineHistoryUpdate(extendDeadlineHisUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 期限延長当選復帰履歴確認
         * @summary 期限延長当選復帰履歴確認
         * @param {ExtendDeadlineHisUpdateConfirmFormResponse} extendDeadlineHisUpdateConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendDeadlineHistoryUpdateConfirm(extendDeadlineHisUpdateConfirmFormResponse: ExtendDeadlineHisUpdateConfirmFormResponse, options?: any): AxiosPromise<Array<ExtendDeadlineHisUpdateConfirmOutputResponse>> {
            return localVarFp.extendDeadlineHistoryUpdateConfirm(extendDeadlineHisUpdateConfirmFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExtendDeadlineHistoryApi - object-oriented interface
 * @export
 * @class ExtendDeadlineHistoryApi
 * @extends {BaseAPI}
 */
export class ExtendDeadlineHistoryApi extends BaseAPI {
    /**
     * 期限延長当選復帰履歴ファイルダウンロード
     * @summary 期限延長当選復帰履歴ファイルダウンロード
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtendDeadlineHistoryApi
     */
    public extendDeadlineHistoryFileDownload(id: number, options?: AxiosRequestConfig) {
        return ExtendDeadlineHistoryApiFp(this.configuration).extendDeadlineHistoryFileDownload(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 期限延長当選復帰履歴一覧
     * @summary 期限延長当選復帰履歴一覧
     * @param {ExtendDeadlineHisListFormResponse} extendDeadlineHisListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtendDeadlineHistoryApi
     */
    public extendDeadlineHistoryList(extendDeadlineHisListFormResponse: ExtendDeadlineHisListFormResponse, options?: AxiosRequestConfig) {
        return ExtendDeadlineHistoryApiFp(this.configuration).extendDeadlineHistoryList(extendDeadlineHisListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 期限延長当選復帰履歴
     * @summary 期限延長当選復帰履歴
     * @param {Array<ExtendDeadlineHisUpdateFormResponse>} extendDeadlineHisUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtendDeadlineHistoryApi
     */
    public extendDeadlineHistoryUpdate(extendDeadlineHisUpdateFormResponse: Array<ExtendDeadlineHisUpdateFormResponse>, options?: AxiosRequestConfig) {
        return ExtendDeadlineHistoryApiFp(this.configuration).extendDeadlineHistoryUpdate(extendDeadlineHisUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 期限延長当選復帰履歴確認
     * @summary 期限延長当選復帰履歴確認
     * @param {ExtendDeadlineHisUpdateConfirmFormResponse} extendDeadlineHisUpdateConfirmFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtendDeadlineHistoryApi
     */
    public extendDeadlineHistoryUpdateConfirm(extendDeadlineHisUpdateConfirmFormResponse: ExtendDeadlineHisUpdateConfirmFormResponse, options?: AxiosRequestConfig) {
        return ExtendDeadlineHistoryApiFp(this.configuration).extendDeadlineHistoryUpdateConfirm(extendDeadlineHisUpdateConfirmFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
