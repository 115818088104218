import React, { Fragment } from 'react';
import { DisplayPointOutputResponse } from '../../../../api-client/domains';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../store/correctionStore';
import { dial, uuid } from '../../../../utils/functions';
import { TinyHead } from '../../../atoms/TinyHead';
import { AnswerMethodSummary, DisplayPointType } from '../../../pages/Correction/constants';
import { getCheckListSectionlabel } from '../../../pages/Correction/utils';
import { ImageQuestion } from '../Main/sections/CheckListSection/questions/ImageQuestion';
import { SelectQuestion } from '../Main/sections/CheckListSection/questions/SelectQuestion';
import { TextQuestion } from '../Main/sections/CheckListSection/questions/TextQuestion';
import { CorrectionModalBase } from './CorrectionModalBase';

// TODO: [添削] テスト未実装
export const EnqueteModal: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const createDisplayPoint = (answerId?: number): DisplayPointOutputResponse => ({
    type: DisplayPointType.ANSWER,
    targetId: answerId,
  });

  return (
    <CorrectionModalBase isModal={!!$.isEnqueteModal} title="アンケート設問一覧">
      {$.res_enqueteDisplay?.map(
        ({
          questionNo,
          answerId,
          content,
          answerMethodCode,
          answerMethodSummaryCode,
          answerContent,
          ngWordList,
          imageUrlList,
          answerChoiceList,
          minimumInputLength,
          maxInputLength,
          showChangeShopReviewCheckBoxFlg,
          shopReviewDisplayFlg,
        }) => (
          <Fragment key={uuid()}>
            <TinyHead>{getCheckListSectionlabel(true, questionNo, content)}</TinyHead>
            {dial(answerMethodSummaryCode, {
              [AnswerMethodSummary.TEXT]: (
                <TextQuestion
                  sendingDisplayPoint={{ type: DisplayPointType.ANSWER, targetId: answerId }}
                  answerContent={answerContent}
                  ngWordList={ngWordList}
                  contentCharLengthMin={minimumInputLength}
                  contentCharLengthMax={maxInputLength}
                  showChangeShopReviewCheckBoxFlg={showChangeShopReviewCheckBoxFlg}
                  shopReviewDisplayFlg={shopReviewDisplayFlg}
                  answerMethodCode={answerMethodCode}
                />
              ),
              [AnswerMethodSummary.SELECT]: (
                <SelectQuestion
                  sendingDisplayPoint={{ type: DisplayPointType.ANSWER, targetId: answerId }}
                  selectionList={[]}
                  answerChoiceList={answerChoiceList}
                  answerMethodCode={answerMethodCode}
                  isEditable={false}
                />
              ),
              [AnswerMethodSummary.IMAGE]: (
                <ImageQuestion
                  imageUrlList={imageUrlList}
                  displayPoint={createDisplayPoint(answerId)}
                  answerId={answerId}
                />
              ),
            })}
            <hr />
          </Fragment>
        )
      )}
    </CorrectionModalBase>
  );
};
