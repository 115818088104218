import { faStream } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import type { DisplayPointOutputResponse } from '../../../../../api-client';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { uuid } from '../../../../../utils/functions';
import { TinyHead } from '../../../../atoms/TinyHead';
import { dpToData } from '../../../../pages/Correction/utils';
import scss from '../../../../../scss/templates/CorrectionTemplate.module.scss';
import { WrappableText } from '../../../../atoms/WrappableText';

// TODO: [添削] テスト未実装
/** 履歴 */
export const Logs: React.FC<{
  infoTitle: string;
  detailTitle: string;
  displayPoint?: Partial<DisplayPointOutputResponse>;
}> = ({ infoTitle, detailTitle, displayPoint: dp }) => {
  const {
    largeState: { state: $, mergeState },
  } = useSafeContext(CorrectionContext);
  const [ngHistory, hasDetails] = useMemo(() => {
    const parser = dpToData(dp);
    return [
      parser.toNGHistories($.res_correctionNgList),
      !!parser.toNGHistoryDetails($.res_answerAndNgHistoryDetail).length,
    ];
  }, [dp, $.res_correctionNgList, $.res_answerAndNgHistoryDetail]);
  const onOpenModal = useCallback(
    () =>
      mergeState({
        isNGHistoryModal: true,
        dpForNGHistoryModal: dp,
        titleForNGHistoryModal: detailTitle,
      }),
    [mergeState, dp, detailTitle]
  );
  return ngHistory.length || hasDetails ? (
    <>
      {hasDetails ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={scss.check_point_card_header}>
            <div>NG</div>
          </div>
          <Button variant="link" onClick={onOpenModal}>
            <FontAwesomeIcon icon={faStream} />
            {detailTitle}
          </Button>
        </div>
      ) : (
        <div className={scss.check_point_card_header}>
          <TinyHead span>{infoTitle}</TinyHead>
        </div>
      )}
      {ngHistory.length ? (
        <Card>
          {ngHistory.map(({ correctionNgComment }) => (
            <div key={uuid()} className="bg-white border border-danger text-danger">
              <Card.Body>
                <WrappableText memo={correctionNgComment} height={60} />
              </Card.Body>
            </div>
          ))}
        </Card>
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};
