import { faTimes, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Nav, Row, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Url } from '../../../constants/Url';
import { useSafeContext } from '../../../hooks/useSafeContext';
import sass from '../../../scss/templates/CorrectionTemplate.module.scss';
import { CorrectionContext } from '../../../store/correctionStore';
import { Button } from '../../atoms/Button';
import { nativeAlert, nativeAlertWithErrorMessage, nativeConfirm } from '../../pages/Correction/utils';
import { CorrectionTargetType } from '../../pages/Correction/constants';
import { when } from '../../../utils/functions';

// TODO: [添削] テスト未実装
export const HeaderNav: React.FC = () => {
  const {
    largeState: { state: $, mergeState },
  } = useSafeContext(CorrectionContext);
  const canOkRelease = $.res_correctReceiptDisplayControl?.okRelease;

  const correctionControl = useMemo(() => {
    return $.res_correctReceiptDisplayControl ?? $.res_correctEnqueteDisplayControl;
  }, [$.res_correctEnqueteDisplayControl, $.res_correctReceiptDisplayControl]);

  const [noRewardflg, setNoRewardFlg] = useState(false);

  // 初期フェッチ用
  useEffect(() => {
    setNoRewardFlg(correctionControl?.noRewardCorrectFlg ?? false);
  }, [$.res_correctEnqueteDisplayControl, $.res_correctReceiptDisplayControl]);

  const onNoRewardFlg = useCallback((flg: boolean) => {
    // API実行
    $.api_correct
      .noRewardFlgUpdate({ noRewardFlg: flg })
      // 成功するとステート更新
      .then(() => setNoRewardFlg(flg));
  }, []);

  return (
    <Row>
      <Col>
        <Nav
          variant="pills"
          defaultActiveKey={!$.isEnquete ? 'receipt' : 'questionnaire'}
          className={`${sass.correction_nav}`}
        >
          <Nav.Item>
            <Nav.Link eventKey="receipt" href={`${Url.TENSAKU.CORRECT_RECEIPT}/${$.applyId}`}>
              購入証明添削
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="questionnaire" href={`${Url.TENSAKU.CORRECT_ENQUETE}/${$.applyId}`}>
              アンケート添削
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Button
              variant="outline-secondary"
              style={{ margin: '0px 10px' }}
              className={sass.correction_manualAssign}
              // FIXME 権限によってボタンの表示を制御する
              onClick={async () => {
                const confirmMessage =
                  'アサインを取得してもよろしいですか？アサインを奪い取ることになるため、事務局の指示があった場合のみ実行してください。';
                if (nativeConfirm(confirmMessage)) {
                  let correctionTargetType: CorrectionTargetType | null;
                  if ($.isEnquete) {
                    correctionTargetType = CorrectionTargetType.ENQUETE;
                  } else {
                    correctionTargetType = CorrectionTargetType.RECEIPT;
                  }

                  try {
                    await $.api_correct.manualApplyAssign({
                      applyId: $.applyId,
                      correctionTargetType,
                    });
                    window.location.reload();
                  } catch (error) {
                    console.error(error);
                    nativeAlertWithErrorMessage(error);
                  }
                }
              }}
            >
              アサイン取得
            </Button>
          </Nav.Item>
          {!$.isEnquete ? (
            <Nav.Item>
              <Button
                variant="outline-secondary"
                className={sass.correction_stepCheckRelease}
                disabled={!canOkRelease}
                onClick={async () => {
                  if (!canOkRelease) return;
                  mergeState({ isOkReleaseModal: true });
                }}
              >
                <FontAwesomeIcon icon={faTimes} className="me-1" />
                OK解除
              </Button>
            </Nav.Item>
          ) : (
            <Nav.Item>
              <Button variant="link" onClick={() => nativeAlert('未実装です')}>
                <FontAwesomeIcon icon={faWindowRestore} /> チェック観点
              </Button>
            </Nav.Item>
          )}
          {when(
            correctionControl?.controlNoRewardCorrectFlg ?? false,
            <div style={{ marginLeft: '8px', paddingTop: '5px', paddingInline: '4px' }}>
              <Form.Check
                type="switch"
                label="報酬なし"
                checked={noRewardflg}
                onChange={(e) => onNoRewardFlg(e.currentTarget.checked)}
              />
            </div>
          )}
          {when($.isEnquete, <AnswerAndNgListLink applyId={$.applyId} />)}
        </Nav>
      </Col>
    </Row>
  );
};

const AnswerAndNgListLink: React.FC<{ applyId: number }> = ({ applyId }) => {
  return (
    <div style={{ marginTop: '0.5rem' }}>
      <a href={`${Url.TENSAKU.ANSWER_HISTORY_LIST}/${applyId}`} target="_blank" rel="noreferrer">
        回答履歴
      </a>
    </div>
  );
};
