import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Row, Col, Tabs, Tab, Table, FloatingLabel } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import type { DispatchSetState } from '../../../interfaces/utils';
import { createTestId } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
/* eslint-disable import/no-cycle */
import { EnqueteListApi, EnqueteListOutputResponse, CommonMasterListApi } from '../../../api-client';
import { RecommendFloatingLabel } from '../../molecules/RecommendFloatingLabel';
import { ModalConfig, ContractShop } from '../../pages/PostingRequest/PostingRequestPage';
/* eslint-enable import/no-cycle */

const sampleGetData = {
  list: [...Array(31)].map((_, i) => {
    return {
      enqueteId: i + 1,
      category: `業種${i + 1}`,
      businessCategory: `提供業態${i + 1}`,
      clientName: `管理クライアント${i + 1}`,
      enqueteName: `アンケート名${i + 1}`,
      description: `説明${i + 1}`,
    };
  }),
};

const sampleSearchData = {
  list: [...Array(1)].map((_, i) => {
    return {
      enqueteId: i + 99,
      category: `業種${i + 99}`,
      businessCategory: `提供業態${i + 99}`,
      clientName: `管理クライアント${i + 99}`,
      enqueteName: `アンケート名${i + 99}`,
      description: `説明${i + 99}`,
    };
  }),
};

const getSampleGetData = (tabType: TabType) => {
  let multiplyNum: number;
  switch (tabType) {
    case ENQUETE_TYPE.selection.key:
      multiplyNum = 0;
      break;
    case ENQUETE_TYPE.stacking.key:
      multiplyNum = 1;
      break;
    case ENQUETE_TYPE.other.key:
      multiplyNum = 2;
      break;
    default:
      break;
  }
  return {
    enqueteList: [...Array(30)].map((_, i) => {
      return {
        enqueteId: multiplyNum * 30 + i + 1,
        category: `業種${multiplyNum * 30 + i + 1}`,
        businessCategory: `提供業態${multiplyNum * 30 + i + 1}`,
        clientName: `管理クライアント${multiplyNum * 30 + i + 1}`,
        enqueteName: `アンケート名${multiplyNum * 30 + i + 1}`,
        description: `説明${multiplyNum * 30 + i + 1}`,
      };
    }),
  };
};

export interface Props {
  clientName: string;
  isModal: boolean;
  setIsModal: DispatchSetState<boolean>;
  config: ModalConfig;
  setConfig: DispatchSetState<ModalConfig>;
  contractShops: ContractShop[];
  setContractShops: DispatchSetState<ContractShop[]>;
}

export interface State {
  api: EnqueteListApi;
  commonApi: CommonMasterListApi;
  tabType: TabType;
  currentEnqueteId?: number;
  currentEnqueteName?: string;
  lists: {
    selection: EnqueteListOutputResponse[];
    stacking: EnqueteListOutputResponse[];
    other: EnqueteListOutputResponse[];
  };
  listsPerPage: {
    selection: EnqueteListOutputResponse[];
    stacking: EnqueteListOutputResponse[];
    other: EnqueteListOutputResponse[];
  };
  currentPages: { selection: number; stacking: number; other: number };
  totalPages: { selection: number; stacking: number; other: number };
  searchParams: { selection: SearchParam; stacking: SearchParam; other: SearchParam };
}

const ENQUETE_TYPE = {
  selection: { id: 2, key: 'selection', name: '事後 選択式' },
  stacking: { id: 3, key: 'stacking', name: '事後 積上式' },
  other: { id: 6, key: 'other', name: '事後 その他' },
} as const;

type TabType = typeof ENQUETE_TYPE[keyof typeof ENQUETE_TYPE]['key'];
type SearchParam = {
  enqueteId: number | undefined;
  enqueteName: string | undefined;
  questionId: number | undefined;
  questionContent: string | undefined;
  clientName: string | undefined;
  enqueteType: number;
};

const MAX_RECORD_PER_PAGE = 100;

export const PostEnqueteModal: React.VFC<Props> = ({
  clientName,
  isModal,
  setIsModal,
  config,
  setConfig,
  contractShops,
  setContractShops,
}) => {
  const initialState = {
    api: new EnqueteListApi(),
    commonApi: new CommonMasterListApi(),
    tabType: ENQUETE_TYPE.selection.key,
    lists: { selection: [], stacking: [], other: [] },
    listsPerPage: { selection: [], stacking: [], other: [] },
    currentPages: { selection: 1, stacking: 1, other: 1 },
    totalPages: { selection: 1, stacking: 1, other: 1 },
    searchParams: {
      selection: {
        enqueteId: undefined,
        enqueteName: undefined,
        questionId: undefined,
        questionContent: undefined,
        clientName: undefined,
        enqueteType: ENQUETE_TYPE.selection.id,
      },
      stacking: {
        enqueteId: undefined,
        enqueteName: undefined,
        questionId: undefined,
        questionContent: undefined,
        clientName: undefined,
        enqueteType: ENQUETE_TYPE.stacking.id,
      },
      other: {
        enqueteId: undefined,
        enqueteName: undefined,
        questionId: undefined,
        questionContent: undefined,
        clientName: undefined,
        enqueteType: ENQUETE_TYPE.other.id,
      },
    },
  };
  const testid = createTestId(PostEnqueteModal);
  const { state: $, mergeState } = useLargeState<State>(initialState);

  useEffect(() => {
    if (!isModal || $.lists[$.tabType].length !== 0) return;
    (async () => {
      if (!$.searchParams[$.tabType].clientName) {
        mergeState({ searchParams: { ...$.searchParams, [$.tabType]: { ...$.searchParams[$.tabType], clientName } } });
      }

      const {
        enqueteId,
        enqueteName,
        questionId,
        questionContent,
        clientName: clientNameparam,
        enqueteType,
      } = $.searchParams[$.tabType];
      const { data: list } = await $.api.enqueteList(
        enqueteType,
        enqueteId,
        enqueteName,
        questionId,
        questionContent,
        clientNameparam
      );
      // const { list } = sampleGetData;

      setPagingStates(list);
    })();
  }, [$.api, $.commonApi, mergeState, $.tabType, isModal]);

  // useEffect(() => {
  //   console.log({ config });
  // }, [config]);

  const onSearch = () => {
    // const { enqueteList } = sampleSearchData;

    const {
      enqueteId,
      enqueteName,
      questionId,
      questionContent,
      clientName: clientNameparam,
      enqueteType,
    } = $.searchParams[$.tabType];
    $.api
      .enqueteList(enqueteType, enqueteId, enqueteName, questionId, questionContent, clientNameparam)
      .then(({ data: list }: AxiosResponse<EnqueteListOutputResponse[]>) => {
        setPagingStates(list);
      });
  };

  const setPagingStates = (list: EnqueteListOutputResponse[]) => {
    const sortList = list.sort((a, b) => b.enqueteId - a.enqueteId);

    mergeState({
      lists: { ...$.lists, [$.tabType]: sortList },
      listsPerPage: { ...$.listsPerPage, [$.tabType]: sortList.slice(0, MAX_RECORD_PER_PAGE) },
      totalPages: { ...$.totalPages, [$.tabType]: Math.ceil(Number(sortList?.length) / MAX_RECORD_PER_PAGE) },
      currentPages: { ...$.currentPages, [$.tabType]: 1 },
    });
  };

  const onSave = () => {
    const { mode, shopIndex, monitorIndex } = config;
    if (!$.currentEnqueteId) return;
    switch (mode) {
      case 'edit':
        setContractShops(
          contractShops.map((shop, si) =>
            si === shopIndex
              ? {
                  ...shop,
                  monitorBases: shop.monitorBases.map((monitor, mi) =>
                    mi === monitorIndex
                      ? {
                          ...monitor,
                        }
                      : monitor
                  ),
                }
              : shop
          )
        );
        break;
      case 'bulkChange':
        setContractShops(
          contractShops.map((shop) => ({
            ...shop,
            monitorBases: shop.monitorBases.map((monitor) =>
              monitor.checked
                ? {
                    ...monitor,
                  }
                : monitor
            ),
          }))
        );
        break;
      default:
        break;
    }

    setConfig({});
    setIsModal(false);
  };

  return (
    <Modal
      onHide={() => {
        setConfig({});
        setIsModal(false);
      }}
      isModal={isModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button
              variant="link"
              className="ms-2"
              data-testid={testid('cancel')}
              onClick={() => {
                setConfig({});
                setIsModal(false);
              }}
            >
              キャンセル
            </Button>
            <Button
              className="ms-2"
              onClick={onSave}
              disabled={!$.currentEnqueteId}
              data-testid={testid('save-button')}
            >
              保存
            </Button>
          </div>

          <Tabs
            activeKey={$.tabType}
            onSelect={(key) => mergeState({ tabType: key as TabType })}
            className="mb-4"
            data-testid={testid('tabs')}
          >
            {Object.values(ENQUETE_TYPE).map(({ key, name }) => (
              <Tab key={key} eventKey={key} title={name} />
            ))}
          </Tabs>

          <Form.Group className="bg-light p-4 mb-4">
            <Row className="mb-3">
              <Col className="px-2">
                <FloatingLabel controlId="enqueteId" label="アンケートID" data-testid={testid('enqueteId')}>
                  <Form.Control
                    type="number"
                    placeholder="アンケートID"
                    value={$.searchParams[$.tabType].enqueteId || ''}
                    onChange={(e) =>
                      mergeState({
                        searchParams: {
                          ...$.searchParams,
                          [$.tabType]: { ...$.searchParams[$.tabType], enqueteId: Number(e.target.value) },
                        },
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col className="px-2">
                <FloatingLabel controlId="enqueteName" label="アンケート名" data-testid={testid('enqueteName')}>
                  <Form.Control
                    type="text"
                    placeholder="アンケート名"
                    value={$.searchParams[$.tabType].enqueteName || ''}
                    onChange={(e) =>
                      mergeState({
                        searchParams: {
                          ...$.searchParams,
                          [$.tabType]: { ...$.searchParams[$.tabType], enqueteName: e.target.value },
                        },
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="px-2">
                <FloatingLabel controlId="questionId" label="設問ID" data-testid={testid('questionId')}>
                  <Form.Control
                    type="number"
                    placeholder="設問ID"
                    value={$.searchParams[$.tabType].questionId || ''}
                    onChange={(e) =>
                      mergeState({
                        searchParams: {
                          ...$.searchParams,
                          [$.tabType]: { ...$.searchParams[$.tabType], questionId: Number(e.target.value) },
                        },
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col className="px-2">
                <FloatingLabel controlId="questionContent" label="設問" data-testid={testid('questionContent')}>
                  <Form.Control
                    type="text"
                    placeholder="設問"
                    value={$.searchParams[$.tabType].questionContent || ''}
                    onChange={(e) =>
                      mergeState({
                        searchParams: {
                          ...$.searchParams,
                          [$.tabType]: { ...$.searchParams[$.tabType], questionContent: e.target.value },
                        },
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
              <Col className="px-2">
                <FloatingLabel controlId="clientName" label="管理クライアント" data-testid={testid('clientName')}>
                  <Form.Control
                    type="text"
                    placeholder="管理クライアント"
                    value={$.searchParams[$.tabType].clientName || ''}
                    onChange={(e) =>
                      mergeState({
                        searchParams: {
                          ...$.searchParams,
                          [$.tabType]: { ...$.searchParams[$.tabType], clientName: e.target.value },
                        },
                      })
                    }
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center">
              <Col className="col-4 px-2">
                <Button className="w-100 py-2" onClick={onSearch} data-testid={testid('search-button')}>
                  検索
                </Button>
              </Col>
            </Row>
          </Form.Group>
          <Table className="text-nowrap" data-testid={testid('table')}>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>アンケートID</th>
                <th>アンケート名 / 説明</th>
                <th>管理クライアント</th>
              </tr>
            </thead>
            <tbody>
              {$.listsPerPage[$.tabType].map(({ enqueteId, enqueteName, description, clientName: cName }, i) => (
                <tr key={enqueteId} className="align-middle">
                  <td>
                    <Form.Check
                      id={`${$.tabType}${enqueteId}`}
                      type="radio"
                      name="radioGroup01"
                      data-testid={testid('radio', i)}
                      checked={$.currentEnqueteId === enqueteId}
                      onChange={() => mergeState({ currentEnqueteId: enqueteId, currentEnqueteName: enqueteName })}
                    />
                  </td>
                  <td>{enqueteId}</td>
                  <td>
                    {enqueteName}
                    <br />
                    <span className="text-secondary">{description}</span>
                  </td>
                  <td>{cName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="mt-4" data-testid={testid('pagination')}>
            <PaginationWithEllipsis
              currentPage={$.currentPages[$.tabType]}
              totalPage={$.totalPages[$.tabType]}
              handleClick={(page) => {
                if (!page || page > $.totalPages[$.tabType]) return;
                mergeState({
                  listsPerPage: {
                    ...$.listsPerPage,
                    [$.tabType]: $.lists[$.tabType].slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
                  },
                  currentPages: { ...$.currentPages, [$.tabType]: page },
                });
              }}
            />
          </div>
        </>
      }
    />
  );
};
