import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from '../../../atoms/Button';
import { DispatchSetState } from '../../../../interfaces/utils';
import { Modal } from '../../../molecules/Modal';
import type { ModalState as ParentState } from '../../../pages/Customer/CustomerAuthHistoryPage';
import { createTestId } from '../../../../utils/functions';
import { CustomerSmsAuthModalForms } from '../../Forms/CustomerSmsAuthModalForms';
import { useLargeState } from '../../../../hooks/useLargeState';
import { AuthHistorySaveFormResponse } from '../../../../api-client';

interface Props {
  isModal: boolean;
  forms: ParentState['forms'];
  setForms: DispatchSetState<ParentState['forms']>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide: () => any;
  submit: (body: ParentState['forms']) => Promise<{ returnFlg: boolean; errorMessage: string[] }>;
  reload: () => void;
  isEdit: boolean;
}

interface ModalState {
  isResultModal: boolean;
  modalMessageList: string[];
  clickFunc: () => void;
}

export const CustomerSmsAuthModal: React.FC<Props> = ({ isModal, onHide, forms, setForms, submit, reload, isEdit }) => {
  const testid = createTestId(CustomerSmsAuthModal);

  const { state: $formData, useBindSet } = useLargeState<{ forms: ParentState['forms'] }>({ forms });

  const setFormData = useBindSet('forms');
  useEffect(() => setForms($formData.forms), [setForms, $formData]);

  const { state: $modal, mergeState } = useLargeState<ModalState>({
    isResultModal: false,
    modalMessageList: [],
    clickFunc: onHide,
  });

  return (
    <>
      <Modal
        centered
        isModal={$modal.isResultModal}
        body={
          <>
            {$modal.modalMessageList.map((message) => {
              return (
                <p key={message} className="text-center">
                  {message}
                </p>
              );
            })}
            <div className="text-center">
              <Button
                data-testid={testid('ok-button')}
                onClick={() => {
                  $modal.clickFunc();
                  mergeState({ isResultModal: false });
                }}
              >
                OK
              </Button>
            </div>
          </>
        }
      />
      <Modal
        closeButton
        centered
        scrollable
        size="lg"
        isModal={isModal}
        onHide={onHide}
        body={
          <>
            <Form>
              <CustomerSmsAuthModalForms forms={forms} setFormData={setFormData} isEdit={isEdit} />
            </Form>
            <Button className="mt-2 me-2" data-testid={testid('cancel-button')} onClick={onHide}>
              キャンセル
            </Button>
            <Button
              className="mt-2"
              data-testid={testid('submit-button')}
              disabled={!$formData.forms.phoneNumber}
              onClick={() => {
                const postForm: AuthHistorySaveFormResponse = {
                  customerId: forms.customerId,
                  disableFlg: forms.disableFlg,
                  id: forms.id,
                  occurrenceAt: forms.occurrenceAt === '' ? undefined : forms.occurrenceAt,
                  phoneNumber: forms.phoneNumber,
                  successFlg: forms.successFlg,
                };
                submit(postForm).then((res) => {
                  if (res.returnFlg) {
                    mergeState({
                      isResultModal: true,
                      modalMessageList: [isEdit ? '更新しました' : '追加しました'],
                      clickFunc: reload,
                    });
                  } else {
                    mergeState({ isResultModal: true, modalMessageList: res.errorMessage, clickFunc: onHide });
                  }
                });
              }}
            >
              {isEdit ? '更新' : '追加'}
            </Button>
          </>
        }
      />
    </>
  );
};
