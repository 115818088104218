import React from 'react';
import { Form } from 'react-bootstrap';
import { Button } from '../../../atoms/Button';
import { DispatchSetState } from '../../../../interfaces/utils';
import { Modal } from '../../../molecules/Modal';
import type { MailInfoState as ParentState } from '../../../pages/Customer/Card/MailInfoCard';
import { createTestId } from '../../../../utils/functions';
import { CustomerDetailContext } from '../../../../store/customerInfoStore';
import { CustomerMailInfoEditModalForms } from '../../Forms/CustomerMailInfoEditModalForms';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { useLargeState } from '../../../../hooks/useLargeState';

interface Props {
  isModal: boolean;
  forms: ParentState['forms'];
  setForms: DispatchSetState<ParentState['forms']>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onHide: () => any;
}

interface ModalState {
  isOkModal: boolean;
  message: string;
}

export const CustomerMailInfoEditModal: React.FC<Props> = ({ isModal, onHide, forms, setForms }) => {
  const testid = createTestId(CustomerMailInfoEditModal);

  const state = useSafeContext(CustomerDetailContext);
  const { state: $modal, mergeState } = useLargeState<ModalState>({ isOkModal: false, message: '' });

  return (
    <>
      <Modal
        centered
        isModal={$modal.isOkModal}
        body={
          <>
            <p className="text-center">{$modal.message}</p>
            <div className="text-center">
              <Button data-testid={testid('ok-button')} onClick={() => state.reload()}>
                OK
              </Button>
            </div>
          </>
        }
      />
      <Modal
        closeButton
        centered
        scrollable
        size="lg"
        isModal={isModal}
        onHide={onHide}
        title="メール"
        body={
          <>
            <Form>
              <CustomerMailInfoEditModalForms forms={forms} setForms={setForms} />
            </Form>
            <Button
              className="mt-2 me-2"
              data-testid={testid('submit-button')}
              onClick={async () => {
                await state.mail(forms).then((updataFlg: boolean) => {
                  if (updataFlg) {
                    mergeState({ isOkModal: true, message: '更新しました' });
                  } else {
                    mergeState({ isOkModal: true, message: 'エラーが発生しました' });
                  }
                });
              }}
            >
              更新
            </Button>
            <Button className="mt-2" data-testid={testid('cancel-button')} onClick={onHide}>
              キャンセル
            </Button>
          </>
        }
      />
    </>
  );
};
