import { Form } from 'react-bootstrap';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { Modal } from '../../molecules/Modal';
import { Button } from '../../atoms/Button';
import { IncResultOutputResponse } from '../../../api-client/domains';
import { PointApi } from '../../../api-client/api/point-api';

interface Props {
  customerId: number;
  amount: number;
  isShow: boolean;
  setIsShow: Dispatch<SetStateAction<boolean>>;
}

type FormState = {
  amount?: number;
  internalCommnet?: string;
  messageForcustomer?: string;
};
export const PointDecreaseModal: React.FC<Props> = ({ customerId, amount, isShow, setIsShow }) => {
  const { register, handleSubmit } = useForm<FormState>();
  const onHide = () => setIsShow(false);

  const onSubmit = (form: FormState) => {
    new PointApi()
      .pointDecrease({
        customerId,
        amount: form.amount ?? 0,
        internalComment: form.internalCommnet ?? '',
        messageForCustomer: form.messageForcustomer ?? '',
      })
      .then(() => {
        window.alert('完了しました');
        window.location.reload();
      })
      .catch((error: AxiosError<IncResultOutputResponse>) => {
        window.alert(`${error.response?.data.errorMessage}`);
      });
  };

  return (
    <Modal
      centered
      scrollable
      isModal={isShow}
      onHide={onHide}
      title="ポイント減算"
      size="lg"
      body={
        <>
          <div>会員Id：{customerId}</div>
          <div>所持ポイント：{amount}</div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>減算ポイント</Form.Label>
              <Form.Control type="number" autoComplete="off" {...register('amount')} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>内部用コメント</Form.Label>
              <Form.Control as="textarea" autoComplete="off" {...register('internalCommnet')} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>ユーザ向けメッセージ</Form.Label>
              <Form.Control as="textarea" autoComplete="off" {...register('messageForcustomer')} required />
            </Form.Group>
            <Button type="submit">実行</Button>
            <Button
              variant="outline-primary"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setIsShow(false);
              }}
            >
              戻る
            </Button>
          </Form>
        </>
      }
    />
  );
};
