import React, { useMemo, useEffect, useCallback } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import type { MailBulkDeliveryDetailState, FormState } from '../../pages/MailBulkDelivery/MailBulkDeliveryDetailPage';
import { Modal } from '../../molecules/Modal';

export type State = MailBulkDeliveryDetailState['forms'];
export type TemplateTypeState = { templateType: number; isModal: boolean; title: string; content: string };

interface Props {
  setForms: DispatchSetState<State>;
  formData: State;
  templateDataList: MailBulkDeliveryDetailState['templateList'];
}

export type TestIds = keyof State | 'templateType' | 'copy-button' | 'ok-button' | 'cancel-button';

export const MailBulkDeliveryDetailForms: React.FC<Props> = React.memo(({ setForms, formData, templateDataList }) => {
  const testId = createTestId<TestIds>(MailBulkDeliveryDetailForms);

  const { state: $form, onChangeSet, mergeState } = useLargeState<State>(formData);
  const {
    state: $template,
    onChangeSet: onChangeTemplate,
    mergeState: mergeTemplate,
  } = useLargeState<TemplateTypeState>({ templateType: -1, isModal: false, title: '', content: '' });

  useEffect(() => {
    setForms({ ...$form });
  }, [setForms, $form]);

  const options: [string, number][] = useMemo(
    () =>
      Object.entries({
        ファンくる: 0,
        Tモニター: 1,
      }),
    []
  );

  const targetTypeItem: [string, keyof FormState][] = useMemo(
    () =>
      Object.entries({
        会員ID: 'targetCustomerId',
        メールアドレス: 'targetMailAddress',
        応募ID: 'targetApplyId',
      }),
    []
  );

  const notificationTypeRadioItem: [string, number][] = useMemo(
    () =>
      Object.entries({
        通知なし: 0,
        通知する: 1,
        '通知する（メールに本文を直接送らない）': 2,
      }),
    []
  );

  const templateItemList: [string, number][] = useMemo(() => {
    return templateDataList.map((item) => [item.type, item.mailTemplateId]);
  }, [templateDataList]);

  const onclickCopy = useCallback(
    (mailTemplateId: number) => {
      if (mailTemplateId === -1) {
        return;
      }

      const templateItem: MailBulkDeliveryDetailState['templateList'] = templateDataList.filter(
        (item) => item.mailTemplateId === mailTemplateId
      );
      if (templateItem.length === 0) {
        return;
      }
      mergeTemplate({ title: templateItem[0].type, content: templateItem[0].content, isModal: true });
    },
    [mergeTemplate, templateDataList]
  );

  return (
    <>
      <Modal
        centered
        isModal={$template.isModal}
        body={
          <>
            <p className="text-center">
              「{$template.title}」の内容をタイトルと本文に上書きします。 よろしいでしょうか？
            </p>
            <div className="text-center">
              <Button
                data-testid={testId('ok-button')}
                className="me-2"
                onClick={() => {
                  mergeState({ title: $template.title, content: $template.content });
                  mergeTemplate({ isModal: false });
                }}
              >
                OK
              </Button>
              <Button
                data-testid={testId('cancel-button')}
                onClick={() => {
                  mergeTemplate({ isModal: false });
                }}
              >
                キャンセル
              </Button>
            </div>
          </>
        }
      />
      <Form.Group
        data-testid={testId('mailBulkDeliveryId')}
        controlId="mailBulkDeliveryId"
        key="mailBulkDeliveryId"
        className="pb-3"
      >
        <Form.Label>ID</Form.Label>
        <Form.Control type="number" autoComplete="off" value={$form.mailBulkDeliveryId || ''} readOnly disabled />
      </Form.Group>
      <Form.Group data-testid={testId('status')} controlId="status" key="status" className="pb-3">
        <Form.Label>ステータス</Form.Label>
        <Form.Control type="text" autoComplete="off" value={$form.status || ''} readOnly disabled />
      </Form.Group>
      <Form.Group
        data-testid={testId('targetOemType')}
        controlId="targetOemType"
        key="targetOemType"
        className="col-md-6"
      >
        <Form.Label>対象会員</Form.Label>
        <Form.Select value={$form.targetOemType} onChange={onChangeSet('targetOemType', String)}>
          {options.map(([label, value]) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group>
        <Form.Label>配信対象会員</Form.Label>
      </Form.Group>
      <Row>
        {targetTypeItem.map(([label, value], i) => (
          <Col key={`${value}-chek-col`}>
            <Form.Group data-testid={testId('targetType', i)} controlId={label} key={label}>
              <Form.Check
                inline
                type="radio"
                key={`${label}-checkbox`}
                name="targetType"
                label={label}
                value={value}
                onChange={onChangeSet('targetType', String)}
                checked={$form.targetType === value}
              />
            </Form.Group>
          </Col>
        ))}
      </Row>
      <Row>
        {targetTypeItem.map(([label, value]) => (
          <Col key={`${value}-text-col`}>
            <Form.Group data-testid={testId(`${value}`)} controlId={value} key={value}>
              <Form.Control
                as="textarea"
                key={`${label}-textarea`}
                autoComplete="off"
                rows={5}
                value={$form[value] || ''}
                onChange={onChangeSet(value, String)}
                disabled={$form.targetType ? $form.targetType !== value : true}
              />
            </Form.Group>
          </Col>
        ))}
        <span>※ 1行に1つ指定を行います。</span>
        <br />
        <span>※ Excelのシートからコピペ可能です。</span>
        <br />
        <span>※ 最大1000件を目安に設定ください</span>
      </Row>
      <Form.Group data-testid={testId('templateType')} controlId="templateType" key="templateType" className="pb-3">
        <Form.Label>テンプレートからコピーする</Form.Label>
        <Row>
          <Col>
            <Form.Select value={$template.templateType || -1} onChange={onChangeTemplate('templateType', Number)}>
              <option key="noselect" value={-1}>
                選択してください
              </option>
              {templateItemList.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col>
            <Button
              data-testid={testId('copy-button')}
              onClick={() => {
                onclickCopy($template.templateType);
              }}
            >
              タイトル・本文へコピー
            </Button>
          </Col>
        </Row>
      </Form.Group>
      <Form.Group data-testid={testId('title')} controlId="title" key="title" className="pb-3">
        <Form.Label>タイトル</Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$form.title || ''}
          onChange={onChangeSet('title', String)}
        />
      </Form.Group>
      <Form.Group data-testid={testId('content')} controlId="content" key="content" className="pb-3">
        <Form.Label>本文</Form.Label>
        <Form.Control
          as="textarea"
          autoComplete="off"
          rows={5}
          value={$form.content || ''}
          onChange={onChangeSet('content', String)}
        />
        <span>※ $(member_name)：会員名（メールアドレスで指定時はメールアドレスになります）</span>
        <br />
        &emsp; <span> $(shop_name):店舗名（応募IDによる対象会員指定時のみ）</span>
        <br />
        &emsp;<span> $(monitor_name):モニター名（応募IDによる対象会員指定時のみ）</span>
      </Form.Group>
      <Row>
        <Form.Label>マイページのお知らせ</Form.Label>
        <br />
        {notificationTypeRadioItem.map(([label, value], i) => (
          <Form.Group
            data-testid={testId('notificationType', i)}
            controlId={`notificationType-${value}`}
            key={`notificationType-${value}`}
            className={value !== 2 ? 'pb-3 col-md-2' : 'pb-3 col-md-6'}
          >
            <Form.Check
              type="radio"
              key={value}
              name="notificationType"
              label={label}
              value={value}
              onChange={onChangeSet('notificationType', Number)}
              checked={$form.notificationType === value}
            />
          </Form.Group>
        ))}
      </Row>
      <Row>
        <Form.Group data-testid={testId('executeAt')} controlId="executeAt" key="executeAt" className="col-md-6">
          <Form.Label>送信日時</Form.Label>
          <Form.Control
            type="datetime-local"
            autoComplete="off"
            value={$form.executeAt || ''}
            onChange={onChangeSet('executeAt', String)}
          />
          <p>※ 日中（09時～21時）に配信が終わるように指定すること</p>
        </Form.Group>
      </Row>
    </>
  );
});
