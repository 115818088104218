import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  CommonMasterListApi,
  CommonMasterListOutputResponse,
  CustomerTagListApi,
  F4DetailInfoApi,
  F4DetailInfoOutputResponse,
  F4DetailUpdateApi,
  F4DetailUpdateFormResponse,
  F4TestDeliveryApi,
  F4TestDeliveryFormResponse,
  IncResultOutputResponse,
  MailMagazineCustomerTagOutputResponse,
  MailMagazinePrefectureFormResponse,
} from '../../../api-client';
import { Url } from '../../../constants/Url';
import { formatISODate } from '../../../utils/functions';
import { Datepicker } from '../../atoms/Datepicker';
import { Title } from '../../atoms/Title';

const streamingModeTypeList = [
  {
    id: 0,
    name: '本番配信',
  },
  {
    id: 1,
    name: '配信停止',
  },
];

export const F4MailDetailModifyPage = () => {
  const location = useLocation<{ id: number }>();
  const history = useHistory();

  const infoApi = new F4DetailInfoApi();
  const updateApi = new F4DetailUpdateApi();
  const testApi = new F4TestDeliveryApi();
  const commonMasterListApi = new CommonMasterListApi();
  const customerTagListApi = new CustomerTagListApi();

  const [data, setData] = useState<F4DetailInfoOutputResponse>({
    memo: '',
    title: '',
    topTitle: '',
    bannerText: '',
    bannerHTML: '',
    sendScheduleTimeStamp: '',
    areaList: [],
    itemList1Title: '',
    itemList1: '',
    itemList2Title: '',
    itemList2: '',
    itemList3Title: '',
    itemList3: '',
    itemList4Title: '',
    itemList4: '',
    customerTagList: { customerTagList: [] },
    sendInvisibleConflictFlg: false,
    sendMode: 0,
    testMailaddressList: [],
  });

  // 配信日時（日付）
  const [streamingDay, setStreamingDay] = useState<string>();
  // 配信日時（時間）
  const [streamingTime, setStreamingTime] = useState<string>();
  // テスト配信
  const [testStreamingText, setTestStreamingText] = useState<string>();

  // 都道府県一覧
  const [prefectures, setPrefectures] = useState<CommonMasterListOutputResponse[]>();
  // 会員属性一覧
  const [customerTagList, setCustomerTagList] = useState<MailMagazineCustomerTagOutputResponse>({
    customerTagList: [],
  });

  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const [testResult, setTestResult] = useState<IncResultOutputResponse>();

  const diff = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() + 9);
    return parsedate;
  };
  const diffInitial = (parsedate: Date) => {
    parsedate.setHours(parsedate.getHours() - 9);
    return parsedate;
  };

  useEffect(() => {
    // 詳細画面に直接アクセスされた場合はエラー画面に遷移させる。
    if (location.pathname === '/keisai/f4-mail-detail' && location.state === undefined) {
      history.push(Url.COMMON_ERROR);
      return;
    }
    // パラメータが渡されてきていなければInfoAPI叩かない
    if (location.state !== undefined) {
      infoApi.f4DetailInfo(location.state.id).then((res: AxiosResponse<F4DetailInfoOutputResponse>) => {
        if (res.data?.sendScheduleTimeStamp) {
          setStreamingDay(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'yyyy-MM-dd')
          );
          setStreamingTime(
            formatISODate(diffInitial(new Date(res.data?.sendScheduleTimeStamp)).toISOString(), 'HH:mm:ss')
          );
        }

        // テスト配信テキストの加工
        if (res.data.testMailaddressList) {
          for (let index = 0; index < res.data.testMailaddressList.length; index += 1) {
            if (res.data.testMailaddressList.length !== index + 1) {
              res.data.testMailaddressList[index] = `${res.data.testMailaddressList[index]}\n`;
            }
          }
          setTestStreamingText(res.data.testMailaddressList.join(''));
        }
        setData(res.data);
      });
    }

    commonMasterListApi.commonMasterList('prefecture').then((res: AxiosResponse<CommonMasterListOutputResponse[]>) => {
      setPrefectures(res.data);
    });

    customerTagListApi.customerTagList().then((res: AxiosResponse<MailMagazineCustomerTagOutputResponse>) => {
      setCustomerTagList(res.data);
    });
  }, []);

  // エリアSelect Changeイベント
  const onChangeMultiSelect = (selectedContents: MultiValue<{ id: number; name: string }>) => {
    const copyAreaList: MailMagazinePrefectureFormResponse[] = [];

    for (let index = 0; index < selectedContents.length; index += 1) {
      copyAreaList.push({
        prefectureId: selectedContents[index].id,
        prefectureName: selectedContents[index].name,
        selectedFlg: true,
      });
    }

    setData({ ...data, areaList: copyAreaList });
  };

  // 会員属性Select Changeイベント
  const onChangeCustomerSelect = (selectedContents: SingleValue<{ tagId: number; tagName: string }>, ind: number) => {
    if (selectedContents !== null) {
      const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
      const targetSelectionList = customerTagList.customerTagList
        .find((r) => {
          return r.tagId === selectedContents.tagId;
        })
        ?.customerTagAnswerList.slice(0, 1);

      if (targetSelectionList) {
        setData({
          ...data,
          customerTagList: {
            customerTagList: addList.customerTagList.map((_, index) => {
              return index === ind
                ? {
                    ..._,
                    tagId: selectedContents.tagId,
                    tagName: selectedContents.tagName,
                    customerTagAnswerList: targetSelectionList,
                  }
                : { ..._ };
            }),
          },
        });
      }
    }
  };

  // 保存イベント
  const submit = () => {
    let sendScheduleTimeStamp = '';

    if (streamingDay !== undefined && streamingDay !== '' && streamingTime !== undefined && streamingTime !== '') {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} ${streamingTime}`))
        .toISOString()
        .slice(0, -5);
    } else if (
      streamingDay !== undefined &&
      streamingDay !== '' &&
      (streamingTime === undefined || streamingTime === '')
    ) {
      sendScheduleTimeStamp = diff(new Date(`${streamingDay} 00:00:00`))
        .toISOString()
        .slice(0, -5);
    }

    const param: F4DetailUpdateFormResponse = {
      id: location.state === undefined ? undefined : location.state.id,
      memo: data.memo === undefined ? '' : data.memo,
      title: data.title,
      topTitle: data.topTitle,
      bannerText: data.bannerText === undefined ? '' : data.bannerText,
      bannerHTML: data.bannerHTML === undefined ? '' : data.bannerHTML,
      sendScheduleTimeStamp: sendScheduleTimeStamp === '' ? undefined : sendScheduleTimeStamp,
      itemList1Title: data.itemList1Title === undefined ? '' : data.itemList1Title,
      itemList1: data.itemList1,
      itemList2Title: data.itemList2Title === undefined ? '' : data.itemList2Title,
      itemList2: data.itemList2,
      itemList3Title: data.itemList3Title === undefined ? '' : data.itemList3Title,
      itemList3: data.itemList3,
      itemList4Title: data.itemList4Title === undefined ? '' : data.itemList4Title,
      itemList4: data.itemList4,
      areaList: data.areaList,
      customerTagList: data.customerTagList.customerTagList,
      sendInvisibleConflictFlg: data.sendInvisibleConflictFlg === undefined ? false : data.sendInvisibleConflictFlg,
      sendMode: data.sendMode === undefined ? 0 : data.sendMode,
      testMailaddressList: testStreamingText?.split('\n'),
    };

    updateApi.f4DetailUpdate(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setUpdResult(res.data);
    });
  };

  // テスト配信イベント
  const testStreaming = () => {
    const param: F4TestDeliveryFormResponse = {
      title: data.title,
      topTitle: data.topTitle,
      bannerText: data.bannerText,
      bannerHTML: data.bannerHTML,
      testMailaddressList: testStreamingText !== undefined ? testStreamingText.split('\n') : [],
    };

    testApi.f4TestDelivery(param).then((res: AxiosResponse<IncResultOutputResponse>) => {
      setTestResult(res.data);
    });
  };

  return (
    <>
      <Title className="mb-4">{location.state === undefined ? 'F4メール編集画面' : 'F4メール詳細画面'}</Title>
      <div className="d-flex justify-content-end mb-4">
        <Button type="button" onClick={testStreaming} className="ms-2">
          テスト配信
        </Button>
        <Button type="button" onClick={submit} className="ms-2">
          保存
        </Button>
      </div>
      {updResult?.result && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          更新しました。
        </Alert>
      )}
      {testResult?.result && (
        <Alert variant="success" style={{ marginTop: 10 }}>
          テスト配信が完了しました。
        </Alert>
      )}
      {updResult !== undefined && updResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}
      {testResult !== undefined && testResult?.result === false && (
        <Alert
          variant="danger"
          style={{ marginTop: 10 }}
        >{`${testResult.errorMessage} (エラーコード：${testResult.errorCode})`}</Alert>
      )}
      <Form.Group className="mb-4">
        <Form.Label>メモ</Form.Label>
        <Form.Control
          as="textarea"
          value={data.memo}
          onChange={(e) => {
            setData({ ...data, memo: e.target.value });
          }}
          rows={5}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>メール件名</Form.Label>
        <Form.Control
          value={data.title}
          onChange={(e) => {
            setData({ ...data, title: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>メールタイトル</Form.Label>
        <Form.Control
          value={data.topTitle}
          onChange={(e) => {
            setData({ ...data, topTitle: e.target.value });
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>お知らせバナーテキスト</Form.Label>
        <Form.Control
          as="textarea"
          value={data.bannerText}
          onChange={(e) => {
            setData({ ...data, bannerText: e.target.value });
          }}
          rows={5}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>お知らせバナーHTML</Form.Label>
        <Form.Control
          as="textarea"
          value={data.bannerHTML}
          onChange={(e) => {
            setData({ ...data, bannerHTML: e.target.value === '' ? undefined : e.target.value });
          }}
          rows={5}
        />
      </Form.Group>
      <Form.Label>配信日時</Form.Label>
      <Form.Group className="d-flex align-items-center mb-4" controlId="readOnly1" style={{ width: '50%' }}>
        <Datepicker
          value={streamingDay === undefined ? '' : streamingDay}
          className="me-2"
          onChange={(e) => {
            setStreamingDay(e.target.value);
          }}
          data-testid="datepicker"
        />
        <Form.Control
          type="time"
          step="1"
          value={streamingTime === undefined ? '' : streamingTime}
          className="me-2"
          onChange={(e) => {
            setStreamingTime(e.target.value);
          }}
          disabled={streamingDay === undefined || streamingDay === ''}
        />
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>商品リスト1</Form.Label>
        <Form.Group className="ms-4">
          <Form.Label>見出し</Form.Label>
          <Form.Control
            value={data.itemList1Title}
            onChange={(e) => {
              setData({ ...data, itemList1Title: e.target.value });
            }}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Group className="ms-4">
          <Form.Label>リスト</Form.Label>
          <Form.Control
            as="textarea"
            value={data.itemList1}
            onChange={(e) => {
              setData({ ...data, itemList1: e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>商品リスト2</Form.Label>
        <Form.Group className="ms-4">
          <Form.Label>見出し</Form.Label>
          <Form.Control
            value={data.itemList2Title}
            onChange={(e) => {
              setData({ ...data, itemList2Title: e.target.value });
            }}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Group className="ms-4">
          <Form.Label>リスト</Form.Label>
          <Form.Control
            as="textarea"
            value={data.itemList2}
            onChange={(e) => {
              setData({ ...data, itemList2: e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>商品リスト3</Form.Label>
        <Form.Group className="ms-4">
          <Form.Label>見出し</Form.Label>
          <Form.Control
            value={data.itemList3Title}
            onChange={(e) => {
              setData({ ...data, itemList3Title: e.target.value });
            }}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Group className="ms-4">
          <Form.Label>リスト</Form.Label>
          <Form.Control
            as="textarea"
            value={data.itemList3}
            onChange={(e) => {
              setData({ ...data, itemList3: e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group className="mb-4">
        <Form.Label>商品リスト4</Form.Label>
        <Form.Group className="ms-4">
          <Form.Label>見出し</Form.Label>
          <Form.Control
            value={data.itemList4Title}
            onChange={(e) => {
              setData({ ...data, itemList4Title: e.target.value });
            }}
          />
        </Form.Group>
      </Form.Group>
      <Form.Group>
        <Form.Group className="ms-4">
          <Form.Label>リスト</Form.Label>
          <Form.Control
            as="textarea"
            value={data.itemList4}
            onChange={(e) => {
              setData({ ...data, itemList4: e.target.value });
            }}
            rows={5}
          />
        </Form.Group>
      </Form.Group>
      <div className="align-items-center " style={{ width: '49.5%' }}>
        <Form.Check.Label className="mt-4">エリア</Form.Check.Label>
        <Select
          className="ms-4 mt-4 mb-4"
          id="prefecture"
          onChange={(e) => {
            onChangeMultiSelect(e);
          }}
          closeMenuOnSelect={false}
          value={data.areaList.map((d) => {
            return { id: d.prefectureId, name: d.prefectureName };
          })}
          isMulti
          getOptionLabel={(option) => {
            return option.name;
          }}
          getOptionValue={(option) => {
            return option.id.toString();
          }}
          options={prefectures}
          placeholder="エリアを選択してください。"
        />
        <Form.Label>会員属性</Form.Label>
        {data.customerTagList.customerTagList?.map((_, i) => {
          return (
            <Col key={i.toString()} className="col-12 mt-2">
              <Form.Group className="d-flex align-items-center" controlId="readOnly1">
                <Form.Label className="mt-2 me-2 ms-4">属性</Form.Label>
                <Col data-testid="attributeSelect">
                  <Select
                    id="prefecture"
                    onChange={(e) => {
                      onChangeCustomerSelect(e, i);
                    }}
                    closeMenuOnSelect
                    value={data.customerTagList.customerTagList[i]}
                    getOptionLabel={(option) => {
                      return option.tagName;
                    }}
                    getOptionValue={(option) => {
                      return option.tagId.toString();
                    }}
                    options={customerTagList.customerTagList}
                  />
                </Col>
                <Form.Label className="mt-2 me-2 ms-2">選択肢</Form.Label>
                <Col>
                  <Form.Select
                    data-testid="ChoiceSelect"
                    value={data.customerTagList.customerTagList[i].customerTagAnswerList[0].id}
                    onChange={(e) => {
                      const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                      const targetTagName = customerTagList.customerTagList
                        .find((row) => {
                          return row.tagId === Number(data.customerTagList.customerTagList[i].tagId);
                        })
                        ?.customerTagAnswerList.find((r) => {
                          return r.id === Number(e.target.value);
                        })?.tagValue;

                      if (targetTagName) {
                        setData({
                          ...data,
                          customerTagList: {
                            customerTagList: addList.customerTagList.map((row, index) => {
                              return index === i
                                ? {
                                    ...row,
                                    customerTagAnswerList: [{ id: Number(e.target.value), tagValue: targetTagName }],
                                  }
                                : { ...row };
                            }),
                          },
                        });
                      }
                    }}
                  >
                    {customerTagList.customerTagList
                      .find((row) => {
                        return row.tagId === _.tagId;
                      })
                      ?.customerTagAnswerList.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.tagValue}
                        </option>
                      ))}
                  </Form.Select>
                </Col>
              </Form.Group>
            </Col>
          );
        })}
        {customerTagList.customerTagList.length > 0 && (
          <div className="d-flex justify-content-end mb-4 mt-4">
            <Button
              data-testid="add"
              variant="link"
              className="text-secondary p-0"
              onClick={() => {
                const addList: MailMagazineCustomerTagOutputResponse = Object.assign([], data.customerTagList);
                addList.customerTagList.push({
                  tagId: customerTagList.customerTagList[0].tagId,
                  tagName: customerTagList.customerTagList[0].tagName,
                  customerTagAnswerList: customerTagList.customerTagList[0].customerTagAnswerList,
                });

                setData({ ...data, customerTagList: addList });
              }}
            >
              <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
              <span style={{ fontSize: '1rem' }}>追加</span>
            </Button>
          </div>
        )}
        <div className="d-flex mb-4 mt-4">
          <Form.Check.Input
            data-testid="sendInvisibleCheckBox"
            className="flex-shrink-0 me-3"
            type="checkbox"
            name="checkBoxGroup01"
            checked={data.sendInvisibleConflictFlg}
            onChange={(e) => {
              setData({ ...data, sendInvisibleConflictFlg: e.target.checked });
            }}
          />
          <Form.Label className="me-5">クローズド案件を含む</Form.Label>
        </div>
        <Form.Group className="mb-4">
          <Form.Label>配信モード</Form.Label>
          <Form.Select
            value={data.sendMode}
            onChange={(e) => {
              setData({ ...data, sendMode: Number(e.target.value) });
            }}
          >
            {streamingModeTypeList.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </div>
      <Form.Group className="mb-4">
        <Form.Label>テスト配信先</Form.Label>
        <Form.Control
          value={testStreamingText}
          onChange={(e) => {
            setTestStreamingText(e.target.value);
          }}
          as="textarea"
          rows={3}
        />
      </Form.Group>
    </>
  );
};
