import React from 'react';
import { NgAndOtherHistoryDetailOutputResponse } from '../../api-client';
import { uuid, when } from '../../utils/functions';
import { ImageThumbnail } from '../atoms/ImageThumbnail';
import { SideBy } from '../atoms/SideBy';
import { Tag } from '../atoms/Tag';
import { TinyHead } from '../atoms/TinyHead';
import { AnswerAndHisotryType } from '../pages/Correction/constants';

interface Props {
  label: string | null;
  details: NgAndOtherHistoryDetailOutputResponse[];
}
export const HistoryNgList: React.FC<Props> = ({ label, details }) => {
  // const getFontColorClass = (type: number | undefined) => {
  //   if (type === AnswerAndHisotryType.NG) return 'text-danger';
  //   return 'text-black';
  // };

  const getClass = (type: number | undefined) => {
    const commonClassName = 'bg-white mt-2 p-2';
    if (type === AnswerAndHisotryType.NG) return `${commonClassName} ms-5 border border-danger text-danger`;
    return `${commonClassName} me-5 border border-black`;
  };

  return (
    <>
      <TinyHead>{label}</TinyHead>
      <div className="p-3 mt-2" style={{ background: 'rgba(0, 0, 0, 0.05)' }}>
        {details.map((detail) => (
          <div key={uuid()} className={getClass(detail.ngAndOtherHistoryType)}>
            {when(
              detail.ngAndOtherHistoryType === AnswerAndHisotryType.INC_EDIT,
              <>
                <Tag label="回答編集" />
                <br />
              </>
            )}
            {detail.content ? <span>{detail.content}</span> : <></>}
            {detail.answerChoiceList ? (
              <ul>
                {detail.answerChoiceList.map((c) => (
                  <li key={uuid()}>{c.content}</li>
                ))}
              </ul>
            ) : (
              <></>
            )}
            {detail.answerImageUrlList ? (
              <SideBy>
                {detail.answerImageUrlList.map((url) => (
                  <ImageThumbnail key={uuid()} url={url} />
                ))}
              </SideBy>
            ) : (
              <></>
            )}

            <p className="text-secondary mb-0">
              {detail.createAt} {detail.createBy}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};
