import React from 'react';

// TODO: [添削] Storybook未実装
/** h5未満のヘッダ用 */
export const TinyHead: React.FC<{ span?: true }> = ({ children, span }) =>
  span ? (
    <span style={{ fontSize: '1rem' }}>
      <strong>{children}</strong>
    </span>
  ) : (
    <p>
      <strong>{children}</strong>
    </p>
  );
