/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { SqlTemplateCreateFormResponse } from '../domains';
// @ts-ignore
import { SqlTemplateInfoOutputResponse } from '../domains';
// @ts-ignore
import { SqlTemplateListOutputResponse } from '../domains';
// @ts-ignore
import { SqlTemplateUpdateFormResponse } from '../domains';
/**
 * SqlTemplateApi - axios parameter creator
 * @export
 */
export const SqlTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * SQLテンプレート情報をコピーする
         * @summary SQLテンプレートコピー
         * @param {number} sqlTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateCopy: async (sqlTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlTemplateId' is not null or undefined
            assertParamExists('sqlTemplateCopy', 'sqlTemplateId', sqlTemplateId)
            const localVarPath = `/api/sql-template-copy/{sql-template-id}`
                .replace(`{${"sql-template-id"}}`, encodeURIComponent(String(sqlTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLテンプレートを登録する
         * @summary SQLテンプレート登録
         * @param {SqlTemplateCreateFormResponse} sqlTemplateCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateCreate: async (sqlTemplateCreateFormResponse: SqlTemplateCreateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlTemplateCreateFormResponse' is not null or undefined
            assertParamExists('sqlTemplateCreate', 'sqlTemplateCreateFormResponse', sqlTemplateCreateFormResponse)
            const localVarPath = `/api/sql-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sqlTemplateCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLテンプレート情報を削除する
         * @summary SQLテンプレート情報削除
         * @param {number} sqlTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateDelete: async (sqlTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlTemplateId' is not null or undefined
            assertParamExists('sqlTemplateDelete', 'sqlTemplateId', sqlTemplateId)
            const localVarPath = `/api/sql-template/{sql-template-id}`
                .replace(`{${"sql-template-id"}}`, encodeURIComponent(String(sqlTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLテンプレート情報を取得する
         * @summary SQLテンプレート情報取得
         * @param {number} sqlTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateInfo: async (sqlTemplateId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlTemplateId' is not null or undefined
            assertParamExists('sqlTemplateInfo', 'sqlTemplateId', sqlTemplateId)
            const localVarPath = `/api/sql-template/{sql-template-id}`
                .replace(`{${"sql-template-id"}}`, encodeURIComponent(String(sqlTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLテンプレート一覧を取得する
         * @summary SQLテンプレート一覧取得
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateList: async (searchText?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sql-template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchText !== undefined) {
                localVarQueryParameter['searchText'] = searchText;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * SQLテンプレートを更新する
         * @summary SQLテンプレート更新
         * @param {number} sqlTemplateId 
         * @param {SqlTemplateUpdateFormResponse} sqlTemplateUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateUpdate: async (sqlTemplateId: number, sqlTemplateUpdateFormResponse: SqlTemplateUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sqlTemplateId' is not null or undefined
            assertParamExists('sqlTemplateUpdate', 'sqlTemplateId', sqlTemplateId)
            // verify required parameter 'sqlTemplateUpdateFormResponse' is not null or undefined
            assertParamExists('sqlTemplateUpdate', 'sqlTemplateUpdateFormResponse', sqlTemplateUpdateFormResponse)
            const localVarPath = `/api/sql-template/{sql-template-id}`
                .replace(`{${"sql-template-id"}}`, encodeURIComponent(String(sqlTemplateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sqlTemplateUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SqlTemplateApi - functional programming interface
 * @export
 */
export const SqlTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SqlTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * SQLテンプレート情報をコピーする
         * @summary SQLテンプレートコピー
         * @param {number} sqlTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlTemplateCopy(sqlTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlTemplateCopy(sqlTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLテンプレートを登録する
         * @summary SQLテンプレート登録
         * @param {SqlTemplateCreateFormResponse} sqlTemplateCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlTemplateCreate(sqlTemplateCreateFormResponse: SqlTemplateCreateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlTemplateCreate(sqlTemplateCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLテンプレート情報を削除する
         * @summary SQLテンプレート情報削除
         * @param {number} sqlTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlTemplateDelete(sqlTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlTemplateDelete(sqlTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLテンプレート情報を取得する
         * @summary SQLテンプレート情報取得
         * @param {number} sqlTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlTemplateInfo(sqlTemplateId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SqlTemplateInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlTemplateInfo(sqlTemplateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLテンプレート一覧を取得する
         * @summary SQLテンプレート一覧取得
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlTemplateList(searchText?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SqlTemplateListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlTemplateList(searchText, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * SQLテンプレートを更新する
         * @summary SQLテンプレート更新
         * @param {number} sqlTemplateId 
         * @param {SqlTemplateUpdateFormResponse} sqlTemplateUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sqlTemplateUpdate(sqlTemplateId: number, sqlTemplateUpdateFormResponse: SqlTemplateUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sqlTemplateUpdate(sqlTemplateId, sqlTemplateUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SqlTemplateApi - factory interface
 * @export
 */
export const SqlTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SqlTemplateApiFp(configuration)
    return {
        /**
         * SQLテンプレート情報をコピーする
         * @summary SQLテンプレートコピー
         * @param {number} sqlTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateCopy(sqlTemplateId: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.sqlTemplateCopy(sqlTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLテンプレートを登録する
         * @summary SQLテンプレート登録
         * @param {SqlTemplateCreateFormResponse} sqlTemplateCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateCreate(sqlTemplateCreateFormResponse: SqlTemplateCreateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.sqlTemplateCreate(sqlTemplateCreateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLテンプレート情報を削除する
         * @summary SQLテンプレート情報削除
         * @param {number} sqlTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateDelete(sqlTemplateId: number, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.sqlTemplateDelete(sqlTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLテンプレート情報を取得する
         * @summary SQLテンプレート情報取得
         * @param {number} sqlTemplateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateInfo(sqlTemplateId: number, options?: any): AxiosPromise<SqlTemplateInfoOutputResponse> {
            return localVarFp.sqlTemplateInfo(sqlTemplateId, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLテンプレート一覧を取得する
         * @summary SQLテンプレート一覧取得
         * @param {string} [searchText] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateList(searchText?: string, options?: any): AxiosPromise<Array<SqlTemplateListOutputResponse>> {
            return localVarFp.sqlTemplateList(searchText, options).then((request) => request(axios, basePath));
        },
        /**
         * SQLテンプレートを更新する
         * @summary SQLテンプレート更新
         * @param {number} sqlTemplateId 
         * @param {SqlTemplateUpdateFormResponse} sqlTemplateUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sqlTemplateUpdate(sqlTemplateId: number, sqlTemplateUpdateFormResponse: SqlTemplateUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.sqlTemplateUpdate(sqlTemplateId, sqlTemplateUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SqlTemplateApi - object-oriented interface
 * @export
 * @class SqlTemplateApi
 * @extends {BaseAPI}
 */
export class SqlTemplateApi extends BaseAPI {
    /**
     * SQLテンプレート情報をコピーする
     * @summary SQLテンプレートコピー
     * @param {number} sqlTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlTemplateApi
     */
    public sqlTemplateCopy(sqlTemplateId: number, options?: AxiosRequestConfig) {
        return SqlTemplateApiFp(this.configuration).sqlTemplateCopy(sqlTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLテンプレートを登録する
     * @summary SQLテンプレート登録
     * @param {SqlTemplateCreateFormResponse} sqlTemplateCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlTemplateApi
     */
    public sqlTemplateCreate(sqlTemplateCreateFormResponse: SqlTemplateCreateFormResponse, options?: AxiosRequestConfig) {
        return SqlTemplateApiFp(this.configuration).sqlTemplateCreate(sqlTemplateCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLテンプレート情報を削除する
     * @summary SQLテンプレート情報削除
     * @param {number} sqlTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlTemplateApi
     */
    public sqlTemplateDelete(sqlTemplateId: number, options?: AxiosRequestConfig) {
        return SqlTemplateApiFp(this.configuration).sqlTemplateDelete(sqlTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLテンプレート情報を取得する
     * @summary SQLテンプレート情報取得
     * @param {number} sqlTemplateId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlTemplateApi
     */
    public sqlTemplateInfo(sqlTemplateId: number, options?: AxiosRequestConfig) {
        return SqlTemplateApiFp(this.configuration).sqlTemplateInfo(sqlTemplateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLテンプレート一覧を取得する
     * @summary SQLテンプレート一覧取得
     * @param {string} [searchText] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlTemplateApi
     */
    public sqlTemplateList(searchText?: string, options?: AxiosRequestConfig) {
        return SqlTemplateApiFp(this.configuration).sqlTemplateList(searchText, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * SQLテンプレートを更新する
     * @summary SQLテンプレート更新
     * @param {number} sqlTemplateId 
     * @param {SqlTemplateUpdateFormResponse} sqlTemplateUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SqlTemplateApi
     */
    public sqlTemplateUpdate(sqlTemplateId: number, sqlTemplateUpdateFormResponse: SqlTemplateUpdateFormResponse, options?: AxiosRequestConfig) {
        return SqlTemplateApiFp(this.configuration).sqlTemplateUpdate(sqlTemplateId, sqlTemplateUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
