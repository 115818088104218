import { AdministratedCookieSaveFormResponse, CustomerInfoApi } from '../api-client';
import { TokenCookies } from './CookiesService';

export const administratedCookieSave = (): void => {
  const customerApi = new CustomerInfoApi();
  const cookie = new TokenCookies();
  const form: AdministratedCookieSaveFormResponse = {
    cookie: cookie.getCustomerCookie(),
  };
  // noinspection JSIgnoredPromiseFromCall
  customerApi.administratedCookieSave(form);
};
