import React from 'react';
import { Page, Text, Document, StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'NotoSansCJKjp',
  src: '/fonts/Nasu-Regular-20200227.ttf',
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'NotoSansCJKjp',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'NotoSansCJKjp',
  },
  author: {
    fontSize: 12,
    fontFamily: 'NotoSansCJKjp',
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'NotoSansCJKjp',
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'left',
    fontFamily: 'NotoSansCJKjp',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'NotoSansCJKjp',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
    fontFamily: 'NotoSansCJKjp',
  },
});
// Excelダウンロード
const TestPdfPage: React.FC = () => {
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.header} fixed>
          ~ react-pdf ~
        </Text>
        <Text style={styles.title}>React-pdfによるPDF作成</Text>
        <Text style={styles.author}>あいうえお</Text>
        <Text style={styles.subtitle}>1.Reactとreact-pdfをインストールします</Text>
        <Text style={styles.text}>
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、そこはかとなく書きつくれば、
          あやしうこそものぐるほしけれ。（Wikipediaより）つれづれなるまゝに、日暮らし、硯にむかひて、
          心にうつりゆくよしなし事を、そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、
          そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
        </Text>
        <Text style={styles.text}>
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、そこはかとなく書きつくれば、
          あやしうこそものぐるほしけれ。（Wikipediaより）つれづれなるまゝに、日暮らし、硯にむかひて、
          心にうつりゆくよしなし事を、そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、
          そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
        </Text>
        <Text style={styles.text}>
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、そこはかとなく書きつくれば、
          あやしうこそものぐるほしけれ。（Wikipediaより）つれづれなるまゝに、日暮らし、硯にむかひて、
          心にうつりゆくよしなし事を、そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、
          そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
        </Text>
        <Text style={styles.text}>
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、そこはかとなく書きつくれば、
          あやしうこそものぐるほしけれ。（Wikipediaより）つれづれなるまゝに、日暮らし、硯にむかひて、
          心にうつりゆくよしなし事を、そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、
          そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
        </Text>
        <Text style={styles.subtitle} break>
          2.日本語フォントでかなり苦労する
        </Text>
        <Text style={styles.text}>
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、そこはかとなく書きつくれば、
          あやしうこそものぐるほしけれ。（Wikipediaより）つれづれなるまゝに、日暮らし、硯にむかひて、
          心にうつりゆくよしなし事を、そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、
          そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
        </Text>
        <Text style={styles.text}>
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、そこはかとなく書きつくれば、
          あやしうこそものぐるほしけれ。（Wikipediaより）つれづれなるまゝに、日暮らし、硯にむかひて、
          心にうつりゆくよしなし事を、そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
          つれづれなるまゝに、日暮らし、硯にむかひて、心にうつりゆくよしなし事を、
          そこはかとなく書きつくれば、あやしうこそものぐるほしけれ。（Wikipediaより）
        </Text>
        <Text style={styles.text}>
          Yendo, pues, caminando nuestro flamante aventurero, iba hablando consigo mesmo, y diciendo: —¿Quién duda, sino
          que en los venideros tiempos, cuando salga a luz la verdadera historia de mis famosos hechos, que el sabio que
          los escribiere no ponga, cuando llegue a contar esta mi primera salida tan de mañana, desta manera?: Apenas
          había el rubicundo Apolo tendido por la faz de la ancha y espaciosa tierra las doradas hebras de sus hermosos
          cabellos, y apenas los pequeños y pintados pajarillos con sus arpadas lenguas habían saludado con dulce y
          meliflua armonía la venida de la rosada Aurora, que, dejando la blanda cama del celoso marido, por las puertas
          y balcones del manchego horizonte a los mortales se mostraba, cuando el famoso caballero don Quijote de la
          Mancha, dejando las ociosas plumas, subió sobre su famoso caballo Rocinante y comenzó a caminar por el antiguo
          y conocido Campo de Montiel.
        </Text>
        <Text style={styles.text}>
          Y era la verdad que por él caminaba; y añadió diciendo: —Dichosa edad y siglo dichoso aquel adonde saldrán a
          luz las famosas hazañas mías, dignas de entallarse en bronces, esculpirse en mármoles y pintarse en tablas,
          para memoria en lo futuro. ¡Oh tú, sabio encantador, quienquiera que seas, a quien ha de tocar el ser
          coronista desta peregrina historia! Ruégote que no te olvides de mi buen Rocinante, compañero eterno mío en
          todos mis caminos y carreras.
        </Text>
        <Text style={styles.text}>
          Luego volvía diciendo, como si verdaderamente fuera enamorado: —¡Oh princesa Dulcinea, señora deste cautivo
          corazón! Mucho agravio me habedes fecho en despedirme y reprocharme con el riguroso afincamiento de mandarme
          no parecer ante la vuestra fermosura. Plégaos, señora, de membraros deste vuestro sujeto corazón, que tantas
          cuitas por vuestro amor padece. Con estos iba ensartando otros disparates, todos al modo de los que sus libros
          le habían enseñado, imitando en cuanto podía su lenguaje. Con esto caminaba tan despacio, y el sol entraba tan
          apriesa y con tanto ardor, que fuera bastante a derretirle los sesos, si algunos tuviera
        </Text>
        <Text style={styles.text}>
          Casi todo aquel día caminó sin acontecerle cosa que de contar fuese, de lo cual se desesperaba, porque
          quisiera topar luego luego con quien hacer experiencia del valor de su fuerte brazo. Autores hay que dicen que
          la primera aventura que le avino fue la del Puerto Lápice, otros dicen que la de los molinos de viento; pero
          lo que yo he podido averiguar en este caso, y lo que he hallado escrito en los anales de la Mancha, es que él
          anduvo todo aquel día, y, al anochecer, su rocín y él se hallaron cansados y muertos de hambre, y que, mirando
          a todas partes por ver si descubriría algún castillo o alguna majada de pastores donde recogerse y adonde
          pudiese remediar su mucha hambre y necesidad, vio, no lejos del camino por donde iba, una venta,que fue como
          si viera una estrella que, no a los portales, sino a los alcázares de su redención le encaminaba. Diose priesa
          a caminar, y llegó a ella a tiempo que anochecía.
        </Text>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  );
};

export default TestPdfPage;
