import { Button } from 'react-bootstrap';
import * as React from 'react';

export const SubmitPrimaryButton = (props: any) => {
  const { children } = props;
  return (
    <Button variant="primary" type="submit">
      {children}
    </Button>
  );
};
