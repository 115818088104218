import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Table, Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { AxiosResponse } from 'axios';
import {
  ReceiptPriceApi,
  ReceiptPriceModifyingDisplayOutputResponse,
  ReceiptPriceModifyingDisplayFormResponse,
  ReceiptPriceModifyingUpdateOutputResponse,
} from '../../../../api-client';
import { useLargeState } from '../../../../hooks/useLargeState';
import { Url } from '../../../../constants/Url';
import { DiffenceAmountComponent } from '../../Table/ReceiptPriceModifyHistoryTable';

interface Props {
  isModal: boolean;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  receiptPriceParam: ReceiptPriceModifyingDisplayFormResponse;
  receiptPriceModifyDisplay: ReceiptPriceModifyingDisplayOutputResponse | undefined;
}

interface Forms {
  workingMemo?: string;
  customerNoticeFlg: boolean;
  title?: string;
  content?: string;
}

export const ReceiptPriceModifyConfirmModal: React.FC<Props> = ({
  isModal,
  setIsModal,
  receiptPriceParam,
  receiptPriceModifyDisplay,
}) => {
  const handleClose = () => {
    setIsModal(false);
  };
  const { state: $, mergeState, onChangeSet } = useLargeState<Forms>({ customerNoticeFlg: true });
  const [canConfirm, setCanConfirm] = useState<boolean>(false);
  const history = useHistory();

  const selectedTemplate = (index: number) => {
    if (receiptPriceModifyDisplay) {
      mergeState({
        title: receiptPriceModifyDisplay.templateList[index].title,
        content: receiptPriceModifyDisplay.templateList[index].content,
      });
    }
  };

  useEffect(() => {
    if ($.customerNoticeFlg && !!$.workingMemo && !!$.title && !!$.content) return setCanConfirm(true);
    if (!$.customerNoticeFlg && $.workingMemo) return setCanConfirm(true);
    return setCanConfirm(false);
  }, [$]);

  useEffect(() => {
    mergeState({ workingMemo: receiptPriceParam.workingMemo });
  }, [mergeState, receiptPriceParam]);

  const onSubmit = useCallback(() => {
    (async () => {
      const api = new ReceiptPriceApi();
      await api
        .receiptPriceModifyingUpdate({
          applyId: receiptPriceParam.applyId,
          customerNoticeFlg: $.customerNoticeFlg!,
          priceAfterModify: receiptPriceParam.priceAfterModify,
          title: $.title!,
          content: $.content!,
          workingMemo: $.workingMemo!,
        })
        .then((res: AxiosResponse<ReceiptPriceModifyingUpdateOutputResponse>) => {
          if (res.data.alertMessage == null) window.alert('更新が完了しました');
          else window.alert(res.data.alertMessage);

          history.push(`${Url.TENSAKU.RECEIPT_PRICE_MODIFY}/${receiptPriceParam.applyId}`);
          window.location.reload();
        });
    })();
  }, [$]);
  return (
    <>
      <Modal show={isModal} size="xl" responsive>
        <Modal.Header>金額修正確認</Modal.Header>
        <Modal.Body>
          <Form>
            <Table>
              <thead>
                <tr>
                  <th colSpan={5}>各種金額の修正</th>
                </tr>
                <tr>
                  <th>種類</th>
                  <th>変更前</th>
                  <th>変更後</th>
                  <th>差分</th>
                  <th>備考</th>
                </tr>
              </thead>
              <tbody>
                {receiptPriceModifyDisplay?.variousModifyList.map((item) => (
                  <tr>
                    <td>{item.categoryName}</td>
                    <td>{item.priceBeforeChange}</td>
                    <td>{item.priceAfterChange}</td>
                    <DiffenceAmountComponent amount={item.differentialAmount} />
                    <td>{item.note}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Form.Group>
              <Form.Label>作業メモ</Form.Label>
              <Form.Control as="textarea" value={$.workingMemo} onChange={onChangeSet('workingMemo')} />
            </Form.Group>
            <Form.Group>
              <Form.Label>ユーザへの通知</Form.Label>
              <Form.Check
                type="switch"
                onChange={() => mergeState({ customerNoticeFlg: !$.customerNoticeFlg })}
                checked={$.customerNoticeFlg}
              />
            </Form.Group>
            <Form.Group className="d-flex">
              <Form.Label>テンプレート</Form.Label>
              <Form.Select onChange={(e) => selectedTemplate(Number(e.target.value))} disabled={!$.customerNoticeFlg}>
                <option>---選択してください---</option>
                {receiptPriceModifyDisplay?.templateList.map((item, i) => (
                  <option value={i}>{item.templateName}</option>
                ))}
              </Form.Select>
              <Button>適用</Button>
            </Form.Group>
            <Form.Group>
              <Form.Label>件名</Form.Label>
              <Form.Control value={$.title} onChange={onChangeSet('title')} disabled={!$.customerNoticeFlg} />
            </Form.Group>
            <Form.Group>
              <Form.Label>本文</Form.Label>
              <Form.Control
                as="textarea"
                value={$.content}
                onChange={onChangeSet('content')}
                disabled={!$.customerNoticeFlg}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSubmit} disabled={!canConfirm}>
            実行
          </Button>
          <Button variant="outline-secondary" onClick={handleClose}>
            戻る
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
