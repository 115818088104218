/* eslint-disable no-param-reassign */
import React, { useState, Dispatch, SetStateAction } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Modal } from '../../molecules/Modal';
import {
  BillingApi,
  BillingDestinationAutoApprovalUpdateFormResponse,
  BillingInvoiceCarryOverFormResponse,
  IncResultOutputResponse,
} from '../../../api-client';

export interface Props {
  isEditModal: boolean;
  setIsEditModal: Dispatch<SetStateAction<boolean>>;

  billingId: number;
  setUpdResult: Dispatch<SetStateAction<IncResultOutputResponse | undefined>>;

  updateAndProgressStatus: (
    forceReturnSalesDptUncommittedFlg: boolean,
    rejectFlg: boolean,
    cancelApplyFlg: boolean
  ) => void;
}

export const BillingInvoiceFirstConfirmModal: React.VFC<Props> = ({
  isEditModal,
  setIsEditModal,
  billingId,
  setUpdResult,
  updateAndProgressStatus,
}) => {
  const billingApi = new BillingApi();

  const onHide = () => {
    setIsEditModal(false);
  };

  const handleSubmit = (e: any, isAutoApproval: boolean) => {
    e.preventDefault();

    billingApi
      .billingDestinationAutoApprovalUpdate({
        id: billingId,
        autoApprovalFlg: isAutoApproval,
      } as BillingDestinationAutoApprovalUpdateFormResponse)
      .then((res) => {
        if (!res.data.result) {
          // 失敗した場合はエラーメッセージを出す
          setUpdResult(res.data);
        } else {
          // 成功した場合は承認処理を進める
          updateAndProgressStatus(false, false, false);
        }
      });
    onHide();
  };

  const getButtonRow = () => {
    return (
      <div className="mt-4 d-flex justify-content-center">
        <Button
          type="submit"
          variant="secondary"
          className="m-1"
          onClick={(e) => {
            handleSubmit(e, true);
          }}
        >
          翌月から自動承認
        </Button>
        <Button
          type="submit"
          variant="secondary"
          className="m-1"
          onClick={(e) => {
            handleSubmit(e, false);
          }}
        >
          毎月確認する
        </Button>
        <Button variant="secondary" onClick={onHide} className="m-1">
          キャンセル
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isEditModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            <Form>
              <div className="d-flex justify-content-center">
                <p>今回初めて承認する請求送付先です。翌月以降自動で承認するかどうか選択してください。</p>
              </div>
              {getButtonRow()}
            </Form>
          </>
        }
      />
    </>
  );
};
