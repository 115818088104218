import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import AccordionItem from 'react-bootstrap/AccordionItem';
import { useSafeContext } from '../../../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../../../store/correctionStore';
import { uuid } from '../../../../../../../utils/functions';
import { TinyHead } from '../../../../../../atoms/TinyHead';
import { MONITOR_RULE_TYPES } from '../../../../../../pages/Correction/constants';
import sass from '../../../../../../../scss/templates/CorrectionTemplate.module.scss';

export const EvidenceList: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);
  return (
    <>
      {$.res_correctionCheckDisplay?.monitorRuleList
        .filter(({ type }) => type === MONITOR_RULE_TYPES.INVESTIGATION_EVIDENCE.code)
        .map(({ imageUrl, content }) => (
          <Card body key={uuid()}>
            <TinyHead>調査証明</TinyHead>
            <div>
              <input
                type="checkbox"
                id="switch_display_survey_proof_monitor_rule"
                className={sass.switch_display_survey_proof_monitor_rule}
              />
              <label htmlFor="switch_display_survey_proof_monitor_rule" className={sass.survey_proof_monitor_rule}>
                {content?.split(/\n/).map((line, i, { length }) => (
                  <span key={uuid()}>
                    {line}
                    {i < length ? <br /> : <></>}
                  </span>
                ))}
                <div>{imageUrl && <img key={uuid()} src={imageUrl} className={sass.monitor_rule_image} alt="" />}</div>
              </label>
            </div>
          </Card>
        ))}
    </>
  );
};
