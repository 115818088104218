import { Workbook } from 'exceljs';
import {
  BillingApi,
  BillingInvoiceDetailApplicationSearchOutputResponse,
  BillingInvoiceStoreDetailItemRowDataOutputResponse,
  BillingInvoiceTotalingDetailItemForPdfRowDataOutputResponse,
} from '../../api-client';
import {
  BILLING_FEE_RATE_TYPE,
  BILLING_INVOICE_PDF_TYPE,
  EXCEL_MONEY_FORMAT,
  EXCEL_PERCENT_FORMAT,
} from '../../BillingConstants';
import { calcFee, calcIncludeTaxAmount, getRateText } from '../../utils/functionsForBilling';

// 請求書明細ダウンロード(Excel)
export const downloadInvoiceExcel = async (billingId: number, type: Array<string>): Promise<Buffer> => {
  const billingApi = new BillingApi();
  const workbook = new Workbook();
  if (type.includes(BILLING_INVOICE_PDF_TYPE.Shop)) {
    const res = await billingApi.billingInvoiceStoreDetailItem(billingId);

    const worksheet = workbook.addWorksheet('店舗・商品名別サマリ');
    const header = res.data.billingInvoiceStoreDetailItemHeaderForPdfOutput;
    const detail = res.data.billingInvoiceStoreDetailItemRowDataOutputList;

    worksheet.columns = [
      { header: header.no, key: 'no', width: 8 },
      { header: header.contractShopName, key: 'contractShopName', width: 33 },
      { header: header.reportCount, key: 'reportCount', width: 12 },
      { header: header.userAmount, key: 'userAmount', width: 13 },
      { header: header.spot, key: 'spot', width: 13 },
      { header: header.stock, key: 'stock', width: 13 },
      { header: header.imageOption, key: 'imageOption', width: 13 },
      { header: header.billingRewardFee, key: 'billingRewardFee', width: 13 },
      { header: header.billingFee, key: 'billingFee', width: 13 },
      { header: header.others, key: 'others', width: 13 },
      { header: header.amount, key: 'amount', width: 13 },
      { header: header.referenceTaxIncludeAmount, key: 'referenceTaxIncludeAmount', width: 13 },
      { header: header.remarks, key: 'remarks', width: 8 },
    ];
    // 塗りつぶしと文字位置
    for (let i = 0; i < worksheet.columns.length; i += 1) {
      worksheet.getCell(worksheet.actualRowCount, i + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'a9a9a9' },
      };
      worksheet.getCell(worksheet.actualRowCount, i + 1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
    }
    worksheet.getRow(1).height = 60; // 1行目(ヘッダ)の高さを広げる

    worksheet.addRows(
      detail.map((item, index) => {
        return {
          no: index + 1,
          contractShopName: item.contractShopName,
          reportCount: item.reportCount,
          userAmount: item.userAmount,
          spot: item.spot,
          stock: item.stock,
          imageOption: item.imageOption,
          billingRewardFee: item.billingRewardFee,
          billingFee: item.billingFee,
          others: item.others,
          amount: item.amount,
          referenceTaxIncludeAmount: item.referenceTaxIncludeAmount,
          remarks: item.remarks,
        };
      })
    );
    // 罫線
    for (let i = 0; i < worksheet.actualRowCount; i += 1) {
      for (let j = 0; j < worksheet.actualColumnCount; j += 1) {
        worksheet.getCell(i + 1, j + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        if (j >= 3 && j <= 11) {
          // ユーザー利用金額から合計(税抜)は決め打ちで通貨
          worksheet.getCell(i + 1, j + 1).numFmt = EXCEL_MONEY_FORMAT;
        }
      }
    }

    // 合計行
    const total = syuukeiStore(detail);
    const totalRow = worksheet.addRow({
      no: '',
      contractShopName: total.contractShopName,
      reportCount: total.reportCount,
      userAmount: total.userAmount,
      spot: total.spot,
      stock: total.stock,
      imageOption: total.imageOption,
      billingRewardFee: total.billingRewardFee,
      billingFee: total.billingFee,
      others: total.others,
      amount: total.amount,
      referenceTaxIncludeAmount: '',
      remarks: total.remarks,
    });
    // 罫線
    for (let i = 0; i < totalRow.actualCellCount; i += 1) {
      worksheet.getCell(worksheet.actualRowCount, i + 1).border = {
        top: { style: 'double' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (i >= 3 && i <= 11) {
        // ユーザー利用金額から合計(税抜)は決め打ちで通貨
        worksheet.getCell(worksheet.actualRowCount, i + 1).numFmt = EXCEL_MONEY_FORMAT;
      }
    }

    // 税率ごとの税抜き合計
    const nontaxAmoutPerTaxRate = res.data.billingInvoiceStoreDetailItemAmountForPdfOutputList;

    nontaxAmoutPerTaxRate.map((v) => {
      worksheet.addRow({
        no: '',
        contractShopName: `消費税${getRateText(v.taxRate)}`,
        reportCount: '',
        userAmount: '',
        spot: '',
        stock: '',
        imageOption: '',
        billingRewardFee: '',
        billingFee: '',
        others: '',
        amount: v.taxValue,
        remarks: '',
      });
    });

    // 税込み合計を算出するために変換する
    const taxAmountMap = new Map<number, number>();
    nontaxAmoutPerTaxRate.map((v) => {
      taxAmountMap.set(v.taxRate, v.nontaxAmount);
    });

    worksheet.getCell(worksheet.actualRowCount, 11).numFmt = EXCEL_MONEY_FORMAT;
    worksheet.addRow({
      no: '',
      contractShopName: 'ご請求金額',
      reportCount: '',
      userAmount: '',
      spot: '',
      stock: '',
      imageOption: '',
      billingRewardFee: '',
      billingFee: '',
      others: '',
      amount: calcIncludeTaxAmount(Array.from(taxAmountMap.entries())),
      remarks: '',
    });
    worksheet.getCell(worksheet.actualRowCount, 11).numFmt = EXCEL_MONEY_FORMAT;

    // 余白3行挿入
    const property = res.data.billingInvoiceHeaderInfoForPdfOutput;
    worksheet.insertRows(1, [[''], [''], ['']], 'n');
    worksheet.getRow(1).height = 26; // 1行目の高さが広がっているので戻す
    worksheet.getCell('L1').value = property.billingPublishedDate;
    worksheet.getCell('L1').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('L2').value = `請求書番号:${property.billingHeaderId.toString().padStart(10, '0')}`;
    worksheet.getCell('L2').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('L3').value = property.companyName;
    worksheet.getCell('L3').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('A2').value = `${property.billingMonth} 店舗・商品名別サマリ`;
    worksheet.getCell('A3').value = `${property.clientName} 御中`;
  }

  if (type.includes(BILLING_INVOICE_PDF_TYPE.Monitor)) {
    const res = await billingApi.billingInvoiceTotalingDetailItemForPdf(billingId);

    const worksheet = workbook.addWorksheet('調査別明細');
    const header = res.data.billingInvoiceTotalingDetailItemHeaderForPdfRowDataOutput;
    const detail = res.data.billingInvoiceTotalingDetailItemForPdfRowDataOutput;

    worksheet.columns = [
      { header: header.no, key: 'no', width: 8 },
      { header: header.contractShopName, key: 'contractShopName', width: 33 },
      { header: header.monitorName, key: 'monitorName', width: 20 },
      { header: header.reportCount, key: 'reportCount', width: 12 },
      { header: header.userAmount, key: 'userAmount', width: 13 },
      { header: header.spot, key: 'spot', width: 13 },
      { header: header.stock, key: 'stock', width: 13 },
      { header: header.imageOption, key: 'imageOption', width: 13 },
      { header: header.billingRewardFee, key: 'billingRewardFee', width: 13 },
      { header: header.billingFee, key: 'billingFee', width: 13 },
      { header: header.others, key: 'others', width: 13 },
      { header: header.amount, key: 'amount', width: 13 },
      { header: header.remarks, key: 'remarks', width: 8 },
    ];
    // 塗りつぶしと文字位置
    for (let i = 0; i < worksheet.columns.length; i += 1) {
      worksheet.getCell(worksheet.actualRowCount, i + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'a9a9a9' },
      };
      worksheet.getCell(worksheet.actualRowCount, i + 1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
    }
    worksheet.getRow(1).height = 60; // 1行目(ヘッダ)の高さを広げる

    worksheet.addRows(
      detail.map((item, index) => {
        return {
          no: index + 1,
          contractShopName: item.contractShopName,
          monitorName: item.monitorName,
          reportCount: item.reportCount,
          userAmount: item.userAmount,
          spot: item.spot,
          stock: item.stock,
          imageOption: item.imageOption,
          billingRewardFee: item.billingRewardFee,
          billingFee: item.billingFee,
          others: item.others,
          amount: item.amount,
          remarks: item.remarks,
        };
      })
    );
    // 罫線
    for (let i = 0; i < worksheet.actualRowCount; i += 1) {
      for (let j = 0; j < worksheet.actualColumnCount; j += 1) {
        worksheet.getCell(i + 1, j + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        if (j >= 4 && j <= 11) {
          // ユーザー利用金額から合計(税抜)は決め打ちで通貨
          worksheet.getCell(i + 1, j + 1).numFmt = EXCEL_MONEY_FORMAT;
        }
      }
    }

    // 合計行
    const total = syuukeiProject(detail);
    const totalRow = worksheet.addRow({
      no: '',
      contractShopName: total.contractShopName,
      monitorName: total.monitorName,
      reportCount: total.reportCount,
      userAmount: total.userAmount,
      spot: total.spot,
      stock: total.stock,
      imageOption: total.imageOption,
      billingRewardFee: total.billingRewardFee,
      billingFee: total.billingFee,
      others: total.others,
      amount: total.amount,
      remarks: total.remarks,
    });
    // 罫線
    for (let i = 0; i < totalRow.actualCellCount; i += 1) {
      worksheet.getCell(worksheet.actualRowCount, i + 1).border = {
        top: { style: 'double' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (i >= 4 && i <= 11) {
        // ユーザー利用金額から合計(税抜)は決め打ちで通貨
        worksheet.getCell(worksheet.actualRowCount, i + 1).numFmt = EXCEL_MONEY_FORMAT;
      }
    }

    // 余白3行挿入
    const property = res.data.billingInvoiceHeaderInfoForPdfOutput;
    worksheet.insertRows(1, [[''], [''], ['']], 'n');
    worksheet.getRow(1).height = 26; // 1行目の高さが広がっているので戻す
    worksheet.getCell('M1').value = property.billingPublishedDate;
    worksheet.getCell('M1').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('M2').value = `請求書番号:${property.billingHeaderId.toString().padStart(10, '0')}`;
    worksheet.getCell('M2').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('M3').value = res.data.billingInvoiceHeaderInfoForPdfOutput.companyName;
    worksheet.getCell('M3').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('A2').value = `${property.billingMonth} 店舗・調査別明細`;
    worksheet.getCell('A3').value = `${property.clientName} 御中`;
  }

  if (type.includes(BILLING_INVOICE_PDF_TYPE.Apply)) {
    const res = await billingApi.billingInvoiceDetailApplicationForPdf(billingId);
    const header = res.data.billingInvoiceDetailApplicationSearchHeaderOutput;
    const detail = res.data.billingInvoiceDetailApplicationSearchOutputList;

    const noColumn = 1;
    const shopNameColumn = 2;
    const monitorBaseNameColumn = 3;
    const applyIdColumn = 4;
    const visitAtColumn = 5;
    const completeAtColumn = 6;
    const customerAmountColumn = 7;
    const billingRewardFeeTypeColumn = 8;
    const billingFeeTypeColumn = 9;
    const billingRewardFeeValueColumn = 10;
    const billingFeeValueColumn = 11;
    const remarksColumn = 12;

    const worksheet = workbook.addWorksheet('派遣別明細');
    worksheet.columns = [
      { header: header.no, key: 'no', width: 8 },
      { header: header.shopName, key: 'shopName', width: 33 },
      // { header: header.contractName, key: 'contractName', width: 8 },
      { header: header.monitorBaseName, key: 'monitorBaseName', width: 33 },
      { header: header.applyId, key: 'applyId', width: 13 },
      { header: header.visitAt, key: 'visitAt', width: 13 },
      { header: header.completeAt, key: 'completeAt', width: 13 },
      { header: header.customerAmount, key: 'customerAmount', width: 13 },
      { header: header.billingRewardFeeType, key: 'billingRewardFeeType', width: 13 },
      { header: header.billingFeeType, key: 'billingFeeType', width: 13 },
      { header: header.billingRewardFeeValue, key: 'billingRewardFeeValue', width: 13 },
      { header: header.billingFeeValue, key: 'billingFeeValue', width: 13 },
      { header: header.remarks, key: 'remarks', width: 8 },
    ];
    // 塗りつぶしと文字位置
    for (let i = 0; i < worksheet.columns.length; i += 1) {
      // ヘッダにスタイルを充てる
      worksheet.getCell(worksheet.actualRowCount, i + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'a9a9a9' },
      };
      worksheet.getCell(worksheet.actualRowCount, i + 1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
      worksheet.getCell(worksheet.actualRowCount, i + 1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    }

    worksheet.addRows(
      detail.map((item, index) => {
        return {
          no: index + 1,
          shopName: item.shopName,
          // contractName: item.contractName,
          monitorBaseName: item.monitorBaseName,
          applyId: item.applyId,
          visitAt: item.visitAt,
          completeAt: item.completeAt,
          customerAmount: item.customerAmount,
          billingRewardFeeType:
            item.billingRewardFeeType === BILLING_FEE_RATE_TYPE.FIXED
              ? item.billingRewardFeeValue
              : item.billingRewardFeeValue / 100,
          billingFeeType:
            item.billingFeeType === BILLING_FEE_RATE_TYPE.FIXED ? item.billingFeeValue : item.billingFeeValue / 100,
          billingRewardFeeValue: calcFee(
            item.billingRewardFeeType,
            item.customerAmount,
            item.billingRewardFeeValue,
            item.billingRewardFeeLower,
            item.billingRewardFeeUpper
          ),
          billingFeeValue: calcFee(
            item.billingFeeType,
            item.customerAmount,
            item.billingFeeValue,
            item.billingFeeLower,
            item.billingFeeUpper
          ),
          remarks: item.remarks,
        };
      })
    );
    // 罫線
    // ヘッダと合計行を除いた中身にスタイルを充てる
    for (let i = 1; i <= worksheet.actualRowCount; i += 1) {
      for (let j = 1; j <= worksheet.actualColumnCount; j += 1) {
        worksheet.getCell(i, j).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        if (j === customerAmountColumn || (j >= billingRewardFeeValueColumn && j <= billingFeeValueColumn)) {
          // ユーザー利用金額から合計(税抜)は決め打ちで通貨
          worksheet.getCell(i, j).numFmt = EXCEL_MONEY_FORMAT;
        }
        if (j === billingRewardFeeTypeColumn) {
          // 謝礼条件
          // detailのindexは0から始まるので-1
          if (detail[i - 1] && detail[i - 1].billingRewardFeeType === BILLING_FEE_RATE_TYPE.FIXED) {
            // またworksheetはヘッダ行も含んでいるので+1
            worksheet.getCell(i + 1, j).numFmt = EXCEL_MONEY_FORMAT;
          }
          if (detail[i - 1] && detail[i - 1].billingRewardFeeType === BILLING_FEE_RATE_TYPE.RATIO) {
            worksheet.getCell(i + 1, j).numFmt = EXCEL_PERCENT_FORMAT;
          }
        }
        if (j === billingFeeTypeColumn) {
          // 手数料条件
          // detailのindexは0から始まるので-1
          if (detail[i - 1] && detail[i - 1].billingFeeType === BILLING_FEE_RATE_TYPE.FIXED) {
            // またworksheetはヘッダ行も含んでいるので+1
            worksheet.getCell(i + 1, j).numFmt = EXCEL_MONEY_FORMAT;
          }
          if (detail[i - 1] && detail[i - 1].billingFeeType === BILLING_FEE_RATE_TYPE.RATIO) {
            worksheet.getCell(i + 1, j).numFmt = EXCEL_PERCENT_FORMAT;
          }
        }
      }
    }

    // 合計行
    const total = culcSumApplicationDetail(detail);
    const totalRow = worksheet.addRow({
      no: '',
      shopName: '合計',
      // contractName: '',
      monitorBaseName: '',
      applyId: '',
      visitAt: '',
      completeAt: '',
      customerAmount: total.customerAmount,
      billingRewardFeeType: '',
      billingFeeType: '',
      billingRewardFeeValue: total.billingRewardFeeValue,
      billingFeeValue: total.billingFeeValue,
      remarks: '',
    });

    // 罫線
    for (let i = 1; i <= totalRow.actualCellCount; i += 1) {
      worksheet.getCell(worksheet.actualRowCount, i).border = {
        top: { style: 'double' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (i >= customerAmountColumn && i <= billingFeeValueColumn) {
        // ユーザー利用金額から合計(税抜)は決め打ちで通貨
        worksheet.getCell(worksheet.actualRowCount, i).numFmt = EXCEL_MONEY_FORMAT;
      }
    }
    // TODO 合計行の備考欄に罫線がかかれないので固定で書いておく
    worksheet.getCell(worksheet.actualRowCount, remarksColumn).border = {
      top: { style: 'double' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    // 余白3行挿入
    const property = res.data.billingInvoiceHeaderInfoForPdfOutput;
    worksheet.insertRows(1, [[''], [''], ['']], 'n');
    worksheet.getRow(1).height = 26; // 1行目の高さが広がっているので戻す
    worksheet.getCell('L1').value = property.billingPublishedDate;
    worksheet.getCell('L1').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('L2').value = `請求書番号:${property.billingHeaderId.toString().padStart(10, '0')}`;
    worksheet.getCell('L2').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('L3').value = res.data.billingInvoiceHeaderInfoForPdfOutput.companyName;
    worksheet.getCell('L3').alignment = { vertical: 'middle', horizontal: 'right' };
    worksheet.getCell('A2').value = `${property.billingMonth} 派遣別明細`;
    worksheet.getCell('A3').value = `${property.clientName} 御中`;
  }
  const data = (await workbook.xlsx.writeBuffer()) as Buffer;

  return Promise.resolve(data);
};

const syuukeiStore = (
  item: Array<BillingInvoiceStoreDetailItemRowDataOutputResponse>
): BillingInvoiceStoreDetailItemRowDataOutputResponse => {
  return item.reduce(
    (result, current) => {
      return {
        no: 0,
        contractShopName: '合計',
        reportCount: result.reportCount + current.reportCount,
        userAmount: result.userAmount + current.userAmount,
        spot: result.spot + current.spot,
        stock: result.stock + current.stock,
        imageOption: result.imageOption + current.imageOption,
        billingRewardFee: result.billingRewardFee + current.billingRewardFee,
        billingFee: result.billingFee + current.billingFee,
        others: result.others + current.others,
        amount: result.amount + current.amount,
        referenceTaxIncludeAmount: result.referenceTaxIncludeAmount + current.referenceTaxIncludeAmount,
        remarks: '',
      };
    },
    {
      no: 0,
      contractShopName: '合計',
      reportCount: 0,
      userAmount: 0,
      spot: 0,
      stock: 0,
      imageOption: 0,
      billingRewardFee: 0,
      billingFee: 0,
      others: 0,
      amount: 0,
      referenceTaxIncludeAmount: 0,
      remarks: '',
    } as BillingInvoiceStoreDetailItemRowDataOutputResponse
  );
};

const syuukeiProject = (
  item: Array<BillingInvoiceTotalingDetailItemForPdfRowDataOutputResponse>
): BillingInvoiceTotalingDetailItemForPdfRowDataOutputResponse => {
  return item.reduce(
    (result, current) => {
      return {
        no: 0,
        contractShopName: '合計',
        reportCount: result.reportCount + current.reportCount,
        userAmount: result.userAmount + current.userAmount,
        spot: result.spot + current.spot,
        stock: result.stock + current.stock,
        imageOption: result.imageOption + current.imageOption,
        billingRewardFee: result.billingRewardFee + current.billingRewardFee,
        billingFee: result.billingFee + current.billingFee,
        others: result.others + current.others,
        amount: result.amount + current.amount,
        remarks: '',
        monitorName: '',
        contractName: '',
      };
    },
    {
      no: 0,
      contractShopName: '合計',
      reportCount: 0,
      userAmount: 0,
      spot: 0,
      stock: 0,
      imageOption: 0,
      billingRewardFee: 0,
      billingFee: 0,
      others: 0,
      amount: 0,
      remarks: '',
      monitorName: '',
      contractName: '',
    } as BillingInvoiceTotalingDetailItemForPdfRowDataOutputResponse
  );
};

const culcSumApplicationDetail = (items: Array<BillingInvoiceDetailApplicationSearchOutputResponse>) => {
  interface Total {
    customerAmount: number;
    billingRewardFeeValue: number;
    billingFeeValue: number;
  }
  const total: Total = {
    customerAmount: 0,
    billingRewardFeeValue: 0,
    billingFeeValue: 0,
  };
  items.map((item) => {
    total.customerAmount += item.customerAmount;
    total.billingRewardFeeValue += calcFee(
      item.billingRewardFeeType,
      item.customerAmount,
      item.billingRewardFeeValue,
      item.billingRewardFeeLower,
      item.billingRewardFeeUpper
    );
    total.billingFeeValue += calcFee(
      item.billingFeeType,
      item.customerAmount,
      item.billingFeeValue,
      item.billingFeeLower,
      item.billingFeeUpper
    );
  });
  return total;
};
