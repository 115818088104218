/* eslint-disable no-param-reassign */
import React, { useState, Dispatch, SetStateAction, useContext } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Modal } from '../../molecules/Modal';
import { BillingApi, BillingInvoiceCarryOverFormResponse, IncResultOutputResponse } from '../../../api-client';
import { BILLING_CARRY_OVER_TYPE } from '../../../BillingConstants';
import { Alert } from '../../atoms/Alert';
import { BillingContext } from '../../context/BillingContext';

export interface Props {
  isEditModal: boolean;
  setIsEditModal: Dispatch<SetStateAction<boolean>>;

  billingId: number;
  setUpdResult: Dispatch<SetStateAction<IncResultOutputResponse | undefined>>;
  updateAt: string;
}

export const BillingInvoiceCarryOverModal: React.VFC<Props> = ({
  isEditModal,
  setIsEditModal,
  billingId,
  setUpdResult,
  updateAt,
}) => {
  const [validated, setValidated] = useState(false);
  const [message, setMessage] = useState<string>();
  const billingApi = new BillingApi();
  const billingContext = useContext(BillingContext);
  const onHide = () => {
    setIsEditModal(false);
  };
  const [carryOverType, setCarryOverType] = useState<number>(BILLING_CARRY_OVER_TYPE.ALL);
  const history = useHistory();

  const handleSubmit = (e: any, isCarryOver: boolean) => {
    e.preventDefault();
    billingApi
      .billingInvoiceCarryOver({
        billingId,
        isCarryOver,
        updateAt,
      } as BillingInvoiceCarryOverFormResponse)
      .then((res) => {
        history.go(0);
      });
    onHide();
  };

  const getCarryOverTypeRow = () => {
    return (
      <Form.Group className="mb-4" controlId="contractName">
        <Row>
          <Col className="text-end">
            <Form.Label className="pt-2 col-4">単位</Form.Label>
          </Col>
          <Col className="col-8">
            <Form.Select onChange={(e) => setCarryOverType(Number(e.target.value))}>
              <option selected value={BILLING_CARRY_OVER_TYPE.ALL}>
                請求書
              </option>
            </Form.Select>
          </Col>
        </Row>
      </Form.Group>
    );
  };

  const getButtonRow = () => {
    return (
      <div className="mt-4 d-flex justify-content-center">
        <Button
          type="submit"
          variant="secondary"
          className="m-1"
          onClick={(e) => {
            handleSubmit(e, true);
          }}
        >
          翌月繰越
        </Button>
        <Button
          type="submit"
          variant="secondary"
          className="m-1"
          onClick={(e) => {
            handleSubmit(e, false);
          }}
        >
          繰越戻し
        </Button>
        <Button variant="secondary" onClick={onHide} className="m-1">
          キャンセル
        </Button>
      </div>
    );
  };

  return (
    <>
      <Modal
        onHide={onHide}
        isModal={isEditModal}
        size="lg"
        closeButton
        centered
        scrollable
        body={
          <>
            {message && <Alert variant="danger">{message}</Alert>}
            <Form noValidate validated={validated}>
              {getCarryOverTypeRow()}
              {getButtonRow()}
            </Form>
          </>
        }
      />
    </>
  );
};
