import * as React from 'react';
import { Button } from 'react-bootstrap';

export const PrimaryButton = (props: any) => {
  const { onClick, children, className } = props;
  return (
    <Button variant="primary" className={className} onClick={onClick}>
      {children}
    </Button>
  );
};
