import { useQuery } from '@tanstack/react-query';
import { BillingApi } from '../api-client';
import { CACHE_KEY_BILLING_BILLING_APPROVE_INC_ACCOUNT, CACHE_KEY_BILLING_INC_ACCOUNT } from '../Constants';

export default function useBillingSuggestBillingApproveIncAccount() {
  const billingApi = new BillingApi();
  const billingApproverRoleName = '営業上長';
  const result = useQuery({
    queryKey: [CACHE_KEY_BILLING_BILLING_APPROVE_INC_ACCOUNT],
    queryFn: async () =>
      billingApi.billingSuggestIncAccountList(encodeURI(billingApproverRoleName)).then((res) => {
        return res;
      }),
  });
  return {
    isSuccess: result.isSuccess,
    data: result.data?.data,
  };
}
