import React, { useState, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Workbook } from 'exceljs';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilState } from 'recoil';
import { format } from 'date-fns';
import iconv from 'iconv-lite';
import { faFileCsv, faFilePdf, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import {
  BillingApi,
  BillingInvoiceDownloadListOutputResponse,
  BillingInvoiceStatusUpdateFormResponse,
  BillingPdfAllDownloadFormResponse,
} from '../../../api-client';
import { useMultipleChecked } from '../../../hooks/useMultipleChecked';
import { fileDownLoad, getDownLoadFileName } from '../../../services/PdfDownloadService';
import {
  ENCODE_SJIS,
  ENCODE_UTF8,
  EXTENSION_PDF,
  LOCALDATETIME_HYPHEN_PADDING_START_DAY_FORMAT,
} from '../../../Constants';
import { BILLING_FLOW_STATUS, BILLING_FLOW_STATUS_FOR_SELECTION } from '../../../BillingConstants';
import { convertMoneyText, getTargetMonth } from '../../../utils/functionsForBilling';
import { currentUserState } from '../../../states/currentUser';
import { Alert } from '../../atoms/Alert';
import useBillingSuggestClient from '../../../hooks/useBillingSuggestClient';
import useBillingSuggestIncAccount from '../../../hooks/useBillingSuggestIncAccount';
import useBillingSuggestContractShop from '../../../hooks/useBillingSuggestContractShop';

const MAX_RECORD_PER_PAGE = 20;

interface SearchParams {
  clientId?: number;
  salesIncAccountId?: number;
  contractShopId?: number;
  targetMonth: string;
  billingFlowStatuses?: Array<number>;
  hasInformart: boolean;
  deliveryNote?: string;
}

export const BillingFormDownload: React.VFC = () => {
  const [list, setList] = useState<BillingInvoiceDownloadListOutputResponse[]>([]);
  const [listPerPage, setListPerPage] = useState<BillingInvoiceDownloadListOutputResponse[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [message, setMessage] = useState<string>();

  const searchParams: SearchParams = {
    clientId: undefined,
    salesIncAccountId: undefined,
    contractShopId: undefined,
    targetMonth: getTargetMonth(),
    billingFlowStatuses: [BILLING_FLOW_STATUS.UNPUBLISHED],
    hasInformart: false,
    deliveryNote: undefined,
  };
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const currentUser = useRecoilState(currentUserState);
  const billingApi = new BillingApi();

  const billingSuggestClientResult = useBillingSuggestClient();
  const billingSuggestIncAccountResult = useBillingSuggestIncAccount();
  const billingSuggestContractShopResult = useBillingSuggestContractShop();

  const onSubmit: SubmitHandler<SearchParams> = (data) => {
    // 全件チェックをリセット
    setCheckedAll(false);
    clearCheck();

    billingApi
      .billingInvoiceDownloadList(
        data.clientId,
        data.salesIncAccountId,
        data.contractShopId,
        `${data.targetMonth}-01`,
        data.billingFlowStatuses,
        data.hasInformart,
        data.deliveryNote
      )
      .then((res) => {
        setList(res.data);
        setListPerPage(res.data.slice(0, MAX_RECORD_PER_PAGE));
        setTotalPage(Math.ceil(Number(res.data?.length) / MAX_RECORD_PER_PAGE));
        setCurrentPage(1);
      });
  };

  useEffect(() => {
    // 初回表示時に検索結果を表示する。
    (async () => {
      const res = await billingApi.billingInvoiceDownloadList(
        searchParams.clientId,
        searchParams.salesIncAccountId,
        searchParams.contractShopId,
        `${searchParams.targetMonth}-01`,
        searchParams.billingFlowStatuses,
        searchParams.hasInformart,
        searchParams.deliveryNote
      );
      setList(res.data);
      setListPerPage(res.data.slice(0, MAX_RECORD_PER_PAGE));
      setTotalPage(Math.ceil(Number(res.data?.length) / MAX_RECORD_PER_PAGE));
      setCurrentPage(1);
    })();
  }, []);

  const { checked, toggleChecked, allCheck, clearCheck } = useMultipleChecked<number>(
    listPerPage.flatMap((cli) => cli.billingInvoiceDestinationListOutputs).map((v) => v.billingHeaderId)
  );

  const checkedListDownload = async () => {
    const checkedList = listPerPage.map((v) => {
      return {
        ...v,
        billingInvoiceDestinationListOutputs: v.billingInvoiceDestinationListOutputs.filter((vv) =>
          checked.includes(vv.billingHeaderId)
        ),
      };
    });
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('一括ダウンロードエクセル');
    worksheet.columns = [
      { header: 'クライアント', key: 'client' },
      { header: 'クライアント合計', key: 'clientAmount' },
      { header: '請求送付先', key: 'billingDestination' },
      { header: 'フローステータス', key: 'flowStatus' },
      { header: '合計金額', key: 'amount' },
      { header: '配送メモ', key: 'deliveryNote' },
      { header: '担当営業', key: 'incAccount' },
    ];

    checkedList.map((v) => {
      worksheet.addRows(
        v.billingInvoiceDestinationListOutputs.map((vv) => {
          return {
            client: `${v.clientId}:${v.clientName}`,
            clientAmount: v.amount?.toString(),
            billingDestination: `${vv.billingDestinationId}:${vv.name}`,
            flowStatus: vv.billingFlowStatus,
            amount: vv.amount?.toString(),
            deliveryNote: vv.deliveryNote,
            incAccount: vv.incAccountName,
          };
        })
      );
    });

    const b = await workbook.xlsx.writeBuffer();
    fileDownLoad(new Blob([b], { type: 'application/octet-binary' }), '一括ダウンロードエクセル.xlsx');
  };
  const handleClickDownloadPDF = (e: any, isAll: boolean) => {
    setMessage(undefined);
    if (isAll) {
      if (checked.length === 0) {
        setMessage('チェックが入っていません。');
      } else {
        // エクセルダウンロードを実施
        checkedListDownload().then(() => {
          // その後PDFをダウンロードする
          const billingPdfAllDownloadResponse: BillingPdfAllDownloadFormResponse = { billingHeaderIds: checked };
          billingApi.billingPdfAllDownload(billingPdfAllDownloadResponse).then((res) => {
            const bytes = Buffer.from(res.data.data, 'base64');
            fileDownLoad(new Blob([bytes], { type: 'application/pdf' }), undefined);
            billingFlowStatusUpdate(checked);
          });
        });
      }
    } else {
      const billingHeaderId = Number(e.currentTarget.value);
      const billingPdfAllDownloadResponse: BillingPdfAllDownloadFormResponse = {
        billingHeaderIds: [billingHeaderId],
      };
      billingApi.billingDestinationInfo(billingHeaderId).then((billingDestInfo) => {
        billingApi.billingPdfAllDownload(billingPdfAllDownloadResponse).then((res) => {
          const bytes = Buffer.from(res.data.data, 'base64');
          fileDownLoad(
            new Blob([bytes], { type: 'application/pdf' }),
            getDownLoadFileName(
              [''],
              billingDestInfo.data.clientName,
              billingDestInfo.data.billingMonth,
              EXTENSION_PDF,
              billingDestInfo.data.billingDestinationName
            )
          );
          billingFlowStatusUpdate([billingHeaderId]);
        });
      });
    }
  };

  const handleClickDownloadCSV = (e: any, isAll: boolean) => {
    setMessage(undefined);
    let billingInvoiceDownloadCsvFormResponse;
    let filename = '';
    let billingHeaderIds: number[] = [];
    if (isAll) {
      if (checked.length === 0) {
        setMessage('チェックが入っていません。');
      } else {
        filename = `invoiceAll_${format(new Date(), LOCALDATETIME_HYPHEN_PADDING_START_DAY_FORMAT)}.csv`;
        billingInvoiceDownloadCsvFormResponse = { billingHeaderId: checked };
        billingHeaderIds = [...checked];
      }
    } else {
      const billingHeaderId = Number(e.currentTarget.value);
      filename = `invoice_${e.currentTarget.value}_${format(
        new Date(),
        LOCALDATETIME_HYPHEN_PADDING_START_DAY_FORMAT
      )}.csv`;
      billingInvoiceDownloadCsvFormResponse = { billingHeaderId: [billingHeaderId] };
      billingHeaderIds = [...[billingHeaderId]];
    }
    if (billingInvoiceDownloadCsvFormResponse === undefined) {
      // チェックが入っていない場合は何もしない
      return;
    }
    billingApi.billingInvoiceDownloadCsv(billingInvoiceDownloadCsvFormResponse).then((res) => {
      const bytes = Buffer.from(res.data.data, 'base64');

      fileDownLoad(new Blob([iconv.encode(bytes.toString(ENCODE_UTF8), ENCODE_SJIS)], { type: 'text/csv' }), filename);
      billingFlowStatusUpdate(billingHeaderIds);
    });
  };

  const billingFlowStatusUpdate = (billingHeaderIds: number[]) => {
    // ステータス更新
    const updateAt = format(new Date(), LOCALDATETIME_HYPHEN_PADDING_START_DAY_FORMAT);
    const form: BillingInvoiceStatusUpdateFormResponse = {
      billingInvoiceStatusUpdateData: billingHeaderIds.map((billingHeaderId) => {
        return {
          billingHeaderId,
          updateAt,
        };
      }),
      forceReturnSalesDptUncommittedFlg: false,
      incAccountId: currentUser[0]!.id,
      rejectFlg: false,
      cancelApplyFlg: false,
    };
    billingApi.billingInvoiceStatusUpdate(form).then((res) => {
      // alert('請求フローステータスを更新しました。');
    });
  };

  const handleCheckAll = () => {
    if (checkedAll) {
      clearCheck();
      setCheckedAll(false);
    } else {
      allCheck();
      setCheckedAll(true);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<SearchParams>({
    defaultValues: searchParams,
  });

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-2 mb-4">
          <Col>
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">クライアント</Form.Label>
                </Col>
                <Col className="col-8">
                  {billingSuggestClientResult.isSuccess && (
                    <Select
                      isSearchable
                      isClearable
                      options={billingSuggestClientResult.data}
                      defaultValue={billingSuggestClientResult.data?.find((v) => v.value === getValues('clientId'))}
                      onChange={(e) => setValue('clientId', e?.value)}
                    />
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">営業担当</Form.Label>
                </Col>
                <Col className="col-8">
                  {billingSuggestIncAccountResult.isSuccess && (
                    <Select
                      isSearchable
                      isClearable
                      options={billingSuggestIncAccountResult.data}
                      defaultValue={billingSuggestIncAccountResult.data?.find(
                        (v) => v.value === getValues('salesIncAccountId')
                      )}
                      onChange={(e) => setValue('salesIncAccountId', e?.value)}
                    />
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-2 mb-4">
          <Col>
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">契約店舗</Form.Label>
                </Col>
                <Col className="col-8">
                  {billingSuggestContractShopResult.isSuccess && (
                    <Select
                      isSearchable
                      isClearable
                      options={billingSuggestContractShopResult.data}
                      defaultValue={billingSuggestContractShopResult.data?.find(
                        (v) => v.value === getValues('contractShopId')
                      )}
                      onChange={(e) => setValue('contractShopId', e?.value)}
                    />
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">請求書発行月</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control type="month" id="month" {...register('targetMonth')} />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-2 mb-4">
          <Col>
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">請求フローステータス</Form.Label>
                </Col>
                <Col className="col-8">
                  <Select
                    id="billingFlowStatus"
                    isSearchable
                    isClearable
                    options={BILLING_FLOW_STATUS_FOR_SELECTION}
                    defaultValue={BILLING_FLOW_STATUS_FOR_SELECTION.filter((v) =>
                      getValues('billingFlowStatuses')?.includes(v.value)
                    )}
                    isMulti
                    onChange={(e) => {
                      let selectedFlowList: Array<number> = [];
                      e.map((v) => {
                        selectedFlowList = [...selectedFlowList, v.value];
                      });
                      setValue('billingFlowStatuses', selectedFlowList);
                    }}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">InforMart要否</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Select
                    id="hasInformart"
                    defaultValue={getValues('hasInformart') ? 'true' : 'false'}
                    onChange={(e) => setValue('hasInformart', e.target.value === 'true')}
                  >
                    <option value="true">要</option>
                    <option value="false">不要</option>
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-2 mb-4">
          <Col className="col-6">
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label className="mt-2 me-2">配送メモ</Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control type="text" {...register('deliveryNote')} />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <div className="d-flex justify-content-center">
          <Button type="submit">検索</Button>
        </div>
      </Form>
      <div className="d-flex justify-content-end mb-4">
        <Button variant="secondary" className="me-4" onClick={(e) => handleClickDownloadPDF(e, true)}>
          <FontAwesomeIcon icon={faFilePdf} fixedWidth className="me-1" />
          PDF一括DL
        </Button>
        <Button variant="secondary" className="me-4" onClick={(e) => handleClickDownloadCSV(e, true)}>
          <FontAwesomeIcon icon={faFileCsv} fixedWidth className="me-1" />
          CSV一括DL
        </Button>
      </div>
      {message && <Alert variant="danger">{message}</Alert>}
      <Table>
        <thead>
          <tr>
            <th>クライアント</th>
            <th>クライアント合計</th>
            <th>請求送付先</th>
            <th>フローステータス</th>
            <th>合計金額</th>
            <th>配送メモ</th>
            <th>担当営業</th>
            <th>InforMartコード</th>
            <th>PDF</th>
            <th>InforMart</th>
            <th>
              <Form.Check.Input
                className="flex-shrink-0 me-2"
                checked={checkedAll}
                type="checkbox"
                name="unBilling"
                onChange={handleCheckAll}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {listPerPage.map((item) => {
            return item.billingInvoiceDestinationListOutputs.map((billing, idx) => {
              return idx === 0 ? (
                <tr key={item.clientId}>
                  <td rowSpan={item.billingInvoiceDestinationListOutputs.length}>
                    {item.clientId}:{item.clientName}
                  </td>
                  <td rowSpan={item.billingInvoiceDestinationListOutputs.length}>{convertMoneyText(item.amount)}</td>
                  <td>
                    {billing.branchNumber}:{billing.name}
                  </td>
                  <td>{billing.billingFlowStatus}</td>
                  <td>{convertMoneyText(billing.amount)}</td>
                  <td>{billing.deliveryNote}</td>
                  <td>{billing.incAccountName}</td>
                  <td>{billing.informartCode}</td>
                  <td>
                    <Button
                      variant="secondary"
                      value={billing.billingHeaderId}
                      onClick={(e) => handleClickDownloadPDF(e, false)}
                    >
                      <FontAwesomeIcon icon={faFilePdf} fixedWidth className="me-1" />
                    </Button>
                  </td>
                  <td>
                    {billing.informartCode !== undefined ? (
                      <Button
                        variant="secondary"
                        value={billing.billingHeaderId}
                        onClick={(e) => handleClickDownloadCSV(e, false)}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} fixedWidth className="me-1" />
                      </Button>
                    ) : (
                      ' '
                    )}
                  </td>
                  <td>
                    <Form.Check.Input
                      className="flex-shrink-0 me-2"
                      checked={checked.includes(billing.billingHeaderId === undefined ? 0 : billing.billingHeaderId)}
                      type="checkbox"
                      name="download"
                      value={billing.billingHeaderId}
                      onChange={() =>
                        toggleChecked(billing.billingHeaderId === undefined ? 0 : billing.billingHeaderId)
                      }
                    />
                  </td>
                </tr>
              ) : (
                <tr key={billing.billingHeaderId + 100000}>
                  <td>
                    {billing.branchNumber}:{billing.name}
                  </td>
                  <td>{billing.billingFlowStatus}</td>
                  <td>{convertMoneyText(billing.amount)}</td>
                  <td>{billing.deliveryNote}</td>
                  <td>{billing.incAccountName}</td>
                  <td>{billing.informartCode}</td>
                  <td>
                    <Button
                      variant="secondary"
                      value={billing.billingHeaderId}
                      onClick={(e) => handleClickDownloadPDF(e, false)}
                    >
                      <FontAwesomeIcon icon={faFilePdf} fixedWidth className="me-1" />
                    </Button>
                  </td>
                  <td>
                    {billing.informartCode !== undefined ? (
                      <Button
                        variant="secondary"
                        value={billing.billingHeaderId}
                        onClick={(e) => handleClickDownloadCSV(e, false)}
                      >
                        <FontAwesomeIcon icon={faInfoCircle} fixedWidth className="me-1" />
                      </Button>
                    ) : (
                      ' '
                    )}
                  </td>
                  <td>
                    <Form.Check.Input
                      className="flex-shrink-0 me-2"
                      checked={checked.includes(billing.billingHeaderId === undefined ? 0 : billing.billingHeaderId)}
                      type="checkbox"
                      name="download"
                      value={billing.billingHeaderId}
                      onChange={() =>
                        toggleChecked(billing.billingHeaderId === undefined ? 0 : billing.billingHeaderId)
                      }
                    />
                  </td>
                </tr>
              );
            });
          })}
        </tbody>
      </Table>
      <div className="mt-4 d-flex justify-content-center">
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
            setCheckedAll(false);
            clearCheck();
          }}
        />
      </div>
    </>
  );
};
