import * as React from 'react';
import { Badge as BootstrapBadge } from 'react-bootstrap';

interface Props {
  bg?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  text?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  pill?: boolean;
  style?: { [key: string]: number | string };
  className?: string;
}

export const Badge: React.FC<Props> = ({ children, ...rest }) => {
  return <BootstrapBadge {...rest}>{children}</BootstrapBadge>;
};
