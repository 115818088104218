import React from 'react';
import type { ShopReplaceMonitorSearchOutputResponse } from '../../../api-client';
import { createTestId } from '../../../utils/functions';
import { Button } from '../../atoms/Button';
import type { ShopData, State as PageState } from '../../pages/ShopReplacePage';
import { PaginationTable } from './PaginationTable';

export const ShopReplaceMonitorSearchTable: React.FC<{
  beforeMonitorBaseId: ShopData['monitorBaseId'];
  monitorSearchList: PageState['monitorSearchList'];
  resultNum: PageState['monitorSearchList']['length'];
  onSelect: (monitorId: PageState['monitorSearchList'][0]['monitorBaseId']) => void;
}> = ({ resultNum, beforeMonitorBaseId, monitorSearchList, onSelect }) => {
  const testId = createTestId(ShopReplaceMonitorSearchTable);
  return (
    <>
      <PaginationTable
        resultNum={resultNum}
        list={monitorSearchList}
        headerNames={['店舗ID', '店舗名', 'モニターベースID', 'モニター月', '残枠数', '操作']}
      >
        {(list) => {
          return (list as ShopReplaceMonitorSearchOutputResponse[]).map(
            ({ shopId, shopName, monitorBaseId, monitorMonth, remainingApplyNumber }, i) => {
              const isDisabled = monitorBaseId === beforeMonitorBaseId;
              return (
                <tr className="align-middle" key={shopId}>
                  <td>{shopId}</td>
                  <td>{shopName}</td>
                  <td>{monitorBaseId}</td>
                  <td>{monitorMonth}</td>
                  <td>{remainingApplyNumber}</td>
                  <td>
                    <Button
                      disabled={isDisabled}
                      variant={isDisabled ? 'danger' : 'primary'}
                      onClick={() => onSelect(monitorBaseId)}
                      data-testid={testId('select-button', i)}
                    >
                      {monitorBaseId === beforeMonitorBaseId ? '同一ID' : '選択'}
                    </Button>
                  </td>
                </tr>
              );
            }
          );
        }}
      </PaginationTable>
    </>
  );
};
