import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink';
import { BillingApi, BillingDestinationListOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { PaginationWithEllipsisBilling } from '../../molecules/PaginationWithEllipsisBilling';

export interface Props {
  list: BillingDestinationListOutputResponse[];
  onClickCopy: (e: any, billingDestinationId: number) => void;
}

export const BillingDestinationTable: React.VFC<Props> = ({ list, onClickCopy }) => {
  const [listPerPage, setListPerPage] = useState<BillingDestinationListOutputResponse[]>(list);
  const MAX_RECORD_PER_PAGE = 100;

  const handleChangePage = (page: number) => {
    setListPerPage(list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
  };

  const handleCopy = () => {};

  useEffect(() => {
    setListPerPage(list.slice(0, MAX_RECORD_PER_PAGE));
  }, [list]);

  return (
    <>
      <Table responsive className="mt-4 mb-4">
        <thead className="text-nowrap">
          <tr>
            <th>クライアント</th>
            <th>営業担当者</th>
            <th>案件名</th>
            <th>枝番</th>
            <th>送付先社名</th>
            {/* <th>部署・役職</th> */}
            {/* <th>送付先担当者名</th> */}
            <th>自動承認フラグ</th>
            <th>紙帳票種別</th>
            <th>メール帳票種別</th>
            <th>お支払い条件</th>
            <th>店舗紐づけ</th>
            <th>&nbsp;</th>
            <th>&nbsp;</th>
            <th>コピー数</th>
          </tr>
        </thead>
        <tbody>
          {listPerPage.map((item, idx) => {
            return (
              <tr key={idx} className="align-middle">
                <td>{item.clientName}</td>
                <td>{item.incAccountName}</td>
                <td>{item.name}</td>
                <td>{item.branchNumber}</td>
                <td>{item.companyName}</td>
                {/* <td>{item.department}</td> */}
                {/* <td>{item.billingStaff}</td> */}
                <td>{item.autoApproval}</td>
                <td>{item.publishmentTypeName}</td>
                <td>{item.mailPublishmentTypeName}</td>
                <td>{item.paymentConditionName}</td>
                <td>
                  <a href={`${Url.BILLING.BILLING_INVOICE_DEST_RELATION}/${item.id}`} target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faLink} fixedWidth />
                  </a>
                </td>
                <td>
                  <a href={`${Url.BILLING.BILLING_DESTINATION_DETAIL}/${item.id}`} target="_blank" rel="noreferrer">
                    編集
                  </a>
                </td>
                <td>
                  <Button variant="secondary" className="me-4" onClick={(e) => onClickCopy(e, item.id)}>
                    コピー
                  </Button>
                </td>
                <td>{item.copyCount === 0 ? '' : item.copyCount}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <div className="mt-4 d-flex justify-content-center">
        <PaginationWithEllipsisBilling
          maxRecordPerPage={MAX_RECORD_PER_PAGE}
          totalDataNum={list.length}
          handleClick={(e) => handleChangePage(e.page)}
        />
      </div>
    </>
  );
};
