// /* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { faTimesCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../atoms/Button';
import { createTestId, isoDateToInput } from '../../../utils/functions';
import type { State as EditPageState } from '../../pages/ClientNg/ClientNgEditPage';
import { Url } from '../../../constants/Url';

interface Props {
  list: EditPageState['list'];
  reasonTypes: EditPageState['reasonTypes'];
  // eslint-disable-next-line react/require-default-props
  isDeleteModal?: boolean;
  // eslint-disable-next-line react/require-default-props, @typescript-eslint/no-explicit-any
  onEdit?: (index: number) => any;
  // eslint-disable-next-line react/require-default-props, @typescript-eslint/no-explicit-any
  onDelete?: (index: number) => any;
}

export const ClientNgEditTable: React.FC<Props> = ({ onEdit, onDelete, list, reasonTypes, isDeleteModal }) => {
  const history = useHistory();
  const testid = createTestId(ClientNgEditTable);
  return (
    <Table data-testid={testid('table')}>
      <thead>
        <tr>
          <th>登録日</th>
          <th>
            NG登録
            <br />
            会員ID
          </th>
          <th>NG理由</th>
          <th>NG理由備考</th>
          {!isDeleteModal ? (
            <>
              <th> </th>
              <th> </th>
            </>
          ) : (
            <></>
          )}
        </tr>
      </thead>
      <tbody>
        {list.map((form, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <tr key={i}>
            <td className="align-middle">{form.createAt && isoDateToInput('date', form.createAt)}</td>
            <td className="align-middle">
              <Button
                variant="link"
                className="p-0"
                onClick={() => {
                  history.push(`${Url.TENSAKU.RELATED_CUSTOMER}/${form.ngCustomerId}`);
                }}
              >
                {form.ngCustomerId}
              </Button>
            </td>
            <td className="align-middle">
              {reasonTypes.find(({ ngReasonTypeId }) => ngReasonTypeId === form.ngReasonType)?.ngReasonTypeName}
            </td>
            <td className="align-middle ">{form.ngReasonNote}</td>
            {!isDeleteModal ? (
              <>
                <td className="align-middle">
                  <Button
                    data-testid={testid('edit-button', i)}
                    variant="primary"
                    onClick={!onEdit ? undefined : () => onEdit(i)}
                  >
                    <FontAwesomeIcon icon={faEdit} fixedWidth />
                  </Button>
                </td>
                <td className="align-middle">
                  <Button
                    data-testid={testid('delete-button', i)}
                    variant="danger"
                    onClick={!onDelete ? undefined : () => onDelete(i)}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                  </Button>
                </td>
              </>
            ) : (
              <></>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
