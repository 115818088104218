import * as React from 'react';
import { OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { Url } from '../../../constants/Url';
import type { PostingRequestInfoListOutputResponse } from '../../../api-client';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import { Index2 } from '../../pages/PostingRequest/PostingRequestListPage';

export interface Props {
  listPerPage: PostingRequestInfoListOutputResponse[];
  setIsShopListModal?: DispatchSetState<boolean>;
  setIndex?: DispatchSetState<Index2>;
}

export const PostingRequestListTable: React.VFC<Props> = ({ listPerPage, setIsShopListModal, setIndex }) => {
  const testid = createTestId(PostingRequestListTable);
  const history = useHistory();

  const listItem = (
    {
      applicationFormId,
      postingRequestId,
      clientId,
      clientName,
      applicationFormType,
      postingRequestDate,
      postingRequestStatus,
      applicationFormStatus,
      contractStartDate,
      contractShops,
    }: PostingRequestInfoListOutputResponse,
    si: number
  ) => {
    let contractShopNames: string[] = [];
    let shops: { id: number | undefined; name: string | undefined }[] = [];
    let monitorNames: string[] = [];
    contractShops.forEach((item) => {
      item.monitorBases.forEach(({ name }, i) => {
        contractShopNames = i === 0 ? [...contractShopNames, item.contractShopName] : [...contractShopNames, '-'];
        shops = item.shopId
          ? [...shops, { id: item.shopId, name: item.shopName }]
          : [...shops, { id: undefined, name: undefined }];
        monitorNames = [...monitorNames, name];
      });
    });

    return (
      <tr key={postingRequestId}>
        <td>{clientId}</td>
        <td>
          {postingRequestStatus !== '依頼取り消し' ? (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
            <label
              style={{ cursor: 'pointer' }}
              className="btn-link"
              onClick={() =>
                history.push({
                  pathname: `${Url.KEISAI.POSTING_RESERVATION}/${postingRequestId}`,
                  state: { id: applicationFormId, applicationFormFlg: true },
                })
              }
            >
              {clientName}
            </label>
          ) : (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/click-events-have-key-events
            <>{clientName}</>
          )}
        </td>
        <td>{applicationFormType}</td>
        <td>{postingRequestDate}</td>
        <td>{postingRequestStatus}</td>
        <td>{applicationFormStatus}</td>
        <td>{contractStartDate}</td>
        <td>{contractShopNames.map((v, i) => (v !== '-' ? <div key={i}>{v}</div> : <div key={i}>&nbsp;</div>))}</td>
        <td>
          {shops.map((shop, i) => (
            <div key={i}>
              <span>
                <OverlayTrigger
                  placement="top-start"
                  overlay={
                    <Popover id="popover-basic">
                      <Popover.Body className="p-1">実店舗設定</Popover.Body>
                    </Popover>
                  }
                >
                  <button
                    type="button"
                    onClick={() => {
                      if (setIndex && setIsShopListModal) {
                        setIndex({ client: si, shop: i });
                        setIsShopListModal(true);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faClipboardList} className="text-secondary flex-shrink-0 me-1" fixedWidth />
                  </button>
                </OverlayTrigger>
              </span>
              <span>{shop.id ? '設定済み' : '未設定'}</span>
            </div>
          ))}
        </td>
        <td>
          {monitorNames.map((v, i) => (
            <div key={i}>{v}</div>
          ))}
        </td>
      </tr>
    );
  };

  return (
    <Table className="mb-4" data-testid={testid('table')}>
      <thead>
        <tr className="align-middle">
          <th>クライアントID</th>
          <th>クライアント名</th>
          <th>申込種別</th>
          <th>依頼日</th>
          <th>掲載状況</th>
          <th>承認状況</th>
          <th>契約開始日</th>
          <th>契約店舗名</th>
          <th>実店舗</th>
          <th>モニター名</th>
        </tr>
      </thead>
      <tbody>{listPerPage?.map((v, si) => listItem(v, si))}</tbody>
    </Table>
  );
};
