import React from 'react';
import { Table } from 'react-bootstrap';
import { Button } from '../../../atoms/Button';
import { createTestId, formatISODate, uuid, when } from '../../../../utils/functions';
import { PointHistoryContext } from '../../../../store/pointPageStore';
import { useSafeContext } from '../../../../hooks/useSafeContext';
import { DATETIME_DISPLAY_FORMAT } from '../../../../Constants';
import { CustomerInfoHeader } from '../../Customer/CustomerInfoHeader';

interface Props {
  onBack: () => void;
}

export const PointHistoryInfoPage: React.FC<Props> = ({ onBack }) => {
  const testid = createTestId(PointHistoryInfoPage);
  const { state: $ } = useSafeContext(PointHistoryContext);

  return (
    <>
      {$.customerId ? <CustomerInfoHeader activeKey="" customerId={$.customerId} /> : <></>}
      <Button data-testid={testid('back-button')} onClick={onBack}>
        戻る
      </Button>
      <Table data-testid={testid('info-table')}>
        <thead />
        <tbody>
          <tr className="align-middle">
            <td>ポイントID</td>
            <td>{$.info?.pointHistoryId}</td>
          </tr>
          <tr className="align-middle">
            <td>ユーザへのメッセージ</td>
            <td>{$.info?.messageForCustomer}</td>
          </tr>
          <tr className="align-middle">
            <td>ポイント交換詳細情報</td>
            <td>{$.info?.exchangePointDetail}</td>
          </tr>
          <tr className="align-middle">
            <td>ポイント付与根拠</td>
            <td>{$.info?.evidence}</td>
          </tr>
        </tbody>
      </Table>
      {when(
        $.receiptList.length > 0,
        <Table data-testid={testid('receipt-table')}>
          <thead>
            <tr className="align-middle">
              <th rowSpan={2}>#</th>
              <th rowSpan={2}>変更日時</th>
              <th rowSpan={2}>変更者</th>
              <th rowSpan={2}>作業メモ</th>
              <th colSpan={5}>各種金額の修正</th>
            </tr>
            <tr className="align-middle">
              <th>種類</th>
              <th>変更前</th>
              <th>変更後</th>
              <th>差分</th>
              <th>備考</th>
            </tr>
          </thead>
          <tbody>
            {$.receiptList.map(({ updateAt, updateIncName, workingMemo, variousModifyList: list }, i) => (
              <React.Fragment key={uuid()}>
                <tr className="align-middle">
                  <td>{i + 1}</td>
                  <td>{formatISODate(updateAt, DATETIME_DISPLAY_FORMAT)}</td>
                  <td>{updateIncName}</td>
                  <td colSpan={6}>{workingMemo}</td>
                </tr>
                {list.map(({ category, priceBeforeChange, priceAfterChange, differentialsAmount, note }) => (
                  <tr key={uuid()} className="align-middle">
                    <td colSpan={4} />
                    <td>{category}</td>
                    <td>{priceBeforeChange}</td>
                    <td>{priceAfterChange}</td>
                    <td>{differentialsAmount}</td>
                    <td>{note}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
