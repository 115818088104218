import React, { useState } from 'react';
import { Form, InputGroup, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { createTestId } from '../../utils/functions';
import { Button } from '../atoms/Button';

export interface Props {
  value: string;
  options: string[];
  onSearch: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickItem: (e: React.MouseEvent<HTMLElement>) => void;
}

export const RecommendSearchBar: React.VFC<Props> = ({ value, options, onSearch, onChange, onClickItem }) => {
  const testid = createTestId(RecommendSearchBar);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <InputGroup className="bg-light p-2 mb-4" data-testid={testid('parent')}>
      <div className="position-relative flex-grow-1">
        <Form.Control
          type="text"
          data-testid={testid('input')}
          value={value}
          onChange={onChange}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
          placeholder="検索"
        />
        {isOpen && (
          <ListGroup
            className="border position-absolute w-100"
            style={{ maxHeight: '202px', overflowY: 'scroll', zIndex: 4 }}
            data-testid={testid('list')}
          >
            {options
              .filter((item) => !value || item.includes(value))
              .map((item) => (
                <ListGroup.Item key={item} className="border-0" onMouseDown={onClickItem} action>
                  {item}
                </ListGroup.Item>
              ))}
          </ListGroup>
        )}
      </div>
      <Button variant="primary" onClick={onSearch} data-testid={testid('search-button')}>
        <FontAwesomeIcon icon={faSearch} className="text-white" fixedWidth />
      </Button>
    </InputGroup>
  );
};
