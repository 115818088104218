import React, { useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import type { FormState } from '../../pages/BulkRejectPage';
import { TagsInputForm } from '../../molecules/TagsInputForm';

interface Props {
  setForms: DispatchSetState<FormState>;
}

export type TestIds = keyof FormState;

export const BulkRejectForms: React.FC<Props> = ({ setForms }) => {
  const testId = createTestId<TestIds>(BulkRejectForms);
  const {
    state: $formState,
    useBindSet,
    onChangeSet: onChangeInput,
  } = useLargeState<FormState>({
    applyIdList: [],
    customerId: '',
  });
  const setApplyIdList = useBindSet('applyIdList');

  useEffect(() => {
    setForms($formState);
  }, [setForms, $formState]);

  return (
    <>
      <Row>
        <Form.Group data-testid={testId('applyIdList')} controlId="applyIdList" key="applyIdList">
          <Form.Label>応募ID</Form.Label>
          <TagsInputForm
            type="number"
            tags={$formState.applyIdList ? $formState.applyIdList.map((applyId: string) => String(applyId)) : []}
            placeholder="応募IDを入力後enterを押下してください"
            setInput={setApplyIdList}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group data-testid={testId('customerId')} controlId="customerId" key="customerId">
          <Form.Label>会員ID</Form.Label>
          <Form.Control
            type="number"
            autoComplete="off"
            required
            min={0}
            value={$formState.customerId || ''}
            onChange={onChangeInput('customerId', String)}
            onKeyDown={(event) => (event.key === 'Enter' ? event.preventDefault() : null)}
          />
        </Form.Group>
      </Row>
    </>
  );
};
