import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import Select from 'react-select';
import { Control, Controller, FieldError, Path, RegisterOptions, useController } from 'react-hook-form';
import sass from '../../scss/molecules/billingCommon.module.scss';
import useBillingSuggestIncAccount from '../../hooks/useBillingSuggestIncAccount';
import useGetValidationRule from '../../hooks/useGetValidationRule';

interface Props<T> {
  registerName: Path<T>;
  control: Control<T>;
  required?: boolean;
  labelName?: string;
}
export const BillingCommonIncAccountSuggest = <T,>({ registerName, control, required, labelName }: Props<T>) => {
  const billingSuggestIncAccountResult = useBillingSuggestIncAccount();
  const {
    fieldState: { error },
  } = useController({ name: registerName, control: control });

  const { getRules } = useGetValidationRule();

  return (
    <Col className="col-6">
      {error !== undefined && <span className={sass.errorMessage}>{(error as FieldError).message}</span>}
      <Form.Group className={sass.billing_select_label}>
        <Form.Label htmlFor={registerName.toString()} className={sass.required}>
          {labelName ?? '担当者'}
        </Form.Label>
        {billingSuggestIncAccountResult.isSuccess && (
          <Controller
            name={registerName}
            control={control}
            render={({ field }) => (
              <div>
                <Select
                  id={registerName.toString()}
                  isSearchable
                  isClearable
                  options={billingSuggestIncAccountResult.data}
                  defaultValue={billingSuggestIncAccountResult.data!.find((v) => v.value === field.value)}
                  onChange={(v) => field.onChange(v?.value)}
                />
              </div>
            )}
            rules={getRules({ required })}
          />
        )}
      </Form.Group>
    </Col>
  );
};
