import * as React from 'react';
import { Table } from 'react-bootstrap';

export interface Props {
  clientDetailInfo: any;
  achievementRate: number;
}

export const FloatingRewardSummaryTable: React.VFC<Props> = ({ clientDetailInfo, achievementRate }) => {
  return (
    <Table className="w-50 mb-4">
      <thead>
        <tr>
          <th>クライアントID</th>
          <th>クライアント名</th>
          <th>派遣マスト数</th>
          <th>達成率</th>
          <th>担当営業</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{clientDetailInfo?.id}</td>
          <td>{clientDetailInfo?.clientName}</td>
          <td>{clientDetailInfo?.requiredNumber}</td>
          <td>{achievementRate}％</td>
          <td>{clientDetailInfo?.roiMemberName}</td>
        </tr>
      </tbody>
    </Table>
  );
};
