import React, { useMemo, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { Tag } from '../../atoms/Tag';
import { uuid } from '../../../utils/functions';
import type { CorrectSettingEachMonitorBaseState } from '../../pages/CorrectSetting/CorrectSettingEachMonitorPage';
import type { CommonCheckOutputResponse } from '../../../api-client';

type ListType = CorrectSettingEachMonitorBaseState['autoCorrectInfo']['autoCorrectRule']['commonCheckList'];

interface Props {
  list: ListType;
}
export type TestIds = 'table';

export const CorrectSettingEachMonitorBaseAutoCorrectTable: React.FC<Props> = ({ list }) => {
  const tableData: {
    label: string;
    name: keyof CommonCheckOutputResponse;
  }[] = useMemo(() => {
    return [
      { label: '確認内容', name: 'checkContent' },
      { label: 'NG時通知文言', name: 'ngMessage' },
      { label: '却下対象アラート', name: 'rejectAlertFlg' },
    ];
  }, []);

  const createDisplayElement = useCallback(
    (item: CommonCheckOutputResponse, labelName: keyof CommonCheckOutputResponse) => {
      if (labelName === 'rejectAlertFlg') {
        return <Tag label={item.rejectAlertFlg ? '出す' : '出さない'} />;
      }

      return item[labelName];
    },
    []
  );

  return (
    <Table>
      <thead>
        <tr className="align-middle">
          {[...tableData.map(({ label }) => label)].map((label) => (
            <th key={label}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {list ? (
          list.map((item) => (
            <tr className="align-middle" key={uuid()}>
              {tableData.map(({ name }) => (
                <td key={String(name)}>{createDisplayElement(item, name)}</td>
              ))}
            </tr>
          ))
        ) : (
          <></>
        )}
      </tbody>
    </Table>
  );
};
