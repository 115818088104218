import * as React from 'react';
import { MonitorRuleSettingAttributeDtoResponse } from '../../../api-client';
import sass from '../../../scss/organisms/calendarTable.module.scss';
import { DayOfWeek } from '../../../Constants';

export const CalenderTable = ({
  monitorRuleSettingAttribute,
}: {
  monitorRuleSettingAttribute?: MonitorRuleSettingAttributeDtoResponse;
}) => {
  return (
    <table className={sass.calender_table}>
      <thead>
        <tr>
          {DayOfWeek.map((day, index) => (
            <th key={`${day}_${index}`}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{monitorRuleSettingAttribute?.mondayVisitsFlg ? '○' : '×'}</td>
          <td>{monitorRuleSettingAttribute?.tuesdayVisitsFlg ? '○' : '×'}</td>
          <td>{monitorRuleSettingAttribute?.wednesdayVisitsFlg ? '○' : '×'}</td>
          <td>{monitorRuleSettingAttribute?.thursdayVisitsFlg ? '○' : '×'}</td>
          <td>{monitorRuleSettingAttribute?.fridayVisitsFlg ? '○' : '×'}</td>
          <td>{monitorRuleSettingAttribute?.saturdayVisitsFlg ? '○' : '×'}</td>
          <td>{monitorRuleSettingAttribute?.sundayVisitsFlg ? '○' : '×'}</td>
          <td>{monitorRuleSettingAttribute?.publicHolidayVisitsFlg ? '○' : '×'}</td>
          <td>{monitorRuleSettingAttribute?.celebrationPreviousDayVisitsFlg ? '○' : '×'}</td>
        </tr>
      </tbody>
    </table>
  );
};
