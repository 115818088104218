import React from 'react';
import type { State as PointGrantPagesState } from '../components/pages/Point/PointGrantPage';
import type { State as PointHistoryPagesState } from '../components/pages/Point/PointHistoryPage';
import { UseLargeState } from '../hooks/useLargeState';
import { LargeStateProviderProps } from '../interfaces/utils';
// eslint-disable-next-line import/no-cycle
import { createSafeContext } from '../utils/functions';

export const PointGrantContext = createSafeContext<UseLargeState<PointGrantPagesState>>();
export const PointHistoryContext = createSafeContext<UseLargeState<PointHistoryPagesState>>();

export const PointGrantProvider: React.FC<LargeStateProviderProps<PointGrantPagesState>> = ({
  largeState,
  children,
}) => <PointGrantContext.Provider value={largeState}>{children}</PointGrantContext.Provider>;
export const PointHistoryProvider: React.FC<LargeStateProviderProps<PointHistoryPagesState>> = ({
  largeState,
  children,
}) => <PointHistoryContext.Provider value={largeState}>{children}</PointHistoryContext.Provider>;
