import axios, { AxiosResponse } from 'axios';
import { format as formatDate } from 'date-fns';
import {
  ImageApi,
  ImageUrlCreateFormResponse,
  ImageUrlCreateOutputResponse,
  ImageUpdateFormResponse,
  IncResultOutputResponse,
} from '../api-client';

/** アップロードされた画像の幅, 高さを取得する */
export const getImageSize = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const reader = new FileReader();
    reader.onload = () => {
      img.src = reader.result as string;
      img.onload = () => {
        resolve({ width: img.naturalWidth, height: img.naturalHeight });
      };
      reader.onerror = (error) => reject(error);
    };
    reader.readAsDataURL(file);
  });
};

/** S3画像アップロード署名付きURLを取得 */
export const getPresignedUrl = async (params: ImageUrlCreateFormResponse): Promise<string | never> => {
  const imageApi = new ImageApi();
  return new Promise((resolve, reject) => {
    imageApi
      .imageUrlCreate(params)
      .then((res: AxiosResponse<ImageUrlCreateOutputResponse>) => {
        const { presignedUrl } = res.data;
        return resolve(presignedUrl);
      })
      .catch(() => {
        const errorMessage = '署名付きURLの取得に失敗しました';
        reject(errorMessage);
        throw new Error(errorMessage);
      });
  });
};

/** S3画像アップロード */
export const s3Upload = async (presignedUrl: string, contentType: string, file: File): Promise<string | never> => {
  return new Promise((resolve, reject) => {
    const instance = axios.create();
    instance
      .put(presignedUrl, file, { headers: { 'Content-Type': contentType } })
      .then(() => {
        const uploadTime = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss');
        return resolve(uploadTime);
      })
      .catch(() => {
        const errorMessage = '画像アップロードに失敗しました';
        reject(errorMessage);
        throw new Error(errorMessage);
      });
  });
};

/** S3画像アップロード連携 */
export const s3UploadAlignment = async (pamams: ImageUpdateFormResponse): Promise<IncResultOutputResponse | never> => {
  const campaignApi = new ImageApi();
  return new Promise((resolve, reject) => {
    campaignApi
      .imageUpdate(pamams)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        return resolve(res.data);
      })
      .catch(() => {
        const errorMessage = '画像アップロードの連携に失敗しました';
        reject(errorMessage);
        throw new Error(errorMessage);
      });
  });
};

/** S3画像削除 */
export const s3Delete = async (id: number): Promise<IncResultOutputResponse | never> => {
  const imageApi = new ImageApi();
  return new Promise((resolve, reject) => {
    imageApi
      .imageDelete(id)
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        return resolve(res.data);
      })
      .catch(() => {
        const errorMessage = '画像削除に失敗しました';
        reject(errorMessage);
        throw new Error(errorMessage);
      });
  });
};

export default {
  getImageSize,
  getPresignedUrl,
  s3Upload,
  s3UploadAlignment,
  s3Delete,
};
