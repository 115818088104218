import { faLink, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import { Accordion, Button, Col, Form, OverlayTrigger, Popover, Row, Table } from 'react-bootstrap';
import {
  MonitorRuleSetInfoMonitorRuleImageOutputResponse,
  MonitorRuleSetInfoMonitorRuleOutputResponse,
  MonitorRuleSetInfoOutputResponse,
} from '../../api-client';
import { Dropzone } from '../molecules/Dropzone';
import { ImageCard } from '../molecules/ImageCard';
import { MonitorRuleSetCommonContents2 } from './MonitorRuleSetCommonContents2';
import { getImageSize, getPresignedUrl, s3Delete, s3Upload, s3UploadAlignment } from '../../utils/s3';
import { ImageProp } from '../pages/MonitorRule/MonitorRuleSetModifyPage';
import { uuid } from '../../utils/functions';

export interface Props {
  data: MonitorRuleSetInfoOutputResponse & ImageProp;
  setData: React.Dispatch<React.SetStateAction<MonitorRuleSetInfoOutputResponse & ImageProp>>;
  rowData: MonitorRuleSetInfoMonitorRuleOutputResponse;

  index: number;
  setIsQuestionAssociationModalFlg: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMonitorRuleId: React.Dispatch<React.SetStateAction<number>>;
}

// 「写真撮影（グルメ）」Contents
export const MonitorRuleSetPhotoShotGourmetContents: React.VFC<Props> = ({
  data,
  rowData,
  setData,
  index,
  setIsQuestionAssociationModalFlg,
  setSelectedMonitorRuleId,
}) => {
  const list: JSX.Element[] = [];
  const sampleRange: any[] = [
    { id: 1, value: '全体が写るように撮影' },
    { id: 2, value: 'メニュー全体が写るように撮影' },
    { id: 99, value: 'その他（フリーテキスト）' },
  ];

  const sampleAngle: any[] = [
    { id: 1, angle: '真上' },
    { id: 2, angle: '真上または斜め上' },
    { id: 3, angle: '斜め上' },
    { id: 4, angle: '真横' },
    { id: 5, angle: '店舗外観（お店の名前がわかるように）が写る角度で撮影' },
    { id: 6, angle: 'メニュー全体が写るように撮影' },
    { id: 99, angle: 'その他（フリーテキスト）' },
  ];

  // 画像添付イベント
  const onSubDrop = async (acceptedFiles: any, ind: number) => {
    // 1ファイルの場合の処理
    if (acceptedFiles.length !== 0 && acceptedFiles.length === 1) {
      const file = acceptedFiles[0];

      const { name, type: contentType } = file;
      const path = window.URL.createObjectURL(file);
      const format = name.split('.').pop();
      const size = await getImageSize(file).catch((error) => console.error('FileReader Error: ', error));
      const imageObj = {
        ...size,
        file,
        name,
        contentType,
        format,
        path,
        isDefault: false,
        monitorRuleListIdx: index,
        imageRuleListIdx: ind,
      };
      setData({
        ...data,
        monitorRuleList: data?.monitorRuleList.map((_, i) => {
          return i === index
            ? {
                ..._,
                imageRuleList: _.imageRuleList?.map((r, c) => {
                  return c === ind
                    ? {
                        ...r,
                        imageList: [
                          ...data.monitorRuleList[i].imageRuleList[c].imageList,
                          { imageId: data.monitorRuleList[i].imageRuleList[c].imageList.length + 1, imageURL: path },
                        ],
                      }
                    : { ...r };
                }),
              }
            : { ..._ };
        }),
        imageList: data.imageList && data.imageList.length > 0 ? data.imageList.concat(imageObj) : [imageObj],
      });
    }
  };

  // 画像削除イベント
  const onImageDelete = (
    newImgList: MonitorRuleSetInfoMonitorRuleImageOutputResponse[] | undefined,
    imageRuleListInd: number,
    imageListInd: number
  ) => {
    if (newImgList) {
      setData({
        ...data,
        monitorRuleList: data?.monitorRuleList.map((_, i) => {
          return i === index
            ? {
                ..._,
                imageRuleList: _.imageRuleList?.map((r, inde) => {
                  return inde === imageRuleListInd
                    ? {
                        ...r,
                        imageList: newImgList.filter((image, ci) => {
                          return ci !== imageListInd;
                        }),
                      }
                    : { ...r };
                }),
              }
            : { ..._ };
        }),
        imageList: data.imageList?.filter(
          (obj) =>
            obj.path !== data.monitorRuleList[index].imageRuleList[imageRuleListInd].imageList[imageListInd].imageURL
        ),
      });
    }
  };

  return (
    <>
      <div className="d-flex mb-4 ">
        <div style={{ width: '50%' }}>
          {data?.monitorRuleList[index].imageRuleList.map((imageRule, ind) => {
            return (
              <div key={ind.toString()} className="d-flex">
                <Accordion className="mb-5" defaultActiveKey="0" style={{ border: '1px solid #dee2e6', width: '90%' }}>
                  <Row className="align-items-center">
                    <Col className="col-auto" style={{ marginLeft: '3rem', fontWeight: 'bold' }}>{`写真撮影${
                      ind + 1
                    }`}</Col>
                    <Col>
                      <Accordion.Header />
                    </Col>
                  </Row>
                  <Accordion.Body className="m-0 pe-1">
                    <Table style={{ borderRight: 'none', borderLeft: '1px solid #dee2e6' }}>
                      <tbody>
                        {/* 撮影対象リスト分行を作成 */}
                        {
                          // 撮影対象リストが存在する場合
                          data?.monitorRuleList[index].imageRuleList![ind]?.imageTargetList?.map((row, inde) => {
                            return (
                              <tr key={`imageTarget${ind + 1} - ${inde + 1}`} className="align-middle">
                                <th
                                  style={{
                                    borderRight: '1px solid #dee2e6',
                                    borderTop: '1px solid #dee2e6',
                                    textAlign: 'center',
                                  }}
                                >
                                  {`撮影対象${inde + 1}`}
                                </th>
                                <td style={{ borderRight: '1px solid #dee2e6', borderTop: '1px solid #dee2e6' }}>
                                  <Form.Select
                                    data-testid="imageTargetSelect"
                                    value={rowData?.imageRuleList![ind]?.imageTargetList![inde] || ''}
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                          return i === index
                                            ? {
                                                ..._,
                                                imageRuleList: _.imageRuleList?.map((r, c) => {
                                                  return c === ind
                                                    ? {
                                                        ...r,
                                                        imageTargetList: r.imageTargetList?.map(
                                                          (imageTarget, targetInd) => {
                                                            return targetInd === inde ? e.target.value : imageTarget;
                                                          }
                                                        ),
                                                      }
                                                    : { ...r };
                                                }),
                                              }
                                            : { ..._ };
                                        }),
                                      });
                                    }}
                                  >
                                    {data?.monitorRuleList
                                      .find((_) => {
                                        return _.monitorRuleType === 6;
                                      })
                                      ?.targetItemList?.map((item, i) => (
                                        <option key={i.toString()} value={item.itemName}>
                                          {item.itemName}
                                        </option>
                                      ))}
                                  </Form.Select>
                                </td>
                                {/* 最初の撮影対象以外は「＋」ボタンを作成しない */}
                                {inde === 0 && (
                                  <td width="5%" style={{ border: 'none', textAlign: 'center' }}>
                                    <Button
                                      data-testid="imageTargetAddButton"
                                      variant="link"
                                      className="text-secondary p-0"
                                      onClick={() => {
                                        const checkCount = data?.monitorRuleList?.find((_d, i) => {
                                          return i === index;
                                        })?.imageRuleList?.[ind]?.imageTargetList?.length;
                                        const itemCount = data.monitorRuleList.find((_) => {
                                          return _.monitorRuleType === 6;
                                        })?.targetItemList?.length;
                                        // 「指定商品」数以上は追加できないように制限
                                        if (
                                          checkCount !== undefined &&
                                          itemCount !== undefined &&
                                          checkCount < itemCount
                                        ) {
                                          // 「指定商品」のうち１番目の商品名
                                          const newImageTarget: any[] | undefined = data.monitorRuleList
                                            .find((_) => {
                                              return _.monitorRuleType === 6;
                                            })
                                            ?.targetItemList?.map((item, i) => {
                                              return item.itemName;
                                            })
                                            .slice(0, 1);
                                          // イメージリストの元の撮影対象リスト
                                          const formerImageTargetList = data?.monitorRuleList?.find((_d, i) => {
                                            return i === index;
                                          })?.imageRuleList?.[ind]?.imageTargetList;

                                          if (newImageTarget) {
                                            const concatList = formerImageTargetList?.concat(newImageTarget);
                                            if (concatList) {
                                              setData({
                                                ...data,
                                                monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                                  return i === index
                                                    ? {
                                                        ..._,
                                                        imageRuleList: _.imageRuleList?.map((r, c) => {
                                                          return c === ind
                                                            ? { ...r, imageTargetList: concatList }
                                                            : { ...r };
                                                        }),
                                                      }
                                                    : { ..._ };
                                                }),
                                              });
                                            }
                                          }
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                                    </Button>
                                  </td>
                                )}
                              </tr>
                            );
                          })
                        }
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>
                            調査目的
                          </th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Control
                              data-testid="objectiveText"
                              value={rowData?.imageRuleList![ind]?.objective || ''}
                              type="text"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind ? { ...r, objective: e.target.value } : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>
                            タイミング
                          </th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Control
                              data-testid="timingText"
                              value={rowData?.imageRuleList![ind]?.timing || ''}
                              type="text"
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind
                                              ? { ...r, timing: e.target.value === '' ? undefined : e.target.value }
                                              : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>範囲</th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Select
                              data-testid="rangeSelect"
                              value={data?.monitorRuleList[index].imageRuleList?.[ind]?.range || ''}
                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind ? { ...r, range: Number(e.target.value) } : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            >
                              {sampleRange.map((item, i) => (
                                <option key={item.id} value={item.id}>
                                  {item.value}
                                </option>
                              ))}
                            </Form.Select>
                          </td>
                        </tr>
                        {/* 「範囲」フリーテキスト */}
                        {data?.monitorRuleList[index].imageRuleList![ind]?.range === 99 && (
                          <tr className="align-middle">
                            <td
                              colSpan={2}
                              style={{
                                borderRight: '1px solid #dee2e6',
                                borderTop: '1px solid #dee2e6',
                                borderBottom: '1px solid #dee2e6',
                              }}
                            >
                              <Form.Control
                                data-testid="rangeFreeText"
                                as="textarea"
                                rows={3}
                                value={data?.monitorRuleList[index].imageRuleList![ind]?.rangeFreeText || ''}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                      return i === index
                                        ? {
                                            ..._,
                                            imageRuleList: _.imageRuleList?.map((r, inde) => {
                                              return inde === ind
                                                ? {
                                                    ...r,
                                                    rangeFreeText: e.target.value === '' ? undefined : e.target.value,
                                                  }
                                                : { ...r };
                                            }),
                                          }
                                        : { ..._ };
                                    }),
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        )}
                        <tr className="align-middle">
                          <th style={{ width: '20%', borderRight: '1px solid #dee2e6', textAlign: 'center' }}>
                            撮影角度
                          </th>
                          <td
                            style={{
                              borderRight: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                            }}
                          >
                            <Form.Select
                              data-testid="angleSelect"
                              value={data?.monitorRuleList[index].imageRuleList![ind]?.angle || ''}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind ? { ...r, angle: Number(e.target.value) } : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            >
                              {sampleAngle.map((item, i) => (
                                <option key={i.toString()} value={item.id}>
                                  {item.angle}
                                </option>
                              ))}
                            </Form.Select>
                          </td>
                        </tr>
                        {/* 「撮影角度」フリーテキスト */}
                        {data?.monitorRuleList[index].imageRuleList![ind]?.angle === 99 && (
                          <tr className="align-middle">
                            <td
                              colSpan={2}
                              style={{
                                borderRight: '1px solid #dee2e6',
                                borderTop: '1px solid #dee2e6',
                                borderBottom: '1px solid #dee2e6',
                              }}
                            >
                              <Form.Control
                                data-testid="angleFreeText"
                                as="textarea"
                                rows={3}
                                value={data?.monitorRuleList[index].imageRuleList![ind]?.angleFreeText || ''}
                                onChange={(e) => {
                                  setData({
                                    ...data,
                                    monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                      return i === index
                                        ? {
                                            ..._,
                                            imageRuleList: _.imageRuleList?.map((r, inde) => {
                                              return inde === ind
                                                ? {
                                                    ...r,
                                                    angleFreeText: e.target.value === '' ? undefined : e.target.value,
                                                  }
                                                : { ...r };
                                            }),
                                          }
                                        : { ..._ };
                                    }),
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        )}
                        <tr className="align-middle">
                          <th
                            style={{
                              width: '20%',
                              borderRight: '1px solid #dee2e6',
                              borderBottom: '1px solid #dee2e6',
                              borderTop: '1px solid #dee2e6',
                              textAlign: 'center',
                            }}
                          >
                            その他注意
                          </th>
                          <td style={{ borderRight: '1px solid #dee2e6' }}>
                            <Form.Control
                              data-testid="imageOtherText"
                              as="textarea"
                              value={data?.monitorRuleList[index].imageRuleList![ind]?.other || ''}
                              onChange={(e) => {
                                setData({
                                  ...data,
                                  monitorRuleList: data?.monitorRuleList.map((_, i) => {
                                    return i === index
                                      ? {
                                          ..._,
                                          imageRuleList: _.imageRuleList?.map((r, inde) => {
                                            return inde === ind
                                              ? { ...r, other: e.target.value === '' ? undefined : e.target.value }
                                              : { ...r };
                                          }),
                                        }
                                      : { ..._ };
                                  }),
                                });
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="align-middle">
                          <td
                            style={{ borderBottom: '1px solid #dee2e6', borderRight: '1px solid #dee2e6' }}
                            colSpan={2}
                          >
                            {/* 一つの写真撮影の中のうち画像の数だけ表示 */}
                            {data?.monitorRuleList
                              ?.find((_d, i) => {
                                return i === index;
                              })
                              ?.imageRuleList?.[ind]?.imageList?.map((ro, imageI) => {
                                return (
                                  <ImageCard
                                    key={uuid()}
                                    url={ro.imageURL}
                                    onDelete={() => {
                                      onImageDelete(
                                        data?.monitorRuleList?.find((_d, i) => {
                                          return i === index;
                                        })?.imageRuleList?.[ind]?.imageList,
                                        ind,
                                        imageI
                                      );
                                    }}
                                  />
                                );
                              })}
                            <Dropzone
                              onDrop={(file) => {
                                onSubDrop(file, ind);
                              }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Accordion.Body>
                </Accordion>
                {ind !== 0 && (
                  <div>
                    <Button
                      data-testid="imageRuleListDeleteButton"
                      variant="link"
                      className="text-secondary mt-2"
                      onClick={() => {
                        const copyImageList = Object.assign(
                          [],
                          data?.monitorRuleList
                            ?.find((d, i) => {
                              return d.monitorRuleType === 7;
                            })
                            ?.imageRuleList.filter((imageRow, imageI) => {
                              return imageI !== ind;
                            })
                        );
                        // 「撮影画像ルールリスト」の削除
                        setData({
                          ...data,
                          monitorRuleList: data?.monitorRuleList.map((_, i) => {
                            return _.monitorRuleType === 7 ? { ..._, imageRuleList: copyImageList } : { ..._ };
                          }),
                        });
                      }}
                      data-html
                    >
                      <FontAwesomeIcon data-testid="deleteButton" className="text-secondary" icon={faTimesCircle} />
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <MonitorRuleSetCommonContents2
        data={data}
        rowData={rowData}
        setData={setData}
        index={index}
        contentsFlg
        setIsQuestionAssociationModalFlg={setIsQuestionAssociationModalFlg}
      />
    </>
  );
};
