import React from 'react';
import { Button, Card, Col, Form, ListGroup, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLines, faTimesCircle, faFileUpload, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

export const RegisterListCardComplex = () => {
  return (
    <>
      <Row>
        <Col>
          <Card className="mb-4">
            <Card.Body>
              <h4>カード型：List 複雑パターン</h4>
              <Form>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="ms-2">
                    キャンセル
                  </Button>
                  <Button variant="outline-secondary" className="ms-2">
                    プレビュー
                  </Button>
                  <Button variant="primary" className="ms-2">
                    保存
                  </Button>
                </div>
                <div className="d-flex justify-content-end mb-4">
                  <Button variant="link" className="text-secondary">
                    <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                    追加
                  </Button>
                </div>
                <div className="border rounded mb-3 p-3">
                  <Row className="align-items-center gx-3">
                    <Col className="flex-grow-0 flex-shrink-0">
                      <button type="button" className="text-secondary">
                        <FontAwesomeIcon icon={faGripLines} fixedWidth />
                      </button>
                    </Col>
                    <Col>
                      <Row className="align-items-center mb-3">
                        <Col className="flex-grow-0 flex-shrink-0">
                          <button type="button" className="text-secondary">
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                          </button>
                        </Col>
                        <Col>
                          <Form.Group controlId="formAccordion_1" className="w-100">
                            <Form.Control type="text" placeholder="プレースホルダー" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end bg-light">
                        <Button variant="link" className="text-secondary p-3">
                          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                          追加
                        </Button>
                      </div>
                      <ListGroup variant="flush" className="bg-light">
                        <ListGroup.Item className="bg-transparent">
                          <Row className="align-items-center gx-3">
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faGripLines} fixedWidth />
                              </button>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faEye} fixedWidth />
                              </button>
                            </Col>
                            <Col className="flex-grow-1">
                              <Row className="gx-3">
                                <Col className="col-10">
                                  <Form.Group controlId="formId2_1">
                                    <Form.Control type="text" placeholder="プレースホルダー" />
                                  </Form.Group>
                                </Col>
                                <Col className="col-2">
                                  <Form.Group controlId="formId2_2">
                                    <Form.Control type="text" />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="d-flex align-items-center" style={{ width: '10rem' }}>
                                <FontAwesomeIcon
                                  icon={faFileUpload}
                                  className="text-secondary flex-shrink-0 me-1"
                                  fixedWidth
                                />
                                <span className="text-truncate flex-grow-1 text-start text-secondary">
                                  画像アップロード
                                </span>
                              </button>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                              </button>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent">
                          <Row className="align-items-center gx-3">
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faGripLines} fixedWidth />
                              </button>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
                              </button>
                            </Col>
                            <Col className="flex-grow-1">
                              <Row className="gx-3">
                                <Col className="col-10">
                                  <Form.Group controlId="formId2_1">
                                    <Form.Control type="text" placeholder="プレースホルダー" />
                                  </Form.Group>
                                </Col>
                                <Col className="col-2">
                                  <Form.Group controlId="formId2_2">
                                    <Form.Control type="text" disabled />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="d-flex align-items-center" style={{ width: '10rem' }}>
                                <FontAwesomeIcon
                                  icon={faFileUpload}
                                  className="text-secondary flex-shrink-0 me-1"
                                  fixedWidth
                                />
                                <span className="text-truncate flex-grow-1 text-start text-secondary">
                                  sampleImageSource.jpg
                                </span>
                              </button>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                              </button>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col className="flex-grow-0 flex-shrink-0">
                      <button type="button" className="text-secondary">
                        <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                      </button>
                    </Col>
                  </Row>
                </div>
                <div className="border rounded mb-3 p-3">
                  <Row className="align-items-center gx-3">
                    <Col className="flex-grow-0 flex-shrink-0">
                      <button type="button" className="text-secondary">
                        <FontAwesomeIcon icon={faGripLines} fixedWidth />
                      </button>
                    </Col>
                    <Col>
                      <Row className="align-items-center mb-3">
                        <Col className="flex-grow-0 flex-shrink-0">
                          <button type="button" className="text-secondary">
                            <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
                          </button>
                        </Col>
                        <Col>
                          <Form.Group controlId="formAccordion_1" className="w-100">
                            <Form.Control type="text" placeholder="プレースホルダー" />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end bg-light">
                        <Button variant="link" className="text-secondary p-3">
                          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
                          追加
                        </Button>
                      </div>
                      <ListGroup variant="flush" className="bg-light">
                        <ListGroup.Item className="bg-transparent">
                          <Row className="align-items-center gx-3">
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faGripLines} fixedWidth />
                              </button>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faEye} fixedWidth />
                              </button>
                            </Col>
                            <Col className="flex-grow-1">
                              <Row className="gx-3">
                                <Col className="col-10">
                                  <Form.Group controlId="formId2_1">
                                    <Form.Control type="text" placeholder="プレースホルダー" />
                                  </Form.Group>
                                </Col>
                                <Col className="col-2">
                                  <Form.Group controlId="formId2_2">
                                    <Form.Control type="text" />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="d-flex align-items-center" style={{ width: '10rem' }}>
                                <FontAwesomeIcon
                                  icon={faFileUpload}
                                  className="text-secondary flex-shrink-0 me-1"
                                  fixedWidth
                                />
                                <span className="text-truncate flex-grow-1 text-start text-secondary">
                                  画像アップロード
                                </span>
                              </button>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                              </button>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                        <ListGroup.Item className="bg-transparent">
                          <Row className="align-items-center gx-3">
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faGripLines} fixedWidth />
                              </button>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
                              </button>
                            </Col>
                            <Col className="flex-grow-1">
                              <Row className="gx-3">
                                <Col className="col-10">
                                  <Form.Group controlId="formId2_1">
                                    <Form.Control type="text" placeholder="プレースホルダー" />
                                  </Form.Group>
                                </Col>
                                <Col className="col-2">
                                  <Form.Group controlId="formId2_2">
                                    <Form.Control type="text" disabled />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="d-flex align-items-center" style={{ width: '10rem' }}>
                                <FontAwesomeIcon
                                  icon={faFileUpload}
                                  className="text-secondary flex-shrink-0 me-1"
                                  fixedWidth
                                />
                                <span className="text-truncate flex-grow-1 text-start text-secondary">
                                  sampleImageSource.jpg
                                </span>
                              </button>
                            </Col>
                            <Col className="flex-grow-0 flex-shrink-0">
                              <button type="button" className="text-secondary">
                                <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                              </button>
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col className="flex-grow-0 flex-shrink-0">
                      <button type="button" className="text-secondary">
                        <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
                      </button>
                    </Col>
                  </Row>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
