import React, { useCallback, useEffect } from 'react';
import { Form, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
// eslint-disable-next-line import/no-cycle
import { CustomerInfoApi } from '../../../api-client';
import type { MailTypeListOutputResponse, MailHistoryRoiDisplayOutputResponse } from '../../../api-client';
import { createTestId } from '../../../utils/functions';
import { Alert } from '../../atoms/Alert';
import { Url } from '../../../constants/Url';
import { MailHistoryListForms } from '../../organisms/Forms/MailHistoryListForms';
import { MailHistoryListTable } from '../../organisms/Table/MailHistoryListTable';
import { MAIL_SEAERCH_TYPE_ALL } from '../../../Constants';
import { CustomerInfoHeader } from '../Customer/CustomerInfoHeader';

type MailHistoryListRoiForm = { customerId: number; messageType?: number };

export interface MailHistoryListOemState {
  api: CustomerInfoApi;
  isLoading: boolean;
  list: MailHistoryRoiDisplayOutputResponse[];
  forms: MailHistoryListRoiForm;
  mailTypeList: [string, number][];
  errorMessage: string[];
}
export type TestIds = 'search-button' | 'link-button' | 'textFlg';

export const MailHistoryListRoiPage: React.FC = () => {
  const testid = createTestId<TestIds>(MailHistoryListRoiPage);
  const { id } = useParams<{ id: string | undefined }>();
  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<MailHistoryListOemState>({
    api: new CustomerInfoApi(),
    isLoading: false,
    list: [],
    forms: { customerId: Number(id), messageType: MAIL_SEAERCH_TYPE_ALL },
    mailTypeList: [],
    errorMessage: [],
  });

  const history = useHistory();
  const setForms = useBindSet('forms');

  useEffect(() => {
    $.api
      .mailTypeList()
      .then((res: AxiosResponse<MailTypeListOutputResponse[]>) => {
        if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
          mergeState({ mailTypeList: [], errorMessage: ['エラーが発生しました。'], isLoading: false });
        } else {
          const mailTypeList: [string, number][] = res.data.map(({ code, name }) => [name, code]);
          mailTypeList.unshift(['すべて', MAIL_SEAERCH_TYPE_ALL]);
          mergeState({ mailTypeList, errorMessage: [], isLoading: false });
        }
      })
      .catch(() => {
        history.push(Url.COMMON_ERROR);
      });
  }, [$.api, history, mergeState]);

  // 初期フェッチ
  useEffect(() => {
    onSearch({ customerId: Number(id) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 検索
  const onSearch = useCallback(
    (body: MailHistoryListRoiForm) => {
      // messageTypeがAll（99）の場合はnullで検索をしたいのでundefinedにする
      const messageType = Number(body.messageType) === MAIL_SEAERCH_TYPE_ALL ? undefined : body.messageType;
      mergeState({ isLoading: true });
      $.api
        .mailHistoryRoiDisplay(body.customerId, messageType)
        .then((res: AxiosResponse<MailHistoryRoiDisplayOutputResponse[]>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            mergeState({ list: [], errorMessage: ['エラーが発生しました。'], isLoading: false });
          } else {
            mergeState({ list: res.data, errorMessage: [], isLoading: false });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    },
    [$.api, history, mergeState]
  );

  return (
    <>
      {$.errorMessage.length
        ? $.errorMessage.map((errorMessage) => (
            <Alert variant="danger" key={errorMessage}>
              {errorMessage}
            </Alert>
          ))
        : undefined}
      <CustomerInfoHeader activeKey="mailHistory" customerId={Number(id)} />
      <Form className="mt-4">
        <Row className="g-2 mb-4">
          <div className="d-flex p-2" style={{ background: 'rgba(0, 0, 0, 0.05)' }}>
            <MailHistoryListForms setForms={setForms} forms={$.forms} mailTypeList={$.mailTypeList} />
            <span className="mt-2 ms-2">
              <Button
                data-testid={testid('search-button')}
                className="mt-4 center-block"
                onClick={() => {
                  onSearch($.forms);
                }}
              >
                検索
              </Button>
            </span>
          </div>
        </Row>
      </Form>
      <LoadingSpinner isLoading={$.isLoading}>
        {$.list.length ? <MailHistoryListTable dataList={$.list} /> : undefined}
      </LoadingSpinner>
    </>
  );
};
