import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { Alert } from '../atoms/Alert';

import {
  HolidayApi,
  HolidayListOutputResponse,
  HolidayCreateFormResponse,
  IncResultOutputResponse,
} from '../../api-client';
import { HolidayManageEditModal } from '../organisms/Modal/HolidayManageEditModal';

const sampleData = [
  {
    id: '1',
    name: '元日',
    holiday: '2022/01/01',
    tocFlg: false,
    billingFlg: true,
    disableFlg: false,
    updateAt: '2022/01/01',
    updateBy: 'テストユーザー',
  },
  {
    id: '2',
    name: '成人の日',
    holiday: '2022/01/10',
    tocFlg: false,
    billingFlg: true,
    disableFlg: false,
    updateAt: '2022/01/01',
    updateBy: 'テストユーザー',
  },
  {
    id: '3',
    name: '建国記念日',
    holiday: '2022/02/11',
    tocFlg: true,
    billingFlg: false,
    disableFlg: false,
    updateAt: '2022/01/01',
    updateBy: 'テストユーザー',
  },
  {
    id: '4',
    name: '社内一斉休暇日',
    holiday: '2022/02/15',
    tocFlg: false,
    billingFlg: false,
    disableFlg: true,
    updateAt: '2022/01/01',
    updateBy: 'テストユーザー',
  },
  {
    id: '5',
    name: '天皇誕生日',
    holiday: '2022/02/23',
    tocFlg: false,
    billingFlg: false,
    disableFlg: false,
    updateAt: '2022/01/01',
    updateBy: 'テストユーザー',
  },
];

export const HolidayManagePage: React.VFC = () => {
  const [data, setData] = useState<HolidayCreateFormResponse>({
    billingFlg: true,
    toCFlg: false,
    name: '',
    holiday: '',
    disableFlg: false,
  });
  const [list, setList] = useState<HolidayListOutputResponse[]>([]);
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>({
    result: false,
    errorCode: '',
    errorMessage: '',
  });
  const [validated, setValidated] = useState(false);
  const [editItem, setEditItem] = useState<any>();
  const [isEditModal, setIsEditModal] = useState<boolean>(false);

  const holidayApi = new HolidayApi();

  const currentYear = new Date().getFullYear();
  const yearOptions = () => {
    const arr = [];
    for (let i = -2; i <= 2; i += 1) {
      arr.push(
        <option key={currentYear + i} value={currentYear + i}>
          {currentYear + i}
        </option>
      );
    }
    return arr;
  };

  function fetchData() {
    holidayApi.getHoliday(currentYear.toString()).then((res: AxiosResponse<HolidayListOutputResponse[]>) => {
      // スタブ用データ表示
      // res.data = sampleData;
      const sortedData = res.data.sort((a, b) => a.holiday.localeCompare(b.holiday));
      setList(sortedData);
    });
  }

  function getDayOfWeek(holiday: string) {
    const date = new Date(`${holiday.slice(0, 4)}/${holiday.slice(5, 7)}/${holiday.slice(8, 10)}`);
    const dayOfWeek = date.getDay();
    const dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'][dayOfWeek];

    return `${holiday}（${dayOfWeekStr}）`;
  }

  useEffect(() => {
    fetchData();
  }, []);

  const handleChageSelect = (event: any): void => {
    holidayApi.getHoliday(event.target.value).then((res: AxiosResponse<HolidayListOutputResponse[]>) => {
      const sortedData = res.data.sort((a, b) => a.holiday.localeCompare(b.holiday));
      setList(sortedData);
    });
  };

  const handleSubmit = (e: any) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(false);
    } else {
      setValidated(true);

      holidayApi
        .createHoliday(data)
        .then((response: AxiosResponse<IncResultOutputResponse>) => {
          setUpdResult(response.data);
          fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
      e.preventDefault();
    }
  };

  const handleClickEdit = (item: any) => {
    setEditItem(item);
    setIsEditModal(true);
  };

  // 削除
  const handleClickDeleteHoliday = (targetId: number) => {
    holidayApi
      .deleteHoliday(targetId)
      .then((response: AxiosResponse<any>) => {
        if (response.data.result) {
          setList(list.filter((item) => item.id !== targetId.toString()));
        }
        setUpdResult(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'holiday':
        setData({ ...data, holiday: event.target.value });
        break;
      case 'name':
        setData({ ...data, name: event.target.value });
        break;
      case 'toCFlg':
        setData({ ...data, toCFlg: event.target.checked });
        break;
      case 'billingFlg':
        setData({ ...data, billingFlg: event.target.checked });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <HolidayManageEditModal
        isEditModal={isEditModal}
        setIsEditModal={setIsEditModal}
        editItem={editItem}
        setList={setList}
      />
      <Row>
        <Col>
          <h3>祝祭日管理</h3>
          {updResult?.result && <Alert variant="success">登録しました。</Alert>}
          {updResult?.errorCode && updResult?.errorMessage && <Alert variant="danger">{updResult.errorMessage}</Alert>}
          <Card>
            <Card.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="g-2 mb-4">
                  <Col>
                    <Form.Group controlId="holiday">
                      <Form.Label>祝祭日</Form.Label>
                      <Form.Control required type="date" name="holiday" onChange={onChangeText} />
                      <Form.Control.Feedback type="invalid">日付は必須入力項目です。</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="name">
                      <Form.Label>理由</Form.Label>
                      <Form.Control required type="text" name="name" placeholder="理由" onChange={onChangeText} />
                      <Form.Control.Feedback type="invalid">理由は必須入力項目です。</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <p className="mb-2">toCフラグ</p>
                    <Form.Check id="toCFlg" className="d-flex">
                      <Form.Check.Input
                        className="flex-shrink-0 me-2"
                        type="checkbox"
                        name="toCFlg"
                        style={{ marginTop: '0.75rem' }}
                        onChange={onChangeText}
                      />
                    </Form.Check>
                  </Col>
                  <Col>
                    <p className="mb-2">請求フラグ</p>
                    <Form.Check id="billingFlg" className="d-flex">
                      <Form.Check.Input
                        className="flex-shrink-0 me-2"
                        type="checkbox"
                        name="billingFlg"
                        style={{ marginTop: '0.75rem' }}
                        onChange={onChangeText}
                        defaultChecked
                      />
                    </Form.Check>
                  </Col>
                  <Col>
                    <Button type="submit" style={{ marginTop: '2rem' }}>
                      登録
                    </Button>
                  </Col>
                </Row>
              </Form>
              <Form>
                <Form.Group className="d-flex mb-4" controlId="year">
                  <Form.Label className="pt-3 pe-2 pb-2 mb-0">祝祭日検索：</Form.Label>
                  <Col className="col-8 p-2">
                    <Form.Select defaultValue={currentYear} onChange={handleChageSelect}>
                      {yearOptions()}
                    </Form.Select>
                  </Col>
                </Form.Group>
                <Table>
                  <thead>
                    <tr className="align-middle">
                      <th>祝祭日</th>
                      <th>理由</th>
                      <th>toCフラグ</th>
                      <th>請求フラグ</th>
                      <th>登録者</th>
                      <th>&nbsp;</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list?.map((item, idx) => {
                      return (
                        !item.disableFlg && (
                          <tr key={idx} className="align-middle">
                            <td>{getDayOfWeek(item.holiday)}</td>
                            <td>{item.name}</td>
                            <td>
                              <Form.Check.Input
                                className="flex-shrink-0 me-2"
                                type="checkbox"
                                name=""
                                checked={item.tocFlg}
                                disabled
                              />
                            </td>
                            <td>
                              <Form.Check.Input
                                className="flex-shrink-0 me-2"
                                type="checkbox"
                                name=""
                                checked={item.billingFlg}
                                disabled
                              />
                            </td>
                            <td>{item.updateBy}</td>
                            <td>
                              <Button onClick={() => handleClickEdit(item)}>編集</Button>
                            </td>
                            <td>
                              <Button type="submit" onClick={() => handleClickDeleteHoliday(Number(item.id))}>
                                削除
                              </Button>
                            </td>
                          </tr>
                        )
                      );
                    })}
                  </tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
