import * as React from 'react';
import { Col, Form } from 'react-bootstrap';
import { FieldPathValue } from 'react-hook-form/dist/types/utils';
import { FieldError, Path, UnpackNestedValue, useController, useFormContext } from 'react-hook-form';
import sass from '../../scss/molecules/billingCommon.module.scss';
import useGetValidationRule from '../../hooks/useGetValidationRule';

interface Props<T> {
  className?: string;
  registerName: Path<T>;
  labelName: string;
  options: {
    id: number;
    name: string;
  }[];
  required?: boolean;
  defaultValue?: number;
}

/**
 * FormProviderでラップされたコンポーネントで使うこと
 * @param className
 * @param registerName
 * @param labelName
 * @param options
 * @param required
 * @param defaultValue
 * @constructor
 */
export const BillingCommonInputSelect = <T,>({ className, registerName, labelName, options, required }: Props<T>) => {
  const { getRules } = useGetValidationRule();
  const { register, control, setValue } = useFormContext<T>();
  const {
    fieldState: { error },
  } = useController({ name: registerName, control: control });
  return (
    <Col className="col-6">
      {error !== undefined && <span className={sass.errorMessage}>{(error as FieldError).message}</span>}
      <Form.Group className={`d-flex justify-content-start ${className}`}>
        <Form.Label
          htmlFor={registerName.toString()}
          style={{ width: '68%', margin: '0.5rem' }}
          className={required ? sass.required : ''}
        >
          {labelName}
        </Form.Label>
        <Form.Select
          id={registerName.toString()}
          {...register(registerName, getRules({ required }))}
          onChange={(e) => setValue(registerName, e.target.value as UnpackNestedValue<FieldPathValue<any, Path<T>>>)}
        >
          <option>{}</option>
          {options.map((v) => (
            <option key={`${registerName.toString()}_${v.id}`} value={v.id}>
              {v.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>
  );
};
