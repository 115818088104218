import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import type { ModalState as ParentState } from '../Modal/Correct/CorrectPhoneMasterEditModal';
import type { CommonMasterListOutputResponse } from '../../../api-client';

interface Props {
  forms: ParentState['forms'];
  setForms: DispatchSetState<ParentState['forms']>;
  prefectureList: CommonMasterListOutputResponse[];
}
export const CorrectPhoneMasterEditModalForms: React.FC<Props> = ({ forms, setForms, prefectureList }) => {
  const { state: $, onChangeSet } = useLargeState<ParentState['forms']>(forms ?? {});

  useEffect(() => {
    setForms({ ...$ });
  }, [setForms, $]);

  return (
    <>
      <Form.Group controlId="chainName" key="chainName">
        <Form.Label>チェーン</Form.Label>
        <Form.Control
          type="text"
          autoComplete="off"
          value={$.chainName || ''}
          onChange={onChangeSet('chainName', String)}
        />
      </Form.Group>
      <Form.Group controlId="storeName" key="storeName">
        <Form.Label>ストア</Form.Label>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '90%' }}>
            <Form.Control
              type="text"
              autoComplete="off"
              value={$.storeName || ''}
              onChange={onChangeSet('storeName', String)}
            />
          </div>
          <div style={{ padding: '5px' }}>店</div>
        </div>
      </Form.Group>
      <Form.Group controlId="storePhoneNumber" key="storePhoneNumber">
        <Form.Label>電話番号</Form.Label>
        <Form.Control
          type="number"
          autoComplete="off"
          value={$.storePhoneNumber !== undefined ? $.storePhoneNumber : undefined}
          onChange={onChangeSet('storePhoneNumber', String)}
        />
      </Form.Group>
      <Form.Group controlId="prefectureId" key="prefectureId">
        <Form.Label>都道府県</Form.Label>
        <Form.Select value={$.prefectureId || ''} onChange={onChangeSet('prefectureId', Number)}>
          <option value={-1} key={-1} hidden>
            --選択してください--
          </option>
          {prefectureList.map(({ id, name }) => {
            return (
              <option value={id} key={id}>
                {name}
              </option>
            );
          })}
        </Form.Select>
      </Form.Group>
    </>
  );
};
