import React, { useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { Alert, Button, ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useLargeState } from '../../../hooks/useLargeState';
import { formatISODate, uuid, when } from '../../../utils/functions';
import { DispatchSetState } from '../../../interfaces/utils';
import { InputNumber } from '../../atoms/InputNumber';
import type {
  CorrectSettingEachMonitorBaseState,
  AutoCorrectChainStoreForm,
} from '../../pages/CorrectSetting/CorrectSettingEachMonitorPage';
import { DATETIME_DISPLAY_FORMAT } from '../../../Constants';
import { Tag } from '../../atoms/Tag';
import { Modal } from '../../molecules/Modal';
import { TagsInputForm } from '../../molecules/TagsInputForm';
import { NgRejectContent } from '../Correction/DataInput/NgRejectContent';
// eslint-disable-next-line import/no-cycle
import { CommonMasterListOutputResponse } from '../../../api-client';

type State = CorrectSettingEachMonitorBaseState['autoCorrectForm'];
type OutputType = CorrectSettingEachMonitorBaseState['autoCorrectInfo'];
type SetStateType = {
  referencedMonitorId: number;
  errorMessage: string;
  isStoreModal: boolean;
  isPrefectureModal: boolean;
  modalIndex: number;
  exclusionFlg: boolean;
  isExistOkChainStore: boolean;
};

interface Props {
  setForms: DispatchSetState<State>;
  autoCorrectInfo: OutputType;
  searchSetting: (id: number) => Promise<OutputType | undefined>;
  comparisonMethodList: CorrectSettingEachMonitorBaseState['comparisonMethodList'];
  prefectureList: CommonMasterListOutputResponse[];
}

export const CorrectSettingEachMonitorBaseAutoCorrectForms: React.FC<Props> = React.memo(
  ({ setForms, autoCorrectInfo, searchSetting, children, comparisonMethodList, prefectureList }) => {
    const initialAutoCorrectForm: State = useMemo(() => {
      let initialOkChainStoreConditionData: State['okTargetChainStoreSetting']['conditionList'] = [];
      let initialNgChainStoreConditionData: State['ngTargetChainStoreSetting']['conditionList'] = [];
      const { targetOkChainStoreSettingList, targetNgChainStoreSettingList } = autoCorrectInfo.autoCorrectRule;

      if (targetOkChainStoreSettingList && targetOkChainStoreSettingList.conditionList.length > 0) {
        initialOkChainStoreConditionData = [
          ...targetOkChainStoreSettingList.conditionList.map((data) => {
            return {
              ...data,
              newFlg: false,
              deleteFlg: false,
            };
          }),
        ];
      }
      if (targetNgChainStoreSettingList && targetNgChainStoreSettingList.conditionList.length > 0) {
        initialNgChainStoreConditionData = [
          ...targetNgChainStoreSettingList.conditionList.map((data) => {
            return {
              ...data,
              newFlg: false,
              deleteFlg: false,
            };
          }),
        ];
      }

      return {
        lastUpdateAt: autoCorrectInfo.autoCorrectRule.updateAt ?? '',
        autoCorrectStartFlg: !!autoCorrectInfo.autoCorrectRule.autoCorrectStartFlg,
        duplicateCheckTargetMonitorIdList: autoCorrectInfo.autoCorrectRule.duplicateCheckTargetMonitorIdList || {
          monitorIdList: '',
          ngMessage: '',
          rejectAlertFlg: false,
        },
        okTargetChainStoreSetting: autoCorrectInfo.autoCorrectRule.targetOkChainStoreSettingList || {
          conditionList: initialOkChainStoreConditionData,
          ngMessage: '',
          rejectAlertFlg: false,
        },
        ngTargetChainStoreSetting: autoCorrectInfo.autoCorrectRule.targetNgChainStoreSettingList || {
          conditionList: initialNgChainStoreConditionData,
          ngMessage: '',
          rejectAlertFlg: false,
        },
        targetPeriodSetting: autoCorrectInfo.autoCorrectRule.targetPeriodSetting || {
          conditionList: [],
          ngMessage: '',
          rejectAlertFlg: false,
        },
        targetPrefecturesSetting: autoCorrectInfo.autoCorrectRule.targetPrefecturesSetting
          ? {
              ...autoCorrectInfo.autoCorrectRule.targetPrefecturesSetting,
              prefectureList: autoCorrectInfo.autoCorrectRule.targetPrefecturesSetting.prefecturesList,
            }
          : {
              prefectureList: [],
              ngMessage: '',
              rejectAlertFlg: false,
            },
        targetPriceSetting: autoCorrectInfo.autoCorrectRule.targetPriceSettingList || {
          conditionList: [],
          ngMessage: '',
          rejectAlertFlg: false,
        },
      };
    }, [autoCorrectInfo]);

    const { state: $, mergeState } = useLargeState<State>(initialAutoCorrectForm);

    useEffect(() => {
      setForms({ ...$ });
    }, [setForms, $]);

    useEffect(() => {
      mergeState({ ...initialAutoCorrectForm });
    }, [initialAutoCorrectForm, mergeState]);

    const { state: $set, mergeState: mergeSet } = useLargeState<SetStateType>({
      referencedMonitorId: 0,
      errorMessage: '',
      isStoreModal: false,
      isPrefectureModal: false,
      modalIndex: 0,
      exclusionFlg: false,
      isExistOkChainStore: true,
    });

    useEffect(() => {
      mergeSet({
        isExistOkChainStore:
          initialAutoCorrectForm.okTargetChainStoreSetting &&
          initialAutoCorrectForm.ngTargetChainStoreSetting.conditionList.length > 0,
      });
    }, [initialAutoCorrectForm, mergeSet]);

    const onSetting = (id: number) => {
      if (!id) return;

      searchSetting(id).then((targetSetting) => {
        if (targetSetting && targetSetting.correctMethodId === autoCorrectInfo.correctMethodId) {
          const okChainStoreData = targetSetting.autoCorrectRule.targetOkChainStoreSettingList
            ? {
                ...targetSetting.autoCorrectRule.targetOkChainStoreSettingList,
                conditionList: targetSetting.autoCorrectRule.targetOkChainStoreSettingList.conditionList.map((data) => {
                  return { ...data, newFlg: false, deleteFlg: false };
                }),
              }
            : { conditionList: [], ngMessage: '', rejectAlertFlg: false };
          const ngChainStoreData = targetSetting.autoCorrectRule.targetNgChainStoreSettingList
            ? {
                ...targetSetting.autoCorrectRule.targetNgChainStoreSettingList,
                conditionList: targetSetting.autoCorrectRule.targetNgChainStoreSettingList.conditionList.map((data) => {
                  return { ...data, newFlg: false, deleteFlg: false };
                }),
              }
            : { conditionList: [], ngMessage: '', rejectAlertFlg: false };

          const prefectureData = targetSetting.autoCorrectRule.targetPrefecturesSetting
            ? {
                ...targetSetting.autoCorrectRule.targetPrefecturesSetting,
                prefectureList: targetSetting.autoCorrectRule.targetPrefecturesSetting?.prefecturesList,
              }
            : { ngMessage: '', rejectAlertFlg: false, prefectureList: [] };

          mergeState({
            lastUpdateAt: targetSetting.autoCorrectRule.updateAt,
            autoCorrectStartFlg: targetSetting.autoCorrectRule.autoCorrectStartFlg,
            duplicateCheckTargetMonitorIdList: targetSetting.autoCorrectRule.duplicateCheckTargetMonitorIdList,
            okTargetChainStoreSetting: okChainStoreData,
            ngTargetChainStoreSetting: ngChainStoreData,
            targetPeriodSetting: targetSetting.autoCorrectRule.targetPeriodSetting,
            targetPrefecturesSetting: prefectureData,
            targetPriceSetting: targetSetting.autoCorrectRule.targetPriceSettingList,
          });

          mergeSet({ errorMessage: '' });
        } else if (targetSetting && targetSetting.correctMethodId !== autoCorrectInfo.correctMethodId) {
          mergeSet({ errorMessage: '添削方法が異なります' });
        }
      });
    };

    const renderDuplicateCheckCard = () => {
      return $.duplicateCheckTargetMonitorIdList ? (
        <Card className="p-2">
          <div className="d-flex">
            <div style={{ minWidth: '95%' }}>
              <div>
                <Form.Label className="mt-2" style={{ minWidth: '80px' }}>
                  モニターID
                </Form.Label>
                <Form.Control
                  type="text"
                  value={$.duplicateCheckTargetMonitorIdList.monitorIdList || ''}
                  onChange={(e) => {
                    const ids = e.target.value.replace(/[^0-9,]/gi, '');
                    mergeState({
                      duplicateCheckTargetMonitorIdList: {
                        ...$.duplicateCheckTargetMonitorIdList,
                        monitorIdList: ids,
                      },
                    });
                  }}
                />
              </div>
              <p className="text-secondary">
                ＊モニターIDを「,」区切りで入力してください。NGメッセージの最後に重複先の応募IDが表示されます。
              </p>
              <NgRejectContent
                ngMessage={$.duplicateCheckTargetMonitorIdList.ngMessage}
                rejectAlertFlg={$.duplicateCheckTargetMonitorIdList.rejectAlertFlg}
                onChangeNgMessage={(value: string) => {
                  mergeState({
                    duplicateCheckTargetMonitorIdList: {
                      ...$.duplicateCheckTargetMonitorIdList,
                      ngMessage: value,
                    },
                  });
                }}
                onChangeRejectAlertFlg={(value: boolean) => {
                  mergeState({
                    duplicateCheckTargetMonitorIdList: {
                      ...$.duplicateCheckTargetMonitorIdList,
                      rejectAlertFlg: !value,
                    },
                  });
                }}
              />
            </div>
            <Button
              variant="link"
              className="ms-2"
              style={{ maxWidth: '5%' }}
              onClick={() => {
                mergeState({ duplicateCheckTargetMonitorIdList: undefined });
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button>
          </div>
        </Card>
      ) : (
        <div className="mw-100 d-flex justify-content-end">
          <Button
            variant="link"
            className="me-2"
            onClick={() => {
              mergeState({
                duplicateCheckTargetMonitorIdList: { monitorIdList: '', ngMessage: '', rejectAlertFlg: false },
              });
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
            追加
          </Button>
        </div>
      );
    };

    const renderChainStoreCard = (
      condition: AutoCorrectChainStoreForm['conditionList'][0],
      i: number,
      exclusionFlg: boolean
    ) => {
      if (condition.deleteFlg) return undefined;
      return (
        <Card className="p-2 me-2" key={i}>
          <div className="d-flex">
            <div style={{ minWidth: '95%' }}>
              <div className="d-flex justify-content-between">
                {condition.newFlg ? (
                  <>
                    <Form.Label className="mt-1" style={{ minWidth: '110px' }}>
                      チェーン・ストア名
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={condition.chainName}
                      onChange={(e) => {
                        mergeState(
                          exclusionFlg
                            ? {
                                ngTargetChainStoreSetting: {
                                  ...$.ngTargetChainStoreSetting,
                                  conditionList: $.ngTargetChainStoreSetting.conditionList.map((info, idx) => {
                                    if (i !== idx) return info;
                                    return {
                                      ...condition,
                                      chainName: e.target.value,
                                    };
                                  }),
                                },
                              }
                            : {
                                okTargetChainStoreSetting: {
                                  ...$.okTargetChainStoreSetting,
                                  conditionList: $.okTargetChainStoreSetting.conditionList.map((info, idx) => {
                                    if (i !== idx) return info;
                                    return {
                                      ...condition,
                                      chainName: e.target.value,
                                    };
                                  }),
                                },
                              }
                        );
                      }}
                    />
                  </>
                ) : (
                  <Form.Label>{condition.chainName}</Form.Label>
                )}
                <Button
                  variant="link"
                  style={{ minWidth: '100px' }}
                  onClick={() => mergeSet({ isStoreModal: true, modalIndex: i, exclusionFlg })}
                >
                  ストア一覧
                </Button>
              </div>
            </div>
            <Button
              variant="link"
              style={{ maxWidth: '5%' }}
              onClick={() => {
                mergeState(
                  exclusionFlg
                    ? {
                        ngTargetChainStoreSetting: {
                          ...$.ngTargetChainStoreSetting,
                          conditionList: $.ngTargetChainStoreSetting.conditionList.map((info, idx) => {
                            if (i !== idx) return info;
                            return { ...info, deleteFlg: true };
                          }),
                        },
                      }
                    : {
                        okTargetChainStoreSetting: {
                          ...$.okTargetChainStoreSetting,
                          conditionList: $.okTargetChainStoreSetting.conditionList.map((info, idx) => {
                            if (i !== idx) return info;
                            return { ...info, deleteFlg: true };
                          }),
                        },
                      }
                );
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button>
          </div>
        </Card>
      );
    };

    const renderPrefectureCard = () => {
      return (
        <Card className="p-2">
          <div className="d-flex justify-content-between">
            <div>
              <Form.Label className="mt-1" style={{ minWidth: '80px' }}>
                都道府県
              </Form.Label>
              <div>
                {$.targetPrefecturesSetting.prefectureList.length > 0 ? (
                  $.targetPrefecturesSetting.prefectureList.map(({ name }) => (
                    <span className="me-1" key={uuid()}>
                      <Tag label={name} key={uuid()} />
                    </span>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
            <Button variant="link" style={{ minWidth: '100px' }} onClick={() => mergeSet({ isPrefectureModal: true })}>
              編集
            </Button>
          </div>
          <NgRejectContent
            ngMessage={$.targetPrefecturesSetting.ngMessage}
            rejectAlertFlg={$.targetPrefecturesSetting.rejectAlertFlg}
            onChangeNgMessage={(value: string) => {
              mergeState({
                targetPrefecturesSetting: {
                  ...$.targetPrefecturesSetting,
                  ngMessage: value,
                },
              });
            }}
            onChangeRejectAlertFlg={(value: boolean) => {
              mergeState({
                targetPrefecturesSetting: {
                  ...$.targetPrefecturesSetting,
                  rejectAlertFlg: !value,
                },
              });
            }}
          />
        </Card>
      );
    };

    const renderTargetPeriodCard = (periodInfo: State['targetPeriodSetting']['conditionList'][0], i: number) => {
      return (
        <Card className="p-2" key={i}>
          <div className="d-flex">
            <div style={{ minWidth: '95%' }}>
              <Form.Select
                value={periodInfo.comparisonMethodId}
                onChange={(e) =>
                  mergeState({
                    targetPeriodSetting: {
                      ...$.targetPeriodSetting,
                      conditionList: $.targetPeriodSetting.conditionList.map((info, idx) => {
                        if (i !== idx) return info;
                        return {
                          ...info,
                          comparisonMethodId: Number(e.target.value),
                        };
                      }),
                    },
                  })
                }
              >
                {comparisonMethodList.map(({ code }) => {
                  return (
                    <option value={code} key={code}>
                      {code === 0 ? '指定日' : '指定期間'}
                    </option>
                  );
                })}
              </Form.Select>
              {when(
                periodInfo.comparisonMethodId === 0,
                <Form.Control
                  type="date"
                  min="1900-01-01"
                  max="2100-12-31"
                  autoComplete="off"
                  value={periodInfo.targetDate || ''}
                  onChange={(e) =>
                    mergeState({
                      targetPeriodSetting: {
                        ...$.targetPeriodSetting,
                        conditionList: $.targetPeriodSetting.conditionList.map((info, idx) => {
                          if (i !== idx) return info;
                          return {
                            ...info,
                            targetDate: e.target.value,
                          };
                        }),
                      },
                    })
                  }
                />
              )}
              {when(
                periodInfo.comparisonMethodId === 1,
                <Row>
                  <Col>
                    <Form.Control
                      type="date"
                      min="1900-01-01"
                      max="2100-12-31"
                      autoComplete="off"
                      value={periodInfo.targetDateStart || ''}
                      onChange={(e) =>
                        mergeState({
                          targetPeriodSetting: {
                            ...$.targetPeriodSetting,
                            conditionList: $.targetPeriodSetting.conditionList.map((info, idx) => {
                              if (i !== idx) return info;
                              return {
                                ...info,
                                targetDateStart: e.target.value,
                              };
                            }),
                          },
                        })
                      }
                    />
                  </Col>
                  <Col className="text-center" xs={1}>
                    ~
                  </Col>
                  <Col>
                    <Form.Control
                      type="date"
                      min="1900-01-01"
                      max="2100-12-31"
                      autoComplete="off"
                      value={periodInfo.targetDateEnd || ''}
                      onChange={(e) =>
                        mergeState({
                          targetPeriodSetting: {
                            ...$.targetPeriodSetting,
                            conditionList: $.targetPeriodSetting.conditionList.map((info, idx) => {
                              if (i !== idx) return info;
                              return {
                                ...info,
                                targetDateEnd: e.target.value,
                              };
                            }),
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              )}
            </div>
            <Button
              variant="link"
              className="ms-2"
              style={{ maxWidth: '5%' }}
              onClick={() => {
                const newArray = $.targetPeriodSetting.conditionList;
                newArray.splice(i, 1);
                mergeState({
                  targetPeriodSetting: { ...$.targetPeriodSetting, conditionList: newArray },
                });
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button>
          </div>
        </Card>
      );
    };

    const renderTargetPriceCard = (priceInfo: State['targetPriceSetting']['conditionList'][0], i: number) => {
      return (
        <Card className="p-2" key={i}>
          <div className="d-flex">
            <div style={{ minWidth: '95%' }}>
              <Form.Select
                value={priceInfo.comparisonMethodId}
                onChange={(e) =>
                  mergeState({
                    targetPriceSetting: {
                      ...$.targetPriceSetting,
                      conditionList: $.targetPriceSetting.conditionList.map((info, idx) => {
                        if (i !== idx) return info;
                        return {
                          ...info,
                          comparisonMethodId: Number(e.target.value),
                        };
                      }),
                    },
                  })
                }
              >
                {comparisonMethodList.map(({ code }) => {
                  return (
                    <option value={code} key={code}>
                      {code === 0 ? '対象金額' : '対象金額範囲'}
                    </option>
                  );
                })}
              </Form.Select>
              {when(
                priceInfo.comparisonMethodId === 0,
                <InputNumber
                  value={priceInfo.targetPrice ?? undefined}
                  onChange={(e) =>
                    mergeState({
                      targetPriceSetting: {
                        ...$.targetPriceSetting,
                        conditionList: $.targetPriceSetting.conditionList.map((info, idx) => {
                          if (i !== idx) return info;
                          return {
                            ...info,
                            targetPrice: e.target.value === '' ? undefined : Number(e.target.value),
                          };
                        }),
                      },
                    })
                  }
                />
              )}
              {when(
                priceInfo.comparisonMethodId === 1,
                <Row>
                  <Col>
                    <InputNumber
                      value={priceInfo.targetPriceMin ?? undefined}
                      onChange={(e) =>
                        mergeState({
                          targetPriceSetting: {
                            ...$.targetPriceSetting,
                            conditionList: $.targetPriceSetting.conditionList.map((info, idx) => {
                              if (i !== idx) return info;
                              return {
                                ...info,
                                targetPriceMin: e.target.value === '' ? undefined : Number(e.target.value),
                              };
                            }),
                          },
                        })
                      }
                      min={0}
                      max={priceInfo.targetPriceMax}
                    />
                  </Col>
                  <Col className="text-center" xs={1}>
                    ~
                  </Col>
                  <Col>
                    <InputNumber
                      value={priceInfo.targetPriceMax ?? undefined}
                      onChange={(e) =>
                        mergeState({
                          targetPriceSetting: {
                            ...$.targetPriceSetting,
                            conditionList: $.targetPriceSetting.conditionList.map((info, idx) => {
                              if (i !== idx) return info;
                              return {
                                ...info,
                                targetPriceMax: e.target.value === '' ? undefined : Number(e.target.value),
                              };
                            }),
                          },
                        })
                      }
                      min={priceInfo.targetPriceMin}
                    />
                  </Col>
                </Row>
              )}
            </div>
            <Button
              variant="link"
              className="ms-2"
              style={{ maxWidth: '5%' }}
              onClick={() => {
                const newArray = $.targetPriceSetting.conditionList;
                newArray.splice(i, 1);
                mergeState({
                  targetPriceSetting: { ...$.targetPriceSetting, conditionList: newArray },
                });
              }}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </Button>
          </div>
        </Card>
      );
    };

    return (
      <>
        <Modal
          centered
          closeButton
          isModal={$set.isStoreModal}
          onHide={() => mergeSet({ isStoreModal: false })}
          body={
            <>
              {$set.exclusionFlg ? (
                <>
                  <h6>
                    {$.ngTargetChainStoreSetting.conditionList[$set.modalIndex]
                      ? $.ngTargetChainStoreSetting.conditionList[$set.modalIndex].chainName
                      : ''}
                  </h6>
                  <TagsInputForm
                    type="text"
                    tags={
                      $.ngTargetChainStoreSetting.conditionList[$set.modalIndex]
                        ? $.ngTargetChainStoreSetting.conditionList[$set.modalIndex].storeList
                        : []
                    }
                    onChange={(tags: string[]) =>
                      mergeState({
                        ngTargetChainStoreSetting: {
                          ...$.ngTargetChainStoreSetting,
                          conditionList: $.ngTargetChainStoreSetting.conditionList.map((info, idx) => {
                            if ($set.modalIndex !== idx) return info;
                            return {
                              ...$.ngTargetChainStoreSetting.conditionList[$set.modalIndex],
                              storeList: tags,
                            };
                          }),
                        },
                      })
                    }
                  />
                </>
              ) : (
                <>
                  <h6>
                    {$.okTargetChainStoreSetting.conditionList[$set.modalIndex]
                      ? $.okTargetChainStoreSetting.conditionList[$set.modalIndex].chainName
                      : ''}
                  </h6>
                  <TagsInputForm
                    type="text"
                    tags={
                      $.okTargetChainStoreSetting.conditionList[$set.modalIndex]
                        ? $.okTargetChainStoreSetting.conditionList[$set.modalIndex].storeList
                        : []
                    }
                    onChange={(tags: string[]) =>
                      mergeState({
                        okTargetChainStoreSetting: {
                          ...$.okTargetChainStoreSetting,
                          conditionList: $.okTargetChainStoreSetting.conditionList.map((info, idx) => {
                            if ($set.modalIndex !== idx) return info;
                            return {
                              ...$.okTargetChainStoreSetting.conditionList[$set.modalIndex],
                              storeList: tags,
                            };
                          }),
                        },
                      })
                    }
                  />
                </>
              )}
            </>
          }
        />
        <Modal
          centered
          closeButton
          isModal={$set.isPrefectureModal}
          onHide={() => mergeSet({ isPrefectureModal: false })}
          title="対象都道府県の設定"
          body={
            <>
              <h6>都道府県</h6>
              <Select
                isClearable
                isSearchable
                isMulti
                placeholder=""
                value={$.targetPrefecturesSetting.prefectureList.map(({ id, name }) => {
                  return { label: name, value: id };
                })}
                options={prefectureList.map(({ id, name }) => {
                  return { label: name, value: id };
                })}
                onChange={(e) =>
                  mergeState({
                    targetPrefecturesSetting: {
                      ...$.targetPrefecturesSetting,
                      prefectureList: e.map(({ label, value }) => {
                        return { id: value, name: label };
                      }),
                    },
                  })
                }
              />
            </>
          }
        />
        <Form.Label className="mt-4">参考にするモニターID</Form.Label>
        <Form.Group controlId="referencedMonitorId" key="referencedMonitorId" className="d-flex">
          <InputNumber
            className="w-75"
            value={$set.referencedMonitorId || undefined}
            onChange={(e) => {
              mergeSet({ referencedMonitorId: Number(e.target.value) });
            }}
          />
          <Button
            className="mx-2"
            onClick={() => {
              onSetting($set.referencedMonitorId);
            }}
          >
            設定
          </Button>
        </Form.Group>
        {$set.errorMessage.length ? <Alert variant="danger">{$set.errorMessage}</Alert> : undefined}
        <div className="border border-dark p-2 mt-2">
          <h6>1. 全案件共通チェック内容の確認</h6>
          <div className="mt-2">{children}</div>

          <h6 className="mt-2">2. 同一モニター内以外で重複チェックを行うモニターIDの設定</h6>
          {renderDuplicateCheckCard()}

          <h6 className="mt-2">3. 承認OK対象チェーン・ストア</h6>
          <div className="bg-light p-2 mt-2">
            <NgRejectContent
              ngMessage={$.okTargetChainStoreSetting.ngMessage}
              rejectAlertFlg={$.okTargetChainStoreSetting.rejectAlertFlg}
              onChangeNgMessage={(value: string) => {
                mergeState({
                  okTargetChainStoreSetting: {
                    ...$.okTargetChainStoreSetting,
                    ngMessage: value,
                  },
                });
              }}
              onChangeRejectAlertFlg={(value: boolean) => {
                mergeState({
                  okTargetChainStoreSetting: {
                    ...$.okTargetChainStoreSetting,
                    rejectAlertFlg: !value,
                  },
                });
              }}
              type="card"
            />
            {$.okTargetChainStoreSetting.conditionList.map((condition, i) => {
              return renderChainStoreCard(condition, i, false);
            })}
            <div className="mw-100 d-flex justify-content-end">
              <Button
                variant="link"
                className="me-2"
                onClick={() => {
                  mergeState({
                    okTargetChainStoreSetting: {
                      ...$.okTargetChainStoreSetting,
                      conditionList: [
                        ...$.okTargetChainStoreSetting.conditionList,
                        {
                          chainName: '',
                          storeList: [],
                          deleteFlg: false,
                          newFlg: true,
                        },
                      ],
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                追加
              </Button>
            </div>
          </div>

          <h6 className="mt-2">4. 承認NG対象チェーン・ストア</h6>
          <div className="bg-light p-2 mt-2">
            <NgRejectContent
              ngMessage={$.ngTargetChainStoreSetting.ngMessage}
              rejectAlertFlg={$.ngTargetChainStoreSetting.rejectAlertFlg}
              onChangeNgMessage={(value: string) => {
                mergeState({
                  ngTargetChainStoreSetting: {
                    ...$.ngTargetChainStoreSetting,
                    ngMessage: value,
                  },
                });
              }}
              onChangeRejectAlertFlg={(value: boolean) => {
                mergeState({
                  ngTargetChainStoreSetting: {
                    ...$.ngTargetChainStoreSetting,
                    rejectAlertFlg: !value,
                  },
                });
              }}
              type="card"
            />
            {$.ngTargetChainStoreSetting.conditionList.map((condition, i) => {
              return renderChainStoreCard(condition, i, true);
            })}
            <div className="mw-100 d-flex justify-content-end">
              <Button
                variant="link"
                className="me-2"
                onClick={() => {
                  mergeState({
                    ngTargetChainStoreSetting: {
                      ...$.ngTargetChainStoreSetting,
                      conditionList: [
                        ...$.ngTargetChainStoreSetting.conditionList,
                        {
                          chainName: '',
                          storeList: [],
                          deleteFlg: false,
                          newFlg: true,
                        },
                      ],
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                追加
              </Button>
            </div>
          </div>

          <h6 className="mt-2">5. 対象都道府県の設定</h6>
          {renderPrefectureCard()}

          <h6 className="mt-2">6. 購入対象期間の設定</h6>
          <div className="bg-light p-2 mt-2">
            <NgRejectContent
              ngMessage={$.targetPeriodSetting.ngMessage}
              rejectAlertFlg={$.targetPeriodSetting.rejectAlertFlg}
              onChangeNgMessage={(value: string) => {
                mergeState({
                  targetPeriodSetting: {
                    ...$.targetPeriodSetting,
                    ngMessage: value,
                  },
                });
              }}
              onChangeRejectAlertFlg={(value: boolean) => {
                mergeState({
                  targetPeriodSetting: {
                    ...$.targetPeriodSetting,
                    rejectAlertFlg: !value,
                  },
                });
              }}
              type="card"
            />
            {$.targetPeriodSetting.conditionList.map((periodInfo, i) => {
              return renderTargetPeriodCard(periodInfo, i);
            })}
            <div className="mw-100 d-flex justify-content-end">
              <Button
                variant="link"
                className="me-2"
                onClick={() => {
                  mergeState({
                    targetPeriodSetting: {
                      ...$.targetPeriodSetting,
                      conditionList: [
                        ...$.targetPeriodSetting.conditionList,
                        {
                          comparisonMethodId: 0,
                        },
                      ],
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                追加
              </Button>
            </div>
          </div>

          <h6 className="mt-2">7. 対象金額の設定</h6>
          <div className="mt-2">
            <NgRejectContent
              ngMessage={$.targetPriceSetting.ngMessage}
              rejectAlertFlg={$.targetPriceSetting.rejectAlertFlg}
              onChangeNgMessage={(value: string) => {
                mergeState({
                  targetPriceSetting: {
                    ...$.targetPriceSetting,
                    ngMessage: value,
                  },
                });
              }}
              onChangeRejectAlertFlg={(value: boolean) => {
                mergeState({
                  targetPriceSetting: {
                    ...$.targetPriceSetting,
                    rejectAlertFlg: !value,
                  },
                });
              }}
              type="card"
            />
            {$.targetPriceSetting.conditionList.map((priceInfo, i) => {
              return renderTargetPriceCard(priceInfo, i);
            })}
            <div className="mw-100 d-flex justify-content-end">
              <Button
                variant="link"
                className="me-2"
                onClick={() => {
                  mergeState({
                    targetPriceSetting: {
                      ...$.targetPriceSetting,
                      conditionList: [
                        ...$.targetPriceSetting.conditionList,
                        {
                          comparisonMethodId: 0,
                          targetPrice: undefined,
                          targetPriceMax: undefined,
                          targetPriceMin: undefined,
                        },
                      ],
                    },
                  });
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                追加
              </Button>
            </div>
          </div>
          <h6 className="mt-2">8. 自動添削の実施</h6>
          <ButtonGroup>
            <Button
              style={{ width: '100px' }}
              variant={$.autoCorrectStartFlg ? 'primary' : 'outline-primary'}
              onClick={() => mergeState({ autoCorrectStartFlg: true })}
            >
              する
            </Button>
            <Button
              style={{ width: '100px' }}
              variant={$.autoCorrectStartFlg ? 'outline-secondary' : 'secondary'}
              onClick={() => mergeState({ autoCorrectStartFlg: false })}
            >
              しない
            </Button>
          </ButtonGroup>
        </div>
        <p className="text-secondary mt-2 d-flex justify-content-end">
          最終更新日時{' '}
          {autoCorrectInfo.autoCorrectRule.updateAt
            ? formatISODate(autoCorrectInfo.autoCorrectRule.updateAt, DATETIME_DISPLAY_FORMAT)
            : ''}{' '}
          {autoCorrectInfo.autoCorrectRule.updateIncName}
        </p>
      </>
    );
  }
);
