/* eslint-disable jsx-a11y/control-has-associated-label */
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import type { ApplyHistoryListDisplayOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { createTestId, formatISODate, isOemUser, uuid } from '../../../utils/functions';
import { Badge } from '../../atoms/Badge';
import { Button } from '../../atoms/Button';
import { PaginationTable } from './PaginationTable';

export interface ListItemProps {
  applyHistory: ApplyHistoryListDisplayOutputResponse;
  customerId: number;
  sCode?: string;
}

export const ListItem: React.VFC<ListItemProps> = ({ applyHistory, customerId, sCode }) => {
  const history = useHistory();
  const styles = {
    borderBottomThick: { borderBottom: '2px solid #ced4da' },
    smallTextGray: { fontSize: '0.8rem', color: 'gray', display: 'inline' },
    smallTextBlack: { fontSize: '0.8rem', display: 'inline' },
  };

  const {
    applyId,
    applyStatusOutput,
    approveAmount,
    approveAt,
    deadlineAt,
    enqueteStatusOutput,
    enqueteSubmissionAt,
    monitorName,
    monitorRuleUrl,
    service,
    shopName,
    startAt,
    surveyProofStatusOutput,
    surveyProofSubmissionAt,
    winningAt,
    monitorId,
  } = applyHistory;

  return (
    <>
      <tr key={`${applyId}-1`}>
        <td>
          <a
            className="p-0"
            style={{ display: 'inline-block' }}
            href={`${Url.TENSAKU.APPLY_INFO}/${applyId}/${customerId}`}
          >
            <p style={{ textDecoration: 'underline' }}>{applyId}</p>
          </a>
        </td>
        <td>
          <Badge pill style={{ fontSize: '0.875rem', width: '100%' }} bg="secondary">
            {applyStatusOutput.name}
          </Badge>
        </td>
        <td colSpan={2}>
          {shopName} <br />
          <p style={{ display: 'inline', color: 'gray' }}>{monitorName}</p>
        </td>
        <td>
          <Button
            variant="link"
            className="p-0"
            onClick={() => {
              history.push(`${Url.TENSAKU.CORRECT_RECEIPT}/${applyId}`);
            }}
          >
            <p style={{ textDecoration: 'underline' }}>{surveyProofStatusOutput.name}</p>
          </Button>
        </td>
        <td>
          <Button
            variant="link"
            className="p-0"
            onClick={() => {
              history.push(`${Url.TENSAKU.CORRECT_ENQUETE}/${applyId}`);
            }}
          >
            <p style={{ textDecoration: 'underline' }}>{enqueteStatusOutput.name}</p>
          </Button>
        </td>
        <td>{service}</td>
        {/* モニタールール表示用の画面を作ったらリンクを活性化する */}
        {/* <td>
          <OverlayTrigger
            placement="top-start"
            overlay={
              <Popover id="popover-basic">
                <Popover.Body className="p-1">モニター条件を表示する</Popover.Body>
              </Popover>
            }
          >
            <button
              type="button"
              onClick={() => {
                history.push({
                  pathname: monitorRuleUrl,
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowAltCircleRight} className="text-secondary flex-shrink-0 me-1" fixedWidth />
            </button>
          </OverlayTrigger>
        </td> */}
        {sCode && (
          <>
            <td>
              <a
                href={`${Url.TOC_OEM.PRECHECK}/${applyId}?sCode=${sCode}`}
                style={styles.smallTextBlack}
                target="_blank"
                rel="noreferrer"
              >
                事前確認
              </a>
              <br />
              <a
                href={`${Url.TOC_OEM.STORE_GUIDE}/${applyId}?sCode=${sCode}`}
                style={styles.smallTextBlack}
                target="_blank"
                rel="noreferrer"
              >
                店内ガイド
              </a>
            </td>
            <td>
              <a
                href={`${Url.TOC_OEM.DETAIL}/${monitorId}?sCode=${sCode}`}
                style={styles.smallTextBlack}
                target="_blank"
                rel="noreferrer"
              >
                応募
              </a>
              <br />
              <a
                href={`${Url.TOC_OEM.BRAND}/${applyId}?sCode=${sCode}`}
                style={styles.smallTextBlack}
                target="_blank"
                rel="noreferrer"
              >
                ブランド
              </a>
            </td>
          </>
        )}
      </tr>
      <tr key={`${applyId}-2`}>
        <td style={styles.borderBottomThick}>
          <p style={styles.smallTextGray}>開始月</p>
          <br />
          <p style={styles.smallTextBlack}> {startAt}</p>
        </td>
        <td style={styles.borderBottomThick}>
          <p style={styles.smallTextGray}>当選日</p>
          <br />
          <p style={styles.smallTextBlack}>{winningAt && formatISODate(winningAt, 'yyyy/MM/dd ')}</p>
        </td>
        <td style={styles.borderBottomThick}>
          <p style={styles.smallTextGray}>提出期限</p>
          <br />
          <p style={styles.smallTextBlack}>{deadlineAt && formatISODate(deadlineAt, 'yyyy/MM/dd ')}</p>
        </td>
        <td style={styles.borderBottomThick}>
          <p style={styles.smallTextGray}>調査証明提出日</p>
          <br />
          <p style={styles.smallTextBlack}>
            {surveyProofSubmissionAt && formatISODate(surveyProofSubmissionAt, 'yyyy/MM/dd ')}
          </p>
        </td>
        <td style={styles.borderBottomThick}>
          <p style={styles.smallTextGray}>アンケート提出日</p>
          <br />
          <p style={styles.smallTextBlack}>
            {enqueteSubmissionAt && formatISODate(enqueteSubmissionAt, 'yyyy/MM/dd ')}
          </p>
        </td>
        <td style={styles.borderBottomThick}>
          <p style={styles.smallTextGray}>承認日</p>
          <br />
          <p style={styles.smallTextBlack}>{approveAt && formatISODate(approveAt, 'yyyy/MM/dd ')}</p>
        </td>
        <td style={styles.borderBottomThick}>
          <p style={styles.smallTextGray}>承認金額</p>
          <br />
          <p style={styles.smallTextBlack}>{`${approveAmount}円`}</p>
        </td>
        {sCode && (
          <>
            <td style={styles.borderBottomThick}>
              <a
                href={`${Url.TOC_OEM.ENQ}/${applyId}?sCode=${sCode}`}
                style={styles.smallTextBlack}
                target="_blank"
                rel="noreferrer"
              >
                アンケート提出
              </a>
              <br />
              <a
                href={`${Url.TOC_OEM.RECEIPT}/${applyId}?sCode=${sCode}`}
                style={styles.smallTextBlack}
                target="_blank"
                rel="noreferrer"
              >
                レシート提出
              </a>
            </td>
            <td style={styles.borderBottomThick}>
              <a
                href={`${Url.TOC_OEM.CAMPAIGN}/${applyId}?sCode=${sCode}`}
                style={styles.smallTextBlack}
                target="_blank"
                rel="noreferrer"
              >
                キャンペーン
              </a>
              <br />
              {}
            </td>
          </>
        )}
      </tr>
    </>
  );
};

export interface Props {
  listPerPage: ApplyHistoryListDisplayOutputResponse[];
  resultNum: number;
  customerId: number;
  sCode?: string;
}

export type TestIds = 'table';

export const ApplyHistoryListTable: React.VFC<Props> = ({ listPerPage, resultNum, customerId, sCode }) => {
  const testid = createTestId<TestIds>(ApplyHistoryListTable);
  const headerNames = ['応募ID', '応募ステータス', '店舗/モニター', '', '調査証明', 'アンケート', 'サービス'];
  if (sCode) {
    headerNames.push('OEMリンク1', 'OEMリンク2');
  }
  return (
    <PaginationTable data-testid={testid('table')} resultNum={resultNum} list={listPerPage} headerNames={headerNames}>
      {(list) => {
        return (list as ApplyHistoryListDisplayOutputResponse[]).map((v) => {
          return <ListItem applyHistory={v} key={uuid()} customerId={customerId} sCode={sCode} />;
        });
      }}
    </PaginationTable>
  );
};
