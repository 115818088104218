/* eslint-disable import/no-cycle */
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ApplyInfoOutputResponse } from '../../../../api-client';
import { Url } from '../../../../constants/Url';
import { createTestId, formatISODate } from '../../../../utils/functions';
import { Button } from '../../../atoms/Button';

export interface Props {
  applyId: string;
  applyInfo: ApplyInfoOutputResponse;
}

const SurveyProofCard: React.FC<Props> = ({ applyId, applyInfo }) => {
  const testid = createTestId(SurveyProofCard);
  const history = useHistory();

  return (
    <Card className="mb-4" data-testid={testid('surveyProofCard')}>
      <Card.Body>
        <Form.Group className="mb-4">
          <>
            <div className="fs-5 p-1 fw-bold">調査証明</div>
            <div>提出ステータス</div>
            <Button
              variant="link"
              onClick={() => {
                history.push(`${Url.TENSAKU.CORRECT_RECEIPT}/${applyId}`);
              }}
            >
              <div className="text-decoration-underline">{applyInfo?.surveyProof.submissionStatus}</div>
            </Button>
            <p className="text-secondary">{`最終提出日時 ${
              applyInfo?.surveyProof.submissionAt &&
              formatISODate(applyInfo?.surveyProof.submissionAt, 'yyyy/MM/dd HH:mm:ss')
            }`}</p>
            <div>再提出依頼（NG）回数</div>
            <div className="fs-5">{`${applyInfo?.surveyProof.resubmissionCount}回`}</div>
            <p className="text-secondary">{`最終再提出依頼（NG）日時 ${
              applyInfo?.surveyProof.ngRejectAt &&
              formatISODate(applyInfo?.surveyProof.ngRejectAt, 'yyyy/MM/dd HH:mm:ss')
            }`}</p>
          </>
          <hr />
          <>
            <div className="fs-5 p-1 fw-bold">アンケート</div>
            <div>提出ステータス</div>
            <Button
              variant="link"
              onClick={() => {
                history.push(`${Url.TENSAKU.CORRECT_ENQUETE}/${applyId}`);
              }}
            >
              <div className="text-decoration-underline">{applyInfo?.enquete.submissionStatus}</div>
            </Button>
            <p className="text-secondary">{`最終提出日時 ${
              applyInfo?.enquete.submissionAt && formatISODate(applyInfo?.enquete.submissionAt, 'yyyy/MM/dd HH:mm:ss')
            }`}</p>
            <div>再提出依頼（NG）回数</div>
            <div className="fs-5">{`${applyInfo?.enquete.resubmissionCount}回`}</div>
            <p className="text-secondary">{`最終再提出依頼（NG）日時 ${
              applyInfo?.enquete.ngRejectAt && formatISODate(applyInfo?.enquete.ngRejectAt, 'yyyy/MM/dd HH:mm:ss')
            }`}</p>
            <div className="bg-light p-2">
              <div>承認日時</div>
              <p className="fw-bold">
                {applyInfo?.approveAt && formatISODate(applyInfo?.approveAt, 'yyyy/MM/dd HH:mm:ss')}
              </p>
            </div>
          </>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default SurveyProofCard;
