import React from 'react';
import { when } from '../../../../../utils/functions';

export const Step: React.FC<{ name: string }> = ({ name, children }) => (
  <>
    <h3>{name}</h3>
    {children}
  </>
);

export const Section: React.FC<{ title: string; description?: string }> = ({ title, description, children }) => (
  <section id={title}>
    <h4>{title}</h4>
    {when(!!description, <p>{description}</p>)}
    {children}
    <hr />
  </section>
);
