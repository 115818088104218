/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContractShopAndMonitorListFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { MonitorApiClientsUpdateFormResponse } from '../domains';
// @ts-ignore
import { MonitorApplyHistoryListOutputResponse } from '../domains';
// @ts-ignore
import { MonitorBulkApplyFormResponse } from '../domains';
// @ts-ignore
import { MonitorListOutputResponse } from '../domains';
// @ts-ignore
import { MonitorRuleSetInfoOutputResponse } from '../domains';
// @ts-ignore
import { MonitorRuleSetListOutputResponse } from '../domains';
/**
 * MonitorApi - axios parameter creator
 * @export
 */
export const MonitorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 契約店舗とモニター一覧を取得する
         * @summary 契約店舗とモニター一覧取得API
         * @param {ContractShopAndMonitorListFormResponse} contractShopAndMonitorListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractShopAndMonitorList: async (contractShopAndMonitorListFormResponse: ContractShopAndMonitorListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractShopAndMonitorListFormResponse' is not null or undefined
            assertParamExists('contractShopAndMonitorList', 'contractShopAndMonitorListFormResponse', contractShopAndMonitorListFormResponse)
            const localVarPath = `/api/contract-shop-and-monitor-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contractShopAndMonitorListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケートダウンロードする
         * @summary アンケートDL API
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteFileDownloadList: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enqueteFileDownloadList', 'id', id)
            const localVarPath = `/api/enquete-file-download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニターAPIクライアントを登録・更新する。
         * @summary モニターAPIクライアント更新
         * @param {MonitorApiClientsUpdateFormResponse} monitorApiClientsUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorApiClientUpdate: async (monitorApiClientsUpdateFormResponse: MonitorApiClientsUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorApiClientsUpdateFormResponse' is not null or undefined
            assertParamExists('monitorApiClientUpdate', 'monitorApiClientsUpdateFormResponse', monitorApiClientsUpdateFormResponse)
            const localVarPath = `/api/monitor/apiclients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitorApiClientsUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニターAPIクライアント一覧をダウンロードする
         * @summary モニターAPIクライアント一覧ダウンロード
         * @param {string} monitorIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorApiClientsDownload: async (monitorIds: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorIds' is not null or undefined
            assertParamExists('monitorApiClientsDownload', 'monitorIds', monitorIds)
            const localVarPath = `/api/monitor/apiclients-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (monitorIds !== undefined) {
                localVarQueryParameter['monitorIds'] = monitorIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニター応募履歴一覧を取得する
         * @summary モニター応募履歴一覧取得
         * @param {string} [monitorIdName] 
         * @param {string} [shopIdName] 
         * @param {string} [clientIdName] 
         * @param {number} [monitorBaseId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorApplyHistoryList: async (monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/monitor-apply-history-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (monitorIdName !== undefined) {
                localVarQueryParameter['monitorIdName'] = monitorIdName;
            }

            if (shopIdName !== undefined) {
                localVarQueryParameter['shopIdName'] = shopIdName;
            }

            if (clientIdName !== undefined) {
                localVarQueryParameter['clientIdName'] = clientIdName;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニター一括適用API
         * @summary モニター一括適用API
         * @param {MonitorBulkApplyFormResponse} monitorBulkApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorBulkApply: async (monitorBulkApplyFormResponse: MonitorBulkApplyFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitorBulkApplyFormResponse' is not null or undefined
            assertParamExists('monitorBulkApply', 'monitorBulkApplyFormResponse', monitorBulkApplyFormResponse)
            const localVarPath = `/api/monitor-bulk-apply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitorBulkApplyFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニター詳細一覧をダウンロードする
         * @summary モニター詳細一覧ダウンロード
         * @param {string} [monitorIdName] 
         * @param {string} [shopIdName] 
         * @param {string} [clientIdName] 
         * @param {number} [monitorBaseId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorDetailListDownload: async (monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/monitor-detail-list-download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (monitorIdName !== undefined) {
                localVarQueryParameter['monitorIdName'] = monitorIdName;
            }

            if (shopIdName !== undefined) {
                localVarQueryParameter['shopIdName'] = shopIdName;
            }

            if (clientIdName !== undefined) {
                localVarQueryParameter['clientIdName'] = clientIdName;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニター履歴取得する
         * @summary モニター履歴取得API
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorHistoryList: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('monitorHistoryList', 'id', id)
            const localVarPath = `/api/monitor-history-list/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * モニター一覧を取得する
         * @summary モニター一覧取得
         * @param {string} [monitorIdName] 
         * @param {string} [shopIdName] 
         * @param {string} [clientIdName] 
         * @param {number} [monitorBaseId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorList: async (monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/monitor-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (monitorIdName !== undefined) {
                localVarQueryParameter['monitorIdName'] = monitorIdName;
            }

            if (shopIdName !== undefined) {
                localVarQueryParameter['shopIdName'] = shopIdName;
            }

            if (clientIdName !== undefined) {
                localVarQueryParameter['clientIdName'] = clientIdName;
            }

            if (monitorBaseId !== undefined) {
                localVarQueryParameter['monitorBaseId'] = monitorBaseId;
            }

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString().substr(0,10) :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString().substr(0,10) :
                    toDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MonitorApi - functional programming interface
 * @export
 */
export const MonitorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MonitorApiAxiosParamCreator(configuration)
    return {
        /**
         * 契約店舗とモニター一覧を取得する
         * @summary 契約店舗とモニター一覧取得API
         * @param {ContractShopAndMonitorListFormResponse} contractShopAndMonitorListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractShopAndMonitorList(contractShopAndMonitorListFormResponse: ContractShopAndMonitorListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitorRuleSetListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractShopAndMonitorList(contractShopAndMonitorListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケートダウンロードする
         * @summary アンケートDL API
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteFileDownloadList(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteFileDownloadList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニターAPIクライアントを登録・更新する。
         * @summary モニターAPIクライアント更新
         * @param {MonitorApiClientsUpdateFormResponse} monitorApiClientsUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorApiClientUpdate(monitorApiClientsUpdateFormResponse: MonitorApiClientsUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorApiClientUpdate(monitorApiClientsUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニターAPIクライアント一覧をダウンロードする
         * @summary モニターAPIクライアント一覧ダウンロード
         * @param {string} monitorIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorApiClientsDownload(monitorIds: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorApiClientsDownload(monitorIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニター応募履歴一覧を取得する
         * @summary モニター応募履歴一覧取得
         * @param {string} [monitorIdName] 
         * @param {string} [shopIdName] 
         * @param {string} [clientIdName] 
         * @param {number} [monitorBaseId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorApplyHistoryList(monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonitorApplyHistoryListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorApplyHistoryList(monitorIdName, shopIdName, clientIdName, monitorBaseId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニター一括適用API
         * @summary モニター一括適用API
         * @param {MonitorBulkApplyFormResponse} monitorBulkApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorBulkApply(monitorBulkApplyFormResponse: MonitorBulkApplyFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorBulkApply(monitorBulkApplyFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニター詳細一覧をダウンロードする
         * @summary モニター詳細一覧ダウンロード
         * @param {string} [monitorIdName] 
         * @param {string} [shopIdName] 
         * @param {string} [clientIdName] 
         * @param {number} [monitorBaseId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorDetailListDownload(monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorDetailListDownload(monitorIdName, shopIdName, clientIdName, monitorBaseId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニター履歴取得する
         * @summary モニター履歴取得API
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorHistoryList(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonitorRuleSetInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorHistoryList(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * モニター一覧を取得する
         * @summary モニター一覧取得
         * @param {string} [monitorIdName] 
         * @param {string} [shopIdName] 
         * @param {string} [clientIdName] 
         * @param {number} [monitorBaseId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorList(monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonitorListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorList(monitorIdName, shopIdName, clientIdName, monitorBaseId, fromDate, toDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MonitorApi - factory interface
 * @export
 */
export const MonitorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MonitorApiFp(configuration)
    return {
        /**
         * 契約店舗とモニター一覧を取得する
         * @summary 契約店舗とモニター一覧取得API
         * @param {ContractShopAndMonitorListFormResponse} contractShopAndMonitorListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractShopAndMonitorList(contractShopAndMonitorListFormResponse: ContractShopAndMonitorListFormResponse, options?: any): AxiosPromise<MonitorRuleSetListOutputResponse> {
            return localVarFp.contractShopAndMonitorList(contractShopAndMonitorListFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケートダウンロードする
         * @summary アンケートDL API
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteFileDownloadList(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.enqueteFileDownloadList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * モニターAPIクライアントを登録・更新する。
         * @summary モニターAPIクライアント更新
         * @param {MonitorApiClientsUpdateFormResponse} monitorApiClientsUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorApiClientUpdate(monitorApiClientsUpdateFormResponse: MonitorApiClientsUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.monitorApiClientUpdate(monitorApiClientsUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * モニターAPIクライアント一覧をダウンロードする
         * @summary モニターAPIクライアント一覧ダウンロード
         * @param {string} monitorIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorApiClientsDownload(monitorIds: string, options?: any): AxiosPromise<string> {
            return localVarFp.monitorApiClientsDownload(monitorIds, options).then((request) => request(axios, basePath));
        },
        /**
         * モニター応募履歴一覧を取得する
         * @summary モニター応募履歴一覧取得
         * @param {string} [monitorIdName] 
         * @param {string} [shopIdName] 
         * @param {string} [clientIdName] 
         * @param {number} [monitorBaseId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorApplyHistoryList(monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<MonitorApplyHistoryListOutputResponse>> {
            return localVarFp.monitorApplyHistoryList(monitorIdName, shopIdName, clientIdName, monitorBaseId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * モニター一括適用API
         * @summary モニター一括適用API
         * @param {MonitorBulkApplyFormResponse} monitorBulkApplyFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorBulkApply(monitorBulkApplyFormResponse: MonitorBulkApplyFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.monitorBulkApply(monitorBulkApplyFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * モニター詳細一覧をダウンロードする
         * @summary モニター詳細一覧ダウンロード
         * @param {string} [monitorIdName] 
         * @param {string} [shopIdName] 
         * @param {string} [clientIdName] 
         * @param {number} [monitorBaseId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorDetailListDownload(monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<string> {
            return localVarFp.monitorDetailListDownload(monitorIdName, shopIdName, clientIdName, monitorBaseId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
        /**
         * モニター履歴取得する
         * @summary モニター履歴取得API
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorHistoryList(id: number, options?: any): AxiosPromise<MonitorRuleSetInfoOutputResponse> {
            return localVarFp.monitorHistoryList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * モニター一覧を取得する
         * @summary モニター一覧取得
         * @param {string} [monitorIdName] 
         * @param {string} [shopIdName] 
         * @param {string} [clientIdName] 
         * @param {number} [monitorBaseId] 
         * @param {string} [fromDate] 
         * @param {string} [toDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorList(monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options?: any): AxiosPromise<Array<MonitorListOutputResponse>> {
            return localVarFp.monitorList(monitorIdName, shopIdName, clientIdName, monitorBaseId, fromDate, toDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MonitorApi - object-oriented interface
 * @export
 * @class MonitorApi
 * @extends {BaseAPI}
 */
export class MonitorApi extends BaseAPI {
    /**
     * 契約店舗とモニター一覧を取得する
     * @summary 契約店舗とモニター一覧取得API
     * @param {ContractShopAndMonitorListFormResponse} contractShopAndMonitorListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorApi
     */
    public contractShopAndMonitorList(contractShopAndMonitorListFormResponse: ContractShopAndMonitorListFormResponse, options?: AxiosRequestConfig) {
        return MonitorApiFp(this.configuration).contractShopAndMonitorList(contractShopAndMonitorListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケートダウンロードする
     * @summary アンケートDL API
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorApi
     */
    public enqueteFileDownloadList(id: number, options?: AxiosRequestConfig) {
        return MonitorApiFp(this.configuration).enqueteFileDownloadList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニターAPIクライアントを登録・更新する。
     * @summary モニターAPIクライアント更新
     * @param {MonitorApiClientsUpdateFormResponse} monitorApiClientsUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorApi
     */
    public monitorApiClientUpdate(monitorApiClientsUpdateFormResponse: MonitorApiClientsUpdateFormResponse, options?: AxiosRequestConfig) {
        return MonitorApiFp(this.configuration).monitorApiClientUpdate(monitorApiClientsUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニターAPIクライアント一覧をダウンロードする
     * @summary モニターAPIクライアント一覧ダウンロード
     * @param {string} monitorIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorApi
     */
    public monitorApiClientsDownload(monitorIds: string, options?: AxiosRequestConfig) {
        return MonitorApiFp(this.configuration).monitorApiClientsDownload(monitorIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニター応募履歴一覧を取得する
     * @summary モニター応募履歴一覧取得
     * @param {string} [monitorIdName] 
     * @param {string} [shopIdName] 
     * @param {string} [clientIdName] 
     * @param {number} [monitorBaseId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorApi
     */
    public monitorApplyHistoryList(monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return MonitorApiFp(this.configuration).monitorApplyHistoryList(monitorIdName, shopIdName, clientIdName, monitorBaseId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニター一括適用API
     * @summary モニター一括適用API
     * @param {MonitorBulkApplyFormResponse} monitorBulkApplyFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorApi
     */
    public monitorBulkApply(monitorBulkApplyFormResponse: MonitorBulkApplyFormResponse, options?: AxiosRequestConfig) {
        return MonitorApiFp(this.configuration).monitorBulkApply(monitorBulkApplyFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニター詳細一覧をダウンロードする
     * @summary モニター詳細一覧ダウンロード
     * @param {string} [monitorIdName] 
     * @param {string} [shopIdName] 
     * @param {string} [clientIdName] 
     * @param {number} [monitorBaseId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorApi
     */
    public monitorDetailListDownload(monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return MonitorApiFp(this.configuration).monitorDetailListDownload(monitorIdName, shopIdName, clientIdName, monitorBaseId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニター履歴取得する
     * @summary モニター履歴取得API
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorApi
     */
    public monitorHistoryList(id: number, options?: AxiosRequestConfig) {
        return MonitorApiFp(this.configuration).monitorHistoryList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * モニター一覧を取得する
     * @summary モニター一覧取得
     * @param {string} [monitorIdName] 
     * @param {string} [shopIdName] 
     * @param {string} [clientIdName] 
     * @param {number} [monitorBaseId] 
     * @param {string} [fromDate] 
     * @param {string} [toDate] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MonitorApi
     */
    public monitorList(monitorIdName?: string, shopIdName?: string, clientIdName?: string, monitorBaseId?: number, fromDate?: string, toDate?: string, options?: AxiosRequestConfig) {
        return MonitorApiFp(this.configuration).monitorList(monitorIdName, shopIdName, clientIdName, monitorBaseId, fromDate, toDate, options).then((request) => request(this.axios, this.basePath));
    }
}
