/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CorrectionNgExecuteFormResponse } from '../domains';
// @ts-ignore
import { EnqueteCorrectDisplayControlOutputResponse } from '../domains';
// @ts-ignore
import { EnqueteCorrectRejectApprovalExecuteFormResponse } from '../domains';
// @ts-ignore
import { EnqueteCorrectionEscalationAnswerExecuteFormResponse } from '../domains';
// @ts-ignore
import { EnqueteCorrectionEscalationExecuteFormResponse } from '../domains';
// @ts-ignore
import { EnqueteCorrectionOkExecuteFormResponse } from '../domains';
// @ts-ignore
import { EnqueteCorrectionRejectApprovalRequestFormResponse } from '../domains';
// @ts-ignore
import { EnqueteCorrectionSaveAnswerFormResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * CorrectEnqueteApi - axios parameter creator
 * @export
 */
export const CorrectEnqueteApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary アンケート添削画面制御
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctEnqueteDisplayControl: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('correctEnqueteDisplayControl', 'applyId', applyId)
            const localVarPath = `/api/correct/enquete/display-control/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート添削の却下承認依頼回答を実行する
         * @summary アンケート添削却下承認依頼回答実行
         * @param {EnqueteCorrectRejectApprovalExecuteFormResponse} enqueteCorrectRejectApprovalExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectRejectApprovalExecute: async (enqueteCorrectRejectApprovalExecuteFormResponse: EnqueteCorrectRejectApprovalExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enqueteCorrectRejectApprovalExecuteFormResponse' is not null or undefined
            assertParamExists('enqueteCorrectRejectApprovalExecute', 'enqueteCorrectRejectApprovalExecuteFormResponse', enqueteCorrectRejectApprovalExecuteFormResponse)
            const localVarPath = `/api/correct/enquete/rejection-approval-request-answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enqueteCorrectRejectApprovalExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート添削エスカレ回答を実行する
         * @summary アンケート添削エスカレ回答実行
         * @param {EnqueteCorrectionEscalationAnswerExecuteFormResponse} enqueteCorrectionEscalationAnswerExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionEscalationAnswerExecute: async (enqueteCorrectionEscalationAnswerExecuteFormResponse: EnqueteCorrectionEscalationAnswerExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enqueteCorrectionEscalationAnswerExecuteFormResponse' is not null or undefined
            assertParamExists('enqueteCorrectionEscalationAnswerExecute', 'enqueteCorrectionEscalationAnswerExecuteFormResponse', enqueteCorrectionEscalationAnswerExecuteFormResponse)
            const localVarPath = `/api/correct/enquete/escalation-answer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enqueteCorrectionEscalationAnswerExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート添削のエスカレを実行する
         * @summary アンケート添削エスカレ実行
         * @param {EnqueteCorrectionEscalationExecuteFormResponse} enqueteCorrectionEscalationExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionEscalationExecute: async (enqueteCorrectionEscalationExecuteFormResponse: EnqueteCorrectionEscalationExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enqueteCorrectionEscalationExecuteFormResponse' is not null or undefined
            assertParamExists('enqueteCorrectionEscalationExecute', 'enqueteCorrectionEscalationExecuteFormResponse', enqueteCorrectionEscalationExecuteFormResponse)
            const localVarPath = `/api/correct/enquete/escalation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enqueteCorrectionEscalationExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート添削NGを実行する
         * @summary アンケート添削NG実行
         * @param {CorrectionNgExecuteFormResponse} correctionNgExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionNgExecute: async (correctionNgExecuteFormResponse: CorrectionNgExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'correctionNgExecuteFormResponse' is not null or undefined
            assertParamExists('enqueteCorrectionNgExecute', 'correctionNgExecuteFormResponse', correctionNgExecuteFormResponse)
            const localVarPath = `/api/correct/enquete/correct-ng`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(correctionNgExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート添削OKを実行する
         * @summary アンケート添削OK実行
         * @param {EnqueteCorrectionOkExecuteFormResponse} enqueteCorrectionOkExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionOkExecute: async (enqueteCorrectionOkExecuteFormResponse: EnqueteCorrectionOkExecuteFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enqueteCorrectionOkExecuteFormResponse' is not null or undefined
            assertParamExists('enqueteCorrectionOkExecute', 'enqueteCorrectionOkExecuteFormResponse', enqueteCorrectionOkExecuteFormResponse)
            const localVarPath = `/api/correct/enquete/correct-ok`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enqueteCorrectionOkExecuteFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート却下を実施する
         * @summary アンケート　却下実施
         * @param {EnqueteCorrectionRejectApprovalRequestFormResponse} enqueteCorrectionRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionRejectExec: async (enqueteCorrectionRejectApprovalRequestFormResponse: EnqueteCorrectionRejectApprovalRequestFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enqueteCorrectionRejectApprovalRequestFormResponse' is not null or undefined
            assertParamExists('enqueteCorrectionRejectExec', 'enqueteCorrectionRejectApprovalRequestFormResponse', enqueteCorrectionRejectApprovalRequestFormResponse)
            const localVarPath = `/api/correct/enquete/rejection-exec`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enqueteCorrectionRejectApprovalRequestFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート添削回答編集を保存する
         * @summary アンケート添削回答編集保存
         * @param {EnqueteCorrectionSaveAnswerFormResponse} enqueteCorrectionSaveAnswerFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionSaveAnswer: async (enqueteCorrectionSaveAnswerFormResponse: EnqueteCorrectionSaveAnswerFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enqueteCorrectionSaveAnswerFormResponse' is not null or undefined
            assertParamExists('enqueteCorrectionSaveAnswer', 'enqueteCorrectionSaveAnswerFormResponse', enqueteCorrectionSaveAnswerFormResponse)
            const localVarPath = `/api/correct/enquete/answer-save`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enqueteCorrectionSaveAnswerFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アンケート添削却下承認依頼を送信する
         * @summary アンケート添削却下承認依頼送信
         * @param {EnqueteCorrectionRejectApprovalRequestFormResponse} enqueteCorrectionRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionSendRejectionApprovalRequest: async (enqueteCorrectionRejectApprovalRequestFormResponse: EnqueteCorrectionRejectApprovalRequestFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enqueteCorrectionRejectApprovalRequestFormResponse' is not null or undefined
            assertParamExists('enqueteCorrectionSendRejectionApprovalRequest', 'enqueteCorrectionRejectApprovalRequestFormResponse', enqueteCorrectionRejectApprovalRequestFormResponse)
            const localVarPath = `/api/correct/enquete/rejection-approval-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enqueteCorrectionRejectApprovalRequestFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorrectEnqueteApi - functional programming interface
 * @export
 */
export const CorrectEnqueteApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorrectEnqueteApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary アンケート添削画面制御
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async correctEnqueteDisplayControl(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnqueteCorrectDisplayControlOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.correctEnqueteDisplayControl(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート添削の却下承認依頼回答を実行する
         * @summary アンケート添削却下承認依頼回答実行
         * @param {EnqueteCorrectRejectApprovalExecuteFormResponse} enqueteCorrectRejectApprovalExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteCorrectRejectApprovalExecute(enqueteCorrectRejectApprovalExecuteFormResponse: EnqueteCorrectRejectApprovalExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteCorrectRejectApprovalExecute(enqueteCorrectRejectApprovalExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート添削エスカレ回答を実行する
         * @summary アンケート添削エスカレ回答実行
         * @param {EnqueteCorrectionEscalationAnswerExecuteFormResponse} enqueteCorrectionEscalationAnswerExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteCorrectionEscalationAnswerExecute(enqueteCorrectionEscalationAnswerExecuteFormResponse: EnqueteCorrectionEscalationAnswerExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteCorrectionEscalationAnswerExecute(enqueteCorrectionEscalationAnswerExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート添削のエスカレを実行する
         * @summary アンケート添削エスカレ実行
         * @param {EnqueteCorrectionEscalationExecuteFormResponse} enqueteCorrectionEscalationExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteCorrectionEscalationExecute(enqueteCorrectionEscalationExecuteFormResponse: EnqueteCorrectionEscalationExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteCorrectionEscalationExecute(enqueteCorrectionEscalationExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート添削NGを実行する
         * @summary アンケート添削NG実行
         * @param {CorrectionNgExecuteFormResponse} correctionNgExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteCorrectionNgExecute(correctionNgExecuteFormResponse: CorrectionNgExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteCorrectionNgExecute(correctionNgExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート添削OKを実行する
         * @summary アンケート添削OK実行
         * @param {EnqueteCorrectionOkExecuteFormResponse} enqueteCorrectionOkExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteCorrectionOkExecute(enqueteCorrectionOkExecuteFormResponse: EnqueteCorrectionOkExecuteFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteCorrectionOkExecute(enqueteCorrectionOkExecuteFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート却下を実施する
         * @summary アンケート　却下実施
         * @param {EnqueteCorrectionRejectApprovalRequestFormResponse} enqueteCorrectionRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteCorrectionRejectExec(enqueteCorrectionRejectApprovalRequestFormResponse: EnqueteCorrectionRejectApprovalRequestFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteCorrectionRejectExec(enqueteCorrectionRejectApprovalRequestFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート添削回答編集を保存する
         * @summary アンケート添削回答編集保存
         * @param {EnqueteCorrectionSaveAnswerFormResponse} enqueteCorrectionSaveAnswerFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteCorrectionSaveAnswer(enqueteCorrectionSaveAnswerFormResponse: EnqueteCorrectionSaveAnswerFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteCorrectionSaveAnswer(enqueteCorrectionSaveAnswerFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アンケート添削却下承認依頼を送信する
         * @summary アンケート添削却下承認依頼送信
         * @param {EnqueteCorrectionRejectApprovalRequestFormResponse} enqueteCorrectionRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enqueteCorrectionSendRejectionApprovalRequest(enqueteCorrectionRejectApprovalRequestFormResponse: EnqueteCorrectionRejectApprovalRequestFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enqueteCorrectionSendRejectionApprovalRequest(enqueteCorrectionRejectApprovalRequestFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CorrectEnqueteApi - factory interface
 * @export
 */
export const CorrectEnqueteApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorrectEnqueteApiFp(configuration)
    return {
        /**
         * 
         * @summary アンケート添削画面制御
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        correctEnqueteDisplayControl(applyId: number, options?: any): AxiosPromise<EnqueteCorrectDisplayControlOutputResponse> {
            return localVarFp.correctEnqueteDisplayControl(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート添削の却下承認依頼回答を実行する
         * @summary アンケート添削却下承認依頼回答実行
         * @param {EnqueteCorrectRejectApprovalExecuteFormResponse} enqueteCorrectRejectApprovalExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectRejectApprovalExecute(enqueteCorrectRejectApprovalExecuteFormResponse: EnqueteCorrectRejectApprovalExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.enqueteCorrectRejectApprovalExecute(enqueteCorrectRejectApprovalExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート添削エスカレ回答を実行する
         * @summary アンケート添削エスカレ回答実行
         * @param {EnqueteCorrectionEscalationAnswerExecuteFormResponse} enqueteCorrectionEscalationAnswerExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionEscalationAnswerExecute(enqueteCorrectionEscalationAnswerExecuteFormResponse: EnqueteCorrectionEscalationAnswerExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.enqueteCorrectionEscalationAnswerExecute(enqueteCorrectionEscalationAnswerExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート添削のエスカレを実行する
         * @summary アンケート添削エスカレ実行
         * @param {EnqueteCorrectionEscalationExecuteFormResponse} enqueteCorrectionEscalationExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionEscalationExecute(enqueteCorrectionEscalationExecuteFormResponse: EnqueteCorrectionEscalationExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.enqueteCorrectionEscalationExecute(enqueteCorrectionEscalationExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート添削NGを実行する
         * @summary アンケート添削NG実行
         * @param {CorrectionNgExecuteFormResponse} correctionNgExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionNgExecute(correctionNgExecuteFormResponse: CorrectionNgExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.enqueteCorrectionNgExecute(correctionNgExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート添削OKを実行する
         * @summary アンケート添削OK実行
         * @param {EnqueteCorrectionOkExecuteFormResponse} enqueteCorrectionOkExecuteFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionOkExecute(enqueteCorrectionOkExecuteFormResponse: EnqueteCorrectionOkExecuteFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.enqueteCorrectionOkExecute(enqueteCorrectionOkExecuteFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート却下を実施する
         * @summary アンケート　却下実施
         * @param {EnqueteCorrectionRejectApprovalRequestFormResponse} enqueteCorrectionRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionRejectExec(enqueteCorrectionRejectApprovalRequestFormResponse: EnqueteCorrectionRejectApprovalRequestFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.enqueteCorrectionRejectExec(enqueteCorrectionRejectApprovalRequestFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート添削回答編集を保存する
         * @summary アンケート添削回答編集保存
         * @param {EnqueteCorrectionSaveAnswerFormResponse} enqueteCorrectionSaveAnswerFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionSaveAnswer(enqueteCorrectionSaveAnswerFormResponse: EnqueteCorrectionSaveAnswerFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.enqueteCorrectionSaveAnswer(enqueteCorrectionSaveAnswerFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * アンケート添削却下承認依頼を送信する
         * @summary アンケート添削却下承認依頼送信
         * @param {EnqueteCorrectionRejectApprovalRequestFormResponse} enqueteCorrectionRejectApprovalRequestFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enqueteCorrectionSendRejectionApprovalRequest(enqueteCorrectionRejectApprovalRequestFormResponse: EnqueteCorrectionRejectApprovalRequestFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.enqueteCorrectionSendRejectionApprovalRequest(enqueteCorrectionRejectApprovalRequestFormResponse, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CorrectEnqueteApi - object-oriented interface
 * @export
 * @class CorrectEnqueteApi
 * @extends {BaseAPI}
 */
export class CorrectEnqueteApi extends BaseAPI {
    /**
     * 
     * @summary アンケート添削画面制御
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectEnqueteApi
     */
    public correctEnqueteDisplayControl(applyId: number, options?: AxiosRequestConfig) {
        return CorrectEnqueteApiFp(this.configuration).correctEnqueteDisplayControl(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート添削の却下承認依頼回答を実行する
     * @summary アンケート添削却下承認依頼回答実行
     * @param {EnqueteCorrectRejectApprovalExecuteFormResponse} enqueteCorrectRejectApprovalExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectEnqueteApi
     */
    public enqueteCorrectRejectApprovalExecute(enqueteCorrectRejectApprovalExecuteFormResponse: EnqueteCorrectRejectApprovalExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectEnqueteApiFp(this.configuration).enqueteCorrectRejectApprovalExecute(enqueteCorrectRejectApprovalExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート添削エスカレ回答を実行する
     * @summary アンケート添削エスカレ回答実行
     * @param {EnqueteCorrectionEscalationAnswerExecuteFormResponse} enqueteCorrectionEscalationAnswerExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectEnqueteApi
     */
    public enqueteCorrectionEscalationAnswerExecute(enqueteCorrectionEscalationAnswerExecuteFormResponse: EnqueteCorrectionEscalationAnswerExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectEnqueteApiFp(this.configuration).enqueteCorrectionEscalationAnswerExecute(enqueteCorrectionEscalationAnswerExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート添削のエスカレを実行する
     * @summary アンケート添削エスカレ実行
     * @param {EnqueteCorrectionEscalationExecuteFormResponse} enqueteCorrectionEscalationExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectEnqueteApi
     */
    public enqueteCorrectionEscalationExecute(enqueteCorrectionEscalationExecuteFormResponse: EnqueteCorrectionEscalationExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectEnqueteApiFp(this.configuration).enqueteCorrectionEscalationExecute(enqueteCorrectionEscalationExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート添削NGを実行する
     * @summary アンケート添削NG実行
     * @param {CorrectionNgExecuteFormResponse} correctionNgExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectEnqueteApi
     */
    public enqueteCorrectionNgExecute(correctionNgExecuteFormResponse: CorrectionNgExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectEnqueteApiFp(this.configuration).enqueteCorrectionNgExecute(correctionNgExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート添削OKを実行する
     * @summary アンケート添削OK実行
     * @param {EnqueteCorrectionOkExecuteFormResponse} enqueteCorrectionOkExecuteFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectEnqueteApi
     */
    public enqueteCorrectionOkExecute(enqueteCorrectionOkExecuteFormResponse: EnqueteCorrectionOkExecuteFormResponse, options?: AxiosRequestConfig) {
        return CorrectEnqueteApiFp(this.configuration).enqueteCorrectionOkExecute(enqueteCorrectionOkExecuteFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート却下を実施する
     * @summary アンケート　却下実施
     * @param {EnqueteCorrectionRejectApprovalRequestFormResponse} enqueteCorrectionRejectApprovalRequestFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectEnqueteApi
     */
    public enqueteCorrectionRejectExec(enqueteCorrectionRejectApprovalRequestFormResponse: EnqueteCorrectionRejectApprovalRequestFormResponse, options?: AxiosRequestConfig) {
        return CorrectEnqueteApiFp(this.configuration).enqueteCorrectionRejectExec(enqueteCorrectionRejectApprovalRequestFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート添削回答編集を保存する
     * @summary アンケート添削回答編集保存
     * @param {EnqueteCorrectionSaveAnswerFormResponse} enqueteCorrectionSaveAnswerFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectEnqueteApi
     */
    public enqueteCorrectionSaveAnswer(enqueteCorrectionSaveAnswerFormResponse: EnqueteCorrectionSaveAnswerFormResponse, options?: AxiosRequestConfig) {
        return CorrectEnqueteApiFp(this.configuration).enqueteCorrectionSaveAnswer(enqueteCorrectionSaveAnswerFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アンケート添削却下承認依頼を送信する
     * @summary アンケート添削却下承認依頼送信
     * @param {EnqueteCorrectionRejectApprovalRequestFormResponse} enqueteCorrectionRejectApprovalRequestFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrectEnqueteApi
     */
    public enqueteCorrectionSendRejectionApprovalRequest(enqueteCorrectionRejectApprovalRequestFormResponse: EnqueteCorrectionRejectApprovalRequestFormResponse, options?: AxiosRequestConfig) {
        return CorrectEnqueteApiFp(this.configuration).enqueteCorrectionSendRejectionApprovalRequest(enqueteCorrectionRejectApprovalRequestFormResponse, options).then((request) => request(this.axios, this.basePath));
    }
}
