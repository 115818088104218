import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId } from '../../../utils/functions';
import { DispatchSetState, FormElement } from '../../../interfaces/utils';
import type { GiftTicketPageState } from '../../pages/GiftTicketPage';

/** 数値入力フォーム */
type NumberFormsState = Pick<
  GiftTicketPageState['forms'],
  'giftTicketId' | 'customerId' | 'applyId' | 'shopId' | 'clientId'
>;
/** 日付入力フォーム */
type DateFormsState = Pick<
  GiftTicketPageState['forms'],
  'createAtStart' | 'createAtEnd' | 'usedDateStart' | 'usedDateEnd' | 'expirationDateStart' | 'expirationDateEnd'
>;

export type State = NumberFormsState & DateFormsState;
interface Props {
  setForms: DispatchSetState<State>;
}

export type TestIds = keyof State;

export const GiftTicketForms: React.FC<Props> = ({ setForms }) => {
  const testId = createTestId<TestIds>(GiftTicketForms);
  const { state: $number, onChangeSet: onChangeNumber, mergeState } = useLargeState<NumberFormsState>({});
  const numberForms: FormElement<NumberFormsState, { label: string }>[] = useMemo(
    () => [
      {
        label: 'ギフト券ID',
        name: 'giftTicketId',
        onChange: onChangeNumber('giftTicketId', Number),
      },
      {
        label: '会員ID',
        name: 'customerId',
        onChange: onChangeNumber('customerId', Number),
      },
      {
        label: '応募ID',
        name: 'applyId',
        onChange: onChangeNumber('applyId', Number),
      },
      {
        label: '店舗ID',
        name: 'shopId',
        onChange: onChangeNumber('shopId', Number),
      },
      {
        label: 'クライアントID',
        name: 'clientId',
        onChange: onChangeNumber('clientId', Number),
      },
    ],
    [onChangeNumber]
  );
  const { id } = useParams<{ id: string | undefined }>();

  const { state: $date, onChangeSet: onChangeDate } = useLargeState<DateFormsState>({});
  const dateForms: {
    label: string;
    start: FormElement<DateFormsState>;
    end: FormElement<DateFormsState>;
  }[] = useMemo(
    () => [
      {
        label: '取得年月日',
        start: {
          name: 'createAtStart',
          onChange: onChangeDate('createAtStart'),
        },
        end: {
          name: 'createAtEnd',
          onChange: onChangeDate('createAtEnd'),
        },
      },
      {
        label: '利用年月日',
        start: {
          name: 'usedDateStart',
          onChange: onChangeDate('usedDateStart'),
        },
        end: {
          name: 'usedDateEnd',
          onChange: onChangeDate('usedDateEnd'),
        },
      },
      {
        label: '有効期限',
        start: {
          name: 'expirationDateStart',
          onChange: onChangeDate('expirationDateStart'),
        },
        end: {
          name: 'expirationDateEnd',
          onChange: onChangeDate('expirationDateEnd'),
        },
      },
    ],
    [onChangeDate]
  );

  useEffect(() => {
    if (typeof id === 'string' && $number.customerId === undefined) {
      const customerId = Number(id);
      mergeState({ customerId });
    }
    setForms({ ...$number, ...$date });
  }, [id, mergeState, setForms, $number, $date]);

  return (
    <>
      <Row>
        {numberForms.map(({ label, name, onChange }) => (
          <Form.Group data-testid={testId(name)} controlId={name} key={name} className="col-md-4">
            <Form.Label>{label}</Form.Label>
            <Form.Control type="number" autoComplete="off" value={$number[name] || ''} onChange={onChange} />
          </Form.Group>
        ))}
      </Row>
      {dateForms.map(({ label, start, end }) => (
        <Row key={label}>
          <Form.Label>{label}</Form.Label>
          <Col>
            <Form.Group data-testid={testId(start.name)} controlId={start.name}>
              <Form.Control type="date" autoComplete="off" value={$date[start.name] || ''} onChange={start.onChange} />
            </Form.Group>
          </Col>
          <Col xs="1">～</Col>
          <Col>
            <Form.Group data-testid={testId(end.name)} controlId={end.name}>
              <Form.Control type="date" autoComplete="off" value={$date[end.name] || ''} onChange={end.onChange} />
            </Form.Group>
          </Col>
        </Row>
      ))}
    </>
  );
};
