import React, { useCallback, useEffect } from 'react';
import { Accordion, Alert, Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { LoadingSpinner } from '../../molecules/Loading/LoadingSpinner';
import sass from '../../../scss/templates/CorrectionTemplate.module.scss';
// eslint-disable-next-line import/no-cycle
import { ShoppingApi, CommonMasterListApi } from '../../../api-client';
import type {
  CommonMasterListOutputResponse,
  DataEntryDisplayOutputResponse,
  DataInputSubmitFormResponse,
  ErrorObjectResponse,
  IncResultOutputResponse,
  ReceiptReadResultFormResponse,
  ShoppingDisplayControlOutputResponse,
  SurveyProofImageInfoDtoResponse,
} from '../../../api-client';
import { isoDateToInput, when } from '../../../utils/functions';
import { Url } from '../../../constants/Url';
import { DataInputNoteContent } from '../../organisms/Correction/DataInput/DataInputContent';
// eslint-disable-next-line import/no-cycle
import { CorrectDataInputForm } from '../../organisms/Forms/CorrectDataInputForm';
// eslint-disable-next-line import/no-cycle
import { ImageLens } from '../../organisms/ImageLens';
import { ShoppingControlAlert } from '../../organisms/Correction/DataInput/ShoppingControlAlert';
import { HTTPStatusCode } from '../../../constants/HTTPStatusCode';

export interface ErrorMessagesForInput {
  target: string;
  message: string;
}
export interface CorrectDataInputState {
  shoppingApi: ShoppingApi;
  commonMasterListApi: CommonMasterListApi;
  isLoading: boolean;
  output: DataEntryDisplayOutputResponse;
  forms: DataInputSubmitFormResponse;
  chainNameList: string[];
  prefectureList: CommonMasterListOutputResponse[];
  imageList: SurveyProofImageInfoDtoResponse[];
  controlInfo: ShoppingDisplayControlOutputResponse;
  errorMessages: string[];
  errorMessagesForInput: ErrorMessagesForInput[];
}
export type TestIds = 'ok-button' | 'draft-button' | 'submit-button';

export const CorrectDataInputPage: React.FC = () => {
  const PREFECTURE_KEY = 'PREFECTURE';

  const initialOutput: DataEntryDisplayOutputResponse = {
    applyId: 0,
    monitorId: 0,
    receiptReadResultList: [],
    surveyProofDtoList: [],
    selectableTaxRateList: [],
  };

  const initialForm: DataInputSubmitFormResponse = {
    applyId: 0,
    receiptReadResultFormList: [],
  };

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<CorrectDataInputState>({
    shoppingApi: new ShoppingApi(),
    commonMasterListApi: new CommonMasterListApi(),
    isLoading: false,
    output: initialOutput,
    forms: initialForm,
    chainNameList: [],
    prefectureList: [],
    imageList: [],
    controlInfo: {
      applyId: 0,
      enqueteStatus: '',
      isDataInputEscalationAnswerPossible: false,
      isDataInputPossible: false,
      surveyProofStatus: '',
      isEscalationAnswerForSv: false,
      isForcedCorrect: false,
    },
    errorMessages: [],
    errorMessagesForInput: [],
  });

  const history = useHistory();
  const setForms = useBindSet('forms');
  const { applyId } = useParams<{ applyId: string | undefined }>();

  const createInitialFormData = useCallback((data: DataEntryDisplayOutputResponse, id: number) => {
    const initialData: DataInputSubmitFormResponse = {
      applyId: id,
      totalAmountIncludeTax: data.totalAmountIncludeTax,
      receiptReadResultFormList: [
        ...data.receiptReadResultList.map((obj) => {
          const returnData: ReceiptReadResultFormResponse = {
            amountReadableFlg: !!obj.receiptAmount?.amountReadableFlg,
            receiptAmount: obj.receiptAmount?.amount,
            autoCorrectReceiptApproveId: obj.autoCorrectReceiptId,
            chainNameReadableFlg: !!obj.chain?.chainNameReadableFlg,
            chainName: obj.chain?.chainName,
            inFocusFlg: !!obj.inFocusCheck?.inFocusCheckFlg,
            prefectureReadableFlg: !!obj.receiptReadResultPrefecture?.prefectureReadableFlg,
            selectPrefectureId: obj.receiptReadResultPrefecture?.selectPrefectureId,
            purchaseCheckReadableFlg: !!obj.purchaseCheckResult?.purchaseCheckReadableFlg,
            purchaseCheckResultFlg: !!obj.purchaseCheckResult?.purchaseCheckResultFlg,
            receiptDateTimeReadableFlg: !!obj.receiptDateTime?.receiptDateTimeReadableFlg,
            receiptDateTime: isoDateToInput('datetime-local', obj.receiptDateTime?.dateTime || '') as string,
            receiptNumberReadableFlg: !!obj.receiptNumber?.receiptNumberReadableFlg,
            shopPhoneNumberReadableFlg: !!obj.shopPhoneNumber?.shopPhoneNumberReadableFlg,
            shopPhoneNumber: obj.shopPhoneNumber?.phoneNumber,
            storeReadableFlg: !!obj.store?.storeReadableFlg,
            storeName: obj.store?.storeName,
            visibleAllCornerFlg: !!obj.visibleAllCorner?.visibleAllCornerFlg,
            inquiryContents: obj.inputUserQuestion?.message,
            receiptAmountIncludeTax: obj.receiptAmountIncludeTax,
            taxRateCode: obj.selectedTaxRateCode,
          };
          return returnData;
        }),
      ],
    };
    return initialData;
  }, []);

  const LATEST = '最新';

  useEffect(() => {
    mergeState({ isLoading: true });
    if (applyId && Number(applyId) > 0) {
      $.shoppingApi
        .dataEntryDisplay(Number(applyId))
        .then((res: AxiosResponse<DataEntryDisplayOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            const initialData: DataInputSubmitFormResponse = createInitialFormData(res.data, Number(applyId));
            const latestSurveyProofDtoList = res.data.surveyProofDtoList.filter(
              ({ versionName }) => versionName === LATEST
            );
            const imageList =
              latestSurveyProofDtoList.length === 1 ? latestSurveyProofDtoList[0].surveyProofImageInfoDtoList : [];
            mergeState({
              output: res.data,
              forms: initialData,
              imageList,
            });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });

      $.shoppingApi
        .chainNameList()
        .then((res: AxiosResponse<Array<string>>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            mergeState({ chainNameList: res.data });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });

      $.shoppingApi
        .shoppingDisplayControl(Number(applyId), false)
        .then((res: AxiosResponse<ShoppingDisplayControlOutputResponse>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            mergeState({ controlInfo: res.data });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });

      $.commonMasterListApi
        .commonMasterList(PREFECTURE_KEY)
        .then((res: AxiosResponse<Array<CommonMasterListOutputResponse>>) => {
          if (Object.prototype.hasOwnProperty.call(res.data, 'errorMessage')) {
            history.push(Url.COMMON_ERROR);
          } else {
            mergeState({ prefectureList: res.data });
          }
        })
        .catch(() => {
          history.push(Url.COMMON_ERROR);
        });
    }
    mergeState({ isLoading: false });
  }, [history, applyId, mergeState, $.shoppingApi, $.commonMasterListApi, createInitialFormData]);

  const onSubmitStart = useCallback(() => {
    mergeState({ isLoading: true });
  }, [mergeState]);

  const onSubmitEnd = useCallback(() => {
    mergeState({ isLoading: false });
  }, [mergeState]);

  /**
   * 入力値のチェック
   * @param body
   */
  const canSubmit = (body: DataInputSubmitFormResponse) => {
    let result = true;
    let tmp: ErrorMessagesForInput[] = [];
    body.receiptReadResultFormList.map((v, index) => {
      if (v.inFocusFlg === undefined) {
        tmp = [...tmp, { target: `inFocusFlg_${index}`, message: '必須の項目です。選択してください。' }];
        result = false;
      }
      if (v.visibleAllCornerFlg === undefined) {
        tmp = [...tmp, { target: `visibleAllCornerFlg_${index}`, message: '必須の項目です。選択してください。' }];
        result = false;
      }
      if (v.receiptNumberReadableFlg === undefined) {
        tmp = [...tmp, { target: `receiptNumberReadableFlg_${index}`, message: '必須の項目です。選択してください。' }];
        result = false;
      }
    });
    mergeState({
      errorMessagesForInput: [...tmp],
    });
    return result;
  };
  // 実行
  const onSubmit = useCallback(
    (body: DataInputSubmitFormResponse) => {
      onSubmitStart();
      if (canSubmit(body)) {
        $.shoppingApi
          .dataInputSubmit(body)
          .then((res: AxiosResponse<IncResultOutputResponse>) => {
            if (res.data.result) {
              history.push(`${Url.TENSAKU.DATA_INPUT_DISTRIBUTE}/${$.output.monitorId}`);
            } else {
              mergeState({ errorMessages: [`${res.data.errorMessage}`] });
            }
          })
          .catch((e) => {
            if (e.response?.status === HTTPStatusCode.UnprocessableEntity) {
              mergeState({ errorMessages: [e.response.data.errorMessage] });
            }
          })
          .finally(() => {
            onSubmitEnd();
          });
      } else {
        onSubmitEnd();
      }
    },
    [$.shoppingApi, history, mergeState, onSubmitEnd, onSubmitStart, $.output.monitorId]
  );

  return (
    <>
      <LoadingSpinner isLoading={$.isLoading}>
        {when(
          $.output.applyId > 0,
          <div className="d-flex">
            <div className={`w-50 h-100 ${sass.correction_contentsCol}`}>
              <ShoppingControlAlert controlInfo={$.controlInfo} wcheckFlg={false} />
              <DataInputNoteContent output={$.output} />
              <CorrectDataInputForm
                formData={$.forms}
                setForms={setForms}
                output={$.output.receiptReadResultList}
                prefectureList={$.prefectureList}
                chainNameList={$.chainNameList}
                taxRateList={$.output.selectableTaxRateList}
                controlInfo={$.controlInfo}
                errorMessagesForInput={$.errorMessagesForInput}
              />
              {when(
                $.controlInfo.isDataInputPossible,
                <div>
                  {when(
                    $.errorMessages.length > 0,
                    <div className="text-danger" style={{ whiteSpace: 'pre-wrap' }}>
                      {$.errorMessages.map((text) => (
                        <Alert variant="danger">{text}</Alert>
                      ))}
                    </div>
                  )}
                  <div className="d-flex justify-content-end mt-2">
                    <Button
                      variant="outline-primary"
                      onClick={() => {
                        onSubmit($.forms);
                      }}
                    >
                      <FontAwesomeIcon icon={faCheckCircle} />
                      入力完了
                    </Button>
                  </div>
                </div>
              )}
            </div>
            <div className={`w-50 h-100 sticky-top ${sass.correction_contentsCol}`}>
              <Card className="ms-2">
                <Accordion defaultActiveKey={['0']} flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>購入証明情報</Accordion.Header>
                    <Accordion.Body>{when($.imageList.length > 0, <ImageLens list={$.imageList} />)}</Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Card>
            </div>
          </div>
        )}
      </LoadingSpinner>
    </>
  );
};
