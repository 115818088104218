import React, { useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { useLargeState } from '../../../../hooks/useLargeState';
import { createTestId, formatISODate } from '../../../../utils/functions';
import type { MailAddressEditConfirmedFormResponse } from '../../../../api-client';
import { CustomerMailInfoEditModal } from '../../../organisms/Modal/Customer/CustomerMailInfoEditModal';
import type { CustomerDetailState } from '../CustomerDetailPage';

export type State = CustomerDetailState['customerData'];
interface Props {
  customerData: State;
}

export interface MailInfoState {
  isModal: boolean;
  forms: MailAddressEditConfirmedFormResponse;
}

export type TestIds = keyof State | 'pen';

export const MailInfoCard: React.FC<Props> = ({ customerData }) => {
  const testid = createTestId<TestIds>(MailInfoCard);

  const { state: $info } = useLargeState<State>(customerData);

  const initialData: MailAddressEditConfirmedFormResponse = useMemo(
    () => ({
      customerId: customerData.customerId,
      mailAddress: customerData.mailAddress,
      mailAddress2: customerData.mailAddress2,
      mailSendFailCount: customerData.mailSendFailCount,
      mailUnreachableFlg: customerData.bounceMailFlg,
      newsletterSendFlg: customerData.newsletterSendFlg || false,
    }),
    [customerData]
  );

  const {
    state: $modal,
    mergeState: mergeModal,
    useBindSet,
  } = useLargeState<MailInfoState>({
    isModal: false,
    forms: initialData,
  });

  const setForms = useBindSet('forms');

  return (
    <>
      <CustomerMailInfoEditModal
        isModal={$modal.isModal}
        forms={$modal.forms}
        setForms={setForms}
        onHide={() =>
          mergeModal({
            isModal: false,
            forms: initialData,
          })
        }
      />
      <Card className="mt-2 me-2" style={{ width: '24.25%' }}>
        <div className="d-flex justify-content-between mt-2">
          <h6>メール</h6>
          <div style={{ cursor: 'pointer' }} data-testid={testid('pen')}>
            <FontAwesomeIcon icon={faPen} onClick={() => mergeModal({ isModal: true })} />
          </div>
        </div>

        <small>メールアドレス</small>
        <p className="mb-0" data-testid={testid('mailAddress')}>
          {$info.mailAddress}
        </p>
        <small>メールアドレス2</small>
        <p className="mb-0" data-testid={testid('mailAddress2')}>
          {$info.mailAddress2 ?? '未設定'}
        </p>
        <small className="text-secondary mb-2" data-testid={testid('mailAddressUpdateAt')}>
          更新日時
          {$info.mailAddressUpdateAt ? formatISODate($info.mailAddressUpdateAt, 'yyyy/MM/dd HH:mm:ss') : ''}
        </small>

        <small>メルマガ配信</small>
        <p className="mb-2" data-testid={testid('newsletterSendFlg')}>
          {$info.newsletterSendFlg ? '配信する' : '配信しない'}
        </p>

        <small>メール不達ステータス</small>
        <p className="mb-0" data-testid={testid('bounceMailFlg')}>
          {$info.bounceMailFlg ? '不達' : ''}
        </p>
        <small className="text-secondary mb-2" data-testid={testid('bounceMailFlgUpdateAt')}>
          更新日時
          {$info.bounceMailFlgUpdateAt ? formatISODate($info.bounceMailFlgUpdateAt, 'yyyy/MM/dd HH:mm:ss') : ''}
        </small>

        <small>メール送信失敗回数</small>
        <p className="mb-0" data-testid={testid('mailSendFailCount')}>
          送信失敗{$info.mailSendFailCount}回{$info.mailSendFailCount === 3 ? '以上' : ''}
        </p>
        <small className="text-secondary" data-testid={testid('failCountUpdateAt')}>
          更新日時
          {$info.failCountUpdateAt ? formatISODate($info.failCountUpdateAt, 'yyyy/MM/dd HH:mm:ss') : ''}
        </small>
      </Card>
    </>
  );
};
