import React, { useCallback, useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { createTestId, nameof } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import type { StatusInfoState as ParentState } from '../../pages/Customer/Card/StatusInfoCard';
import { POINT_EXCHENGE_STATUS, POINT_EXCHENGE_STATUS_NAME } from '../../../Constants';

interface Props {
  forms: ParentState['forms'];
  setForms: DispatchSetState<ParentState['forms']>;
  oemFlag: boolean;
}
export const CustomerStatusInfoEditModalForms: React.FC<Props> = ({ forms, setForms, oemFlag }) => {
  const testid = createTestId<keyof ParentState['forms'] | 'auto-fill-button'>(CustomerStatusInfoEditModalForms);
  const { state: $, onChangeSet, mergeState } = useLargeState<ParentState['forms']>(forms ?? {});

  const { state: $flg, onChangeSet: changeFlg } = useLargeState<{ pointExchangeStatus: number }>({
    pointExchangeStatus: forms.pointFreezeFlg ? POINT_EXCHENGE_STATUS.POINT_FREEZE : POINT_EXCHENGE_STATUS.NORMAL,
  });

  const name = (x: keyof ParentState['forms']) => nameof(x);
  useEffect(() => {
    setForms($);
  }, [setForms, $]);

  useEffect(() => {
    mergeState({ pointFreezeFlg: Number($flg.pointExchangeStatus) === POINT_EXCHENGE_STATUS.POINT_FREEZE });
  }, [mergeState, $flg.pointExchangeStatus]);

  const onchangeTestCustomerFlg = useCallback(
    (testCustomerFlg: boolean) => {
      mergeState({ testCustomerFlg: !testCustomerFlg });
    },
    [mergeState]
  );

  const options: [string, number][] = useMemo(
    () =>
      Object.entries({
        ポイント凍結: POINT_EXCHENGE_STATUS.POINT_FREEZE,
        通常: POINT_EXCHENGE_STATUS.NORMAL,
      }),
    []
  );

  return (
    <>
      <Form.Group controlId={`${name('customerStatus')}-0`} data-testid={testid('customerStatus', 0)}>
        <Form.Label>会員ステータス</Form.Label>
        <Form.Check
          type="radio"
          key="customerStatus-active"
          name="customerStatus"
          label="アクティブ"
          value={0}
          onChange={onChangeSet('customerStatus', Number)}
          checked={$.customerStatus === 0}
        />
      </Form.Group>
      <Form.Group controlId={`${name('customerStatus')}-1`} data-testid={testid('customerStatus', 1)}>
        <Form.Check
          type="radio"
          key="customerStatus-withdrawal"
          name="customerStatus"
          label="退会"
          value={1}
          onChange={onChangeSet('customerStatus', Number)}
          checked={$.customerStatus === 1}
        />
      </Form.Group>
      {!oemFlag ? (
        <>
          <Form.Group controlId={name('pointFreezeFlg')} data-testid={testid('pointFreezeFlg')}>
            <Form.Label>ポイント交換ステータス</Form.Label>
            <Form.Select value={$flg.pointExchangeStatus} onChange={changeFlg('pointExchangeStatus')}>
              {options.map(([label, value]) => (
                <option key={`${value}`} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group controlId={name('pointFreezeReason')} data-testid={testid('pointFreezeReason')}>
            <Form.Label>ポイント凍結理由</Form.Label>
            <Form.Control type="text" value={$.pointFreezeReason} onChange={onChangeSet('pointFreezeReason', String)} />
          </Form.Group>
        </>
      ) : undefined}
      <br />
      <Form.Group controlId={name('testCustomerFlg')} data-testid={testid('testCustomerFlg')}>
        <Form.Label>テスト会員</Form.Label>
        <small className="text-danger">
          →※旧基盤には連携されないため、変更した場合、必ず旧基盤も変更してください。
        </small>
        <Form.Check
          type="switch"
          onChange={() => onchangeTestCustomerFlg($.testCustomerFlg)}
          checked={$.testCustomerFlg}
        />
      </Form.Group>
    </>
  );
};
