import * as React from 'react';
import { Table } from 'react-bootstrap';
import { ParameterListTable } from './SqlTemplateParameterTable';
import { SqlQueryInfoOutputResponse, SqlQueryListOutputResponse } from '../../../api-client/domains';
import { when } from '../../../utils/functions';
import { UpdateForm } from '../../pages/SqlQuery/SqlQueryUpdatePage';
import { QueryCreateForm } from '../../pages/SqlQuery/SqlQueryCreateConfirmPage';

// WARNING: フロントエンドとバックエンドで二重管理になっている
export const execType = (type: string | undefined) => {
  switch (type) {
    case '1':
      return '一回限り';
    case '2':
      return '繰り返し実行する';
    default:
      return '';
  }
};

// WARNING: フロントエンドとバックエンドで二重管理になっている
const repeatType = (type: string) => {
  switch (type) {
    case '1':
      return '日';
    case '2':
      return '月';
    case '3':
      return '火';
    case '4':
      return '水';
    case '5':
      return '木';
    case '6':
      return '金';
    case '7':
      return '土';
    case '100':
      return '1日';
    case '150':
      return '15日';
    case '300':
      return '月末日';
    case '999':
      return '毎日';
    default:
  }
};

export const SqlQueryInfoTable: React.FC<{
  tableTitle: string;
  tableData: SqlQueryInfoOutputResponse | UpdateForm | QueryCreateForm;
}> = ({ tableTitle, tableData }) => {
  const executeTimestamp = (type: string | undefined) => {
    if (typeof tableData.repeatType === 'string' && typeof tableData.repeatTime === 'string') {
      return type === '1'
        ? tableData?.executeTimestamp
        : `${repeatType(tableData?.repeatType)} / ${tableData?.repeatTime}`;
    }
    return '';
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>{tableTitle}</th>
        </tr>
      </thead>
      <tbody>
        {tableData?.queryId ? (
          <tr>
            <th>ID</th>
            <td>{tableData?.queryId}</td>
          </tr>
        ) : null}
        <tr>
          <th>クエリ名</th>
          <td>{tableData?.queryName}</td>
        </tr>
        <tr>
          <th>SQL</th>
          <td>{tableData?.sqlString}</td>
        </tr>
        <tr>
          <th>パラメータ一覧</th>
          <td>
            <ParameterListTable parameterList={tableData?.parameterList} />
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>{tableData?.mailaddressList.map((address: any) => `${address.mailaddress},`)}</td>
        </tr>
        <tr>
          <th>スキップフラグ</th>
          <td>{tableData?.skipFlag ? 'ON' : 'OFF'}</td>
        </tr>
        <tr>
          <th>有効フラグ</th>
          <td>{tableData?.validFlag ? 'ON' : 'OFF'}</td>
        </tr>
        <tr>
          <th>実行タイプ</th>
          <td>{`${execType(tableData?.execType)} ${executeTimestamp(tableData?.execType)}`}</td>
        </tr>
        <tr>
          <th>個人情報保護フラグ</th>
          <td>{tableData?.personalInformationProtectionFlag ? 'ON' : 'OFF'}</td>
        </tr>
        <tr>
          <th>上長承認の証跡URL</th>
          <td>{tableData?.superiorApprovalUrl}</td>
        </tr>
        <tr>
          <th>最終更新者</th>
          <td>{tableData?.updateBy}</td>
        </tr>
        <tr>
          <th>更新日時</th>
          <td>{tableData?.updateAt}</td>
        </tr>
        <tr>
          <th>実行結果(クリックでダウンロード)</th>
          <td>
            {when(
              tableData?.resultFilePath !== '',
              <a href={tableData?.resultFilePath} download>
                結果
              </a>
            )}
            {when(tableData?.resultFilePath === '', <>未実行</>)}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
