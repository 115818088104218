/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GiftTicketInfoOutputResponse } from '../domains';
// @ts-ignore
import { GiftTicketListFormResponse } from '../domains';
// @ts-ignore
import { GiftTicketListOutputResponse } from '../domains';
// @ts-ignore
import { GiftTicketUpdateFormResponse } from '../domains';
// @ts-ignore
import { GiftUseStateListOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
/**
 * GiftTicketApi - axios parameter creator
 * @export
 */
export const GiftTicketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ギフト券の編集画面に表示する初期の情報
         * @summary ギフト券編集画面表示
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftTicketDetail: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('giftTicketDetail', 'id', id)
            const localVarPath = `/api/gift-ticket/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ギフト券の一覧を検索条件に応じて表示する。
         * @summary ギフト券一覧表示
         * @param {GiftTicketListFormResponse} giftTicketListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftTicketList: async (giftTicketListFormResponse: GiftTicketListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftTicketListFormResponse' is not null or undefined
            assertParamExists('giftTicketList', 'giftTicketListFormResponse', giftTicketListFormResponse)
            const localVarPath = `/api/gift-ticket/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftTicketListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ギフト券の編集画面を更新する
         * @summary ギフト券編集画面更新
         * @param {GiftTicketUpdateFormResponse} giftTicketUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftTicketUpdate: async (giftTicketUpdateFormResponse: GiftTicketUpdateFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'giftTicketUpdateFormResponse' is not null or undefined
            assertParamExists('giftTicketUpdate', 'giftTicketUpdateFormResponse', giftTicketUpdateFormResponse)
            const localVarPath = `/api/gift-ticket/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(giftTicketUpdateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ギフト券の利用状態を一覧で表示する。主にプルダウン表示のために使用。
         * @summary ギフト券利用状態一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftTicketUseStateList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/gift-ticket/use-state`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GiftTicketApi - functional programming interface
 * @export
 */
export const GiftTicketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GiftTicketApiAxiosParamCreator(configuration)
    return {
        /**
         * ギフト券の編集画面に表示する初期の情報
         * @summary ギフト券編集画面表示
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftTicketDetail(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftTicketInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftTicketDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ギフト券の一覧を検索条件に応じて表示する。
         * @summary ギフト券一覧表示
         * @param {GiftTicketListFormResponse} giftTicketListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftTicketList(giftTicketListFormResponse: GiftTicketListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GiftTicketListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftTicketList(giftTicketListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ギフト券の編集画面を更新する
         * @summary ギフト券編集画面更新
         * @param {GiftTicketUpdateFormResponse} giftTicketUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftTicketUpdate(giftTicketUpdateFormResponse: GiftTicketUpdateFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftTicketUpdate(giftTicketUpdateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ギフト券の利用状態を一覧で表示する。主にプルダウン表示のために使用。
         * @summary ギフト券利用状態一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async giftTicketUseStateList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GiftUseStateListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.giftTicketUseStateList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GiftTicketApi - factory interface
 * @export
 */
export const GiftTicketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GiftTicketApiFp(configuration)
    return {
        /**
         * ギフト券の編集画面に表示する初期の情報
         * @summary ギフト券編集画面表示
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftTicketDetail(id: number, options?: any): AxiosPromise<GiftTicketInfoOutputResponse> {
            return localVarFp.giftTicketDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * ギフト券の一覧を検索条件に応じて表示する。
         * @summary ギフト券一覧表示
         * @param {GiftTicketListFormResponse} giftTicketListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftTicketList(giftTicketListFormResponse: GiftTicketListFormResponse, options?: any): AxiosPromise<GiftTicketListOutputResponse> {
            return localVarFp.giftTicketList(giftTicketListFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ギフト券の編集画面を更新する
         * @summary ギフト券編集画面更新
         * @param {GiftTicketUpdateFormResponse} giftTicketUpdateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftTicketUpdate(giftTicketUpdateFormResponse: GiftTicketUpdateFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.giftTicketUpdate(giftTicketUpdateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ギフト券の利用状態を一覧で表示する。主にプルダウン表示のために使用。
         * @summary ギフト券利用状態一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        giftTicketUseStateList(options?: any): AxiosPromise<Array<GiftUseStateListOutputResponse>> {
            return localVarFp.giftTicketUseStateList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GiftTicketApi - object-oriented interface
 * @export
 * @class GiftTicketApi
 * @extends {BaseAPI}
 */
export class GiftTicketApi extends BaseAPI {
    /**
     * ギフト券の編集画面に表示する初期の情報
     * @summary ギフト券編集画面表示
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftTicketApi
     */
    public giftTicketDetail(id: number, options?: AxiosRequestConfig) {
        return GiftTicketApiFp(this.configuration).giftTicketDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ギフト券の一覧を検索条件に応じて表示する。
     * @summary ギフト券一覧表示
     * @param {GiftTicketListFormResponse} giftTicketListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftTicketApi
     */
    public giftTicketList(giftTicketListFormResponse: GiftTicketListFormResponse, options?: AxiosRequestConfig) {
        return GiftTicketApiFp(this.configuration).giftTicketList(giftTicketListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ギフト券の編集画面を更新する
     * @summary ギフト券編集画面更新
     * @param {GiftTicketUpdateFormResponse} giftTicketUpdateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftTicketApi
     */
    public giftTicketUpdate(giftTicketUpdateFormResponse: GiftTicketUpdateFormResponse, options?: AxiosRequestConfig) {
        return GiftTicketApiFp(this.configuration).giftTicketUpdate(giftTicketUpdateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ギフト券の利用状態を一覧で表示する。主にプルダウン表示のために使用。
     * @summary ギフト券利用状態一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GiftTicketApi
     */
    public giftTicketUseStateList(options?: AxiosRequestConfig) {
        return GiftTicketApiFp(this.configuration).giftTicketUseStateList(options).then((request) => request(this.axios, this.basePath));
    }
}
