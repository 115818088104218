import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Col, InputGroup, ListGroup, Tabs, Tab } from 'react-bootstrap';
import { createTestId } from '../../../utils/functions';
import { useLargeState } from '../../../hooks/useLargeState';
import { Button } from '../../atoms/Button';
import { Modal } from '../../molecules/Modal';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import type { CommonMasterListOutputResponse } from '../../../api-client';
import type { DispatchSetState } from '../../../interfaces/utils';

const TAB_TYPE = {
  ALL: 'all',
  SELECTED: 'selected',
} as const;

export interface Props {
  isModal: boolean;
  setIsModal: DispatchSetState<boolean>;
  values: CommonMasterListOutputResponse[];
  setValues: DispatchSetState<CommonMasterListOutputResponse[]>;
  options: CommonMasterListOutputResponse[];
}

export interface State {
  tabType: TabType;
  searchText: string;
  currentPages: { all: number; selected: number };
  totalPages: { all: number; selected: number };
  lists: {
    all: { id: number; name: string; selected: boolean }[];
    selected: { id: number; name: string; selected: boolean }[];
  };
  listsPerPage: {
    all: { id: number; name: string; selected: boolean }[];
    selected: { id: number; name: string; selected: boolean }[];
  };
}

type ListItem = CommonMasterListOutputResponse & { selected: boolean };

const initialState = {
  tabType: TAB_TYPE.ALL,
  searchText: '',
  currentPages: { all: 1, selected: 1 },
  totalPages: { all: 1, selected: 1 },
  lists: { all: [], selected: [] },
  listsPerPage: { all: [], selected: [] },
};

export type TabType = typeof TAB_TYPE[keyof typeof TAB_TYPE];
const MAX_RECORD_PER_PAGE = 100;

export const MonitorCommonModal: React.VFC<Props> = ({ isModal, setIsModal, values, setValues, options }) => {
  const testid = createTestId(MonitorCommonModal);
  const { state: $, mergeState } = useLargeState<State>(initialState);

  useEffect(() => {
    if (!values) return;
    const all = options.map((item) => ({ ...item, selected: values.some((v) => item.id === v.id) }));
    const selected = values.map((item) => ({ ...item, selected: true }));

    mergeState({
      lists: { all, selected },
      listsPerPage: { all: all.slice(0, MAX_RECORD_PER_PAGE), selected: selected.slice(0, MAX_RECORD_PER_PAGE) },
      totalPages: {
        all: Math.ceil(Number(all.length) / MAX_RECORD_PER_PAGE),
        selected: Math.ceil(Number(selected.length) / MAX_RECORD_PER_PAGE),
      },
    });
  }, [options, values, mergeState]);

  const onChange = (target: ListItem) => {
    const all = $.lists.all.map((v) => (v.id === target.id ? { ...target, selected: !target.selected } : v));

    const selected = !target.selected
      ? [...$.lists.selected, { ...target, selected: true }].sort((a, b) => a.id - b.id)
      : $.lists.selected.filter((item) => item.id !== target.id);

    mergeState({
      lists: { all, selected },
      listsPerPage: {
        all: all.slice(($.currentPages.all - 1) * MAX_RECORD_PER_PAGE, $.currentPages.all * MAX_RECORD_PER_PAGE),
        selected: selected.slice(
          ($.currentPages.selected - 1) * MAX_RECORD_PER_PAGE,
          $.currentPages.selected * MAX_RECORD_PER_PAGE
        ),
      },
      totalPages: { ...$.totalPages, selected: Math.ceil(Number(selected.length) / MAX_RECORD_PER_PAGE) },
    });
  };

  const onSearch = () => {
    const all = options
      .filter((v) => !$.searchText || v.name.includes($.searchText))
      .map((v) => ({ ...v, selected: $.lists.selected.some(({ id }) => v.id === id) }));

    mergeState({
      lists: { ...$.lists, all },
      listsPerPage: { ...$.listsPerPage, all: all.slice(0, MAX_RECORD_PER_PAGE) },
      totalPages: { ...$.totalPages, all: Math.ceil(Number(all.length) / MAX_RECORD_PER_PAGE) },
      currentPages: { ...$.currentPages, all: 1 },
    });
  };

  return (
    <Modal
      onHide={() => setIsModal(false)}
      isModal={isModal}
      size="lg"
      closeButton
      centered
      scrollable
      body={
        <>
          <div className="d-flex justify-content-end mb-4">
            <Button variant="link" className="ms-2" onClick={() => setIsModal(false)} data-testid={testid('cancel')}>
              キャンセル
            </Button>
            <Button
              className="ms-2"
              data-testid={testid('save-button')}
              onClick={() => {
                setValues($.lists.selected.map(({ selected, ...rest }) => rest));
                setIsModal(false);
              }}
            >
              保存
            </Button>
          </div>

          <Tabs
            activeKey={$.tabType}
            onSelect={(key) => mergeState({ tabType: key as TabType })}
            className="mb-4"
            data-testid={testid('tabs')}
          >
            <Tab key={TAB_TYPE.ALL} eventKey={TAB_TYPE.ALL} title="全て" />
            <Tab key={TAB_TYPE.SELECTED} eventKey={TAB_TYPE.SELECTED} title="選択済み" />
          </Tabs>

          <InputGroup className="mb-4" data-testid={testid('search-bar')}>
            <Form.Control
              className="w-50"
              type="text"
              value={$.searchText}
              onChange={(e) => mergeState({ searchText: e.target.value })}
            />
            <Button className="flex-shrink-1" onClick={onSearch} disabled={$.tabType === TAB_TYPE.SELECTED}>
              検索
            </Button>
          </InputGroup>
          <div className="mb-4" data-testid={testid('pagination')}>
            <PaginationWithEllipsis
              currentPage={$.currentPages[$.tabType]}
              totalPage={$.totalPages[$.tabType]}
              handleClick={(page) => {
                if (!page || page > $.totalPages[$.tabType]) return;
                mergeState({
                  currentPages: { ...$.currentPages, [$.tabType]: page },
                  listsPerPage: {
                    ...$.listsPerPage,
                    [$.tabType]: $.lists[$.tabType].slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE),
                  },
                });
              }}
            />
          </div>

          <Col className="mb-4">
            <ListGroup data-testid={testid('list')}>
              {$.listsPerPage[$.tabType].map((item) => (
                <ListGroup.Item key={item.id}>
                  <Form.Check id={String(item.id)} className="d-flex">
                    <Form.Check.Input
                      className="flex-shrink-0 me-2"
                      type="checkbox"
                      onChange={() => onChange(item)}
                      checked={item.selected}
                    />
                    <Form.Check.Label className="w-100">{item.name}</Form.Check.Label>
                  </Form.Check>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </>
      }
    />
  );
};
