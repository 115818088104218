import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-cycle
import {
  CorrectApi,
  CorrectEnqueteApi,
  CorrectionTemplateApi,
  CorrectReceiptApi,
  EnqueteAnswerListFormResponse,
  NoteApi,
} from '../../../api-client';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useLargeState } from '../../../hooks/useLargeState';
import { CorrectionPageState as State, CorrectionProvider } from '../../../store/correctionStore';
import { debugLog } from '../../../utils/functions';
import { CorrectStatusDisplay } from '../../molecules/CorrectStatusDisplay';
import { HeaderNav } from '../../organisms/Correction/HeaderNav';
// eslint-disable-next-line import/no-cycle
import { EnqueteContents } from '../../organisms/Correction/Main/EnqueteContents';
import { NGHistoryModal } from '../../organisms/Correction/Modal/NGHistoryModal';
import { NoteModal } from '../../organisms/Correction/Modal/NoteModal';
import { ReceiptInfoModal } from '../../organisms/Correction/Modal/ReceiptInfoModal';
import { ResultModal } from '../../organisms/Correction/Modal/ResultModal';
import { CorrectionTemplate } from '../../templates/CorrectionTemplate';
import * as CONSTANTS from './constants';
// eslint-disable-next-line import/no-cycle
import { debugLogCurrentUserDataForBackend } from './debug';
import { nativeAlert, questionPreSend } from './utils';
import { DisplayPointType } from './constants';
import { Alert } from '../../atoms/Alert';

// TODO: [添削] テスト未実装
export const CorrectionEnquetePage: React.FC = React.memo(() => {
  const largeState = useLargeState<State>({
    isLoading: true,
    isEnquete: true,

    applyId: Number(useParams<{ id: string | undefined }>().id),

    api_correct: new CorrectApi(),
    api_correctReceipt: new CorrectReceiptApi(),
    api_correctionTemplate: new CorrectionTemplateApi(),
    api_note: new NoteApi(),
    api_correctEnquete: new CorrectEnqueteApi(),
    isNoteModal: false,
    isResultModal: false,
    isNGHistoryModal: false,

    commentForms_ng: [],
    commentForms_escale: [],
    selectedForms: [],
    imageForms: { editImageIds: [] },

    // 強制的にエスカレ回答を表示状態にする
    // debug_forceEscalateAnswer: true,
  });
  const { state: $, mergeState: mergeStateWithRender, useBindSet: useLargeBindSet } = largeState;

  const { currentUser, isAuthChecking } = useCurrentUser();

  /** 初期フェッチ */
  useEffect(() => {
    getEnqueteContents();
  }, [
    $.api_correct,
    $.api_correctReceipt,
    $.api_note,
    $.api_correctionTemplate,
    $.api_correctEnquete,
    mergeStateWithRender,
    $.applyId,
  ]);

  // アンケートコンテンツの取得
  const getEnqueteContents = () => {
    (async () => {
      mergeStateWithRender({
        isLoading: false,
        // - CorrectEnqueteApi
        res_correctEnqueteDisplayControl: {
          ...(await $.api_correctEnquete.correctEnqueteDisplayControl($.applyId)).data,
          // ...warnValue('表示制御', {
          //   // -- 添削OK、NGを許可
          //   // correctionExec: false,
          //   correctionExec: true,
          //   // -- エスカレ権限
          //   // escalation: CONSTANTS.DisplayControlEscalate.DISABLE,
          //   // escalation: CONSTANTS.DisplayControlEscalate.DISPLAY,
          //   escalation: CONSTANTS.DisplayControlEscalate.ANSWER,
          //   // -- ノート表示
          //   // noteUpdate: false,
          //   noteUpdate: true,
          //   // -- 却下権限
          //   // reject: CONSTANTS.DisplayControlReject.DISABLE,
          //   reject: CONSTANTS.DisplayControlReject.REQUEST,
          //   // reject: CONSTANTS.DisplayControlReject.APPROVE_WITH_ESCALE,
          //   // reject: CONSTANTS.DisplayControlReject.APPROVE_WITH_ANSWER,
          //   // reject: CONSTANTS.DisplayControlReject.EXECUTE,
          //   // -- 却下エスカレ先
          //   rejectEscalationTargetName: 'ここにエスカレ先が入る',
          //   // -- 回答編集
          //   answerEdit: false,
          // }),
        },
        // - CorrectApi
        res_enqueteDisplay: (await $.api_correct.enqueteDisplay($.applyId)).data,
        res_correctionCheckDisplay: (
          await $.api_correct.correctionCheckDisplay($.applyId, CONSTANTS.CorrectionTargetType.ENQUETE)
        ).data,
        res_applyInfoDisplay: (await $.api_correct.applyInfoDisplay($.applyId)).data,
        res_rejectInfoDisplay: (await $.api_correct.rejectInfoDisplay($.applyId)).data,
        res_receiptInfoDisplay: (await $.api_correct.receiptInfoDisplay($.applyId)).data,
        res_correctionNgList: (await $.api_correct.correctionNgList($.applyId, CONSTANTS.CorrectionTargetType.ENQUETE))
          .data,
        res_answerAndNgHistoryDetail: (
          await $.api_correct.answerAndNgHistoryDetail($.applyId, CONSTANTS.CorrectionTargetType.ENQUETE)
        ).data,
        res_escalationInfoDisplay: (
          await $.api_correct.escalationInfoDisplay($.applyId, CONSTANTS.CorrectionTargetType.ENQUETE)
        ).data,
        res_correctionHistoryDisplay: (await $.api_correct.correctionHistoryDisplay($.applyId)).data,
        // - NoteApi
        res_noteDisplay: (await $.api_note.noteDisplay($.applyId)).data,
        // ノート表示デバッグ用(現状モックレスポンスでアンケ添削用にノート表示項目がないため)
        // res_noteDisplay: {
        //   noteList: (await $.api_note.noteDisplay($.applyId)).data.noteList.map((note, i) => ({
        //     ...note,
        //     displayPoint: { type: CONSTANTS.DisplayPointType.QUESTION, targetId: [20, 5, 9, 17, 25][i] },
        //   })),
        // },
        enum_noteVisibleRangeType: (await $.api_note.noteVisibleRangeTypeList()).data,
        enum_noteVisibleTargetType: (await $.api_note.noteVisibleTargetTypeList()).data,
        res_noteTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.ENQUETE_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.NOTE,
          })
        ).data,
        // - TemplateApi
        res_ngTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.ENQUETE_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.NG,
          })
        ).data,
        res_rejectTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.ENQUETE_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.REJECT_REQUEST_MESSAGE_TO_CUSTOMER,
          })
        ).data,
        res_escalationTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.ENQUETE_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.ESCALATION,
          })
        ).data,
        res_answerTemplateList: (
          await $.api_correctionTemplate.correctionTemplateList({
            correctionType: CONSTANTS.CorrectionType.ENQUETE_CORRECTION_FLG,
            operationTypeGroup: CONSTANTS.OperationTypeGroup.ESCALATION_ANSWER,
          })
        ).data,
        res_correctionStatus: (await $.api_correct.correctionStatusDisplay($.applyId)).data,
      });
    })();
  };

  // 回答保存用の初期フェッチ
  const setLoading = useLargeBindSet('isLoading');
  // -----------------------------------------------------------------------------
  useEffect(() => {
    mergeStateWithRender({
      saveAnswer: async () => {
        // アンケート保存APIの引数の設定
        const freeAnswerList: EnqueteAnswerListFormResponse[] =
          $.commentForms_enqueteText?.map((value) => ({
            answerId: value.dp.targetId ?? NaN,
            commentAnswer: value.content,
            shopReviewDisplayFlg: value.shopReviewDisplayFlg,
            answerChoiseList: [],
          })) ?? [];
        const selectionAnswerList: EnqueteAnswerListFormResponse[] =
          $.selectedForms?.map((value) => ({
            answerId: value.dp.targetId ?? NaN,
            commentAnswer: null,
            answerChoiceList: value.selectionIds,
          })) ?? [];

        const imageAnswerList = $.imageForms?.editImageIds ?? [];
        const answerList = [...freeAnswerList, ...selectionAnswerList, ...imageAnswerList];
        // 回答の編集をしていない場合はtrue
        if (answerList.length === 0) return true;
        try {
          if (
            !(await questionPreSend(
              () => ({
                applyId: $.applyId,
                commentForms_enqueteText: ($.commentForms_enqueteText ?? []).filter(
                  ({ dp: { type: displayPointType } }) => displayPointType === DisplayPointType.ANSWER
                ),
              }),
              async ({ applyId, commentForms_enqueteText }) => {
                await $.api_correctEnquete.enqueteCorrectionSaveAnswer({
                  applyId,
                  enqueteAnswerList: answerList,
                });
                // フィルタリングされた回答を再代入することで、
                // 例えば整合性が合わないケースでの問題を予防する
                mergeStateWithRender({ commentForms_enqueteText });
                getEnqueteContents();
              },
              '回答の変更を保存しますか？',
              setLoading
            ))
          ) {
            // confirmでキャンセルを選択した場合のみfalseを返す
            return false;
          }
          // 回答の修正を反映した場合trueを返す
          return true;
        } catch (error) {
          nativeAlert(error);
        }
      },
    });
  }, [$.commentForms_enqueteText, $.selectedForms, $.imageForms]);

  // FIXME: [添削] バックエンドのデバッグ用
  useEffect(() => {
    debugLogCurrentUserDataForBackend({ currentUser, isAuthChecking });
  }, [currentUser, isAuthChecking]);

  debugLog('再描画された', $, { ...CONSTANTS });

  /**
   * ページ内遷移できるようにハッシュをクリアする
   * TODO:B 動かないので一旦コメントアウト うまく動くようになったらレシート添削画面にも埋め込む
   */
  // useEffect(() => {
  //   if (window.location.hash && !largeState.state.isLoading) {
  //     const hashSave = window.location.hash
  //     window.location.hash = ""
  //     window.location.hash = hashSave
  //   }
  // },[largeState.state.isLoading]);

  return (
    <CorrectionProvider largeState={largeState}>
      <NoteModal />
      <ResultModal />
      <NGHistoryModal />
      <ReceiptInfoModal />
      <CorrectionTemplate>
        <CorrectStatusDisplay />
        <HeaderNav />
        <EnqueteContents />
      </CorrectionTemplate>
    </CorrectionProvider>
  );
});
function useBindSet(arg0: string) {
  throw new Error('Function not implemented.');
}
