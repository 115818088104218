import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row, ToggleButton } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import { createTestId, uuid } from '../../../utils/functions';
import { InputNumber } from '../../atoms/InputNumber';
import type { State as PageState } from '../../pages/ApplySearch/ExecutiveStaffApplySearchPage';

export const initialForms: State = {
  searchType: 'applyId',
  searchValue: undefined,
  targetStatus: 0, // すべて
};
export type State = PageState['forms'];

export const ExecutiveStaffApplySearchForms: React.FC<{ setForms: DispatchSetState<State> }> = ({ setForms }) => {
  const testid = createTestId(ExecutiveStaffApplySearchForms);
  const { state: $, mergeState, onChangeSet } = useLargeState<State>(initialForms);
  const options: [string, string][] = useMemo(
    () =>
      Object.entries({
        応募ID: 'applyId',
        店舗ID: 'contractShopId',
        クライアントID: 'clientId',
      }),
    []
  );

  const radios = [
    { name: '全て', value: 0 },
    { name: 'チェック待ち', value: 3 },
  ];
  const [radioValue, setRadioValue] = useState(0);

  useEffect(() => setForms($), [setForms, $]);
  return (
    <>
      <Row className="mb-3">
        <Col className="col-3">
          <Form.Group data-testid={testid('searchType')}>
            <Form.Select value={$.searchType} onChange={onChangeSet('searchType')}>
              {options.map(([label, value]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col className="col-3">
          <Form.Group data-testid={testid('searchValue')}>
            <Form.Control type="number" value={$.searchValue} onChange={onChangeSet('searchValue')} />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Form.Group data-testid={testid('targetStatus')}>
            <p>添削</p>
            {radios.map((radio, idx) => (
              <ToggleButton
                key={uuid()}
                id={`radio-${idx}`}
                type="radio"
                variant="outline-primary"
                name="targetStatus"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={(e) => {
                  mergeState({ targetStatus: Number(e.currentTarget.value) });
                  setRadioValue(Number(e.currentTarget.value));
                }}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
