import React, { useEffect } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import '../../App.css';
import Head from '../organisms/Head';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MymenuListApi, MymenuListOutputResponse } from '../../api-client';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useLargeState } from '../../hooks/useLargeState';
import { createTestId, when } from '../../utils/functions';

export interface State {
  api: MymenuListApi;
  groupList: MymenuListOutputResponse[];
}

const MyPage: React.FC = () => {
  // 認証情報取得
  const { currentUser, isAuthChecking } = useCurrentUser();
  const testid = createTestId(MyPage);
  const history = useHistory();

  const { state: $, mergeState } = useLargeState<State>({ api: new MymenuListApi(), groupList: [] });

  useEffect(() => {
    if (!currentUser?.id) return;

    $.api.mymenuList(currentUser?.id).then((res: AxiosResponse<MymenuListOutputResponse[]>) => {
      mergeState({ groupList: res.data });
    });
  }, [currentUser?.id, $.api, mergeState]);

  const handleTransition = (url: string) => {
    history.push({ pathname: url.startsWith('/') ? url : `/${url}` });
  };

  return (
    <div>
      {when(isAuthChecking, <div>ログイン情報を確認中…</div>)}
      {when(!isAuthChecking && !currentUser, <div>ログインしていません</div>)}
      {when(
        !!currentUser,
        <>
          <Head
            title="マイページテスト"
            description="マイページテスト説明文"
            keywords="aaa,bbb,ccc"
            thumbnail="http://test.com/img.png"
            url="http://test.com"
            noindex="noindex"
          />
          <div className="app mb-4">
            <div className="mt-20 block-card paper-root paper-elevation3">
              <h3 className="text-center center">{currentUser?.name}</h3>
            </div>
          </div>

          <div className="d-flex flex-wrap gap-4">
            {$.groupList.map((g) => (
              <Card key={g.menuGroupId} style={{ width: 'calc(33.333% - 1rem)' }}>
                <Card.Header className="fw-bold">{g.menuGroupName}</Card.Header>
                <ListGroup variant="flush" data-testid={testid('listGroup')}>
                  {g.menus.map(({ id, name, url }) => (
                    <ListGroup.Item action key={id} onClick={() => handleTransition(url)}>
                      {name}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default MyPage;
