import { AxiosResponse } from 'axios';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ContractShopListApi, IncResultOutputResponse } from '../../../api-client';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { Url } from '../../../constants/Url';

// FIXME: テストが通らなかったので
type ContractShopListFormResponse = any;

export const ContractShopListPage = () => {
  // レスポンスデータ
  const [data, setData] = useState<ContractShopListFormResponse[]>([]);
  // 更新結果データ
  const [updResult, setUpdResult] = useState<IncResultOutputResponse | null>(null);
  // 検索パラメータ
  const [searchParams, setSearchParms] = useState<ContractShopListFormResponse>({
    id: undefined,
    name: undefined,
    clientName: undefined,
  });

  // ページ関連データ
  const [listPerPage, setListPerPage] = useState<ContractShopListFormResponse[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const MAX_RECORD_PER_PAGE = 20;

  // 削除モーダル開閉フラグ
  const [isModal, setIsModal] = useState<boolean>(false);
  // 削除用ID格納
  const [deleteId, setDeleteId] = useState<number>();

  const history = useHistory();

  const contractShopListApi = new ContractShopListApi();

  useEffect(() => {
    contractShopListApi.contractShopList().then((res: AxiosResponse<ContractShopListFormResponse[]>) => {
      setData(res.data);
    });
    setCurrentPage(1);
    setListPerPage(data?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(data?.length) / MAX_RECORD_PER_PAGE));
  }, []);

  useEffect(() => {
    setListPerPage(data?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(data?.length) / MAX_RECORD_PER_PAGE));
  }, [data, currentPage]);

  // 検索ボタンイベント
  const handleOnSearch = async (e: any) => {
    contractShopListApi
      .contractShopList(searchParams.id, searchParams.name, searchParams.clientName)
      .then((res: AxiosResponse<ContractShopListFormResponse[]>) => {
        setData(res.data);
      });
    setCurrentPage(1);
    setListPerPage(data?.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(data?.length) / MAX_RECORD_PER_PAGE));
  };

  // 検索パラメータ検知イベント
  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case 'contractShopId':
        setSearchParms({
          ...searchParams,
          id: Number.isNaN(parseInt(event.target.value, 10)) ? undefined : parseInt(event.target.value, 10),
        });
        break;
      case 'contractShopName':
        setSearchParms({ ...searchParams, name: event.target.value === '' ? undefined : event.target.value });
        break;
      case 'clientName':
        setSearchParms({ ...searchParams, clientName: event.target.value === '' ? undefined : event.target.value });
        break;
      default:
        break;
    }
  };

  // モーダル（No）イベント
  const handleClickModalNo = (): void => {
    setIsModal(false);
    setDeleteId(undefined);
  };

  // コピーボタンイベント
  // const copyTextToClipboard = (contractShopId : string , contractShopName: string , clientName : string) => {
  //   navigator.clipboard.writeText(`${contractShopId} ${contractShopName} ${clientName}`);
  // }

  // const popover =
  // (
  //   <Tooltip id="button-tooltip" style={{ maxWidth: '25rem'}} >Copied!</Tooltip>
  // )

  const rows = listPerPage?.map((row: ContractShopListFormResponse, index: number) => {
    return (
      <tr key={index} className="align-middle">
        <td>{row.id}</td>
        <td>{row.name}</td>
        <td>{row.clientName}</td>
        <td style={{ textAlign: 'center' }}>
          <Button variant="link" className="p-0">
            <a href={`${Url.KEISAI.CONTRACT_SHOP_CONFIRM}/${row.id}`} target="_blank" rel="noreferrer">
              <FontAwesomeIcon icon={faPen} fixedWidth className="text-secondary flex-shrink-0 me-1" />
            </a>
          </Button>

          {/* <OverlayTrigger trigger="click" placement="auto" overlay={popover} rootClose  >
            <Button
              variant="link"
              className="text-secondary"
              onClick={() => {
                if(row.id !== undefined && row.name !== undefined && row.clientName !== undefined)
                  copyTextToClipboard(row.id.toString(),row?.name,row.clientName);
              }}
            >
              <FontAwesomeIcon icon={faCopy} fixedWidth className="me-1" />
            </Button>
          </OverlayTrigger> */}

          {/* <Button
            data-testid="delete"
            variant="link"
            className="text-secondary"
            onClick={() => {
              setIsModal(true);
              setDeleteId(row?.id);
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} fixedWidth className="me-1" />
          </Button> */}
        </td>
      </tr>
    );
  });

  return (
    <>
      <Row>
        <Col>
          <h3 style={{ marginBottom: '20px' }}>契約店舗一覧</h3>
          {updResult !== null && updResult?.result === false && (
            <Alert
              variant="danger"
              style={{ marginTop: 10 }}
            >{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
          )}
          <Card>
            <div className="d-flex pe-3 ps-3 pt-4 pb-4" style={{ width: '100%', height: '100%' }}>
              <Row style={{ width: '100%' }}>
                <Col className="col-2" style={{ width: '30%' }}>
                  <div className="form-floating" style={{ marginLeft: 8 }}>
                    <input
                      type="number"
                      className="form-control"
                      id="contractShopId"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      data-testid="contractShopId"
                    />
                    <label>契約店舗ID</label>
                  </div>
                </Col>
                <Col className="col-2" style={{ width: '30%' }}>
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="contractShopName"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      data-testid="contractShopName"
                    />
                    <label htmlFor="contractShopName">契約店舗名</label>
                  </div>
                </Col>
                <Col className="col-2" style={{ width: '30%' }}>
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="clientName"
                      placeholder="入力してください。"
                      onChange={onChangeText}
                      data-testid="clientName"
                    />
                    <label htmlFor="clientName">クライアント名</label>
                  </div>
                </Col>
                <Col className="pe-0 me-0" style={{ textAlign: 'center' }}>
                  <p style={{ width: '100%', height: '100%' }}>
                    <Button onClick={handleOnSearch} type="button" style={{ width: '100%', height: '100%' }}>
                      検索
                    </Button>
                  </p>
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
      </Row>
      <div style={{ paddingTop: '24px' }}>
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(data.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />
      </div>
      <>
        <Table className="mt-3" style={{ tableLayout: 'auto', width: '100%' }}>
          <thead>
            <tr className="align-middle">
              <th data-testid="contractShopIdHead" style={{ width: '10%' }}>
                契約店舗ID
              </th>
              <th data-testid="contractShopNameHead">契約店舗名</th>
              <th data-testid="clientNameHead">クライアント名</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </>
    </>
  );
};
