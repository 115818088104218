import { faPlus, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import type { DisplayPointOutputResponse, NoteListOutputResponse } from '../../../../../api-client';
import { DATETIME_DISPLAY_FORMAT } from '../../../../../Constants';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import scss from '../../../../../scss/templates/CorrectionTemplate.module.scss';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { debugLog, formatISODate, uuid, when } from '../../../../../utils/functions';
import { Button } from '../../../../atoms/Button';
import { EditDelete } from '../../../../atoms/EditDelete';
import { TemplateFormMode } from '../../../../pages/Correction/constants';
import { dpToData, nativeAlert, nativeConfirm } from '../../../../pages/Correction/utils';

// TODO: [添削] テスト未実装
/** ノート */
export const NoteList: React.FC<{
  /** 表示と送信に使用するDisplayPoint */
  displayAndSendPoint?: Partial<DisplayPointOutputResponse>;
}> = ({ displayAndSendPoint: dasp }) => {
  const {
    largeState: { state: $, mergeState },
  } = useSafeContext(CorrectionContext);
  const openModal = useCallback(
    (note: Partial<NoteListOutputResponse>) => {
      debugLog({ note });
      mergeState({
        isNoteModal: true,
        templateFormMode: TemplateFormMode.NOTE,
        form_editNote: note,
      });
    },
    [mergeState]
  );

  const notes = useMemo(() => dpToData(dasp).toNote($.res_noteDisplay?.noteList), [dasp, $.res_noteDisplay?.noteList]);
  const isEnableAdd = useMemo(
    () => $.res_correctReceiptDisplayControl?.noteUpdate || $.res_correctEnqueteDisplayControl?.noteUpdate,
    [$.res_correctReceiptDisplayControl?.noteUpdate, $.res_correctEnqueteDisplayControl?.noteUpdate]
  );
  const isEnable = useMemo(() => !!notes?.length || !!isEnableAdd, [notes, isEnableAdd]);
  const getImportantCss = (importantFlg: boolean) => {
    if (!importantFlg) return '';
    return 'fw-bold text-danger';
    // FIXME [添削] 案6への変更用。不要になったら消す。（リリースまで残ってたら消す）
    // return 'fw-bold bg-warning';
  };
  return (
    <>
      {isEnable ? (
        <Card>
          <div className={`${scss.check_point_card_header} ${scss.card_header_gray}`}>
            <div>ノート</div>
            <div>
              {when(
                !!isEnableAdd,
                <Button
                  variant="link"
                  onClick={() => openModal({ displayPoint: dasp as NoteListOutputResponse['displayPoint'] })}
                >
                  <FontAwesomeIcon icon={faPlus} /> ノート追加
                </Button>
              )}
            </div>
          </div>
          {(notes ?? []).map((note) => (
            <Card key={uuid()} className={getImportantCss(note.importantFlg)}>
              <Card.Body style={{ marginRight: 75 }}>
                {/* TODO: [添削] ノートの画像系は今回実装しない */}
                {/* <SideBy>
                  {note.noteAttachedImageList.map(({ filePath }) => (
                    <ImageThumbnail key={uuid()} url={filePath} />
                  ))}
                </SideBy> */}
                {when(
                  note.importantFlg,
                  <p>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    管理者からの重要なノート
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </p>
                )}
                <pre>
                  {(note.content ?? '').split(/\n/).map((line, i, { length }) => (
                    <span key={uuid()}>
                      {line}
                      {i !== length - 1 ? <br /> : <></>}
                    </span>
                  ))}
                  <p
                    className="text-secondary mb-0"
                    style={{ fontWeight: 'normal', marginTop: '10px' }}
                  >{`${formatISODate(note.updateAt, DATETIME_DISPLAY_FORMAT)} ${note.updateBy}`}</p>
                </pre>
              </Card.Body>
              <EditDelete
                onEdit={() => openModal(note)}
                onDelete={async () => {
                  if (nativeConfirm('削除してもよろしいですか？また、削除すると他の入力中の項目はリセットされます。')) {
                    try {
                      await $.api_note.noteDelete(note.noteId);
                      window.location.reload();
                    } catch (error) {
                      console.error(error);
                      nativeAlert(error);
                    }
                  }
                }}
              />
            </Card>
          ))}
        </Card>
      ) : (
        <Card>
          <div className={`${scss.check_point_card_header} ${scss.card_header_gray}`}>
            <div>ノートなし</div>
          </div>
        </Card>
      )}
    </>
  );
};
