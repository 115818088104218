import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { CampaignApi, CampaignListOutputResponse, IncResultOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Alert } from '../../atoms/Alert';
import { Button } from '../../atoms/Button';
import { SearchFrom } from '../../molecules/Form/SearchFrom';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { CampaignTable } from '../../organisms/Table/CampaignTable';

const sampleSearchList: CampaignListOutputResponse[] = [
  {
    id: 1,
    imageUrl: 'http://fancrew.jp/api/3.0/image/brand/0000/4320/2117960-1641275727657.jpg',
    name: 'キャンペーン名',
    publicEndDate: '2022-12-25',
    publicStartDate: '2022-02-03',
    shopShowType: 'クローズド',
    showType: '表示',
    oemShowFlg: true,
    richShowFlg: false,
    shopCount: 1,
  },
  {
    id: 2,
    imageUrl: 'http://fancrew.jp/api/3.0/image/brand/0000/4320/2117960-1641275727657.jpg',
    name: 'キャンペーン名',
    publicEndDate: '2022-12-25',
    publicStartDate: '2022-02-03',
    shopShowType: 'クローズド',
    showType: '非表示',
    oemShowFlg: false,
    richShowFlg: true,
    shopCount: 1,
  },
  {
    id: 3,
    imageUrl: 'http://fancrew.jp/api/3.0/image/brand/0000/4320/2117960-1641275727657.jpg',
    name: 'キャンペーン名',
    publicEndDate: '2022-12-25',
    publicStartDate: '2022-02-03',
    shopShowType: 'クローズド',
    showType: 'クローズド',
    oemShowFlg: true,
    richShowFlg: true,
    shopCount: 1,
  },
];

const sampleGetList = [...Array(101)].map((_, i) => {
  return {
    id: i + 1,
    imageUrl: 'http://fancrew.jp/api/3.0/image/brand/0000/4320/2117960-1641275727657.jpg',
    name: 'キャンペーン名',
    publicEndDate: '2022-12-25',
    publicStartDate: '2022-02-03',
    shopCount: 20,
    shopShowType: 'クローズド',
    showType: '表示',
    oemShowFlg: true,
    richShowFlg: false,
    monitorBaseCount: 1,
  };
});

const MAX_RECORD_PER_PAGE = 20;

export const CampaignPage: React.VFC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [list, setList] = useState<CampaignListOutputResponse[]>([]);
  const [listPerPage, setListPerPage] = useState<CampaignListOutputResponse[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [updResult, setUpdResult] = useState<IncResultOutputResponse | null>(null);

  const history = useHistory();
  const campaignApi = new CampaignApi();

  useEffect(() => {
    campaignApi.campaignList().then((res: AxiosResponse<CampaignListOutputResponse[]>) => {
      const sortedData = res.data.sort((a, b) => b.id - a.id);
      setList(sortedData);
      setListPerPage(sortedData.slice(0, MAX_RECORD_PER_PAGE));
      setTotalPage(Math.ceil(Number(sortedData?.length) / MAX_RECORD_PER_PAGE));
    });
    if (history?.action) history.action = 'POP';
  }, [history?.action]);

  useEffect(() => {
    setListPerPage(list.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(list?.length) / MAX_RECORD_PER_PAGE));
  }, [list, currentPage]);

  const handleClickSearchBtn = () => {
    campaignApi.campaignList(searchText).then((res: AxiosResponse<CampaignListOutputResponse[]>) => {
      setList(res.data.sort((a, b) => b.id - a.id));
      setCurrentPage(1);
    });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.CAMPAIGN}</Title>
      {updResult?.errorCode && updResult?.errorMessage && (
        <Alert variant="danger">{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}

      <SearchFrom
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        onClick={handleClickSearchBtn}
      />
      <div className="d-flex justify-content-end mb-4">
        <Button
          variant="link"
          className="text-secondary"
          onClick={() => {
            history.push({
              pathname: Url.KEISAI.CAMPAIGN_MODIFY,
              state: { type: 'modify' },
            });
          }}
        >
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          追加
        </Button>
      </div>
      <CampaignTable list={list} setList={setList} listPerPage={listPerPage} setUpdResult={setUpdResult} />
      <div className="mt-4">
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />
      </div>
    </>
  );
};
