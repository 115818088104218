import { AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { useForm, SubmitHandler, useController } from 'react-hook-form';
import {
  BillingApi,
  BillingDestinationCopyFormResponse,
  BillingDestinationListOutputResponse,
  IncResultOutputResponse,
} from '../../../api-client';
import { BillingDestinationTable } from '../../organisms/Table/BillingDestinationTable';
import { BILLING_DEFAULT_FADE_TIME } from '../../../BillingConstants';
import { BillingUpdateResultModal } from '../../organisms/Modal/BillingUpdateResultModal';
import useBillingSuggestClient from '../../../hooks/useBillingSuggestClient';
import useBillingSuggestIncAccount from '../../../hooks/useBillingSuggestIncAccount';
import useBillingSuggestContractShop from '../../../hooks/useBillingSuggestContractShop';
import useBillingSuggestContract from '../../../hooks/useBillingSuggestContract';

type SearchParams = {
  clientId: number | undefined;
  incAccountId: number | undefined;
  billingDestinationName: string | undefined;
  contractId: number | undefined;
  contractShopId: number | undefined;
};

export const BillingDestinationListPage: React.VFC = () => {
  const [list, setList] = useState<BillingDestinationListOutputResponse[]>([]);
  const searchParam: SearchParams = {
    clientId: undefined,
    incAccountId: undefined,
    billingDestinationName: undefined,
    contractId: undefined,
    contractShopId: undefined,
  };
  const [updResult, setUpdResult] = useState<IncResultOutputResponse>();
  const billingSuggestClientResult = useBillingSuggestClient();
  const billingSuggestIncAccountResult = useBillingSuggestIncAccount();
  const billingSuggestContractShopResult = useBillingSuggestContractShop();
  const billingSuggestContractResult = useBillingSuggestContract();

  const billingApi = new BillingApi();

  /**
   * コピーボタン実行
   */
  const handleCopyExec = () => {
    // コピー処理の実行
    handleCopyFetch();
    // その後検索を行い画面をリセット
    fetchData();
  };
  const handleCopyFetch = () => {
    let updateList: BillingDestinationCopyFormResponse[] = [];
    list.map((v) => {
      if (v.copyCount > 0) {
        updateList = [
          ...updateList,
          {
            billingDestinationId: v.id,
            copyCount: v.copyCount,
          } as BillingDestinationCopyFormResponse,
        ];
      }
    });
    billingApi.billingDestinationCopy(updateList).then((res) => {
      setUpdResult(res.data);
    });
  };

  const handleClickCopy = (e: any, billingDestinationId: number) => {
    e.preventDefault();
    const updated = list.map((v) => {
      if (v.id === billingDestinationId) {
        return { ...v, copyCount: v.copyCount + 1 };
      }
      return v;
    });
    setList(updated);
  };

  const fetchData = () => {
    billingApi
      .billingDestinationList(
        searchParam.clientId,
        searchParam.incAccountId,
        searchParam.billingDestinationName,
        searchParam.contractId,
        searchParam.contractShopId
      )
      .then((res: AxiosResponse<BillingDestinationListOutputResponse[]>) => {
        setList(res.data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<SearchParams>();
  const onSubmit: SubmitHandler<SearchParams> = (data) => {
    billingApi
      .billingDestinationList(
        data.clientId,
        data.incAccountId,
        data.billingDestinationName,
        data.contractId,
        data.contractShopId
      )
      .then((res: AxiosResponse<BillingDestinationListOutputResponse[]>) => {
        setList(res.data);
      });
  };

  const {
    field: { value: clientIdValue, onChange: clientIdOnChange, ...restClientIdField },
  } = useController({ name: 'clientId', control });
  const {
    field: { value: incAccountIdValue, onChange: incAccountIdOnChange, ...restIncAccountIdField },
  } = useController({ name: 'incAccountId', control });
  const {
    field: { value: contractIdValue, onChange: contractIdOnChange, ...restContractIdField },
  } = useController({ name: 'contractId', control });
  const {
    field: { value: contractShopIdValue, onChange: contractShopIdOnChange, ...restContractShopIdField },
  } = useController({ name: 'contractShopId', control });

  return (
    <>
      {updResult !== undefined && (
        <BillingUpdateResultModal
          fadeoutTime={BILLING_DEFAULT_FADE_TIME}
          updResult={updResult}
          setUpdResult={setUpdResult}
        />
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-2 mb-4">
          <Col className="col-6">
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label htmlFor="clientId" className="mt-2 me-2">
                    クライアント
                  </Form.Label>
                </Col>
                <Col className="col-8">
                  {billingSuggestClientResult.isSuccess && (
                    <Select
                      id="clientId"
                      isSearchable
                      isClearable
                      options={billingSuggestClientResult.data}
                      onChange={(e) => clientIdOnChange(e?.value)}
                      {...restClientIdField}
                    />
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label htmlFor="incAccountId" className="mt-2 me-2">
                    営業担当者
                  </Form.Label>
                </Col>
                <Col className="col-8">
                  {billingSuggestIncAccountResult.isSuccess && (
                    <Select
                      id="incAccountId"
                      isSearchable
                      isClearable
                      options={billingSuggestIncAccountResult.data}
                      onChange={(e) => incAccountIdOnChange(e?.value)}
                      {...restIncAccountIdField}
                    />
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-2 mb-4">
          <Col className="col-6" />
          <Col className="col-6">
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label htmlFor="billingDestinationName" className="mt-2 me-2">
                    案件名
                  </Form.Label>
                </Col>
                <Col className="col-8">
                  <Form.Control type="text" id="billingDestinationName" {...register('billingDestinationName')} />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="g-2 mb-4">
          <Col className="col-6">
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label htmlFor="contractId" className="mt-2 me-2">
                    契約
                  </Form.Label>
                </Col>
                <Col className="col-8">
                  {billingSuggestContractResult.isSuccess && (
                    <Select
                      id="contractId"
                      isSearchable
                      isClearable
                      options={billingSuggestContractResult.data}
                      onChange={(e) => contractIdOnChange(e?.value)}
                      {...restContractIdField}
                    />
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group>
              <Row>
                <Col className="col-4">
                  <Form.Label htmlFor="contractShopId" className="mt-2 me-2">
                    契約店舗
                  </Form.Label>
                </Col>
                <Col className="col-8">
                  {billingSuggestContractShopResult.isSuccess && (
                    <Select
                      id="contractShopId"
                      isSearchable
                      isClearable
                      options={billingSuggestContractShopResult.data}
                      onChange={(e) => contractShopIdOnChange(e?.value)}
                      {...restContractShopIdField}
                    />
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <div className="d-flex justify-content-center position-relative">
            <Button type="submit" variant="secondary">
              検索
            </Button>
            <Button
              type="button"
              variant="secondary"
              className="position-absolute"
              style={{ right: '1rem' }}
              onClick={(e) => handleCopyExec()}
            >
              コピーを反映
            </Button>
          </div>
        </Row>
      </Form>

      <BillingDestinationTable list={list} onClickCopy={handleClickCopy} />
    </>
  );
};
