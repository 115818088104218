/* tslint:disable */
/* eslint-disable */
/**
 * Fancrew Inc向けサービス
 * Kotlin + Spring Boot
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApprovedAmountModifyHistoryInfoOutputResponse } from '../domains';
// @ts-ignore
import { GrantPointConfirmFormResponse } from '../domains';
// @ts-ignore
import { GrantPointConfirmOutputResponse } from '../domains';
// @ts-ignore
import { GrantPointCreateFormResponse } from '../domains';
// @ts-ignore
import { GrantPointCreateOutputResponse } from '../domains';
// @ts-ignore
import { GrantPointFileUploadConfirmFormResponse } from '../domains';
// @ts-ignore
import { GrantPointFileUploadConfirmOutputResponse } from '../domains';
// @ts-ignore
import { IncResultOutputResponse } from '../domains';
// @ts-ignore
import { PointDecreaseFormResponse } from '../domains';
// @ts-ignore
import { PointHistoryInfoOutputResponse } from '../domains';
// @ts-ignore
import { PointHistoryListFormResponse } from '../domains';
// @ts-ignore
import { PointHistoryListOutputResponse } from '../domains';
// @ts-ignore
import { PointTypeListOutputResponse } from '../domains';
// @ts-ignore
import { ResultOutputResponse } from '../domains';
/**
 * PointApi - axios parameter creator
 * @export
 */
export const PointApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 承認済の金額を修正した履歴の表示
         * @summary 承認金額修正履歴
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvedAmountModifyHistoryInfo: async (applyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applyId' is not null or undefined
            assertParamExists('approvedAmountModifyHistoryInfo', 'applyId', applyId)
            const localVarPath = `/api/receipt-price/modify/{apply-id}`
                .replace(`{${"apply-id"}}`, encodeURIComponent(String(applyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイント付与確認画面を表示する。Formを使用するためPostメソッドに変更
         * @summary ポイント付与確認画面表示
         * @param {GrantPointConfirmFormResponse} grantPointConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPointConfirm: async (grantPointConfirmFormResponse: GrantPointConfirmFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantPointConfirmFormResponse' is not null or undefined
            assertParamExists('grantPointConfirm', 'grantPointConfirmFormResponse', grantPointConfirmFormResponse)
            const localVarPath = `/api/point/confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grantPointConfirmFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイント付与を実行する
         * @summary ポイント付与実行
         * @param {Array<GrantPointCreateFormResponse>} grantPointCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPointCreate: async (grantPointCreateFormResponse: Array<GrantPointCreateFormResponse>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantPointCreateFormResponse' is not null or undefined
            assertParamExists('grantPointCreate', 'grantPointCreateFormResponse', grantPointCreateFormResponse)
            const localVarPath = `/api/point/grant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grantPointCreateFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ポイント一括付与のテンプレートファイルをダウンロードする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPointFileTemplateDlController: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/point/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイント付与ファイルのアップロードによって複数会員の付与を行う
         * @summary ポイント付与ファイルアップロード
         * @param {GrantPointFileUploadConfirmFormResponse} grantPointFileUploadConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPointFileUploadConfirm: async (grantPointFileUploadConfirmFormResponse: GrantPointFileUploadConfirmFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grantPointFileUploadConfirmFormResponse' is not null or undefined
            assertParamExists('grantPointFileUploadConfirm', 'grantPointFileUploadConfirmFormResponse', grantPointFileUploadConfirmFormResponse)
            const localVarPath = `/api/point/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grantPointFileUploadConfirmFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイントの減算を行う
         * @summary ポイント減算ツール
         * @param {PointDecreaseFormResponse} pointDecreaseFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointDecrease: async (pointDecreaseFormResponse: PointDecreaseFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointDecreaseFormResponse' is not null or undefined
            assertParamExists('pointDecrease', 'pointDecreaseFormResponse', pointDecreaseFormResponse)
            const localVarPath = `/api/point/decrease`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointDecreaseFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイント詳細を表示する
         * @summary ポイント詳細
         * @param {number} pointTypeCode 
         * @param {number} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointHistoryInfo: async (pointTypeCode: number, pointId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointTypeCode' is not null or undefined
            assertParamExists('pointHistoryInfo', 'pointTypeCode', pointTypeCode)
            // verify required parameter 'pointId' is not null or undefined
            assertParamExists('pointHistoryInfo', 'pointId', pointId)
            const localVarPath = `/api/point/{point-type-code}/{point-id}`
                .replace(`{${"point-type-code"}}`, encodeURIComponent(String(pointTypeCode)))
                .replace(`{${"point-id"}}`, encodeURIComponent(String(pointId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイント履歴を検索込みで画面に表示する。Formを使用するためPostメソッドに変更
         * @summary ポイント履歴画面表示
         * @param {number} customerId 
         * @param {PointHistoryListFormResponse} pointHistoryListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointHistoryList: async (customerId: number, pointHistoryListFormResponse: PointHistoryListFormResponse, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('pointHistoryList', 'customerId', customerId)
            // verify required parameter 'pointHistoryListFormResponse' is not null or undefined
            assertParamExists('pointHistoryList', 'pointHistoryListFormResponse', pointHistoryListFormResponse)
            const localVarPath = `/api/point/list/{customer-id}`
                .replace(`{${"customer-id"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pointHistoryListFormResponse, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ポイントタイプ一覧を表示する
         * @summary ポイントタイプ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointTypeList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/point-type/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointApi - functional programming interface
 * @export
 */
export const PointApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointApiAxiosParamCreator(configuration)
    return {
        /**
         * 承認済の金額を修正した履歴の表示
         * @summary 承認金額修正履歴
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvedAmountModifyHistoryInfo(applyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovedAmountModifyHistoryInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvedAmountModifyHistoryInfo(applyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ポイント付与確認画面を表示する。Formを使用するためPostメソッドに変更
         * @summary ポイント付与確認画面表示
         * @param {GrantPointConfirmFormResponse} grantPointConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantPointConfirm(grantPointConfirmFormResponse: GrantPointConfirmFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GrantPointConfirmOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantPointConfirm(grantPointConfirmFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ポイント付与を実行する
         * @summary ポイント付与実行
         * @param {Array<GrantPointCreateFormResponse>} grantPointCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantPointCreate(grantPointCreateFormResponse: Array<GrantPointCreateFormResponse>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GrantPointCreateOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantPointCreate(grantPointCreateFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ポイント一括付与のテンプレートファイルをダウンロードする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantPointFileTemplateDlController(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantPointFileTemplateDlController(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ポイント付与ファイルのアップロードによって複数会員の付与を行う
         * @summary ポイント付与ファイルアップロード
         * @param {GrantPointFileUploadConfirmFormResponse} grantPointFileUploadConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantPointFileUploadConfirm(grantPointFileUploadConfirmFormResponse: GrantPointFileUploadConfirmFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GrantPointFileUploadConfirmOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantPointFileUploadConfirm(grantPointFileUploadConfirmFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ポイントの減算を行う
         * @summary ポイント減算ツール
         * @param {PointDecreaseFormResponse} pointDecreaseFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointDecrease(pointDecreaseFormResponse: PointDecreaseFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IncResultOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointDecrease(pointDecreaseFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ポイント詳細を表示する
         * @summary ポイント詳細
         * @param {number} pointTypeCode 
         * @param {number} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointHistoryInfo(pointTypeCode: number, pointId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointHistoryInfoOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointHistoryInfo(pointTypeCode, pointId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ポイント履歴を検索込みで画面に表示する。Formを使用するためPostメソッドに変更
         * @summary ポイント履歴画面表示
         * @param {number} customerId 
         * @param {PointHistoryListFormResponse} pointHistoryListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointHistoryList(customerId: number, pointHistoryListFormResponse: PointHistoryListFormResponse, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PointHistoryListOutputResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointHistoryList(customerId, pointHistoryListFormResponse, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ポイントタイプ一覧を表示する
         * @summary ポイントタイプ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pointTypeList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PointTypeListOutputResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pointTypeList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointApi - factory interface
 * @export
 */
export const PointApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointApiFp(configuration)
    return {
        /**
         * 承認済の金額を修正した履歴の表示
         * @summary 承認金額修正履歴
         * @param {number} applyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvedAmountModifyHistoryInfo(applyId: number, options?: any): AxiosPromise<ApprovedAmountModifyHistoryInfoOutputResponse> {
            return localVarFp.approvedAmountModifyHistoryInfo(applyId, options).then((request) => request(axios, basePath));
        },
        /**
         * ポイント付与確認画面を表示する。Formを使用するためPostメソッドに変更
         * @summary ポイント付与確認画面表示
         * @param {GrantPointConfirmFormResponse} grantPointConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPointConfirm(grantPointConfirmFormResponse: GrantPointConfirmFormResponse, options?: any): AxiosPromise<Array<GrantPointConfirmOutputResponse>> {
            return localVarFp.grantPointConfirm(grantPointConfirmFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ポイント付与を実行する
         * @summary ポイント付与実行
         * @param {Array<GrantPointCreateFormResponse>} grantPointCreateFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPointCreate(grantPointCreateFormResponse: Array<GrantPointCreateFormResponse>, options?: any): AxiosPromise<GrantPointCreateOutputResponse> {
            return localVarFp.grantPointCreate(grantPointCreateFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ポイント一括付与のテンプレートファイルをダウンロードする
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPointFileTemplateDlController(options?: any): AxiosPromise<string> {
            return localVarFp.grantPointFileTemplateDlController(options).then((request) => request(axios, basePath));
        },
        /**
         * ポイント付与ファイルのアップロードによって複数会員の付与を行う
         * @summary ポイント付与ファイルアップロード
         * @param {GrantPointFileUploadConfirmFormResponse} grantPointFileUploadConfirmFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantPointFileUploadConfirm(grantPointFileUploadConfirmFormResponse: GrantPointFileUploadConfirmFormResponse, options?: any): AxiosPromise<Array<GrantPointFileUploadConfirmOutputResponse>> {
            return localVarFp.grantPointFileUploadConfirm(grantPointFileUploadConfirmFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ポイントの減算を行う
         * @summary ポイント減算ツール
         * @param {PointDecreaseFormResponse} pointDecreaseFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointDecrease(pointDecreaseFormResponse: PointDecreaseFormResponse, options?: any): AxiosPromise<IncResultOutputResponse> {
            return localVarFp.pointDecrease(pointDecreaseFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ポイント詳細を表示する
         * @summary ポイント詳細
         * @param {number} pointTypeCode 
         * @param {number} pointId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointHistoryInfo(pointTypeCode: number, pointId: number, options?: any): AxiosPromise<PointHistoryInfoOutputResponse> {
            return localVarFp.pointHistoryInfo(pointTypeCode, pointId, options).then((request) => request(axios, basePath));
        },
        /**
         * ポイント履歴を検索込みで画面に表示する。Formを使用するためPostメソッドに変更
         * @summary ポイント履歴画面表示
         * @param {number} customerId 
         * @param {PointHistoryListFormResponse} pointHistoryListFormResponse 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointHistoryList(customerId: number, pointHistoryListFormResponse: PointHistoryListFormResponse, options?: any): AxiosPromise<PointHistoryListOutputResponse> {
            return localVarFp.pointHistoryList(customerId, pointHistoryListFormResponse, options).then((request) => request(axios, basePath));
        },
        /**
         * ポイントタイプ一覧を表示する
         * @summary ポイントタイプ一覧
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pointTypeList(options?: any): AxiosPromise<Array<PointTypeListOutputResponse>> {
            return localVarFp.pointTypeList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointApi - object-oriented interface
 * @export
 * @class PointApi
 * @extends {BaseAPI}
 */
export class PointApi extends BaseAPI {
    /**
     * 承認済の金額を修正した履歴の表示
     * @summary 承認金額修正履歴
     * @param {number} applyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public approvedAmountModifyHistoryInfo(applyId: number, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).approvedAmountModifyHistoryInfo(applyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイント付与確認画面を表示する。Formを使用するためPostメソッドに変更
     * @summary ポイント付与確認画面表示
     * @param {GrantPointConfirmFormResponse} grantPointConfirmFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public grantPointConfirm(grantPointConfirmFormResponse: GrantPointConfirmFormResponse, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).grantPointConfirm(grantPointConfirmFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイント付与を実行する
     * @summary ポイント付与実行
     * @param {Array<GrantPointCreateFormResponse>} grantPointCreateFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public grantPointCreate(grantPointCreateFormResponse: Array<GrantPointCreateFormResponse>, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).grantPointCreate(grantPointCreateFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ポイント一括付与のテンプレートファイルをダウンロードする
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public grantPointFileTemplateDlController(options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).grantPointFileTemplateDlController(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイント付与ファイルのアップロードによって複数会員の付与を行う
     * @summary ポイント付与ファイルアップロード
     * @param {GrantPointFileUploadConfirmFormResponse} grantPointFileUploadConfirmFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public grantPointFileUploadConfirm(grantPointFileUploadConfirmFormResponse: GrantPointFileUploadConfirmFormResponse, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).grantPointFileUploadConfirm(grantPointFileUploadConfirmFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイントの減算を行う
     * @summary ポイント減算ツール
     * @param {PointDecreaseFormResponse} pointDecreaseFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointDecrease(pointDecreaseFormResponse: PointDecreaseFormResponse, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).pointDecrease(pointDecreaseFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイント詳細を表示する
     * @summary ポイント詳細
     * @param {number} pointTypeCode 
     * @param {number} pointId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointHistoryInfo(pointTypeCode: number, pointId: number, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).pointHistoryInfo(pointTypeCode, pointId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイント履歴を検索込みで画面に表示する。Formを使用するためPostメソッドに変更
     * @summary ポイント履歴画面表示
     * @param {number} customerId 
     * @param {PointHistoryListFormResponse} pointHistoryListFormResponse 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointHistoryList(customerId: number, pointHistoryListFormResponse: PointHistoryListFormResponse, options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).pointHistoryList(customerId, pointHistoryListFormResponse, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ポイントタイプ一覧を表示する
     * @summary ポイントタイプ一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointApi
     */
    public pointTypeList(options?: AxiosRequestConfig) {
        return PointApiFp(this.configuration).pointTypeList(options).then((request) => request(this.axios, this.basePath));
    }
}
