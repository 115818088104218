import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useLargeState } from '../../../hooks/useLargeState';
import { DispatchSetState } from '../../../interfaces/utils';
import type { CorrectPhoneMasterState } from '../../pages/Correction/CorrectPhoneMasterPage';

export type State = CorrectPhoneMasterState['forms'];
interface Props {
  setForms: DispatchSetState<State>;
}

export const CorrectPhoneMasterForms: React.FC<Props> = ({ setForms }) => {
  const { state: $, onChangeSet } = useLargeState<State>({});

  useEffect(() => {
    setForms({ ...$ });
  }, [setForms, $]);

  return (
    <>
      <Form.Group controlId="chainName" key="chainName" className="col-md-3">
        <Form.Label>チェーン</Form.Label>
        <Form.Control type="text" autoComplete="off" value={$.chainName} onChange={onChangeSet('chainName', String)} />
      </Form.Group>
      <Form.Group controlId="storeName" key="storeName" className="col-md-3">
        <Form.Label>ストア</Form.Label>
        <Form.Control type="text" autoComplete="off" value={$.storeName} onChange={onChangeSet('storeName', String)} />
      </Form.Group>
      <Form.Group controlId="phoneNumber" key="phoneNumber" className="col-md-4">
        <Form.Label>電話番号</Form.Label>
        <Form.Control
          type="number"
          autoComplete="off"
          value={$.phoneNumber !== undefined && $.phoneNumber !== null ? $.phoneNumber : ''}
          onChange={onChangeSet('phoneNumber', String)}
        />
      </Form.Group>
    </>
  );
};
