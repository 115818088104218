import * as React from 'react';
import { Button } from 'react-bootstrap';

interface IProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  text: string;
}

export const LinkButton: React.FC<IProps> = ({ text, onClick }) => {
  // const { onClick, text } = props;
  return (
    <Button variant="link" onClick={onClick}>
      {text}
    </Button>
  );
};
