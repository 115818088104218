import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ApiClientApi, ApiClientListOutputResponse } from '../../../api-client';

export const ApiClientListPage = () => {
  const [data, setData] = useState<ApiClientListOutputResponse[]>([]);
  const getApi = new ApiClientApi();
  const history = useHistory();
  useEffect(() => {
    getApi.apiClientList().then((res: AxiosResponse<ApiClientListOutputResponse[]>) => {
      // 表示用テストデータ作成
      // const testData : ApiClientListOutputResponse =
      // {
      //   id: 2,
      //   name: "Test会社",
      //   serviceName:"TestService",
      //   accessKey:"11111111111",
      //   disableFlg:false
      // }
      // const testData2 : ApiClientListOutputResponse =
      // {
      //   id: 3,
      //   name: "Test会社2",
      //   serviceName:"TestService",
      //   accessKey:"22222222222",
      //   disableFlg:true
      // }

      // res.data.push(testData);
      // res.data.push(testData2);
      setData(res.data);
    });
  }, []);

  const rows =
    data.length !== 0
      ? data.map((row, index) => {
          return (
            <tr key={index}>
              <td>{row.id}</td>
              <td>{row.name}</td>
              <td>{row.serviceName}</td>
              <td>{row.accessKey}</td>
              <td>{row.disableFlg === undefined || row.disableFlg === false ? '-' : '〇'}</td>
              <td>
                <Button
                  variant="link"
                  data-testid={`linkButton${row.id}`}
                  onClick={() => {
                    history.push(`./api-client-modify/id/${row.id}`);
                  }}
                  className="d-block text-start"
                >
                  編集
                </Button>
              </td>
            </tr>
          );
        })
      : '';

  return (
    <>
      <Row>
        <Col>
          <h3>APIクライアント一覧</h3>
          <Card>
            <Card.Body>
              <Form>
                <Table>
                  <thead>
                    <tr className="align-middle">
                      <th>ID</th>
                      <th>会社名</th>
                      <th>サービス名</th>
                      <th>key</th>
                      <th>ファンくるAPI</th>
                      <th>編集</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
