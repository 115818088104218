import React from 'react';
import { useSafeContext } from '../../../../../hooks/useSafeContext';
import { CorrectionContext } from '../../../../../store/correctionStore';
import { HistoryNgList } from '../../../../molecules/HistoryNgList';

export const CustomerRemarksSection: React.FC = () => {
  const {
    largeState: { state: $ },
  } = useSafeContext(CorrectionContext);

  const details = $.res_correctionCheckDisplay?.customerInputRemarksList ?? [];

  return <HistoryNgList label={null} details={details} />;
};
