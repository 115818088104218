import * as React from 'react';
import { Table } from 'react-bootstrap';
import { FieldArrayWithId, UseFormRegister } from 'react-hook-form';
import type { BannerCommonListOutputResponse } from '../../../api-client';
import { FormControl } from '../../molecules/Form/FormControl';

export interface Props {
  fields: FieldArrayWithId<BannerCommonListOutputResponse, 'list', 'id'>[];
  register: UseFormRegister<BannerCommonListOutputResponse>;
}

export const BannerCommonTable: React.VFC<Props> = ({ fields, register }) => {
  return (
    <Table className="mb-4">
      <thead>
        <tr>
          <th>バナー広告</th>
          <th className="col-2">表示件数</th>
          <th className="col-2">制限文字数</th>
          <th>PC用パラメータ</th>
          <th>SP用パラメータ</th>
        </tr>
      </thead>
      <tbody>
        {fields.map(({ id, title }, idx) => {
          return (
            <tr key={id} className="align-middle">
              <td>{title}</td>
              <td>
                <FormControl type="number" className="mb-0" register={register} registerName={`list.${idx}.viewNum`} />
              </td>
              <td>
                <FormControl
                  type="number"
                  className="mb-0"
                  register={register}
                  registerName={`list.${idx}.limitLength`}
                />
              </td>
              <td>
                <FormControl type="text" className="mb-0" register={register} registerName={`list.${idx}.paramForPC`} />
              </td>
              <td>
                <FormControl type="text" className="mb-0" register={register} registerName={`list.${idx}.paramForSP`} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
