import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { BrandApi, BrandListOutputResponse, IncResultOutputResponse } from '../../../api-client';
import { Url } from '../../../constants/Url';
import { TITLE } from '../../../constants/Title';
import { Title } from '../../atoms/Title';
import { Alert } from '../../atoms/Alert';
import { Button } from '../../atoms/Button';
import { SearchFrom } from '../../molecules/Form/SearchFrom';
import { PaginationWithEllipsis } from '../../molecules/PaginationWithEllipsis';
import { BrandTable } from '../../organisms/Table/BrandTable';

const sampleSearchList = [
  {
    id: 1,
    imageUrl: '/s3/20211213.jpg',
    name: 'ブランド名',
    shopCount: 20,
    shopShowType: 'クローズド',
    showType: '表示',
    oemShowFlg: true,
    richShowFlg: false,
  },
  {
    id: 2,
    imageUrl: '/s3/20211213.jpg',
    name: 'ブランド名',
    shopCount: 20,
    shopShowType: 'クローズド',
    showType: '非表示',
    oemShowFlg: false,
    richShowFlg: true,
  },
  {
    id: 3,
    imageUrl: '/s3/20211213.jpg',
    name: 'ブランド名',
    shopCount: 20,
    shopShowType: 'クローズド',
    showType: 'クローズド',
    oemShowFlg: true,
    richShowFlg: true,
  },
];

const sampleGetList = [...Array(41)].map((_, i) => {
  return {
    id: i + 1,
    imageUrl: '/s3/20211213.jpg',
    name: 'ブランド名',
    shopCount: 20,
    shopShowType: 'クローズド',
    showType: '表示',
    oemShowFlg: true,
    richShowFlg: false,
  };
});

const MAX_RECORD_PER_PAGE = 20;

export const BrandPage: React.VFC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [list, setList] = useState<BrandListOutputResponse[]>([]);
  const [listPerPage, setListPerPage] = useState<BrandListOutputResponse[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [updResult, setUpdResult] = useState<IncResultOutputResponse | null>(null);

  const history = useHistory();
  const brandApi = new BrandApi();

  useEffect(() => {
    brandApi.brandList().then((res: AxiosResponse<BrandListOutputResponse[]>) => {
      // res.data = sampleGetList;
      const sortedData = res.data.sort((a, b) => b.id - a.id);
      setList(sortedData);
      setListPerPage(sortedData.slice(0, MAX_RECORD_PER_PAGE));
      setTotalPage(Math.ceil(Number(sortedData?.length) / MAX_RECORD_PER_PAGE));
    });
    if (history?.action) history.action = 'POP';
  }, [history?.action]);

  useEffect(() => {
    setListPerPage(list.slice((currentPage - 1) * MAX_RECORD_PER_PAGE, currentPage * MAX_RECORD_PER_PAGE));
    setTotalPage(Math.ceil(Number(list?.length) / MAX_RECORD_PER_PAGE));
  }, [list, currentPage]);

  const handleClickSearchBtn = () => {
    brandApi.brandList(searchText).then((res: AxiosResponse<BrandListOutputResponse[]>) => {
      // res.data = sampleSearchList;
      setList(res.data.sort((a, b) => b.id - a.id));
      setCurrentPage(1);
    });
  };

  return (
    <>
      <Title className="mb-4">{TITLE.KEISAI.BRAND}</Title>
      {updResult?.errorCode && updResult?.errorMessage && (
        <Alert variant="danger">{`${updResult.errorMessage} (エラーコード：${updResult.errorCode})`}</Alert>
      )}

      <SearchFrom
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        onClick={handleClickSearchBtn}
      />
      <div className="d-flex justify-content-end mb-4">
        <Button variant="link" className="text-secondary">
          <FontAwesomeIcon icon={faPlus} fixedWidth className="me-1" />
          <Link to={`${Url.KEISAI.BRAND_MODIFY}`} target="_blank">
            追加
          </Link>
        </Button>
      </div>
      <BrandTable list={list} setList={setList} listPerPage={listPerPage} setUpdResult={setUpdResult} />
      <div className="mt-4">
        <PaginationWithEllipsis
          currentPage={currentPage}
          totalPage={totalPage}
          handleClick={(page) => {
            if (!page || page > totalPage) return;
            setListPerPage(list.slice((page - 1) * MAX_RECORD_PER_PAGE, page * MAX_RECORD_PER_PAGE));
            setCurrentPage(page);
          }}
        />
      </div>
    </>
  );
};
