import React, { useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { Button, Card, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
/* eslint-disable import/no-cycle */
import {
  ApiClientApi,
  ApiClientListOutputResponse,
  CommonMasterListOutputResponse,
  IncResultOutputResponse,
  MonitorApi,
  MonitorApiClientsUpdateFormResponse,
} from '../../../api-client';
import { useLargeState } from '../../../hooks/useLargeState';
import { createTestId, execDownload } from '../../../utils/functions';

import { Title } from '../../atoms/Title';
import { TITLE } from '../../../constants/Title';
import { TagsChoice } from '../../molecules/TagsChoice';
import { MonitorCommonModal } from '../../organisms/Modal/MonitorCommonModal';
import { Alert } from '../../atoms/Alert';

/* eslint-enable import/no-cycle */

export interface State {
  monitorApi: MonitorApi;
  apiClientApi: ApiClientApi;
  apiClientsOptions: CommonMasterListOutputResponse[];
  apiClients: CommonMasterListOutputResponse[];
  isOemModal: boolean;
  updateForm: MonitorApiClientsUpdateFormResponse;
  disableFlg: boolean;
  updateMonitorIds: string;
  updResult: IncResultOutputResponse;
  downloadMonitorIds: string;
}

export const MonitorApiClientsSettingPage: React.VFC = () => {
  const testid = createTestId(MonitorApiClientsSettingPage);
  const history = useHistory();

  const {
    state: $,
    mergeState,
    useBindSet,
  } = useLargeState<State>({
    monitorApi: new MonitorApi(),
    apiClientApi: new ApiClientApi(),
    apiClientsOptions: [],
    apiClients: [],
    isOemModal: false,
    updateForm: {
      apiClientIds: [],
      monitorIds: '',
    },
    disableFlg: false,
    updateMonitorIds: '',
    updResult: { result: false },
    downloadMonitorIds: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    $.updateMonitorIds = e.target.value;
  };

  const handleInputChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    $.downloadMonitorIds = e.target.value;
  };

  const handleOnDownload = async (e: any) => {
    if ($.downloadMonitorIds == null) return;
    $.monitorApi.monitorApiClientsDownload($.downloadMonitorIds).then((res: AxiosResponse<string>) => {
      execDownload(
        res.data,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'OEM表示範囲.xlsx'
      ).catch((err: IncResultOutputResponse) => {
        mergeState({ updResult: err });
      });
    });
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    mergeState({ disableFlg: true });

    $.monitorApi
      .monitorApiClientUpdate({
        apiClientIds: $.apiClients.map(({ id }) => id),
        monitorIds: $.updateMonitorIds,
      })
      .then((res: AxiosResponse<IncResultOutputResponse>) => {
        mergeState({ updResult: res.data });
      })
      .catch((err: IncResultOutputResponse) => {
        mergeState({ updResult: err });
      });

    mergeState({ disableFlg: false });
  };

  useEffect(() => {
    $.apiClientApi.apiClientList().then((res: AxiosResponse<ApiClientListOutputResponse[]>) => {
      mergeState({ apiClientsOptions: res.data.map(({ id, name }) => ({ id, name })) });
    });
  }, []);

  return (
    <>
      <MonitorCommonModal
        isModal={$.isOemModal}
        setIsModal={useBindSet('isOemModal')}
        options={$.apiClientsOptions}
        values={$.apiClients}
        setValues={useBindSet('apiClients')}
      />

      <Title className="mb-4" data-testid={testid('title')}>
        {TITLE.KEISAI.MONITOR_APICLIENTS_SETTING}
      </Title>

      <label>OEM表示範囲設定</label>
      <Form onSubmit={handleOnSubmit}>
        {$.updResult?.result && (
          <Alert variant="success" data-testid={testid('success-alert')}>
            OEM表示範囲を保存しました。
          </Alert>
        )}
        {$.updResult?.errorMessage && (
          <Alert variant="danger" data-testid={testid('failure-alert')}>{`${$.updResult.errorMessage}`}</Alert>
        )}
        <Form.Group className="mb-4" data-testid={testid('updateMonitorIds')}>
          <Form.Label>モニターID</Form.Label>
          <Form.Control
            type="text"
            required
            placeholder="複数の場合はカンマ区切りで入力"
            onChange={handleInputChange}
            disabled={$.disableFlg}
          />
        </Form.Group>

        <p>OEM表示範囲を無くす場合は、OEM表示範囲で何も選択せずに「保存」を押してください</p>

        <Card className="mb-4" data-testid={testid('apiClients')}>
          <Card.Header className="fw-bold">OEM表示範囲</Card.Header>
          <Card.Body>
            <TagsChoice
              tags={$.apiClients?.map(({ name }) => name)}
              onDelete={(name) => mergeState({ apiClients: $.apiClients.filter((v) => v.name !== name) })}
              onApply={() => mergeState({ isOemModal: true })}
              disabled={$.disableFlg}
            />
          </Card.Body>
        </Card>
        <Button id="keep" type="submit" style={{ textAlign: 'center' }} disabled={$.disableFlg}>
          保存
        </Button>
      </Form>

      <br />
      <br />
      <br />

      <label>OEM表示範囲リスト</label>
      <Form.Group className="mb-4" data-testid={testid('downloadMonitorIds')}>
        <Form.Label>モニターID</Form.Label>
        <Form.Control
          type="text"
          required
          placeholder="複数の場合はカンマ区切りで入力"
          onChange={handleInputChange2}
          disabled={$.disableFlg}
        />
      </Form.Group>
      <Button id="download" onClick={handleOnDownload} data-testid={testid('download-button')}>
        ダウンロード
      </Button>
    </>
  );
};
